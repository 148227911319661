import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge,OverlayTrigger, Container, Row, Col, Card} from 'react-bootstrap';
import { BeeList3 } from "./BeeList3";

class SpellingComponent3 extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            spellings:[]
        }
    }

    componentDidMount(){
    //  this.setState({spellings: BeeList3});
    }

    render(){
        return (
            <div>
                <br/>
                <Container>

                <Card
                    border="primary"
                    style={{ width: '18rem' },{padding: 16},{ height: '3rem' }}
                    className="mb-2"
                    bg={"Info".toLowerCase()}
                    text={"Info".toLowerCase() === 'light' ? 'dark' : 'white'}
                >
                    <Card.Body>
                    <Card.Title style={{ align: 'center' }} >2021 Spelling Bee Three Word List</Card.Title>
                    </Card.Body>
                </Card>

                <Row>
                {
                
                BeeList3.map(
                    (spelling,index) =>
                <Col xs="3">
                    <OverlayTrigger trigger={['hover', 'focus']} key={spelling.spelling_name}  placement="bottom" overlay={ 
                        <Card border="info" id={spelling.spelling_name} style={{ width: '21rem' }}
                        bg={"Info".toLowerCase()}
                        text={"Info".toLowerCase() === 'light' ? 'dark' : 'white'}
                        >
                        <Card.Title>{spelling.spelling_name}</Card.Title>
                        <Card.Img variant="top" src={spelling.spelling_name+'.png'} />
                        <Card.Body>
                            <Card.Text>
                                {  
                                    spelling.spellingDetails.map(
                                        detail =>
                                        <li key={detail.id} ><b>({detail.word_type})-{detail.spelling_usage}</b></li>
                                    )
                                    
                                }
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    }>

                        <Badge pill variant="light"
                        onClick={() => speakTheWord(spelling.spelling_name)}
                        key={spelling.spelling_name}
                        >
                            <h6>{spelling.spelling_name}</h6>
                        </Badge>
                    </OverlayTrigger>
                    </Col>
                    )
                  }
                    </Row>
                </Container>
            </div>
        )
    }
}

const speakTheWord = word => {
    if ( 'speechSynthesis' in window ) {
        var to_speak = new SpeechSynthesisUtterance(word);
            window.speechSynthesis.speak(to_speak);
    }
  };

export default SpellingComponent3