export const BeeList3=[
    {
        "id": 338,
        "spelling_name": "Badinage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 318,
                "word_type": "n.",
                "spelling_usage": "Playful raillery; banter."
            }
        ]
    },
    {
        "id": 390,
        "spelling_name": "Bailiwick",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 426,
                "word_type": "n.",
                "spelling_usage": "The precincts within which a bailiff has jurisdiction;\n   the limits of a bailiff's authority."
            }
        ]
    },
    {
        "id": 630,
        "spelling_name": "Bankrupt",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 885,
                "word_type": "n.",
                "spelling_usage": "A trader who secretes himself, or does certain other acts\n   tending to defraud his creditors."
            },
            {
                "spelling_detail_id": 886,
                "word_type": "n.",
                "spelling_usage": "A trader who becomes unable to pay his debts; an\n   insolvent trader; popularly, any person who is unable to pay his debts;\n   an insolvent person."
            },
            {
                "spelling_detail_id": 887,
                "word_type": "n.",
                "spelling_usage": "A person who, in accordance with the terms of a law\n   relating to bankruptcy, has been judicially declared to be unable to\n   meet his liabilities."
            },
            {
                "spelling_detail_id": 888,
                "word_type": "a.",
                "spelling_usage": "Being a bankrupt or in a condition of bankruptcy; unable\n   to pay, or legally discharged from paying, one's debts; as, a bankrupt\n   merchant."
            },
            {
                "spelling_detail_id": 889,
                "word_type": "a.",
                "spelling_usage": "Depleted of money; not having the means of meeting\n   pecuniary liabilities; as, a bankrupt treasury."
            },
            {
                "spelling_detail_id": 890,
                "word_type": "a.",
                "spelling_usage": "Relating to bankrupts and bankruptcy."
            },
            {
                "spelling_detail_id": 891,
                "word_type": "a.",
                "spelling_usage": "Destitute of, or wholly wanting (something once\n   possessed, or something one should possess)."
            },
            {
                "spelling_detail_id": 894,
                "word_type": "v. t.",
                "spelling_usage": "To make bankrupt; to bring financial ruin upon; to\n   impoverish."
            }
        ]
    },
    {
        "id": 873,
        "spelling_name": "Barricade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 1305,
                "word_type": "n.",
                "spelling_usage": "A fortification, made in haste, of trees, earth,\n   palisades, wagons, or anything that will obstruct the progress or\n   attack of an enemy. It is usually an obstruction formed in streets to\n   block an enemy's access."
            },
            {
                "spelling_detail_id": 1306,
                "word_type": "n.",
                "spelling_usage": "Any bar, obstruction, or means of defense."
            },
            {
                "spelling_detail_id": 1309,
                "word_type": "n.",
                "spelling_usage": "To fortify or close with a barricade or with barricades;\n   to stop up, as a passage; to obstruct; as, the workmen barricaded the\n   streets of Paris."
            }
        ]
    },
    {
        "id": 913,
        "spelling_name": "Bas-relief",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 1535,
                "word_type": "n.",
                "spelling_usage": "Low relief; sculpture, the figures of which project\n   less than half of their true proportions; -- called also bassrelief and\n   basso-rilievo. See Alto-rilievo."
            }
        ]
    },
    {
        "id": 967,
        "spelling_name": "Basilica",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 1480,
                "word_type": "n.",
                "spelling_usage": "Originally, the place of a king; but afterward, an\n   apartment provided in the houses of persons of importance, where\n   assemblies were held for dispensing justice; and hence, any large hall\n   used for this purpose."
            },
            {
                "spelling_detail_id": 1481,
                "word_type": "n.",
                "spelling_usage": "A building used by the Romans as a place of public\n   meeting, with court rooms, etc., attached."
            },
            {
                "spelling_detail_id": 1482,
                "word_type": "n.",
                "spelling_usage": "A church building of the earlier centuries of\n   Christianity, the plan of which was taken from the basilica of the\n   Romans. The name is still applied to some churches by way of honorary\n   distinction."
            },
            {
                "spelling_detail_id": 1483,
                "word_type": "n.",
                "spelling_usage": "A digest of the laws of Justinian, translated from the\n   original Latin into Greek, by order of Basil I., in the ninth century."
            }
        ]
    },
    {
        "id": 1077,
        "spelling_name": "Bathos",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 1694,
                "word_type": "n.",
                "spelling_usage": "A ludicrous descent from the elevated to the low, in\n   writing or speech; anticlimax."
            }
        ]
    },
    {
        "id": 1103,
        "spelling_name": "Battalion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 1728,
                "word_type": "n.",
                "spelling_usage": "A body of troops; esp. a body of troops or an army in\n   battle array."
            },
            {
                "spelling_detail_id": 1729,
                "word_type": "n.",
                "spelling_usage": "A regiment, or two or more companies of a regiment, esp.\n   when assembled for drill or battle."
            },
            {
                "spelling_detail_id": 1730,
                "word_type": "v. t.",
                "spelling_usage": "To form into battalions."
            }
        ]
    },
    {
        "id": 1148,
        "spelling_name": "Bauxite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 1815,
                "word_type": "n.",
                "spelling_usage": "Alt. of Beauxite"
            }
        ]
    },
    {
        "id": 1343,
        "spelling_name": "Beccafico",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 2262,
                "word_type": "n.",
                "spelling_usage": "A small bird. (Silvia hortensis), which is highly prized\n   by the Italians for the delicacy of its flesh in the autumn, when it\n   has fed on figs, grapes, etc."
            }
        ]
    },
    {
        "id": 1345,
        "spelling_name": "Bechamel",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 2263,
                "word_type": "n.",
                "spelling_usage": "A rich, white sauce, prepared with butter and cream."
            }
        ]
    },
    {
        "id": 1504,
        "spelling_name": "Beelzebub",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 2481,
                "word_type": "n.",
                "spelling_usage": "The title of a heathen deity to whom the Jews ascribed\n   the sovereignty of the evil spirits; hence, the Devil or a devil. See\n   Baal."
            }
        ]
    },
    {
        "id": 1880,
        "spelling_name": "Benedictine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 3099,
                "word_type": "a.",
                "spelling_usage": "Pertaining to the monks of St. Benedict, or St. Benet."
            },
            {
                "spelling_detail_id": 3100,
                "word_type": "n.",
                "spelling_usage": "One of a famous order of monks, established by St.\n   Benedict of Nursia in the sixth century. This order was introduced into\n   the United States in 1846."
            }
        ]
    },
    {
        "id": 1932,
        "spelling_name": "Benison",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 3186,
                "word_type": "n.",
                "spelling_usage": "Blessing; beatitude; benediction."
            }
        ]
    },
    {
        "id": 2253,
        "spelling_name": "Betony",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 3643,
                "word_type": "n.",
                "spelling_usage": "A plant of the genus Betonica (Linn.)."
            }
        ]
    },
    {
        "id": 2295,
        "spelling_name": "Beurre",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 3719,
                "word_type": "n.",
                "spelling_usage": "A beurre (or buttery) pear, one with the meat soft and\n   melting; -- used with a distinguishing word; as, Beurre d'Anjou; Beurre\n   Clairgeau."
            }
        ]
    },
    {
        "id": 2422,
        "spelling_name": "Bibliopegist",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 3899,
                "word_type": "n.",
                "spelling_usage": "A bookbinder."
            }
        ]
    },
    {
        "id": 2734,
        "spelling_name": "Binturong",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 4364,
                "word_type": "n.",
                "spelling_usage": "A small Asiatic civet of the genus Arctilis."
            }
        ]
    },
    {
        "id": 2799,
        "spelling_name": "Bipolar",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 4435,
                "word_type": "a.",
                "spelling_usage": "Doubly polar; having two poles; as, a bipolar cell or\n   corpuscle."
            }
        ]
    },
    {
        "id": 2991,
        "spelling_name": "Bitumen",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 4739,
                "word_type": "n.",
                "spelling_usage": "Mineral pitch; a black, tarry substance, burning with a\n   bright flame; Jew's pitch. It occurs as an abundant natural product in\n   many places, as on the shores of the Dead and Caspian Seas. It is used\n   in cements, in the construction of pavements, etc. See Asphalt."
            },
            {
                "spelling_detail_id": 4740,
                "word_type": "n.",
                "spelling_usage": "By extension, any one of the natural hydrocarbons,\n   including the hard, solid, brittle varieties called asphalt, the\n   semisolid maltha and mineral tars, the oily petroleums, and even the\n   light, volatile naphthas."
            }
        ]
    },
    {
        "id": 3135,
        "spelling_name": "Blancmange",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 4985,
                "word_type": "n.",
                "spelling_usage": "A preparation for desserts, etc., made from isinglass,\n   sea moss, cornstarch, or other gelatinous or starchy substance, with\n   mild, usually sweetened and flavored, and shaped in a mold."
            }
        ]
    },
    {
        "id": 3177,
        "spelling_name": "Blastema",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 5077,
                "word_type": "n.",
                "spelling_usage": "The structureless, protoplasmic tissue of the embryo; the\n   primitive basis of an organ yet unformed, from which it grows."
            }
        ]
    },
    {
        "id": 3208,
        "spelling_name": "Blatherskite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 5111,
                "word_type": "n.",
                "spelling_usage": "A blustering, talkative fellow."
            }
        ]
    },
    {
        "id": 3300,
        "spelling_name": "Blight",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 5289,
                "word_type": "v. t.",
                "spelling_usage": "To affect with blight; to blast; to prevent the growth\n   and fertility of."
            },
            {
                "spelling_detail_id": 5290,
                "word_type": "v. t.",
                "spelling_usage": "Hence: To destroy the happiness of; to ruin; to mar\n   essentially; to frustrate; as, to blight one's prospects."
            },
            {
                "spelling_detail_id": 5291,
                "word_type": "v. i.",
                "spelling_usage": "To be affected by blight; to blast; as, this vine never\n   blights."
            },
            {
                "spelling_detail_id": 5292,
                "word_type": "n.",
                "spelling_usage": "Mildew; decay; anything nipping or blasting; -- applied as\n   a general name to various injuries or diseases of plants, causing the\n   whole or a part to wither, whether occasioned by insects, fungi, or\n   atmospheric influences."
            },
            {
                "spelling_detail_id": 5293,
                "word_type": "n.",
                "spelling_usage": "The act of blighting, or the state of being blighted; a\n   withering or mildewing, or a stoppage of growth in the whole or a part\n   of a plant, etc."
            },
            {
                "spelling_detail_id": 5294,
                "word_type": "n.",
                "spelling_usage": "That which frustrates one's plans or withers one's hopes;\n   that which impairs or destroys."
            },
            {
                "spelling_detail_id": 5295,
                "word_type": "n.",
                "spelling_usage": "A downy species of aphis, or plant louse, destructive to\n   fruit trees, infesting both the roots and branches; -- also applied to\n   several other injurious insects."
            },
            {
                "spelling_detail_id": 5296,
                "word_type": "n.",
                "spelling_usage": "A rashlike eruption on the human skin."
            }
        ]
    },
    {
        "id": 3450,
        "spelling_name": "Blottesque",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 5579,
                "word_type": "a.",
                "spelling_usage": "Characterized by blots or heavy touches; coarsely\n   depicted; wanting in delineation."
            }
        ]
    },
    {
        "id": 3629,
        "spelling_name": "Bobolink",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 5948,
                "word_type": "n.",
                "spelling_usage": "An American singing bird (Dolichonyx oryzivorus). The\n   male is black and white; the female is brown; -- called also, ricebird,\n   reedbird, and Boblincoln."
            }
        ]
    },
    {
        "id": 3736,
        "spelling_name": "Bolide",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 6140,
                "word_type": "n.",
                "spelling_usage": "A kind of bright meteor; a bolis."
            }
        ]
    },
    {
        "id": 3748,
        "spelling_name": "Bolognese",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 6158,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Bologna."
            },
            {
                "spelling_detail_id": 6159,
                "word_type": "n.",
                "spelling_usage": "A native of Bologna."
            }
        ]
    },
    {
        "id": 3880,
        "spelling_name": "Bonspiel",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 6407,
                "word_type": "n.",
                "spelling_usage": "A cur/ing match between clubs."
            }
        ]
    },
    {
        "id": 4170,
        "spelling_name": "Bouillon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 6912,
                "word_type": "n.",
                "spelling_usage": "A nutritious liquid food made by boiling beef, or other\n   meat, in water; a clear soup or broth."
            },
            {
                "spelling_detail_id": 6913,
                "word_type": "n.",
                "spelling_usage": "An excrescence on a horse's frush or frog."
            }
        ]
    },
    {
        "id": 4213,
        "spelling_name": "Bourgeois",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 7007,
                "word_type": "n.",
                "spelling_usage": "A size of type between long primer and brevier. See\n   Type."
            },
            {
                "spelling_detail_id": 7008,
                "word_type": "n.",
                "spelling_usage": "A man of middle rank in society; one of the shopkeeping\n   class."
            },
            {
                "spelling_detail_id": 7009,
                "word_type": "a.",
                "spelling_usage": "Characteristic of the middle class, as in France."
            }
        ]
    },
    {
        "id": 4231,
        "spelling_name": "Boutade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 7031,
                "word_type": "n.",
                "spelling_usage": "An outbreak; a caprice; a whim."
            }
        ]
    },
    {
        "id": 4233,
        "spelling_name": "Boutonniere",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 7033,
                "word_type": "n.",
                "spelling_usage": "A bouquet worn in a buttonhole."
            }
        ]
    },
    {
        "id": 4714,
        "spelling_name": "Breviloquence",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 7974,
                "word_type": "n.",
                "spelling_usage": "A brief and pertinent mode of speaking."
            }
        ]
    },
    {
        "id": 5094,
        "spelling_name": "Brume",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 8618,
                "word_type": "n.",
                "spelling_usage": "Mist; fog; vapors."
            }
        ]
    },
    {
        "id": 5169,
        "spelling_name": "Buccal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 8739,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the mouth or cheeks."
            }
        ]
    },
    {
        "id": 5233,
        "spelling_name": "Buffa",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 8879,
                "word_type": "n. fem.",
                "spelling_usage": "The comic actress in an opera."
            },
            {
                "spelling_detail_id": 8880,
                "word_type": "a.",
                "spelling_usage": "Comic, farcical."
            }
        ]
    },
    {
        "id": 5878,
        "spelling_name": "Cachexia",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 9973,
                "word_type": "n.",
                "spelling_usage": "Alt. of Cachexy"
            }
        ]
    },
    {
        "id": 6028,
        "spelling_name": "Caisson",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 10170,
                "word_type": "n.",
                "spelling_usage": "A chest to hold ammunition."
            },
            {
                "spelling_detail_id": 10171,
                "word_type": "n.",
                "spelling_usage": "A four-wheeled carriage for conveying ammunition,\n   consisting of two parts, a body and a limber. In light field batteries\n   there is one caisson to each piece, having two ammunition boxes on the\n   body, and one on the limber."
            },
            {
                "spelling_detail_id": 10172,
                "word_type": "n.",
                "spelling_usage": "A chest filled with explosive materials, to be laid in the\n   way of an enemy and exploded on his approach."
            },
            {
                "spelling_detail_id": 10173,
                "word_type": "n.",
                "spelling_usage": "A water-tight box, of timber or iron within which work is\n   carried on in building foundations or structures below the water level."
            },
            {
                "spelling_detail_id": 10174,
                "word_type": "n.",
                "spelling_usage": "A hollow floating box, usually of iron, which serves to\n   close the entrances of docks and basins."
            },
            {
                "spelling_detail_id": 10175,
                "word_type": "n.",
                "spelling_usage": "A structure, usually with an air chamber, placed beneath a\n   vessel to lift or float it."
            },
            {
                "spelling_detail_id": 10176,
                "word_type": "n.",
                "spelling_usage": "A sunk panel of ceilings or soffits."
            }
        ]
    },
    {
        "id": 6152,
        "spelling_name": "Calefacient",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 10348,
                "word_type": "a.",
                "spelling_usage": "Making warm; heating."
            },
            {
                "spelling_detail_id": 10349,
                "word_type": "n.",
                "spelling_usage": "A substance that excites warmth in the parts to which\n   it is applied, as mustard."
            }
        ]
    },
    {
        "id": 6298,
        "spelling_name": "Calumny",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 10580,
                "word_type": "n.",
                "spelling_usage": "False accusation of a crime or offense, maliciously made\n   or reported, to the injury of another; malicious misrepresentation;\n   slander; detraction."
            }
        ]
    },
    {
        "id": 6338,
        "spelling_name": "Camarilla",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 10636,
                "word_type": "n.",
                "spelling_usage": "The private audience chamber of a king."
            },
            {
                "spelling_detail_id": 10637,
                "word_type": "n.",
                "spelling_usage": "A company of secret and irresponsible advisers, as of a\n   king; a cabal or clique."
            }
        ]
    },
    {
        "id": 6449,
        "spelling_name": "Canaille",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 10793,
                "word_type": "n.",
                "spelling_usage": "The lowest class of people; the rabble; the vulgar."
            },
            {
                "spelling_detail_id": 10794,
                "word_type": "n.",
                "spelling_usage": "Shorts or inferior flour."
            }
        ]
    },
    {
        "id": 6628,
        "spelling_name": "Cantatrice",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 11097,
                "word_type": "n.",
                "spelling_usage": "A female professional singer."
            }
        ]
    },
    {
        "id": 6829,
        "spelling_name": "Capsaicin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 11426,
                "word_type": "n.",
                "spelling_usage": "A colorless crystalline substance extracted from the\n   Capsicum annuum, and giving off vapors of intense acridity."
            }
        ]
    },
    {
        "id": 7201,
        "spelling_name": "Carrion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 11976,
                "word_type": "n.",
                "spelling_usage": "The dead and putrefying body or flesh of an animal; flesh\n   so corrupted as to be unfit for food."
            },
            {
                "spelling_detail_id": 11977,
                "word_type": "n.",
                "spelling_usage": "A contemptible or worthless person; -- a term of reproach."
            },
            {
                "spelling_detail_id": 11978,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to dead and putrefying carcasses; feeding\n   on carrion."
            }
        ]
    },
    {
        "id": 7229,
        "spelling_name": "Carthusian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12043,
                "word_type": "n.",
                "spelling_usage": "A member of an exceeding austere religious order,\n   founded at Chartreuse in France by St. Bruno, in the year 1086."
            },
            {
                "spelling_detail_id": 12044,
                "word_type": "a.",
                "spelling_usage": "Pertaining to the Carthusian."
            }
        ]
    },
    {
        "id": 7246,
        "spelling_name": "Cartouches",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12061,
                "word_type": "pl. ",
                "spelling_usage": "of Cartouch"
            }
        ]
    },
    {
        "id": 7273,
        "spelling_name": "Caryatid",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12116,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a caryatid."
            },
            {
                "spelling_detail_id": 12118,
                "word_type": "n.",
                "spelling_usage": "A draped female figure supporting an entablature, in the\n   place of a column or pilaster."
            }
        ]
    },
    {
        "id": 7345,
        "spelling_name": "Cassock",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12239,
                "word_type": "n.",
                "spelling_usage": "A long outer garment formerly worn by men and women, as\n   well as by soldiers as part of their uniform."
            },
            {
                "spelling_detail_id": 12240,
                "word_type": "n.",
                "spelling_usage": "A garment resembling a long frock coat worn by the clergy\n   of certain churches when officiating, and by others as the usually\n   outer garment."
            }
        ]
    },
    {
        "id": 7367,
        "spelling_name": "Castellated",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12306,
                "word_type": "a.",
                "spelling_usage": "Inclosed within a building; as, a fountain or cistern\n   castellated."
            },
            {
                "spelling_detail_id": 12307,
                "word_type": "a.",
                "spelling_usage": "Furnished with turrets and battlements, like a castle;\n   built in the style of a castle."
            }
        ]
    },
    {
        "id": 7377,
        "spelling_name": "Castilian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12323,
                "word_type": "n.",
                "spelling_usage": "An inhabitant or native of Castile, in Spain."
            },
            {
                "spelling_detail_id": 12324,
                "word_type": "n.",
                "spelling_usage": "The Spanish language as spoken in Castile."
            }
        ]
    },
    {
        "id": 7436,
        "spelling_name": "Catachresis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12410,
                "word_type": "n.",
                "spelling_usage": "A figure by which one word is wrongly put for another,\n   or by which a word is wrested from its true signification; as, \"To take\n   arms against a sea of troubles\". Shak. \"Her voice was but the shadow of\n   a sound.\" Young."
            }
        ]
    },
    {
        "id": 7491,
        "spelling_name": "Catarrh",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 12482,
                "word_type": "n.",
                "spelling_usage": "An inflammatory affection of any mucous membrane, in which\n   there are congestion, swelling, and an altertion in the quantity and\n   quality of mucus secreted; as, catarrh of the stomach; catarrh of the\n   bladder."
            }
        ]
    },
    {
        "id": 8034,
        "spelling_name": "Cephalopod",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 13269,
                "word_type": "n.",
                "spelling_usage": "Alt. of Cephalopode"
            }
        ]
    },
    {
        "id": 8234,
        "spelling_name": "Cetology",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 13527,
                "word_type": "n.",
                "spelling_usage": "The description or natural history of cetaceous animals."
            }
        ]
    },
    {
        "id": 8326,
        "spelling_name": "Chalet",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 13682,
                "word_type": "n.",
                "spelling_usage": "A herdsman's hut in the mountains of Switzerland."
            },
            {
                "spelling_detail_id": 13683,
                "word_type": "n.",
                "spelling_usage": "A summer cottage or country house in the Swiss mountains;\n   any country house built in the style of the Swiss cottages."
            }
        ]
    },
    {
        "id": 8327,
        "spelling_name": "Chalice",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 13684,
                "word_type": "n.",
                "spelling_usage": "A cup or bowl; especially, the cup used in the sacrament\n   of the Lord's Supper."
            }
        ]
    },
    {
        "id": 8378,
        "spelling_name": "Champignon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 13787,
                "word_type": "n.",
                "spelling_usage": "An edible species of mushroom (Agaricus campestris)."
            }
        ]
    },
    {
        "id": 8617,
        "spelling_name": "Chasuble",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 14277,
                "word_type": "n.",
                "spelling_usage": "The outer vestment worn by the priest in saying Mass,\n   consisting, in the Roman Catholic Church, of a broad, flat, back piece,\n   and a narrower front piece, the two connected over the shoulders only.\n   The back has usually a large cross, the front an upright bar or pillar,\n   designed to be emblematical of Christ's sufferings. In the Greek Church\n   the chasuble is a large round mantle."
            }
        ]
    },
    {
        "id": 8831,
        "spelling_name": "Chevalier",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 14623,
                "word_type": "n.",
                "spelling_usage": "A horseman; a knight; a gallant young man."
            },
            {
                "spelling_detail_id": 14624,
                "word_type": "n.",
                "spelling_usage": "A member of certain orders of knighthood."
            }
        ]
    },
    {
        "id": 8872,
        "spelling_name": "Chicanery",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 14679,
                "word_type": "n.",
                "spelling_usage": "Mean or unfair artifice to perplex a cause and obscure\n   the truth; stratagem; sharp practice; sophistry."
            }
        ]
    },
    {
        "id": 8975,
        "spelling_name": "Chimera",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 14847,
                "word_type": "n.",
                "spelling_usage": "A monster represented as vomiting flames, and as having\n   the head of a lion, the body of a goat, and the tail of a dragon."
            },
            {
                "spelling_detail_id": 14848,
                "word_type": "n.",
                "spelling_usage": "A vain, foolish, or incongruous fancy, or creature of the\n   imagination; as, the chimera of an author."
            }
        ]
    },
    {
        "id": 9441,
        "spelling_name": "Chrysolite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 15530,
                "word_type": "n.",
                "spelling_usage": "A mineral, composed of silica, magnesia, and iron, of a\n   yellow to green color. It is common in certain volcanic rocks; --\n   called also olivine and peridot. Sometimes used as a gem. The name was\n   also early used for yellow varieties of tourmaline and topaz."
            }
        ]
    },
    {
        "id": 9561,
        "spelling_name": "Cicatrize",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 15699,
                "word_type": "v. t.",
                "spelling_usage": "To heal or induce the formation of a cicatrix in, as\n   in wounded or ulcerated flesh."
            },
            {
                "spelling_detail_id": 15700,
                "word_type": "v. i.",
                "spelling_usage": "To heal; to have a new skin."
            }
        ]
    },
    {
        "id": 9965,
        "spelling_name": "Clamorous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 16299,
                "word_type": "a.",
                "spelling_usage": "Speaking and repeating loud words; full of clamor;\n   calling or demanding loudly or urgently; vociferous; noisy; bawling;\n   loud; turbulent."
            }
        ]
    },
    {
        "id": 10091,
        "spelling_name": "Clavichord",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 16504,
                "word_type": "n.",
                "spelling_usage": "A keyed stringed instrument, now superseded by the\n   pianoforte. See Clarichord."
            }
        ]
    },
    {
        "id": 10179,
        "spelling_name": "Clematis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 16687,
                "word_type": "n.",
                "spelling_usage": "A genus of flowering plants, of many species, mostly\n   climbers, having feathery styles, which greatly enlarge in the fruit;\n   -- called also virgin's bower."
            }
        ]
    },
    {
        "id": 10538,
        "spelling_name": "Coadjutor",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 17315,
                "word_type": "n.",
                "spelling_usage": "One who aids another; an assistant; a coworker."
            },
            {
                "spelling_detail_id": 17316,
                "word_type": "n.",
                "spelling_usage": "The assistant of a bishop or of a priest holding a\n   benefice."
            }
        ]
    },
    {
        "id": 10579,
        "spelling_name": "Coalescence",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 17370,
                "word_type": "n.",
                "spelling_usage": "The act or state of growing together, as similar\n   parts; the act of uniting by natural affinity or attraction; the state\n   of being united; union; concretion."
            }
        ]
    },
    {
        "id": 10619,
        "spelling_name": "Coaxation",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 17450,
                "word_type": "n.",
                "spelling_usage": "The act of croaking."
            }
        ]
    },
    {
        "id": 10671,
        "spelling_name": "Coccygeal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 17534,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the coccyx; as, the coccygeal\n   vertebrae."
            }
        ]
    },
    {
        "id": 10774,
        "spelling_name": "Codicil",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 17712,
                "word_type": "n.",
                "spelling_usage": "A clause added to a will."
            }
        ]
    },
    {
        "id": 10944,
        "spelling_name": "Coiffure",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 17957,
                "word_type": "n.",
                "spelling_usage": "A headdress, or manner of dressing the hair."
            }
        ]
    },
    {
        "id": 11182,
        "spelling_name": "Colonel",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 18342,
                "word_type": "n.",
                "spelling_usage": "The chief officer of a regiment; an officer ranking next\n   above a lieutenant colonel and next below a brigadier general."
            }
        ]
    },
    {
        "id": 11235,
        "spelling_name": "Colporteur",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 18421,
                "word_type": "n.",
                "spelling_usage": "A hawker; specifically, one who travels about selling\n   and distributing religious tracts and books."
            }
        ]
    },
    {
        "id": 12006,
        "spelling_name": "Concinnate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 19770,
                "word_type": "v. t.",
                "spelling_usage": "To place fitly together; to adapt; to clear."
            }
        ]
    },
    {
        "id": 12551,
        "spelling_name": "Conjure",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 20644,
                "word_type": "v. t.",
                "spelling_usage": "To call on or summon by a sacred name or in solemn\n   manner; to implore earnestly; to adjure."
            },
            {
                "spelling_detail_id": 20645,
                "word_type": "v. i.",
                "spelling_usage": "To combine together by an oath; to conspire; to\n   confederate."
            },
            {
                "spelling_detail_id": 20646,
                "word_type": "v. t.",
                "spelling_usage": "To affect or effect by conjuration; to call forth or\n   send away by magic arts; to excite or alter, as if by magic or by the\n   aid of supernatural powers."
            },
            {
                "spelling_detail_id": 20647,
                "word_type": "v. i.",
                "spelling_usage": "To practice magical arts; to use the tricks of a\n   conjurer; to juggle; to charm."
            }
        ]
    },
    {
        "id": 12593,
        "spelling_name": "Connoisseur",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 20706,
                "word_type": "n.",
                "spelling_usage": "One well versed in any subject; a skillful or knowing\n   person; a critical judge of any art, particulary of one of the fine\n   arts."
            }
        ]
    },
    {
        "id": 12761,
        "spelling_name": "Consomme",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 20996,
                "word_type": "n.",
                "spelling_usage": "A clear soup or bouillion boiled down so as to be very\n   rich."
            }
        ]
    },
    {
        "id": 12845,
        "spelling_name": "Constricting",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 21142,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Constrict"
            }
        ]
    },
    {
        "id": 13145,
        "spelling_name": "Contretemps",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 21631,
                "word_type": "n.",
                "spelling_usage": "An unexpected and untoward accident; something\n   inopportune or embarrassing; a hitch."
            }
        ]
    },
    {
        "id": 13201,
        "spelling_name": "Contumelious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 21713,
                "word_type": "a.",
                "spelling_usage": "Exhibiting contumely; rudely contemptuous; insolent;\n   disdainful."
            },
            {
                "spelling_detail_id": 21714,
                "word_type": "a.",
                "spelling_usage": "Shameful; disgraceful."
            }
        ]
    },
    {
        "id": 13480,
        "spelling_name": "Copious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22172,
                "word_type": "a.",
                "spelling_usage": "Large in quantity or amount; plentiful; abundant;\n   fruitful."
            }
        ]
    },
    {
        "id": 13520,
        "spelling_name": "Copse",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22221,
                "word_type": "n.",
                "spelling_usage": "A wood of small growth; a thicket of brushwood. See Coppice."
            },
            {
                "spelling_detail_id": 22222,
                "word_type": "v. t.",
                "spelling_usage": "To trim or cut; -- said of small trees, brushwood, tufts\n   of grass, etc."
            },
            {
                "spelling_detail_id": 22223,
                "word_type": "v. t.",
                "spelling_usage": "To plant and preserve, as a copse."
            }
        ]
    },
    {
        "id": 13610,
        "spelling_name": "Cordillera",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22376,
                "word_type": "n.",
                "spelling_usage": "A mountain ridge or chain."
            }
        ]
    },
    {
        "id": 13654,
        "spelling_name": "Cormorant",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22460,
                "word_type": "n.",
                "spelling_usage": "Any species of Phalacrocorax, a genus of sea birds\n   having a sac under the beak; the shag. Cormorants devour fish\n   voraciously, and have become the emblem of gluttony. They are generally\n   black, and hence are called sea ravens, and coalgeese."
            },
            {
                "spelling_detail_id": 22461,
                "word_type": "n.",
                "spelling_usage": "A voracious eater; a glutton, or gluttonous servant."
            }
        ]
    },
    {
        "id": 13777,
        "spelling_name": "Corporate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22653,
                "word_type": "a.",
                "spelling_usage": "Formed into a body by legal enactment; united in an\n   association, and endowed by law with the rights and liabilities of an\n   individual; incorporated; as, a corporate town."
            },
            {
                "spelling_detail_id": 22654,
                "word_type": "a.",
                "spelling_usage": "Belonging to a corporation or incorporated body."
            },
            {
                "spelling_detail_id": 22655,
                "word_type": "a.",
                "spelling_usage": "United; general; collectively one."
            },
            {
                "spelling_detail_id": 22656,
                "word_type": "v. t.",
                "spelling_usage": "To incorporate."
            },
            {
                "spelling_detail_id": 22657,
                "word_type": "v. i.",
                "spelling_usage": "To become incorporated."
            }
        ]
    },
    {
        "id": 13852,
        "spelling_name": "Corrigenda",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22772,
                "word_type": "pl. ",
                "spelling_usage": "of Corrigendum"
            }
        ]
    },
    {
        "id": 13910,
        "spelling_name": "Corsair",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22862,
                "word_type": "n.",
                "spelling_usage": "A pirate; one who cruises about without authorization from\n   any government, to seize booty on sea or land."
            },
            {
                "spelling_detail_id": 22863,
                "word_type": "n.",
                "spelling_usage": "A piratical vessel."
            }
        ]
    },
    {
        "id": 13951,
        "spelling_name": "Corybantic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 22912,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or resembling, the Corybantes or\n   their rites; frantic; frenzied; as, a corybantic dance."
            }
        ]
    },
    {
        "id": 14068,
        "spelling_name": "Coterie",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 23071,
                "word_type": "n.",
                "spelling_usage": "A set or circle of persons who meet familiarly, as for\n   social, literary, or other purposes; a clique."
            }
        ]
    },
    {
        "id": 14139,
        "spelling_name": "Coulomb",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 23189,
                "word_type": "n.",
                "spelling_usage": "The standard unit of quantity in electrical measurements.\n   It is the quantity of electricity conveyed in one second by the current\n   produced by an electro-motive force of one volt acting in a circuit\n   having a resistance of one ohm, or the quantity transferred by one\n   ampere in one second. Formerly called weber."
            }
        ]
    },
    {
        "id": 14161,
        "spelling_name": "Countenance",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 23242,
                "word_type": "v. t.",
                "spelling_usage": "To encourage; to favor; to approve; to aid; to\n   abet."
            },
            {
                "spelling_detail_id": 23243,
                "word_type": "v. t.",
                "spelling_usage": "To make a show of; to pretend."
            }
        ]
    },
    {
        "id": 14525,
        "spelling_name": "Cozen",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 23857,
                "word_type": "v. t.",
                "spelling_usage": "To cheat; to defraud; to beguile; to deceive, usually by\n   small arts, or in a pitiful way."
            },
            {
                "spelling_detail_id": 23858,
                "word_type": "v. i.",
                "spelling_usage": "To deceive; to cheat; to act deceitfully."
            }
        ]
    },
    {
        "id": 14873,
        "spelling_name": "Crepuscular",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 24474,
                "word_type": "a.",
                "spelling_usage": "Alt. of Crepusculous"
            }
        ]
    },
    {
        "id": 14882,
        "spelling_name": "Crescive",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 24496,
                "word_type": "a.",
                "spelling_usage": "Increasing; growing."
            }
        ]
    },
    {
        "id": 15244,
        "spelling_name": "Croustade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 25116,
                "word_type": "n.",
                "spelling_usage": "Bread baked in a mold, and scooped out, to serve minces\n   upon."
            }
        ]
    },
    {
        "id": 16040,
        "spelling_name": "Cygnet",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 26437,
                "word_type": "n.",
                "spelling_usage": "A young swan."
            }
        ]
    },
    {
        "id": 16187,
        "spelling_name": "Dactylic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 26630,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, consisting chiefly or wholly of, dactyls;\n   as, dactylic verses."
            },
            {
                "spelling_detail_id": 26631,
                "word_type": "n.",
                "spelling_usage": "A line consisting chiefly or wholly of dactyls; as, these\n   lines are dactylics."
            },
            {
                "spelling_detail_id": 26632,
                "word_type": "n.",
                "spelling_usage": "Dactylic meters."
            }
        ]
    },
    {
        "id": 16237,
        "spelling_name": "Daguerreotype",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 26708,
                "word_type": "n.",
                "spelling_usage": "An early variety of photograph, produced on a silver\n   plate, or copper plate covered with silver, and rendered sensitive by\n   the action of iodine, or iodine and bromine, on which, after exposure\n   in the camera, the latent image is developed by the vapor of mercury."
            },
            {
                "spelling_detail_id": 26709,
                "word_type": "n.",
                "spelling_usage": "The process of taking such pictures."
            },
            {
                "spelling_detail_id": 26712,
                "word_type": "v. t.",
                "spelling_usage": "To produce or represent by the daguerreotype\n   process, as a picture."
            },
            {
                "spelling_detail_id": 26713,
                "word_type": "v. t.",
                "spelling_usage": "To impress with great distinctness; to imprint;\n   to imitate exactly."
            }
        ]
    },
    {
        "id": 16359,
        "spelling_name": "Damson",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 26897,
                "word_type": "n.",
                "spelling_usage": "A small oval plum of a blue color, the fruit of a variety\n   of the Prunus domestica; -- called also damask plum."
            }
        ]
    },
    {
        "id": 16465,
        "spelling_name": "Darnel",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 27079,
                "word_type": "n.",
                "spelling_usage": "Any grass of the genus Lolium, esp. the Lolium temulentum\n   (bearded darnel), the grains of which have been reputed poisonous.\n   Other species, as Lolium perenne (rye grass or ray grass), and its\n   variety L. Italicum (Italian rye grass), are highly esteemed for\n   pasture and for making hay."
            }
        ]
    },
    {
        "id": 16851,
        "spelling_name": "Decastich",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 27670,
                "word_type": "n.",
                "spelling_usage": "A poem consisting of ten lines."
            }
        ]
    },
    {
        "id": 17579,
        "spelling_name": "Deleterious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 28767,
                "word_type": "a.",
                "spelling_usage": "Hurtful; noxious; destructive; pernicious; as, a\n   deleterious plant or quality; a deleterious example."
            }
        ]
    },
    {
        "id": 17648,
        "spelling_name": "Deliquesce",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 28883,
                "word_type": "v. i.",
                "spelling_usage": "To dissolve gradually and become liquid by\n   attracting and absorbing moisture from the air, as certain salts,\n   acids, and alkalies."
            }
        ]
    },
    {
        "id": 17901,
        "spelling_name": "Demulcent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 29245,
                "word_type": "a.",
                "spelling_usage": "Softening; mollifying; soothing; assuasive; as, oil is\n   demulcent."
            },
            {
                "spelling_detail_id": 29246,
                "word_type": "n.",
                "spelling_usage": "A substance, usually of a mucilaginous or oily nature,\n   supposed to be capable of soothing an inflamed nervous membrane, or\n   protecting it from irritation. Gum Arabic, glycerin, olive oil, etc.,\n   are demulcents."
            }
        ]
    },
    {
        "id": 17909,
        "spelling_name": "Demurrage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 29264,
                "word_type": "n.",
                "spelling_usage": "The detention of a vessel by the freighter beyond the\n   time allowed in her charter party for loading, unloading, or sailing."
            },
            {
                "spelling_detail_id": 29265,
                "word_type": "n.",
                "spelling_usage": "The allowance made to the master or owner of the ship\n   for such delay or detention."
            }
        ]
    },
    {
        "id": 17943,
        "spelling_name": "Dengue",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 29309,
                "word_type": "n.",
                "spelling_usage": "A specific epidemic disease attended with high fever,\n   cutaneous eruption, and severe pains in the head and limbs, resembling\n   those of rheumatism; -- called also breakbone fever. It occurs in\n   India, Egypt, the West Indies, etc., is of short duration, and rarely\n   fatal."
            }
        ]
    },
    {
        "id": 17985,
        "spelling_name": "Denouement",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 29370,
                "word_type": "n.",
                "spelling_usage": "The unraveling or discovery of a plot; the catastrophe,\n   especially of a drama or a romance."
            },
            {
                "spelling_detail_id": 29371,
                "word_type": "n.",
                "spelling_usage": "The solution of a mystery; issue; outcome."
            }
        ]
    },
    {
        "id": 18489,
        "spelling_name": "Desiccate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 30132,
                "word_type": "v. t.",
                "spelling_usage": "To dry up; to deprive or exhaust of moisture; to\n   preserve by drying; as, to desiccate fish or fruit."
            },
            {
                "spelling_detail_id": 30133,
                "word_type": "v. i.",
                "spelling_usage": "To become dry."
            }
        ]
    },
    {
        "id": 18557,
        "spelling_name": "Desman",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 30236,
                "word_type": "n.",
                "spelling_usage": "An amphibious, insectivorous mammal found in Russia\n   (Myogale moschata). It is allied to the moles, but is called muskrat by\n   some English writers."
            }
        ]
    },
    {
        "id": 18880,
        "spelling_name": "Devastavit",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 30672,
                "word_type": "n.",
                "spelling_usage": "Waste or misapplication of the assets of a deceased\n   person by an executor or an administrator."
            }
        ]
    },
    {
        "id": 18982,
        "spelling_name": "Devout",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 30839,
                "word_type": "v. t.",
                "spelling_usage": "Devoted to religion or to religious feelings and duties;\n   absorbed in religious exercises; given to devotion; pious; reverent;\n   religious."
            },
            {
                "spelling_detail_id": 30840,
                "word_type": "v. t.",
                "spelling_usage": "Expressing devotion or piety; as, eyes devout; sighs\n   devout; a devout posture."
            },
            {
                "spelling_detail_id": 30841,
                "word_type": "v. t.",
                "spelling_usage": "Warmly devoted; hearty; sincere; earnest; as, devout\n   wishes for one's welfare."
            },
            {
                "spelling_detail_id": 30842,
                "word_type": "n.",
                "spelling_usage": "A devotee."
            },
            {
                "spelling_detail_id": 30843,
                "word_type": "n.",
                "spelling_usage": "A devotional composition, or part of a composition;\n   devotion."
            }
        ]
    },
    {
        "id": 19039,
        "spelling_name": "Dhole",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 30918,
                "word_type": "n.",
                "spelling_usage": "A fierce, wild dog (Canis Dukhunensis), found in the\n   mountains of India. It is remarkable for its propensity to hunt the\n   tiger and other wild animals in packs."
            }
        ]
    },
    {
        "id": 19175,
        "spelling_name": "Diapason",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 31105,
                "word_type": "n.",
                "spelling_usage": "The octave, or interval which includes all the tones of\n   the diatonic scale."
            },
            {
                "spelling_detail_id": 31106,
                "word_type": "n.",
                "spelling_usage": "Concord, as of notes an octave apart; harmony."
            },
            {
                "spelling_detail_id": 31107,
                "word_type": "n.",
                "spelling_usage": "The entire compass of tones."
            },
            {
                "spelling_detail_id": 31108,
                "word_type": "n.",
                "spelling_usage": "A standard of pitch; a tuning fork; as, the French normal\n   diapason."
            },
            {
                "spelling_detail_id": 31109,
                "word_type": "n.",
                "spelling_usage": "One of certain stops in the organ, so called because they\n   extend through the scale of the instrument. They are of several kinds,\n   as open diapason, stopped diapason, double diapason, and the like."
            }
        ]
    },
    {
        "id": 19226,
        "spelling_name": "Diastole",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 31176,
                "word_type": "n.",
                "spelling_usage": "The rhythmical expansion or dilatation of the heart and\n   arteries; -- correlative to systole, or contraction."
            },
            {
                "spelling_detail_id": 31177,
                "word_type": "n.",
                "spelling_usage": "A figure by which a syllable naturally short is made\n   long."
            }
        ]
    },
    {
        "id": 19625,
        "spelling_name": "Diluent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 31768,
                "word_type": "a.",
                "spelling_usage": "Diluting; making thinner or weaker by admixture, esp. of\n   water."
            },
            {
                "spelling_detail_id": 31769,
                "word_type": "n.",
                "spelling_usage": "That which dilutes."
            },
            {
                "spelling_detail_id": 31770,
                "word_type": "n.",
                "spelling_usage": "An agent used for effecting dilution of the blood; a weak\n   drink."
            }
        ]
    },
    {
        "id": 19788,
        "spelling_name": "Diphtheria",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32020,
                "word_type": "n.",
                "spelling_usage": "A very dangerous contagious disease in which the air\n   passages, and especially the throat, become coated with a false\n   membrane, produced by the solidification of an inflammatory exudation.\n   Cf. Group."
            }
        ]
    },
    {
        "id": 19896,
        "spelling_name": "Dirigible",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32188,
                "word_type": "a.",
                "spelling_usage": "Capable of being directed; steerable; as, a dirigible\n   balloon."
            }
        ]
    },
    {
        "id": 20132,
        "spelling_name": "Disciform",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32514,
                "word_type": "a.",
                "spelling_usage": "Discoid."
            }
        ]
    },
    {
        "id": 20215,
        "spelling_name": "Disconcerting",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32645,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Disconcert"
            }
        ]
    },
    {
        "id": 20227,
        "spelling_name": "Disconsolate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32661,
                "word_type": "n.",
                "spelling_usage": "Disconsolateness."
            },
            {
                "spelling_detail_id": 32662,
                "word_type": "v. t.",
                "spelling_usage": "Destitute of consolation; deeply dejected and\n   dispirited; hopelessly sad; comfortless; filled with grief; as, a\n   bereaved and disconsolate parent."
            },
            {
                "spelling_detail_id": 32663,
                "word_type": "v. t.",
                "spelling_usage": "Inspiring dejection; saddening; cheerless; as, the\n   disconsolate darkness of the winter nights."
            }
        ]
    },
    {
        "id": 20251,
        "spelling_name": "Discord",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32699,
                "word_type": "v. i.",
                "spelling_usage": "Want of concord or agreement; absence of unity or\n   harmony in sentiment or action; variance leading to contention and\n   strife; disagreement; -- applied to persons or to things, and to\n   thoughts, feelings, or purposes."
            },
            {
                "spelling_detail_id": 32700,
                "word_type": "v. i.",
                "spelling_usage": "Union of musical sounds which strikes the ear harshly\n   or disagreeably, owing to the incommensurability of the vibrations\n   which they produce; want of musical concord or harmony; a chord\n   demanding resolution into a concord."
            },
            {
                "spelling_detail_id": 32701,
                "word_type": "n.",
                "spelling_usage": "To disagree; to be discordant; to jar; to clash; not to\n   suit."
            }
        ]
    },
    {
        "id": 20406,
        "spelling_name": "Disembogue",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 32949,
                "word_type": "v. t.",
                "spelling_usage": "To pour out or discharge at the mouth, as a stream;\n   to vent; to discharge into an ocean, a lake, etc."
            },
            {
                "spelling_detail_id": 32950,
                "word_type": "v. t.",
                "spelling_usage": "To eject; to cast forth."
            },
            {
                "spelling_detail_id": 32951,
                "word_type": "v. i.",
                "spelling_usage": "To become discharged; to flow out; to find vent; to\n   pour out contents."
            }
        ]
    },
    {
        "id": 20525,
        "spelling_name": "Disgorged",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 33086,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Disgorge"
            }
        ]
    },
    {
        "id": 20730,
        "spelling_name": "Dismayed",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 33382,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Dismay"
            }
        ]
    },
    {
        "id": 21202,
        "spelling_name": "Dissuade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 34076,
                "word_type": "v. t.",
                "spelling_usage": "To advise or exhort against; to try to persuade (one\n   from a course)."
            },
            {
                "spelling_detail_id": 34077,
                "word_type": "v. t.",
                "spelling_usage": "To divert by persuasion; to turn from a purpose by\n   reasons or motives; -- with from; as, I could not dissuade him from his\n   purpose."
            }
        ]
    },
    {
        "id": 21576,
        "spelling_name": "Divulge",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 34748,
                "word_type": "v. t.",
                "spelling_usage": "To make public; to several or communicate to the\n   public; to tell (a secret) so that it may become generally known; to\n   disclose; -- said of that which had been confided as a secret, or had\n   been before unknown; as, to divulge a secret."
            },
            {
                "spelling_detail_id": 34749,
                "word_type": "v. t.",
                "spelling_usage": "To indicate publicly; to proclaim."
            },
            {
                "spelling_detail_id": 34750,
                "word_type": "v. t.",
                "spelling_usage": "To impart; to communicate."
            },
            {
                "spelling_detail_id": 34751,
                "word_type": "v. i.",
                "spelling_usage": "To become publicly known."
            }
        ]
    },
    {
        "id": 21808,
        "spelling_name": "Dolmen",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 35097,
                "word_type": "n.",
                "spelling_usage": "A cromlech. See Cromlech."
            }
        ]
    },
    {
        "id": 22777,
        "spelling_name": "Dudgeon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 36892,
                "word_type": "n.",
                "spelling_usage": "The root of the box tree, of which hafts for daggers were\n   made."
            },
            {
                "spelling_detail_id": 36893,
                "word_type": "n.",
                "spelling_usage": "The haft of a dagger."
            },
            {
                "spelling_detail_id": 36894,
                "word_type": "n.",
                "spelling_usage": "A dudgeon-hafted dagger; a dagger."
            },
            {
                "spelling_detail_id": 36895,
                "word_type": "n.",
                "spelling_usage": "Resentment; ill will; anger; displeasure."
            },
            {
                "spelling_detail_id": 36896,
                "word_type": "a.",
                "spelling_usage": "Homely; rude; coarse."
            }
        ]
    },
    {
        "id": 22821,
        "spelling_name": "Dulcinea",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 36965,
                "word_type": "n.",
                "spelling_usage": "A mistress; a sweetheart."
            }
        ]
    },
    {
        "id": 23325,
        "spelling_name": "Ecchymosis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 37747,
                "word_type": "n.",
                "spelling_usage": "A livid or black and blue spot, produced by the\n   extravasation or effusion of blood into the areolar tissue from a\n   contusion."
            }
        ]
    },
    {
        "id": 23341,
        "spelling_name": "Ecclesiology",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 37766,
                "word_type": "n.",
                "spelling_usage": "The science or theory of church building and\n   decoration."
            }
        ]
    },
    {
        "id": 23673,
        "spelling_name": "Efflux",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 38225,
                "word_type": "n.",
                "spelling_usage": "The act or process of flowing out, or issuing forth;\n   effusion; outflow; as, the efflux of matter from an ulcer; the efflux\n   of men's piety."
            },
            {
                "spelling_detail_id": 38226,
                "word_type": "n.",
                "spelling_usage": "That which flows out; emanation; effluence."
            },
            {
                "spelling_detail_id": 38227,
                "word_type": "v. i.",
                "spelling_usage": "To run out; to flow forth; to pass away."
            }
        ]
    },
    {
        "id": 23865,
        "spelling_name": "Elan",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 38488,
                "word_type": "b.",
                "spelling_usage": "Ardor inspired by passion or enthusiasm."
            }
        ]
    },
    {
        "id": 24066,
        "spelling_name": "Eleemosynary",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 38768,
                "word_type": "a.",
                "spelling_usage": "Relating to charity, alms, or almsgiving; intended\n   for the distribution of charity; as, an eleemosynary corporation."
            },
            {
                "spelling_detail_id": 38769,
                "word_type": "a.",
                "spelling_usage": "Given in charity or alms; having the nature of alms;\n   as, eleemosynary assistance."
            },
            {
                "spelling_detail_id": 38770,
                "word_type": "a.",
                "spelling_usage": "Supported by charity; as, eleemosynary poor."
            },
            {
                "spelling_detail_id": 38772,
                "word_type": "n.",
                "spelling_usage": "One who subsists on charity; a dependent."
            }
        ]
    },
    {
        "id": 24071,
        "spelling_name": "Elegiac",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 38779,
                "word_type": "a.",
                "spelling_usage": "Belonging to elegy, or written in elegiacs; plaintive;\n   expressing sorrow or lamentation; as, an elegiac lay; elegiac strains."
            },
            {
                "spelling_detail_id": 38780,
                "word_type": "a.",
                "spelling_usage": "Used in elegies; as, elegiac verse; the elegiac distich or\n   couplet, consisting of a dactylic hexameter and pentameter."
            },
            {
                "spelling_detail_id": 38781,
                "word_type": "n.",
                "spelling_usage": "Elegiac verse."
            }
        ]
    },
    {
        "id": 24448,
        "spelling_name": "Embolus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 39316,
                "word_type": "n.",
                "spelling_usage": "Something inserted, as a wedge; the piston or sucker of a\n   pump or syringe."
            },
            {
                "spelling_detail_id": 39317,
                "word_type": "n.",
                "spelling_usage": "A plug of some substance lodged in a blood vessel, being\n   brought thither by the blood current. It consists most frequently of a\n   clot of fibrin, a detached shred of a morbid growth, a globule of fat,\n   or a microscopic organism."
            }
        ]
    },
    {
        "id": 24459,
        "spelling_name": "Embouchure",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 39338,
                "word_type": "n.",
                "spelling_usage": "The mouth of a river; also, the mouth of a cannon."
            },
            {
                "spelling_detail_id": 39339,
                "word_type": "n.",
                "spelling_usage": "The mouthpiece of a wind instrument."
            },
            {
                "spelling_detail_id": 39340,
                "word_type": "n.",
                "spelling_usage": "The shaping of the lips to the mouthpiece; as, a flute\n   player has a good embouchure."
            }
        ]
    },
    {
        "id": 24634,
        "spelling_name": "Emollient",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 39562,
                "word_type": "a.",
                "spelling_usage": "Softening; making supple; acting as an emollient."
            },
            {
                "spelling_detail_id": 39563,
                "word_type": "n.",
                "spelling_usage": "An external something or soothing application to allay\n   irritation, soreness, etc."
            }
        ]
    },
    {
        "id": 24636,
        "spelling_name": "Emolument",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 39565,
                "word_type": "n.",
                "spelling_usage": "The profit arising from office, employment, or labor;\n   gain; compensation; advantage; perquisites, fees, or salary."
            }
        ]
    },
    {
        "id": 25332,
        "spelling_name": "Engrossed",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 40516,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Engross"
            }
        ]
    },
    {
        "id": 25452,
        "spelling_name": "Ennui",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 40674,
                "word_type": "n.",
                "spelling_usage": "A feeling of weariness and disgust; dullness and languor of\n   spirits, arising from satiety or want of interest; tedium."
            }
        ]
    },
    {
        "id": 25898,
        "spelling_name": "Epaulet",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 41293,
                "word_type": "n.",
                "spelling_usage": "Alt. of Epaulette"
            }
        ]
    },
    {
        "id": 25911,
        "spelling_name": "Epenthesis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 41307,
                "word_type": "n.",
                "spelling_usage": "The insertion of a letter or a sound in the body of a\n   word; as, the b in \"nimble\" from AS. n/mol."
            }
        ]
    },
    {
        "id": 25986,
        "spelling_name": "Epideictic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 41405,
                "word_type": "a.",
                "spelling_usage": "Serving to show forth, explain, or exhibit; -- applied\n   by the Greeks to a kind of oratory, which, by full amplification, seeks\n   to persuade."
            }
        ]
    },
    {
        "id": 26528,
        "spelling_name": "Erubescent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42141,
                "word_type": "a.",
                "spelling_usage": "Red, or reddish; blushing."
            }
        ]
    },
    {
        "id": 26615,
        "spelling_name": "Escheator",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42268,
                "word_type": "n.",
                "spelling_usage": "An officer whose duty it is to observe what escheats\n   have taken place, and to take charge of them."
            }
        ]
    },
    {
        "id": 26634,
        "spelling_name": "Escritoire",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42293,
                "word_type": "n.",
                "spelling_usage": "A piece of furniture used as a writing table, commonly\n   with drawers, pigeonholes, and the like; a secretary or writing desk."
            }
        ]
    },
    {
        "id": 26700,
        "spelling_name": "Esplanade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42360,
                "word_type": "n.",
                "spelling_usage": "A clear space between a citadel and the nearest houses\n   of the town."
            },
            {
                "spelling_detail_id": 42361,
                "word_type": "n.",
                "spelling_usage": "The glacis of the counterscarp, or the slope of the\n   parapet of the covered way toward the country."
            },
            {
                "spelling_detail_id": 42362,
                "word_type": "n.",
                "spelling_usage": "A grass plat; a lawn."
            },
            {
                "spelling_detail_id": 42363,
                "word_type": "n.",
                "spelling_usage": "Any clear, level space used for public walks or drives;\n   esp., a terrace by the seaside."
            }
        ]
    },
    {
        "id": 26777,
        "spelling_name": "Estival",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42502,
                "word_type": "n.",
                "spelling_usage": "Alt. of Estivation"
            }
        ]
    },
    {
        "id": 26785,
        "spelling_name": "Estovers",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42511,
                "word_type": "n. pl.",
                "spelling_usage": "Necessaries or supples; an allowance to a person out\n   of an estate or other thing for support; as of wood to a tenant for\n   life, etc., of sustenance to a man confined for felony of his estate,\n   or alimony to a woman divorced out of her husband's estate."
            }
        ]
    },
    {
        "id": 26822,
        "spelling_name": "Etagere",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42558,
                "word_type": "n.",
                "spelling_usage": "A piece of furniture having a number of uninclosed shelves\n   or stages, one above another, for receiving articles of elegance or\n   use."
            }
        ]
    },
    {
        "id": 26911,
        "spelling_name": "Ethylene",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42684,
                "word_type": "n.",
                "spelling_usage": "A colorless, gaseous hydrocarbon, C2H4, forming an\n   important ingredient of illuminating gas, and also obtained by the\n   action of concentrated sulphuric acid in alcohol. It is an unsaturated\n   compound and combines directly with chlorine and bromine to form oily\n   liquids (Dutch liquid), -- hence called olefiant gas. Called also\n   ethene, elayl, and formerly, bicarbureted hydrogen."
            }
        ]
    },
    {
        "id": 26928,
        "spelling_name": "Etruscan",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42708,
                "word_type": "n.",
                "spelling_usage": "Of or relating to Etruria."
            },
            {
                "spelling_detail_id": 42709,
                "word_type": "n.",
                "spelling_usage": "A native or inhabitant of Etruria."
            }
        ]
    },
    {
        "id": 27056,
        "spelling_name": "Euphonious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42847,
                "word_type": "a.",
                "spelling_usage": "Pleasing or sweet in sound; euphonic; smooth-sounding."
            }
        ]
    },
    {
        "id": 27088,
        "spelling_name": "Euripus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 42883,
                "word_type": "n.",
                "spelling_usage": "A strait; a narrow tract of water, where the tide, or a\n   current, flows and reflows with violence, as the ancient fright of this\n   name between Eubaea and Baeotia. Hence, a flux and reflux."
            }
        ]
    },
    {
        "id": 27243,
        "spelling_name": "Eviction",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 43114,
                "word_type": "n.",
                "spelling_usage": "The act or process of evicting; or state of being\n   evicted; the recovery of lands, tenements, etc., from another's\n   possession by due course of law; dispossession by paramount title or\n   claim of such title; ejectment; ouster."
            },
            {
                "spelling_detail_id": 43115,
                "word_type": "n.",
                "spelling_usage": "Conclusive evidence; proof."
            }
        ]
    },
    {
        "id": 27494,
        "spelling_name": "Exchequer",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 43457,
                "word_type": "n.",
                "spelling_usage": "One of the superior courts of law; -- so called from a\n   checkered cloth, which covers, or formerly covered, the table."
            },
            {
                "spelling_detail_id": 43458,
                "word_type": "n.",
                "spelling_usage": "The department of state having charge of the collection\n   and management of the royal revenue. [Eng.] Hence, the treasury; and,\n   colloquially, pecuniary possessions in general; as, the company's\n   exchequer is low."
            },
            {
                "spelling_detail_id": 43461,
                "word_type": "v. t.",
                "spelling_usage": "To institute a process against (any one) in the Court\n   of Exchequer."
            }
        ]
    },
    {
        "id": 27623,
        "spelling_name": "Execrable",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 43638,
                "word_type": "a.",
                "spelling_usage": "Deserving to be execrated; accursed; damnable;\n   detestable; abominable; as, an execrable wretch."
            }
        ]
    },
    {
        "id": 27709,
        "spelling_name": "Exhalation",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 43780,
                "word_type": "n.",
                "spelling_usage": "The act or process of exhaling, or sending forth in the\n   form of steam or vapor; evaporation."
            },
            {
                "spelling_detail_id": 43781,
                "word_type": "n.",
                "spelling_usage": "That which is exhaled, or which rises in the form of\n   vapor, fume, or steam; effluvium; emanation; as, exhalations from the\n   earth or flowers, decaying matter, etc."
            },
            {
                "spelling_detail_id": 43782,
                "word_type": "n.",
                "spelling_usage": "A bright phenomenon; a meteor."
            }
        ]
    },
    {
        "id": 27764,
        "spelling_name": "Exiguous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 43868,
                "word_type": "a.",
                "spelling_usage": "Scanty; small; slender; diminutive."
            }
        ]
    },
    {
        "id": 27805,
        "spelling_name": "Exogenous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 43926,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or having the character of, an exogen; --\n   the opposite of endogenous."
            },
            {
                "spelling_detail_id": 43927,
                "word_type": "a.",
                "spelling_usage": "Growing by addition to the exterior."
            },
            {
                "spelling_detail_id": 43928,
                "word_type": "a.",
                "spelling_usage": "Growing from previously ossified parts; -- opposed to\n   autogenous."
            }
        ]
    },
    {
        "id": 27885,
        "spelling_name": "Expatiate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 44030,
                "word_type": "v. i.",
                "spelling_usage": "To range at large, or without restraint."
            },
            {
                "spelling_detail_id": 44031,
                "word_type": "v. i.",
                "spelling_usage": "To enlarge in discourse or writing; to be copious in\n   argument or discussion; to descant."
            },
            {
                "spelling_detail_id": 44032,
                "word_type": "v. t.",
                "spelling_usage": "To expand; to spread; to extend; to diffuse; to\n   broaden."
            }
        ]
    },
    {
        "id": 28098,
        "spelling_name": "Expugnable",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 44381,
                "word_type": "a.",
                "spelling_usage": "Capable of being expugned."
            }
        ]
    },
    {
        "id": 28138,
        "spelling_name": "Exsect",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 44424,
                "word_type": "v. t.",
                "spelling_usage": "A cutting out or away."
            },
            {
                "spelling_detail_id": 44425,
                "word_type": "v. t.",
                "spelling_usage": "The removal by operation of a portion of a limb;\n   particularly, the removal of a portion of a bone in the vicinity of a\n   joint; the act or process of cutting out."
            }
        ]
    },
    {
        "id": 28337,
        "spelling_name": "Extravasate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 44715,
                "word_type": "v. t.",
                "spelling_usage": "To force or let out of the proper vessels or\n   arteries, as blood."
            }
        ]
    },
    {
        "id": 28534,
        "spelling_name": "Facile",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 45023,
                "word_type": "a.",
                "spelling_usage": "Easy to be done or performed: not difficult; performable or\n   attainable with little labor."
            },
            {
                "spelling_detail_id": 45024,
                "word_type": "a.",
                "spelling_usage": "Easy to be surmounted or removed; easily conquerable;\n   readily mastered."
            },
            {
                "spelling_detail_id": 45025,
                "word_type": "a.",
                "spelling_usage": "Easy of access or converse; mild; courteous; not haughty,\n   austere, or distant; affable; complaisant."
            },
            {
                "spelling_detail_id": 45026,
                "word_type": "a.",
                "spelling_usage": "Easily persuaded to good or bad; yielding; ductile to a\n   fault; pliant; flexible."
            },
            {
                "spelling_detail_id": 45027,
                "word_type": "a.",
                "spelling_usage": "Ready; quick; expert; as, he is facile in expedients; he\n   wields a facile pen."
            }
        ]
    },
    {
        "id": 28545,
        "spelling_name": "Facsimile",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 45048,
                "word_type": "n.",
                "spelling_usage": "A copy of anything made, either so as to be deceptive or\n   so as to give every part and detail of the original; an exact copy or\n   likeness."
            },
            {
                "spelling_detail_id": 45049,
                "word_type": "v. t.",
                "spelling_usage": "To make a facsimile of."
            }
        ]
    },
    {
        "id": 28795,
        "spelling_name": "Fanfaronade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 45575,
                "word_type": "n.",
                "spelling_usage": "A swaggering; vain boasting; ostentation; a bluster."
            }
        ]
    },
    {
        "id": 28865,
        "spelling_name": "Farina",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 45699,
                "word_type": "n.",
                "spelling_usage": "A fine flour or meal made from cereal grains or from the\n   starch or fecula of vegetables, extracted by various processes, and\n   used in cookery."
            },
            {
                "spelling_detail_id": 45700,
                "word_type": "n.",
                "spelling_usage": "Pollen."
            }
        ]
    },
    {
        "id": 28888,
        "spelling_name": "Farrago",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 45742,
                "word_type": "n.",
                "spelling_usage": "A mass composed of various materials confusedly mixed; a\n   medley; a mixture."
            }
        ]
    },
    {
        "id": 29462,
        "spelling_name": "Ferruginous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 46724,
                "word_type": "a.",
                "spelling_usage": "Partaking of iron; containing particles of iron."
            },
            {
                "spelling_detail_id": 46725,
                "word_type": "a.",
                "spelling_usage": "Resembling iron rust in appearance or color; brownish\n   red, or yellowish red."
            }
        ]
    },
    {
        "id": 29511,
        "spelling_name": "Fester",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 46800,
                "word_type": "n.",
                "spelling_usage": "To generate pus; to become imflamed and suppurate; as, a\n   sore or a wound festers."
            },
            {
                "spelling_detail_id": 46801,
                "word_type": "n.",
                "spelling_usage": "To be inflamed; to grow virulent, or malignant; to grow in\n   intensity; to rankle."
            },
            {
                "spelling_detail_id": 46802,
                "word_type": "v. t.",
                "spelling_usage": "To cause to fester or rankle."
            },
            {
                "spelling_detail_id": 46803,
                "word_type": "n.",
                "spelling_usage": "A small sore which becomes inflamed and discharges corrupt\n   matter; a pustule."
            },
            {
                "spelling_detail_id": 46804,
                "word_type": "n.",
                "spelling_usage": "A festering or rankling."
            }
        ]
    },
    {
        "id": 29685,
        "spelling_name": "Ficus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 47041,
                "word_type": "n.",
                "spelling_usage": "A genus of trees or shrubs, one species of which (F. Carica)\n   produces the figs of commerce; the fig tree."
            }
        ]
    },
    {
        "id": 29790,
        "spelling_name": "Filar",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 47256,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a thread or line; characterized by\n   threads stretched across the field of view; as, a filar microscope; a\n   filar micrometer."
            }
        ]
    },
    {
        "id": 30184,
        "spelling_name": "Flagellum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 47994,
                "word_type": "v. t.",
                "spelling_usage": "A young, flexible shoot of a plant; esp., the long\n   trailing branch of a vine, or a slender branch in certain mosses."
            },
            {
                "spelling_detail_id": 47995,
                "word_type": "v. t.",
                "spelling_usage": "A long, whiplike cilium. See Flagellata."
            },
            {
                "spelling_detail_id": 47996,
                "word_type": "v. t.",
                "spelling_usage": "An appendage of the reproductive apparatus of the\n   snail."
            },
            {
                "spelling_detail_id": 47997,
                "word_type": "v. t.",
                "spelling_usage": "A lashlike appendage of a crustacean, esp. the\n   terminal ortion of the antennae and the epipodite of the maxilipeds.\n   See Maxilliped."
            }
        ]
    },
    {
        "id": 30250,
        "spelling_name": "Flaneur",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 48090,
                "word_type": "n.",
                "spelling_usage": "One who strolls about aimlessly; a lounger; a loafer."
            }
        ]
    },
    {
        "id": 30378,
        "spelling_name": "Fleche",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 48355,
                "word_type": "n.",
                "spelling_usage": "A simple fieldwork, consisting of two faces forming a\n   salient angle pointing outward and open at the gorge."
            }
        ]
    },
    {
        "id": 30873,
        "spelling_name": "Fodder",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 49260,
                "word_type": "n.",
                "spelling_usage": "A weight by which lead and some other metals were formerly\n   sold, in England, varying from 19/ to 24 cwt.; a fother."
            },
            {
                "spelling_detail_id": 49261,
                "word_type": "n.",
                "spelling_usage": "That which is fed out to cattle horses, and sheep, as hay,\n   cornstalks, vegetables, etc."
            },
            {
                "spelling_detail_id": 49264,
                "word_type": "v.t.",
                "spelling_usage": "To feed, as cattle, with dry food or cut grass, etc.;to\n   furnish with hay, straw, oats, etc."
            }
        ]
    },
    {
        "id": 31557,
        "spelling_name": "Fortissimo",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 50432,
                "word_type": "adv.",
                "spelling_usage": "Very loud; with the utmost strength or loudness."
            }
        ]
    },
    {
        "id": 31609,
        "spelling_name": "Fossiliferous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 50522,
                "word_type": "a.",
                "spelling_usage": "Containing or composed of fossils."
            }
        ]
    },
    {
        "id": 31800,
        "spelling_name": "Franchise",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 50859,
                "word_type": "a.",
                "spelling_usage": "Exemption from constraint or oppression; freedom;\n   liberty."
            },
            {
                "spelling_detail_id": 50860,
                "word_type": "a.",
                "spelling_usage": "A particular privilege conferred by grant from a\n   sovereign or a government, and vested in individuals; an imunity or\n   exemption from ordinary jurisdiction; a constitutional or statutory\n   right or privilege, esp. the right to vote."
            },
            {
                "spelling_detail_id": 50861,
                "word_type": "a.",
                "spelling_usage": "The district or jurisdiction to which a particular\n   privilege extends; the limits of an immunity; hence, an asylum or\n   sanctuary."
            },
            {
                "spelling_detail_id": 50862,
                "word_type": "a.",
                "spelling_usage": "Magnanimity; generosity; liberality; frankness;\n   nobility."
            },
            {
                "spelling_detail_id": 50865,
                "word_type": "v. t.",
                "spelling_usage": "To make free; to enfranchise; to give liberty to."
            }
        ]
    },
    {
        "id": 31811,
        "spelling_name": "Frangipane",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 50875,
                "word_type": "n.",
                "spelling_usage": "A perfume of jasmine; frangipani."
            },
            {
                "spelling_detail_id": 50876,
                "word_type": "n.",
                "spelling_usage": "A species of pastry, containing cream and almonds."
            }
        ]
    },
    {
        "id": 32054,
        "spelling_name": "Frigate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 51302,
                "word_type": "n.",
                "spelling_usage": "Originally, a vessel of the Mediterranean propelled by\n   sails and by oars. The French, about 1650, transferred the name to\n   larger vessels, and by 1750 it had been appropriated for a class of war\n   vessels intermediate between corvettes and ships of the line. Frigates,\n   from about 1750 to 1850, had one full battery deck and, often, a spar\n   deck with a lighter battery. They carried sometimes as many as fifty\n   guns. After the application of steam to navigation steam frigates of\n   largely increased size and power were built, and formed the main part\n   of the navies of the world till about 1870, when the introduction of\n   ironclads superseded them."
            },
            {
                "spelling_detail_id": 51303,
                "word_type": "n.",
                "spelling_usage": "Any small vessel on the water."
            }
        ]
    },
    {
        "id": 32386,
        "spelling_name": "Fulgent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 51832,
                "word_type": "a.",
                "spelling_usage": "Exquisitely bright; shining; dazzling; effulgent."
            }
        ]
    },
    {
        "id": 32632,
        "spelling_name": "Furuncle",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 52211,
                "word_type": "n.",
                "spelling_usage": "A superficial, inflammatory tumor, suppurating with a\n   central core; a boil."
            }
        ]
    },
    {
        "id": 32717,
        "spelling_name": "Gabbro",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 52352,
                "word_type": "n.",
                "spelling_usage": "A name originally given by the Italians to a kind of\n   serpentine, later to the rock called euphotide, and now generally used\n   for a coarsely crystalline, igneous rock consisting of lamellar\n   pyroxene (diallage) and labradorite, with sometimes chrysolite (olivine\n   gabbro)."
            }
        ]
    },
    {
        "id": 32919,
        "spelling_name": "Gallivat",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 52675,
                "word_type": "n.",
                "spelling_usage": "A small armed vessel, with sails and oars, -- used on the\n   Malabar coast."
            }
        ]
    },
    {
        "id": 32945,
        "spelling_name": "Galoot",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 52719,
                "word_type": "n.",
                "spelling_usage": "A noisy, swaggering, or worthless fellow; a rowdy."
            }
        ]
    },
    {
        "id": 32997,
        "spelling_name": "Gambol",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 52778,
                "word_type": "n.",
                "spelling_usage": "A skipping or leaping about in frolic; a hop; a sportive\n   prank."
            },
            {
                "spelling_detail_id": 52783,
                "word_type": "v. i.",
                "spelling_usage": "To dance and skip about in sport; to frisk; to skip; to\n   play in frolic, like boys or lambs."
            }
        ]
    },
    {
        "id": 33179,
        "spelling_name": "Garrulous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 53066,
                "word_type": "a.",
                "spelling_usage": "Talking much, especially about commonplace or trivial\n   things; talkative; loquacious."
            },
            {
                "spelling_detail_id": 53067,
                "word_type": "a.",
                "spelling_usage": "Having a loud, harsh note; noisy; -- said of birds; as,\n   the garrulous roller."
            }
        ]
    },
    {
        "id": 33504,
        "spelling_name": "Gendarme",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 53556,
                "word_type": "n.",
                "spelling_usage": "One of a body of heavy cavalry."
            },
            {
                "spelling_detail_id": 53557,
                "word_type": "n.",
                "spelling_usage": "An armed policeman in France."
            }
        ]
    },
    {
        "id": 33622,
        "spelling_name": "Gentilitial",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 53756,
                "word_type": "a.",
                "spelling_usage": "Alt. of Gentilitious"
            }
        ]
    },
    {
        "id": 33653,
        "spelling_name": "Genuflect",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 53812,
                "word_type": "v. i.",
                "spelling_usage": "To bend the knee, as in worship."
            }
        ]
    },
    {
        "id": 33870,
        "spelling_name": "Giallolino",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 54109,
                "word_type": "n.",
                "spelling_usage": "A term variously employed by early writers on art,\n   though commonly designating the yellow oxide of lead, or massicot."
            }
        ]
    },
    {
        "id": 34001,
        "spelling_name": "Ginglymus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 54329,
                "word_type": "n.",
                "spelling_usage": "A hinge joint; an articulation, admitting of flexion and\n   extension, or motion in two directions only, as the elbow and the\n   ankle."
            }
        ]
    },
    {
        "id": 34020,
        "spelling_name": "Girandole",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 54348,
                "word_type": "n.",
                "spelling_usage": "An ornamental branched candlestick."
            },
            {
                "spelling_detail_id": 54349,
                "word_type": "n.",
                "spelling_usage": "A flower stand, fountain, or the like, of branching\n   form."
            },
            {
                "spelling_detail_id": 54350,
                "word_type": "n.",
                "spelling_usage": "A kind of revolving firework."
            },
            {
                "spelling_detail_id": 54351,
                "word_type": "n.",
                "spelling_usage": "A series of chambers in defensive mines."
            }
        ]
    },
    {
        "id": 34077,
        "spelling_name": "Glacis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 54482,
                "word_type": "n.",
                "spelling_usage": "A gentle slope, or a smooth, gently sloping bank;\n   especially (Fort.), that slope of earth which inclines from the covered\n   way toward the exterior ground or country (see Illust. of Ravelin)."
            }
        ]
    },
    {
        "id": 34185,
        "spelling_name": "Glaucomatous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 54648,
                "word_type": "a.",
                "spelling_usage": "Having the nature of glaucoma."
            }
        ]
    },
    {
        "id": 34200,
        "spelling_name": "Glazier",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 54674,
                "word_type": "n.",
                "spelling_usage": "One whose business is to set glass."
            }
        ]
    },
    {
        "id": 34576,
        "spelling_name": "Gnomon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 55213,
                "word_type": "n.",
                "spelling_usage": "The style or pin, which by its shadow, shows the hour of\n   the day. It is usually set parallel to the earth's axis."
            },
            {
                "spelling_detail_id": 55214,
                "word_type": "n.",
                "spelling_usage": "A style or column erected perpendicularly to the horizon,\n   formerly used in astronomocal observations. Its principal use was to\n   find the altitude of the sun by measuring the length of its shadow."
            },
            {
                "spelling_detail_id": 55215,
                "word_type": "n.",
                "spelling_usage": "The space included between the boundary lines of two\n   similar parallelograms, the one within the other, with an angle in\n   common; as, the gnomon bcdefg of the parallelograms ac and af. The\n   parallelogram bf is the complement of the parallelogram df."
            },
            {
                "spelling_detail_id": 55216,
                "word_type": "n.",
                "spelling_usage": "The index of the hour circle of a globe."
            }
        ]
    },
    {
        "id": 34811,
        "spelling_name": "Goosander",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 55563,
                "word_type": "n.",
                "spelling_usage": "A species of merganser (M. merganser) of Northern Europe\n   and America; -- called also merganser, dundiver, sawbill, sawneb,\n   shelduck, and sheldrake. See Merganser."
            }
        ]
    },
    {
        "id": 35217,
        "spelling_name": "Graticule",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 56291,
                "word_type": "n.",
                "spelling_usage": "A design or draught which has been divided into squares,\n   in order to reproduce it in other dimensions."
            }
        ]
    },
    {
        "id": 35509,
        "spelling_name": "Grison",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 56818,
                "word_type": "n.",
                "spelling_usage": "A South American animal of the family Mustelidae (Galictis\n   vittata). It is about two feet long, exclusive of the tail. Its under\n   parts are black. Also called South American glutton."
            },
            {
                "spelling_detail_id": 56819,
                "word_type": "n.",
                "spelling_usage": "A South American monkey (Lagothrix infumatus), said to be\n   gluttonous."
            }
        ]
    },
    {
        "id": 35761,
        "spelling_name": "Gudgeon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 57237,
                "word_type": "n.",
                "spelling_usage": "A small European freshwater fish (Gobio fluviatilis),\n   allied to the carp. It is easily caught and often used for food and for\n   bait. In America the killifishes or minnows are often called gudgeons."
            },
            {
                "spelling_detail_id": 57238,
                "word_type": "n.",
                "spelling_usage": "What may be got without skill or merit."
            },
            {
                "spelling_detail_id": 57239,
                "word_type": "n.",
                "spelling_usage": "A person easily duped or cheated."
            },
            {
                "spelling_detail_id": 57240,
                "word_type": "n.",
                "spelling_usage": "The pin of iron fastened in the end of a wooden shaft or\n   axle, on which it turns; formerly, any journal, or pivot, or bearing,\n   as the pintle and eye of a hinge, but esp. the end journal of a\n   horizontal."
            },
            {
                "spelling_detail_id": 57241,
                "word_type": "n.",
                "spelling_usage": "A metal eye or socket attached to the sternpost to receive\n   the pintle of the rudder."
            },
            {
                "spelling_detail_id": 57242,
                "word_type": "v. t.",
                "spelling_usage": "To deprive fraudulently; to cheat; to dupe; to impose\n   upon."
            }
        ]
    },
    {
        "id": 35778,
        "spelling_name": "Guerite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 57259,
                "word_type": "n.",
                "spelling_usage": "A projecting turret for a sentry, as at the salient angles\n   of works, or the acute angles of bastions."
            }
        ]
    },
    {
        "id": 35829,
        "spelling_name": "Guilloche",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 57335,
                "word_type": "n.",
                "spelling_usage": "An ornament in the form of two or more bands or strings\n   twisted over each other in a continued series, leaving circular\n   openings which are filled with round ornaments."
            }
        ]
    },
    {
        "id": 35858,
        "spelling_name": "Gules",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 57379,
                "word_type": "n.",
                "spelling_usage": "The tincture red, indicated in seals and engraved figures of\n   escutcheons by parallel vertical lines. Hence, used poetically for a\n   red color or that which is red."
            }
        ]
    },
    {
        "id": 36140,
        "spelling_name": "Habeas corpus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 57756,
                "word_type": "",
                "spelling_usage": "A writ having for its object to bring a party before a\n   court or judge; especially, one to inquire into the cause of a person's\n   imprisonment or detention by another, with the view to protect the\n   right to personal liberty; also, one to bring a prisoner into court to\n   testify in a pending trial."
            }
        ]
    },
    {
        "id": 36181,
        "spelling_name": "Hackamore",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 57836,
                "word_type": "n.",
                "spelling_usage": "A halter consisting of a long leather or rope strap and\n   headstall, -- used for leading or tieing a pack animal."
            }
        ]
    },
    {
        "id": 36329,
        "spelling_name": "Hagiographer",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 58020,
                "word_type": "n.",
                "spelling_usage": "One of the writers of the hagiographa; a writer of\n   lives of the saints."
            }
        ]
    },
    {
        "id": 36388,
        "spelling_name": "Halcyon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 58106,
                "word_type": "n.",
                "spelling_usage": "A kingfisher. By modern ornithologists restricted to a\n   genus including a limited number of species having omnivorous habits,\n   as the sacred kingfisher (Halcyon sancta) of Australia."
            },
            {
                "spelling_detail_id": 58107,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or resembling, the halcyon, which was\n   anciently said to lay her eggs in nests on or near the sea during the\n   calm weather about the winter solstice."
            },
            {
                "spelling_detail_id": 58108,
                "word_type": "a.",
                "spelling_usage": "Hence: Calm; quiet; peaceful; undisturbed; happy."
            }
        ]
    },
    {
        "id": 36524,
        "spelling_name": "Hamadryad",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 58306,
                "word_type": "n.",
                "spelling_usage": "A tree nymph whose life ended with that of the\n   particular tree, usually an oak, which had been her abode."
            },
            {
                "spelling_detail_id": 58307,
                "word_type": "n.",
                "spelling_usage": "A large venomous East Indian snake (Orhiophagus\n   bungarus), allied to the cobras."
            }
        ]
    },
    {
        "id": 36696,
        "spelling_name": "Harangue",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 58620,
                "word_type": "n.",
                "spelling_usage": "A speech addressed to a large public assembly; a popular\n   oration; a loud address a multitude; in a bad sense, a noisy or pompous\n   speech; declamation; ranting."
            },
            {
                "spelling_detail_id": 58623,
                "word_type": "v. i.",
                "spelling_usage": "To make an harangue; to declaim."
            },
            {
                "spelling_detail_id": 58624,
                "word_type": "v. t.",
                "spelling_usage": "To address by an harangue."
            }
        ]
    },
    {
        "id": 36779,
        "spelling_name": "Haricot",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 58765,
                "word_type": "n.",
                "spelling_usage": "A ragout or stew of meat with beans and other vegetables."
            },
            {
                "spelling_detail_id": 58766,
                "word_type": "n.",
                "spelling_usage": "The ripe seeds, or the unripe pod, of the common string\n   bean (Phaseolus vulgaris), used as a vegetable. Other species of the\n   same genus furnish different kinds of haricots."
            }
        ]
    },
    {
        "id": 36797,
        "spelling_name": "Harmattan",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 58798,
                "word_type": "n.",
                "spelling_usage": "A dry, hot wind, prevailing on the Atlantic coast of\n   Africa, in December, January, and February, blowing from the interior\n   or Sahara. It is usually accompanied by a haze which obscures the sun."
            }
        ]
    },
    {
        "id": 36963,
        "spelling_name": "Hauberk",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 59071,
                "word_type": "v. t.",
                "spelling_usage": "A coat of mail; especially, the long coat of mail of\n   the European Middle Ages, as contrasted with the habergeon, which is\n   shorter and sometimes sleeveless. By old writers it is often used\n   synonymously with habergeon. See Habergeon."
            }
        ]
    },
    {
        "id": 37000,
        "spelling_name": "Hauteur",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 59131,
                "word_type": "n.",
                "spelling_usage": "Haughty manner or spirit; haughtiness; pride; arrogance."
            }
        ]
    },
    {
        "id": 37267,
        "spelling_name": "Hebdomadal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 59674,
                "word_type": "a.",
                "spelling_usage": "Alt. of Hebdomadary"
            }
        ]
    },
    {
        "id": 37400,
        "spelling_name": "Heliacal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 59884,
                "word_type": "a.",
                "spelling_usage": "Emerging from the light of the sun, or passing into it;\n   rising or setting at the same, or nearly the same, time as the sun."
            }
        ]
    },
    {
        "id": 37462,
        "spelling_name": "Hellebore",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 59962,
                "word_type": "n.",
                "spelling_usage": "A genus of perennial herbs (Helleborus) of the Crowfoot\n   family, mostly having powerfully cathartic and even poisonous\n   qualities. H. niger is the European black hellebore, or Christmas rose,\n   blossoming in winter or earliest spring. H. officinalis was the\n   officinal hellebore of the ancients."
            },
            {
                "spelling_detail_id": 59963,
                "word_type": "n.",
                "spelling_usage": "Any plant of several species of the poisonous liliaceous\n   genus Veratrum, especially V. album and V. viride, both called white\n   hellebore."
            }
        ]
    },
    {
        "id": 37653,
        "spelling_name": "Hemorrhage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 60216,
                "word_type": "n.",
                "spelling_usage": "Any discharge of blood from the blood vessels."
            }
        ]
    },
    {
        "id": 37903,
        "spelling_name": "Hermeneutics",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 60533,
                "word_type": "n.",
                "spelling_usage": "The science of interpretation and explanation;\n   exegesis; esp., that branch of theology which defines the laws whereby\n   the meaning of the Scriptures is to be ascertained."
            }
        ]
    },
    {
        "id": 38094,
        "spelling_name": "Heuristic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 60772,
                "word_type": "a.",
                "spelling_usage": "Serving to discover or find out."
            }
        ]
    },
    {
        "id": 38338,
        "spelling_name": "Hilum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 61112,
                "word_type": "n.",
                "spelling_usage": "The eye of a bean or other seed; the mark or scar at the\n   point of attachment of an ovule or seed to its base or support; --\n   called also hile."
            },
            {
                "spelling_detail_id": 61113,
                "word_type": "n.",
                "spelling_usage": "The part of a gland, or similar organ, where the blood\n   vessels and nerves enter; the hilus; as, the hilum of the kidney."
            }
        ]
    },
    {
        "id": 38363,
        "spelling_name": "Hindmost",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 61145,
                "word_type": "a.",
                "spelling_usage": "Furthest in or toward the rear; last."
            }
        ]
    },
    {
        "id": 38441,
        "spelling_name": "Hirsute",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 61257,
                "word_type": "a.",
                "spelling_usage": "Rough with hair; set with bristles; shaggy."
            },
            {
                "spelling_detail_id": 61258,
                "word_type": "a.",
                "spelling_usage": "Rough and coarse; boorish."
            },
            {
                "spelling_detail_id": 61259,
                "word_type": "a.",
                "spelling_usage": "Pubescent with coarse or stiff hairs."
            },
            {
                "spelling_detail_id": 61260,
                "word_type": "a.",
                "spelling_usage": "Covered with hairlike feathers, as the feet of certain\n   birds."
            }
        ]
    },
    {
        "id": 39095,
        "spelling_name": "Horologist",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 62362,
                "word_type": "n.",
                "spelling_usage": "One versed in horology."
            }
        ]
    },
    {
        "id": 39173,
        "spelling_name": "Hortatory",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 62486,
                "word_type": "a.",
                "spelling_usage": "Giving exhortation or advise; encouraging; exhortatory;\n   inciting; as, a hortatory speech."
            }
        ]
    },
    {
        "id": 39479,
        "spelling_name": "Hummock",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 63017,
                "word_type": "n.",
                "spelling_usage": "A rounded knoll or hillock; a rise of ground of no great\n   extent, above a level surface."
            },
            {
                "spelling_detail_id": 63018,
                "word_type": "n.",
                "spelling_usage": "A ridge or pile of ice on an ice field."
            },
            {
                "spelling_detail_id": 63019,
                "word_type": "n.",
                "spelling_usage": "Timbered land. See Hammock."
            }
        ]
    },
    {
        "id": 39590,
        "spelling_name": "Hurtle",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 63221,
                "word_type": "v. t.",
                "spelling_usage": "To meet with violence or shock; to clash; to jostle."
            },
            {
                "spelling_detail_id": 63222,
                "word_type": "v. t.",
                "spelling_usage": "To move rapidly; to wheel or rush suddenly or with\n   violence; to whirl round rapidly; to skirmish."
            },
            {
                "spelling_detail_id": 63223,
                "word_type": "v. t.",
                "spelling_usage": "To make a threatening sound, like the clash of arms; to\n   make a sound as of confused clashing or confusion; to resound."
            },
            {
                "spelling_detail_id": 63224,
                "word_type": "v. t.",
                "spelling_usage": "To move with violence or impetuosity; to whirl; to\n   brandish."
            },
            {
                "spelling_detail_id": 63225,
                "word_type": "v. t.",
                "spelling_usage": "To push; to jostle; to hurl."
            }
        ]
    },
    {
        "id": 39986,
        "spelling_name": "Hypallage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 63712,
                "word_type": "n.",
                "spelling_usage": "A figure consisting of a transference of attributes from\n   their proper subjects to other. Thus Virgil says, \"dare classibus\n   austros,\" to give the winds to the fleets, instead of dare classibus\n   austris, to give the fleets to the winds."
            }
        ]
    },
    {
        "id": 40067,
        "spelling_name": "Hypertrophy",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 63805,
                "word_type": "n.",
                "spelling_usage": "A condition of overgrowth or excessive development of\n   an organ or part; -- the opposite of atrophy."
            }
        ]
    },
    {
        "id": 40235,
        "spelling_name": "Hyssop",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64001,
                "word_type": "n.",
                "spelling_usage": "A plant (Hyssopus officinalis). The leaves have an aromatic\n   smell, and a warm, pungent taste."
            }
        ]
    },
    {
        "id": 40285,
        "spelling_name": "Ibidem",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64055,
                "word_type": "adv.",
                "spelling_usage": "In the same place; -- abbreviated ibid. or ib."
            }
        ]
    },
    {
        "id": 40288,
        "spelling_name": "Icarian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64060,
                "word_type": "a.",
                "spelling_usage": "Soaring too high for safety, like Icarus; adventurous in\n   flight."
            }
        ]
    },
    {
        "id": 40338,
        "spelling_name": "Ichthyology",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64124,
                "word_type": "n.",
                "spelling_usage": "The natural history of fishes; that branch of zoology\n   which relates to fishes, including their structure, classification, and\n   habits."
            }
        ]
    },
    {
        "id": 40385,
        "spelling_name": "Icosahedron",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64176,
                "word_type": "n.",
                "spelling_usage": "A solid bounded by twenty sides or faces."
            }
        ]
    },
    {
        "id": 40649,
        "spelling_name": "Illative",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64528,
                "word_type": "a.",
                "spelling_usage": "Relating to, dependent on, or denoting, illation;\n   inferential; conclusive; as, an illative consequence or proposition; an\n   illative word, as then, therefore, etc."
            },
            {
                "spelling_detail_id": 64529,
                "word_type": "n.",
                "spelling_usage": "An illative particle, as for, because."
            }
        ]
    },
    {
        "id": 40783,
        "spelling_name": "Imaret",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 64753,
                "word_type": "n.",
                "spelling_usage": "A lodging house for Mohammedan pilgrims."
            }
        ]
    },
    {
        "id": 40987,
        "spelling_name": "Immiscible",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 65001,
                "word_type": "a.",
                "spelling_usage": "Not capable of being mixed or mingled."
            }
        ]
    },
    {
        "id": 41004,
        "spelling_name": "Immoderate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 65018,
                "word_type": "a.",
                "spelling_usage": "Not moderate; exceeding just or usual and suitable\n   bounds; excessive; extravagant; unreasonable; as, immoderate demands;\n   immoderate grief; immoderate laughter."
            }
        ]
    },
    {
        "id": 41191,
        "spelling_name": "Impenetrable",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 65281,
                "word_type": "a.",
                "spelling_usage": "Incapable of being penetrated or pierced; not\n   admitting the passage of other bodies; not to be entered; impervious;\n   as, an impenetrable shield."
            },
            {
                "spelling_detail_id": 65282,
                "word_type": "a.",
                "spelling_usage": "Having the property of preventing any other substance\n   from occupying the same space at the same time."
            },
            {
                "spelling_detail_id": 65283,
                "word_type": "a.",
                "spelling_usage": "Inaccessible, as to knowledge, reason, sympathy,\n   etc.; unimpressible; not to be moved by arguments or motives; as, an\n   impenetrable mind, or heart."
            }
        ]
    },
    {
        "id": 41615,
        "spelling_name": "Impugn",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 65891,
                "word_type": "v. t.",
                "spelling_usage": "To attack by words or arguments; to contradict; to\n   assail; to call in question; to make insinuations against; to gainsay;\n   to oppose."
            }
        ]
    },
    {
        "id": 41922,
        "spelling_name": "Inchoate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 66300,
                "word_type": "a.",
                "spelling_usage": "Recently, or just, begun; beginning; partially but not\n   fully in existence or operation; existing in its elements; incomplete."
            },
            {
                "spelling_detail_id": 66301,
                "word_type": "v. t.",
                "spelling_usage": "To begin."
            }
        ]
    },
    {
        "id": 42340,
        "spelling_name": "Incunabula",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 66877,
                "word_type": "pl. ",
                "spelling_usage": "of Incunabulum"
            }
        ]
    },
    {
        "id": 42815,
        "spelling_name": "Ineffective",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 67569,
                "word_type": "a.",
                "spelling_usage": "Not effective; ineffectual; futile; inefficient;\n   useless; as, an ineffective appeal."
            }
        ]
    },
    {
        "id": 42901,
        "spelling_name": "Inevitable",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 67672,
                "word_type": "a.",
                "spelling_usage": "Not evitable; incapable of being shunned; unavoidable;\n   certain."
            },
            {
                "spelling_detail_id": 67673,
                "word_type": "a.",
                "spelling_usage": "Irresistible."
            }
        ]
    },
    {
        "id": 42930,
        "spelling_name": "Inexorably",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 67705,
                "word_type": "adv.",
                "spelling_usage": "In an inexorable manner; inflexibly."
            }
        ]
    },
    {
        "id": 43426,
        "spelling_name": "Inheritance",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 68436,
                "word_type": "n.",
                "spelling_usage": "The act or state of inheriting; as, the inheritance of\n   an estate; the inheritance of mental or physical qualities."
            },
            {
                "spelling_detail_id": 68437,
                "word_type": "n.",
                "spelling_usage": "That which is or may be inherited; that which is\n   derived by an heir from an ancestor or other person; a heritage; a\n   possession which passes by descent."
            },
            {
                "spelling_detail_id": 68438,
                "word_type": "n.",
                "spelling_usage": "A permanent or valuable possession or blessing, esp.\n   one received by gift or without purchase; a benefaction."
            },
            {
                "spelling_detail_id": 68439,
                "word_type": "n.",
                "spelling_usage": "Possession; ownership; acquisition."
            },
            {
                "spelling_detail_id": 68440,
                "word_type": "n.",
                "spelling_usage": "Transmission and reception by animal or plant\n   generation."
            },
            {
                "spelling_detail_id": 68441,
                "word_type": "n.",
                "spelling_usage": "A perpetual or continuing right which a man and his\n   heirs have to an estate; an estate which a man has by descent as heir\n   to another, or which he may transmit to another as his heir; an estate\n   derived from an ancestor to an heir in course of law."
            }
        ]
    },
    {
        "id": 43911,
        "spelling_name": "Insouciance",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 69136,
                "word_type": "n.",
                "spelling_usage": "Carelessness; heedlessness; thoughtlessness;\n   unconcern."
            }
        ]
    },
    {
        "id": 44087,
        "spelling_name": "Insurance",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 69416,
                "word_type": "n.",
                "spelling_usage": "The act of insuring, or assuring, against loss or damage\n   by a contingent event; a contract whereby, for a stipulated\n   consideration, called premium, one party undertakes to indemnify or\n   guarantee another against loss by certain specified risks. Cf.\n   Assurance, n., 6."
            },
            {
                "spelling_detail_id": 69417,
                "word_type": "n.",
                "spelling_usage": "The premium paid for insuring property or life."
            },
            {
                "spelling_detail_id": 69418,
                "word_type": "n.",
                "spelling_usage": "The sum for which life or property is insured."
            },
            {
                "spelling_detail_id": 69419,
                "word_type": "n.",
                "spelling_usage": "A guaranty, security, or pledge; assurance."
            }
        ]
    },
    {
        "id": 44269,
        "spelling_name": "Intercede",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 69683,
                "word_type": "v. i.",
                "spelling_usage": "To pass between; to intervene."
            },
            {
                "spelling_detail_id": 69684,
                "word_type": "v. i.",
                "spelling_usage": "To act between parties with a view to reconcile\n   differences; to make intercession; to beg or plead in behalf of\n   another; to mediate; -- usually followed by with and for; as, I will\n   intercede with him for you."
            },
            {
                "spelling_detail_id": 69685,
                "word_type": "v. t.",
                "spelling_usage": "To be, to come, or to pass, between; to separate."
            }
        ]
    },
    {
        "id": 44568,
        "spelling_name": "Internecine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 70083,
                "word_type": "a.",
                "spelling_usage": "Involving, or accompanied by, mutual slaughter;\n   mutually destructive."
            }
        ]
    },
    {
        "id": 44599,
        "spelling_name": "Interpellate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 70126,
                "word_type": "v. t.",
                "spelling_usage": "To question imperatively, as a minister, or other\n   executive officer, in explanation of his conduct; -- generally on the\n   part of a legislative body."
            }
        ]
    },
    {
        "id": 44859,
        "spelling_name": "Intoxicating",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 70481,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Intoxicate"
            },
            {
                "spelling_detail_id": 70486,
                "word_type": "a.",
                "spelling_usage": "Producing intoxication; fitted to intoxicate; as,\n   intoxicating liquors."
            }
        ]
    },
    {
        "id": 45492,
        "spelling_name": "Irreverent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71376,
                "word_type": "a.",
                "spelling_usage": "Not reverent; showing a want of reverence; expressive\n   of a want of veneration; as, an irreverent babbler; an irreverent jest."
            }
        ]
    },
    {
        "id": 45495,
        "spelling_name": "Irreversible",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71379,
                "word_type": "a.",
                "spelling_usage": "Incapable of being reversed or turned about or back;\n   incapable of being made to run backward; as, an irreversible engine."
            },
            {
                "spelling_detail_id": 71380,
                "word_type": "a.",
                "spelling_usage": "Incapable of being reversed, recalled, repealed, or\n   annulled; as, an irreversible sentence or decree."
            }
        ]
    },
    {
        "id": 45545,
        "spelling_name": "Isagoge",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71451,
                "word_type": "n.",
                "spelling_usage": "An introduction."
            }
        ]
    },
    {
        "id": 45812,
        "spelling_name": "Jacaranda",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71820,
                "word_type": "n.",
                "spelling_usage": "The native Brazilian name for certain leguminous trees,\n   which produce the beautiful woods called king wood, tiger wood, and\n   violet wood."
            },
            {
                "spelling_detail_id": 71821,
                "word_type": "n.",
                "spelling_usage": "A genus of bignoniaceous Brazilian trees with showy\n   trumpet-shaped flowers."
            }
        ]
    },
    {
        "id": 45846,
        "spelling_name": "Jacobean",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71899,
                "word_type": "a.",
                "spelling_usage": "Alt. of Jacobian"
            }
        ]
    },
    {
        "id": 45863,
        "spelling_name": "Jacquard",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71921,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or invented by, Jacquard, a French\n   mechanician, who died in 1834."
            }
        ]
    },
    {
        "id": 45914,
        "spelling_name": "Jalousie",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 71992,
                "word_type": "n.",
                "spelling_usage": "A Venetian or slatted inside window blind."
            }
        ]
    },
    {
        "id": 45954,
        "spelling_name": "Janthina",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 72052,
                "word_type": "n.",
                "spelling_usage": "See Ianthina."
            }
        ]
    },
    {
        "id": 46135,
        "spelling_name": "Jesuit",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 72323,
                "word_type": "n.",
                "spelling_usage": "One of a religious order founded by Ignatius Loyola, and\n   approved in 1540, under the title of The Society of Jesus."
            },
            {
                "spelling_detail_id": 72324,
                "word_type": "n.",
                "spelling_usage": "Fig.: A crafty person; an intriguer."
            }
        ]
    },
    {
        "id": 46481,
        "spelling_name": "Julienne",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 72908,
                "word_type": "n.",
                "spelling_usage": "A kind of soup containing thin slices or shreds of\n   carrots, onions, etc."
            }
        ]
    },
    {
        "id": 46650,
        "spelling_name": "Kakapo",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 73172,
                "word_type": "n.",
                "spelling_usage": "A singular nocturnal parrot (Strigops habroptilus), native\n   of New Zealand. It lives in holes during the day, but is active at\n   night. It resembles an owl in its colors and general appearance. It has\n   large wings, but can fly only a short distance. Called also owl parrot,\n   night parrot, and night kaka."
            }
        ]
    },
    {
        "id": 47254,
        "spelling_name": "Knickerbockers",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 74068,
                "word_type": "n. pl.",
                "spelling_usage": "The name for a style of short breeches;\n   smallclothes."
            }
        ]
    },
    {
        "id": 47366,
        "spelling_name": "Kobold",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 74281,
                "word_type": "n.",
                "spelling_usage": "A kind of domestic spirit in German mythology,\n   corresponding to the Scottish brownie and the English Robin Goodfellow."
            }
        ]
    },
    {
        "id": 47502,
        "spelling_name": "Labefaction",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 74435,
                "word_type": "n.",
                "spelling_usage": "The act of labefying or making weak; the state of\n   being weakened; decay; ruin."
            }
        ]
    },
    {
        "id": 47710,
        "spelling_name": "Lacustrine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 74761,
                "word_type": "a.",
                "spelling_usage": "Found in, or pertaining to, lakes or ponds, or growing\n   in them; as, lacustrine flowers."
            }
        ]
    },
    {
        "id": 48114,
        "spelling_name": "Lapidary",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 75368,
                "word_type": "n.",
                "spelling_usage": "An artificer who cuts, polishes, and engraves precious\n   stones; hence, a dealer in precious stones."
            },
            {
                "spelling_detail_id": 75369,
                "word_type": "n.",
                "spelling_usage": "A virtuoso skilled in gems or precious stones; a\n   connoisseur of lapidary work."
            },
            {
                "spelling_detail_id": 75370,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the art of cutting stones, or\n   engraving on stones, either gems or monuments; as, lapidary\n   ornamentation."
            },
            {
                "spelling_detail_id": 75371,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to monumental inscriptions; as, lapidary\n   adulation."
            }
        ]
    },
    {
        "id": 48268,
        "spelling_name": "Lassitude",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 75589,
                "word_type": "n.",
                "spelling_usage": "A condition of the body, or mind, when its voluntary\n   functions are performed with difficulty, and only by a strong exertion\n   of the will; languor; debility; weariness."
            }
        ]
    },
    {
        "id": 48543,
        "spelling_name": "Leach",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 76070,
                "word_type": "n.",
                "spelling_usage": "See 3d Leech."
            },
            {
                "spelling_detail_id": 76071,
                "word_type": "n.",
                "spelling_usage": "A quantity of wood ashes, through which water passes, and\n   thus imbibes the alkali."
            },
            {
                "spelling_detail_id": 76072,
                "word_type": "n.",
                "spelling_usage": "A tub or vat for leaching ashes, bark, etc."
            },
            {
                "spelling_detail_id": 76075,
                "word_type": "v. t.",
                "spelling_usage": "To remove the soluble constituents from by subjecting to\n   the action of percolating water or other liquid; as, to leach ashes or\n   coffee."
            },
            {
                "spelling_detail_id": 76076,
                "word_type": "v. t.",
                "spelling_usage": "To dissolve out; -- often used with out; as, to leach out\n   alkali from ashes."
            },
            {
                "spelling_detail_id": 76077,
                "word_type": "v. i.",
                "spelling_usage": "To part with soluble constituents by percolation."
            },
            {
                "spelling_detail_id": 76078,
                "word_type": "n.",
                "spelling_usage": "See Leech, a physician."
            }
        ]
    },
    {
        "id": 48668,
        "spelling_name": "Lecithin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 76338,
                "word_type": "n.",
                "spelling_usage": "A complex, nitrogenous phosphorized substance widely\n   distributed through the animal body, and especially conspicuous in the\n   brain and nerve tissue, in yolk of eggs, and in the white blood\n   corpuscles."
            }
        ]
    },
    {
        "id": 48834,
        "spelling_name": "Lemniscus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 76611,
                "word_type": "n.",
                "spelling_usage": "One of two oval bodies hanging from the interior walls\n   of the body in the Acanthocephala."
            }
        ]
    },
    {
        "id": 48911,
        "spelling_name": "Leonine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 76726,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or characteristic of, the lion; as, a\n   leonine look; leonine rapacity."
            }
        ]
    },
    {
        "id": 49170,
        "spelling_name": "Liana",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 77134,
                "word_type": "n.",
                "spelling_usage": "A luxuriant woody plant, climbing high trees and having\n   ropelike stems. The grapevine often has the habit of a liane. Lianes\n   are abundant in the forests of the Amazon region."
            }
        ]
    },
    {
        "id": 49457,
        "spelling_name": "Lilliputian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 77695,
                "word_type": "n.",
                "spelling_usage": "One belonging to a very diminutive race described in\n   Swift's \"Voyage to Lilliput.\""
            },
            {
                "spelling_detail_id": 77696,
                "word_type": "n.",
                "spelling_usage": "A person or thing of very small size."
            },
            {
                "spelling_detail_id": 77697,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the imaginary island of Lilliput\n   described by Swift, or to its inhabitants."
            },
            {
                "spelling_detail_id": 77698,
                "word_type": "a.",
                "spelling_usage": "Of very small size; diminutive; dwarfed."
            }
        ]
    },
    {
        "id": 49638,
        "spelling_name": "Linnet",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 78045,
                "word_type": "n.",
                "spelling_usage": "Any one of several species of fringilline birds of the\n   genera Linota, Acanthis, and allied genera, esp. the common European\n   species (L. cannabina), which, in full summer plumage, is chestnut\n   brown above, with the breast more or less crimson. The feathers of its\n   head are grayish brown, tipped with crimson. Called also gray linnet,\n   red linnet, rose linnet, brown linnet, lintie, lintwhite, gorse\n   thatcher, linnet finch, and greater redpoll. The American redpoll\n   linnet (Acanthis linaria) often has the crown and throat rosy. See\n   Redpoll, and Twite."
            }
        ]
    },
    {
        "id": 49768,
        "spelling_name": "Litany",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 78256,
                "word_type": "n.",
                "spelling_usage": "A solemn form of supplication in the public worship of\n   various churches, in which the clergy and congregation join, the former\n   leading and the latter responding in alternate sentences. It is usually\n   of a penitential character."
            }
        ]
    },
    {
        "id": 49891,
        "spelling_name": "Litigious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 78414,
                "word_type": "a.",
                "spelling_usage": "Inclined to judicial contest; given to the practice of\n   contending in law; guarrelsome; contentious; fond of litigation."
            },
            {
                "spelling_detail_id": 78415,
                "word_type": "a.",
                "spelling_usage": "Subject to contention; disputable; controvertible;\n   debatable; doubtful; precarious."
            },
            {
                "spelling_detail_id": 78416,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to legal disputes."
            }
        ]
    },
    {
        "id": 49943,
        "spelling_name": "Livery",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 78524,
                "word_type": "n.",
                "spelling_usage": "The act of delivering possession of lands or tenements."
            },
            {
                "spelling_detail_id": 78525,
                "word_type": "n.",
                "spelling_usage": "The writ by which possession is obtained."
            },
            {
                "spelling_detail_id": 78526,
                "word_type": "n.",
                "spelling_usage": "Release from wardship; deliverance."
            },
            {
                "spelling_detail_id": 78527,
                "word_type": "n.",
                "spelling_usage": "That which is delivered out statedly or formally, as\n   clothing, food, etc."
            },
            {
                "spelling_detail_id": 78528,
                "word_type": "n.",
                "spelling_usage": "The uniform clothing issued by feudal superiors to their\n   retainers and serving as a badge when in military service."
            },
            {
                "spelling_detail_id": 78529,
                "word_type": "n.",
                "spelling_usage": "The peculiar dress by which the servants of a nobleman or\n   gentleman are distinguished; as, a claret-colored livery."
            },
            {
                "spelling_detail_id": 78530,
                "word_type": "n.",
                "spelling_usage": "Hence, also, the peculiar dress or garb appropriated by any\n   association or body of persons to their own use; as, the livery of the\n   London tradesmen, of a priest, of a charity school, etc.; also, the\n   whole body or company of persons wearing such a garb, and entitled to\n   the privileges of the association; as, the whole livery of London."
            },
            {
                "spelling_detail_id": 78531,
                "word_type": "n.",
                "spelling_usage": "Hence, any characteristic dress or outward appearance."
            },
            {
                "spelling_detail_id": 78532,
                "word_type": "n.",
                "spelling_usage": "An allowance of food statedly given out; a ration, as to a\n   family, to servants, to horses, etc."
            },
            {
                "spelling_detail_id": 78533,
                "word_type": "n.",
                "spelling_usage": "The feeding, stabling, and care of horses for compensation;\n   boarding; as, to keep one's horses at livery."
            },
            {
                "spelling_detail_id": 78534,
                "word_type": "n.",
                "spelling_usage": "The keeping of horses in readiness to be hired temporarily\n   for riding or driving; the state of being so kept."
            },
            {
                "spelling_detail_id": 78535,
                "word_type": "n.",
                "spelling_usage": "A low grade of wool."
            },
            {
                "spelling_detail_id": 78536,
                "word_type": "v. t.",
                "spelling_usage": "To clothe in, or as in, livery."
            }
        ]
    },
    {
        "id": 49973,
        "spelling_name": "Llanero",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 78584,
                "word_type": "n.",
                "spelling_usage": "One of the inhabitants of the llanos of South America."
            }
        ]
    },
    {
        "id": 50040,
        "spelling_name": "Lobscouse",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 78698,
                "word_type": "n.",
                "spelling_usage": "A combination of meat with vegetables, bread, etc.,\n   usually stewed, sometimes baked; an olio."
            }
        ]
    },
    {
        "id": 50275,
        "spelling_name": "Longevous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 79064,
                "word_type": "a.",
                "spelling_usage": "Living a long time; of great age."
            }
        ]
    },
    {
        "id": 50641,
        "spelling_name": "Luculent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 79740,
                "word_type": "a.",
                "spelling_usage": "Lucid; clear; transparent."
            },
            {
                "spelling_detail_id": 79741,
                "word_type": "a.",
                "spelling_usage": "Clear; evident; luminous."
            },
            {
                "spelling_detail_id": 79742,
                "word_type": "a.",
                "spelling_usage": "Bright; shining in beauty."
            }
        ]
    },
    {
        "id": 50645,
        "spelling_name": "Luddite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 79746,
                "word_type": "n.",
                "spelling_usage": "One of a number of riotous persons in England, who for six\n   years (1811-17) tried to prevent the use of labor-saving machinery by\n   breaking it, burning factories, etc.; -- so called from Ned Lud, a\n   half-witted man who some years previously had broken stocking frames."
            }
        ]
    },
    {
        "id": 50770,
        "spelling_name": "Lurching",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 79950,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Lurch"
            }
        ]
    },
    {
        "id": 50841,
        "spelling_name": "Lutheran",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 80076,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Luther; adhering to the doctrines of\n   Luther or the Lutheran Church."
            },
            {
                "spelling_detail_id": 80077,
                "word_type": "n.",
                "spelling_usage": "One who accepts or adheres to the doctrines of Luther or\n   the Lutheran Church."
            }
        ]
    },
    {
        "id": 50987,
        "spelling_name": "Macaque",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 80261,
                "word_type": "n.",
                "spelling_usage": "Any one of several species of short-tailed monkeys of the\n   genus Macacus; as, M. maurus, the moor macaque of the East Indies."
            }
        ]
    },
    {
        "id": 51042,
        "spelling_name": "Mackinaw",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 80352,
                "word_type": "",
                "spelling_usage": "A thick blanket formerly in common use in the western part\n   of the United States."
            }
        ]
    },
    {
        "id": 51083,
        "spelling_name": "Macropterous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 80399,
                "word_type": "a.",
                "spelling_usage": "Having long wings."
            }
        ]
    },
    {
        "id": 51441,
        "spelling_name": "Malachite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 80963,
                "word_type": "n.",
                "spelling_usage": "Native hydrous carbonate of copper, usually occurring in\n   green mammillary masses with concentric fibrous structure."
            }
        ]
    },
    {
        "id": 51588,
        "spelling_name": "Malleolus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 81158,
                "word_type": "n.",
                "spelling_usage": "A projection at the distal end of each bone of the leg\n   at the ankle joint. The malleolus of the tibia is the internal\n   projection, that of the fibula the external."
            },
            {
                "spelling_detail_id": 81159,
                "word_type": "n.",
                "spelling_usage": "\" A layer, \" a shoot partly buried in the ground, and\n   there cut halfway through."
            }
        ]
    },
    {
        "id": 51805,
        "spelling_name": "Mangonel",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 81451,
                "word_type": "n.",
                "spelling_usage": "A military engine formerly used for throwing stones and\n   javelins."
            }
        ]
    },
    {
        "id": 52193,
        "spelling_name": "Marquee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 82049,
                "word_type": "n.",
                "spelling_usage": "A large field tent; esp., one adapted to the use of an\n   officer of high rank."
            }
        ]
    },
    {
        "id": 52274,
        "spelling_name": "Martinet",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 82177,
                "word_type": "n.",
                "spelling_usage": "In military language, a strict disciplinarian; in\n   general, one who lays stress on a rigid adherence to the details of\n   discipline, or to forms and fixed methods."
            },
            {
                "spelling_detail_id": 82178,
                "word_type": "n.",
                "spelling_usage": "The martin."
            }
        ]
    },
    {
        "id": 52746,
        "spelling_name": "Mechanics",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83007,
                "word_type": "n.",
                "spelling_usage": "That science, or branch of applied mathematics, which\n   treats of the action of forces on bodies."
            }
        ]
    },
    {
        "id": 52856,
        "spelling_name": "Meditation",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83155,
                "word_type": "n.",
                "spelling_usage": "The act of meditating; close or continued thought; the\n   turning or revolving of a subject in the mind; serious contemplation;\n   reflection; musing."
            },
            {
                "spelling_detail_id": 83156,
                "word_type": "n.",
                "spelling_usage": "Thought; -- without regard to kind."
            }
        ]
    },
    {
        "id": 52859,
        "spelling_name": "Mediterranean",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83159,
                "word_type": "a.",
                "spelling_usage": "Inclosed, or nearly inclosed, with land; as, the\n   Mediterranean Sea, between Europe and Africa."
            },
            {
                "spelling_detail_id": 83160,
                "word_type": "a.",
                "spelling_usage": "Inland; remote from the ocean."
            },
            {
                "spelling_detail_id": 83161,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Mediterranean Sea; as,\n   Mediterranean trade; a Mediterranean voyage."
            }
        ]
    },
    {
        "id": 52904,
        "spelling_name": "Megacephalic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83253,
                "word_type": "a.",
                "spelling_usage": "Alt. of Megacephalous"
            }
        ]
    },
    {
        "id": 52960,
        "spelling_name": "Meiosis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83315,
                "word_type": "n.",
                "spelling_usage": "Diminution; a species of hyperbole, representing a thing\n   as being less than it really is."
            }
        ]
    },
    {
        "id": 53016,
        "spelling_name": "Melee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83384,
                "word_type": "n.",
                "spelling_usage": "A fight in which the combatants are mingled in one confused\n   mass; a hand to hand conflict; an affray."
            }
        ]
    },
    {
        "id": 53182,
        "spelling_name": "Mendicity",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83616,
                "word_type": "n.",
                "spelling_usage": "The practice of begging; the life of a beggar;\n   mendicancy."
            }
        ]
    },
    {
        "id": 53345,
        "spelling_name": "Meringue",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83850,
                "word_type": "n.",
                "spelling_usage": "A delicate pastry made of powdered sugar and the whites\n   of eggs whipped up, -- with jam or cream added."
            }
        ]
    },
    {
        "id": 53434,
        "spelling_name": "Mesial",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 83961,
                "word_type": "a.",
                "spelling_usage": "Middle; median; in, or in the region of, the mesial plane;\n   internal; -- opposed to lateral."
            }
        ]
    },
    {
        "id": 54082,
        "spelling_name": "Mignonette",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 84789,
                "word_type": "n.",
                "spelling_usage": "A plant (Reseda odorata) having greenish flowers with\n   orange-colored stamens, and exhaling a delicious fragrance. In Africa\n   it is a low shrub, but further north it is usually an annual herb."
            }
        ]
    },
    {
        "id": 54308,
        "spelling_name": "Minimize",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 85159,
                "word_type": "v. t.",
                "spelling_usage": "To reduce to the smallest part or proportion possible;\n   to reduce to a minimum."
            }
        ]
    },
    {
        "id": 55146,
        "spelling_name": "Modiste",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 86337,
                "word_type": "n.",
                "spelling_usage": "A female maker of, or dealer in, articles of fashion,\n   especially of the fashionable dress of ladies; a woman who gives\n   direction to the style or mode of dress."
            }
        ]
    },
    {
        "id": 55187,
        "spelling_name": "Moiety",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 86396,
                "word_type": "a.",
                "spelling_usage": "One of two equal parts; a half; as, a moiety of an estate,\n   of goods, or of profits; the moiety of a jury, or of a nation."
            },
            {
                "spelling_detail_id": 86397,
                "word_type": "a.",
                "spelling_usage": "An indefinite part; a small part."
            }
        ]
    },
    {
        "id": 55815,
        "spelling_name": "Moraine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 87290,
                "word_type": "n.",
                "spelling_usage": "An accumulation of earth and stones carried forward and\n   deposited by a glacier."
            }
        ]
    },
    {
        "id": 55861,
        "spelling_name": "Morel",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 87381,
                "word_type": "n.",
                "spelling_usage": "An edible fungus (Morchella esculenta), the upper part of\n   which is covered with a reticulated and pitted hymenium. It is used as\n   food, and for flavoring sauces."
            },
            {
                "spelling_detail_id": 87382,
                "word_type": "n.",
                "spelling_usage": "Nightshade; -- so called from its blackish purple berries."
            },
            {
                "spelling_detail_id": 87383,
                "word_type": "n.",
                "spelling_usage": "A kind of cherry. See Morello."
            }
        ]
    },
    {
        "id": 56135,
        "spelling_name": "Mountebank",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 87815,
                "word_type": "n.",
                "spelling_usage": "One who mounts a bench or stage in the market or other\n   public place, boasts of his skill in curing diseases, and vends\n   medicines which he pretends are infalliable remedies; a quack doctor."
            },
            {
                "spelling_detail_id": 87816,
                "word_type": "n.",
                "spelling_usage": "Any boastful or false pretender; a charlatan; a quack."
            },
            {
                "spelling_detail_id": 87817,
                "word_type": "v. t.",
                "spelling_usage": "To cheat by boasting and false pretenses; to gull."
            },
            {
                "spelling_detail_id": 87818,
                "word_type": "v. i.",
                "spelling_usage": "To play the mountebank."
            }
        ]
    },
    {
        "id": 56166,
        "spelling_name": "Moustache",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 87876,
                "word_type": "n.",
                "spelling_usage": "Mustache."
            }
        ]
    },
    {
        "id": 56297,
        "spelling_name": "Mufti",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 88135,
                "word_type": "n.",
                "spelling_usage": "An official expounder of Mohammedan law."
            },
            {
                "spelling_detail_id": 88136,
                "word_type": "n.",
                "spelling_usage": "Citizen's dress when worn by a naval or military officer; --\n   a term derived from the British service in India."
            }
        ]
    },
    {
        "id": 57038,
        "spelling_name": "Nainsook",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 89143,
                "word_type": "n.",
                "spelling_usage": "A thick sort of jaconet muslin, plain or striped,\n   formerly made in India."
            }
        ]
    },
    {
        "id": 57428,
        "spelling_name": "Necromancer",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 89763,
                "word_type": "n.",
                "spelling_usage": "One who practices necromancy; a sorcerer; a wizard."
            }
        ]
    },
    {
        "id": 57646,
        "spelling_name": "Neophyte",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90068,
                "word_type": "n.",
                "spelling_usage": "A new convert or proselyte; -- a name given by the early\n   Christians, and still given by the Roman Catholics, to such as have\n   recently embraced the Christian faith, and been admitted to baptism,\n   esp. to converts from heathenism or Judaism."
            },
            {
                "spelling_detail_id": 90069,
                "word_type": "n.",
                "spelling_usage": "A novice; a tyro; a beginner in anything."
            }
        ]
    },
    {
        "id": 57740,
        "spelling_name": "Nescience",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90193,
                "word_type": "n.",
                "spelling_usage": "Want of knowledge; ignorance; agnosticism."
            }
        ]
    },
    {
        "id": 57944,
        "spelling_name": "Nictitate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90518,
                "word_type": "v. i.",
                "spelling_usage": "To wink; to nictate."
            }
        ]
    },
    {
        "id": 58026,
        "spelling_name": "Nihilism",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90626,
                "word_type": "n.",
                "spelling_usage": "Nothingness; nihility."
            },
            {
                "spelling_detail_id": 90627,
                "word_type": "n.",
                "spelling_usage": "The doctrine that nothing can be known; scepticism as to\n   all knowledge and all reality."
            },
            {
                "spelling_detail_id": 90628,
                "word_type": "n.",
                "spelling_usage": "The theories and practices of the Nihilists."
            }
        ]
    },
    {
        "id": 58050,
        "spelling_name": "Nimiety",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90662,
                "word_type": "n.",
                "spelling_usage": "State of being in excess."
            }
        ]
    },
    {
        "id": 58107,
        "spelling_name": "Nitid",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90761,
                "word_type": "a.",
                "spelling_usage": "Bright; lustrous; shining."
            },
            {
                "spelling_detail_id": 90762,
                "word_type": "a.",
                "spelling_usage": "Gay; spruce; fine; -- said of persons."
            }
        ]
    },
    {
        "id": 58167,
        "spelling_name": "Nival",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90829,
                "word_type": "a.",
                "spelling_usage": "Abounding with snow; snowy."
            }
        ]
    },
    {
        "id": 58202,
        "spelling_name": "Nocive",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 90884,
                "word_type": "a.",
                "spelling_usage": "Hurtful; injurious."
            }
        ]
    },
    {
        "id": 58366,
        "spelling_name": "Nonage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91106,
                "word_type": "n.",
                "spelling_usage": "The ninth part of movable goods, formerly payable to the\n   clergy on the death of persons in their parishes."
            },
            {
                "spelling_detail_id": 91107,
                "word_type": "n.",
                "spelling_usage": "Time of life before a person becomes of age; legal\n   immaturity; minority."
            }
        ]
    },
    {
        "id": 58475,
        "spelling_name": "Nonpareil",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91240,
                "word_type": "a.",
                "spelling_usage": "Something of unequaled excellence; a peerless thing or\n   person; a nonesuch; -- often used as a name."
            },
            {
                "spelling_detail_id": 91241,
                "word_type": "a.",
                "spelling_usage": "A size of type next smaller than minion and next larger\n   than agate (or ruby)."
            },
            {
                "spelling_detail_id": 91242,
                "word_type": "a.",
                "spelling_usage": "A beautifully colored finch (Passerina ciris), native of\n   the Southern United States. The male has the head and neck deep blue,\n   rump and under parts bright red, back and wings golden green, and the\n   tail bluish purple. Called also painted finch."
            },
            {
                "spelling_detail_id": 91243,
                "word_type": "a.",
                "spelling_usage": "Any other similar bird of the same genus."
            },
            {
                "spelling_detail_id": 91244,
                "word_type": "a.",
                "spelling_usage": "Having no equal; peerless."
            }
        ]
    },
    {
        "id": 58720,
        "spelling_name": "Notturno",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91628,
                "word_type": "n.",
                "spelling_usage": "Same as Nocturne."
            }
        ]
    },
    {
        "id": 58731,
        "spelling_name": "Noumenon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91641,
                "word_type": "n.",
                "spelling_usage": "The of itself unknown and unknowable rational object, or\n   thing in itself, which is distinguished from the phenomenon through\n   which it is apprehended by the senses, and by which it is interpreted\n   and understood; -- so used in the philosophy of Kant and his followers."
            }
        ]
    },
    {
        "id": 58814,
        "spelling_name": "Nubilous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91770,
                "word_type": "a.",
                "spelling_usage": "Cloudy."
            }
        ]
    },
    {
        "id": 58823,
        "spelling_name": "Nuciform",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91779,
                "word_type": "a.",
                "spelling_usage": "Shaped like a nut; nut-shaped."
            }
        ]
    },
    {
        "id": 58854,
        "spelling_name": "Nudibranch",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91822,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Nudibranchiata."
            },
            {
                "spelling_detail_id": 91823,
                "word_type": "n.",
                "spelling_usage": "One of the Nudibranchiata."
            }
        ]
    },
    {
        "id": 58865,
        "spelling_name": "Nugatory",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 91835,
                "word_type": "a.",
                "spelling_usage": "Trifling; vain; futile; insignificant."
            },
            {
                "spelling_detail_id": 91836,
                "word_type": "a.",
                "spelling_usage": "Of no force; inoperative; ineffectual."
            }
        ]
    },
    {
        "id": 59099,
        "spelling_name": "Obdurate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 92180,
                "word_type": "a.",
                "spelling_usage": "Hardened in feelings, esp. against moral or mollifying\n   influences; unyielding; hard-hearted; stubbornly wicked."
            },
            {
                "spelling_detail_id": 92181,
                "word_type": "a.",
                "spelling_usage": "Hard; harsh; rugged; rough; intractable."
            },
            {
                "spelling_detail_id": 92182,
                "word_type": "v. t.",
                "spelling_usage": "To harden."
            }
        ]
    },
    {
        "id": 59115,
        "spelling_name": "Obeisant",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 92206,
                "word_type": "a.",
                "spelling_usage": "Ready to obey; reverent; differential; also, servilely\n   submissive."
            }
        ]
    },
    {
        "id": 59233,
        "spelling_name": "Obloquy",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 92376,
                "word_type": "n.",
                "spelling_usage": "Censorious speech; defamatory language; language that\n   casts contempt on men or their actions; blame; reprehension."
            },
            {
                "spelling_detail_id": 92377,
                "word_type": "n.",
                "spelling_usage": "Cause of reproach; disgrace."
            }
        ]
    },
    {
        "id": 59553,
        "spelling_name": "Octonocular",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 92834,
                "word_type": "a.",
                "spelling_usage": "Having eight eyes."
            }
        ]
    },
    {
        "id": 59606,
        "spelling_name": "Odin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 92904,
                "word_type": "n.",
                "spelling_usage": "The supreme deity of the Scandinavians; -- the same as Woden,\n   of the German tribes."
            }
        ]
    },
    {
        "id": 59746,
        "spelling_name": "Offspring",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 93139,
                "word_type": "n.sing. & pl.",
                "spelling_usage": "The act of production; generation."
            },
            {
                "spelling_detail_id": 93140,
                "word_type": "n.sing. & pl.",
                "spelling_usage": "That which is produced; a child or children;\n   a descendant or descendants, however remote from the stock."
            },
            {
                "spelling_detail_id": 93141,
                "word_type": "n.sing. & pl.",
                "spelling_usage": "Origin; lineage; family."
            }
        ]
    },
    {
        "id": 59830,
        "spelling_name": "Olecranon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 93254,
                "word_type": "n.",
                "spelling_usage": "The large process at the proximal end of the ulna which\n   projects behind the articulation with the humerus and forms the bony\n   prominence of the elbow."
            }
        ]
    },
    {
        "id": 60103,
        "spelling_name": "Oolite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 93619,
                "word_type": "n.",
                "spelling_usage": "A variety of limestone, consisting of small round grains,\n   resembling the roe of a fish. It sometimes constitutes extensive beds,\n   as in the European Jurassic. See the Chart of Geology."
            }
        ]
    },
    {
        "id": 60311,
        "spelling_name": "Oppidan",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 93923,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a town."
            },
            {
                "spelling_detail_id": 93924,
                "word_type": "n.",
                "spelling_usage": "An inhabitant of a town."
            },
            {
                "spelling_detail_id": 93925,
                "word_type": "n.",
                "spelling_usage": "A student of Eton College, England, who is not a King's\n   scholar, and who boards in a private family."
            }
        ]
    },
    {
        "id": 60356,
        "spelling_name": "Oppugn",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 94004,
                "word_type": "v. t.",
                "spelling_usage": "To fight against; to attack; to be in conflict with; to\n   oppose; to resist."
            }
        ]
    },
    {
        "id": 60658,
        "spelling_name": "Oriole",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 94490,
                "word_type": "n.",
                "spelling_usage": "Any one of various species of Old World singing birds of\n   the family Oriolidae. They are usually conspicuously colored with\n   yellow and black. The European or golden oriole (Oriolus galbula, or O.\n   oriolus) has a very musical flutelike note."
            },
            {
                "spelling_detail_id": 94491,
                "word_type": "n.",
                "spelling_usage": "In America, any one of several species of the genus\n   Icterus, belonging to the family Icteridae. See Baltimore oriole, and\n   Orchard oriole, under Orchard."
            }
        ]
    },
    {
        "id": 60672,
        "spelling_name": "Ormolu",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 94507,
                "word_type": "n.",
                "spelling_usage": "A variety of brass made to resemble gold by the use of less\n   zinc and more copper in its composition than ordinary brass contains.\n   Its golden color is often heightened by means of lacquer of some sort,\n   or by use of acids. Called also mosaic gold."
            }
        ]
    },
    {
        "id": 60915,
        "spelling_name": "Ossicle",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 94807,
                "word_type": "n.",
                "spelling_usage": "A little bone; as, the auditory ossicles in the tympanum\n   of the ear."
            },
            {
                "spelling_detail_id": 94808,
                "word_type": "n.",
                "spelling_usage": "One of numerous small calcareous structures forming the\n   skeleton of certain echinoderms, as the starfishes."
            }
        ]
    },
    {
        "id": 61026,
        "spelling_name": "Otacoustic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 94941,
                "word_type": "a.",
                "spelling_usage": "Assisting the sense of hearing; as, an otacoustic\n   instrument."
            },
            {
                "spelling_detail_id": 94942,
                "word_type": "n.",
                "spelling_usage": "Alt. of Otacousticon"
            }
        ]
    },
    {
        "id": 61047,
        "spelling_name": "Otiose",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 94972,
                "word_type": "a.",
                "spelling_usage": "Being at leisure or ease; unemployed; indolent; idle."
            }
        ]
    },
    {
        "id": 61083,
        "spelling_name": "Oubliette",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 95013,
                "word_type": "n.",
                "spelling_usage": "A dungeon with an opening only at the top, found in some\n   old castles and other strongholds, into which persons condemned to\n   perpetual imprisonment, or to perish secretly, were thrust, or lured to\n   fall."
            }
        ]
    },
    {
        "id": 61247,
        "spelling_name": "Outlandish",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 95234,
                "word_type": "a.",
                "spelling_usage": "Foreign; not native."
            },
            {
                "spelling_detail_id": 95235,
                "word_type": "a.",
                "spelling_usage": "Hence: Not according with usage; strange; rude;\n   barbarous; uncouth; clownish; as, an outlandish dress, behavior, or\n   speech."
            }
        ]
    },
    {
        "id": 61315,
        "spelling_name": "Outre",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 95337,
                "word_type": "a.",
                "spelling_usage": "Being out of the common course or limits; extravagant;\n   bizarre."
            }
        ]
    },
    {
        "id": 62038,
        "spelling_name": "Oviparous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 96317,
                "word_type": "a.",
                "spelling_usage": "Producing young from rggs; as, an oviparous animal, in\n   which the egg is generally separated from the animal, and hatched after\n   exclusion; -- opposed to viviparous."
            }
        ]
    },
    {
        "id": 62105,
        "spelling_name": "Oxalis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 96405,
                "word_type": "n.",
                "spelling_usage": "A genus of plants, mostly herbs, with acid-tasting\n   trifoliolate or multifoliolate leaves; -- called also wood sorrel."
            }
        ]
    },
    {
        "id": 62389,
        "spelling_name": "Pahoehoe",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 96838,
                "word_type": "n.",
                "spelling_usage": "A name given in the Sandwich Islands to lava having a\n   relatively smooth surface, in distinction from the rough-surfaced lava,\n   called a-a."
            }
        ]
    },
    {
        "id": 62435,
        "spelling_name": "Paladin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 96929,
                "word_type": "n.",
                "spelling_usage": "A knight-errant; a distinguished champion; as, the\n   paladins of Charlemagne."
            }
        ]
    },
    {
        "id": 62469,
        "spelling_name": "Palaver",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 96975,
                "word_type": "n.",
                "spelling_usage": "Talk; conversation; esp., idle or beguiling talk; talk\n   intended to deceive; flattery."
            },
            {
                "spelling_detail_id": 96976,
                "word_type": "n.",
                "spelling_usage": "In Africa, a parley with the natives; a talk; hence, a\n   public conference and deliberation; a debate."
            },
            {
                "spelling_detail_id": 96979,
                "word_type": "v. t. & i.",
                "spelling_usage": "To make palaver with, or to; to used palaver;to\n   talk idly or deceitfully; to employ flattery; to cajole; as, to palaver\n   artfully."
            }
        ]
    },
    {
        "id": 62783,
        "spelling_name": "Panegyric",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 97444,
                "word_type": "a.",
                "spelling_usage": "An oration or eulogy in praise of some person or\n   achievement; a formal or elaborate encomium; a laudatory discourse;\n   laudation. See Synonym of Eulogy."
            },
            {
                "spelling_detail_id": 97445,
                "word_type": "a.",
                "spelling_usage": "Alt. of Panegyrical"
            }
        ]
    },
    {
        "id": 62967,
        "spelling_name": "Papeterie",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 97697,
                "word_type": "n.",
                "spelling_usage": "A case or box containing paper and materials for\n   writing."
            }
        ]
    },
    {
        "id": 63093,
        "spelling_name": "Paraffin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 97861,
                "word_type": "n.",
                "spelling_usage": "Alt. of Paraffine"
            }
        ]
    },
    {
        "id": 63136,
        "spelling_name": "Parallax",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 97922,
                "word_type": "n.",
                "spelling_usage": "The apparent displacement, or difference of position, of\n   an object, as seen from two different stations, or points of view."
            },
            {
                "spelling_detail_id": 97923,
                "word_type": "n.",
                "spelling_usage": "The apparent difference in position of a body (as the\n   sun, or a star) as seen from some point on the earth's surface, and as\n   seen from some other conventional point, as the earth's center or the\n   sun."
            }
        ]
    },
    {
        "id": 63290,
        "spelling_name": "Parchment",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 98144,
                "word_type": "n.",
                "spelling_usage": "The skin of a lamb, sheep, goat, young calf, or other\n   animal, prepared for writing on. See Vellum."
            },
            {
                "spelling_detail_id": 98145,
                "word_type": "n.",
                "spelling_usage": "The envelope of the coffee grains, inside the pulp."
            }
        ]
    },
    {
        "id": 63382,
        "spelling_name": "Parishioner",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 98283,
                "word_type": "n.",
                "spelling_usage": "One who belongs to, or is connected with, a parish."
            }
        ]
    },
    {
        "id": 63438,
        "spelling_name": "Paronomasia",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 98369,
                "word_type": "n.",
                "spelling_usage": "A play upon words; a figure by which the same word is\n   used in different senses, or words similar in sound are set in\n   opposition to each other, so as to give antithetical force to the\n   sentence; punning."
            }
        ]
    },
    {
        "id": 63458,
        "spelling_name": "Paroxysm",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 98394,
                "word_type": "n.",
                "spelling_usage": "The fit, attack, or exacerbation, of a disease that\n   occurs at intervals, or has decided remissions or intermissions."
            },
            {
                "spelling_detail_id": 98395,
                "word_type": "n.",
                "spelling_usage": "Any sudden and violent emotion; spasmodic passion or\n   action; a convulsion; a fit."
            }
        ]
    },
    {
        "id": 63461,
        "spelling_name": "Parquet",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 98398,
                "word_type": "n.",
                "spelling_usage": "A body of seats on the floor of a music hall or theater\n   nearest the orchestra; but commonly applied to the whole lower floor of\n   a theater, from the orchestra to the dress circle; the pit."
            },
            {
                "spelling_detail_id": 98399,
                "word_type": "n.",
                "spelling_usage": "Same as Parquetry."
            }
        ]
    },
    {
        "id": 63512,
        "spelling_name": "Parterre",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 98493,
                "word_type": "n.",
                "spelling_usage": "An ornamental and diversified arrangement of beds or\n   plots, in which flowers are cultivated, with intervening spaces of\n   gravel or turf for walking on."
            },
            {
                "spelling_detail_id": 98494,
                "word_type": "n.",
                "spelling_usage": "The pit of a theater; the parquet."
            }
        ]
    },
    {
        "id": 63805,
        "spelling_name": "Patois",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 99070,
                "word_type": "n.",
                "spelling_usage": "A dialect peculiar to the illiterate classes; a provincial\n   form of speech."
            }
        ]
    },
    {
        "id": 64277,
        "spelling_name": "Pekoe",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 99814,
                "word_type": "n.",
                "spelling_usage": "A kind of black tea."
            }
        ]
    },
    {
        "id": 64303,
        "spelling_name": "Pelisse",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 99845,
                "word_type": "n.",
                "spelling_usage": "An outer garment for men or women, originally of fur, or\n   lined with fur; a lady's outer garment, made of silk or other fabric."
            }
        ]
    },
    {
        "id": 64747,
        "spelling_name": "Peregrination",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 100485,
                "word_type": "n.",
                "spelling_usage": "A traveling from one country to another; a\n   wandering; sojourn in foreign countries."
            }
        ]
    },
    {
        "id": 65118,
        "spelling_name": "Perorate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 100956,
                "word_type": "v. i.",
                "spelling_usage": "To make a peroration; to harangue."
            }
        ]
    },
    {
        "id": 65193,
        "spelling_name": "Persiflage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 101064,
                "word_type": "n.",
                "spelling_usage": "Frivolous or bantering talk; a frivolous manner of\n   treating any subject, whether serious or otherwise; light raillery."
            }
        ]
    },
    {
        "id": 65280,
        "spelling_name": "Pertinacity",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 101219,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being pertinacious; obstinacy;\n   perseverance; persistency."
            }
        ]
    },
    {
        "id": 65826,
        "spelling_name": "Phloem",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 101908,
                "word_type": "n.",
                "spelling_usage": "That portion of fibrovascular bundles which corresponds to\n   the inner bark; the liber tissue; -- distinguished from xylem."
            }
        ]
    },
    {
        "id": 66538,
        "spelling_name": "Pillage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 102874,
                "word_type": "n.",
                "spelling_usage": "The act of pillaging; robbery."
            },
            {
                "spelling_detail_id": 102875,
                "word_type": "n.",
                "spelling_usage": "That which is taken from another or others by open force,\n   particularly and chiefly from enemies in war; plunder; spoil; booty."
            },
            {
                "spelling_detail_id": 102878,
                "word_type": "v. i.",
                "spelling_usage": "To strip of money or goods by open violence; to\n   plunder; to spoil; to lay waste; as, to pillage the camp of an enemy."
            },
            {
                "spelling_detail_id": 102879,
                "word_type": "v. i.",
                "spelling_usage": "To take spoil; to plunder; to ravage."
            }
        ]
    },
    {
        "id": 66649,
        "spelling_name": "Pinetum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 103057,
                "word_type": "n.",
                "spelling_usage": "A plantation of pine trees; esp., a collection of living\n   pine trees made for ornamental or scientific purposes."
            }
        ]
    },
    {
        "id": 66670,
        "spelling_name": "Pinioned",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 103088,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Pinion"
            },
            {
                "spelling_detail_id": 103094,
                "word_type": "a.",
                "spelling_usage": "Having wings or pinions."
            }
        ]
    },
    {
        "id": 66706,
        "spelling_name": "Pinniped",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 103160,
                "word_type": "n.",
                "spelling_usage": "One of the Pinnipedia; a seal."
            },
            {
                "spelling_detail_id": 103161,
                "word_type": "n.",
                "spelling_usage": "One of the Pinnipedes."
            }
        ]
    },
    {
        "id": 66832,
        "spelling_name": "Piscivorous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 103359,
                "word_type": "a.",
                "spelling_usage": "Feeding or subsisting on fish."
            }
        ]
    },
    {
        "id": 66997,
        "spelling_name": "Plagiarism",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 103641,
                "word_type": "n.",
                "spelling_usage": "The act or practice of plagiarizing."
            },
            {
                "spelling_detail_id": 103642,
                "word_type": "n.",
                "spelling_usage": "That which plagiarized."
            }
        ]
    },
    {
        "id": 67082,
        "spelling_name": "Plangency",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 103794,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being plangent; a beating sound."
            }
        ]
    },
    {
        "id": 67382,
        "spelling_name": "Plenipotentiary",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 104305,
                "word_type": "n.",
                "spelling_usage": "A person invested with full power to transact any\n   business; especially, an ambassador or envoy to a foreign court, with\n   full power to negotiate a treaty, or to transact other business."
            },
            {
                "spelling_detail_id": 104306,
                "word_type": "a.",
                "spelling_usage": "Containing or conferring full power; invested with\n   full power; as, plenipotentiary license; plenipotentiary ministers."
            }
        ]
    },
    {
        "id": 67576,
        "spelling_name": "Plumage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 104588,
                "word_type": "n.",
                "spelling_usage": "The entire clothing of a bird."
            }
        ]
    },
    {
        "id": 67702,
        "spelling_name": "Pneumatocyst",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 104801,
                "word_type": "n.",
                "spelling_usage": "A cyst or sac of a siphonophore, containing air, and\n   serving as a float, as in Physalia."
            }
        ]
    },
    {
        "id": 67772,
        "spelling_name": "Podagra",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 104901,
                "word_type": "n.",
                "spelling_usage": "Gout in the joints of the foot; -- applied also to gout in\n   other parts of body."
            }
        ]
    },
    {
        "id": 67937,
        "spelling_name": "Polemic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 105201,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to controversy; maintaining, or\n   involving, controversy; controversial; disputative; as, a polemic\n   discourse or essay; polemic theology."
            },
            {
                "spelling_detail_id": 105202,
                "word_type": "a.",
                "spelling_usage": "Engaged in, or addicted to, polemics, or to controversy;\n   disputations; as, a polemic writer."
            },
            {
                "spelling_detail_id": 105203,
                "word_type": "n.",
                "spelling_usage": "One who writes in support of one opinion, doctrine, or\n   system, in opposition to another; one skilled in polemics; a\n   controversialist; a disputant."
            },
            {
                "spelling_detail_id": 105204,
                "word_type": "n.",
                "spelling_usage": "A polemic argument or controversy."
            }
        ]
    },
    {
        "id": 67975,
        "spelling_name": "Politesse",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 105278,
                "word_type": "n.",
                "spelling_usage": "Politeness."
            }
        ]
    },
    {
        "id": 68042,
        "spelling_name": "Poltroon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 105399,
                "word_type": "n.",
                "spelling_usage": "An arrant coward; a dastard; a craven; a mean-spirited\n   wretch."
            },
            {
                "spelling_detail_id": 105400,
                "word_type": "a.",
                "spelling_usage": "Base; vile; contemptible; cowardly."
            }
        ]
    },
    {
        "id": 68338,
        "spelling_name": "Pomology",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 105775,
                "word_type": "n.",
                "spelling_usage": "The science of fruits; a treatise on fruits; the\n   cultivation of fruits and fruit trees."
            }
        ]
    },
    {
        "id": 68382,
        "spelling_name": "Pongee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 105834,
                "word_type": "n.",
                "spelling_usage": "A fabric of undyed silk from India and China."
            }
        ]
    },
    {
        "id": 68499,
        "spelling_name": "Porcupine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 106043,
                "word_type": "n.",
                "spelling_usage": "Any Old Word rodent of the genus Hystrix, having the\n   back covered with long, sharp, erectile spines or quills, sometimes a\n   foot long. The common species of Europe and Asia (Hystrix cristata) is\n   the best known."
            },
            {
                "spelling_detail_id": 106044,
                "word_type": "n.",
                "spelling_usage": "Any species of Erethizon and related genera, native of\n   America. They are related to the true porcupines, but have shorter\n   spines, and are arboreal in their habits. The Canada porcupine\n   (Erethizon dorsatus) is a well known species."
            }
        ]
    },
    {
        "id": 68623,
        "spelling_name": "Portmanteau",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 106226,
                "word_type": "n.",
                "spelling_usage": "A bag or case, usually of leather, for carrying\n   wearing apparel, etc., on journeys."
            }
        ]
    },
    {
        "id": 68647,
        "spelling_name": "Porwigle",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 106257,
                "word_type": "n.",
                "spelling_usage": "See Polliwig."
            }
        ]
    },
    {
        "id": 68853,
        "spelling_name": "Potager",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 106591,
                "word_type": "n.",
                "spelling_usage": "A porringer."
            }
        ]
    },
    {
        "id": 69409,
        "spelling_name": "Predilection",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 107445,
                "word_type": "n.",
                "spelling_usage": "A previous liking; a prepossession of mind in favor\n   of something; predisposition to choose or like; partiality."
            }
        ]
    },
    {
        "id": 69780,
        "spelling_name": "Prescient",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 107932,
                "word_type": "a.",
                "spelling_usage": "Having knowledge of coming events; foreseeing; conscious\n   beforehand."
            }
        ]
    },
    {
        "id": 69811,
        "spelling_name": "Presentient",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 108009,
                "word_type": "a.",
                "spelling_usage": "Feeling or perceiving beforehand."
            }
        ]
    },
    {
        "id": 69880,
        "spelling_name": "Prestidigitation",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 108140,
                "word_type": "n.",
                "spelling_usage": "Legerdemain; sleight of hand; juggling."
            }
        ]
    },
    {
        "id": 69930,
        "spelling_name": "Pretentious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 108217,
                "word_type": "a.",
                "spelling_usage": "Full of pretension; disposed to lay claim to more than\n   is one's; presuming; assuming."
            }
        ]
    },
    {
        "id": 70495,
        "spelling_name": "Proffered",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109194,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Proffer"
            }
        ]
    },
    {
        "id": 70512,
        "spelling_name": "Profligacy",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109227,
                "word_type": "a.",
                "spelling_usage": "The quality of state of being profligate; a profligate\n   or very vicious course of life; a state of being abandoned in moral\n   principle and in vice; dissoluteness."
            }
        ]
    },
    {
        "id": 70527,
        "spelling_name": "Profusion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109255,
                "word_type": "n.",
                "spelling_usage": "The act of one who is profuse; a lavishing or pouring\n   out without sting."
            },
            {
                "spelling_detail_id": 109256,
                "word_type": "n.",
                "spelling_usage": "Abundance; exuberant plenty; lavish supply; as, a\n   profusion of commodities."
            }
        ]
    },
    {
        "id": 70779,
        "spelling_name": "Propane",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109660,
                "word_type": "n.",
                "spelling_usage": "A heavy gaseous hydrocarbon, C3H8, of the paraffin series,\n   occurring naturally dissolved in crude petroleum, and also made\n   artificially; -- called also propyl hydride."
            }
        ]
    },
    {
        "id": 70888,
        "spelling_name": "Propound",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109840,
                "word_type": "v. t.",
                "spelling_usage": "To offer for consideration; to exhibit; to propose;\n   as, to propound a question; to propound an argument."
            },
            {
                "spelling_detail_id": 109841,
                "word_type": "v. t.",
                "spelling_usage": "To propose or name as a candidate for admission to\n   communion with a church."
            }
        ]
    },
    {
        "id": 70947,
        "spelling_name": "Proscenium",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109907,
                "word_type": "n.",
                "spelling_usage": "The part where the actors performed; the stage."
            },
            {
                "spelling_detail_id": 109908,
                "word_type": "n.",
                "spelling_usage": "The part of the stage in front of the curtain;\n   sometimes, the curtain and its framework."
            }
        ]
    },
    {
        "id": 70976,
        "spelling_name": "Proselytizer",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 109959,
                "word_type": "n.",
                "spelling_usage": "One who proselytes."
            }
        ]
    },
    {
        "id": 71196,
        "spelling_name": "Protruding",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 110270,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Protrude"
            }
        ]
    },
    {
        "id": 71340,
        "spelling_name": "Pruritus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 110510,
                "word_type": "n.",
                "spelling_usage": "Itching."
            }
        ]
    },
    {
        "id": 71466,
        "spelling_name": "Psoriasis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 110658,
                "word_type": "n.",
                "spelling_usage": "The state of being affected with psora."
            },
            {
                "spelling_detail_id": 110659,
                "word_type": "n.",
                "spelling_usage": "A cutaneous disease, characterized by imbricated silvery\n   scales, affecting only the superficial layers of the skin."
            }
        ]
    },
    {
        "id": 71556,
        "spelling_name": "Ptosis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 110758,
                "word_type": "n.",
                "spelling_usage": "Drooping of the upper eyelid, produced by paralysis of its\n   levator muscle."
            }
        ]
    },
    {
        "id": 71636,
        "spelling_name": "Puerilely",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 110881,
                "word_type": "adv.",
                "spelling_usage": "In a puerile manner; childishly."
            }
        ]
    },
    {
        "id": 71683,
        "spelling_name": "Pulchritude",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 110973,
                "word_type": "n.",
                "spelling_usage": "That quality of appearance which pleases the eye;\n   beauty; comeliness; grace; loveliness."
            },
            {
                "spelling_detail_id": 110974,
                "word_type": "n.",
                "spelling_usage": "Attractive moral excellence; moral beauty."
            }
        ]
    },
    {
        "id": 72195,
        "spelling_name": "Pylorus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 111790,
                "word_type": "n.",
                "spelling_usage": "The opening from the stomach into the intestine."
            },
            {
                "spelling_detail_id": 111791,
                "word_type": "n.",
                "spelling_usage": "A posterior division of the stomach in some invertebrates."
            }
        ]
    },
    {
        "id": 72352,
        "spelling_name": "Pythagorean",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 111965,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Pythagoras (a Greek philosopher,\n   born about 582 b. c.), or his philosophy."
            },
            {
                "spelling_detail_id": 111966,
                "word_type": "n.",
                "spelling_usage": "A follower of Pythagoras; one of the school of\n   philosophers founded by Pythagoras."
            }
        ]
    },
    {
        "id": 72374,
        "spelling_name": "Pyxis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 111996,
                "word_type": "n.",
                "spelling_usage": "A box; a pyx."
            },
            {
                "spelling_detail_id": 111997,
                "word_type": "n.",
                "spelling_usage": "A pyxidium."
            },
            {
                "spelling_detail_id": 111998,
                "word_type": "n.",
                "spelling_usage": "The acetabulum. See Acetabulum, 2.\n   Q () the seventeenth letter of the English alphabet, has but one sound\n   (that of k), and is always followed by u, the two letters together\n   being sounded like kw, except in some words in which the u is silent.\n   See Guide to Pronunciation, / 249. Q is not found in Anglo-Saxon, cw\n   being used instead of qu; as in cwic, quick; cwen, queen. The name (k/)\n   is from the French ku, which is from the Latin name of the same letter;\n   its form is from the Latin, which derived it, through a Greek alphabet,\n   from the Ph/nician, the ultimate origin being Egyptian."
            }
        ]
    },
    {
        "id": 72758,
        "spelling_name": "Queue",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 112635,
                "word_type": "n.",
                "spelling_usage": "A tail-like appendage of hair; a pigtail."
            },
            {
                "spelling_detail_id": 112636,
                "word_type": "n.",
                "spelling_usage": "A line of persons waiting anywhere."
            },
            {
                "spelling_detail_id": 112637,
                "word_type": "v. t.",
                "spelling_usage": "To fasten, as hair, in a queue."
            }
        ]
    },
    {
        "id": 73198,
        "spelling_name": "Rafflesia",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 113359,
                "word_type": "n.",
                "spelling_usage": "A genus of stemless, leafless plants, living\n   parasitically upon the roots and stems of grapevines in Malaysia. The\n   flowers have a carrionlike odor, and are very large, in one species\n   (Rafflesia Arnoldi) having a diameter of two or three feet."
            }
        ]
    },
    {
        "id": 73418,
        "spelling_name": "Ranine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 113783,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the frogs and toads."
            },
            {
                "spelling_detail_id": 113784,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or designating, a swelling under the tongue;\n   also, pertaining to the region where the swelling occurs; -- applied\n   especially to branches of the lingual artery and lingual vein."
            }
        ]
    },
    {
        "id": 73451,
        "spelling_name": "Ranunculus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 113850,
                "word_type": "n.",
                "spelling_usage": "A genus of herbs, mostly with yellow flowers, including\n   crowfoot, buttercups, and the cultivated ranunculi (R. Asiaticus, R.\n   aconitifolius, etc.) in which the flowers are double and of various\n   colors."
            }
        ]
    },
    {
        "id": 73776,
        "spelling_name": "Realgar",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 114455,
                "word_type": "n.",
                "spelling_usage": "Arsenic sulphide, a mineral of a brilliant red color; red\n   orpiment. It is also an artificial product."
            }
        ]
    },
    {
        "id": 73897,
        "spelling_name": "Reboation",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 114656,
                "word_type": "n.",
                "spelling_usage": "Repetition of a bellow."
            }
        ]
    },
    {
        "id": 74157,
        "spelling_name": "Reconnoiter",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 115104,
                "word_type": "v. t.",
                "spelling_usage": "Alt. of Reconnoitre"
            }
        ]
    },
    {
        "id": 74397,
        "spelling_name": "Redingote",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 115476,
                "word_type": "n.",
                "spelling_usage": "A long plain double-breasted outside coat for women."
            }
        ]
    },
    {
        "id": 74427,
        "spelling_name": "Redound",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 115516,
                "word_type": "v. i.",
                "spelling_usage": "To roll back, as a wave or flood; to be sent or driven\n   back; to flow back, as a consequence or effect; to conduce; to\n   contribute; to result."
            },
            {
                "spelling_detail_id": 115517,
                "word_type": "v. i.",
                "spelling_usage": "To be in excess; to remain over and above; to be\n   redundant; to overflow."
            },
            {
                "spelling_detail_id": 115518,
                "word_type": "n.",
                "spelling_usage": "The coming back, as of consequence or effect; result;\n   return; requital."
            },
            {
                "spelling_detail_id": 115519,
                "word_type": "n.",
                "spelling_usage": "Rebound; reverberation."
            }
        ]
    },
    {
        "id": 74809,
        "spelling_name": "Regnal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 116202,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the reign of a monarch; as, regnal\n   years."
            }
        ]
    },
    {
        "id": 75426,
        "spelling_name": "Repartee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 117181,
                "word_type": "n.",
                "spelling_usage": "A smart, ready, and witty reply."
            },
            {
                "spelling_detail_id": 117184,
                "word_type": "v. i.",
                "spelling_usage": "To make smart and witty replies."
            }
        ]
    },
    {
        "id": 76156,
        "spelling_name": "Retinoscopy",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 118485,
                "word_type": "n.",
                "spelling_usage": "The study of the retina of the eye by means of the\n   ophthalmoscope."
            }
        ]
    },
    {
        "id": 76303,
        "spelling_name": "Reveille",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 118742,
                "word_type": "n.",
                "spelling_usage": "The beat of drum, or bugle blast, about break of day, to\n   give notice that it is time for the soldiers to rise, and for the\n   sentinels to forbear challenging."
            }
        ]
    },
    {
        "id": 76565,
        "spelling_name": "Rheostat",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 119174,
                "word_type": "n.",
                "spelling_usage": "A contrivance for adjusting or regulating the strength of\n   electrical currents, operating usually by the intercalation of\n   resistance which can be varied at will."
            }
        ]
    },
    {
        "id": 76637,
        "spelling_name": "Rhizome",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 119256,
                "word_type": "n.",
                "spelling_usage": "A rootstock. See Rootstock."
            }
        ]
    },
    {
        "id": 76984,
        "spelling_name": "Riparian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 119891,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the bank of a river; as, riparian\n   rights."
            }
        ]
    },
    {
        "id": 77030,
        "spelling_name": "Rissole",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 120020,
                "word_type": "n.",
                "spelling_usage": "A small ball of rich minced meat or fish, covered with\n   pastry and fried."
            }
        ]
    },
    {
        "id": 77173,
        "spelling_name": "Rococo",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 120283,
                "word_type": "n.",
                "spelling_usage": "A florid style of ornamentation which prevailed in Europe\n   in the latter part of the eighteenth century."
            },
            {
                "spelling_detail_id": 120284,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the style called rococo; like rococo;\n   florid; fantastic."
            }
        ]
    },
    {
        "id": 77206,
        "spelling_name": "Roiling",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 120345,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Roil"
            }
        ]
    },
    {
        "id": 77288,
        "spelling_name": "Rondeau",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 120510,
                "word_type": "n.",
                "spelling_usage": "A species of lyric poetry so composed as to contain a\n   refrain or repetition which recurs according to a fixed law, and a\n   limited number of rhymes recurring also by rule."
            },
            {
                "spelling_detail_id": 120511,
                "word_type": "n.",
                "spelling_usage": "See Rondo, 1."
            }
        ]
    },
    {
        "id": 77295,
        "spelling_name": "Rongeur",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 120524,
                "word_type": "n.",
                "spelling_usage": "An instrument for removing small rough portions of bone."
            }
        ]
    },
    {
        "id": 77603,
        "spelling_name": "Roux",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 121115,
                "word_type": "n.",
                "spelling_usage": "A thickening, made of flour, for soups and gravies."
            }
        ]
    },
    {
        "id": 77656,
        "spelling_name": "Rubato",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 121230,
                "word_type": "a.",
                "spelling_usage": "Robbed; borrowed."
            }
        ]
    },
    {
        "id": 77668,
        "spelling_name": "Rubefacient",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 121255,
                "word_type": "a.",
                "spelling_usage": "Making red."
            },
            {
                "spelling_detail_id": 121256,
                "word_type": "n.",
                "spelling_usage": "An external application which produces redness of the\n   skin."
            }
        ]
    },
    {
        "id": 77684,
        "spelling_name": "Rubicon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 121274,
                "word_type": "n.",
                "spelling_usage": "A small river which separated Italy from Cisalpine Gaul,\n   the province alloted to Julius Caesar."
            }
        ]
    },
    {
        "id": 77756,
        "spelling_name": "Ruelle",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 121395,
                "word_type": "n.",
                "spelling_usage": "A private circle or assembly at a private house; a circle."
            }
        ]
    },
    {
        "id": 77793,
        "spelling_name": "Rugose",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 121478,
                "word_type": "a.",
                "spelling_usage": "Wrinkled; full of wrinkles; specifically (Bot.), having the\n   veinlets sunken and the spaces between them elevated, as the leaves of\n   the sage and horehound."
            }
        ]
    },
    {
        "id": 78099,
        "spelling_name": "Sacerdotal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 122081,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to priests, or to the order of\n   priests; relating to the priesthood; priesty; as, sacerdotal dignity;\n   sacerdotal functions."
            }
        ]
    },
    {
        "id": 78154,
        "spelling_name": "Sacristy",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 122176,
                "word_type": "n.",
                "spelling_usage": "An apartment in a church where the sacred utensils,\n   vestments, etc., are kept; a vestry."
            }
        ]
    },
    {
        "id": 78397,
        "spelling_name": "Salmagundi",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 122569,
                "word_type": "n.",
                "spelling_usage": "A mixture of chopped meat and pickled herring, with\n   oil, vinegar, pepper, and onions."
            },
            {
                "spelling_detail_id": 122570,
                "word_type": "n.",
                "spelling_usage": "Hence, a mixture of various ingredients; an olio or\n   medley; a potpourri; a miscellany."
            }
        ]
    },
    {
        "id": 78501,
        "spelling_name": "Samarium",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 122727,
                "word_type": "n.",
                "spelling_usage": "A rare metallic element of doubtful identity."
            }
        ]
    },
    {
        "id": 78885,
        "spelling_name": "Satiety",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 123228,
                "word_type": "n.",
                "spelling_usage": "The state of being satiated or glutted; fullness of\n   gratification, either of the appetite or of any sensual desire;\n   fullness beyond desire; an excess of gratification which excites\n   wearisomeness or loathing; repletion; satiation."
            }
        ]
    },
    {
        "id": 78931,
        "spelling_name": "Saturnine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 123299,
                "word_type": "a.",
                "spelling_usage": "Born under, or influenced by, the planet Saturn."
            },
            {
                "spelling_detail_id": 123300,
                "word_type": "a.",
                "spelling_usage": "Heavy; grave; gloomy; dull; -- the opposite of\n   mercurial; as, a saturnine person or temper."
            },
            {
                "spelling_detail_id": 123301,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to lead; characterized by, or\n   resembling, lead, which was formerly called Saturn."
            }
        ]
    },
    {
        "id": 78955,
        "spelling_name": "Sauger",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 123342,
                "word_type": "n.",
                "spelling_usage": "An American fresh-water food fish (Stizostedion Canadense);\n   -- called also gray pike, blue pike, hornfish, land pike, sand pike,\n   pickering, and pickerel."
            }
        ]
    },
    {
        "id": 79088,
        "spelling_name": "Scabbard",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 123568,
                "word_type": "n.",
                "spelling_usage": "The case in which the blade of a sword, dagger, etc., is\n   kept; a sheath."
            },
            {
                "spelling_detail_id": 123569,
                "word_type": "v. t.",
                "spelling_usage": "To put in a scabbard."
            }
        ]
    },
    {
        "id": 79142,
        "spelling_name": "Scallion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 123679,
                "word_type": "n.",
                "spelling_usage": "A kind of small onion (Allium Ascalonicum), native of\n   Palestine; the eschalot, or shallot."
            },
            {
                "spelling_detail_id": 123680,
                "word_type": "n.",
                "spelling_usage": "Any onion which does not \"bottom out,\" but remains with a\n   thick stem like a leek."
            }
        ]
    },
    {
        "id": 79335,
        "spelling_name": "Scepter",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 124012,
                "word_type": "n.",
                "spelling_usage": "Alt. of Sceptre"
            },
            {
                "spelling_detail_id": 124019,
                "word_type": "v. t.",
                "spelling_usage": "Alt. of Sceptre"
            }
        ]
    },
    {
        "id": 79511,
        "spelling_name": "Scintillation",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 124248,
                "word_type": "n.",
                "spelling_usage": "The act of scintillating."
            },
            {
                "spelling_detail_id": 124249,
                "word_type": "n.",
                "spelling_usage": "A spark or flash emitted in scintillating."
            }
        ]
    },
    {
        "id": 79517,
        "spelling_name": "Sciolistic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 124255,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to sciolism, or a sciolist; partaking\n   of sciolism; resembling a sciolist."
            }
        ]
    },
    {
        "id": 79735,
        "spelling_name": "Scourge",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 124593,
                "word_type": "n.",
                "spelling_usage": "A lash; a strap or cord; especially, a lash used to\n   inflict pain or punishment; an instrument of punishment or discipline;\n   a whip."
            },
            {
                "spelling_detail_id": 124594,
                "word_type": "n.",
                "spelling_usage": "Hence, a means of inflicting punishment, vengeance, or\n   suffering; an infliction of affliction; a punishment."
            },
            {
                "spelling_detail_id": 124597,
                "word_type": "n.",
                "spelling_usage": "To whip severely; to lash."
            },
            {
                "spelling_detail_id": 124598,
                "word_type": "n.",
                "spelling_usage": "To punish with severity; to chastise; to afflict, as for\n   sins or faults, and with the purpose of correction."
            },
            {
                "spelling_detail_id": 124599,
                "word_type": "n.",
                "spelling_usage": "To harass or afflict severely."
            }
        ]
    },
    {
        "id": 79872,
        "spelling_name": "Scrivener",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 124862,
                "word_type": "n.",
                "spelling_usage": "A professional writer; one whose occupation is to draw\n   contracts or prepare writings."
            },
            {
                "spelling_detail_id": 124863,
                "word_type": "n.",
                "spelling_usage": "One whose business is to place money at interest; a\n   broker."
            },
            {
                "spelling_detail_id": 124864,
                "word_type": "n.",
                "spelling_usage": "A writing master."
            }
        ]
    },
    {
        "id": 79984,
        "spelling_name": "Scurrilous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 125058,
                "word_type": "a.",
                "spelling_usage": "Using the low and indecent language of the meaner sort\n   of people, or such as only the license of buffoons can warrant; as, a\n   scurrilous fellow."
            },
            {
                "spelling_detail_id": 125059,
                "word_type": "a.",
                "spelling_usage": "Containing low indecency or abuse; mean; foul; vile;\n   obscenely jocular; as, scurrilous language."
            }
        ]
    },
    {
        "id": 80364,
        "spelling_name": "Sebaceous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 125636,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or secreting, fat; composed of fat;\n   having the appearance of fat; as, the sebaceous secretions of some\n   plants, or the sebaceous humor of animals."
            }
        ]
    },
    {
        "id": 80375,
        "spelling_name": "Secant",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 125648,
                "word_type": "a.",
                "spelling_usage": "Cutting; divivding into two parts; as, a secant line."
            },
            {
                "spelling_detail_id": 125649,
                "word_type": "a.",
                "spelling_usage": "A line that cuts another; especially, a straight line\n   cutting a curve in two or more points."
            },
            {
                "spelling_detail_id": 125650,
                "word_type": "a.",
                "spelling_usage": "A right line drawn from the center of a circle through one\n   end of a circular arc, and terminated by a tangent drawn from the other\n   end; the number expressing the ratio line of this line to the radius of\n   the circle. See Trigonometrical function, under Function."
            }
        ]
    },
    {
        "id": 80525,
        "spelling_name": "Sedulous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 125905,
                "word_type": "a.",
                "spelling_usage": "Diligent in application or pursuit; constant, steady, and\n   persevering in business, or in endeavors to effect an object; steadily\n   industrious; assiduous; as, the sedulous bee."
            }
        ]
    },
    {
        "id": 80619,
        "spelling_name": "Seine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 126077,
                "word_type": "n.",
                "spelling_usage": "A large net, one edge of which is provided with sinkers, and\n   the other with floats. It hangs vertically in the water, and when its\n   ends are brought together or drawn ashore incloses the fish."
            }
        ]
    },
    {
        "id": 80938,
        "spelling_name": "Semaphore",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 126441,
                "word_type": "n.",
                "spelling_usage": "A signal telegraph; an apparatus for giving signals by\n   the disposition of lanterns, flags, oscillating arms, etc."
            }
        ]
    },
    {
        "id": 81172,
        "spelling_name": "Senecio",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 126733,
                "word_type": "n.",
                "spelling_usage": "A very large genus of composite plants including the\n   groundsel and the golden ragwort."
            }
        ]
    },
    {
        "id": 81179,
        "spelling_name": "Seneschal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 126740,
                "word_type": "n.",
                "spelling_usage": "An officer in the houses of princes and dignitaries, in\n   the Middle Ages, who had the superintendence of feasts and domestic\n   ceremonies; a steward. Sometimes the seneschal had the dispensing of\n   justice, and was given high military commands."
            }
        ]
    },
    {
        "id": 81399,
        "spelling_name": "Sepulchral",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 127077,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to burial, to the grave, or to\n   monuments erected to the memory of the dead; as, a sepulchral stone; a\n   sepulchral inscription."
            },
            {
                "spelling_detail_id": 127078,
                "word_type": "a.",
                "spelling_usage": "Unnaturally low and grave; hollow in tone; -- said of\n   sound, especially of the voice."
            }
        ]
    },
    {
        "id": 81608,
        "spelling_name": "Sesquipedalian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 127425,
                "word_type": "a.",
                "spelling_usage": "Measuring or containing a foot and a half; as, a\n   sesquipedalian pygmy; -- sometimes humorously applied to long words."
            }
        ]
    },
    {
        "id": 81782,
        "spelling_name": "Sforzando",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 127796,
                "word_type": "a.",
                "spelling_usage": "Alt. of Sforzato"
            }
        ]
    },
    {
        "id": 82492,
        "spelling_name": "Shrike",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 129252,
                "word_type": "v. i.",
                "spelling_usage": "Any one of numerous species of oscinine birds of the\n   family Laniidae, having a strong hooked bill, toothed at the tip. Most\n   shrikes are insectivorous, but the common European gray shrike (Lanius\n   excubitor), the great northern shrike (L. borealis), and several\n   others, kill mice, small birds, etc., and often impale them on thorns,\n   and are, on that account called also butcher birds. See under Butcher."
            }
        ]
    },
    {
        "id": 82597,
        "spelling_name": "Sibilant",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 129457,
                "word_type": "a.",
                "spelling_usage": "Making a hissing sound; uttered with a hissing sound;\n   hissing; as, s, z, sh, and zh, are sibilant elementary sounds."
            },
            {
                "spelling_detail_id": 129458,
                "word_type": "n.",
                "spelling_usage": "A sibiliant letter."
            }
        ]
    },
    {
        "id": 82971,
        "spelling_name": "Simulacrum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 130099,
                "word_type": "n.",
                "spelling_usage": "A likeness; a semblance; a mock appearance; a sham; --\n   now usually in a derogatory sense."
            }
        ]
    },
    {
        "id": 83003,
        "spelling_name": "Sinciput",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 130151,
                "word_type": "n.",
                "spelling_usage": "The fore part of the head."
            },
            {
                "spelling_detail_id": 130152,
                "word_type": "n.",
                "spelling_usage": "The part of the head of a bird between the base of the\n   bill and the vertex."
            }
        ]
    },
    {
        "id": 83171,
        "spelling_name": "Sirenian",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 130465,
                "word_type": "n.",
                "spelling_usage": "Any species of Sirenia."
            }
        ]
    },
    {
        "id": 84505,
        "spelling_name": "Solecism",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133041,
                "word_type": "n.",
                "spelling_usage": "An impropriety or incongruity of language in the\n   combination of words or parts of a sentence; esp., deviation from the\n   idiom of a language or from the rules of syntax."
            },
            {
                "spelling_detail_id": 133042,
                "word_type": "n.",
                "spelling_usage": "Any inconsistency, unfitness, absurdity, or impropriety,\n   as in deeds or manners."
            }
        ]
    },
    {
        "id": 84536,
        "spelling_name": "Solenoid",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133088,
                "word_type": "n.",
                "spelling_usage": "An electrodynamic spiral having the conjuctive wire\n   turned back along its axis, so as to neutralize that component of the\n   effect of the current which is due to the length of the spiral, and\n   reduce the whole effect to that of a series of equal and parallel\n   circular currents. When traversed by a current the solenoid exhibits\n   polarity and attraction or repulsion, like a magnet."
            }
        ]
    },
    {
        "id": 84703,
        "spelling_name": "Somnolent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133353,
                "word_type": "a.",
                "spelling_usage": "Sleepy; drowsy; inclined to sleep."
            }
        ]
    },
    {
        "id": 84863,
        "spelling_name": "Sorghum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133589,
                "word_type": "n.",
                "spelling_usage": "A genus of grasses, properly limited to two species,\n   Sorghum Halepense, the Arabian millet, or Johnson grass (see Johnson\n   grass), and S. vulgare, the Indian millet (see Indian millet, under\n   Indian)."
            },
            {
                "spelling_detail_id": 133590,
                "word_type": "n.",
                "spelling_usage": "A variety of Sorghum vulgare, grown for its saccharine\n   juice; the Chinese sugar cane."
            }
        ]
    },
    {
        "id": 84872,
        "spelling_name": "Sororal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133598,
                "word_type": "a.",
                "spelling_usage": "Relating to a sister; sisterly."
            }
        ]
    },
    {
        "id": 84932,
        "spelling_name": "Souchong",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133697,
                "word_type": "n.",
                "spelling_usage": "A kind of black tea of a fine quality."
            }
        ]
    },
    {
        "id": 84989,
        "spelling_name": "Souterrain",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 133832,
                "word_type": "n.",
                "spelling_usage": "A grotto or cavern under ground."
            }
        ]
    },
    {
        "id": 85545,
        "spelling_name": "Sphygmometer",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 134784,
                "word_type": "n.",
                "spelling_usage": "An instrument for measuring the strength of the pulse\n   beat; a sphygmograph."
            }
        ]
    },
    {
        "id": 85867,
        "spelling_name": "Spodumene",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 135352,
                "word_type": "n.",
                "spelling_usage": "A mineral of a white to yellowish, purplish, or\n   emerald-green color, occuring in prismatic crystals, often of great\n   size. It is a silicate of aluminia and lithia. See Hiddenite."
            }
        ]
    },
    {
        "id": 85979,
        "spelling_name": "Sporran",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 135537,
                "word_type": "n.",
                "spelling_usage": "A large purse or pouch made of skin with the hair or fur\n   on, worn in front of the kilt by Highlanders when in full dress."
            }
        ]
    },
    {
        "id": 86459,
        "spelling_name": "Stalagmite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 136474,
                "word_type": "n.",
                "spelling_usage": "A deposit more or less resembling an inverted\n   stalactite, formed by calcareous water dropping on the floors of\n   caverns; hence, a similar deposit of other material."
            }
        ]
    },
    {
        "id": 86525,
        "spelling_name": "Stanchion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 136638,
                "word_type": "n.",
                "spelling_usage": "A prop or support; a piece of timber in the form of a\n   stake or post, used for a support or stay."
            },
            {
                "spelling_detail_id": 136639,
                "word_type": "n.",
                "spelling_usage": "Any upright post or beam used as a support, as for the\n   deck, the quarter rails, awnings, etc."
            },
            {
                "spelling_detail_id": 136640,
                "word_type": "n.",
                "spelling_usage": "A vertical bar for confining cattle in a stall."
            }
        ]
    },
    {
        "id": 86565,
        "spelling_name": "Stannum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 136748,
                "word_type": "n.",
                "spelling_usage": "The technical name of tin. See Tin."
            }
        ]
    },
    {
        "id": 86780,
        "spelling_name": "Stealthily",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 137177,
                "word_type": "adv.",
                "spelling_usage": "In a stealthy manner."
            }
        ]
    },
    {
        "id": 87092,
        "spelling_name": "Stevedore",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 137643,
                "word_type": "n.",
                "spelling_usage": "One whose occupation is to load and unload vessels in\n   port; one who stows a cargo in a hold."
            }
        ]
    },
    {
        "id": 87742,
        "spelling_name": "Stretto",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 138929,
                "word_type": "n.",
                "spelling_usage": "The crowding of answer upon subject near the end of a\n   fugue."
            },
            {
                "spelling_detail_id": 138930,
                "word_type": "n.",
                "spelling_usage": "In an opera or oratorio, a coda, or winding up, in an\n   accelerated time."
            }
        ]
    },
    {
        "id": 87779,
        "spelling_name": "Strife",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 139000,
                "word_type": "n.",
                "spelling_usage": "The act of striving; earnest endeavor."
            },
            {
                "spelling_detail_id": 139001,
                "word_type": "n.",
                "spelling_usage": "Exertion or contention for superiority; contest of\n   emulation, either by intellectual or physical efforts."
            },
            {
                "spelling_detail_id": 139002,
                "word_type": "n.",
                "spelling_usage": "Altercation; violent contention; fight; battle."
            },
            {
                "spelling_detail_id": 139003,
                "word_type": "n.",
                "spelling_usage": "That which is contended against; occasion of contest."
            }
        ]
    },
    {
        "id": 88107,
        "spelling_name": "Styptic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 139684,
                "word_type": "a.",
                "spelling_usage": "Producing contraction; stopping bleeding; having the\n   quality of restraining hemorrhage when applied to the bleeding part;\n   astringent."
            },
            {
                "spelling_detail_id": 139685,
                "word_type": "n.",
                "spelling_usage": "A styptic medicine."
            }
        ]
    },
    {
        "id": 88156,
        "spelling_name": "Subaltern",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 139739,
                "word_type": "a.",
                "spelling_usage": "Ranked or ranged below; subordinate; inferior;\n   specifically (Mil.), ranking as a junior officer; being below the rank\n   of captain; as, a subaltern officer."
            },
            {
                "spelling_detail_id": 139740,
                "word_type": "a.",
                "spelling_usage": "Asserting only a part of what is asserted in a related\n   proposition."
            },
            {
                "spelling_detail_id": 139741,
                "word_type": "n.",
                "spelling_usage": "A person holding a subordinate position; specifically, a\n   commissioned military officer below the rank of captain."
            },
            {
                "spelling_detail_id": 139742,
                "word_type": "n.",
                "spelling_usage": "A subaltern proposition."
            }
        ]
    },
    {
        "id": 88576,
        "spelling_name": "Substantial",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 140318,
                "word_type": "a.",
                "spelling_usage": "Belonging to substance; actually existing; real; as,\n   substantial life."
            },
            {
                "spelling_detail_id": 140319,
                "word_type": "a.",
                "spelling_usage": "Not seeming or imaginary; not illusive; real; solid;\n   true; veritable."
            },
            {
                "spelling_detail_id": 140320,
                "word_type": "a.",
                "spelling_usage": "Corporeal; material; firm."
            },
            {
                "spelling_detail_id": 140321,
                "word_type": "a.",
                "spelling_usage": "Having good substance; strong; stout; solid; firm; as,\n   substantial cloth; a substantial fence or wall."
            },
            {
                "spelling_detail_id": 140322,
                "word_type": "a.",
                "spelling_usage": "Possessed of goods or an estate; moderately wealthy;\n   responsible; as, a substantial freeholder."
            }
        ]
    },
    {
        "id": 88729,
        "spelling_name": "Succade",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 140531,
                "word_type": "n.",
                "spelling_usage": "A sweetmeat."
            },
            {
                "spelling_detail_id": 140532,
                "word_type": "n.",
                "spelling_usage": "Sweetmeats, or preserves in sugar, whether fruit,\n   vegetables, or confections."
            }
        ]
    },
    {
        "id": 88795,
        "spelling_name": "Succussion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 140623,
                "word_type": "n.",
                "spelling_usage": "The act of shaking; a shake; esp. (Med.), a shaking of\n   the body to ascertain if there be a liquid in the thorax."
            }
        ]
    },
    {
        "id": 88954,
        "spelling_name": "Sulcus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 140918,
                "word_type": "n.",
                "spelling_usage": "A furrow; a groove; a fissure."
            }
        ]
    },
    {
        "id": 89203,
        "spelling_name": "Supercilious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 141262,
                "word_type": "a.",
                "spelling_usage": "Lofty with pride; haughty; dictatorial; overbearing;\n   arrogant; as, a supercilious officer; asupercilious air; supercilious\n   behavior."
            }
        ]
    },
    {
        "id": 89286,
        "spelling_name": "Superintendent",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 141358,
                "word_type": "a.",
                "spelling_usage": "Overseeing; superintending."
            },
            {
                "spelling_detail_id": 141359,
                "word_type": "n.",
                "spelling_usage": "One who has the oversight and charge of some place,\n   institution, or organization, affairs, etc., with the power of\n   direction; as, the superintendent of an almshouse; the superintendent\n   of public works."
            }
        ]
    },
    {
        "id": 89598,
        "spelling_name": "Surcease",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 141795,
                "word_type": "n.",
                "spelling_usage": "Cessation; stop; end."
            },
            {
                "spelling_detail_id": 141796,
                "word_type": "v. t.",
                "spelling_usage": "To cause to cease; to end."
            },
            {
                "spelling_detail_id": 141797,
                "word_type": "v. i.",
                "spelling_usage": "To cease."
            }
        ]
    },
    {
        "id": 89636,
        "spelling_name": "Surfeit",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 141865,
                "word_type": "n.",
                "spelling_usage": "Excess in eating and drinking."
            },
            {
                "spelling_detail_id": 141866,
                "word_type": "n.",
                "spelling_usage": "Fullness and oppression of the system, occasioned often by\n   excessive eating and drinking."
            },
            {
                "spelling_detail_id": 141867,
                "word_type": "n.",
                "spelling_usage": "Disgust caused by excess; satiety."
            },
            {
                "spelling_detail_id": 141868,
                "word_type": "v. i.",
                "spelling_usage": "To load the stomach with food, so that sickness or\n   uneasiness ensues; to eat to excess."
            },
            {
                "spelling_detail_id": 141869,
                "word_type": "v. i.",
                "spelling_usage": "To indulge to satiety in any gratification."
            },
            {
                "spelling_detail_id": 141872,
                "word_type": "v. t.",
                "spelling_usage": "To feed so as to oppress the stomach and derange the\n   function of the system; to overfeed, and produce satiety, sickness, or\n   uneasiness; -- often reflexive; as, to surfeit one's self with sweets."
            },
            {
                "spelling_detail_id": 141873,
                "word_type": "v. t.",
                "spelling_usage": "To fill to satiety and disgust; to cloy; as, he\n   surfeits us with compliments."
            }
        ]
    },
    {
        "id": 89837,
        "spelling_name": "Susurrus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 142200,
                "word_type": "n.",
                "spelling_usage": "The act of whispering; a whisper; a murmur."
            }
        ]
    },
    {
        "id": 90233,
        "spelling_name": "Sylph",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 142996,
                "word_type": "n.",
                "spelling_usage": "An imaginary being inhabiting the air; a fairy."
            },
            {
                "spelling_detail_id": 142997,
                "word_type": "n.",
                "spelling_usage": "Fig.: A slender, graceful woman."
            },
            {
                "spelling_detail_id": 142998,
                "word_type": "n.",
                "spelling_usage": "Any one of several species of very brilliant South American\n   humming birds, having a very long and deeply-forked tail; as, the\n   blue-tailed sylph (Cynanthus cyanurus)."
            }
        ]
    },
    {
        "id": 90386,
        "spelling_name": "Syncope",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 143216,
                "word_type": "n.",
                "spelling_usage": "An elision or retrenchment of one or more letters or\n   syllables from the middle of a word; as, ne'er for never, ev'ry for\n   every."
            },
            {
                "spelling_detail_id": 143217,
                "word_type": "n.",
                "spelling_usage": "Same as Syncopation."
            },
            {
                "spelling_detail_id": 143218,
                "word_type": "n.",
                "spelling_usage": "A fainting, or swooning. See Fainting."
            },
            {
                "spelling_detail_id": 143219,
                "word_type": "n.",
                "spelling_usage": "A pause or cessation; suspension."
            }
        ]
    },
    {
        "id": 90410,
        "spelling_name": "Synecdoche",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 143253,
                "word_type": "n.",
                "spelling_usage": "A figure or trope by which a part of a thing is put for\n   the whole (as, fifty sail for fifty ships), or the whole for a part\n   (as, the smiling year for spring), the species for the genus (as,\n   cutthroat for assassin), the genus for the species (as, a creature for\n   a man), the name of the material for the thing made, etc."
            }
        ]
    },
    {
        "id": 90562,
        "spelling_name": "Syzygy",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 143448,
                "word_type": "n.",
                "spelling_usage": "The point of an orbit, as of the moon or a planet, at which\n   it is in conjunction or opposition; -- commonly used in the plural."
            },
            {
                "spelling_detail_id": 143449,
                "word_type": "n.",
                "spelling_usage": "The coupling together of different feet; as, in Greek\n   verse, an iambic syzygy."
            },
            {
                "spelling_detail_id": 143450,
                "word_type": "n.",
                "spelling_usage": "Any one of the segments of an arm of a crinoid composed of\n   two joints so closely united that the line of union is obliterated on\n   the outer, though visible on the inner, side."
            },
            {
                "spelling_detail_id": 143451,
                "word_type": "n.",
                "spelling_usage": "The immovable union of two joints of a crinoidal arm.\n   T () the twentieth letter of the English alphabet, is a nonvocal\n   consonant. With the letter h it forms the digraph th, which has two\n   distinct sounds, as in thin, then. See Guide to Pronunciation,\n   //262-264, and also //153, 156, 169, 172, 176, 178-180."
            }
        ]
    },
    {
        "id": 90855,
        "spelling_name": "Tamarack",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 143995,
                "word_type": "n.",
                "spelling_usage": "The American larch; also, the larch of Oregon and British\n   Columbia (Larix occidentalis). See Hackmatack, and Larch."
            },
            {
                "spelling_detail_id": 143996,
                "word_type": "n.",
                "spelling_usage": "The black pine (Pinus Murrayana) of Alaska, California,\n   etc. It is a small tree with fine-grained wood."
            }
        ]
    },
    {
        "id": 90859,
        "spelling_name": "Tamarisk",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144001,
                "word_type": "n.",
                "spelling_usage": "Any shrub or tree of the genus Tamarix, the species of\n   which are European and Asiatic. They have minute scalelike leaves, and\n   small flowers in spikes. An Arabian species (T. mannifera) is the\n   source of one kind of manna."
            }
        ]
    },
    {
        "id": 90903,
        "spelling_name": "Tanager",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144080,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of bright-colored singing\n   birds belonging to Tanagra, Piranga, and allied genera. The scarlet\n   tanager (Piranga erythromelas) and the summer redbird (Piranga rubra)\n   are common species of the United States."
            }
        ]
    },
    {
        "id": 90991,
        "spelling_name": "Tapetum",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144218,
                "word_type": "n.",
                "spelling_usage": "An area in the pigmented layer of the choroid coat of the\n   eye in many animals, which has an iridescent or metallic luster and\n   helps to make the eye visible in the dark. Sometimes applied to the\n   whole layer of pigmented epithelium of the choroid."
            }
        ]
    },
    {
        "id": 91066,
        "spelling_name": "Tarpaulin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144322,
                "word_type": "n.",
                "spelling_usage": "A piece of canvas covered with tar or a waterproof\n   composition, used for covering the hatches of a ship, hammocks, boats,\n   etc."
            },
            {
                "spelling_detail_id": 144323,
                "word_type": "n.",
                "spelling_usage": "A hat made of, or covered with, painted or tarred cloth,\n   worn by sailors and others."
            },
            {
                "spelling_detail_id": 144324,
                "word_type": "n.",
                "spelling_usage": "Hence, a sailor; a seaman; a tar."
            }
        ]
    },
    {
        "id": 91177,
        "spelling_name": "Tatterdemalion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144508,
                "word_type": "n.",
                "spelling_usage": "A ragged fellow; a ragamuffin."
            }
        ]
    },
    {
        "id": 91203,
        "spelling_name": "Taurine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144543,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the genus Taurus, or cattle."
            },
            {
                "spelling_detail_id": 144544,
                "word_type": "n.",
                "spelling_usage": "A body occurring in small quantity in the juices of\n   muscle, in the lungs, and elsewhere, but especially in the bile, where\n   it is found as a component part of taurocholic acid, from which it can\n   be prepared by decomposition of the acid. It crystallizes in colorless,\n   regular six-sided prisms, and is especially characterized by containing\n   both nitrogen and sulphur, being chemically amido-isethionic acid,\n   C2H7NSO3."
            }
        ]
    },
    {
        "id": 91276,
        "spelling_name": "Tazza",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144643,
                "word_type": "n.",
                "spelling_usage": "An ornamental cup or vase with a large, flat, shallow bowl,\n   resting on a pedestal and often having handles."
            }
        ]
    },
    {
        "id": 91442,
        "spelling_name": "Teledu",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 144879,
                "word_type": "n.",
                "spelling_usage": "An East Indian carnivore (Mydaus meliceps) allied to the\n   badger, and noted for the very offensive odor that it emits, somewhat\n   resembling that of a skunk. It is a native of the high mountains of\n   Java and Sumatra, and has long, silky fur. Called also stinking badger,\n   and stinkard."
            }
        ]
    },
    {
        "id": 91676,
        "spelling_name": "Tenon",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 145255,
                "word_type": "n.",
                "spelling_usage": "A projecting member left by cutting away the wood around it,\n   and made to insert into a mortise, and in this way secure together the\n   parts of a frame; especially, such a member when it passes entirely\n   through the thickness of the piece in which the mortise is cut, and\n   shows on the other side. Cf. Tooth, Tusk."
            },
            {
                "spelling_detail_id": 145256,
                "word_type": "v. t.",
                "spelling_usage": "To cut or fit for insertion into a mortise, as the end of\n   a piece of timber."
            }
        ]
    },
    {
        "id": 92153,
        "spelling_name": "Teutonic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 145946,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Teutons, esp. the ancient\n   Teutons; Germanic."
            },
            {
                "spelling_detail_id": 145947,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to any of the Teutonic languages, or the\n   peoples who speak these languages."
            },
            {
                "spelling_detail_id": 145948,
                "word_type": "n.",
                "spelling_usage": "The language of the ancient Germans; the Teutonic\n   languages, collectively."
            }
        ]
    },
    {
        "id": 92196,
        "spelling_name": "Thalamus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 146013,
                "word_type": "n.",
                "spelling_usage": "A mass of nervous matter on either side of the third\n   ventricle of the brain; -- called also optic thalamus."
            },
            {
                "spelling_detail_id": 146014,
                "word_type": "n.",
                "spelling_usage": "Same as Thallus."
            },
            {
                "spelling_detail_id": 146015,
                "word_type": "n.",
                "spelling_usage": "The receptacle of a flower; a torus."
            }
        ]
    },
    {
        "id": 92198,
        "spelling_name": "Thalassic",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 146017,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the sea; -- sometimes applied to\n   rocks formed from sediments deposited upon the sea bottom."
            }
        ]
    },
    {
        "id": 92252,
        "spelling_name": "Thaumaturge",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 146095,
                "word_type": "n.",
                "spelling_usage": "A magician; a wonder worker."
            }
        ]
    },
    {
        "id": 92288,
        "spelling_name": "Theca",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 146144,
                "word_type": "n.",
                "spelling_usage": "A sheath; a case; as, the theca, or cell, of an anther; the\n   theca, or spore case, of a fungus; the theca of the spinal cord."
            },
            {
                "spelling_detail_id": 146145,
                "word_type": "n.",
                "spelling_usage": "The chitinous cup which protects the hydranths of certain\n   hydroids."
            },
            {
                "spelling_detail_id": 146146,
                "word_type": "n.",
                "spelling_usage": "The more or less cuplike calicle of a coral."
            },
            {
                "spelling_detail_id": 146147,
                "word_type": "n.",
                "spelling_usage": "The wall forming a calicle of a coral."
            }
        ]
    },
    {
        "id": 92786,
        "spelling_name": "Threnody",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 146896,
                "word_type": "n.",
                "spelling_usage": "A song of lamentation; a threnode."
            }
        ]
    },
    {
        "id": 92966,
        "spelling_name": "Thylacine",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 147249,
                "word_type": "n.",
                "spelling_usage": "The zebra wolf. See under Wolf."
            }
        ]
    },
    {
        "id": 93079,
        "spelling_name": "Tiffin",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 147454,
                "word_type": "n.",
                "spelling_usage": "A lunch, or slight repast between breakfast and dinner; --\n   originally, a Provincial English word, but introduced into India, and\n   brought back to England in a special sense."
            }
        ]
    },
    {
        "id": 93253,
        "spelling_name": "Tinnient",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 147785,
                "word_type": "a.",
                "spelling_usage": "Emitting a clear sound."
            }
        ]
    },
    {
        "id": 93401,
        "spelling_name": "Tmesis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 148028,
                "word_type": "n.",
                "spelling_usage": "The separation of the parts of a compound word by the\n   intervention of one or more words; as, in what place soever, for\n   whatsoever place."
            }
        ]
    },
    {
        "id": 93445,
        "spelling_name": "Toccata",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 148092,
                "word_type": "n.",
                "spelling_usage": "An old form of piece for the organ or harpsichord,\n   somewhat in the free and brilliant style of the prelude, fantasia, or\n   capriccio."
            }
        ]
    },
    {
        "id": 93709,
        "spelling_name": "Topazolite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 148529,
                "word_type": "n.",
                "spelling_usage": "A topaz-yellow variety of garnet."
            }
        ]
    },
    {
        "id": 93758,
        "spelling_name": "Toque",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 148621,
                "word_type": "n.",
                "spelling_usage": "A kind of cap worn in the 16th century, and copied in modern\n   fashions; -- called also toquet."
            },
            {
                "spelling_detail_id": 148622,
                "word_type": "n.",
                "spelling_usage": "A variety of the bonnet monkey."
            }
        ]
    },
    {
        "id": 93773,
        "spelling_name": "Toreador",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 148643,
                "word_type": "n.",
                "spelling_usage": "A bullfighter."
            }
        ]
    },
    {
        "id": 93808,
        "spelling_name": "Torpid",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 148698,
                "word_type": "a.",
                "spelling_usage": "Having lost motion, or the power of exertion and feeling;\n   numb; benumbed; as, a torpid limb."
            },
            {
                "spelling_detail_id": 148699,
                "word_type": "a.",
                "spelling_usage": "Dull; stupid; sluggish; inactive."
            }
        ]
    },
    {
        "id": 94003,
        "spelling_name": "Towhee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 149046,
                "word_type": "n.",
                "spelling_usage": "The chewink."
            }
        ]
    },
    {
        "id": 94218,
        "spelling_name": "Traitorous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 149421,
                "word_type": "a.",
                "spelling_usage": "Guilty of treason; treacherous; perfidious; faithless;\n   as, a traitorous officer or subject."
            },
            {
                "spelling_detail_id": 149422,
                "word_type": "a.",
                "spelling_usage": "Consisting in treason; partaking of treason; implying\n   breach of allegiance; as, a traitorous scheme."
            }
        ]
    },
    {
        "id": 94232,
        "spelling_name": "Tralatitious",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 149440,
                "word_type": "a.",
                "spelling_usage": "Passed along; handed down; transmitted."
            },
            {
                "spelling_detail_id": 149441,
                "word_type": "a.",
                "spelling_usage": "Metaphorical; figurative; not literal."
            }
        ]
    },
    {
        "id": 94392,
        "spelling_name": "Transience",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 149682,
                "word_type": "n.",
                "spelling_usage": "Alt. of Transiency"
            }
        ]
    },
    {
        "id": 94872,
        "spelling_name": "Trichinosis",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 150490,
                "word_type": "n.",
                "spelling_usage": "The disease produced by the presence of trichinae in\n   the muscles and intestinal track. It is marked by fever, muscular\n   pains, and symptoms resembling those of typhoid fever, and is\n   frequently fatal."
            }
        ]
    },
    {
        "id": 94998,
        "spelling_name": "Trigeminal",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 150654,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or designating, the fifth pair of\n   cranial nerves, which divide on each side of the head into three main\n   branches distributed to the orbits, jaws, and parts of the mouth;\n   trifacial."
            }
        ]
    },
    {
        "id": 95199,
        "spelling_name": "Triquetra",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 150958,
                "word_type": "pl. ",
                "spelling_usage": "of Triquetrum"
            }
        ]
    },
    {
        "id": 95312,
        "spelling_name": "Trochee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 151107,
                "word_type": "n.",
                "spelling_usage": "A foot of two syllables, the first long and the second\n   short, as in the Latin word ante, or the first accented and the second\n   unaccented, as in the English word motion; a choreus."
            }
        ]
    },
    {
        "id": 95422,
        "spelling_name": "Trough",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 151283,
                "word_type": "n.",
                "spelling_usage": "A long, hollow vessel, generally for holding water or other\n   liquid, especially one formed by excavating a log longitudinally on one\n   side; a long tray; also, a wooden channel for conveying water, as to a\n   mill wheel."
            },
            {
                "spelling_detail_id": 151284,
                "word_type": "n.",
                "spelling_usage": "Any channel, receptacle, or depression, of a long and\n   narrow shape; as, trough between two ridges, etc."
            }
        ]
    },
    {
        "id": 95715,
        "spelling_name": "Tullibee",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 151804,
                "word_type": "n.",
                "spelling_usage": "A whitefish (Coregonus tullibee) found in the Great Lakes\n   of North America; -- called also mongrel whitefish."
            }
        ]
    },
    {
        "id": 95757,
        "spelling_name": "Tumulus",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 151876,
                "word_type": "n.",
                "spelling_usage": "An artificial hillock, especially one raised over a grave,\n   particularly over the graves of persons buried in ancient times; a\n   barrow."
            }
        ]
    },
    {
        "id": 95976,
        "spelling_name": "Tussock",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 152258,
                "word_type": "n.",
                "spelling_usage": "A tuft, as of grass, twigs, hair, or the like; especially,\n   a dense tuft or bunch of grass or sedge."
            },
            {
                "spelling_detail_id": 152259,
                "word_type": "n.",
                "spelling_usage": "Same as Tussock grass, below."
            },
            {
                "spelling_detail_id": 152260,
                "word_type": "n.",
                "spelling_usage": "A caterpillar of any one of numerous species of bombycid\n   moths. The body of these caterpillars is covered with hairs which form\n   long tufts or brushes. Some species are very injurious to shade and\n   fruit trees. Called also tussock caterpillar. See Orgyia."
            }
        ]
    },
    {
        "id": 96304,
        "spelling_name": "Ullage",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 152804,
                "word_type": "n.",
                "spelling_usage": "The amount which a vessel, as a cask, of liquor lacks of\n   being full; wantage; deficiency."
            }
        ]
    },
    {
        "id": 96357,
        "spelling_name": "Ululate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 152872,
                "word_type": "v. i.",
                "spelling_usage": "To howl, as a dog or a wolf; to wail; as, ululating\n   jackals."
            }
        ]
    },
    {
        "id": 97354,
        "spelling_name": "Unguiculate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 154235,
                "word_type": "n.",
                "spelling_usage": "One of the Unguiculata."
            },
            {
                "spelling_detail_id": 154236,
                "word_type": "a.",
                "spelling_usage": "Alt. of Unguiculated"
            }
        ]
    },
    {
        "id": 98272,
        "spelling_name": "Unwonted",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 155439,
                "word_type": "a.",
                "spelling_usage": "Not wonted; unaccustomed; unused; not made familiar by\n   practice; as, a child unwonted to strangers."
            },
            {
                "spelling_detail_id": 155440,
                "word_type": "a.",
                "spelling_usage": "Uncommon; unusual; infrequent; rare; as, unwonted\n   changes."
            }
        ]
    },
    {
        "id": 98607,
        "spelling_name": "Urticaria",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 155895,
                "word_type": "n.",
                "spelling_usage": "The nettle rash, a disease characterized by a transient\n   eruption of red pimples and of wheals, accompanied with a burning or\n   stinging sensation and with itching; uredo."
            }
        ]
    },
    {
        "id": 98643,
        "spelling_name": "Ustion",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 155956,
                "word_type": "n.",
                "spelling_usage": "The act of burning, or the state of being burned."
            }
        ]
    },
    {
        "id": 98748,
        "spelling_name": "Vaccary",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 156118,
                "word_type": "n.",
                "spelling_usage": "A cow house, dairy house, or cow pasture."
            }
        ]
    },
    {
        "id": 98873,
        "spelling_name": "Valetudinary",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 156289,
                "word_type": "a.",
                "spelling_usage": "Infirm; sickly; valetudinarian."
            },
            {
                "spelling_detail_id": 156290,
                "word_type": "n.",
                "spelling_usage": "A valetudinarian."
            }
        ]
    },
    {
        "id": 99064,
        "spelling_name": "Variscite",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 156599,
                "word_type": "n.",
                "spelling_usage": "An apple-green mineral occurring in reniform masses. It\n   is a hydrous phosphate of alumina."
            }
        ]
    },
    {
        "id": 99290,
        "spelling_name": "Venenate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 156957,
                "word_type": "v. t.",
                "spelling_usage": "To poison; to infect with poison."
            },
            {
                "spelling_detail_id": 156958,
                "word_type": "a.",
                "spelling_usage": "Poisoned."
            }
        ]
    },
    {
        "id": 99433,
        "spelling_name": "Verdure",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157190,
                "word_type": "n.",
                "spelling_usage": "Green; greenness; freshness of vegetation; as, the verdure\n   of the meadows in June."
            }
        ]
    },
    {
        "id": 99451,
        "spelling_name": "Veridical",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157226,
                "word_type": "a.",
                "spelling_usage": "Truth-telling; truthful; veracious."
            }
        ]
    },
    {
        "id": 99462,
        "spelling_name": "Verisimilitude",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157242,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being verisimilar; the\n   appearance of truth; probability; likelihood."
            }
        ]
    },
    {
        "id": 99469,
        "spelling_name": "Vermeil",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157252,
                "word_type": "n.",
                "spelling_usage": "Vermilion; also, the color of vermilion, a bright,\n   beautiful red."
            },
            {
                "spelling_detail_id": 157253,
                "word_type": "n.",
                "spelling_usage": "Silver gilt or gilt bronze."
            },
            {
                "spelling_detail_id": 157254,
                "word_type": "n.",
                "spelling_usage": "A liquid composition applied to a gilded surface to give\n   luster to the gold."
            }
        ]
    },
    {
        "id": 99475,
        "spelling_name": "Vermicelli",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157261,
                "word_type": "n.",
                "spelling_usage": "The flour of a hard and small-grained wheat made into\n   dough, and forced through small cylinders or pipes till it takes a\n   slender, wormlike form, whence the Italian name. When the paste is made\n   in larger tubes, it is called macaroni."
            }
        ]
    },
    {
        "id": 99604,
        "spelling_name": "Vervain",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157446,
                "word_type": "n.",
                "spelling_usage": "Any plant of the genus Verbena."
            }
        ]
    },
    {
        "id": 99665,
        "spelling_name": "Vestigial",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157541,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a vestige or remnant; like a\n   vestige."
            }
        ]
    },
    {
        "id": 99765,
        "spelling_name": "Vicenary",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157704,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to twenty; consisting of twenty."
            }
        ]
    },
    {
        "id": 99867,
        "spelling_name": "Vilipend",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157864,
                "word_type": "v. t.",
                "spelling_usage": "To value lightly; to depreciate; to slight; to\n   despise."
            }
        ]
    },
    {
        "id": 99912,
        "spelling_name": "Vinaigrette",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 157924,
                "word_type": "n.",
                "spelling_usage": "A sauce, made of vinegar, oil, and other ingredients,\n   -- used esp. for cold meats."
            },
            {
                "spelling_detail_id": 157925,
                "word_type": "n.",
                "spelling_usage": "A small perforated box for holding aromatic vinegar\n   contained in a sponge, or a smelling bottle for smelling salts; --\n   called also vinegarette."
            },
            {
                "spelling_detail_id": 157926,
                "word_type": "n.",
                "spelling_usage": "A small, two-wheeled vehicle, like a Bath chair, to be\n   drawn or pushed by a boy or man."
            }
        ]
    },
    {
        "id": 99973,
        "spelling_name": "Violaceous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 158011,
                "word_type": "a.",
                "spelling_usage": "Resembling violets in color; bluish purple."
            },
            {
                "spelling_detail_id": 158012,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a natural order of plants, of which\n   the violet is the type. It contains about twenty genera and two hundred\n   and fifty species."
            }
        ]
    },
    {
        "id": 100014,
        "spelling_name": "Vireo",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 158075,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of American singing birds\n   belonging to Vireo and allied genera of the family Vireonidae. In many\n   of the species the back is greenish, or olive-colored. Called also\n   greenlet."
            }
        ]
    },
    {
        "id": 100154,
        "spelling_name": "Vitiate",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 158302,
                "word_type": "v. t.",
                "spelling_usage": "To make vicious, faulty, or imperfect; to render\n   defective; to injure the substance or qualities of; to impair; to\n   contaminate; to spoil; as, exaggeration vitiates a style of writing;\n   sewer gas vitiates the air."
            },
            {
                "spelling_detail_id": 158303,
                "word_type": "v. t.",
                "spelling_usage": "To cause to fail of effect, either wholly or in part;\n   to make void; to destroy, as the validity or binding force of an\n   instrument or transaction; to annul; as, any undue influence exerted on\n   a jury vitiates their verdict; fraud vitiates a contract."
            }
        ]
    },
    {
        "id": 100262,
        "spelling_name": "Vizierial",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 158428,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or issued by, a vizier."
            }
        ]
    },
    {
        "id": 100807,
        "spelling_name": "Warden",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 159408,
                "word_type": "n.",
                "spelling_usage": "A keeper; a guardian; a watchman."
            },
            {
                "spelling_detail_id": 159409,
                "word_type": "n.",
                "spelling_usage": "An officer who keeps or guards; a keeper; as, the warden of\n   a prison."
            },
            {
                "spelling_detail_id": 159410,
                "word_type": "n.",
                "spelling_usage": "A head official; as, the warden of a college; specifically\n   (Eccl.), a churchwarden."
            },
            {
                "spelling_detail_id": 159411,
                "word_type": "n.",
                "spelling_usage": "A large, hard pear, chiefly used for baking and roasting."
            }
        ]
    },
    {
        "id": 100942,
        "spelling_name": "Wassail",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 159681,
                "word_type": "n.",
                "spelling_usage": "An ancient expression of good wishes on a festive\n   occasion, especially in drinking to some one."
            },
            {
                "spelling_detail_id": 159682,
                "word_type": "n.",
                "spelling_usage": "An occasion on which such good wishes are expressed in\n   drinking; a drinking bout; a carouse."
            },
            {
                "spelling_detail_id": 159683,
                "word_type": "n.",
                "spelling_usage": "The liquor used for a wassail; esp., a beverage formerly\n   much used in England at Christmas and other festivals, made of ale (or\n   wine) flavored with spices, sugar, toast, roasted apples, etc.; --\n   called also lamb's wool."
            },
            {
                "spelling_detail_id": 159684,
                "word_type": "n.",
                "spelling_usage": "A festive or drinking song or glee."
            },
            {
                "spelling_detail_id": 159685,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to wassail, or to a wassail; convivial;\n   as, a wassail bowl."
            },
            {
                "spelling_detail_id": 159686,
                "word_type": "v. i.",
                "spelling_usage": "To hold a wassail; to carouse."
            }
        ]
    },
    {
        "id": 100956,
        "spelling_name": "Wasteweir",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 159719,
                "word_type": "n.",
                "spelling_usage": "An overfall, or weir, for the escape, or overflow, of\n   superfluous water from a canal, reservoir, pond, or the like."
            }
        ]
    },
    {
        "id": 101506,
        "spelling_name": "Weka",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 160651,
                "word_type": "n.",
                "spelling_usage": "A New Zealand rail (Ocydromus australis) which has wings so\n   short as to be incapable of flight."
            }
        ]
    },
    {
        "id": 102862,
        "spelling_name": "Wrath",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 163071,
                "word_type": "a.",
                "spelling_usage": "Violent anger; vehement exasperation; indignation; rage;\n   fury; ire."
            },
            {
                "spelling_detail_id": 163072,
                "word_type": "a.",
                "spelling_usage": "The effects of anger or indignation; the just punishment of\n   an offense or a crime."
            },
            {
                "spelling_detail_id": 163073,
                "word_type": "a.",
                "spelling_usage": "See Wroth."
            },
            {
                "spelling_detail_id": 163074,
                "word_type": "v. t.",
                "spelling_usage": "To anger; to enrage; -- also used impersonally."
            }
        ]
    },
    {
        "id": 103076,
        "spelling_name": "Xiphias",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 163416,
                "word_type": "n.",
                "spelling_usage": "A genus of fishes comprising the common swordfish."
            },
            {
                "spelling_detail_id": 163417,
                "word_type": "n.",
                "spelling_usage": "The constellation Dorado."
            },
            {
                "spelling_detail_id": 163418,
                "word_type": "n.",
                "spelling_usage": "A comet shaped like a sword"
            }
        ]
    },
    {
        "id": 103445,
        "spelling_name": "Yttriferous",
        "image_path": null,
        "bee_type": 3,
        "spellingDetails": [
            {
                "spelling_detail_id": 163948,
                "word_type": "a.",
                "spelling_usage": "Bearing or containing yttrium or the allied elements;\n   as, gadolinite is one of the yttriferous minerals."
            }
        ]
    }
]