import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge,OverlayTrigger, Container, Row, Col, Card} from 'react-bootstrap';
import { BeeList1 } from "./BeeList1";

class SpellingComponent extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            spellings:[]
        }
    }

    componentDidMount(){
    }

    render(){
        return (
            <div>
                <br/>
                <Container>

                <Card
                    border="info" 
                    style={{ width: '18rem' },{padding: 16},{ height: '3rem' }}
                    className="mb-2"
                    bg={"info"}
                    text={"info" === 'light' ? 'dark' : 'white'}
                >
                    <Card.Body>
                    <Card.Title style={{ align: 'center' }} >2021 Spelling Bee One Word List</Card.Title>
                    </Card.Body>
                </Card>

                <Row>

                {
                
                BeeList1.map(
                    (spelling,index) =>
                <Col xs="3">
                    <OverlayTrigger trigger={['hover', 'focus']} key={spelling.spelling_name}  placement="right" overlay={ 
                        <Card id={spelling.spelling_name} style={{ width: '21rem' }}
                        bg={"info"}
                        text={"info" === 'light' ? 'dark' : 'white'}
                        >
                        <Card.Title>{spelling.spelling_name}</Card.Title>
                        <Card.Img variant="top" src={spelling.spelling_name.toLowerCase()+'.png'} />
                        <Card.Body>
                            <Card.Text>
                                {  
                                    spelling.spellingDetails.map(
                                        detail =>
                                        <li key={detail.id} ><b>({detail.word_type})-{detail.spelling_usage}</b></li>
                                    )
                                }
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    }>
                        <Badge pill variant="light"
                        onClick={() => speakTheWord(spelling.spelling_name)}
                        key={spelling.spelling_name}
                        >
                            <h6>{spelling.spelling_name}</h6>
                        </Badge>
                        {/* <Speech text="Welcome to react speech" /> */}
                    </OverlayTrigger>
                    </Col>
                    )
                  }
                    </Row>
                </Container>
            </div>
        )
    }
}

const speakTheWord = word => {

    if ( 'speechSynthesis' in window ) {
        var to_speak = new SpeechSynthesisUtterance(word);
            window.speechSynthesis.speak(to_speak);
    }
    // const voices = window.speechSynthesis.getVoices();
    // msg.voice = voices[0];
    // msg.text = word;
    // speechSynthesis.speak(msg);
  };

export default SpellingComponent