import './App.css';
import SpellingComponent from './components/SpellingComponent';
import SpellingComponent2 from './components/SpellingComponent2';
import SpellingComponent3 from './components/SpellingComponent3';
import GreComponent from './components/GreComponent';
import HomeComponent from './components/HomeComponent';
import ContactComponent from './components/ContactComponent';

// import Navbar1 from './components/Navbar/Navbar';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Contact from './Contact';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
// import { Nav } from 'react-bootstrap';

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar1/> */}

        <Navbar collapseOnSelect bg="info" expand="lg">
          <Navbar.Brand href="/home"><img variant="top" src='/spellchamps-logo3.png' width="126" height="126" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            
            <Nav className="mr-auto">   
              <Nav.Link href="/home">Home</Nav.Link>           
              <NavDropdown title="Spelling Bee" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/bee1">Bee One</NavDropdown.Item>
                <NavDropdown.Item href="/bee2">Bee Two</NavDropdown.Item>
                <NavDropdown.Item href="/bee3">Bee Three</NavDropdown.Item> 
              </NavDropdown>
              <Nav.Link href="/gre">GRE Vocabulary</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>

          </Navbar.Collapse>
        </Navbar>

        <Switch>
            <Route path="/" exact component={HomeComponent}/>
            <Route path="/home" exact component={HomeComponent}/>
            <Route path="/bee1" component={SpellingComponent}/>
            <Route path="/bee2" component={SpellingComponent2}/>
            <Route path="/bee3" component={SpellingComponent3}/>
            <Route path="/gre" component={GreComponent}/>
            <Route path="/contact" exact component={ContactComponent}/>
        </Switch>

      </div>
    </Router>

  );
}

const Home = () =>(
  <div>
    <h1>Home page</h1>
  </div>
);

export default App;
