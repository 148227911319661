import './App.css';

function Contact() {
  return (
    <div>
       <h1>Contact Us:</h1>
    </div>
  );
}

export default Contact;
