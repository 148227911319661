import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge,OverlayTrigger, Container, Row, Col, Card} from 'react-bootstrap';

import { BeeList2 } from "./BeeList2";

class SpellingComponent2 extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            spellings:[]
        }
    }

    componentDidMount(){
        // SpellingService.getSpellings().then((response)=>{
        //     this.setState({spellings: response.data})
        // });
    }

    render(){
        return (
            <div>
                <br/>
                <Container>

                <Card
                    border="Primary"
                    style={{ width: '18rem' },{padding: 16},{margin: 16},{ height: '3rem' }}
                    className="mb-2"
                    bg={"Info".toLowerCase()}
                    text={"Info".toLowerCase() === 'light' ? 'dark' : 'white'}
                >
                    <Card.Body>
                    <Card.Title>2021 Spelling Bee Two Word List</Card.Title>
                    </Card.Body>
                </Card>

                <Row>

                {
                
                BeeList2.map(
                    (spelling,index) =>

                //     <OverlayTrigger
                //     trigger="hover"
                //     key={spelling.spelling_name}
                //     placement={spelling.spelling_name}
                //     overlay={
                //       <Popover id={`popover-positioned-${spelling.spelling_name}`}>
                //         <Popover.Title as="h3">{`Popover ${spelling.spelling_name}`}</Popover.Title>
                //         <Popover.Content>
                //           <strong>Holy guacamole!</strong> Check this info.
                //         </Popover.Content>
                //       </Popover>
                //     }
                //   >
                //     <Button variant="secondary">Popover on {spelling.spelling_name}</Button>
                //   </OverlayTrigger>
                <Col xs="3">
                    <OverlayTrigger trigger={['hover', 'focus']} key={spelling.spelling_name}  placement="bottom" overlay={
                        <Card id={spelling.spelling_name} style={{ width: '21rem' }}
                        bg={"Info".toLowerCase()}
                        text={"Info".toLowerCase() === 'light' ? 'dark' : 'white'}
                        >
                        <Card.Title>{spelling.spelling_name}</Card.Title>
                        <Card.Img variant="top" src={spelling.spelling_name.toLowerCase()+'.png'} />
                        <Card.Body>
                            <Card.Text>
                                {  
                                    spelling.spellingDetails.map(
                                        detail =>
                                        <li key={detail.id} ><b>({detail.word_type})-{detail.spelling_usage}</b></li>
                                    )
                                    
                                }
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    }>


                        {/* <Card
                            border="success"
                            onClick={() => speakTheWord(spelling.spelling_name)}
                            key={index}
                            style={{ width: '18rem' },{padding: 16},{ height: '3rem' }}
                            className="mb-2"
                        >
                            <Card.Body>
                            <Card.Title>{spelling.spelling_name}</Card.Title>
                            </Card.Body>
                        </Card> */}
                        <Badge pill variant="light"
                        onClick={() => speakTheWord(spelling.spelling_name)}
                        key={spelling.spelling_name}
                        >
                            <h6>{spelling.spelling_name}</h6>
                        </Badge>
                    </OverlayTrigger>
                    </Col>
                )
                /* <table className="table table-striped">
                    <thead>
                        <tr>
                            <td> Spelling Name</td>
                            <td> Details</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.spellings.map(
                                spelling =>
                                <tr key = {spelling.id}>
                                    <td>{spelling.spelling_name}</td>
                                    <td>
                                        {
                                           spelling.spellingDetails.map(
                                                detail =>
                                                <li key={detail.id}><b>({detail.word_type})</b>-{detail.spelling_usage}</li>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table> */}
                    </Row>
                </Container>
            </div>
        )
    }
}

const speakTheWord = word => {
    if ( 'speechSynthesis' in window ) {
        var to_speak = new SpeechSynthesisUtterance(word);
            window.speechSynthesis.speak(to_speak);
    }
  };

export default SpellingComponent2