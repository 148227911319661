export const BeeList2 =[
    {
        "id": 254,
        "spelling_name": "Backgammon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 185,
                "word_type": "n.",
                "spelling_usage": "A game of chance and skill, played by two persons on a\n   \"board\" marked off into twenty-four spaces called \"points\". Each player\n   has fifteen pieces, or \"men\", the movements of which from point to\n   point are determined by throwing dice. Formerly called tables."
            },
            {
                "spelling_detail_id": 186,
                "word_type": "v. i.",
                "spelling_usage": "In the game of backgammon, to beat by ending the\n   game before the loser is clear of his first \"table\"."
            }
        ]
    },
    {
        "id": 462,
        "spelling_name": "Baleen",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 559,
                "word_type": "n.",
                "spelling_usage": "Plates or blades of \"whalebone,\" from two to twelve feet\n   long, and sometimes a foot wide, which in certain whales (Balaenoidea)\n   are attached side by side along the upper jaw, and form a fringelike\n   sieve by which the food is retained in the mouth."
            }
        ]
    },
    {
        "id": 544,
        "spelling_name": "Baltic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 705,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the sea which separates Norway and\n   Sweden from Jutland, Denmark, and Germany; situated on the Baltic Sea."
            }
        ]
    },
    {
        "id": 559,
        "spelling_name": "Banal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 734,
                "word_type": "a.",
                "spelling_usage": "Commonplace; trivial; hackneyed; trite."
            }
        ]
    },
    {
        "id": 582,
        "spelling_name": "Bandicoot",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 783,
                "word_type": "n.",
                "spelling_usage": "A species of very large rat (Mus giganteus), found in\n   India and Ceylon. It does much injury to rice fields and gardens."
            },
            {
                "spelling_detail_id": 784,
                "word_type": "n.",
                "spelling_usage": "A ratlike marsupial animal (genus Perameles) of several\n   species, found in Australia and Tasmania."
            }
        ]
    },
    {
        "id": 646,
        "spelling_name": "Banquet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 915,
                "word_type": "n.",
                "spelling_usage": "A feast; a sumptuous entertainment of eating and drinking;\n   often, a complimentary or ceremonious feast, followed by speeches."
            },
            {
                "spelling_detail_id": 916,
                "word_type": "n.",
                "spelling_usage": "A dessert; a course of sweetmeats; a sweetmeat or\n   sweetmeats."
            },
            {
                "spelling_detail_id": 919,
                "word_type": "v. t.",
                "spelling_usage": "To treat with a banquet or sumptuous entertainment of\n   food; to feast."
            },
            {
                "spelling_detail_id": 920,
                "word_type": "v. i.",
                "spelling_usage": "To regale one's self with good eating and drinking; to\n   feast."
            },
            {
                "spelling_detail_id": 921,
                "word_type": "v. i.",
                "spelling_usage": "To partake of a dessert after a feast."
            }
        ]
    },
    {
        "id": 917,
        "spelling_name": "Basaltic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 1369,
                "word_type": "a.",
                "spelling_usage": "Pertaining to basalt; formed of, or containing, basalt;\n   as basaltic lava."
            }
        ]
    },
    {
        "id": 1294,
        "spelling_name": "Beatific",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 2192,
                "word_type": "a.",
                "spelling_usage": "Alt. of Beatifical"
            }
        ]
    },
    {
        "id": 1447,
        "spelling_name": "Bedraggled",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 2408,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Bedraggle"
            }
        ]
    },
    {
        "id": 1639,
        "spelling_name": "Behemoth",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 2681,
                "word_type": "n.",
                "spelling_usage": "An animal, probably the hippopotamus, described in Job\n   xl. 15-24."
            }
        ]
    },
    {
        "id": 1786,
        "spelling_name": "Bellwether",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 2914,
                "word_type": "n.",
                "spelling_usage": "A wether, or sheep, which leads the flock, with a bell\n   on his neck."
            },
            {
                "spelling_detail_id": 2915,
                "word_type": "n.",
                "spelling_usage": "Hence: A leader."
            }
        ]
    },
    {
        "id": 1896,
        "spelling_name": "Beneficent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3125,
                "word_type": "a.",
                "spelling_usage": "Doing or producing good; performing acts of kindness\n   and charity; characterized by beneficence."
            }
        ]
    },
    {
        "id": 2006,
        "spelling_name": "Bereavement",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3287,
                "word_type": "n.",
                "spelling_usage": "The state of being bereaved; deprivation; esp., the\n   loss of a relative by death."
            }
        ]
    },
    {
        "id": 2012,
        "spelling_name": "Bergamot",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3292,
                "word_type": "n.",
                "spelling_usage": "A tree of the Orange family (Citrus bergamia), having a\n   roundish or pear-shaped fruit, from the rind of which an essential oil\n   of delicious odor is extracted, much prized as a perfume. Also, the\n   fruit."
            },
            {
                "spelling_detail_id": 3293,
                "word_type": "n.",
                "spelling_usage": "A variety of mint (Mentha aquatica, var. glabrata)."
            },
            {
                "spelling_detail_id": 3294,
                "word_type": "n.",
                "spelling_usage": "The essence or perfume made from the fruit."
            },
            {
                "spelling_detail_id": 3295,
                "word_type": "n.",
                "spelling_usage": "A variety of pear."
            },
            {
                "spelling_detail_id": 3296,
                "word_type": "n.",
                "spelling_usage": "A variety of snuff perfumed with bergamot."
            },
            {
                "spelling_detail_id": 3297,
                "word_type": "n.",
                "spelling_usage": "A coarse tapestry, manufactured from flock of cotton or\n   hemp, mixed with ox's or goat's hair; -- said to have been invented at\n   Bergamo, Italy. Encyc. Brit."
            }
        ]
    },
    {
        "id": 2044,
        "spelling_name": "Berserk",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3340,
                "word_type": "n.",
                "spelling_usage": "Alt. of Berserker"
            }
        ]
    },
    {
        "id": 2047,
        "spelling_name": "Berth",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3344,
                "word_type": "n.",
                "spelling_usage": "Convenient sea room."
            },
            {
                "spelling_detail_id": 3345,
                "word_type": "n.",
                "spelling_usage": "A room in which a number of the officers or ship's company\n   mess and reside."
            },
            {
                "spelling_detail_id": 3346,
                "word_type": "n.",
                "spelling_usage": "The place where a ship lies when she is at anchor, or at a\n   wharf."
            },
            {
                "spelling_detail_id": 3347,
                "word_type": "n.",
                "spelling_usage": "An allotted place; an appointment; situation or employment."
            },
            {
                "spelling_detail_id": 3348,
                "word_type": "n.",
                "spelling_usage": "A place in a ship to sleep in; a long box or shelf on the\n   side of a cabin or stateroom, or of a railway car, for sleeping in."
            },
            {
                "spelling_detail_id": 3351,
                "word_type": "v. t.",
                "spelling_usage": "To give an anchorage to, or a place to lie at; to place\n   in a berth; as, she was berthed stem to stern with the Adelaide."
            },
            {
                "spelling_detail_id": 3352,
                "word_type": "v. t.",
                "spelling_usage": "To allot or furnish berths to, on shipboard; as, to berth\n   a ship's company."
            }
        ]
    },
    {
        "id": 2098,
        "spelling_name": "Besieged",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3422,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Besiege"
            }
        ]
    },
    {
        "id": 2115,
        "spelling_name": "Besmirch",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3443,
                "word_type": "v. t.",
                "spelling_usage": "To smirch or soil; to discolor; to obscure. Hence: To\n   dishonor; to sully."
            }
        ]
    },
    {
        "id": 2449,
        "spelling_name": "Bicameral",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 3928,
                "word_type": "a.",
                "spelling_usage": "Consisting of, or including, two chambers, or\n   legislative branches."
            }
        ]
    },
    {
        "id": 2662,
        "spelling_name": "Billiards",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4252,
                "word_type": "n.",
                "spelling_usage": "A game played with ivory balls o a cloth-covered,\n   rectangular table, bounded by elastic cushions. The player seeks to\n   impel his ball with his cue so that it shall either strike (carom upon)\n   two other balls, or drive another ball into one of the pockets with\n   which the table sometimes is furnished."
            }
        ]
    },
    {
        "id": 2670,
        "spelling_name": "Billow",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4261,
                "word_type": "n.",
                "spelling_usage": "A great wave or surge of the sea or other water, caused\n   usually by violent wind."
            },
            {
                "spelling_detail_id": 4262,
                "word_type": "n.",
                "spelling_usage": "A great wave or flood of anything."
            },
            {
                "spelling_detail_id": 4265,
                "word_type": "v. i.",
                "spelling_usage": "To surge; to rise and roll in waves or surges; to\n   undulate."
            }
        ]
    },
    {
        "id": 2703,
        "spelling_name": "Binary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4303,
                "word_type": "a.",
                "spelling_usage": "Compounded or consisting of two things or parts;\n   characterized by two (things)."
            },
            {
                "spelling_detail_id": 4304,
                "word_type": "n.",
                "spelling_usage": "That which is constituted of two figures, things, or parts;\n   two; duality."
            }
        ]
    },
    {
        "id": 2727,
        "spelling_name": "Binomial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4355,
                "word_type": "n.",
                "spelling_usage": "An expression consisting of two terms connected by the\n   sign plus (+) or minus (-); as, a + b, or 7 - 3."
            },
            {
                "spelling_detail_id": 4356,
                "word_type": "a.",
                "spelling_usage": "Consisting of two terms; pertaining to binomials; as, a\n   binomial root."
            },
            {
                "spelling_detail_id": 4357,
                "word_type": "a.",
                "spelling_usage": "Having two names; -- used of the system by which every\n   animal and plant receives two names, the one indicating the genus, the\n   other the species, to which it belongs."
            }
        ]
    },
    {
        "id": 2884,
        "spelling_name": "Biscuit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4554,
                "word_type": "n.",
                "spelling_usage": "A kind of unraised bread, of many varieties, plain, sweet,\n   or fancy, formed into flat cakes, and bakes hard; as, ship biscuit."
            },
            {
                "spelling_detail_id": 4555,
                "word_type": "n.",
                "spelling_usage": "A small loaf or cake of bread, raised and shortened, or\n   made light with soda or baking powder. Usually a number are baked in\n   the same pan, forming a sheet or card."
            },
            {
                "spelling_detail_id": 4556,
                "word_type": "n.",
                "spelling_usage": "Earthen ware or porcelain which has undergone the first\n   baking, before it is subjected to the glazing."
            },
            {
                "spelling_detail_id": 4557,
                "word_type": "n.",
                "spelling_usage": "A species of white, unglazed porcelain, in which vases,\n   figures, and groups are formed in miniature."
            }
        ]
    },
    {
        "id": 2933,
        "spelling_name": "Bison",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4624,
                "word_type": "n.",
                "spelling_usage": "The aurochs or European bison."
            },
            {
                "spelling_detail_id": 4625,
                "word_type": "n.",
                "spelling_usage": "The American bison buffalo (Bison Americanus), a large,\n   gregarious bovine quadruped with shaggy mane and short black horns,\n   which formerly roamed in herds over most of the temperate portion of\n   North America, but is now restricted to very limited districts in the\n   region of the Rocky Mountains, and is rapidly decreasing in numbers."
            }
        ]
    },
    {
        "id": 2976,
        "spelling_name": "Bittern",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 4716,
                "word_type": "n.",
                "spelling_usage": "A wading bird of the genus Botaurus, allied to the herons,\n   of various species."
            },
            {
                "spelling_detail_id": 4717,
                "word_type": "a.",
                "spelling_usage": "The brine which remains in salt works after the salt is\n   concreted, having a bitter taste from the chloride of magnesium which\n   it contains."
            },
            {
                "spelling_detail_id": 4718,
                "word_type": "a.",
                "spelling_usage": "A very bitter compound of quassia, cocculus Indicus, etc.,\n   used by fraudulent brewers in adulterating beer."
            }
        ]
    },
    {
        "id": 3163,
        "spelling_name": "Blarney",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 5042,
                "word_type": "n.",
                "spelling_usage": "Smooth, wheedling talk; flattery."
            },
            {
                "spelling_detail_id": 5045,
                "word_type": "v. t.",
                "spelling_usage": "To influence by blarney; to wheedle with smooth talk;\n   to make or accomplish by blarney."
            }
        ]
    },
    {
        "id": 3206,
        "spelling_name": "Blatant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 5109,
                "word_type": "a.",
                "spelling_usage": "Bellowing, as a calf; bawling; brawling; clamoring;\n   disagreeably clamorous; sounding loudly and harshly."
            }
        ]
    },
    {
        "id": 3697,
        "spelling_name": "Bohemian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6065,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Bohemia, or to the language of its\n   ancient inhabitants or their descendants. See Bohemian, n., 2."
            },
            {
                "spelling_detail_id": 6066,
                "word_type": "n.",
                "spelling_usage": "Of or pertaining to a social gypsy or \"Bohemian\" (see\n   Bohemian, n., 3); vagabond; unconventional; free and easy."
            },
            {
                "spelling_detail_id": 6067,
                "word_type": "n.",
                "spelling_usage": "A native of Bohemia."
            },
            {
                "spelling_detail_id": 6068,
                "word_type": "n.",
                "spelling_usage": "The language of the Czechs (the ancient inhabitants of\n   Bohemia), the richest and most developed of the dialects of the Slavic\n   family."
            },
            {
                "spelling_detail_id": 6069,
                "word_type": "n.",
                "spelling_usage": "A restless vagabond; -- originally, an idle stroller or\n   gypsy (as in France) thought to have come from Bohemia; in later times\n   often applied to an adventurer in art or literature, of irregular,\n   unconventional habits, questionable tastes, or free morals."
            }
        ]
    },
    {
        "id": 3781,
        "spelling_name": "Bombastic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6255,
                "word_type": "a.",
                "spelling_usage": "Alt. of Bombastical"
            }
        ]
    },
    {
        "id": 3805,
        "spelling_name": "Bona fide",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6278,
                "word_type": "",
                "spelling_usage": "In or with good faith; without fraud or deceit; real or\n   really; actual or actually; genuine or genuinely; as, you must proceed\n   bona fide; a bona fide purchaser or transaction."
            }
        ]
    },
    {
        "id": 4034,
        "spelling_name": "Borough",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6680,
                "word_type": "n.",
                "spelling_usage": "In England, an incorporated town that is not a city; also,\n   a town that sends members to parliament; in Scotland, a body corporate,\n   consisting of the inhabitants of a certain district, erected by the\n   sovereign, with a certain jurisdiction; in America, an incorporated\n   town or village, as in Pennsylvania and Connecticut."
            },
            {
                "spelling_detail_id": 6681,
                "word_type": "n.",
                "spelling_usage": "The collective body of citizens or inhabitants of a\n   borough; as, the borough voted to lay a tax."
            },
            {
                "spelling_detail_id": 6682,
                "word_type": "n.",
                "spelling_usage": "An association of men who gave pledges or sureties to the\n   king for the good behavior of each other."
            },
            {
                "spelling_detail_id": 6683,
                "word_type": "n.",
                "spelling_usage": "The pledge or surety thus given."
            }
        ]
    },
    {
        "id": 4099,
        "spelling_name": "Botany",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6785,
                "word_type": "a. & n.",
                "spelling_usage": "The science which treats of the structure of plants,\n   the functions of their parts, their places of growth, their\n   classification, and the terms which are employed in their description\n   and denomination. See Plant."
            },
            {
                "spelling_detail_id": 6786,
                "word_type": "a. & n.",
                "spelling_usage": "A book which treats of the science of botany."
            }
        ]
    },
    {
        "id": 4164,
        "spelling_name": "Bough",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6901,
                "word_type": "n.",
                "spelling_usage": "An arm or branch of a tree, esp. a large arm or main branch."
            },
            {
                "spelling_detail_id": 6902,
                "word_type": "n.",
                "spelling_usage": "A gallows."
            }
        ]
    },
    {
        "id": 4199,
        "spelling_name": "Bountiful",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6983,
                "word_type": "a.",
                "spelling_usage": "Free in giving; liberal in bestowing gifts and favors."
            },
            {
                "spelling_detail_id": 6984,
                "word_type": "a.",
                "spelling_usage": "Plentiful; abundant; as, a bountiful supply of food."
            }
        ]
    },
    {
        "id": 4203,
        "spelling_name": "Bouquet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 6992,
                "word_type": "n.",
                "spelling_usage": "A nosegay; a bunch of flowers."
            },
            {
                "spelling_detail_id": 6993,
                "word_type": "n.",
                "spelling_usage": "A perfume; an aroma; as, the bouquet of wine."
            }
        ]
    },
    {
        "id": 4290,
        "spelling_name": "Bowsprit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7157,
                "word_type": "n.",
                "spelling_usage": "A large boom or spar, which projects over the stem of a\n   ship or other vessel, to carry sail forward."
            }
        ]
    },
    {
        "id": 4298,
        "spelling_name": "Bowyer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7170,
                "word_type": "n.",
                "spelling_usage": "An archer; one who uses bow."
            },
            {
                "spelling_detail_id": 7171,
                "word_type": "n.",
                "spelling_usage": "One who makes or sells bows."
            }
        ]
    },
    {
        "id": 4342,
        "spelling_name": "Bracelet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7261,
                "word_type": "n.",
                "spelling_usage": "An ornamental band or ring, for the wrist or the arm; in\n   modern times, an ornament encircling the wrist, worn by women or girls."
            },
            {
                "spelling_detail_id": 7262,
                "word_type": "n.",
                "spelling_usage": "A piece of defensive armor for the arm."
            }
        ]
    },
    {
        "id": 4403,
        "spelling_name": "Braggart",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7348,
                "word_type": "v. i.",
                "spelling_usage": "A boaster."
            },
            {
                "spelling_detail_id": 7349,
                "word_type": "a.",
                "spelling_usage": "Boastful."
            }
        ]
    },
    {
        "id": 4505,
        "spelling_name": "Brandywine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7520,
                "word_type": "n.",
                "spelling_usage": "Brandy."
            }
        ]
    },
    {
        "id": 4542,
        "spelling_name": "Bravado",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7588,
                "word_type": "n.",
                "spelling_usage": "Boastful and threatening behavior; a boastful menace."
            }
        ]
    },
    {
        "id": 4696,
        "spelling_name": "Brethren",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 7946,
                "word_type": "n.",
                "spelling_usage": "pl. of Brother."
            },
            {
                "spelling_detail_id": 8523,
                "word_type": "pl. ",
                "spelling_usage": "of Brother"
            },
            {
                "spelling_detail_id": 8525,
                "word_type": "pl. ",
                "spelling_usage": "of Brother"
            }
        ]
    },
    {
        "id": 4774,
        "spelling_name": "Bridge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8066,
                "word_type": "n.",
                "spelling_usage": "A structure, usually of wood, stone, brick, or iron,\n   erected over a river or other water course, or over a chasm, railroad,\n   etc., to make a passageway from one bank to the other."
            },
            {
                "spelling_detail_id": 8067,
                "word_type": "n.",
                "spelling_usage": "Anything supported at the ends, which serves to keep some\n   other thing from resting upon the object spanned, as in engraving,\n   watchmaking, etc., or which forms a platform or staging over which\n   something passes or is conveyed."
            },
            {
                "spelling_detail_id": 8068,
                "word_type": "n.",
                "spelling_usage": "The small arch or bar at right angles to the strings of a\n   violin, guitar, etc., serving of raise them and transmit their\n   vibrations to the body of the instrument."
            },
            {
                "spelling_detail_id": 8069,
                "word_type": "n.",
                "spelling_usage": "A device to measure the resistance of a wire or other\n   conductor forming part of an electric circuit."
            },
            {
                "spelling_detail_id": 8070,
                "word_type": "n.",
                "spelling_usage": "A low wall or vertical partition in the fire chamber of a\n   furnace, for deflecting flame, etc.; -- usually called a bridge wall."
            },
            {
                "spelling_detail_id": 8073,
                "word_type": "v. t.",
                "spelling_usage": "To build a bridge or bridges on or over; as, to bridge a\n   river."
            },
            {
                "spelling_detail_id": 8074,
                "word_type": "v. t.",
                "spelling_usage": "To open or make a passage, as by a bridge."
            },
            {
                "spelling_detail_id": 8075,
                "word_type": "v. t.",
                "spelling_usage": "To find a way of getting over, as a difficulty; --\n   generally with over."
            }
        ]
    },
    {
        "id": 4926,
        "spelling_name": "Brochure",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8354,
                "word_type": "v. t.",
                "spelling_usage": "A printed and stitched book containing only a few\n   leaves; a pamphlet."
            }
        ]
    },
    {
        "id": 4934,
        "spelling_name": "Brogue",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8365,
                "word_type": "n.",
                "spelling_usage": "A stout, coarse shoe; a brogan."
            },
            {
                "spelling_detail_id": 8366,
                "word_type": "v. t.",
                "spelling_usage": "A dialectic pronunciation; esp. the Irish manner of\n   pronouncing English."
            }
        ]
    },
    {
        "id": 4969,
        "spelling_name": "Bromide",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8429,
                "word_type": "n.",
                "spelling_usage": "A compound of bromine with a positive radical."
            }
        ]
    },
    {
        "id": 5166,
        "spelling_name": "Bubonic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8736,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a bubo or buboes; characterized by\n   buboes."
            }
        ]
    },
    {
        "id": 5239,
        "spelling_name": "Buffet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8895,
                "word_type": "n.",
                "spelling_usage": "A cupboard or set of shelves, either movable or fixed at\n   one side of a room, for the display of plate, china, etc., a sideboard."
            },
            {
                "spelling_detail_id": 8896,
                "word_type": "n.",
                "spelling_usage": "A counter for refreshments; a restaurant at a railroad\n   station, or place of public gathering."
            },
            {
                "spelling_detail_id": 8897,
                "word_type": "v. i.",
                "spelling_usage": "A blow with the hand; a slap on the face; a cuff."
            },
            {
                "spelling_detail_id": 8898,
                "word_type": "v. i.",
                "spelling_usage": "A blow from any source, or that which affects like a\n   blow, as the violence of winds or waves; a stroke; an adverse action;\n   an affliction; a trial; adversity."
            },
            {
                "spelling_detail_id": 8899,
                "word_type": "v. i.",
                "spelling_usage": "A small stool; a stool for a buffet or counter."
            },
            {
                "spelling_detail_id": 8902,
                "word_type": "v. t.",
                "spelling_usage": "To strike with the hand or fist; to box; to beat; to\n   cuff; to slap."
            },
            {
                "spelling_detail_id": 8903,
                "word_type": "v. t.",
                "spelling_usage": "To affect as with blows; to strike repeatedly; to strive\n   with or contend against; as, to buffet the billows."
            },
            {
                "spelling_detail_id": 8904,
                "word_type": "v. t.",
                "spelling_usage": "To deaden the sound of (bells) by muffling the clapper."
            },
            {
                "spelling_detail_id": 8905,
                "word_type": "v. i.",
                "spelling_usage": "To exercise or play at boxing; to strike; to smite; to\n   strive; to contend."
            },
            {
                "spelling_detail_id": 8906,
                "word_type": "v. i.",
                "spelling_usage": "To make one's way by blows or struggling."
            }
        ]
    },
    {
        "id": 5297,
        "spelling_name": "Bulbous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 8998,
                "word_type": "n.",
                "spelling_usage": "Having or containing bulbs, or a bulb; growing from bulbs;\n   bulblike in shape or structure."
            }
        ]
    },
    {
        "id": 5378,
        "spelling_name": "Bulwark",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9137,
                "word_type": "n.",
                "spelling_usage": "A rampart; a fortification; a bastion or outwork."
            },
            {
                "spelling_detail_id": 9138,
                "word_type": "n.",
                "spelling_usage": "That which secures against an enemy, or defends from\n   attack; any means of defense or protection."
            },
            {
                "spelling_detail_id": 9139,
                "word_type": "n.",
                "spelling_usage": "The sides of a ship above the upper deck."
            },
            {
                "spelling_detail_id": 9142,
                "word_type": "v. t.",
                "spelling_usage": "To fortify with, or as with, a rampart or wall; to\n   secure by fortification; to protect."
            }
        ]
    },
    {
        "id": 5402,
        "spelling_name": "Bumptious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9177,
                "word_type": "a.",
                "spelling_usage": "Self-conceited; forward; pushing."
            }
        ]
    },
    {
        "id": 5476,
        "spelling_name": "Bureau",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9309,
                "word_type": "n.",
                "spelling_usage": "Originally, a desk or writing table with drawers for\n   papers."
            },
            {
                "spelling_detail_id": 9310,
                "word_type": "n.",
                "spelling_usage": "The place where such a bureau is used; an office where\n   business requiring writing is transacted."
            },
            {
                "spelling_detail_id": 9311,
                "word_type": "n.",
                "spelling_usage": "Hence: A department of public business requiring a force of\n   clerks; the body of officials in a department who labor under the\n   direction of a chief."
            },
            {
                "spelling_detail_id": 9312,
                "word_type": "n.",
                "spelling_usage": "A chest of drawers for clothes, especially when made as an\n   ornamental piece of furniture."
            }
        ]
    },
    {
        "id": 5509,
        "spelling_name": "Burglarious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9352,
                "word_type": "a.",
                "spelling_usage": "Pertaining to burglary; constituting the crime of\n   burglary."
            }
        ]
    },
    {
        "id": 5514,
        "spelling_name": "Burgoo",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9359,
                "word_type": "n.",
                "spelling_usage": "A kind of oatmeal pudding, or thick gruel, used by seamen."
            }
        ]
    },
    {
        "id": 5520,
        "spelling_name": "Burial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9367,
                "word_type": "n.",
                "spelling_usage": "A grave; a tomb; a place of sepulture."
            },
            {
                "spelling_detail_id": 9368,
                "word_type": "n.",
                "spelling_usage": "The act of burying; depositing a dead body in the earth, in\n   a tomb or vault, or in the water, usually with attendant ceremonies;\n   sepulture; interment."
            }
        ]
    },
    {
        "id": 5586,
        "spelling_name": "Bursary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9486,
                "word_type": "n.",
                "spelling_usage": "The treasury of a college or monastery."
            },
            {
                "spelling_detail_id": 9487,
                "word_type": "n.",
                "spelling_usage": "A scholarship or charitable foundation in a university, as\n   in Scotland; a sum given to enable a student to pursue his studies."
            }
        ]
    },
    {
        "id": 5821,
        "spelling_name": "Cabaret",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 9886,
                "word_type": "n.",
                "spelling_usage": "A tavern; a house where liquors are retailed."
            },
            {
                "spelling_detail_id": 9887,
                "word_type": "n.",
                "spelling_usage": "a type of restaurant where liquor and dinner is served,\n   and entertainment is provided, as by musicians, dancers, or comedians,\n   and providing space for dancing by the patrons; -- similar to a\n   nightclub. The term cabaret is often used in the names of such an\n   establishment."
            },
            {
                "spelling_detail_id": 9888,
                "word_type": "n.",
                "spelling_usage": "the type of entertainment provided in a cabaret{2}."
            }
        ]
    },
    {
        "id": 5934,
        "spelling_name": "Cadaverous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10040,
                "word_type": "a.",
                "spelling_usage": "Having the appearance or color of a dead human body;\n   pale; ghastly; as, a cadaverous look."
            },
            {
                "spelling_detail_id": 10041,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to, or having the qualities of, a dead\n   body."
            }
        ]
    },
    {
        "id": 5954,
        "spelling_name": "Cadge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10077,
                "word_type": "v. t. & i.",
                "spelling_usage": "To carry, as a burden."
            },
            {
                "spelling_detail_id": 10078,
                "word_type": "v. t. & i.",
                "spelling_usage": "To hawk or peddle, as fish, poultry, etc."
            },
            {
                "spelling_detail_id": 10079,
                "word_type": "v. t. & i.",
                "spelling_usage": "To intrude or live on another meanly; to beg."
            },
            {
                "spelling_detail_id": 10080,
                "word_type": "n.",
                "spelling_usage": "A circular frame on which cadgers carry hawks for sale."
            }
        ]
    },
    {
        "id": 6002,
        "spelling_name": "Caffeine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10132,
                "word_type": "n.",
                "spelling_usage": "A white, bitter, crystallizable substance, obtained from\n   coffee. It is identical with the alkaloid theine from tea leaves, and\n   with guaranine from guarana."
            }
        ]
    },
    {
        "id": 6047,
        "spelling_name": "Calabash",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10205,
                "word_type": "n.",
                "spelling_usage": "The common gourd (plant or fruit)."
            },
            {
                "spelling_detail_id": 10206,
                "word_type": "n.",
                "spelling_usage": "The fruit of the calabash tree."
            },
            {
                "spelling_detail_id": 10207,
                "word_type": "n.",
                "spelling_usage": "A water dipper, bottle, bascket, or other utensil, made\n   from the dry shell of a calabash or gourd."
            }
        ]
    },
    {
        "id": 6141,
        "spelling_name": "Calculator",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10332,
                "word_type": "n.",
                "spelling_usage": "One who computes or reckons: one who estimates or\n   considers the force and effect of causes, with a view to form a correct\n   estimate of the effects."
            }
        ]
    },
    {
        "id": 6161,
        "spelling_name": "Calendar",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10362,
                "word_type": "n.",
                "spelling_usage": "An orderly arrangement of the division of time, adapted\n   to the purposes of civil life, as years, months, weeks, and days; also,\n   a register of the year with its divisions; an almanac."
            },
            {
                "spelling_detail_id": 10363,
                "word_type": "n.",
                "spelling_usage": "A tabular statement of the dates of feasts, offices,\n   saints' days, etc., esp. of those which are liable to change yearly\n   according to the varying date of Easter."
            },
            {
                "spelling_detail_id": 10364,
                "word_type": "n.",
                "spelling_usage": "An orderly list or enumeration of persons, things, or\n   events; a schedule; as, a calendar of state papers; a calendar of bills\n   presented in a legislative assembly; a calendar of causes arranged for\n   trial in court; a calendar of a college or an academy."
            },
            {
                "spelling_detail_id": 10367,
                "word_type": "v. t.",
                "spelling_usage": "To enter or write in a calendar; to register."
            }
        ]
    },
    {
        "id": 6182,
        "spelling_name": "Calibrate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10399,
                "word_type": "v. i.",
                "spelling_usage": "To ascertain the caliber of, as of a thermometer\n   tube; also, more generally, to determine or rectify the graduation of,\n   as of the various standards or graduated instruments."
            }
        ]
    },
    {
        "id": 6213,
        "spelling_name": "Calisthenics",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10434,
                "word_type": "n.",
                "spelling_usage": "The science, art, or practice of healthful exercise\n   of the body and limbs, to promote strength and gracefulness; light\n   gymnastics."
            }
        ]
    },
    {
        "id": 6254,
        "spelling_name": "Callow",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10521,
                "word_type": "a.",
                "spelling_usage": "Destitute of feathers; naked; unfledged."
            },
            {
                "spelling_detail_id": 10522,
                "word_type": "a.",
                "spelling_usage": "Immature; boyish; \"green\"; as, a callow youth."
            },
            {
                "spelling_detail_id": 10523,
                "word_type": "n.",
                "spelling_usage": "A kind of duck. See Old squaw."
            }
        ]
    },
    {
        "id": 6411,
        "spelling_name": "Campanology",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10741,
                "word_type": "n.",
                "spelling_usage": "The art of ringing bells, or a treatise on the art."
            }
        ]
    },
    {
        "id": 6447,
        "spelling_name": "Canada",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10790,
                "word_type": "n.",
                "spelling_usage": "A British province in North America, giving its name to\n   various plants and animals."
            }
        ]
    },
    {
        "id": 6489,
        "spelling_name": "Candelabrum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 10860,
                "word_type": "n.",
                "spelling_usage": "A lamp stand of any sort."
            },
            {
                "spelling_detail_id": 10861,
                "word_type": "n.",
                "spelling_usage": "A highly ornamented stand of marble or other ponderous\n   material, usually having three feet, -- frequently a votive offering to\n   a temple."
            },
            {
                "spelling_detail_id": 10862,
                "word_type": "n.",
                "spelling_usage": "A large candlestick, having several branches."
            }
        ]
    },
    {
        "id": 6622,
        "spelling_name": "Cantankerous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11090,
                "word_type": "a.",
                "spelling_usage": "Perverse; contentious; ugly; malicious."
            }
        ]
    },
    {
        "id": 6708,
        "spelling_name": "Capacity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11241,
                "word_type": "n.",
                "spelling_usage": "The power of receiving or containing; extent of room or\n   space; passive power; -- used in reference to physical things."
            },
            {
                "spelling_detail_id": 11242,
                "word_type": "n.",
                "spelling_usage": "The power of receiving and holding ideas, knowledge,\n   etc.; the comprehensiveness of the mind; the receptive faculty;\n   capability of undestanding or feeling."
            },
            {
                "spelling_detail_id": 11243,
                "word_type": "n.",
                "spelling_usage": "Ability; power pertaining to, or resulting from, the\n   possession of strength, wealth, or talent; possibility of being or of\n   doing."
            },
            {
                "spelling_detail_id": 11244,
                "word_type": "n.",
                "spelling_usage": "Outward condition or circumstances; occupation;\n   profession; character; position; as, to work in the capacity of a mason\n   or a carpenter."
            },
            {
                "spelling_detail_id": 11245,
                "word_type": "n.",
                "spelling_usage": "Legal or noral qualification, as of age, residence,\n   character, etc., necessary for certain purposes, as for holding office,\n   for marrying, for making contracts, will, etc.; legal power or right;\n   competency."
            }
        ]
    },
    {
        "id": 6743,
        "spelling_name": "Capillary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11297,
                "word_type": "a.",
                "spelling_usage": "Resembling a hair; fine; minute; very slender; having\n   minute tubes or interspaces; having very small bore; as, the capillary\n   vessels of animals and plants."
            },
            {
                "spelling_detail_id": 11298,
                "word_type": "a.",
                "spelling_usage": "Pertaining to capillary tubes or vessels; as, capillary\n   action."
            },
            {
                "spelling_detail_id": 11299,
                "word_type": "n.",
                "spelling_usage": "A tube or vessel, extremely fine or minute."
            },
            {
                "spelling_detail_id": 11300,
                "word_type": "n.",
                "spelling_usage": "A minute, thin-walled vessel; particularly one of the\n   smallest blood vessels connecting arteries and veins, but used also for\n   the smallest lymphatic and biliary vessels."
            }
        ]
    },
    {
        "id": 6838,
        "spelling_name": "Capstan",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11436,
                "word_type": "n.",
                "spelling_usage": "A vertical cleated drum or cylinder, revolving on an\n   upright spindle, and surmounted by a drumhead with sockets for bars or\n   levers. It is much used, especially on shipboard, for moving or raising\n   heavy weights or exerting great power by traction upon a rope or cable,\n   passing around the drum. It is operated either by steam power or by a\n   number of men walking around the capstan, each pushing on the end of a\n   lever fixed in its socket."
            }
        ]
    },
    {
        "id": 6844,
        "spelling_name": "Capsule",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11442,
                "word_type": "n.",
                "spelling_usage": "a dry fruit or pod which is made up of several parts or\n   carpels, and opens to discharge the seeds, as, the capsule of the\n   poppy, the flax, the lily, etc."
            },
            {
                "spelling_detail_id": 11443,
                "word_type": "n.",
                "spelling_usage": "A small saucer of clay for roasting or melting samples of\n   ores, etc.; a scorifier."
            },
            {
                "spelling_detail_id": 11444,
                "word_type": "n.",
                "spelling_usage": "a small, shallow, evaporating dish, usually of porcelain."
            },
            {
                "spelling_detail_id": 11445,
                "word_type": "n.",
                "spelling_usage": "A small cylindrical or spherical gelatinous envelope in\n   which nauseous or acrid doses are inclosed to be swallowed."
            },
            {
                "spelling_detail_id": 11446,
                "word_type": "n.",
                "spelling_usage": "A membranous sac containing fluid, or investing an organ\n   or joint; as, the capsule of the lens of the eye. Also, a capsulelike\n   organ."
            },
            {
                "spelling_detail_id": 11447,
                "word_type": "n.",
                "spelling_usage": "A metallic seal or cover for closing a bottle."
            },
            {
                "spelling_detail_id": 11448,
                "word_type": "n.",
                "spelling_usage": "A small cup or shell, as of metal, for a percussion cap,\n   cartridge, etc."
            }
        ]
    },
    {
        "id": 6855,
        "spelling_name": "Captivated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11473,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Captivate"
            }
        ]
    },
    {
        "id": 6894,
        "spelling_name": "Caramel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11540,
                "word_type": "n.",
                "spelling_usage": "Burnt sugar; a brown or black porous substance obtained by\n   heating sugar. It is soluble in water, and is used for coloring\n   spirits, gravies, etc."
            },
            {
                "spelling_detail_id": 11541,
                "word_type": "n.",
                "spelling_usage": "A kind of confectionery, usually a small cube or square of\n   tenacious paste, or candy, of varying composition and flavor."
            }
        ]
    },
    {
        "id": 7047,
        "spelling_name": "Caricature",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11756,
                "word_type": "v. t.",
                "spelling_usage": "An exaggeration, or distortion by exaggeration, of\n   parts or characteristics, as in a picture."
            },
            {
                "spelling_detail_id": 11757,
                "word_type": "v. t.",
                "spelling_usage": "A picture or other figure or description in which\n   the peculiarities of a person or thing are so exaggerated as to appear\n   ridiculous; a burlesque; a parody."
            },
            {
                "spelling_detail_id": 11760,
                "word_type": "v. t.",
                "spelling_usage": "To make or draw a caricature of; to represent with\n   ridiculous exaggeration; to burlesque."
            }
        ]
    },
    {
        "id": 7151,
        "spelling_name": "Carpal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11911,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the carpus, or wrist."
            },
            {
                "spelling_detail_id": 11912,
                "word_type": "n.",
                "spelling_usage": "One of the bones or cartilages of the carpus; a carpale."
            }
        ]
    },
    {
        "id": 7193,
        "spelling_name": "Carriage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 11960,
                "word_type": "n.",
                "spelling_usage": "That which is carried; burden; baggage."
            },
            {
                "spelling_detail_id": 11961,
                "word_type": "n.",
                "spelling_usage": "The act of carrying, transporting, or conveying."
            },
            {
                "spelling_detail_id": 11962,
                "word_type": "n.",
                "spelling_usage": "The price or expense of carrying."
            },
            {
                "spelling_detail_id": 11963,
                "word_type": "n.",
                "spelling_usage": "That which carries of conveys,"
            },
            {
                "spelling_detail_id": 11964,
                "word_type": "n.",
                "spelling_usage": "A wheeled vehicle for persons, esp. one designed for\n   elegance and comfort."
            },
            {
                "spelling_detail_id": 11965,
                "word_type": "n.",
                "spelling_usage": "A wheeled vehicle carrying a fixed burden, as a gun\n   carriage."
            },
            {
                "spelling_detail_id": 11966,
                "word_type": "n.",
                "spelling_usage": "A part of a machine which moves and carries of supports\n   some other moving object or part."
            },
            {
                "spelling_detail_id": 11967,
                "word_type": "n.",
                "spelling_usage": "A frame or cage in which something is carried or\n   supported; as, a bell carriage."
            },
            {
                "spelling_detail_id": 11968,
                "word_type": "n.",
                "spelling_usage": "The manner of carrying one's self; behavior; bearing;\n   deportment; personal manners."
            },
            {
                "spelling_detail_id": 11969,
                "word_type": "n.",
                "spelling_usage": "The act or manner of conducting measures or projects;\n   management."
            }
        ]
    },
    {
        "id": 7230,
        "spelling_name": "Cartilage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12045,
                "word_type": "n.",
                "spelling_usage": "A translucent, elastic tissue; gristle."
            }
        ]
    },
    {
        "id": 7311,
        "spelling_name": "Cashier",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12182,
                "word_type": "n.",
                "spelling_usage": "One who has charge of money; a cash keeper; the officer\n   who has charge of the payments and receipts (moneys, checks, notes), of\n   a bank or a mercantile company."
            },
            {
                "spelling_detail_id": 12185,
                "word_type": "v. t.",
                "spelling_usage": "To dismiss or discard; to discharge; to dismiss with\n   ignominy from military service or from an office or place of trust."
            },
            {
                "spelling_detail_id": 12186,
                "word_type": "v. t.",
                "spelling_usage": "To put away or reject; to disregard."
            }
        ]
    },
    {
        "id": 7332,
        "spelling_name": "Casserole",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12223,
                "word_type": "n.",
                "spelling_usage": "A small round dish with a handle, usually of porcelain."
            },
            {
                "spelling_detail_id": 12224,
                "word_type": "n.",
                "spelling_usage": "A mold (in the shape of a hollow vessel or incasement)\n   of boiled rice, mashed potato or paste, baked, and afterwards filled\n   with vegetables or meat."
            }
        ]
    },
    {
        "id": 7410,
        "spelling_name": "Casualty",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12384,
                "word_type": "n.",
                "spelling_usage": "That which comes without design or without being\n   foreseen; contingency."
            },
            {
                "spelling_detail_id": 12385,
                "word_type": "n.",
                "spelling_usage": "Any injury of the body from accident; hence, death, or\n   other misfortune, occasioned by an accident; as, an unhappy casualty."
            },
            {
                "spelling_detail_id": 12386,
                "word_type": "n.",
                "spelling_usage": "Numerical loss caused by death, wounds, discharge, or\n   desertion."
            }
        ]
    },
    {
        "id": 7441,
        "spelling_name": "Cataclysmic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12416,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a cataclysm."
            }
        ]
    },
    {
        "id": 7457,
        "spelling_name": "Catalepsy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12435,
                "word_type": "n.",
                "spelling_usage": "Alt. of Catalepsis"
            }
        ]
    },
    {
        "id": 7595,
        "spelling_name": "Cathedral",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12640,
                "word_type": "n.",
                "spelling_usage": "The principal church in a diocese, so called because in\n   it the bishop has his official chair (Cathedra) or throne."
            },
            {
                "spelling_detail_id": 12641,
                "word_type": "a.",
                "spelling_usage": "Pertaining to the head church of a diocese; as, a\n   cathedral church; cathedral service."
            },
            {
                "spelling_detail_id": 12642,
                "word_type": "a.",
                "spelling_usage": "Emanating from the chair of office, as of a pope or\n   bishop; official; authoritative."
            },
            {
                "spelling_detail_id": 12643,
                "word_type": "a.",
                "spelling_usage": "Resembling the aisles of a cathedral; as, cathedral\n   walks."
            }
        ]
    },
    {
        "id": 7719,
        "spelling_name": "Cauterize",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12822,
                "word_type": "v. t.",
                "spelling_usage": "To burn or sear with a cautery or caustic."
            },
            {
                "spelling_detail_id": 12823,
                "word_type": "v. t.",
                "spelling_usage": "To sear, as the conscience."
            }
        ]
    },
    {
        "id": 7725,
        "spelling_name": "Cautioned",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12830,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Caution"
            }
        ]
    },
    {
        "id": 7732,
        "spelling_name": "Cavalcade",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12842,
                "word_type": "n.",
                "spelling_usage": "A procession of persons on horseback; a formal, pompous\n   march of horsemen by way of parade."
            }
        ]
    },
    {
        "id": 7745,
        "spelling_name": "Caveat",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12868,
                "word_type": "n.",
                "spelling_usage": "A notice given by an interested party to some officer not\n   to do a certain act until the party is heard in opposition; as, a\n   caveat entered in a probate court to stop the proving of a will or the\n   taking out of letters of administration, etc."
            },
            {
                "spelling_detail_id": 12869,
                "word_type": "n.",
                "spelling_usage": "A description of some invention, designed to be patented,\n   lodged in the patent office before the patent right is applied for, and\n   operating as a bar to the issue of letters patent to any other person,\n   respecting the same invention."
            },
            {
                "spelling_detail_id": 12870,
                "word_type": "n.",
                "spelling_usage": "Intimation of caution; warning; protest."
            }
        ]
    },
    {
        "id": 7793,
        "spelling_name": "Cayenne",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12924,
                "word_type": "n.",
                "spelling_usage": "Cayenne pepper."
            }
        ]
    },
    {
        "id": 7806,
        "spelling_name": "Cedar",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12941,
                "word_type": "n.",
                "spelling_usage": "The name of several evergreen trees. The wood is remarkable\n   for its durability and fragrant odor."
            },
            {
                "spelling_detail_id": 12942,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to cedar."
            }
        ]
    },
    {
        "id": 7838,
        "spelling_name": "Celery",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12984,
                "word_type": "n.",
                "spelling_usage": "A plant of the Parsley family (Apium graveolens), of which\n   the blanched leafstalks are used as a salad."
            }
        ]
    },
    {
        "id": 7839,
        "spelling_name": "Celestial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 12985,
                "word_type": "a.",
                "spelling_usage": "Belonging to the aerial regions, or visible heavens."
            },
            {
                "spelling_detail_id": 12986,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the spiritual heaven; heavenly;\n   divine."
            },
            {
                "spelling_detail_id": 12987,
                "word_type": "n.",
                "spelling_usage": "An inhabitant of heaven."
            },
            {
                "spelling_detail_id": 12988,
                "word_type": "n.",
                "spelling_usage": "A native of China."
            }
        ]
    },
    {
        "id": 7873,
        "spelling_name": "Celsius",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13035,
                "word_type": "n.",
                "spelling_usage": "The Celsius thermometer or scale, so called from Anders\n   Celsius, a Swedish astronomer, who invented it. It is the same as the\n   centigrade thermometer or scale."
            }
        ]
    },
    {
        "id": 7890,
        "spelling_name": "Cemetery",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13064,
                "word_type": "n.",
                "spelling_usage": "A place or ground set apart for the burial of the dead; a\n   graveyard; a churchyard; a necropolis."
            }
        ]
    },
    {
        "id": 7917,
        "spelling_name": "Census",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13105,
                "word_type": "n.",
                "spelling_usage": "A numbering of the people, and valuation of their estate,\n   for the purpose of imposing taxes, etc.; -- usually made once in five\n   years."
            },
            {
                "spelling_detail_id": 13106,
                "word_type": "n.",
                "spelling_usage": "An official registration of the number of the people, the\n   value of their estates, and other general statistics of a country."
            }
        ]
    },
    {
        "id": 8053,
        "spelling_name": "Ceramics",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13289,
                "word_type": "n.",
                "spelling_usage": "The art of making things of baked clay; as pottery,\n   tiles, etc."
            },
            {
                "spelling_detail_id": 13290,
                "word_type": "n.",
                "spelling_usage": "Work formed of clay in whole or in part, and baked; as,\n   vases, urns, etc."
            }
        ]
    },
    {
        "id": 8086,
        "spelling_name": "Cerebellum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13335,
                "word_type": "n.",
                "spelling_usage": "The large lobe of the hind brain in front of and above\n   the medulla; the little brain. It controls combined muscular action.\n   See Brain."
            }
        ]
    },
    {
        "id": 8395,
        "spelling_name": "Chancellor",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13815,
                "word_type": "n.",
                "spelling_usage": "A judicial court of chancery, which in England and in\n   the United States is distinctively a court with equity jurisdiction."
            }
        ]
    },
    {
        "id": 8457,
        "spelling_name": "Chaperonage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 13960,
                "word_type": "n.",
                "spelling_usage": "Attendance of a chaperon on a lady in public;\n   protection afforded by a chaperon."
            }
        ]
    },
    {
        "id": 8521,
        "spelling_name": "Charioteer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14115,
                "word_type": "n.",
                "spelling_usage": "One who drives a chariot."
            },
            {
                "spelling_detail_id": 14116,
                "word_type": "n.",
                "spelling_usage": "A constellation. See Auriga, and Wagones."
            }
        ]
    },
    {
        "id": 8552,
        "spelling_name": "Charon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14171,
                "word_type": "n.",
                "spelling_usage": "The son of Erebus and Nox, whose office it was to ferry the\n   souls of the dead over the Styx, a river of the infernal regions."
            }
        ]
    },
    {
        "id": 8619,
        "spelling_name": "Chateau",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14287,
                "word_type": "n.",
                "spelling_usage": "A castle or a fortress in France."
            },
            {
                "spelling_detail_id": 14288,
                "word_type": "n.",
                "spelling_usage": "A manor house or residence of the lord of the manor; a\n   gentleman's country seat; also, particularly, a royal residence; as,\n   the chateau of the Louvre; the chateau of the Luxembourg."
            }
        ]
    },
    {
        "id": 8725,
        "spelling_name": "Chef",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14476,
                "word_type": "n.",
                "spelling_usage": "A chief of head person."
            },
            {
                "spelling_detail_id": 14477,
                "word_type": "n.",
                "spelling_usage": "The head cook of large establishment, as a club, a family,\n   etc."
            },
            {
                "spelling_detail_id": 14478,
                "word_type": "n.",
                "spelling_usage": "Same as Chief."
            }
        ]
    },
    {
        "id": 8763,
        "spelling_name": "Chemise",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14520,
                "word_type": "n.",
                "spelling_usage": "A shift, or undergarment, worn by women."
            },
            {
                "spelling_detail_id": 14521,
                "word_type": "n.",
                "spelling_usage": "A wall that lines the face of a bank or earthwork."
            }
        ]
    },
    {
        "id": 8767,
        "spelling_name": "Chemistry",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14525,
                "word_type": "n.",
                "spelling_usage": "That branch of science which treats of the composition\n   of substances, and of the changes which they undergo in consequence of\n   alterations in the constitution of the molecules, which depend upon\n   variations of the number, kind, or mode of arrangement, of the\n   constituent atoms. These atoms are not assumed to be indivisible, but\n   merely the finest grade of subdivision hitherto attained. Chemistry\n   deals with the changes in the composition and constitution of\n   molecules. See Atom, Molecule."
            },
            {
                "spelling_detail_id": 14526,
                "word_type": "n.",
                "spelling_usage": "An application of chemical theory and method to the\n   consideration of some particular subject; as, the chemistry of iron;\n   the chemistry of indigo."
            },
            {
                "spelling_detail_id": 14527,
                "word_type": "n.",
                "spelling_usage": "A treatise on chemistry."
            }
        ]
    },
    {
        "id": 8797,
        "spelling_name": "Cherubic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14570,
                "word_type": "a.",
                "spelling_usage": "Alt. of Cherubical"
            }
        ]
    },
    {
        "id": 9013,
        "spelling_name": "Chinook",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 14908,
                "word_type": "n.",
                "spelling_usage": "One of a tribe of North American Indians now living in the\n   state of Washington, noted for the custom of flattening their skulls.\n   Chinooks also called Flathead Indians."
            },
            {
                "spelling_detail_id": 14909,
                "word_type": "n.",
                "spelling_usage": "A warm westerly wind from the country of the Chinooks,\n   sometimes experienced on the slope of the Rocky Mountains, in Montana\n   and the adjacent territory."
            },
            {
                "spelling_detail_id": 14910,
                "word_type": "n.",
                "spelling_usage": "A jargon of words from various languages (the largest\n   proportion of which is from that of the Chinooks) generally understood\n   by all the Indian tribes of the northwestern territories of the United\n   States."
            }
        ]
    },
    {
        "id": 9189,
        "spelling_name": "Cholera",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15166,
                "word_type": "n.",
                "spelling_usage": "One of several diseases affecting the digestive and\n   intestinal tract and more or less dangerous to life, esp. the one\n   commonly called Asiatic cholera."
            }
        ]
    },
    {
        "id": 9392,
        "spelling_name": "Chromosome",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15468,
                "word_type": "n.",
                "spelling_usage": "One of the minute bodies into which the chromatin of\n   the nucleus is resolved during mitotic cell division; the idant of\n   Weismann."
            }
        ]
    },
    {
        "id": 9419,
        "spelling_name": "Chronometer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15504,
                "word_type": "n.",
                "spelling_usage": "An instrument for measuring time; a timekeeper."
            },
            {
                "spelling_detail_id": 15505,
                "word_type": "n.",
                "spelling_usage": "A portable timekeeper, with a heavy compensation\n   balance, and usually beating half seconds; -- intended to keep time\n   with great accuracy for use an astronomical observations, in\n   determining longitude, etc."
            },
            {
                "spelling_detail_id": 15506,
                "word_type": "n.",
                "spelling_usage": "A metronome."
            }
        ]
    },
    {
        "id": 9428,
        "spelling_name": "Chrysalis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15516,
                "word_type": "n.",
                "spelling_usage": "The pupa state of certain insects, esp. of butterflies,\n   from which the perfect insect emerges. See Pupa, and Aurelia (a)."
            }
        ]
    },
    {
        "id": 9507,
        "spelling_name": "Churlish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15634,
                "word_type": "a.",
                "spelling_usage": "Like a churl; rude; cross-grained; ungracious; surly;\n   illiberal; niggardly."
            },
            {
                "spelling_detail_id": 15635,
                "word_type": "a.",
                "spelling_usage": "Wanting pliancy; unmanageable; unyielding; not easily\n   wrought; as, a churlish soil; the churlish and intractable nature of\n   some minerals."
            }
        ]
    },
    {
        "id": 9549,
        "spelling_name": "Cicada",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15687,
                "word_type": "n.",
                "spelling_usage": "Any species of the genus Cicada. They are large hemipterous\n   insects, with nearly transparent wings. The male makes a shrill sound\n   by peculiar organs in the under side of the abdomen, consisting of a\n   pair of stretched membranes, acted upon by powerful muscles. A noted\n   American species (C. septendecim) is called the seventeen year locust.\n   Another common species is the dogday cicada."
            }
        ]
    },
    {
        "id": 9650,
        "spelling_name": "Cinnamon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15810,
                "word_type": "n.",
                "spelling_usage": "The inner bark of the shoots of Cinnamomum Zeylanicum, a\n   tree growing in Ceylon. It is aromatic, of a moderately pungent taste,\n   and is one of the best cordial, carminative, and restorative spices."
            },
            {
                "spelling_detail_id": 15811,
                "word_type": "n.",
                "spelling_usage": "Cassia."
            }
        ]
    },
    {
        "id": 9691,
        "spelling_name": "Circuitous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15890,
                "word_type": "a.",
                "spelling_usage": "Going round in a circuit; roundabout; indirect; as, a\n   circuitous road; a circuitous manner of accomplishing an end."
            }
        ]
    },
    {
        "id": 9738,
        "spelling_name": "Circumflex",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 15964,
                "word_type": "n.",
                "spelling_usage": "A wave of the voice embracing both a rise and fall or a\n   fall and a rise on the same a syllable."
            },
            {
                "spelling_detail_id": 15965,
                "word_type": "n.",
                "spelling_usage": "A character, or accent, denoting in Greek a rise and of\n   the voice on the same long syllable, marked thus [~ or /]; and in Latin\n   and some other languages, denoting a long and contracted syllable,\n   marked [/ or ^]. See Accent, n., 2."
            },
            {
                "spelling_detail_id": 15968,
                "word_type": "v. t.",
                "spelling_usage": "To mark or pronounce with a circumflex."
            },
            {
                "spelling_detail_id": 15969,
                "word_type": "a.",
                "spelling_usage": "Moving or turning round; circuitous."
            },
            {
                "spelling_detail_id": 15970,
                "word_type": "a.",
                "spelling_usage": "Curved circularly; -- applied to several arteries of\n   the hip and thigh, to arteries, veins, and a nerve of the shoulder, and\n   to other parts."
            }
        ]
    },
    {
        "id": 9865,
        "spelling_name": "Cistern",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16128,
                "word_type": "n.",
                "spelling_usage": "An artificial reservoir or tank for holding water, beer,\n   or other liquids."
            },
            {
                "spelling_detail_id": 16129,
                "word_type": "n.",
                "spelling_usage": "A natural reservoir; a hollow place containing water."
            }
        ]
    },
    {
        "id": 9904,
        "spelling_name": "Civet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16190,
                "word_type": "n.",
                "spelling_usage": "A substance, of the consistence of butter or honey, taken\n   from glands in the anal pouch of the civet (Viverra civetta). It is of\n   clear yellowish or brownish color, of a strong, musky odor, offensive\n   when undiluted, but agreeable when a small portion is mixed with\n   another substance. It is used as a perfume."
            },
            {
                "spelling_detail_id": 16191,
                "word_type": "n.",
                "spelling_usage": "The animal that produces civet (Viverra civetta); -- called\n   also civet cat. It is carnivorous, from two to three feet long, and of\n   a brownish gray color, with transverse black bands and spots on the\n   body and tail. It is a native of northern Africa and of Asia. The name\n   is also applied to other species."
            },
            {
                "spelling_detail_id": 16192,
                "word_type": "v. t.",
                "spelling_usage": "To scent or perfume with civet."
            }
        ]
    },
    {
        "id": 9943,
        "spelling_name": "Clairvoyance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16260,
                "word_type": "n.",
                "spelling_usage": "A power, attributed to some persons while in a\n   mesmeric state, of discering objects not perceptible by the senses in\n   their normal condition."
            }
        ]
    },
    {
        "id": 9973,
        "spelling_name": "Clandestine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16318,
                "word_type": "a.",
                "spelling_usage": "Conducted with secrecy; withdrawn from public notice,\n   usually for an evil purpose; kept secret; hidden; private; underhand;\n   as, a clandestine marriage."
            }
        ]
    },
    {
        "id": 10018,
        "spelling_name": "Clarinet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16396,
                "word_type": "n.",
                "spelling_usage": "A wind instrument, blown by a single reed, of richer and\n   fuller tone than the oboe, which has a double reed. It is the leading\n   instrument in a military band."
            }
        ]
    },
    {
        "id": 10044,
        "spelling_name": "Classical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16440,
                "word_type": "n.",
                "spelling_usage": "Of or relating to the first class or rank, especially in\n   literature or art."
            },
            {
                "spelling_detail_id": 16441,
                "word_type": "n.",
                "spelling_usage": "Of or pertaining to the ancient Greeks and Romans, esp.\n   to Greek or Roman authors of the highest rank, or of the period when\n   their best literature was produced; of or pertaining to places\n   inhabited by the ancient Greeks and Romans, or rendered famous by their\n   deeds."
            },
            {
                "spelling_detail_id": 16442,
                "word_type": "n.",
                "spelling_usage": "Conforming to the best authority in literature and art;\n   chaste; pure; refined; as, a classical style."
            }
        ]
    },
    {
        "id": 10067,
        "spelling_name": "Clatter",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16475,
                "word_type": "v. i.",
                "spelling_usage": "To make a rattling sound by striking hard bodies\n   together; to make a succession of abrupt, rattling sounds."
            },
            {
                "spelling_detail_id": 16476,
                "word_type": "v. i.",
                "spelling_usage": "To talk fast and noisily; to rattle with the tongue."
            },
            {
                "spelling_detail_id": 16477,
                "word_type": "v. t.",
                "spelling_usage": "To make a rattling noise with."
            },
            {
                "spelling_detail_id": 16478,
                "word_type": "n.",
                "spelling_usage": "A rattling noise, esp. that made by the collision of hard\n   bodies; also, any loud, abrupt sound; a repetition of abrupt sounds."
            },
            {
                "spelling_detail_id": 16479,
                "word_type": "n.",
                "spelling_usage": "Commotion; disturbance."
            },
            {
                "spelling_detail_id": 16480,
                "word_type": "n.",
                "spelling_usage": "Rapid, noisy talk; babble; chatter."
            }
        ]
    },
    {
        "id": 10144,
        "spelling_name": "Clearance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16614,
                "word_type": "n.",
                "spelling_usage": "The act of clearing; as, to make a thorough clearance."
            },
            {
                "spelling_detail_id": 16615,
                "word_type": "n.",
                "spelling_usage": "A certificate that a ship or vessel has been cleared at\n   the customhouse; permission to sail."
            },
            {
                "spelling_detail_id": 16616,
                "word_type": "n.",
                "spelling_usage": "Clear or net profit."
            },
            {
                "spelling_detail_id": 16617,
                "word_type": "n.",
                "spelling_usage": "The distance by which one object clears another, as the\n   distance between the piston and cylinder head at the end of a stroke in\n   a steam engine, or the least distance between the point of a cogwheel\n   tooth and the bottom of a space between teeth of a wheel with which it\n   engages."
            }
        ]
    },
    {
        "id": 10182,
        "spelling_name": "Clemency",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 16690,
                "word_type": "n.",
                "spelling_usage": "Disposition to forgive and spare, as offenders; mildness\n   of temper; gentleness; tenderness; mercy."
            },
            {
                "spelling_detail_id": 16691,
                "word_type": "n.",
                "spelling_usage": "Mildness or softness of the elements; as, the clemency of\n   the season."
            }
        ]
    },
    {
        "id": 10599,
        "spelling_name": "Coarse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 17402,
                "word_type": "superl.",
                "spelling_usage": "Large in bulk, or composed of large parts or\n   particles; of inferior quality or appearance; not fine in material or\n   close in texture; gross; thick; rough; -- opposed to fine; as, coarse\n   sand; coarse thread; coarse cloth; coarse bread."
            },
            {
                "spelling_detail_id": 17403,
                "word_type": "superl.",
                "spelling_usage": "Not refined; rough; rude; unpolished; gross;\n   indelicate; as, coarse manners; coarse language."
            }
        ]
    },
    {
        "id": 10745,
        "spelling_name": "Cocoa",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 17668,
                "word_type": "",
                "spelling_usage": "Alt. of Cocoa palm"
            },
            {
                "spelling_detail_id": 17670,
                "word_type": "n.",
                "spelling_usage": "A preparation made from the seeds of the chocolate tree, and\n   used in making, a beverage; also the beverage made from cocoa or cocoa\n   shells."
            }
        ]
    },
    {
        "id": 10832,
        "spelling_name": "Coeval",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 17783,
                "word_type": "n.",
                "spelling_usage": "Of the same age; existing during the same period of time,\n   especially time long and remote; -- usually followed by with."
            },
            {
                "spelling_detail_id": 17784,
                "word_type": "n.",
                "spelling_usage": "One of the same age; a contemporary."
            }
        ]
    },
    {
        "id": 10864,
        "spelling_name": "Cogently",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 17844,
                "word_type": "adv.",
                "spelling_usage": "In a cogent manner; forcibly; convincingly;\n   conclusively."
            }
        ]
    },
    {
        "id": 10892,
        "spelling_name": "Cognizant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 17886,
                "word_type": "a.",
                "spelling_usage": "Having cognizance or knowledge. (of)."
            }
        ]
    },
    {
        "id": 10930,
        "spelling_name": "Cohesive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 17937,
                "word_type": "a.",
                "spelling_usage": "Holding the particles of a homogeneous body together; as,\n   cohesive attraction; producing cohesion; as, a cohesive force."
            },
            {
                "spelling_detail_id": 17938,
                "word_type": "a.",
                "spelling_usage": "Cohering, or sticking together, as in a mass; capable of\n   cohering; tending to cohere; as, cohesive clay."
            }
        ]
    },
    {
        "id": 11129,
        "spelling_name": "Collision",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18273,
                "word_type": "n.",
                "spelling_usage": "The act of striking together; a striking together, as of\n   two hard bodies; a violent meeting, as of railroad trains; a clashing."
            },
            {
                "spelling_detail_id": 18274,
                "word_type": "n.",
                "spelling_usage": "A state of opposition; antagonism; interference."
            }
        ]
    },
    {
        "id": 11243,
        "spelling_name": "Colubrine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18434,
                "word_type": "a.",
                "spelling_usage": "like or related to snakes of the genus Coluber."
            },
            {
                "spelling_detail_id": 18435,
                "word_type": "a.",
                "spelling_usage": "Like a snake; cunning; crafty."
            }
        ]
    },
    {
        "id": 11303,
        "spelling_name": "Combination",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18540,
                "word_type": "n.",
                "spelling_usage": "The act or process of combining or uniting persons and\n   things."
            },
            {
                "spelling_detail_id": 18541,
                "word_type": "n.",
                "spelling_usage": "The result of combining or uniting; union of persons\n   or things; esp. a union or alliance of persons or states to effect some\n   purpose; -- usually in a bad sense."
            },
            {
                "spelling_detail_id": 18542,
                "word_type": "n.",
                "spelling_usage": "The act or process of uniting by chemical affinity, by\n   which substances unite with each other in definite proportions by\n   weight to form distinct compounds."
            },
            {
                "spelling_detail_id": 18543,
                "word_type": "n.",
                "spelling_usage": "The different arrangements of a number of objects, as\n   letters, into groups."
            }
        ]
    },
    {
        "id": 11314,
        "spelling_name": "Combustible",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18564,
                "word_type": "a.",
                "spelling_usage": "Capable of taking fire and burning; apt to catch fire;\n   inflammable."
            },
            {
                "spelling_detail_id": 18565,
                "word_type": "a.",
                "spelling_usage": "Easily kindled or excited; quick; fiery; irascible."
            },
            {
                "spelling_detail_id": 18566,
                "word_type": "n.",
                "spelling_usage": "A substance that may be set on fire, or which is\n   liable to take fire and burn."
            }
        ]
    },
    {
        "id": 11321,
        "spelling_name": "Comedienne",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18586,
                "word_type": "n.",
                "spelling_usage": "A women who plays in comedy."
            }
        ]
    },
    {
        "id": 11435,
        "spelling_name": "Commerce",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18776,
                "word_type": "n.",
                "spelling_usage": "The exchange or buying and selling of commodities; esp.\n   the exchange of merchandise, on a large scale, between different places\n   or communities; extended trade or traffic."
            },
            {
                "spelling_detail_id": 18777,
                "word_type": "n.",
                "spelling_usage": "Social intercourse; the dealings of one person or class\n   in society with another; familiarity."
            },
            {
                "spelling_detail_id": 18778,
                "word_type": "n.",
                "spelling_usage": "Sexual intercourse."
            },
            {
                "spelling_detail_id": 18779,
                "word_type": "n.",
                "spelling_usage": "A round game at cards, in which the cards are subject to\n   exchange, barter, or trade."
            },
            {
                "spelling_detail_id": 18782,
                "word_type": "v. i.",
                "spelling_usage": "To carry on trade; to traffic."
            },
            {
                "spelling_detail_id": 18783,
                "word_type": "v. i.",
                "spelling_usage": "To hold intercourse; to commune."
            }
        ]
    },
    {
        "id": 11469,
        "spelling_name": "Commissioner",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18833,
                "word_type": "n.",
                "spelling_usage": "A person who has a commission or warrant to perform\n   some office, or execute some business, for the government, corporation,\n   or person employing him; as, a commissioner to take affidavits or to\n   adjust claims."
            },
            {
                "spelling_detail_id": 18834,
                "word_type": "n.",
                "spelling_usage": "An officer having charge of some department or bureau\n   of the public service."
            }
        ]
    },
    {
        "id": 11494,
        "spelling_name": "Commodious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 18880,
                "word_type": "a.",
                "spelling_usage": "Adapted to its use or purpose, or to wants and\n   necessities; serviceable; spacious and convenient; roomy and\n   comfortable; as, a commodious house."
            }
        ]
    },
    {
        "id": 11560,
        "spelling_name": "Commute",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19023,
                "word_type": "v. t.",
                "spelling_usage": "To exchange; to put or substitute something else in\n   place of, as a smaller penalty, obligation, or payment, for a greater,\n   or a single thing for an aggregate; hence, to lessen; to diminish; as,\n   to commute a sentence of death to one of imprisonment for life; to\n   commute tithes; to commute charges for fares."
            },
            {
                "spelling_detail_id": 19024,
                "word_type": "v. i.",
                "spelling_usage": "To obtain or bargain for exemption or substitution; to\n   effect a commutation."
            },
            {
                "spelling_detail_id": 19025,
                "word_type": "v. i.",
                "spelling_usage": "To pay, or arrange to pay, in gross instead of part by\n   part; as, to commute for a year's travel over a route."
            }
        ]
    },
    {
        "id": 11601,
        "spelling_name": "Comparison",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19103,
                "word_type": "n.",
                "spelling_usage": "The act of comparing; an examination of two or more\n   objects with the view of discovering the resemblances or differences;\n   relative estimate."
            },
            {
                "spelling_detail_id": 19104,
                "word_type": "n.",
                "spelling_usage": "The state of being compared; a relative estimate; also,\n   a state, quality, or relation, admitting of being compared; as, to\n   bring a thing into comparison with another; there is no comparison\n   between them."
            },
            {
                "spelling_detail_id": 19105,
                "word_type": "n.",
                "spelling_usage": "That to which, or with which, a thing is compared, as\n   being equal or like; illustration; similitude."
            },
            {
                "spelling_detail_id": 19106,
                "word_type": "n.",
                "spelling_usage": "The modification, by inflection or otherwise, which the\n   adjective and adverb undergo to denote degrees of quality or quantity;\n   as, little, less, least, are examples of comparison."
            },
            {
                "spelling_detail_id": 19107,
                "word_type": "n.",
                "spelling_usage": "A figure by which one person or thing is compared to\n   another, or the two are considered with regard to some property or\n   quality, which is common to them both; e.g., the lake sparkled like a\n   jewel."
            },
            {
                "spelling_detail_id": 19108,
                "word_type": "n.",
                "spelling_usage": "The faculty of the reflective group which is supposed\n   to perceive resemblances and contrasts."
            },
            {
                "spelling_detail_id": 19109,
                "word_type": "v. t.",
                "spelling_usage": "To compare."
            }
        ]
    },
    {
        "id": 11648,
        "spelling_name": "Compendium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19185,
                "word_type": "n.",
                "spelling_usage": "A brief compilation or composition, containing the\n   principal heads, or general principles, of a larger work or system; an\n   abridgment; an epitome; a compend; a condensed summary."
            }
        ]
    },
    {
        "id": 11683,
        "spelling_name": "Complacency",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19236,
                "word_type": "n.",
                "spelling_usage": "Calm contentment; satisfaction; gratification."
            },
            {
                "spelling_detail_id": 19237,
                "word_type": "n.",
                "spelling_usage": "The cause of pleasure or joy."
            },
            {
                "spelling_detail_id": 19238,
                "word_type": "n.",
                "spelling_usage": "The manifestation of contentment or satisfaction; good\n   nature; kindness; civility; affability."
            }
        ]
    },
    {
        "id": 11702,
        "spelling_name": "Complementary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19275,
                "word_type": "a.",
                "spelling_usage": "Serving to fill out or to complete; as,\n   complementary numbers."
            },
            {
                "spelling_detail_id": 19276,
                "word_type": "n.",
                "spelling_usage": "One skilled in compliments."
            }
        ]
    },
    {
        "id": 11733,
        "spelling_name": "Complicated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19321,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Complicate"
            }
        ]
    },
    {
        "id": 11769,
        "spelling_name": "Comportment",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19372,
                "word_type": "n.",
                "spelling_usage": "Manner of acting; behavior; bearing."
            }
        ]
    },
    {
        "id": 11849,
        "spelling_name": "Compunction",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19530,
                "word_type": "n.",
                "spelling_usage": "A pricking; stimulation."
            },
            {
                "spelling_detail_id": 19531,
                "word_type": "n.",
                "spelling_usage": "A picking of heart; poignant grief proceeding from a\n   sense of guilt or consciousness of causing pain; the sting of\n   conscience."
            }
        ]
    },
    {
        "id": 11879,
        "spelling_name": "Concatenate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19575,
                "word_type": "v. t.",
                "spelling_usage": "To link together; to unite in a series or chain, as\n   things depending on one another."
            }
        ]
    },
    {
        "id": 11903,
        "spelling_name": "Conceit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19610,
                "word_type": "n.",
                "spelling_usage": "That which is conceived, imagined, or formed in the mind;\n   idea; thought; image; conception."
            },
            {
                "spelling_detail_id": 19611,
                "word_type": "n.",
                "spelling_usage": "Faculty of conceiving ideas; mental faculty; apprehension;\n   as, a man of quick conceit."
            },
            {
                "spelling_detail_id": 19612,
                "word_type": "n.",
                "spelling_usage": "Quickness of apprehension; active imagination; lively\n   fancy."
            },
            {
                "spelling_detail_id": 19613,
                "word_type": "n.",
                "spelling_usage": "A fanciful, odd, or extravagant notion; a quant fancy; an\n   unnatural or affected conception; a witty thought or turn of\n   expression; a fanciful device; a whim; a quip."
            },
            {
                "spelling_detail_id": 19614,
                "word_type": "n.",
                "spelling_usage": "An overweening idea of one's self; vanity."
            },
            {
                "spelling_detail_id": 19615,
                "word_type": "n.",
                "spelling_usage": "Design; pattern."
            },
            {
                "spelling_detail_id": 19616,
                "word_type": "v. t.",
                "spelling_usage": "To conceive; to imagine."
            },
            {
                "spelling_detail_id": 19617,
                "word_type": "v. i.",
                "spelling_usage": "To form an idea; to think."
            }
        ]
    },
    {
        "id": 12019,
        "spelling_name": "Conclave",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19784,
                "word_type": "n.",
                "spelling_usage": "The set of apartments within which the cardinals of the\n   Roman Catholic Church are continuously secluded while engaged in\n   choosing a pope."
            },
            {
                "spelling_detail_id": 19785,
                "word_type": "n.",
                "spelling_usage": "The body of cardinals shut up in the conclave for the\n   election of a pope; hence, the body of cardinals."
            },
            {
                "spelling_detail_id": 19786,
                "word_type": "n.",
                "spelling_usage": "A private meeting; a close or secret assembly."
            }
        ]
    },
    {
        "id": 12034,
        "spelling_name": "Concoct",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19818,
                "word_type": "v. t.",
                "spelling_usage": "To digest; to convert into nourishment by the organs of\n   nutrition."
            },
            {
                "spelling_detail_id": 19819,
                "word_type": "v. t.",
                "spelling_usage": "To purify or refine chemically."
            },
            {
                "spelling_detail_id": 19820,
                "word_type": "v. t.",
                "spelling_usage": "To prepare from crude materials, as food; to invent or\n   prepare by combining different ingredients; as, to concoct a new dish\n   or beverage."
            },
            {
                "spelling_detail_id": 19821,
                "word_type": "v. t.",
                "spelling_usage": "To digest in the mind; to devise; to make up; to\n   contrive; to plan; to plot."
            },
            {
                "spelling_detail_id": 19822,
                "word_type": "v. t.",
                "spelling_usage": "To mature or perfect; to ripen."
            }
        ]
    },
    {
        "id": 12048,
        "spelling_name": "Concordance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19846,
                "word_type": "n.",
                "spelling_usage": "Agreement; accordance."
            },
            {
                "spelling_detail_id": 19847,
                "word_type": "n.",
                "spelling_usage": "Concord; agreement."
            },
            {
                "spelling_detail_id": 19848,
                "word_type": "n.",
                "spelling_usage": "An alphabetical verbal index showing the places in the\n   text of a book where each principal word may be found, with its\n   immediate context in each place."
            },
            {
                "spelling_detail_id": 19849,
                "word_type": "n.",
                "spelling_usage": "A topical index or orderly analysis of the contents of\n   a book."
            }
        ]
    },
    {
        "id": 12119,
        "spelling_name": "Condensation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 19968,
                "word_type": "n.",
                "spelling_usage": "The act or process of condensing or of being\n   condensed; the state of being condensed."
            },
            {
                "spelling_detail_id": 19969,
                "word_type": "n.",
                "spelling_usage": "The act or process of reducing, by depression of\n   temperature or increase of pressure, etc., to another and denser form,\n   as gas to the condition of a liquid or steam to water."
            },
            {
                "spelling_detail_id": 19970,
                "word_type": "n.",
                "spelling_usage": "A rearrangement or concentration of the different\n   constituents of one or more substances into a distinct and definite\n   compound of greater complexity and molecular weight, often resulting in\n   an increase of density, as the condensation of oxygen into ozone, or of\n   acetone into mesitylene."
            }
        ]
    },
    {
        "id": 12188,
        "spelling_name": "Conduit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 20095,
                "word_type": "n.",
                "spelling_usage": "A pipe, canal, channel, or passage for conveying water or\n   fluid."
            },
            {
                "spelling_detail_id": 20096,
                "word_type": "n.",
                "spelling_usage": "A structure forming a reservoir for water."
            },
            {
                "spelling_detail_id": 20097,
                "word_type": "n.",
                "spelling_usage": "A narrow passage for private communication."
            }
        ]
    },
    {
        "id": 12238,
        "spelling_name": "Conference",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 20174,
                "word_type": "n.",
                "spelling_usage": "The act of comparing two or more things together;\n   comparison."
            },
            {
                "spelling_detail_id": 20175,
                "word_type": "n.",
                "spelling_usage": "The act of consulting together formally; serious\n   conversation or discussion; interchange of views."
            },
            {
                "spelling_detail_id": 20176,
                "word_type": "n.",
                "spelling_usage": "A meeting for consultation, discussion, or an\n   interchange of opinions."
            },
            {
                "spelling_detail_id": 20177,
                "word_type": "n.",
                "spelling_usage": "A meeting of the two branches of a legislature, by\n   their committees, to adjust between them."
            },
            {
                "spelling_detail_id": 20178,
                "word_type": "n.",
                "spelling_usage": "A stated meeting of preachers and others, invested with\n   authority to take cognizance of ecclesiastical matters."
            },
            {
                "spelling_detail_id": 20179,
                "word_type": "n.",
                "spelling_usage": "A voluntary association of Congregational churches of a\n   district; the district in which such churches are."
            }
        ]
    },
    {
        "id": 12453,
        "spelling_name": "Congratulate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 20493,
                "word_type": "v. t.",
                "spelling_usage": "To address with expressions of sympathetic\n   pleasure on account of some happy event affecting the person addressed;\n   to wish joy to."
            },
            {
                "spelling_detail_id": 20494,
                "word_type": "v. i.",
                "spelling_usage": "To express of feel sympathetic joy; as, to\n   congratulate with one's country."
            }
        ]
    },
    {
        "id": 12646,
        "spelling_name": "Consecrate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 20782,
                "word_type": "a.",
                "spelling_usage": "Consecrated; devoted; dedicated; sacred."
            },
            {
                "spelling_detail_id": 20785,
                "word_type": "v. t.",
                "spelling_usage": "To make, or declare to be, sacred; to appropriate to\n   sacred uses; to set apart, dedicate, or devote, to the service or\n   worship of God; as, to consecrate a church; to give (one's self)\n   unreservedly, as to the service of God."
            },
            {
                "spelling_detail_id": 20786,
                "word_type": "v. t.",
                "spelling_usage": "To set apart to a sacred office; as, to consecrate a\n   bishop."
            },
            {
                "spelling_detail_id": 20787,
                "word_type": "v. t.",
                "spelling_usage": "To canonize; to exalt to the rank of a saint; to\n   enroll among the gods, as a Roman emperor."
            },
            {
                "spelling_detail_id": 20788,
                "word_type": "v. t.",
                "spelling_usage": "To render venerable or revered; to hallow; to\n   dignify; as, rules or principles consecrated by time."
            }
        ]
    },
    {
        "id": 12674,
        "spelling_name": "Consequent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 20829,
                "word_type": "a.",
                "spelling_usage": "Following as a result, inference, or natural effect."
            },
            {
                "spelling_detail_id": 20830,
                "word_type": "a.",
                "spelling_usage": "Following by necessary inference or rational deduction;\n   as, a proposition consequent to other propositions."
            },
            {
                "spelling_detail_id": 20831,
                "word_type": "n.",
                "spelling_usage": "That which follows, or results from, a cause; a result\n   or natural effect."
            },
            {
                "spelling_detail_id": 20832,
                "word_type": "n.",
                "spelling_usage": "That which follows from propositions by rational\n   deduction; that which is deduced from reasoning or argumentation; a\n   conclusion, or inference."
            },
            {
                "spelling_detail_id": 20833,
                "word_type": "n.",
                "spelling_usage": "The second term of a ratio, as the term b in the ratio\n   a:b, the first a, being the antecedent."
            }
        ]
    },
    {
        "id": 12804,
        "spelling_name": "Constabulary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21060,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to constables; consisting of\n   constables."
            },
            {
                "spelling_detail_id": 21061,
                "word_type": "n.",
                "spelling_usage": "The collective body of constables in any town,\n   district, or country."
            }
        ]
    },
    {
        "id": 13029,
        "spelling_name": "Contingent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21434,
                "word_type": "a.",
                "spelling_usage": "Possible, or liable, but not certain, to occur;\n   incidental; casual."
            },
            {
                "spelling_detail_id": 21435,
                "word_type": "a.",
                "spelling_usage": "Dependent on that which is undetermined or unknown; as,\n   the success of his undertaking is contingent upon events which he can\n   not control."
            },
            {
                "spelling_detail_id": 21436,
                "word_type": "a.",
                "spelling_usage": "Dependent for effect on something that may or may not\n   occur; as, a contingent estate."
            },
            {
                "spelling_detail_id": 21437,
                "word_type": "n.",
                "spelling_usage": "An event which may or may not happen; that which is\n   unforeseen, undetermined, or dependent on something future; a\n   contingency."
            },
            {
                "spelling_detail_id": 21438,
                "word_type": "n.",
                "spelling_usage": "That which falls to one in a division or apportionment\n   among a number; a suitable share; proportion; esp., a quota of troops."
            }
        ]
    },
    {
        "id": 13095,
        "spelling_name": "Contradictory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21557,
                "word_type": "a.",
                "spelling_usage": "Affirming the contrary; implying a denial of what\n   has been asserted; also, mutually contradicting; inconsistent."
            },
            {
                "spelling_detail_id": 21558,
                "word_type": "a.",
                "spelling_usage": "Opposing or opposed; repugnant."
            },
            {
                "spelling_detail_id": 21560,
                "word_type": "n.",
                "spelling_usage": "A proposition or thing which denies or opposes\n   another; contrariety."
            },
            {
                "spelling_detail_id": 21561,
                "word_type": "n.",
                "spelling_usage": "propositions with the same terms, but opposed to\n   each other both in quality and quantity."
            }
        ]
    },
    {
        "id": 13126,
        "spelling_name": "Contrariwise",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21596,
                "word_type": "adv.",
                "spelling_usage": "On the contrary; oppositely; on the other hand."
            },
            {
                "spelling_detail_id": 21597,
                "word_type": "adv.",
                "spelling_usage": "In a contrary order; conversely."
            }
        ]
    },
    {
        "id": 13159,
        "spelling_name": "Contrite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21652,
                "word_type": "a.",
                "spelling_usage": "Thoroughly bruised or broken."
            },
            {
                "spelling_detail_id": 21653,
                "word_type": "a.",
                "spelling_usage": "Broken down with grief and penitence; deeply sorrowful\n   for sin because it is displeasing to God; humbly and thoroughly\n   penitent."
            },
            {
                "spelling_detail_id": 21654,
                "word_type": "n.",
                "spelling_usage": "A contrite person."
            },
            {
                "spelling_detail_id": 21655,
                "word_type": "v.",
                "spelling_usage": "In a contrite manner."
            }
        ]
    },
    {
        "id": 13206,
        "spelling_name": "Contusion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21720,
                "word_type": "n.",
                "spelling_usage": "The act or process of beating, bruising, or pounding;\n   the state of being beaten or bruised."
            },
            {
                "spelling_detail_id": 21721,
                "word_type": "n.",
                "spelling_usage": "A bruise; an injury attended with more or less\n   disorganization of the subcutaneous tissue and effusion of blood\n   beneath the skin, but without apparent wound."
            }
        ]
    },
    {
        "id": 13207,
        "spelling_name": "Conundrum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21722,
                "word_type": "n.",
                "spelling_usage": "A kind of riddle based upon some fanciful or fantastic\n   resemblance between things quite unlike; a puzzling question, of which\n   the answer is or involves a pun."
            },
            {
                "spelling_detail_id": 21723,
                "word_type": "n.",
                "spelling_usage": "A question to which only a conjectural answer can be\n   made."
            }
        ]
    },
    {
        "id": 13329,
        "spelling_name": "Convince",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 21940,
                "word_type": "v. t.",
                "spelling_usage": "To overpower; to overcome; to subdue or master."
            },
            {
                "spelling_detail_id": 21941,
                "word_type": "v. t.",
                "spelling_usage": "To overcome by argument; to force to yield assent to\n   truth; to satisfy by proof."
            },
            {
                "spelling_detail_id": 21942,
                "word_type": "v. t.",
                "spelling_usage": "To confute; to prove the fallacy of."
            },
            {
                "spelling_detail_id": 21943,
                "word_type": "v. t.",
                "spelling_usage": "To prove guilty; to convict."
            }
        ]
    },
    {
        "id": 13374,
        "spelling_name": "Convulsive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22002,
                "word_type": "a.",
                "spelling_usage": "Producing, or attended with, convulsions or spasms;\n   characterized by convulsions; convulsionary."
            }
        ]
    },
    {
        "id": 13571,
        "spelling_name": "Coralline",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22306,
                "word_type": "a.",
                "spelling_usage": "Composed of corallines; as, coralline limestone."
            },
            {
                "spelling_detail_id": 22307,
                "word_type": "n.",
                "spelling_usage": "A submarine, semicalcareous or calcareous plant,\n   consisting of many jointed branches."
            },
            {
                "spelling_detail_id": 22308,
                "word_type": "n.",
                "spelling_usage": "Formerly any slender coral-like animal; -- sometimes\n   applied more particulary to bryozoan corals."
            }
        ]
    },
    {
        "id": 13617,
        "spelling_name": "Corduroy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22385,
                "word_type": "n.",
                "spelling_usage": "A sort of cotton velveteen, having the surface raised in\n   ridges."
            },
            {
                "spelling_detail_id": 22386,
                "word_type": "n.",
                "spelling_usage": "Trousers or breeches of corduroy."
            },
            {
                "spelling_detail_id": 22387,
                "word_type": "v. t.",
                "spelling_usage": "To form of logs laid side by side."
            }
        ]
    },
    {
        "id": 13627,
        "spelling_name": "Coriander",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22417,
                "word_type": "n.",
                "spelling_usage": "An umbelliferous plant, the Coriandrum sativum, the\n   fruit or seeds of which have a strong smell and a spicy taste, and in\n   medicine are considered as stomachic and carminative."
            }
        ]
    },
    {
        "id": 13666,
        "spelling_name": "Cornea",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22486,
                "word_type": "n.",
                "spelling_usage": "The transparent part of the coat of the eyeball which\n   covers the iris and pupil and admits light to the interior. See Eye."
            }
        ]
    },
    {
        "id": 13722,
        "spelling_name": "Cornucopia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22566,
                "word_type": "n.",
                "spelling_usage": "The horn of plenty, from which fruits and flowers are\n   represented as issuing. It is an emblem of abundance."
            },
            {
                "spelling_detail_id": 22567,
                "word_type": "n.",
                "spelling_usage": "A genus of grasses bearing spikes of flowers resembling\n   the cornucopia in form."
            }
        ]
    },
    {
        "id": 13735,
        "spelling_name": "Corollary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22584,
                "word_type": "n.",
                "spelling_usage": "That which is given beyond what is actually due, as a\n   garland of flowers in addition to wages; surplus; something added or\n   superfluous."
            },
            {
                "spelling_detail_id": 22585,
                "word_type": "n.",
                "spelling_usage": "Something which follows from the demonstration of a\n   proposition; an additional inference or deduction from a demonstrated\n   proposition; a consequence."
            }
        ]
    },
    {
        "id": 13796,
        "spelling_name": "Corpulent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22682,
                "word_type": "a.",
                "spelling_usage": "Very fat; obese."
            },
            {
                "spelling_detail_id": 22683,
                "word_type": "a.",
                "spelling_usage": "Solid; gross; opaque."
            }
        ]
    },
    {
        "id": 13808,
        "spelling_name": "Corral",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22702,
                "word_type": "n.",
                "spelling_usage": "A pen for animals; esp., an inclosure made with wagons, by\n   emigrants in the vicinity of hostile Indians, as a place of security\n   for horses, cattle, etc."
            },
            {
                "spelling_detail_id": 22705,
                "word_type": "v. t.",
                "spelling_usage": "To surround and inclose; to coop up; to put into an\n   inclosed space; -- primarily used with reference to securing horses and\n   cattle in an inclosure of wagons while traversing the plains, but in\n   the Southwestern United States now colloquially applied to the\n   capturing, securing, or penning of anything."
            }
        ]
    },
    {
        "id": 13881,
        "spelling_name": "Corrosive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22814,
                "word_type": "a.",
                "spelling_usage": "Eating away; having the power of gradually wearing,\n   changing, or destroying the texture or substance of a body; as, the\n   corrosive action of an acid."
            },
            {
                "spelling_detail_id": 22815,
                "word_type": "a.",
                "spelling_usage": "Having the quality of fretting or vexing."
            },
            {
                "spelling_detail_id": 22816,
                "word_type": "n.",
                "spelling_usage": "That which has the quality of eating or wearing away\n   gradually."
            },
            {
                "spelling_detail_id": 22817,
                "word_type": "n.",
                "spelling_usage": "That which has the power of fretting or irritating."
            }
        ]
    },
    {
        "id": 13909,
        "spelling_name": "Corsage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 22860,
                "word_type": "n.",
                "spelling_usage": "The waist or bodice of a lady's dress; as, a low corsage."
            },
            {
                "spelling_detail_id": 22861,
                "word_type": "n.",
                "spelling_usage": "a flower or small arrangement of flowers worn by a person\n   as a personal ornament. Typically worn by women on special occasions\n   (as, at a ball or an anniversary celebration), a corsage may be worn\n   pinned to the chest, or tied to the wrist. It is usually larger or more\n   elaborate than a boutonniere."
            }
        ]
    },
    {
        "id": 14298,
        "spelling_name": "Countless",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 23459,
                "word_type": "a.",
                "spelling_usage": "Incapable of being counted; not ascertainable;\n   innumerable."
            }
        ]
    },
    {
        "id": 14336,
        "spelling_name": "Courage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 23523,
                "word_type": "n.",
                "spelling_usage": "The heart; spirit; temper; disposition."
            },
            {
                "spelling_detail_id": 23524,
                "word_type": "n.",
                "spelling_usage": "Heart; inclination; desire; will."
            },
            {
                "spelling_detail_id": 23525,
                "word_type": "n.",
                "spelling_usage": "That quality of mind which enables one to encounter danger\n   and difficulties with firmness, or without fear, or fainting of heart;\n   valor; boldness; resolution."
            }
        ]
    },
    {
        "id": 14565,
        "spelling_name": "Cradle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 23947,
                "word_type": "n.",
                "spelling_usage": "A bed or cot for a baby, oscillating on rockers or swinging\n   on pivots; hence, the place of origin, or in which anything is nurtured\n   or protected in the earlier period of existence; as, a cradle of crime;\n   the cradle of liberty."
            },
            {
                "spelling_detail_id": 23948,
                "word_type": "n.",
                "spelling_usage": "Infancy, or very early life."
            },
            {
                "spelling_detail_id": 23949,
                "word_type": "n.",
                "spelling_usage": "An implement consisting of a broad scythe for cutting\n   grain, with a set of long fingers parallel to the scythe, designed to\n   receive the grain, and to lay it evenly in a swath."
            },
            {
                "spelling_detail_id": 23950,
                "word_type": "n.",
                "spelling_usage": "A tool used in mezzotint engraving, which, by a rocking\n   motion, raises burrs on the surface of the plate, so preparing the\n   ground."
            },
            {
                "spelling_detail_id": 23951,
                "word_type": "n.",
                "spelling_usage": "A framework of timbers, or iron bars, moving upon ways or\n   rollers, used to support, lift, or carry ships or other vessels, heavy\n   guns, etc., as up an inclined plane, or across a strip of land, or in\n   launching a ship."
            },
            {
                "spelling_detail_id": 23952,
                "word_type": "n.",
                "spelling_usage": "A case for a broken or dislocated limb."
            },
            {
                "spelling_detail_id": 23953,
                "word_type": "n.",
                "spelling_usage": "A frame to keep the bedclothes from contact with the\n   person."
            },
            {
                "spelling_detail_id": 23954,
                "word_type": "n.",
                "spelling_usage": "A machine on rockers, used in washing out auriferous earth;\n   -- also called a rocker."
            },
            {
                "spelling_detail_id": 23955,
                "word_type": "n.",
                "spelling_usage": "A suspended scaffold used in shafts."
            },
            {
                "spelling_detail_id": 23956,
                "word_type": "n.",
                "spelling_usage": "The ribbing for vaulted ceilings and arches intended to be\n   covered with plaster."
            },
            {
                "spelling_detail_id": 23957,
                "word_type": "n.",
                "spelling_usage": "The basket or apparatus in which, when a line has been made\n   fast to a wrecked ship from the shore, the people are brought off from\n   the wreck."
            },
            {
                "spelling_detail_id": 23960,
                "word_type": "v. t.",
                "spelling_usage": "To lay to rest, or rock, as in a cradle; to lull or\n   quiet, as by rocking."
            },
            {
                "spelling_detail_id": 23961,
                "word_type": "v. t.",
                "spelling_usage": "To nurse or train in infancy."
            },
            {
                "spelling_detail_id": 23962,
                "word_type": "v. t.",
                "spelling_usage": "To cut and lay with a cradle, as grain."
            },
            {
                "spelling_detail_id": 23963,
                "word_type": "v. t.",
                "spelling_usage": "To transport a vessel by means of a cradle."
            },
            {
                "spelling_detail_id": 23964,
                "word_type": "v. i.",
                "spelling_usage": "To lie or lodge, as in a cradle."
            }
        ]
    },
    {
        "id": 14634,
        "spelling_name": "Cranium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24080,
                "word_type": "n.",
                "spelling_usage": "The skull of an animal; especially, that part of the\n   skull, either cartilaginous or bony, which immediately incloses the\n   brain; the brain case or brainpan. See Skull."
            }
        ]
    },
    {
        "id": 14746,
        "spelling_name": "Crease",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24265,
                "word_type": "n.",
                "spelling_usage": "See Creese."
            },
            {
                "spelling_detail_id": 24266,
                "word_type": "n.",
                "spelling_usage": "A line or mark made by folding or doubling any pliable\n   substance; hence, a similar mark, however produced."
            },
            {
                "spelling_detail_id": 24267,
                "word_type": "n.",
                "spelling_usage": "One of the lines serving to define the limits of the bowler\n   and the striker."
            },
            {
                "spelling_detail_id": 24270,
                "word_type": "v. t.",
                "spelling_usage": "To make a crease or mark in, as by folding or doubling."
            }
        ]
    },
    {
        "id": 14799,
        "spelling_name": "Credulity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24355,
                "word_type": "n.",
                "spelling_usage": "Readiness of belief; a disposition to believe on slight\n   evidence."
            }
        ]
    },
    {
        "id": 14925,
        "spelling_name": "Cribbage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24580,
                "word_type": "v. t.",
                "spelling_usage": "A game of cards, played by two or four persons, in\n   which there is a crib. (See Crib, 11.) It is characterized by a great\n   variety of chances."
            }
        ]
    },
    {
        "id": 15002,
        "spelling_name": "Crinoline",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24702,
                "word_type": "n.",
                "spelling_usage": "A kind of stiff cloth, used chiefly by women, for\n   underskirts, to expand the gown worn over it; -- so called because\n   originally made of hair."
            },
            {
                "spelling_detail_id": 24703,
                "word_type": "n.",
                "spelling_usage": "A lady's skirt made of any stiff material; latterly, a\n   hoop skirt."
            }
        ]
    },
    {
        "id": 15034,
        "spelling_name": "Criteria",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24758,
                "word_type": "pl. ",
                "spelling_usage": "of Criterion"
            }
        ]
    },
    {
        "id": 15037,
        "spelling_name": "Crith",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24761,
                "word_type": "n.",
                "spelling_usage": "The unit for estimating the weight of a/riform substances;\n   -- the weight of a liter of hydrogen at 0/ centigrade, and with a\n   tension of 76 centimeters of mercury. It is 0.0896 of a gram, or\n   1.38274 grains."
            }
        ]
    },
    {
        "id": 15079,
        "spelling_name": "Crocodile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 24836,
                "word_type": "n.",
                "spelling_usage": "A large reptile of the genus Crocodilus, of several\n   species. They grow to the length of sixteen or eighteen feet, and\n   inhabit the large rivers of Africa, Asia, and America. The eggs, laid\n   in the sand, are hatched by the sun's heat. The best known species is\n   that of the Nile (C. vulgaris, or C. Niloticus). The Florida crocodile\n   (C. Americanus) is much less common than the alligator and has longer\n   jaws. The name is also sometimes applied to the species of other\n   related genera, as the gavial and the alligator."
            },
            {
                "spelling_detail_id": 24837,
                "word_type": "n.",
                "spelling_usage": "A fallacious dilemma, mythically supposed to have been\n   first used by a crocodile."
            }
        ]
    },
    {
        "id": 15298,
        "spelling_name": "Cruciferous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25232,
                "word_type": "a.",
                "spelling_usage": "Bearing a cross."
            },
            {
                "spelling_detail_id": 25233,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or resembling, a family of plants\n   which have four petals arranged like the arms of a cross, as the\n   mustard, radish, turnip, etc."
            }
        ]
    },
    {
        "id": 15317,
        "spelling_name": "Cruel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25264,
                "word_type": "n.",
                "spelling_usage": "See Crewel."
            },
            {
                "spelling_detail_id": 25265,
                "word_type": "a.",
                "spelling_usage": "Disposed to give pain to others; willing or pleased to hurt,\n   torment, or afflict; destitute of sympathetic kindness and pity;\n   savage; inhuman; hard-hearted; merciless."
            },
            {
                "spelling_detail_id": 25266,
                "word_type": "a.",
                "spelling_usage": "Causing, or fitted to cause, pain, grief, or misery."
            },
            {
                "spelling_detail_id": 25267,
                "word_type": "a.",
                "spelling_usage": "Attended with cruetly; painful; harsh."
            }
        ]
    },
    {
        "id": 15401,
        "spelling_name": "Crux",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25407,
                "word_type": "n.",
                "spelling_usage": "Anything that is very puzzling or difficult to explain."
            }
        ]
    },
    {
        "id": 15537,
        "spelling_name": "Cudgel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25613,
                "word_type": "n.",
                "spelling_usage": "A staff used in cudgel play, shorter than the quarterstaff,\n   and wielded with one hand; hence, any heavy stick used as a weapon."
            },
            {
                "spelling_detail_id": 25618,
                "word_type": "v. t.",
                "spelling_usage": "To beat with a cudgel."
            }
        ]
    },
    {
        "id": 15698,
        "spelling_name": "Cupola",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25861,
                "word_type": "n.",
                "spelling_usage": "A roof having a rounded form, hemispherical or nearly so;\n   also, a ceiling having the same form. When on a large scale it is\n   usually called dome."
            },
            {
                "spelling_detail_id": 25862,
                "word_type": "n.",
                "spelling_usage": "A small structure standing on the top of a dome; a lantern."
            },
            {
                "spelling_detail_id": 25863,
                "word_type": "n.",
                "spelling_usage": "A furnace for melting iron or other metals in large\n   quantity, -- used chiefly in foundries and steel works."
            },
            {
                "spelling_detail_id": 25864,
                "word_type": "n.",
                "spelling_usage": "A revolving shot-proof turret for heavy ordnance."
            },
            {
                "spelling_detail_id": 25865,
                "word_type": "n.",
                "spelling_usage": "The top of the spire of the cochlea of the ear."
            }
        ]
    },
    {
        "id": 15730,
        "spelling_name": "Curator",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25898,
                "word_type": "n.",
                "spelling_usage": "One who has the care and superintendence of anything, as\n   of a museum; a custodian; a keeper."
            },
            {
                "spelling_detail_id": 25899,
                "word_type": "n.",
                "spelling_usage": "One appointed to act as guardian of the estate of a person\n   not legally competent to manage it, or of an absentee; a trustee; a\n   guardian."
            }
        ]
    },
    {
        "id": 15768,
        "spelling_name": "Curio",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 25976,
                "word_type": "n.",
                "spelling_usage": "Any curiosity or article of virtu."
            }
        ]
    },
    {
        "id": 15789,
        "spelling_name": "Curmudgeon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26016,
                "word_type": "n.",
                "spelling_usage": "An avaricious, grasping fellow; a miser; a niggard; a\n   churl."
            }
        ]
    },
    {
        "id": 15819,
        "spelling_name": "Cursive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26079,
                "word_type": "a.",
                "spelling_usage": "Running; flowing."
            },
            {
                "spelling_detail_id": 26080,
                "word_type": "n.",
                "spelling_usage": "A character used in cursive writing."
            },
            {
                "spelling_detail_id": 26081,
                "word_type": "n.",
                "spelling_usage": "A manuscript, especially of the New Testament, written in\n   small, connected characters or in a running hand; -- opposed to uncial."
            }
        ]
    },
    {
        "id": 15853,
        "spelling_name": "Curtly",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26125,
                "word_type": "adv.",
                "spelling_usage": "In a curt manner."
            }
        ]
    },
    {
        "id": 15889,
        "spelling_name": "Cushion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26173,
                "word_type": "n.",
                "spelling_usage": "A case or bag stuffed with some soft and elastic material,\n   and used to sit or recline upon; a soft pillow or pad."
            },
            {
                "spelling_detail_id": 26174,
                "word_type": "n.",
                "spelling_usage": "Anything resembling a cushion in properties or use"
            },
            {
                "spelling_detail_id": 26175,
                "word_type": "n.",
                "spelling_usage": "a pad on which gilders cut gold leaf"
            },
            {
                "spelling_detail_id": 26176,
                "word_type": "n.",
                "spelling_usage": "a mass of steam in the end of the cylinder of a steam\n   engine to receive the impact of the piston"
            },
            {
                "spelling_detail_id": 26177,
                "word_type": "n.",
                "spelling_usage": "the elastic edge of a billiard table."
            },
            {
                "spelling_detail_id": 26178,
                "word_type": "n.",
                "spelling_usage": "A riotous kind of dance, formerly common at weddings; --\n   called also cushion dance."
            },
            {
                "spelling_detail_id": 26181,
                "word_type": "v. t.",
                "spelling_usage": "To seat or place on, or as on a cushion."
            },
            {
                "spelling_detail_id": 26182,
                "word_type": "v. t.",
                "spelling_usage": "To furnish with cushions; as, to cushion a chaise."
            },
            {
                "spelling_detail_id": 26183,
                "word_type": "v. t.",
                "spelling_usage": "To conceal or cover up, as under a cushion."
            }
        ]
    },
    {
        "id": 15932,
        "spelling_name": "Cutaneous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26280,
                "word_type": "a.",
                "spelling_usage": "Of pertaining to the skin; existing on, or affecting,\n   the skin; as, a cutaneous disease; cutaneous absorption; cutaneous\n   respiration."
            }
        ]
    },
    {
        "id": 15944,
        "spelling_name": "Cutis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26295,
                "word_type": "n.",
                "spelling_usage": "See Dermis."
            }
        ]
    },
    {
        "id": 15983,
        "spelling_name": "Cyanosis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26365,
                "word_type": "n.",
                "spelling_usage": "A condition in which, from insufficient a/ration of the\n   blood, the surface of the body becomes blue. See Cyanopathy."
            }
        ]
    },
    {
        "id": 16018,
        "spelling_name": "Cyclone",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26413,
                "word_type": "n.",
                "spelling_usage": "A violent storm, often of vast extent, characterized by\n   high winds rotating about a calm center of low atmospheric pressure.\n   This center moves onward, often with a velocity of twenty or thirty\n   miles an hour."
            }
        ]
    },
    {
        "id": 16045,
        "spelling_name": "Cylindrical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26449,
                "word_type": "a.",
                "spelling_usage": "Having the form of a cylinder, or of a section of its\n   convex surface; partaking of the properties of the cylinder."
            }
        ]
    },
    {
        "id": 16149,
        "spelling_name": "Cytoplasm",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26580,
                "word_type": "n.",
                "spelling_usage": "The substance of the body of a cell, as distinguished\n   from the karyoplasma, or substance of the nucleus."
            }
        ]
    },
    {
        "id": 16295,
        "spelling_name": "Dalmatian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26787,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Dalmatia."
            }
        ]
    },
    {
        "id": 16402,
        "spelling_name": "Dangerous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 26961,
                "word_type": "a.",
                "spelling_usage": "Attended or beset with danger; full of risk; perilous;\n   hazardous; unsafe."
            },
            {
                "spelling_detail_id": 26962,
                "word_type": "a.",
                "spelling_usage": "Causing danger; ready to do harm or injury."
            },
            {
                "spelling_detail_id": 26963,
                "word_type": "a.",
                "spelling_usage": "In a condition of danger, as from illness; threatened\n   with death."
            },
            {
                "spelling_detail_id": 26964,
                "word_type": "a.",
                "spelling_usage": "Hard to suit; difficult to please."
            },
            {
                "spelling_detail_id": 26965,
                "word_type": "a.",
                "spelling_usage": "Reserved; not affable."
            }
        ]
    },
    {
        "id": 16431,
        "spelling_name": "Dappled",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27002,
                "word_type": "a.",
                "spelling_usage": "Marked with spots of different shades of color; spotted;\n   variegated; as, a dapple horse."
            },
            {
                "spelling_detail_id": 27003,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Dapple"
            }
        ]
    },
    {
        "id": 16744,
        "spelling_name": "Debilitate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27551,
                "word_type": "v. t.",
                "spelling_usage": "To impair the strength of; to weaken; to enfeeble;\n   as, to debilitate the body by intemperance."
            }
        ]
    },
    {
        "id": 16767,
        "spelling_name": "Debris",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27574,
                "word_type": "n.",
                "spelling_usage": "Broken and detached fragments, taken collectively;\n   especially, fragments detached from a rock or mountain, and piled up at\n   the base."
            },
            {
                "spelling_detail_id": 27575,
                "word_type": "n.",
                "spelling_usage": "Rubbish, especially such as results from the destruction of\n   anything; remains; ruins."
            }
        ]
    },
    {
        "id": 16897,
        "spelling_name": "Decennial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27735,
                "word_type": "a.",
                "spelling_usage": "Consisting of ten years; happening every ten years; as,\n   a decennial period; decennial games."
            },
            {
                "spelling_detail_id": 27736,
                "word_type": "n.",
                "spelling_usage": "A tenth year or tenth anniversary."
            }
        ]
    },
    {
        "id": 16937,
        "spelling_name": "Deciduous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27788,
                "word_type": "a.",
                "spelling_usage": "Falling off, or subject to fall or be shed, at a certain\n   season, or a certain stage or interval of growth, as leaves (except of\n   evergreens) in autumn, or as parts of animals, such as hair, teeth,\n   antlers, etc.; also, shedding leaves or parts at certain seasons,\n   stages, or intervals; as, deciduous trees; the deciduous membrane."
            }
        ]
    },
    {
        "id": 16989,
        "spelling_name": "Declamatory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27867,
                "word_type": "a.",
                "spelling_usage": "Pertaining to declamation; treated in the manner of a\n   rhetorician; as, a declamatory theme."
            },
            {
                "spelling_detail_id": 27868,
                "word_type": "a.",
                "spelling_usage": "Characterized by rhetorical display; pretentiously\n   rhetorical; without solid sense or argument; bombastic; noisy; as, a\n   declamatory way or style."
            }
        ]
    },
    {
        "id": 17005,
        "spelling_name": "Declension",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 27892,
                "word_type": "n.",
                "spelling_usage": "The act or the state of declining; declination;\n   descent; slope."
            },
            {
                "spelling_detail_id": 27893,
                "word_type": "n.",
                "spelling_usage": "A falling off towards a worse state; a downward\n   tendency; deterioration; decay; as, the declension of virtue, of\n   science, of a state, etc."
            },
            {
                "spelling_detail_id": 27894,
                "word_type": "n.",
                "spelling_usage": "Act of courteously refusing; act of declining; a\n   declinature; refusal; as, the declension of a nomination."
            },
            {
                "spelling_detail_id": 27895,
                "word_type": "n.",
                "spelling_usage": "Inflection of nouns, adjectives, etc., according to the\n   grammatical cases."
            },
            {
                "spelling_detail_id": 27896,
                "word_type": "n.",
                "spelling_usage": "The form of the inflection of a word declined by cases;\n   as, the first or the second declension of nouns, adjectives, etc."
            },
            {
                "spelling_detail_id": 27897,
                "word_type": "n.",
                "spelling_usage": "Rehearsing a word as declined."
            }
        ]
    },
    {
        "id": 17097,
        "spelling_name": "Decrepitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 28045,
                "word_type": "n.",
                "spelling_usage": "The broken state produced by decay and the infirmities\n   of age; infirm old age."
            }
        ]
    },
    {
        "id": 17124,
        "spelling_name": "Decumbiture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 28076,
                "word_type": "n.",
                "spelling_usage": "Confinement to a sick bed, or time of taking to one's\n   bed from sickness."
            },
            {
                "spelling_detail_id": 28077,
                "word_type": "n.",
                "spelling_usage": "Aspect of the heavens at the time of taking to one's\n   sick bed, by which the prognostics of recovery or death were made."
            }
        ]
    },
    {
        "id": 17294,
        "spelling_name": "Deference",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 28362,
                "word_type": "n.",
                "spelling_usage": "A yielding of judgment or preference from respect to the\n   wishes or opinion of another; submission in opinion; regard; respect;\n   complaisance."
            }
        ]
    },
    {
        "id": 17306,
        "spelling_name": "Defiant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 28377,
                "word_type": "a.",
                "spelling_usage": "Full of defiance; bold; insolent; as, a defiant spirit or\n   act."
            }
        ]
    },
    {
        "id": 17513,
        "spelling_name": "Deities",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 28670,
                "word_type": "pl. ",
                "spelling_usage": "of Deity"
            }
        ]
    },
    {
        "id": 17758,
        "spelling_name": "Dementia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29056,
                "word_type": "n.",
                "spelling_usage": "Insanity; madness; esp. that form which consists in\n   weakness or total loss of thought and reason; mental imbecility;\n   idiocy."
            }
        ]
    },
    {
        "id": 17884,
        "spelling_name": "Demonstrative",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29223,
                "word_type": "a.",
                "spelling_usage": "Having the nature of demonstration; tending to\n   demonstrate; making evident; exhibiting clearly or conclusively."
            },
            {
                "spelling_detail_id": 29224,
                "word_type": "a.",
                "spelling_usage": "Expressing, or apt to express, much; displaying\n   feeling or sentiment; as, her nature was demonstrative."
            },
            {
                "spelling_detail_id": 29225,
                "word_type": "a.",
                "spelling_usage": "Consisting of eulogy or of invective."
            },
            {
                "spelling_detail_id": 29226,
                "word_type": "n.",
                "spelling_usage": "A demonstrative pronoun; as, \"this\" and \"that\" are\n   demonstratives."
            }
        ]
    },
    {
        "id": 17958,
        "spelling_name": "Denizen",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29329,
                "word_type": "n.",
                "spelling_usage": "A dweller; an inhabitant."
            },
            {
                "spelling_detail_id": 29330,
                "word_type": "n.",
                "spelling_usage": "One who is admitted by favor to all or a part of the\n   rights of citizenship, where he did not possess them by birth; an\n   adopted or naturalized citizen."
            },
            {
                "spelling_detail_id": 29331,
                "word_type": "n.",
                "spelling_usage": "One admitted to residence in a foreign country."
            },
            {
                "spelling_detail_id": 29332,
                "word_type": "v. t.",
                "spelling_usage": "To constitute (one) a denizen; to admit to residence,\n   with certain rights and privileges."
            },
            {
                "spelling_detail_id": 29333,
                "word_type": "v. t.",
                "spelling_usage": "To provide with denizens; to populate with adopted or\n   naturalized occupants."
            }
        ]
    },
    {
        "id": 17975,
        "spelling_name": "Denominator",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29357,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, gives a name; origin or source\n   of a name."
            },
            {
                "spelling_detail_id": 29358,
                "word_type": "n.",
                "spelling_usage": "That number placed below the line in vulgar fractions\n   which shows into how many parts the integer or unit is divided."
            },
            {
                "spelling_detail_id": 29359,
                "word_type": "n.",
                "spelling_usage": "That part of any expression under a fractional form\n   which is situated below the horizontal line signifying division."
            }
        ]
    },
    {
        "id": 18015,
        "spelling_name": "Denticulate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29415,
                "word_type": "a.",
                "spelling_usage": "Alt. of Denticulated"
            }
        ]
    },
    {
        "id": 18020,
        "spelling_name": "Dentifrice",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29421,
                "word_type": "n.",
                "spelling_usage": "A powder or other substance to be used in cleaning the\n   teeth; tooth powder."
            }
        ]
    },
    {
        "id": 18263,
        "spelling_name": "Depredation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29775,
                "word_type": "n.",
                "spelling_usage": "The act of depredating, or the state of being\n   depredated; the act of despoiling or making inroads; as, the sea often\n   makes depredation on the land."
            }
        ]
    },
    {
        "id": 18345,
        "spelling_name": "Derelict",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29901,
                "word_type": "a.",
                "spelling_usage": "Given up or forsaken by the natural owner or guardian;\n   left and abandoned; as, derelict lands."
            },
            {
                "spelling_detail_id": 29902,
                "word_type": "a.",
                "spelling_usage": "Lost; adrift; hence, wanting; careless; neglectful;\n   unfaithful."
            },
            {
                "spelling_detail_id": 29903,
                "word_type": "n.",
                "spelling_usage": "A thing voluntary abandoned or willfully cast away by its\n   proper owner, especially a ship abandoned at sea."
            },
            {
                "spelling_detail_id": 29904,
                "word_type": "n.",
                "spelling_usage": "A tract of land left dry by the sea, and fit for\n   cultivation or use."
            }
        ]
    },
    {
        "id": 18357,
        "spelling_name": "Derisive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 29920,
                "word_type": "a.",
                "spelling_usage": "Expressing, serving for, or characterized by, derision."
            }
        ]
    },
    {
        "id": 18472,
        "spelling_name": "Deserter",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 30107,
                "word_type": "n.",
                "spelling_usage": "One who forsakes a duty, a cause or a party, a friend, or\n   any one to whom he owes service; especially, a soldier or a seaman who\n   abandons the service without leave; one guilty of desertion."
            }
        ]
    },
    {
        "id": 18520,
        "spelling_name": "Designer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 30184,
                "word_type": "n.",
                "spelling_usage": "One who designs, marks out, or plans; a contriver."
            },
            {
                "spelling_detail_id": 30185,
                "word_type": "n.",
                "spelling_usage": "One who produces or creates original works of art or\n   decoration."
            },
            {
                "spelling_detail_id": 30186,
                "word_type": "n.",
                "spelling_usage": "A plotter; a schemer; -- used in a bad sense."
            }
        ]
    },
    {
        "id": 18701,
        "spelling_name": "Desultorily",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 30423,
                "word_type": "adv.",
                "spelling_usage": "In a desultory manner; without method; loosely;\n   immethodically."
            }
        ]
    },
    {
        "id": 18739,
        "spelling_name": "Detergent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 30485,
                "word_type": "a.",
                "spelling_usage": "Cleansing; purging."
            },
            {
                "spelling_detail_id": 30486,
                "word_type": "n.",
                "spelling_usage": "A substance which cleanses the skin, as water or soap; a\n   medicine to cleanse wounds, ulcers, etc."
            }
        ]
    },
    {
        "id": 18827,
        "spelling_name": "Detritus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 30614,
                "word_type": "n.",
                "spelling_usage": "A mass of substances worn off from solid bodies by\n   attrition, and reduced to small portions; as, diluvial detritus."
            },
            {
                "spelling_detail_id": 30615,
                "word_type": "n.",
                "spelling_usage": "Hence: Any fragments separated from the body to which\n   they belonged; any product of disintegration."
            }
        ]
    },
    {
        "id": 19072,
        "spelling_name": "Diacritic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 30955,
                "word_type": "a.",
                "spelling_usage": "Alt. of Diacritical"
            }
        ]
    },
    {
        "id": 19135,
        "spelling_name": "Dialysis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31046,
                "word_type": "n.",
                "spelling_usage": "Diaeresis. See Diaeresis, 1."
            },
            {
                "spelling_detail_id": 31047,
                "word_type": "n.",
                "spelling_usage": "Same as Asyndeton."
            },
            {
                "spelling_detail_id": 31048,
                "word_type": "n.",
                "spelling_usage": "Debility."
            },
            {
                "spelling_detail_id": 31049,
                "word_type": "n.",
                "spelling_usage": "A solution of continuity; division; separation of parts."
            },
            {
                "spelling_detail_id": 31050,
                "word_type": "n.",
                "spelling_usage": "The separation of different substances in solution, as\n   crystalloids and colloids, by means of their unequal diffusion,\n   especially through natural or artificial membranes."
            }
        ]
    },
    {
        "id": 19172,
        "spelling_name": "Dianthus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31102,
                "word_type": "n.",
                "spelling_usage": "A genus of plants containing some of the most popular of\n   cultivated flowers, including the pink, carnation, and Sweet William."
            }
        ]
    },
    {
        "id": 19188,
        "spelling_name": "Diaphanous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31129,
                "word_type": "a.",
                "spelling_usage": "Allowing light to pass through, as porcelain;\n   translucent or transparent; pellucid; clear."
            }
        ]
    },
    {
        "id": 19335,
        "spelling_name": "Dictum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31324,
                "word_type": "n.",
                "spelling_usage": "An authoritative statement; a dogmatic saying; an apothegm."
            },
            {
                "spelling_detail_id": 31325,
                "word_type": "n.",
                "spelling_usage": "A judicial opinion expressed by judges on points that do\n   not necessarily arise in the case, and are not involved in it."
            },
            {
                "spelling_detail_id": 31326,
                "word_type": "n.",
                "spelling_usage": "The report of a judgment made by one of the judges who has\n   given it."
            },
            {
                "spelling_detail_id": 31327,
                "word_type": "n.",
                "spelling_usage": "An arbitrament or award."
            }
        ]
    },
    {
        "id": 19534,
        "spelling_name": "Dignify",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31635,
                "word_type": "v. t.",
                "spelling_usage": "To invest with dignity or honor; to make illustrious;\n   to give distinction to; to exalt in rank; to honor."
            }
        ]
    },
    {
        "id": 19548,
        "spelling_name": "Digression",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31655,
                "word_type": "n.",
                "spelling_usage": "The act of digressing or deviating, esp. from the main\n   subject of a discourse; hence, a part of a discourse deviating from its\n   main design or subject."
            },
            {
                "spelling_detail_id": 31656,
                "word_type": "n.",
                "spelling_usage": "A turning aside from the right path; transgression;\n   offense."
            },
            {
                "spelling_detail_id": 31657,
                "word_type": "n.",
                "spelling_usage": "The elongation, or angular distance from the sun; --\n   said chiefly of the inferior planets."
            }
        ]
    },
    {
        "id": 19610,
        "spelling_name": "Diligence",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 31748,
                "word_type": "n.",
                "spelling_usage": "The quality of being diligent; carefulness; careful\n   attention; -- the opposite of negligence."
            },
            {
                "spelling_detail_id": 31749,
                "word_type": "n.",
                "spelling_usage": "Interested and persevering application; devoted and\n   painstaking effort to accomplish what is undertaken; assiduity in\n   service."
            },
            {
                "spelling_detail_id": 31750,
                "word_type": "n.",
                "spelling_usage": "Process by which persons, lands, or effects are seized\n   for debt; process for enforcing the attendance of witnesses or the\n   production of writings."
            },
            {
                "spelling_detail_id": 31751,
                "word_type": "n.",
                "spelling_usage": "A four-wheeled public stagecoach, used in France."
            }
        ]
    },
    {
        "id": 20186,
        "spelling_name": "Discomfiture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 32606,
                "word_type": "v. t.",
                "spelling_usage": "The act of discomfiting, or the state of being\n   discomfited; rout; overthrow; defeat; frustration; confusion and\n   dejection."
            }
        ]
    },
    {
        "id": 20266,
        "spelling_name": "Discountenance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 32727,
                "word_type": "v. t.",
                "spelling_usage": "To ruffle or discompose the countenance of; to\n   put of countenance; to put to shame; to abash."
            },
            {
                "spelling_detail_id": 32728,
                "word_type": "v. t.",
                "spelling_usage": "To refuse to countenance, or give the support of\n   one's approval to; to give one's influence against; to restrain by cold\n   treatment; to discourage."
            },
            {
                "spelling_detail_id": 32729,
                "word_type": "n.",
                "spelling_usage": "Unfavorable aspect; unfriendly regard; cold\n   treatment; disapprobation; whatever tends to check or discourage."
            }
        ]
    },
    {
        "id": 20581,
        "spelling_name": "Dishevel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33175,
                "word_type": "v. t.",
                "spelling_usage": "To suffer (the hair) to hang loosely or disorderly; to\n   spread or throw (the hair) in disorder; -- used chiefly in the passive\n   participle."
            },
            {
                "spelling_detail_id": 33176,
                "word_type": "v. t.",
                "spelling_usage": "To spread loosely or disorderly."
            },
            {
                "spelling_detail_id": 33177,
                "word_type": "v. i.",
                "spelling_usage": "To be spread in disorder or hang negligently, as the\n   hair."
            }
        ]
    },
    {
        "id": 20713,
        "spelling_name": "Dismal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33363,
                "word_type": "a.",
                "spelling_usage": "Fatal; ill-omened; unlucky."
            },
            {
                "spelling_detail_id": 33364,
                "word_type": "a.",
                "spelling_usage": "Gloomy to the eye or ear; sorrowful and depressing to the\n   feelings; foreboding; cheerless; dull; dreary; as, a dismal outlook;\n   dismal stories; a dismal place."
            }
        ]
    },
    {
        "id": 20813,
        "spelling_name": "Disparate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33505,
                "word_type": "a.",
                "spelling_usage": "Unequal; dissimilar; separate."
            },
            {
                "spelling_detail_id": 33506,
                "word_type": "a.",
                "spelling_usage": "Pertaining to two coordinate species or divisions."
            }
        ]
    },
    {
        "id": 20937,
        "spelling_name": "Disposition",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33711,
                "word_type": "n.",
                "spelling_usage": "The act of disposing, arranging, ordering, regulating,\n   or transferring; application; disposal; as, the disposition of a man's\n   property by will."
            },
            {
                "spelling_detail_id": 33712,
                "word_type": "n.",
                "spelling_usage": "The state or the manner of being disposed or arranged;\n   distribution; arrangement; order; as, the disposition of the trees in\n   an orchard; the disposition of the several parts of an edifice."
            },
            {
                "spelling_detail_id": 33713,
                "word_type": "n.",
                "spelling_usage": "Tendency to any action or state resulting from natural\n   constitution; nature; quality; as, a disposition in plants to grow in a\n   direction upward; a disposition in bodies to putrefaction."
            },
            {
                "spelling_detail_id": 33714,
                "word_type": "n.",
                "spelling_usage": "Conscious inclination; propension or propensity."
            },
            {
                "spelling_detail_id": 33715,
                "word_type": "n.",
                "spelling_usage": "Natural or prevailing spirit, or temperament of mind,\n   especially as shown in intercourse with one's fellow-men; temper of\n   mind."
            },
            {
                "spelling_detail_id": 33716,
                "word_type": "n.",
                "spelling_usage": "Mood; humor."
            }
        ]
    },
    {
        "id": 20974,
        "spelling_name": "Disproportionate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33764,
                "word_type": "a.",
                "spelling_usage": "Not proportioned; unsymmetrical; unsuitable to\n   something else in bulk, form, value, or extent; out of proportion;\n   inadequate; as, in a perfect body none of the limbs are\n   disproportionate; it is wisdom not to undertake a work disproportionate\n   means."
            }
        ]
    },
    {
        "id": 21043,
        "spelling_name": "Disrepair",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33852,
                "word_type": "n.",
                "spelling_usage": "A state of being in bad condition, and wanting repair."
            }
        ]
    },
    {
        "id": 21095,
        "spelling_name": "Dissemble",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 33918,
                "word_type": "v. t.",
                "spelling_usage": "To hide under a false semblance or seeming; to feign\n   (something) not to be what it really is; to put an untrue appearance\n   upon; to disguise; to mask."
            },
            {
                "spelling_detail_id": 33919,
                "word_type": "v. t.",
                "spelling_usage": "To put on the semblance of; to make pretense of; to\n   simulate; to feign."
            },
            {
                "spelling_detail_id": 33920,
                "word_type": "v. i.",
                "spelling_usage": "To conceal the real fact, motives, /tention, or\n   sentiments, under some pretense; to assume a false appearance; to act\n   the hypocrite."
            }
        ]
    },
    {
        "id": 21164,
        "spelling_name": "Dissipate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34006,
                "word_type": "v. t.",
                "spelling_usage": "To scatter completely; to disperse and cause to\n   disappear; -- used esp. of the dispersion of things that can never\n   again be collected or restored."
            },
            {
                "spelling_detail_id": 34007,
                "word_type": "v. t.",
                "spelling_usage": "To destroy by wasteful extravagance or lavish use; to\n   squander."
            },
            {
                "spelling_detail_id": 34008,
                "word_type": "v. i.",
                "spelling_usage": "To separate into parts and disappear; to waste away;\n   to scatter; to disperse; to vanish; as, a fog or cloud gradually\n   dissipates before the rays or heat of the sun; the heat of a body\n   dissipates."
            },
            {
                "spelling_detail_id": 34009,
                "word_type": "v. i.",
                "spelling_usage": "To be extravagant, wasteful, or dissolute in the\n   pursuit of pleasure; to engage in dissipation."
            }
        ]
    },
    {
        "id": 21197,
        "spelling_name": "Dissonance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34068,
                "word_type": "n.",
                "spelling_usage": "A mingling of discordant sounds; an inharmonious\n   combination of sounds; discord."
            },
            {
                "spelling_detail_id": 34069,
                "word_type": "n.",
                "spelling_usage": "Want of agreement; incongruity."
            }
        ]
    },
    {
        "id": 21444,
        "spelling_name": "Diurnal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34494,
                "word_type": "a.",
                "spelling_usage": "Relating to the daytime; belonging to the period of\n   daylight, distinguished from the night; -- opposed to nocturnal; as,\n   diurnal heat; diurnal hours."
            },
            {
                "spelling_detail_id": 34495,
                "word_type": "a.",
                "spelling_usage": "Daily; recurring every day; performed in a day; going\n   through its changes in a day; constituting the measure of a day; as, a\n   diurnal fever; a diurnal task; diurnal aberration, or diurnal parallax;\n   the diurnal revolution of the earth."
            },
            {
                "spelling_detail_id": 34496,
                "word_type": "a.",
                "spelling_usage": "Opening during the day, and closing at night; -- said of\n   flowers or leaves."
            },
            {
                "spelling_detail_id": 34497,
                "word_type": "a.",
                "spelling_usage": "Active by day; -- applied especially to the eagles and\n   hawks among raptorial birds, and to butterflies (Diurna) among insects."
            },
            {
                "spelling_detail_id": 34498,
                "word_type": "a.",
                "spelling_usage": "A daybook; a journal."
            },
            {
                "spelling_detail_id": 34499,
                "word_type": "a.",
                "spelling_usage": "A small volume containing the daily service for the\n   \"little hours,\" viz., prime, tierce, sext, nones, vespers, and\n   compline."
            },
            {
                "spelling_detail_id": 34500,
                "word_type": "a.",
                "spelling_usage": "A diurnal bird or insect."
            }
        ]
    },
    {
        "id": 21471,
        "spelling_name": "Diverge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34550,
                "word_type": "v. i.",
                "spelling_usage": "To extend from a common point in different directions;\n   to tend from one point and recede from each other; to tend to spread\n   apart; to turn aside or deviate (as from a given direction); -- opposed\n   to converge; as, rays of light diverge as they proceed from the sun."
            },
            {
                "spelling_detail_id": 34551,
                "word_type": "v. i.",
                "spelling_usage": "To differ from a typical form; to vary from a normal\n   condition; to dissent from a creed or position generally held or taken."
            }
        ]
    },
    {
        "id": 21516,
        "spelling_name": "Divestiture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34617,
                "word_type": "n.",
                "spelling_usage": "The act of stripping, or depriving; the state of being\n   divested; the deprivation, or surrender, of possession of property,\n   rights, etc."
            }
        ]
    },
    {
        "id": 21658,
        "spelling_name": "Documentary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34888,
                "word_type": "a.",
                "spelling_usage": "Pertaining to written evidence; contained or certified\n   in writing."
            }
        ]
    },
    {
        "id": 21670,
        "spelling_name": "Dodecahedron",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 34901,
                "word_type": "n.",
                "spelling_usage": "A solid having twelve faces."
            }
        ]
    },
    {
        "id": 21779,
        "spelling_name": "Dolce",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35049,
                "word_type": "adv.",
                "spelling_usage": "Alt. of Dolcemente"
            }
        ]
    },
    {
        "id": 21782,
        "spelling_name": "Doldrums",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35053,
                "word_type": "n. pl.",
                "spelling_usage": "A part of the ocean near the equator, abounding in\n   calms, squalls, and light, baffling winds, which sometimes prevent all\n   progress for weeks; -- so called by sailors."
            }
        ]
    },
    {
        "id": 21846,
        "spelling_name": "Domesticity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35166,
                "word_type": "n.",
                "spelling_usage": "The state of being domestic; domestic character;\n   household life."
            }
        ]
    },
    {
        "id": 21851,
        "spelling_name": "Domiciled",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35172,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Domicile"
            }
        ]
    },
    {
        "id": 22086,
        "spelling_name": "Doubt",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35562,
                "word_type": "v. i.",
                "spelling_usage": "To waver in opinion or judgment; to be in uncertainty as\n   to belief respecting anything; to hesitate in belief; to be undecided\n   as to the truth of the negative or the affirmative proposition; to b e\n   undetermined."
            },
            {
                "spelling_detail_id": 35563,
                "word_type": "v. i.",
                "spelling_usage": "To suspect; to fear; to be apprehensive."
            },
            {
                "spelling_detail_id": 35564,
                "word_type": "v. t.",
                "spelling_usage": "To question or hold questionable; to withhold assent to;\n   to hesitate to believe, or to be inclined not to believe; to withhold\n   confidence from; to distrust; as, I have heard the story, but I doubt\n   the truth of it."
            },
            {
                "spelling_detail_id": 35565,
                "word_type": "v. t.",
                "spelling_usage": "To suspect; to fear; to be apprehensive of."
            },
            {
                "spelling_detail_id": 35566,
                "word_type": "v. t.",
                "spelling_usage": "To fill with fear; to affright."
            },
            {
                "spelling_detail_id": 35567,
                "word_type": "v. i.",
                "spelling_usage": "A fluctuation of mind arising from defect of knowledge or\n   evidence; uncertainty of judgment or mind; unsettled state of opinion\n   concerning the reality of an event, or the truth of an assertion, etc.;\n   hesitation."
            },
            {
                "spelling_detail_id": 35568,
                "word_type": "v. i.",
                "spelling_usage": "Uncertainty of condition."
            },
            {
                "spelling_detail_id": 35569,
                "word_type": "v. i.",
                "spelling_usage": "Suspicion; fear; apprehension; dread."
            },
            {
                "spelling_detail_id": 35570,
                "word_type": "v. i.",
                "spelling_usage": "Difficulty expressed or urged for solution; point\n   unsettled; objection."
            }
        ]
    },
    {
        "id": 22148,
        "spelling_name": "Dowager",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35655,
                "word_type": "n.",
                "spelling_usage": "A widow endowed, or having a jointure; a widow who either\n   enjoys a dower from her deceased husband, or has property of her own\n   brought by her to her husband on marriage, and settled on her after his\n   decease."
            },
            {
                "spelling_detail_id": 35656,
                "word_type": "n.",
                "spelling_usage": "A title given in England to a widow, to distinguish her\n   from the wife of her husband's heir bearing the same name; -- chiefly\n   applied to widows of personages of rank."
            }
        ]
    },
    {
        "id": 22247,
        "spelling_name": "Draconian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35824,
                "word_type": "a.",
                "spelling_usage": "Pertaining to Draco, a famous lawgiver of Athens, 621 b.\n   c."
            }
        ]
    },
    {
        "id": 22290,
        "spelling_name": "Dragoon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35913,
                "word_type": "n.",
                "spelling_usage": "Formerly, a soldier who was taught and armed to serve\n   either on horseback or on foot; now, a mounted soldier; a cavalry man."
            },
            {
                "spelling_detail_id": 35914,
                "word_type": "n.",
                "spelling_usage": "A variety of pigeon."
            },
            {
                "spelling_detail_id": 35917,
                "word_type": "v. t.",
                "spelling_usage": "To harass or reduce to subjection by dragoons; to\n   persecute by abandoning a place to the rage of soldiers."
            },
            {
                "spelling_detail_id": 35918,
                "word_type": "v. t.",
                "spelling_usage": "To compel submission by violent measures; to harass; to\n   persecute."
            }
        ]
    },
    {
        "id": 22316,
        "spelling_name": "Dramatization",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35964,
                "word_type": "n.",
                "spelling_usage": "Act of dramatizing."
            }
        ]
    },
    {
        "id": 22322,
        "spelling_name": "Dramaturgy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35970,
                "word_type": "n.",
                "spelling_usage": "The art of dramatic composition and representation."
            }
        ]
    },
    {
        "id": 22328,
        "spelling_name": "Drape",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 35979,
                "word_type": "v. t.",
                "spelling_usage": "To cover or adorn with drapery or folds of cloth, or as\n   with drapery; as, to drape a bust, a building, etc."
            },
            {
                "spelling_detail_id": 35980,
                "word_type": "v. t.",
                "spelling_usage": "To rail at; to banter."
            },
            {
                "spelling_detail_id": 35981,
                "word_type": "v. i.",
                "spelling_usage": "To make cloth."
            },
            {
                "spelling_detail_id": 35982,
                "word_type": "v. i.",
                "spelling_usage": "To design drapery, arrange its folds, etc., as for\n   hangings, costumes, statues, etc."
            }
        ]
    },
    {
        "id": 22553,
        "spelling_name": "Dromedary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 36498,
                "word_type": "n.",
                "spelling_usage": "The Arabian camel (Camelus dromedarius), having one hump\n   or protuberance on the back, in distinction from the Bactrian camel,\n   which has two humps."
            }
        ]
    },
    {
        "id": 22627,
        "spelling_name": "Drowsy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 36637,
                "word_type": "superl.",
                "spelling_usage": "Inclined to drowse; heavy with sleepiness; lethargic;\n   dozy."
            },
            {
                "spelling_detail_id": 36638,
                "word_type": "superl.",
                "spelling_usage": "Disposing to sleep; lulling; soporific."
            },
            {
                "spelling_detail_id": 36639,
                "word_type": "superl.",
                "spelling_usage": "Dull; stupid."
            }
        ]
    },
    {
        "id": 22733,
        "spelling_name": "Dubiously",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 36829,
                "word_type": "adv.",
                "spelling_usage": "In a dubious manner."
            }
        ]
    },
    {
        "id": 22750,
        "spelling_name": "Duchy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 36846,
                "word_type": "n.",
                "spelling_usage": "The territory or dominions of a duke; a dukedom."
            }
        ]
    },
    {
        "id": 22812,
        "spelling_name": "Dulcet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 36952,
                "word_type": "a.",
                "spelling_usage": "Sweet to the taste; luscious."
            },
            {
                "spelling_detail_id": 36953,
                "word_type": "a.",
                "spelling_usage": "Sweet to the ear; melodious; harmonious."
            }
        ]
    },
    {
        "id": 22922,
        "spelling_name": "Duodenum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37118,
                "word_type": "n.",
                "spelling_usage": "The part of the small intestines between the stomach and\n   the jejunum. See Illust. of Digestive apparatus, under Digestive."
            }
        ]
    },
    {
        "id": 22964,
        "spelling_name": "Duress",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37175,
                "word_type": "n.",
                "spelling_usage": "Hardship; constraint; pressure; imprisonment; restraint of\n   liberty."
            },
            {
                "spelling_detail_id": 37176,
                "word_type": "n.",
                "spelling_usage": "The state of compulsion or necessity in which a person is\n   influenced, whether by the unlawful restrain of his liberty or by\n   actual or threatened physical violence, to incur a civil liability or\n   to commit an offense."
            },
            {
                "spelling_detail_id": 37177,
                "word_type": "v. t.",
                "spelling_usage": "To subject to duress."
            }
        ]
    },
    {
        "id": 23065,
        "spelling_name": "Dynamite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37337,
                "word_type": "n.",
                "spelling_usage": "An explosive substance consisting of nitroglycerin\n   absorbed by some inert, porous solid, as infusorial earth, sawdust,\n   etc. It is safer than nitroglycerin, being less liable to explosion\n   from moderate shocks, or from spontaneous decomposition."
            }
        ]
    },
    {
        "id": 23103,
        "spelling_name": "Dyspeptic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37378,
                "word_type": "a.",
                "spelling_usage": "Alt. of Dyspeptical"
            },
            {
                "spelling_detail_id": 37380,
                "word_type": "n.",
                "spelling_usage": "A person afflicted with dyspepsia."
            }
        ]
    },
    {
        "id": 23297,
        "spelling_name": "Ebullience",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37704,
                "word_type": "n.",
                "spelling_usage": "Alt. of Ebulliency"
            }
        ]
    },
    {
        "id": 23350,
        "spelling_name": "Echelon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37774,
                "word_type": "n.",
                "spelling_usage": "An arrangement of a body of troops when its divisions are\n   drawn up in parallel lines each to the right or the left of the one in\n   advance of it, like the steps of a ladder in position for climbing.\n   Also used adjectively; as, echelon distance."
            },
            {
                "spelling_detail_id": 37775,
                "word_type": "n.",
                "spelling_usage": "An arrangement of a fleet in a wedge or V formation."
            },
            {
                "spelling_detail_id": 37776,
                "word_type": "v. t.",
                "spelling_usage": "To place in echelon; to station divisions of troops in\n   echelon."
            },
            {
                "spelling_detail_id": 37777,
                "word_type": "v. i.",
                "spelling_usage": "To take position in echelon."
            }
        ]
    },
    {
        "id": 23361,
        "spelling_name": "Echinoderm",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37788,
                "word_type": "n.",
                "spelling_usage": "One of the Echinodermata."
            }
        ]
    },
    {
        "id": 23372,
        "spelling_name": "Echoed",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37809,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Echo"
            }
        ]
    },
    {
        "id": 23392,
        "spelling_name": "Eclipse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37836,
                "word_type": "n.",
                "spelling_usage": "An interception or obscuration of the light of the sun,\n   moon, or other luminous body, by the intervention of some other body,\n   either between it and the eye, or between the luminous body and that\n   illuminated by it. A lunar eclipse is caused by the moon passing\n   through the earth's shadow; a solar eclipse, by the moon coming between\n   the sun and the observer. A satellite is eclipsed by entering the\n   shadow of its primary. The obscuration of a planet or star by the moon\n   or a planet, though of the nature of an eclipse, is called an\n   occultation. The eclipse of a small portion of the sun by Mercury or\n   Venus is called a transit of the planet."
            },
            {
                "spelling_detail_id": 37837,
                "word_type": "n.",
                "spelling_usage": "The loss, usually temporary or partial, of light,\n   brilliancy, luster, honor, consciousness, etc.; obscuration; gloom;\n   darkness."
            },
            {
                "spelling_detail_id": 37840,
                "word_type": "v. t.",
                "spelling_usage": "To cause the obscuration of; to darken or hide; -- said\n   of a heavenly body; as, the moon eclipses the sun."
            },
            {
                "spelling_detail_id": 37841,
                "word_type": "v. t.",
                "spelling_usage": "To obscure, darken, or extinguish the beauty, luster,\n   honor, etc., of; to sully; to cloud; to throw into the shade by\n   surpassing."
            },
            {
                "spelling_detail_id": 37842,
                "word_type": "v. i.",
                "spelling_usage": "To suffer an eclipse."
            }
        ]
    },
    {
        "id": 23424,
        "spelling_name": "Ecstatic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37892,
                "word_type": "n.",
                "spelling_usage": "Pertaining to, or caused by, ecstasy or excessive\n   emotion; of the nature, or in a state, of ecstasy; as, ecstatic gaze;\n   ecstatic trance."
            },
            {
                "spelling_detail_id": 37893,
                "word_type": "n.",
                "spelling_usage": "Delightful beyond measure; rapturous; ravishing; as,\n   ecstatic bliss or joy."
            },
            {
                "spelling_detail_id": 37894,
                "word_type": "n.",
                "spelling_usage": "An enthusiast."
            }
        ]
    },
    {
        "id": 23455,
        "spelling_name": "Ectoplasm",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37930,
                "word_type": "n.",
                "spelling_usage": "The outer transparent layer of protoplasm in a\n   developing ovum."
            },
            {
                "spelling_detail_id": 37931,
                "word_type": "n.",
                "spelling_usage": "The outer hyaline layer of protoplasm in a vegetable\n   cell."
            },
            {
                "spelling_detail_id": 37932,
                "word_type": "n.",
                "spelling_usage": "The ectosarc of protozoan."
            }
        ]
    },
    {
        "id": 23475,
        "spelling_name": "Eczema",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37954,
                "word_type": "n.",
                "spelling_usage": "An inflammatory disease of the skin, characterized by the\n   presence of redness and itching, an eruption of small vesicles, and the\n   discharge of a watery exudation, which often dries up, leaving the skin\n   covered with crusts; -- called also tetter, milk crust, and salt rheum."
            }
        ]
    },
    {
        "id": 23488,
        "spelling_name": "Eddy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37969,
                "word_type": "n.",
                "spelling_usage": "A current of air or water running back, or in a direction\n   contrary to the main current."
            },
            {
                "spelling_detail_id": 37970,
                "word_type": "n.",
                "spelling_usage": "A current of water or air moving in a circular direction; a\n   whirlpool."
            },
            {
                "spelling_detail_id": 37973,
                "word_type": "v. i.",
                "spelling_usage": "To move as an eddy, or as in an eddy; to move in a circle."
            },
            {
                "spelling_detail_id": 37974,
                "word_type": "v. t.",
                "spelling_usage": "To collect as into an eddy."
            }
        ]
    },
    {
        "id": 23495,
        "spelling_name": "Edenic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 37980,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Eden; paradisaic."
            }
        ]
    },
    {
        "id": 23523,
        "spelling_name": "Edification",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38025,
                "word_type": "n.",
                "spelling_usage": "The act of edifying, or the state of being edified; a\n   building up, especially in a moral or spiritual sense; moral,\n   intellectual, or spiritual improvement; instruction."
            },
            {
                "spelling_detail_id": 38026,
                "word_type": "n.",
                "spelling_usage": "A building or edifice."
            }
        ]
    },
    {
        "id": 23587,
        "spelling_name": "Eerily",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38107,
                "word_type": "adv.",
                "spelling_usage": "In a strange, unearthly way."
            }
        ]
    },
    {
        "id": 23592,
        "spelling_name": "Efface",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38113,
                "word_type": "v. t.",
                "spelling_usage": "To cause to disappear (as anything impresses or\n   inscribed upon a surface) by rubbing out, striking out, etc.; to erase;\n   to render illegible or indiscernible; as, to efface the letters on a\n   monument, or the inscription on a coin."
            },
            {
                "spelling_detail_id": 38114,
                "word_type": "v. t.",
                "spelling_usage": "To destroy, as a mental impression; to wear away."
            }
        ]
    },
    {
        "id": 23641,
        "spelling_name": "Effete",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38182,
                "word_type": "a.",
                "spelling_usage": "No longer capable of producing young, as an animal, or\n   fruit, as the earth; hence, worn out with age; exhausted of energy;\n   incapable of efficient action; no longer productive; barren; sterile."
            }
        ]
    },
    {
        "id": 23706,
        "spelling_name": "Effusive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38273,
                "word_type": "a.",
                "spelling_usage": "Pouring out; pouring forth freely."
            }
        ]
    },
    {
        "id": 23849,
        "spelling_name": "Elaborative",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38471,
                "word_type": "a.",
                "spelling_usage": "Serving or tending to elaborate; constructing with\n   labor and minute attention to details."
            }
        ]
    },
    {
        "id": 24021,
        "spelling_name": "Electrode",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38676,
                "word_type": "n.",
                "spelling_usage": "The path by which electricity is conveyed into or from a\n   solution or other conducting medium; esp., the ends of the wires or\n   conductors, leading from source of electricity, and terminating in the\n   medium traversed by the current."
            }
        ]
    },
    {
        "id": 24069,
        "spelling_name": "Elegant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38776,
                "word_type": "a.",
                "spelling_usage": "Very choice, and hence, pleasing to good taste;\n   characterized by grace, propriety, and refinement, and the absence of\n   every thing offensive; exciting admiration and approbation by symmetry,\n   completeness, freedom from blemish, and the like; graceful; tasteful\n   and highly attractive; as, elegant manners; elegant style of\n   composition; an elegant speaker; an elegant structure."
            },
            {
                "spelling_detail_id": 38777,
                "word_type": "a.",
                "spelling_usage": "Exercising a nice choice; discriminating beauty or\n   sensitive to beauty; as, elegant taste."
            }
        ]
    },
    {
        "id": 24122,
        "spelling_name": "Elevator",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38870,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, raises or lifts up anything"
            },
            {
                "spelling_detail_id": 38871,
                "word_type": "n.",
                "spelling_usage": "A mechanical contrivance, usually an endless belt or\n   chain with a series of scoops or buckets, for transferring grain to an\n   upper loft for storage."
            },
            {
                "spelling_detail_id": 38872,
                "word_type": "n.",
                "spelling_usage": "A cage or platform and the hoisting machinery in a hotel,\n   warehouse, mine, etc., for conveying persons, goods, etc., to or from\n   different floors or levels; -- called in England a lift; the cage or\n   platform itself."
            },
            {
                "spelling_detail_id": 38873,
                "word_type": "n.",
                "spelling_usage": "A building for elevating, storing, and discharging,\n   grain."
            },
            {
                "spelling_detail_id": 38874,
                "word_type": "n.",
                "spelling_usage": "A muscle which serves to raise a part of the body, as the\n   leg or the eye."
            },
            {
                "spelling_detail_id": 38875,
                "word_type": "n.",
                "spelling_usage": "An instrument for raising a depressed portion of a bone."
            }
        ]
    },
    {
        "id": 24203,
        "spelling_name": "Elocution",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 38991,
                "word_type": "n.",
                "spelling_usage": "Utterance by speech."
            },
            {
                "spelling_detail_id": 38992,
                "word_type": "n.",
                "spelling_usage": "Oratorical or expressive delivery, including the graces\n   of intonation, gesture, etc.; style or manner of speaking or reading in\n   public; as, clear, impressive elocution."
            },
            {
                "spelling_detail_id": 38993,
                "word_type": "n.",
                "spelling_usage": "Suitable and impressive writing or style; eloquent\n   diction."
            }
        ]
    },
    {
        "id": 24244,
        "spelling_name": "Elucidate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39051,
                "word_type": "v. t.",
                "spelling_usage": "To make clear or manifest; to render more\n   intelligible; to illustrate; as, an example will elucidate the subject."
            }
        ]
    },
    {
        "id": 24262,
        "spelling_name": "Elusive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39067,
                "word_type": "a.",
                "spelling_usage": "Tending to elude; using arts or deception to escape;\n   adroitly escaping or evading; eluding the grasp; fallacious."
            }
        ]
    },
    {
        "id": 24314,
        "spelling_name": "Emancipatory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39134,
                "word_type": "a.",
                "spelling_usage": "Pertaining to emancipation, or tending to effect\n   emancipation."
            }
        ]
    },
    {
        "id": 24404,
        "spelling_name": "Emblazoned",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39259,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Emblazon"
            }
        ]
    },
    {
        "id": 24514,
        "spelling_name": "Embryo",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39418,
                "word_type": "n.",
                "spelling_usage": "The first rudiments of an organism, whether animal or plant"
            },
            {
                "spelling_detail_id": 39419,
                "word_type": "n.",
                "spelling_usage": "The young of an animal in the womb, or more specifically,\n   before its parts are developed and it becomes a fetus (see Fetus)."
            },
            {
                "spelling_detail_id": 39420,
                "word_type": "n.",
                "spelling_usage": "The germ of the plant, which is inclosed in the seed and\n   which is developed by germination."
            },
            {
                "spelling_detail_id": 39421,
                "word_type": "a.",
                "spelling_usage": "Pertaining to an embryo; rudimentary; undeveloped; as, an\n   embryo bud."
            }
        ]
    },
    {
        "id": 24557,
        "spelling_name": "Emerald",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39463,
                "word_type": "n.",
                "spelling_usage": "A precious stone of a rich green color, a variety of\n   beryl. See Beryl."
            },
            {
                "spelling_detail_id": 39464,
                "word_type": "n.",
                "spelling_usage": "A kind of type, in size between minion and nonpare/l. It\n   is used by English printers."
            },
            {
                "spelling_detail_id": 39465,
                "word_type": "a.",
                "spelling_usage": "Of a rich green color, like that of the emerald."
            }
        ]
    },
    {
        "id": 24571,
        "spelling_name": "Emeritus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39481,
                "word_type": "a.",
                "spelling_usage": "Honorably discharged from the performance of public duty\n   on account of age, infirmity, or long and faithful services; -- said of\n   an officer of a college or pastor of a church."
            },
            {
                "spelling_detail_id": 39483,
                "word_type": "n.",
                "spelling_usage": "A veteran who has honorably completed his service."
            }
        ]
    },
    {
        "id": 24603,
        "spelling_name": "Eminent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39524,
                "word_type": "a.",
                "spelling_usage": "High; lofty; towering; prominent."
            },
            {
                "spelling_detail_id": 39525,
                "word_type": "a.",
                "spelling_usage": "Being, metaphorically, above others, whether by birth,\n   high station, merit, or virtue; high in public estimation;\n   distinguished; conspicuous; as, an eminent station; an eminent\n   historian, statements, statesman, or saint."
            }
        ]
    },
    {
        "id": 24685,
        "spelling_name": "Emphysema",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39624,
                "word_type": "n.",
                "spelling_usage": "A swelling produced by gas or air diffused in the\n   cellular tissue."
            }
        ]
    },
    {
        "id": 24722,
        "spelling_name": "Emporium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39678,
                "word_type": "n.",
                "spelling_usage": "A place of trade; a market place; a mart; esp., a city or\n   town with extensive commerce; the commercial center of a country."
            },
            {
                "spelling_detail_id": 39679,
                "word_type": "n.",
                "spelling_usage": "The brain."
            }
        ]
    },
    {
        "id": 24763,
        "spelling_name": "Emulate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39741,
                "word_type": "a.",
                "spelling_usage": "Striving to excel; ambitious; emulous."
            },
            {
                "spelling_detail_id": 39744,
                "word_type": "v. t.",
                "spelling_usage": "To strive to equal or to excel in qualities or actions;\n   to imitate, with a view to equal or to outdo, to vie with; to rival;\n   as, to emulate the good and the great."
            }
        ]
    },
    {
        "id": 24779,
        "spelling_name": "Emulsify",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39762,
                "word_type": "v. t.",
                "spelling_usage": "To convert into an emulsion; to form an emulsion; to\n   reduce from an oily substance to a milky fluid in which the fat\n   globules are in a very finely divided state, giving it the semblance of\n   solution; as, the pancreatic juice emulsifies the oily part of food."
            }
        ]
    },
    {
        "id": 24939,
        "spelling_name": "Encore",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 39970,
                "word_type": "adv. / interj.",
                "spelling_usage": "Once more; again; -- used by the auditors and\n   spectators of plays, concerts, and other entertainments, to call for a\n   repetition of a particular part."
            },
            {
                "spelling_detail_id": 39971,
                "word_type": "n.",
                "spelling_usage": "A call or demand (as, by continued applause) for a\n   repetition; as, the encores were numerous."
            },
            {
                "spelling_detail_id": 39974,
                "word_type": "v. t.",
                "spelling_usage": "To call for a repetition or reappearance of; as, to\n   encore a song or a singer."
            }
        ]
    },
    {
        "id": 24968,
        "spelling_name": "Encroach",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 40008,
                "word_type": "v. i.",
                "spelling_usage": "To enter by gradual steps or by stealth into the\n   possessions or rights of another; to trespass; to intrude; to trench;\n   -- commonly with on or upon; as, to encroach on a neighbor; to encroach\n   on the highway."
            },
            {
                "spelling_detail_id": 40009,
                "word_type": "n.",
                "spelling_usage": "Encroachment."
            }
        ]
    },
    {
        "id": 25160,
        "spelling_name": "Endure",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 40241,
                "word_type": "v. i.",
                "spelling_usage": "To continue in the same state without perishing; to\n   last; to remain."
            },
            {
                "spelling_detail_id": 40242,
                "word_type": "v. i.",
                "spelling_usage": "To remain firm, as under trial or suffering; to suffer\n   patiently or without yielding; to bear up under adversity; to hold out."
            },
            {
                "spelling_detail_id": 40243,
                "word_type": "v. t.",
                "spelling_usage": "To remain firm under; to sustain; to undergo; to support\n   without breaking or yielding; as, metals endure a certain degree of\n   heat without melting; to endure wind and weather."
            },
            {
                "spelling_detail_id": 40244,
                "word_type": "v. t.",
                "spelling_usage": "To bear with patience; to suffer without opposition or\n   without sinking under the pressure or affliction; to bear up under; to\n   put up with; to tolerate."
            },
            {
                "spelling_detail_id": 40245,
                "word_type": "v. t.",
                "spelling_usage": "To harden; to toughen; to make hardy."
            }
        ]
    },
    {
        "id": 25190,
        "spelling_name": "Enervate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 40284,
                "word_type": "v. t.",
                "spelling_usage": "To deprive of nerve, force, strength, or courage; to\n   render feeble or impotent; to make effeminate; to impair the moral\n   powers of."
            },
            {
                "spelling_detail_id": 40285,
                "word_type": "a.",
                "spelling_usage": "Weakened; weak; without strength of force."
            }
        ]
    },
    {
        "id": 25268,
        "spelling_name": "Engineer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 40408,
                "word_type": "n.",
                "spelling_usage": "A person skilled in the principles and practice of any\n   branch of engineering. See under Engineering, n."
            },
            {
                "spelling_detail_id": 40409,
                "word_type": "n.",
                "spelling_usage": "One who manages as engine, particularly a steam engine;\n   an engine driver."
            },
            {
                "spelling_detail_id": 40410,
                "word_type": "n.",
                "spelling_usage": "One who carries through an enterprise by skillful or\n   artful contrivance; an efficient manager."
            },
            {
                "spelling_detail_id": 40413,
                "word_type": "v. t.",
                "spelling_usage": "To lay out or construct, as an engineer; to perform\n   the work of an engineer on; as, to engineer a road."
            },
            {
                "spelling_detail_id": 40414,
                "word_type": "v. t.",
                "spelling_usage": "To use contrivance and effort for; to guide the course\n   of; to manage; as, to engineer a bill through Congress."
            }
        ]
    },
    {
        "id": 25524,
        "spelling_name": "Ensconced",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 40764,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Ensconce"
            }
        ]
    },
    {
        "id": 25770,
        "spelling_name": "Entree",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41118,
                "word_type": "n.",
                "spelling_usage": "A coming in, or entrance; hence, freedom of access;\n   permission or right to enter; as, to have the entree of a house."
            },
            {
                "spelling_detail_id": 41119,
                "word_type": "n.",
                "spelling_usage": "In French usage, a dish served at the beginning of dinner\n   to give zest to the appetite; in English usage, a side dish, served\n   with a joint, or between the courses, as a cutlet, scalloped oysters,\n   etc."
            }
        ]
    },
    {
        "id": 25774,
        "spelling_name": "Entrepreneur",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41124,
                "word_type": "n.",
                "spelling_usage": "One who creates a product on his own account; whoever\n   undertakes on his own account an industrial enterprise in which workmen\n   are employed."
            }
        ]
    },
    {
        "id": 25797,
        "spelling_name": "Enumerated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41155,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Enumerate"
            }
        ]
    },
    {
        "id": 25803,
        "spelling_name": "Enunciate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41166,
                "word_type": "v. t.",
                "spelling_usage": "To make a formal statement of; to announce; to\n   proclaim; to declare, as a truth."
            },
            {
                "spelling_detail_id": 41167,
                "word_type": "v. t.",
                "spelling_usage": "To make distinctly audible; to utter articulately; to\n   pronounce; as, to enunciate a word distinctly."
            },
            {
                "spelling_detail_id": 41168,
                "word_type": "v. i.",
                "spelling_usage": "To utter words or syllables articulately."
            }
        ]
    },
    {
        "id": 25825,
        "spelling_name": "Enviable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41197,
                "word_type": "a.",
                "spelling_usage": "Fitted to excite envy; capable of awakening an ardent\n   desire to posses or to resemble."
            }
        ]
    },
    {
        "id": 25836,
        "spelling_name": "Environs",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41211,
                "word_type": "n. pl.",
                "spelling_usage": "The parts or places which surround another place, or\n   lie in its neighborhood; suburbs; as, the environs of a city or town."
            }
        ]
    },
    {
        "id": 25861,
        "spelling_name": "Enzyme",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41252,
                "word_type": "n.",
                "spelling_usage": "An unorganized or unformed ferment, in distinction from an\n   organized or living ferment; a soluble, or chemical, ferment. Ptyalin,\n   pepsin, diastase, and rennet are good examples of enzymes."
            }
        ]
    },
    {
        "id": 25881,
        "spelling_name": "Epact",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41276,
                "word_type": "n.",
                "spelling_usage": "The moon's age at the beginning of the calendar year, or the\n   number of days by which the last new moon has preceded the beginning of\n   the year."
            }
        ]
    },
    {
        "id": 25976,
        "spelling_name": "Epicurean",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41389,
                "word_type": "a.",
                "spelling_usage": "Pertaining to Epicurus, or following his philosophy."
            },
            {
                "spelling_detail_id": 41390,
                "word_type": "a.",
                "spelling_usage": "Given to luxury; adapted to luxurious tastes; luxurious;\n   pertaining to good eating."
            },
            {
                "spelling_detail_id": 41391,
                "word_type": "n.",
                "spelling_usage": "A follower or Epicurus."
            },
            {
                "spelling_detail_id": 41392,
                "word_type": "n.",
                "spelling_usage": "One given to epicurean indulgence."
            }
        ]
    },
    {
        "id": 26003,
        "spelling_name": "Epidermis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41425,
                "word_type": "v. t.",
                "spelling_usage": "The outer, nonsensitive layer of the skin; cuticle;\n   scarfskin. See Dermis."
            },
            {
                "spelling_detail_id": 41426,
                "word_type": "v. t.",
                "spelling_usage": "The outermost layer of the cells, which covers both\n   surfaces of leaves, and also the surface of stems, when they are first\n   formed. As stems grow old this layer is lost, and never replaced."
            }
        ]
    },
    {
        "id": 26047,
        "spelling_name": "Epilepsy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41476,
                "word_type": "n.",
                "spelling_usage": "The \"falling sickness,\" so called because the patient\n   falls suddenly to the ground; a disease characterized by paroxysms (or\n   fits) occurring at interval and attended by sudden loss of\n   consciousness, and convulsive motions of the muscles."
            }
        ]
    },
    {
        "id": 26119,
        "spelling_name": "Episcopal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41561,
                "word_type": "a.",
                "spelling_usage": "Governed by bishops; as, an episcopal church."
            },
            {
                "spelling_detail_id": 41562,
                "word_type": "a.",
                "spelling_usage": "Belonging to, or vested in, bishops; as, episcopal\n   jurisdiction or authority; the episcopal system."
            }
        ]
    },
    {
        "id": 26152,
        "spelling_name": "Epistolary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41607,
                "word_type": "a.",
                "spelling_usage": "Pertaining to epistles or letters; suitable to letters\n   and correspondence; as, an epistolary style."
            },
            {
                "spelling_detail_id": 41608,
                "word_type": "a.",
                "spelling_usage": "Contained in letters; carried on by letters."
            }
        ]
    },
    {
        "id": 26191,
        "spelling_name": "Epithet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41653,
                "word_type": "n.",
                "spelling_usage": "An adjective expressing some quality, attribute, or\n   relation, that is properly or specially appropriate to a person or\n   thing; as, a just man; a verdant lawn."
            },
            {
                "spelling_detail_id": 41654,
                "word_type": "n.",
                "spelling_usage": "Term; expression; phrase."
            },
            {
                "spelling_detail_id": 41655,
                "word_type": "v. t.",
                "spelling_usage": "To describe by an epithet."
            }
        ]
    },
    {
        "id": 26199,
        "spelling_name": "Epitome",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41664,
                "word_type": "n.",
                "spelling_usage": "A work in which the contents of a former work are reduced\n   within a smaller space by curtailment and condensation; a brief\n   summary; an abridgement."
            },
            {
                "spelling_detail_id": 41665,
                "word_type": "n.",
                "spelling_usage": "A compact or condensed representation of anything."
            }
        ]
    },
    {
        "id": 26217,
        "spelling_name": "Epoch",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41687,
                "word_type": "n.",
                "spelling_usage": "A fixed point of time, established in history by the\n   occurrence of some grand or remarkable event; a point of time marked by\n   an event of great subsequent influence; as, the epoch of the creation;\n   the birth of Christ was the epoch which gave rise to the Christian era."
            },
            {
                "spelling_detail_id": 41688,
                "word_type": "n.",
                "spelling_usage": "A period of time, longer or shorter, remarkable for events\n   of great subsequent influence; a memorable period; as, the epoch of\n   maritime discovery, or of the Reformation."
            },
            {
                "spelling_detail_id": 41689,
                "word_type": "n.",
                "spelling_usage": "A division of time characterized by the prevalence of\n   similar conditions of the earth; commonly a minor division or part of a\n   period."
            },
            {
                "spelling_detail_id": 41690,
                "word_type": "n.",
                "spelling_usage": "The date at which a planet or comet has a longitude or\n   position."
            },
            {
                "spelling_detail_id": 41691,
                "word_type": "n.",
                "spelling_usage": "An arbitrary fixed date, for which the elements used in\n   computing the place of a planet, or other heavenly body, at any other\n   date, are given; as, the epoch of Mars; lunar elements for the epoch\n   March 1st, 1860."
            }
        ]
    },
    {
        "id": 26267,
        "spelling_name": "Equanimity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41760,
                "word_type": "n.",
                "spelling_usage": "Evenness of mind; that calm temper or firmness of mind\n   which is not easily elated or depressed; patience; calmness; composure;\n   as, to bear misfortunes with equanimity."
            }
        ]
    },
    {
        "id": 26280,
        "spelling_name": "Equestrian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41778,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to horses or horsemen, or to\n   horsemanship; as, equestrian feats, or games."
            },
            {
                "spelling_detail_id": 41779,
                "word_type": "a.",
                "spelling_usage": "Being or riding on horseback; mounted; as, an\n   equestrian statue."
            },
            {
                "spelling_detail_id": 41780,
                "word_type": "a.",
                "spelling_usage": "Belonging to, or composed of, the ancient Roman\n   equities or knights; as, the equestrian order."
            },
            {
                "spelling_detail_id": 41781,
                "word_type": "n.",
                "spelling_usage": "One who rides on horseback; a horseman; a rider."
            }
        ]
    },
    {
        "id": 26306,
        "spelling_name": "Equilibrium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41811,
                "word_type": "n.",
                "spelling_usage": "Equality of weight or force; an equipoise or a state\n   of rest produced by the mutual counteraction of two or more forces."
            },
            {
                "spelling_detail_id": 41812,
                "word_type": "n.",
                "spelling_usage": "A level position; a just poise or balance in respect\n   to an object, so that it remains firm; equipoise; as, to preserve the\n   equilibrium of the body."
            },
            {
                "spelling_detail_id": 41813,
                "word_type": "n.",
                "spelling_usage": "A balancing of the mind between motives or reasons,\n   with consequent indecision and doubt."
            }
        ]
    },
    {
        "id": 26315,
        "spelling_name": "Equinox",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41825,
                "word_type": "n.",
                "spelling_usage": "The time when the sun enters one of the equinoctial\n   points, that is, about March 21 and September 22. See Autumnal equinox,\n   Vernal equinox, under Autumnal and Vernal."
            },
            {
                "spelling_detail_id": 41826,
                "word_type": "n.",
                "spelling_usage": "Equinoctial wind or storm."
            }
        ]
    },
    {
        "id": 26360,
        "spelling_name": "Equivalent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41887,
                "word_type": "a.",
                "spelling_usage": "Equal in wortir or value, force, power, effect, import,\n   and the like; alike in significance and value; of the same import or\n   meaning."
            },
            {
                "spelling_detail_id": 41888,
                "word_type": "a.",
                "spelling_usage": "Equal in measure but not admitting of superposition; --\n   applied to magnitudes; as, a square may be equivalent to a triangle."
            },
            {
                "spelling_detail_id": 41889,
                "word_type": "a.",
                "spelling_usage": "Contemporaneous in origin; as, the equivalent strata of\n   different countries."
            },
            {
                "spelling_detail_id": 41890,
                "word_type": "n.",
                "spelling_usage": "Something equivalent; that which is equal in value,\n   worth, weight, or force; as, to offer an equivalent for damage done."
            },
            {
                "spelling_detail_id": 41891,
                "word_type": "n.",
                "spelling_usage": "That comparative quantity by weight of an element which\n   possesses the same chemical value as other elements, as determined by\n   actual experiment and reference to the same standard. Specifically: (a)\n   The comparative proportions by which one element replaces another in\n   any particular compound; thus, as zinc replaces hydrogen in\n   hydrochloric acid, their equivalents are 32.5 and 1. (b) The combining\n   proportion by weight of a substance, or the number expressing this\n   proportion, in any particular compound; as, the equivalents of hydrogen\n   and oxygen in water are respectively 1 and 8, and in hydric dioxide 1\n   and 16."
            },
            {
                "spelling_detail_id": 41892,
                "word_type": "n.",
                "spelling_usage": "A combining unit, whether an atom, a radical, or a\n   molecule; as, in acid salt two or more equivalents of acid unite with\n   one or more equivalents of base."
            },
            {
                "spelling_detail_id": 41893,
                "word_type": "v. t.",
                "spelling_usage": "To make the equivalent to; to equal; equivalence."
            }
        ]
    },
    {
        "id": 26370,
        "spelling_name": "Equivocate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41908,
                "word_type": "a.",
                "spelling_usage": "To use words of equivocal or doubtful signification; to\n   express one's opinions in terms which admit of different senses, with\n   intent to deceive; to use ambiguous expressions with a view to mislead;\n   as, to equivocate is the work of duplicity."
            },
            {
                "spelling_detail_id": 41909,
                "word_type": "v. t.",
                "spelling_usage": "To render equivocal or ambiguous."
            }
        ]
    },
    {
        "id": 26386,
        "spelling_name": "Eradicate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 41932,
                "word_type": "v. t.",
                "spelling_usage": "To pluck up by the roots; to root up; as, an oak tree\n   eradicated."
            },
            {
                "spelling_detail_id": 41933,
                "word_type": "v. t.",
                "spelling_usage": "To root out; to destroy utterly; to extirpate; as, to\n   eradicate diseases, or errors."
            }
        ]
    },
    {
        "id": 26516,
        "spelling_name": "Erroneous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42118,
                "word_type": "a.",
                "spelling_usage": "Wandering; straying; deviating from the right course; --\n   hence, irregular; unnatural."
            },
            {
                "spelling_detail_id": 42119,
                "word_type": "a.",
                "spelling_usage": "Misleading; misled; mistaking."
            },
            {
                "spelling_detail_id": 42120,
                "word_type": "a.",
                "spelling_usage": "Containing error; not conformed to truth or justice;\n   incorrect; false; mistaken; as, an erroneous doctrine; erroneous\n   opinion, observation, deduction, view, etc."
            }
        ]
    },
    {
        "id": 26544,
        "spelling_name": "Eruption",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42158,
                "word_type": "n.",
                "spelling_usage": "The act of breaking out or bursting forth; as: (a) A\n   violent throwing out of flames, lava, etc., as from a volcano of a\n   fissure in the earth's crust. (b) A sudden and overwhelming hostile\n   movement of armed men from one country to another. Milton. (c) A\n   violent commotion."
            },
            {
                "spelling_detail_id": 42159,
                "word_type": "n.",
                "spelling_usage": "That which bursts forth."
            },
            {
                "spelling_detail_id": 42160,
                "word_type": "n.",
                "spelling_usage": "A violent exclamation; ejaculation."
            },
            {
                "spelling_detail_id": 42161,
                "word_type": "n.",
                "spelling_usage": "The breaking out of pimples, or an efflorescence, as in\n   measles, scarlatina, etc."
            }
        ]
    },
    {
        "id": 26602,
        "spelling_name": "Escarpment",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42245,
                "word_type": "n.",
                "spelling_usage": "A steep descent or declivity; steep face or edge of a\n   ridge; ground about a fortified place, cut away nearly vertically to\n   prevent hostile approach. See Scarp."
            }
        ]
    },
    {
        "id": 26617,
        "spelling_name": "Eschew",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42272,
                "word_type": "a.",
                "spelling_usage": "To shun; to avoid, as something wrong, or from a feeling of\n   distaste; to keep one's self clear of."
            },
            {
                "spelling_detail_id": 42273,
                "word_type": "a.",
                "spelling_usage": "To escape from; to avoid."
            }
        ]
    },
    {
        "id": 26674,
        "spelling_name": "Esoteric",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42334,
                "word_type": "a.",
                "spelling_usage": "Designed for, and understood by, the specially initiated\n   alone; not communicated, or not intelligible, to the general body of\n   followers; private; interior; acroamatic; -- said of the private and\n   more recondite instructions and doctrines of philosophers. Opposed to\n   exoteric."
            }
        ]
    },
    {
        "id": 26703,
        "spelling_name": "Espousal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42366,
                "word_type": "n.",
                "spelling_usage": "The act of espousing or betrothing; especially, in the\n   plural, betrothal; plighting of the troths; a contract of marriage;\n   sometimes, the marriage ceremony."
            },
            {
                "spelling_detail_id": 42367,
                "word_type": "n.",
                "spelling_usage": "The uniting or allying one's self with anything;\n   maintenance; adoption; as, the espousal of a quarrel."
            }
        ]
    },
    {
        "id": 26747,
        "spelling_name": "Establishment",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42448,
                "word_type": "n.",
                "spelling_usage": "The act of establishing; a ratifying or ordaining;\n   settlement; confirmation."
            },
            {
                "spelling_detail_id": 42449,
                "word_type": "n.",
                "spelling_usage": "The state of being established, founded, and the\n   like; fixed state."
            },
            {
                "spelling_detail_id": 42450,
                "word_type": "n.",
                "spelling_usage": "That which is established; as: (a) A form of\n   government, civil or ecclesiastical; especially, a system of religion\n   maintained by the civil power; as, the Episcopal establishment of\n   England. (b) A permanent civil, military, or commercial, force or\n   organization. (c) The place in which one is permanently fixed for\n   residence or business; residence, including grounds, furniture,\n   equipage, etc.; with which one is fitted out; also, any office or place\n   of business, with its fixtures; that which serves for the carrying on\n   of a business; as, to keep up a large establishment; a manufacturing\n   establishment."
            }
        ]
    },
    {
        "id": 26932,
        "spelling_name": "Etude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42713,
                "word_type": "n.",
                "spelling_usage": "A composition in the fine arts which is intended, or may\n   serve, for a study."
            },
            {
                "spelling_detail_id": 42714,
                "word_type": "n.",
                "spelling_usage": "A study; an exercise; a piece for practice of some special\n   point of technical execution."
            }
        ]
    },
    {
        "id": 26952,
        "spelling_name": "Eucalyptus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42737,
                "word_type": "n.",
                "spelling_usage": "A myrtaceous genus of trees, mostly Australian. Many of\n   them grow to an immense height, one or two species exceeding the height\n   even of the California Sequoia."
            }
        ]
    },
    {
        "id": 27134,
        "spelling_name": "Evaluate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42946,
                "word_type": "v. t.",
                "spelling_usage": "To fix the value of; to rate; to appraise."
            }
        ]
    },
    {
        "id": 27139,
        "spelling_name": "Evanescent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42952,
                "word_type": "a.",
                "spelling_usage": "Liable to vanish or pass away like vapor; vanishing;\n   fleeting; as, evanescent joys."
            },
            {
                "spelling_detail_id": 42953,
                "word_type": "a.",
                "spelling_usage": "Vanishing from notice; imperceptible."
            }
        ]
    },
    {
        "id": 27169,
        "spelling_name": "Evaporation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 42990,
                "word_type": "n.",
                "spelling_usage": "The process by which any substance is converted from a\n   liquid state into, and carried off in, vapor; as, the evaporation of\n   water, of ether, of camphor."
            },
            {
                "spelling_detail_id": 42991,
                "word_type": "n.",
                "spelling_usage": "The transformation of a portion of a fluid into vapor,\n   in order to obtain the fixed matter contained in it in a state of\n   greater consistence."
            },
            {
                "spelling_detail_id": 42992,
                "word_type": "n.",
                "spelling_usage": "That which is evaporated; vapor."
            },
            {
                "spelling_detail_id": 42993,
                "word_type": "n.",
                "spelling_usage": "See Vaporization."
            }
        ]
    },
    {
        "id": 27342,
        "spelling_name": "Exaggerate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43237,
                "word_type": "v. t.",
                "spelling_usage": "To heap up; to accumulate."
            },
            {
                "spelling_detail_id": 43238,
                "word_type": "v. t.",
                "spelling_usage": "To amplify; to magnify; to enlarge beyond bounds or\n   the truth ; to delineate extravagantly ; to overstate the truth\n   concerning."
            }
        ]
    },
    {
        "id": 27402,
        "spelling_name": "Exasperate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43318,
                "word_type": "a.",
                "spelling_usage": "Exasperated; imbittered."
            },
            {
                "spelling_detail_id": 43321,
                "word_type": "v. t.",
                "spelling_usage": "To irritate in a high degree; to provoke; to enrage;\n   to exscite or to inflame the anger of; as, to exasperate a person or\n   his feelings."
            },
            {
                "spelling_detail_id": 43322,
                "word_type": "v. t.",
                "spelling_usage": "To make grievous, or more grievous or malignant; to\n   aggravate; to imbitter; as, to exasperate enmity."
            }
        ]
    },
    {
        "id": 27507,
        "spelling_name": "Excision",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43478,
                "word_type": "n.",
                "spelling_usage": "The act of excising or cutting out or off; extirpation;\n   destruction."
            },
            {
                "spelling_detail_id": 43479,
                "word_type": "n.",
                "spelling_usage": "The act of cutting off from the church; excommunication."
            },
            {
                "spelling_detail_id": 43480,
                "word_type": "n.",
                "spelling_usage": "The removal, especially of small parts, with a cutting\n   instrument."
            }
        ]
    },
    {
        "id": 27658,
        "spelling_name": "Exemplar",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43698,
                "word_type": "n.",
                "spelling_usage": "A model, original, or pattern, to be copied or imitated;\n   a specimen; sometimes; an ideal model or type, as that which an artist\n   conceives."
            },
            {
                "spelling_detail_id": 43699,
                "word_type": "n.",
                "spelling_usage": "A copy of a book or writing."
            },
            {
                "spelling_detail_id": 43700,
                "word_type": "a.",
                "spelling_usage": "Exemplary."
            }
        ]
    },
    {
        "id": 27684,
        "spelling_name": "Exercise",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43740,
                "word_type": "n.",
                "spelling_usage": "The act of exercising; a setting in action or practicing;\n   employment in the proper mode of activity; exertion; application; use;\n   habitual activity; occupation, in general; practice."
            },
            {
                "spelling_detail_id": 43741,
                "word_type": "n.",
                "spelling_usage": "Exertion for the sake of training or improvement whether\n   physical, intellectual, or moral; practice to acquire skill, knowledge,\n   virtue, perfectness, grace, etc."
            },
            {
                "spelling_detail_id": 43742,
                "word_type": "n.",
                "spelling_usage": "Bodily exertion for the sake of keeping the organs and\n   functions in a healthy state; hygienic activity; as, to take exercise\n   on horseback."
            },
            {
                "spelling_detail_id": 43743,
                "word_type": "n.",
                "spelling_usage": "The performance of an office, a ceremony, or a religious\n   duty."
            },
            {
                "spelling_detail_id": 43744,
                "word_type": "n.",
                "spelling_usage": "That which is done for the sake of exercising,\n   practicing, training, or promoting skill, health, mental, improvement,\n   moral discipline, etc.; that which is assigned or prescribed for such\n   ends; hence, a disquisition; a lesson; a task; as, military or naval\n   exercises; musical exercises; an exercise in composition."
            },
            {
                "spelling_detail_id": 43745,
                "word_type": "n.",
                "spelling_usage": "That which gives practice; a trial; a test."
            },
            {
                "spelling_detail_id": 43748,
                "word_type": "v. t.",
                "spelling_usage": "To set in action; to cause to act, move, or make\n   exertion; to give employment to; to put in action habitually or\n   constantly; to school or train; to exert repeatedly; to busy."
            },
            {
                "spelling_detail_id": 43749,
                "word_type": "v. t.",
                "spelling_usage": "To exert for the sake of training or improvement; to\n   practice in order to develop; hence, also, to improve by practice; to\n   discipline, and to use or to for the purpose of training; as, to\n   exercise arms; to exercise one's self in music; to exercise troops."
            },
            {
                "spelling_detail_id": 43750,
                "word_type": "v. t.",
                "spelling_usage": "To occupy the attention and effort of; to task; to\n   tax, especially in a painful or vexatious manner; harass; to vex; to\n   worry or make anxious; to affect; to discipline; as, exercised with\n   pain."
            },
            {
                "spelling_detail_id": 43751,
                "word_type": "v. t.",
                "spelling_usage": "To put in practice; to carry out in action; to perform\n   the duties of; to use; to employ; to practice; as, to exercise\n   authority; to exercise an office."
            },
            {
                "spelling_detail_id": 43752,
                "word_type": "v. i.",
                "spelling_usage": "To exercise one's self, as under military training; to\n   drill; to take exercise; to use action or exertion; to practice\n   gymnastics; as, to exercise for health or amusement."
            }
        ]
    },
    {
        "id": 27732,
        "spelling_name": "Exhibition",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43824,
                "word_type": "n.",
                "spelling_usage": "The act of exhibiting for inspection, or of holding\n   forth to view; manifestation; display."
            },
            {
                "spelling_detail_id": 43825,
                "word_type": "n.",
                "spelling_usage": "That which is exhibited, held forth, or displayed;\n   also, any public show; a display of works of art, or of feats of skill,\n   or of oratorical or dramatic ability; as, an exhibition of animals; an\n   exhibition of pictures, statues, etc.; an industrial exhibition."
            },
            {
                "spelling_detail_id": 43826,
                "word_type": "n.",
                "spelling_usage": "Sustenance; maintenance; allowance, esp. for meat and\n   drink; pension. Specifically: (Eng. Univ.) Private benefaction for the\n   maintenance of scholars."
            },
            {
                "spelling_detail_id": 43827,
                "word_type": "n.",
                "spelling_usage": "The act of administering a remedy."
            }
        ]
    },
    {
        "id": 27799,
        "spelling_name": "Exodus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43916,
                "word_type": "n.",
                "spelling_usage": "A going out; particularly (the Exodus), the going out or\n   journey of the Israelites from Egypt under the conduct of Moses; and\n   hence, any large migration from a place."
            },
            {
                "spelling_detail_id": 43917,
                "word_type": "n.",
                "spelling_usage": "The second of the Old Testament, which contains the\n   narrative of the departure of the Israelites from Egypt."
            }
        ]
    },
    {
        "id": 27815,
        "spelling_name": "Exoneration",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43940,
                "word_type": "n.",
                "spelling_usage": "The act of disburdening, discharging, or freeing\n   morally from a charge or imputation; also, the state of being\n   disburdened or freed from a charge."
            }
        ]
    },
    {
        "id": 27832,
        "spelling_name": "Exorbitant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 43958,
                "word_type": "a.",
                "spelling_usage": "Departing from an orbit or usual track; hence,\n   deviating from the usual or due course; going beyond the appointed\n   rules or established limits of right or propriety; excessive;\n   extravagant; enormous; inordinate; as, exorbitant appetites and\n   passions; exorbitant charges, demands, or claims."
            },
            {
                "spelling_detail_id": 43959,
                "word_type": "a.",
                "spelling_usage": "Not comprehended in a settled rule or method;\n   anomalous."
            }
        ]
    },
    {
        "id": 28082,
        "spelling_name": "Expression",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44359,
                "word_type": "n.",
                "spelling_usage": "The act of expressing; the act of forcing out by\n   pressure; as, the expression of juices or oils; also, of extorting or\n   eliciting; as, a forcible expression of truth."
            },
            {
                "spelling_detail_id": 44360,
                "word_type": "n.",
                "spelling_usage": "The act of declaring or signifying; declaration;\n   utterance; as, an expression of the public will."
            },
            {
                "spelling_detail_id": 44361,
                "word_type": "n.",
                "spelling_usage": "Lively or vivid representation of meaning, sentiment,\n   or feeling, etc.; significant and impressive indication, whether by\n   language, appearance, or gesture; that manner or style which gives life\n   and suggestive force to ideas and sentiments; as, he reads with\n   expression; her performance on the piano has expression."
            },
            {
                "spelling_detail_id": 44362,
                "word_type": "n.",
                "spelling_usage": "That which is expressed by a countenance, a posture, a\n   work of art, etc.; look, as indicative of thought or feeling."
            },
            {
                "spelling_detail_id": 44363,
                "word_type": "n.",
                "spelling_usage": "A form of words in which an idea or sentiment is\n   conveyed; a mode of speech; a phrase; as, a common expression; an odd\n   expression."
            },
            {
                "spelling_detail_id": 44364,
                "word_type": "n.",
                "spelling_usage": "The representation of any quantity by its appropriate\n   characters or signs."
            }
        ]
    },
    {
        "id": 28106,
        "spelling_name": "Expunge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44392,
                "word_type": "v. t.",
                "spelling_usage": "To blot out, as with pen; to rub out; to efface\n   designedly; to obliterate; to strike out wholly; as, to expunge words,\n   lines, or sentences."
            },
            {
                "spelling_detail_id": 44393,
                "word_type": "v. t.",
                "spelling_usage": "To strike out; to wipe out or destroy; to annihilate;\n   as, to expugne an offense."
            }
        ]
    },
    {
        "id": 28119,
        "spelling_name": "Exquisite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44404,
                "word_type": "a.",
                "spelling_usage": "Carefully selected or sought out; hence, of\n   distinguishing and surpassing quality; exceedingly nice; delightfully\n   excellent; giving rare satisfaction; as, exquisite workmanship."
            },
            {
                "spelling_detail_id": 44405,
                "word_type": "a.",
                "spelling_usage": "Exceeding; extreme; keen; -- used in a bad or a good\n   sense; as, exquisite pain or pleasure."
            },
            {
                "spelling_detail_id": 44406,
                "word_type": "a.",
                "spelling_usage": "Of delicate perception or close and accurate\n   discrimination; not easy to satisfy; exact; nice; fastidious; as,\n   exquisite judgment, taste, or discernment."
            },
            {
                "spelling_detail_id": 44407,
                "word_type": "n.",
                "spelling_usage": "One who manifests an exquisite attention to external\n   appearance; one who is overnice in dress or ornament; a fop; a dandy."
            }
        ]
    },
    {
        "id": 28167,
        "spelling_name": "Extant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44457,
                "word_type": "a.",
                "spelling_usage": "Standing out or above any surface; protruded."
            },
            {
                "spelling_detail_id": 44458,
                "word_type": "a.",
                "spelling_usage": "Still existing; not destroyed or lost; outstanding."
            },
            {
                "spelling_detail_id": 44459,
                "word_type": "a.",
                "spelling_usage": "Publicly known; conspicuous."
            }
        ]
    },
    {
        "id": 28172,
        "spelling_name": "Extemporaneous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44464,
                "word_type": "a.",
                "spelling_usage": "Composed, performed, or uttered on the spur of the\n   moment, or without previous study; unpremeditated; off-hand; extempore;\n   extemporary; as, an extemporaneous address or production."
            }
        ]
    },
    {
        "id": 28198,
        "spelling_name": "Extensive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44506,
                "word_type": "a.",
                "spelling_usage": "Having wide extent; of much superficial extent;\n   expanded; large; broad; wide; comprehensive; as, an extensive farm; an\n   extensive lake; an extensive sphere of operations; extensive\n   benevolence; extensive greatness."
            },
            {
                "spelling_detail_id": 44507,
                "word_type": "a.",
                "spelling_usage": "Capable of being extended."
            }
        ]
    },
    {
        "id": 28242,
        "spelling_name": "Extinguish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44582,
                "word_type": "v. t.",
                "spelling_usage": "To quench; to put out, as a light or fire; to\n   stifle; to cause to die out; to put an end to; to destroy; as, to\n   extinguish a flame, or life, or love, or hope, a pretense or a right."
            },
            {
                "spelling_detail_id": 44583,
                "word_type": "v. t.",
                "spelling_usage": "To obscure; to eclipse, as by superior splendor."
            }
        ]
    },
    {
        "id": 28510,
        "spelling_name": "Fabulist",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44955,
                "word_type": "n.",
                "spelling_usage": "One who invents or writes fables."
            }
        ]
    },
    {
        "id": 28518,
        "spelling_name": "Facade",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 44967,
                "word_type": "n.",
                "spelling_usage": "The front of a building; esp., the principal front, having\n   some architectural pretensions. Thus a church is said to have its\n   facade unfinished, though the interior may be in use."
            }
        ]
    },
    {
        "id": 28554,
        "spelling_name": "Factitious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 45062,
                "word_type": "a.",
                "spelling_usage": "Made by art, in distinction from what is produced by\n   nature; artificial; sham; formed by, or adapted to, an artificial or\n   conventional, in distinction from a natural, standard or rule; not\n   natural; as, factitious cinnabar or jewels; a factitious taste."
            }
        ]
    },
    {
        "id": 28562,
        "spelling_name": "Factorial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 45076,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a factory."
            },
            {
                "spelling_detail_id": 45077,
                "word_type": "a.",
                "spelling_usage": "Related to factorials."
            },
            {
                "spelling_detail_id": 45078,
                "word_type": "n.",
                "spelling_usage": "A name given to the factors of a continued product when\n   the former are derivable from one and the same function F(x) by\n   successively imparting a constant increment or decrement h to the\n   independent variable. Thus the product F(x).F(x + h).F(x + 2h) . . .\n   F[x + (n-1)h] is called a factorial term, and its several factors take\n   the name of factorials."
            },
            {
                "spelling_detail_id": 45079,
                "word_type": "n.",
                "spelling_usage": "The product of the consecutive numbers from unity up to\n   any given number."
            }
        ]
    },
    {
        "id": 28798,
        "spelling_name": "Fanged",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 45587,
                "word_type": "a.",
                "spelling_usage": "Having fangs or tusks; as, a fanged adder. Also used\n   figuratively."
            }
        ]
    },
    {
        "id": 28844,
        "spelling_name": "Farcical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 45659,
                "word_type": "a.",
                "spelling_usage": "Pertaining to farce; appropriated to farce; ludicrous;\n   unnatural; unreal."
            },
            {
                "spelling_detail_id": 45660,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the disease called farcy. See Farcy,\n   n."
            }
        ]
    },
    {
        "id": 29071,
        "spelling_name": "Faux",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46055,
                "word_type": "n.",
                "spelling_usage": "See Fauces."
            }
        ]
    },
    {
        "id": 29116,
        "spelling_name": "Fealty",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46133,
                "word_type": "n.",
                "spelling_usage": "Fidelity to one's lord; the feudal obligation by which the\n   tenant or vassal was bound to be faithful to his lord; the special oath\n   by which this obligation was assumed; fidelity to a superior power, or\n   to a government; loyality. It is no longer the practice to exact the\n   performance of fealty, as a feudal obligation."
            },
            {
                "spelling_detail_id": 46134,
                "word_type": "n.",
                "spelling_usage": "Fidelity; constancy; faithfulness, as of a friend to a\n   friend, or of a wife to her husband."
            }
        ]
    },
    {
        "id": 29188,
        "spelling_name": "Fecund",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46266,
                "word_type": "a.",
                "spelling_usage": "Fruitful in children; prolific."
            }
        ]
    },
    {
        "id": 29231,
        "spelling_name": "Feign",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46382,
                "word_type": "v. t.",
                "spelling_usage": "To give a mental existence to, as to something not real\n   or actual; to imagine; to invent; hence, to pretend; to form and relate\n   as if true."
            },
            {
                "spelling_detail_id": 46383,
                "word_type": "v. t.",
                "spelling_usage": "To represent by a false appearance of; to pretend; to\n   counterfeit; as, to feign a sickness."
            },
            {
                "spelling_detail_id": 46384,
                "word_type": "v. t.",
                "spelling_usage": "To dissemble; to conceal."
            }
        ]
    },
    {
        "id": 29285,
        "spelling_name": "Felonious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46478,
                "word_type": "a.",
                "spelling_usage": "Having the quality of felony; malignant; malicious;\n   villainous; traitorous; perfidious; in a legal sense, done with intent\n   to commit a crime; as, felonious homicide."
            }
        ]
    },
    {
        "id": 29351,
        "spelling_name": "Fenestrated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46585,
                "word_type": "a.",
                "spelling_usage": "Having windows; characterized by windows."
            },
            {
                "spelling_detail_id": 46586,
                "word_type": "a.",
                "spelling_usage": "Same as Fenestrate."
            }
        ]
    },
    {
        "id": 29432,
        "spelling_name": "Ferret",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46693,
                "word_type": "n.",
                "spelling_usage": "An animal of the Weasel family (Mustela / Putorius furo),\n   about fourteen inches in length, of a pale yellow or white color, with\n   red eyes. It is a native of Africa, but has been domesticated in\n   Europe. Ferrets are used to drive rabbits and rats out of their holes."
            },
            {
                "spelling_detail_id": 46696,
                "word_type": "n.",
                "spelling_usage": "To drive or hunt out of a lurking place, as a ferret does\n   the cony; to search out by patient and sagacious efforts; -- often used\n   with out; as, to ferret out a secret."
            },
            {
                "spelling_detail_id": 46697,
                "word_type": "n.",
                "spelling_usage": "A kind of narrow tape, usually made of woolen; sometimes of\n   cotton or silk; -- called also ferreting."
            },
            {
                "spelling_detail_id": 46698,
                "word_type": "n.",
                "spelling_usage": "The iron used for trying the melted glass to see if is fit\n   to work, and for shaping the rings at the mouths of bottles."
            }
        ]
    },
    {
        "id": 29577,
        "spelling_name": "Feudalism",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46901,
                "word_type": "n.",
                "spelling_usage": "The feudal system; a system by which the holding of\n   estates in land is made dependent upon an obligation to render military\n   service to the kind or feudal superior; feudal principles and usages."
            }
        ]
    },
    {
        "id": 29613,
        "spelling_name": "Fez",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 46951,
                "word_type": "n.",
                "spelling_usage": "A felt or cloth cap, usually red and having a tassel, -- a\n   variety of the tarboosh. See Tarboosh."
            }
        ]
    },
    {
        "id": 29664,
        "spelling_name": "Fibula",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47014,
                "word_type": "n.",
                "spelling_usage": "A brooch, clasp, or buckle."
            },
            {
                "spelling_detail_id": 47015,
                "word_type": "n.",
                "spelling_usage": "The outer and usually the smaller of the two bones of the\n   leg, or hind limb, below the knee."
            },
            {
                "spelling_detail_id": 47016,
                "word_type": "n.",
                "spelling_usage": "A needle for sewing up wounds."
            }
        ]
    },
    {
        "id": 29712,
        "spelling_name": "Fiduciary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47087,
                "word_type": "n.",
                "spelling_usage": "One who holds a thing in trust for another; a trustee."
            },
            {
                "spelling_detail_id": 47088,
                "word_type": "n.",
                "spelling_usage": "One who depends for salvation on faith, without works;\n   an Antinomian."
            }
        ]
    },
    {
        "id": 29794,
        "spelling_name": "Filbert",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47261,
                "word_type": "n.",
                "spelling_usage": "The fruit of the Corylus Avellana or hazel. It is an oval\n   nut, containing a kernel that has a mild, farinaceous, oily taste,\n   agreeable to the palate."
            }
        ]
    },
    {
        "id": 29805,
        "spelling_name": "Filial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47288,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a son or daughter; becoming to a child\n   in relation to his parents; as, filial obedience."
            },
            {
                "spelling_detail_id": 47289,
                "word_type": "a.",
                "spelling_usage": "Bearing the relation of a child."
            }
        ]
    },
    {
        "id": 29882,
        "spelling_name": "Financier",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47437,
                "word_type": "n.",
                "spelling_usage": "One charged with the administration of finance; an\n   officer who administers the public revenue; a treasurer."
            },
            {
                "spelling_detail_id": 47438,
                "word_type": "n.",
                "spelling_usage": "One skilled in financial operations; one acquainted with\n   money matters."
            },
            {
                "spelling_detail_id": 47441,
                "word_type": "v. i.",
                "spelling_usage": "To conduct financial operations."
            }
        ]
    },
    {
        "id": 29995,
        "spelling_name": "Firefly",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47657,
                "word_type": "n.",
                "spelling_usage": "Any luminous winged insect, esp. luminous beetles of the\n   family Lampyridae."
            }
        ]
    },
    {
        "id": 30017,
        "spelling_name": "Firmament",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47698,
                "word_type": "v. & a.",
                "spelling_usage": "Fixed foundation; established basis."
            },
            {
                "spelling_detail_id": 47699,
                "word_type": "v. & a.",
                "spelling_usage": "The region of the air; the sky or heavens."
            },
            {
                "spelling_detail_id": 47700,
                "word_type": "v. & a.",
                "spelling_usage": "The orb of the fixed stars; the most rmote of the\n   celestial spheres."
            }
        ]
    },
    {
        "id": 30039,
        "spelling_name": "Fiscal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47731,
                "word_type": "a.",
                "spelling_usage": "Pertaining to the public treasury or revenue."
            },
            {
                "spelling_detail_id": 47732,
                "word_type": "n.",
                "spelling_usage": "The income of a prince or a state; revenue; exhequer."
            },
            {
                "spelling_detail_id": 47733,
                "word_type": "n.",
                "spelling_usage": "A treasurer."
            },
            {
                "spelling_detail_id": 47734,
                "word_type": "n.",
                "spelling_usage": "A public officer in Scotland who prosecutes in petty\n   criminal cases; -- called also procurator fiscal."
            },
            {
                "spelling_detail_id": 47735,
                "word_type": "n.",
                "spelling_usage": "The solicitor in Spain and Portugal; the attorney-general."
            }
        ]
    },
    {
        "id": 30074,
        "spelling_name": "Fission",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47793,
                "word_type": "n.",
                "spelling_usage": "A cleaving, splitting, or breaking up into parts."
            },
            {
                "spelling_detail_id": 47794,
                "word_type": "n.",
                "spelling_usage": "A method of asexual reproduction among the lowest\n   (unicellular) organisms by means of a process of self-division,\n   consisting of gradual division or cleavage of the into two parts, each\n   of which then becomes a separate and independent organisms; as when a\n   cell in an animal or plant, or its germ, undergoes a spontaneous\n   division, and the parts again subdivide. See Segmentation, and Cell\n   division, under Division."
            },
            {
                "spelling_detail_id": 47795,
                "word_type": "n.",
                "spelling_usage": "A process by which certain coral polyps, echinoderms,\n   annelids, etc., spontaneously subdivide, each individual thus forming\n   two or more new ones. See Strobilation."
            }
        ]
    },
    {
        "id": 30158,
        "spelling_name": "Flabbergast",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 47948,
                "word_type": "v. t.",
                "spelling_usage": "To astonish; to strike with wonder, esp. by\n   extraordinary statements."
            }
        ]
    },
    {
        "id": 30196,
        "spelling_name": "Flagon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48012,
                "word_type": "n.",
                "spelling_usage": "A vessel with a narrow mouth, used for holding and\n   conveying liquors. It is generally larger than a bottle, and of leather\n   or stoneware rather than of glass."
            }
        ]
    },
    {
        "id": 30414,
        "spelling_name": "Fleetness",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48422,
                "word_type": "n.",
                "spelling_usage": "Swiftness; rapidity; velocity; celerity; speed; as, the\n   fleetness of a horse or of time."
            }
        ]
    },
    {
        "id": 30488,
        "spelling_name": "Flimsy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48547,
                "word_type": "superl.",
                "spelling_usage": "Weak; feeble; limp; slight; vain; without strength or\n   solidity; of loose and unsubstantial structure; without reason or\n   plausibility; as, a flimsy argument, excuse, objection."
            },
            {
                "spelling_detail_id": 48548,
                "word_type": "n.",
                "spelling_usage": "Thin or transfer paper."
            },
            {
                "spelling_detail_id": 48549,
                "word_type": "n.",
                "spelling_usage": "A bank note."
            }
        ]
    },
    {
        "id": 30649,
        "spelling_name": "Flotsam",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48832,
                "word_type": "n.",
                "spelling_usage": "Alt. of Flotson"
            }
        ]
    },
    {
        "id": 30661,
        "spelling_name": "Flourish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48855,
                "word_type": "v. i.",
                "spelling_usage": "To grow luxuriantly; to increase and enlarge, as a\n   healthy growing plant; a thrive."
            },
            {
                "spelling_detail_id": 48856,
                "word_type": "v. i.",
                "spelling_usage": "To be prosperous; to increase in wealth, honor,\n   comfort, happiness, or whatever is desirable; to thrive; to be\n   prominent and influental; specifically, of authors, painters, etc., to\n   be in a state of activity or production."
            },
            {
                "spelling_detail_id": 48857,
                "word_type": "v. i.",
                "spelling_usage": "To use florid language; to indulge in rhetorical\n   figures and lofty expressions; to be flowery."
            },
            {
                "spelling_detail_id": 48858,
                "word_type": "v. i.",
                "spelling_usage": "To make bold and sweeping, fanciful, or wanton\n   movements, by way of ornament, parade, bravado, etc.; to play with\n   fantastic and irregular motion."
            },
            {
                "spelling_detail_id": 48859,
                "word_type": "v. i.",
                "spelling_usage": "To make ornamental strokes with the pen; to write\n   graceful, decorative figures."
            },
            {
                "spelling_detail_id": 48860,
                "word_type": "v. i.",
                "spelling_usage": "To execute an irregular or fanciful strain of music,\n   by way of ornament or prelude."
            },
            {
                "spelling_detail_id": 48861,
                "word_type": "v. i.",
                "spelling_usage": "To boast; to vaunt; to brag."
            },
            {
                "spelling_detail_id": 48862,
                "word_type": "v. t.",
                "spelling_usage": "To adorn with flowers orbeautiful figures, either\n   natural or artificial; to ornament with anything showy; to embellish."
            },
            {
                "spelling_detail_id": 48863,
                "word_type": "v. t.",
                "spelling_usage": "To embellish with the flowers of diction; to adorn\n   with rhetorical figures; to grace with ostentatious eloquence; to set\n   off with a parade of words."
            },
            {
                "spelling_detail_id": 48864,
                "word_type": "v. t.",
                "spelling_usage": "To move in bold or irregular figures; to swing about\n   in circles or vibrations by way of show or triumph; to brandish."
            },
            {
                "spelling_detail_id": 48865,
                "word_type": "v. t.",
                "spelling_usage": "To develop; to make thrive; to expand."
            },
            {
                "spelling_detail_id": 48867,
                "word_type": "n.",
                "spelling_usage": "A flourishing condition; prosperity; vigor."
            },
            {
                "spelling_detail_id": 48868,
                "word_type": "n.",
                "spelling_usage": "Decoration; ornament; beauty."
            },
            {
                "spelling_detail_id": 48869,
                "word_type": "n.",
                "spelling_usage": "Something made or performed in a fanciful, wanton, or\n   vaunting manner, by way of ostentation, to excite admiration, etc.;\n   ostentatious embellishment; ambitious copiousness or amplification;\n   parade of words and figures; show; as, a flourish of rhetoric or of\n   wit."
            },
            {
                "spelling_detail_id": 48870,
                "word_type": "n.",
                "spelling_usage": "A fanciful stroke of the pen or graver; a merely\n   decorative figure."
            },
            {
                "spelling_detail_id": 48871,
                "word_type": "n.",
                "spelling_usage": "A fantastic or decorative musical passage; a strain of\n   triumph or bravado, not forming part of a regular musical composition;\n   a cal; a fanfare."
            },
            {
                "spelling_detail_id": 48872,
                "word_type": "n.",
                "spelling_usage": "The waving of a weapon or other thing; a brandishing; as,\n   the flourish of a sword."
            }
        ]
    },
    {
        "id": 30710,
        "spelling_name": "Fluctuation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48958,
                "word_type": "n.",
                "spelling_usage": "A motion like that of waves; a moving in this and that\n   direction; as, the fluctuations of the sea."
            },
            {
                "spelling_detail_id": 48959,
                "word_type": "n.",
                "spelling_usage": "A wavering; unsteadiness; as, fluctuations of opinion;\n   fluctuations of prices."
            },
            {
                "spelling_detail_id": 48960,
                "word_type": "n.",
                "spelling_usage": "The motion or undulation of a fluid collected in a\n   natural or artifical cavity, which is felt when it is subjected to\n   pressure or percussion."
            }
        ]
    },
    {
        "id": 30713,
        "spelling_name": "Fluency",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 48967,
                "word_type": "n.",
                "spelling_usage": "The quality of being fluent; smoothness; readiness of\n   utterance; volubility."
            }
        ]
    },
    {
        "id": 30766,
        "spelling_name": "Fluoride",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 49036,
                "word_type": "n.",
                "spelling_usage": "A binary compound of fluorine with another element or\n   radical."
            }
        ]
    },
    {
        "id": 30776,
        "spelling_name": "Flurry",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 49047,
                "word_type": "n.",
                "spelling_usage": "A sudden and brief blast or gust; a light, temporary\n   breeze; as, a flurry of wind."
            },
            {
                "spelling_detail_id": 49048,
                "word_type": "n.",
                "spelling_usage": "A light shower or snowfall accompanied with wind."
            },
            {
                "spelling_detail_id": 49049,
                "word_type": "n.",
                "spelling_usage": "Violent agitation; commotion; bustle; hurry."
            },
            {
                "spelling_detail_id": 49050,
                "word_type": "n.",
                "spelling_usage": "The violent spasms of a dying whale."
            },
            {
                "spelling_detail_id": 49053,
                "word_type": "v. t.",
                "spelling_usage": "To put in a state of agitation; to excite or alarm."
            }
        ]
    },
    {
        "id": 30960,
        "spelling_name": "Follicle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 49425,
                "word_type": "n.",
                "spelling_usage": "A simple podlike pericarp which contains several seeds\n   and opens along the inner or ventral suture, as in the peony, larkspur\n   and milkweed."
            },
            {
                "spelling_detail_id": 49426,
                "word_type": "n.",
                "spelling_usage": "A small cavity, tubular depression, or sac; as, a hair\n   follicle."
            },
            {
                "spelling_detail_id": 49427,
                "word_type": "n.",
                "spelling_usage": "A simple gland or glandular cavity; a crypt."
            },
            {
                "spelling_detail_id": 49428,
                "word_type": "n.",
                "spelling_usage": "A small mass of adenoid tissue; as, a lymphatic follicle."
            }
        ]
    },
    {
        "id": 30974,
        "spelling_name": "Fomentation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 49467,
                "word_type": "n.",
                "spelling_usage": "The act of fomenting; the application of warm, soft,\n   medicinal substances, as for the purpose of easing pain, by relaxing\n   the skin, or of discussing tumors."
            },
            {
                "spelling_detail_id": 49468,
                "word_type": "n.",
                "spelling_usage": "The lotion applied to a diseased part."
            },
            {
                "spelling_detail_id": 49469,
                "word_type": "n.",
                "spelling_usage": "Excitation; instigation; encouragement."
            }
        ]
    },
    {
        "id": 31366,
        "spelling_name": "Forfeit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50088,
                "word_type": "n.",
                "spelling_usage": "Injury; wrong; mischief."
            },
            {
                "spelling_detail_id": 50089,
                "word_type": "n.",
                "spelling_usage": "A thing forfeit or forfeited; what is or may be taken from\n   one in requital of a misdeed committed; that which is lost, or the\n   right to which is alienated, by a crime, offense, neglect of duty, or\n   breach of contract; hence, a fine; a mulct; a penalty; as, he who\n   murders pays the forfeit of his life."
            },
            {
                "spelling_detail_id": 50090,
                "word_type": "n.",
                "spelling_usage": "Something deposited and redeemable by a sportive fine; --\n   whence the game of forfeits."
            },
            {
                "spelling_detail_id": 50091,
                "word_type": "n.",
                "spelling_usage": "Lost or alienated for an offense or crime; liable to penal\n   seizure."
            },
            {
                "spelling_detail_id": 50094,
                "word_type": "n.",
                "spelling_usage": "To lose, or lose the right to, by some error, fault,\n   offense, or crime; to render one's self by misdeed liable to be\n   deprived of; to alienate the right to possess, by some neglect or\n   crime; as, to forfeit an estate by treason; to forfeit reputation by a\n   breach of promise; -- with to before the one acquiring what is\n   forfeited."
            },
            {
                "spelling_detail_id": 50095,
                "word_type": "v. i.",
                "spelling_usage": "To be guilty of a misdeed; to be criminal; to\n   transgress."
            },
            {
                "spelling_detail_id": 50096,
                "word_type": "v. i.",
                "spelling_usage": "To fail to keep an obligation."
            },
            {
                "spelling_detail_id": 50097,
                "word_type": "p. p. / a.",
                "spelling_usage": "In the condition of being forfeited; subject to\n   alienation."
            }
        ]
    },
    {
        "id": 31450,
        "spelling_name": "Formation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50268,
                "word_type": "n.",
                "spelling_usage": "The act of giving form or shape to anything; a forming;\n   a shaping."
            },
            {
                "spelling_detail_id": 50269,
                "word_type": "n.",
                "spelling_usage": "The manner in which a thing is formed; structure;\n   construction; conformation; form; as, the peculiar formation of the\n   heart."
            },
            {
                "spelling_detail_id": 50270,
                "word_type": "n.",
                "spelling_usage": "A substance formed or deposited."
            },
            {
                "spelling_detail_id": 50271,
                "word_type": "n.",
                "spelling_usage": "Mineral deposits and rock masses designated with\n   reference to their origin; as, the siliceous formation about geysers;\n   alluvial formations; marine formations."
            },
            {
                "spelling_detail_id": 50272,
                "word_type": "n.",
                "spelling_usage": "A group of beds of the same age or period; as, the\n   Eocene formation."
            },
            {
                "spelling_detail_id": 50273,
                "word_type": "n.",
                "spelling_usage": "The arrangement of a body of troops, as in a square,\n   column, etc."
            }
        ]
    },
    {
        "id": 31461,
        "spelling_name": "Formica",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50296,
                "word_type": "n.",
                "spelling_usage": "A Linnaean genus of hymenopterous insects, including the\n   common ants. See Ant."
            }
        ]
    },
    {
        "id": 31667,
        "spelling_name": "Fountain",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50628,
                "word_type": "n.",
                "spelling_usage": "A spring of water issuing from the earth."
            },
            {
                "spelling_detail_id": 50629,
                "word_type": "n.",
                "spelling_usage": "An artificially produced jet or stream of water; also,\n   the structure or works in which such a jet or stream rises or flows; a\n   basin built and constantly supplied with pure water for drinking and\n   other useful purposes, or for ornament."
            },
            {
                "spelling_detail_id": 50630,
                "word_type": "n.",
                "spelling_usage": "A reservoir or chamber to contain a liquid which can be\n   conducted or drawn off as needed for use; as, the ink fountain in a\n   printing press, etc."
            },
            {
                "spelling_detail_id": 50631,
                "word_type": "n.",
                "spelling_usage": "The source from which anything proceeds, or from which\n   anything is supplied continuously; origin; source."
            }
        ]
    },
    {
        "id": 31746,
        "spelling_name": "Fracas",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50726,
                "word_type": "v. t.",
                "spelling_usage": "An uproar; a noisy quarrel; a disturbance; a brawl."
            }
        ]
    },
    {
        "id": 31768,
        "spelling_name": "Fragment",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50792,
                "word_type": "v. t.",
                "spelling_usage": "A part broken off; a small, detached portion; an\n   imperfect part; as, a fragment of an ancient writing."
            }
        ]
    },
    {
        "id": 31864,
        "spelling_name": "Fraudulent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 50951,
                "word_type": "a.",
                "spelling_usage": "Using fraud; trickly; deceitful; dishonest."
            },
            {
                "spelling_detail_id": 50952,
                "word_type": "a.",
                "spelling_usage": "Characterized by,, founded on, or proceeding from,\n   fraund; as, a fraudulent bargain."
            },
            {
                "spelling_detail_id": 50953,
                "word_type": "a.",
                "spelling_usage": "Obtained or performed by artifice; as, fraudulent\n   conquest."
            }
        ]
    },
    {
        "id": 32104,
        "spelling_name": "Frisket",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 51381,
                "word_type": "a.",
                "spelling_usage": "The light frame which holds the sheet of paper to the\n   tympan in printing."
            }
        ]
    },
    {
        "id": 32187,
        "spelling_name": "Frontier",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 51517,
                "word_type": "n.",
                "spelling_usage": "That part of a country which fronts or faces another\n   country or an unsettled region; the marches; the border, confine, or\n   extreme part of a country, bordering on another country; the border of\n   the settled and cultivated part of a country; as, the frontier of\n   civilization."
            },
            {
                "spelling_detail_id": 51518,
                "word_type": "n.",
                "spelling_usage": "An outwork."
            },
            {
                "spelling_detail_id": 51519,
                "word_type": "a.",
                "spelling_usage": "Lying on the exterior part; bordering; conterminous; as,\n   a frontier town."
            },
            {
                "spelling_detail_id": 51520,
                "word_type": "a.",
                "spelling_usage": "Of or relating to a frontier."
            },
            {
                "spelling_detail_id": 51521,
                "word_type": "v. i.",
                "spelling_usage": "To constitute or form a frontier; to have a frontier;\n   -- with on."
            }
        ]
    },
    {
        "id": 32236,
        "spelling_name": "Froward",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 51606,
                "word_type": "a.",
                "spelling_usage": "Not willing to yield or compIy with what is required or is\n   reasonable; perverse; disobedient; peevish; as, a froward child."
            }
        ]
    },
    {
        "id": 32266,
        "spelling_name": "Frugal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 51645,
                "word_type": "n.",
                "spelling_usage": "Economical in the use or appropriation of resources; not\n   wasteful or lavish; wise in the expenditure or application of force,\n   materials, time, etc.; characterized by frugality; sparing; economical;\n   saving; as, a frugal housekeeper; frugal of time."
            },
            {
                "spelling_detail_id": 51646,
                "word_type": "n.",
                "spelling_usage": "Obtained by, or appropriate to, economy; as, a frugal\n   fortune."
            }
        ]
    },
    {
        "id": 32336,
        "spelling_name": "Fucoid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 51747,
                "word_type": "a.",
                "spelling_usage": "Properly, belonging to an order of alga: (Fucoideae) which\n   are blackish in color, and produce oospores which are not fertilized\n   until they have escaped from the conceptacle. The common rockweeds and\n   the gulfweed (Sargassum) are fucoid in character."
            },
            {
                "spelling_detail_id": 51748,
                "word_type": "a.",
                "spelling_usage": "In a vague sense, resembling seaweeds, or of the nature of\n   seaweeds."
            },
            {
                "spelling_detail_id": 51749,
                "word_type": "n.",
                "spelling_usage": "A plant, whether recent or fossil, which resembles a\n   seaweed. See Fucoid, a."
            }
        ]
    },
    {
        "id": 32496,
        "spelling_name": "Funambulist",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 51997,
                "word_type": "n.",
                "spelling_usage": "A ropewalker or ropedancer."
            }
        ]
    },
    {
        "id": 32555,
        "spelling_name": "Funnel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 52093,
                "word_type": "v. t.",
                "spelling_usage": "A vessel of the shape of an inverted hollow cone,\n   terminating below in a pipe, and used for conveying liquids into a\n   close vessel; a tunnel."
            },
            {
                "spelling_detail_id": 52094,
                "word_type": "v. t.",
                "spelling_usage": "A passage or avenue for a fluid or flowing substance;\n   specifically, a smoke flue or pipe; the iron chimney of a steamship or\n   the like."
            }
        ]
    },
    {
        "id": 32686,
        "spelling_name": "Futility",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 52302,
                "word_type": "n.",
                "spelling_usage": "The quality of being talkative; talkativeness;\n   loquaciousness; loquacity."
            },
            {
                "spelling_detail_id": 52303,
                "word_type": "n.",
                "spelling_usage": "The quality of producing no valuable effect, or of coming\n   to nothing; uselessness."
            }
        ]
    },
    {
        "id": 32863,
        "spelling_name": "Gallant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 52589,
                "word_type": "a.",
                "spelling_usage": "Showy; splendid; magnificent; gay; well-dressed."
            },
            {
                "spelling_detail_id": 52590,
                "word_type": "a.",
                "spelling_usage": "Noble in bearing or spirit; brave; high-spirited;\n   courageous; heroic; magnanimous; as, a gallant youth; a gallant\n   officer."
            },
            {
                "spelling_detail_id": 52591,
                "word_type": "a.",
                "spelling_usage": "Polite and attentive to ladies; courteous to women;\n   chivalrous."
            },
            {
                "spelling_detail_id": 52592,
                "word_type": "n.",
                "spelling_usage": "A man of mettle or spirit; a gay; fashionable man; a young\n   blood."
            },
            {
                "spelling_detail_id": 52593,
                "word_type": "n.",
                "spelling_usage": "One fond of paying attention to ladies."
            },
            {
                "spelling_detail_id": 52594,
                "word_type": "n.",
                "spelling_usage": "One who wooes; a lover; a suitor; in a bad sense, a\n   seducer."
            },
            {
                "spelling_detail_id": 52597,
                "word_type": "v. t.",
                "spelling_usage": "To attend or wait on, as a lady; as, to gallant ladies\n   to the play."
            },
            {
                "spelling_detail_id": 52598,
                "word_type": "v. t.",
                "spelling_usage": "To handle with grace or in a modish manner; as, to\n   gallant a fan."
            }
        ]
    },
    {
        "id": 32956,
        "spelling_name": "Galvanize",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 52733,
                "word_type": "v. t.",
                "spelling_usage": "To affect with galvanism; to subject to the action of\n   electrical currents."
            },
            {
                "spelling_detail_id": 52734,
                "word_type": "v. t.",
                "spelling_usage": "To plate, as with gold, silver, etc., by means of\n   electricity."
            },
            {
                "spelling_detail_id": 52735,
                "word_type": "v. t.",
                "spelling_usage": "To restore to consciousness by galvanic action (as\n   from a state of suspended animation); hence, to stimulate or excite to\n   a factitious animation or activity."
            },
            {
                "spelling_detail_id": 52736,
                "word_type": "v. t.",
                "spelling_usage": "To coat, as iron, with zinc. See Galvanized iron."
            }
        ]
    },
    {
        "id": 33136,
        "spelling_name": "Garish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 52993,
                "word_type": "a.",
                "spelling_usage": "Showy; dazzling; ostentatious; attracting or exciting\n   attention."
            },
            {
                "spelling_detail_id": 52994,
                "word_type": "a.",
                "spelling_usage": "Gay to extravagance; flighty."
            }
        ]
    },
    {
        "id": 33160,
        "spelling_name": "Garniture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53040,
                "word_type": "v. t.",
                "spelling_usage": "That which garnishes; ornamental appendage;\n   embellishment; furniture; dress."
            }
        ]
    },
    {
        "id": 33206,
        "spelling_name": "Gasiform",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53106,
                "word_type": "a.",
                "spelling_usage": "Having a form of gas; gaseous."
            }
        ]
    },
    {
        "id": 33265,
        "spelling_name": "Gastronome",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53178,
                "word_type": "n.",
                "spelling_usage": "Alt. of Gastronomer"
            }
        ]
    },
    {
        "id": 33311,
        "spelling_name": "Gaucho",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53259,
                "word_type": "n.",
                "spelling_usage": "One of the native inhabitants of the pampas, of\n   Spanish-American descent. They live mostly by rearing cattle."
            }
        ]
    },
    {
        "id": 33316,
        "spelling_name": "Gaudery",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53268,
                "word_type": "n.",
                "spelling_usage": "Finery; ornaments; ostentatious display."
            }
        ]
    },
    {
        "id": 33387,
        "spelling_name": "Gazette",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53378,
                "word_type": "n.",
                "spelling_usage": "A newspaper; a printed sheet published periodically; esp.,\n   the official journal published by the British government, and\n   containing legal and state notices."
            },
            {
                "spelling_detail_id": 53381,
                "word_type": "v. t.",
                "spelling_usage": "To announce or publish in a gazette; to announce\n   officially, as an appointment, or a case of bankruptcy."
            }
        ]
    },
    {
        "id": 33469,
        "spelling_name": "Gemini",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53508,
                "word_type": "n. pl.",
                "spelling_usage": "A constellation of the zodiac, containing the two\n   bright stars Castor and Pollux; also, the third sign of the zodiac,\n   which the sun enters about May 20th."
            }
        ]
    },
    {
        "id": 33513,
        "spelling_name": "Genealogical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53569,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to genealogy; as, a genealogical\n   table; genealogical order."
            }
        ]
    },
    {
        "id": 33604,
        "spelling_name": "Genteel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53733,
                "word_type": "a.",
                "spelling_usage": "Possessing or exhibiting the qualities popularly regarded\n   as belonging to high birth and breeding; free from vulgarity, or\n   lowness of taste or behavior; adapted to a refined or cultivated taste;\n   polite; well-bred; as, genteel company, manners, address."
            },
            {
                "spelling_detail_id": 53734,
                "word_type": "a.",
                "spelling_usage": "Graceful in mien or form; elegant in appearance, dress, or\n   manner; as, the lady has a genteel person. Law."
            },
            {
                "spelling_detail_id": 53735,
                "word_type": "a.",
                "spelling_usage": "Suited to the position of lady or a gentleman; as, to live\n   in a genteel allowance."
            }
        ]
    },
    {
        "id": 33658,
        "spelling_name": "Genus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53816,
                "word_type": "n.",
                "spelling_usage": "A class of objects divided into several subordinate species;\n   a class more extensive than a species; a precisely defined and exactly\n   divided class; one of the five predicable conceptions, or sorts of\n   terms."
            },
            {
                "spelling_detail_id": 53817,
                "word_type": "n.",
                "spelling_usage": "An assemblage of species, having so many fundamental points\n   of structure in common, that in the judgment of competent scientists,\n   they may receive a common substantive name. A genus is not necessarily\n   the lowest definable group of species, for it may often be divided into\n   several subgenera. In proportion as its definition is exact, it is\n   natural genus; if its definition can not be made clear, it is more or\n   less an artificial genus."
            }
        ]
    },
    {
        "id": 33769,
        "spelling_name": "Germane",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 53950,
                "word_type": "a.",
                "spelling_usage": "Literally, near akin; hence, closely allied; appropriate\n   or fitting; relevant."
            }
        ]
    },
    {
        "id": 33827,
        "spelling_name": "Gesture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54032,
                "word_type": "n.",
                "spelling_usage": "Manner of carrying the body; position of the body or\n   limbs; posture."
            },
            {
                "spelling_detail_id": 54033,
                "word_type": "n.",
                "spelling_usage": "A motion of the body or limbs expressive of sentiment or\n   passion; any action or posture intended to express an idea or a\n   passion, or to enforce or emphasize an argument, assertion, or opinion."
            },
            {
                "spelling_detail_id": 54036,
                "word_type": "v. t.",
                "spelling_usage": "To accompany or illustrate with gesture or action; to\n   gesticulate."
            },
            {
                "spelling_detail_id": 54037,
                "word_type": "v. i.",
                "spelling_usage": "To make gestures; to gesticulate."
            }
        ]
    },
    {
        "id": 33848,
        "spelling_name": "Ghastly",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54075,
                "word_type": "superl.",
                "spelling_usage": "Like a ghost in appearance; deathlike; pale; pallid;\n   dismal."
            },
            {
                "spelling_detail_id": 54076,
                "word_type": "superl.",
                "spelling_usage": "Horrible; shocking; dreadful; hideous."
            },
            {
                "spelling_detail_id": 54077,
                "word_type": "adv.",
                "spelling_usage": "In a ghastly manner; hideously."
            }
        ]
    },
    {
        "id": 33896,
        "spelling_name": "Gibbous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54146,
                "word_type": "a.",
                "spelling_usage": "Swelling by a regular curve or surface; protuberant;\n   convex; as, the moon is gibbous between the half-moon and the full\n   moon."
            },
            {
                "spelling_detail_id": 54147,
                "word_type": "a.",
                "spelling_usage": "Hunched; hump-backed."
            }
        ]
    },
    {
        "id": 33954,
        "spelling_name": "Gilded",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54231,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Gild"
            }
        ]
    },
    {
        "id": 34091,
        "spelling_name": "Gladiatorial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54503,
                "word_type": "a.",
                "spelling_usage": "Alt. of Gladiatorian"
            }
        ]
    },
    {
        "id": 34142,
        "spelling_name": "Glareous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54593,
                "word_type": "a.",
                "spelling_usage": "Glairy."
            }
        ]
    },
    {
        "id": 34269,
        "spelling_name": "Glissando",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54793,
                "word_type": "n. & a.",
                "spelling_usage": "A gliding effect; gliding."
            }
        ]
    },
    {
        "id": 34272,
        "spelling_name": "Glisten",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54798,
                "word_type": "v. i.",
                "spelling_usage": "To sparkle or shine; especially, to shine with a mild,\n   subdued, and fitful luster; to emit a soft, scintillating light; to\n   gleam; as, the glistening stars."
            }
        ]
    },
    {
        "id": 34306,
        "spelling_name": "Globular",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 54842,
                "word_type": "a.",
                "spelling_usage": "Globe-shaped; having the form of a ball or sphere;\n   spherical, or nearly so; as, globular atoms."
            }
        ]
    },
    {
        "id": 34476,
        "spelling_name": "Gluttonous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 55094,
                "word_type": "a.",
                "spelling_usage": "Given to gluttony; eating to excess; indulging the\n   appetite; voracious; as, a gluttonous age."
            }
        ]
    },
    {
        "id": 34534,
        "spelling_name": "Gnarled",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 55160,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Gnarl"
            },
            {
                "spelling_detail_id": 55164,
                "word_type": "a.",
                "spelling_usage": "Knotty; full of knots or gnarls; twisted; crossgrained."
            }
        ]
    },
    {
        "id": 34885,
        "spelling_name": "Gossamer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 55696,
                "word_type": "n.",
                "spelling_usage": "A fine, filmy substance, like cobwebs, floating in the\n   air, in calm, clear weather, especially in autumn. It is seen in\n   stubble fields and on furze or low bushes, and is formed by small\n   spiders."
            },
            {
                "spelling_detail_id": 55697,
                "word_type": "n.",
                "spelling_usage": "Any very thin gauzelike fabric; also, a thin waterproof\n   stuff."
            },
            {
                "spelling_detail_id": 55698,
                "word_type": "n.",
                "spelling_usage": "An outer garment, made of waterproof gossamer."
            }
        ]
    },
    {
        "id": 34906,
        "spelling_name": "Gothamite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 55727,
                "word_type": "n.",
                "spelling_usage": "A gothamist."
            },
            {
                "spelling_detail_id": 55728,
                "word_type": "n.",
                "spelling_usage": "An inhabitant of New York city."
            }
        ]
    },
    {
        "id": 34917,
        "spelling_name": "Gouge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 55746,
                "word_type": "n.",
                "spelling_usage": "A chisel, with a hollow or semicylindrical blade, for\n   scooping or cutting holes, channels, or grooves, in wood, stone, etc.;\n   a similar instrument, with curved edge, for turning wood."
            },
            {
                "spelling_detail_id": 55747,
                "word_type": "n.",
                "spelling_usage": "A bookbinder's tool for blind tooling or gilding, having a\n   face which forms a curve."
            },
            {
                "spelling_detail_id": 55748,
                "word_type": "n.",
                "spelling_usage": "An incising tool which cuts forms or blanks for gloves,\n   envelopes, etc. from leather, paper, etc."
            },
            {
                "spelling_detail_id": 55749,
                "word_type": "n.",
                "spelling_usage": "Soft material lying between the wall of a vein aud the solid\n   vein."
            },
            {
                "spelling_detail_id": 55750,
                "word_type": "n.",
                "spelling_usage": "The act of scooping out with a gouge, or as with a gouge; a\n   groove or cavity scooped out, as with a gouge."
            },
            {
                "spelling_detail_id": 55751,
                "word_type": "n.",
                "spelling_usage": "Imposition; cheat; fraud; also, an impostor; a cheat; a\n   trickish person."
            }
        ]
    },
    {
        "id": 34928,
        "spelling_name": "Gourd",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 55766,
                "word_type": "n.",
                "spelling_usage": "A fleshy, three-celled, many-seeded fruit, as the melon,\n   pumpkin, cucumber, etc., of the order Cucurbitaceae; and especially the\n   bottle gourd (Lagenaria vulgaris) which occurs in a great variety of\n   forms, and, when the interior part is removed, serves for bottles,\n   dippers, cups, and other dishes."
            },
            {
                "spelling_detail_id": 55767,
                "word_type": "n.",
                "spelling_usage": "A dipper or other vessel made from the shell of a gourd;\n   hence, a drinking vessel; a bottle."
            },
            {
                "spelling_detail_id": 55768,
                "word_type": "n.",
                "spelling_usage": "A false die. See Gord."
            },
            {
                "spelling_detail_id": 55769,
                "word_type": "n.",
                "spelling_usage": "Alt. of Gourde"
            }
        ]
    },
    {
        "id": 35098,
        "spelling_name": "Grandeur",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 56100,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being grand; vastness; greatness;\n   splendor; magnificence; stateliness; sublimity; dignity; elevation of\n   thought or expression; nobility of action."
            }
        ]
    },
    {
        "id": 35105,
        "spelling_name": "Grandiloquent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 56108,
                "word_type": "a.",
                "spelling_usage": "Speaking in a lofty style; pompous; bombastic."
            }
        ]
    },
    {
        "id": 35214,
        "spelling_name": "Grateful",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 56287,
                "word_type": "a.",
                "spelling_usage": "Having a due sense of benefits received; kindly disposed\n   toward one from whom a favor has been received; willing to acknowledge\n   and repay, or give thanks for, benefits; as, a grateful heart."
            },
            {
                "spelling_detail_id": 56288,
                "word_type": "a.",
                "spelling_usage": "Affording pleasure; pleasing to the senses; gratifying;\n   delicious; as, a grateful present; food grateful to the palate;\n   grateful sleep."
            }
        ]
    },
    {
        "id": 35225,
        "spelling_name": "Gratis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 56307,
                "word_type": "adv.",
                "spelling_usage": "For nothing; without fee or recompense; freely;\n   gratuitously."
            }
        ]
    },
    {
        "id": 35395,
        "spelling_name": "Gregorian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 56621,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or originated by, some person named\n   Gregory, especially one of the popes of that name."
            }
        ]
    },
    {
        "id": 35590,
        "spelling_name": "Grotesqueness",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 56952,
                "word_type": "n.",
                "spelling_usage": "Quality of being grotesque."
            }
        ]
    },
    {
        "id": 35760,
        "spelling_name": "Gubernatorial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 57236,
                "word_type": "a.",
                "spelling_usage": "Pertaining to a governor, or to government."
            }
        ]
    },
    {
        "id": 35968,
        "spelling_name": "Gustatory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 57543,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or subservient to, the sense of taste;\n   as, the gustatory nerve which supplies the front of the tongue."
            }
        ]
    },
    {
        "id": 35992,
        "spelling_name": "Guttural",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 57580,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the throat; formed in the throat;\n   relating to, or characteristic of, a sound formed in the throat."
            },
            {
                "spelling_detail_id": 57581,
                "word_type": "n.",
                "spelling_usage": "A sound formed in the throat; esp., a sound formed by the\n   aid of the back of the tongue, much retracted, and the soft palate;\n   also, a letter representing such a sound."
            }
        ]
    },
    {
        "id": 36098,
        "spelling_name": "Gypsum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 57698,
                "word_type": "n.",
                "spelling_usage": "A mineral consisting of the hydrous sulphate of lime\n   (calcium). When calcined, it forms plaster of Paris. Selenite is a\n   transparent, crystalline variety; alabaster, a fine, white, massive\n   variety."
            }
        ]
    },
    {
        "id": 36318,
        "spelling_name": "Haggis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 58005,
                "word_type": "n.",
                "spelling_usage": "A Scotch pudding made of the heart, liver, lights, etc., of\n   a sheep or lamb, minced with suet, onions, oatmeal, etc., highly\n   seasoned, and boiled in the stomach of the same animal; minced head and\n   pluck."
            }
        ]
    },
    {
        "id": 36442,
        "spelling_name": "Halibut",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 58184,
                "word_type": "n.",
                "spelling_usage": "A large, northern, marine flatfish (Hippoglossus\n   vulgaris), of the family Pleuronectidae. It often grows very large,\n   weighing more than three hundred pounds. It is an important food fish."
            }
        ]
    },
    {
        "id": 36471,
        "spelling_name": "Hallowed",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 58224,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Hallow"
            }
        ]
    },
    {
        "id": 36477,
        "spelling_name": "Hallucinate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 58231,
                "word_type": "v. i.",
                "spelling_usage": "To wander; to go astray; to err; to blunder; --\n   used of mental processes."
            }
        ]
    },
    {
        "id": 36679,
        "spelling_name": "Haphazard",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 58596,
                "word_type": "n.",
                "spelling_usage": "Extra hazard; chance; accident; random."
            }
        ]
    },
    {
        "id": 36707,
        "spelling_name": "Harbinger",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 58635,
                "word_type": "n.",
                "spelling_usage": "One who provides lodgings; especially, the officer of\n   the English royal household who formerly preceded the court when\n   traveling, to provide and prepare lodgings."
            },
            {
                "spelling_detail_id": 58636,
                "word_type": "n.",
                "spelling_usage": "A forerunner; a precursor; a messenger."
            },
            {
                "spelling_detail_id": 58639,
                "word_type": "v. t.",
                "spelling_usage": "To usher in; to be a harbinger of."
            }
        ]
    },
    {
        "id": 37438,
        "spelling_name": "Heliotrope",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 59926,
                "word_type": "n.",
                "spelling_usage": "An instrument or machine for showing when the sun\n   arrived at the tropics and equinoctial line."
            },
            {
                "spelling_detail_id": 59927,
                "word_type": "n.",
                "spelling_usage": "A plant of the genus Heliotropium; -- called also\n   turnsole and girasole. H. Peruvianum is the commonly cultivated species\n   with fragrant flowers."
            },
            {
                "spelling_detail_id": 59928,
                "word_type": "n.",
                "spelling_usage": "An instrument for making signals to an observer at a\n   distance, by means of the sun's rays thrown from a mirror."
            },
            {
                "spelling_detail_id": 59929,
                "word_type": "n.",
                "spelling_usage": "See Bloodstone (a)."
            }
        ]
    },
    {
        "id": 37491,
        "spelling_name": "Helmet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60007,
                "word_type": "n.",
                "spelling_usage": "A defensive covering for the head. See Casque, Headpiece,\n   Morion, Sallet, and Illust. of Beaver."
            },
            {
                "spelling_detail_id": 60008,
                "word_type": "n.",
                "spelling_usage": "The representation of a helmet over shields or coats of\n   arms, denoting gradations of rank by modifications of form."
            },
            {
                "spelling_detail_id": 60009,
                "word_type": "n.",
                "spelling_usage": "A helmet-shaped hat, made of cork, felt, metal, or other\n   suitable material, worn as part of the uniform of soldiers, firemen,\n   etc., also worn in hot countries as a protection from the heat of the\n   sun."
            },
            {
                "spelling_detail_id": 60010,
                "word_type": "n.",
                "spelling_usage": "That which resembles a helmet in form, position, etc."
            },
            {
                "spelling_detail_id": 60011,
                "word_type": "n.",
                "spelling_usage": "The upper part of a retort."
            },
            {
                "spelling_detail_id": 60012,
                "word_type": "n.",
                "spelling_usage": "The hood-formed upper sepal or petal of some flowers, as of\n   the monkshood or the snapdragon."
            },
            {
                "spelling_detail_id": 60013,
                "word_type": "n.",
                "spelling_usage": "A naked shield or protuberance on the top or fore part of\n   the head of a bird."
            }
        ]
    },
    {
        "id": 37737,
        "spelling_name": "Heptad",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60323,
                "word_type": "n.",
                "spelling_usage": "An atom which has a valence of seven, and which can be\n   theoretically combined with, substituted for, or replaced by, seven\n   monad atoms or radicals; as, iodine is a heptad in iodic acid. Also\n   used as an adjective."
            }
        ]
    },
    {
        "id": 37783,
        "spelling_name": "Herbaceous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60376,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to herbs; having the nature, texture,\n   or characteristics, of an herb; as, herbaceous plants; an herbaceous\n   stem."
            }
        ]
    },
    {
        "id": 37821,
        "spelling_name": "Herculean",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60422,
                "word_type": "a.",
                "spelling_usage": "Requiring the strength of Hercules; hence, very great,\n   difficult, or dangerous; as, an Herculean task."
            },
            {
                "spelling_detail_id": 60423,
                "word_type": "a.",
                "spelling_usage": "Having extraordinary strength or size; as, Herculean\n   limbs."
            }
        ]
    },
    {
        "id": 37849,
        "spelling_name": "Heredity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60472,
                "word_type": "n.",
                "spelling_usage": "Hereditary transmission of the physical and psychical\n   qualities of parents to their offspring; the biological law by which\n   living beings tend to repeat their characteristics in their\n   descendants. See Pangenesis."
            }
        ]
    },
    {
        "id": 37909,
        "spelling_name": "Hermitage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60544,
                "word_type": "n.",
                "spelling_usage": "The habitation of a hermit; a secluded residence."
            },
            {
                "spelling_detail_id": 60545,
                "word_type": "n.",
                "spelling_usage": "A celebrated French wine, both white and red, of the\n   Department of Drome."
            }
        ]
    },
    {
        "id": 37954,
        "spelling_name": "Herringbone",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60595,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or like, the spine of a herring;\n   especially, characterized by an arrangement of work in rows of parallel\n   lines, which in the alternate rows slope in different directions."
            }
        ]
    },
    {
        "id": 38118,
        "spelling_name": "Hexagonal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60803,
                "word_type": "a.",
                "spelling_usage": "Having six sides and six angles; six-sided."
            }
        ]
    },
    {
        "id": 38171,
        "spelling_name": "Hiatus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60865,
                "word_type": "pl. ",
                "spelling_usage": "of Hiatus"
            },
            {
                "spelling_detail_id": 60867,
                "word_type": "n.",
                "spelling_usage": "An opening; an aperture; a gap; a chasm; esp., a defect in\n   a manuscript, where some part is lost or effaced; a space where\n   something is wanting; a break."
            },
            {
                "spelling_detail_id": 60868,
                "word_type": "n.",
                "spelling_usage": "The concurrence of two vowels in two successive words or\n   syllables."
            }
        ]
    },
    {
        "id": 38174,
        "spelling_name": "Hibernaculum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 60870,
                "word_type": "n.",
                "spelling_usage": "A winter bud, in which the rudimentary foliage or\n   flower, as of most trees and shrubs in the temperate zone, is protected\n   by closely overlapping scales."
            },
            {
                "spelling_detail_id": 60871,
                "word_type": "n.",
                "spelling_usage": "A little case in which certain insects pass the\n   winter."
            },
            {
                "spelling_detail_id": 60872,
                "word_type": "n.",
                "spelling_usage": "Winter home or abiding place."
            }
        ]
    },
    {
        "id": 38650,
        "spelling_name": "Hoity-toity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 61604,
                "word_type": "a.",
                "spelling_usage": "Thoughtless; giddy; flighty; also, haughty;\n   patronizing; as, to be in hoity-toity spirits, or to assume hoity-toity\n   airs; used also as an exclamation, denoting surprise or disapprobation,\n   with some degree of contempt."
            }
        ]
    },
    {
        "id": 38691,
        "spelling_name": "Hollyhock",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 61707,
                "word_type": "n.",
                "spelling_usage": "A species of Althaea (A. rosea), bearing flowers of\n   various colors; -- called also rose mallow."
            }
        ]
    },
    {
        "id": 38699,
        "spelling_name": "Holocaust",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 61719,
                "word_type": "n.",
                "spelling_usage": "A burnt sacrifice; an offering, the whole of which was\n   consumed by fire, among the Jews and some pagan nations."
            },
            {
                "spelling_detail_id": 61720,
                "word_type": "n.",
                "spelling_usage": "Sacrifice or loss of many lives, as by the burning of a\n   theater or a ship. [An extended use not authorized by careful writers.]"
            }
        ]
    },
    {
        "id": 38792,
        "spelling_name": "Homiletics",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 61853,
                "word_type": "n.",
                "spelling_usage": "The art of preaching; that branch of theology which\n   treats of homilies or sermons, and the best method of preparing and\n   delivering them."
            }
        ]
    },
    {
        "id": 38954,
        "spelling_name": "Honorific",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 62087,
                "word_type": "a.",
                "spelling_usage": "Conferring honor; tending to honor."
            }
        ]
    },
    {
        "id": 39210,
        "spelling_name": "Hostile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 62542,
                "word_type": "a.",
                "spelling_usage": "Belonging or appropriate to an enemy; showing the\n   disposition of an enemy; showing ill will and malevolence, or a desire\n   to thwart and injure; occupied by an enemy or enemies; inimical;\n   unfriendly; as, a hostile force; hostile intentions; a hostile country;\n   hostile to a sudden change."
            },
            {
                "spelling_detail_id": 62543,
                "word_type": "n.",
                "spelling_usage": "An enemy; esp., an American Indian in arms against the\n   whites; -- commonly in the plural."
            }
        ]
    },
    {
        "id": 39309,
        "spelling_name": "Hover",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 62732,
                "word_type": "n.",
                "spelling_usage": "A cover; a shelter; a protection."
            },
            {
                "spelling_detail_id": 62735,
                "word_type": "v. i.",
                "spelling_usage": "To hang fluttering in the air, or on the wing; to remain\n   in flight or floating about or over a place or object; to be suspended\n   in the air above something."
            },
            {
                "spelling_detail_id": 62736,
                "word_type": "v. i.",
                "spelling_usage": "To hang about; to move to and fro near a place,\n   threateningly, watchfully, or irresolutely."
            }
        ]
    },
    {
        "id": 39457,
        "spelling_name": "Humerus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 62990,
                "word_type": "n.",
                "spelling_usage": "The bone of the brachium, or upper part of the arm or fore\n   limb."
            },
            {
                "spelling_detail_id": 62991,
                "word_type": "n.",
                "spelling_usage": "The part of the limb containing the humerus; the brachium."
            }
        ]
    },
    {
        "id": 39483,
        "spelling_name": "Humor",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63023,
                "word_type": "n.",
                "spelling_usage": "Moisture, especially, the moisture or fluid of animal\n   bodies, as the chyle, lymph, etc.; as, the humors of the eye, etc."
            },
            {
                "spelling_detail_id": 63024,
                "word_type": "n.",
                "spelling_usage": "A vitiated or morbid animal fluid, such as often causes an\n   eruption on the skin."
            },
            {
                "spelling_detail_id": 63025,
                "word_type": "n.",
                "spelling_usage": "State of mind, whether habitual or temporary (as formerly\n   supposed to depend on the character or combination of the fluids of the\n   body); disposition; temper; mood; as, good humor; ill humor."
            },
            {
                "spelling_detail_id": 63026,
                "word_type": "n.",
                "spelling_usage": "Changing and uncertain states of mind; caprices; freaks;\n   vagaries; whims."
            },
            {
                "spelling_detail_id": 63027,
                "word_type": "n.",
                "spelling_usage": "That quality of the imagination which gives to ideas an\n   incongruous or fantastic turn, and tends to excite laughter or mirth by\n   ludicrous images or representations; a playful fancy; facetiousness."
            },
            {
                "spelling_detail_id": 63030,
                "word_type": "v. t.",
                "spelling_usage": "To comply with the humor of; to adjust matters so as suit\n   the peculiarities, caprices, or exigencies of; to adapt one's self to;\n   to indulge by skillful adaptation; as, to humor the mind."
            },
            {
                "spelling_detail_id": 63031,
                "word_type": "v. t.",
                "spelling_usage": "To help on by indulgence or compliant treatment; to\n   soothe; to gratify; to please."
            }
        ]
    },
    {
        "id": 39665,
        "spelling_name": "Hybrid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63347,
                "word_type": "n.",
                "spelling_usage": "The offspring of the union of two distinct species; an\n   animal or plant produced from the mixture of two species. See Mongrel."
            },
            {
                "spelling_detail_id": 63348,
                "word_type": "a.",
                "spelling_usage": "Produced from the mixture of two species; as, plants of\n   hybrid nature."
            }
        ]
    },
    {
        "id": 39694,
        "spelling_name": "Hydrangea",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63381,
                "word_type": "n.",
                "spelling_usage": "A genus of shrubby plants bearing opposite leaves and\n   large heads of showy flowers, white, or of various colors. H.\n   hortensis, the common garden species, is a native of China or Japan."
            }
        ]
    },
    {
        "id": 39695,
        "spelling_name": "Hydrant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63382,
                "word_type": "n.",
                "spelling_usage": "A discharge pipe with a valve and spout at which water may\n   be drawn from the mains of waterworks; a water plug."
            }
        ]
    },
    {
        "id": 39817,
        "spelling_name": "Hydrophobia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63516,
                "word_type": "n.",
                "spelling_usage": "An abnormal dread of water, said to be a symptom of\n   canine madness; hence:"
            },
            {
                "spelling_detail_id": 63517,
                "word_type": "n.",
                "spelling_usage": "The disease caused by a bite form, or inoculation with\n   the saliva of, a rabid creature, of which the chief symptoms are, a\n   sense of dryness and construction in the throat, causing difficulty in\n   deglutition, and a marked heightening of reflex excitability, producing\n   convulsions whenever the patient attempts to swallow, or is disturbed\n   in any way, as by the sight or sound of water; rabies; canine madness."
            }
        ]
    },
    {
        "id": 40108,
        "spelling_name": "Hypochondria",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63852,
                "word_type": "n.",
                "spelling_usage": "Hypochondriasis; melancholy; the blues."
            },
            {
                "spelling_detail_id": 63860,
                "word_type": "pl. ",
                "spelling_usage": "of Hypochondrium"
            }
        ]
    },
    {
        "id": 40146,
        "spelling_name": "Hypogeous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63895,
                "word_type": "a.",
                "spelling_usage": "Growing under ground; remaining under ground; ripening\n   its fruit under ground."
            }
        ]
    },
    {
        "id": 40196,
        "spelling_name": "Hypotenuse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63957,
                "word_type": "n.",
                "spelling_usage": "Alt. of Hypothenuse"
            }
        ]
    },
    {
        "id": 40229,
        "spelling_name": "Hyrax",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 63995,
                "word_type": "n.",
                "spelling_usage": "Any animal of the genus Hyrax, of which about four species\n   are known. They constitute the order Hyracoidea. The best known species\n   are the daman (H. Syriacus) of Palestine, and the klipdas (H. capensis)\n   of South Africa. Other species are H. arboreus and H. Sylvestris, the\n   former from Southern, and the latter from Western, Africa. See Daman."
            }
        ]
    },
    {
        "id": 40264,
        "spelling_name": "Iambic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64029,
                "word_type": "a.",
                "spelling_usage": "Consisting of a short syllable followed by a long one, or\n   of an unaccented syllable followed by an accented; as, an iambic foot."
            },
            {
                "spelling_detail_id": 64030,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or composed of, iambics; as, an iambic\n   verse; iambic meter. See Lambus."
            },
            {
                "spelling_detail_id": 64031,
                "word_type": "n.",
                "spelling_usage": "An iambic foot; an iambus."
            },
            {
                "spelling_detail_id": 64032,
                "word_type": "n.",
                "spelling_usage": "A verse composed of iambic feet."
            },
            {
                "spelling_detail_id": 64033,
                "word_type": "n.",
                "spelling_usage": "A satirical poem (such poems having been anciently written\n   in iambic verse); a satire; a lampoon."
            }
        ]
    },
    {
        "id": 40281,
        "spelling_name": "Iberian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64051,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Iberia."
            }
        ]
    },
    {
        "id": 40282,
        "spelling_name": "Ibex",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64054,
                "word_type": "n.",
                "spelling_usage": "One of several species of wild goats having very large,\n   recurved horns, transversely ridged in front; -- called also steinbok."
            }
        ]
    },
    {
        "id": 40483,
        "spelling_name": "Idiosyncratic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64315,
                "word_type": "a.",
                "spelling_usage": "Alt. of Idiosyncratical"
            }
        ]
    },
    {
        "id": 40536,
        "spelling_name": "Idyllic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64392,
                "word_type": "a.",
                "spelling_usage": "Of or belonging to idyls."
            }
        ]
    },
    {
        "id": 40569,
        "spelling_name": "Ignominious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64435,
                "word_type": "a.",
                "spelling_usage": "Marked with ignominy; in curring public disgrace;\n   dishonorable; shameful."
            },
            {
                "spelling_detail_id": 64436,
                "word_type": "a.",
                "spelling_usage": "Deserving ignominy; despicable."
            },
            {
                "spelling_detail_id": 64437,
                "word_type": "a.",
                "spelling_usage": "Humiliating; degrading; as, an ignominious judgment or\n   sentence."
            }
        ]
    },
    {
        "id": 40982,
        "spelling_name": "Imminent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 64994,
                "word_type": "a.",
                "spelling_usage": "Threatening to occur immediately; near at hand;\n   impending; -- said especially of misfortune or peril."
            },
            {
                "spelling_detail_id": 64995,
                "word_type": "a.",
                "spelling_usage": "Full of danger; threatening; menacing; perilous."
            },
            {
                "spelling_detail_id": 64996,
                "word_type": "a.",
                "spelling_usage": "(With upon) Bent upon; attentive to."
            }
        ]
    },
    {
        "id": 41011,
        "spelling_name": "Immolate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65028,
                "word_type": "v. t.",
                "spelling_usage": "To sacrifice; to offer in sacrifice; to kill, as a\n   sacrificial victim."
            }
        ]
    },
    {
        "id": 41154,
        "spelling_name": "Impeachable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65232,
                "word_type": "a.",
                "spelling_usage": "That may be impeached; liable to impeachment;\n   chargeable with a crime."
            }
        ]
    },
    {
        "id": 41172,
        "spelling_name": "Impediment",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65253,
                "word_type": "n.",
                "spelling_usage": "That which impedes or hinders progress, motion,\n   activity, or effect."
            },
            {
                "spelling_detail_id": 65254,
                "word_type": "v. t.",
                "spelling_usage": "To impede."
            }
        ]
    },
    {
        "id": 41260,
        "spelling_name": "Impermeable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65371,
                "word_type": "a.",
                "spelling_usage": "Not permeable; not permitting passage, as of a fluid.\n   through its substance; impervious; impenetrable; as, India rubber is\n   impermeable to water and to air."
            }
        ]
    },
    {
        "id": 41304,
        "spelling_name": "Impetus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65427,
                "word_type": "n.",
                "spelling_usage": "A property possessed by a moving body in virtue of its\n   weight and its motion; the force with which any body is driven or\n   impelled; momentum."
            },
            {
                "spelling_detail_id": 65428,
                "word_type": "n.",
                "spelling_usage": "Fig.: Impulse; incentive; vigor; force."
            },
            {
                "spelling_detail_id": 65429,
                "word_type": "n.",
                "spelling_usage": "The aititude through which a heavy body must fall to\n   acquire a velocity equal to that with which a ball is discharged from a\n   piece."
            }
        ]
    },
    {
        "id": 41326,
        "spelling_name": "Implacable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65455,
                "word_type": "a.",
                "spelling_usage": "Not placable; not to be appeased; incapable of being\n   pacified; inexorable; as, an implacable prince."
            },
            {
                "spelling_detail_id": 65456,
                "word_type": "a.",
                "spelling_usage": "Incapable of ebign relieved or assuaged;\n   inextinguishable."
            }
        ]
    },
    {
        "id": 41371,
        "spelling_name": "Implore",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65514,
                "word_type": "v. t.",
                "spelling_usage": "To call upon, or for, in supplication; to beseech; to\n   prey to, or for, earnestly; to petition with urency; to entreat; to\n   beg; -- followed directly by the word expressing the thing sought, or\n   the person from whom it is sought."
            },
            {
                "spelling_detail_id": 65515,
                "word_type": "v. i.",
                "spelling_usage": "To entreat; to beg; to prey."
            },
            {
                "spelling_detail_id": 65516,
                "word_type": "n.",
                "spelling_usage": "Imploration."
            }
        ]
    },
    {
        "id": 41416,
        "spelling_name": "Importunate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65582,
                "word_type": "a.",
                "spelling_usage": "Troublesomely urgent; unreasonably solicitous;\n   overpressing in request or demand; urgent; teasing; as, an impotunate\n   petitioner, curiosity."
            },
            {
                "spelling_detail_id": 65583,
                "word_type": "a.",
                "spelling_usage": "Hard to be borne; unendurable."
            }
        ]
    },
    {
        "id": 41433,
        "spelling_name": "Imposing",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65600,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Impose"
            },
            {
                "spelling_detail_id": 65609,
                "word_type": "a.",
                "spelling_usage": "Laying as a duty; enjoining."
            },
            {
                "spelling_detail_id": 65610,
                "word_type": "a.",
                "spelling_usage": "Adapted to impress forcibly; impressive; commanding; as,\n   an imposing air; an imposing spectacle."
            },
            {
                "spelling_detail_id": 65611,
                "word_type": "a.",
                "spelling_usage": "Deceiving; deluding; misleading."
            },
            {
                "spelling_detail_id": 65612,
                "word_type": "n.",
                "spelling_usage": "The act of imposing the columns of a page, or the pages\n   of a sheet. See Impose, v. t., 4."
            }
        ]
    },
    {
        "id": 41497,
        "spelling_name": "Impresario",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 65713,
                "word_type": "n.",
                "spelling_usage": "The projector, manager, or conductor, of an opera or\n   concert company."
            }
        ]
    },
    {
        "id": 41935,
        "spelling_name": "Incinerate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 66323,
                "word_type": "",
                "spelling_usage": "Reduced to ashes by burning; thoroughly consumed."
            },
            {
                "spelling_detail_id": 66326,
                "word_type": "v. t.",
                "spelling_usage": "To burn to ashes; to consume; to burn."
            }
        ]
    },
    {
        "id": 41982,
        "spelling_name": "Inclement",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 66384,
                "word_type": "a.",
                "spelling_usage": "Not clement; destitute of a mild and kind temper; void\n   of tenderness; unmerciful; severe; harsh."
            },
            {
                "spelling_detail_id": 66385,
                "word_type": "a.",
                "spelling_usage": "Physically severe or harsh (generally restricted to the\n   elements or weather); rough; boisterous; stormy; rigorously cold, etc.;\n   as, inclement weather."
            }
        ]
    },
    {
        "id": 42006,
        "spelling_name": "Inclusion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 66436,
                "word_type": "n.",
                "spelling_usage": "The act of including, or the state of being included;\n   limitation; restriction; as, the lines of inclusion of his policy."
            },
            {
                "spelling_detail_id": 66437,
                "word_type": "n.",
                "spelling_usage": "A foreign substance, either liquid or solid, usually of\n   minute size, inclosed in the mass of a mineral."
            }
        ]
    },
    {
        "id": 42035,
        "spelling_name": "Incoherent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 66474,
                "word_type": "a.",
                "spelling_usage": "Not coherent; wanting cohesion; loose; unconnected;\n   physically disconnected; not fixed to each; -- said of material\n   substances."
            },
            {
                "spelling_detail_id": 66475,
                "word_type": "a.",
                "spelling_usage": "Wanting coherence or agreement; incongruous;\n   inconsistent; having no dependence of one part on another; logically\n   disconnected."
            }
        ]
    },
    {
        "id": 42398,
        "spelling_name": "Indecipherable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 66950,
                "word_type": "a.",
                "spelling_usage": "Not decipherable; incapable of being deciphered,\n   explained, or solved."
            }
        ]
    },
    {
        "id": 42411,
        "spelling_name": "Indefatigable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 66969,
                "word_type": "a.",
                "spelling_usage": "Incapable of being fatigued; not readily exhausted;\n   unremitting in labor or effort; untiring; unwearying; not yielding to\n   fatigue; as, indefatigable exertions, perseverance, application."
            }
        ]
    },
    {
        "id": 42448,
        "spelling_name": "Indemnity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67012,
                "word_type": "n.",
                "spelling_usage": "Security; insurance; exemption from loss or damage, past\n   or to come; immunity from penalty, or the punishment of past offenses;\n   amnesty."
            },
            {
                "spelling_detail_id": 67013,
                "word_type": "n.",
                "spelling_usage": "Indemnification, compensation, or remuneration for loss,\n   damage, or injury sustained."
            }
        ]
    },
    {
        "id": 42525,
        "spelling_name": "Indict",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67159,
                "word_type": "v. t.",
                "spelling_usage": "To write; to compose; to dictate; to indite."
            },
            {
                "spelling_detail_id": 67160,
                "word_type": "v. t.",
                "spelling_usage": "To appoint publicly or by authority; to proclaim or\n   announce."
            },
            {
                "spelling_detail_id": 67161,
                "word_type": "v. t.",
                "spelling_usage": "To charge with a crime, in due form of law, by the\n   finding or presentment of a grand jury; to find an indictment against;\n   as, to indict a man for arson. It is the peculiar province of a grand\n   jury to indict, as it is of a house of representatives to impeach."
            }
        ]
    },
    {
        "id": 42549,
        "spelling_name": "Indigent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67197,
                "word_type": "a.",
                "spelling_usage": "Wanting; void; free; destitute; -- used with of."
            },
            {
                "spelling_detail_id": 67198,
                "word_type": "a.",
                "spelling_usage": "Destitute of property or means of comfortable\n   subsistence; needy; poor; in want; necessitous."
            }
        ]
    },
    {
        "id": 42697,
        "spelling_name": "Indolent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67387,
                "word_type": "a.",
                "spelling_usage": "Free from toil, pain, or trouble."
            },
            {
                "spelling_detail_id": 67388,
                "word_type": "a.",
                "spelling_usage": "Indulging in ease; avoiding labor and exertion;\n   habitually idle; lazy; inactive; as, an indolent man."
            },
            {
                "spelling_detail_id": 67389,
                "word_type": "a.",
                "spelling_usage": "Causing little or no pain or annoyance; as, an indolent\n   tumor."
            }
        ]
    },
    {
        "id": 42733,
        "spelling_name": "Inducement",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67441,
                "word_type": "n.",
                "spelling_usage": "The act of inducing, or the state of being induced."
            },
            {
                "spelling_detail_id": 67442,
                "word_type": "n.",
                "spelling_usage": "That which induces; a motive or consideration that\n   leads one to action or induces one to act; as, reward is an inducement\n   to toil."
            },
            {
                "spelling_detail_id": 67443,
                "word_type": "n.",
                "spelling_usage": "Matter stated by way of explanatory preamble or\n   introduction to the main allegations of a pleading; a leading to."
            }
        ]
    },
    {
        "id": 42763,
        "spelling_name": "Indulgent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67493,
                "word_type": "a.",
                "spelling_usage": "Prone to indulge; yielding to the wishes, humor, or\n   appetites of those under one's care; compliant; not opposing or\n   restraining; tolerant; mild; favorable; not severe; as, an indulgent\n   parent."
            }
        ]
    },
    {
        "id": 42847,
        "spelling_name": "Ineluctable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67603,
                "word_type": "a.",
                "spelling_usage": "Not to be overcome by struggling; irresistible;\n   inevitable."
            }
        ]
    },
    {
        "id": 42851,
        "spelling_name": "Ineptitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67609,
                "word_type": "n.",
                "spelling_usage": "The quality of being inept; unfitness; inaptitude;\n   unsuitableness."
            },
            {
                "spelling_detail_id": 67610,
                "word_type": "n.",
                "spelling_usage": "Absurdity; nonsense; foolishness."
            }
        ]
    },
    {
        "id": 43014,
        "spelling_name": "Infarction",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67809,
                "word_type": "n.",
                "spelling_usage": "The act of stuffing or filling; an overloading and\n   obstruction of any organ or vessel of the body; constipation."
            }
        ]
    },
    {
        "id": 43021,
        "spelling_name": "Infatuation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67819,
                "word_type": "n.",
                "spelling_usage": "The act of infatuating; the state of being infatuated;\n   folly; that which infatuates."
            }
        ]
    },
    {
        "id": 43123,
        "spelling_name": "Inflammable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 67989,
                "word_type": "a.",
                "spelling_usage": "Capable of being easily set fire; easily enkindled;\n   combustible; as, inflammable oils or spirits."
            },
            {
                "spelling_detail_id": 67990,
                "word_type": "a.",
                "spelling_usage": "Excitable; irritable; irascible; easily provoked; as,\n   an inflammable temper."
            }
        ]
    },
    {
        "id": 43315,
        "spelling_name": "Ingenuous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68276,
                "word_type": "a.",
                "spelling_usage": "Of honorable extraction; freeborn; noble; as, ingenuous\n   blood of birth."
            },
            {
                "spelling_detail_id": 68277,
                "word_type": "a.",
                "spelling_usage": "Noble; generous; magnanimous; honorable; upright;\n   high-minded; as, an ingenuous ardor or zeal."
            },
            {
                "spelling_detail_id": 68278,
                "word_type": "a.",
                "spelling_usage": "Free from reserve, disguise, equivocation, or\n   dissimulation; open; frank; as, an ingenuous man; an ingenuous\n   declaration, confession, etc."
            },
            {
                "spelling_detail_id": 68279,
                "word_type": "a.",
                "spelling_usage": "Ingenious."
            }
        ]
    },
    {
        "id": 43328,
        "spelling_name": "Inglorious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68296,
                "word_type": "a.",
                "spelling_usage": "Not glorious; not bringing honor or glory; not\n   accompanied with fame, honor, or celebrity; obscure; humble; as, an\n   inglorious life of ease."
            },
            {
                "spelling_detail_id": 68297,
                "word_type": "a.",
                "spelling_usage": "Shameful; disgraceful; ignominious; as, inglorious\n   flight, defeat, etc."
            }
        ]
    },
    {
        "id": 43336,
        "spelling_name": "Ingot",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68307,
                "word_type": "n.",
                "spelling_usage": "That in which metal is cast; a mold."
            },
            {
                "spelling_detail_id": 68308,
                "word_type": "n.",
                "spelling_usage": "A bar or wedge of steel, gold, or other malleable metal,\n   cast in a mold; a mass of unwrought cast metal."
            }
        ]
    },
    {
        "id": 43461,
        "spelling_name": "Inimical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68483,
                "word_type": "a.",
                "spelling_usage": "Having the disposition or temper of an enemy; unfriendly;\n   unfavorable; -- chiefly applied to private, as hostile is to public,\n   enmity."
            },
            {
                "spelling_detail_id": 68484,
                "word_type": "a.",
                "spelling_usage": "Opposed in tendency, influence, or effects; antagonistic;\n   inconsistent; incompatible; adverse; repugnant."
            }
        ]
    },
    {
        "id": 43511,
        "spelling_name": "Injurious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68562,
                "word_type": "a.",
                "spelling_usage": "Not just; wrongful; iniquitous; culpable."
            },
            {
                "spelling_detail_id": 68563,
                "word_type": "a.",
                "spelling_usage": "Causing injury or harm; hurtful; harmful; detrimental;\n   mischievous; as, acts injurious to health, credit, reputation,\n   property, etc."
            }
        ]
    },
    {
        "id": 43605,
        "spelling_name": "Innovator",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68705,
                "word_type": "n.",
                "spelling_usage": "One who innovates."
            }
        ]
    },
    {
        "id": 43632,
        "spelling_name": "Inoculate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68736,
                "word_type": "v. t.",
                "spelling_usage": "To bud; to insert, or graft, as the bud of a tree or\n   plant in another tree or plant."
            },
            {
                "spelling_detail_id": 68737,
                "word_type": "v. t.",
                "spelling_usage": "To insert a foreign bud into; as, to inoculate a\n   tree."
            },
            {
                "spelling_detail_id": 68738,
                "word_type": "v. t.",
                "spelling_usage": "To communicate a disease to ( a person ) by inserting\n   infectious matter in the skin or flesh; as, to inoculate a person with\n   the virus of smallpox,rabies, etc. See Vaccinate."
            },
            {
                "spelling_detail_id": 68739,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To introduce into the mind; -- used especially\n   of harmful ideas or principles; to imbue; as, to inoculate one with\n   treason or infidelity."
            },
            {
                "spelling_detail_id": 68740,
                "word_type": "v. i.",
                "spelling_usage": "To graft by inserting buds."
            },
            {
                "spelling_detail_id": 68741,
                "word_type": "v. i.",
                "spelling_usage": "To communicate disease by inoculation."
            }
        ]
    },
    {
        "id": 43680,
        "spelling_name": "Inquietude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68800,
                "word_type": "n.",
                "spelling_usage": "Disturbed state; uneasiness either of body or mind;\n   restlessness; disquietude."
            }
        ]
    },
    {
        "id": 43701,
        "spelling_name": "Inquisitor",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 68832,
                "word_type": "n.",
                "spelling_usage": "An inquisitive person; one fond of asking questions."
            },
            {
                "spelling_detail_id": 68833,
                "word_type": "n.",
                "spelling_usage": "One whose official duty it is to examine and inquire,\n   as coroners, sheriffs, etc."
            },
            {
                "spelling_detail_id": 68834,
                "word_type": "n.",
                "spelling_usage": "A member of the Court of Inquisition."
            }
        ]
    },
    {
        "id": 43840,
        "spelling_name": "Insignia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69025,
                "word_type": "n. pl.",
                "spelling_usage": "Distinguishing marks of authority, office, or honor;\n   badges; tokens; decorations; as, the insignia of royalty or of an\n   order."
            },
            {
                "spelling_detail_id": 69026,
                "word_type": "n. pl.",
                "spelling_usage": "Typical and characteristic marks or signs, by which\n   anything is known or distinguished; as, the insignia of a trade."
            }
        ]
    },
    {
        "id": 43953,
        "spelling_name": "Installation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69199,
                "word_type": "n.",
                "spelling_usage": "The act of installing or giving possession of an\n   office, rank, or order, with the usual rites or ceremonies; as, the\n   installation of an ordained minister in a parish."
            },
            {
                "spelling_detail_id": 69200,
                "word_type": "n.",
                "spelling_usage": "The whole of a system of machines, apparatus, and\n   accessories, when set up and arranged for practical working, as in\n   electric lighting, transmission of power, etc."
            }
        ]
    },
    {
        "id": 44028,
        "spelling_name": "Instrument",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69326,
                "word_type": "n.",
                "spelling_usage": "That by means of which any work is performed, or result\n   is effected; a tool; a utensil; an implement; as, the instruments of a\n   mechanic; astronomical instruments."
            },
            {
                "spelling_detail_id": 69327,
                "word_type": "n.",
                "spelling_usage": "A contrivance or implement, by which musical sounds are\n   produced; as, a musical instrument."
            },
            {
                "spelling_detail_id": 69328,
                "word_type": "n.",
                "spelling_usage": "A writing, as the means of giving formal expression to\n   some act; a writing expressive of some act, contract, process, as a\n   deed, contract, writ, etc."
            },
            {
                "spelling_detail_id": 69329,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, is made a means, or is caused\n   to serve a purpose; a medium, means, or agent."
            },
            {
                "spelling_detail_id": 69330,
                "word_type": "v. t.",
                "spelling_usage": "To perform upon an instrument; to prepare for an\n   instrument; as, a sonata instrumented for orchestra."
            }
        ]
    },
    {
        "id": 44210,
        "spelling_name": "Intensify",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69601,
                "word_type": "v. t.",
                "spelling_usage": "To render more intense; as, to intensify heat or\n   cold; to intensify colors; to intensify a photographic negative; to\n   intensify animosity."
            },
            {
                "spelling_detail_id": 69602,
                "word_type": "v. i.",
                "spelling_usage": "To become intense, or more intense; to act with\n   increasing power or energy."
            }
        ]
    },
    {
        "id": 44408,
        "spelling_name": "Interim",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69872,
                "word_type": "n.",
                "spelling_usage": "The meantime; time intervening; interval between events,\n   etc."
            },
            {
                "spelling_detail_id": 69873,
                "word_type": "n.",
                "spelling_usage": "A name given to each of three compromises made by the\n   emperor Charles V. of Germany for the sake of harmonizing the\n   connecting opinions of Protestants and Catholics."
            }
        ]
    },
    {
        "id": 44467,
        "spelling_name": "Interlocutor",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69949,
                "word_type": "n.",
                "spelling_usage": "One who takes part in dialogue or conversation; a\n   talker, interpreter, or questioner."
            },
            {
                "spelling_detail_id": 69950,
                "word_type": "n.",
                "spelling_usage": "An interlocutory judgment or sentence."
            }
        ]
    },
    {
        "id": 44518,
        "spelling_name": "Intermezzo",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70012,
                "word_type": "n.",
                "spelling_usage": "An interlude; an intermede. See Intermede."
            }
        ]
    },
    {
        "id": 44647,
        "spelling_name": "Interred",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 69636,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Inter"
            }
        ]
    },
    {
        "id": 44651,
        "spelling_name": "Interregnum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70202,
                "word_type": "n.",
                "spelling_usage": "The time during which a throne is vacant between the\n   death or abdication of a sovereign and the accession of his successor."
            },
            {
                "spelling_detail_id": 70203,
                "word_type": "n.",
                "spelling_usage": "Any period during which, for any cause, the executive\n   branch of a government is suspended or interrupted."
            }
        ]
    },
    {
        "id": 44666,
        "spelling_name": "Interrogative",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70222,
                "word_type": "a.",
                "spelling_usage": "Denoting a question; expressed in the form of a\n   question; as, an interrogative sentence; an interrogative pronoun."
            },
            {
                "spelling_detail_id": 70223,
                "word_type": "n.",
                "spelling_usage": "A word used in asking questions; as, who? which?\n   why?"
            }
        ]
    },
    {
        "id": 44707,
        "spelling_name": "Intersperse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70278,
                "word_type": "v. t.",
                "spelling_usage": "To scatter or set here and there among other\n   things; to insert at intervals; as, to intersperse pictures in a book."
            },
            {
                "spelling_detail_id": 70279,
                "word_type": "v. t.",
                "spelling_usage": "To diversify or adorn with things set or scattered\n   at intervals; to place something at intervals in or among; as, to\n   intersperse a book with pictures."
            }
        ]
    },
    {
        "id": 44917,
        "spelling_name": "Intricate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70555,
                "word_type": "a.",
                "spelling_usage": "Entangled; involved; perplexed; complicated; difficult\n   to understand, follow, arrange, or adjust; as, intricate machinery,\n   labyrinths, accounts, plots, etc."
            },
            {
                "spelling_detail_id": 70556,
                "word_type": "v. t.",
                "spelling_usage": "To entangle; to involve; to make perplexing."
            }
        ]
    },
    {
        "id": 45020,
        "spelling_name": "Inundate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70706,
                "word_type": "v. t.",
                "spelling_usage": "To cover with a flood; to overflow; to deluge; to\n   flood; as, the river inundated the town."
            },
            {
                "spelling_detail_id": 70707,
                "word_type": "v. t.",
                "spelling_usage": "To fill with an overflowing abundance or superfluity;\n   as, the country was inundated with bills of credit."
            }
        ]
    },
    {
        "id": 45072,
        "spelling_name": "Invective",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70776,
                "word_type": "a.",
                "spelling_usage": "Characterized by invection; critical; denunciatory;\n   satirical; abusive; railing."
            },
            {
                "spelling_detail_id": 70777,
                "word_type": "n.",
                "spelling_usage": "An expression which inveighs or rails against a person;\n   a severe or violent censure or reproach; something uttered or written,\n   intended to cast opprobrium, censure, or reproach on another; a harsh\n   or reproachful accusation; -- followed by against, having reference to\n   the person or thing affected; as an invective against tyranny."
            }
        ]
    },
    {
        "id": 45112,
        "spelling_name": "Invertebrate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70848,
                "word_type": "a.",
                "spelling_usage": "Destitute of a backbone; having no vertebrae; of or\n   pertaining to the Invertebrata."
            },
            {
                "spelling_detail_id": 70849,
                "word_type": "n.",
                "spelling_usage": "One of the Invertebrata."
            }
        ]
    },
    {
        "id": 45154,
        "spelling_name": "Inviolable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 70915,
                "word_type": "a.",
                "spelling_usage": "Not violable; not susceptible of hurt, wound, or harm\n   (used with respect to either physical or moral damage); not susceptible\n   of being profaned or corrupted; sacred; holy; as, inviolable honor or\n   chastity; an inviolable shrine."
            },
            {
                "spelling_detail_id": 70916,
                "word_type": "a.",
                "spelling_usage": "Unviolated; uninjured; undefiled; uncorrupted."
            },
            {
                "spelling_detail_id": 70917,
                "word_type": "a.",
                "spelling_usage": "Not capable of being broken or violated; as, an\n   inviolable covenant, agreement, promise, or vow."
            }
        ]
    },
    {
        "id": 45231,
        "spelling_name": "Inwardly",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71042,
                "word_type": "adv.",
                "spelling_usage": "In the inner parts; internally."
            },
            {
                "spelling_detail_id": 71043,
                "word_type": "adv.",
                "spelling_usage": "Toward the center; inward; as, to curve inwardly."
            },
            {
                "spelling_detail_id": 71044,
                "word_type": "adv.",
                "spelling_usage": "In the heart or mind; mentally; privately; secret/y;\n   as, he inwardly repines."
            },
            {
                "spelling_detail_id": 71045,
                "word_type": "adv.",
                "spelling_usage": "Intimately; thoroughly."
            }
        ]
    },
    {
        "id": 45303,
        "spelling_name": "Iridescent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71132,
                "word_type": "a.",
                "spelling_usage": "Having colors like the rainbow; exhibiting a play of\n   changeable colors; nacreous; prismatic; as, iridescent glass."
            }
        ]
    },
    {
        "id": 45499,
        "spelling_name": "Irrevocable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71384,
                "word_type": "a.",
                "spelling_usage": "Incapable of being recalled or revoked; unchangeable;\n   irreversible; unalterable; as, an irrevocable promise or decree;\n   irrevocable fate."
            }
        ]
    },
    {
        "id": 45506,
        "spelling_name": "Irrigation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71392,
                "word_type": "n.",
                "spelling_usage": "The act or process of irrigating, or the state of being\n   irrigated; especially, the operation of causing water to flow over\n   lands, for nourishing plants."
            }
        ]
    },
    {
        "id": 45510,
        "spelling_name": "Irritability",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71397,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being irritable; quick\n   excitability; petulance; fretfulness; as, irritability of temper."
            },
            {
                "spelling_detail_id": 71398,
                "word_type": "n.",
                "spelling_usage": "A natural susceptibility, characteristic of all\n   living organisms, tissues, and cells, to the influence of certain\n   stimuli, response being manifested in a variety of ways, -- as that\n   quality in plants by which they exhibit motion under suitable\n   stimulation; esp., the property which living muscle processes, of\n   responding either to a direct stimulus of its substance, or to the\n   stimulating influence of its nerve fibers, the response being indicated\n   by a change of form, or contraction; contractility."
            },
            {
                "spelling_detail_id": 71399,
                "word_type": "n.",
                "spelling_usage": "A condition of morbid excitability of an organ or\n   part of the body; undue susceptibility to the influence of stimuli. See\n   Irritation, n., 3."
            }
        ]
    },
    {
        "id": 45681,
        "spelling_name": "Isosceles",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71623,
                "word_type": "a.",
                "spelling_usage": "Having two legs or sides that are equal; -- said of a\n   triangle."
            }
        ]
    },
    {
        "id": 45879,
        "spelling_name": "Jadeite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 71947,
                "word_type": "n.",
                "spelling_usage": "See Jade, the stone."
            }
        ]
    },
    {
        "id": 46003,
        "spelling_name": "Jasper",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72126,
                "word_type": "n.",
                "spelling_usage": "An opaque, impure variety of quartz, of red, yellow, and\n   other dull colors, breaking with a smooth surface. It admits of a high\n   polish, and is used for vases, seals, snuff boxes, etc. When the colors\n   are in stripes or bands, it is called striped / banded jasper. The\n   Egyptian pebble is a brownish yellow jasper."
            }
        ]
    },
    {
        "id": 46025,
        "spelling_name": "Javelin",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72156,
                "word_type": "n.",
                "spelling_usage": "A sort of light spear, to be thrown or cast by thew hand;\n   anciently, a weapon of war used by horsemen and foot soldiers; now used\n   chiefly in hunting the wild boar and other fierce game."
            },
            {
                "spelling_detail_id": 72157,
                "word_type": "v. t.",
                "spelling_usage": "To pierce with a javelin."
            }
        ]
    },
    {
        "id": 46218,
        "spelling_name": "Jingoism",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72468,
                "word_type": "n.",
                "spelling_usage": "The policy of the Jingoes, so called. See Jingo, 2."
            }
        ]
    },
    {
        "id": 46244,
        "spelling_name": "Jocularity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72520,
                "word_type": "n.",
                "spelling_usage": "Jesting; merriment."
            }
        ]
    },
    {
        "id": 46328,
        "spelling_name": "Jonquil",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72659,
                "word_type": "n.",
                "spelling_usage": "Alt. of Jonquille"
            }
        ]
    },
    {
        "id": 46351,
        "spelling_name": "Joule",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72687,
                "word_type": "n.",
                "spelling_usage": "A unit of work which is equal to 107 units of work in the C.\n   G. S. system of units (ergs), and is practically equivalent to the\n   energy expended in one second by an electric current of one ampere in a\n   resistance of one ohm. One joule is approximately equal to 0.738 foot\n   pounds."
            }
        ]
    },
    {
        "id": 46436,
        "spelling_name": "Judicious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 72837,
                "word_type": "a.",
                "spelling_usage": "Of or relating to a court; judicial."
            },
            {
                "spelling_detail_id": 72838,
                "word_type": "a.",
                "spelling_usage": "Directed or governed by sound judgment; having sound\n   judgment; wise; prudent; sagacious; discreet."
            }
        ]
    },
    {
        "id": 46543,
        "spelling_name": "Jurassic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 73021,
                "word_type": "a.",
                "spelling_usage": "Of the age of the middle Mesozoic, including, as divided\n   in England and Europe, the Lias, Oolite, and Wealden; -- named from\n   certain rocks of the Jura mountains."
            },
            {
                "spelling_detail_id": 73022,
                "word_type": "n.",
                "spelling_usage": "The Jurassic period or formation; -- called also the\n   Jura."
            }
        ]
    },
    {
        "id": 46576,
        "spelling_name": "Justiciable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 73075,
                "word_type": "a.",
                "spelling_usage": "Proper to be examined in a court of justice."
            }
        ]
    },
    {
        "id": 46614,
        "spelling_name": "Juxtapose",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 73132,
                "word_type": "v. t.",
                "spelling_usage": "To place in juxtaposition."
            }
        ]
    },
    {
        "id": 46660,
        "spelling_name": "Kaleidoscope",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 73183,
                "word_type": "n.",
                "spelling_usage": "An instrument invented by Sir David Brewster, which\n   contains loose fragments of colored glass, etc., and reflecting\n   surfaces so arranged that changes of position exhibit its contents in\n   an endless variety of beautiful colors and symmetrical forms. It has\n   been much employed in arts of design."
            }
        ]
    },
    {
        "id": 46852,
        "spelling_name": "Keratitis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 73466,
                "word_type": "n.",
                "spelling_usage": "Inflammation of the cornea."
            }
        ]
    },
    {
        "id": 46866,
        "spelling_name": "Kerchief",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 73482,
                "word_type": "n.",
                "spelling_usage": "A square of fine linen worn by women as a covering for\n   the head; hence, anything similar in form or material, worn for\n   ornament on other parts of the person; -- mostly used in compounds; as,\n   neckerchief; breastkerchief; and later, handkerchief."
            },
            {
                "spelling_detail_id": 73483,
                "word_type": "n.",
                "spelling_usage": "A lady who wears a kerchief."
            }
        ]
    },
    {
        "id": 47406,
        "spelling_name": "Kraken",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74324,
                "word_type": "n.",
                "spelling_usage": "A fabulous Scandinavian sea monster, often represented as\n   resembling an island, but sometimes as resembling an immense octopus."
            }
        ]
    },
    {
        "id": 47539,
        "spelling_name": "Laboratory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74516,
                "word_type": "n.",
                "spelling_usage": "The workroom of a chemist; also, a place devoted to\n   experiments in any branch of natural science; as, a chemical, physical,\n   or biological laboratory. Hence, by extension, a place where something\n   is prepared, or some operation is performed; as, the liver is the\n   laboratory of the bile."
            }
        ]
    },
    {
        "id": 47569,
        "spelling_name": "Labyrinthine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74559,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or like, a labyrinth; labyrinthal."
            }
        ]
    },
    {
        "id": 47591,
        "spelling_name": "Laceration",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74596,
                "word_type": "n.",
                "spelling_usage": "The act of lacerating."
            },
            {
                "spelling_detail_id": 74597,
                "word_type": "n.",
                "spelling_usage": "A breach or wound made by lacerating."
            }
        ]
    },
    {
        "id": 47624,
        "spelling_name": "Lackadaisical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74653,
                "word_type": "a.",
                "spelling_usage": "Affectedly pensive; languidly sentimental."
            }
        ]
    },
    {
        "id": 47639,
        "spelling_name": "Laconic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74672,
                "word_type": "a.",
                "spelling_usage": "Alt. of Laconical"
            },
            {
                "spelling_detail_id": 74675,
                "word_type": "n.",
                "spelling_usage": "Laconism."
            }
        ]
    },
    {
        "id": 47817,
        "spelling_name": "Laity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 74920,
                "word_type": "a.",
                "spelling_usage": "The people, as distinguished from the clergy; the body of\n   the people not in orders."
            },
            {
                "spelling_detail_id": 74921,
                "word_type": "a.",
                "spelling_usage": "The state of a layman."
            },
            {
                "spelling_detail_id": 74922,
                "word_type": "a.",
                "spelling_usage": "Those who are not of a certain profession, as law or\n   medicine, in distinction from those belonging to it."
            }
        ]
    },
    {
        "id": 47963,
        "spelling_name": "Lanceolate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75119,
                "word_type": "a.",
                "spelling_usage": "Alt. of Lanceolated"
            }
        ]
    },
    {
        "id": 48047,
        "spelling_name": "Languish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75255,
                "word_type": "v. i.",
                "spelling_usage": "To become languid or weak; to lose strength or\n   animation; to be or become dull, feeble or spiritless; to pine away; to\n   wither or fade."
            },
            {
                "spelling_detail_id": 75256,
                "word_type": "v. i.",
                "spelling_usage": "To assume an expression of weariness or tender grief,\n   appealing for sympathy."
            },
            {
                "spelling_detail_id": 75257,
                "word_type": "v. i.",
                "spelling_usage": "To cause to droop or pine."
            },
            {
                "spelling_detail_id": 75258,
                "word_type": "n.",
                "spelling_usage": "See Languishiment."
            }
        ]
    },
    {
        "id": 48055,
        "spelling_name": "Languorous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75269,
                "word_type": "a.",
                "spelling_usage": "Producing, or tending to produce, languor;\n   characterized by languor."
            }
        ]
    },
    {
        "id": 48106,
        "spelling_name": "Lapel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75360,
                "word_type": "n.",
                "spelling_usage": "That part of a garment which is turned back; specifically,\n   the lap, or fold, of the front of a coat in continuation of collar."
            }
        ]
    },
    {
        "id": 48295,
        "spelling_name": "Latency",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75654,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being latent."
            }
        ]
    },
    {
        "id": 48399,
        "spelling_name": "Laudatory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75808,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining praise, or to the expression of praise;\n   as, laudatory verses; the laudatory powers of Dryden."
            }
        ]
    },
    {
        "id": 48411,
        "spelling_name": "Laughter",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75824,
                "word_type": "v. i.",
                "spelling_usage": "A movement (usually involuntary) of the muscles of the\n   face, particularly of the lips, with a peculiar expression of the eyes,\n   indicating merriment, satisfaction, or derision, and usually attended\n   by a sonorous and interrupted expulsion of air from the lungs. See\n   Laugh, v. i."
            }
        ]
    },
    {
        "id": 48417,
        "spelling_name": "Launch",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75834,
                "word_type": "v. i.",
                "spelling_usage": "To throw, as a lance or dart; to hurl; to let fly."
            },
            {
                "spelling_detail_id": 75835,
                "word_type": "v. i.",
                "spelling_usage": "To strike with, or as with, a lance; to pierce."
            },
            {
                "spelling_detail_id": 75836,
                "word_type": "v. i.",
                "spelling_usage": "To cause to move or slide from the land into the water;\n   to set afloat; as, to launch a ship."
            },
            {
                "spelling_detail_id": 75837,
                "word_type": "v. i.",
                "spelling_usage": "To send out; to start (one) on a career; to set going;\n   to give a start to (something); to put in operation; as, to launch a\n   son in the world; to launch a business project or enterprise."
            },
            {
                "spelling_detail_id": 75838,
                "word_type": "v. i.",
                "spelling_usage": "To move with force and swiftness like a sliding from the\n   stocks into the water; to plunge; to make a beginning; as, to launch\n   into the current of a stream; to launch into an argument or discussion;\n   to launch into lavish expenditures; -- often with out."
            },
            {
                "spelling_detail_id": 75839,
                "word_type": "n.",
                "spelling_usage": "The act of launching."
            },
            {
                "spelling_detail_id": 75840,
                "word_type": "n.",
                "spelling_usage": "The movement of a vessel from land into the water;\n   especially, the sliding on ways from the stocks on which it is built."
            },
            {
                "spelling_detail_id": 75841,
                "word_type": "n.",
                "spelling_usage": "The boat of the largest size belonging to a ship of war;\n   also, an open boat of any size driven by steam, naphtha, electricity,\n   or the like."
            }
        ]
    },
    {
        "id": 48433,
        "spelling_name": "Laureate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75861,
                "word_type": "a.",
                "spelling_usage": "Crowned, or decked, with laurel."
            },
            {
                "spelling_detail_id": 75862,
                "word_type": "n.",
                "spelling_usage": "One crowned with laurel; a poet laureate."
            },
            {
                "spelling_detail_id": 75865,
                "word_type": "v. i.",
                "spelling_usage": "To honor with a wreath of laurel, as formerly was done\n   in bestowing a degree at the English universities."
            }
        ]
    },
    {
        "id": 48465,
        "spelling_name": "Lavender",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 75904,
                "word_type": "n.",
                "spelling_usage": "An aromatic plant of the genus Lavandula (L. vera),\n   common in the south of Europe. It yields and oil used in medicine and\n   perfumery. The Spike lavender (L. Spica) yields a coarser oil (oil of\n   spike), used in the arts."
            },
            {
                "spelling_detail_id": 75905,
                "word_type": "n.",
                "spelling_usage": "The pale, purplish color of lavender flowers, paler and\n   more delicate than lilac."
            }
        ]
    },
    {
        "id": 48742,
        "spelling_name": "Legato",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76485,
                "word_type": "a.",
                "spelling_usage": "Connected; tied; -- a term used when successive tones are\n   to be produced in a closely connected, smoothly gliding manner. It is\n   often indicated by a tie, thus /, /, or /, /, written over or under the\n   notes to be so performed; -- opposed to staccato."
            }
        ]
    },
    {
        "id": 48753,
        "spelling_name": "Legerity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76506,
                "word_type": "n.",
                "spelling_usage": "Lightness; nimbleness."
            }
        ]
    },
    {
        "id": 48784,
        "spelling_name": "Legislature",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76544,
                "word_type": "n.",
                "spelling_usage": "The body of persons in a state or kingdom invested\n   with power to make and repeal laws; a legislative body."
            }
        ]
    },
    {
        "id": 48790,
        "spelling_name": "Legitimately",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76556,
                "word_type": "adv.",
                "spelling_usage": "In a legitimate manner; lawfully; genuinely."
            }
        ]
    },
    {
        "id": 48809,
        "spelling_name": "Leguminous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76578,
                "word_type": "a.",
                "spelling_usage": "Pertaining to pulse; consisting of pulse."
            },
            {
                "spelling_detail_id": 76579,
                "word_type": "a.",
                "spelling_usage": "Belonging to, or resembling, a very large natural order\n   of plants (Leguminosae), which bear legumes, including peas, beans,\n   clover, locust trees, acacias, and mimosas."
            }
        ]
    },
    {
        "id": 48819,
        "spelling_name": "Leisure",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76592,
                "word_type": "n.",
                "spelling_usage": "Freedom from occupation or business; vacant time; time\n   free from employment."
            },
            {
                "spelling_detail_id": 76593,
                "word_type": "n.",
                "spelling_usage": "Time at one's command, free from engagement; convenient\n   opportunity; hence, convenience; ease."
            },
            {
                "spelling_detail_id": 76594,
                "word_type": "a.",
                "spelling_usage": "Unemployed; as, leisure hours."
            }
        ]
    },
    {
        "id": 48901,
        "spelling_name": "Lento",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 76710,
                "word_type": "a. & adv.",
                "spelling_usage": "Slow; in slow time; slowly; -- rarely written lente."
            }
        ]
    },
    {
        "id": 49100,
        "spelling_name": "Leviathan",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 77025,
                "word_type": "n.",
                "spelling_usage": "An aquatic animal, described in the book of Job, ch.\n   xli., and mentioned in other passages of Scripture."
            },
            {
                "spelling_detail_id": 77026,
                "word_type": "n.",
                "spelling_usage": "The whale, or a great whale."
            }
        ]
    },
    {
        "id": 49253,
        "spelling_name": "Licensure",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 77264,
                "word_type": "n.",
                "spelling_usage": "A licensing."
            }
        ]
    },
    {
        "id": 49300,
        "spelling_name": "Liege",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 77356,
                "word_type": "a.",
                "spelling_usage": "Sovereign; independent; having authority or right to\n   allegiance; as, a liege lord."
            },
            {
                "spelling_detail_id": 77357,
                "word_type": "a.",
                "spelling_usage": "Serving an independent sovereign or master; bound by a\n   feudal tenure; obliged to be faithful and loyal to a superior, as a\n   vassal to his lord; faithful; loyal; as, a liege man; a liege subject."
            },
            {
                "spelling_detail_id": 77358,
                "word_type": "a.",
                "spelling_usage": "Full; perfect; complete; pure."
            },
            {
                "spelling_detail_id": 77359,
                "word_type": "n.",
                "spelling_usage": "A free and independent person; specif., a lord paramount; a\n   sovereign."
            },
            {
                "spelling_detail_id": 77360,
                "word_type": "n.",
                "spelling_usage": "The subject of a sovereign or lord; a liegeman."
            }
        ]
    },
    {
        "id": 49534,
        "spelling_name": "Limpid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 77845,
                "word_type": "a.",
                "spelling_usage": "Characterized by clearness or transparency; clear; as, a\n   limpid stream."
            }
        ]
    },
    {
        "id": 49605,
        "spelling_name": "Lingua Franca",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 77997,
                "word_type": "",
                "spelling_usage": "The commercial language of the Levant, -- a mixture of\n   the languages of the people of the region and of foreign traders."
            }
        ]
    },
    {
        "id": 49703,
        "spelling_name": "Liquefaction",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 78131,
                "word_type": "n.",
                "spelling_usage": "The act or operation of making or becoming liquid;\n   especially, the conversion of a solid into a liquid by the sole agency\n   of heat."
            },
            {
                "spelling_detail_id": 78132,
                "word_type": "n.",
                "spelling_usage": "The state of being liquid."
            },
            {
                "spelling_detail_id": 78133,
                "word_type": "n.",
                "spelling_usage": "The act, process, or method, of reducing a gas or\n   vapor to a liquid by means of cold or pressure; as, the liquefaction of\n   oxygen or hydrogen."
            }
        ]
    },
    {
        "id": 49894,
        "spelling_name": "Litmus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 78419,
                "word_type": "n.",
                "spelling_usage": "A dyestuff extracted from certain lichens (Roccella\n   tinctoria, Lecanora tartarea, etc.), as a blue amorphous mass which\n   consists of a compound of the alkaline carbonates with certain coloring\n   matters related to orcin and orcein."
            }
        ]
    },
    {
        "id": 50157,
        "spelling_name": "Logarithmic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 78895,
                "word_type": "a.",
                "spelling_usage": "Alt. of Logarithmical"
            }
        ]
    },
    {
        "id": 50187,
        "spelling_name": "Logographic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 78937,
                "word_type": "a.",
                "spelling_usage": "Alt. of Logographical"
            }
        ]
    },
    {
        "id": 50297,
        "spelling_name": "Longitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79089,
                "word_type": "n.",
                "spelling_usage": "Length; measure or distance along the longest line; --\n   distinguished from breadth or thickness; as, the longitude of a room;\n   rare now, except in a humorous sense."
            },
            {
                "spelling_detail_id": 79090,
                "word_type": "n.",
                "spelling_usage": "The arc or portion of the equator intersected between\n   the meridian of a given place and the meridian of some other place from\n   which longitude is reckoned, as from Greenwich, England, or sometimes\n   from the capital of a country, as from Washington or Paris. The\n   longitude of a place is expressed either in degrees or in time; as,\n   that of New York is 74¡ or 4 h. 56 min. west of Greenwich."
            },
            {
                "spelling_detail_id": 79091,
                "word_type": "n.",
                "spelling_usage": "The distance in degrees, reckoned from the vernal\n   equinox, on the ecliptic, to a circle at right angles to the ecliptic\n   passing through the heavenly body whose longitude is designated; as,\n   the longitude of Capella is 79¡."
            }
        ]
    },
    {
        "id": 50392,
        "spelling_name": "Loquacious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79287,
                "word_type": "a.",
                "spelling_usage": "Given to continual talking; talkative; garrulous."
            },
            {
                "spelling_detail_id": 79288,
                "word_type": "a.",
                "spelling_usage": "Speaking; expressive."
            },
            {
                "spelling_detail_id": 79289,
                "word_type": "a.",
                "spelling_usage": "Apt to blab and disclose secrets."
            }
        ]
    },
    {
        "id": 50429,
        "spelling_name": "Lorikeet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79355,
                "word_type": "n.",
                "spelling_usage": "Any one numerous species of small brush-tongued parrots\n   or lories, found mostly in Australia, New Guinea and the adjacent\n   islands, with some forms in the East Indies. They are arboreal in their\n   habits and feed largely upon the honey of flowers. They belong to\n   Trichoglossus, Loriculus, and several allied genera."
            }
        ]
    },
    {
        "id": 50580,
        "spelling_name": "Lozenge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79648,
                "word_type": "n.",
                "spelling_usage": "A diamond-shaped figure usually with the upper and lower\n   angles slightly acute, borne upon a shield or escutcheon. Cf. Fusil."
            },
            {
                "spelling_detail_id": 79649,
                "word_type": "n.",
                "spelling_usage": "A form of the escutcheon used by women instead of the\n   shield which is used by men."
            },
            {
                "spelling_detail_id": 79650,
                "word_type": "n.",
                "spelling_usage": "A figure with four equal sides, having two acute and two\n   obtuse angles; a rhomb."
            },
            {
                "spelling_detail_id": 79651,
                "word_type": "n.",
                "spelling_usage": "Anything in the form of lozenge."
            },
            {
                "spelling_detail_id": 79652,
                "word_type": "n.",
                "spelling_usage": "A small cake of sugar and starch, flavored, and often\n   medicated. -- originally in the form of a lozenge."
            }
        ]
    },
    {
        "id": 50666,
        "spelling_name": "Lugubrious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79782,
                "word_type": "a.",
                "spelling_usage": "Mournful; indicating sorrow, often ridiculously or\n   feignedly; doleful; woful; pitiable; as, a whining tone and a\n   lugubrious look."
            }
        ]
    },
    {
        "id": 50671,
        "spelling_name": "lullaby",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79792,
                "word_type": "v. t.",
                "spelling_usage": "A song to quiet babes or lull them to sleep; that which\n   quiets."
            },
            {
                "spelling_detail_id": 79793,
                "word_type": "v. t.",
                "spelling_usage": "Hence: Good night; good-by."
            }
        ]
    },
    {
        "id": 50759,
        "spelling_name": "Lupine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 79932,
                "word_type": "n.",
                "spelling_usage": "A leguminous plant of the genus Lupinus, especially L.\n   albus, the seeds of which have been used for food from ancient times.\n   The common species of the Eastern United States is L. perennis. There\n   are many species in California."
            },
            {
                "spelling_detail_id": 79933,
                "word_type": "n.",
                "spelling_usage": "Wolfish; ravenous."
            }
        ]
    },
    {
        "id": 50864,
        "spelling_name": "Luxuriate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80103,
                "word_type": "v. i.",
                "spelling_usage": "To grow exuberantly; to grow to superfluous\n   abundance."
            },
            {
                "spelling_detail_id": 80104,
                "word_type": "v. i.",
                "spelling_usage": "To feed or live luxuriously; as, the herds luxuriate\n   in the pastures."
            },
            {
                "spelling_detail_id": 80105,
                "word_type": "v. i.",
                "spelling_usage": "To indulge with unrestrained delight and freedom; as,\n   to luxuriate in description."
            }
        ]
    },
    {
        "id": 51018,
        "spelling_name": "Machete",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80310,
                "word_type": "n.",
                "spelling_usage": "A large heavy knife resembling a broadsword, often two or\n   three feet in length, -- used by the inhabitants of Spanish America as\n   a hatchet to cut their way through thickets, and for various other\n   purposes."
            }
        ]
    },
    {
        "id": 51029,
        "spelling_name": "Machination",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80324,
                "word_type": "n.",
                "spelling_usage": "The act of machinating."
            },
            {
                "spelling_detail_id": 80325,
                "word_type": "n.",
                "spelling_usage": "That which is devised; a device; a hostile or\n   treacherous scheme; an artful design or plot."
            }
        ]
    },
    {
        "id": 51041,
        "spelling_name": "Mackerel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80349,
                "word_type": "n.",
                "spelling_usage": "A pimp; also, a bawd."
            },
            {
                "spelling_detail_id": 80350,
                "word_type": "n.",
                "spelling_usage": "Any species of the genus Scomber, and of several related\n   genera. They are finely formed and very active oceanic fishes. Most of\n   them are highly prized for food."
            }
        ]
    },
    {
        "id": 51056,
        "spelling_name": "Macrobiotics",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80369,
                "word_type": "n.",
                "spelling_usage": "The art of prolonging life."
            }
        ]
    },
    {
        "id": 51059,
        "spelling_name": "Macrocosm",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80374,
                "word_type": "n.",
                "spelling_usage": "The great world; that part of the universe which is\n   exterior to man; -- contrasted with microcosm, or man. See Microcosm."
            }
        ]
    },
    {
        "id": 51301,
        "spelling_name": "Mahogany",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80708,
                "word_type": "n.",
                "spelling_usage": "A large tree of the genus Swietenia (S. Mahogoni), found\n   in tropical America."
            },
            {
                "spelling_detail_id": 80709,
                "word_type": "n.",
                "spelling_usage": "The wood of the Swietenia Mahogoni. It is of a reddish\n   brown color, beautifully veined, very hard, and susceptible of a fine\n   polish. It is used in the manufacture of furniture."
            },
            {
                "spelling_detail_id": 80710,
                "word_type": "n.",
                "spelling_usage": "A table made of mahogany wood."
            }
        ]
    },
    {
        "id": 51330,
        "spelling_name": "Maidenhair",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80751,
                "word_type": "n.",
                "spelling_usage": "A fern of the genus Adiantum (A. pedatum), having very\n   slender graceful stalks. It is common in the United States, and is\n   sometimes used in medicine. The name is also applied to other species\n   of the same genus, as to the Venus-hair."
            }
        ]
    },
    {
        "id": 51396,
        "spelling_name": "Maize",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80869,
                "word_type": "n.",
                "spelling_usage": "A large species of American grass of the genus Zea (Z.\n   Mays), widely cultivated as a forage and food plant; Indian corn. Also,\n   its seed, growing on cobs, and used as food for men animals."
            }
        ]
    },
    {
        "id": 51471,
        "spelling_name": "Malaise",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 80994,
                "word_type": "n.",
                "spelling_usage": "An indefinite feeling of uneasiness, or of being sick or\n   ill at ease."
            }
        ]
    },
    {
        "id": 51479,
        "spelling_name": "Malapropism",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81003,
                "word_type": "n.",
                "spelling_usage": "A grotesque misuse of a word; a word so used."
            }
        ]
    },
    {
        "id": 51535,
        "spelling_name": "Malevolent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81075,
                "word_type": "a.",
                "spelling_usage": "Wishing evil; disposed to injure others; rejoicing in\n   another's misfortune."
            }
        ]
    },
    {
        "id": 51540,
        "spelling_name": "Malfeasance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81080,
                "word_type": "n.",
                "spelling_usage": "The doing of an act which a person ought not to do;\n   evil conduct; an illegal deed."
            }
        ]
    },
    {
        "id": 51561,
        "spelling_name": "Malinger",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81121,
                "word_type": "v. i.",
                "spelling_usage": "To act the part of a malingerer; to feign illness or\n   inability."
            }
        ]
    },
    {
        "id": 51650,
        "spelling_name": "Mammalian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81232,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Mammalia or mammals."
            }
        ]
    },
    {
        "id": 51721,
        "spelling_name": "Mandarin",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81338,
                "word_type": "n.",
                "spelling_usage": "A Chinese public officer or nobleman; a civil or military\n   official in China and Annam."
            },
            {
                "spelling_detail_id": 81339,
                "word_type": "n.",
                "spelling_usage": "A small orange, with easily separable rind. It is thought\n   to be of Chinese origin, and is counted a distinct species (Citrus\n   nobilis)mandarin orange; tangerine --."
            }
        ]
    },
    {
        "id": 51754,
        "spelling_name": "Mandrill",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81382,
                "word_type": "n.",
                "spelling_usage": "a large West African baboon (Cynocephalus, / Papio,\n   mormon). The adult male has, on the sides of the nose, large, naked,\n   grooved swellings, conspicuously striped with blue and red."
            }
        ]
    },
    {
        "id": 51946,
        "spelling_name": "Mantle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81649,
                "word_type": "n.",
                "spelling_usage": "A loose garment to be worn over other garments; an\n   enveloping robe; a cloak. Hence, figuratively, a covering or concealing\n   envelope."
            },
            {
                "spelling_detail_id": 81650,
                "word_type": "n.",
                "spelling_usage": "Same as Mantling."
            },
            {
                "spelling_detail_id": 81651,
                "word_type": "n.",
                "spelling_usage": "The external fold, or folds, of the soft, exterior membrane\n   of the body of a mollusk. It usually forms a cavity inclosing the\n   gills. See Illusts. of Buccinum, and Byssus."
            },
            {
                "spelling_detail_id": 81652,
                "word_type": "n.",
                "spelling_usage": "Any free, outer membrane."
            },
            {
                "spelling_detail_id": 81653,
                "word_type": "n.",
                "spelling_usage": "The back of a bird together with the folded wings."
            },
            {
                "spelling_detail_id": 81654,
                "word_type": "n.",
                "spelling_usage": "A mantel. See Mantel."
            },
            {
                "spelling_detail_id": 81655,
                "word_type": "n.",
                "spelling_usage": "The outer wall and casing of a blast furnace, above the\n   hearth."
            },
            {
                "spelling_detail_id": 81656,
                "word_type": "n.",
                "spelling_usage": "A penstock for a water wheel."
            },
            {
                "spelling_detail_id": 81659,
                "word_type": "v. t.",
                "spelling_usage": "To cover or envelop, as with a mantle; to cloak; to\n   hide; to disguise."
            },
            {
                "spelling_detail_id": 81660,
                "word_type": "v. i.",
                "spelling_usage": "To unfold and spread out the wings, like a mantle; --\n   said of hawks. Also used figuratively."
            },
            {
                "spelling_detail_id": 81661,
                "word_type": "v. i.",
                "spelling_usage": "To spread out; -- said of wings."
            },
            {
                "spelling_detail_id": 81662,
                "word_type": "v. i.",
                "spelling_usage": "To spread over the surface as a covering; to overspread;\n   as, the scum mantled on the pool."
            },
            {
                "spelling_detail_id": 81663,
                "word_type": "v. i.",
                "spelling_usage": "To gather, assume, or take on, a covering, as froth,\n   scum, etc."
            }
        ]
    },
    {
        "id": 51953,
        "spelling_name": "Mantra",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81669,
                "word_type": "n.",
                "spelling_usage": "A prayer; an invocation; a religious formula; a charm."
            }
        ]
    },
    {
        "id": 51980,
        "spelling_name": "Manumit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81716,
                "word_type": "v. t.",
                "spelling_usage": "To release from slavery; to liberate from personal\n   bondage or servitude; to free, as a slave."
            }
        ]
    },
    {
        "id": 52115,
        "spelling_name": "Marimba",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81914,
                "word_type": "n.",
                "spelling_usage": "A musical istrument of percussion, consisting of bars\n   yielding musical tones when struck."
            }
        ]
    },
    {
        "id": 52126,
        "spelling_name": "Marionette",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 81929,
                "word_type": "n.",
                "spelling_usage": "A puppet moved by strings, as in a puppet show."
            },
            {
                "spelling_detail_id": 81930,
                "word_type": "n.",
                "spelling_usage": "The buffel duck."
            }
        ]
    },
    {
        "id": 52189,
        "spelling_name": "Marooned",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82041,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Maroon"
            }
        ]
    },
    {
        "id": 52249,
        "spelling_name": "Marsupial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82135,
                "word_type": "a.",
                "spelling_usage": "Having a pouch for carrying the immature young; of or\n   pertaining to the Marsupialia."
            },
            {
                "spelling_detail_id": 82136,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a marsupium; as, the marsupial\n   bones."
            },
            {
                "spelling_detail_id": 82137,
                "word_type": "n.",
                "spelling_usage": "One of the Marsupialia."
            }
        ]
    },
    {
        "id": 52422,
        "spelling_name": "Mastodon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82429,
                "word_type": "n.",
                "spelling_usage": "An extinct genus of mammals closely allied to the\n   elephant, but having less complex molar teeth, and often a pair of\n   lower, as well as upper, tusks, which are incisor teeth. The species\n   were mostly larger than elephants, and their romains occur in nearly\n   all parts of the world in deposits ranging from Miocene to late\n   Quaternary time."
            }
        ]
    },
    {
        "id": 52511,
        "spelling_name": "Matriculation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82585,
                "word_type": "n.",
                "spelling_usage": "The act or process of matriculating; the state of\n   being matriculated."
            }
        ]
    },
    {
        "id": 52593,
        "spelling_name": "Mauve",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82729,
                "word_type": "n.",
                "spelling_usage": "A color of a delicate purple, violet, or lilac."
            }
        ]
    },
    {
        "id": 52596,
        "spelling_name": "Maverick",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82732,
                "word_type": "n.",
                "spelling_usage": "In the southwestern part of the united States, a bullock\n   or heifer that has not been branded, and is unclaimed or wild; -- said\n   to be from Maverick, the name of a cattle owner in Texas who neglected\n   to brand his cattle."
            }
        ]
    },
    {
        "id": 52602,
        "spelling_name": "Mawkish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 82742,
                "word_type": "a.",
                "spelling_usage": "Apt to cause satiety or loathing; nauseous; disgusting."
            },
            {
                "spelling_detail_id": 82743,
                "word_type": "a.",
                "spelling_usage": "Easily disgusted; squeamish; sentimentally fastidious."
            }
        ]
    },
    {
        "id": 52898,
        "spelling_name": "Meeting",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83226,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Meet"
            },
            {
                "spelling_detail_id": 83242,
                "word_type": "n.",
                "spelling_usage": "A coming together; an assembling; as, the meeting of\n   Congress."
            },
            {
                "spelling_detail_id": 83243,
                "word_type": "n.",
                "spelling_usage": "A junction, crossing, or union; as, the meeting of the\n   roads or of two rivers."
            },
            {
                "spelling_detail_id": 83244,
                "word_type": "n.",
                "spelling_usage": "A congregation; a collection of people; a convention; as,\n   a large meeting; an harmonius meeting."
            },
            {
                "spelling_detail_id": 83245,
                "word_type": "n.",
                "spelling_usage": "An assembly for worship; as, to attend meeting on Sunday;\n   -- in England, applied distinctively and disparagingly to the\n   worshiping assemblies of Dissenters."
            }
        ]
    },
    {
        "id": 53060,
        "spelling_name": "Mellifluous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83433,
                "word_type": "a.",
                "spelling_usage": "Flowing as with honey; smooth; flowing sweetly or\n   smoothly; as, a mellifluous voice."
            }
        ]
    },
    {
        "id": 53165,
        "spelling_name": "Menagerie",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83590,
                "word_type": "n.",
                "spelling_usage": "A piace where animals are kept and trained."
            },
            {
                "spelling_detail_id": 83591,
                "word_type": "n.",
                "spelling_usage": "A collection of wild or exotic animals, kept for\n   exhibition."
            }
        ]
    },
    {
        "id": 53172,
        "spelling_name": "Mendacious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83604,
                "word_type": "a.",
                "spelling_usage": "Given to deception or falsehood; lying; as, a\n   mendacious person."
            },
            {
                "spelling_detail_id": 83605,
                "word_type": "a.",
                "spelling_usage": "False; counterfeit; containing falsehood; as, a\n   mendacious statement."
            }
        ]
    },
    {
        "id": 53192,
        "spelling_name": "Menial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83629,
                "word_type": "n.",
                "spelling_usage": "Belonging to a retinue or train of servants; performing\n   servile office; serving."
            },
            {
                "spelling_detail_id": 83630,
                "word_type": "n.",
                "spelling_usage": "Pertaining to servants, esp. domestic servants; servile;\n   low; mean."
            },
            {
                "spelling_detail_id": 83631,
                "word_type": "n.",
                "spelling_usage": "A domestic servant or retainer, esp. one of humble rank;\n   one employed in low or servile offices."
            },
            {
                "spelling_detail_id": 83632,
                "word_type": "n.",
                "spelling_usage": "A person of a servile character or disposition."
            }
        ]
    },
    {
        "id": 53198,
        "spelling_name": "Meningitis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83637,
                "word_type": "n.",
                "spelling_usage": "Inflammation of the membranes of the brain or spinal\n   cord."
            }
        ]
    },
    {
        "id": 53254,
        "spelling_name": "Mention",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83707,
                "word_type": "n.",
                "spelling_usage": "A speaking or notice of anything, -- usually in a brief or\n   cursory manner. Used especially in the phrase to make mention of."
            },
            {
                "spelling_detail_id": 83710,
                "word_type": "v. t.",
                "spelling_usage": "To make mention of; to speak briefly of; to name."
            }
        ]
    },
    {
        "id": 53266,
        "spelling_name": "Mephitic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83721,
                "word_type": "a.",
                "spelling_usage": "Alt. of Mephitical"
            }
        ]
    },
    {
        "id": 53327,
        "spelling_name": "Merely",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83821,
                "word_type": "adv.",
                "spelling_usage": "Purely; unmixedly; absolutely."
            },
            {
                "spelling_detail_id": 83822,
                "word_type": "adv.",
                "spelling_usage": "Not otherwise than; simply; barely; only."
            }
        ]
    },
    {
        "id": 53333,
        "spelling_name": "Merganser",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83829,
                "word_type": "n.",
                "spelling_usage": "Any bird of the genus Merganser, and allied genera. They\n   are allied to the ducks, but have a sharply serrated bill."
            }
        ]
    },
    {
        "id": 53340,
        "spelling_name": "Meridian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83838,
                "word_type": "a.",
                "spelling_usage": "Being at, or pertaining to, midday; belonging to, or\n   passing through, the highest point attained by the sun in his diurnal\n   course."
            },
            {
                "spelling_detail_id": 83839,
                "word_type": "a.",
                "spelling_usage": "Pertaining to the highest point or culmination; as,\n   meridian splendor."
            },
            {
                "spelling_detail_id": 83840,
                "word_type": "a.",
                "spelling_usage": "Midday; noon."
            },
            {
                "spelling_detail_id": 83841,
                "word_type": "a.",
                "spelling_usage": "Hence: The highest point, as of success, prosperity, or\n   the like; culmination."
            },
            {
                "spelling_detail_id": 83842,
                "word_type": "a.",
                "spelling_usage": "A great circle of the sphere passing through the poles of\n   the heavens and the zenith of a given place. It is crossed by the sun\n   at midday."
            },
            {
                "spelling_detail_id": 83843,
                "word_type": "a.",
                "spelling_usage": "A great circle on the surface of the earth, passing\n   through the poles and any given place; also, the half of such a circle\n   included between the poles."
            }
        ]
    },
    {
        "id": 53346,
        "spelling_name": "Merino",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 83851,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a variety of sheep with very fine wool,\n   originally bred in Spain."
            },
            {
                "spelling_detail_id": 83852,
                "word_type": "a.",
                "spelling_usage": "Made of the wool of the merino sheep."
            },
            {
                "spelling_detail_id": 83854,
                "word_type": "n.",
                "spelling_usage": "A breed of sheep originally from Spain, noted for the\n   fineness of its wool."
            },
            {
                "spelling_detail_id": 83855,
                "word_type": "n.",
                "spelling_usage": "A fine fabric of merino wool."
            }
        ]
    },
    {
        "id": 53704,
        "spelling_name": "Metatarsal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 84302,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the metatarsus."
            },
            {
                "spelling_detail_id": 84303,
                "word_type": "n.",
                "spelling_usage": "A metatarsal bone."
            }
        ]
    },
    {
        "id": 53878,
        "spelling_name": "Mezzanine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 84526,
                "word_type": "n.",
                "spelling_usage": "Same as Entresol."
            },
            {
                "spelling_detail_id": 84527,
                "word_type": "n.",
                "spelling_usage": "A partial story which is not on the same level with the\n   story of the main part of the edifice, as of a back building, where the\n   floors are on a level with landings of the staircase of the main house."
            }
        ]
    },
    {
        "id": 53895,
        "spelling_name": "Miasma",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 84551,
                "word_type": "n.",
                "spelling_usage": "Infectious particles or germs floating in the air; air made\n   noxious by the presence of such particles or germs; noxious effluvia;\n   malaria."
            }
        ]
    },
    {
        "id": 54219,
        "spelling_name": "Mimetic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 84994,
                "word_type": "",
                "spelling_usage": "Alt. of Mimetical"
            }
        ]
    },
    {
        "id": 54236,
        "spelling_name": "Minacious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 85021,
                "word_type": "a.",
                "spelling_usage": "Threatening; menacing."
            }
        ]
    },
    {
        "id": 54355,
        "spelling_name": "Minotaur",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 85235,
                "word_type": "n.",
                "spelling_usage": "A fabled monster, half man and half bull, confined in the\n   labyrinth constructed by Daedalus in Crete."
            }
        ]
    },
    {
        "id": 54380,
        "spelling_name": "Minutia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 85286,
                "word_type": "n.",
                "spelling_usage": "A minute particular; a small or minor detail; -- used\n   chiefly in the plural."
            }
        ]
    },
    {
        "id": 54524,
        "spelling_name": "Miscible",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 85466,
                "word_type": "a.",
                "spelling_usage": "Capable of being mixed; mixable; as, water and alcohol\n   are miscible in all proportions."
            }
        ]
    },
    {
        "id": 54552,
        "spelling_name": "Misconstrue",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 85499,
                "word_type": "v. t.",
                "spelling_usage": "To construe wrongly; to interpret erroneously."
            }
        ]
    },
    {
        "id": 54716,
        "spelling_name": "Mislead",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 85712,
                "word_type": "v. t.",
                "spelling_usage": "To lead into a wrong way or path; to lead astray; to\n   guide into error; to cause to mistake; to deceive."
            }
        ]
    },
    {
        "id": 55000,
        "spelling_name": "Mittimus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86101,
                "word_type": "n.",
                "spelling_usage": "A precept or warrant granted by a justice for committing\n   to prison a party charged with crime; a warrant of commitment to\n   prison."
            },
            {
                "spelling_detail_id": 86102,
                "word_type": "n.",
                "spelling_usage": "A writ for removing records from one court to another."
            }
        ]
    },
    {
        "id": 55050,
        "spelling_name": "Mobility",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86182,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being mobile; as, the mobility of\n   a liquid, of an army, of the populace, of features, of a muscle."
            },
            {
                "spelling_detail_id": 86183,
                "word_type": "n.",
                "spelling_usage": "The mob; the lower classes."
            }
        ]
    },
    {
        "id": 55127,
        "spelling_name": "Modicum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86318,
                "word_type": "n.",
                "spelling_usage": "A little; a small quantity; a measured simply."
            }
        ]
    },
    {
        "id": 55149,
        "spelling_name": "Modular",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86341,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to mode, modulation, module, or modius;\n   as, modular arrangement; modular accent; modular measure."
            }
        ]
    },
    {
        "id": 55215,
        "spelling_name": "Molasses",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86436,
                "word_type": "n.",
                "spelling_usage": "The thick, brown or dark colored, viscid,\n   uncrystallizable sirup which drains from sugar, in the process of\n   manufacture; any thick, viscid, sweet sirup made from vegetable juice\n   or sap, as of the sorghum or maple. See Treacle."
            }
        ]
    },
    {
        "id": 55267,
        "spelling_name": "Mollify",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86544,
                "word_type": "v. t.",
                "spelling_usage": "To soften; to make tender; to reduce the hardness,\n   harshness, or asperity of; to qualify; as, to mollify the ground."
            },
            {
                "spelling_detail_id": 86545,
                "word_type": "v. t.",
                "spelling_usage": "To assuage, as pain or irritation, to appease, as\n   excited feeling or passion; to pacify; to calm."
            }
        ]
    },
    {
        "id": 55431,
        "spelling_name": "Monitory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86774,
                "word_type": "a.",
                "spelling_usage": "Giving admonition; instructing by way of caution;\n   warning."
            },
            {
                "spelling_detail_id": 86775,
                "word_type": "n.",
                "spelling_usage": "Admonition; warning; especially, a monition proceeding\n   from an ecclesiastical court, but not addressed to any one person."
            }
        ]
    },
    {
        "id": 55466,
        "spelling_name": "Monochrome",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86826,
                "word_type": "n.",
                "spelling_usage": "A painting or drawing in a single color; a picture made\n   with a single color."
            }
        ]
    },
    {
        "id": 55471,
        "spelling_name": "Monocle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 86831,
                "word_type": "n.",
                "spelling_usage": "An eyeglass for one eye."
            }
        ]
    },
    {
        "id": 55771,
        "spelling_name": "Moorage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 87225,
                "word_type": "n.",
                "spelling_usage": "A place for mooring."
            }
        ]
    },
    {
        "id": 55850,
        "spelling_name": "Mordant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 87353,
                "word_type": "a.",
                "spelling_usage": "Biting; caustic; sarcastic; keen; severe."
            },
            {
                "spelling_detail_id": 87354,
                "word_type": "a.",
                "spelling_usage": "Serving to fix colors."
            },
            {
                "spelling_detail_id": 87355,
                "word_type": "n.",
                "spelling_usage": "Any corroding substance used in etching."
            },
            {
                "spelling_detail_id": 87356,
                "word_type": "n.",
                "spelling_usage": "Any substance, as alum or copperas, which, having a\n   twofold attraction for organic fibers and coloring matter, serves as a\n   bond of union, and thus gives fixity to, or bites in, the dyes."
            },
            {
                "spelling_detail_id": 87357,
                "word_type": "n.",
                "spelling_usage": "Any sticky matter by which the gold leaf is made to\n   adhere."
            },
            {
                "spelling_detail_id": 87360,
                "word_type": "v. t.",
                "spelling_usage": "To subject to the action of, or imbue with, a mordant;\n   as, to mordant goods for dyeing."
            }
        ]
    },
    {
        "id": 55877,
        "spelling_name": "Moribund",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 87400,
                "word_type": "a.",
                "spelling_usage": "In a dying state; dying; at the point of death."
            },
            {
                "spelling_detail_id": 87401,
                "word_type": "n.",
                "spelling_usage": "A dying person."
            }
        ]
    },
    {
        "id": 55911,
        "spelling_name": "Moroccan",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 87446,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Morocco, or its inhabitants."
            }
        ]
    },
    {
        "id": 55998,
        "spelling_name": "Mosaic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 87591,
                "word_type": "n.",
                "spelling_usage": "A surface decoration made by inlaying in patterns small\n   pieces of variously colored glass, stone, or other material; -- called\n   also mosaic work."
            },
            {
                "spelling_detail_id": 87592,
                "word_type": "n.",
                "spelling_usage": "A picture or design made in mosaic; an article decorated in\n   mosaic."
            },
            {
                "spelling_detail_id": 87593,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the style of work called mosaic; formed\n   by uniting pieces of different colors; variegated; tessellated; also,\n   composed of various materials or ingredients."
            },
            {
                "spelling_detail_id": 87594,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Moses, the leader of the Israelites, or\n   established through his agency; as, the Mosaic law, rites, or\n   institutions."
            }
        ]
    },
    {
        "id": 56388,
        "spelling_name": "Multifarious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 88261,
                "word_type": "a.",
                "spelling_usage": "Having multiplicity; having great diversity or\n   variety; of various kinds; diversified; made up of many differing\n   parts; manifold."
            },
            {
                "spelling_detail_id": 88262,
                "word_type": "a.",
                "spelling_usage": "Having parts, as leaves, arranged in many vertical\n   rows."
            }
        ]
    },
    {
        "id": 56464,
        "spelling_name": "Multivalent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 88356,
                "word_type": "a.",
                "spelling_usage": "Having a valence greater than one, as silicon."
            },
            {
                "spelling_detail_id": 88357,
                "word_type": "a.",
                "spelling_usage": "Having more than one degree of valence, as sulphur."
            }
        ]
    },
    {
        "id": 56532,
        "spelling_name": "Municipal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 88458,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a city or a corporation having the\n   right of administering local government; as, municipal rights;\n   municipal officers."
            },
            {
                "spelling_detail_id": 88459,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a state, kingdom, or nation."
            }
        ]
    },
    {
        "id": 56595,
        "spelling_name": "Murmuration",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 88540,
                "word_type": "n.",
                "spelling_usage": "The act of murmuring; a murmur."
            }
        ]
    },
    {
        "id": 56735,
        "spelling_name": "Mustard",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 88742,
                "word_type": "n.",
                "spelling_usage": "The name of several cruciferous plants of the genus\n   Brassica (formerly Sinapis), as white mustard (B. alba), black mustard\n   (B. Nigra), wild mustard or charlock (B. Sinapistrum)."
            },
            {
                "spelling_detail_id": 88743,
                "word_type": "n.",
                "spelling_usage": "A powder or a paste made from the seeds of black or white\n   mustard, used as a condiment and a rubefacient. Taken internally it is\n   stimulant and diuretic, and in large doses is emetic."
            }
        ]
    },
    {
        "id": 56882,
        "spelling_name": "Myopic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 88939,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or affected with, or characterized by,\n   myopia; nearsighted."
            }
        ]
    },
    {
        "id": 57110,
        "spelling_name": "Napoleon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89247,
                "word_type": "n.",
                "spelling_usage": "A French gold coin of twenty francs, or about $3.86."
            }
        ]
    },
    {
        "id": 57302,
        "spelling_name": "Nautilus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89561,
                "word_type": "n.",
                "spelling_usage": "The only existing genus of tetrabranchiate cephalopods.\n   About four species are found living in the tropical Pacific, but many\n   other species are found fossil. The shell is spiral, symmetrical, and\n   chambered, or divided into several cavities by simple curved\n   partitions, which are traversed and connected together by a continuous\n   and nearly central tube or siphuncle. See Tetrabranchiata."
            },
            {
                "spelling_detail_id": 89562,
                "word_type": "n.",
                "spelling_usage": "The argonaut; -- also called paper nautilus. See\n   Argonauta, and Paper nautilus, under Paper."
            },
            {
                "spelling_detail_id": 89563,
                "word_type": "n.",
                "spelling_usage": "A variety of diving bell, the lateral as well as vertical\n   motions of which are controlled, by the occupants."
            }
        ]
    },
    {
        "id": 57350,
        "spelling_name": "Neapolitan",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89631,
                "word_type": "a.",
                "spelling_usage": "Of of pertaining to Naples in Italy."
            },
            {
                "spelling_detail_id": 89632,
                "word_type": "n.",
                "spelling_usage": "A native or citizen of Naples."
            }
        ]
    },
    {
        "id": 57383,
        "spelling_name": "Nebulous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89690,
                "word_type": "a.",
                "spelling_usage": "Cloudy; hazy; misty."
            },
            {
                "spelling_detail_id": 89691,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or having the appearance of, a nebula;\n   nebular; cloudlike."
            }
        ]
    },
    {
        "id": 57401,
        "spelling_name": "Necessity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89719,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being necessary, unavoidable, or\n   absolutely requisite; inevitableness; indispensableness."
            },
            {
                "spelling_detail_id": 89720,
                "word_type": "n.",
                "spelling_usage": "The condition of being needy or necessitous; pressing\n   need; indigence; want."
            },
            {
                "spelling_detail_id": 89721,
                "word_type": "n.",
                "spelling_usage": "That which is necessary; a necessary; a requisite;\n   something indispensable; -- often in the plural."
            },
            {
                "spelling_detail_id": 89722,
                "word_type": "n.",
                "spelling_usage": "That which makes an act or an event unavoidable;\n   irresistible force; overruling power; compulsion, physical or moral;\n   fate; fatality."
            },
            {
                "spelling_detail_id": 89723,
                "word_type": "n.",
                "spelling_usage": "The negation of freedom in voluntary action; the\n   subjection of all phenomena, whether material or spiritual, to\n   inevitable causation; necessitarianism."
            }
        ]
    },
    {
        "id": 57445,
        "spelling_name": "Necrotic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89783,
                "word_type": "a.",
                "spelling_usage": "Affected with necrosis; as, necrotic tissue;\n   characterized by, or producing, necrosis; as, a necrotic process."
            }
        ]
    },
    {
        "id": 57455,
        "spelling_name": "Nectarine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 89794,
                "word_type": "a.",
                "spelling_usage": "Nectareous."
            },
            {
                "spelling_detail_id": 89795,
                "word_type": "n.",
                "spelling_usage": "A smooth-skinned variety of peach."
            }
        ]
    },
    {
        "id": 57661,
        "spelling_name": "Neoterism",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 90085,
                "word_type": "n.",
                "spelling_usage": "An innovation or novelty; a neoteric word or phrase."
            }
        ]
    },
    {
        "id": 57697,
        "spelling_name": "Nepotism",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 90129,
                "word_type": "n.",
                "spelling_usage": "Undue attachment to relations; favoritism shown to\n   members of one's family; bestowal of patronage in consideration of\n   relationship, rather than of merit or of legal claim."
            }
        ]
    },
    {
        "id": 57809,
        "spelling_name": "Neuropathy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 90306,
                "word_type": "n.",
                "spelling_usage": "An affection of the nervous system or of a nerve."
            }
        ]
    },
    {
        "id": 57887,
        "spelling_name": "Nexus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 90430,
                "word_type": "n.",
                "spelling_usage": "Connection; tie."
            }
        ]
    },
    {
        "id": 58181,
        "spelling_name": "Nobiliary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 90847,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the nobility."
            },
            {
                "spelling_detail_id": 90848,
                "word_type": "n.",
                "spelling_usage": "A history of noble families."
            }
        ]
    },
    {
        "id": 58224,
        "spelling_name": "Nocturnal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 90912,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, done or occuring in, the night; as,\n   nocturnal darkness, cries, expedition, etc.; -- opposed to diurnal."
            },
            {
                "spelling_detail_id": 90913,
                "word_type": "a.",
                "spelling_usage": "Having a habit of seeking food or moving about at night;\n   as, nocturnal birds and insects."
            },
            {
                "spelling_detail_id": 90914,
                "word_type": "n.",
                "spelling_usage": "An instrument formerly used for taking the altitude of\n   the stars, etc., at sea."
            }
        ]
    },
    {
        "id": 58314,
        "spelling_name": "Nomenclature",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91055,
                "word_type": "n.",
                "spelling_usage": "A name."
            },
            {
                "spelling_detail_id": 91056,
                "word_type": "n.",
                "spelling_usage": "A vocabulary, dictionary, or glossary."
            },
            {
                "spelling_detail_id": 91057,
                "word_type": "n.",
                "spelling_usage": "The technical names used in any particular branch of\n   science or art, or by any school or individual; as, the nomenclature of\n   botany or of chemistry; the nomenclature of Lavoisier and his\n   associates."
            }
        ]
    },
    {
        "id": 58317,
        "spelling_name": "Nominal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91061,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a name or names; having to do with the\n   literal meaning of a word; verbal; as, a nominal definition."
            },
            {
                "spelling_detail_id": 91062,
                "word_type": "a.",
                "spelling_usage": "Existing in name only; not real; as, a nominal difference."
            },
            {
                "spelling_detail_id": 91063,
                "word_type": "n.",
                "spelling_usage": "A nominalist."
            },
            {
                "spelling_detail_id": 91064,
                "word_type": "n.",
                "spelling_usage": "A verb formed from a noun."
            },
            {
                "spelling_detail_id": 91065,
                "word_type": "n.",
                "spelling_usage": "A name; an appellation."
            }
        ]
    },
    {
        "id": 58405,
        "spelling_name": "Nonconformist",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91150,
                "word_type": "n.",
                "spelling_usage": "One who does not conform to an established church;\n   especially, one who does not conform to the established church of\n   England; a dissenter."
            }
        ]
    },
    {
        "id": 58637,
        "spelling_name": "Nostalgia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91473,
                "word_type": "n.",
                "spelling_usage": "Homesickness; esp., a severe and sometimes fatal form of\n   melancholia, due to homesickness."
            }
        ]
    },
    {
        "id": 58711,
        "spelling_name": "Notoriety",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91617,
                "word_type": "n.",
                "spelling_usage": "The quality or condition of being notorious; the state\n   of being generally or publicly known; -- commonly used in an\n   unfavorable sense; as, the notoriety of a crime."
            }
        ]
    },
    {
        "id": 58790,
        "spelling_name": "Noxious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91737,
                "word_type": "a.",
                "spelling_usage": "Hurtful; harmful; baneful; pernicious; injurious;\n   destructive; unwholesome; insalubrious; as, noxious air, food, or\n   climate; pernicious; corrupting to morals; as, noxious practices or\n   examples."
            },
            {
                "spelling_detail_id": 91738,
                "word_type": "a.",
                "spelling_usage": "Guilty; criminal."
            }
        ]
    },
    {
        "id": 58829,
        "spelling_name": "Nucleated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 91785,
                "word_type": "a.",
                "spelling_usage": "Having a nucleus; nucleate; as, nucleated cells."
            }
        ]
    },
    {
        "id": 58982,
        "spelling_name": "Nutation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92019,
                "word_type": "n.",
                "spelling_usage": "The act of nodding."
            },
            {
                "spelling_detail_id": 92020,
                "word_type": "n.",
                "spelling_usage": "A very small libratory motion of the earth's axis, by\n   which its inclination to the plane of the ecliptic is constantly\n   varying by a small amount."
            },
            {
                "spelling_detail_id": 92021,
                "word_type": "n.",
                "spelling_usage": "The motion of a flower in following the apparent movement\n   of the sun, from the east in the morning to the west in the evening."
            },
            {
                "spelling_detail_id": 92022,
                "word_type": "n.",
                "spelling_usage": "Circumnutation."
            }
        ]
    },
    {
        "id": 58993,
        "spelling_name": "Nutria",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92038,
                "word_type": "n.",
                "spelling_usage": "The fur of the coypu. See Coypu."
            }
        ]
    },
    {
        "id": 59111,
        "spelling_name": "Obediential",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92201,
                "word_type": "a.",
                "spelling_usage": "According to the rule of obedience."
            }
        ]
    },
    {
        "id": 59119,
        "spelling_name": "Obelisk",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92209,
                "word_type": "n.",
                "spelling_usage": "An upright, four-sided pillar, gradually tapering as it\n   rises, and terminating in a pyramid called pyramidion. It is ordinarily\n   monolithic. Egyptian obelisks are commonly covered with hieroglyphic\n   writing from top to bottom."
            },
            {
                "spelling_detail_id": 92210,
                "word_type": "n.",
                "spelling_usage": "A mark of reference; -- called also dagger [/]. See\n   Dagger, n., 2."
            },
            {
                "spelling_detail_id": 92213,
                "word_type": "v. t.",
                "spelling_usage": "To mark or designate with an obelisk."
            }
        ]
    },
    {
        "id": 59140,
        "spelling_name": "Obfuscate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92236,
                "word_type": "a.",
                "spelling_usage": "Obfuscated; darkened; obscured."
            },
            {
                "spelling_detail_id": 92239,
                "word_type": "v. t.",
                "spelling_usage": "To darken; to obscure; to becloud; hence, to confuse;\n   to bewilder."
            }
        ]
    },
    {
        "id": 59198,
        "spelling_name": "Oblige",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92328,
                "word_type": "v. t.",
                "spelling_usage": "To attach, as by a bond."
            },
            {
                "spelling_detail_id": 92329,
                "word_type": "v. t.",
                "spelling_usage": "To constrain by physical, moral, or legal force; to put\n   under obligation to do or forbear something."
            },
            {
                "spelling_detail_id": 92330,
                "word_type": "v. t.",
                "spelling_usage": "To bind by some favor rendered; to place under a debt;\n   hence, to do a favor to; to please; to gratify; to accommodate."
            }
        ]
    },
    {
        "id": 59215,
        "spelling_name": "Obliterate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92355,
                "word_type": "v. t.",
                "spelling_usage": "To erase or blot out; to efface; to render\n   undecipherable, as a writing."
            },
            {
                "spelling_detail_id": 92356,
                "word_type": "v. t.",
                "spelling_usage": "To wear out; to remove or destroy utterly by any\n   means; to render imperceptible; as. to obliterate ideas; to obliterate\n   the monuments of antiquity."
            },
            {
                "spelling_detail_id": 92357,
                "word_type": "a.",
                "spelling_usage": "Scarcely distinct; -- applied to the markings of\n   insects."
            }
        ]
    },
    {
        "id": 59321,
        "spelling_name": "Obstetrician",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92515,
                "word_type": "n.",
                "spelling_usage": "One skilled in obstetrics; an accoucheur."
            }
        ]
    },
    {
        "id": 59329,
        "spelling_name": "Obstreperous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92526,
                "word_type": "a.",
                "spelling_usage": "Attended by, or making, a loud and tumultuous noise;\n   clamorous; noisy; vociferous."
            }
        ]
    },
    {
        "id": 59426,
        "spelling_name": "Occipital",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92656,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the occiput, or back part of the\n   head, or to the occipital bone."
            },
            {
                "spelling_detail_id": 92657,
                "word_type": "n.",
                "spelling_usage": "The occipital bone."
            }
        ]
    },
    {
        "id": 59438,
        "spelling_name": "Occultation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92675,
                "word_type": "n.",
                "spelling_usage": "The hiding of a heavenly body from sight by the\n   intervention of some other of the heavenly bodies; -- applied\n   especially to eclipses of stars and planets by the moon, and to the\n   eclipses of satellites of planets by their primaries."
            },
            {
                "spelling_detail_id": 92676,
                "word_type": "n.",
                "spelling_usage": "Fig.: The state of being occult."
            }
        ]
    },
    {
        "id": 59445,
        "spelling_name": "Occupancy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92684,
                "word_type": "n.",
                "spelling_usage": "The act of taking or holding possession; possession;\n   occupation."
            }
        ]
    },
    {
        "id": 59453,
        "spelling_name": "Occur",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92704,
                "word_type": "v. i.",
                "spelling_usage": "To meet; to clash."
            },
            {
                "spelling_detail_id": 92705,
                "word_type": "v. i.",
                "spelling_usage": "To go in order to meet; to make reply."
            },
            {
                "spelling_detail_id": 92706,
                "word_type": "v. i.",
                "spelling_usage": "To meet one's eye; to be found or met with; to present\n   itself; to offer; to appear; to happen; to take place; as, I will write\n   if opportunity occurs."
            },
            {
                "spelling_detail_id": 92707,
                "word_type": "v. i.",
                "spelling_usage": "To meet or come to the mind; to suggest itself; to be\n   presented to the imagination or memory."
            }
        ]
    },
    {
        "id": 59588,
        "spelling_name": "Oculus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92877,
                "word_type": "n.",
                "spelling_usage": "An eye; (Bot.) a leaf bud."
            },
            {
                "spelling_detail_id": 92878,
                "word_type": "n.",
                "spelling_usage": "A round window, usually a small one."
            }
        ]
    },
    {
        "id": 59615,
        "spelling_name": "Odometer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92915,
                "word_type": "n.",
                "spelling_usage": "An instrument attached to the wheel of a vehicle, to\n   measure the distance traversed; also, a wheel used by surveyors, which\n   registers the miles and rods traversed."
            }
        ]
    },
    {
        "id": 59623,
        "spelling_name": "Odontiasis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 92924,
                "word_type": "n.",
                "spelling_usage": "Cutting of the teeth; dentition."
            }
        ]
    },
    {
        "id": 59852,
        "spelling_name": "Olfactory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93278,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or connected with, the sense of\n   smell; as, the olfactory nerves; the olfactory cells."
            },
            {
                "spelling_detail_id": 93280,
                "word_type": "n.",
                "spelling_usage": "An olfactory organ; also, the sense of smell; -- usually\n   in the plural."
            }
        ]
    },
    {
        "id": 59867,
        "spelling_name": "Oligarchy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93296,
                "word_type": "n.",
                "spelling_usage": "A form of government in which the supreme power is\n   placed in the hands of a few persons; also, those who form the ruling\n   few."
            }
        ]
    },
    {
        "id": 59903,
        "spelling_name": "Olympiad",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93349,
                "word_type": "n.",
                "spelling_usage": "A period of four years, by which the ancient Greeks\n   reckoned time, being the interval from one celebration of the Olympic\n   games to another, beginning with the victory of Cor/bus in the foot\n   race, which took place in the year 776 b.c.; as, the era of the\n   olympiads."
            }
        ]
    },
    {
        "id": 59942,
        "spelling_name": "Omnibus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93395,
                "word_type": "n.",
                "spelling_usage": "A long four-wheeled carriage, having seats for many\n   people; especially, one with seats running lengthwise, used in\n   conveying passengers short distances."
            },
            {
                "spelling_detail_id": 93396,
                "word_type": "n.",
                "spelling_usage": "A sheet-iron cover for articles in a leer or annealing\n   arch, to protect them from drafts."
            }
        ]
    },
    {
        "id": 59971,
        "spelling_name": "Omniscient",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93427,
                "word_type": "a.",
                "spelling_usage": "Having universal knowledge; knowing all things;\n   infinitely knowing or wise; as, the omniscient God."
            }
        ]
    },
    {
        "id": 60041,
        "spelling_name": "Onerous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93535,
                "word_type": "a.",
                "spelling_usage": "Burdensome; oppressive."
            }
        ]
    },
    {
        "id": 60064,
        "spelling_name": "Onomatopoeia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93570,
                "word_type": "n.",
                "spelling_usage": "The formation of words in imitation of sounds; a\n   figure of speech in which the sound of a word is imitative of the sound\n   of the thing which the word represents; as, the buzz of bees; the hiss\n   of a goose; the crackle of fire."
            }
        ]
    },
    {
        "id": 60084,
        "spelling_name": "Onus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93595,
                "word_type": "n.",
                "spelling_usage": "A burden; an obligation."
            }
        ]
    },
    {
        "id": 60153,
        "spelling_name": "Opalescence",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93678,
                "word_type": "n.",
                "spelling_usage": "A reflection of a milky or pearly light from the\n   interior of a mineral, as in the moonstone; the state or quality of\n   being opalescent."
            }
        ]
    },
    {
        "id": 60322,
        "spelling_name": "Opponency",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93936,
                "word_type": "n.",
                "spelling_usage": "The act of opening an academical disputation; the\n   proposition of objections to a tenet, as an exercise for a degree."
            }
        ]
    },
    {
        "id": 60337,
        "spelling_name": "Opposite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93963,
                "word_type": "a.",
                "spelling_usage": "Placed over against; standing or situated over against or\n   in front; facing; -- often with to; as, a house opposite to the\n   Exchange."
            },
            {
                "spelling_detail_id": 93964,
                "word_type": "a.",
                "spelling_usage": "Applied to the other of two things which are entirely\n   different; other; as, the opposite sex; the opposite extreme."
            },
            {
                "spelling_detail_id": 93965,
                "word_type": "a.",
                "spelling_usage": "Extremely different; inconsistent; contrary; repugnant;\n   antagonistic."
            },
            {
                "spelling_detail_id": 93966,
                "word_type": "a.",
                "spelling_usage": "Set over against each other, but separated by the whole\n   diameter of the stem, as two leaves at the same node."
            },
            {
                "spelling_detail_id": 93967,
                "word_type": "a.",
                "spelling_usage": "Placed directly in front of another part or organ, as a\n   stamen which stands before a petal."
            },
            {
                "spelling_detail_id": 93968,
                "word_type": "n.",
                "spelling_usage": "One who opposes; an opponent; an antagonist."
            },
            {
                "spelling_detail_id": 93969,
                "word_type": "n.",
                "spelling_usage": "That which is opposed or contrary; as, sweetness and its\n   opposite."
            }
        ]
    },
    {
        "id": 60353,
        "spelling_name": "Opprobrious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 93998,
                "word_type": "a.",
                "spelling_usage": "Expressive of opprobrium; attaching disgrace;\n   reproachful; scurrilous; as, opprobrious language."
            },
            {
                "spelling_detail_id": 93999,
                "word_type": "a.",
                "spelling_usage": "Infamous; despised; rendered hateful; as, an\n   opprobrious name."
            }
        ]
    },
    {
        "id": 60395,
        "spelling_name": "Opulent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94059,
                "word_type": "a.",
                "spelling_usage": "Having a large estate or property; wealthy; rich;\n   affluent; as, an opulent city; an opulent citizen."
            }
        ]
    },
    {
        "id": 60397,
        "spelling_name": "Opus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94062,
                "word_type": "n.",
                "spelling_usage": "A work; specif. (Mus.), a musical composition."
            }
        ]
    },
    {
        "id": 60481,
        "spelling_name": "Orchestra",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94191,
                "word_type": "n.",
                "spelling_usage": "The space in a theater between the stage and the\n   audience; -- originally appropriated by the Greeks to the chorus and\n   its evolutions, afterward by the Romans to persons of distinction, and\n   by the moderns to a band of instrumental musicians."
            },
            {
                "spelling_detail_id": 94192,
                "word_type": "n.",
                "spelling_usage": "The place in any public hall appropriated to a band of\n   instrumental musicians."
            },
            {
                "spelling_detail_id": 94193,
                "word_type": "n.",
                "spelling_usage": "Loosely: A band of instrumental musicians performing in\n   a theater, concert hall, or other place of public amusement."
            },
            {
                "spelling_detail_id": 94194,
                "word_type": "n.",
                "spelling_usage": "Strictly: A band suitable for the performance of\n   symphonies, overtures, etc., as well as for the accompaniment of\n   operas, oratorios, cantatas, masses, and the like, or of vocal and\n   instrumental solos."
            },
            {
                "spelling_detail_id": 94195,
                "word_type": "n.",
                "spelling_usage": "A band composed, for the largest part, of players of the\n   various viol instruments, many of each kind, together with a proper\n   complement of wind instruments of wood and brass; -- as distinguished\n   from a military or street band of players on wind instruments, and from\n   an assemblage of solo players for the rendering of concerted pieces,\n   such as septets, octets, and the like."
            },
            {
                "spelling_detail_id": 94196,
                "word_type": "n.",
                "spelling_usage": "The instruments employed by a full band, collectively;\n   as, an orchestra of forty stringed instruments, with proper complement\n   of wind instruments."
            }
        ]
    },
    {
        "id": 60523,
        "spelling_name": "Ordinance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94277,
                "word_type": "n.",
                "spelling_usage": "Orderly arrangement; preparation; provision."
            },
            {
                "spelling_detail_id": 94278,
                "word_type": "n.",
                "spelling_usage": "A rule established by authority; a permanent rule of\n   action; a statute, law, regulation, rescript, or accepted usage; an\n   edict or decree; esp., a local law enacted by a municipal government;\n   as, a municipal ordinance."
            },
            {
                "spelling_detail_id": 94279,
                "word_type": "n.",
                "spelling_usage": "An established rite or ceremony."
            },
            {
                "spelling_detail_id": 94280,
                "word_type": "n.",
                "spelling_usage": "Rank; order; station."
            },
            {
                "spelling_detail_id": 94281,
                "word_type": "n.",
                "spelling_usage": "Ordnance; cannon."
            }
        ]
    },
    {
        "id": 60619,
        "spelling_name": "Oriel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94418,
                "word_type": "n.",
                "spelling_usage": "A gallery for minstrels."
            },
            {
                "spelling_detail_id": 94419,
                "word_type": "n.",
                "spelling_usage": "A small apartment next a hall, where certain persons were\n   accustomed to dine; a sort of recess."
            },
            {
                "spelling_detail_id": 94420,
                "word_type": "n.",
                "spelling_usage": "A bay window. See Bay window."
            }
        ]
    },
    {
        "id": 60696,
        "spelling_name": "Ornithology",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94536,
                "word_type": "n.",
                "spelling_usage": "That branch of zoology which treats of the natural\n   history of birds and their classification."
            },
            {
                "spelling_detail_id": 94537,
                "word_type": "n.",
                "spelling_usage": "A treatise or book on this science."
            }
        ]
    },
    {
        "id": 60853,
        "spelling_name": "Oscillation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94727,
                "word_type": "n.",
                "spelling_usage": "The act of oscillating; a swinging or moving backward\n   and forward, like a pendulum; vibration."
            },
            {
                "spelling_detail_id": 94728,
                "word_type": "n.",
                "spelling_usage": "Fluctuation; variation; change back and forth."
            }
        ]
    },
    {
        "id": 60905,
        "spelling_name": "Osprey",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 94796,
                "word_type": "n.",
                "spelling_usage": "Alt. of Ospray"
            }
        ]
    },
    {
        "id": 61076,
        "spelling_name": "Ottoman",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 95004,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Turks; as, the Ottoman power or\n   empire."
            },
            {
                "spelling_detail_id": 95006,
                "word_type": "n.",
                "spelling_usage": "A Turk."
            },
            {
                "spelling_detail_id": 95007,
                "word_type": "n.",
                "spelling_usage": "A stuffed seat without a back, originally used in Turkey."
            }
        ]
    },
    {
        "id": 62001,
        "spelling_name": "Overweening",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96268,
                "word_type": "a.",
                "spelling_usage": "Unduly confident; arrogant; presumptuous; conceited."
            },
            {
                "spelling_detail_id": 96269,
                "word_type": "n.",
                "spelling_usage": "Conceit; arrogance."
            }
        ]
    },
    {
        "id": 62179,
        "spelling_name": "Oxygenate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96499,
                "word_type": "v. t.",
                "spelling_usage": "To unite, or cause to combine, with oxygen; to treat\n   with oxygen; to oxidize; as, oxygenated water (hydrogen dioxide)."
            }
        ]
    },
    {
        "id": 62277,
        "spelling_name": "Pachyderm",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96619,
                "word_type": "n.",
                "spelling_usage": "One of the Pachydermata."
            }
        ]
    },
    {
        "id": 62373,
        "spelling_name": "Pageantry",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96819,
                "word_type": "n.",
                "spelling_usage": "Scenic shows or spectacles, taken collectively;\n   spectacular quality; splendor."
            }
        ]
    },
    {
        "id": 62382,
        "spelling_name": "Pagoda",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96828,
                "word_type": "n.",
                "spelling_usage": "A term by which Europeans designate religious temples and\n   tower-like buildings of the Hindoos and Buddhists of India, Farther\n   India, China, and Japan, -- usually but not always, devoted to idol\n   worship."
            },
            {
                "spelling_detail_id": 96829,
                "word_type": "n.",
                "spelling_usage": "An idol."
            },
            {
                "spelling_detail_id": 96830,
                "word_type": "n.",
                "spelling_usage": "A gold or silver coin, of various kinds and values,\n   formerly current in India. The Madras gold pagoda was worth about three\n   and a half rupees."
            }
        ]
    },
    {
        "id": 62454,
        "spelling_name": "Palatable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96946,
                "word_type": "a.",
                "spelling_usage": "Agreeable to the palate or taste; savory; hence,\n   acceptable; pleasing; as, palatable food; palatable advice."
            }
        ]
    },
    {
        "id": 62460,
        "spelling_name": "Palatial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 96958,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a palace; suitable for a palace;\n   resembling a palace; royal; magnificent; as, palatial structures."
            },
            {
                "spelling_detail_id": 96959,
                "word_type": "a.",
                "spelling_usage": "Palatal; palatine."
            },
            {
                "spelling_detail_id": 96960,
                "word_type": "n.",
                "spelling_usage": "A palatal letter."
            }
        ]
    },
    {
        "id": 62521,
        "spelling_name": "Paleozoic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97048,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to, or designating, the older division\n   of geological time during which life is known to have existed,\n   including the Silurian, Devonian, and Carboniferous ages, and also to\n   the life or rocks of those ages. See Chart of Geology."
            }
        ]
    },
    {
        "id": 62591,
        "spelling_name": "Palliative",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97165,
                "word_type": "a.",
                "spelling_usage": "Serving to palliate; serving to extenuate or mitigate."
            },
            {
                "spelling_detail_id": 97166,
                "word_type": "n.",
                "spelling_usage": "That which palliates; a palliative agent."
            }
        ]
    },
    {
        "id": 62604,
        "spelling_name": "Pallor",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97182,
                "word_type": "a.",
                "spelling_usage": "Paleness; want of color; pallidity; as, pallor of the\n   complexion."
            }
        ]
    },
    {
        "id": 62730,
        "spelling_name": "Panacea",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97369,
                "word_type": "n.",
                "spelling_usage": "A remedy for all diseases; a universal medicine; a\n   cure-all; catholicon; hence, a relief or solace for affliction."
            },
            {
                "spelling_detail_id": 97370,
                "word_type": "n.",
                "spelling_usage": "The herb allheal."
            }
        ]
    },
    {
        "id": 62736,
        "spelling_name": "Panary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97379,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to bread or to breadmaking."
            },
            {
                "spelling_detail_id": 97380,
                "word_type": "n.",
                "spelling_usage": "A storehouse for bread."
            }
        ]
    },
    {
        "id": 62749,
        "spelling_name": "Pancreas",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97395,
                "word_type": "n.",
                "spelling_usage": "The sweetbread, a gland connected with the intestine of\n   nearly all vertebrates. It is usually elongated and light-colored, and\n   its secretion, called the pancreatic juice, is discharged, often\n   together with the bile, into the upper part of the intestines, and is a\n   powerful aid in digestion. See Illust. of Digestive apparatus."
            }
        ]
    },
    {
        "id": 62896,
        "spelling_name": "Pantheon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97598,
                "word_type": "n.",
                "spelling_usage": "A temple dedicated to all the gods; especially, the\n   building so called at Rome."
            },
            {
                "spelling_detail_id": 97599,
                "word_type": "n.",
                "spelling_usage": "The collective gods of a people, or a work treating of\n   them; as, a divinity of the Greek pantheon."
            }
        ]
    },
    {
        "id": 62920,
        "spelling_name": "Pantomime",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97623,
                "word_type": "n.",
                "spelling_usage": "A universal mimic; an actor who assumes many parts;\n   also, any actor."
            },
            {
                "spelling_detail_id": 97624,
                "word_type": "n.",
                "spelling_usage": "One who acts his part by gesticulation or dumb show\n   only, without speaking; a pantomimist."
            },
            {
                "spelling_detail_id": 97625,
                "word_type": "n.",
                "spelling_usage": "A dramatic representation by actors who use only dumb\n   show; hence, dumb show, generally."
            },
            {
                "spelling_detail_id": 97626,
                "word_type": "n.",
                "spelling_usage": "A dramatic and spectacular entertainment of which dumb\n   acting as well as burlesque dialogue, music, and dancing by Clown,\n   Harlequin, etc., are features."
            },
            {
                "spelling_detail_id": 97627,
                "word_type": "a.",
                "spelling_usage": "Representing only in mute actions; pantomimic; as, a\n   pantomime dance."
            }
        ]
    },
    {
        "id": 63016,
        "spelling_name": "Papyrus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97752,
                "word_type": "n.",
                "spelling_usage": "A tall rushlike plant (Cyperus Papyrus) of the Sedge\n   family, formerly growing in Egypt, and now found in Abyssinia, Syria,\n   Sicily, etc. The stem is triangular and about an inch thick."
            },
            {
                "spelling_detail_id": 97753,
                "word_type": "n.",
                "spelling_usage": "The material upon which the ancient Egyptians wrote. It\n   was formed by cutting the stem of the plant into thin longitudinal\n   slices, which were gummed together and pressed."
            },
            {
                "spelling_detail_id": 97754,
                "word_type": "n.",
                "spelling_usage": "A manuscript written on papyrus; esp., pl., written\n   scrolls made of papyrus; as, the papyri of Egypt or Herculaneum."
            }
        ]
    },
    {
        "id": 63026,
        "spelling_name": "Parable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 97766,
                "word_type": "a.",
                "spelling_usage": "Procurable."
            },
            {
                "spelling_detail_id": 97767,
                "word_type": "n.",
                "spelling_usage": "A comparison; a similitude; specifically, a short\n   fictitious narrative of something which might really occur in life or\n   nature, by means of which a moral is drawn; as, the parables of Christ."
            },
            {
                "spelling_detail_id": 97768,
                "word_type": "v. t.",
                "spelling_usage": "To represent by parable."
            }
        ]
    },
    {
        "id": 63363,
        "spelling_name": "Pariah",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 98245,
                "word_type": "n.",
                "spelling_usage": "One of an aboriginal people of Southern India, regarded by\n   the four castes of the Hindoos as of very low grade. They are usually\n   the serfs of the Sudra agriculturalists. See Caste."
            },
            {
                "spelling_detail_id": 98246,
                "word_type": "n.",
                "spelling_usage": "An outcast; one despised by society."
            }
        ]
    },
    {
        "id": 63403,
        "spelling_name": "Parley",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 98312,
                "word_type": "n.",
                "spelling_usage": "Mutual discourse or conversation; discussion; hence, an\n   oral conference with an enemy, as with regard to a truce."
            },
            {
                "spelling_detail_id": 98315,
                "word_type": "v. i.",
                "spelling_usage": "To speak with another; to confer on some point of mutual\n   concern; to discuss orally; hence, specifically, to confer orally with\n   an enemy; to treat with him by words, as on an exchange of prisoners,\n   an armistice, or terms of peace."
            }
        ]
    },
    {
        "id": 63411,
        "spelling_name": "Parliamentary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 98325,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Parliament; as, parliamentary\n   authority."
            },
            {
                "spelling_detail_id": 98326,
                "word_type": "a.",
                "spelling_usage": "Enacted or done by Parliament; as, a parliamentary\n   act."
            },
            {
                "spelling_detail_id": 98327,
                "word_type": "a.",
                "spelling_usage": "According to the rules and usages of Parliament or\n   of deliberative bodies; as, a parliamentary motion."
            }
        ]
    },
    {
        "id": 63576,
        "spelling_name": "Partridge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 98628,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of small gallinaceous birds\n   of the genus Perdix and several related genera of the family\n   Perdicidae, of the Old World. The partridge is noted as a game bird."
            },
            {
                "spelling_detail_id": 98629,
                "word_type": "n.",
                "spelling_usage": "Any one of several species of quail-like birds belonging\n   to Colinus, and allied genera."
            },
            {
                "spelling_detail_id": 98630,
                "word_type": "n.",
                "spelling_usage": "The ruffed grouse (Bonasa umbellus)."
            }
        ]
    },
    {
        "id": 63706,
        "spelling_name": "Pastry",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 98898,
                "word_type": "n.",
                "spelling_usage": "The place where pastry is made."
            },
            {
                "spelling_detail_id": 98899,
                "word_type": "n.",
                "spelling_usage": "Articles of food made of paste, or having a crust made of\n   paste, as pies, tarts, etc."
            }
        ]
    },
    {
        "id": 63749,
        "spelling_name": "Patent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 98980,
                "word_type": "a.",
                "spelling_usage": "Open; expanded; evident; apparent; unconcealed; manifest;\n   public; conspicuous."
            },
            {
                "spelling_detail_id": 98981,
                "word_type": "a.",
                "spelling_usage": "Open to public perusal; -- said of a document conferring\n   some right or privilege; as, letters patent. See Letters patent, under\n   3d Letter."
            },
            {
                "spelling_detail_id": 98982,
                "word_type": "a.",
                "spelling_usage": "Appropriated or protected by letters patent; secured by\n   official authority to the exclusive possession, control, and disposal\n   of some person or party; patented; as, a patent right; patent\n   medicines."
            },
            {
                "spelling_detail_id": 98983,
                "word_type": "a.",
                "spelling_usage": "Spreading; forming a nearly right angle with the steam or\n   branch; as, a patent leaf."
            },
            {
                "spelling_detail_id": 98984,
                "word_type": "a.",
                "spelling_usage": "A letter patent, or letters patent; an official document,\n   issued by a sovereign power, conferring a right or privilege on some\n   person or party."
            },
            {
                "spelling_detail_id": 98985,
                "word_type": "a.",
                "spelling_usage": "A writing securing to an invention."
            },
            {
                "spelling_detail_id": 98986,
                "word_type": "a.",
                "spelling_usage": "A document making a grant and conveyance of public lands."
            },
            {
                "spelling_detail_id": 98987,
                "word_type": "a.",
                "spelling_usage": "The right or privilege conferred by such a document; hence,\n   figuratively, a right, privilege, or license of the nature of a patent."
            },
            {
                "spelling_detail_id": 98990,
                "word_type": "v. t.",
                "spelling_usage": "To grant by patent; to make the subject of a patent; to\n   secure or protect by patent; as, to patent an invention; to patent\n   public lands."
            }
        ]
    },
    {
        "id": 63790,
        "spelling_name": "Pathos",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99043,
                "word_type": "n.",
                "spelling_usage": "That quality or property of anything which touches the\n   feelings or excites emotions and passions, esp., that which awakens\n   tender emotions, such as pity, sorrow, and the like; contagious warmth\n   of feeling, action, or expression; pathetic quality; as, the pathos of\n   a picture, of a poem, or of a cry."
            }
        ]
    },
    {
        "id": 63816,
        "spelling_name": "Patrician",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99089,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Roman patres (fathers) or\n   senators, or patricians."
            },
            {
                "spelling_detail_id": 99090,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or appropriate to, a person of high\n   birth; noble; not plebeian."
            },
            {
                "spelling_detail_id": 99091,
                "word_type": "n.",
                "spelling_usage": "Originally, a member of any of the families constituting\n   the populus Romanus, or body of Roman citizens, before the development\n   of the plebeian order; later, one who, by right of birth or by special\n   privilege conferred, belonged to the nobility."
            },
            {
                "spelling_detail_id": 99092,
                "word_type": "n.",
                "spelling_usage": "A person of high birth; a nobleman."
            },
            {
                "spelling_detail_id": 99093,
                "word_type": "n.",
                "spelling_usage": "One familiar with the works of the Christian Fathers;\n   one versed in patristic lore."
            }
        ]
    },
    {
        "id": 63856,
        "spelling_name": "Patronymic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99158,
                "word_type": "a.",
                "spelling_usage": "Derived from ancestors; as, a patronymic denomination."
            },
            {
                "spelling_detail_id": 99159,
                "word_type": "n.",
                "spelling_usage": "A modification of the father's name borne by the son; a\n   name derived from that of a parent or ancestor; as, Pelides, the son of\n   Peleus; Johnson, the son of John; Macdonald, the son of Donald;\n   Paulowitz, the son of Paul; also, the surname of a family; the family\n   name."
            }
        ]
    },
    {
        "id": 63882,
        "spelling_name": "Paucity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99200,
                "word_type": "n.",
                "spelling_usage": "Fewness; smallness of number; scarcity."
            },
            {
                "spelling_detail_id": 99201,
                "word_type": "n.",
                "spelling_usage": "Smallnes of quantity; exiguity; insufficiency; as, paucity\n   of blood."
            }
        ]
    },
    {
        "id": 63903,
        "spelling_name": "Pauper",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99227,
                "word_type": "n.",
                "spelling_usage": "A poor person; especially, one development on private or\n   public charity. Also used adjectively; as, pouper immigrants, pouper\n   labor."
            }
        ]
    },
    {
        "id": 64102,
        "spelling_name": "Peculate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99567,
                "word_type": "v. i.",
                "spelling_usage": "To appropriate to one's own use the property of the\n   public; to steal public moneys intrusted to one's care; to embezzle."
            }
        ]
    },
    {
        "id": 64116,
        "spelling_name": "Pecuniary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99588,
                "word_type": "a.",
                "spelling_usage": "Relating to money; monetary; as, a pecuniary penalty; a\n   pecuniary reward."
            }
        ]
    },
    {
        "id": 64139,
        "spelling_name": "Pedantry",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99619,
                "word_type": "n.",
                "spelling_usage": "The act, character, or manners of a pedant; vain\n   ostentation of learning."
            }
        ]
    },
    {
        "id": 64162,
        "spelling_name": "Pedestrian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99649,
                "word_type": "a.",
                "spelling_usage": "Going on foot; performed on foot; as, a pedestrian\n   journey."
            },
            {
                "spelling_detail_id": 99650,
                "word_type": "n.",
                "spelling_usage": "A walker; one who journeys on foot; a foot traveler;\n   specif., a professional walker or runner."
            }
        ]
    },
    {
        "id": 64293,
        "spelling_name": "Pelf",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 99833,
                "word_type": "n.",
                "spelling_usage": "Money; riches; lucre; gain; -- generally conveying the idea\n   of something ill-gotten or worthless. It has no plural."
            }
        ]
    },
    {
        "id": 64436,
        "spelling_name": "Penitentiary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100060,
                "word_type": "a.",
                "spelling_usage": "Relating to penance, or to the rules and measures of\n   penance."
            },
            {
                "spelling_detail_id": 100061,
                "word_type": "a.",
                "spelling_usage": "Expressive of penitence; as, a penitentiary letter."
            },
            {
                "spelling_detail_id": 100062,
                "word_type": "a.",
                "spelling_usage": "Used for punishment, discipline, and reformation."
            },
            {
                "spelling_detail_id": 100064,
                "word_type": "n.",
                "spelling_usage": "One who prescribes the rules and measures of penance."
            },
            {
                "spelling_detail_id": 100065,
                "word_type": "n.",
                "spelling_usage": "One who does penance."
            },
            {
                "spelling_detail_id": 100066,
                "word_type": "n.",
                "spelling_usage": "A small building in a monastery where penitents\n   confessed."
            },
            {
                "spelling_detail_id": 100067,
                "word_type": "n.",
                "spelling_usage": "That part of a church to which penitents were\n   admitted."
            },
            {
                "spelling_detail_id": 100068,
                "word_type": "n.",
                "spelling_usage": "An office of the papal court which examines cases of\n   conscience, confession, absolution from vows, etc., and delivers\n   decisions, dispensations, etc. Its chief is a cardinal, called the\n   Grand Penitentiary, appointed by the pope."
            },
            {
                "spelling_detail_id": 100069,
                "word_type": "n.",
                "spelling_usage": "An officer in some dioceses since A. D. 1215, vested\n   with power from the bishop to absolve in cases reserved to him."
            },
            {
                "spelling_detail_id": 100070,
                "word_type": "n.",
                "spelling_usage": "A house of correction, in which offenders are\n   confined for punishment, discipline, and reformation, and in which they\n   are generally compelled to labor."
            }
        ]
    },
    {
        "id": 64493,
        "spelling_name": "Pensive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100149,
                "word_type": "a.",
                "spelling_usage": "Thoughtful, sober, or sad; employed in serious reflection;\n   given to, or favorable to, earnest or melancholy musing."
            },
            {
                "spelling_detail_id": 100150,
                "word_type": "a.",
                "spelling_usage": "Expressing or suggesting thoughtfulness with sadness; as,\n   pensive numbers."
            }
        ]
    },
    {
        "id": 64656,
        "spelling_name": "Peradventure",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100358,
                "word_type": "adv. & conj.",
                "spelling_usage": "By chance; perhaps; it may be; if;\n   supposing."
            },
            {
                "spelling_detail_id": 100359,
                "word_type": "n.",
                "spelling_usage": "Chance; hap; hence, doubt; question; as, proved\n   beyond peradventure."
            }
        ]
    },
    {
        "id": 64689,
        "spelling_name": "Perceptible",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100400,
                "word_type": "a.",
                "spelling_usage": "Capable of being perceived; cognizable; discernible;\n   perceivable."
            }
        ]
    },
    {
        "id": 64756,
        "spelling_name": "Peremptory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100496,
                "word_type": "a.",
                "spelling_usage": "Precluding debate or expostulation; not admitting of\n   question or appeal; positive; absolute; decisive; conclusive; final."
            },
            {
                "spelling_detail_id": 100497,
                "word_type": "a.",
                "spelling_usage": "Positive in opinion or judgment; decided; dictatorial;\n   dogmatical."
            },
            {
                "spelling_detail_id": 100498,
                "word_type": "a.",
                "spelling_usage": "Firmly determined; unawed."
            }
        ]
    },
    {
        "id": 64804,
        "spelling_name": "Performance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100569,
                "word_type": "n.",
                "spelling_usage": "The act of performing; the carrying into execution or\n   action; execution; achievement; accomplishment; representation by\n   action; as, the performance of an undertaking of a duty."
            },
            {
                "spelling_detail_id": 100570,
                "word_type": "n.",
                "spelling_usage": "That which is performed or accomplished; a thing done\n   or carried through; an achievement; a deed; an act; a feat; esp., an\n   action of an elaborate or public character."
            }
        ]
    },
    {
        "id": 64907,
        "spelling_name": "Perilous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100687,
                "word_type": "a.",
                "spelling_usage": "Full of, attended with, or involving, peril; dangerous;\n   hazardous; as, a perilous undertaking."
            },
            {
                "spelling_detail_id": 100688,
                "word_type": "a.",
                "spelling_usage": "Daring; reckless; dangerous."
            }
        ]
    },
    {
        "id": 64955,
        "spelling_name": "Peripheral",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100758,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a periphery; constituting a\n   periphery; peripheric."
            },
            {
                "spelling_detail_id": 100759,
                "word_type": "a.",
                "spelling_usage": "External; away from the center; as, the peripheral\n   portion of the nervous system."
            }
        ]
    },
    {
        "id": 65099,
        "spelling_name": "Permutation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 100931,
                "word_type": "n.",
                "spelling_usage": "The act of permuting; exchange of the thing for\n   another; mutual transference; interchange."
            },
            {
                "spelling_detail_id": 100932,
                "word_type": "n.",
                "spelling_usage": "The arrangement of any determinate number of things,\n   as units, objects, letters, etc., in all possible orders, one after the\n   other; -- called also alternation. Cf. Combination, n., 4."
            },
            {
                "spelling_detail_id": 100933,
                "word_type": "n.",
                "spelling_usage": "Any one of such possible arrangements."
            },
            {
                "spelling_detail_id": 100934,
                "word_type": "n.",
                "spelling_usage": "Barter; exchange."
            }
        ]
    },
    {
        "id": 65184,
        "spelling_name": "Perseverance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101047,
                "word_type": "n.",
                "spelling_usage": "The act of persevering; persistence in anything\n   undertaken; continued pursuit or prosecution of any business, or\n   enterprise begun."
            },
            {
                "spelling_detail_id": 101048,
                "word_type": "n.",
                "spelling_usage": "Discrimination."
            },
            {
                "spelling_detail_id": 101049,
                "word_type": "n.",
                "spelling_usage": "Continuance in a state of grace until it is succeeded\n   by a state of glory; sometimes called final perseverance, and the\n   perseverance of the saints. See Calvinism."
            }
        ]
    },
    {
        "id": 65233,
        "spelling_name": "Personnel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101139,
                "word_type": "n.",
                "spelling_usage": "The body of persons employed in some public service, as\n   the army, navy, etc.; -- distinguished from materiel."
            }
        ]
    },
    {
        "id": 65262,
        "spelling_name": "Persuasible",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101190,
                "word_type": "a.",
                "spelling_usage": "Capable of being persuaded; persuadable."
            },
            {
                "spelling_detail_id": 101191,
                "word_type": "a.",
                "spelling_usage": "Persuasive."
            }
        ]
    },
    {
        "id": 65311,
        "spelling_name": "Peruse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101261,
                "word_type": "v. t.",
                "spelling_usage": "To observe; to examine with care."
            },
            {
                "spelling_detail_id": 101262,
                "word_type": "v. t.",
                "spelling_usage": "To read through; to read carefully."
            }
        ]
    },
    {
        "id": 65320,
        "spelling_name": "Pervasive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101271,
                "word_type": "a.",
                "spelling_usage": "Tending to pervade, or having power to spread\n   throughout; of a pervading quality."
            }
        ]
    },
    {
        "id": 65383,
        "spelling_name": "Pestilence",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101347,
                "word_type": "n.",
                "spelling_usage": "Specifically, the disease known as the plague; hence,\n   any contagious or infectious epidemic disease that is virulent and\n   devastating."
            },
            {
                "spelling_detail_id": 101348,
                "word_type": "n.",
                "spelling_usage": "Fig.: That which is pestilent, noxious, or pernicious\n   to the moral character of great numbers."
            }
        ]
    },
    {
        "id": 65458,
        "spelling_name": "Petrifying",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101447,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Petrify"
            }
        ]
    },
    {
        "id": 65470,
        "spelling_name": "Petroleum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101464,
                "word_type": "n.",
                "spelling_usage": "Rock oil, mineral oil, or natural oil, a dark brown or\n   greenish inflammable liquid, which, at certain points, exists in the\n   upper strata of the earth, from whence it is pumped, or forced by\n   pressure of the gas attending it. It consists of a complex mixture of\n   various hydrocarbons, largely of the methane series, but may vary much\n   in appearance, composition, and properties. It is refined by\n   distillation, and the products include kerosene, benzine, gasoline,\n   paraffin, etc."
            }
        ]
    },
    {
        "id": 65563,
        "spelling_name": "Phalanges",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101577,
                "word_type": "n.",
                "spelling_usage": "pl. of Phalanx."
            },
            {
                "spelling_detail_id": 101597,
                "word_type": "pl. ",
                "spelling_usage": "of Phalanx"
            }
        ]
    },
    {
        "id": 65614,
        "spelling_name": "Pharaoh",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101641,
                "word_type": "n.",
                "spelling_usage": "A title by which the sovereigns of ancient Egypt were\n   designated."
            },
            {
                "spelling_detail_id": 101642,
                "word_type": "n.",
                "spelling_usage": "See Faro."
            }
        ]
    },
    {
        "id": 65641,
        "spelling_name": "Pharmacy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101674,
                "word_type": "n.",
                "spelling_usage": "The art or practice of preparing and preserving drugs,\n   and of compounding and dispensing medicines according to prescriptions\n   of physicians; the occupation of an apothecary or a pharmaceutical\n   chemist."
            },
            {
                "spelling_detail_id": 101675,
                "word_type": "n.",
                "spelling_usage": "A place where medicines are compounded; a drug store; an\n   apothecary's shop."
            }
        ]
    },
    {
        "id": 65656,
        "spelling_name": "Pharynx",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101694,
                "word_type": "n.",
                "spelling_usage": "The part of the alimentary canal between the cavity of the\n   mouth and the esophagus. It has one or two external openings through\n   the nose in the higher vertebrates, and lateral branchial openings in\n   fishes and some amphibias."
            }
        ]
    },
    {
        "id": 65731,
        "spelling_name": "Philharmonic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101786,
                "word_type": "a.",
                "spelling_usage": "Loving harmony or music."
            }
        ]
    },
    {
        "id": 65745,
        "spelling_name": "Philistine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101804,
                "word_type": "n.",
                "spelling_usage": "A native or an inhabitant of ancient Philistia, a coast\n   region of southern Palestine."
            },
            {
                "spelling_detail_id": 101805,
                "word_type": "n.",
                "spelling_usage": "A bailiff."
            },
            {
                "spelling_detail_id": 101806,
                "word_type": "n.",
                "spelling_usage": "A person deficient in liberal culture and refinement;\n   one without appreciation of the nobler aspirations and sentiments of\n   humanity; one whose scope is limited to selfish and material interests."
            },
            {
                "spelling_detail_id": 101807,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Philistines."
            },
            {
                "spelling_detail_id": 101808,
                "word_type": "a.",
                "spelling_usage": "Uncultured; commonplace."
            }
        ]
    },
    {
        "id": 65790,
        "spelling_name": "Philosophize",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101860,
                "word_type": "v. i.",
                "spelling_usage": "To reason like a philosopher; to search into the\n   reason and nature of things; to investigate phenomena, and assign\n   rational causes for their existence."
            }
        ]
    },
    {
        "id": 65818,
        "spelling_name": "Phlegmatic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101897,
                "word_type": "a.",
                "spelling_usage": "Watery."
            },
            {
                "spelling_detail_id": 101898,
                "word_type": "a.",
                "spelling_usage": "Abounding in phlegm; as, phlegmatic humors; a\n   phlegmatic constitution."
            },
            {
                "spelling_detail_id": 101899,
                "word_type": "a.",
                "spelling_usage": "Generating or causing phlegm."
            },
            {
                "spelling_detail_id": 101900,
                "word_type": "a.",
                "spelling_usage": "Not easily excited to action or passion; cold; dull;\n   sluggish; heavy; as, a phlegmatic person."
            }
        ]
    },
    {
        "id": 65846,
        "spelling_name": "Phlox",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101929,
                "word_type": "n.",
                "spelling_usage": "A genus of American herbs, having showy red, white, or\n   purple flowers."
            }
        ]
    },
    {
        "id": 65873,
        "spelling_name": "Phonetician",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101960,
                "word_type": "n.",
                "spelling_usage": "One versed in phonetics; a phonetist."
            }
        ]
    },
    {
        "id": 65880,
        "spelling_name": "Phonics",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 101969,
                "word_type": "n.",
                "spelling_usage": "See Phonetics."
            }
        ]
    },
    {
        "id": 65935,
        "spelling_name": "Phosphorescent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102034,
                "word_type": "a.",
                "spelling_usage": "Shining with a phosphoric light; luminous without\n   sensible heat."
            },
            {
                "spelling_detail_id": 102035,
                "word_type": "n.",
                "spelling_usage": "A phosphorescent substance."
            }
        ]
    },
    {
        "id": 65969,
        "spelling_name": "Photogenic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102073,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to photogeny; producing or generating\n   light."
            }
        ]
    },
    {
        "id": 66036,
        "spelling_name": "Phraseology",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102151,
                "word_type": "n.",
                "spelling_usage": "Manner of expression; peculiarity of diction; style."
            },
            {
                "spelling_detail_id": 102152,
                "word_type": "n.",
                "spelling_usage": "A collection of phrases; a phrase book."
            }
        ]
    },
    {
        "id": 66086,
        "spelling_name": "Phycology",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102210,
                "word_type": "n.",
                "spelling_usage": "The science of algae, or seaweeds; algology."
            }
        ]
    },
    {
        "id": 66145,
        "spelling_name": "Phylum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102275,
                "word_type": "n.",
                "spelling_usage": "One of the larger divisions of the animal kingdom; a\n   branch; a grand division."
            }
        ]
    },
    {
        "id": 66432,
        "spelling_name": "Piety",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102699,
                "word_type": "n.",
                "spelling_usage": "Veneration or reverence of the Supreme Being, and love of\n   his character; loving obedience to the will of God, and earnest\n   devotion to his service."
            },
            {
                "spelling_detail_id": 102700,
                "word_type": "n.",
                "spelling_usage": "Duty; dutifulness; filial reverence and devotion;\n   affectionate reverence and service shown toward parents, relatives,\n   benefactors, country, etc."
            }
        ]
    },
    {
        "id": 66496,
        "spelling_name": "Pilaster",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102792,
                "word_type": "n.",
                "spelling_usage": "An upright architectural member right-angled in plan,\n   constructionally a pier (See Pier, 1 (b)), but architecturally\n   corresponding to a column, having capital, shaft, and base to agree\n   with those of the columns of the same order. In most cases the\n   projection from the wall is one third of its width, or less."
            }
        ]
    },
    {
        "id": 66502,
        "spelling_name": "Pilcrow",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102799,
                "word_type": "n.",
                "spelling_usage": "a paragraph mark, /."
            }
        ]
    },
    {
        "id": 66518,
        "spelling_name": "Pileus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 102833,
                "word_type": "n.",
                "spelling_usage": "A kind of skull cap of felt."
            },
            {
                "spelling_detail_id": 102834,
                "word_type": "n.",
                "spelling_usage": "The expanded upper portion of many of the fungi. See\n   Mushroom."
            },
            {
                "spelling_detail_id": 102835,
                "word_type": "n.",
                "spelling_usage": "The top of the head of a bird, from the bill to the nape."
            }
        ]
    },
    {
        "id": 66687,
        "spelling_name": "Pinnacle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103136,
                "word_type": "n.",
                "spelling_usage": "An architectural member, upright, and generally ending in\n   a small spire, -- used to finish a buttress, to constitute a part in a\n   proportion, as where pinnacles flank a gable or spire, and the like.\n   Pinnacles may be considered primarily as added weight, where it is\n   necessary to resist the thrust of an arch, etc."
            },
            {
                "spelling_detail_id": 103137,
                "word_type": "n.",
                "spelling_usage": "Anything resembling a pinnacle; a lofty peak; a pointed\n   summit."
            },
            {
                "spelling_detail_id": 103140,
                "word_type": "v. t.",
                "spelling_usage": "To build or furnish with a pinnacle or pinnacles."
            }
        ]
    },
    {
        "id": 66741,
        "spelling_name": "Pious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103210,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to piety; exhibiting piety; reverential;\n   dutiful; religious; devout; godly."
            },
            {
                "spelling_detail_id": 103211,
                "word_type": "a.",
                "spelling_usage": "Practiced under the pretext of religion; prompted by\n   mistaken piety; as, pious errors; pious frauds."
            }
        ]
    },
    {
        "id": 66906,
        "spelling_name": "Pitiful",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103497,
                "word_type": "a.",
                "spelling_usage": "Full of pity; tender-hearted; compassionate; kind;\n   merciful; sympathetic."
            },
            {
                "spelling_detail_id": 103498,
                "word_type": "a.",
                "spelling_usage": "Piteous; lamentable; eliciting compassion."
            },
            {
                "spelling_detail_id": 103499,
                "word_type": "a.",
                "spelling_usage": "To be pitied for littleness or meanness; miserable;\n   paltry; contemptible; despicable."
            }
        ]
    },
    {
        "id": 66920,
        "spelling_name": "Pituitary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103517,
                "word_type": "a.",
                "spelling_usage": "Secreting mucus or phlegm; as, the pituitary membrane,\n   or the mucous membrane which lines the nasal cavities."
            },
            {
                "spelling_detail_id": 103518,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the pituitary body; as, the\n   pituitary fossa."
            }
        ]
    },
    {
        "id": 66981,
        "spelling_name": "Placoderm",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103624,
                "word_type": "n.",
                "spelling_usage": "One of the Placodermi."
            }
        ]
    },
    {
        "id": 67016,
        "spelling_name": "Plague",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103667,
                "word_type": "n.",
                "spelling_usage": "That which smites, wounds, or troubles; a blow; a calamity;\n   any afflictive evil or torment; a great trail or vexation."
            },
            {
                "spelling_detail_id": 103668,
                "word_type": "n.",
                "spelling_usage": "An acute malignant contagious fever, that often prevails in\n   Egypt, Syria, and Turkey, and has at times visited the large cities of\n   Europe with frightful mortality; hence, any pestilence; as, the great\n   London plague."
            },
            {
                "spelling_detail_id": 103671,
                "word_type": "v. t.",
                "spelling_usage": "To infest or afflict with disease, calamity, or natural\n   evil of any kind."
            },
            {
                "spelling_detail_id": 103672,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To vex; to tease; to harass."
            }
        ]
    },
    {
        "id": 67074,
        "spelling_name": "Planetarium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103779,
                "word_type": "n.",
                "spelling_usage": "An orrery. See Orrery."
            }
        ]
    },
    {
        "id": 67125,
        "spelling_name": "Plantain",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 103860,
                "word_type": "n.",
                "spelling_usage": "A treelike perennial herb (Musa paradisiaca) of tropical\n   regions, bearing immense leaves and large clusters of the fruits called\n   plantains. See Musa."
            },
            {
                "spelling_detail_id": 103861,
                "word_type": "n.",
                "spelling_usage": "The fruit of this plant. It is long and somewhat\n   cylindrical, slightly curved, and, when ripe, soft, fleshy, and covered\n   with a thick but tender yellowish skin. The plantain is a staple\n   article of food in most tropical countries, especially when cooked."
            },
            {
                "spelling_detail_id": 103862,
                "word_type": "n.",
                "spelling_usage": "Any plant of the genus Plantago, but especially the P.\n   major, a low herb with broad spreading radical leaves, and slender\n   spikes of minute flowers. It is a native of Europe, but now found near\n   the abode of civilized man in nearly all parts of the world."
            }
        ]
    },
    {
        "id": 67222,
        "spelling_name": "Platinum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104051,
                "word_type": "n.",
                "spelling_usage": "A metallic element, intermediate in value between silver\n   and gold, occurring native or alloyed with other metals, also as the\n   platinum arsenide (sperrylite). It is heavy tin-white metal which is\n   ductile and malleable, but very infusible, and characterized by its\n   resistance to strong chemical reagents. It is used for crucibles, for\n   stills for sulphuric acid, rarely for coin, and in the form of foil and\n   wire for many purposes. Specific gravity 21.5. Atomic weight 194.3.\n   Symbol Pt. Formerly called platina."
            }
        ]
    },
    {
        "id": 67223,
        "spelling_name": "Platitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104052,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being flat, thin, or insipid;\n   flat commonness; triteness; staleness of ideas of language."
            },
            {
                "spelling_detail_id": 104053,
                "word_type": "n.",
                "spelling_usage": "A thought or remark which is flat, dull, trite, or weak;\n   a truism; a commonplace."
            }
        ]
    },
    {
        "id": 67425,
        "spelling_name": "Plethora",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104357,
                "word_type": "n.",
                "spelling_usage": "Overfullness; especially, excessive fullness of the blood\n   vessels; repletion; that state of the blood vessels or of the system\n   when the blood exceeds a healthy standard in quantity; hyperaemia; --\n   opposed to anaemia."
            },
            {
                "spelling_detail_id": 104358,
                "word_type": "n.",
                "spelling_usage": "State of being overfull; excess; superabundance."
            }
        ]
    },
    {
        "id": 67487,
        "spelling_name": "Pliant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104433,
                "word_type": "v.",
                "spelling_usage": "Capable of plying or bending; readily yielding to force or\n   pressure without breaking; flexible; pliable; lithe; limber; plastic;\n   as, a pliant thread; pliant wax. Also used figuratively: Easily\n   influenced for good or evil; tractable; as, a pliant heart."
            },
            {
                "spelling_detail_id": 104434,
                "word_type": "v.",
                "spelling_usage": "Favorable to pliancy."
            }
        ]
    },
    {
        "id": 67560,
        "spelling_name": "Pluck",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104552,
                "word_type": "v. t.",
                "spelling_usage": "To pull; to draw."
            },
            {
                "spelling_detail_id": 104553,
                "word_type": "v. t.",
                "spelling_usage": "Especially, to pull with sudden force or effort, or to\n   pull off or out from something, with a twitch; to twitch; also, to\n   gather, to pick; as, to pluck feathers from a fowl; to pluck hair or\n   wool from a skin; to pluck grapes."
            },
            {
                "spelling_detail_id": 104554,
                "word_type": "v. t.",
                "spelling_usage": "To strip of, or as of, feathers; as, to pluck a fowl."
            },
            {
                "spelling_detail_id": 104555,
                "word_type": "v. t.",
                "spelling_usage": "To reject at an examination for degrees."
            },
            {
                "spelling_detail_id": 104556,
                "word_type": "v. i.",
                "spelling_usage": "To make a motion of pulling or twitching; -- usually with\n   at; as, to pluck at one's gown."
            },
            {
                "spelling_detail_id": 104557,
                "word_type": "n.",
                "spelling_usage": "The act of plucking; a pull; a twitch."
            },
            {
                "spelling_detail_id": 104558,
                "word_type": "n.",
                "spelling_usage": "The heart, liver, and lights of an animal."
            },
            {
                "spelling_detail_id": 104559,
                "word_type": "n.",
                "spelling_usage": "Spirit; courage; indomitable resolution; fortitude."
            },
            {
                "spelling_detail_id": 104560,
                "word_type": "n.",
                "spelling_usage": "The act of plucking, or the state of being plucked, at\n   college. See Pluck, v. t., 4."
            },
            {
                "spelling_detail_id": 104561,
                "word_type": "v. t.",
                "spelling_usage": "The lyrie."
            }
        ]
    },
    {
        "id": 67665,
        "spelling_name": "Plush",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104745,
                "word_type": "n.",
                "spelling_usage": "A textile fabric with a nap or shag on one side, longer and\n   softer than the nap of velvet."
            }
        ]
    },
    {
        "id": 67791,
        "spelling_name": "Podium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 104922,
                "word_type": "n.",
                "spelling_usage": "A low wall, serving as a foundation, a substructure, or a\n   terrace wall."
            },
            {
                "spelling_detail_id": 104923,
                "word_type": "n.",
                "spelling_usage": "The dwarf wall surrounding the arena of an amphitheater,\n   from the top of which the seats began."
            },
            {
                "spelling_detail_id": 104924,
                "word_type": "n.",
                "spelling_usage": "The masonry under the stylobate of a temple, sometimes a\n   mere foundation, sometimes containing chambers."
            },
            {
                "spelling_detail_id": 104925,
                "word_type": "n.",
                "spelling_usage": "The foot."
            }
        ]
    },
    {
        "id": 68134,
        "spelling_name": "Polyglot",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 105509,
                "word_type": "a.",
                "spelling_usage": "Containing, or made up, of, several languages; as, a\n   polyglot lexicon, Bible."
            },
            {
                "spelling_detail_id": 105510,
                "word_type": "a.",
                "spelling_usage": "Versed in, or speaking, many languages."
            },
            {
                "spelling_detail_id": 105511,
                "word_type": "n.",
                "spelling_usage": "One who speaks several languages."
            },
            {
                "spelling_detail_id": 105512,
                "word_type": "n.",
                "spelling_usage": "A book containing several versions of the same text, or\n   containing the same subject matter in several languages; esp., the\n   Scriptures in several languages."
            }
        ]
    },
    {
        "id": 68354,
        "spelling_name": "Pomposity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 105796,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being pompous; pompousness."
            }
        ]
    },
    {
        "id": 68396,
        "spelling_name": "Pontiff",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 105849,
                "word_type": "n.",
                "spelling_usage": "A high priest."
            },
            {
                "spelling_detail_id": 105850,
                "word_type": "n.",
                "spelling_usage": "One of the sacred college, in ancient Rome, which had the\n   supreme jurisdiction over all matters of religion, at the head of which\n   was the Pontifex Maximus."
            },
            {
                "spelling_detail_id": 105851,
                "word_type": "n.",
                "spelling_usage": "The chief priest."
            },
            {
                "spelling_detail_id": 105852,
                "word_type": "n.",
                "spelling_usage": "The pope."
            }
        ]
    },
    {
        "id": 68465,
        "spelling_name": "Populace",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 105991,
                "word_type": "n.",
                "spelling_usage": "The common people; the vulgar; the multitude, --\n   comprehending all persons not distinguished by rank, office, education,\n   or profession."
            }
        ]
    },
    {
        "id": 68528,
        "spelling_name": "Porosity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106081,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being porous; -- opposed to\n   density."
            }
        ]
    },
    {
        "id": 68587,
        "spelling_name": "Portentous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106172,
                "word_type": "a.",
                "spelling_usage": "Of the nature of a portent; containing portents;\n   foreshadowing, esp. foreshadowing ill; ominous."
            },
            {
                "spelling_detail_id": 106173,
                "word_type": "a.",
                "spelling_usage": "Hence: Monstrous; prodigious; wonderful; dreadful; as,\n   a beast of portentous size."
            }
        ]
    },
    {
        "id": 68601,
        "spelling_name": "Portico",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106194,
                "word_type": "n.",
                "spelling_usage": "A colonnade or covered ambulatory, especially in classical\n   styles of architecture; usually, a colonnade at the entrance of a\n   building."
            }
        ]
    },
    {
        "id": 68632,
        "spelling_name": "Portrait",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106233,
                "word_type": "n.",
                "spelling_usage": "The likeness of a person, painted, drawn, or engraved;\n   commonly, a representation of the human face painted from real life."
            },
            {
                "spelling_detail_id": 106234,
                "word_type": "n.",
                "spelling_usage": "Hence, any graphic or vivid delineation or description of\n   a person; as, a portrait in words."
            },
            {
                "spelling_detail_id": 106235,
                "word_type": "v. t.",
                "spelling_usage": "To portray; to draw."
            }
        ]
    },
    {
        "id": 68674,
        "spelling_name": "Posse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106311,
                "word_type": "n.",
                "spelling_usage": "See Posse comitatus."
            }
        ]
    },
    {
        "id": 68683,
        "spelling_name": "Possessive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106330,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to possession; having or indicating\n   possession."
            },
            {
                "spelling_detail_id": 106331,
                "word_type": "n.",
                "spelling_usage": "The possessive case."
            },
            {
                "spelling_detail_id": 106332,
                "word_type": "n.",
                "spelling_usage": "A possessive pronoun, or a word in the possessive case."
            }
        ]
    },
    {
        "id": 68759,
        "spelling_name": "Posthumous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106447,
                "word_type": "a.",
                "spelling_usage": "Born after the death of the father, or taken from the\n   dead body of the mother; as, a posthumous son or daughter."
            },
            {
                "spelling_detail_id": 106448,
                "word_type": "a.",
                "spelling_usage": "Published after the death of the author; as, posthumous\n   works; a posthumous edition."
            },
            {
                "spelling_detail_id": 106449,
                "word_type": "a.",
                "spelling_usage": "Being or continuing after one's death; as, a posthumous\n   reputation."
            }
        ]
    },
    {
        "id": 68866,
        "spelling_name": "Potassium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106607,
                "word_type": "n.",
                "spelling_usage": "An Alkali element, occurring abundantly but always\n   combined, as in the chloride, sulphate, carbonate, or silicate, in the\n   minerals sylvite, kainite, orthoclase, muscovite, etc. Atomic weight\n   39.0. Symbol K (Kalium)."
            }
        ]
    },
    {
        "id": 68918,
        "spelling_name": "Potpourri",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 106681,
                "word_type": "n.",
                "spelling_usage": "A medley or mixture."
            },
            {
                "spelling_detail_id": 106682,
                "word_type": "n.",
                "spelling_usage": "A ragout composed of different sorts of meats,\n   vegetables, etc., cooked together."
            },
            {
                "spelling_detail_id": 106683,
                "word_type": "n.",
                "spelling_usage": "A jar or packet of flower leaves, perfumes, and spices,\n   used to scent a room."
            },
            {
                "spelling_detail_id": 106684,
                "word_type": "n.",
                "spelling_usage": "A piece of music made up of different airs strung\n   together; a medley."
            },
            {
                "spelling_detail_id": 106685,
                "word_type": "n.",
                "spelling_usage": "A literary production composed of parts brought together\n   without order or bond of connection."
            }
        ]
    },
    {
        "id": 69159,
        "spelling_name": "Praxis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107095,
                "word_type": "n.",
                "spelling_usage": "Use; practice; especially, exercise or discipline for a\n   specific purpose or object."
            },
            {
                "spelling_detail_id": 107096,
                "word_type": "n.",
                "spelling_usage": "An example or form of exercise, or a collection of such\n   examples, for practice."
            }
        ]
    },
    {
        "id": 69341,
        "spelling_name": "Precursor",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107352,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, precedes an event, and indicates\n   its approach; a forerunner; a harbinger."
            }
        ]
    },
    {
        "id": 69387,
        "spelling_name": "Predicament",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107413,
                "word_type": "n.",
                "spelling_usage": "A class or kind described by any definite marks;\n   hence, condition; particular situation or state; especially, an\n   unfortunate or trying position or condition."
            },
            {
                "spelling_detail_id": 107414,
                "word_type": "n.",
                "spelling_usage": "See Category."
            }
        ]
    },
    {
        "id": 69434,
        "spelling_name": "Preeminent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107475,
                "word_type": "a.",
                "spelling_usage": "Eminent above others; prominent among those who are\n   eminent; superior in excellence; surpassing, or taking precedence of,\n   others; rarely, surpassing others in evil, or in bad qualities; as,\n   preeminent in guilt."
            }
        ]
    },
    {
        "id": 69489,
        "spelling_name": "Preferential",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107546,
                "word_type": "a.",
                "spelling_usage": "Giving, indicating, or having a preference or\n   precedence; as, a preferential claim; preferential shares."
            }
        ]
    },
    {
        "id": 69531,
        "spelling_name": "Prehensile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107597,
                "word_type": "n.",
                "spelling_usage": "Adapted to seize or grasp; seizing; grasping; as, the\n   prehensile tail of a monkey."
            }
        ]
    },
    {
        "id": 69556,
        "spelling_name": "Prejudice",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107627,
                "word_type": "n.",
                "spelling_usage": "Foresight."
            },
            {
                "spelling_detail_id": 107628,
                "word_type": "n.",
                "spelling_usage": "An opinion or judgment formed without due examination;\n   prejudgment; a leaning toward one side of a question from other\n   considerations than those belonging to it; an unreasonable predilection\n   for, or objection against, anything; especially, an opinion or leaning\n   adverse to anything, without just grounds, or before sufficient\n   knowledge."
            },
            {
                "spelling_detail_id": 107629,
                "word_type": "n.",
                "spelling_usage": "A bias on the part of judge, juror, or witness which\n   interferes with fairness of judgment."
            },
            {
                "spelling_detail_id": 107630,
                "word_type": "n.",
                "spelling_usage": "Mischief; hurt; damage; injury; detriment."
            },
            {
                "spelling_detail_id": 107633,
                "word_type": "n.",
                "spelling_usage": "To cause to have prejudice; to prepossess with opinions\n   formed without due knowledge or examination; to bias the mind of, by\n   hasty and incorrect notions; to give an unreasonable bent to, as to one\n   side or the other of a cause; as, to prejudice a critic or a juryman."
            },
            {
                "spelling_detail_id": 107634,
                "word_type": "n.",
                "spelling_usage": "To obstruct or injure by prejudices, or by previous bias\n   of the mind; hence, generally, to hurt; to damage; to injure; to\n   impair; as, to prejudice a good cause."
            }
        ]
    },
    {
        "id": 69590,
        "spelling_name": "Preliminary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107671,
                "word_type": "a.",
                "spelling_usage": "Introductory; previous; preceding the main discourse\n   or business; prefatory; as, preliminary observations to a discourse or\n   book; preliminary articles to a treaty; preliminary measures;\n   preliminary examinations."
            },
            {
                "spelling_detail_id": 107673,
                "word_type": "n.",
                "spelling_usage": "That which precedes the main discourse, work, design,\n   or business; something introductory or preparatory; as, the\n   preliminaries to a negotiation or duel; to take one's preliminaries the\n   year before entering college."
            }
        ]
    },
    {
        "id": 69635,
        "spelling_name": "Premonition",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107738,
                "word_type": "n.",
                "spelling_usage": "Previous warning, notice, or information; forewarning;\n   as, a premonition of danger."
            }
        ]
    },
    {
        "id": 69710,
        "spelling_name": "Preponderance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107834,
                "word_type": "n.",
                "spelling_usage": "Alt. of Preponderancy"
            }
        ]
    },
    {
        "id": 69729,
        "spelling_name": "Preposterous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 107862,
                "word_type": "a.",
                "spelling_usage": "Having that first which ought to be last; inverted in\n   order."
            },
            {
                "spelling_detail_id": 107863,
                "word_type": "a.",
                "spelling_usage": "Contrary to nature or reason; not adapted to the end;\n   utterly and glaringly foolish; unreasonably absurd; perverted."
            }
        ]
    },
    {
        "id": 69839,
        "spelling_name": "President",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108055,
                "word_type": "n.",
                "spelling_usage": "Precedent."
            },
            {
                "spelling_detail_id": 108056,
                "word_type": "a.",
                "spelling_usage": "Occupying the first rank or chief place; having the\n   highest authority; presiding."
            },
            {
                "spelling_detail_id": 108057,
                "word_type": "n.",
                "spelling_usage": "One who is elected or appointed to preside; a presiding\n   officer, as of a legislative body."
            },
            {
                "spelling_detail_id": 108058,
                "word_type": "n.",
                "spelling_usage": "The chief officer of a corporation, company,\n   institution, society, or the like."
            },
            {
                "spelling_detail_id": 108059,
                "word_type": "n.",
                "spelling_usage": "The chief executive officer of the government in certain\n   republics; as, the president of the United States."
            },
            {
                "spelling_detail_id": 108060,
                "word_type": "n.",
                "spelling_usage": "A protector; a guardian; a presiding genius."
            }
        ]
    },
    {
        "id": 69902,
        "spelling_name": "Presumptuous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108172,
                "word_type": "a.",
                "spelling_usage": "Full of presumption; presuming; overconfident or\n   venturesome; audacious; rash; taking liberties unduly; arrogant;\n   insolent; as, a presumptuous commander; presumptuous conduct."
            },
            {
                "spelling_detail_id": 108173,
                "word_type": "a.",
                "spelling_usage": "Founded on presumption; as, a presumptuous idea."
            },
            {
                "spelling_detail_id": 108174,
                "word_type": "a.",
                "spelling_usage": "Done with hold design, rash confidence, or in\n   violation of known duty; willful."
            }
        ]
    },
    {
        "id": 69996,
        "spelling_name": "Prevenient",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108311,
                "word_type": "a.",
                "spelling_usage": "Going before; preceding; hence, preventive."
            }
        ]
    },
    {
        "id": 70010,
        "spelling_name": "Previous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108335,
                "word_type": "a.",
                "spelling_usage": "Going before in time; being or happening before something\n   else; antecedent; prior; as, previous arrangements; a previous illness."
            }
        ]
    },
    {
        "id": 70094,
        "spelling_name": "Prima donna",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108501,
                "word_type": "a.",
                "spelling_usage": "The first or chief female singer in an opera."
            }
        ]
    },
    {
        "id": 70132,
        "spelling_name": "Primitive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108577,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the beginning or origin, or to early\n   times; original; primordial; primeval; first; as, primitive innocence;\n   the primitive church."
            },
            {
                "spelling_detail_id": 108578,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a former time; old-fashioned;\n   characterized by simplicity; as, a primitive style of dress."
            },
            {
                "spelling_detail_id": 108579,
                "word_type": "a.",
                "spelling_usage": "Original; primary; radical; not derived; as, primitive\n   verb in grammar."
            },
            {
                "spelling_detail_id": 108580,
                "word_type": "n.",
                "spelling_usage": "An original or primary word; a word not derived from\n   another; -- opposed to derivative."
            }
        ]
    },
    {
        "id": 70321,
        "spelling_name": "Procedure",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108911,
                "word_type": "n.",
                "spelling_usage": "The act or manner of proceeding or moving forward;\n   progress; process; operation; conduct."
            },
            {
                "spelling_detail_id": 108912,
                "word_type": "n.",
                "spelling_usage": "A step taken; an act performed; a proceeding; the steps\n   taken in an action or other legal proceeding."
            },
            {
                "spelling_detail_id": 108913,
                "word_type": "n.",
                "spelling_usage": "That which results; issue; product."
            }
        ]
    },
    {
        "id": 70373,
        "spelling_name": "Procrastinate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 108999,
                "word_type": "v. t.",
                "spelling_usage": "To put off till to-morrow, or from day to day; to\n   defer; to postpone; to delay; as, to procrastinate repentance."
            },
            {
                "spelling_detail_id": 109000,
                "word_type": "v. i.",
                "spelling_usage": "To delay; to be dilatory."
            }
        ]
    },
    {
        "id": 70416,
        "spelling_name": "Procurement",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109061,
                "word_type": "n.",
                "spelling_usage": "The act of procuring or obtaining; obtainment;\n   attainment."
            },
            {
                "spelling_detail_id": 109062,
                "word_type": "n.",
                "spelling_usage": "Efficient contrivance; management; agency."
            }
        ]
    },
    {
        "id": 70432,
        "spelling_name": "Prodigious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109083,
                "word_type": "a.",
                "spelling_usage": "Of the nature of a prodigy; marvelous; wonderful;\n   portentous."
            },
            {
                "spelling_detail_id": 109084,
                "word_type": "a.",
                "spelling_usage": "Extraordinary in bulk, extent, quantity, or degree;\n   very great; vast; huge; immense; as, a prodigious mountain; a\n   prodigious creature; a prodigious blunder."
            }
        ]
    },
    {
        "id": 70523,
        "spelling_name": "Profundity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109249,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being profound; depth of place,\n   knowledge, feeling, etc."
            }
        ]
    },
    {
        "id": 70608,
        "spelling_name": "Proletarian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109393,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the proletaries; belonging to the\n   commonalty; hence, mean; vile; vulgar."
            },
            {
                "spelling_detail_id": 109394,
                "word_type": "n.",
                "spelling_usage": "A proletary."
            }
        ]
    },
    {
        "id": 70623,
        "spelling_name": "Prolix",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109417,
                "word_type": "a.",
                "spelling_usage": "Extending to a great length; unnecessarily long; minute in\n   narration or argument; excessively particular in detail; -- rarely used\n   except with reference to discourse written or spoken; as, a prolix\n   oration; a prolix poem; a prolix sermon."
            },
            {
                "spelling_detail_id": 109418,
                "word_type": "a.",
                "spelling_usage": "Indulging in protracted discourse; tedious; wearisome; --\n   applied to a speaker or writer."
            }
        ]
    },
    {
        "id": 70664,
        "spelling_name": "Prominent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109473,
                "word_type": "a.",
                "spelling_usage": "Standing out, or projecting, beyond the line surface of\n   something; jutting; protuberant; in high relief; as, a prominent figure\n   on a vase."
            },
            {
                "spelling_detail_id": 109474,
                "word_type": "a.",
                "spelling_usage": "Hence; Distinctly manifest; likely to attract attention\n   from its size or position; conspicuous; as, a prominent feature of the\n   face; a prominent building."
            },
            {
                "spelling_detail_id": 109475,
                "word_type": "a.",
                "spelling_usage": "Eminent; distinguished above others; as, a prominent\n   character."
            }
        ]
    },
    {
        "id": 70681,
        "spelling_name": "Promontory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109502,
                "word_type": "n.",
                "spelling_usage": "A high point of land or rock projecting into the sea\n   beyond the line of coast; a headland; a high cape."
            },
            {
                "spelling_detail_id": 109503,
                "word_type": "n.",
                "spelling_usage": "A projecting part. Especially: (a) The projecting angle\n   of the ventral side of the sacrum where it joins the last lumbar\n   vertebra. (b) A prominence on the inner wall of the tympanum of the\n   ear."
            }
        ]
    },
    {
        "id": 70835,
        "spelling_name": "Propinquity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109749,
                "word_type": "n.",
                "spelling_usage": "Nearness in place; neighborhood; proximity."
            },
            {
                "spelling_detail_id": 109750,
                "word_type": "n.",
                "spelling_usage": "Nearness in time."
            },
            {
                "spelling_detail_id": 109751,
                "word_type": "n.",
                "spelling_usage": "Nearness of blood; kindred; affinity."
            }
        ]
    },
    {
        "id": 70896,
        "spelling_name": "Proprietary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109845,
                "word_type": "n.",
                "spelling_usage": "A proprietor or owner; one who has exclusive title to\n   a thing; one who possesses, or holds the title to, a thing in his own\n   right."
            },
            {
                "spelling_detail_id": 109846,
                "word_type": "n.",
                "spelling_usage": "A body proprietors, taken collectively."
            },
            {
                "spelling_detail_id": 109847,
                "word_type": "n.",
                "spelling_usage": "A monk who had reserved goods and effects to himself,\n   notwithstanding his renunciation of all at the time of profession."
            },
            {
                "spelling_detail_id": 109848,
                "word_type": "a.",
                "spelling_usage": "Belonging, or pertaining, to a proprietor; considered\n   as property; owned; as, proprietary medicine."
            }
        ]
    },
    {
        "id": 70913,
        "spelling_name": "Propulsion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109867,
                "word_type": "n.",
                "spelling_usage": "The act driving forward or away; the act or process of\n   propelling; as, steam propulsion."
            },
            {
                "spelling_detail_id": 109868,
                "word_type": "n.",
                "spelling_usage": "An impelling act or movement."
            }
        ]
    },
    {
        "id": 70936,
        "spelling_name": "Prorogue",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 109895,
                "word_type": "v. t.",
                "spelling_usage": "To protract; to prolong; to extend."
            },
            {
                "spelling_detail_id": 109896,
                "word_type": "v. t.",
                "spelling_usage": "To defer; to delay; to postpone; as, to proroguedeath;\n   to prorogue a marriage."
            },
            {
                "spelling_detail_id": 109897,
                "word_type": "v. t.",
                "spelling_usage": "To end the session of a parliament by an order of the\n   sovereign, thus deferring its business."
            }
        ]
    },
    {
        "id": 71026,
        "spelling_name": "Prosperous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110025,
                "word_type": "a.",
                "spelling_usage": "Tending to prosperity; favoring; favorable; helpful."
            },
            {
                "spelling_detail_id": 110026,
                "word_type": "a.",
                "spelling_usage": "Being prospered; advancing in the pursuit of anything\n   desirable; making gain, or increase; thriving; successful; as, a\n   prosperous voyage; a prosperous undertaking; a prosperous man or\n   nation."
            }
        ]
    },
    {
        "id": 71035,
        "spelling_name": "Prosthetic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110037,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to prosthesis; prefixed, as a letter\n   or letters to a word."
            }
        ]
    },
    {
        "id": 71073,
        "spelling_name": "Protectorate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110100,
                "word_type": "n.",
                "spelling_usage": "Government by a protector; -- applied especially to\n   the government of England by Oliver Cromwell."
            },
            {
                "spelling_detail_id": 110101,
                "word_type": "n.",
                "spelling_usage": "The authority assumed by a superior power over an\n   inferior or a dependent one, whereby the former protects the latter\n   from invasion and shares in the management of its affairs."
            }
        ]
    },
    {
        "id": 71203,
        "spelling_name": "Protuberant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110282,
                "word_type": "a.",
                "spelling_usage": "Prominent, or excessively prominent; bulging beyond\n   the surrounding or adjacent surface; swelling; as, a protuberant joint;\n   a protuberant eye."
            }
        ]
    },
    {
        "id": 71234,
        "spelling_name": "Proverb",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110332,
                "word_type": "n.",
                "spelling_usage": "An old and common saying; a phrase which is often\n   repeated; especially, a sentence which briefly and forcibly expresses\n   some practical truth, or the result of experience and observation; a\n   maxim; a saw; an adage."
            },
            {
                "spelling_detail_id": 110333,
                "word_type": "n.",
                "spelling_usage": "A striking or paradoxical assertion; an obscure saying; an\n   enigma; a parable."
            },
            {
                "spelling_detail_id": 110334,
                "word_type": "n.",
                "spelling_usage": "A familiar illustration; a subject of contemptuous\n   reference."
            },
            {
                "spelling_detail_id": 110335,
                "word_type": "n.",
                "spelling_usage": "A drama exemplifying a proverb."
            },
            {
                "spelling_detail_id": 110336,
                "word_type": "v. t.",
                "spelling_usage": "To name in, or as, a proverb."
            },
            {
                "spelling_detail_id": 110337,
                "word_type": "v. t.",
                "spelling_usage": "To provide with a proverb."
            },
            {
                "spelling_detail_id": 110338,
                "word_type": "v. i.",
                "spelling_usage": "To write or utter proverbs."
            }
        ]
    },
    {
        "id": 71253,
        "spelling_name": "Provincial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110373,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to province; constituting a province;\n   as, a provincial government; a provincial dialect."
            },
            {
                "spelling_detail_id": 110374,
                "word_type": "a.",
                "spelling_usage": "Exhibiting the ways or manners of a province;\n   characteristic of the inhabitants of a province; not cosmopolitan;\n   countrified; not polished; rude; hence, narrow; illiberal."
            },
            {
                "spelling_detail_id": 110375,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to an ecclesiastical province, or to\n   the jurisdiction of an archbishop; not ecumenical; as, a provincial\n   synod."
            },
            {
                "spelling_detail_id": 110376,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Provence; Provencal."
            },
            {
                "spelling_detail_id": 110377,
                "word_type": "n.",
                "spelling_usage": "A person belonging to a province; one who is\n   provincial."
            },
            {
                "spelling_detail_id": 110378,
                "word_type": "n.",
                "spelling_usage": "A monastic superior, who, under the general of his\n   order, has the direction of all the religious houses of the same\n   fraternity in a given district, called a province of the order."
            }
        ]
    },
    {
        "id": 71270,
        "spelling_name": "Proviso",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110401,
                "word_type": "n.",
                "spelling_usage": "An article or clause in any statute, agreement, contract,\n   grant, or other writing, by which a condition is introduced, usually\n   beginning with the word provided; a conditional stipulation that\n   affects an agreement, contract, law, grant, or the like; as, the\n   contract was impaired by its proviso."
            }
        ]
    },
    {
        "id": 71288,
        "spelling_name": "Prowess",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110434,
                "word_type": "a.",
                "spelling_usage": "Distinguished bravery; valor; especially, military bravery\n   and skill; gallantry; intrepidity; fearlessness."
            }
        ]
    },
    {
        "id": 71361,
        "spelling_name": "Psalmody",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110540,
                "word_type": "n.",
                "spelling_usage": "The act, practice, or art of singing psalms or sacred\n   songs; also, psalms collectively, or a collection of psalms."
            }
        ]
    },
    {
        "id": 71667,
        "spelling_name": "Pugnacious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 110950,
                "word_type": "a.",
                "spelling_usage": "Disposed to fight; inclined to fighting; quarrelsome;\n   fighting."
            }
        ]
    },
    {
        "id": 71791,
        "spelling_name": "Pumice",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111130,
                "word_type": "n.",
                "spelling_usage": "A very light porous volcanic scoria, usually of a gray\n   color, the pores of which are capillary and parallel, giving it a\n   fibrous structure. It is supposed to be produced by the disengagement\n   of watery vapor without liquid or plastic lava. It is much used, esp.\n   in the form of powder, for smoothing and polishing. Called also pumice\n   stone."
            }
        ]
    },
    {
        "id": 71835,
        "spelling_name": "Punctuation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111202,
                "word_type": "n.",
                "spelling_usage": "The act or art of punctuating or pointing a writing or\n   discourse; the art or mode of dividing literary composition into\n   sentences, and members of a sentence, by means of points, so as to\n   elucidate the author's meaning."
            }
        ]
    },
    {
        "id": 71852,
        "spelling_name": "Pungent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111223,
                "word_type": "v. t.",
                "spelling_usage": "Causing a sharp sensation, as of the taste, smell, or\n   feelings; pricking; biting; acrid; as, a pungent spice."
            },
            {
                "spelling_detail_id": 111224,
                "word_type": "v. t.",
                "spelling_usage": "Sharply painful; penetrating; poignant; severe;\n   caustic; stinging."
            },
            {
                "spelling_detail_id": 111225,
                "word_type": "v. t.",
                "spelling_usage": "Prickly-pointed; hard and sharp."
            }
        ]
    },
    {
        "id": 71860,
        "spelling_name": "Puniness",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111235,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being puny; littleness;\n   pettiness; feebleness."
            }
        ]
    },
    {
        "id": 71936,
        "spelling_name": "Puree",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111361,
                "word_type": "n.",
                "spelling_usage": "A dish made by boiling any article of food to a pulp and\n   rubbing it through a sieve; as, a puree of fish, or of potatoes;\n   especially, a soup the thickening of which is so treated."
            }
        ]
    },
    {
        "id": 71957,
        "spelling_name": "Purification",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111403,
                "word_type": "n.",
                "spelling_usage": "The act of purifying; the act or operation of\n   separating and removing from anything that which is impure or noxious,\n   or heterogeneous or foreign to it; as, the purification of liquors, or\n   of metals."
            },
            {
                "spelling_detail_id": 111404,
                "word_type": "n.",
                "spelling_usage": "The act or operation of cleansing ceremonially, by\n   removing any pollution or defilement."
            },
            {
                "spelling_detail_id": 111405,
                "word_type": "n.",
                "spelling_usage": "A cleansing from guilt or the pollution of sin; the\n   extinction of sinful desires, appetites, and inclinations."
            }
        ]
    },
    {
        "id": 72003,
        "spelling_name": "Purpose",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111488,
                "word_type": "n.",
                "spelling_usage": "That which a person sets before himself as an object to be\n   reached or accomplished; the end or aim to which the view is directed\n   in any plan, measure, or exertion; view; aim; design; intention; plan."
            },
            {
                "spelling_detail_id": 111489,
                "word_type": "n.",
                "spelling_usage": "Proposal to another; discourse."
            },
            {
                "spelling_detail_id": 111490,
                "word_type": "n.",
                "spelling_usage": "Instance; example."
            },
            {
                "spelling_detail_id": 111493,
                "word_type": "v. t.",
                "spelling_usage": "To set forth; to bring forward."
            },
            {
                "spelling_detail_id": 111494,
                "word_type": "v. t.",
                "spelling_usage": "To propose, as an aim, to one's self; to determine\n   upon, as some end or object to be accomplished; to intend; to design;\n   to resolve; -- often followed by an infinitive or dependent clause."
            },
            {
                "spelling_detail_id": 111495,
                "word_type": "v. i.",
                "spelling_usage": "To have a purpose or intention; to discourse."
            }
        ]
    },
    {
        "id": 72055,
        "spelling_name": "Pursuit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111567,
                "word_type": "v. t.",
                "spelling_usage": "The act of following or going after; esp., a following\n   with haste, either for sport or in hostility; chase; prosecution; as,\n   the pursuit of game; the pursuit of an enemy."
            },
            {
                "spelling_detail_id": 111568,
                "word_type": "v. t.",
                "spelling_usage": "A following with a view to reach, accomplish, or\n   obtain; endeavor to attain to or gain; as, the pursuit of knowledge;\n   the pursuit of happiness or pleasure."
            },
            {
                "spelling_detail_id": 111569,
                "word_type": "v. t.",
                "spelling_usage": "Course of business or occupation; continued employment\n   with a view to same end; as, mercantile pursuits; a literary pursuit."
            },
            {
                "spelling_detail_id": 111570,
                "word_type": "v. t.",
                "spelling_usage": "Prosecution."
            }
        ]
    },
    {
        "id": 72118,
        "spelling_name": "Putrescent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111689,
                "word_type": "a.",
                "spelling_usage": "Becoming putrid or rotten."
            },
            {
                "spelling_detail_id": 111690,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the process of putrefaction; as, a\n   putrescent smell."
            }
        ]
    },
    {
        "id": 72208,
        "spelling_name": "Pyramid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111803,
                "word_type": "n.",
                "spelling_usage": "A solid body standing on a triangular, square, or\n   polygonal base, and terminating in a point at the top; especially, a\n   structure or edifice of this shape."
            },
            {
                "spelling_detail_id": 111804,
                "word_type": "n.",
                "spelling_usage": "A solid figure contained by a plane rectilineal figure as\n   base and several triangles which have a common vertex and whose bases\n   are sides of the base."
            },
            {
                "spelling_detail_id": 111805,
                "word_type": "n.",
                "spelling_usage": "The game of pool in which the balls are placed in the form\n   of a triangle at spot."
            }
        ]
    },
    {
        "id": 72322,
        "spelling_name": "Pyrotechnics",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 111930,
                "word_type": "n.",
                "spelling_usage": "The art of making fireworks; the manufacture and use\n   of fireworks; pyrotechny."
            }
        ]
    },
    {
        "id": 72427,
        "spelling_name": "Quadriceps",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112088,
                "word_type": "n.",
                "spelling_usage": "The great extensor muscle of the knee, divided above\n   into four parts which unite in a single tendon at the knee."
            }
        ]
    },
    {
        "id": 72446,
        "spelling_name": "Quadrilateral",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112107,
                "word_type": "a.",
                "spelling_usage": "Having four sides, and consequently four angles;\n   quadrangular."
            },
            {
                "spelling_detail_id": 112108,
                "word_type": "n.",
                "spelling_usage": "A plane figure having four sides, and consequently\n   four angles; a quadrangular figure; any figure formed by four lines."
            },
            {
                "spelling_detail_id": 112109,
                "word_type": "n.",
                "spelling_usage": "An area defended by four fortresses supporting each\n   other; as, the Venetian quadrilateral, comprising Mantua, Peschiera,\n   Verona, and Legnano."
            }
        ]
    },
    {
        "id": 72450,
        "spelling_name": "Quadrillion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112115,
                "word_type": "n.",
                "spelling_usage": "According to the French notation, which is followed\n   also upon the Continent and in the United States, a unit with fifteen\n   ciphers annexed; according to the English notation, the number produced\n   by involving a million to the fourth power, or the number represented\n   by a unit with twenty-four ciphers annexed. See the Note under\n   Numeration."
            }
        ]
    },
    {
        "id": 72547,
        "spelling_name": "Quandary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112257,
                "word_type": "n.",
                "spelling_usage": "A state of difficulty or perplexity; doubt; uncertainty."
            },
            {
                "spelling_detail_id": 112258,
                "word_type": "v. t.",
                "spelling_usage": "To bring into a state of uncertainty, perplexity, or\n   difficulty."
            }
        ]
    },
    {
        "id": 72566,
        "spelling_name": "Quarantine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112285,
                "word_type": "n.",
                "spelling_usage": "A space of forty days; -- used of Lent."
            },
            {
                "spelling_detail_id": 112286,
                "word_type": "n.",
                "spelling_usage": "Specifically, the term, originally of forty days,\n   during which a ship arriving in port, and suspected of being infected a\n   malignant contagious disease, is obliged to forbear all intercourse\n   with the shore; hence, such restraint or inhibition of intercourse;\n   also, the place where infected or prohibited vessels are stationed."
            },
            {
                "spelling_detail_id": 112287,
                "word_type": "n.",
                "spelling_usage": "The period of forty days during which the widow had the\n   privilege of remaining in the mansion house of which her husband died\n   seized."
            },
            {
                "spelling_detail_id": 112290,
                "word_type": "v. t.",
                "spelling_usage": "To compel to remain at a distance, or in a given\n   place, without intercourse, when suspected of having contagious\n   disease; to put under, or in, quarantine."
            }
        ]
    },
    {
        "id": 72657,
        "spelling_name": "Quaver",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112469,
                "word_type": "v. i.",
                "spelling_usage": "To tremble; to vibrate; to shake."
            },
            {
                "spelling_detail_id": 112470,
                "word_type": "v. i.",
                "spelling_usage": "Especially, to shake the voice; to utter or form sound\n   with rapid or tremulous vibrations, as in singing; also, to trill on a\n   musical instrument"
            },
            {
                "spelling_detail_id": 112471,
                "word_type": "v. t.",
                "spelling_usage": "To utter with quavers."
            },
            {
                "spelling_detail_id": 112472,
                "word_type": "n.",
                "spelling_usage": "A shake, or rapid and tremulous vibration, of the voice, or\n   of an instrument of music."
            },
            {
                "spelling_detail_id": 112473,
                "word_type": "n.",
                "spelling_usage": "An eighth note. See Eighth."
            }
        ]
    },
    {
        "id": 72800,
        "spelling_name": "Quiddity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112706,
                "word_type": "n.",
                "spelling_usage": "The essence, nature, or distinctive peculiarity, of a\n   thing; that which answers the question, Quid est? or, What is it?"
            },
            {
                "spelling_detail_id": 112707,
                "word_type": "n.",
                "spelling_usage": "A trifling nicety; a cavil; a quibble."
            }
        ]
    },
    {
        "id": 72843,
        "spelling_name": "Quince",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112788,
                "word_type": "n.",
                "spelling_usage": "The fruit of a shrub (Cydonia vulgaris) belonging to the\n   same tribe as the apple. It somewhat resembles an apple, but differs in\n   having many seeds in each carpel. It has hard flesh of high flavor, but\n   very acid, and is largely used for marmalade, jelly, and preserves."
            },
            {
                "spelling_detail_id": 112789,
                "word_type": "n.",
                "spelling_usage": "a quince tree or shrub."
            }
        ]
    },
    {
        "id": 72921,
        "spelling_name": "Quintessential",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112876,
                "word_type": "a.",
                "spelling_usage": "Of the nature of a quintessence; purest."
            }
        ]
    },
    {
        "id": 72951,
        "spelling_name": "Quirky",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112918,
                "word_type": "a.",
                "spelling_usage": "Full of quirks; tricky; as, a quirky lawyer."
            }
        ]
    },
    {
        "id": 72986,
        "spelling_name": "Quizzical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 112982,
                "word_type": "a.",
                "spelling_usage": "Relating to quizzing: given to quizzing; of the nature\n   of a quiz; farcical; sportive."
            }
        ]
    },
    {
        "id": 73006,
        "spelling_name": "Quorum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 113013,
                "word_type": "n.",
                "spelling_usage": "Such a number of the officers or members of any body as is\n   competent by law or constitution to transact business; as, a quorum of\n   the House of Representatives; a constitutional quorum was not present."
            }
        ]
    },
    {
        "id": 73016,
        "spelling_name": "Quotidian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 113033,
                "word_type": "a.",
                "spelling_usage": "Occurring or returning daily; as, a quotidian fever."
            },
            {
                "spelling_detail_id": 113034,
                "word_type": "n.",
                "spelling_usage": "Anything returning daily; especially (Med.), an\n   intermittent fever or ague which returns every day."
            }
        ]
    },
    {
        "id": 73017,
        "spelling_name": "Quotient",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 113035,
                "word_type": "n.",
                "spelling_usage": "The number resulting from the division of one number by\n   another, and showing how often a less number is contained in a greater;\n   thus, the quotient of twelve divided by four is three."
            },
            {
                "spelling_detail_id": 113036,
                "word_type": "n.",
                "spelling_usage": "The result of any process inverse to multiplication. See\n   the Note under Multiplication."
            }
        ]
    },
    {
        "id": 73038,
        "spelling_name": "Rabbinic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 113061,
                "word_type": "a.",
                "spelling_usage": "Alt. of Rabbinical"
            },
            {
                "spelling_detail_id": 113063,
                "word_type": "n.",
                "spelling_usage": "The language or dialect of the rabbins; the later Hebrew."
            }
        ]
    },
    {
        "id": 73299,
        "spelling_name": "Rallies",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 113573,
                "word_type": "pl. ",
                "spelling_usage": "of Rally"
            }
        ]
    },
    {
        "id": 73433,
        "spelling_name": "Ransom",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 113822,
                "word_type": "n.",
                "spelling_usage": "The release of a captive, or of captured property, by\n   payment of a consideration; redemption; as, prisoners hopeless of\n   ransom."
            },
            {
                "spelling_detail_id": 113823,
                "word_type": "n.",
                "spelling_usage": "The money or price paid for the redemption of a prisoner,\n   or for goods captured by an enemy; payment for freedom from restraint,\n   penalty, or forfeit."
            },
            {
                "spelling_detail_id": 113824,
                "word_type": "n.",
                "spelling_usage": "A sum paid for the pardon of some great offense and the\n   discharge of the offender; also, a fine paid in lieu of corporal\n   punishment."
            },
            {
                "spelling_detail_id": 113827,
                "word_type": "n.",
                "spelling_usage": "To redeem from captivity, servitude, punishment, or\n   forfeit, by paying a price; to buy out of servitude or penalty; to\n   rescue; to deliver; as, to ransom prisoners from an enemy."
            },
            {
                "spelling_detail_id": 113828,
                "word_type": "n.",
                "spelling_usage": "To exact a ransom for, or a payment on."
            }
        ]
    },
    {
        "id": 73632,
        "spelling_name": "Raucous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 114183,
                "word_type": "a.",
                "spelling_usage": "Hoarse; harsh; rough; as, a raucous, thick tone."
            }
        ]
    },
    {
        "id": 73904,
        "spelling_name": "Rebuff",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 114668,
                "word_type": "n.",
                "spelling_usage": "Repercussion, or beating back; a quick and sudden\n   resistance."
            },
            {
                "spelling_detail_id": 114669,
                "word_type": "n.",
                "spelling_usage": "Sudden check; unexpected repulse; defeat; refusal;\n   repellence; rejection of solicitation."
            },
            {
                "spelling_detail_id": 114672,
                "word_type": "v. t.",
                "spelling_usage": "To beat back; to offer sudden resistance to; to check;\n   to repel or repulse violently, harshly, or uncourteously."
            }
        ]
    },
    {
        "id": 74139,
        "spelling_name": "Reconcilable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115077,
                "word_type": "a.",
                "spelling_usage": "Capable of being reconciled; as, reconcilable\n   adversaries; an act reconciable with previous acts."
            }
        ]
    },
    {
        "id": 74181,
        "spelling_name": "Record",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115135,
                "word_type": "v. t.",
                "spelling_usage": "To recall to mind; to recollect; to remember; to\n   meditate."
            },
            {
                "spelling_detail_id": 115136,
                "word_type": "v. t.",
                "spelling_usage": "To repeat; to recite; to sing or play."
            },
            {
                "spelling_detail_id": 115137,
                "word_type": "v. t.",
                "spelling_usage": "To preserve the memory of, by committing to writing, to\n   printing, to inscription, or the like; to make note of; to write or\n   enter in a book or on parchment, for the purpose of preserving\n   authentic evidence of; to register; to enroll; as, to record the\n   proceedings of a court; to record historical events."
            },
            {
                "spelling_detail_id": 115138,
                "word_type": "v. i.",
                "spelling_usage": "To reflect; to ponder."
            },
            {
                "spelling_detail_id": 115139,
                "word_type": "v. i.",
                "spelling_usage": "To sing or repeat a tune."
            },
            {
                "spelling_detail_id": 115140,
                "word_type": "v. t.",
                "spelling_usage": "A writing by which some act or event, or a number of\n   acts or events, is recorded; a register; as, a record of the acts of\n   the Hebrew kings; a record of the variations of temperature during a\n   certain time; a family record."
            },
            {
                "spelling_detail_id": 115141,
                "word_type": "v. t.",
                "spelling_usage": "An official contemporaneous writing by which the acts of\n   some public body, or public officer, are recorded; as, a record of city\n   ordinances; the records of the receiver of taxes."
            },
            {
                "spelling_detail_id": 115142,
                "word_type": "v. t.",
                "spelling_usage": "An authentic official copy of a document which has been\n   entered in a book, or deposited in the keeping of some officer\n   designated by law."
            },
            {
                "spelling_detail_id": 115143,
                "word_type": "v. t.",
                "spelling_usage": "An official contemporaneous memorandum stating the\n   proceedings of a court of justice; a judicial record."
            },
            {
                "spelling_detail_id": 115144,
                "word_type": "v. t.",
                "spelling_usage": "The various legal papers used in a case, together with\n   memoranda of the proceedings of the court; as, it is not permissible to\n   allege facts not in the record."
            },
            {
                "spelling_detail_id": 115145,
                "word_type": "v. t.",
                "spelling_usage": "Testimony; witness; attestation."
            },
            {
                "spelling_detail_id": 115146,
                "word_type": "v. t.",
                "spelling_usage": "That which serves to perpetuate a knowledge of acts or\n   events; a monument; a memorial."
            },
            {
                "spelling_detail_id": 115147,
                "word_type": "v. t.",
                "spelling_usage": "That which has been, or might be, recorded; the known\n   facts in the course, progress, or duration of anything, as in the life\n   of a public man; as, a politician with a good or a bad record."
            },
            {
                "spelling_detail_id": 115148,
                "word_type": "v. t.",
                "spelling_usage": "That which has been publicly achieved in any kind of\n   competitive sport as recorded in some authoritative manner, as the time\n   made by a winning horse in a race."
            }
        ]
    },
    {
        "id": 74222,
        "spelling_name": "Recriminatory",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115223,
                "word_type": "a.",
                "spelling_usage": "Having the quality of recrimination; retorting\n   accusation; recriminating."
            }
        ]
    },
    {
        "id": 74258,
        "spelling_name": "Rectitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115272,
                "word_type": "n.",
                "spelling_usage": "Straightness."
            },
            {
                "spelling_detail_id": 115273,
                "word_type": "n.",
                "spelling_usage": "Rightness of principle or practice; exact conformity to\n   truth, or to the rules prescribed for moral conduct, either by divine\n   or human laws; uprightness of mind; uprightness; integrity; honesty;\n   justice."
            },
            {
                "spelling_detail_id": 115274,
                "word_type": "n.",
                "spelling_usage": "Right judgment."
            }
        ]
    },
    {
        "id": 74288,
        "spelling_name": "Recuperation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115317,
                "word_type": "n..",
                "spelling_usage": "Recovery, as of anything lost, especially of the\n   health or strength."
            }
        ]
    },
    {
        "id": 74310,
        "spelling_name": "Recusancy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115347,
                "word_type": "n.",
                "spelling_usage": "The state of being recusant; nonconformity."
            }
        ]
    },
    {
        "id": 74421,
        "spelling_name": "Redolent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115505,
                "word_type": "a.",
                "spelling_usage": "Diffusing odor or fragrance; spreading sweet scent;\n   scented; odorous; smelling; -- usually followed by of."
            }
        ]
    },
    {
        "id": 74580,
        "spelling_name": "Referendum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 115788,
                "word_type": "n.",
                "spelling_usage": "A diplomatic agent's note asking for instructions from\n   his government concerning a particular matter or point."
            },
            {
                "spelling_detail_id": 115789,
                "word_type": "n.",
                "spelling_usage": "The right to approve or reject by popular vote a\n   meassure passed upon by a legislature."
            }
        ]
    },
    {
        "id": 74733,
        "spelling_name": "Regalia",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116051,
                "word_type": "n. pl.",
                "spelling_usage": "That which belongs to royalty. Specifically: (a) The\n   rights and prerogatives of a king. (b) Royal estates and revenues. (c)\n   Ensings, symbols, or paraphernalia of royalty."
            },
            {
                "spelling_detail_id": 116052,
                "word_type": "n. pl.",
                "spelling_usage": "Hence, decorations or insignia of an office or order,\n   as of Freemasons, Odd Fellows,etc."
            },
            {
                "spelling_detail_id": 116053,
                "word_type": "n. pl.",
                "spelling_usage": "Sumptuous food; delicacies."
            },
            {
                "spelling_detail_id": 116054,
                "word_type": "n.",
                "spelling_usage": "A kind of cigar of large size and superior quality; also,\n   the size in which such cigars are classed."
            }
        ]
    },
    {
        "id": 74776,
        "spelling_name": "Regicide",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116134,
                "word_type": "n.",
                "spelling_usage": "One who kills or who murders a king; specifically\n   (Eng.Hist.), one of the judges who condemned Charles I. to death."
            },
            {
                "spelling_detail_id": 116135,
                "word_type": "n.",
                "spelling_usage": "The killing or the murder of a king."
            }
        ]
    },
    {
        "id": 74795,
        "spelling_name": "Registrar",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116184,
                "word_type": "n.",
                "spelling_usage": "One who registers; a recorder; a keeper of records; as,\n   a registrar of births, deaths, and marriages. See Register, n., 3."
            }
        ]
    },
    {
        "id": 74860,
        "spelling_name": "Regurgitate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116282,
                "word_type": "v. t.",
                "spelling_usage": "To throw or pour back, as from a deep or hollow\n   place; to pour or throw back in great quantity."
            },
            {
                "spelling_detail_id": 116283,
                "word_type": "v. i.",
                "spelling_usage": "To be thrown or poured back; to rush or surge back."
            }
        ]
    },
    {
        "id": 74868,
        "spelling_name": "Rehearsal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116294,
                "word_type": "n.",
                "spelling_usage": "The act of rehearsing; recital; narration; repetition;\n   specifically, a private recital, performance, or season of practice, in\n   preparation for a public exhibition or exercise."
            }
        ]
    },
    {
        "id": 74968,
        "spelling_name": "Reiterate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116419,
                "word_type": "v. t.",
                "spelling_usage": "To repeat again and again; to say or do repeatedly;\n   sometimes, to repeat."
            },
            {
                "spelling_detail_id": 116420,
                "word_type": "a.",
                "spelling_usage": "Reiterated; repeated."
            }
        ]
    },
    {
        "id": 75002,
        "spelling_name": "Rejuvenate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116469,
                "word_type": "v. t.",
                "spelling_usage": "To render young again."
            }
        ]
    },
    {
        "id": 75212,
        "spelling_name": "Reminiscent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116817,
                "word_type": "a.",
                "spelling_usage": "Recalling to mind, or capable of recalling to mind;\n   having remembrance; reminding one of something."
            },
            {
                "spelling_detail_id": 116818,
                "word_type": "n.",
                "spelling_usage": "One who is addicted to indulging, narrating, or\n   recording reminiscences."
            }
        ]
    },
    {
        "id": 75248,
        "spelling_name": "Remonstrance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116881,
                "word_type": "n.",
                "spelling_usage": "The act of remonstrating"
            },
            {
                "spelling_detail_id": 116882,
                "word_type": "n.",
                "spelling_usage": "A pointing out; manifestation; proof; demonstration."
            },
            {
                "spelling_detail_id": 116883,
                "word_type": "n.",
                "spelling_usage": "Earnest presentation of reason in opposition to\n   something; protest; expostulation."
            },
            {
                "spelling_detail_id": 116884,
                "word_type": "n.",
                "spelling_usage": "Same as Monstrance."
            }
        ]
    },
    {
        "id": 75265,
        "spelling_name": "Remorseful",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 116908,
                "word_type": "a.",
                "spelling_usage": "Full of remorse."
            },
            {
                "spelling_detail_id": 116909,
                "word_type": "a.",
                "spelling_usage": "Compassionate; feeling tenderly."
            },
            {
                "spelling_detail_id": 116910,
                "word_type": "a.",
                "spelling_usage": "Exciting pity; pitiable."
            }
        ]
    },
    {
        "id": 75347,
        "spelling_name": "Rennet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117068,
                "word_type": "n.",
                "spelling_usage": "A name of many different kinds of apples. Cf. Reinette."
            },
            {
                "spelling_detail_id": 117069,
                "word_type": "v.",
                "spelling_usage": "The inner, or mucous, membrane of the fourth stomach of the\n   calf, or other young ruminant; also, an infusion or preparation of it,\n   used for coagulating milk."
            }
        ]
    },
    {
        "id": 75511,
        "spelling_name": "Replete",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117328,
                "word_type": "a.",
                "spelling_usage": "Filled again; completely filled; full; charged; abounding."
            },
            {
                "spelling_detail_id": 117329,
                "word_type": "v. t.",
                "spelling_usage": "To fill completely, or to satiety."
            }
        ]
    },
    {
        "id": 75556,
        "spelling_name": "Repository",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117420,
                "word_type": "n.",
                "spelling_usage": "A place where things are or may be reposited, or laid\n   up, for safety or preservation; a depository."
            }
        ]
    },
    {
        "id": 75591,
        "spelling_name": "Reprieve",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117493,
                "word_type": "v. t.",
                "spelling_usage": "To delay the punishment of; to suspend the execution\n   of sentence on; to give a respite to; to respite; as, to reprieve a\n   criminal for thirty days."
            },
            {
                "spelling_detail_id": 117494,
                "word_type": "v. t.",
                "spelling_usage": "To relieve for a time, or temporarily."
            },
            {
                "spelling_detail_id": 117495,
                "word_type": "n.",
                "spelling_usage": "A temporary suspension of the execution of a sentence,\n   especially of a sentence of death."
            },
            {
                "spelling_detail_id": 117496,
                "word_type": "n.",
                "spelling_usage": "Interval of ease or relief; respite."
            }
        ]
    },
    {
        "id": 75601,
        "spelling_name": "Reprisal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117508,
                "word_type": "n.",
                "spelling_usage": "The act of taking from an enemy by way of reteliation or\n   indemnity."
            },
            {
                "spelling_detail_id": 117509,
                "word_type": "n.",
                "spelling_usage": "Anything taken from an enemy in retaliation."
            },
            {
                "spelling_detail_id": 117510,
                "word_type": "n.",
                "spelling_usage": "The act of retorting on an enemy by inflicting suffering\n   or death on a prisoner taken from him, in retaliation for an act of\n   inhumanity."
            },
            {
                "spelling_detail_id": 117511,
                "word_type": "n.",
                "spelling_usage": "Any act of retaliation."
            }
        ]
    },
    {
        "id": 75659,
        "spelling_name": "Repudiate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117614,
                "word_type": "v. t.",
                "spelling_usage": "To cast off; to disavow; to have nothing to do with;\n   to renounce; to reject."
            },
            {
                "spelling_detail_id": 117615,
                "word_type": "v. t.",
                "spelling_usage": "To divorce, put away, or discard, as a wife, or a\n   woman one has promised to marry."
            },
            {
                "spelling_detail_id": 117616,
                "word_type": "v. t.",
                "spelling_usage": "To refuse to acknowledge or to pay; to disclaim; as,\n   the State has repudiated its debts."
            }
        ]
    },
    {
        "id": 75698,
        "spelling_name": "Requiem",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117670,
                "word_type": "n.",
                "spelling_usage": "A mass said or sung for the repose of a departed soul."
            },
            {
                "spelling_detail_id": 117671,
                "word_type": "n.",
                "spelling_usage": "Any grand musical composition, performed in honor of a\n   deceased person."
            },
            {
                "spelling_detail_id": 117672,
                "word_type": "n.",
                "spelling_usage": "Rest; quiet; peace."
            }
        ]
    },
    {
        "id": 75708,
        "spelling_name": "Requisition",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117686,
                "word_type": "n.",
                "spelling_usage": "The act of requiring, as of right; a demand or\n   application made as by authority."
            },
            {
                "spelling_detail_id": 117687,
                "word_type": "n.",
                "spelling_usage": "A formal demand made by one state or government upon\n   another for the surrender or extradition of a fugitive from justice."
            },
            {
                "spelling_detail_id": 117688,
                "word_type": "n.",
                "spelling_usage": "A notarial demand of a debt."
            },
            {
                "spelling_detail_id": 117689,
                "word_type": "n.",
                "spelling_usage": "A demand by the invader upon the people of an invaded\n   country for supplies, as of provision, forage, transportation, etc."
            },
            {
                "spelling_detail_id": 117690,
                "word_type": "n.",
                "spelling_usage": "A formal application by one officer to another for\n   things needed in the public service; as, a requisition for clothing,\n   troops, or money."
            },
            {
                "spelling_detail_id": 117691,
                "word_type": "n.",
                "spelling_usage": "That which is required by authority; especially, a\n   quota of supplies or necessaries."
            },
            {
                "spelling_detail_id": 117692,
                "word_type": "n.",
                "spelling_usage": "A written or normal call; an invitation; a summons;\n   as, a reqisition for a public meeting."
            },
            {
                "spelling_detail_id": 117693,
                "word_type": "v. t.",
                "spelling_usage": "To make a reqisition on or for; as, to requisition\n   a district for forage; to requisition troops."
            },
            {
                "spelling_detail_id": 117694,
                "word_type": "v. t.",
                "spelling_usage": "To present a requisition to; to summon request; as,\n   to requisition a person to be a candidate."
            }
        ]
    },
    {
        "id": 75827,
        "spelling_name": "Residue",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117898,
                "word_type": "n.",
                "spelling_usage": "That which remains after a part is taken, separated,\n   removed, or designated; remnant; remainder."
            },
            {
                "spelling_detail_id": 117899,
                "word_type": "n.",
                "spelling_usage": "That part of a testeator's estate wwhich is not disposed\n   of in his will by particular and special legacies and devises, and\n   which remains after payment of debts and legacies."
            },
            {
                "spelling_detail_id": 117900,
                "word_type": "n.",
                "spelling_usage": "That which remains of a molecule after the removal of a\n   portion of its constituents; hence, an atom or group regarded as a\n   portion of a molecule; -- used as nearly equivalent to radical, but in\n   a more general sense."
            },
            {
                "spelling_detail_id": 117901,
                "word_type": "n.",
                "spelling_usage": "Any positive or negative number that differs from a given\n   number by a multiple of a given modulus; thus, if 7 is the modulus, and\n   9 the given number, the numbers -5, 2, 16, 23, etc., are residues."
            }
        ]
    },
    {
        "id": 75841,
        "spelling_name": "Resilience",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 117922,
                "word_type": "n.",
                "spelling_usage": "Alt. of Resiliency"
            }
        ]
    },
    {
        "id": 75943,
        "spelling_name": "Resplendence",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118121,
                "word_type": "n.",
                "spelling_usage": "Alt. of Resplendency"
            }
        ]
    },
    {
        "id": 76042,
        "spelling_name": "Resume",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118316,
                "word_type": "n.",
                "spelling_usage": "A summing up; a condensed statement; an abridgment or brief\n   recapitulation."
            },
            {
                "spelling_detail_id": 118319,
                "word_type": "v. t.",
                "spelling_usage": "To take back."
            },
            {
                "spelling_detail_id": 118320,
                "word_type": "v. t.",
                "spelling_usage": "To enter upon, or take up again."
            },
            {
                "spelling_detail_id": 118321,
                "word_type": "v. t.",
                "spelling_usage": "To begin again; to recommence, as something which has\n   been interrupted; as, to resume an argument or discourse."
            }
        ]
    },
    {
        "id": 76063,
        "spelling_name": "Resuscitate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118347,
                "word_type": "a.",
                "spelling_usage": "Restored to life."
            },
            {
                "spelling_detail_id": 118350,
                "word_type": "v. t.",
                "spelling_usage": "To revivify; to revive; especially, to recover or\n   restore from apparent death; as, to resuscitate a drowned person; to\n   resuscitate withered plants."
            },
            {
                "spelling_detail_id": 118351,
                "word_type": "v. i.",
                "spelling_usage": "To come to life again; to revive."
            }
        ]
    },
    {
        "id": 76131,
        "spelling_name": "Reticulated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118453,
                "word_type": "a.",
                "spelling_usage": "Resembling network; having the form or appearance of a\n   net; netted; as, a reticulated structure."
            },
            {
                "spelling_detail_id": 118454,
                "word_type": "a.",
                "spelling_usage": "Having veins, fibers, or lines crossing like the\n   threads or fibers of a network; as, a reticulate leaf; a reticulated\n   surface; a reticulated wing of an insect."
            }
        ]
    },
    {
        "id": 76153,
        "spelling_name": "Retinol",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118481,
                "word_type": "n.",
                "spelling_usage": "A hydrocarbon oil obtained by the distillation of resin,\n   -- used in printer's ink."
            }
        ]
    },
    {
        "id": 76200,
        "spelling_name": "Retreat",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118566,
                "word_type": "n.",
                "spelling_usage": "The act of retiring or withdrawing one's self, especially\n   from what is dangerous or disagreeable."
            },
            {
                "spelling_detail_id": 118567,
                "word_type": "n.",
                "spelling_usage": "The place to which anyone retires; a place or privacy or\n   safety; a refuge; an asylum."
            },
            {
                "spelling_detail_id": 118568,
                "word_type": "n.",
                "spelling_usage": "The retiring of an army or body of men from the face of an\n   enemy, or from any ground occupied to a greater distance from the\n   enemy, or from an advanced position."
            },
            {
                "spelling_detail_id": 118569,
                "word_type": "n.",
                "spelling_usage": "The withdrawing of a ship or fleet from an enemy for the\n   purpose of avoiding an engagement or escaping after defeat."
            },
            {
                "spelling_detail_id": 118570,
                "word_type": "n.",
                "spelling_usage": "A signal given in the army or navy, by the beat of a drum\n   or the sounding of trumpet or bugle, at sunset (when the roll is\n   called), or for retiring from action."
            },
            {
                "spelling_detail_id": 118571,
                "word_type": "n.",
                "spelling_usage": "A special season of solitude and silence to engage in\n   religious exercises."
            },
            {
                "spelling_detail_id": 118572,
                "word_type": "n.",
                "spelling_usage": "A period of several days of withdrawal from society to a\n   religious house for exclusive occupation in the duties of devotion; as,\n   to appoint or observe a retreat."
            },
            {
                "spelling_detail_id": 118575,
                "word_type": "v. i.",
                "spelling_usage": "To make a retreat; to retire from any position or\n   place; to withdraw; as, the defeated army retreated from the field."
            }
        ]
    },
    {
        "id": 76243,
        "spelling_name": "Retrograde",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118634,
                "word_type": "a.",
                "spelling_usage": "Apparently moving backward, and contrary to the\n   succession of the signs, that is, from east to west, as a planet."
            },
            {
                "spelling_detail_id": 118635,
                "word_type": "a.",
                "spelling_usage": "Tending or moving backward; having a backward course;\n   contrary; as, a retrograde motion; -- opposed to progressive."
            },
            {
                "spelling_detail_id": 118636,
                "word_type": "a.",
                "spelling_usage": "Declining from a better to a worse state; as, a\n   retrograde people; retrograde ideas, morals, etc."
            },
            {
                "spelling_detail_id": 118639,
                "word_type": "v. i.",
                "spelling_usage": "To go in a retrograde direction; to move, or appear\n   to move, backward, as a planet."
            },
            {
                "spelling_detail_id": 118640,
                "word_type": "v. i.",
                "spelling_usage": "Hence, to decline from a better to a worse\n   condition, as in morals or intelligence."
            }
        ]
    },
    {
        "id": 76308,
        "spelling_name": "Revelation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118753,
                "word_type": "n.",
                "spelling_usage": "The act of revealing, disclosing, or discovering to\n   others what was before unknown to them."
            },
            {
                "spelling_detail_id": 118754,
                "word_type": "n.",
                "spelling_usage": "That which is revealed."
            },
            {
                "spelling_detail_id": 118755,
                "word_type": "n.",
                "spelling_usage": "The act of revealing divine truth."
            },
            {
                "spelling_detail_id": 118756,
                "word_type": "n.",
                "spelling_usage": "That which is revealed by God to man; esp., the Bible."
            },
            {
                "spelling_detail_id": 118757,
                "word_type": "n.",
                "spelling_usage": "Specifically, the last book of the sacred canon,\n   containing the prophecies of St. John; the Apocalypse."
            }
        ]
    },
    {
        "id": 76319,
        "spelling_name": "Revendicate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 118769,
                "word_type": "v. t.",
                "spelling_usage": "To reclaim; to demand the restoration of."
            }
        ]
    },
    {
        "id": 76475,
        "spelling_name": "Revulsive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 119057,
                "word_type": "a.",
                "spelling_usage": "Causing, or tending to, revulsion."
            },
            {
                "spelling_detail_id": 119058,
                "word_type": "n.",
                "spelling_usage": "That which causes revulsion; specifically (Med.), a\n   revulsive remedy or agent."
            }
        ]
    },
    {
        "id": 76659,
        "spelling_name": "Rhododendron",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 119279,
                "word_type": "n.",
                "spelling_usage": "A genus of shrubs or small trees, often having\n   handsome evergreen leaves, and remarkable for the beauty of their\n   flowers; rosebay."
            }
        ]
    },
    {
        "id": 76679,
        "spelling_name": "Rhombus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 119302,
                "word_type": "n.",
                "spelling_usage": "Same as Rhomb, 1."
            }
        ]
    },
    {
        "id": 76719,
        "spelling_name": "Rhythmically",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 119354,
                "word_type": "adv.",
                "spelling_usage": "In a rhythmical manner."
            }
        ]
    },
    {
        "id": 76788,
        "spelling_name": "Rictus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 119472,
                "word_type": "n.",
                "spelling_usage": "The gape of the mouth, as of birds; -- often resricted to\n   the corners of the mouth."
            }
        ]
    },
    {
        "id": 77416,
        "spelling_name": "Roseola",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 120727,
                "word_type": "n.",
                "spelling_usage": "A rose-colored efflorescence upon the skin, occurring in\n   circumscribed patches of little or no elevation and often alternately\n   fading and reviving; also, an acute specific disease which is\n   characterized by an eruption of this character; -- called also rose\n   rash."
            }
        ]
    },
    {
        "id": 77430,
        "spelling_name": "Rosin",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 120755,
                "word_type": "n.",
                "spelling_usage": "The hard, amber-colored resin left after distilling off the\n   volatile oil of turpentine; colophony."
            },
            {
                "spelling_detail_id": 120756,
                "word_type": "v. t.",
                "spelling_usage": "To rub with rosin, as musicians rub the bow of a violin."
            }
        ]
    },
    {
        "id": 77590,
        "spelling_name": "Roustabout",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 121090,
                "word_type": "n.",
                "spelling_usage": "A laborer, especially a deck hand, on a river\n   steamboat, who moves the cargo, loads and unloads wood, and the like;\n   in an opprobrious sense, a shiftless vagrant who lives by chance jobs."
            }
        ]
    },
    {
        "id": 77612,
        "spelling_name": "Rowan",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 121151,
                "word_type": "n.",
                "spelling_usage": "Rowan tree."
            }
        ]
    },
    {
        "id": 77663,
        "spelling_name": "Rubble",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 121247,
                "word_type": "n.",
                "spelling_usage": "Water-worn or rough broken stones; broken bricks, etc.,\n   used in coarse masonry, or to fill up between the facing courses of\n   walls."
            },
            {
                "spelling_detail_id": 121248,
                "word_type": "n.",
                "spelling_usage": "Rough stone as it comes from the quarry; also, a\n   quarryman's term for the upper fragmentary and decomposed portion of a\n   mass of stone; brash."
            },
            {
                "spelling_detail_id": 121249,
                "word_type": "n.",
                "spelling_usage": "A mass or stratum of fragments or rock lying under the\n   alluvium, and derived from the neighboring rock."
            },
            {
                "spelling_detail_id": 121250,
                "word_type": "n.",
                "spelling_usage": "The whole of the bran of wheat before it is sorted into\n   pollard, bran, etc."
            }
        ]
    },
    {
        "id": 77832,
        "spelling_name": "Ruminate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 121565,
                "word_type": "v. i.",
                "spelling_usage": "To chew the cud; to chew again what has been slightly\n   chewed and swallowed."
            },
            {
                "spelling_detail_id": 121566,
                "word_type": "v. i.",
                "spelling_usage": "To think again and again; to muse; to meditate; to\n   ponder; to reflect."
            },
            {
                "spelling_detail_id": 121567,
                "word_type": "v. t.",
                "spelling_usage": "To chew over again."
            },
            {
                "spelling_detail_id": 121568,
                "word_type": "v. t.",
                "spelling_usage": "To meditate or ponder over; to muse on."
            },
            {
                "spelling_detail_id": 121569,
                "word_type": "a.",
                "spelling_usage": "Alt. of Ruminated"
            }
        ]
    },
    {
        "id": 77951,
        "spelling_name": "Rustication",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 121876,
                "word_type": "n.",
                "spelling_usage": "The act of rusticating, or the state of being\n   rusticated; specifically, the punishment of a student for some offense,\n   by compelling him to leave the institution for a time."
            },
            {
                "spelling_detail_id": 121877,
                "word_type": "n.",
                "spelling_usage": "Rustic work."
            }
        ]
    },
    {
        "id": 77957,
        "spelling_name": "Rustle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 121884,
                "word_type": "v. i.",
                "spelling_usage": "To make a quick succession of small sounds, like the\n   rubbing or moving of silk cloth or dry leaves."
            },
            {
                "spelling_detail_id": 121885,
                "word_type": "v. i.",
                "spelling_usage": "To stir about energetically; to strive to succeed; to\n   bustle about."
            },
            {
                "spelling_detail_id": 121886,
                "word_type": "v. t.",
                "spelling_usage": "To cause to rustle; as, the wind rustles the leaves."
            },
            {
                "spelling_detail_id": 121887,
                "word_type": "n.",
                "spelling_usage": "A quick succession or confusion of small sounds, like those\n   made by shaking leaves or straw, by rubbing silk, or the like; a\n   rustling."
            }
        ]
    },
    {
        "id": 78123,
        "spelling_name": "Sacrament",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122116,
                "word_type": "n.",
                "spelling_usage": "The oath of allegiance taken by Roman soldiers; hence, a\n   sacred ceremony used to impress an obligation; a solemn oath-taking; an\n   oath."
            },
            {
                "spelling_detail_id": 122117,
                "word_type": "n.",
                "spelling_usage": "The pledge or token of an oath or solemn covenant; a\n   sacred thing; a mystery."
            },
            {
                "spelling_detail_id": 122118,
                "word_type": "n.",
                "spelling_usage": "One of the solemn religious ordinances enjoined by\n   Christ, the head of the Christian church, to be observed by his\n   followers; hence, specifically, the eucharist; the Lord's Supper."
            },
            {
                "spelling_detail_id": 122119,
                "word_type": "v. t.",
                "spelling_usage": "To bind by an oath."
            }
        ]
    },
    {
        "id": 78156,
        "spelling_name": "Sacrosanct",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122178,
                "word_type": "a.",
                "spelling_usage": "Sacred; inviolable."
            }
        ]
    },
    {
        "id": 78208,
        "spelling_name": "Sagacious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122294,
                "word_type": "a.",
                "spelling_usage": "Of quick sense perceptions; keen-scented; skilled in\n   following a trail."
            },
            {
                "spelling_detail_id": 122295,
                "word_type": "a.",
                "spelling_usage": "Hence, of quick intellectual perceptions; of keen\n   penetration and judgment; discerning and judicious; knowing;\n   far-sighted; shrewd; sage; wise; as, a sagacious man; a sagacious\n   remark."
            }
        ]
    },
    {
        "id": 78345,
        "spelling_name": "Salience",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122491,
                "word_type": "n.",
                "spelling_usage": "The quality or condition of being salient; a leaping; a\n   springing forward; an assaulting."
            },
            {
                "spelling_detail_id": 122492,
                "word_type": "n.",
                "spelling_usage": "The quality or state of projecting, or being projected;\n   projection; protrusion."
            }
        ]
    },
    {
        "id": 78377,
        "spelling_name": "Salivate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122539,
                "word_type": "v. t.",
                "spelling_usage": "To produce an abnormal flow of saliva in; to produce\n   salivation or ptyalism in, as by the use of mercury."
            },
            {
                "spelling_detail_id": 122540,
                "word_type": "v. i.",
                "spelling_usage": "To produce saliva, esp. in excess."
            }
        ]
    },
    {
        "id": 78466,
        "spelling_name": "Salubrious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122668,
                "word_type": "a.",
                "spelling_usage": "Favorable to health; healthful; promoting health; as,\n   salubrious air, water, or climate."
            }
        ]
    },
    {
        "id": 78550,
        "spelling_name": "Sanctimonious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122794,
                "word_type": "a.",
                "spelling_usage": "Possessing sanctimony; holy; sacred; saintly."
            },
            {
                "spelling_detail_id": 122795,
                "word_type": "a.",
                "spelling_usage": "Making a show of sanctity; affecting saintliness;\n   hypocritically devout or pious."
            }
        ]
    },
    {
        "id": 78566,
        "spelling_name": "Sandal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 122829,
                "word_type": "n.",
                "spelling_usage": "Same as Sendal."
            },
            {
                "spelling_detail_id": 122830,
                "word_type": "n.",
                "spelling_usage": "Sandalwood."
            },
            {
                "spelling_detail_id": 122831,
                "word_type": "n.",
                "spelling_usage": "A kind of shoe consisting of a sole strapped to the foot; a\n   protection for the foot, covering its lower surface, but not its upper."
            },
            {
                "spelling_detail_id": 122832,
                "word_type": "n.",
                "spelling_usage": "A kind of slipper."
            },
            {
                "spelling_detail_id": 122833,
                "word_type": "n.",
                "spelling_usage": "An overshoe with parallel openings across the instep."
            }
        ]
    },
    {
        "id": 78724,
        "spelling_name": "Sapphire",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123034,
                "word_type": "n.",
                "spelling_usage": "Native alumina or aluminium sesquioxide, Al2O3; corundum;\n   esp., the blue transparent variety of corundum, highly prized as a gem."
            },
            {
                "spelling_detail_id": 123035,
                "word_type": "n.",
                "spelling_usage": "The color of the gem; bright blue."
            },
            {
                "spelling_detail_id": 123036,
                "word_type": "n.",
                "spelling_usage": "Any humming bird of the genus Hylocharis, native of South\n   America. The throat and breast are usually bright blue."
            },
            {
                "spelling_detail_id": 123037,
                "word_type": "a.",
                "spelling_usage": "Of or resembling sapphire; sapphirine; blue."
            }
        ]
    },
    {
        "id": 78785,
        "spelling_name": "Sarcophagus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123105,
                "word_type": "n.",
                "spelling_usage": "A species of limestone used among the Greeks for\n   making coffins, which was so called because it consumed within a few\n   weeks the flesh of bodies deposited in it. It is otherwise called lapis\n   Assius, or Assian stone, and is said to have been found at Assos, a\n   city of Lycia."
            },
            {
                "spelling_detail_id": 123106,
                "word_type": "n.",
                "spelling_usage": "A coffin or chest-shaped tomb of the kind of stone\n   described above; hence, any stone coffin."
            },
            {
                "spelling_detail_id": 123107,
                "word_type": "n.",
                "spelling_usage": "A stone shaped like a sarcophagus and placed by a\n   grave as a memorial."
            }
        ]
    },
    {
        "id": 78808,
        "spelling_name": "Sardonic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123135,
                "word_type": "a.",
                "spelling_usage": "Forced; unnatural; insincere; hence, derisive, mocking,\n   malignant, or bitterly sarcastic; -- applied only to a laugh, smile, or\n   some facial semblance of gayety."
            },
            {
                "spelling_detail_id": 123136,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or resembling, a kind of linen made at\n   Colchis."
            }
        ]
    },
    {
        "id": 78844,
        "spelling_name": "Sartorial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123175,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a tailor or his work."
            },
            {
                "spelling_detail_id": 123176,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the sartorius muscle."
            }
        ]
    },
    {
        "id": 78873,
        "spelling_name": "Satchel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123209,
                "word_type": "n.",
                "spelling_usage": "A little sack or bag for carrying papers, books, or small\n   articles of wearing apparel; a hand bag."
            }
        ]
    },
    {
        "id": 79234,
        "spelling_name": "Scarab",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123856,
                "word_type": "n.",
                "spelling_usage": "Alt. of Scarabee"
            }
        ]
    },
    {
        "id": 79240,
        "spelling_name": "Scarcely",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123867,
                "word_type": "adv.",
                "spelling_usage": "With difficulty; hardly; scantly; barely; but just."
            },
            {
                "spelling_detail_id": 123868,
                "word_type": "adv.",
                "spelling_usage": "Frugally; penuriously."
            }
        ]
    },
    {
        "id": 79263,
        "spelling_name": "Scarlatina",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 123906,
                "word_type": "n.",
                "spelling_usage": "Scarlet fever."
            }
        ]
    },
    {
        "id": 79352,
        "spelling_name": "Schedule",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124032,
                "word_type": "n.",
                "spelling_usage": "A written or printed scroll or sheet of paper; a\n   document; especially, a formal list or inventory; a list or catalogue\n   annexed to a larger document, as to a will, a lease, a statute, etc."
            },
            {
                "spelling_detail_id": 124033,
                "word_type": "v. t.",
                "spelling_usage": "To form into, or place in, a schedule."
            }
        ]
    },
    {
        "id": 79426,
        "spelling_name": "Scholarship",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124123,
                "word_type": "n.",
                "spelling_usage": "The character and qualities of a scholar; attainments\n   in science or literature; erudition; learning."
            },
            {
                "spelling_detail_id": 124124,
                "word_type": "n.",
                "spelling_usage": "Literary education."
            },
            {
                "spelling_detail_id": 124125,
                "word_type": "n.",
                "spelling_usage": "Maintenance for a scholar; a foundation for the\n   support of a student."
            }
        ]
    },
    {
        "id": 79461,
        "spelling_name": "Schooner",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124183,
                "word_type": "n.",
                "spelling_usage": "Originally, a small, sharp-built vessel, with two masts\n   and fore-and-aft rig. Sometimes it carried square topsails on one or\n   both masts and was called a topsail schooner. About 1840, longer\n   vessels with three masts, fore-and-aft rigged, came into use, and since\n   that time vessels with four masts and even with six masts, so rigged,\n   are built. Schooners with more than two masts are designated\n   three-masted schooners, four-masted schooners, etc. See Illustration in\n   Appendix."
            },
            {
                "spelling_detail_id": 124184,
                "word_type": "n.",
                "spelling_usage": "A large goblet or drinking glass, -- used for lager beer\n   or ale."
            }
        ]
    },
    {
        "id": 79484,
        "spelling_name": "Sciatica",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124210,
                "word_type": "n.",
                "spelling_usage": "Neuralgia of the sciatic nerve, an affection\n   characterized by paroxysmal attacks of pain in the buttock, back of the\n   thigh, or in the leg or foot, following the course of the branches of\n   the sciatic nerve. The name is also popularly applied to various\n   painful affections of the hip and the parts adjoining it. See Ischiadic\n   passion, under Ischiadic."
            }
        ]
    },
    {
        "id": 79585,
        "spelling_name": "Sclerosis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124334,
                "word_type": "n.",
                "spelling_usage": "Induration; hardening; especially, that form of\n   induration produced in an organ by increase of its interstitial\n   connective tissue."
            },
            {
                "spelling_detail_id": 124335,
                "word_type": "n.",
                "spelling_usage": "Hardening of the cell wall by lignification."
            }
        ]
    },
    {
        "id": 79599,
        "spelling_name": "Scoff",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124356,
                "word_type": "n.",
                "spelling_usage": "Derision; ridicule; mockery; derisive or mocking expression\n   of scorn, contempt, or reproach."
            },
            {
                "spelling_detail_id": 124357,
                "word_type": "n.",
                "spelling_usage": "An object of scorn, mockery, or derision."
            },
            {
                "spelling_detail_id": 124360,
                "word_type": "n.",
                "spelling_usage": "To show insolent ridicule or mockery; to manifest contempt\n   by derisive acts or language; -- often with at."
            },
            {
                "spelling_detail_id": 124361,
                "word_type": "v. t.",
                "spelling_usage": "To treat or address with derision; to assail scornfully;\n   to mock at."
            }
        ]
    },
    {
        "id": 79698,
        "spelling_name": "Scorpion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124525,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of pulmonate arachnids of the\n   order Scorpiones, having a suctorial mouth, large claw-bearing palpi,\n   and a caudal sting."
            },
            {
                "spelling_detail_id": 124526,
                "word_type": "n.",
                "spelling_usage": "The pine or gray lizard (Sceloporus undulatus)."
            },
            {
                "spelling_detail_id": 124527,
                "word_type": "n.",
                "spelling_usage": "The scorpene."
            },
            {
                "spelling_detail_id": 124528,
                "word_type": "n.",
                "spelling_usage": "A painful scourge."
            },
            {
                "spelling_detail_id": 124529,
                "word_type": "n.",
                "spelling_usage": "A sign and constellation. See Scorpio."
            },
            {
                "spelling_detail_id": 124530,
                "word_type": "n.",
                "spelling_usage": "An ancient military engine for hurling stones and other\n   missiles."
            }
        ]
    },
    {
        "id": 79924,
        "spelling_name": "Scrutiny",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124941,
                "word_type": "n.",
                "spelling_usage": "Close examination; minute inspection; critical\n   observation."
            },
            {
                "spelling_detail_id": 124942,
                "word_type": "n.",
                "spelling_usage": "An examination of catechumens, in the last week of Lent,\n   who were to receive baptism on Easter Day."
            },
            {
                "spelling_detail_id": 124943,
                "word_type": "n.",
                "spelling_usage": "A ticket, or little paper billet, on which a vote is\n   written."
            },
            {
                "spelling_detail_id": 124944,
                "word_type": "n.",
                "spelling_usage": "An examination by a committee of the votes given at an\n   election, for the purpose of correcting the poll."
            },
            {
                "spelling_detail_id": 124945,
                "word_type": "v. t.",
                "spelling_usage": "To scrutinize."
            }
        ]
    },
    {
        "id": 79948,
        "spelling_name": "Scullery",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 124996,
                "word_type": "n.",
                "spelling_usage": "A place where dishes, kettles, and culinary utensils, are\n   cleaned and kept; also, a room attached to the kitchen, where the\n   coarse work is done; a back kitchen."
            },
            {
                "spelling_detail_id": 124997,
                "word_type": "n.",
                "spelling_usage": "Hence, refuse; filth; offal."
            }
        ]
    },
    {
        "id": 79958,
        "spelling_name": "Sculpture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 125010,
                "word_type": "n.",
                "spelling_usage": "The art of carving, cutting, or hewing wood, stone,\n   metal, etc., into statues, ornaments, etc., or into figures, as of men,\n   or other things; hence, the art of producing figures and groups,\n   whether in plastic or hard materials."
            },
            {
                "spelling_detail_id": 125011,
                "word_type": "n.",
                "spelling_usage": "Carved work modeled of, or cut upon, wood, stone, metal,\n   etc."
            },
            {
                "spelling_detail_id": 125014,
                "word_type": "v. t.",
                "spelling_usage": "To form with the chisel on, in, or from, wood, stone,\n   or metal; to carve; to engrave."
            }
        ]
    },
    {
        "id": 80043,
        "spelling_name": "Scythe",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 125153,
                "word_type": "n.",
                "spelling_usage": "An instrument for mowing grass, grain, or the like, by\n   hand, composed of a long, curving blade, with a sharp edge, made fast\n   to a long handle, called a snath, which is bent into a form convenient\n   for use."
            },
            {
                "spelling_detail_id": 125154,
                "word_type": "n.",
                "spelling_usage": "A scythe-shaped blade attached to ancient war chariots."
            },
            {
                "spelling_detail_id": 125155,
                "word_type": "v. t.",
                "spelling_usage": "To cut with a scythe; to cut off as with a scythe; to\n   mow."
            }
        ]
    },
    {
        "id": 80387,
        "spelling_name": "Secession",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 125666,
                "word_type": "n.",
                "spelling_usage": "The act of seceding; separation from fellowship or\n   association with others, as in a religious or political organization;\n   withdrawal."
            },
            {
                "spelling_detail_id": 125667,
                "word_type": "n.",
                "spelling_usage": "The withdrawal of a State from the national Union."
            }
        ]
    },
    {
        "id": 80502,
        "spelling_name": "Sedge",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 125874,
                "word_type": "n.",
                "spelling_usage": "Any plant of the genus Carex, perennial, endogenous herbs,\n   often growing in dense tufts in marshy places. They have triangular\n   jointless stems, a spiked inflorescence, and long grasslike leaves\n   which are usually rough on the margins and midrib. There are several\n   hundred species."
            },
            {
                "spelling_detail_id": 125875,
                "word_type": "n.",
                "spelling_usage": "A flock of herons."
            }
        ]
    },
    {
        "id": 80506,
        "spelling_name": "Sediment",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 125879,
                "word_type": "n.",
                "spelling_usage": "The matter which subsides to the bottom, frrom water or\n   any other liquid; settlings; lees; dregs."
            },
            {
                "spelling_detail_id": 125880,
                "word_type": "n.",
                "spelling_usage": "The material of which sedimentary rocks are formed."
            }
        ]
    },
    {
        "id": 80586,
        "spelling_name": "Seethe",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 126027,
                "word_type": "n.",
                "spelling_usage": "To decoct or prepare for food in hot liquid; to boil; as,\n   to seethe flesh."
            },
            {
                "spelling_detail_id": 126028,
                "word_type": "v. i.",
                "spelling_usage": "To be a state of ebullition or violent commotion; to be\n   hot; to boil."
            }
        ]
    },
    {
        "id": 80641,
        "spelling_name": "Seize",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 126103,
                "word_type": "v. t.",
                "spelling_usage": "To fall or rush upon suddenly and lay hold of; to gripe\n   or grasp suddenly; to reach and grasp."
            },
            {
                "spelling_detail_id": 126104,
                "word_type": "v. t.",
                "spelling_usage": "To take possession of by force."
            },
            {
                "spelling_detail_id": 126105,
                "word_type": "v. t.",
                "spelling_usage": "To invade suddenly; to take sudden hold of; to come upon\n   suddenly; as, a fever seizes a patient."
            },
            {
                "spelling_detail_id": 126106,
                "word_type": "v. t.",
                "spelling_usage": "To take possession of by virtue of a warrant or other\n   legal authority; as, the sheriff seized the debtor's goods."
            },
            {
                "spelling_detail_id": 126107,
                "word_type": "v. t.",
                "spelling_usage": "To fasten; to fix."
            },
            {
                "spelling_detail_id": 126108,
                "word_type": "v. t.",
                "spelling_usage": "To grap with the mind; to comprehend fully and\n   distinctly; as, to seize an idea."
            },
            {
                "spelling_detail_id": 126109,
                "word_type": "v. t.",
                "spelling_usage": "To bind or fasten together with a lashing of small stuff,\n   as yarn or marline; as, to seize ropes."
            }
        ]
    },
    {
        "id": 80961,
        "spelling_name": "Semester",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 126481,
                "word_type": "n.",
                "spelling_usage": "A period of six months; especially, a term in a college\n   or uneversity which divides the year into two terms."
            }
        ]
    },
    {
        "id": 80997,
        "spelling_name": "Semicolon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 126519,
                "word_type": "n.",
                "spelling_usage": "The punctuation mark [;] indicating a separation between\n   parts or members of a sentence more distinct than that marked by a\n   comma."
            }
        ]
    },
    {
        "id": 81350,
        "spelling_name": "Septennial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127006,
                "word_type": "a.",
                "spelling_usage": "Lasting or continuing seven years; as, septennial\n   parliaments."
            },
            {
                "spelling_detail_id": 127007,
                "word_type": "a.",
                "spelling_usage": "Happening or returning once in every seven years; as,\n   septennial elections in England."
            }
        ]
    },
    {
        "id": 81412,
        "spelling_name": "Sequential",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127106,
                "word_type": "a.",
                "spelling_usage": "Succeeding or following in order."
            }
        ]
    },
    {
        "id": 81446,
        "spelling_name": "Serenade",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127160,
                "word_type": "n.",
                "spelling_usage": "Music sung or performed in the open air at nights; --\n   usually applied to musical entertainments given in the open air at\n   night, especially by gentlemen, in a spirit of gallantry, under the\n   windows of ladies."
            },
            {
                "spelling_detail_id": 127161,
                "word_type": "n.",
                "spelling_usage": "A piece of music suitable to be performed at such times."
            },
            {
                "spelling_detail_id": 127164,
                "word_type": "v. t.",
                "spelling_usage": "To entertain with a serenade."
            },
            {
                "spelling_detail_id": 127165,
                "word_type": "v. i.",
                "spelling_usage": "To perform a serenade."
            }
        ]
    },
    {
        "id": 81488,
        "spelling_name": "Serious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127222,
                "word_type": "a.",
                "spelling_usage": "Grave in manner or disposition; earnest; thoughtful;\n   solemn; not light, gay, or volatile."
            },
            {
                "spelling_detail_id": 127223,
                "word_type": "a.",
                "spelling_usage": "Really intending what is said; being in earnest; not\n   jesting or deceiving."
            },
            {
                "spelling_detail_id": 127224,
                "word_type": "a.",
                "spelling_usage": "Important; weighty; not trifling; grave."
            },
            {
                "spelling_detail_id": 127225,
                "word_type": "a.",
                "spelling_usage": "Hence, giving rise to apprehension; attended with danger;\n   as, a serious injury."
            }
        ]
    },
    {
        "id": 81546,
        "spelling_name": "Serrated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127305,
                "word_type": "a.",
                "spelling_usage": "Notched on the edge, like a saw."
            },
            {
                "spelling_detail_id": 127306,
                "word_type": "a.",
                "spelling_usage": "Beset with teeth pointing forwards or upwards; as,\n   serrate leaves."
            }
        ]
    },
    {
        "id": 81590,
        "spelling_name": "Servitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127402,
                "word_type": "n.",
                "spelling_usage": "The state of voluntary or compulsory subjection to a\n   master; the condition of being bound to service; the condition of a\n   slave; slavery; bondage; hence, a state of slavish dependence."
            },
            {
                "spelling_detail_id": 127403,
                "word_type": "n.",
                "spelling_usage": "Servants, collectively."
            },
            {
                "spelling_detail_id": 127404,
                "word_type": "n.",
                "spelling_usage": "A right whereby one thing is subject to another thing or\n   person for use or convenience, contrary to the common right."
            }
        ]
    },
    {
        "id": 81594,
        "spelling_name": "Sesame",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127408,
                "word_type": "n.",
                "spelling_usage": "Either of two annual herbaceous plants of the genus Sesamum\n   (S. Indicum, and S. orientale), from the seeds of which an oil is\n   expressed; also, the small obovate, flattish seeds of these plants,\n   sometimes used as food. See Benne."
            }
        ]
    },
    {
        "id": 81621,
        "spelling_name": "Sessile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127440,
                "word_type": "a.",
                "spelling_usage": "Attached without any sensible projecting support."
            },
            {
                "spelling_detail_id": 127441,
                "word_type": "a.",
                "spelling_usage": "Resting directly upon the main stem or branch, without a\n   petiole or footstalk; as, a sessile leaf or blossom."
            },
            {
                "spelling_detail_id": 127442,
                "word_type": "a.",
                "spelling_usage": "Permanently attached; -- said of the gonophores of certain\n   hydroids which never became detached."
            }
        ]
    },
    {
        "id": 81656,
        "spelling_name": "Seton",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127547,
                "word_type": "n.",
                "spelling_usage": "A few silk threads or horsehairs, or a strip of linen or the\n   like, introduced beneath the skin by a knife or needle, so as to form\n   an issue; also, the issue so formed."
            }
        ]
    },
    {
        "id": 81661,
        "spelling_name": "Settee",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127553,
                "word_type": "n.",
                "spelling_usage": "A long seat with a back, -- made to accommodate several\n   persons at once."
            },
            {
                "spelling_detail_id": 127554,
                "word_type": "n.",
                "spelling_usage": "A vessel with a very long, sharp prow, carrying two or\n   three masts with lateen sails, -- used in the Mediterranean."
            }
        ]
    },
    {
        "id": 81699,
        "spelling_name": "Severance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127675,
                "word_type": "n.",
                "spelling_usage": "The act of severing, or the state of being severed;\n   partition; separation."
            },
            {
                "spelling_detail_id": 127676,
                "word_type": "n.",
                "spelling_usage": "The act of dividing; the singling or severing of two or\n   more that join, or are joined, in one writ; the putting in several or\n   separate pleas or answers by two or more disjointly; the destruction of\n   the unity of interest in a joint estate."
            }
        ]
    },
    {
        "id": 81710,
        "spelling_name": "Sewage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 127703,
                "word_type": "n.",
                "spelling_usage": "The contents of a sewer or drain; refuse liquids or matter\n   carried off by sewers"
            },
            {
                "spelling_detail_id": 127704,
                "word_type": "n.",
                "spelling_usage": "Sewerage, 2."
            }
        ]
    },
    {
        "id": 82017,
        "spelling_name": "Sheathed",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 128330,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Sheathe"
            },
            {
                "spelling_detail_id": 128336,
                "word_type": "a.",
                "spelling_usage": "Povided with, or inclosed in, sheath."
            },
            {
                "spelling_detail_id": 128337,
                "word_type": "a.",
                "spelling_usage": "Invested by a sheath, or cylindrical membranaceous tube,\n   which is the base of the leaf, as the stalk or culm in grasses;\n   vaginate."
            }
        ]
    },
    {
        "id": 82693,
        "spelling_name": "Sieve",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 129628,
                "word_type": "n.",
                "spelling_usage": "A utensil for separating the finer and coarser parts of a\n   pulverized or granulated substance from each other. It consist of a\n   vessel, usually shallow, with the bottom perforated, or made of hair,\n   wire, or the like, woven in meshes."
            },
            {
                "spelling_detail_id": 129629,
                "word_type": "n.",
                "spelling_usage": "A kind of coarse basket."
            }
        ]
    },
    {
        "id": 82807,
        "spelling_name": "Silhouette",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 129849,
                "word_type": "n.",
                "spelling_usage": "A representation of the outlines of an object filled in\n   with a black color; a profile portrait in black, such as a shadow\n   appears to be."
            },
            {
                "spelling_detail_id": 129850,
                "word_type": "v. t.",
                "spelling_usage": "To represent by a silhouette; to project upon a\n   background, so as to be like a silhouette."
            }
        ]
    },
    {
        "id": 82979,
        "spelling_name": "Simultaneity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 130109,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being simultaneous;\n   simultaneousness."
            }
        ]
    },
    {
        "id": 83060,
        "spelling_name": "Sinister",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 130277,
                "word_type": "a.",
                "spelling_usage": "On the left hand, or the side of the left hand; left; --\n   opposed to dexter, or right."
            },
            {
                "spelling_detail_id": 130278,
                "word_type": "a.",
                "spelling_usage": "Unlucky; inauspicious; disastrous; injurious; evil; --\n   the left being usually regarded as the unlucky side; as, sinister\n   influences."
            },
            {
                "spelling_detail_id": 130279,
                "word_type": "a.",
                "spelling_usage": "Wrong, as springing from indirection or obliquity;\n   perverse; dishonest; corrupt; as, sinister aims."
            },
            {
                "spelling_detail_id": 130280,
                "word_type": "a.",
                "spelling_usage": "Indicative of lurking evil or harm; boding covert danger;\n   as, a sinister countenance."
            }
        ]
    },
    {
        "id": 83553,
        "spelling_name": "Slate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 131171,
                "word_type": "v. t.",
                "spelling_usage": "An argillaceous rock which readily splits into thin\n   plates; argillite; argillaceous schist."
            },
            {
                "spelling_detail_id": 131172,
                "word_type": "v. t.",
                "spelling_usage": "Any rock or stone having a slaty structure."
            },
            {
                "spelling_detail_id": 131173,
                "word_type": "v. t.",
                "spelling_usage": "A prepared piece of such stone."
            },
            {
                "spelling_detail_id": 131174,
                "word_type": "v. t.",
                "spelling_usage": "A thin, flat piece, for roofing or covering houses, etc."
            },
            {
                "spelling_detail_id": 131175,
                "word_type": "v. t.",
                "spelling_usage": "A tablet for writing upon."
            },
            {
                "spelling_detail_id": 131176,
                "word_type": "v. t.",
                "spelling_usage": "An artificial material, resembling slate, and used for\n   the above purposes."
            },
            {
                "spelling_detail_id": 131177,
                "word_type": "v. t.",
                "spelling_usage": "A thin plate of any material; a flake."
            },
            {
                "spelling_detail_id": 131178,
                "word_type": "v. t.",
                "spelling_usage": "A list of candidates, prepared for nomination or for\n   election; a list of candidates, or a programme of action, devised\n   beforehand."
            },
            {
                "spelling_detail_id": 131181,
                "word_type": "v. t.",
                "spelling_usage": "To cover with slate, or with a substance resembling\n   slate; as, to slate a roof; to slate a globe."
            },
            {
                "spelling_detail_id": 131182,
                "word_type": "v. t.",
                "spelling_usage": "To register (as on a slate and subject to revision), for\n   an appointment."
            },
            {
                "spelling_detail_id": 131183,
                "word_type": "v. t.",
                "spelling_usage": "To set a dog upon; to bait; to slat. See 2d Slat, 3."
            }
        ]
    },
    {
        "id": 83812,
        "spelling_name": "Slovenly",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 131724,
                "word_type": "adv.",
                "spelling_usage": "a slovenly manner."
            }
        ]
    },
    {
        "id": 83989,
        "spelling_name": "Smithereens",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 132084,
                "word_type": "n. pl.",
                "spelling_usage": "Fragments; atoms; smithers."
            }
        ]
    },
    {
        "id": 84240,
        "spelling_name": "Snout",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 132574,
                "word_type": "n.",
                "spelling_usage": "The long, projecting nose of a beast, as of swine."
            },
            {
                "spelling_detail_id": 132575,
                "word_type": "n.",
                "spelling_usage": "The nose of a man; -- in contempt."
            },
            {
                "spelling_detail_id": 132576,
                "word_type": "n.",
                "spelling_usage": "The nozzle of a pipe, hose, etc."
            },
            {
                "spelling_detail_id": 132577,
                "word_type": "n.",
                "spelling_usage": "The anterior prolongation of the head of a gastropod; --\n   called also rostrum."
            },
            {
                "spelling_detail_id": 132578,
                "word_type": "n.",
                "spelling_usage": "The anterior prolongation of the head of weevils and allied\n   beetles."
            },
            {
                "spelling_detail_id": 132579,
                "word_type": "v. t.",
                "spelling_usage": "To furnish with a nozzle or point."
            }
        ]
    },
    {
        "id": 84340,
        "spelling_name": "Sobriety",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 132756,
                "word_type": "n.",
                "spelling_usage": "Habitual soberness or temperance as to the use of\n   spirituous liquors; as, a man of sobriety."
            },
            {
                "spelling_detail_id": 132757,
                "word_type": "n.",
                "spelling_usage": "Habitual freedom from enthusiasm, inordinate passion, or\n   overheated imagination; calmness; coolness; gravity; seriousness; as,\n   the sobriety of riper years."
            }
        ]
    },
    {
        "id": 84490,
        "spelling_name": "Solder",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133000,
                "word_type": "n.",
                "spelling_usage": "A metal or metallic alloy used when melted for uniting\n   adjacent metallic edges or surfaces; a metallic cement."
            },
            {
                "spelling_detail_id": 133001,
                "word_type": "n.",
                "spelling_usage": "anything which unites or cements."
            },
            {
                "spelling_detail_id": 133004,
                "word_type": "n.",
                "spelling_usage": "To unite (metallic surfaces or edges) by the intervention\n   of a more fusible metal or metallic alloy applied when melted; to join\n   by means of metallic cement."
            },
            {
                "spelling_detail_id": 133005,
                "word_type": "n.",
                "spelling_usage": "To mend; to patch up."
            }
        ]
    },
    {
        "id": 84550,
        "spelling_name": "Solicit",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133109,
                "word_type": "v. t.",
                "spelling_usage": "To ask from with earnestness; to make petition to; to\n   apply to for obtaining something; as, to solicit person for alms."
            },
            {
                "spelling_detail_id": 133110,
                "word_type": "v. t.",
                "spelling_usage": "To endeavor to obtain; to seek; to plead for; as, to\n   solicit an office; to solicit a favor."
            },
            {
                "spelling_detail_id": 133111,
                "word_type": "v. t.",
                "spelling_usage": "To awake or excite to action; to rouse desire in; to\n   summon; to appeal to; to invite."
            },
            {
                "spelling_detail_id": 133112,
                "word_type": "v. t.",
                "spelling_usage": "To urge the claims of; to plead; to act as solicitor\n   for or with reference to."
            },
            {
                "spelling_detail_id": 133113,
                "word_type": "v. t.",
                "spelling_usage": "To disturb; to disquiet; -- a Latinism rarely used."
            }
        ]
    },
    {
        "id": 84596,
        "spelling_name": "Solitaire",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133177,
                "word_type": "n.",
                "spelling_usage": "A person who lives in solitude; a recluse; a hermit."
            },
            {
                "spelling_detail_id": 133178,
                "word_type": "n.",
                "spelling_usage": "A single diamond in a setting; also, sometimes, a\n   precious stone of any kind set alone."
            },
            {
                "spelling_detail_id": 133179,
                "word_type": "n.",
                "spelling_usage": "A game which one person can play alone; -- applied to\n   many games of cards, etc.; also, to a game played on a board with pegs\n   or balls, in which the object is, beginning with all the places filled\n   except one, to remove all but one of the pieces by \"jumping,\" as in\n   draughts."
            },
            {
                "spelling_detail_id": 133180,
                "word_type": "n.",
                "spelling_usage": "A large extinct bird (Pezophaps solitaria) which\n   formerly inhabited the islands of Mauritius and Rodrigeuz. It was\n   larger and taller than the wild turkey. Its wings were too small for\n   flight. Called also solitary."
            },
            {
                "spelling_detail_id": 133181,
                "word_type": "n.",
                "spelling_usage": "Any species of American thrushlike birds of the genus\n   Myadestes. They are noted their sweet songs and retiring habits. Called\n   also fly-catching thrush. A West Indian species (Myadestes sibilans) is\n   called the invisible bird."
            }
        ]
    },
    {
        "id": 84774,
        "spelling_name": "Soothsayer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133465,
                "word_type": "n.",
                "spelling_usage": "One who foretells events by the art of soothsaying; a\n   prognosticator."
            },
            {
                "spelling_detail_id": 133466,
                "word_type": "n.",
                "spelling_usage": "A mantis."
            }
        ]
    },
    {
        "id": 84794,
        "spelling_name": "Sophisticated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133497,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Sophisticate"
            },
            {
                "spelling_detail_id": 133501,
                "word_type": "a.",
                "spelling_usage": "Adulterated; not pure; not genuine."
            }
        ]
    },
    {
        "id": 84800,
        "spelling_name": "Sophomoric",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133507,
                "word_type": "a.",
                "spelling_usage": "Alt. of Sophomorical"
            }
        ]
    },
    {
        "id": 84809,
        "spelling_name": "Soporific",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133517,
                "word_type": "a.",
                "spelling_usage": "Causing sleep; tending to cause sleep; soporiferous; as,\n   the soporific virtues of opium."
            },
            {
                "spelling_detail_id": 133518,
                "word_type": "n.",
                "spelling_usage": "A medicine, drug, plant, or other agent that has the\n   quality of inducing sleep; a narcotic."
            }
        ]
    },
    {
        "id": 84819,
        "spelling_name": "Soprano",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133527,
                "word_type": "n.",
                "spelling_usage": "The treble; the highest vocal register; the highest kind\n   of female or boy's voice; the upper part in harmony for mixed voices."
            },
            {
                "spelling_detail_id": 133528,
                "word_type": "n.",
                "spelling_usage": "A singer, commonly a woman, with a treble voice."
            }
        ]
    },
    {
        "id": 84878,
        "spelling_name": "Sorrel",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 133605,
                "word_type": "a.",
                "spelling_usage": "Of a yellowish or redish brown color; as, a sorrel horse."
            },
            {
                "spelling_detail_id": 133606,
                "word_type": "n.",
                "spelling_usage": "A yellowish or redish brown color."
            },
            {
                "spelling_detail_id": 133607,
                "word_type": "n.",
                "spelling_usage": "One of various plants having a sour juice; especially, a\n   plant of the genus Rumex, as Rumex Acetosa, Rumex Acetosella, etc."
            }
        ]
    },
    {
        "id": 85197,
        "spelling_name": "Spasmodic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134203,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to spasm; consisting in spasm; occuring\n   in, or characterized by, spasms; as, a spasmodic asthma."
            },
            {
                "spelling_detail_id": 134204,
                "word_type": "a.",
                "spelling_usage": "Soon relaxed or exhausted; convulsive; intermittent; as,\n   spasmodic zeal or industry."
            },
            {
                "spelling_detail_id": 134205,
                "word_type": "n.",
                "spelling_usage": "A medicine for spasm."
            }
        ]
    },
    {
        "id": 85232,
        "spelling_name": "Spatula",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134254,
                "word_type": "n.",
                "spelling_usage": "An implement shaped like a knife, flat, thin, and somewhat\n   flexible, used for spreading paints, fine plasters, drugs in\n   compounding prescriptions, etc. Cf. Palette knife, under Palette."
            }
        ]
    },
    {
        "id": 85291,
        "spelling_name": "Specimen",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134388,
                "word_type": "n.",
                "spelling_usage": "A part, or small portion, of anything, or one of a number\n   of things, intended to exhibit the kind and quality of the whole, or of\n   what is not exhibited; a sample; as, a specimen of a man's handwriting;\n   a specimen of painting; aspecimen of one's art."
            }
        ]
    },
    {
        "id": 85294,
        "spelling_name": "Specious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134392,
                "word_type": "a.",
                "spelling_usage": "Presenting a pleasing appearance; pleasing in form or\n   look; showy."
            },
            {
                "spelling_detail_id": 134393,
                "word_type": "a.",
                "spelling_usage": "Apparently right; superficially fair, just, or correct,\n   but not so in reality; appearing well at first view; plausible; as,\n   specious reasoning; a specious argument."
            }
        ]
    },
    {
        "id": 85325,
        "spelling_name": "Spectrometer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134439,
                "word_type": "n.",
                "spelling_usage": "A spectroscope fitted for measurements of the\n   luminious spectra observed with it."
            }
        ]
    },
    {
        "id": 85335,
        "spelling_name": "Speculate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134456,
                "word_type": "v. i.",
                "spelling_usage": "To consider by turning a subject in the mind, and\n   viewing it in its different aspects and relations; to meditate; to\n   contemplate; to theorize; as, to speculate on questions in religion; to\n   speculate on political events."
            },
            {
                "spelling_detail_id": 134457,
                "word_type": "v. i.",
                "spelling_usage": "To view subjects from certain premises given or\n   assumed, and infer conclusions respecting them a priori."
            },
            {
                "spelling_detail_id": 134458,
                "word_type": "v. i.",
                "spelling_usage": "To purchase with the expectation of a contingent\n   advance in value, and a consequent sale at a profit; -- often, in a\n   somewhat depreciative sense, of unsound or hazardous transactions; as,\n   to speculate in coffee, in sugar, or in bank stock."
            },
            {
                "spelling_detail_id": 134459,
                "word_type": "v. t.",
                "spelling_usage": "To consider attentively; as, to speculate the nature\n   of a thing."
            }
        ]
    },
    {
        "id": 85666,
        "spelling_name": "Spinosity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134998,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being spiny or thorny;\n   spininess."
            }
        ]
    },
    {
        "id": 85680,
        "spelling_name": "Spiracle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135019,
                "word_type": "n.",
                "spelling_usage": "The nostril, or one of the nostrils, of whales,\n   porpoises, and allied animals."
            },
            {
                "spelling_detail_id": 135020,
                "word_type": "n.",
                "spelling_usage": "One of the external openings communicating with the air\n   tubes or tracheae of insects, myriapods, and arachnids. They are\n   variable in number, and are usually situated on the sides of the thorax\n   and abdomen, a pair to a segment. These openings are usually\n   elliptical, and capable of being closed. See Illust. under Coleoptera."
            },
            {
                "spelling_detail_id": 135021,
                "word_type": "n.",
                "spelling_usage": "A tubular orifice communicating with the gill cavity of\n   certain ganoid and all elasmobranch fishes. It is the modified first\n   gill cleft."
            },
            {
                "spelling_detail_id": 135022,
                "word_type": "n.",
                "spelling_usage": "Any small aperture or vent for air or other fluid."
            }
        ]
    },
    {
        "id": 85818,
        "spelling_name": "Splenetic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135263,
                "word_type": "a.",
                "spelling_usage": "Affected with spleen; malicious; spiteful; peevish;\n   fretful."
            },
            {
                "spelling_detail_id": 135264,
                "word_type": "n.",
                "spelling_usage": "A person affected with spleen."
            }
        ]
    },
    {
        "id": 85860,
        "spelling_name": "Splutter",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135347,
                "word_type": "v. i.",
                "spelling_usage": "To speak hastily and confusedly; to sputter."
            },
            {
                "spelling_detail_id": 135348,
                "word_type": "n.",
                "spelling_usage": "A confused noise, as of hasty speaking."
            }
        ]
    },
    {
        "id": 85882,
        "spelling_name": "Spoken",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 134286,
                "word_type": "p. p.",
                "spelling_usage": "of Speak"
            },
            {
                "spelling_detail_id": 135385,
                "word_type": "a.",
                "spelling_usage": "Uttered in speech; delivered by word of mouth; oral; as, a\n   spoken narrative; the spoken word."
            },
            {
                "spelling_detail_id": 135386,
                "word_type": "a.",
                "spelling_usage": "Characterized by a certain manner or style in speaking; --\n   often in composition; as, a pleasant-spoken man."
            }
        ]
    },
    {
        "id": 85931,
        "spelling_name": "Spontaneity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135464,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being spontaneous, or acting\n   from native feeling, proneness, or temperament, without constraint or\n   external force."
            },
            {
                "spelling_detail_id": 135465,
                "word_type": "n.",
                "spelling_usage": "The tendency to undergo change, characteristic of both\n   animal and vegetable organisms, and not restrained or cheked by the\n   environment."
            },
            {
                "spelling_detail_id": 135466,
                "word_type": "n.",
                "spelling_usage": "The tendency to activity of muscular tissue, including\n   the voluntary muscles, when in a state of healthful vigor and\n   refreshment."
            }
        ]
    },
    {
        "id": 85959,
        "spelling_name": "Sporadically",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135509,
                "word_type": "adv.",
                "spelling_usage": "In a sporadic manner."
            }
        ]
    },
    {
        "id": 86139,
        "spelling_name": "Spurious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135887,
                "word_type": "a.",
                "spelling_usage": "Not proceeding from the true source, or from the source\n   pretended; not genuine; false; adulterate."
            },
            {
                "spelling_detail_id": 135888,
                "word_type": "a.",
                "spelling_usage": "Not legitimate; bastard; as, spurious issue."
            }
        ]
    },
    {
        "id": 86194,
        "spelling_name": "Squalid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 135981,
                "word_type": "a.",
                "spelling_usage": "Dirty through neglect; foul; filthy; extremely dirty."
            }
        ]
    },
    {
        "id": 86232,
        "spelling_name": "Squander",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136030,
                "word_type": "v. t.",
                "spelling_usage": "To scatter; to disperse."
            },
            {
                "spelling_detail_id": 136031,
                "word_type": "v. t.",
                "spelling_usage": "To spend lavishly or profusely; to spend prodigally or\n   wastefully; to use without economy or judgment; to dissipate; as, to\n   squander an estate."
            },
            {
                "spelling_detail_id": 136032,
                "word_type": "v. i.",
                "spelling_usage": "To spend lavishly; to be wasteful."
            },
            {
                "spelling_detail_id": 136033,
                "word_type": "v. i.",
                "spelling_usage": "To wander at random; to scatter."
            },
            {
                "spelling_detail_id": 136034,
                "word_type": "n.",
                "spelling_usage": "The act of squandering; waste."
            }
        ]
    },
    {
        "id": 86295,
        "spelling_name": "Squid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136177,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of ten-armed cephalopods having\n   a long, tapered body, and a caudal fin on each side; especially, any\n   species of Loligo, Ommastrephes, and related genera. See Calamary,\n   Decacerata, Dibranchiata."
            },
            {
                "spelling_detail_id": 136178,
                "word_type": "n.",
                "spelling_usage": "A fishhook with a piece of bright lead, bone, or other\n   substance, fastened on its shank to imitate a squid."
            }
        ]
    },
    {
        "id": 86427,
        "spelling_name": "Staid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136421,
                "word_type": "a.",
                "spelling_usage": "Sober; grave; steady; sedate; composed; regular; not wild,\n   volatile, or fanciful."
            },
            {
                "spelling_detail_id": 137099,
                "word_type": "",
                "spelling_usage": "of Stay"
            }
        ]
    },
    {
        "id": 86490,
        "spelling_name": "Stalwart",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136555,
                "word_type": "a.",
                "spelling_usage": "Alt. of Stalworth"
            }
        ]
    },
    {
        "id": 86500,
        "spelling_name": "Stamina",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136562,
                "word_type": "pl. ",
                "spelling_usage": "of Stamen"
            },
            {
                "spelling_detail_id": 136567,
                "word_type": "n. pl.",
                "spelling_usage": "See Stamen."
            },
            {
                "spelling_detail_id": 136568,
                "word_type": "n. pl.",
                "spelling_usage": "The fixed, firm part of a body, which supports it or\n   gives it strength and solidity; as, the bones are the stamina of animal\n   bodies; the ligneous parts of trees are the stamina which constitute\n   their strength."
            },
            {
                "spelling_detail_id": 136569,
                "word_type": "n. pl.",
                "spelling_usage": "Whatever constitutes the principal strength or support\n   of anything; power of endurance; backbone; vigor; as, the stamina of a\n   constitution or of life; the stamina of a State."
            }
        ]
    },
    {
        "id": 86516,
        "spelling_name": "Stampede",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136617,
                "word_type": "v. t.",
                "spelling_usage": "A wild, headlong scamper, or running away, of a number\n   of animals; usually caused by fright; hence, any sudden flight or\n   dispersion, as of a crowd or an army in consequence of a panic."
            },
            {
                "spelling_detail_id": 136618,
                "word_type": "v. i.",
                "spelling_usage": "To run away in a panic; -- said droves of cattle,\n   horses, etc., also of armies."
            },
            {
                "spelling_detail_id": 136619,
                "word_type": "v. t.",
                "spelling_usage": "To disperse by causing sudden fright, as a herd or\n   drove of animals."
            }
        ]
    },
    {
        "id": 86570,
        "spelling_name": "Stanzaic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 136756,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or consisting of, stanzas; as, a couplet\n   in stanzaic form."
            }
        ]
    },
    {
        "id": 86710,
        "spelling_name": "Statistician",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137033,
                "word_type": "n.",
                "spelling_usage": "One versed in statistics; one who collects and\n   classifies facts for statistics."
            }
        ]
    },
    {
        "id": 86723,
        "spelling_name": "Statuesque",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137054,
                "word_type": "a.",
                "spelling_usage": "Partaking of, or exemplifying, the characteristics of a\n   statue; having the symmetry, or other excellence, of a statue\n   artistically made; as, statuesquelimbs; a statuesque attitude."
            }
        ]
    },
    {
        "id": 86905,
        "spelling_name": "Stellular",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137370,
                "word_type": "a.",
                "spelling_usage": "Having the shape or appearance of little stars;\n   radiated."
            },
            {
                "spelling_detail_id": 137371,
                "word_type": "a.",
                "spelling_usage": "Marked with starlike spots of color."
            }
        ]
    },
    {
        "id": 86936,
        "spelling_name": "Stenographer",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137428,
                "word_type": "n.",
                "spelling_usage": "One who is skilled in stenography; a writer of\n   shorthand."
            }
        ]
    },
    {
        "id": 87065,
        "spelling_name": "Sternum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137611,
                "word_type": "n.",
                "spelling_usage": "A plate of cartilage, or a series of bony or cartilaginous\n   plates or segments, in the median line of the pectoral skeleton of most\n   vertebrates above fishes; the breastbone."
            },
            {
                "spelling_detail_id": 137612,
                "word_type": "n.",
                "spelling_usage": "The ventral part of any one of the somites of an\n   arthropod."
            }
        ]
    },
    {
        "id": 87067,
        "spelling_name": "Sternutation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137613,
                "word_type": "n.",
                "spelling_usage": "The act of sneezing."
            }
        ]
    },
    {
        "id": 87171,
        "spelling_name": "Stigmata",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137790,
                "word_type": "pl. ",
                "spelling_usage": "of Stigma"
            },
            {
                "spelling_detail_id": 137802,
                "word_type": "n.",
                "spelling_usage": "pl. of Stigma."
            }
        ]
    },
    {
        "id": 87229,
        "spelling_name": "Stimuli",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 137914,
                "word_type": "pl. ",
                "spelling_usage": "of Stimulus"
            }
        ]
    },
    {
        "id": 87378,
        "spelling_name": "Stomach",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 138196,
                "word_type": "n.",
                "spelling_usage": "An enlargement, or series of enlargements, in the anterior\n   part of the alimentary canal, in which food is digested; any cavity in\n   which digestion takes place in an animal; a digestive cavity. See\n   Digestion, and Gastric juice, under Gastric."
            },
            {
                "spelling_detail_id": 138197,
                "word_type": "n.",
                "spelling_usage": "The desire for food caused by hunger; appetite; as, a good\n   stomach for roast beef."
            },
            {
                "spelling_detail_id": 138198,
                "word_type": "n.",
                "spelling_usage": "Hence appetite in general; inclination; desire."
            },
            {
                "spelling_detail_id": 138199,
                "word_type": "n.",
                "spelling_usage": "Violence of temper; anger; sullenness; resentment; willful\n   obstinacy; stubbornness."
            },
            {
                "spelling_detail_id": 138200,
                "word_type": "n.",
                "spelling_usage": "Pride; haughtiness; arrogance."
            },
            {
                "spelling_detail_id": 138203,
                "word_type": "v. t.",
                "spelling_usage": "To resent; to remember with anger; to dislike."
            },
            {
                "spelling_detail_id": 138204,
                "word_type": "v. t.",
                "spelling_usage": "To bear without repugnance; to brook."
            },
            {
                "spelling_detail_id": 138205,
                "word_type": "v. i.",
                "spelling_usage": "To be angry."
            }
        ]
    },
    {
        "id": 87583,
        "spelling_name": "Strait",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 138615,
                "word_type": "a.",
                "spelling_usage": "A variant of Straight."
            },
            {
                "spelling_detail_id": 138616,
                "word_type": "superl.",
                "spelling_usage": "Narrow; not broad."
            },
            {
                "spelling_detail_id": 138617,
                "word_type": "superl.",
                "spelling_usage": "Tight; close; closely fitting."
            },
            {
                "spelling_detail_id": 138618,
                "word_type": "superl.",
                "spelling_usage": "Close; intimate; near; familiar."
            },
            {
                "spelling_detail_id": 138619,
                "word_type": "superl.",
                "spelling_usage": "Strict; scrupulous; rigorous."
            },
            {
                "spelling_detail_id": 138620,
                "word_type": "superl.",
                "spelling_usage": "Difficult; distressful; straited."
            },
            {
                "spelling_detail_id": 138621,
                "word_type": "superl.",
                "spelling_usage": "Parsimonious; niggargly; mean."
            },
            {
                "spelling_detail_id": 138622,
                "word_type": "adv.",
                "spelling_usage": "Strictly; rigorously."
            },
            {
                "spelling_detail_id": 138624,
                "word_type": "a.",
                "spelling_usage": "A narrow pass or passage."
            },
            {
                "spelling_detail_id": 138625,
                "word_type": "a.",
                "spelling_usage": "A (comparatively) narrow passageway connecting two large\n   bodies of water; -- often in the plural; as, the strait, or straits, of\n   Gibraltar; the straits of Magellan; the strait, or straits, of\n   Mackinaw."
            },
            {
                "spelling_detail_id": 138626,
                "word_type": "a.",
                "spelling_usage": "A neck of land; an isthmus."
            },
            {
                "spelling_detail_id": 138627,
                "word_type": "a.",
                "spelling_usage": "Fig.: A condition of narrowness or restriction; doubt;\n   distress; difficulty; poverty; perplexity; -- sometimes in the plural;\n   as, reduced to great straits."
            },
            {
                "spelling_detail_id": 138628,
                "word_type": "v. t.",
                "spelling_usage": "To put to difficulties."
            }
        ]
    },
    {
        "id": 87649,
        "spelling_name": "Stratification",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 138748,
                "word_type": "n.",
                "spelling_usage": "The act or process of laying in strata, or the\n   state of being laid in the form of strata, or layers."
            },
            {
                "spelling_detail_id": 138749,
                "word_type": "n.",
                "spelling_usage": "The deposition of material in successive layers in\n   the growth of a cell wall, thus giving rise to a stratified appearance."
            }
        ]
    },
    {
        "id": 87691,
        "spelling_name": "Streamlet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 138830,
                "word_type": "n.",
                "spelling_usage": "A small stream; a rivulet; a rill."
            }
        ]
    },
    {
        "id": 87740,
        "spelling_name": "Stretcher",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 138918,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, stretches."
            },
            {
                "spelling_detail_id": 138919,
                "word_type": "n.",
                "spelling_usage": "A brick or stone laid with its longer dimension in the\n   line of direction of the wall."
            },
            {
                "spelling_detail_id": 138920,
                "word_type": "n.",
                "spelling_usage": "A piece of timber used in building."
            },
            {
                "spelling_detail_id": 138921,
                "word_type": "n.",
                "spelling_usage": "A narrow crosspiece of the bottom of a boat against\n   which a rower braces his feet."
            },
            {
                "spelling_detail_id": 138922,
                "word_type": "n.",
                "spelling_usage": "A crosspiece placed between the sides of a boat to keep\n   them apart when hoisted up and griped."
            },
            {
                "spelling_detail_id": 138923,
                "word_type": "n.",
                "spelling_usage": "A litter, or frame, for carrying disabled, wounded, or\n   dead persons."
            },
            {
                "spelling_detail_id": 138924,
                "word_type": "n.",
                "spelling_usage": "An overstretching of the truth; a lie."
            },
            {
                "spelling_detail_id": 138925,
                "word_type": "n.",
                "spelling_usage": "One of the rods in an umbrella, attached at one end to\n   one of the ribs, and at the other to the tube sliding upon the handle."
            },
            {
                "spelling_detail_id": 138926,
                "word_type": "n.",
                "spelling_usage": "An instrument for stretching boots or gloves."
            },
            {
                "spelling_detail_id": 138927,
                "word_type": "n.",
                "spelling_usage": "The frame upon which canvas is stretched for a painting."
            }
        ]
    },
    {
        "id": 87907,
        "spelling_name": "Strum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 139320,
                "word_type": "v. t. & i.",
                "spelling_usage": "To play on an instrument of music, or as on an\n   instrument, in an unskillful or noisy way; to thrum; as, to strum a\n   piano."
            }
        ]
    },
    {
        "id": 88035,
        "spelling_name": "Stupefy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 139573,
                "word_type": "v. t.",
                "spelling_usage": "To make stupid; to make dull; to blunt the faculty of\n   perception or understanding in; to deprive of sensibility; to make\n   torpid."
            },
            {
                "spelling_detail_id": 139574,
                "word_type": "v. t.",
                "spelling_usage": "To deprive of material mobility."
            }
        ]
    },
    {
        "id": 88166,
        "spelling_name": "Subaqueous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 139754,
                "word_type": "a.",
                "spelling_usage": "Being under water, or beneath the surface of water;\n   adapted for use under water; submarine; as, a subaqueous helmet."
            },
            {
                "spelling_detail_id": 139755,
                "word_type": "a.",
                "spelling_usage": "Formed in or under water; as, subaqueous deposits."
            }
        ]
    },
    {
        "id": 88225,
        "spelling_name": "Subcutaneous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 139824,
                "word_type": "a.",
                "spelling_usage": "Situated under the skin; hypodermic."
            }
        ]
    },
    {
        "id": 88530,
        "spelling_name": "Subsequent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140250,
                "word_type": "a.",
                "spelling_usage": "Following in time; coming or being after something else\n   at any time, indefinitely; as, subsequent events; subsequent ages or\n   years; a period long subsequent to the foundation of Rome."
            },
            {
                "spelling_detail_id": 140251,
                "word_type": "a.",
                "spelling_usage": "Following in order of place; succeeding; as, a\n   subsequent clause in a treaty."
            }
        ]
    },
    {
        "id": 88595,
        "spelling_name": "Substitute",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140348,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, is substituted or put in the\n   place of another; one who acts for another; that which stands in lieu\n   of something else"
            },
            {
                "spelling_detail_id": 140349,
                "word_type": "n.",
                "spelling_usage": "a person who enlists for military service in the place\n   of a conscript or drafted man."
            },
            {
                "spelling_detail_id": 140352,
                "word_type": "n.",
                "spelling_usage": "To put in the place of another person or thing; to\n   exchange."
            }
        ]
    },
    {
        "id": 88638,
        "spelling_name": "Subterranean",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140407,
                "word_type": "a.",
                "spelling_usage": "Alt. of Subterraneous"
            }
        ]
    },
    {
        "id": 88658,
        "spelling_name": "Subtlety",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140442,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being subtle, or sly; cunning;\n   craftiness; artfulness."
            },
            {
                "spelling_detail_id": 140443,
                "word_type": "n.",
                "spelling_usage": "Nice discernment with delicacy of mental action; nicety\n   of discrimination."
            },
            {
                "spelling_detail_id": 140444,
                "word_type": "n.",
                "spelling_usage": "Something that is sly, crafty, or delusive."
            }
        ]
    },
    {
        "id": 88757,
        "spelling_name": "Succinct",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140578,
                "word_type": "a.",
                "spelling_usage": "Girded or tucked up; bound; drawn tightly together."
            },
            {
                "spelling_detail_id": 140579,
                "word_type": "a.",
                "spelling_usage": "Compressed into a narrow compass; brief; concise."
            }
        ]
    },
    {
        "id": 88785,
        "spelling_name": "Succulent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140611,
                "word_type": "a.",
                "spelling_usage": "Full of juice; juicy."
            }
        ]
    },
    {
        "id": 88788,
        "spelling_name": "Succumb",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140616,
                "word_type": "v. t.",
                "spelling_usage": "To yield; to submit; to give up unresistingly; as, to\n   succumb under calamities; to succumb to disease."
            }
        ]
    },
    {
        "id": 88826,
        "spelling_name": "Sudation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140692,
                "word_type": "n.",
                "spelling_usage": "A sweating."
            }
        ]
    },
    {
        "id": 88845,
        "spelling_name": "Suet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 140726,
                "word_type": "n.",
                "spelling_usage": "The fat and fatty tissues of an animal, especially the harder\n   fat about the kidneys and loins in beef and mutton, which, when melted\n   and freed from the membranes, forms tallow."
            }
        ]
    },
    {
        "id": 89046,
        "spelling_name": "Sultana",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141029,
                "word_type": "n.",
                "spelling_usage": "The wife of a sultan; a sultaness."
            },
            {
                "spelling_detail_id": 141030,
                "word_type": "n.",
                "spelling_usage": "A kind of seedless raisin produced near Smyrna in Asiatic\n   Turkey."
            }
        ]
    },
    {
        "id": 89066,
        "spelling_name": "Summary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141062,
                "word_type": "a.",
                "spelling_usage": "Formed into a sum; summed up; reduced into a narrow\n   compass, or into few words; short; brief; concise; compendious; as, a\n   summary statement of facts."
            },
            {
                "spelling_detail_id": 141063,
                "word_type": "a.",
                "spelling_usage": "Hence, rapidly performed; quickly executed; as, a summary\n   process; to take summary vengeance."
            },
            {
                "spelling_detail_id": 141065,
                "word_type": "a.",
                "spelling_usage": "A general or comprehensive statement; an abridged account;\n   an abstract, abridgment, or compendium, containing the sum or substance\n   of a fuller account."
            }
        ]
    },
    {
        "id": 89242,
        "spelling_name": "Superficiality",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141303,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being superficial; also,\n   that which is superficial."
            }
        ]
    },
    {
        "id": 89296,
        "spelling_name": "Superlative",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141377,
                "word_type": "a.",
                "spelling_usage": "Lifted up to the highest degree; most eminent;\n   surpassing all other; supreme; as, superlative wisdom or prudence; a\n   woman of superlative beauty; the superlative glory of the divine\n   character."
            },
            {
                "spelling_detail_id": 141378,
                "word_type": "a.",
                "spelling_usage": "Expressing the highest or lowest degree of the\n   quality, manner, etc., denoted by an adjective or an adverb. The\n   superlative degree is formed from the positive by the use of -est,\n   most, or least; as, highest, most pleasant, least bright."
            },
            {
                "spelling_detail_id": 141379,
                "word_type": "n.",
                "spelling_usage": "That which is highest or most eminent; the utmost\n   degree."
            },
            {
                "spelling_detail_id": 141380,
                "word_type": "n.",
                "spelling_usage": "The superlative degree of adjectives and adverbs;\n   also, a form or word by which the superlative degree is expressed; as,\n   strongest, wisest, most stormy, least windy, are all superlatives."
            }
        ]
    },
    {
        "id": 89378,
        "spelling_name": "Superstitious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141479,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to superstition; proceeding from,\n   or manifesting, superstition; as, superstitious rites; superstitious\n   observances."
            },
            {
                "spelling_detail_id": 141480,
                "word_type": "a.",
                "spelling_usage": "Evincing superstition; overscrupulous and rigid in\n   religious observances; addicted to superstition; full of idle fancies\n   and scruples in regard to religion."
            },
            {
                "spelling_detail_id": 141481,
                "word_type": "a.",
                "spelling_usage": "Overexact; scrupulous beyond need."
            }
        ]
    },
    {
        "id": 89416,
        "spelling_name": "Supine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141527,
                "word_type": "a.",
                "spelling_usage": "Lying on the back, or with the face upward; -- opposed to\n   prone."
            },
            {
                "spelling_detail_id": 141528,
                "word_type": "a.",
                "spelling_usage": "Leaning backward, or inclining with exposure to the sun;\n   sloping; inclined."
            },
            {
                "spelling_detail_id": 141529,
                "word_type": "a.",
                "spelling_usage": "Negligent; heedless; indolent; listless."
            },
            {
                "spelling_detail_id": 141530,
                "word_type": "n.",
                "spelling_usage": "A verbal noun; or (according to C.F.Becker), a case of the\n   infinitive mood ending in -um and -u, that in -um being sometimes\n   called the former supine, and that in -u the latter supine."
            }
        ]
    },
    {
        "id": 89457,
        "spelling_name": "Supplicate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141592,
                "word_type": "v. t.",
                "spelling_usage": "To entreat for; to seek by earnest prayer; to ask\n   for earnestly and humbly; as, to supplicate blessings on Christian\n   efforts to spread the gospel."
            },
            {
                "spelling_detail_id": 141593,
                "word_type": "v. t.",
                "spelling_usage": "To address in prayer; to entreat as a supplicant;\n   as, to supplicate the Deity."
            },
            {
                "spelling_detail_id": 141594,
                "word_type": "v. i.",
                "spelling_usage": "To make petition with earnestness and submission; to\n   implore."
            }
        ]
    },
    {
        "id": 89576,
        "spelling_name": "Supremacy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141766,
                "word_type": "n.",
                "spelling_usage": "The state of being supreme, or in the highest station of\n   power; highest or supreme authority or power; as, the supremacy of a\n   king or a parliament."
            }
        ]
    },
    {
        "id": 89628,
        "spelling_name": "Surety",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141845,
                "word_type": "n.",
                "spelling_usage": "The state of being sure; certainty; security."
            },
            {
                "spelling_detail_id": 141846,
                "word_type": "n.",
                "spelling_usage": "That which makes sure; that which confirms; ground of\n   confidence or security."
            },
            {
                "spelling_detail_id": 141847,
                "word_type": "n.",
                "spelling_usage": "Security against loss or damage; security for payment, or\n   for the performance of some act."
            },
            {
                "spelling_detail_id": 141848,
                "word_type": "n.",
                "spelling_usage": "One who is bound with and for another who is primarily\n   liable, and who is called the principal; one who engages to answer for\n   another's appearance in court, or for his payment of a debt, or for\n   performance of some act; a bondsman; a bail."
            },
            {
                "spelling_detail_id": 141849,
                "word_type": "n.",
                "spelling_usage": "Hence, a substitute; a hostage."
            },
            {
                "spelling_detail_id": 141850,
                "word_type": "n.",
                "spelling_usage": "Evidence; confirmation; warrant."
            },
            {
                "spelling_detail_id": 141851,
                "word_type": "v. t.",
                "spelling_usage": "To act as surety for."
            }
        ]
    },
    {
        "id": 89677,
        "spelling_name": "Surmountable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141929,
                "word_type": "a.",
                "spelling_usage": "Capable of being surmounted or overcome; superable."
            }
        ]
    },
    {
        "id": 89696,
        "spelling_name": "Surplus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141951,
                "word_type": "n.",
                "spelling_usage": "That which remains when use or need is satisfied, or when\n   a limit is reached; excess; overplus."
            },
            {
                "spelling_detail_id": 141952,
                "word_type": "n.",
                "spelling_usage": "Specifically, an amount in the public treasury at any time\n   greater than is required for the ordinary purposes of the government."
            },
            {
                "spelling_detail_id": 141953,
                "word_type": "a.",
                "spelling_usage": "Being or constituting a surplus; more than sufficient; as,\n   surplus revenues; surplus population; surplus words."
            }
        ]
    },
    {
        "id": 89722,
        "spelling_name": "Surreptitious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 141998,
                "word_type": "a.",
                "spelling_usage": "Done or made by stealth, or without proper\n   authority; made or introduced fraudulently; clandestine; stealthy; as,\n   a surreptitious passage in an old manuscript; a surreptitious removal\n   of goods."
            }
        ]
    },
    {
        "id": 89724,
        "spelling_name": "Surrogate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 142000,
                "word_type": "n.",
                "spelling_usage": "A deputy; a delegate; a substitute."
            },
            {
                "spelling_detail_id": 142001,
                "word_type": "n.",
                "spelling_usage": "The deputy of an ecclesiastical judge, most commonly of\n   a bishop or his chancellor, especially a deputy who grants marriage\n   licenses."
            },
            {
                "spelling_detail_id": 142002,
                "word_type": "n.",
                "spelling_usage": "In some States of the United States, an officer who\n   presides over the probate of wills and testaments and yield the\n   settlement of estates."
            },
            {
                "spelling_detail_id": 142003,
                "word_type": "v. t.",
                "spelling_usage": "To put in the place of another; to substitute."
            }
        ]
    },
    {
        "id": 89807,
        "spelling_name": "Suspicion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 142153,
                "word_type": "n.",
                "spelling_usage": "The act of suspecting; the imagination or apprehension\n   of the existence of something (esp. something wrong or hurtful) without\n   proof, or upon very slight evidence, or upon no evidence."
            },
            {
                "spelling_detail_id": 142154,
                "word_type": "n.",
                "spelling_usage": "Slight degree; suggestion; hint."
            },
            {
                "spelling_detail_id": 142155,
                "word_type": "v. t.",
                "spelling_usage": "To view with suspicion; to suspect; to doubt."
            }
        ]
    },
    {
        "id": 89851,
        "spelling_name": "Suture",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 142219,
                "word_type": "n.",
                "spelling_usage": "The act of sewing; also, the line along which two things or\n   parts are sewed together, or are united so as to form a seam, or that\n   which resembles a seam."
            },
            {
                "spelling_detail_id": 142220,
                "word_type": "n.",
                "spelling_usage": "The uniting of the parts of a wound by stitching."
            },
            {
                "spelling_detail_id": 142221,
                "word_type": "n.",
                "spelling_usage": "The stitch by which the parts are united."
            },
            {
                "spelling_detail_id": 142222,
                "word_type": "n.",
                "spelling_usage": "The line of union, or seam, in an immovable articulation,\n   like those between the bones of the skull; also, such an articulation\n   itself; synarthrosis. See Harmonic suture, under Harmonic."
            },
            {
                "spelling_detail_id": 142223,
                "word_type": "n.",
                "spelling_usage": "The line, or seam, formed by the union of two margins in\n   any part of a plant; as, the ventral suture of a legume."
            },
            {
                "spelling_detail_id": 142224,
                "word_type": "n.",
                "spelling_usage": "A line resembling a seam; as, the dorsal suture of a\n   legume, which really corresponds to a midrib."
            },
            {
                "spelling_detail_id": 142225,
                "word_type": "n.",
                "spelling_usage": "The line at which the elytra of a beetle meet and are\n   sometimes confluent."
            },
            {
                "spelling_detail_id": 142226,
                "word_type": "n.",
                "spelling_usage": "A seam, or impressed line, as between the segments of a\n   crustacean, or between the whorls of a univalve shell."
            }
        ]
    },
    {
        "id": 90035,
        "spelling_name": "Swerve",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 142659,
                "word_type": "v. i.",
                "spelling_usage": "To stray; to wander; to rope."
            },
            {
                "spelling_detail_id": 142660,
                "word_type": "v. i.",
                "spelling_usage": "To go out of a straight line; to deflect."
            },
            {
                "spelling_detail_id": 142661,
                "word_type": "v. i.",
                "spelling_usage": "To wander from any line prescribed, or from a rule or\n   duty; to depart from what is established by law, duty, custom, or the\n   like; to deviate."
            },
            {
                "spelling_detail_id": 142662,
                "word_type": "v. i.",
                "spelling_usage": "To bend; to incline."
            },
            {
                "spelling_detail_id": 142663,
                "word_type": "v. i.",
                "spelling_usage": "To climb or move upward by winding or turning."
            },
            {
                "spelling_detail_id": 142664,
                "word_type": "v. t.",
                "spelling_usage": "To turn aside."
            }
        ]
    },
    {
        "id": 90181,
        "spelling_name": "Sycophant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 142933,
                "word_type": "n.",
                "spelling_usage": "An informer; a talebearer."
            },
            {
                "spelling_detail_id": 142934,
                "word_type": "n.",
                "spelling_usage": "A base parasite; a mean or servile flatterer;\n   especially, a flatterer of princes and great men."
            },
            {
                "spelling_detail_id": 142935,
                "word_type": "v. t.",
                "spelling_usage": "To inform against; hence, to calumniate."
            },
            {
                "spelling_detail_id": 142936,
                "word_type": "v. t.",
                "spelling_usage": "To play the sycophant toward; to flatter\n   obsequiously."
            },
            {
                "spelling_detail_id": 142937,
                "word_type": "v. i.",
                "spelling_usage": "To play the sycophant."
            }
        ]
    },
    {
        "id": 90218,
        "spelling_name": "Syllabus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 142981,
                "word_type": "n.",
                "spelling_usage": "A compendium containing the heads of a discourse, and the\n   like; an abstract."
            }
        ]
    },
    {
        "id": 90292,
        "spelling_name": "Sympathy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 143092,
                "word_type": "n.",
                "spelling_usage": "Feeling corresponding to that which another feels; the\n   quality of being affected by the affection of another, with feelings\n   correspondent in kind, if not in degree; fellow-feeling."
            },
            {
                "spelling_detail_id": 143093,
                "word_type": "n.",
                "spelling_usage": "An agreement of affections or inclinations, or a\n   conformity of natural temperament, which causes persons to be pleased,\n   or in accord, with one another; as, there is perfect sympathy between\n   them."
            },
            {
                "spelling_detail_id": 143094,
                "word_type": "n.",
                "spelling_usage": "Kindness of feeling toward one who suffers; pity;\n   commiseration; compassion."
            },
            {
                "spelling_detail_id": 143095,
                "word_type": "n.",
                "spelling_usage": "The reciprocal influence exercised by the various organs\n   or parts of the body on one another, as manifested in the transmission\n   of a disease by unknown means from one organ to another quite remote,\n   or in the influence exerted by a diseased condition of one part on\n   another part or organ, as in the vomiting produced by a tumor of the\n   brain."
            },
            {
                "spelling_detail_id": 143096,
                "word_type": "n.",
                "spelling_usage": "That relation which exists between different persons by\n   which one of them produces in the others a state or condition like that\n   of himself. This is shown in the tendency to yawn which a person often\n   feels on seeing another yawn, or the strong inclination to become\n   hysteric experienced by many women on seeing another person suffering\n   with hysteria."
            },
            {
                "spelling_detail_id": 143097,
                "word_type": "n.",
                "spelling_usage": "A tendency of inanimate things to unite, or to act on\n   each other; as, the sympathy between the loadstone and iron."
            },
            {
                "spelling_detail_id": 143098,
                "word_type": "n.",
                "spelling_usage": "Similarity of function, use office, or the like."
            }
        ]
    },
    {
        "id": 90321,
        "spelling_name": "Symposium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 143135,
                "word_type": "n.",
                "spelling_usage": "A drinking together; a merry feast."
            },
            {
                "spelling_detail_id": 143136,
                "word_type": "n.",
                "spelling_usage": "A collection of short essays by different authors on a\n   common topic; -- so called from the appellation given to the\n   philosophical dialogue by the Greeks."
            }
        ]
    },
    {
        "id": 90406,
        "spelling_name": "Syndicate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 143244,
                "word_type": "n.",
                "spelling_usage": "The office or jurisdiction of a syndic; a council, or\n   body of syndics."
            },
            {
                "spelling_detail_id": 143245,
                "word_type": "n.",
                "spelling_usage": "An association of persons officially authorized to\n   undertake some duty or to negotiate some business; also, an association\n   of persons who combine to carry out, on their own account, a financial\n   or industrial project; as, a syndicate of bankers formed to take up and\n   dispose of an entire issue of government bonds."
            },
            {
                "spelling_detail_id": 143246,
                "word_type": "v. t.",
                "spelling_usage": "To judge; to censure."
            }
        ]
    },
    {
        "id": 90513,
        "spelling_name": "Syringe",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 143381,
                "word_type": "n.",
                "spelling_usage": "A kind of small hand-pump for throwing a stream of liquid,\n   or for purposes of aspiration. It consists of a small cylindrical\n   barrel and piston, or a bulb of soft elastic material, with or without\n   valves, and with a nozzle which is sometimes at the end of a flexible\n   tube; -- used for injecting animal bodies, cleansing wounds, etc."
            },
            {
                "spelling_detail_id": 143384,
                "word_type": "v. t.",
                "spelling_usage": "To inject by means of a syringe; as, to syringe warm\n   water into a vein."
            },
            {
                "spelling_detail_id": 143385,
                "word_type": "v. t.",
                "spelling_usage": "To wash and clean by injection from a syringe."
            }
        ]
    },
    {
        "id": 90590,
        "spelling_name": "Tabernacle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 143484,
                "word_type": "n.",
                "spelling_usage": "A slightly built or temporary habitation; especially, a\n   tent."
            },
            {
                "spelling_detail_id": 143485,
                "word_type": "n.",
                "spelling_usage": "A portable structure of wooden framework covered with\n   curtains, which was carried through the wilderness in the Israelitish\n   exodus, as a place of sacrifice and worship."
            },
            {
                "spelling_detail_id": 143486,
                "word_type": "n.",
                "spelling_usage": "Hence, the Jewish temple; sometimes, any other place\n   for worship."
            },
            {
                "spelling_detail_id": 143487,
                "word_type": "n.",
                "spelling_usage": "Figuratively: The human body, as the temporary abode of\n   the soul."
            },
            {
                "spelling_detail_id": 143488,
                "word_type": "n.",
                "spelling_usage": "Any small cell, or like place, in which some holy or\n   precious things was deposited or kept."
            },
            {
                "spelling_detail_id": 143489,
                "word_type": "n.",
                "spelling_usage": "The ornamental receptacle for the pyx, or for the\n   consecrated elements, whether a part of a building or movable."
            },
            {
                "spelling_detail_id": 143490,
                "word_type": "n.",
                "spelling_usage": "A niche for the image of a saint, or for any sacred\n   painting or sculpture."
            },
            {
                "spelling_detail_id": 143491,
                "word_type": "n.",
                "spelling_usage": "Hence, a work of art of sacred subject, having a\n   partially architectural character, as a solid frame resting on a\n   bracket, or the like."
            },
            {
                "spelling_detail_id": 143492,
                "word_type": "n.",
                "spelling_usage": "A tryptich for sacred imagery."
            },
            {
                "spelling_detail_id": 143493,
                "word_type": "n.",
                "spelling_usage": "A seat or stall in a choir, with its canopy."
            },
            {
                "spelling_detail_id": 143494,
                "word_type": "n.",
                "spelling_usage": "A boxlike step for a mast with the after side open, so\n   that the mast can be lowered to pass under bridges, etc."
            },
            {
                "spelling_detail_id": 143497,
                "word_type": "v. i.",
                "spelling_usage": "To dwell or reside for a time; to be temporary\n   housed."
            }
        ]
    },
    {
        "id": 90669,
        "spelling_name": "Taciturn",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 143638,
                "word_type": "a.",
                "spelling_usage": "Habitually silent; not given to converse; not apt to talk\n   or speak."
            }
        ]
    },
    {
        "id": 90917,
        "spelling_name": "Tangerine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144108,
                "word_type": "n.",
                "spelling_usage": "A kind of orange, much like the mandarin, but of deeper\n   color and higher flavor. It is said to have been produced in America\n   from the mandarin."
            }
        ]
    },
    {
        "id": 91023,
        "spelling_name": "Tarantula",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144257,
                "word_type": "n.",
                "spelling_usage": "Any one of several species of large spiders, popularly\n   supposed to be very venomous, especially the European species\n   (Tarantula apuliae). The tarantulas of Texas and adjacent countries are\n   large species of Mygale."
            }
        ]
    },
    {
        "id": 91031,
        "spelling_name": "Tardigrade",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144264,
                "word_type": "a.",
                "spelling_usage": "Moving or stepping slowly; slow-paced."
            },
            {
                "spelling_detail_id": 144265,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Tardigrada."
            },
            {
                "spelling_detail_id": 144266,
                "word_type": "n.",
                "spelling_usage": "One of the Tardigrada."
            }
        ]
    },
    {
        "id": 91091,
        "spelling_name": "Tarsier",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144359,
                "word_type": "n.",
                "spelling_usage": "See Tarsius."
            }
        ]
    },
    {
        "id": 91224,
        "spelling_name": "Tautology",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144569,
                "word_type": "n.",
                "spelling_usage": "A repetition of the same meaning in different words;\n   needless repetition of an idea in different words or phrases; a\n   representation of anything as the cause, condition, or consequence of\n   itself, as in the following lines: --//The dawn is overcast, the\n   morning lowers,/And heavily in clouds brings on the day. Addison."
            }
        ]
    },
    {
        "id": 91378,
        "spelling_name": "Tedious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144794,
                "word_type": "a.",
                "spelling_usage": "Involving tedium; tiresome from continuance, prolixity,\n   slowness, or the like; wearisome."
            }
        ]
    },
    {
        "id": 91401,
        "spelling_name": "Teeter",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144832,
                "word_type": "v. i. & t.",
                "spelling_usage": "To move up and down on the ends of a balanced\n   plank, or the like, as children do for sport; to seesaw; to titter; to\n   titter-totter."
            }
        ]
    },
    {
        "id": 91457,
        "spelling_name": "Teleology",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 144895,
                "word_type": "n.",
                "spelling_usage": "The doctrine of the final causes of things"
            },
            {
                "spelling_detail_id": 144896,
                "word_type": "n.",
                "spelling_usage": "the doctrine of design, which assumes that the phenomena\n   of organic life, particularly those of evolution, are explicable only\n   by purposive causes, and that they in no way admit of a mechanical\n   explanation or one based entirely on biological science; the doctrine\n   of adaptation to purpose."
            }
        ]
    },
    {
        "id": 91550,
        "spelling_name": "Tempestuous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145059,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a tempest; involving or resembling\n   a tempest; turbulent; violent; stormy; as, tempestuous weather; a\n   tempestuous night; a tempestuous debate."
            }
        ]
    },
    {
        "id": 91688,
        "spelling_name": "Tensile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145276,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to extension; as, tensile strength."
            },
            {
                "spelling_detail_id": 145277,
                "word_type": "a.",
                "spelling_usage": "Capable of extension; ductile; tensible."
            }
        ]
    },
    {
        "id": 91761,
        "spelling_name": "Tepidity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145392,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being tepid; moderate warmth;\n   lukewarmness; tepidness."
            }
        ]
    },
    {
        "id": 91784,
        "spelling_name": "Tercentenary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145415,
                "word_type": "a.",
                "spelling_usage": "Including, or relating to, an interval of three\n   hundred years."
            },
            {
                "spelling_detail_id": 145416,
                "word_type": "n.",
                "spelling_usage": "The three hundredth anniversary of any event; also, a\n   celebration of such an anniversary."
            }
        ]
    },
    {
        "id": 91860,
        "spelling_name": "Terminus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145533,
                "word_type": "n.",
                "spelling_usage": "Literally, a boundary; a border; a limit."
            },
            {
                "spelling_detail_id": 145534,
                "word_type": "n.",
                "spelling_usage": "The Roman divinity who presided over boundaries, whose\n   statue was properly a short pillar terminating in the bust of a man,\n   woman, satyr, or the like, but often merely a post or stone stuck in\n   the ground on a boundary line."
            },
            {
                "spelling_detail_id": 145535,
                "word_type": "n.",
                "spelling_usage": "Hence, any post or stone marking a boundary; a term. See\n   Term, 8."
            },
            {
                "spelling_detail_id": 145536,
                "word_type": "n.",
                "spelling_usage": "Either end of a railroad line; also, the station house,\n   or the town or city, at that place."
            }
        ]
    },
    {
        "id": 91883,
        "spelling_name": "Terraced",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145569,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Terrace"
            }
        ]
    },
    {
        "id": 91910,
        "spelling_name": "Terrier",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145606,
                "word_type": "n.",
                "spelling_usage": "An auger or borer."
            },
            {
                "spelling_detail_id": 145607,
                "word_type": "n.",
                "spelling_usage": "One of a breed of small dogs, which includes several\n   distinct subbreeds, some of which, such as the Skye terrier and\n   Yorkshire terrier, have long hair and drooping ears, while others, at\n   the English and the black-and-tan terriers, have short, close, smooth\n   hair and upright ears."
            },
            {
                "spelling_detail_id": 145608,
                "word_type": "n.",
                "spelling_usage": "Formerly, a collection of acknowledgments of the vassals\n   or tenants of a lordship, containing the rents and services they owed\n   to the lord, and the like."
            },
            {
                "spelling_detail_id": 145609,
                "word_type": "n.",
                "spelling_usage": "In modern usage, a book or roll in which the lands of\n   private persons or corporations are described by their site,\n   boundaries, number of acres, or the like."
            }
        ]
    },
    {
        "id": 91995,
        "spelling_name": "Testimony",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145752,
                "word_type": "n.",
                "spelling_usage": "A solemn declaration or affirmation made for the purpose\n   of establishing or proving some fact."
            },
            {
                "spelling_detail_id": 145753,
                "word_type": "n.",
                "spelling_usage": "Affirmation; declaration; as, these doctrines are\n   supported by the uniform testimony of the fathers; the belief of past\n   facts must depend on the evidence of human testimony, or the testimony\n   of historians."
            },
            {
                "spelling_detail_id": 145754,
                "word_type": "n.",
                "spelling_usage": "Open attestation; profession."
            },
            {
                "spelling_detail_id": 145755,
                "word_type": "n.",
                "spelling_usage": "Witness; evidence; proof of some fact."
            },
            {
                "spelling_detail_id": 145756,
                "word_type": "n.",
                "spelling_usage": "The two tables of the law."
            },
            {
                "spelling_detail_id": 145757,
                "word_type": "n.",
                "spelling_usage": "Hence, the whole divine revelation; the sacre/\n   Scriptures."
            },
            {
                "spelling_detail_id": 145758,
                "word_type": "v. t.",
                "spelling_usage": "To witness; to attest; to prove by testimony."
            }
        ]
    },
    {
        "id": 92018,
        "spelling_name": "Tetanus",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 145786,
                "word_type": "n.",
                "spelling_usage": "A painful and usually fatal disease, resulting generally\n   from a wound, and having as its principal symptom persistent spasm of\n   the voluntary muscles. When the muscles of the lower jaw are affected,\n   it is called locked-jaw, or lickjaw, and it takes various names from\n   the various incurvations of the body resulting from the spasm."
            },
            {
                "spelling_detail_id": 145787,
                "word_type": "n.",
                "spelling_usage": "That condition of a muscle in which it is in a state of\n   continued vibratory contraction, as when stimulated by a series of\n   induction shocks."
            }
        ]
    },
    {
        "id": 92360,
        "spelling_name": "Theomachy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146243,
                "word_type": "n.",
                "spelling_usage": "A fighting against the gods, as the battle of the gaints\n   with the gods."
            },
            {
                "spelling_detail_id": 146244,
                "word_type": "n.",
                "spelling_usage": "A battle or strife among the gods."
            },
            {
                "spelling_detail_id": 146245,
                "word_type": "n.",
                "spelling_usage": "Opposition to God or the divine will."
            }
        ]
    },
    {
        "id": 92377,
        "spelling_name": "Theorem",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146263,
                "word_type": "n.",
                "spelling_usage": "That which is considered and established as a principle;\n   hence, sometimes, a rule."
            },
            {
                "spelling_detail_id": 146264,
                "word_type": "n.",
                "spelling_usage": "A statement of a principle to be demonstrated."
            },
            {
                "spelling_detail_id": 146265,
                "word_type": "v. t.",
                "spelling_usage": "To formulate into a theorem."
            }
        ]
    },
    {
        "id": 92511,
        "spelling_name": "Thespian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146430,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Thespis; hence, relating to the\n   drama; dramatic; as, the Thespian art."
            },
            {
                "spelling_detail_id": 146431,
                "word_type": "n.",
                "spelling_usage": "An actor."
            }
        ]
    },
    {
        "id": 92558,
        "spelling_name": "Thievery",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146513,
                "word_type": "n.",
                "spelling_usage": "The practice of stealing; theft; thievishness."
            },
            {
                "spelling_detail_id": 146514,
                "word_type": "n.",
                "spelling_usage": "That which is stolen."
            }
        ]
    },
    {
        "id": 92660,
        "spelling_name": "Thoracic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146686,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the thorax, or chest."
            },
            {
                "spelling_detail_id": 146687,
                "word_type": "n.",
                "spelling_usage": "One of a group of fishes having the ventral fins placed\n   beneath the thorax or beneath the pectorial fins."
            }
        ]
    },
    {
        "id": 92687,
        "spelling_name": "Thoroughbred",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146727,
                "word_type": "a.",
                "spelling_usage": "Bred from the best blood through a long line;\n   pure-blooded; -- said of stock, as horses. Hence, having the\n   characteristics of such breeding; mettlesome; courageous; of elegant\n   form, or the like."
            },
            {
                "spelling_detail_id": 146728,
                "word_type": "n.",
                "spelling_usage": "A thoroughbred animal, especially a horse."
            }
        ]
    },
    {
        "id": 92729,
        "spelling_name": "Thrasonical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 146811,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Thraso; like, or becoming to,\n   Thraso; bragging; boastful; vainglorious."
            }
        ]
    },
    {
        "id": 92862,
        "spelling_name": "Throughout",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 147044,
                "word_type": "prep.",
                "spelling_usage": "Quite through; from one extremity to the other of;\n   also, every part of; as, to search throughout the house."
            },
            {
                "spelling_detail_id": 147045,
                "word_type": "adv.",
                "spelling_usage": "In every part; as, the cloth was of a piece\n   throughout."
            }
        ]
    },
    {
        "id": 93470,
        "spelling_name": "Toga",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148140,
                "word_type": "n.",
                "spelling_usage": "The loose outer garment worn by the ancient Romans,\n   consisting of a single broad piece of woolen cloth of a shape\n   approaching a semicircle. It was of undyed wool, except the border of\n   the toga praetexta."
            }
        ]
    },
    {
        "id": 93492,
        "spelling_name": "Toilsome",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148169,
                "word_type": "a.",
                "spelling_usage": "Attended with toil, or fatigue and pain; laborious;\n   wearisome; as, toilsome work."
            }
        ]
    },
    {
        "id": 93553,
        "spelling_name": "Tomahawk",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148260,
                "word_type": "n.",
                "spelling_usage": "A kind of war hatchet used by the American Indians. It\n   was originally made of stone, but afterwards of iron."
            },
            {
                "spelling_detail_id": 148263,
                "word_type": "v. t.",
                "spelling_usage": "To cut, strike, or kill, with a tomahawk."
            }
        ]
    },
    {
        "id": 93716,
        "spelling_name": "Topgallant",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148551,
                "word_type": "a.",
                "spelling_usage": "Situated above the topmast and below the royal mast;\n   designatb, or pertaining to, the third spars in order from the deck;\n   as, the topgallant mast, yards, braces, and the like. See Illustration\n   of Ship."
            },
            {
                "spelling_detail_id": 148552,
                "word_type": "a.",
                "spelling_usage": "Fig.: Highest; elevated; splendid."
            },
            {
                "spelling_detail_id": 148553,
                "word_type": "n.",
                "spelling_usage": "A topgallant mast or sail."
            },
            {
                "spelling_detail_id": 148554,
                "word_type": "n.",
                "spelling_usage": "Fig.: Anything elevated or splendid."
            }
        ]
    },
    {
        "id": 93827,
        "spelling_name": "Torrent",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148724,
                "word_type": "n.",
                "spelling_usage": "A violent stream, as of water, lava, or the like; a stream\n   suddenly raised and running rapidly, as down a precipice."
            },
            {
                "spelling_detail_id": 148725,
                "word_type": "n.",
                "spelling_usage": "Fig.: A violent or rapid flow; a strong current; a flood;\n   as, a torrent of vices; a torrent of eloquence."
            },
            {
                "spelling_detail_id": 148726,
                "word_type": "n.",
                "spelling_usage": "Rolling or rushing in a rapid stream."
            }
        ]
    },
    {
        "id": 93841,
        "spelling_name": "Torsion",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148741,
                "word_type": "n.",
                "spelling_usage": "The act of turning or twisting, or the state of being\n   twisted; the twisting or wrenching of a body by the exertion of a\n   lateral force tending to turn one end or part of it about a\n   longitudinal axis, while the other is held fast or turned in the\n   opposite direction."
            },
            {
                "spelling_detail_id": 148742,
                "word_type": "n.",
                "spelling_usage": "That force with which a thread, wire, or rod of any\n   material, returns, or tends to return, to a state of rest after it has\n   been twisted; torsibility."
            }
        ]
    },
    {
        "id": 93858,
        "spelling_name": "Tortoise",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 148764,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of reptiles of the order\n   Testudinata."
            },
            {
                "spelling_detail_id": 148765,
                "word_type": "n.",
                "spelling_usage": "Same as Testudo, 2."
            },
            {
                "spelling_detail_id": 148766,
                "word_type": "n.",
                "spelling_usage": "having a color like that of a tortoise's shell, black\n   with white and orange spots; -- used mostly to describe cats of that\n   color."
            },
            {
                "spelling_detail_id": 148767,
                "word_type": "n.",
                "spelling_usage": "a tortoise-shell cat."
            }
        ]
    },
    {
        "id": 94094,
        "spelling_name": "Tracheotomy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149174,
                "word_type": "n.",
                "spelling_usage": "The operation of making an opening into the windpipe."
            }
        ]
    },
    {
        "id": 94115,
        "spelling_name": "Tractability",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149216,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being tractable or docile;\n   docility; tractableness."
            }
        ]
    },
    {
        "id": 94183,
        "spelling_name": "Tragedian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149334,
                "word_type": "n.",
                "spelling_usage": "A writer of tragedy."
            },
            {
                "spelling_detail_id": 149335,
                "word_type": "n.",
                "spelling_usage": "An actor or player in tragedy."
            }
        ]
    },
    {
        "id": 94267,
        "spelling_name": "Tranquil",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149507,
                "word_type": "a.",
                "spelling_usage": "Quiet; calm; undisturbed; peaceful; not agitated; as, the\n   atmosphere is tranquil; the condition of the country is tranquil."
            }
        ]
    },
    {
        "id": 94296,
        "spelling_name": "Transcend",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149545,
                "word_type": "v. t.",
                "spelling_usage": "To rise above; to surmount; as, lights in the heavens\n   transcending the region of the clouds."
            },
            {
                "spelling_detail_id": 149546,
                "word_type": "v. t.",
                "spelling_usage": "To pass over; to go beyond; to exceed."
            },
            {
                "spelling_detail_id": 149547,
                "word_type": "v. t.",
                "spelling_usage": "To surpass; to outgo; to excel; to exceed."
            },
            {
                "spelling_detail_id": 149548,
                "word_type": "v. i.",
                "spelling_usage": "To climb; to mount."
            },
            {
                "spelling_detail_id": 149549,
                "word_type": "v. i.",
                "spelling_usage": "To be transcendent; to excel."
            }
        ]
    },
    {
        "id": 94341,
        "spelling_name": "Transference",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149615,
                "word_type": "n.",
                "spelling_usage": "The act of transferring; conveyance; passage;\n   transfer."
            }
        ]
    },
    {
        "id": 94509,
        "spelling_name": "Transposable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 149862,
                "word_type": "a.",
                "spelling_usage": "That may transposed; as, a transposable phrase."
            }
        ]
    },
    {
        "id": 94612,
        "spelling_name": "Traverse",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150037,
                "word_type": "a.",
                "spelling_usage": "Lying across; being in a direction across something else;\n   as, paths cut with traverse trenches."
            },
            {
                "spelling_detail_id": 150038,
                "word_type": "adv.",
                "spelling_usage": "Athwart; across; crosswise."
            },
            {
                "spelling_detail_id": 150039,
                "word_type": "a.",
                "spelling_usage": "Anything that traverses, or crosses."
            },
            {
                "spelling_detail_id": 150040,
                "word_type": "a.",
                "spelling_usage": "Something that thwarts, crosses, or obstructs; a cross\n   accident; as, he would have succeeded, had it not been for unlucky\n   traverses not under his control."
            },
            {
                "spelling_detail_id": 150041,
                "word_type": "a.",
                "spelling_usage": "A barrier, sliding door, movable screen, curtain, or the\n   like."
            },
            {
                "spelling_detail_id": 150042,
                "word_type": "a.",
                "spelling_usage": "A gallery or loft of communication from side to side of a\n   church or other large building."
            },
            {
                "spelling_detail_id": 150043,
                "word_type": "a.",
                "spelling_usage": "A work thrown up to intercept an enfilade, or reverse\n   fire, along exposed passage, or line of work."
            },
            {
                "spelling_detail_id": 150044,
                "word_type": "a.",
                "spelling_usage": "A formal denial of some matter of fact alleged by the\n   opposite party in any stage of the pleadings. The technical words\n   introducing a traverse are absque hoc, without this; that is, without\n   this which follows."
            },
            {
                "spelling_detail_id": 150045,
                "word_type": "a.",
                "spelling_usage": "The zigzag course or courses made by a ship in passing\n   from one place to another; a compound course."
            },
            {
                "spelling_detail_id": 150046,
                "word_type": "a.",
                "spelling_usage": "A line lying across a figure or other lines; a\n   transversal."
            },
            {
                "spelling_detail_id": 150047,
                "word_type": "a.",
                "spelling_usage": "A line surveyed across a plot of ground."
            },
            {
                "spelling_detail_id": 150048,
                "word_type": "a.",
                "spelling_usage": "The turning of a gun so as to make it point in any\n   desired direction."
            },
            {
                "spelling_detail_id": 150049,
                "word_type": "a.",
                "spelling_usage": "A turning; a trick; a subterfuge."
            },
            {
                "spelling_detail_id": 150052,
                "word_type": "a.",
                "spelling_usage": "To lay in a cross direction; to cross."
            },
            {
                "spelling_detail_id": 150053,
                "word_type": "a.",
                "spelling_usage": "To cross by way of opposition; to thwart with obstacles;\n   to obstruct; to bring to naught."
            },
            {
                "spelling_detail_id": 150054,
                "word_type": "a.",
                "spelling_usage": "To wander over; to cross in traveling; as, to traverse\n   the habitable globe."
            },
            {
                "spelling_detail_id": 150055,
                "word_type": "a.",
                "spelling_usage": "To pass over and view; to survey carefully."
            },
            {
                "spelling_detail_id": 150056,
                "word_type": "a.",
                "spelling_usage": "To turn to the one side or the other, in order to point\n   in any direction; as, to traverse a cannon."
            },
            {
                "spelling_detail_id": 150057,
                "word_type": "a.",
                "spelling_usage": "To plane in a direction across the grain of the wood; as,\n   to traverse a board."
            },
            {
                "spelling_detail_id": 150058,
                "word_type": "a.",
                "spelling_usage": "To deny formally, as what the opposite party has alleged.\n   When the plaintiff or defendant advances new matter, he avers it to be\n   true, and traverses what the other party has affirmed. To traverse an\n   indictment or an office is to deny it."
            },
            {
                "spelling_detail_id": 150059,
                "word_type": "v. i.",
                "spelling_usage": "To use the posture or motions of opposition or\n   counteraction, as in fencing."
            },
            {
                "spelling_detail_id": 150060,
                "word_type": "v. i.",
                "spelling_usage": "To turn, as on a pivot; to move round; to swivel; as,\n   the needle of a compass traverses; if it does not traverse well, it is\n   an unsafe guide."
            },
            {
                "spelling_detail_id": 150061,
                "word_type": "v. i.",
                "spelling_usage": "To tread or move crosswise, as a horse that throws his\n   croup to one side and his head to the other."
            }
        ]
    },
    {
        "id": 94670,
        "spelling_name": "Treatise",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150172,
                "word_type": "n.",
                "spelling_usage": "A written composition on a particular subject, in which\n   its principles are discussed or explained; a tract."
            },
            {
                "spelling_detail_id": 150173,
                "word_type": "n.",
                "spelling_usage": "Story; discourse."
            }
        ]
    },
    {
        "id": 94675,
        "spelling_name": "Treble",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150183,
                "word_type": "a.",
                "spelling_usage": "Threefold; triple."
            },
            {
                "spelling_detail_id": 150184,
                "word_type": "a.",
                "spelling_usage": "Acute; sharp; as, a treble sound."
            },
            {
                "spelling_detail_id": 150185,
                "word_type": "a.",
                "spelling_usage": "Playing or singing the highest part or most acute sounds;\n   playing or singing the treble; as, a treble violin or voice."
            },
            {
                "spelling_detail_id": 150186,
                "word_type": "adv.",
                "spelling_usage": "Trebly; triply."
            },
            {
                "spelling_detail_id": 150187,
                "word_type": "n.",
                "spelling_usage": "The highest of the four principal parts in music; the part\n   usually sung by boys or women; soprano."
            },
            {
                "spelling_detail_id": 150190,
                "word_type": "v. t.",
                "spelling_usage": "To make thrice as much; to make threefold."
            },
            {
                "spelling_detail_id": 150191,
                "word_type": "v. t.",
                "spelling_usage": "To utter in a treble key; to whine."
            },
            {
                "spelling_detail_id": 150192,
                "word_type": "v. i.",
                "spelling_usage": "To become threefold."
            }
        ]
    },
    {
        "id": 94681,
        "spelling_name": "Trebuchet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150196,
                "word_type": "n.",
                "spelling_usage": "Alt. of Trebucket"
            }
        ]
    },
    {
        "id": 94697,
        "spelling_name": "Trefoil",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150226,
                "word_type": "n.",
                "spelling_usage": "Any plant of the genus Trifolium, which includes the white\n   clover, red clover, etc.; -- less properly, applied also to the\n   nonesuch, or black medic. See Clover, and Medic."
            },
            {
                "spelling_detail_id": 150227,
                "word_type": "n.",
                "spelling_usage": "An ornamental foliation consisting of three divisions, or\n   foils."
            },
            {
                "spelling_detail_id": 150228,
                "word_type": "n.",
                "spelling_usage": "A charge representing the clover leaf."
            }
        ]
    },
    {
        "id": 94705,
        "spelling_name": "Trellis",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150236,
                "word_type": "n.",
                "spelling_usage": "A structure or frame of crossbarred work, or latticework,\n   used for various purposes, as for screens or for supporting plants."
            }
        ]
    },
    {
        "id": 94724,
        "spelling_name": "Tremulous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150260,
                "word_type": "a.",
                "spelling_usage": "Shaking; shivering; quivering; as, a tremulous limb; a\n   tremulous motion of the hand or the lips; the tremulous leaf of the\n   poplar."
            },
            {
                "spelling_detail_id": 150261,
                "word_type": "a.",
                "spelling_usage": "Affected with fear or timidity; trembling."
            }
        ]
    },
    {
        "id": 94757,
        "spelling_name": "Trepidation",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150319,
                "word_type": "n.",
                "spelling_usage": "An involuntary trembling, sometimes an effect of\n   paralysis, but usually caused by terror or fear; quaking; quivering."
            },
            {
                "spelling_detail_id": 150320,
                "word_type": "n.",
                "spelling_usage": "Hence, a state of terror or alarm; fear; confusion;\n   fright; as, the men were in great trepidation."
            },
            {
                "spelling_detail_id": 150321,
                "word_type": "n.",
                "spelling_usage": "A libration of the starry sphere in the Ptolemaic\n   system; a motion ascribed to the firmament, to account for certain\n   small changes in the position of the ecliptic and of the stars."
            }
        ]
    },
    {
        "id": 94762,
        "spelling_name": "Trespass",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150327,
                "word_type": "v. i.",
                "spelling_usage": "To pass beyond a limit or boundary; hence, to depart;\n   to go."
            },
            {
                "spelling_detail_id": 150328,
                "word_type": "v. i.",
                "spelling_usage": "To commit a trespass; esp., to enter unlawfully upon\n   the land of another."
            },
            {
                "spelling_detail_id": 150329,
                "word_type": "v. i.",
                "spelling_usage": "To go too far; to put any one to inconvenience by\n   demand or importunity; to intrude; as, to trespass upon the time or\n   patience of another."
            },
            {
                "spelling_detail_id": 150330,
                "word_type": "v. i.",
                "spelling_usage": "To commit any offense, or to do any act that injures\n   or annoys another; to violate any rule of rectitude, to the injury of\n   another; hence, in a moral sense, to transgress voluntarily any divine\n   law or command; to violate any known rule of duty; to sin; -- often\n   followed by against."
            },
            {
                "spelling_detail_id": 150331,
                "word_type": "v.",
                "spelling_usage": "Any injury or offence done to another."
            },
            {
                "spelling_detail_id": 150332,
                "word_type": "v.",
                "spelling_usage": "Any voluntary transgression of the moral law; any\n   violation of a known rule of duty; sin."
            },
            {
                "spelling_detail_id": 150333,
                "word_type": "v.",
                "spelling_usage": "An unlawful act committed with force and violence (vi et\n   armis) on the person, property, or relative rights of another."
            },
            {
                "spelling_detail_id": 150334,
                "word_type": "v.",
                "spelling_usage": "An action for injuries accompanied with force."
            }
        ]
    },
    {
        "id": 94852,
        "spelling_name": "Tributary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150458,
                "word_type": "a.",
                "spelling_usage": "Paying tribute to another, either from compulsion, as an\n   acknowledgment of submission, or to secure protection, or for the\n   purpose of purchasing peace."
            },
            {
                "spelling_detail_id": 150459,
                "word_type": "a.",
                "spelling_usage": "Hence, subject; subordinate; inferior."
            },
            {
                "spelling_detail_id": 150460,
                "word_type": "a.",
                "spelling_usage": "Paid in tribute."
            },
            {
                "spelling_detail_id": 150461,
                "word_type": "a.",
                "spelling_usage": "Yielding supplies of any kind; serving to form or make\n   up, a greater object of the same kind, as a part, branch, etc.;\n   contributing; as, the Ohio has many tributary streams, and is itself\n   tributary to the Mississippi."
            },
            {
                "spelling_detail_id": 150463,
                "word_type": "n.",
                "spelling_usage": "A ruler or state that pays tribute, or a stated sum, to\n   a conquering power, for the purpose of securing peace and protection,\n   or as an acknowledgment of submission, or for the purchase of security."
            },
            {
                "spelling_detail_id": 150464,
                "word_type": "n.",
                "spelling_usage": "A stream or river flowing into a larger river or into a\n   lake; an affluent."
            }
        ]
    },
    {
        "id": 94976,
        "spelling_name": "Trifle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150624,
                "word_type": "n.",
                "spelling_usage": "A thing of very little value or importance; a paltry, or\n   trivial, affair."
            },
            {
                "spelling_detail_id": 150625,
                "word_type": "n.",
                "spelling_usage": "A dish composed of sweetmeats, fruits, cake, wine, etc.,\n   with syllabub poured over it."
            },
            {
                "spelling_detail_id": 150628,
                "word_type": "n.",
                "spelling_usage": "To act or talk without seriousness, gravity, weight, or\n   dignity; to act or talk with levity; to indulge in light or trivial\n   amusements."
            },
            {
                "spelling_detail_id": 150629,
                "word_type": "v. t.",
                "spelling_usage": "To make of no importance; to treat as a trifle."
            },
            {
                "spelling_detail_id": 150630,
                "word_type": "v. t.",
                "spelling_usage": "To spend in vanity; to fritter away; to waste; as, to\n   trifle away money."
            }
        ]
    },
    {
        "id": 94988,
        "spelling_name": "Triforium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150641,
                "word_type": "n.",
                "spelling_usage": "The gallery or open space between the vaulting and the\n   roof of the aisles of a church, often forming a rich arcade in the\n   interior of the church, above the nave arches and below the clearstory\n   windows."
            }
        ]
    },
    {
        "id": 95016,
        "spelling_name": "Trigonometry",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150680,
                "word_type": "n.",
                "spelling_usage": "That branch of mathematics which treats of the\n   relations of the sides and angles of triangles, which the methods of\n   deducing from certain given parts other required parts, and also of the\n   general relations which exist between the trigonometrical functions of\n   arcs or angles."
            },
            {
                "spelling_detail_id": 150681,
                "word_type": "n.",
                "spelling_usage": "A treatise in this science."
            }
        ]
    },
    {
        "id": 95050,
        "spelling_name": "Trillium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150725,
                "word_type": "n.",
                "spelling_usage": "A genus of liliaceous plants; the three-leaved\n   nightshade; -- so called because all the parts of the plant are in\n   threes."
            }
        ]
    },
    {
        "id": 95135,
        "spelling_name": "Tripartite",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150868,
                "word_type": "v. i.",
                "spelling_usage": "Divided into three parts; triparted; as, a\n   tripartite leaf."
            },
            {
                "spelling_detail_id": 150869,
                "word_type": "v. i.",
                "spelling_usage": "Having three corresponding parts or copies; as, to\n   make indentures tripartite."
            },
            {
                "spelling_detail_id": 150870,
                "word_type": "v. i.",
                "spelling_usage": "Made between three parties; as, a tripartite treaty."
            }
        ]
    },
    {
        "id": 95225,
        "spelling_name": "Triste",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 150983,
                "word_type": "imp.",
                "spelling_usage": "of Trist"
            },
            {
                "spelling_detail_id": 150989,
                "word_type": "n.",
                "spelling_usage": "A cattle fair."
            }
        ]
    },
    {
        "id": 95265,
        "spelling_name": "Trituration",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151032,
                "word_type": "n.",
                "spelling_usage": "The act of triturating, or reducing to a fine or\n   impalpable powder by grinding, rubbing, bruising, etc."
            }
        ]
    },
    {
        "id": 95393,
        "spelling_name": "Tropical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151236,
                "word_type": "n.",
                "spelling_usage": "Of or pertaining to the tropics; characteristic of, or\n   incident to, the tropics; being within the tropics; as, tropical\n   climate; tropical latitudes; tropical heat; tropical diseases."
            },
            {
                "spelling_detail_id": 151237,
                "word_type": "n.",
                "spelling_usage": "Rhetorically changed from its exact original sense; being\n   of the nature of a trope; figurative; metaphorical."
            }
        ]
    },
    {
        "id": 95425,
        "spelling_name": "Trounce",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151289,
                "word_type": "v. t.",
                "spelling_usage": "To punish or beat severely; to whip smartly; to flog;\n   to castigate."
            }
        ]
    },
    {
        "id": 95496,
        "spelling_name": "Truffle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151392,
                "word_type": "n.",
                "spelling_usage": "Any one of several kinds of roundish, subterranean fungi,\n   usually of a blackish color. The French truffle (Tuber melanosporum)\n   and the English truffle (T. aestivum) are much esteemed as articles of\n   food."
            }
        ]
    },
    {
        "id": 95526,
        "spelling_name": "Truncheon",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151458,
                "word_type": "n.",
                "spelling_usage": "A short staff, a club; a cudgel; a shaft of a spear."
            },
            {
                "spelling_detail_id": 151459,
                "word_type": "n.",
                "spelling_usage": "A baton, or military staff of command."
            },
            {
                "spelling_detail_id": 151460,
                "word_type": "n.",
                "spelling_usage": "A stout stem, as of a tree, with the branches lopped\n   off, to produce rapid growth."
            },
            {
                "spelling_detail_id": 151461,
                "word_type": "v. t.",
                "spelling_usage": "To beat with a truncheon."
            }
        ]
    },
    {
        "id": 95644,
        "spelling_name": "Tubular",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151695,
                "word_type": "a.",
                "spelling_usage": "Having the form of a tube, or pipe; consisting of a pipe;\n   fistular; as, a tubular snout; a tubular calyx. Also, containing, or\n   provided with, tubes."
            }
        ]
    },
    {
        "id": 95700,
        "spelling_name": "Tuition",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151787,
                "word_type": "n.",
                "spelling_usage": "Superintending care over a young person; the particular\n   watch and care of a tutor or guardian over his pupil or ward;\n   guardianship."
            },
            {
                "spelling_detail_id": 151788,
                "word_type": "n.",
                "spelling_usage": "Especially, the act, art, or business of teaching;\n   instruction; as, children are sent to school for tuition; his tuition\n   was thorough."
            },
            {
                "spelling_detail_id": 151789,
                "word_type": "n.",
                "spelling_usage": "The money paid for instruction; the price or payment for\n   instruction."
            }
        ]
    },
    {
        "id": 95726,
        "spelling_name": "Tumbling",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151806,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Tumble"
            },
            {
                "spelling_detail_id": 151826,
                "word_type": "",
                "spelling_usage": "a. & vb. n. from Tumble, v."
            }
        ]
    },
    {
        "id": 95771,
        "spelling_name": "Tungsten",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151913,
                "word_type": "n.",
                "spelling_usage": "A rare element of the chromium group found in certain\n   minerals, as wolfram and scheelite, and isolated as a heavy steel-gray\n   metal which is very hard and infusible. It has both acid and basic\n   properties. When alloyed in small quantities with steel, it greatly\n   increases its hardness. Symbol W (Wolframium). Atomic weight, 183.6.\n   Specific gravity, 18."
            },
            {
                "spelling_detail_id": 151914,
                "word_type": "n.",
                "spelling_usage": "Scheelite, or calcium tungstate."
            }
        ]
    },
    {
        "id": 95802,
        "spelling_name": "Tupelo",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 151960,
                "word_type": "n.",
                "spelling_usage": "A North American tree (Nyssa multiflora) of the Dogwood\n   family, having brilliant, glossy foliage and acid red berries. The wood\n   is crossgrained and very difficult to split. Called also black gum,\n   sour gum, and pepperidge."
            }
        ]
    },
    {
        "id": 95939,
        "spelling_name": "Turpentine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152204,
                "word_type": "n.",
                "spelling_usage": "A semifluid or fluid oleoresin, primarily the exudation\n   of the terebinth, or turpentine, tree (Pistacia Terebinthus), a native\n   of the Mediterranean region. It is also obtained from many coniferous\n   trees, especially species of pine, larch, and fir."
            }
        ]
    },
    {
        "id": 95942,
        "spelling_name": "Turpitude",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152208,
                "word_type": "n.",
                "spelling_usage": "Inherent baseness or vileness of principle, words, or\n   actions; shameful wickedness; depravity."
            }
        ]
    },
    {
        "id": 95985,
        "spelling_name": "Tutelage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152266,
                "word_type": "n.",
                "spelling_usage": "The act of guarding or protecting; guardianship;\n   protection; as, the king's right of seigniory and tutelage."
            },
            {
                "spelling_detail_id": 152267,
                "word_type": "n.",
                "spelling_usage": "The state of being under a guardian; care or protection\n   enjoyed."
            }
        ]
    },
    {
        "id": 96072,
        "spelling_name": "Twilight",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152407,
                "word_type": "n.",
                "spelling_usage": "The light perceived before the rising, and after the\n   setting, of the sun, or when the sun is less than 18¡ below the\n   horizon, occasioned by the illumination of the earth's atmosphere by\n   the direct rays of the sun and their reflection on the earth."
            },
            {
                "spelling_detail_id": 152408,
                "word_type": "n.",
                "spelling_usage": "faint light; a dubious or uncertain medium through which\n   anything is viewed."
            },
            {
                "spelling_detail_id": 152409,
                "word_type": "a.",
                "spelling_usage": "Seen or done by twilight."
            },
            {
                "spelling_detail_id": 152410,
                "word_type": "a.",
                "spelling_usage": "Imperfectly illuminated; shaded; obscure."
            }
        ]
    },
    {
        "id": 96178,
        "spelling_name": "Tympanum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152626,
                "word_type": "n.",
                "spelling_usage": "The ear drum, or middle ear. Sometimes applied\n   incorrectly to the tympanic membrane. See Ear."
            },
            {
                "spelling_detail_id": 152627,
                "word_type": "n.",
                "spelling_usage": "A chamber in the anterior part of the syrinx of birds."
            },
            {
                "spelling_detail_id": 152628,
                "word_type": "n.",
                "spelling_usage": "One of the naked, inflatable air sacs on the neck of the\n   prairie chicken and other species of grouse."
            },
            {
                "spelling_detail_id": 152629,
                "word_type": "n.",
                "spelling_usage": "The recessed face of a pediment within the frame made by\n   the upper and lower cornices, being usually a triangular space or\n   table."
            },
            {
                "spelling_detail_id": 152630,
                "word_type": "n.",
                "spelling_usage": "The space within an arch, and above a lintel or a\n   subordinate arch, spanning the opening below the arch."
            },
            {
                "spelling_detail_id": 152631,
                "word_type": "n.",
                "spelling_usage": "A drum-shaped wheel with spirally curved partitions by\n   which water is raised to the axis when the wheel revolves with the\n   lower part of the circumference submerged, -- used for raising water,\n   as for irrigation."
            }
        ]
    },
    {
        "id": 96224,
        "spelling_name": "Tyrannical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152697,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a tyrant; suiting a tyrant;\n   unjustly severe in government; absolute; imperious; despotic; cruel;\n   arbitrary; as, a tyrannical prince; a tyrannical master; tyrannical\n   government."
            }
        ]
    },
    {
        "id": 96324,
        "spelling_name": "Ulterior",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152826,
                "word_type": "a.",
                "spelling_usage": "Situated beyond, or on the farther side; thither; --\n   correlative with hither."
            },
            {
                "spelling_detail_id": 152827,
                "word_type": "a.",
                "spelling_usage": "Further; remoter; more distant; succeeding; as, ulterior\n   demands or propositions; ulterior views; what ulterior measures will be\n   adopted is uncertain."
            },
            {
                "spelling_detail_id": 152828,
                "word_type": "n.",
                "spelling_usage": "Ulterior side or part."
            }
        ]
    },
    {
        "id": 96394,
        "spelling_name": "Umbrage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152928,
                "word_type": "n.",
                "spelling_usage": "Shade; shadow; obscurity; hence, that which affords a\n   shade, as a screen of trees or foliage."
            },
            {
                "spelling_detail_id": 152929,
                "word_type": "n.",
                "spelling_usage": "Shadowy resemblance; shadow."
            },
            {
                "spelling_detail_id": 152930,
                "word_type": "n.",
                "spelling_usage": "The feeling of being overshadowed; jealousy of another, as\n   standing in one's light or way; hence, suspicion of injury or wrong;\n   offense; resentment."
            }
        ]
    },
    {
        "id": 96415,
        "spelling_name": "Umpirage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 152955,
                "word_type": "n.",
                "spelling_usage": "The office of an umpire; the power, right, or authority\n   of an umpire to decide."
            },
            {
                "spelling_detail_id": 152956,
                "word_type": "n.",
                "spelling_usage": "The act of umpiring; arbitrament."
            }
        ]
    },
    {
        "id": 96470,
        "spelling_name": "Unanimous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 153030,
                "word_type": "a.",
                "spelling_usage": "Being of one mind; agreeing in opinion, design, or\n   determination; consentient; not discordant or dissentient; harmonious;\n   as, the assembly was unanimous; the members of the council were\n   unanimous."
            },
            {
                "spelling_detail_id": 153031,
                "word_type": "a.",
                "spelling_usage": "Formed with unanimity; indicating unanimity; having the\n   agreement and consent of all; agreed upon without the opposition or\n   contradiction of any; as, a unanimous opinion; a unanimous vote."
            }
        ]
    },
    {
        "id": 96757,
        "spelling_name": "Unconscionable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 153417,
                "word_type": "a.",
                "spelling_usage": "Not conscionable; not conforming to reason;\n   unreasonable; exceeding the limits of any reasonable claim or\n   expectation; inordinate; as, an unconscionable person or demand;\n   unconscionable size."
            },
            {
                "spelling_detail_id": 153418,
                "word_type": "a.",
                "spelling_usage": "Not guided by, or conformed to, conscience."
            }
        ]
    },
    {
        "id": 96789,
        "spelling_name": "Uncouth",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 153457,
                "word_type": "a.",
                "spelling_usage": "Unknown."
            },
            {
                "spelling_detail_id": 153458,
                "word_type": "a.",
                "spelling_usage": "Uncommon; rare; exquisite; elegant."
            },
            {
                "spelling_detail_id": 153459,
                "word_type": "a.",
                "spelling_usage": "Unfamiliar; strange; hence, mysterious; dreadful; also,\n   odd; awkward; boorish; as, uncouth manners."
            }
        ]
    },
    {
        "id": 96807,
        "spelling_name": "Unctuous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 153489,
                "word_type": "a.",
                "spelling_usage": "Of the nature or quality of an unguent or ointment;\n   fatty; oily; greasy."
            },
            {
                "spelling_detail_id": 153490,
                "word_type": "a.",
                "spelling_usage": "Having a smooth, greasy feel, as certain minerals."
            },
            {
                "spelling_detail_id": 153491,
                "word_type": "a.",
                "spelling_usage": "Bland; suave; also, tender; fervid; as, an unctuous\n   speech; sometimes, insincerely suave or fervid."
            }
        ]
    },
    {
        "id": 96924,
        "spelling_name": "Undergird",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 153638,
                "word_type": "v. t.",
                "spelling_usage": "To blind below; to gird round the bottom."
            }
        ]
    },
    {
        "id": 97307,
        "spelling_name": "Unfurl",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 154178,
                "word_type": "v. t. & i.",
                "spelling_usage": "To loose from a furled state; to unfold; to expand;\n   to open or spread; as, to unfurl sails; to unfurl a flag."
            }
        ]
    },
    {
        "id": 97557,
        "spelling_name": "University",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 154530,
                "word_type": "n.",
                "spelling_usage": "The universe; the whole."
            },
            {
                "spelling_detail_id": 154531,
                "word_type": "n.",
                "spelling_usage": "An association, society, guild, or corporation, esp.\n   one capable of having and acquiring property."
            },
            {
                "spelling_detail_id": 154532,
                "word_type": "n.",
                "spelling_usage": "An institution organized and incorporated for the\n   purpose of imparting instruction, examining students, and otherwise\n   promoting education in the higher branches of literature, science, art,\n   etc., empowered to confer degrees in the several arts and faculties, as\n   in theology, law, medicine, music, etc. A university may exist without\n   having any college connected with it, or it may consist of but one\n   college, or it may comprise an assemblage of colleges established in\n   any place, with professors for instructing students in the sciences and\n   other branches of learning."
            }
        ]
    },
    {
        "id": 97562,
        "spelling_name": "Univocal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 154537,
                "word_type": "a.",
                "spelling_usage": "Having one meaning only; -- contrasted with equivocal."
            },
            {
                "spelling_detail_id": 154538,
                "word_type": "a.",
                "spelling_usage": "Having unison of sound, as the octave in music. See\n   Unison, n., 2."
            },
            {
                "spelling_detail_id": 154539,
                "word_type": "n.",
                "spelling_usage": "Having always the same drift or tenor; uniform; certain;\n   regular."
            },
            {
                "spelling_detail_id": 154540,
                "word_type": "n.",
                "spelling_usage": "Unequivocal; indubitable."
            },
            {
                "spelling_detail_id": 154541,
                "word_type": "n.",
                "spelling_usage": "A generic term, or a term applicable in the same sense to\n   all the species it embraces."
            },
            {
                "spelling_detail_id": 154542,
                "word_type": "n.",
                "spelling_usage": "A word having but one meaning."
            }
        ]
    },
    {
        "id": 97574,
        "spelling_name": "Unkempt",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 154557,
                "word_type": "a.",
                "spelling_usage": "Not combed; disheveled; as, an urchin with unkempt hair."
            },
            {
                "spelling_detail_id": 154558,
                "word_type": "a.",
                "spelling_usage": "Fig.; Not smoothed; unpolished; rough."
            }
        ]
    },
    {
        "id": 98299,
        "spelling_name": "Upbraid",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 155486,
                "word_type": "v. t.",
                "spelling_usage": "To charge with something wrong or disgraceful; to\n   reproach; to cast something in the teeth of; -- followed by with or\n   for, and formerly of, before the thing imputed."
            },
            {
                "spelling_detail_id": 155487,
                "word_type": "v. t.",
                "spelling_usage": "To reprove severely; to rebuke; to chide."
            },
            {
                "spelling_detail_id": 155488,
                "word_type": "v. t.",
                "spelling_usage": "To treat with contempt."
            },
            {
                "spelling_detail_id": 155489,
                "word_type": "v. t.",
                "spelling_usage": "To object or urge as a matter of reproach; to cast up;\n   -- with to before the person."
            },
            {
                "spelling_detail_id": 155490,
                "word_type": "v. i.",
                "spelling_usage": "To utter upbraidings."
            },
            {
                "spelling_detail_id": 155491,
                "word_type": "n.",
                "spelling_usage": "The act of reproaching; contumely."
            }
        ]
    },
    {
        "id": 98464,
        "spelling_name": "Uranium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 155717,
                "word_type": "n.",
                "spelling_usage": "An element of the chromium group, found in certain rare\n   minerals, as pitchblende, uranite, etc., and reduced as a heavy, hard,\n   nickel-white metal which is quite permanent. Its yellow oxide is used\n   to impart to glass a delicate greenish-yellow tint which is accompanied\n   by a strong fluorescence, and its black oxide is used as a pigment in\n   porcelain painting. Symbol U. Atomic weight 239."
            }
        ]
    },
    {
        "id": 98598,
        "spelling_name": "Ursine",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 155885,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a bear; resembling a bear."
            }
        ]
    },
    {
        "id": 98664,
        "spelling_name": "Usurper",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 155988,
                "word_type": "n.",
                "spelling_usage": "One who usurps; especially, one who seizes illegally on\n   sovereign power; as, the usurper of a throne, of power, or of the\n   rights of a patron."
            }
        ]
    },
    {
        "id": 98680,
        "spelling_name": "Utilitarian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156007,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to utility; consisting in utility;\n   /iming at utility as distinguished from beauty, ornament, etc.;\n   sometimes, reproachfully, evincing, or characterized by, a regard for\n   utility of a lower kind, or marked by a sordid spirit; as, utilitarian\n   narrowness; a utilitarian indifference to art."
            },
            {
                "spelling_detail_id": 156008,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to utilitarianism; supporting\n   utilitarianism; as, the utilitarian view of morality; the Utilitarian\n   Society."
            },
            {
                "spelling_detail_id": 156009,
                "word_type": "n.",
                "spelling_usage": "One who holds the doctrine of utilitarianism."
            }
        ]
    },
    {
        "id": 98726,
        "spelling_name": "Uvula",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156082,
                "word_type": "n.",
                "spelling_usage": "The pendent fleshy lobe in the middle of the posterior\n   border of the soft palate."
            }
        ]
    },
    {
        "id": 98764,
        "spelling_name": "Vacillate",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156139,
                "word_type": "v. t.",
                "spelling_usage": "To move one way and the other; to reel or stagger; to\n   waver."
            },
            {
                "spelling_detail_id": 156140,
                "word_type": "v. t.",
                "spelling_usage": "To fluctuate in mind or opinion; to be unsteady or\n   inconstant; to waver."
            }
        ]
    },
    {
        "id": 98779,
        "spelling_name": "Vacuousness",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156155,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being vacuous; emptiness;\n   vacuity."
            }
        ]
    },
    {
        "id": 98821,
        "spelling_name": "Vague",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156212,
                "word_type": "v. i.",
                "spelling_usage": "Wandering; vagrant; vagabond."
            },
            {
                "spelling_detail_id": 156213,
                "word_type": "v. i.",
                "spelling_usage": "Unsettled; unfixed; undetermined; indefinite; ambiguous;\n   as, a vague idea; a vague proposition."
            },
            {
                "spelling_detail_id": 156214,
                "word_type": "v. i.",
                "spelling_usage": "Proceeding from no known authority; unauthenticated;\n   uncertain; flying; as, a vague report."
            },
            {
                "spelling_detail_id": 156215,
                "word_type": "n.",
                "spelling_usage": "An indefinite expanse."
            },
            {
                "spelling_detail_id": 156216,
                "word_type": "v. i.",
                "spelling_usage": "To wander; to roam; to stray."
            },
            {
                "spelling_detail_id": 156217,
                "word_type": "n.",
                "spelling_usage": "A wandering; a vagary."
            }
        ]
    },
    {
        "id": 98829,
        "spelling_name": "Vainglorious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156237,
                "word_type": "a.",
                "spelling_usage": "Feeling or indicating vainglory; elated by vanity;\n   boastful."
            }
        ]
    },
    {
        "id": 98845,
        "spelling_name": "Valedictorian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156256,
                "word_type": "n.",
                "spelling_usage": "One who pronounces a valedictory address;\n   especially, in American colleges, the student who pronounces the\n   valedictory of the graduating class at the annual commencement, usually\n   the student who ranks first in scholarship."
            }
        ]
    },
    {
        "id": 98904,
        "spelling_name": "Valorous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156335,
                "word_type": "a.",
                "spelling_usage": "Possessing or exhibiting valor; brave; courageous;\n   valiant; intrepid."
            }
        ]
    },
    {
        "id": 98910,
        "spelling_name": "Valuator",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156344,
                "word_type": "n.",
                "spelling_usage": "One who assesses, or sets a value on, anything; an\n   appraiser."
            }
        ]
    },
    {
        "id": 98959,
        "spelling_name": "Vanguard",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156438,
                "word_type": "n.",
                "spelling_usage": "The troops who march in front of an army; the advance\n   guard; the van."
            }
        ]
    },
    {
        "id": 98977,
        "spelling_name": "Vanquish",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156465,
                "word_type": "v. t.",
                "spelling_usage": "To conquer, overcome, or subdue in battle, as an\n   enemy."
            },
            {
                "spelling_detail_id": 156466,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to defeat in any contest; to get the better of;\n   to put down; to refute."
            },
            {
                "spelling_detail_id": 156467,
                "word_type": "n.",
                "spelling_usage": "A disease in sheep, in which they pine away."
            }
        ]
    },
    {
        "id": 98986,
        "spelling_name": "Vantage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156473,
                "word_type": "n.",
                "spelling_usage": "superior or more favorable situation or opportunity; gain;\n   profit; advantage."
            },
            {
                "spelling_detail_id": 156474,
                "word_type": "n.",
                "spelling_usage": "The first point after deuce."
            },
            {
                "spelling_detail_id": 156475,
                "word_type": "v. t.",
                "spelling_usage": "To profit; to aid."
            }
        ]
    },
    {
        "id": 99033,
        "spelling_name": "Varicella",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156556,
                "word_type": "n.",
                "spelling_usage": "Chicken pox."
            }
        ]
    },
    {
        "id": 99037,
        "spelling_name": "Varicose",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156560,
                "word_type": "a.",
                "spelling_usage": "Irregularly swollen or enlarged; affected with, or\n   containing, varices, or varicosities; of or pertaining to varices, or\n   varicosities; as, a varicose nerve fiber; a varicose vein; varicose\n   ulcers."
            },
            {
                "spelling_detail_id": 156561,
                "word_type": "a.",
                "spelling_usage": "Intended for the treatment of varicose veins; -- said of\n   elastic stockings, bandages. and the like."
            }
        ]
    },
    {
        "id": 99042,
        "spelling_name": "Variegated",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156566,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Variegate"
            },
            {
                "spelling_detail_id": 156569,
                "word_type": "a.",
                "spelling_usage": "Having marks or patches of different colors; as,\n   variegated leaves, or flowers."
            }
        ]
    },
    {
        "id": 99084,
        "spelling_name": "Vascular",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156639,
                "word_type": "a.",
                "spelling_usage": "Consisting of, or containing, vessels as an essential\n   part of a structure; full of vessels; specifically (Bot.), pertaining\n   to, or containing, special ducts, or tubes, for the circulation of sap."
            },
            {
                "spelling_detail_id": 156640,
                "word_type": "a.",
                "spelling_usage": "Operating by means of, or made up of an arrangement of,\n   vessels; as, the vascular system in animals, including the arteries,\n   veins, capillaries, lacteals, etc."
            },
            {
                "spelling_detail_id": 156641,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the vessels of animal and vegetable\n   bodies; as, the vascular functions."
            },
            {
                "spelling_detail_id": 156642,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the higher division of plants, that\n   is, the phaenogamous plants, all of which are vascular, in distinction\n   from the cryptogams, which to a large extent are cellular only."
            }
        ]
    },
    {
        "id": 99099,
        "spelling_name": "Vassal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156663,
                "word_type": "n.",
                "spelling_usage": "The grantee of a fief, feud, or fee; one who holds land of\n   superior, and who vows fidelity and homage to him; a feudatory; a\n   feudal tenant."
            },
            {
                "spelling_detail_id": 156664,
                "word_type": "n.",
                "spelling_usage": "A subject; a dependent; a servant; a slave."
            },
            {
                "spelling_detail_id": 156665,
                "word_type": "a.",
                "spelling_usage": "Resembling a vassal; slavish; servile."
            },
            {
                "spelling_detail_id": 156666,
                "word_type": "v. t.",
                "spelling_usage": "To treat as a vassal; to subject to control; to enslave."
            }
        ]
    },
    {
        "id": 99117,
        "spelling_name": "Vatican",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156700,
                "word_type": "n.",
                "spelling_usage": "A magnificent assemblage of buildings at Rome, near the\n   church of St. Peter, including the pope's palace, a museum, a library,\n   a famous chapel, etc."
            }
        ]
    },
    {
        "id": 99128,
        "spelling_name": "Vaudeville",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156709,
                "word_type": "n.",
                "spelling_usage": "A kind of song of a lively character, frequently\n   embodying a satire on some person or event, sung to a familiar air in\n   couplets with a refrain; a street song; a topical song."
            },
            {
                "spelling_detail_id": 156710,
                "word_type": "n.",
                "spelling_usage": "A theatrical piece, usually a comedy, the dialogue of\n   which is intermingled with light or satirical songs, set to familiar\n   airs."
            }
        ]
    },
    {
        "id": 99174,
        "spelling_name": "Vegetarian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156787,
                "word_type": "n.",
                "spelling_usage": "One who holds that vegetables and fruits are the only\n   proper food for man. Strict vegetarians eat no meat, eggs, or milk."
            },
            {
                "spelling_detail_id": 156788,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to vegetarianism; as, a vegetarian\n   diet."
            }
        ]
    },
    {
        "id": 99185,
        "spelling_name": "Vehemence",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156806,
                "word_type": "n.",
                "spelling_usage": "The quality pr state of being vehement; impetuous force;\n   impetuosity; violence; fury; as, the vehemence."
            },
            {
                "spelling_detail_id": 156807,
                "word_type": "n.",
                "spelling_usage": "Violent ardor; great heat; animated fervor; as, the\n   vehemence of love, anger, or other passions."
            }
        ]
    },
    {
        "id": 99231,
        "spelling_name": "Vellum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156878,
                "word_type": "n.",
                "spelling_usage": "A fine kind of parchment, usually made from calfskin, and\n   rendered clear and white, -- used as for writing upon, and for binding\n   books."
            }
        ]
    },
    {
        "id": 99294,
        "spelling_name": "Venerable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156964,
                "word_type": "a.",
                "spelling_usage": "Capable of being venerated; worthy of veneration or\n   reverence; deserving of honor and respect; -- generally implying an\n   advanced age; as, a venerable magistrate; a venerable parent."
            },
            {
                "spelling_detail_id": 156965,
                "word_type": "a.",
                "spelling_usage": "Rendered sacred by religious or other associations; that\n   should be regarded with awe and treated with reverence; as, the\n   venerable walls of a temple or a church."
            }
        ]
    },
    {
        "id": 99312,
        "spelling_name": "Vengeance",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156991,
                "word_type": "n.",
                "spelling_usage": "Punishment inflicted in return for an injury or an\n   offense; retribution; -- often, in a bad sense, passionate or\n   unrestrained revenge."
            },
            {
                "spelling_detail_id": 156992,
                "word_type": "n.",
                "spelling_usage": "Harm; mischief."
            }
        ]
    },
    {
        "id": 99318,
        "spelling_name": "Venial",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 156998,
                "word_type": "a.",
                "spelling_usage": "Capable of being forgiven; not heinous; excusable;\n   pardonable; as, a venial fault or transgression."
            },
            {
                "spelling_detail_id": 156999,
                "word_type": "a.",
                "spelling_usage": "Allowed; permitted."
            }
        ]
    },
    {
        "id": 99348,
        "spelling_name": "Ventricle",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157070,
                "word_type": "n.",
                "spelling_usage": "A cavity, or one of the cavities, of an organ, as of the\n   larynx or the brain; specifically, the posterior chamber, or one of the\n   two posterior chambers, of the heart, which receives the blood from the\n   auricle and forces it out from the heart. See Heart."
            },
            {
                "spelling_detail_id": 157071,
                "word_type": "n.",
                "spelling_usage": "The stomach."
            },
            {
                "spelling_detail_id": 157072,
                "word_type": "n.",
                "spelling_usage": "Fig.: Any cavity, or hollow place, in which any function\n   may be conceived of as operating."
            }
        ]
    },
    {
        "id": 99364,
        "spelling_name": "Ventriloquy",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157089,
                "word_type": "n.",
                "spelling_usage": "Same as Ventriloquism."
            }
        ]
    },
    {
        "id": 99369,
        "spelling_name": "Ventured",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157096,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Venture"
            }
        ]
    },
    {
        "id": 99376,
        "spelling_name": "Venue",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157109,
                "word_type": "n.",
                "spelling_usage": "A neighborhood or near place; the place or county in which\n   anything is alleged to have happened; also, the place where an action\n   is laid."
            },
            {
                "spelling_detail_id": 157110,
                "word_type": "n.",
                "spelling_usage": "A bout; a hit; a turn. See Venew."
            }
        ]
    },
    {
        "id": 99383,
        "spelling_name": "Veracity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157121,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being veracious; habitual\n   observance of truth; truthfulness; truth; as, a man of veracity."
            }
        ]
    },
    {
        "id": 99405,
        "spelling_name": "Verbatim",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157152,
                "word_type": "adv.",
                "spelling_usage": "Word for word; in the same words; verbally; as, to tell\n   a story verbatim as another has related it."
            }
        ]
    },
    {
        "id": 99406,
        "spelling_name": "Verbena",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157153,
                "word_type": "n.",
                "spelling_usage": "A genus of herbaceous plants of which several species are\n   extensively cultivated for the great beauty of their flowers; vervain."
            }
        ]
    },
    {
        "id": 99464,
        "spelling_name": "Veritable",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157245,
                "word_type": "a.",
                "spelling_usage": "Agreeable to truth or to fact; actual; real; true;\n   genuine."
            }
        ]
    },
    {
        "id": 99476,
        "spelling_name": "Vermicide",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157262,
                "word_type": "n.",
                "spelling_usage": "A medicine which destroys intestinal worms; a worm\n   killer."
            }
        ]
    },
    {
        "id": 99511,
        "spelling_name": "Vernal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157312,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the spring; appearing in the spring;\n   as, vernal bloom."
            },
            {
                "spelling_detail_id": 157313,
                "word_type": "a.",
                "spelling_usage": "Fig.: Belonging to youth, the spring of life."
            }
        ]
    },
    {
        "id": 99540,
        "spelling_name": "Versatile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157344,
                "word_type": "a.",
                "spelling_usage": "Capable of being turned round."
            },
            {
                "spelling_detail_id": 157345,
                "word_type": "a.",
                "spelling_usage": "Liable to be turned in opinion; changeable; variable;\n   unsteady; inconstant; as versatile disposition."
            },
            {
                "spelling_detail_id": 157346,
                "word_type": "a.",
                "spelling_usage": "Turning with ease from one thing to another; readily\n   applied to a new task, or to various subjects; many-sided; as,\n   versatile genius; a versatile politician."
            },
            {
                "spelling_detail_id": 157347,
                "word_type": "a.",
                "spelling_usage": "Capable of turning; freely movable; as, a versatile\n   anther, which is fixed at one point to the filament, and hence is very\n   easily turned around; a versatile toe of a bird."
            }
        ]
    },
    {
        "id": 99598,
        "spelling_name": "Vertigo",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157439,
                "word_type": "n.",
                "spelling_usage": "Dizziness or swimming of the head; an affection of the\n   head in which objects, though stationary, appear to move in various\n   directions, and the person affected finds it difficult to maintain an\n   erect posture; giddiness."
            },
            {
                "spelling_detail_id": 157440,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of small land snails belonging\n   to the genus Vertigo, having an elongated or conical spiral shell and\n   usually teeth in the aperture."
            }
        ]
    },
    {
        "id": 99661,
        "spelling_name": "Vestibule",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157536,
                "word_type": "n.",
                "spelling_usage": "The porch or entrance into a house; a hall or\n   antechamber next the entrance; a lobby; a porch; a hall."
            }
        ]
    },
    {
        "id": 99686,
        "spelling_name": "Vetiver",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157570,
                "word_type": "n.",
                "spelling_usage": "An East Indian grass (Andropogon muricatus); also, its\n   fragrant roots which are much used for making mats and screens. Also\n   called kuskus, and khuskhus."
            }
        ]
    },
    {
        "id": 99699,
        "spelling_name": "Vexatious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157595,
                "word_type": "a.",
                "spelling_usage": "Causing vexation; agitating; afflictive; annoying; as, a\n   vexatious controversy; a vexatious neighbor."
            },
            {
                "spelling_detail_id": 157596,
                "word_type": "a.",
                "spelling_usage": "Full or vexation, trouble, or disquiet; disturbed."
            }
        ]
    },
    {
        "id": 99727,
        "spelling_name": "Viaticum",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157636,
                "word_type": "n.",
                "spelling_usage": "An allowance for traveling expenses made to those who\n   were sent into the provinces to exercise any office or perform any\n   service."
            },
            {
                "spelling_detail_id": 157637,
                "word_type": "n.",
                "spelling_usage": "Provisions for a journey."
            },
            {
                "spelling_detail_id": 157638,
                "word_type": "n.",
                "spelling_usage": "The communion, or eucharist, when given to persons in\n   danger of death."
            }
        ]
    },
    {
        "id": 99754,
        "spelling_name": "Vicarious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157679,
                "word_type": "prep.",
                "spelling_usage": "Of or pertaining to a vicar, substitute, or deputy;\n   deputed; delegated; as, vicarious power or authority."
            },
            {
                "spelling_detail_id": 157680,
                "word_type": "prep.",
                "spelling_usage": "Acting of suffering for another; as, a vicarious\n   agent or officer."
            },
            {
                "spelling_detail_id": 157681,
                "word_type": "prep.",
                "spelling_usage": "Performed of suffered in the place of another;\n   substituted; as, a vicarious sacrifice; vicarious punishment."
            },
            {
                "spelling_detail_id": 157682,
                "word_type": "prep.",
                "spelling_usage": "Acting as a substitute; -- said of abnormal action\n   which replaces a suppressed normal function; as, vicarious hemorrhage\n   replacing menstruation."
            }
        ]
    },
    {
        "id": 99776,
        "spelling_name": "Vicinity",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157719,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being near, or not remote;\n   nearness; propinquity; proximity; as, the value of the estate was\n   increased by the vicinity of two country seats."
            },
            {
                "spelling_detail_id": 157720,
                "word_type": "n.",
                "spelling_usage": "That which is near, or not remote; that which is adjacent\n   to anything; adjoining space or country; neighborhood."
            }
        ]
    },
    {
        "id": 99794,
        "spelling_name": "Victorian",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157751,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the reign of Queen Victoria of\n   England; as, the Victorian poets."
            }
        ]
    },
    {
        "id": 99848,
        "spelling_name": "Vignette",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157837,
                "word_type": "n.",
                "spelling_usage": "A running ornament consisting of leaves and tendrils,\n   used in Gothic architecture."
            },
            {
                "spelling_detail_id": 157838,
                "word_type": "n.",
                "spelling_usage": "A decorative design, originally representing vine\n   branches or tendrils, at the head of a chapter, of a manuscript or\n   printed book, or in a similar position; hence, by extension, any small\n   picture in a book; hence, also, as such pictures are often without a\n   definite bounding line, any picture, as an engraving, a photograph, or\n   the like, which vanishes gradually at the edge."
            },
            {
                "spelling_detail_id": 157839,
                "word_type": "v. t.",
                "spelling_usage": "To make, as an engraving or a photograph, with a\n   border or edge insensibly fading away."
            }
        ]
    },
    {
        "id": 99918,
        "spelling_name": "Vincible",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157936,
                "word_type": "a.",
                "spelling_usage": "Capable of being overcome or subdued; conquerable."
            }
        ]
    },
    {
        "id": 99935,
        "spelling_name": "Vindictive",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157964,
                "word_type": "a.",
                "spelling_usage": "Disposed to revenge; prompted or characterized by\n   revenge; revengeful."
            },
            {
                "spelling_detail_id": 157965,
                "word_type": "a.",
                "spelling_usage": "Punitive."
            }
        ]
    },
    {
        "id": 99941,
        "spelling_name": "Vinegar",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157972,
                "word_type": "a.",
                "spelling_usage": "A sour liquid used as a condiment, or as a preservative,\n   and obtained by the spontaneous (acetous) fermentation, or by the\n   artificial oxidation, of wine, cider, beer, or the like."
            },
            {
                "spelling_detail_id": 157973,
                "word_type": "a.",
                "spelling_usage": "Hence, anything sour; -- used also metaphorically."
            },
            {
                "spelling_detail_id": 157974,
                "word_type": "v. t.",
                "spelling_usage": "To convert into vinegar; to make like vinegar; to\n   render sour or sharp."
            }
        ]
    },
    {
        "id": 99948,
        "spelling_name": "Vineyard",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 157982,
                "word_type": "n.",
                "spelling_usage": "An inclosure or yard for grapevines; a plantation of\n   vines producing grapes."
            }
        ]
    },
    {
        "id": 99991,
        "spelling_name": "Violin",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158049,
                "word_type": "n.",
                "spelling_usage": "A small instrument with four strings, played with a bow; a\n   fiddle."
            }
        ]
    },
    {
        "id": 100052,
        "spelling_name": "Virtually",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158130,
                "word_type": "adv.",
                "spelling_usage": "In a virtual manner; in efficacy or effect only, and\n   not actually; to all intents and purposes; practically."
            }
        ]
    },
    {
        "id": 100062,
        "spelling_name": "Virulence",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158154,
                "word_type": "n.",
                "spelling_usage": "Alt. of Virulency"
            }
        ]
    },
    {
        "id": 100072,
        "spelling_name": "Visage",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158171,
                "word_type": "n.",
                "spelling_usage": "The face, countenance, or look of a person or an animal; --\n   chiefly applied to the human face."
            },
            {
                "spelling_detail_id": 158172,
                "word_type": "v. t.",
                "spelling_usage": "To face."
            }
        ]
    },
    {
        "id": 100089,
        "spelling_name": "Viscount",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158195,
                "word_type": "a.",
                "spelling_usage": "An officer who formerly supplied the place of the count,\n   or earl; the sheriff of the county."
            },
            {
                "spelling_detail_id": 158196,
                "word_type": "a.",
                "spelling_usage": "A nobleman of the fourth rank, next in order below an\n   earl and next above a baron; also, his degree or title of nobility. See\n   Peer, n., 3."
            }
        ]
    },
    {
        "id": 100173,
        "spelling_name": "Vitreous",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158320,
                "word_type": "a.",
                "spelling_usage": "Consisting of, or resembling, glass; glassy; as, vitreous\n   rocks."
            },
            {
                "spelling_detail_id": 158321,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to glass; derived from glass; as,\n   vitreous electricity."
            }
        ]
    },
    {
        "id": 100195,
        "spelling_name": "Vitriolic",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158350,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to vitriol; derived from, or\n   resembling, vitriol; vitriolous; as, a vitriolic taste. Cf. Vitriol."
            }
        ]
    },
    {
        "id": 100209,
        "spelling_name": "Vituperative",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158366,
                "word_type": "a.",
                "spelling_usage": "Uttering or writing censure; containing, or\n   characterized by, abuse; scolding; abusive."
            }
        ]
    },
    {
        "id": 100214,
        "spelling_name": "Vivacious",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158370,
                "word_type": "a.",
                "spelling_usage": "Having vigorous powers of life; tenacious of life;\n   long-lived."
            },
            {
                "spelling_detail_id": 158371,
                "word_type": "a.",
                "spelling_usage": "Sprightly in temper or conduct; lively; merry; as, a\n   vivacious poet."
            },
            {
                "spelling_detail_id": 158372,
                "word_type": "a.",
                "spelling_usage": "Living through the winter, or from year to year;\n   perennial."
            }
        ]
    },
    {
        "id": 100268,
        "spelling_name": "Vocabulary",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158435,
                "word_type": "n.",
                "spelling_usage": "A list or collection of words arranged in alphabetical\n   order and explained; a dictionary or lexicon, either of a whole\n   language, a single work or author, a branch of science, or the like; a\n   word-book."
            },
            {
                "spelling_detail_id": 158436,
                "word_type": "n.",
                "spelling_usage": "A sum or stock of words employed."
            }
        ]
    },
    {
        "id": 100322,
        "spelling_name": "Volatile",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158549,
                "word_type": "a.",
                "spelling_usage": "Passing through the air on wings, or by the buoyant force\n   of the atmosphere; flying; having the power to fly."
            },
            {
                "spelling_detail_id": 158550,
                "word_type": "a.",
                "spelling_usage": "Capable of wasting away, or of easily passing into the\n   aeriform state; subject to evaporation."
            },
            {
                "spelling_detail_id": 158551,
                "word_type": "a.",
                "spelling_usage": "Fig.: Light-hearted; easily affected by circumstances;\n   airy; lively; hence, changeable; fickle; as, a volatile temper."
            },
            {
                "spelling_detail_id": 158552,
                "word_type": "n.",
                "spelling_usage": "A winged animal; wild fowl; game."
            }
        ]
    },
    {
        "id": 100351,
        "spelling_name": "Volition",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 158588,
                "word_type": "n.",
                "spelling_usage": "The act of willing or choosing; the act of forming a\n   purpose; the exercise of the will."
            },
            {
                "spelling_detail_id": 158589,
                "word_type": "n.",
                "spelling_usage": "The result of an act or exercise of choosing or willing;\n   a state of choice."
            },
            {
                "spelling_detail_id": 158590,
                "word_type": "n.",
                "spelling_usage": "The power of willing or determining; will."
            }
        ]
    },
    {
        "id": 100659,
        "spelling_name": "Waiver",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 159095,
                "word_type": "n.",
                "spelling_usage": "The act of waiving, or not insisting on, some right, claim,\n   or privilege."
            }
        ]
    },
    {
        "id": 101365,
        "spelling_name": "Wearisome",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 160355,
                "word_type": "a.",
                "spelling_usage": "Causing weariness; tiresome; tedious; weariful; as, a\n   wearisome march; a wearisome day's work; a wearisome book."
            }
        ]
    },
    {
        "id": 101608,
        "spelling_name": "Wentletrap",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 160828,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of elegant, usually white,\n   marine shells of the genus Scalaria, especially Scalaria pretiosa,\n   which was formerly highly valued; -- called also staircase shell. See\n   Scalaria."
            }
        ]
    },
    {
        "id": 101691,
        "spelling_name": "Wharf",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 160962,
                "word_type": "n.",
                "spelling_usage": "A structure or platform of timber, masonry, iron, earth, or\n   other material, built on the shore of a harbor, river, canal, or the\n   like, and usually extending from the shore to deep water, so that\n   vessels may lie close alongside to receive and discharge cargo,\n   passengers, etc.; a quay; a pier."
            },
            {
                "spelling_detail_id": 160963,
                "word_type": "n.",
                "spelling_usage": "The bank of a river, or the shore of the sea."
            },
            {
                "spelling_detail_id": 160966,
                "word_type": "v. t.",
                "spelling_usage": "To guard or secure by a firm wall of timber or stone\n   constructed like a wharf; to furnish with a wharf or wharfs."
            },
            {
                "spelling_detail_id": 160967,
                "word_type": "v. t.",
                "spelling_usage": "To place upon a wharf; to bring to a wharf."
            }
        ]
    },
    {
        "id": 101790,
        "spelling_name": "Wherewithal",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 161144,
                "word_type": "adv. & n.",
                "spelling_usage": "Wherewith."
            }
        ]
    },
    {
        "id": 101795,
        "spelling_name": "Whet",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 161155,
                "word_type": "v. t.",
                "spelling_usage": "To rub or on with some substance, as a piece of stone, for\n   the purpose of sharpening; to sharpen by attrition; as, to whet a\n   knife."
            },
            {
                "spelling_detail_id": 161156,
                "word_type": "v. t.",
                "spelling_usage": "To make sharp, keen, or eager; to excite; to stimulate;\n   as, to whet the appetite or the courage."
            },
            {
                "spelling_detail_id": 161157,
                "word_type": "n.",
                "spelling_usage": "The act of whetting."
            },
            {
                "spelling_detail_id": 161158,
                "word_type": "n.",
                "spelling_usage": "That which whets or sharpens; esp., an appetizer."
            }
        ]
    },
    {
        "id": 101846,
        "spelling_name": "Whimper",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 161246,
                "word_type": "v. i.",
                "spelling_usage": "To cry with a low, whining, broken voice; to whine; to\n   complain; as, a child whimpers."
            },
            {
                "spelling_detail_id": 161247,
                "word_type": "v. t.",
                "spelling_usage": "To utter in alow, whining tone."
            },
            {
                "spelling_detail_id": 161248,
                "word_type": "n.",
                "spelling_usage": "A low, whining, broken cry; a low, whining sound,\n   expressive of complaint or grief."
            }
        ]
    },
    {
        "id": 101853,
        "spelling_name": "Whimsical",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 161258,
                "word_type": "a.",
                "spelling_usage": "Full of, or characterized by, whims; actuated by a whim;\n   having peculiar notions; queer; strange; freakish."
            },
            {
                "spelling_detail_id": 161259,
                "word_type": "a.",
                "spelling_usage": "Odd or fantastic in appearance; quaintly devised;\n   fantastic."
            }
        ]
    },
    {
        "id": 102173,
        "spelling_name": "Wildebeest",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 161838,
                "word_type": "n.",
                "spelling_usage": "The gnu."
            }
        ]
    },
    {
        "id": 102272,
        "spelling_name": "Window",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 162049,
                "word_type": "n.",
                "spelling_usage": "An opening in the wall of a building for the admission of\n   light and air, usually closed by casements or sashes containing some\n   transparent material, as glass, and capable of being opened and shut at\n   pleasure."
            },
            {
                "spelling_detail_id": 162050,
                "word_type": "n.",
                "spelling_usage": "The shutter, casement, sash with its fittings, or other\n   framework, which closes a window opening."
            },
            {
                "spelling_detail_id": 162051,
                "word_type": "n.",
                "spelling_usage": "A figure formed of lines crossing each other."
            },
            {
                "spelling_detail_id": 162054,
                "word_type": "v. t.",
                "spelling_usage": "To furnish with windows."
            },
            {
                "spelling_detail_id": 162055,
                "word_type": "v. t.",
                "spelling_usage": "To place at or in a window."
            }
        ]
    },
    {
        "id": 102377,
        "spelling_name": "Wisdom",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 162260,
                "word_type": "a.",
                "spelling_usage": "The quality of being wise; knowledge, and the capacity to\n   make due use of it; knowledge of the best ends and the best means;\n   discernment and judgment; discretion; sagacity; skill; dexterity."
            },
            {
                "spelling_detail_id": 162261,
                "word_type": "a.",
                "spelling_usage": "The results of wise judgments; scientific or practical\n   truth; acquired knowledge; erudition."
            }
        ]
    },
    {
        "id": 102844,
        "spelling_name": "Wraith",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 163041,
                "word_type": "n.",
                "spelling_usage": "An apparition of a person in his exact likeness, seen\n   before death, or a little after; hence, an apparition; a specter; a\n   vision; an unreal image."
            },
            {
                "spelling_detail_id": 163042,
                "word_type": "n.",
                "spelling_usage": "Sometimes, improperly, a spirit thought to preside over the\n   waters; -- called also water wraith."
            }
        ]
    },
    {
        "id": 103396,
        "spelling_name": "Yorkshire",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 163884,
                "word_type": "n.",
                "spelling_usage": "A county in the north of England."
            }
        ]
    },
    {
        "id": 103637,
        "spelling_name": "Zirconium",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 164170,
                "word_type": "n.",
                "spelling_usage": "A rare element of the carbon-silicon group, intermediate\n   between the metals and nonmetals, obtained from the mineral zircon as a\n   dark sooty powder, or as a gray metallic crystalline substance. Symbol\n   Zr. Atomic weight, 90.4."
            }
        ]
    },
    {
        "id": 103656,
        "spelling_name": "Zodiac",
        "image_path": null,
        "bee_type": 2,
        "spellingDetails": [
            {
                "spelling_detail_id": 164189,
                "word_type": "n.",
                "spelling_usage": "An imaginary belt in the heavens, 16¡ or 18¡ broad, in the\n   middle of which is the ecliptic, or sun's path. It comprises the twelve\n   constellations, which one constituted, and from which were named, the\n   twelve signs of the zodiac."
            },
            {
                "spelling_detail_id": 164190,
                "word_type": "n.",
                "spelling_usage": "A figure representing the signs, symbols, and\n   constellations of the zodiac."
            },
            {
                "spelling_detail_id": 164191,
                "word_type": "n.",
                "spelling_usage": "A girdle; a belt."
            }
        ]
    }
]