
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { Badge,OverlayTrigger, Container, Row, Col, Card} from 'react-bootstrap';


class HomeComponent extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            spellings:[]
        }
    }

    componentDidMount(){
    }

    render(){
        return (
            <div>
                <br/>
                <Container fluid="md">
                        {/* <Carousel>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="homepage2.jpg"
                                alt="Second slide"
                                />

                                <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="homepage3.jpg"
                                alt="Second slide"
                                />

                                <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </Carousel.Caption>
                            </Carousel.Item>                            
                        </Carousel> */}
                        <Card>
                            <Card.Img variant="top" src="homepage2.jpg" />
                        </Card>
                </Container>
            </div>
        )
    }
}


export default HomeComponent