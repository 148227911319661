export const GreList = [   
    {
        "id": 464,
        "spelling_name": "Baleful",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 561,
                "word_type": "a.",
                "spelling_usage": "Full of deadly or pernicious influence; destructive."
            },
            {
                "spelling_detail_id": 562,
                "word_type": "a.",
                "spelling_usage": "Full of grief or sorrow; woeful; sad."
            }
        ]
    },
    {
        "id": 473,
        "spelling_name": "Balk",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 570,
                "word_type": "v. i.",
                "spelling_usage": "A ridge of land left unplowed between furrows, or at the\n   end of a field; a piece missed by the plow slipping aside."
            },
            {
                "spelling_detail_id": 571,
                "word_type": "v. i.",
                "spelling_usage": "A great beam, rafter, or timber; esp., the tie-beam of a\n   house. The loft above was called \"the balks.\""
            },
            {
                "spelling_detail_id": 572,
                "word_type": "v. i.",
                "spelling_usage": "One of the beams connecting the successive supports of a\n   trestle bridge or bateau bridge."
            },
            {
                "spelling_detail_id": 573,
                "word_type": "v. i.",
                "spelling_usage": "A hindrance or disappointment; a check."
            },
            {
                "spelling_detail_id": 574,
                "word_type": "v. i.",
                "spelling_usage": "A sudden and obstinate stop; a failure."
            },
            {
                "spelling_detail_id": 575,
                "word_type": "v. i.",
                "spelling_usage": "A deceptive gesture of the pitcher, as if to deliver the\n   ball."
            },
            {
                "spelling_detail_id": 578,
                "word_type": "v. t.",
                "spelling_usage": "To leave or make balks in."
            },
            {
                "spelling_detail_id": 579,
                "word_type": "v. t.",
                "spelling_usage": "To leave heaped up; to heap up in piles."
            },
            {
                "spelling_detail_id": 580,
                "word_type": "v. t.",
                "spelling_usage": "To omit, miss, or overlook by chance."
            },
            {
                "spelling_detail_id": 581,
                "word_type": "v. t.",
                "spelling_usage": "To miss intentionally; to avoid; to shun; to refuse; to\n   let go by; to shirk."
            },
            {
                "spelling_detail_id": 582,
                "word_type": "v. t.",
                "spelling_usage": "To disappoint; to frustrate; to foil; to baffle; to\n   /hwart; as, to balk expectation."
            },
            {
                "spelling_detail_id": 583,
                "word_type": "v. i.",
                "spelling_usage": "To engage in contradiction; to be in opposition."
            },
            {
                "spelling_detail_id": 584,
                "word_type": "v. i.",
                "spelling_usage": "To stop abruptly and stand still obstinately; to jib; to\n   stop short; to swerve; as, the horse balks."
            },
            {
                "spelling_detail_id": 585,
                "word_type": "v. i.",
                "spelling_usage": "To indicate to fishermen, by shouts or signals from shore,\n   the direction taken by the shoals of herring."
            }
        ]
    },
    {
        "id": 600,
        "spelling_name": "Bandy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 800,
                "word_type": "n.",
                "spelling_usage": "A carriage or cart used in India, esp. one drawn by\n   bullocks."
            },
            {
                "spelling_detail_id": 802,
                "word_type": "n.",
                "spelling_usage": "A club bent at the lower part for striking a ball at play; a\n   hockey stick."
            },
            {
                "spelling_detail_id": 803,
                "word_type": "n.",
                "spelling_usage": "The game played with such a club; hockey; shinney; bandy\n   ball."
            },
            {
                "spelling_detail_id": 806,
                "word_type": "v. t.",
                "spelling_usage": "To beat to and fro, as a ball in playing at bandy."
            },
            {
                "spelling_detail_id": 807,
                "word_type": "v. t.",
                "spelling_usage": "To give and receive reciprocally; to exchange."
            },
            {
                "spelling_detail_id": 808,
                "word_type": "v. t.",
                "spelling_usage": "To toss about, as from man to man; to agitate."
            },
            {
                "spelling_detail_id": 809,
                "word_type": "v. i.",
                "spelling_usage": "To content, as at some game in which each strives to\n   drive the ball his own way."
            },
            {
                "spelling_detail_id": 810,
                "word_type": "a.",
                "spelling_usage": "Bent; crooked; curved laterally, esp. with the convex side\n   outward; as, a bandy leg."
            }
        ]
    },
    {
        "id": 605,
        "spelling_name": "Baneful",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 818,
                "word_type": "a.",
                "spelling_usage": "Having poisonous qualities; deadly; destructive;\n   injurious; noxious; pernicious."
            }
        ]
    },
    {
        "id": 855,
        "spelling_name": "Barrage",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 1273,
                "word_type": "n.",
                "spelling_usage": "An artificial bar or obstruction placed in a river or\n   water course to increase the depth of water; as, the barrages of the\n   Nile."
            }
        ]
    },
    {
        "id": 868,
        "spelling_name": "Barren",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 1295,
                "word_type": "a.",
                "spelling_usage": "Incapable of producing offspring; producing no young;\n   sterile; -- said of women and female animals."
            },
            {
                "spelling_detail_id": 1296,
                "word_type": "a.",
                "spelling_usage": "Not producing vegetation, or useful vegetation; /rile."
            },
            {
                "spelling_detail_id": 1297,
                "word_type": "a.",
                "spelling_usage": "Unproductive; fruitless; unprofitable; empty."
            },
            {
                "spelling_detail_id": 1298,
                "word_type": "a.",
                "spelling_usage": "Mentally dull; stupid."
            },
            {
                "spelling_detail_id": 1299,
                "word_type": "n.",
                "spelling_usage": "A tract of barren land."
            },
            {
                "spelling_detail_id": 1300,
                "word_type": "n.",
                "spelling_usage": "Elevated lands or plains on which grow small trees, but not\n   timber; as, pine barrens; oak barrens. They are not necessarily\n   sterile, and are often fertile."
            }
        ]
    },
    {
        "id": 988,
        "spelling_name": "Bask",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 1516,
                "word_type": "v. t.",
                "spelling_usage": "To lie in warmth; to be exposed to genial heat."
            },
            {
                "spelling_detail_id": 1517,
                "word_type": "v. t.",
                "spelling_usage": "To warm by continued exposure to heat; to warm with genial\n   heat."
            }
        ]
    },
    {
        "id": 1299,
        "spelling_name": "Beatify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 2198,
                "word_type": "v. t.",
                "spelling_usage": "To pronounce or regard as happy, or supremely blessed,\n   or as conferring happiness."
            },
            {
                "spelling_detail_id": 2199,
                "word_type": "v. t.",
                "spelling_usage": "To make happy; to bless with the completion of\n   celestial enjoyment."
            },
            {
                "spelling_detail_id": 2200,
                "word_type": "v. t.",
                "spelling_usage": "To ascertain and declare, by a public process and\n   decree, that a deceased person is one of \"the blessed\" and is to be\n   reverenced as such, though not canonized."
            }
        ]
    },
    {
        "id": 1431,
        "spelling_name": "Bedizen",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 2385,
                "word_type": "v. t.",
                "spelling_usage": "To dress or adorn tawdrily or with false taste."
            }
        ]
    },
    {
        "id": 1618,
        "spelling_name": "Beguile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 2657,
                "word_type": "v. t.",
                "spelling_usage": "To delude by guile, artifice, or craft; to deceive or\n   impose on, as by a false statement; to lure."
            },
            {
                "spelling_detail_id": 2658,
                "word_type": "v. t.",
                "spelling_usage": "To elude, or evade by craft; to foil."
            },
            {
                "spelling_detail_id": 2659,
                "word_type": "v. t.",
                "spelling_usage": "To cause the time of to pass without notice; to relieve\n   the tedium or weariness of; to while away; to divert."
            }
        ]
    },
    {
        "id": 1681,
        "spelling_name": "Belabor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 2757,
                "word_type": "v. t.",
                "spelling_usage": "To ply diligently; to work carefully upon."
            },
            {
                "spelling_detail_id": 2758,
                "word_type": "v. t.",
                "spelling_usage": "To beat soundly; to cudgel."
            }
        ]
    },
    {
        "id": 1765,
        "spelling_name": "Bellicose",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 2883,
                "word_type": "a.",
                "spelling_usage": "Inclined to war or contention; warlike; pugnacious."
            }
        ]
    },
    {
        "id": 1772,
        "spelling_name": "Belligerent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 2889,
                "word_type": "p. pr.",
                "spelling_usage": "Waging war; carrying on war."
            },
            {
                "spelling_detail_id": 2890,
                "word_type": "p. pr.",
                "spelling_usage": "Pertaining, or tending, to war; of or relating to\n   belligerents; as, a belligerent tone; belligerent rights."
            },
            {
                "spelling_detail_id": 2891,
                "word_type": "n.",
                "spelling_usage": "A nation or state recognized as carrying on war; a\n   person engaged in warfare."
            }
        ]
    },
    {
        "id": 1889,
        "spelling_name": "Benefactor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3114,
                "word_type": "n.",
                "spelling_usage": "One who confers a benefit or benefits."
            }
        ]
    },
    {
        "id": 1914,
        "spelling_name": "Benevolence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3157,
                "word_type": "n.",
                "spelling_usage": "The disposition to do good; good will; charitableness;\n   love of mankind, accompanied with a desire to promote their happiness."
            },
            {
                "spelling_detail_id": 3158,
                "word_type": "n.",
                "spelling_usage": "An act of kindness; good done; charity given."
            },
            {
                "spelling_detail_id": 3159,
                "word_type": "n.",
                "spelling_usage": "A species of compulsory contribution or tax, which has\n   sometimes been illegally exacted by arbitrary kings of England, and\n   falsely represented as a gratuity."
            }
        ]
    },
    {
        "id": 1926,
        "spelling_name": "Benign",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3176,
                "word_type": "a.",
                "spelling_usage": "Of a kind or gentle disposition; gracious; generous;\n   favorable; benignant."
            },
            {
                "spelling_detail_id": 3177,
                "word_type": "a.",
                "spelling_usage": "Exhibiting or manifesting kindness, gentleness, favor,\n   etc.; mild; kindly; salutary; wholesome."
            },
            {
                "spelling_detail_id": 3178,
                "word_type": "a.",
                "spelling_usage": "Of a mild type or character; as, a benign disease."
            }
        ]
    },
    {
        "id": 1932,
        "spelling_name": "Benison",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3186,
                "word_type": "n.",
                "spelling_usage": "Blessing; beatitude; benediction."
            }
        ]
    },
    {
        "id": 1987,
        "spelling_name": "Bequest",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3261,
                "word_type": "n.",
                "spelling_usage": "The act of bequeathing or leaving by will; as, a bequest\n   of property by A. to B."
            },
            {
                "spelling_detail_id": 3262,
                "word_type": "n.",
                "spelling_usage": "That which is left by will, esp. personal property; a\n   legacy; also, a gift."
            },
            {
                "spelling_detail_id": 3263,
                "word_type": "v. t.",
                "spelling_usage": "To bequeath, or leave as a legacy."
            }
        ]
    },
    {
        "id": 1993,
        "spelling_name": "Berate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3271,
                "word_type": "v. t.",
                "spelling_usage": "To rate or chide vehemently; to scold."
            }
        ]
    },
    {
        "id": 2009,
        "spelling_name": "Bereft",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3282,
                "word_type": "",
                "spelling_usage": "of Bereave"
            },
            {
                "spelling_detail_id": 3289,
                "word_type": "",
                "spelling_usage": "imp. & p. p. of Bereave."
            }
        ]
    },
    {
        "id": 2328,
        "spelling_name": "Bewilder",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 3773,
                "word_type": "v. t.",
                "spelling_usage": "To lead into perplexity or confusion, as for want of a\n   plain path; to perplex with mazes; or in general, to perplex or confuse\n   greatly."
            }
        ]
    },
    {
        "id": 2580,
        "spelling_name": "Bigot",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 4115,
                "word_type": "n.",
                "spelling_usage": "A hypocrite; esp., a superstitious hypocrite."
            },
            {
                "spelling_detail_id": 4116,
                "word_type": "n.",
                "spelling_usage": "A person who regards his own faith and views in matters of\n   religion as unquestionably right, and any belief or opinion opposed to\n   or differing from them as unreasonable or wicked. In an extended sense,\n   a person who is intolerant of opinions which conflict with his own, as\n   in politics or morals; one obstinately and blindly devoted to his own\n   church, party, belief, or opinion."
            },
            {
                "spelling_detail_id": 4117,
                "word_type": "a.",
                "spelling_usage": "Bigoted."
            }
        ]
    },
    {
        "id": 2614,
        "spelling_name": "Bilge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 4158,
                "word_type": "n.",
                "spelling_usage": "The protuberant part of a cask, which is usually in the\n   middle."
            },
            {
                "spelling_detail_id": 4159,
                "word_type": "n.",
                "spelling_usage": "That part of a ship's hull or bottom which is broadest and\n   most nearly flat, and on which she would rest if aground."
            },
            {
                "spelling_detail_id": 4160,
                "word_type": "n.",
                "spelling_usage": "Bilge water."
            },
            {
                "spelling_detail_id": 4163,
                "word_type": "v. i.",
                "spelling_usage": "To suffer a fracture in the bilge; to spring a leak by a\n   fracture in the bilge."
            },
            {
                "spelling_detail_id": 4164,
                "word_type": "v. i.",
                "spelling_usage": "To bulge."
            },
            {
                "spelling_detail_id": 4165,
                "word_type": "v. t.",
                "spelling_usage": "To fracture the bilge of, or stave in the bottom of (a\n   ship or other vessel)."
            },
            {
                "spelling_detail_id": 4166,
                "word_type": "v. t.",
                "spelling_usage": "To cause to bulge."
            }
        ]
    },
    {
        "id": 3147,
        "spelling_name": "Blandishment",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 4999,
                "word_type": "n.",
                "spelling_usage": "The act of blandishing; a word or act expressive of\n   affection or kindness, and tending to win the heart; soft words and\n   artful caresses; cajolery; allurement."
            }
        ]
    },
    {
        "id": 3149,
        "spelling_name": "Blandness",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 5001,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being bland."
            }
        ]
    },
    {
        "id": 3206,
        "spelling_name": "Blatant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 5109,
                "word_type": "a.",
                "spelling_usage": "Bellowing, as a calf; bawling; brawling; clamoring;\n   disagreeably clamorous; sounding loudly and harshly."
            }
        ]
    },
    {
        "id": 3341,
        "spelling_name": "Blithe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 5375,
                "word_type": "a.",
                "spelling_usage": "Gay; merry; sprightly; joyous; glad; cheerful; as, a blithe\n   spirit."
            }
        ]
    },
    {
        "id": 3679,
        "spelling_name": "Boggle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 6043,
                "word_type": "n.",
                "spelling_usage": "To stop or hesitate as if suddenly frightened, or in doubt,\n   or impeded by unforeseen difficulties; to take alarm; to exhibit\n   hesitancy and indecision."
            },
            {
                "spelling_detail_id": 6044,
                "word_type": "n.",
                "spelling_usage": "To do anything awkwardly or unskillfully."
            },
            {
                "spelling_detail_id": 6045,
                "word_type": "n.",
                "spelling_usage": "To play fast and loose; to dissemble."
            },
            {
                "spelling_detail_id": 6046,
                "word_type": "v. t.",
                "spelling_usage": "To embarrass with difficulties; to make a bungle or\n   botch of."
            }
        ]
    },
    {
        "id": 3692,
        "spelling_name": "Bogus",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 6057,
                "word_type": "a.",
                "spelling_usage": "Spurious; fictitious; sham; -- a cant term originally\n   applied to counterfeit coin, and hence denoting anything counterfeit."
            },
            {
                "spelling_detail_id": 6058,
                "word_type": "n.",
                "spelling_usage": "A liquor made of rum and molasses."
            }
        ]
    },
    {
        "id": 3711,
        "spelling_name": "Boisterous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 6099,
                "word_type": "a.",
                "spelling_usage": "Rough or rude; unbending; unyielding; strong; powerful."
            },
            {
                "spelling_detail_id": 6100,
                "word_type": "a.",
                "spelling_usage": "Exhibiting tumultuous violence and fury; acting with\n   noisy turbulence; violent; rough; stormy."
            },
            {
                "spelling_detail_id": 6101,
                "word_type": "a.",
                "spelling_usage": "Noisy; rough; turbulent; as, boisterous mirth;\n   boisterous behavior."
            },
            {
                "spelling_detail_id": 6102,
                "word_type": "a.",
                "spelling_usage": "Vehement; excessive."
            }
        ]
    },
    {
        "id": 3751,
        "spelling_name": "Bolster",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 6162,
                "word_type": "n.",
                "spelling_usage": "A long pillow or cushion, used to support the head of a\n   person lying on a bed; -- generally laid under the pillows."
            },
            {
                "spelling_detail_id": 6163,
                "word_type": "n.",
                "spelling_usage": "A pad, quilt, or anything used to hinder pressure, support\n   any part of the body, or make a bandage sit easy upon a wounded part; a\n   compress."
            },
            {
                "spelling_detail_id": 6164,
                "word_type": "n.",
                "spelling_usage": "Anything arranged to act as a support, as in various forms\n   of mechanism, etc."
            },
            {
                "spelling_detail_id": 6165,
                "word_type": "n.",
                "spelling_usage": "A cushioned or a piece part of a saddle."
            },
            {
                "spelling_detail_id": 6166,
                "word_type": "n.",
                "spelling_usage": "A cushioned or a piece of soft wood covered with tarred\n   canvas, placed on the trestletrees and against the mast, for the\n   collars of the shrouds to rest on, to prevent chafing."
            },
            {
                "spelling_detail_id": 6167,
                "word_type": "n.",
                "spelling_usage": "Anything used to prevent chafing."
            },
            {
                "spelling_detail_id": 6168,
                "word_type": "n.",
                "spelling_usage": "A plate of iron or a mass of wood under the end of a\n   bridge girder, to keep the girder from resting directly on the\n   abutment."
            },
            {
                "spelling_detail_id": 6169,
                "word_type": "n.",
                "spelling_usage": "A transverse bar above the axle of a wagon, on which the\n   bed or body rests."
            },
            {
                "spelling_detail_id": 6170,
                "word_type": "n.",
                "spelling_usage": "The crossbeam forming the bearing piece of the body of a\n   railway car; the central and principal cross beam of a car truck."
            },
            {
                "spelling_detail_id": 6171,
                "word_type": "n.",
                "spelling_usage": "the perforated plate in a punching machine on which\n   anything rests when being punched."
            },
            {
                "spelling_detail_id": 6172,
                "word_type": "n.",
                "spelling_usage": "That part of a knife blade which abuts upon the end of the\n   handle."
            },
            {
                "spelling_detail_id": 6173,
                "word_type": "n.",
                "spelling_usage": "The metallic end of a pocketknife handle."
            },
            {
                "spelling_detail_id": 6174,
                "word_type": "n.",
                "spelling_usage": "The rolls forming the ends or sides of the Ionic capital."
            },
            {
                "spelling_detail_id": 6175,
                "word_type": "n.",
                "spelling_usage": "A block of wood on the carriage of a siege gun, upon which\n   the breech of the gun rests when arranged for transportation."
            },
            {
                "spelling_detail_id": 6178,
                "word_type": "v. t.",
                "spelling_usage": "To support with a bolster or pillow."
            },
            {
                "spelling_detail_id": 6179,
                "word_type": "v. t.",
                "spelling_usage": "To support, hold up, or maintain with difficulty or\n   unusual effort; -- often with up."
            }
        ]
    },
    {
        "id": 3949,
        "spelling_name": "Boorish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 6530,
                "word_type": "a.",
                "spelling_usage": "Like a boor; clownish; uncultured; unmannerly."
            }
        ]
    },
    {
        "id": 4403,
        "spelling_name": "Braggart",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 7348,
                "word_type": "v. i.",
                "spelling_usage": "A boaster."
            },
            {
                "spelling_detail_id": 7349,
                "word_type": "a.",
                "spelling_usage": "Boastful."
            }
        ]
    },
    {
        "id": 4522,
        "spelling_name": "Brash",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 7546,
                "word_type": "a.",
                "spelling_usage": "Hasty in temper; impetuous."
            },
            {
                "spelling_detail_id": 7547,
                "word_type": "a.",
                "spelling_usage": "Brittle, as wood or vegetables."
            },
            {
                "spelling_detail_id": 7548,
                "word_type": "n.",
                "spelling_usage": "A rash or eruption; a sudden or transient fit of sickness."
            },
            {
                "spelling_detail_id": 7549,
                "word_type": "n.",
                "spelling_usage": "Refuse boughs of trees; also, the clippings of hedges."
            },
            {
                "spelling_detail_id": 7550,
                "word_type": "n.",
                "spelling_usage": "Broken and angular fragments of rocks underlying alluvial\n   deposits."
            },
            {
                "spelling_detail_id": 7551,
                "word_type": "n.",
                "spelling_usage": "Broken fragments of ice."
            }
        ]
    },
    {
        "id": 4524,
        "spelling_name": "Brass",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 7557,
                "word_type": "n.",
                "spelling_usage": "An alloy (usually yellow) of copper and zinc, in variable\n   proportion, but often containing two parts of copper to one part of\n   zinc. It sometimes contains tin, and rarely other metals."
            },
            {
                "spelling_detail_id": 7558,
                "word_type": "n.",
                "spelling_usage": "A journal bearing, so called because frequently made of\n   brass. A brass is often lined with a softer metal, when the latter is\n   generally called a white metal lining. See Axle box, Journal Box, and\n   Bearing."
            },
            {
                "spelling_detail_id": 7559,
                "word_type": "n.",
                "spelling_usage": "Coin made of copper, brass, or bronze."
            },
            {
                "spelling_detail_id": 7560,
                "word_type": "n.",
                "spelling_usage": "Impudence; a brazen face."
            },
            {
                "spelling_detail_id": 7561,
                "word_type": "n.",
                "spelling_usage": "Utensils, ornaments, or other articles of brass."
            },
            {
                "spelling_detail_id": 7562,
                "word_type": "n.",
                "spelling_usage": "A brass plate engraved with a figure or device.\n   Specifically, one used as a memorial to the dead, and generally having\n   the portrait, coat of arms, etc."
            },
            {
                "spelling_detail_id": 7563,
                "word_type": "n.",
                "spelling_usage": "Lumps of pyrites or sulphuret of iron, the color of which is\n   near to that of brass."
            }
        ]
    },
    {
        "id": 4571,
        "spelling_name": "Brazen",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 7650,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, made of, or resembling, brass."
            },
            {
                "spelling_detail_id": 7651,
                "word_type": "a.",
                "spelling_usage": "Sounding harsh and loud, like resounding brass."
            },
            {
                "spelling_detail_id": 7652,
                "word_type": "a.",
                "spelling_usage": "Impudent; immodest; shameless; having a front like brass;\n   as, a brazen countenance."
            },
            {
                "spelling_detail_id": 7655,
                "word_type": "v. t.",
                "spelling_usage": "To carry through impudently or shamelessly; as, to\n   brazen the matter through."
            }
        ]
    },
    {
        "id": 4586,
        "spelling_name": "Breach",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 7669,
                "word_type": "n.",
                "spelling_usage": "The act of breaking, in a figurative sense."
            },
            {
                "spelling_detail_id": 7670,
                "word_type": "n.",
                "spelling_usage": "Specifically: A breaking or infraction of a law, or of any\n   obligation or tie; violation; non-fulfillment; as, a breach of\n   contract; a breach of promise."
            },
            {
                "spelling_detail_id": 7671,
                "word_type": "n.",
                "spelling_usage": "A gap or opening made made by breaking or battering, as in\n   a wall or fortification; the space between the parts of a solid body\n   rent by violence; a break; a rupture."
            },
            {
                "spelling_detail_id": 7672,
                "word_type": "n.",
                "spelling_usage": "A breaking of waters, as over a vessel; the waters\n   themselves; surge; surf."
            },
            {
                "spelling_detail_id": 7673,
                "word_type": "n.",
                "spelling_usage": "A breaking up of amicable relations; rupture."
            },
            {
                "spelling_detail_id": 7674,
                "word_type": "n.",
                "spelling_usage": "A bruise; a wound."
            },
            {
                "spelling_detail_id": 7675,
                "word_type": "n.",
                "spelling_usage": "A hernia; a rupture."
            },
            {
                "spelling_detail_id": 7676,
                "word_type": "n.",
                "spelling_usage": "A breaking out upon; an assault."
            },
            {
                "spelling_detail_id": 7679,
                "word_type": "v. t.",
                "spelling_usage": "To make a breach or opening in; as, to breach the walls\n   of a city."
            },
            {
                "spelling_detail_id": 7680,
                "word_type": "v. i.",
                "spelling_usage": "To break the water, as by leaping out; -- said of a\n   whale."
            }
        ]
    },
    {
        "id": 4877,
        "spelling_name": "Brittle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 8262,
                "word_type": "a.",
                "spelling_usage": "Easily broken; apt to break; fragile; not tough or\n   tenacious."
            }
        ]
    },
    {
        "id": 4883,
        "spelling_name": "Broach",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 8268,
                "word_type": "n.",
                "spelling_usage": "A spit."
            },
            {
                "spelling_detail_id": 8269,
                "word_type": "n.",
                "spelling_usage": "An awl; a bodkin; also, a wooden rod or pin, sharpened at\n   each end, used by thatchers."
            },
            {
                "spelling_detail_id": 8270,
                "word_type": "n.",
                "spelling_usage": "A tool of steel, generally tapering, and of a polygonal\n   form, with from four to eight cutting edges, for smoothing or enlarging\n   holes in metal; sometimes made smooth or without edges, as for\n   burnishing pivot holes in watches; a reamer. The broach for gun barrels\n   is commonly square and without taper."
            },
            {
                "spelling_detail_id": 8271,
                "word_type": "n.",
                "spelling_usage": "A straight tool with file teeth, made of steel, to be\n   pressed through irregular holes in metal that cannot be dressed by\n   revolving tools; a drift."
            },
            {
                "spelling_detail_id": 8272,
                "word_type": "n.",
                "spelling_usage": "A broad chisel for stonecutting."
            },
            {
                "spelling_detail_id": 8273,
                "word_type": "n.",
                "spelling_usage": "A spire rising from a tower."
            },
            {
                "spelling_detail_id": 8274,
                "word_type": "n.",
                "spelling_usage": "A clasp for fastening a garment. See Brooch."
            },
            {
                "spelling_detail_id": 8275,
                "word_type": "n.",
                "spelling_usage": "A spitlike start, on the head of a young stag."
            },
            {
                "spelling_detail_id": 8276,
                "word_type": "n.",
                "spelling_usage": "The stick from which candle wicks are suspended for\n   dipping."
            },
            {
                "spelling_detail_id": 8277,
                "word_type": "n.",
                "spelling_usage": "The pin in a lock which enters the barrel of the key."
            },
            {
                "spelling_detail_id": 8280,
                "word_type": "n.",
                "spelling_usage": "To spit; to pierce as with a spit."
            },
            {
                "spelling_detail_id": 8281,
                "word_type": "n.",
                "spelling_usage": "To tap; to pierce, as a cask, in order to draw the liquor.\n   Hence: To let out; to shed, as blood."
            },
            {
                "spelling_detail_id": 8282,
                "word_type": "n.",
                "spelling_usage": "To open for the first time, as stores."
            },
            {
                "spelling_detail_id": 8283,
                "word_type": "n.",
                "spelling_usage": "To make public; to utter; to publish first; to put forth;\n   to introduce as a topic of conversation."
            },
            {
                "spelling_detail_id": 8284,
                "word_type": "n.",
                "spelling_usage": "To cause to begin or break out."
            },
            {
                "spelling_detail_id": 8285,
                "word_type": "n.",
                "spelling_usage": "To shape roughly, as a block of stone, by chiseling with a\n   coarse tool."
            },
            {
                "spelling_detail_id": 8286,
                "word_type": "n.",
                "spelling_usage": "To enlarge or dress (a hole), by using a broach."
            }
        ]
    },
    {
        "id": 4916,
        "spelling_name": "Brobdingnagian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 8340,
                "word_type": "a.",
                "spelling_usage": "Colossal; of extraordinary height; gigantic."
            },
            {
                "spelling_detail_id": 8341,
                "word_type": "n.",
                "spelling_usage": "A giant."
            }
        ]
    },
    {
        "id": 5014,
        "spelling_name": "Brook",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 8495,
                "word_type": "v. t.",
                "spelling_usage": "A natural stream of water smaller than a river or creek."
            },
            {
                "spelling_detail_id": 8498,
                "word_type": "v. t.",
                "spelling_usage": "To use; to enjoy."
            },
            {
                "spelling_detail_id": 8499,
                "word_type": "v. t.",
                "spelling_usage": "To bear; to endure; to put up with; to tolerate; as,\n   young men can not brook restraint."
            },
            {
                "spelling_detail_id": 8500,
                "word_type": "v. t.",
                "spelling_usage": "To deserve; to earn."
            }
        ]
    },
    {
        "id": 5095,
        "spelling_name": "Brummagem",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 8619,
                "word_type": "a.",
                "spelling_usage": "Counterfeit; gaudy but worthless; sham."
            }
        ]
    },
    {
        "id": 5458,
        "spelling_name": "Buoyant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 9269,
                "word_type": "v. t. & i.",
                "spelling_usage": "Having the quality of rising or floating in a\n   fluid; tending to rise or float; as, iron is buoyant in mercury."
            },
            {
                "spelling_detail_id": 9270,
                "word_type": "v. t. & i.",
                "spelling_usage": "Bearing up, as a fluid; sustaining another body by\n   being specifically heavier."
            },
            {
                "spelling_detail_id": 9271,
                "word_type": "v. t. & i.",
                "spelling_usage": "Light-hearted; vivacious; cheerful; as, a buoyant\n   disposition; buoyant spirits."
            }
        ]
    },
    {
        "id": 5493,
        "spelling_name": "Burgeon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 9332,
                "word_type": "v. i.",
                "spelling_usage": "To bud. See Bourgeon."
            }
        ]
    },
    {
        "id": 5556,
        "spelling_name": "Burnish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 9440,
                "word_type": "a.",
                "spelling_usage": "To cause to shine; to make smooth and bright; to polish;\n   specifically, to polish by rubbing with something hard and smooth; as,\n   to burnish brass or paper."
            },
            {
                "spelling_detail_id": 9441,
                "word_type": "v. i.",
                "spelling_usage": "To shine forth; to brighten; to become smooth and\n   glossy, as from swelling or filling out; hence, to grow large."
            },
            {
                "spelling_detail_id": 9442,
                "word_type": "n.",
                "spelling_usage": "The effect of burnishing; gloss; brightness; luster."
            }
        ]
    },
    {
        "id": 5809,
        "spelling_name": "Cabal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 9867,
                "word_type": "n.",
                "spelling_usage": "Tradition; occult doctrine. See Cabala"
            },
            {
                "spelling_detail_id": 9868,
                "word_type": "n.",
                "spelling_usage": "A secret."
            },
            {
                "spelling_detail_id": 9869,
                "word_type": "n.",
                "spelling_usage": "A number of persons united in some close design, usually to\n   promote their private views and interests in church or state by\n   intrigue; a secret association composed of a few designing persons; a\n   junto."
            },
            {
                "spelling_detail_id": 9870,
                "word_type": "n.",
                "spelling_usage": "The secret artifices or machinations of a few persons united\n   in a close design; intrigue."
            },
            {
                "spelling_detail_id": 9873,
                "word_type": "v. i.",
                "spelling_usage": "To unite in a small party to promote private views and\n   interests by intrigue; to intrigue; to plot."
            }
        ]
    },
    {
        "id": 5954,
        "spelling_name": "Cadge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 10077,
                "word_type": "v. t. & i.",
                "spelling_usage": "To carry, as a burden."
            },
            {
                "spelling_detail_id": 10078,
                "word_type": "v. t. & i.",
                "spelling_usage": "To hawk or peddle, as fish, poultry, etc."
            },
            {
                "spelling_detail_id": 10079,
                "word_type": "v. t. & i.",
                "spelling_usage": "To intrude or live on another meanly; to beg."
            },
            {
                "spelling_detail_id": 10080,
                "word_type": "n.",
                "spelling_usage": "A circular frame on which cadgers carry hawks for sale."
            }
        ]
    },
    {
        "id": 6031,
        "spelling_name": "Cajole",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 10185,
                "word_type": "v. i.",
                "spelling_usage": "To deceive with flattery or fair words; to wheedle."
            }
        ]
    },
    {
        "id": 6207,
        "spelling_name": "Calipers",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 10427,
                "word_type": "n. pl.",
                "spelling_usage": "An instrument, usually resembling a pair of dividers\n   or compasses with curved legs, for measuring the diameter or thickness\n   of bodies, as of work shaped in a lathe or planer, timber, masts, shot,\n   etc.; or the bore of firearms, tubes, etc.; -- called also caliper\n   compasses, or caliber compasses."
            }
        ]
    },
    {
        "id": 6213,
        "spelling_name": "Calisthenics",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 10434,
                "word_type": "n.",
                "spelling_usage": "The science, art, or practice of healthful exercise\n   of the body and limbs, to promote strength and gracefulness; light\n   gymnastics."
            }
        ]
    },
    {
        "id": 6298,
        "spelling_name": "Calumny",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 10580,
                "word_type": "n.",
                "spelling_usage": "False accusation of a crime or offense, maliciously made\n   or reported, to the injury of another; malicious misrepresentation;\n   slander; detraction."
            }
        ]
    },
    {
        "id": 6495,
        "spelling_name": "Candid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 10867,
                "word_type": "a.",
                "spelling_usage": "White."
            },
            {
                "spelling_detail_id": 10868,
                "word_type": "a.",
                "spelling_usage": "Free from undue bias; disposed to think and judge according\n   to truth and justice, or without partiality or prejudice; fair; just;\n   impartial; as, a candid opinion."
            },
            {
                "spelling_detail_id": 10869,
                "word_type": "a.",
                "spelling_usage": "Open; frank; ingenuous; outspoken."
            }
        ]
    },
    {
        "id": 6614,
        "spelling_name": "Cant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 11059,
                "word_type": "n.",
                "spelling_usage": "A corner; angle; niche."
            },
            {
                "spelling_detail_id": 11060,
                "word_type": "n.",
                "spelling_usage": "An outer or external angle."
            },
            {
                "spelling_detail_id": 11061,
                "word_type": "n.",
                "spelling_usage": "An inclination from a horizontal or vertical line; a slope or\n   bevel; a titl."
            },
            {
                "spelling_detail_id": 11062,
                "word_type": "n.",
                "spelling_usage": "A sudden thrust, push, kick, or other impulse, producing a\n   bias or change of direction; also, the bias or turn so give; as, to\n   give a ball a cant."
            },
            {
                "spelling_detail_id": 11063,
                "word_type": "n.",
                "spelling_usage": "A segment forming a side piece in the head of a cask."
            },
            {
                "spelling_detail_id": 11064,
                "word_type": "n.",
                "spelling_usage": "A segment of he rim of a wooden cogwheel."
            },
            {
                "spelling_detail_id": 11065,
                "word_type": "n.",
                "spelling_usage": "A piece of wood laid upon the deck of a vessel to support the\n   bulkheads."
            },
            {
                "spelling_detail_id": 11068,
                "word_type": "v. t.",
                "spelling_usage": "To incline; to set at an angle; to tilt over; to tip upon\n   the edge; as, to cant a cask; to cant a ship."
            },
            {
                "spelling_detail_id": 11069,
                "word_type": "v. t.",
                "spelling_usage": "To give a sudden turn or new direction to; as, to cant\n   round a stick of timber; to cant a football."
            },
            {
                "spelling_detail_id": 11070,
                "word_type": "v. t.",
                "spelling_usage": "To cut off an angle from, as from a square piece of\n   timber, or from the head of a bolt."
            },
            {
                "spelling_detail_id": 11071,
                "word_type": "n.",
                "spelling_usage": "An affected, singsong mode of speaking."
            },
            {
                "spelling_detail_id": 11072,
                "word_type": "n.",
                "spelling_usage": "The idioms and peculiarities of speech in any sect, class, or\n   occupation."
            },
            {
                "spelling_detail_id": 11073,
                "word_type": "n.",
                "spelling_usage": "The use of religious phraseology without understanding or\n   sincerity; empty, solemn speech, implying what is not felt; hypocrisy."
            },
            {
                "spelling_detail_id": 11074,
                "word_type": "n.",
                "spelling_usage": "Vulgar jargon; slang; the secret language spoker by gipsies,\n   thieves, tramps, or beggars."
            },
            {
                "spelling_detail_id": 11075,
                "word_type": "a.",
                "spelling_usage": "Of the nature of cant; affected; vulgar."
            },
            {
                "spelling_detail_id": 11076,
                "word_type": "v. i.",
                "spelling_usage": "To speak in a whining voice, or an affected, singsong\n   tone."
            },
            {
                "spelling_detail_id": 11077,
                "word_type": "v. i.",
                "spelling_usage": "To make whining pretensions to goodness; to talk with an\n   affectation of religion, philanthropy, etc.; to practice hypocrisy; as,\n   a canting fanatic."
            },
            {
                "spelling_detail_id": 11078,
                "word_type": "v. i.",
                "spelling_usage": "To use pretentious language, barbarous jargon, or\n   technical terms; to talk with an affectation of learning."
            },
            {
                "spelling_detail_id": 11079,
                "word_type": "n.",
                "spelling_usage": "A call for bidders at a public sale; an auction."
            },
            {
                "spelling_detail_id": 11080,
                "word_type": "v. t.",
                "spelling_usage": "to sell by auction, or bid a price at a sale by auction."
            }
        ]
    },
    {
        "id": 6622,
        "spelling_name": "Cantankerous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 11090,
                "word_type": "a.",
                "spelling_usage": "Perverse; contentious; ugly; malicious."
            }
        ]
    },
    {
        "id": 6682,
        "spelling_name": "Canvass",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 11184,
                "word_type": "n.",
                "spelling_usage": "To sift; to strain; to examine thoroughly; to scrutinize;\n   as, to canvass the votes cast at an election; to canvass a district\n   with reference to its probable vote."
            },
            {
                "spelling_detail_id": 11185,
                "word_type": "n.",
                "spelling_usage": "To examine by discussion; to debate."
            },
            {
                "spelling_detail_id": 11186,
                "word_type": "n.",
                "spelling_usage": "To go trough, with personal solicitation or public\n   addresses; as, to canvass a district for votes; to canvass a city for\n   subscriptions."
            },
            {
                "spelling_detail_id": 11187,
                "word_type": "v. i.",
                "spelling_usage": "To search thoroughly; to engage in solicitation by\n   traversing a district; as, to canvass for subscriptions or for votes;\n   to canvass for a book, a publisher, or in behalf of a charity; --\n   commonly followed by for."
            },
            {
                "spelling_detail_id": 11188,
                "word_type": "n.",
                "spelling_usage": "Close inspection; careful review for verification; as, a\n   canvass of votes."
            },
            {
                "spelling_detail_id": 11189,
                "word_type": "n.",
                "spelling_usage": "Examination in the way of discussion or debate."
            },
            {
                "spelling_detail_id": 11190,
                "word_type": "n.",
                "spelling_usage": "Search; exploration; solicitation; systematic effort to\n   obtain votes, subscribers, etc."
            }
        ]
    },
    {
        "id": 7370,
        "spelling_name": "Castigate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 12315,
                "word_type": "v. t.",
                "spelling_usage": "To punish by stripes; to chastise by blows; to\n   chasten; also, to chastise verbally; to reprove; to criticise severely."
            },
            {
                "spelling_detail_id": 12316,
                "word_type": "v. t.",
                "spelling_usage": "To emend; to correct."
            }
        ]
    },
    {
        "id": 7373,
        "spelling_name": "Castigation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 12317,
                "word_type": "n.",
                "spelling_usage": "Corrective punishment; chastisement; reproof; pungent\n   criticism."
            },
            {
                "spelling_detail_id": 12318,
                "word_type": "n.",
                "spelling_usage": "Emendation; correction."
            }
        ]
    },
    {
        "id": 7707,
        "spelling_name": "Caustic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 12803,
                "word_type": "a.",
                "spelling_usage": "Alt. of Caustical"
            },
            {
                "spelling_detail_id": 12806,
                "word_type": "a.",
                "spelling_usage": "Any substance or means which, applied to animal or other\n   organic tissue, burns, corrodes, or destroys it by chemical action; an\n   escharotic."
            },
            {
                "spelling_detail_id": 12807,
                "word_type": "a.",
                "spelling_usage": "A caustic curve or caustic surface."
            }
        ]
    },
    {
        "id": 7732,
        "spelling_name": "Cavalcade",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 12842,
                "word_type": "n.",
                "spelling_usage": "A procession of persons on horseback; a formal, pompous\n   march of horsemen by way of parade."
            }
        ]
    },
    {
        "id": 7913,
        "spelling_name": "Censure",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 13095,
                "word_type": "n.",
                "spelling_usage": "Judgment either favorable or unfavorable; opinion."
            },
            {
                "spelling_detail_id": 13096,
                "word_type": "n.",
                "spelling_usage": "The act of blaming or finding fault with and condemning as\n   wrong; reprehension; blame."
            },
            {
                "spelling_detail_id": 13097,
                "word_type": "n.",
                "spelling_usage": "Judicial or ecclesiastical sentence or reprimand;\n   condemnatory judgment."
            },
            {
                "spelling_detail_id": 13100,
                "word_type": "v. i.",
                "spelling_usage": "To form or express a judgment in regard to; to\n   estimate; to judge."
            },
            {
                "spelling_detail_id": 13101,
                "word_type": "v. i.",
                "spelling_usage": "To find fault with and condemn as wrong; to blame; to\n   express disapprobation of."
            },
            {
                "spelling_detail_id": 13102,
                "word_type": "v. i.",
                "spelling_usage": "To condemn or reprimand by a judicial or ecclesiastical\n   sentence."
            },
            {
                "spelling_detail_id": 13103,
                "word_type": "v. i.",
                "spelling_usage": "To judge."
            }
        ]
    },
    {
        "id": 8011,
        "spelling_name": "Centurion",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 13241,
                "word_type": "n.",
                "spelling_usage": "A military officer who commanded a minor division of the\n   Roman army; a captain of a century."
            }
        ]
    },
    {
        "id": 8280,
        "spelling_name": "Chagrin",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 13607,
                "word_type": "n.",
                "spelling_usage": "Vexation; mortification."
            },
            {
                "spelling_detail_id": 13610,
                "word_type": "n.",
                "spelling_usage": "To excite ill-humor in; to vex; to mortify; as, he was not\n   a little chagrined."
            },
            {
                "spelling_detail_id": 13611,
                "word_type": "v. i.",
                "spelling_usage": "To be vexed or annoyed."
            },
            {
                "spelling_detail_id": 13612,
                "word_type": "a.",
                "spelling_usage": "Chagrined."
            }
        ]
    },
    {
        "id": 8586,
        "spelling_name": "Chary",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14216,
                "word_type": "a.",
                "spelling_usage": "Careful; wary; cautious; not rash, reckless, or spendthrift;\n   saving; frugal."
            }
        ]
    },
    {
        "id": 8603,
        "spelling_name": "Chaste",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14253,
                "word_type": "a.",
                "spelling_usage": "Pure from unlawful sexual intercourse; virtuous; continent."
            },
            {
                "spelling_detail_id": 14254,
                "word_type": "a.",
                "spelling_usage": "Pure in thought and act; innocent; free from lewdness and\n   obscenity, or indecency in act or speech; modest; as, a chaste mind;\n   chaste eyes."
            },
            {
                "spelling_detail_id": 14255,
                "word_type": "a.",
                "spelling_usage": "Pure in design and expression; correct; free from\n   barbarisms or vulgarisms; refined; simple; as, a chaste style in\n   composition or art."
            },
            {
                "spelling_detail_id": 14256,
                "word_type": "a.",
                "spelling_usage": "Unmarried."
            }
        ]
    },
    {
        "id": 8606,
        "spelling_name": "Chastened",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14258,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Chasten"
            },
            {
                "spelling_detail_id": 14262,
                "word_type": "a.",
                "spelling_usage": "Corrected; disciplined; refined; purified; toned down."
            }
        ]
    },
    {
        "id": 8613,
        "spelling_name": "Chastisement",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14271,
                "word_type": "n.",
                "spelling_usage": "The act of chastising; pain inflicted for punishment\n   and correction; discipline; punishment."
            }
        ]
    },
    {
        "id": 8870,
        "spelling_name": "Chicane",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14676,
                "word_type": "n.",
                "spelling_usage": "The use of artful subterfuge, designed to draw away\n   attention from the merits of a case or question; -- specifically\n   applied to legal proceedings; trickery; chicanery; caviling; sophistry."
            },
            {
                "spelling_detail_id": 14677,
                "word_type": "n.",
                "spelling_usage": "To use shifts, cavils, or artifices."
            }
        ]
    },
    {
        "id": 8872,
        "spelling_name": "Chicanery",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14679,
                "word_type": "n.",
                "spelling_usage": "Mean or unfair artifice to perplex a cause and obscure\n   the truth; stratagem; sharp practice; sophistry."
            }
        ]
    },
    {
        "id": 9074,
        "spelling_name": "Chisel",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 14996,
                "word_type": "n.",
                "spelling_usage": "A tool with a cutting edge on one end of a metal blade,\n   used in dressing, shaping, or working in timber, stone, metal, etc.; --\n   usually driven by a mallet or hammer."
            },
            {
                "spelling_detail_id": 15001,
                "word_type": "v. t.",
                "spelling_usage": "To cut, pare, gouge, or engrave with a chisel; as, to\n   chisel a block of marble into a statue."
            },
            {
                "spelling_detail_id": 15002,
                "word_type": "v. t.",
                "spelling_usage": "To cut close, as in a bargain; to cheat."
            }
        ]
    },
    {
        "id": 9506,
        "spelling_name": "Churl",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 15630,
                "word_type": "n.",
                "spelling_usage": "A rustic; a countryman or laborer."
            },
            {
                "spelling_detail_id": 15631,
                "word_type": "n.",
                "spelling_usage": "A rough, surly, ill-bred man; a boor."
            },
            {
                "spelling_detail_id": 15632,
                "word_type": "n.",
                "spelling_usage": "A selfish miser; an illiberal person; a niggard."
            },
            {
                "spelling_detail_id": 15633,
                "word_type": "a.",
                "spelling_usage": "Churlish; rough; selfish."
            }
        ]
    },
    {
        "id": 9507,
        "spelling_name": "Churlish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 15634,
                "word_type": "a.",
                "spelling_usage": "Like a churl; rude; cross-grained; ungracious; surly;\n   illiberal; niggardly."
            },
            {
                "spelling_detail_id": 15635,
                "word_type": "a.",
                "spelling_usage": "Wanting pliancy; unmanageable; unyielding; not easily\n   wrought; as, a churlish soil; the churlish and intractable nature of\n   some minerals."
            }
        ]
    },
    {
        "id": 9961,
        "spelling_name": "Clamor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 16289,
                "word_type": "n.",
                "spelling_usage": "A great outcry or vociferation; loud and continued shouting\n   or exclamation."
            },
            {
                "spelling_detail_id": 16290,
                "word_type": "n.",
                "spelling_usage": "Any loud and continued noise."
            },
            {
                "spelling_detail_id": 16291,
                "word_type": "n.",
                "spelling_usage": "A continued expression of dissatisfaction or discontent; a\n   popular outcry."
            },
            {
                "spelling_detail_id": 16294,
                "word_type": "v. t.",
                "spelling_usage": "To salute loudly."
            },
            {
                "spelling_detail_id": 16295,
                "word_type": "v. t.",
                "spelling_usage": "To stun with noise."
            },
            {
                "spelling_detail_id": 16296,
                "word_type": "v. t.",
                "spelling_usage": "To utter loudly or repeatedly; to shout."
            },
            {
                "spelling_detail_id": 16297,
                "word_type": "v. i.",
                "spelling_usage": "To utter loud sounds or outcries; to vociferate; to\n   complain; to make importunate demands."
            }
        ]
    },
    {
        "id": 10237,
        "spelling_name": "Clientele",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 16781,
                "word_type": "n.",
                "spelling_usage": "The condition or position of a client; clientship"
            },
            {
                "spelling_detail_id": 16782,
                "word_type": "n.",
                "spelling_usage": "The clients or dependents of a nobleman of patron."
            },
            {
                "spelling_detail_id": 16783,
                "word_type": "n.",
                "spelling_usage": "The persons who make habitual use of the services of\n   another person; one's clients, collectively; as, the clientele of a\n   lawyer, doctor, notary, etc."
            }
        ]
    },
    {
        "id": 10268,
        "spelling_name": "Clinch",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 16834,
                "word_type": "v. t.",
                "spelling_usage": "To hold firmly; to hold fast by grasping or embracing\n   tightly."
            },
            {
                "spelling_detail_id": 16835,
                "word_type": "v. t.",
                "spelling_usage": "To set closely together; to close tightly; as, to clinch\n   the teeth or the first."
            },
            {
                "spelling_detail_id": 16836,
                "word_type": "v. t.",
                "spelling_usage": "To bend or turn over the point of (something that has\n   been driven through an object), so that it will hold fast; as, to\n   clinch a nail."
            },
            {
                "spelling_detail_id": 16837,
                "word_type": "v. t.",
                "spelling_usage": "To make conclusive; to confirm; to establish; as, to\n   clinch an argument."
            },
            {
                "spelling_detail_id": 16838,
                "word_type": "v. i.",
                "spelling_usage": "To hold fast; to grasp something firmly; to seize or\n   grasp one another."
            },
            {
                "spelling_detail_id": 16839,
                "word_type": "n.",
                "spelling_usage": "The act or process of holding fast; that which serves to\n   hold fast; a grip; a grasp; a clamp; a holdfast; as, to get a good\n   clinch of an antagonist, or of a weapon; to secure anything by a\n   clinch."
            },
            {
                "spelling_detail_id": 16840,
                "word_type": "n.",
                "spelling_usage": "A pun."
            },
            {
                "spelling_detail_id": 16841,
                "word_type": "n.",
                "spelling_usage": "A hitch or bend by which a rope is made fast to the ring of\n   an anchor, or the breeching of a ship's gun to the ringbolts."
            }
        ]
    },
    {
        "id": 10273,
        "spelling_name": "Cling",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 16848,
                "word_type": "v. i.",
                "spelling_usage": "To adhere closely; to stick; to hold fast, especially by\n   twining round or embracing; as, the tendril of a vine clings to its\n   support; -- usually followed by to or together."
            },
            {
                "spelling_detail_id": 16849,
                "word_type": "v. t.",
                "spelling_usage": "To cause to adhere to, especially by twining round or\n   embracing."
            },
            {
                "spelling_detail_id": 16850,
                "word_type": "v. t.",
                "spelling_usage": "To make to dry up or wither."
            },
            {
                "spelling_detail_id": 16851,
                "word_type": "n.",
                "spelling_usage": "Adherence; attachment; devotion."
            }
        ]
    },
    {
        "id": 10376,
        "spelling_name": "Clot",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17060,
                "word_type": "n.",
                "spelling_usage": "A concretion or coagulation; esp. a soft, slimy, coagulated\n   mass, as of blood; a coagulum."
            },
            {
                "spelling_detail_id": 17063,
                "word_type": "v. i.",
                "spelling_usage": "To concrete, coagulate, or thicken, as soft or fluid\n   matter by evaporation; to become a cot or clod."
            },
            {
                "spelling_detail_id": 17064,
                "word_type": "v. t.",
                "spelling_usage": "To form into a slimy mass."
            }
        ]
    },
    {
        "id": 10396,
        "spelling_name": "Cloture",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17097,
                "word_type": "n.",
                "spelling_usage": "See Closure, 5."
            }
        ]
    },
    {
        "id": 10562,
        "spelling_name": "Coagulation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17344,
                "word_type": "n.",
                "spelling_usage": "The change from a liquid to a thickened, curdlike,\n   insoluble state, not by evaporation, but by some kind of chemical\n   reaction; as, the spontaneous coagulation of freshly drawn blood; the\n   coagulation of milk by rennet, or acid, and the coagulation of egg\n   albumin by heat. Coagulation is generally the change of an albuminous\n   body into an insoluble modification."
            },
            {
                "spelling_detail_id": 17345,
                "word_type": "n.",
                "spelling_usage": "The substance or body formed by coagulation."
            }
        ]
    },
    {
        "id": 10581,
        "spelling_name": "Coalescing",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17367,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Coalesce"
            }
        ]
    },
    {
        "id": 10618,
        "spelling_name": "Coax",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17448,
                "word_type": "v. t.",
                "spelling_usage": "To persuade by gentle, insinuating courtesy, flattering,\n   or fondling; to wheedle; to soothe."
            },
            {
                "spelling_detail_id": 17449,
                "word_type": "n.",
                "spelling_usage": "A simpleton; a dupe."
            }
        ]
    },
    {
        "id": 10758,
        "spelling_name": "Coda",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17690,
                "word_type": "n.",
                "spelling_usage": "A few measures added beyond the natural termination of a\n   composition."
            }
        ]
    },
    {
        "id": 10761,
        "spelling_name": "Coddle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17695,
                "word_type": "v. t.",
                "spelling_usage": "To parboil, or soften by boiling."
            },
            {
                "spelling_detail_id": 17696,
                "word_type": "v. t.",
                "spelling_usage": "To treat with excessive tenderness; to pamper."
            }
        ]
    },
    {
        "id": 10816,
        "spelling_name": "Coerce",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17766,
                "word_type": "v. t.",
                "spelling_usage": "To restrain by force, especially by law or authority; to\n   repress; to curb."
            },
            {
                "spelling_detail_id": 17767,
                "word_type": "v. t.",
                "spelling_usage": "To compel or constrain to any action; as, to coerce a\n   man to vote for a certain candidate."
            },
            {
                "spelling_detail_id": 17768,
                "word_type": "v. t.",
                "spelling_usage": "To compel or enforce; as, to coerce obedience."
            }
        ]
    },
    {
        "id": 10832,
        "spelling_name": "Coeval",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17783,
                "word_type": "n.",
                "spelling_usage": "Of the same age; existing during the same period of time,\n   especially time long and remote; -- usually followed by with."
            },
            {
                "spelling_detail_id": 17784,
                "word_type": "n.",
                "spelling_usage": "One of the same age; a contemporary."
            }
        ]
    },
    {
        "id": 10863,
        "spelling_name": "Cogent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17842,
                "word_type": "p. a.",
                "spelling_usage": "Compelling, in a physical sense; powerful."
            },
            {
                "spelling_detail_id": 17843,
                "word_type": "p. a.",
                "spelling_usage": "Having the power to compel conviction or move the will;\n   constraining; conclusive; forcible; powerful; not easily reasisted."
            }
        ]
    },
    {
        "id": 10873,
        "spelling_name": "Cogitate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17854,
                "word_type": "v. i.",
                "spelling_usage": "To engage in continuous thought; to think."
            },
            {
                "spelling_detail_id": 17855,
                "word_type": "v. t.",
                "spelling_usage": "To think over; to plan."
            }
        ]
    },
    {
        "id": 10892,
        "spelling_name": "Cognizant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17886,
                "word_type": "a.",
                "spelling_usage": "Having cognizance or knowledge. (of)."
            }
        ]
    },
    {
        "id": 10940,
        "spelling_name": "Cohort",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 17948,
                "word_type": "n.",
                "spelling_usage": "A body of about five or six hundred soldiers; the tenth\n   part of a legion."
            },
            {
                "spelling_detail_id": 17949,
                "word_type": "n.",
                "spelling_usage": "Any band or body of warriors."
            },
            {
                "spelling_detail_id": 17950,
                "word_type": "n.",
                "spelling_usage": "A natural group of orders of plants, less comprehensive\n   than a class."
            }
        ]
    },
    {
        "id": 10990,
        "spelling_name": "Colander",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 18029,
                "word_type": "n.",
                "spelling_usage": "A utensil with a bottom perforated with little holes for\n   straining liquids, mashed vegetable pulp, etc.; a strainer of\n   wickerwork, perforated metal, or the like."
            }
        ]
    },
    {
        "id": 11149,
        "spelling_name": "Colloquial",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 18300,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or used in, conversation, esp. common\n   and familiar conversation; conversational; hence, unstudied; informal;\n   as, colloquial intercourse; colloquial phrases; a colloquial style."
            }
        ]
    },
    {
        "id": 11163,
        "spelling_name": "Collusion",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 18317,
                "word_type": "n.",
                "spelling_usage": "A secret agreement and cooperation for a fraudulent or\n   deceitful purpose; a playing into each other's hands; deceit; fraud;\n   cunning."
            },
            {
                "spelling_detail_id": 18318,
                "word_type": "n.",
                "spelling_usage": "An agreement between two or more persons to defraud a\n   person of his rights, by the forms of law, or to obtain an object\n   forbidden by law."
            }
        ]
    },
    {
        "id": 11316,
        "spelling_name": "Combustion",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 18568,
                "word_type": "n.",
                "spelling_usage": "The state of burning."
            },
            {
                "spelling_detail_id": 18569,
                "word_type": "n.",
                "spelling_usage": "The combination of a combustible with a supporter of\n   combustion, producing heat, and sometimes both light and heat."
            },
            {
                "spelling_detail_id": 18570,
                "word_type": "n.",
                "spelling_usage": "Violent agitation; confusion; tumult."
            }
        ]
    },
    {
        "id": 11389,
        "spelling_name": "Commemorate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 18707,
                "word_type": "v. t.",
                "spelling_usage": "To call to remembrance by a special act or\n   observance; to celebrate with honor and solemnity; to honor, as a\n   person or event, by some act of respect or affection, intended to\n   preserve the remembrance of the person or event; as, to commemorate the\n   sufferings and dying love of our Savior by the sacrament of the Lord's\n   Supper; to commemorate the Declaration of Independence by the\n   observance of the Fourth of July."
            }
        ]
    },
    {
        "id": 11494,
        "spelling_name": "Commodious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 18880,
                "word_type": "a.",
                "spelling_usage": "Adapted to its use or purpose, or to wants and\n   necessities; serviceable; spacious and convenient; roomy and\n   comfortable; as, a commodious house."
            }
        ]
    },
    {
        "id": 11562,
        "spelling_name": "Commuter",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19026,
                "word_type": "n.",
                "spelling_usage": "One who commutes; especially, one who commutes in\n   traveling."
            }
        ]
    },
    {
        "id": 11695,
        "spelling_name": "Complaisance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19257,
                "word_type": "n.",
                "spelling_usage": "Disposition to please or oblige; obliging compliance\n   with the wishes of others; a deportment indicative of a desire to\n   please; courtesy; civility."
            }
        ]
    },
    {
        "id": 11696,
        "spelling_name": "Complaisant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19258,
                "word_type": "a.",
                "spelling_usage": "Desirous to please; courteous; obliging; compliant;\n   as, a complaisant gentleman."
            }
        ]
    },
    {
        "id": 11849,
        "spelling_name": "Compunction",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19530,
                "word_type": "n.",
                "spelling_usage": "A pricking; stimulation."
            },
            {
                "spelling_detail_id": 19531,
                "word_type": "n.",
                "spelling_usage": "A picking of heart; poignant grief proceeding from a\n   sense of guilt or consciousness of causing pain; the sting of\n   conscience."
            }
        ]
    },
    {
        "id": 11894,
        "spelling_name": "Conceal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19596,
                "word_type": "v. t.",
                "spelling_usage": "To hide or withdraw from observation; to cover; to\n   cover or keep from sight; to prevent the discovery of; to withhold\n   knowledge of."
            }
        ]
    },
    {
        "id": 11903,
        "spelling_name": "Conceit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19610,
                "word_type": "n.",
                "spelling_usage": "That which is conceived, imagined, or formed in the mind;\n   idea; thought; image; conception."
            },
            {
                "spelling_detail_id": 19611,
                "word_type": "n.",
                "spelling_usage": "Faculty of conceiving ideas; mental faculty; apprehension;\n   as, a man of quick conceit."
            },
            {
                "spelling_detail_id": 19612,
                "word_type": "n.",
                "spelling_usage": "Quickness of apprehension; active imagination; lively\n   fancy."
            },
            {
                "spelling_detail_id": 19613,
                "word_type": "n.",
                "spelling_usage": "A fanciful, odd, or extravagant notion; a quant fancy; an\n   unnatural or affected conception; a witty thought or turn of\n   expression; a fanciful device; a whim; a quip."
            },
            {
                "spelling_detail_id": 19614,
                "word_type": "n.",
                "spelling_usage": "An overweening idea of one's self; vanity."
            },
            {
                "spelling_detail_id": 19615,
                "word_type": "n.",
                "spelling_usage": "Design; pattern."
            },
            {
                "spelling_detail_id": 19616,
                "word_type": "v. t.",
                "spelling_usage": "To conceive; to imagine."
            },
            {
                "spelling_detail_id": 19617,
                "word_type": "v. i.",
                "spelling_usage": "To form an idea; to think."
            }
        ]
    },
    {
        "id": 12005,
        "spelling_name": "Conciliatory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19769,
                "word_type": "a.",
                "spelling_usage": "Tending to conciliate; pacific; mollifying;\n   propitiating."
            }
        ]
    },
    {
        "id": 12034,
        "spelling_name": "Concoct",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19818,
                "word_type": "v. t.",
                "spelling_usage": "To digest; to convert into nourishment by the organs of\n   nutrition."
            },
            {
                "spelling_detail_id": 19819,
                "word_type": "v. t.",
                "spelling_usage": "To purify or refine chemically."
            },
            {
                "spelling_detail_id": 19820,
                "word_type": "v. t.",
                "spelling_usage": "To prepare from crude materials, as food; to invent or\n   prepare by combining different ingredients; as, to concoct a new dish\n   or beverage."
            },
            {
                "spelling_detail_id": 19821,
                "word_type": "v. t.",
                "spelling_usage": "To digest in the mind; to devise; to make up; to\n   contrive; to plan; to plot."
            },
            {
                "spelling_detail_id": 19822,
                "word_type": "v. t.",
                "spelling_usage": "To mature or perfect; to ripen."
            }
        ]
    },
    {
        "id": 12046,
        "spelling_name": "Concord",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19838,
                "word_type": "n.",
                "spelling_usage": "A state of agreement; harmony; union."
            },
            {
                "spelling_detail_id": 19839,
                "word_type": "n.",
                "spelling_usage": "Agreement by stipulation; compact; covenant; treaty or\n   league."
            },
            {
                "spelling_detail_id": 19840,
                "word_type": "n.",
                "spelling_usage": "Agreement of words with one another, in gender, number,\n   person, or case."
            },
            {
                "spelling_detail_id": 19841,
                "word_type": "n.",
                "spelling_usage": "An agreement between the parties to a fine of land in\n   reference to the manner in which it should pass, being an\n   acknowledgment that the land in question belonged to the complainant.\n   See Fine."
            },
            {
                "spelling_detail_id": 19842,
                "word_type": "n.",
                "spelling_usage": "An agreeable combination of tones simultaneously heard; a\n   consonant chord; consonance; harmony."
            },
            {
                "spelling_detail_id": 19843,
                "word_type": "n.",
                "spelling_usage": "A variety of American grape, with large dark blue (almost\n   black) grapes in compact clusters."
            },
            {
                "spelling_detail_id": 19844,
                "word_type": "v. i.",
                "spelling_usage": "To agree; to act together."
            }
        ]
    },
    {
        "id": 12094,
        "spelling_name": "Concur",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19918,
                "word_type": "v. i.",
                "spelling_usage": "To run together; to meet."
            },
            {
                "spelling_detail_id": 19919,
                "word_type": "v. i.",
                "spelling_usage": "To meet in the same point; to combine or conjoin; to\n   contribute or help toward a common object or effect."
            },
            {
                "spelling_detail_id": 19920,
                "word_type": "v. i.",
                "spelling_usage": "To unite or agree (in action or opinion); to join; to\n   act jointly; to agree; to coincide; to correspond."
            },
            {
                "spelling_detail_id": 19921,
                "word_type": "v. i.",
                "spelling_usage": "To assent; to consent."
            }
        ]
    },
    {
        "id": 12121,
        "spelling_name": "Condense",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 19974,
                "word_type": "v. t.",
                "spelling_usage": "To make more close, compact, or dense; to compress or\n   concentrate into a smaller compass; to consolidate; to abridge; to\n   epitomize."
            },
            {
                "spelling_detail_id": 19975,
                "word_type": "v. t.",
                "spelling_usage": "To reduce into another and denser form, as by cold or\n   pressure; as, to condense gas into a liquid form, or steam into water."
            },
            {
                "spelling_detail_id": 19976,
                "word_type": "v. i.",
                "spelling_usage": "To become more compact; to be reduced into a denser\n   form."
            },
            {
                "spelling_detail_id": 19977,
                "word_type": "v. i.",
                "spelling_usage": "To combine or unite (as two chemical substances) with\n   or without separation of some unimportant side products."
            },
            {
                "spelling_detail_id": 19978,
                "word_type": "v. i.",
                "spelling_usage": "To undergo polymerization."
            },
            {
                "spelling_detail_id": 19979,
                "word_type": "a.",
                "spelling_usage": "Condensed; compact; dense."
            }
        ]
    },
    {
        "id": 12161,
        "spelling_name": "Condone",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20048,
                "word_type": "v. t.",
                "spelling_usage": "To pardon; to forgive."
            },
            {
                "spelling_detail_id": 20049,
                "word_type": "v. t.",
                "spelling_usage": "To pardon; to overlook the offense of; esp., to forgive\n   for a violation of the marriage law; -- said of either the husband or\n   the wife."
            }
        ]
    },
    {
        "id": 12167,
        "spelling_name": "Conduce",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20055,
                "word_type": "n.",
                "spelling_usage": "To lead or tend, esp. with reference to a favorable or\n   desirable result; to contribute; -- usually followed by to or toward."
            },
            {
                "spelling_detail_id": 20056,
                "word_type": "v. t.",
                "spelling_usage": "To conduct; to lead; to guide."
            }
        ]
    },
    {
        "id": 12398,
        "spelling_name": "Congeal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20416,
                "word_type": "v. t.",
                "spelling_usage": "To change from a fluid to a solid state by cold; to\n   freeze."
            },
            {
                "spelling_detail_id": 20417,
                "word_type": "v. t.",
                "spelling_usage": "To affect as if by freezing; to check the flow of, or\n   cause to run cold; to chill."
            },
            {
                "spelling_detail_id": 20418,
                "word_type": "v. i.",
                "spelling_usage": "To grow hard, stiff, or thick, from cold or other\n   causes; to become solid; to freeze; to cease to flow; to run cold; to\n   be chilled."
            }
        ]
    },
    {
        "id": 12521,
        "spelling_name": "Conjoin",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20590,
                "word_type": "v. t.",
                "spelling_usage": "To join together; to unite."
            },
            {
                "spelling_detail_id": 20591,
                "word_type": "v. i.",
                "spelling_usage": "To unite; to join; to league."
            }
        ]
    },
    {
        "id": 12593,
        "spelling_name": "Connoisseur",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20706,
                "word_type": "n.",
                "spelling_usage": "One well versed in any subject; a skillful or knowing\n   person; a critical judge of any art, particulary of one of the fine\n   arts."
            }
        ]
    },
    {
        "id": 12596,
        "spelling_name": "Connotation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20709,
                "word_type": "n.",
                "spelling_usage": "The act of connoting; a making known or designating\n   something additional; implication of something more than is asserted."
            }
        ]
    },
    {
        "id": 12599,
        "spelling_name": "Connote",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20715,
                "word_type": "v. t.",
                "spelling_usage": "To mark along with; to suggest or indicate as\n   additional; to designate by implication; to include in the meaning; to\n   imply."
            },
            {
                "spelling_detail_id": 20716,
                "word_type": "v. t.",
                "spelling_usage": "To imply as an attribute."
            }
        ]
    },
    {
        "id": 12675,
        "spelling_name": "Consequential",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20834,
                "word_type": "a.",
                "spelling_usage": "Following as a consequence, result, or logical\n   inference; consequent."
            },
            {
                "spelling_detail_id": 20835,
                "word_type": "a.",
                "spelling_usage": "Assuming or exhibiting an air of consequence;\n   pretending to importance; pompous; self-important; as, a consequential\n   man. See Consequence, n., 4."
            }
        ]
    },
    {
        "id": 12750,
        "spelling_name": "Console",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 20976,
                "word_type": "v. t.",
                "spelling_usage": "To cheer in distress or depression; to alleviate the\n   grief and raise the spirits of; to relieve; to comfort; to soothe."
            },
            {
                "spelling_detail_id": 20977,
                "word_type": "n.",
                "spelling_usage": "A bracket whose projection is not more than half its\n   height."
            },
            {
                "spelling_detail_id": 20978,
                "word_type": "n.",
                "spelling_usage": "Any small bracket; also, a console table."
            }
        ]
    },
    {
        "id": 12785,
        "spelling_name": "Conspicuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21034,
                "word_type": "a.",
                "spelling_usage": "Open to the view; obvious to the eye; easy to be seen;\n   plainly visible; manifest; attracting the eye."
            },
            {
                "spelling_detail_id": 21035,
                "word_type": "a.",
                "spelling_usage": "Obvious to the mental eye; easily recognized; clearly\n   defined; notable; prominent; eminent; distinguished; as, a conspicuous\n   excellence, or fault."
            }
        ]
    },
    {
        "id": 12814,
        "spelling_name": "Consternation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21081,
                "word_type": "n.",
                "spelling_usage": "Amazement or horror that confounds the faculties,\n   and incapacitates for reflection; terror, combined with amazement;\n   dismay."
            }
        ]
    },
    {
        "id": 12835,
        "spelling_name": "Constrain",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21129,
                "word_type": "v. t.",
                "spelling_usage": "To secure by bonds; to chain; to bond or confine; to\n   hold tightly; to constringe."
            },
            {
                "spelling_detail_id": 21130,
                "word_type": "v. t.",
                "spelling_usage": "To bring into a narrow compass; to compress."
            },
            {
                "spelling_detail_id": 21131,
                "word_type": "v. t.",
                "spelling_usage": "To hold back by force; to restrain; to repress."
            },
            {
                "spelling_detail_id": 21132,
                "word_type": "v. t.",
                "spelling_usage": "To compel; to force; to necessitate; to oblige."
            },
            {
                "spelling_detail_id": 21133,
                "word_type": "v. t.",
                "spelling_usage": "To violate; to ravish."
            },
            {
                "spelling_detail_id": 21134,
                "word_type": "v. t.",
                "spelling_usage": "To produce in such a manner as to give an unnatural\n   effect; as, a constrained voice."
            }
        ]
    },
    {
        "id": 12843,
        "spelling_name": "Constrict",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21143,
                "word_type": "v. t.",
                "spelling_usage": "To draw together; to render narrower or smaller; to\n   bind; to cramp; to contract or cause to shrink."
            }
        ]
    },
    {
        "id": 12901,
        "spelling_name": "Consume",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21233,
                "word_type": "v. t.",
                "spelling_usage": "To destroy, as by decomposition, dissipation, waste, or\n   fire; to use up; to expend; to waste; to burn up; to eat up; to devour."
            },
            {
                "spelling_detail_id": 21234,
                "word_type": "v. i.",
                "spelling_usage": "To waste away slowly."
            }
        ]
    },
    {
        "id": 12907,
        "spelling_name": "Consummate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21238,
                "word_type": "a.",
                "spelling_usage": "Carried to the utmost extent or degree; of the highest\n   quality; complete; perfect."
            },
            {
                "spelling_detail_id": 21241,
                "word_type": "v. t. ",
                "spelling_usage": "To bring to completion; to raise to the highest\n   point or degree; to complete; to finish; to perfect; to achieve."
            }
        ]
    },
    {
        "id": 12944,
        "spelling_name": "Contemn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21295,
                "word_type": "v. t.",
                "spelling_usage": "To view or treat with contempt, as mean and despicable;\n   to reject with disdain; to despise; to scorn."
            }
        ]
    },
    {
        "id": 12990,
        "spelling_name": "Contentious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21369,
                "word_type": "a.",
                "spelling_usage": "Fond of contention; given to angry debate; provoking\n   dispute or contention; quarrelsome."
            },
            {
                "spelling_detail_id": 21370,
                "word_type": "a.",
                "spelling_usage": "Relating to contention or strife; involving or\n   characterized by contention."
            },
            {
                "spelling_detail_id": 21371,
                "word_type": "a.",
                "spelling_usage": "Contested; litigated; litigious; having power to\n   decide controversy."
            }
        ]
    },
    {
        "id": 13020,
        "spelling_name": "Contiguous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21411,
                "word_type": "a.",
                "spelling_usage": "In actual contact; touching; also, adjacent; near;\n   neighboring; adjoining."
            }
        ]
    },
    {
        "id": 13159,
        "spelling_name": "Contrite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21652,
                "word_type": "a.",
                "spelling_usage": "Thoroughly bruised or broken."
            },
            {
                "spelling_detail_id": 21653,
                "word_type": "a.",
                "spelling_usage": "Broken down with grief and penitence; deeply sorrowful\n   for sin because it is displeasing to God; humbly and thoroughly\n   penitent."
            },
            {
                "spelling_detail_id": 21654,
                "word_type": "n.",
                "spelling_usage": "A contrite person."
            },
            {
                "spelling_detail_id": 21655,
                "word_type": "v.",
                "spelling_usage": "In a contrite manner."
            }
        ]
    },
    {
        "id": 13199,
        "spelling_name": "Contumacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21708,
                "word_type": "a.",
                "spelling_usage": "Exhibiting contumacy; contemning authority;\n   obstinate; perverse; stubborn; disobedient."
            },
            {
                "spelling_detail_id": 21709,
                "word_type": "a.",
                "spelling_usage": "Willfully disobedient to the summous or prders of a\n   court."
            }
        ]
    },
    {
        "id": 13207,
        "spelling_name": "Conundrum",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21722,
                "word_type": "n.",
                "spelling_usage": "A kind of riddle based upon some fanciful or fantastic\n   resemblance between things quite unlike; a puzzling question, of which\n   the answer is or involves a pun."
            },
            {
                "spelling_detail_id": 21723,
                "word_type": "n.",
                "spelling_usage": "A question to which only a conjectural answer can be\n   made."
            }
        ]
    },
    {
        "id": 13326,
        "spelling_name": "Conviction",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21932,
                "word_type": "n.",
                "spelling_usage": "The act of convicting; the act of proving, finding, or\n   adjudging, guilty of an offense."
            },
            {
                "spelling_detail_id": 21933,
                "word_type": "n.",
                "spelling_usage": "A judgment of condemnation entered by a court having\n   jurisdiction; the act or process of finding guilty, or the state of\n   being found guilty of any crime by a legal tribunal."
            },
            {
                "spelling_detail_id": 21934,
                "word_type": "n.",
                "spelling_usage": "The act of convincing of error, or of compelling the\n   admission of a truth; confutation."
            },
            {
                "spelling_detail_id": 21935,
                "word_type": "n.",
                "spelling_usage": "The state of being convinced or convicted; strong\n   persuasion or belief; especially, the state of being convicted of sin,\n   or by one's conscience."
            }
        ]
    },
    {
        "id": 13350,
        "spelling_name": "Convoke",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21969,
                "word_type": "v. t.",
                "spelling_usage": "To call together; to summon to meet; to assemble by\n   summons."
            }
        ]
    },
    {
        "id": 13355,
        "spelling_name": "Convoluted",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 21971,
                "word_type": "a.",
                "spelling_usage": "Having convolutions."
            },
            {
                "spelling_detail_id": 21972,
                "word_type": "a.",
                "spelling_usage": "Folded in tortuous windings."
            }
        ]
    },
    {
        "id": 13603,
        "spelling_name": "Cordial",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22360,
                "word_type": "a.",
                "spelling_usage": "Proceeding from the heart."
            },
            {
                "spelling_detail_id": 22361,
                "word_type": "a.",
                "spelling_usage": "Hearty; sincere; warm; affectionate."
            },
            {
                "spelling_detail_id": 22362,
                "word_type": "a.",
                "spelling_usage": "Tending to revive, cheer, or invigorate; giving strength\n   or spirits."
            },
            {
                "spelling_detail_id": 22363,
                "word_type": "n.",
                "spelling_usage": "Anything that comforts, gladdens, and exhilarates."
            },
            {
                "spelling_detail_id": 22364,
                "word_type": "n.",
                "spelling_usage": "Any invigorating and stimulating preparation; as, a\n   peppermint cordial."
            },
            {
                "spelling_detail_id": 22365,
                "word_type": "n.",
                "spelling_usage": "Aromatized and sweetened spirit, used as a beverage; a\n   liqueur."
            }
        ]
    },
    {
        "id": 13614,
        "spelling_name": "Cordon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22378,
                "word_type": "n.",
                "spelling_usage": "A cord or ribbon bestowed or borne as a badge of honor; a\n   broad ribbon, usually worn after the manner of a baldric, constituting\n   a mark of a very high grade in an honorary order. Cf. Grand cordon."
            },
            {
                "spelling_detail_id": 22379,
                "word_type": "n.",
                "spelling_usage": "The cord worn by a Franciscan friar."
            },
            {
                "spelling_detail_id": 22380,
                "word_type": "n.",
                "spelling_usage": "The coping of the scarp wall, which projects beyong the\n   face of the wall a few inches."
            },
            {
                "spelling_detail_id": 22381,
                "word_type": "n.",
                "spelling_usage": "A line or series of sentinels, or of military posts,\n   inclosing or guarding any place or thing."
            },
            {
                "spelling_detail_id": 22382,
                "word_type": "n.",
                "spelling_usage": "A rich and ornamental lace or string, used to secure a\n   mantle in some costumes of state."
            }
        ]
    },
    {
        "id": 13722,
        "spelling_name": "Cornucopia",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22566,
                "word_type": "n.",
                "spelling_usage": "The horn of plenty, from which fruits and flowers are\n   represented as issuing. It is an emblem of abundance."
            },
            {
                "spelling_detail_id": 22567,
                "word_type": "n.",
                "spelling_usage": "A genus of grasses bearing spikes of flowers resembling\n   the cornucopia in form."
            }
        ]
    },
    {
        "id": 13782,
        "spelling_name": "Corporeal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22663,
                "word_type": "a.",
                "spelling_usage": "Having a body; consisting of, or pertaining to, a\n   material body or substance; material; -- opposed to spiritual or\n   immaterial."
            }
        ]
    },
    {
        "id": 13831,
        "spelling_name": "Correlate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22739,
                "word_type": "v. i.",
                "spelling_usage": "To have reciprocal or mutual relations; to be\n   mutually related."
            },
            {
                "spelling_detail_id": 22740,
                "word_type": "v. t.",
                "spelling_usage": "To put in relation with each other; to connect\n   together by the disclosure of a mutual relation; as, to correlate\n   natural phenomena."
            },
            {
                "spelling_detail_id": 22741,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, stands in a reciprocal relation\n   to something else, as father to son; a correlative."
            }
        ]
    },
    {
        "id": 13867,
        "spelling_name": "Corroboration",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22795,
                "word_type": "n.",
                "spelling_usage": "The act of corroborating, strengthening, or\n   confirming; addition of strength; confirmation; as, the corroboration\n   of an argument, or of information."
            },
            {
                "spelling_detail_id": 22796,
                "word_type": "n.",
                "spelling_usage": "That which corroborates."
            }
        ]
    },
    {
        "id": 13937,
        "spelling_name": "Coruscate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 22897,
                "word_type": "v. i.",
                "spelling_usage": "To glitter in flashes; to flash."
            }
        ]
    },
    {
        "id": 14161,
        "spelling_name": "Countenance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23242,
                "word_type": "v. t.",
                "spelling_usage": "To encourage; to favor; to approve; to aid; to\n   abet."
            },
            {
                "spelling_detail_id": 23243,
                "word_type": "v. t.",
                "spelling_usage": "To make a show of; to pretend."
            }
        ]
    },
    {
        "id": 14206,
        "spelling_name": "Counterfeit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23314,
                "word_type": "adv.",
                "spelling_usage": "Representing by imitation or likeness; having a\n   resemblance to something else; portrayed."
            },
            {
                "spelling_detail_id": 23315,
                "word_type": "adv.",
                "spelling_usage": "Fabricated in imitation of something else, with a\n   view to defraud by passing the false copy for genuine or original; as,\n   counterfeit antiques; counterfeit coin."
            },
            {
                "spelling_detail_id": 23316,
                "word_type": "adv.",
                "spelling_usage": "Assuming the appearance of something; false;\n   spurious; deceitful; hypocritical; as, a counterfeit philanthropist."
            },
            {
                "spelling_detail_id": 23317,
                "word_type": "n.",
                "spelling_usage": "That which resembles or is like another thing; a\n   likeness; a portrait; a counterpart."
            },
            {
                "spelling_detail_id": 23318,
                "word_type": "n.",
                "spelling_usage": "That which is made in imitation of something, with a\n   view to deceive by passing the false for the true; as, the bank note\n   was a counterfeit."
            },
            {
                "spelling_detail_id": 23319,
                "word_type": "n.",
                "spelling_usage": "One who pretends to be what he is not; one who\n   personates another; an impostor; a cheat."
            },
            {
                "spelling_detail_id": 23322,
                "word_type": "v. t.",
                "spelling_usage": "To imitate, or put on a semblance of; to mimic; as,\n   to counterfeit the voice of another person."
            },
            {
                "spelling_detail_id": 23323,
                "word_type": "v. t.",
                "spelling_usage": "To imitate with a view to deceiving, by passing the\n   copy for that which is original or genuine; to forge; as, to\n   counterfeit the signature of another, coins, notes, etc."
            },
            {
                "spelling_detail_id": 23324,
                "word_type": "v. i.",
                "spelling_usage": "To carry on a deception; to dissemble; to feign; to\n   pretend."
            },
            {
                "spelling_detail_id": 23325,
                "word_type": "v. i.",
                "spelling_usage": "To make counterfeits."
            }
        ]
    },
    {
        "id": 14282,
        "spelling_name": "Countervail",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23444,
                "word_type": "v. t.",
                "spelling_usage": "To act against with equal force, power, or effect;\n   to thwart or overcome by such action; to furnish an equivalent to or\n   for; to counterbalance; to compensate."
            },
            {
                "spelling_detail_id": 23445,
                "word_type": "n.",
                "spelling_usage": "Power or value sufficient to obviate any effect; equal\n   weight, strength, or value; equivalent; compensation; requital."
            }
        ]
    },
    {
        "id": 14427,
        "spelling_name": "Covert",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23710,
                "word_type": "v. t.",
                "spelling_usage": "Covered over; private; hid; secret; disguised."
            },
            {
                "spelling_detail_id": 23711,
                "word_type": "v. t.",
                "spelling_usage": "Sheltered; not open or exposed; retired; protected; as,\n   a covert nook."
            },
            {
                "spelling_detail_id": 23712,
                "word_type": "v. t.",
                "spelling_usage": "Under cover, authority or protection; as, a feme covert,\n   a married woman who is considered as being under the protection and\n   control of her husband."
            },
            {
                "spelling_detail_id": 23713,
                "word_type": "a.",
                "spelling_usage": "A place that covers and protects; a shelter; a defense."
            },
            {
                "spelling_detail_id": 23714,
                "word_type": "a.",
                "spelling_usage": "One of the special feathers covering the bases of the\n   quills of the wings and tail of a bird. See Illust. of Bird."
            }
        ]
    },
    {
        "id": 14438,
        "spelling_name": "Covetous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23729,
                "word_type": "v. t.",
                "spelling_usage": "Very desirous; eager to obtain; -- used in a good\n   sense."
            },
            {
                "spelling_detail_id": 23730,
                "word_type": "v. t.",
                "spelling_usage": "Inordinately desirous; excessively eager to obtain and\n   possess (esp. money); avaricious; -- in a bad sense."
            }
        ]
    },
    {
        "id": 14470,
        "spelling_name": "Cower",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23777,
                "word_type": "v. i.",
                "spelling_usage": "To stoop by bending the knees; to crouch; to squat;\n   hence, to quail; to sink through fear."
            },
            {
                "spelling_detail_id": 23778,
                "word_type": "v. t.",
                "spelling_usage": "To cherish with care."
            }
        ]
    },
    {
        "id": 14515,
        "spelling_name": "Coy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 23839,
                "word_type": "a.",
                "spelling_usage": "Quiet; still."
            },
            {
                "spelling_detail_id": 23840,
                "word_type": "a.",
                "spelling_usage": "Shrinking from approach or familiarity; reserved; bashful;\n   shy; modest; -- usually applied to women, sometimes with an implication\n   of coquetry."
            },
            {
                "spelling_detail_id": 23841,
                "word_type": "a.",
                "spelling_usage": "Soft; gentle; hesitating."
            },
            {
                "spelling_detail_id": 23844,
                "word_type": "v. t.",
                "spelling_usage": "To allure; to entice; to decoy."
            },
            {
                "spelling_detail_id": 23845,
                "word_type": "v. t.",
                "spelling_usage": "To caress with the hand; to stroke."
            },
            {
                "spelling_detail_id": 23846,
                "word_type": "v. i.",
                "spelling_usage": "To behave with reserve or coyness; to shrink from approach\n   or familiarity."
            },
            {
                "spelling_detail_id": 23847,
                "word_type": "v. i.",
                "spelling_usage": "To make difficulty; to be unwilling."
            }
        ]
    },
    {
        "id": 14674,
        "spelling_name": "Crass",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 24147,
                "word_type": "a.",
                "spelling_usage": "Gross; thick; dense; coarse; not elaborated or refined."
            }
        ]
    },
    {
        "id": 14693,
        "spelling_name": "Cravat",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 24169,
                "word_type": "n.",
                "spelling_usage": "A neckcloth; a piece of silk, fine muslin, or other cloth,\n   worn by men about the neck."
            }
        ]
    },
    {
        "id": 14697,
        "spelling_name": "Craven",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 24176,
                "word_type": "a.",
                "spelling_usage": "Cowardly; fainthearted; spiritless."
            },
            {
                "spelling_detail_id": 24177,
                "word_type": "n.",
                "spelling_usage": "A recreant; a coward; a weak-hearted, spiritless fellow.\n   See Recreant, n."
            },
            {
                "spelling_detail_id": 24180,
                "word_type": "v. t.",
                "spelling_usage": "To make recreant, weak, spiritless, or cowardly."
            }
        ]
    },
    {
        "id": 14746,
        "spelling_name": "Crease",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 24265,
                "word_type": "n.",
                "spelling_usage": "See Creese."
            },
            {
                "spelling_detail_id": 24266,
                "word_type": "n.",
                "spelling_usage": "A line or mark made by folding or doubling any pliable\n   substance; hence, a similar mark, however produced."
            },
            {
                "spelling_detail_id": 24267,
                "word_type": "n.",
                "spelling_usage": "One of the lines serving to define the limits of the bowler\n   and the striker."
            },
            {
                "spelling_detail_id": 24270,
                "word_type": "v. t.",
                "spelling_usage": "To make a crease or mark in, as by folding or doubling."
            }
        ]
    },
    {
        "id": 14799,
        "spelling_name": "Credulity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 24355,
                "word_type": "n.",
                "spelling_usage": "Readiness of belief; a disposition to believe on slight\n   evidence."
            }
        ]
    },
    {
        "id": 14800,
        "spelling_name": "Credulous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 24356,
                "word_type": "a.",
                "spelling_usage": "Apt to believe on slight evidence; easily imposed upon;\n   unsuspecting."
            },
            {
                "spelling_detail_id": 24357,
                "word_type": "a.",
                "spelling_usage": "Believed too readily."
            }
        ]
    },
    {
        "id": 15371,
        "spelling_name": "Crush",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 25349,
                "word_type": "v. t.",
                "spelling_usage": "To press or bruise between two hard bodies; to squeeze,\n   so as to destroy the natural shape or integrity of the parts, or to\n   force together into a mass; as, to crush grapes."
            },
            {
                "spelling_detail_id": 25350,
                "word_type": "v. t.",
                "spelling_usage": "To reduce to fine particles by pounding or grinding; to\n   comminute; as, to crush quartz."
            },
            {
                "spelling_detail_id": 25351,
                "word_type": "v. t.",
                "spelling_usage": "To overwhelm by pressure or weight; to beat or force\n   down, as by an incumbent weight."
            },
            {
                "spelling_detail_id": 25352,
                "word_type": "v. t.",
                "spelling_usage": "To oppress or burden grievously."
            },
            {
                "spelling_detail_id": 25353,
                "word_type": "v. t.",
                "spelling_usage": "To overcome completely; to subdue totally."
            },
            {
                "spelling_detail_id": 25354,
                "word_type": "v. i.",
                "spelling_usage": "To be or become broken down or in, or pressed into a\n   smaller compass, by external weight or force; as, an eggshell crushes\n   easily."
            },
            {
                "spelling_detail_id": 25355,
                "word_type": "n.",
                "spelling_usage": "A violent collision or compression; a crash; destruction;\n   ruin."
            },
            {
                "spelling_detail_id": 25356,
                "word_type": "n.",
                "spelling_usage": "Violent pressure, as of a crowd; a crowd which produced\n   uncomfortable pressure; as, a crush at a peception."
            }
        ]
    },
    {
        "id": 15414,
        "spelling_name": "Cryptic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 25440,
                "word_type": "a.",
                "spelling_usage": "Alt. of Cryptical"
            }
        ]
    },
    {
        "id": 15639,
        "spelling_name": "Cumbersome",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 25767,
                "word_type": "a.",
                "spelling_usage": "Burdensome or hindering, as a weight or drag;\n   embarrassing; vexatious; cumbrous."
            },
            {
                "spelling_detail_id": 25768,
                "word_type": "a.",
                "spelling_usage": "Not easily managed; as, a cumbersome contrivance or\n   machine."
            }
        ]
    },
    {
        "id": 15789,
        "spelling_name": "Curmudgeon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 26016,
                "word_type": "n.",
                "spelling_usage": "An avaricious, grasping fellow; a miser; a niggard; a\n   churl."
            }
        ]
    },
    {
        "id": 15801,
        "spelling_name": "Curriculum",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 26043,
                "word_type": "n.",
                "spelling_usage": "A race course; a place for running."
            },
            {
                "spelling_detail_id": 26044,
                "word_type": "n.",
                "spelling_usage": "A course; particularly, a specified fixed course of\n   study, as in a university."
            }
        ]
    },
    {
        "id": 15826,
        "spelling_name": "Cursory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 26090,
                "word_type": "a.",
                "spelling_usage": "Running about; not stationary."
            },
            {
                "spelling_detail_id": 26091,
                "word_type": "a.",
                "spelling_usage": "Characterized by haste; hastily or superficially\n   performed; slight; superficial; careless."
            }
        ]
    },
    {
        "id": 15831,
        "spelling_name": "Curtail",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 26099,
                "word_type": "v. t.",
                "spelling_usage": "To cut off the end or tail, or any part, of; to\n   shorten; to abridge; to diminish; to reduce."
            },
            {
                "spelling_detail_id": 26100,
                "word_type": "n.",
                "spelling_usage": "The scroll termination of any architectural member, as of\n   a step, etc."
            }
        ]
    },
    {
        "id": 16260,
        "spelling_name": "Dainty",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 26737,
                "word_type": "n.",
                "spelling_usage": "Value; estimation; the gratification or pleasure taken in\n   anything."
            },
            {
                "spelling_detail_id": 26738,
                "word_type": "n.",
                "spelling_usage": "That which is delicious or delicate; a delicacy."
            },
            {
                "spelling_detail_id": 26739,
                "word_type": "n.",
                "spelling_usage": "A term of fondness."
            },
            {
                "spelling_detail_id": 26740,
                "word_type": "superl.",
                "spelling_usage": "Rare; valuable; costly."
            },
            {
                "spelling_detail_id": 26741,
                "word_type": "superl.",
                "spelling_usage": "Delicious to the palate; toothsome."
            },
            {
                "spelling_detail_id": 26742,
                "word_type": "superl.",
                "spelling_usage": "Nice; delicate; elegant, in form, manner, or breeding;\n   well-formed; neat; tender."
            },
            {
                "spelling_detail_id": 26743,
                "word_type": "superl.",
                "spelling_usage": "Requiring dainties. Hence: Overnice; hard to please;\n   fastidious; squeamish; scrupulous; ceremonious."
            }
        ]
    },
    {
        "id": 16403,
        "spelling_name": "Dangle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 26968,
                "word_type": "v. i.",
                "spelling_usage": "To hang loosely, or with a swinging or jerking motion."
            },
            {
                "spelling_detail_id": 26969,
                "word_type": "v. t.",
                "spelling_usage": "To cause to dangle; to swing, as something suspended\n   loosely; as, to dangle the feet."
            }
        ]
    },
    {
        "id": 16548,
        "spelling_name": "Daunt",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 27227,
                "word_type": "v. t.",
                "spelling_usage": "To overcome; to conquer."
            },
            {
                "spelling_detail_id": 27228,
                "word_type": "v. t.",
                "spelling_usage": "To repress or subdue the courage of; to check by fear of\n   danger; to cow; to intimidate; to dishearten."
            }
        ]
    },
    {
        "id": 16567,
        "spelling_name": "Dawdler",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 27252,
                "word_type": "n.",
                "spelling_usage": "One who wastes time in trifling employments; an idler; a\n   trifler."
            }
        ]
    },
    {
        "id": 16674,
        "spelling_name": "Dearth",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 27451,
                "word_type": "n.",
                "spelling_usage": "Scarcity which renders dear; want; lack; specifically, lack\n   of food on account of failure of crops; famine."
            }
        ]
    },
    {
        "id": 16699,
        "spelling_name": "Debacle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 27490,
                "word_type": "n.",
                "spelling_usage": "A breaking or bursting forth; a violent rush or flood of\n   waters which breaks down opposing barriers, and hurls forward and\n   disperses blocks of stone and other debris."
            }
        ]
    },
    {
        "id": 17071,
        "spelling_name": "Decorum",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28001,
                "word_type": "n.",
                "spelling_usage": "Propriety of manner or conduct; grace arising from\n   suitableness of speech and behavior to one's own character, or to the\n   place and occasion; decency of conduct; seemliness; that which is\n   seemly or suitable."
            }
        ]
    },
    {
        "id": 17084,
        "spelling_name": "Decree",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28022,
                "word_type": "n.",
                "spelling_usage": "An order from one having authority, deciding what is to be\n   done by a subordinate; also, a determination by one having power,\n   deciding what is to be done or to take place; edict, law; authoritative\n   ru// decision."
            },
            {
                "spelling_detail_id": 28023,
                "word_type": "n.",
                "spelling_usage": "A decision, order, or sentence, given in a cause by a court\n   of equity or admiralty."
            },
            {
                "spelling_detail_id": 28024,
                "word_type": "n.",
                "spelling_usage": "A determination or judgment of an umpire on a case\n   submitted to him."
            },
            {
                "spelling_detail_id": 28025,
                "word_type": "n.",
                "spelling_usage": "An edict or law made by a council for regulating any\n   business within their jurisdiction; as, the decrees of ecclesiastical\n   councils."
            },
            {
                "spelling_detail_id": 28028,
                "word_type": "v. t.",
                "spelling_usage": "To determine judicially by authority, or by decree; to\n   constitute by edict; to appoint by decree or law; to determine; to\n   order; to ordain; as, a court decrees a restoration of property."
            },
            {
                "spelling_detail_id": 28029,
                "word_type": "v. t.",
                "spelling_usage": "To ordain by fate."
            },
            {
                "spelling_detail_id": 28030,
                "word_type": "v. i.",
                "spelling_usage": "To make decrees; -- used absolutely."
            }
        ]
    },
    {
        "id": 17114,
        "spelling_name": "Decry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28067,
                "word_type": "v. t.",
                "spelling_usage": "To cry down; to censure as faulty, mean, or worthless; to\n   clamor against; to blame clamorously; to discredit; to disparage."
            }
        ]
    },
    {
        "id": 17229,
        "spelling_name": "Defalcate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28241,
                "word_type": "v. t.",
                "spelling_usage": "To cut off; to take away or deduct a part of; -- used\n   chiefly of money, accounts, rents, income, etc."
            },
            {
                "spelling_detail_id": 28242,
                "word_type": "v. i.",
                "spelling_usage": "To commit defalcation; to embezzle money held in\n   trust."
            }
        ]
    },
    {
        "id": 17293,
        "spelling_name": "Defer",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28357,
                "word_type": "v. t.",
                "spelling_usage": "To put off; to postpone to a future time; to delay the\n   execution of; to delay; to withhold."
            },
            {
                "spelling_detail_id": 28358,
                "word_type": "v. i.",
                "spelling_usage": "To put off; to delay to act; to wait."
            },
            {
                "spelling_detail_id": 28359,
                "word_type": "v. t.",
                "spelling_usage": "To render or offer."
            },
            {
                "spelling_detail_id": 28360,
                "word_type": "v. t.",
                "spelling_usage": "To lay before; to submit in a respectful manner; to\n   refer; -- with to."
            },
            {
                "spelling_detail_id": 28361,
                "word_type": "v. i.",
                "spelling_usage": "To yield deference to the wishes of another; to submit to\n   the opinion of another, or to authority; -- with to."
            }
        ]
    },
    {
        "id": 17296,
        "spelling_name": "Deferential",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28366,
                "word_type": "a.",
                "spelling_usage": "Expressing deference; accustomed to defer."
            }
        ]
    },
    {
        "id": 17305,
        "spelling_name": "Defiance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28374,
                "word_type": "n.",
                "spelling_usage": "The act of defying, putting in opposition, or provoking\n   to combat; a challenge; a provocation; a summons to combat."
            },
            {
                "spelling_detail_id": 28375,
                "word_type": "n.",
                "spelling_usage": "A state of opposition; willingness to flight; disposition\n   to resist; contempt of opposition."
            },
            {
                "spelling_detail_id": 28376,
                "word_type": "n.",
                "spelling_usage": "A casting aside; renunciation; rejection."
            }
        ]
    },
    {
        "id": 17634,
        "spelling_name": "Delineate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28861,
                "word_type": "a.",
                "spelling_usage": "Delineated; portrayed."
            },
            {
                "spelling_detail_id": 28864,
                "word_type": "v. t.",
                "spelling_usage": "To indicate by lines drawn in the form or figure of;\n   to represent by sketch, design, or diagram; to sketch out; to portray;\n   to picture; in drawing and engraving, to represent in lines, as with\n   the pen, pencil, or graver; hence, to represent with accuracy and\n   minuteness. See Delineation."
            },
            {
                "spelling_detail_id": 28865,
                "word_type": "v. t.",
                "spelling_usage": "To portray to the mind or understanding by words; to\n   set forth; to describe."
            }
        ]
    },
    {
        "id": 17713,
        "spelling_name": "Deluge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 28979,
                "word_type": "n.",
                "spelling_usage": "A washing away; an overflowing of the land by water; an\n   inundation; a flood; specifically, The Deluge, the great flood in the\n   days of Noah (Gen. vii.)."
            },
            {
                "spelling_detail_id": 28980,
                "word_type": "n.",
                "spelling_usage": "Fig.: Anything which overwhelms, or causes great\n   destruction."
            },
            {
                "spelling_detail_id": 28983,
                "word_type": "v. t.",
                "spelling_usage": "To overflow with water; to inundate; to overwhelm."
            },
            {
                "spelling_detail_id": 28984,
                "word_type": "v. t.",
                "spelling_usage": "To overwhelm, as with a deluge; to cover; to overspread;\n   to overpower; to submerge; to destroy; as, the northern nations deluged\n   the Roman empire with their armies; the land is deluged with woe."
            }
        ]
    },
    {
        "id": 17730,
        "spelling_name": "Demagogue",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29005,
                "word_type": "n.",
                "spelling_usage": "A leader of the rabble; one who attempts to control the\n   multitude by specious or deceitful arts; an unprincipled and factious\n   mob orator or political leader."
            }
        ]
    },
    {
        "id": 17903,
        "spelling_name": "Demur",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29250,
                "word_type": "v. i.",
                "spelling_usage": "To linger; to stay; to tarry."
            },
            {
                "spelling_detail_id": 29251,
                "word_type": "v. i.",
                "spelling_usage": "To delay; to pause; to suspend proceedings or judgment in\n   view of a doubt or difficulty; to hesitate; to put off the\n   determination or conclusion of an affair."
            },
            {
                "spelling_detail_id": 29252,
                "word_type": "v. i.",
                "spelling_usage": "To scruple or object; to take exception; as, I demur to\n   that statement."
            },
            {
                "spelling_detail_id": 29253,
                "word_type": "v. i.",
                "spelling_usage": "To interpose a demurrer. See Demurrer, 2."
            },
            {
                "spelling_detail_id": 29254,
                "word_type": "v. t.",
                "spelling_usage": "To suspend judgment concerning; to doubt of or hesitate\n   about."
            },
            {
                "spelling_detail_id": 29255,
                "word_type": "v. t.",
                "spelling_usage": "To cause delay to; to put off."
            },
            {
                "spelling_detail_id": 29256,
                "word_type": "v. i.",
                "spelling_usage": "Stop; pause; hesitation as to proceeding; suspense of\n   decision or action; scruple."
            }
        ]
    },
    {
        "id": 17949,
        "spelling_name": "Denigrate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29318,
                "word_type": "v. t.",
                "spelling_usage": "To blacken thoroughly; to make very black."
            },
            {
                "spelling_detail_id": 29319,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To blacken or sully; to defame."
            }
        ]
    },
    {
        "id": 17985,
        "spelling_name": "Denouement",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29370,
                "word_type": "n.",
                "spelling_usage": "The unraveling or discovery of a plot; the catastrophe,\n   especially of a drama or a romance."
            },
            {
                "spelling_detail_id": 29371,
                "word_type": "n.",
                "spelling_usage": "The solution of a mystery; issue; outcome."
            }
        ]
    },
    {
        "id": 18160,
        "spelling_name": "Deplete",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29620,
                "word_type": "a.",
                "spelling_usage": "To empty or unload, as the vessels of human system, by\n   bloodletting or by medicine."
            },
            {
                "spelling_detail_id": 29621,
                "word_type": "a.",
                "spelling_usage": "To reduce by destroying or consuming the vital powers of;\n   to exhaust, as a country of its strength or resources, a treasury of\n   money, etc."
            }
        ]
    },
    {
        "id": 18225,
        "spelling_name": "Deposition",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29718,
                "word_type": "n.",
                "spelling_usage": "The act of depositing or deposing; the act of laying\n   down or thrown down; precipitation."
            },
            {
                "spelling_detail_id": 29719,
                "word_type": "n.",
                "spelling_usage": "The act of bringing before the mind; presentation."
            },
            {
                "spelling_detail_id": 29720,
                "word_type": "n.",
                "spelling_usage": "The act of setting aside a sovereign or a public\n   officer; deprivation of authority and dignity; displacement; removal."
            },
            {
                "spelling_detail_id": 29721,
                "word_type": "n.",
                "spelling_usage": "That which is deposited; matter laid or thrown down;\n   sediment; alluvial matter; as, banks are sometimes depositions of\n   alluvial matter."
            },
            {
                "spelling_detail_id": 29722,
                "word_type": "n.",
                "spelling_usage": "An opinion, example, or statement, laid down or\n   asserted; a declaration."
            },
            {
                "spelling_detail_id": 29723,
                "word_type": "n.",
                "spelling_usage": "The act of laying down one's testimony in writing;\n   also, testimony laid or taken down in writing, under oath or\n   affirmation, before some competent officer, and in reply to\n   interrogatories and cross-interrogatories."
            }
        ]
    },
    {
        "id": 18234,
        "spelling_name": "Deprave",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29741,
                "word_type": "n. t.",
                "spelling_usage": "To speak ill of; to depreciate; to malign; to revile."
            },
            {
                "spelling_detail_id": 29742,
                "word_type": "n. t.",
                "spelling_usage": "To make bad or worse; to vitiate; to corrupt."
            }
        ]
    },
    {
        "id": 18244,
        "spelling_name": "Deprecate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29752,
                "word_type": "v. t.",
                "spelling_usage": "To pray against, as an evil; to seek to avert by\n   prayer; to desire the removal of; to seek deliverance from; to express\n   deep regret for; to disapprove of strongly."
            }
        ]
    },
    {
        "id": 18263,
        "spelling_name": "Depredation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29775,
                "word_type": "n.",
                "spelling_usage": "The act of depredating, or the state of being\n   depredated; the act of despoiling or making inroads; as, the sea often\n   makes depredation on the land."
            }
        ]
    },
    {
        "id": 18346,
        "spelling_name": "Dereliction",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29905,
                "word_type": "n.",
                "spelling_usage": "The act of leaving with an intention not to reclaim or\n   resume; an utter forsaking abandonment."
            },
            {
                "spelling_detail_id": 29906,
                "word_type": "n.",
                "spelling_usage": "A neglect or omission as if by willful abandonment."
            },
            {
                "spelling_detail_id": 29907,
                "word_type": "n.",
                "spelling_usage": "The state of being left or abandoned."
            },
            {
                "spelling_detail_id": 29908,
                "word_type": "n.",
                "spelling_usage": "A retiring of the sea, occasioning a change of\n   high-water mark, whereby land is gained."
            }
        ]
    },
    {
        "id": 18356,
        "spelling_name": "Derision",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29918,
                "word_type": "n.",
                "spelling_usage": "The act of deriding, or the state of being derided;\n   mockery; scornful or contemptuous treatment which holds one up to\n   ridicule."
            },
            {
                "spelling_detail_id": 29919,
                "word_type": "n.",
                "spelling_usage": "An object of derision or scorn; a laughing-stock."
            }
        ]
    },
    {
        "id": 18365,
        "spelling_name": "Derivative",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 29937,
                "word_type": "a.",
                "spelling_usage": "Obtained by derivation; derived; not radical, original,\n   or fundamental; originating, deduced, or formed from something else;\n   secondary; as, a derivative conveyance; a derivative word."
            },
            {
                "spelling_detail_id": 29938,
                "word_type": "n.",
                "spelling_usage": "That which is derived; anything obtained or deduced\n   from another."
            },
            {
                "spelling_detail_id": 29939,
                "word_type": "n.",
                "spelling_usage": "A word formed from another word, by a prefix or suffix,\n   an internal modification, or some other change; a word which takes its\n   origin from a root."
            },
            {
                "spelling_detail_id": 29940,
                "word_type": "n.",
                "spelling_usage": "A chord, not fundamental, but obtained from another by\n   inversion; or, vice versa, a ground tone or root implied in its\n   harmonics in an actual chord."
            },
            {
                "spelling_detail_id": 29941,
                "word_type": "n.",
                "spelling_usage": "An agent which is adapted to produce a derivation (in\n   the medical sense)."
            },
            {
                "spelling_detail_id": 29942,
                "word_type": "n.",
                "spelling_usage": "A derived function; a function obtained from a given\n   function by a certain algebraic process."
            },
            {
                "spelling_detail_id": 29943,
                "word_type": "n.",
                "spelling_usage": "A substance so related to another substance by\n   modification or partial substitution as to be regarded as derived from\n   it; thus, the amido compounds are derivatives of ammonia, and the\n   hydrocarbons are derivatives of methane, benzene, etc."
            }
        ]
    },
    {
        "id": 18419,
        "spelling_name": "Derogatory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30015,
                "word_type": "a.",
                "spelling_usage": "Tending to derogate, or lessen in value; expressing\n   derogation; detracting; injurious; -- with from to, or unto."
            }
        ]
    },
    {
        "id": 18460,
        "spelling_name": "Descry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30087,
                "word_type": "v. t.",
                "spelling_usage": "To spy out or discover by the eye, as objects distant or\n   obscure; to espy; to recognize; to discern; to discover."
            },
            {
                "spelling_detail_id": 30088,
                "word_type": "v. t.",
                "spelling_usage": "To discover; to disclose; to reveal."
            },
            {
                "spelling_detail_id": 30089,
                "word_type": "n.",
                "spelling_usage": "Discovery or view, as of an army seen at a distance."
            }
        ]
    },
    {
        "id": 18488,
        "spelling_name": "Desiccant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30128,
                "word_type": "a.",
                "spelling_usage": "Drying; desiccative."
            },
            {
                "spelling_detail_id": 30129,
                "word_type": "n.",
                "spelling_usage": "A medicine or application for drying up a sore."
            }
        ]
    },
    {
        "id": 18695,
        "spelling_name": "Desuetude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30417,
                "word_type": "n.",
                "spelling_usage": "The cessation of use; disuse; discontinuance of\n   practice, custom, or fashion."
            }
        ]
    },
    {
        "id": 18704,
        "spelling_name": "Desultory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30426,
                "word_type": "a.",
                "spelling_usage": "Leaping or skipping about."
            },
            {
                "spelling_detail_id": 30427,
                "word_type": "a.",
                "spelling_usage": "Jumping, or passing, from one thing or subject to\n   another, without order or rational connection; without logical\n   sequence; disconnected; immethodical; aimless; as, desultory minds."
            },
            {
                "spelling_detail_id": 30428,
                "word_type": "a.",
                "spelling_usage": "Out of course; by the way; as a digression; not\n   connected with the subject; as, a desultory remark."
            }
        ]
    },
    {
        "id": 18735,
        "spelling_name": "Deter",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30480,
                "word_type": "v. t.",
                "spelling_usage": "To prevent by fear; hence, to hinder or prevent from\n   action by fear of consequences, or difficulty, risk, etc."
            }
        ]
    },
    {
        "id": 18813,
        "spelling_name": "Detraction",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30595,
                "word_type": "n.",
                "spelling_usage": "A taking away or withdrawing."
            },
            {
                "spelling_detail_id": 30596,
                "word_type": "n.",
                "spelling_usage": "The act of taking away from the reputation or good name\n   of another; a lessening or cheapening in the estimation of others; the\n   act of depreciating another, from envy or malice; calumny."
            }
        ]
    },
    {
        "id": 18837,
        "spelling_name": "Detumescence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30626,
                "word_type": "n.",
                "spelling_usage": "Diminution of swelling; subsidence of anything\n   swollen."
            }
        ]
    },
    {
        "id": 19009,
        "spelling_name": "Dexterity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30882,
                "word_type": "n.",
                "spelling_usage": "Right-handedness."
            },
            {
                "spelling_detail_id": 30883,
                "word_type": "n.",
                "spelling_usage": "Readiness and grace in physical activity; skill and ease\n   in using the hands; expertness in manual acts; as, dexterity with the\n   chisel."
            },
            {
                "spelling_detail_id": 30884,
                "word_type": "n.",
                "spelling_usage": "Readiness in the use or control of the mental powers;\n   quickness and skill in managing any complicated or difficult affair;\n   adroitness."
            }
        ]
    },
    {
        "id": 19057,
        "spelling_name": "Diabolical",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 30936,
                "word_type": "a.",
                "spelling_usage": "Pertaining to the devil; resembling, or appropriate, or\n   appropriate to, the devil; devilish; infernal; impious; atrocious;\n   nefarious; outrageously wicked; as, a diabolic or diabolical temper or\n   act."
            }
        ]
    },
    {
        "id": 19188,
        "spelling_name": "Diaphanous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 31129,
                "word_type": "a.",
                "spelling_usage": "Allowing light to pass through, as porcelain;\n   translucent or transparent; pellucid; clear."
            }
        ]
    },
    {
        "id": 19245,
        "spelling_name": "Diatribe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 31200,
                "word_type": "n.",
                "spelling_usage": "A prolonged or exhaustive discussion; especially, an\n   acrimonious or invective harangue; a strain of abusive or railing\n   language; a philippic."
            }
        ]
    },
    {
        "id": 19343,
        "spelling_name": "Didactic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 31335,
                "word_type": "a.",
                "spelling_usage": "Alt. of Didactical"
            },
            {
                "spelling_detail_id": 31337,
                "word_type": "n.",
                "spelling_usage": "A treatise on teaching or education."
            }
        ]
    },
    {
        "id": 19444,
        "spelling_name": "Diffidence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 31492,
                "word_type": "n.",
                "spelling_usage": "The state of being diffident; distrust; want of\n   confidence; doubt of the power, ability, or disposition of others."
            },
            {
                "spelling_detail_id": 31493,
                "word_type": "n.",
                "spelling_usage": "Distrust of one's self or one's own powers; lack of\n   self-reliance; modesty; modest reserve; bashfulness."
            }
        ]
    },
    {
        "id": 19588,
        "spelling_name": "Dilate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 31714,
                "word_type": "v. t.",
                "spelling_usage": "To expand; to distend; to enlarge or extend in all\n   directions; to swell; -- opposed to contract; as, the air dilates the\n   lungs; air is dilated by increase of heat."
            },
            {
                "spelling_detail_id": 31715,
                "word_type": "v. t.",
                "spelling_usage": "To enlarge upon; to relate at large; to tell copiously\n   or diffusely."
            },
            {
                "spelling_detail_id": 31716,
                "word_type": "v. i.",
                "spelling_usage": "To grow wide; to expand; to swell or extend in all\n   directions."
            },
            {
                "spelling_detail_id": 31717,
                "word_type": "v. i.",
                "spelling_usage": "To speak largely and copiously; to dwell in narration;\n   to enlarge; -- with on or upon."
            },
            {
                "spelling_detail_id": 31718,
                "word_type": "a.",
                "spelling_usage": "Extensive; expanded."
            }
        ]
    },
    {
        "id": 19604,
        "spelling_name": "Dilettante",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 31743,
                "word_type": "v. t.",
                "spelling_usage": "An admirer or lover of the fine arts; popularly, an\n   amateur; especially, one who follows an art or a branch of knowledge,\n   desultorily, or for amusement only."
            }
        ]
    },
    {
        "id": 19892,
        "spelling_name": "Dirge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32183,
                "word_type": "a.",
                "spelling_usage": "A piece of music of a mournful character, to accompany\n   funeral rites; a funeral hymn."
            }
        ]
    },
    {
        "id": 19964,
        "spelling_name": "Disallow",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32290,
                "word_type": "v. t.",
                "spelling_usage": "To refuse to allow; to deny the force or validity of;\n   to disown and reject; as, the judge disallowed the executor's charge."
            }
        ]
    },
    {
        "id": 20103,
        "spelling_name": "Discern",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32461,
                "word_type": "v. t.",
                "spelling_usage": "To see and identify by noting a difference or\n   differences; to note the distinctive character of; to discriminate; to\n   distinguish."
            },
            {
                "spelling_detail_id": 32462,
                "word_type": "v. t.",
                "spelling_usage": "To see by the eye or by the understanding; to perceive\n   and recognize; as, to discern a difference."
            },
            {
                "spelling_detail_id": 32463,
                "word_type": "v. i.",
                "spelling_usage": "To see or understand the difference; to make\n   distinction; as, to discern between good and evil, truth and falsehood."
            },
            {
                "spelling_detail_id": 32464,
                "word_type": "v. i.",
                "spelling_usage": "To make cognizance."
            }
        ]
    },
    {
        "id": 20183,
        "spelling_name": "Discomfit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32602,
                "word_type": "v. t.",
                "spelling_usage": "To scatter in fight; to put to rout; to defeat."
            },
            {
                "spelling_detail_id": 32603,
                "word_type": "v. t.",
                "spelling_usage": "To break up and frustrate the plans of; to balk/ to\n   throw into perplexity and dejection; to disconcert."
            },
            {
                "spelling_detail_id": 32604,
                "word_type": "a.",
                "spelling_usage": "Discomfited; overthrown."
            },
            {
                "spelling_detail_id": 32605,
                "word_type": "n.",
                "spelling_usage": "Rout; overthrow; discomfiture."
            }
        ]
    },
    {
        "id": 20213,
        "spelling_name": "Disconcert",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32646,
                "word_type": "v. t.",
                "spelling_usage": "To break up the harmonious progress of; to throw\n   into disorder or confusion; as, the emperor disconcerted the plans of\n   his enemy."
            },
            {
                "spelling_detail_id": 32647,
                "word_type": "v. t.",
                "spelling_usage": "To confuse the faculties of; to disturb the\n   composure of; to discompose; to abash."
            },
            {
                "spelling_detail_id": 32648,
                "word_type": "n.",
                "spelling_usage": "Want of concert; disagreement."
            }
        ]
    },
    {
        "id": 20266,
        "spelling_name": "Discountenance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32727,
                "word_type": "v. t.",
                "spelling_usage": "To ruffle or discompose the countenance of; to\n   put of countenance; to put to shame; to abash."
            },
            {
                "spelling_detail_id": 32728,
                "word_type": "v. t.",
                "spelling_usage": "To refuse to countenance, or give the support of\n   one's approval to; to give one's influence against; to restrain by cold\n   treatment; to discourage."
            },
            {
                "spelling_detail_id": 32729,
                "word_type": "n.",
                "spelling_usage": "Unfavorable aspect; unfriendly regard; cold\n   treatment; disapprobation; whatever tends to check or discourage."
            }
        ]
    },
    {
        "id": 20279,
        "spelling_name": "Discourse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32743,
                "word_type": "n.",
                "spelling_usage": "The power of the mind to reason or infer by running, as\n   it were, from one fact or reason to another, and deriving a conclusion;\n   an exercise or act of this power; reasoning; range of reasoning\n   faculty."
            },
            {
                "spelling_detail_id": 32744,
                "word_type": "n.",
                "spelling_usage": "Conversation; talk."
            },
            {
                "spelling_detail_id": 32745,
                "word_type": "n.",
                "spelling_usage": "The art and manner of speaking and conversing."
            },
            {
                "spelling_detail_id": 32746,
                "word_type": "n.",
                "spelling_usage": "Consecutive speech, either written or unwritten, on a\n   given line of thought; speech; treatise; dissertation; sermon, etc.;\n   as, the preacher gave us a long discourse on duty."
            },
            {
                "spelling_detail_id": 32747,
                "word_type": "n.",
                "spelling_usage": "Dealing; transaction."
            },
            {
                "spelling_detail_id": 32750,
                "word_type": "v. i.",
                "spelling_usage": "To exercise reason; to employ the mind in judging and\n   inferring; to reason."
            },
            {
                "spelling_detail_id": 32751,
                "word_type": "v. i.",
                "spelling_usage": "To express one's self in oral discourse; to expose\n   one's views; to talk in a continuous or formal manner; to hold forth;\n   to speak; to converse."
            },
            {
                "spelling_detail_id": 32752,
                "word_type": "v. i.",
                "spelling_usage": "To relate something; to tell."
            },
            {
                "spelling_detail_id": 32753,
                "word_type": "v. i.",
                "spelling_usage": "To treat of something in writing and formally."
            },
            {
                "spelling_detail_id": 32754,
                "word_type": "v. t.",
                "spelling_usage": "To treat of; to expose or set forth in language."
            },
            {
                "spelling_detail_id": 32755,
                "word_type": "v. t.",
                "spelling_usage": "To utter or give forth; to speak."
            },
            {
                "spelling_detail_id": 32756,
                "word_type": "v. t.",
                "spelling_usage": "To talk to; to confer with."
            }
        ]
    },
    {
        "id": 20302,
        "spelling_name": "Discredit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32792,
                "word_type": "n.",
                "spelling_usage": "The act of discrediting or disbelieving, or the state of\n   being discredited or disbelieved; as, later accounts have brought the\n   story into discredit."
            },
            {
                "spelling_detail_id": 32793,
                "word_type": "n.",
                "spelling_usage": "Hence, some degree of dishonor or disesteem; ill repute;\n   reproach; -- applied to persons or things."
            },
            {
                "spelling_detail_id": 32796,
                "word_type": "v. t.",
                "spelling_usage": "To refuse credence to; not to accept as true; to\n   disbelieve; as, the report is discredited."
            },
            {
                "spelling_detail_id": 32797,
                "word_type": "v. t.",
                "spelling_usage": "To deprive of credibility; to destroy confidence or\n   trust in; to cause disbelief in the accuracy or authority of."
            },
            {
                "spelling_detail_id": 32798,
                "word_type": "v. t.",
                "spelling_usage": "To deprive of credit or good repute; to bring\n   reproach upon; to make less reputable; to disgrace."
            }
        ]
    },
    {
        "id": 20307,
        "spelling_name": "Discreet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32801,
                "word_type": "superl.",
                "spelling_usage": "Possessed of discernment, especially in avoiding\n   error or evil, and in the adaptation of means to ends; prudent;\n   sagacious; judicious; not rash or heedless; cautious."
            },
            {
                "spelling_detail_id": 32802,
                "word_type": "superl.",
                "spelling_usage": "Differing; distinct."
            }
        ]
    },
    {
        "id": 20311,
        "spelling_name": "Discrete",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32809,
                "word_type": "a.",
                "spelling_usage": "Separate; distinct; disjunct."
            },
            {
                "spelling_detail_id": 32810,
                "word_type": "a.",
                "spelling_usage": "Disjunctive; containing a disjunctive or discretive\n   clause; as, \"I resign my life, but not my honor,\" is a discrete\n   proposition."
            },
            {
                "spelling_detail_id": 32811,
                "word_type": "a.",
                "spelling_usage": "Separate; not coalescent; -- said of things usually\n   coalescent."
            },
            {
                "spelling_detail_id": 32812,
                "word_type": "v. t.",
                "spelling_usage": "To separate."
            }
        ]
    },
    {
        "id": 20366,
        "spelling_name": "Disdain",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32893,
                "word_type": "v. t.",
                "spelling_usage": "A feeling of contempt and aversion; the regarding\n   anything as unworthy of or beneath one; scorn."
            },
            {
                "spelling_detail_id": 32894,
                "word_type": "v. t.",
                "spelling_usage": "That which is worthy to be disdained or regarded with\n   contempt and aversion."
            },
            {
                "spelling_detail_id": 32895,
                "word_type": "v. t.",
                "spelling_usage": "The state of being despised; shame."
            },
            {
                "spelling_detail_id": 32898,
                "word_type": "v. t.",
                "spelling_usage": "To think unworthy; to deem unsuitable or unbecoming;\n   as, to disdain to do a mean act."
            },
            {
                "spelling_detail_id": 32899,
                "word_type": "v. t.",
                "spelling_usage": "To reject as unworthy of one's self, or as not\n   deserving one's notice; to look with scorn upon; to scorn, as base\n   acts, character, etc."
            },
            {
                "spelling_detail_id": 32900,
                "word_type": "v. i.",
                "spelling_usage": "To be filled with scorn; to feel contemptuous anger; to\n   be haughty."
            }
        ]
    },
    {
        "id": 20433,
        "spelling_name": "Disencumber",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 32980,
                "word_type": "v. t.",
                "spelling_usage": "To free from encumbrance, or from anything which\n   clogs, impedes, or obstructs; to disburden."
            }
        ]
    },
    {
        "id": 20583,
        "spelling_name": "Disheveled",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33171,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Dishevel"
            },
            {
                "spelling_detail_id": 33179,
                "word_type": "a.",
                "spelling_usage": "Having in loose disorder; disarranged; as, disheveled\n   hair."
            },
            {
                "spelling_detail_id": 33180,
                "word_type": "a.",
                "spelling_usage": "Having the hair in loose disorder."
            }
        ]
    },
    {
        "id": 20631,
        "spelling_name": "Disingenuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33243,
                "word_type": "a.",
                "spelling_usage": "Not noble; unbecoming true honor or dignity; mean;\n   unworthy; as, disingenuous conduct or schemes."
            },
            {
                "spelling_detail_id": 33244,
                "word_type": "a.",
                "spelling_usage": "Not ingenuous; wanting in noble candor or frankness;\n   not frank or open; uncandid; unworthily or meanly artful."
            }
        ]
    },
    {
        "id": 20646,
        "spelling_name": "Disinter",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33264,
                "word_type": "v. t.",
                "spelling_usage": "To take out of the grave or tomb; to unbury; to\n   exhume; to dig up."
            },
            {
                "spelling_detail_id": 33265,
                "word_type": "v. t.",
                "spelling_usage": "To bring out, as from a grave or hiding place; to\n   bring from obscurity into view."
            }
        ]
    },
    {
        "id": 20704,
        "spelling_name": "Dislodge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33353,
                "word_type": "v. t.",
                "spelling_usage": "To drive from a lodge or place of rest; to remove from\n   a place of quiet or repose; as, shells resting in the sea at a\n   considerate depth are not dislodged by storms."
            },
            {
                "spelling_detail_id": 33354,
                "word_type": "v. t.",
                "spelling_usage": "To drive out from a place of hiding or defense; as, to\n   dislodge a deer, or an enemy."
            },
            {
                "spelling_detail_id": 33355,
                "word_type": "v. i.",
                "spelling_usage": "To go from a place of rest."
            },
            {
                "spelling_detail_id": 33356,
                "word_type": "n.",
                "spelling_usage": "Dwelling apart; separation."
            }
        ]
    },
    {
        "id": 20713,
        "spelling_name": "Dismal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33363,
                "word_type": "a.",
                "spelling_usage": "Fatal; ill-omened; unlucky."
            },
            {
                "spelling_detail_id": 33364,
                "word_type": "a.",
                "spelling_usage": "Gloomy to the eye or ear; sorrowful and depressing to the\n   feelings; foreboding; cheerless; dull; dreary; as, a dismal outlook;\n   dismal stories; a dismal place."
            }
        ]
    },
    {
        "id": 20813,
        "spelling_name": "Disparate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33505,
                "word_type": "a.",
                "spelling_usage": "Unequal; dissimilar; separate."
            },
            {
                "spelling_detail_id": 33506,
                "word_type": "a.",
                "spelling_usage": "Pertaining to two coordinate species or divisions."
            }
        ]
    },
    {
        "id": 20967,
        "spelling_name": "Disproof",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33753,
                "word_type": "n.",
                "spelling_usage": "A proving to be false or erroneous; confutation;\n   refutation; as, to offer evidence in disproof of a statement."
            }
        ]
    },
    {
        "id": 21056,
        "spelling_name": "Disrobe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33869,
                "word_type": "v. t. & i.",
                "spelling_usage": "To divest of a robe; to undress; figuratively, to\n   strip of covering; to divest of that which clothes or decorates; as,\n   autumn disrobes the fields of verdure."
            }
        ]
    },
    {
        "id": 21095,
        "spelling_name": "Dissemble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33918,
                "word_type": "v. t.",
                "spelling_usage": "To hide under a false semblance or seeming; to feign\n   (something) not to be what it really is; to put an untrue appearance\n   upon; to disguise; to mask."
            },
            {
                "spelling_detail_id": 33919,
                "word_type": "v. t.",
                "spelling_usage": "To put on the semblance of; to make pretense of; to\n   simulate; to feign."
            },
            {
                "spelling_detail_id": 33920,
                "word_type": "v. i.",
                "spelling_usage": "To conceal the real fact, motives, /tention, or\n   sentiments, under some pretense; to assume a false appearance; to act\n   the hypocrite."
            }
        ]
    },
    {
        "id": 21099,
        "spelling_name": "Disseminate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33925,
                "word_type": "v. t. & i.",
                "spelling_usage": "To sow broadcast or as seed; to scatter for\n   growth and propagation, like seed; to spread abroad; to diffuse; as,\n   principles, ideas, opinions, and errors are disseminated when they are\n   spread abroad for propagation."
            },
            {
                "spelling_detail_id": 33926,
                "word_type": "v. t. & i.",
                "spelling_usage": "To spread or extend by dispersion."
            }
        ]
    },
    {
        "id": 21107,
        "spelling_name": "Dissent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 33935,
                "word_type": "v. i.",
                "spelling_usage": "To differ in opinion; to be of unlike or contrary\n   sentiment; to disagree; -- followed by from."
            },
            {
                "spelling_detail_id": 33936,
                "word_type": "v. i.",
                "spelling_usage": "To differ from an established church in regard to\n   doctrines, rites, or government."
            },
            {
                "spelling_detail_id": 33937,
                "word_type": "v. i.",
                "spelling_usage": "To differ; to be of a contrary nature."
            },
            {
                "spelling_detail_id": 33938,
                "word_type": "n.",
                "spelling_usage": "The act of dissenting; difference of opinion; refusal to\n   adopt something proposed; nonagreement, nonconcurrence, or\n   disagreement."
            },
            {
                "spelling_detail_id": 33939,
                "word_type": "n.",
                "spelling_usage": "Separation from an established church, especially that of\n   England; nonconformity."
            },
            {
                "spelling_detail_id": 33940,
                "word_type": "n.",
                "spelling_usage": "Contrariety of nature; diversity in quality."
            }
        ]
    },
    {
        "id": 21188,
        "spelling_name": "Dissolution",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34040,
                "word_type": "n.",
                "spelling_usage": "The act of dissolving, sundering, or separating into\n   component parts; separation."
            },
            {
                "spelling_detail_id": 34041,
                "word_type": "n.",
                "spelling_usage": "Change from a solid to a fluid state; solution by heat\n   or moisture; liquefaction; melting."
            },
            {
                "spelling_detail_id": 34042,
                "word_type": "n.",
                "spelling_usage": "Change of form by chemical agency; decomposition;\n   resolution."
            },
            {
                "spelling_detail_id": 34043,
                "word_type": "n.",
                "spelling_usage": "The dispersion of an assembly by terminating its\n   sessions; the breaking up of a partnership."
            },
            {
                "spelling_detail_id": 34044,
                "word_type": "n.",
                "spelling_usage": "The extinction of life in the human body; separation\n   of the soul from the body; death."
            },
            {
                "spelling_detail_id": 34045,
                "word_type": "n.",
                "spelling_usage": "The state of being dissolved, or of undergoing\n   liquefaction."
            },
            {
                "spelling_detail_id": 34046,
                "word_type": "n.",
                "spelling_usage": "The new product formed by dissolving a body; a\n   solution."
            },
            {
                "spelling_detail_id": 34047,
                "word_type": "n.",
                "spelling_usage": "Destruction of anything by the separation of its\n   parts; ruin."
            },
            {
                "spelling_detail_id": 34048,
                "word_type": "n.",
                "spelling_usage": "Corruption of morals; dissipation; dissoluteness."
            }
        ]
    },
    {
        "id": 21326,
        "spelling_name": "Distrait",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34309,
                "word_type": "a.",
                "spelling_usage": "Absent-minded; lost in thought; abstracted."
            }
        ]
    },
    {
        "id": 21327,
        "spelling_name": "Distraught",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34275,
                "word_type": "p. p.",
                "spelling_usage": "of Distract"
            },
            {
                "spelling_detail_id": 34310,
                "word_type": "a.",
                "spelling_usage": "Torn asunder; separated."
            },
            {
                "spelling_detail_id": 34311,
                "word_type": "a.",
                "spelling_usage": "Distracted; perplexed."
            }
        ]
    },
    {
        "id": 21474,
        "spelling_name": "Divergence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34553,
                "word_type": "n.",
                "spelling_usage": "Alt. of Divergency"
            }
        ]
    },
    {
        "id": 21516,
        "spelling_name": "Divestiture",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34617,
                "word_type": "n.",
                "spelling_usage": "The act of stripping, or depriving; the state of being\n   divested; the deprivation, or surrender, of possession of property,\n   rights, etc."
            }
        ]
    },
    {
        "id": 21576,
        "spelling_name": "Divulge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34748,
                "word_type": "v. t.",
                "spelling_usage": "To make public; to several or communicate to the\n   public; to tell (a secret) so that it may become generally known; to\n   disclose; -- said of that which had been confided as a secret, or had\n   been before unknown; as, to divulge a secret."
            },
            {
                "spelling_detail_id": 34749,
                "word_type": "v. t.",
                "spelling_usage": "To indicate publicly; to proclaim."
            },
            {
                "spelling_detail_id": 34750,
                "word_type": "v. t.",
                "spelling_usage": "To impart; to communicate."
            },
            {
                "spelling_detail_id": 34751,
                "word_type": "v. i.",
                "spelling_usage": "To become publicly known."
            }
        ]
    },
    {
        "id": 21735,
        "spelling_name": "Doggerel",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 34986,
                "word_type": "a.",
                "spelling_usage": "Low in style, and irregular in measure; as, doggerel\n   rhymes."
            },
            {
                "spelling_detail_id": 34987,
                "word_type": "n.",
                "spelling_usage": "A sort of loose or irregular verse; mean or undignified\n   poetry."
            }
        ]
    },
    {
        "id": 21745,
        "spelling_name": "Dogmatic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 35001,
                "word_type": "n.",
                "spelling_usage": "One of an ancient sect of physicians who went by general\n   principles; -- opposed to the Empiric."
            },
            {
                "spelling_detail_id": 35002,
                "word_type": "a.",
                "spelling_usage": "Alt. of Dogmatical"
            }
        ]
    },
    {
        "id": 21785,
        "spelling_name": "Doleful",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 35064,
                "word_type": "a.",
                "spelling_usage": "Full of dole or grief; expressing or exciting sorrow;\n   sorrowful; sad; dismal."
            }
        ]
    },
    {
        "id": 21820,
        "spelling_name": "Dolt",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 35118,
                "word_type": "n.",
                "spelling_usage": "A heavy, stupid fellow; a blockhead; a numskull; an\n   ignoramus; a dunce; a dullard."
            },
            {
                "spelling_detail_id": 35119,
                "word_type": "v. i.",
                "spelling_usage": "To behave foolishly."
            }
        ]
    },
    {
        "id": 21972,
        "spelling_name": "Dormant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 35354,
                "word_type": "a.",
                "spelling_usage": "Sleeping; as, a dormant animal; hence, not in action or\n   exercise; quiescent; at rest; in abeyance; not disclosed, asserted, or\n   insisted on; as, dormant passions; dormant claims or titles."
            },
            {
                "spelling_detail_id": 35355,
                "word_type": "a.",
                "spelling_usage": "In a sleeping posture; as, a lion dormant; --\n   distinguished from couchant."
            },
            {
                "spelling_detail_id": 35356,
                "word_type": "a.",
                "spelling_usage": "A large beam in the roof of a house upon which portions of\n   the other timbers rest or \" sleep.\""
            }
        ]
    },
    {
        "id": 22024,
        "spelling_name": "Dote",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 35442,
                "word_type": "n.",
                "spelling_usage": "A marriage portion. [Obs.] See 1st Dot, n."
            },
            {
                "spelling_detail_id": 35443,
                "word_type": "n.",
                "spelling_usage": "Natural endowments."
            },
            {
                "spelling_detail_id": 35446,
                "word_type": "v. i.",
                "spelling_usage": "To act foolishly."
            },
            {
                "spelling_detail_id": 35447,
                "word_type": "v. i.",
                "spelling_usage": "To be weak-minded, silly, or idiotic; to have the\n   intellect impaired, especially by age, so that the mind wanders or\n   wavers; to drivel."
            },
            {
                "spelling_detail_id": 35448,
                "word_type": "v. i.",
                "spelling_usage": "To be excessively or foolishly fond; to love to excess; to\n   be weakly affectionate; -- with on or upon; as, the mother dotes on her\n   child."
            },
            {
                "spelling_detail_id": 35449,
                "word_type": "n.",
                "spelling_usage": "An imbecile; a dotard."
            }
        ]
    },
    {
        "id": 22373,
        "spelling_name": "Drawl",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36120,
                "word_type": "v. t.",
                "spelling_usage": "To utter in a slow, lengthened tone."
            },
            {
                "spelling_detail_id": 36121,
                "word_type": "v. i.",
                "spelling_usage": "To speak with slow and lingering utterance, from\n   laziness, lack of spirit, affectation, etc."
            },
            {
                "spelling_detail_id": 36122,
                "word_type": "n.",
                "spelling_usage": "A lengthened, slow monotonous utterance."
            }
        ]
    },
    {
        "id": 22540,
        "spelling_name": "Droll",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36477,
                "word_type": "superl.",
                "spelling_usage": "Queer, and fitted to provoke laughter; ludicrous from\n   oddity; amusing and strange."
            },
            {
                "spelling_detail_id": 36478,
                "word_type": "n.",
                "spelling_usage": "One whose practice it is to raise mirth by odd tricks; a\n   jester; a buffoon; a merry-andrew."
            },
            {
                "spelling_detail_id": 36479,
                "word_type": "n.",
                "spelling_usage": "Something exhibited to raise mirth or sport, as a puppet, a\n   farce, and the like."
            },
            {
                "spelling_detail_id": 36482,
                "word_type": "v. i.",
                "spelling_usage": "To jest; to play the buffoon."
            },
            {
                "spelling_detail_id": 36483,
                "word_type": "v. t.",
                "spelling_usage": "To lead or influence by jest or trick; to banter or jest;\n   to cajole."
            },
            {
                "spelling_detail_id": 36484,
                "word_type": "v. t.",
                "spelling_usage": "To make a jest of; to set in a comical light."
            }
        ]
    },
    {
        "id": 22556,
        "spelling_name": "Drone",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36501,
                "word_type": "v. i.",
                "spelling_usage": "The male of bees, esp. of the honeybee. It gathers no\n   honey. See Honeybee."
            },
            {
                "spelling_detail_id": 36502,
                "word_type": "v. i.",
                "spelling_usage": "One who lives on the labors of others; a lazy, idle\n   fellow; a sluggard."
            },
            {
                "spelling_detail_id": 36503,
                "word_type": "v. i.",
                "spelling_usage": "That which gives out a grave or monotonous tone or dull\n   sound; as: (a) A drum. [Obs.] Halliwell. (b) The part of the bagpipe\n   containing the two lowest tubes, which always sound the key note and\n   the fifth."
            },
            {
                "spelling_detail_id": 36504,
                "word_type": "v. i.",
                "spelling_usage": "A humming or deep murmuring sound."
            },
            {
                "spelling_detail_id": 36505,
                "word_type": "v. i.",
                "spelling_usage": "A monotonous bass, as in a pastoral composition."
            },
            {
                "spelling_detail_id": 36508,
                "word_type": "n.",
                "spelling_usage": "To utter or make a low, dull, monotonous, humming or\n   murmuring sound."
            },
            {
                "spelling_detail_id": 36509,
                "word_type": "n.",
                "spelling_usage": "To love in idleness; to do nothing."
            }
        ]
    },
    {
        "id": 22625,
        "spelling_name": "Drowsiness",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36636,
                "word_type": "n.",
                "spelling_usage": "State of being drowsy."
            }
        ]
    },
    {
        "id": 22732,
        "spelling_name": "Dubious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36826,
                "word_type": "a.",
                "spelling_usage": "Doubtful or not settled in opinion; being in doubt;\n   wavering or fluctuating; undetermined."
            },
            {
                "spelling_detail_id": 36827,
                "word_type": "a.",
                "spelling_usage": "Occasioning doubt; not clear, or obvious; equivocal;\n   questionable; doubtful; as, a dubious answer."
            },
            {
                "spelling_detail_id": 36828,
                "word_type": "a.",
                "spelling_usage": "Of uncertain event or issue; as, in dubious battle."
            }
        ]
    },
    {
        "id": 22812,
        "spelling_name": "Dulcet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36952,
                "word_type": "a.",
                "spelling_usage": "Sweet to the taste; luscious."
            },
            {
                "spelling_detail_id": 36953,
                "word_type": "a.",
                "spelling_usage": "Sweet to the ear; melodious; harmonious."
            }
        ]
    },
    {
        "id": 22836,
        "spelling_name": "Dullard",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 36988,
                "word_type": "n.",
                "spelling_usage": "A stupid person; a dunce."
            },
            {
                "spelling_detail_id": 36989,
                "word_type": "a.",
                "spelling_usage": "Stupid."
            }
        ]
    },
    {
        "id": 22927,
        "spelling_name": "Dupe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37123,
                "word_type": "n.",
                "spelling_usage": "One who has been deceived or who is easily deceived; a gull;\n   as, the dupe of a schemer."
            },
            {
                "spelling_detail_id": 37126,
                "word_type": "n.",
                "spelling_usage": "To deceive; to trick; to mislead by imposing on one's\n   credulity; to gull; as, dupe one by flattery."
            }
        ]
    },
    {
        "id": 22942,
        "spelling_name": "Duplicity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37146,
                "word_type": "n.",
                "spelling_usage": "Doubleness; a twofold state."
            },
            {
                "spelling_detail_id": 37147,
                "word_type": "n.",
                "spelling_usage": "Doubleness of heart or speech; insincerity; a sustained\n   form of deception which consists in entertaining or pretending to\n   entertain one of feelings, and acting as if influenced by another; bad\n   faith."
            },
            {
                "spelling_detail_id": 37148,
                "word_type": "n.",
                "spelling_usage": "The use of two or more distinct allegations or answers,\n   where one is sufficient."
            },
            {
                "spelling_detail_id": 37149,
                "word_type": "n.",
                "spelling_usage": "In indictments, the union of two incompatible offenses."
            }
        ]
    },
    {
        "id": 22964,
        "spelling_name": "Duress",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37175,
                "word_type": "n.",
                "spelling_usage": "Hardship; constraint; pressure; imprisonment; restraint of\n   liberty."
            },
            {
                "spelling_detail_id": 37176,
                "word_type": "n.",
                "spelling_usage": "The state of compulsion or necessity in which a person is\n   influenced, whether by the unlawful restrain of his liberty or by\n   actual or threatened physical violence, to incur a civil liability or\n   to commit an offense."
            },
            {
                "spelling_detail_id": 37177,
                "word_type": "v. t.",
                "spelling_usage": "To subject to duress."
            }
        ]
    },
    {
        "id": 23020,
        "spelling_name": "Dwarf",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37271,
                "word_type": "n.",
                "spelling_usage": "An animal or plant which is much below the ordinary size of\n   its species or kind; especially, a diminutive human being."
            },
            {
                "spelling_detail_id": 37274,
                "word_type": "v. t.",
                "spelling_usage": "To hinder from growing to the natural size; to make or\n   keep small; to stunt."
            },
            {
                "spelling_detail_id": 37275,
                "word_type": "v. i.",
                "spelling_usage": "To become small; to diminish in size."
            }
        ]
    },
    {
        "id": 23070,
        "spelling_name": "Dynamo",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37342,
                "word_type": "n.",
                "spelling_usage": "A dynamo-electric machine."
            }
        ]
    },
    {
        "id": 23210,
        "spelling_name": "Earthenware",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37538,
                "word_type": "n.",
                "spelling_usage": "Vessels and other utensils, ornaments, or the like,\n   made of baked clay. See Crockery, Pottery, Stoneware, and Porcelain."
            }
        ]
    },
    {
        "id": 23297,
        "spelling_name": "Ebullience",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37704,
                "word_type": "n.",
                "spelling_usage": "Alt. of Ebulliency"
            }
        ]
    },
    {
        "id": 23299,
        "spelling_name": "Ebullient",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37706,
                "word_type": "a.",
                "spelling_usage": "Boiling up or over; hence, manifesting exhilaration or\n   excitement, as of feeling; effervescing."
            }
        ]
    },
    {
        "id": 23387,
        "spelling_name": "Eclat",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37828,
                "word_type": "n.",
                "spelling_usage": "Brilliancy of success or effort; splendor; brilliant show;\n   striking effect; glory; renown."
            },
            {
                "spelling_detail_id": 37829,
                "word_type": "n.",
                "spelling_usage": "Demonstration of admiration and approbation; applause."
            }
        ]
    },
    {
        "id": 23473,
        "spelling_name": "Ecumenical",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37952,
                "word_type": "a.",
                "spelling_usage": "General; universal; in ecclesiastical usage, that which\n   concerns the whole church; as, an ecumenical council."
            }
        ]
    },
    {
        "id": 23477,
        "spelling_name": "Edacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37957,
                "word_type": "a.",
                "spelling_usage": "Given to eating; voracious; devouring."
            }
        ]
    },
    {
        "id": 23488,
        "spelling_name": "Eddy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 37969,
                "word_type": "n.",
                "spelling_usage": "A current of air or water running back, or in a direction\n   contrary to the main current."
            },
            {
                "spelling_detail_id": 37970,
                "word_type": "n.",
                "spelling_usage": "A current of water or air moving in a circular direction; a\n   whirlpool."
            },
            {
                "spelling_detail_id": 37973,
                "word_type": "v. i.",
                "spelling_usage": "To move as an eddy, or as in an eddy; to move in a circle."
            },
            {
                "spelling_detail_id": 37974,
                "word_type": "v. t.",
                "spelling_usage": "To collect as into an eddy."
            }
        ]
    },
    {
        "id": 23518,
        "spelling_name": "Edible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38019,
                "word_type": "a.",
                "spelling_usage": "Fit to be eaten as food; eatable; esculent; as, edible\n   fishes."
            },
            {
                "spelling_detail_id": 38020,
                "word_type": "n.",
                "spelling_usage": "Anything edible."
            }
        ]
    },
    {
        "id": 23529,
        "spelling_name": "Edify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38034,
                "word_type": "v. i.",
                "spelling_usage": "To build; to construct."
            },
            {
                "spelling_detail_id": 38035,
                "word_type": "v. i.",
                "spelling_usage": "To instruct and improve, especially in moral and\n   religious knowledge; to teach."
            },
            {
                "spelling_detail_id": 38036,
                "word_type": "v. i.",
                "spelling_usage": "To teach or persuade."
            },
            {
                "spelling_detail_id": 38037,
                "word_type": "v. i.",
                "spelling_usage": "To improve."
            }
        ]
    },
    {
        "id": 23592,
        "spelling_name": "Efface",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38113,
                "word_type": "v. t.",
                "spelling_usage": "To cause to disappear (as anything impresses or\n   inscribed upon a surface) by rubbing out, striking out, etc.; to erase;\n   to render illegible or indiscernible; as, to efface the letters on a\n   monument, or the inscription on a coin."
            },
            {
                "spelling_detail_id": 38114,
                "word_type": "v. t.",
                "spelling_usage": "To destroy, as a mental impression; to wear away."
            }
        ]
    },
    {
        "id": 23641,
        "spelling_name": "Effete",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38182,
                "word_type": "a.",
                "spelling_usage": "No longer capable of producing young, as an animal, or\n   fruit, as the earth; hence, worn out with age; exhausted of energy;\n   incapable of efficient action; no longer productive; barren; sterile."
            }
        ]
    },
    {
        "id": 23644,
        "spelling_name": "Efficacy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38185,
                "word_type": "n.",
                "spelling_usage": "Power to produce effects; operation or energy of an agent\n   or force; production of the effect intended; as, the efficacy of\n   medicine in counteracting disease; the efficacy of prayer."
            }
        ]
    },
    {
        "id": 23668,
        "spelling_name": "Effluvia",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38223,
                "word_type": "pl. ",
                "spelling_usage": "of Effluvium"
            }
        ]
    },
    {
        "id": 23690,
        "spelling_name": "Effrontery",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38247,
                "word_type": "n.",
                "spelling_usage": "Impudence or boldness in confronting or in\n   transgressing the bounds of duty or decorum; insulting\n   presumptuousness; shameless boldness; barefaced assurance."
            }
        ]
    },
    {
        "id": 23773,
        "spelling_name": "Egress",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38352,
                "word_type": "n.",
                "spelling_usage": "The act of going out or leaving, or the power to leave;\n   departure."
            },
            {
                "spelling_detail_id": 38353,
                "word_type": "n.",
                "spelling_usage": "The passing off from the sun's disk of an inferior planet,\n   in a transit."
            },
            {
                "spelling_detail_id": 38354,
                "word_type": "v. i.",
                "spelling_usage": "To go out; to depart; to leave."
            }
        ]
    },
    {
        "id": 23845,
        "spelling_name": "Elaborate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38464,
                "word_type": "a.",
                "spelling_usage": "Wrought with labor; finished with great care; studied;\n   executed with exactness or painstaking; as, an elaborate discourse; an\n   elaborate performance; elaborate research."
            },
            {
                "spelling_detail_id": 38467,
                "word_type": "v. t.",
                "spelling_usage": "To produce with labor"
            },
            {
                "spelling_detail_id": 38468,
                "word_type": "v. t.",
                "spelling_usage": "To perfect with painstaking; to improve or refine\n   with labor and study, or by successive operations; as, to elaborate a\n   painting or a literary work."
            }
        ]
    },
    {
        "id": 24079,
        "spelling_name": "Elegy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38789,
                "word_type": "n.",
                "spelling_usage": "A mournful or plaintive poem; a funereal song; a poem of\n   lamentation."
            }
        ]
    },
    {
        "id": 24137,
        "spelling_name": "Elicit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 38901,
                "word_type": "a.",
                "spelling_usage": "Elicited; drawn out; made real; open; evident."
            },
            {
                "spelling_detail_id": 38904,
                "word_type": "v. t.",
                "spelling_usage": "To draw out or entice forth; to bring to light; to bring\n   out against the will; to deduce by reason or argument; as, to elicit\n   truth by discussion."
            }
        ]
    },
    {
        "id": 24234,
        "spelling_name": "Eloquence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 39033,
                "word_type": "n.",
                "spelling_usage": "Fluent, forcible, elegant, and persuasive speech in\n   public; the power of expressing strong emotions in striking and\n   appropriate language either spoken or written, thereby producing\n   conviction or persuasion."
            },
            {
                "spelling_detail_id": 39034,
                "word_type": "n.",
                "spelling_usage": "Fig.: Whatever produces the effect of moving and\n   persuasive speech."
            },
            {
                "spelling_detail_id": 39035,
                "word_type": "n.",
                "spelling_usage": "That which is eloquently uttered or written."
            }
        ]
    },
    {
        "id": 24294,
        "spelling_name": "Emaciate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 39107,
                "word_type": "v. i.",
                "spelling_usage": "To lose flesh gradually and become very lean; to waste\n   away in flesh."
            },
            {
                "spelling_detail_id": 39108,
                "word_type": "v. t.",
                "spelling_usage": "To cause to waste away in flesh and become very lean;\n   as, his sickness emaciated him."
            },
            {
                "spelling_detail_id": 39109,
                "word_type": "a.",
                "spelling_usage": "Emaciated."
            }
        ]
    },
    {
        "id": 24381,
        "spelling_name": "Embellish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 39234,
                "word_type": "v. t.",
                "spelling_usage": "To make beautiful or elegant by ornaments; to\n   decorate; to adorn; as, to embellish a book with pictures, a garden\n   with shrubs and flowers, a narrative with striking anecdotes, or style\n   with metaphors."
            }
        ]
    },
    {
        "id": 24390,
        "spelling_name": "Embezzle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 39245,
                "word_type": "v. t.",
                "spelling_usage": "To appropriate fraudulently to one's own use, as\n   property intrusted to one's care; to apply to one's private uses by a\n   breach of trust; as, to embezzle money held in trust."
            },
            {
                "spelling_detail_id": 39246,
                "word_type": "v. t.",
                "spelling_usage": "To misappropriate; to waste; to dissipate in\n   extravagance."
            }
        ]
    },
    {
        "id": 24694,
        "spelling_name": "Empirical",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 39637,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or founded upon, experiment or\n   experience; depending upon the observation of phenomena; versed in\n   experiments."
            },
            {
                "spelling_detail_id": 39638,
                "word_type": "a.",
                "spelling_usage": "Depending upon experience or observation alone, without\n   due regard to science and theory; -- said especially of medical\n   practice, remedies, etc.; wanting in science and deep insight; as,\n   empiric skill, remedies."
            }
        ]
    },
    {
        "id": 24933,
        "spelling_name": "Encomium",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 39965,
                "word_type": "n.",
                "spelling_usage": "Warm or high praise; panegyric; strong commendation."
            }
        ]
    },
    {
        "id": 24980,
        "spelling_name": "Encumbrance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40022,
                "word_type": "n.",
                "spelling_usage": "That which encumbers; a burden which impedes action,\n   or renders it difficult and laborious; a clog; an impediment. See\n   Incumbrance."
            },
            {
                "spelling_detail_id": 40023,
                "word_type": "n.",
                "spelling_usage": "Same as Incumbrance."
            }
        ]
    },
    {
        "id": 25018,
        "spelling_name": "Endearing",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40073,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Endear"
            },
            {
                "spelling_detail_id": 40078,
                "word_type": "a.",
                "spelling_usage": "Making dear or beloved; causing love."
            }
        ]
    },
    {
        "id": 25020,
        "spelling_name": "Endeavor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40082,
                "word_type": "v. t.",
                "spelling_usage": "To exert physical or intellectual strength for the\n   attainment of; to use efforts to effect; to strive to achieve or reach;\n   to try; to attempt."
            },
            {
                "spelling_detail_id": 40083,
                "word_type": "v. i.",
                "spelling_usage": "To exert one's self; to work for a certain end."
            },
            {
                "spelling_detail_id": 40084,
                "word_type": "n.",
                "spelling_usage": "An exertion of physical or intellectual strength toward\n   the attainment of an object; a systematic or continuous attempt; an\n   effort; a trial."
            }
        ]
    },
    {
        "id": 25033,
        "spelling_name": "Endemic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40094,
                "word_type": "a.",
                "spelling_usage": "Alt. of Endemical"
            },
            {
                "spelling_detail_id": 40096,
                "word_type": "n.",
                "spelling_usage": "An endemic disease."
            }
        ]
    },
    {
        "id": 25109,
        "spelling_name": "Endorse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40182,
                "word_type": "v. t.",
                "spelling_usage": "Same as Indorse."
            },
            {
                "spelling_detail_id": 40183,
                "word_type": "n.",
                "spelling_usage": "A subordinary, resembling the pale, but of one fourth its\n   width (according to some writers, one eighth)."
            }
        ]
    },
    {
        "id": 25164,
        "spelling_name": "Enduring",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40240,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Endure"
            },
            {
                "spelling_detail_id": 40248,
                "word_type": "a.",
                "spelling_usage": "Lasting; durable; long-suffering; as, an enduring\n   disposition."
            }
        ]
    },
    {
        "id": 25190,
        "spelling_name": "Enervate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40284,
                "word_type": "v. t.",
                "spelling_usage": "To deprive of nerve, force, strength, or courage; to\n   render feeble or impotent; to make effeminate; to impair the moral\n   powers of."
            },
            {
                "spelling_detail_id": 40285,
                "word_type": "a.",
                "spelling_usage": "Weakened; weak; without strength of force."
            }
        ]
    },
    {
        "id": 25261,
        "spelling_name": "Engender",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40394,
                "word_type": "v. t.",
                "spelling_usage": "To produce by the union of the sexes; to beget."
            },
            {
                "spelling_detail_id": 40395,
                "word_type": "v. t.",
                "spelling_usage": "To cause to exist; to bring forth; to produce; to sow\n   the seeds of; as, angry words engender strife."
            },
            {
                "spelling_detail_id": 40396,
                "word_type": "v. i.",
                "spelling_usage": "To assume form; to come into existence; to be caused\n   or produced."
            },
            {
                "spelling_detail_id": 40397,
                "word_type": "v. i.",
                "spelling_usage": "To come together; to meet, as in sexual embrace."
            },
            {
                "spelling_detail_id": 40398,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, engenders."
            }
        ]
    },
    {
        "id": 25322,
        "spelling_name": "Engrave",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40496,
                "word_type": "v. t.",
                "spelling_usage": "To deposit in the grave; to bury."
            },
            {
                "spelling_detail_id": 40501,
                "word_type": "v. t.",
                "spelling_usage": "To cut in; to make by incision."
            },
            {
                "spelling_detail_id": 40502,
                "word_type": "v. t.",
                "spelling_usage": "To cut with a graving instrument in order to form an\n   inscription or pictorial representation; to carve figures; to mark with\n   incisions."
            },
            {
                "spelling_detail_id": 40503,
                "word_type": "v. t.",
                "spelling_usage": "To form or represent by means of incisions upon wood,\n   stone, metal, or the like; as, to engrave an inscription."
            },
            {
                "spelling_detail_id": 40504,
                "word_type": "v. t.",
                "spelling_usage": "To impress deeply; to infix, as if with a graver."
            }
        ]
    },
    {
        "id": 25334,
        "spelling_name": "Engrossing",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40517,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Engross"
            }
        ]
    },
    {
        "id": 25337,
        "spelling_name": "Engulf",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40530,
                "word_type": "v. t.",
                "spelling_usage": "To absorb or swallow up as in a gulf."
            }
        ]
    },
    {
        "id": 25362,
        "spelling_name": "Enigma",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40555,
                "word_type": "n.",
                "spelling_usage": "A dark, obscure, or inexplicable saying; a riddle; a\n   statement, the hidden meaning of which is to be discovered or guessed."
            },
            {
                "spelling_detail_id": 40556,
                "word_type": "n.",
                "spelling_usage": "An action, mode of action, or thing, which cannot be\n   satisfactorily explained; a puzzle; as, his conduct is an enigma."
            }
        ]
    },
    {
        "id": 25428,
        "spelling_name": "Enmity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40644,
                "word_type": "n.",
                "spelling_usage": "The quality of being an enemy; hostile or unfriendly\n   disposition."
            },
            {
                "spelling_detail_id": 40645,
                "word_type": "n.",
                "spelling_usage": "A state of opposition; hostility."
            }
        ]
    },
    {
        "id": 25523,
        "spelling_name": "Ensconce",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40766,
                "word_type": "v. t.",
                "spelling_usage": "To cover or shelter, as with a sconce or fort; to\n   place or hide securely; to conceal."
            }
        ]
    },
    {
        "id": 25542,
        "spelling_name": "Ensign",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40786,
                "word_type": "n.",
                "spelling_usage": "A flag; a banner; a standard; esp., the national flag, or a\n   banner indicating nationality, carried by a ship or a body of soldiers;\n   -- as distinguished from flags indicating divisions of the army, rank\n   of naval officers, or private signals, and the like."
            },
            {
                "spelling_detail_id": 40787,
                "word_type": "n.",
                "spelling_usage": "A signal displayed like a standard, to give notice."
            },
            {
                "spelling_detail_id": 40788,
                "word_type": "n.",
                "spelling_usage": "Sign; badge of office, rank, or power; symbol."
            },
            {
                "spelling_detail_id": 40789,
                "word_type": "n.",
                "spelling_usage": "Formerly, a commissioned officer of the army who carried\n   the ensign or flag of a company or regiment."
            },
            {
                "spelling_detail_id": 40790,
                "word_type": "n.",
                "spelling_usage": "A commissioned officer of the lowest grade in the navy,\n   corresponding to the grade of second lieutenant in the army."
            },
            {
                "spelling_detail_id": 40791,
                "word_type": "v. t.",
                "spelling_usage": "To designate as by an ensign."
            },
            {
                "spelling_detail_id": 40792,
                "word_type": "v. t.",
                "spelling_usage": "To distinguish by a mark or ornament; esp. (Her.), by a\n   crown; thus, any charge which has a crown immediately above or upon it,\n   is said to be ensigned."
            }
        ]
    },
    {
        "id": 25587,
        "spelling_name": "Entangle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40853,
                "word_type": "v. t.",
                "spelling_usage": "To twist or interweave in such a manner as not to be\n   easily separated; to make tangled, confused, and intricate; as, to\n   entangle yarn or the hair."
            },
            {
                "spelling_detail_id": 40854,
                "word_type": "v. t.",
                "spelling_usage": "To involve in such complications as to render\n   extrication a bewildering difficulty; hence, metaphorically, to\n   insnare; to perplex; to bewilder; to puzzle; as, to entangle the feet\n   in a net, or in briers."
            }
        ]
    },
    {
        "id": 25664,
        "spelling_name": "Entice",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 40968,
                "word_type": "v. t.",
                "spelling_usage": "To draw on, by exciting hope or desire; to allure; to\n   attract; as, the bait enticed the fishes. Often in a bad sense: To lead\n   astray; to induce to evil; to tempt; as, the sirens enticed them to\n   listen."
            }
        ]
    },
    {
        "id": 25758,
        "spelling_name": "Entreat",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41101,
                "word_type": "v. t.",
                "spelling_usage": "To treat, or conduct toward; to deal with; to use."
            },
            {
                "spelling_detail_id": 41102,
                "word_type": "v. t.",
                "spelling_usage": "To treat with, or in respect to, a thing desired;\n   hence, to ask earnestly; to beseech; to petition or pray with urgency;\n   to supplicate; to importune."
            },
            {
                "spelling_detail_id": 41103,
                "word_type": "v. t.",
                "spelling_usage": "To beseech or supplicate successfully; to prevail upon\n   by prayer or solicitation; to persuade."
            },
            {
                "spelling_detail_id": 41104,
                "word_type": "v. t.",
                "spelling_usage": "To invite; to entertain."
            },
            {
                "spelling_detail_id": 41105,
                "word_type": "v. i.",
                "spelling_usage": "To treat or discourse; hence, to enter into\n   negotiations, as for a treaty."
            },
            {
                "spelling_detail_id": 41106,
                "word_type": "v. i.",
                "spelling_usage": "To make an earnest petition or request."
            },
            {
                "spelling_detail_id": 41107,
                "word_type": "n.",
                "spelling_usage": "Entreaty."
            }
        ]
    },
    {
        "id": 25803,
        "spelling_name": "Enunciate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41166,
                "word_type": "v. t.",
                "spelling_usage": "To make a formal statement of; to announce; to\n   proclaim; to declare, as a truth."
            },
            {
                "spelling_detail_id": 41167,
                "word_type": "v. t.",
                "spelling_usage": "To make distinctly audible; to utter articulately; to\n   pronounce; as, to enunciate a word distinctly."
            },
            {
                "spelling_detail_id": 41168,
                "word_type": "v. i.",
                "spelling_usage": "To utter words or syllables articulately."
            }
        ]
    },
    {
        "id": 25861,
        "spelling_name": "Enzyme",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41252,
                "word_type": "n.",
                "spelling_usage": "An unorganized or unformed ferment, in distinction from an\n   organized or living ferment; a soluble, or chemical, ferment. Ptyalin,\n   pepsin, diastase, and rennet are good examples of enzymes."
            }
        ]
    },
    {
        "id": 25976,
        "spelling_name": "Epicurean",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41389,
                "word_type": "a.",
                "spelling_usage": "Pertaining to Epicurus, or following his philosophy."
            },
            {
                "spelling_detail_id": 41390,
                "word_type": "a.",
                "spelling_usage": "Given to luxury; adapted to luxurious tastes; luxurious;\n   pertaining to good eating."
            },
            {
                "spelling_detail_id": 41391,
                "word_type": "n.",
                "spelling_usage": "A follower or Epicurus."
            },
            {
                "spelling_detail_id": 41392,
                "word_type": "n.",
                "spelling_usage": "One given to epicurean indulgence."
            }
        ]
    },
    {
        "id": 26078,
        "spelling_name": "Epiphany",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41512,
                "word_type": "n.",
                "spelling_usage": "An appearance, or a becoming manifest."
            },
            {
                "spelling_detail_id": 41513,
                "word_type": "n.",
                "spelling_usage": "A church festival celebrated on the 6th of January, the\n   twelfth day after Christmas, in commemoration of the visit of the Magi\n   of the East to Bethlehem, to see and worship the child Jesus; or, as\n   others maintain, to commemorate the appearance of the star to the Magi,\n   symbolizing the manifestation of Christ to the Gentles; Twelfthtide."
            }
        ]
    },
    {
        "id": 26149,
        "spelling_name": "Epistle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41601,
                "word_type": "n.",
                "spelling_usage": "A writing directed or sent to a person or persons; a\n   written communication; a letter; -- applied usually to formal,\n   didactic, or elegant letters."
            },
            {
                "spelling_detail_id": 41602,
                "word_type": "n.",
                "spelling_usage": "One of the letters in the New Testament which were\n   addressed to their Christian brethren by Apostles."
            },
            {
                "spelling_detail_id": 41603,
                "word_type": "v. t.",
                "spelling_usage": "To write; to communicate in a letter or by writing."
            }
        ]
    },
    {
        "id": 26191,
        "spelling_name": "Epithet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41653,
                "word_type": "n.",
                "spelling_usage": "An adjective expressing some quality, attribute, or\n   relation, that is properly or specially appropriate to a person or\n   thing; as, a just man; a verdant lawn."
            },
            {
                "spelling_detail_id": 41654,
                "word_type": "n.",
                "spelling_usage": "Term; expression; phrase."
            },
            {
                "spelling_detail_id": 41655,
                "word_type": "v. t.",
                "spelling_usage": "To describe by an epithet."
            }
        ]
    },
    {
        "id": 26199,
        "spelling_name": "Epitome",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41664,
                "word_type": "n.",
                "spelling_usage": "A work in which the contents of a former work are reduced\n   within a smaller space by curtailment and condensation; a brief\n   summary; an abridgement."
            },
            {
                "spelling_detail_id": 41665,
                "word_type": "n.",
                "spelling_usage": "A compact or condensed representation of anything."
            }
        ]
    },
    {
        "id": 26248,
        "spelling_name": "Equable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41724,
                "word_type": "a.",
                "spelling_usage": "Equal and uniform; continuing the same at different times;\n   -- said of motion, and the like; uniform in surface; smooth; as, an\n   equable plain or globe."
            },
            {
                "spelling_detail_id": 41725,
                "word_type": "a.",
                "spelling_usage": "Uniform in action or intensity; not variable or changing;\n   -- said of the feelings or temper."
            }
        ]
    },
    {
        "id": 26267,
        "spelling_name": "Equanimity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41760,
                "word_type": "n.",
                "spelling_usage": "Evenness of mind; that calm temper or firmness of mind\n   which is not easily elated or depressed; patience; calmness; composure;\n   as, to bear misfortunes with equanimity."
            }
        ]
    },
    {
        "id": 26306,
        "spelling_name": "Equilibrium",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41811,
                "word_type": "n.",
                "spelling_usage": "Equality of weight or force; an equipoise or a state\n   of rest produced by the mutual counteraction of two or more forces."
            },
            {
                "spelling_detail_id": 41812,
                "word_type": "n.",
                "spelling_usage": "A level position; a just poise or balance in respect\n   to an object, so that it remains firm; equipoise; as, to preserve the\n   equilibrium of the body."
            },
            {
                "spelling_detail_id": 41813,
                "word_type": "n.",
                "spelling_usage": "A balancing of the mind between motives or reasons,\n   with consequent indecision and doubt."
            }
        ]
    },
    {
        "id": 26326,
        "spelling_name": "Equipoise",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41843,
                "word_type": "n.",
                "spelling_usage": "Equality of weight or force; hence, equilibrium; a state\n   in which the two ends or sides of a thing are balanced, and hence\n   equal; state of being equally balanced; -- said of moral, political, or\n   social interests or forces."
            },
            {
                "spelling_detail_id": 41844,
                "word_type": "n.",
                "spelling_usage": "Counterpoise."
            }
        ]
    },
    {
        "id": 26367,
        "spelling_name": "Equivocal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41900,
                "word_type": "a.",
                "spelling_usage": "(Literally, called equally one thing or the other;\n   hence:) Having two significations equally applicable; capable of double\n   interpretation; of doubtful meaning; ambiguous; uncertain; as,\n   equivocal words; an equivocal sentence."
            },
            {
                "spelling_detail_id": 41901,
                "word_type": "a.",
                "spelling_usage": "Capable of being ascribed to different motives, or of\n   signifying opposite feelings, purposes, or characters; deserving to be\n   suspected; as, his actions are equivocal."
            },
            {
                "spelling_detail_id": 41902,
                "word_type": "a.",
                "spelling_usage": "Uncertain, as an indication or sign; doubtful."
            },
            {
                "spelling_detail_id": 41903,
                "word_type": "n.",
                "spelling_usage": "A word or expression capable of different meanings; an\n   ambiguous term; an equivoque."
            }
        ]
    },
    {
        "id": 26370,
        "spelling_name": "Equivocate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41908,
                "word_type": "a.",
                "spelling_usage": "To use words of equivocal or doubtful signification; to\n   express one's opinions in terms which admit of different senses, with\n   intent to deceive; to use ambiguous expressions with a view to mislead;\n   as, to equivocate is the work of duplicity."
            },
            {
                "spelling_detail_id": 41909,
                "word_type": "v. t.",
                "spelling_usage": "To render equivocal or ambiguous."
            }
        ]
    },
    {
        "id": 26386,
        "spelling_name": "Eradicate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 41932,
                "word_type": "v. t.",
                "spelling_usage": "To pluck up by the roots; to root up; as, an oak tree\n   eradicated."
            },
            {
                "spelling_detail_id": 41933,
                "word_type": "v. t.",
                "spelling_usage": "To root out; to destroy utterly; to extirpate; as, to\n   eradicate diseases, or errors."
            }
        ]
    },
    {
        "id": 26510,
        "spelling_name": "Erratic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42106,
                "word_type": "a.",
                "spelling_usage": "Having no certain course; roving about without a fixed\n   destination; wandering; moving; -- hence, applied to the planets as\n   distinguished from the fixed stars."
            },
            {
                "spelling_detail_id": 42107,
                "word_type": "a.",
                "spelling_usage": "Deviating from a wise of the common course in opinion or\n   conduct; eccentric; strange; queer; as, erratic conduct."
            },
            {
                "spelling_detail_id": 42108,
                "word_type": "a.",
                "spelling_usage": "Irregular; changeable."
            },
            {
                "spelling_detail_id": 42109,
                "word_type": "n.",
                "spelling_usage": "One who deviates from common and accepted opinions; one\n   who is eccentric or preserve in his intellectual character."
            },
            {
                "spelling_detail_id": 42110,
                "word_type": "n.",
                "spelling_usage": "A rogue."
            },
            {
                "spelling_detail_id": 42111,
                "word_type": "n.",
                "spelling_usage": "Any stone or material that has been borne away from its\n   original site by natural agencies; esp., a large block or fragment of\n   rock; a bowlder."
            }
        ]
    },
    {
        "id": 26538,
        "spelling_name": "Erudite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42152,
                "word_type": "a.",
                "spelling_usage": "Characterized by extensive reading or knowledge; well\n   instructed; learned."
            }
        ]
    },
    {
        "id": 26617,
        "spelling_name": "Eschew",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42272,
                "word_type": "a.",
                "spelling_usage": "To shun; to avoid, as something wrong, or from a feeling of\n   distaste; to keep one's self clear of."
            },
            {
                "spelling_detail_id": 42273,
                "word_type": "a.",
                "spelling_usage": "To escape from; to avoid."
            }
        ]
    },
    {
        "id": 26674,
        "spelling_name": "Esoteric",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42334,
                "word_type": "a.",
                "spelling_usage": "Designed for, and understood by, the specially initiated\n   alone; not communicated, or not intelligible, to the general body of\n   followers; private; interior; acroamatic; -- said of the private and\n   more recondite instructions and doctrines of philosophers. Opposed to\n   exoteric."
            }
        ]
    },
    {
        "id": 26704,
        "spelling_name": "Espouse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42370,
                "word_type": "v. t.",
                "spelling_usage": "To betroth; to promise in marriage; to give as spouse."
            },
            {
                "spelling_detail_id": 42371,
                "word_type": "v. t.",
                "spelling_usage": "To take as spouse; to take to wife; to marry."
            },
            {
                "spelling_detail_id": 42372,
                "word_type": "v. t.",
                "spelling_usage": "To take to one's self with a view to maintain; to make\n   one's own; to take up the cause of; to adopt; to embrace."
            }
        ]
    },
    {
        "id": 27025,
        "spelling_name": "Eulogy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42815,
                "word_type": "n.",
                "spelling_usage": "A speech or writing in commendation of the character or\n   services of a person; as, a fitting eulogy to worth."
            }
        ]
    },
    {
        "id": 27044,
        "spelling_name": "Eupeptic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42836,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to good digestion; easy of digestion;\n   having a good digestion; as, eupeptic food; an eupeptic man."
            }
        ]
    },
    {
        "id": 27108,
        "spelling_name": "Euthanasia",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42908,
                "word_type": "n.",
                "spelling_usage": "An easy death; a mode of dying to be desired."
            }
        ]
    },
    {
        "id": 27174,
        "spelling_name": "Evasive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 42999,
                "word_type": "a.",
                "spelling_usage": "Tending to evade, or marked by evasion; elusive;\n   shuffling; avoiding by artifice."
            }
        ]
    },
    {
        "id": 27261,
        "spelling_name": "Evince",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43144,
                "word_type": "v. t.",
                "spelling_usage": "To conquer; to subdue."
            },
            {
                "spelling_detail_id": 43145,
                "word_type": "v. t.",
                "spelling_usage": "To show in a clear manner; to prove beyond any\n   reasonable doubt; to manifest; to make evident; to bring to light; to\n   evidence."
            }
        ]
    },
    {
        "id": 27283,
        "spelling_name": "Evoke",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43167,
                "word_type": "v. t.",
                "spelling_usage": "To call out; to summon forth."
            },
            {
                "spelling_detail_id": 43168,
                "word_type": "v. t.",
                "spelling_usage": "To call away; to remove from one tribunal to another."
            }
        ]
    },
    {
        "id": 27566,
        "spelling_name": "Excoriation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43560,
                "word_type": "n.",
                "spelling_usage": "The act of excoriating or flaying, or state of being\n   excoriated, or stripped of the skin; abrasion."
            },
            {
                "spelling_detail_id": 43561,
                "word_type": "n.",
                "spelling_usage": "Stripping of possession; spoliation."
            }
        ]
    },
    {
        "id": 27598,
        "spelling_name": "Exculpate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43599,
                "word_type": "v. t.",
                "spelling_usage": "To clear from alleged fault or guilt; to prove to be\n   guiltless; to relieve of blame; to acquit."
            }
        ]
    },
    {
        "id": 27720,
        "spelling_name": "Exhaustive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43808,
                "word_type": "a.",
                "spelling_usage": "Serving or tending to exhaust; exhibiting all the facts\n   or arguments; as, an exhaustive method."
            }
        ]
    },
    {
        "id": 27758,
        "spelling_name": "Exigency",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43860,
                "word_type": "n.",
                "spelling_usage": "The state of being exigent; urgent or exacting want;\n   pressing necessity or distress; need; a case demanding immediate\n   action, supply, or remedy; as, an unforeseen exigency."
            }
        ]
    },
    {
        "id": 27799,
        "spelling_name": "Exodus",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43916,
                "word_type": "n.",
                "spelling_usage": "A going out; particularly (the Exodus), the going out or\n   journey of the Israelites from Egypt under the conduct of Moses; and\n   hence, any large migration from a place."
            },
            {
                "spelling_detail_id": 43917,
                "word_type": "n.",
                "spelling_usage": "The second of the Old Testament, which contains the\n   narrative of the departure of the Israelites from Egypt."
            }
        ]
    },
    {
        "id": 27815,
        "spelling_name": "Exoneration",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43940,
                "word_type": "n.",
                "spelling_usage": "The act of disburdening, discharging, or freeing\n   morally from a charge or imputation; also, the state of being\n   disburdened or freed from a charge."
            }
        ]
    },
    {
        "id": 27832,
        "spelling_name": "Exorbitant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 43958,
                "word_type": "a.",
                "spelling_usage": "Departing from an orbit or usual track; hence,\n   deviating from the usual or due course; going beyond the appointed\n   rules or established limits of right or propriety; excessive;\n   extravagant; enormous; inordinate; as, exorbitant appetites and\n   passions; exorbitant charges, demands, or claims."
            },
            {
                "spelling_detail_id": 43959,
                "word_type": "a.",
                "spelling_usage": "Not comprehended in a settled rule or method;\n   anomalous."
            }
        ]
    },
    {
        "id": 27885,
        "spelling_name": "Expatiate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44030,
                "word_type": "v. i.",
                "spelling_usage": "To range at large, or without restraint."
            },
            {
                "spelling_detail_id": 44031,
                "word_type": "v. i.",
                "spelling_usage": "To enlarge in discourse or writing; to be copious in\n   argument or discussion; to descant."
            },
            {
                "spelling_detail_id": 44032,
                "word_type": "v. t.",
                "spelling_usage": "To expand; to spread; to extend; to diffuse; to\n   broaden."
            }
        ]
    },
    {
        "id": 27916,
        "spelling_name": "Expedient",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44079,
                "word_type": "a.",
                "spelling_usage": "Hastening or forward; hence, tending to further or\n   promote a proposed object; fit or proper under the circumstances;\n   conducive to self-interest; desirable; advisable; advantageous; --\n   sometimes contradistinguished from right."
            },
            {
                "spelling_detail_id": 44080,
                "word_type": "a.",
                "spelling_usage": "Quick; expeditious."
            },
            {
                "spelling_detail_id": 44081,
                "word_type": "n.",
                "spelling_usage": "That which serves to promote or advance; suitable means\n   to accomplish an end."
            },
            {
                "spelling_detail_id": 44082,
                "word_type": "n.",
                "spelling_usage": "Means devised in an exigency; shift."
            }
        ]
    },
    {
        "id": 27975,
        "spelling_name": "Expiation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44174,
                "word_type": "n.",
                "spelling_usage": "The act of making satisfaction or atonement for any\n   crime or fault; the extinguishing of guilt by suffering or penalty."
            },
            {
                "spelling_detail_id": 44175,
                "word_type": "n.",
                "spelling_usage": "The means by which reparation or atonement for crimes or\n   sins is made; an expiatory sacrifice or offering; an atonement."
            },
            {
                "spelling_detail_id": 44176,
                "word_type": "n.",
                "spelling_usage": "An act by which the treats of prodigies were averted\n   among the ancient heathen."
            }
        ]
    },
    {
        "id": 28026,
        "spelling_name": "Exploit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44260,
                "word_type": "n.",
                "spelling_usage": "A deed or act; especially, a heroic act; a deed of renown;\n   an adventurous or noble achievement; as, the exploits of Alexander the\n   Great."
            },
            {
                "spelling_detail_id": 44261,
                "word_type": "n.",
                "spelling_usage": "Combat; war."
            },
            {
                "spelling_detail_id": 44262,
                "word_type": "n.",
                "spelling_usage": "To utilize; to make available; to get the value or\n   usefulness out of; as, to exploit a mine or agricultural lands; to\n   exploit public opinion."
            },
            {
                "spelling_detail_id": 44263,
                "word_type": "n.",
                "spelling_usage": "Hence: To draw an illegitimate profit from; to speculate\n   on; to put upon."
            }
        ]
    },
    {
        "id": 28065,
        "spelling_name": "Expostulate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44327,
                "word_type": "v. i.",
                "spelling_usage": "To reason earnestly with a person on some\n   impropriety of his conduct, representing the wrong he has done or\n   intends, and urging him to make redress or to desist; to remonstrate;\n   -- followed by with."
            },
            {
                "spelling_detail_id": 44328,
                "word_type": "v. t.",
                "spelling_usage": "To discuss; to examine."
            }
        ]
    },
    {
        "id": 28109,
        "spelling_name": "Expurgate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44396,
                "word_type": "v. t.",
                "spelling_usage": "To purify; to clear from anything noxious, offensive,\n   or erroneous; to cleanse; to purge; as, to expurgate a book."
            }
        ]
    },
    {
        "id": 28131,
        "spelling_name": "Exscind",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44419,
                "word_type": "v. t.",
                "spelling_usage": "To cut off; to separate or expel from union; to\n   extirpate."
            }
        ]
    },
    {
        "id": 28167,
        "spelling_name": "Extant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44457,
                "word_type": "a.",
                "spelling_usage": "Standing out or above any surface; protruded."
            },
            {
                "spelling_detail_id": 44458,
                "word_type": "a.",
                "spelling_usage": "Still existing; not destroyed or lost; outstanding."
            },
            {
                "spelling_detail_id": 44459,
                "word_type": "a.",
                "spelling_usage": "Publicly known; conspicuous."
            }
        ]
    },
    {
        "id": 28175,
        "spelling_name": "Extempore",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44468,
                "word_type": "adv.",
                "spelling_usage": "Without previous study or meditation; without\n   preparation; on the spur of the moment; suddenly; extemporaneously; as,\n   to write or speak extempore."
            },
            {
                "spelling_detail_id": 44469,
                "word_type": "a.",
                "spelling_usage": "Done or performed extempore."
            },
            {
                "spelling_detail_id": 44470,
                "word_type": "n.",
                "spelling_usage": "Speaking or writing done extempore."
            }
        ]
    },
    {
        "id": 28205,
        "spelling_name": "Extenuate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44520,
                "word_type": "v. t.",
                "spelling_usage": "To make thin or slender; to draw out so as to lessen\n   the thickness."
            },
            {
                "spelling_detail_id": 44521,
                "word_type": "v. t.",
                "spelling_usage": "To lessen; to palliate; to lessen or weaken the force\n   of; to diminish the conception of, as crime, guilt, faults, ills,\n   accusations, etc.; -- opposed to aggravate."
            },
            {
                "spelling_detail_id": 44522,
                "word_type": "v. t.",
                "spelling_usage": "To lower or degrade; to detract from."
            },
            {
                "spelling_detail_id": 44523,
                "word_type": "v. i.",
                "spelling_usage": "To become thinner; to make excuses; to advance\n   palliating considerations."
            },
            {
                "spelling_detail_id": 44524,
                "word_type": "a.",
                "spelling_usage": "Thin; slender."
            }
        ]
    },
    {
        "id": 28239,
        "spelling_name": "Extinct",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44574,
                "word_type": "a.",
                "spelling_usage": "Extinguished; put out; quenched; as, a fire, a light, or a\n   lamp, is extinct; an extinct volcano."
            },
            {
                "spelling_detail_id": 44575,
                "word_type": "a.",
                "spelling_usage": "Without a survivor; without force; dead; as, a family\n   becomes extinct; an extinct feud or law."
            },
            {
                "spelling_detail_id": 44576,
                "word_type": "v. t.",
                "spelling_usage": "To cause to be extinct."
            }
        ]
    },
    {
        "id": 28242,
        "spelling_name": "Extinguish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44582,
                "word_type": "v. t.",
                "spelling_usage": "To quench; to put out, as a light or fire; to\n   stifle; to cause to die out; to put an end to; to destroy; as, to\n   extinguish a flame, or life, or love, or hope, a pretense or a right."
            },
            {
                "spelling_detail_id": 44583,
                "word_type": "v. t.",
                "spelling_usage": "To obscure; to eclipse, as by superior splendor."
            }
        ]
    },
    {
        "id": 28250,
        "spelling_name": "Extirpate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44592,
                "word_type": "v. t.",
                "spelling_usage": "To pluck up by the stem or root; to root out; to\n   eradicate, literally or figuratively; to destroy wholly; as, to\n   extirpate weeds; to extirpate a tumor; to extirpate a sect; to\n   extirpate error or heresy."
            }
        ]
    },
    {
        "id": 28260,
        "spelling_name": "Extol",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44602,
                "word_type": "v. t.",
                "spelling_usage": "To place on high; to lift up; to elevate."
            },
            {
                "spelling_detail_id": 44603,
                "word_type": "v. t.",
                "spelling_usage": "To elevate by praise; to eulogize; to praise; to magnify;\n   as, to extol virtue; to extol an act or a person."
            }
        ]
    },
    {
        "id": 28266,
        "spelling_name": "Extort",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44609,
                "word_type": "v. t.",
                "spelling_usage": "To wrest from an unwilling person by physical force,\n   menace, duress, torture, or any undue or illegal exercise of power or\n   ingenuity; to wrench away (from); to tear away; to wring (from); to\n   exact; as, to extort contributions from the vanquished; to extort\n   confessions of guilt; to extort a promise; to extort payment of a debt."
            },
            {
                "spelling_detail_id": 44610,
                "word_type": "v. t.",
                "spelling_usage": "To get by the offense of extortion. See Extortion, 2."
            },
            {
                "spelling_detail_id": 44611,
                "word_type": "v. i.",
                "spelling_usage": "To practice extortion."
            },
            {
                "spelling_detail_id": 44612,
                "word_type": "p. p. & a.",
                "spelling_usage": "Extorted."
            }
        ]
    },
    {
        "id": 28351,
        "spelling_name": "Extricable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44740,
                "word_type": "a.",
                "spelling_usage": "Capable of being extricated."
            }
        ]
    },
    {
        "id": 28378,
        "spelling_name": "Exuberance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 44770,
                "word_type": "n.",
                "spelling_usage": "The state of being exuberant; an overflowing quantity;\n   a copious or excessive production or supply; superabundance; richness;\n   as, an exuberance of joy, of fancy, or of foliage."
            }
        ]
    },
    {
        "id": 28526,
        "spelling_name": "Facetious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 45010,
                "word_type": "a.",
                "spelling_usage": "Given to wit and good humor; merry; sportive; jocular;\n   as, a facetious companion."
            },
            {
                "spelling_detail_id": 45011,
                "word_type": "a.",
                "spelling_usage": "Characterized by wit and pleasantry; exciting laughter;\n   as, a facetious story or reply."
            }
        ]
    },
    {
        "id": 28534,
        "spelling_name": "Facile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 45023,
                "word_type": "a.",
                "spelling_usage": "Easy to be done or performed: not difficult; performable or\n   attainable with little labor."
            },
            {
                "spelling_detail_id": 45024,
                "word_type": "a.",
                "spelling_usage": "Easy to be surmounted or removed; easily conquerable;\n   readily mastered."
            },
            {
                "spelling_detail_id": 45025,
                "word_type": "a.",
                "spelling_usage": "Easy of access or converse; mild; courteous; not haughty,\n   austere, or distant; affable; complaisant."
            },
            {
                "spelling_detail_id": 45026,
                "word_type": "a.",
                "spelling_usage": "Easily persuaded to good or bad; yielding; ductile to a\n   fault; pliant; flexible."
            },
            {
                "spelling_detail_id": 45027,
                "word_type": "a.",
                "spelling_usage": "Ready; quick; expert; as, he is facile in expedients; he\n   wields a facile pen."
            }
        ]
    },
    {
        "id": 28600,
        "spelling_name": "Fagged",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 45139,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Fag"
            }
        ]
    },
    {
        "id": 28681,
        "spelling_name": "Fallacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 45366,
                "word_type": "a.",
                "spelling_usage": "Embodying or pertaining to a fallacy; illogical; fitted\n   to deceive; misleading; delusive; as, fallacious arguments or\n   reasoning."
            }
        ]
    },
    {
        "id": 28723,
        "spelling_name": "Falter",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 45440,
                "word_type": "v. t.",
                "spelling_usage": "To thrash in the chaff; also, to cleanse or sift, as\n   barley."
            },
            {
                "spelling_detail_id": 45443,
                "word_type": "v. & n.",
                "spelling_usage": "To hesitate; to speak brokenly or weakly; to stammer;\n   as, his tongue falters."
            },
            {
                "spelling_detail_id": 45444,
                "word_type": "v. & n.",
                "spelling_usage": "To tremble; to totter; to be unsteady."
            },
            {
                "spelling_detail_id": 45445,
                "word_type": "v. & n.",
                "spelling_usage": "To hesitate in purpose or action."
            },
            {
                "spelling_detail_id": 45446,
                "word_type": "v. & n.",
                "spelling_usage": "To fail in distinctness or regularity of exercise; --\n   said of the mind or of thought."
            },
            {
                "spelling_detail_id": 45447,
                "word_type": "v. t.",
                "spelling_usage": "To utter with hesitation, or in a broken, trembling, or\n   weak manner."
            },
            {
                "spelling_detail_id": 45448,
                "word_type": "v. i.",
                "spelling_usage": "Hesitation; trembling; feebleness; an uncertain or\n   broken sound; as, a slight falter in her voice."
            }
        ]
    },
    {
        "id": 29036,
        "spelling_name": "Fatuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46002,
                "word_type": "a.",
                "spelling_usage": "Feeble in mind; weak; silly; stupid; foolish; fatuitous."
            },
            {
                "spelling_detail_id": 46003,
                "word_type": "a.",
                "spelling_usage": "Without reality; illusory, like the ignis fatuus."
            }
        ]
    },
    {
        "id": 29097,
        "spelling_name": "Fawn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46106,
                "word_type": "n.",
                "spelling_usage": "A young deer; a buck or doe of the first year. See Buck."
            },
            {
                "spelling_detail_id": 46107,
                "word_type": "n.",
                "spelling_usage": "The young of an animal; a whelp."
            },
            {
                "spelling_detail_id": 46108,
                "word_type": "n.",
                "spelling_usage": "A fawn color."
            },
            {
                "spelling_detail_id": 46109,
                "word_type": "a.",
                "spelling_usage": "Of the color of a fawn; fawn-colored."
            },
            {
                "spelling_detail_id": 46110,
                "word_type": "v. i.",
                "spelling_usage": "To bring forth a fawn."
            },
            {
                "spelling_detail_id": 46113,
                "word_type": "v. i.",
                "spelling_usage": "To court favor by low cringing, frisking, etc., as a dog;\n   to flatter meanly; -- often followed by on or upon."
            },
            {
                "spelling_detail_id": 46114,
                "word_type": "n.",
                "spelling_usage": "A servile cringe or bow; mean flattery; sycophancy."
            }
        ]
    },
    {
        "id": 29181,
        "spelling_name": "Feckless",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46256,
                "word_type": "a.",
                "spelling_usage": "Spiritless; weak; worthless."
            }
        ]
    },
    {
        "id": 29188,
        "spelling_name": "Fecund",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46266,
                "word_type": "a.",
                "spelling_usage": "Fruitful in children; prolific."
            }
        ]
    },
    {
        "id": 29236,
        "spelling_name": "Feint",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46389,
                "word_type": "a.",
                "spelling_usage": "Feigned; counterfeit."
            },
            {
                "spelling_detail_id": 46390,
                "word_type": "a.",
                "spelling_usage": "That which is feigned; an assumed or false appearance; a\n   pretense; a stratagem; a fetch."
            },
            {
                "spelling_detail_id": 46391,
                "word_type": "a.",
                "spelling_usage": "A mock blow or attack on one part when another part is\n   intended to be struck; -- said of certain movements in fencing, boxing,\n   war, etc."
            },
            {
                "spelling_detail_id": 46392,
                "word_type": "v. i.",
                "spelling_usage": "To make a feint, or mock attack."
            }
        ]
    },
    {
        "id": 29250,
        "spelling_name": "Felicitous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46408,
                "word_type": "a.",
                "spelling_usage": "Characterized by felicity; happy; prosperous;\n   delightful; skilful; successful; happily applied or expressed;\n   appropriate."
            }
        ]
    },
    {
        "id": 29283,
        "spelling_name": "Felon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46474,
                "word_type": "a.",
                "spelling_usage": "A person who has committed a felony."
            },
            {
                "spelling_detail_id": 46475,
                "word_type": "a.",
                "spelling_usage": "A person guilty or capable of heinous crime."
            },
            {
                "spelling_detail_id": 46476,
                "word_type": "a.",
                "spelling_usage": "A kind of whitlow; a painful imflammation of the periosteum\n   of a finger, usually of the last joint."
            },
            {
                "spelling_detail_id": 46477,
                "word_type": "a.",
                "spelling_usage": "Characteristic of a felon; malignant; fierce; malicious;\n   cruel; traitorous; disloyal."
            }
        ]
    },
    {
        "id": 29383,
        "spelling_name": "Feral",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46624,
                "word_type": "a.",
                "spelling_usage": "Wild; untamed; ferine; not domesticated; -- said of beasts,\n   birds, and plants."
            },
            {
                "spelling_detail_id": 46625,
                "word_type": "a.",
                "spelling_usage": "Funereal; deadly; fatal; dangerous."
            }
        ]
    },
    {
        "id": 29405,
        "spelling_name": "Ferment",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46656,
                "word_type": "n.",
                "spelling_usage": "That which causes fermentation, as yeast, barm, or\n   fermenting beer."
            },
            {
                "spelling_detail_id": 46657,
                "word_type": "n.",
                "spelling_usage": "Intestine motion; heat; tumult; agitation."
            },
            {
                "spelling_detail_id": 46658,
                "word_type": "n.",
                "spelling_usage": "A gentle internal motion of the constituent parts of a\n   fluid; fermentation."
            },
            {
                "spelling_detail_id": 46661,
                "word_type": "n.",
                "spelling_usage": "To cause ferment of fermentation in; to set in motion; to\n   excite internal emotion in; to heat."
            },
            {
                "spelling_detail_id": 46662,
                "word_type": "v. i.",
                "spelling_usage": "To undergo fermentation; to be in motion, or to be\n   excited into sensible internal motion, as the constituent oarticles of\n   an animal or vegetable fluid; to work; to effervesce."
            },
            {
                "spelling_detail_id": 46663,
                "word_type": "v. i.",
                "spelling_usage": "To be agitated or excited by violent emotions."
            }
        ]
    },
    {
        "id": 29420,
        "spelling_name": "Ferocity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46679,
                "word_type": "n.",
                "spelling_usage": "Savage wildness or fierceness; fury; cruelty; as,\n   ferocity of countenance."
            }
        ]
    },
    {
        "id": 29432,
        "spelling_name": "Ferret",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46693,
                "word_type": "n.",
                "spelling_usage": "An animal of the Weasel family (Mustela / Putorius furo),\n   about fourteen inches in length, of a pale yellow or white color, with\n   red eyes. It is a native of Africa, but has been domesticated in\n   Europe. Ferrets are used to drive rabbits and rats out of their holes."
            },
            {
                "spelling_detail_id": 46696,
                "word_type": "n.",
                "spelling_usage": "To drive or hunt out of a lurking place, as a ferret does\n   the cony; to search out by patient and sagacious efforts; -- often used\n   with out; as, to ferret out a secret."
            },
            {
                "spelling_detail_id": 46697,
                "word_type": "n.",
                "spelling_usage": "A kind of narrow tape, usually made of woolen; sometimes of\n   cotton or silk; -- called also ferreting."
            },
            {
                "spelling_detail_id": 46698,
                "word_type": "n.",
                "spelling_usage": "The iron used for trying the melted glass to see if is fit\n   to work, and for shaping the rings at the mouths of bottles."
            }
        ]
    },
    {
        "id": 29495,
        "spelling_name": "Fervid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46774,
                "word_type": "a.",
                "spelling_usage": "Very hot; burning; boiling."
            },
            {
                "spelling_detail_id": 46775,
                "word_type": "a.",
                "spelling_usage": "Ardent; vehement; zealous."
            }
        ]
    },
    {
        "id": 29496,
        "spelling_name": "Fervor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46776,
                "word_type": "n.",
                "spelling_usage": "Heat; excessive warmth."
            },
            {
                "spelling_detail_id": 46777,
                "word_type": "n.",
                "spelling_usage": "Intensity of feeling or expression; glowing ardor; passion;\n   holy zeal; earnestness."
            }
        ]
    },
    {
        "id": 29525,
        "spelling_name": "Festoon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46817,
                "word_type": "n.",
                "spelling_usage": "A garland or wreath hanging in a depending curve, used in\n   decoration for festivals, etc.; anything arranged in this way."
            },
            {
                "spelling_detail_id": 46818,
                "word_type": "n.",
                "spelling_usage": "A carved ornament consisting of flowers, and leaves,\n   intermixed or twisted together, wound with a ribbon, and hanging or\n   depending in a natural curve. See Illust. of Bucranium."
            },
            {
                "spelling_detail_id": 46821,
                "word_type": "v. t.",
                "spelling_usage": "To form in festoons, or to adorn with festoons."
            }
        ]
    },
    {
        "id": 29547,
        "spelling_name": "Fetid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46862,
                "word_type": "a.",
                "spelling_usage": "Having an offensive smell; stinking."
            }
        ]
    },
    {
        "id": 29561,
        "spelling_name": "Fetter",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46877,
                "word_type": "n.",
                "spelling_usage": "A chain or shackle for the feet; a chain by which an animal\n   is confined by the foot, either made fast or disabled from free and\n   rapid motion; a bond; a shackle."
            },
            {
                "spelling_detail_id": 46878,
                "word_type": "n.",
                "spelling_usage": "Anything that confines or restrains; a restraint."
            },
            {
                "spelling_detail_id": 46879,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "To put fetters upon; to shackle or confine the\n   feet of with a chain; to bind."
            },
            {
                "spelling_detail_id": 46880,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "To restrain from motion; to impose restraints\n   on; to confine; to enchain; as, fettered by obligations."
            }
        ]
    },
    {
        "id": 29575,
        "spelling_name": "Feud",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 46896,
                "word_type": "n.",
                "spelling_usage": "A combination of kindred to avenge injuries or affronts, done\n   or offered to any of their blood, on the offender and all his race."
            },
            {
                "spelling_detail_id": 46897,
                "word_type": "n.",
                "spelling_usage": "A contention or quarrel; especially, an inveterate strife\n   between families, clans, or parties; deadly hatred; contention\n   satisfied only by bloodshed."
            },
            {
                "spelling_detail_id": 46898,
                "word_type": "n.",
                "spelling_usage": "A stipendiary estate in land, held of superior, by service;\n   the right which a vassal or tenant had to the lands or other immovable\n   thing of his lord, to use the same and take the profists thereof\n   hereditarily, rendering to his superior such duties and services as\n   belong to military tenure, etc., the property of the soil always\n   remaining in the lord or superior; a fief; a fee."
            }
        ]
    },
    {
        "id": 29700,
        "spelling_name": "Fidelity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47067,
                "word_type": "n.",
                "spelling_usage": "Faithfulness; adherence to right; careful and exact\n   observance of duty, or discharge of obligations."
            },
            {
                "spelling_detail_id": 47068,
                "word_type": "n.",
                "spelling_usage": "Adherence to a person or party to which one is bound;\n   loyalty."
            },
            {
                "spelling_detail_id": 47069,
                "word_type": "n.",
                "spelling_usage": "Adherence to the marriage contract."
            },
            {
                "spelling_detail_id": 47070,
                "word_type": "n.",
                "spelling_usage": "Adherence to truth; veracity; honesty."
            }
        ]
    },
    {
        "id": 29703,
        "spelling_name": "Fidget",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47075,
                "word_type": "v. i.",
                "spelling_usage": "To move uneasily one way and the other; to move\n   irregularly, or by fits and starts."
            },
            {
                "spelling_detail_id": 47076,
                "word_type": "n.",
                "spelling_usage": "Uneasiness; restlessness."
            },
            {
                "spelling_detail_id": 47077,
                "word_type": "n.",
                "spelling_usage": "A general nervous restlessness, manifested by incessant\n   changes of position; dysphoria."
            }
        ]
    },
    {
        "id": 29773,
        "spelling_name": "Figurehead",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47238,
                "word_type": "n.",
                "spelling_usage": "The figure, statue, or bust, on the prow of a ship."
            },
            {
                "spelling_detail_id": 47239,
                "word_type": "n.",
                "spelling_usage": "A person who allows his name to be used to give\n   standing to enterprises in which he has no responsible interest or\n   duties; a nominal, but not real, head or chief."
            }
        ]
    },
    {
        "id": 29912,
        "spelling_name": "Finesse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47511,
                "word_type": "a.",
                "spelling_usage": "Subtilty of contrivance to gain a point; artifice;\n   stratagem."
            },
            {
                "spelling_detail_id": 47512,
                "word_type": "a.",
                "spelling_usage": "The act of finessing. See Finesse, v. i., 2."
            },
            {
                "spelling_detail_id": 47515,
                "word_type": "v. i.",
                "spelling_usage": "To use artifice or stratagem."
            },
            {
                "spelling_detail_id": 47516,
                "word_type": "v. i.",
                "spelling_usage": "To attempt, when second or third player, to make a\n   lower card answer the purpose of a higher, when an intermediate card is\n   out, risking the chance of its being held by the opponent yet to play."
            }
        ]
    },
    {
        "id": 29929,
        "spelling_name": "Finical",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47551,
                "word_type": "a.",
                "spelling_usage": "Affectedly fine; overnice; unduly particular; fastidious."
            }
        ]
    },
    {
        "id": 29932,
        "spelling_name": "Finicky",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47554,
                "word_type": "a.",
                "spelling_usage": "Finical; unduly particular."
            }
        ]
    },
    {
        "id": 30074,
        "spelling_name": "Fission",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 47793,
                "word_type": "n.",
                "spelling_usage": "A cleaving, splitting, or breaking up into parts."
            },
            {
                "spelling_detail_id": 47794,
                "word_type": "n.",
                "spelling_usage": "A method of asexual reproduction among the lowest\n   (unicellular) organisms by means of a process of self-division,\n   consisting of gradual division or cleavage of the into two parts, each\n   of which then becomes a separate and independent organisms; as when a\n   cell in an animal or plant, or its germ, undergoes a spontaneous\n   division, and the parts again subdivide. See Segmentation, and Cell\n   division, under Division."
            },
            {
                "spelling_detail_id": 47795,
                "word_type": "n.",
                "spelling_usage": "A process by which certain coral polyps, echinoderms,\n   annelids, etc., spontaneously subdivide, each individual thus forming\n   two or more new ones. See Strobilation."
            }
        ]
    },
    {
        "id": 30220,
        "spelling_name": "Flamboyant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48052,
                "word_type": "a.",
                "spelling_usage": "Characterized by waving or flamelike curves, as in the\n   tracery of windows, etc.; -- said of the later (15th century) French\n   Gothic style."
            }
        ]
    },
    {
        "id": 30332,
        "spelling_name": "Flaunting",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48291,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Flaunt"
            }
        ]
    },
    {
        "id": 30355,
        "spelling_name": "Flax",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48328,
                "word_type": "n.",
                "spelling_usage": "A plant of the genus Linum, esp. the L. usitatissimum, which\n   has a single, slender stalk, about a foot and a half high, with blue\n   flowers. The fiber of the bark is used for making thread and cloth,\n   called linen, cambric, lawn, lace, etc. Linseed oil is expressed from\n   the seed."
            },
            {
                "spelling_detail_id": 48329,
                "word_type": "n.",
                "spelling_usage": "The skin or fibrous part of the flax plant, when broken and\n   cleaned by hatcheling or combing."
            }
        ]
    },
    {
        "id": 30389,
        "spelling_name": "Fledged",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48369,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Fledge"
            }
        ]
    },
    {
        "id": 30406,
        "spelling_name": "Fleet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48403,
                "word_type": "n. & a.",
                "spelling_usage": "To sail; to float."
            },
            {
                "spelling_detail_id": 48404,
                "word_type": "n. & a.",
                "spelling_usage": "To fly swiftly; to pass over quickly; to hasten; to\n   flit as a light substance."
            },
            {
                "spelling_detail_id": 48405,
                "word_type": "n. & a.",
                "spelling_usage": "To slip on the whelps or the barrel of a capstan or\n   windlass; -- said of a cable or hawser."
            },
            {
                "spelling_detail_id": 48406,
                "word_type": "v. t.",
                "spelling_usage": "To pass over rapidly; to skin the surface of; as, a ship\n   that fleets the gulf."
            },
            {
                "spelling_detail_id": 48407,
                "word_type": "v. t.",
                "spelling_usage": "To hasten over; to cause to pass away lighty, or in mirth\n   and joy."
            },
            {
                "spelling_detail_id": 48408,
                "word_type": "v. t.",
                "spelling_usage": "To draw apart the blocks of; -- said of a tackle."
            },
            {
                "spelling_detail_id": 48409,
                "word_type": "v. t.",
                "spelling_usage": "To cause to slip down the barrel of a capstan or\n   windlass, as a rope or chain."
            },
            {
                "spelling_detail_id": 48410,
                "word_type": "v. i.",
                "spelling_usage": "Swift in motion; moving with velocity; light and quick in\n   going from place to place; nimble."
            },
            {
                "spelling_detail_id": 48411,
                "word_type": "v. i.",
                "spelling_usage": "Light; superficially thin; not penetrating deep, as soil."
            },
            {
                "spelling_detail_id": 48412,
                "word_type": "v. i.",
                "spelling_usage": "A number of vessels in company, especially war vessels;\n   also, the collective naval force of a country, etc."
            },
            {
                "spelling_detail_id": 48413,
                "word_type": "v. i.",
                "spelling_usage": "A flood; a creek or inlet; a bay or estuary; a river; --\n   obsolete, except as a place name, -- as Fleet Street in London."
            },
            {
                "spelling_detail_id": 48414,
                "word_type": "v. i.",
                "spelling_usage": "A former prison in London, which originally stood near a\n   stream, the Fleet (now filled up)."
            },
            {
                "spelling_detail_id": 48415,
                "word_type": "v. i.",
                "spelling_usage": "To take the cream from; to skim."
            }
        ]
    },
    {
        "id": 30488,
        "spelling_name": "Flimsy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48547,
                "word_type": "superl.",
                "spelling_usage": "Weak; feeble; limp; slight; vain; without strength or\n   solidity; of loose and unsubstantial structure; without reason or\n   plausibility; as, a flimsy argument, excuse, objection."
            },
            {
                "spelling_detail_id": 48548,
                "word_type": "n.",
                "spelling_usage": "Thin or transfer paper."
            },
            {
                "spelling_detail_id": 48549,
                "word_type": "n.",
                "spelling_usage": "A bank note."
            }
        ]
    },
    {
        "id": 30489,
        "spelling_name": "Flinch",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48552,
                "word_type": "v. i.",
                "spelling_usage": "To withdraw from any suffering or undertaking, from pain\n   or danger; to fail in doing or perserving; to show signs of yielding or\n   of suffering; to shrink; to wince; as, one of the parties flinched from\n   the combat."
            },
            {
                "spelling_detail_id": 48553,
                "word_type": "v. i.",
                "spelling_usage": "To let the foot slip from a ball, when attempting to\n   give a tight croquet."
            },
            {
                "spelling_detail_id": 48554,
                "word_type": "n.",
                "spelling_usage": "The act of flinching."
            }
        ]
    },
    {
        "id": 30571,
        "spelling_name": "Floe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48709,
                "word_type": "n.",
                "spelling_usage": "A low, flat mass of floating ice."
            }
        ]
    },
    {
        "id": 30594,
        "spelling_name": "Flop",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48757,
                "word_type": "v. t.",
                "spelling_usage": "To clap or strike, as a bird its wings, a fish its tail,\n   etc.; to flap."
            },
            {
                "spelling_detail_id": 48758,
                "word_type": "v. t.",
                "spelling_usage": "To turn suddenly, as something broad and flat."
            },
            {
                "spelling_detail_id": 48759,
                "word_type": "v. i.",
                "spelling_usage": "To strike about with something broad abd flat, as a fish\n   with its tail, or a bird with its wings; to rise and fall; as, the brim\n   of a hat flops."
            },
            {
                "spelling_detail_id": 48760,
                "word_type": "v. i.",
                "spelling_usage": "To fall, sink, or throw one's self, heavily, clumsily, and\n   unexpectedly on the ground."
            },
            {
                "spelling_detail_id": 48761,
                "word_type": "n.",
                "spelling_usage": "Act of flopping."
            }
        ]
    },
    {
        "id": 30617,
        "spelling_name": "Florid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48789,
                "word_type": "a.",
                "spelling_usage": "Covered with flowers; abounding in flowers; flowery."
            },
            {
                "spelling_detail_id": 48790,
                "word_type": "a.",
                "spelling_usage": "Bright in color; flushed with red; of a lively reddish\n   color; as, a florid countenance."
            },
            {
                "spelling_detail_id": 48791,
                "word_type": "a.",
                "spelling_usage": "Embellished with flowers of rhetoric; enriched to excess\n   with figures; excessively ornate; as, a florid style; florid eloquence."
            },
            {
                "spelling_detail_id": 48792,
                "word_type": "a.",
                "spelling_usage": "Flowery; ornamental; running in rapid melodic figures,\n   divisions, or passages, as in variations; full of fioriture or little\n   ornamentations."
            }
        ]
    },
    {
        "id": 30668,
        "spelling_name": "Flout",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48878,
                "word_type": "v. t.",
                "spelling_usage": "To mock or insult; to treat with contempt."
            },
            {
                "spelling_detail_id": 48879,
                "word_type": "v. i.",
                "spelling_usage": "To practice mocking; to behave with contempt; to sneer;\n   to fleer; -- often with at."
            },
            {
                "spelling_detail_id": 48880,
                "word_type": "n.",
                "spelling_usage": "A mock; an insult."
            }
        ]
    },
    {
        "id": 30733,
        "spelling_name": "Fluke",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 48991,
                "word_type": "n.",
                "spelling_usage": "The European flounder. See Flounder."
            },
            {
                "spelling_detail_id": 48992,
                "word_type": "n.",
                "spelling_usage": "A parasitic trematode worm of several species, having a\n   flat, lanceolate body and two suckers. Two species (Fasciola hepatica\n   and Distoma lanceolatum) are found in the livers of sheep, and produce\n   the disease called rot."
            },
            {
                "spelling_detail_id": 48993,
                "word_type": "n.",
                "spelling_usage": "The part of an anchor which fastens in the ground; a flook.\n   See Anchor."
            },
            {
                "spelling_detail_id": 48994,
                "word_type": "n.",
                "spelling_usage": "One of the lobes of a whale's tail, so called from the\n   resemblance to the fluke of an anchor."
            },
            {
                "spelling_detail_id": 48995,
                "word_type": "n.",
                "spelling_usage": "An instrument for cleaning out a hole drilled in stone for\n   blasting."
            },
            {
                "spelling_detail_id": 48996,
                "word_type": "n.",
                "spelling_usage": "An accidental and favorable stroke at billiards (called a\n   scratch in the United States); hence, any accidental or unexpected\n   advantage; as, he won by a fluke."
            }
        ]
    },
    {
        "id": 30786,
        "spelling_name": "Fluster",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49086,
                "word_type": "v. t.",
                "spelling_usage": "To make hot and rosy, as with drinking; to heat; hence,\n   to throw into agitation and confusion; to confuse; to muddle."
            },
            {
                "spelling_detail_id": 49087,
                "word_type": "v. i.",
                "spelling_usage": "To be in a heat or bustle; to be agitated and confused."
            },
            {
                "spelling_detail_id": 49088,
                "word_type": "n.",
                "spelling_usage": "Heat or glow, as from drinking; agitation mingled with\n   confusion; disorder."
            }
        ]
    },
    {
        "id": 30906,
        "spelling_name": "Foible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49306,
                "word_type": "a.",
                "spelling_usage": "Weak; feeble."
            },
            {
                "spelling_detail_id": 49307,
                "word_type": "n.",
                "spelling_usage": "A moral weakness; a failing; a weak point; a frailty."
            },
            {
                "spelling_detail_id": 49308,
                "word_type": "n.",
                "spelling_usage": "The half of a sword blade or foil blade nearest the point;\n   -- opposed to forte."
            }
        ]
    },
    {
        "id": 30907,
        "spelling_name": "Foil",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49311,
                "word_type": "v. t.",
                "spelling_usage": "To tread under foot; to trample."
            },
            {
                "spelling_detail_id": 49312,
                "word_type": "v. t.",
                "spelling_usage": "To render (an effort or attempt) vain or nugatory; to\n   baffle; to outwit; to balk; to frustrate; to defeat."
            },
            {
                "spelling_detail_id": 49313,
                "word_type": "v. t.",
                "spelling_usage": "To blunt; to dull; to spoil; as, to foil the scent in\n   chase."
            },
            {
                "spelling_detail_id": 49314,
                "word_type": "v. t.",
                "spelling_usage": "To defile; to soil."
            },
            {
                "spelling_detail_id": 49315,
                "word_type": "n.",
                "spelling_usage": "Failure of success when on the point of attainment; defeat;\n   frustration; miscarriage."
            },
            {
                "spelling_detail_id": 49316,
                "word_type": "n.",
                "spelling_usage": "A blunt weapon used in fencing, resembling a smallsword in\n   the main, but usually lighter and having a button at the point."
            },
            {
                "spelling_detail_id": 49317,
                "word_type": "n.",
                "spelling_usage": "The track or trail of an animal."
            },
            {
                "spelling_detail_id": 49318,
                "word_type": "n.",
                "spelling_usage": "A leaf or very thin sheet of metal; as, brass foil; tin foil;\n   gold foil."
            },
            {
                "spelling_detail_id": 49319,
                "word_type": "n.",
                "spelling_usage": "A thin leaf of sheet copper silvered and burnished, and\n   afterwards coated with transparent colors mixed with isinglass; --\n   employed by jewelers to give color or brilliancy to pastes and inferior\n   stones."
            },
            {
                "spelling_detail_id": 49320,
                "word_type": "n.",
                "spelling_usage": "Anything that serves by contrast of color or quality to adorn\n   or set off another thing to advantage."
            },
            {
                "spelling_detail_id": 49321,
                "word_type": "n.",
                "spelling_usage": "A thin coat of tin, with quicksilver, laid on the back of a\n   looking-glass, to cause reflection."
            },
            {
                "spelling_detail_id": 49322,
                "word_type": "n.",
                "spelling_usage": "The space between the cusps in Gothic architecture; a rounded\n   or leaflike ornament, in windows, niches, etc. A group of foils is\n   called trefoil, quatrefoil, quinquefoil, etc., according to the number\n   of arcs of which it is composed."
            }
        ]
    },
    {
        "id": 30973,
        "spelling_name": "Foment",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49464,
                "word_type": "v. t.",
                "spelling_usage": "To apply a warm lotion to; to bathe with a cloth or\n   sponge wet with warm water or medicated liquid."
            },
            {
                "spelling_detail_id": 49465,
                "word_type": "v. t.",
                "spelling_usage": "To cherish with heat; to foster."
            },
            {
                "spelling_detail_id": 49466,
                "word_type": "v. t.",
                "spelling_usage": "To nurse to life or activity; to cherish and promote by\n   excitements; to encourage; to abet; to instigate; -- used often in a\n   bad sense; as, to foment ill humors."
            }
        ]
    },
    {
        "id": 31078,
        "spelling_name": "Foppish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49648,
                "word_type": "a.",
                "spelling_usage": "Foplike; characteristic of a top in dress or manners;\n   making an ostentatious display of gay clothing; affected in manners."
            }
        ]
    },
    {
        "id": 31082,
        "spelling_name": "Forage",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49663,
                "word_type": "n.",
                "spelling_usage": "The act of foraging; search for provisions, etc."
            },
            {
                "spelling_detail_id": 49664,
                "word_type": "n.",
                "spelling_usage": "Food of any kind for animals, especially for horses and\n   cattle, as grass, pasture, hay, corn, oats."
            },
            {
                "spelling_detail_id": 49667,
                "word_type": "v. i.",
                "spelling_usage": "To wander or rove in search of food; to collect food,\n   esp. forage, for horses and cattle by feeding on or stripping the\n   country; to ravage; to feed on spoil."
            },
            {
                "spelling_detail_id": 49668,
                "word_type": "v. t.",
                "spelling_usage": "To strip of provisions; to supply with forage; as, to\n   forage steeds."
            }
        ]
    },
    {
        "id": 31096,
        "spelling_name": "Foray",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49681,
                "word_type": "n.",
                "spelling_usage": "A sudden or irregular incursion in border warfare; hence,\n   any irregular incursion for war or spoils; a raid."
            },
            {
                "spelling_detail_id": 49682,
                "word_type": "v. t.",
                "spelling_usage": "To pillage; to ravage."
            }
        ]
    },
    {
        "id": 31101,
        "spelling_name": "Forbear",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49686,
                "word_type": "n.",
                "spelling_usage": "An ancestor; a forefather; -- usually in the plural."
            },
            {
                "spelling_detail_id": 49691,
                "word_type": "v. i.",
                "spelling_usage": "To refrain from proceeding; to pause; to delay."
            },
            {
                "spelling_detail_id": 49692,
                "word_type": "v. i.",
                "spelling_usage": "To refuse; to decline; to give no heed."
            },
            {
                "spelling_detail_id": 49693,
                "word_type": "v. i.",
                "spelling_usage": "To control one's self when provoked."
            },
            {
                "spelling_detail_id": 49694,
                "word_type": "v. t.",
                "spelling_usage": "To keep away from; to avoid; to abstain from; to give\n   up; as, to forbear the use of a word of doubdtful propriety."
            },
            {
                "spelling_detail_id": 49695,
                "word_type": "v. t.",
                "spelling_usage": "To treat with consideration or indulgence."
            },
            {
                "spelling_detail_id": 49696,
                "word_type": "v. t.",
                "spelling_usage": "To cease from bearing."
            }
        ]
    },
    {
        "id": 31102,
        "spelling_name": "Forbearance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49697,
                "word_type": "n.",
                "spelling_usage": "The act of forbearing or waiting; the exercise of\n   patience."
            },
            {
                "spelling_detail_id": 49698,
                "word_type": "n.",
                "spelling_usage": "The quality of being forbearing; indulgence toward\n   offenders or enemies; long-suffering."
            }
        ]
    },
    {
        "id": 31138,
        "spelling_name": "Ford",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 49772,
                "word_type": "v. i.",
                "spelling_usage": "A place in a river, or other water, where it may be passed\n   by man or beast on foot, by wading."
            },
            {
                "spelling_detail_id": 49773,
                "word_type": "v. i.",
                "spelling_usage": "A stream; a current."
            },
            {
                "spelling_detail_id": 49776,
                "word_type": "v. t.",
                "spelling_usage": "To pass or cross, as a river or other water, by wading; to\n   wade through."
            }
        ]
    },
    {
        "id": 31318,
        "spelling_name": "Forestall",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50019,
                "word_type": "v. t.",
                "spelling_usage": "To take beforehand, or in advance; to anticipate."
            },
            {
                "spelling_detail_id": 50020,
                "word_type": "v. t.",
                "spelling_usage": "To take possession of, in advance of some one or\n   something else, to the exclusion or detriment of the latter; to get\n   ahead of; to preoccupy; also, to exclude, hinder, or prevent, by prior\n   occupation, or by measures taken in advance."
            },
            {
                "spelling_detail_id": 50021,
                "word_type": "v. t.",
                "spelling_usage": "To deprive; -- with of."
            },
            {
                "spelling_detail_id": 50022,
                "word_type": "v. t.",
                "spelling_usage": "To obstruct or stop up, as a way; to stop the passage\n   of on highway; to intercept on the road, as goods on the way to market."
            }
        ]
    },
    {
        "id": 31366,
        "spelling_name": "Forfeit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50088,
                "word_type": "n.",
                "spelling_usage": "Injury; wrong; mischief."
            },
            {
                "spelling_detail_id": 50089,
                "word_type": "n.",
                "spelling_usage": "A thing forfeit or forfeited; what is or may be taken from\n   one in requital of a misdeed committed; that which is lost, or the\n   right to which is alienated, by a crime, offense, neglect of duty, or\n   breach of contract; hence, a fine; a mulct; a penalty; as, he who\n   murders pays the forfeit of his life."
            },
            {
                "spelling_detail_id": 50090,
                "word_type": "n.",
                "spelling_usage": "Something deposited and redeemable by a sportive fine; --\n   whence the game of forfeits."
            },
            {
                "spelling_detail_id": 50091,
                "word_type": "n.",
                "spelling_usage": "Lost or alienated for an offense or crime; liable to penal\n   seizure."
            },
            {
                "spelling_detail_id": 50094,
                "word_type": "n.",
                "spelling_usage": "To lose, or lose the right to, by some error, fault,\n   offense, or crime; to render one's self by misdeed liable to be\n   deprived of; to alienate the right to possess, by some neglect or\n   crime; as, to forfeit an estate by treason; to forfeit reputation by a\n   breach of promise; -- with to before the one acquiring what is\n   forfeited."
            },
            {
                "spelling_detail_id": 50095,
                "word_type": "v. i.",
                "spelling_usage": "To be guilty of a misdeed; to be criminal; to\n   transgress."
            },
            {
                "spelling_detail_id": 50096,
                "word_type": "v. i.",
                "spelling_usage": "To fail to keep an obligation."
            },
            {
                "spelling_detail_id": 50097,
                "word_type": "p. p. / a.",
                "spelling_usage": "In the condition of being forfeited; subject to\n   alienation."
            }
        ]
    },
    {
        "id": 31380,
        "spelling_name": "Forge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50110,
                "word_type": "n.",
                "spelling_usage": "A place or establishment where iron or other metals are\n   wrought by heating and hammering; especially, a furnace, or a shop with\n   its furnace, etc., where iron is heated and wrought; a smithy."
            },
            {
                "spelling_detail_id": 50111,
                "word_type": "n.",
                "spelling_usage": "The works where wrought iron is produced directly from the\n   ore, or where iron is rendered malleable by puddling and shingling; a\n   shingling mill."
            },
            {
                "spelling_detail_id": 50112,
                "word_type": "n.",
                "spelling_usage": "The act of beating or working iron or steel; the manufacture\n   of metalic bodies."
            },
            {
                "spelling_detail_id": 50115,
                "word_type": "n.",
                "spelling_usage": "To form by heating and hammering; to beat into any\n   particular shape, as a metal."
            },
            {
                "spelling_detail_id": 50116,
                "word_type": "n.",
                "spelling_usage": "To form or shape out in any way; to produce; to frame; to\n   invent."
            },
            {
                "spelling_detail_id": 50117,
                "word_type": "n.",
                "spelling_usage": "To coin."
            },
            {
                "spelling_detail_id": 50118,
                "word_type": "n.",
                "spelling_usage": "To make falsely; to produce, as that which is untrue or not\n   genuine; to fabricate; to counterfeit, as, a signature, or a signed\n   document."
            },
            {
                "spelling_detail_id": 50119,
                "word_type": "v. t.",
                "spelling_usage": "To commit forgery."
            },
            {
                "spelling_detail_id": 50120,
                "word_type": "v. t.",
                "spelling_usage": "To move heavily and slowly, as a ship after the sails are\n   furled; to work one's way, as one ship in outsailing another; -- used\n   especially in the phrase to forge ahead."
            },
            {
                "spelling_detail_id": 50121,
                "word_type": "v. t.",
                "spelling_usage": "To impel forward slowly; as, to forge a ship forward."
            }
        ]
    },
    {
        "id": 31386,
        "spelling_name": "Forgery",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50127,
                "word_type": "n.",
                "spelling_usage": "The act of forging metal into shape."
            },
            {
                "spelling_detail_id": 50128,
                "word_type": "n.",
                "spelling_usage": "The act of forging, fabricating, or producing falsely;\n   esp., the crime of fraudulently making or altering a writing or\n   signature purporting to be made by another; the false making or\n   material alteration of or addition to a written instrument for the\n   purpose of deceit and fraud; as, the forgery of a bond."
            },
            {
                "spelling_detail_id": 50129,
                "word_type": "n.",
                "spelling_usage": "That which is forged, fabricated, falsely devised, or\n   counterfeited."
            }
        ]
    },
    {
        "id": 31524,
        "spelling_name": "Forswear",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50382,
                "word_type": "v. i.",
                "spelling_usage": "To reject or renounce upon oath; hence, to renounce\n   earnestly, determinedly, or with protestations."
            },
            {
                "spelling_detail_id": 50383,
                "word_type": "v. i.",
                "spelling_usage": "To deny upon oath."
            },
            {
                "spelling_detail_id": 50384,
                "word_type": "v. i.",
                "spelling_usage": "To swear falsely; to commit perjury."
            }
        ]
    },
    {
        "id": 31622,
        "spelling_name": "Foster",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50542,
                "word_type": "v. t.",
                "spelling_usage": "To feed; to nourish; to support; to bring up."
            },
            {
                "spelling_detail_id": 50543,
                "word_type": "v. t.",
                "spelling_usage": "To cherish; to promote the growth of; to encourage; to\n   sustain and promote; as, to foster genius."
            },
            {
                "spelling_detail_id": 50544,
                "word_type": "v. i.",
                "spelling_usage": "To be nourished or trained up together."
            },
            {
                "spelling_detail_id": 50545,
                "word_type": "v. t.",
                "spelling_usage": "Relating to nourishment; affording, receiving, or\n   sharing nourishment or nurture; -- applied to father, mother, child,\n   brother, etc., to indicate that the person so called stands in the\n   relation of parent, child, brother, etc., as regards sustenance and\n   nurture, but not by tie of blood."
            },
            {
                "spelling_detail_id": 50546,
                "word_type": "n.",
                "spelling_usage": "A forester."
            },
            {
                "spelling_detail_id": 50548,
                "word_type": "n.",
                "spelling_usage": "One who, or that which, fosters."
            }
        ]
    },
    {
        "id": 31746,
        "spelling_name": "Fracas",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50726,
                "word_type": "v. t.",
                "spelling_usage": "An uproar; a noisy quarrel; a disturbance; a brawl."
            }
        ]
    },
    {
        "id": 31766,
        "spelling_name": "Fragile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50788,
                "word_type": "a.",
                "spelling_usage": "Easily broken; brittle; frail; delicate; easily destroyed."
            }
        ]
    },
    {
        "id": 31779,
        "spelling_name": "Fragrant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50806,
                "word_type": "a.",
                "spelling_usage": "Affecting the olfactory nerves agreeably; sweet of smell;\n   odorous; having or emitting an agreeable perfume."
            }
        ]
    },
    {
        "id": 31836,
        "spelling_name": "Frantic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 50915,
                "word_type": "a.",
                "spelling_usage": "Mad; raving; furious; violent; wild and disorderly;\n   distracted."
            }
        ]
    },
    {
        "id": 31999,
        "spelling_name": "Fret",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 51191,
                "word_type": "n.",
                "spelling_usage": "See 1st Frith."
            },
            {
                "spelling_detail_id": 51194,
                "word_type": "v. t.",
                "spelling_usage": "To devour."
            },
            {
                "spelling_detail_id": 51195,
                "word_type": "v. t.",
                "spelling_usage": "To rub; to wear away by friction; to chafe; to gall;\n   hence, to eat away; to gnaw; as, to fret cloth; to fret a piece of gold\n   or other metal; a worm frets the plants of a ship."
            },
            {
                "spelling_detail_id": 51196,
                "word_type": "v. t.",
                "spelling_usage": "To impair; to wear away; to diminish."
            },
            {
                "spelling_detail_id": 51197,
                "word_type": "v. t.",
                "spelling_usage": "To make rough, agitate, or disturb; to cause to ripple;\n   as, to fret the surface of water."
            },
            {
                "spelling_detail_id": 51198,
                "word_type": "v. t.",
                "spelling_usage": "To tease; to irritate; to vex."
            },
            {
                "spelling_detail_id": 51199,
                "word_type": "v. i.",
                "spelling_usage": "To be worn away; to chafe; to fray; as, a wristband frets\n   on the edges."
            },
            {
                "spelling_detail_id": 51200,
                "word_type": "v. i.",
                "spelling_usage": "To eat in; to make way by corrosion."
            },
            {
                "spelling_detail_id": 51201,
                "word_type": "v. i.",
                "spelling_usage": "To be agitated; to be in violent commotion; to rankle; as,\n   rancor frets in the malignant breast."
            },
            {
                "spelling_detail_id": 51202,
                "word_type": "v. i.",
                "spelling_usage": "To be vexed; to be chafed or irritated; to be angry; to\n   utter peevish expressions."
            },
            {
                "spelling_detail_id": 51203,
                "word_type": "n.",
                "spelling_usage": "The agitation of the surface of a fluid by fermentation or\n   other cause; a rippling on the surface of water."
            },
            {
                "spelling_detail_id": 51204,
                "word_type": "n.",
                "spelling_usage": "Agitation of mind marked by complaint and impatience;\n   disturbance of temper; irritation; as, he keeps his mind in a continual\n   fret."
            },
            {
                "spelling_detail_id": 51205,
                "word_type": "n.",
                "spelling_usage": "Herpes; tetter."
            },
            {
                "spelling_detail_id": 51206,
                "word_type": "n.",
                "spelling_usage": "The worn sides of river banks, where ores, or stones\n   containing them, accumulate by being washed down from the hills, and\n   thus indicate to the miners the locality of the veins."
            },
            {
                "spelling_detail_id": 51207,
                "word_type": "v. t.",
                "spelling_usage": "To ornament with raised work; to variegate; to diversify."
            },
            {
                "spelling_detail_id": 51208,
                "word_type": "n.",
                "spelling_usage": "Ornamental work in relief, as carving or embossing. See\n   Fretwork."
            },
            {
                "spelling_detail_id": 51209,
                "word_type": "n.",
                "spelling_usage": "An ornament consisting of smmall fillets or slats\n   intersecting each other or bent at right angles, as in classical\n   designs, or at obilique angles, as often in Oriental art."
            },
            {
                "spelling_detail_id": 51210,
                "word_type": "n.",
                "spelling_usage": "The reticulated headdress or net, made of gold or silver\n   wire, in which ladies in the Middle Ages confined their hair."
            },
            {
                "spelling_detail_id": 51211,
                "word_type": "n.",
                "spelling_usage": "A saltire interlaced with a mascle."
            },
            {
                "spelling_detail_id": 51212,
                "word_type": "n.",
                "spelling_usage": "A short piece of wire, or other material fixed across the\n   finger board of a guitar or a similar instrument, to indicate where the\n   finger is to be placed."
            },
            {
                "spelling_detail_id": 51213,
                "word_type": "v. t.",
                "spelling_usage": "To furnish with frets, as an instrument of music."
            }
        ]
    },
    {
        "id": 32086,
        "spelling_name": "Fringe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 51350,
                "word_type": "n.",
                "spelling_usage": "An ornamental appendage to the border of a piece of stuff,\n   originally consisting of the ends of the warp, projecting beyond the\n   woven fabric; but more commonly made separate and sewed on, consisting\n   sometimes of projecting ends, twisted or plaited together, and\n   sometimes of loose threads of wool, silk, or linen, or narrow strips of\n   leather, or the like."
            },
            {
                "spelling_detail_id": 51351,
                "word_type": "n.",
                "spelling_usage": "Something resembling in any respect a fringe; a line of\n   objects along a border or edge; a border; an edging; a margin; a\n   confine."
            },
            {
                "spelling_detail_id": 51352,
                "word_type": "n.",
                "spelling_usage": "One of a number of light or dark bands, produced by the\n   interference of light; a diffraction band; -- called also interference\n   fringe."
            },
            {
                "spelling_detail_id": 51353,
                "word_type": "n.",
                "spelling_usage": "The peristome or fringelike appendage of the capsules of\n   most mosses. See Peristome."
            },
            {
                "spelling_detail_id": 51356,
                "word_type": "v. t.",
                "spelling_usage": "To adorn the edge of with a fringe or as with a fringe."
            }
        ]
    },
    {
        "id": 32236,
        "spelling_name": "Froward",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 51606,
                "word_type": "a.",
                "spelling_usage": "Not willing to yield or compIy with what is required or is\n   reasonable; perverse; disobedient; peevish; as, a froward child."
            }
        ]
    },
    {
        "id": 32266,
        "spelling_name": "Frugal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 51645,
                "word_type": "n.",
                "spelling_usage": "Economical in the use or appropriation of resources; not\n   wasteful or lavish; wise in the expenditure or application of force,\n   materials, time, etc.; characterized by frugality; sparing; economical;\n   saving; as, a frugal housekeeper; frugal of time."
            },
            {
                "spelling_detail_id": 51646,
                "word_type": "n.",
                "spelling_usage": "Obtained by, or appropriate to, economy; as, a frugal\n   fortune."
            }
        ]
    },
    {
        "id": 32435,
        "spelling_name": "Fulmination",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 51908,
                "word_type": "n.",
                "spelling_usage": "The act of fulminating or exploding; detonation."
            },
            {
                "spelling_detail_id": 51909,
                "word_type": "n.",
                "spelling_usage": "The act of thundering forth threats or censures, as\n   with authority."
            },
            {
                "spelling_detail_id": 51910,
                "word_type": "n.",
                "spelling_usage": "That which is fulminated or thundered forth; vehement\n   menace or censure."
            }
        ]
    },
    {
        "id": 32441,
        "spelling_name": "Fulsome",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 51919,
                "word_type": "a.",
                "spelling_usage": "Full; abundant; plenteous; not shriveled."
            },
            {
                "spelling_detail_id": 51920,
                "word_type": "a.",
                "spelling_usage": "Offending or disgusting by overfullness, excess, or\n   grossness; cloying; gross; nauseous; esp., offensive from excess of\n   praise; as, fulsome flattery."
            },
            {
                "spelling_detail_id": 51921,
                "word_type": "a.",
                "spelling_usage": "Lustful; wanton; obscene; also, tending to obscenity."
            }
        ]
    },
    {
        "id": 32630,
        "spelling_name": "Furtive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 52209,
                "word_type": "a.",
                "spelling_usage": "Stolen; obtained or characterized by stealth; sly; secret;\n   stealthy; as, a furtive look."
            }
        ]
    },
    {
        "id": 32675,
        "spelling_name": "Fustian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 52283,
                "word_type": "n.",
                "spelling_usage": "A kind of coarse twilled cotton or cotton and linen stuff,\n   including corduroy, velveteen, etc."
            },
            {
                "spelling_detail_id": 52284,
                "word_type": "n.",
                "spelling_usage": "An inflated style of writing; a kind of writing in which\n   high-sounding words are used,' above the dignity of the thoughts or\n   subject; bombast."
            },
            {
                "spelling_detail_id": 52285,
                "word_type": "a.",
                "spelling_usage": "Made of fustian."
            },
            {
                "spelling_detail_id": 52286,
                "word_type": "a.",
                "spelling_usage": "Pompous; ridiculously tumid; inflated; bombastic; as,\n   fustian history."
            }
        ]
    },
    {
        "id": 32801,
        "spelling_name": "Gainsay",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 52485,
                "word_type": "v. t.",
                "spelling_usage": "To contradict; to deny; to controvert; to dispute; to\n   forbid."
            }
        ]
    },
    {
        "id": 32813,
        "spelling_name": "Gait",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 52498,
                "word_type": "n.",
                "spelling_usage": "A going; a walk; a march; a way."
            },
            {
                "spelling_detail_id": 52499,
                "word_type": "n.",
                "spelling_usage": "Manner of walking or stepping; bearing or carriage while\n   moving."
            }
        ]
    },
    {
        "id": 32863,
        "spelling_name": "Gallant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 52589,
                "word_type": "a.",
                "spelling_usage": "Showy; splendid; magnificent; gay; well-dressed."
            },
            {
                "spelling_detail_id": 52590,
                "word_type": "a.",
                "spelling_usage": "Noble in bearing or spirit; brave; high-spirited;\n   courageous; heroic; magnanimous; as, a gallant youth; a gallant\n   officer."
            },
            {
                "spelling_detail_id": 52591,
                "word_type": "a.",
                "spelling_usage": "Polite and attentive to ladies; courteous to women;\n   chivalrous."
            },
            {
                "spelling_detail_id": 52592,
                "word_type": "n.",
                "spelling_usage": "A man of mettle or spirit; a gay; fashionable man; a young\n   blood."
            },
            {
                "spelling_detail_id": 52593,
                "word_type": "n.",
                "spelling_usage": "One fond of paying attention to ladies."
            },
            {
                "spelling_detail_id": 52594,
                "word_type": "n.",
                "spelling_usage": "One who wooes; a lover; a suitor; in a bad sense, a\n   seducer."
            },
            {
                "spelling_detail_id": 52597,
                "word_type": "v. t.",
                "spelling_usage": "To attend or wait on, as a lady; as, to gallant ladies\n   to the play."
            },
            {
                "spelling_detail_id": 52598,
                "word_type": "v. t.",
                "spelling_usage": "To handle with grace or in a modish manner; as, to\n   gallant a fan."
            }
        ]
    },
    {
        "id": 33099,
        "spelling_name": "Garble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 52942,
                "word_type": "v. t.",
                "spelling_usage": "To sift or bolt, to separate the fine or valuable parts\n   of from the coarse and useless parts, or from dros or dirt; as, to\n   garble spices."
            },
            {
                "spelling_detail_id": 52943,
                "word_type": "v. t.",
                "spelling_usage": "To pick out such parts of as may serve a purpose; to\n   mutilate; to pervert; as, to garble a quotation; to garble an account."
            },
            {
                "spelling_detail_id": 52944,
                "word_type": "n.",
                "spelling_usage": "Refuse; rubbish."
            },
            {
                "spelling_detail_id": 52945,
                "word_type": "n.",
                "spelling_usage": "Impurities separated from spices, drugs, etc.; -- also\n   called garblings."
            }
        ]
    },
    {
        "id": 33146,
        "spelling_name": "Garner",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 53011,
                "word_type": "n.",
                "spelling_usage": "A granary; a building or place where grain is stored for\n   preservation."
            },
            {
                "spelling_detail_id": 53014,
                "word_type": "v. t.",
                "spelling_usage": "To gather for preservation; to store, as in a granary;\n   to treasure."
            }
        ]
    },
    {
        "id": 33179,
        "spelling_name": "Garrulous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 53066,
                "word_type": "a.",
                "spelling_usage": "Talking much, especially about commonplace or trivial\n   things; talkative; loquacious."
            },
            {
                "spelling_detail_id": 53067,
                "word_type": "a.",
                "spelling_usage": "Having a loud, harsh note; noisy; -- said of birds; as,\n   the garrulous roller."
            }
        ]
    },
    {
        "id": 33309,
        "spelling_name": "Gauche",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 53255,
                "word_type": "n.",
                "spelling_usage": "Left handed; hence, awkward; clumsy."
            },
            {
                "spelling_detail_id": 53256,
                "word_type": "n.",
                "spelling_usage": "Winding; twisted; warped; -- applied to curves and\n   surfaces."
            }
        ]
    },
    {
        "id": 33310,
        "spelling_name": "Gaucherie",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 53257,
                "word_type": "n.",
                "spelling_usage": "An awkward action; clumsiness; boorishness."
            }
        ]
    },
    {
        "id": 33769,
        "spelling_name": "Germane",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 53950,
                "word_type": "a.",
                "spelling_usage": "Literally, near akin; hence, closely allied; appropriate\n   or fitting; relevant."
            }
        ]
    },
    {
        "id": 33801,
        "spelling_name": "Gerontocracy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 53990,
                "word_type": "n.",
                "spelling_usage": "Government by old men."
            }
        ]
    },
    {
        "id": 34050,
        "spelling_name": "Gist",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 54417,
                "word_type": "n.",
                "spelling_usage": "A resting place."
            },
            {
                "spelling_detail_id": 54418,
                "word_type": "n.",
                "spelling_usage": "The main point, as of a question; the point on which an\n   action rests; the pith of a matter; as, the gist of a question."
            }
        ]
    },
    {
        "id": 34208,
        "spelling_name": "Glean",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 54692,
                "word_type": "v. t.",
                "spelling_usage": "To gather after a reaper; to collect in scattered or\n   fragmentary parcels, as the grain left by a reaper, or grapes left\n   after the gathering."
            },
            {
                "spelling_detail_id": 54693,
                "word_type": "v. t.",
                "spelling_usage": "To gather from (a field or vineyard) what is left."
            },
            {
                "spelling_detail_id": 54694,
                "word_type": "v. t.",
                "spelling_usage": "To collect with patient and minute labor; to pick out; to\n   obtain."
            },
            {
                "spelling_detail_id": 54695,
                "word_type": "v. i.",
                "spelling_usage": "To gather stalks or ears of grain left by reapers."
            },
            {
                "spelling_detail_id": 54696,
                "word_type": "v. i.",
                "spelling_usage": "To pick up or gather anything by degrees."
            },
            {
                "spelling_detail_id": 54697,
                "word_type": "n.",
                "spelling_usage": "A collection made by gleaning."
            },
            {
                "spelling_detail_id": 54698,
                "word_type": "n.",
                "spelling_usage": "Cleaning; afterbirth."
            }
        ]
    },
    {
        "id": 34241,
        "spelling_name": "Glib",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 54743,
                "word_type": "superl.",
                "spelling_usage": "Smooth; slippery; as, ice is glib."
            },
            {
                "spelling_detail_id": 54744,
                "word_type": "superl.",
                "spelling_usage": "Speaking or spoken smoothly and with flippant rapidity;\n   fluent; voluble; as, a glib tongue; a glib speech."
            },
            {
                "spelling_detail_id": 54745,
                "word_type": "v. t.",
                "spelling_usage": "To make glib."
            },
            {
                "spelling_detail_id": 54746,
                "word_type": "n.",
                "spelling_usage": "A thick lock of hair, hanging over the eyes."
            },
            {
                "spelling_detail_id": 54747,
                "word_type": "v. t.",
                "spelling_usage": "To castrate; to geld; to emasculate."
            }
        ]
    },
    {
        "id": 34257,
        "spelling_name": "Glimmer",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 54773,
                "word_type": "v. i.",
                "spelling_usage": "To give feeble or scattered rays of light; to shine\n   faintly; to show a faint, unsteady light; as, the glimmering dawn; a\n   glimmering lamp."
            },
            {
                "spelling_detail_id": 54774,
                "word_type": "n.",
                "spelling_usage": "A faint, unsteady light; feeble, scattered rays of light;\n   also, a gleam."
            },
            {
                "spelling_detail_id": 54775,
                "word_type": "n.",
                "spelling_usage": "Mica. See Mica."
            }
        ]
    },
    {
        "id": 34287,
        "spelling_name": "Gloat",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 54819,
                "word_type": "v. i.",
                "spelling_usage": "To look steadfastly; to gaze earnestly; -- usually in a\n   bad sense, to gaze with malignant satisfaction, passionate desire,\n   lust, or avarice."
            }
        ]
    },
    {
        "id": 34450,
        "spelling_name": "Glut",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55054,
                "word_type": "v. t.",
                "spelling_usage": "To swallow, or to swallow greedlly; to gorge."
            },
            {
                "spelling_detail_id": 55055,
                "word_type": "v. t.",
                "spelling_usage": "To fill to satiety; to satisfy fully the desire or craving\n   of; to satiate; to sate; to cloy."
            },
            {
                "spelling_detail_id": 55056,
                "word_type": "v. i.",
                "spelling_usage": "To eat gluttonously or to satiety."
            },
            {
                "spelling_detail_id": 55057,
                "word_type": "n.",
                "spelling_usage": "That which is swallowed."
            },
            {
                "spelling_detail_id": 55058,
                "word_type": "n.",
                "spelling_usage": "Plenty, to satiety or repletion; a full supply; hence, often,\n   a supply beyond sufficiency or to loathing; over abundance; as, a glut\n   of the market."
            },
            {
                "spelling_detail_id": 55059,
                "word_type": "n.",
                "spelling_usage": "Something that fills up an opening; a clog."
            },
            {
                "spelling_detail_id": 55060,
                "word_type": "n.",
                "spelling_usage": "A wooden wedge used in splitting blocks."
            },
            {
                "spelling_detail_id": 55061,
                "word_type": "n.",
                "spelling_usage": "A piece of wood used to fill up behind cribbing or tubbing."
            },
            {
                "spelling_detail_id": 55062,
                "word_type": "n.",
                "spelling_usage": "A bat, or small piece of brick, used to fill out a course."
            },
            {
                "spelling_detail_id": 55063,
                "word_type": "n.",
                "spelling_usage": "An arched opening to the ashpit of a klin."
            },
            {
                "spelling_detail_id": 55064,
                "word_type": "n.",
                "spelling_usage": "A block used for a fulcrum."
            },
            {
                "spelling_detail_id": 55065,
                "word_type": "n.",
                "spelling_usage": "The broad-nosed eel (Anguilla latirostris), found in Europe,\n   Asia, the West Indies, etc."
            }
        ]
    },
    {
        "id": 34558,
        "spelling_name": "Gnaw",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55189,
                "word_type": "v. t.",
                "spelling_usage": "To bite, as something hard or tough, which is not readily\n   separated or crushed; to bite off little by little, with effort; to\n   wear or eat away by scraping or continuous biting with the teeth; to\n   nibble at."
            },
            {
                "spelling_detail_id": 55190,
                "word_type": "v. t.",
                "spelling_usage": "To bite in agony or rage."
            },
            {
                "spelling_detail_id": 55191,
                "word_type": "v. t.",
                "spelling_usage": "To corrode; to fret away; to waste."
            },
            {
                "spelling_detail_id": 55192,
                "word_type": "v. i.",
                "spelling_usage": "To use the teeth in biting; to bite with repeated effort,\n   as in eating or removing with the teethsomething hard, unwiedly, or\n   unmanageable."
            }
        ]
    },
    {
        "id": 34595,
        "spelling_name": "Goad",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55257,
                "word_type": "v. t.",
                "spelling_usage": "A pointed instrument used to urge on a beast; hence, any\n   necessity that urges or stimulates."
            },
            {
                "spelling_detail_id": 55260,
                "word_type": "v. t.",
                "spelling_usage": "To prick; to drive with a goad; hence, to urge forward, or\n   to rouse by anything pungent, severe, irritating, or inflaming; to\n   stimulate."
            }
        ]
    },
    {
        "id": 34843,
        "spelling_name": "Gorge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55616,
                "word_type": "n.",
                "spelling_usage": "The throat; the gullet; the canal by which food passes to\n   the stomach."
            },
            {
                "spelling_detail_id": 55617,
                "word_type": "n.",
                "spelling_usage": "A narrow passage or entrance"
            },
            {
                "spelling_detail_id": 55618,
                "word_type": "n.",
                "spelling_usage": "A defile between mountains."
            },
            {
                "spelling_detail_id": 55619,
                "word_type": "n.",
                "spelling_usage": "The entrance into a bastion or other outwork of a fort; --\n   usually synonymous with rear. See Illust. of Bastion."
            },
            {
                "spelling_detail_id": 55620,
                "word_type": "n.",
                "spelling_usage": "That which is gorged or swallowed, especially by a hawk or\n   other fowl."
            },
            {
                "spelling_detail_id": 55621,
                "word_type": "n.",
                "spelling_usage": "A filling or choking of a passage or channel by an\n   obstruction; as, an ice gorge in a river."
            },
            {
                "spelling_detail_id": 55622,
                "word_type": "n.",
                "spelling_usage": "A concave molding; a cavetto."
            },
            {
                "spelling_detail_id": 55623,
                "word_type": "n.",
                "spelling_usage": "The groove of a pulley."
            },
            {
                "spelling_detail_id": 55626,
                "word_type": "n.",
                "spelling_usage": "To swallow; especially, to swallow with greediness, or in\n   large mouthfuls or quantities."
            },
            {
                "spelling_detail_id": 55627,
                "word_type": "n.",
                "spelling_usage": "To glut; to fill up to the throat; to satiate."
            },
            {
                "spelling_detail_id": 55628,
                "word_type": "v. i.",
                "spelling_usage": "To eat greedily and to satiety."
            }
        ]
    },
    {
        "id": 34885,
        "spelling_name": "Gossamer",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55696,
                "word_type": "n.",
                "spelling_usage": "A fine, filmy substance, like cobwebs, floating in the\n   air, in calm, clear weather, especially in autumn. It is seen in\n   stubble fields and on furze or low bushes, and is formed by small\n   spiders."
            },
            {
                "spelling_detail_id": 55697,
                "word_type": "n.",
                "spelling_usage": "Any very thin gauzelike fabric; also, a thin waterproof\n   stuff."
            },
            {
                "spelling_detail_id": 55698,
                "word_type": "n.",
                "spelling_usage": "An outer garment, made of waterproof gossamer."
            }
        ]
    },
    {
        "id": 34917,
        "spelling_name": "Gouge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55746,
                "word_type": "n.",
                "spelling_usage": "A chisel, with a hollow or semicylindrical blade, for\n   scooping or cutting holes, channels, or grooves, in wood, stone, etc.;\n   a similar instrument, with curved edge, for turning wood."
            },
            {
                "spelling_detail_id": 55747,
                "word_type": "n.",
                "spelling_usage": "A bookbinder's tool for blind tooling or gilding, having a\n   face which forms a curve."
            },
            {
                "spelling_detail_id": 55748,
                "word_type": "n.",
                "spelling_usage": "An incising tool which cuts forms or blanks for gloves,\n   envelopes, etc. from leather, paper, etc."
            },
            {
                "spelling_detail_id": 55749,
                "word_type": "n.",
                "spelling_usage": "Soft material lying between the wall of a vein aud the solid\n   vein."
            },
            {
                "spelling_detail_id": 55750,
                "word_type": "n.",
                "spelling_usage": "The act of scooping out with a gouge, or as with a gouge; a\n   groove or cavity scooped out, as with a gouge."
            },
            {
                "spelling_detail_id": 55751,
                "word_type": "n.",
                "spelling_usage": "Imposition; cheat; fraud; also, an impostor; a cheat; a\n   trickish person."
            }
        ]
    },
    {
        "id": 34934,
        "spelling_name": "Gourmand",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 55775,
                "word_type": "n.",
                "spelling_usage": "A greedy or ravenous eater; a glutton. See Gormand."
            }
        ]
    },
    {
        "id": 35105,
        "spelling_name": "Grandiloquent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 56108,
                "word_type": "a.",
                "spelling_usage": "Speaking in a lofty style; pompous; bombastic."
            }
        ]
    },
    {
        "id": 35238,
        "spelling_name": "Grave",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 56326,
                "word_type": "v. t.",
                "spelling_usage": "To clean, as a vessel's bottom, of barnacles, grass,\n   etc., and pay it over with pitch; -- so called because graves or\n   greaves was formerly used for this purpose."
            },
            {
                "spelling_detail_id": 56327,
                "word_type": "superl.",
                "spelling_usage": "Of great weight; heavy; ponderous."
            },
            {
                "spelling_detail_id": 56328,
                "word_type": "superl.",
                "spelling_usage": "Of importance; momentous; weighty; influential; sedate;\n   serious; -- said of character, relations, etc.; as, grave deportment,\n   character, influence, etc."
            },
            {
                "spelling_detail_id": 56329,
                "word_type": "superl.",
                "spelling_usage": "Not light or gay; solemn; sober; plain; as, a grave\n   color; a grave face."
            },
            {
                "spelling_detail_id": 56330,
                "word_type": "superl.",
                "spelling_usage": "Not acute or sharp; low; deep; -- said of sound; as, a\n   grave note or key."
            },
            {
                "spelling_detail_id": 56331,
                "word_type": "superl.",
                "spelling_usage": "Slow and solemn in movement."
            },
            {
                "spelling_detail_id": 56336,
                "word_type": "n.",
                "spelling_usage": "To dig. [Obs.] Chaucer."
            },
            {
                "spelling_detail_id": 56337,
                "word_type": "n.",
                "spelling_usage": "To carve or cut, as letters or figures, on some hard\n   substance; to engrave."
            },
            {
                "spelling_detail_id": 56338,
                "word_type": "n.",
                "spelling_usage": "To carve out or give shape to, by cutting with a chisel; to\n   sculpture; as, to grave an image."
            },
            {
                "spelling_detail_id": 56339,
                "word_type": "n.",
                "spelling_usage": "To impress deeply (on the mind); to fix indelibly."
            },
            {
                "spelling_detail_id": 56340,
                "word_type": "n.",
                "spelling_usage": "To entomb; to bury."
            },
            {
                "spelling_detail_id": 56341,
                "word_type": "v. i.",
                "spelling_usage": "To write or delineate on hard substances, by means of\n   incised lines; to practice engraving."
            },
            {
                "spelling_detail_id": 56342,
                "word_type": "n.",
                "spelling_usage": "An excavation in the earth as a place of burial; also, any\n   place of interment; a tomb; a sepulcher. Hence: Death; destruction."
            }
        ]
    },
    {
        "id": 35292,
        "spelling_name": "Graze",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 56428,
                "word_type": "v. t.",
                "spelling_usage": "To feed or supply (cattle, sheep, etc.) with grass; to\n   furnish pasture for."
            },
            {
                "spelling_detail_id": 56429,
                "word_type": "v. t.",
                "spelling_usage": "To feed on; to eat (growing herbage); to eat grass from\n   (a pasture); to browse."
            },
            {
                "spelling_detail_id": 56430,
                "word_type": "v. t.",
                "spelling_usage": "To tend (cattle, etc.) while grazing."
            },
            {
                "spelling_detail_id": 56431,
                "word_type": "v. t.",
                "spelling_usage": "To rub or touch lightly the surface of (a thing) in\n   passing; as, the bullet grazed the wall."
            },
            {
                "spelling_detail_id": 56432,
                "word_type": "v. i.",
                "spelling_usage": "To eat grass; to feed on growing herbage; as, cattle\n   graze on the meadows."
            },
            {
                "spelling_detail_id": 56433,
                "word_type": "v. i.",
                "spelling_usage": "To yield grass for grazing."
            },
            {
                "spelling_detail_id": 56434,
                "word_type": "v. i.",
                "spelling_usage": "To touch something lightly in passing."
            },
            {
                "spelling_detail_id": 56435,
                "word_type": "n.",
                "spelling_usage": "The act of grazing; the cropping of grass."
            },
            {
                "spelling_detail_id": 56436,
                "word_type": "n.",
                "spelling_usage": "A light touch; a slight scratch."
            }
        ]
    },
    {
        "id": 35390,
        "spelling_name": "Gregarious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 56616,
                "word_type": "a.",
                "spelling_usage": "Habitually living or moving in flocks or herds; tending\n   to flock or herd together; not habitually solitary or living alone."
            }
        ]
    },
    {
        "id": 35440,
        "spelling_name": "Grievous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 56687,
                "word_type": "a.",
                "spelling_usage": "Causing grief or sorrow; painful; afflictive; hard to\n   bear; offensive; harmful."
            },
            {
                "spelling_detail_id": 56688,
                "word_type": "a.",
                "spelling_usage": "Characterized by great atrocity; heinous; aggravated;\n   flagitious; as, a grievous sin."
            },
            {
                "spelling_detail_id": 56689,
                "word_type": "a.",
                "spelling_usage": "Full of, or expressing, grief; showing great sorrow or\n   affliction; as, a grievous cry."
            }
        ]
    },
    {
        "id": 35611,
        "spelling_name": "Grouse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57007,
                "word_type": "n. sing. & pl.",
                "spelling_usage": "Any of the numerous species of gallinaceous\n   birds of the family Tetraonidae, and subfamily Tetraoninae, inhabiting\n   Europe, Asia, and North America. They have plump bodies, strong,\n   well-feathered legs, and usually mottled plumage. The group includes\n   the ptarmigans (Lagopus), having feathered feet."
            },
            {
                "spelling_detail_id": 57008,
                "word_type": "v. i.",
                "spelling_usage": "To seek or shoot grouse."
            },
            {
                "spelling_detail_id": 57009,
                "word_type": "v. i.",
                "spelling_usage": "To complain or grumble."
            }
        ]
    },
    {
        "id": 35620,
        "spelling_name": "Grovel",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57027,
                "word_type": "adv.",
                "spelling_usage": "To creep on the earth, or with the face to the ground; to\n   lie prone, or move uneasily with the body prostrate on the earth; to\n   lie fiat on one's belly, expressive of abjectness; to crawl."
            },
            {
                "spelling_detail_id": 57028,
                "word_type": "adv.",
                "spelling_usage": "To tend toward, or delight in, what is sensual or base;\n   to be low, abject, or mean."
            }
        ]
    },
    {
        "id": 35670,
        "spelling_name": "Grumble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57102,
                "word_type": "v. i.",
                "spelling_usage": "To murmur or mutter with discontent; to make\n   ill-natured complaints in a low voice and a surly manner."
            },
            {
                "spelling_detail_id": 57103,
                "word_type": "v. i.",
                "spelling_usage": "To growl; to snarl in deep tones; as, a lion grumbling\n   over his prey."
            },
            {
                "spelling_detail_id": 57104,
                "word_type": "v. i.",
                "spelling_usage": "To rumble; to make a low, harsh, and heavy sound; to\n   mutter; as, the distant thunder grumbles."
            },
            {
                "spelling_detail_id": 57105,
                "word_type": "v. t.",
                "spelling_usage": "To express or utter with grumbling."
            },
            {
                "spelling_detail_id": 57106,
                "word_type": "n.",
                "spelling_usage": "The noise of one that grumbles."
            },
            {
                "spelling_detail_id": 57107,
                "word_type": "n.",
                "spelling_usage": "A grumbling, discontented disposition."
            }
        ]
    },
    {
        "id": 35823,
        "spelling_name": "Guile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57327,
                "word_type": "n.",
                "spelling_usage": "Craft; deceitful cunning; artifice; duplicity; wile; deceit;\n   treachery."
            },
            {
                "spelling_detail_id": 57328,
                "word_type": "n.",
                "spelling_usage": "To disguise or conceal; to deceive or delude."
            }
        ]
    },
    {
        "id": 35825,
        "spelling_name": "Guileless",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57330,
                "word_type": "a.",
                "spelling_usage": "Free from guile; artless."
            }
        ]
    },
    {
        "id": 35872,
        "spelling_name": "Gullible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57403,
                "word_type": "a.",
                "spelling_usage": "Easily gulled; that may be duped."
            }
        ]
    },
    {
        "id": 35958,
        "spelling_name": "Gush",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57518,
                "word_type": "v. i.",
                "spelling_usage": "To issue with violence and rapidity, as a fluid; to rush\n   forth as a fluid from confinement; to flow copiously."
            },
            {
                "spelling_detail_id": 57519,
                "word_type": "v. i.",
                "spelling_usage": "To make a sentimental or untimely exhibition of affection;\n   to display enthusiasm in a silly, demonstrative manner."
            },
            {
                "spelling_detail_id": 57520,
                "word_type": "v. t.",
                "spelling_usage": "A sudden and violent issue of a fluid from an inclosed\n   plase; an emission of a liquid in a large quantity, and with force; the\n   fluid thus emitted; a rapid outpouring of anything; as, a gush of song\n   from a bird."
            },
            {
                "spelling_detail_id": 57521,
                "word_type": "v. t.",
                "spelling_usage": "A sentimental exhibition of affection or enthusiasm, etc.;\n   effusive display of sentiment."
            }
        ]
    },
    {
        "id": 35964,
        "spelling_name": "Gust",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57532,
                "word_type": "n.",
                "spelling_usage": "A sudden squall; a violent blast of wind; a sudden and brief\n   rushing or driving of the wind. Snow, and hail, stormy gust and flaw."
            },
            {
                "spelling_detail_id": 57533,
                "word_type": "n.",
                "spelling_usage": "A sudden violent burst of passion."
            },
            {
                "spelling_detail_id": 57534,
                "word_type": "n.",
                "spelling_usage": "The sense or pleasure of tasting; relish; gusto."
            },
            {
                "spelling_detail_id": 57535,
                "word_type": "n.",
                "spelling_usage": "Gratification of any kind, particularly that which is\n   exquisitely relished; enjoyment."
            },
            {
                "spelling_detail_id": 57536,
                "word_type": "n.",
                "spelling_usage": "Intellectual taste; fancy."
            },
            {
                "spelling_detail_id": 57537,
                "word_type": "v. t.",
                "spelling_usage": "To taste; to have a relish for."
            }
        ]
    },
    {
        "id": 36180,
        "spelling_name": "Hack",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 57816,
                "word_type": "n.",
                "spelling_usage": "A frame or grating of various kinds; as, a frame for drying\n   bricks, fish, or cheese; a rack for feeding cattle; a grating in a mill\n   race, etc."
            },
            {
                "spelling_detail_id": 57817,
                "word_type": "n.",
                "spelling_usage": "Unburned brick or tile, stacked up for drying."
            },
            {
                "spelling_detail_id": 57820,
                "word_type": "v. t.",
                "spelling_usage": "To cut irregulary, without skill or definite purpose; to\n   notch; to mangle by repeated strokes of a cutting instrument; as, to\n   hack a post."
            },
            {
                "spelling_detail_id": 57821,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To mangle in speaking."
            },
            {
                "spelling_detail_id": 57822,
                "word_type": "v. i.",
                "spelling_usage": "To cough faintly and frequently, or in a short, broken\n   manner; as, a hacking cough."
            },
            {
                "spelling_detail_id": 57823,
                "word_type": "n.",
                "spelling_usage": "A notch; a cut."
            },
            {
                "spelling_detail_id": 57824,
                "word_type": "n.",
                "spelling_usage": "An implement for cutting a notch; a large pick used in\n   breaking stone."
            },
            {
                "spelling_detail_id": 57825,
                "word_type": "n.",
                "spelling_usage": "A hacking; a catch in speaking; a short, broken cough."
            },
            {
                "spelling_detail_id": 57826,
                "word_type": "n.",
                "spelling_usage": "A kick on the shins."
            },
            {
                "spelling_detail_id": 57827,
                "word_type": "n.",
                "spelling_usage": "A horse, hackneyed or let out for common hire; also, a horse\n   used in all kinds of work, or a saddle horse, as distinguished from\n   hunting and carriage horses."
            },
            {
                "spelling_detail_id": 57828,
                "word_type": "n.",
                "spelling_usage": "A coach or carriage let for hire; particularly, a a coach\n   with two seats inside facing each other; a hackney coach."
            },
            {
                "spelling_detail_id": 57829,
                "word_type": "n.",
                "spelling_usage": "A bookmaker who hires himself out for any sort of literary\n   work; an overworked man; a drudge."
            },
            {
                "spelling_detail_id": 57830,
                "word_type": "n.",
                "spelling_usage": "A procuress."
            },
            {
                "spelling_detail_id": 57831,
                "word_type": "a.",
                "spelling_usage": "Hackneyed; hired; mercenary."
            },
            {
                "spelling_detail_id": 57832,
                "word_type": "v. t.",
                "spelling_usage": "To use as a hack; to let out for hire."
            },
            {
                "spelling_detail_id": 57833,
                "word_type": "v. t.",
                "spelling_usage": "To use frequently and indiscriminately, so as to render\n   trite and commonplace."
            },
            {
                "spelling_detail_id": 57834,
                "word_type": "v. i.",
                "spelling_usage": "To be exposed or offered or to common use for hire; to\n   turn prostitute."
            },
            {
                "spelling_detail_id": 57835,
                "word_type": "v. i.",
                "spelling_usage": "To live the life of a drudge or hack."
            }
        ]
    },
    {
        "id": 36388,
        "spelling_name": "Halcyon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 58106,
                "word_type": "n.",
                "spelling_usage": "A kingfisher. By modern ornithologists restricted to a\n   genus including a limited number of species having omnivorous habits,\n   as the sacred kingfisher (Halcyon sancta) of Australia."
            },
            {
                "spelling_detail_id": 58107,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or resembling, the halcyon, which was\n   anciently said to lay her eggs in nests on or near the sea during the\n   calm weather about the winter solstice."
            },
            {
                "spelling_detail_id": 58108,
                "word_type": "a.",
                "spelling_usage": "Hence: Calm; quiet; peaceful; undisturbed; happy."
            }
        ]
    },
    {
        "id": 36470,
        "spelling_name": "Hallow",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 58226,
                "word_type": "v. t.",
                "spelling_usage": "To make holy; to set apart for holy or religious use; to\n   consecrate; to treat or keep as sacred; to reverence."
            }
        ]
    },
    {
        "id": 36680,
        "spelling_name": "Hapless",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 58597,
                "word_type": "a.",
                "spelling_usage": "Without hap or luck; luckless; unfortunate; unlucky;\n   unhappy; as, hapless youth; hapless maid."
            }
        ]
    },
    {
        "id": 36696,
        "spelling_name": "Harangue",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 58620,
                "word_type": "n.",
                "spelling_usage": "A speech addressed to a large public assembly; a popular\n   oration; a loud address a multitude; in a bad sense, a noisy or pompous\n   speech; declamation; ranting."
            },
            {
                "spelling_detail_id": 58623,
                "word_type": "v. i.",
                "spelling_usage": "To make an harangue; to declaim."
            },
            {
                "spelling_detail_id": 58624,
                "word_type": "v. t.",
                "spelling_usage": "To address by an harangue."
            }
        ]
    },
    {
        "id": 36707,
        "spelling_name": "Harbinger",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 58635,
                "word_type": "n.",
                "spelling_usage": "One who provides lodgings; especially, the officer of\n   the English royal household who formerly preceded the court when\n   traveling, to provide and prepare lodgings."
            },
            {
                "spelling_detail_id": 58636,
                "word_type": "n.",
                "spelling_usage": "A forerunner; a precursor; a messenger."
            },
            {
                "spelling_detail_id": 58639,
                "word_type": "v. t.",
                "spelling_usage": "To usher in; to be a harbinger of."
            }
        ]
    },
    {
        "id": 36856,
        "spelling_name": "Harrow",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 58894,
                "word_type": "n.",
                "spelling_usage": "An implement of agriculture, usually formed of pieces of\n   timber or metal crossing each other, and set with iron or wooden teeth.\n   It is drawn over plowed land to level it and break the clods, to stir\n   the soil and make it fine, or to cover seed when sown."
            },
            {
                "spelling_detail_id": 58895,
                "word_type": "n.",
                "spelling_usage": "An obstacle formed by turning an ordinary harrow upside\n   down, the frame being buried."
            },
            {
                "spelling_detail_id": 58898,
                "word_type": "n.",
                "spelling_usage": "To draw a harrow over, as for the purpose of breaking clods\n   and leveling the surface, or for covering seed; as, to harrow land."
            },
            {
                "spelling_detail_id": 58899,
                "word_type": "n.",
                "spelling_usage": "To break or tear, as with a harrow; to wound; to lacerate;\n   to torment or distress; to vex."
            },
            {
                "spelling_detail_id": 58900,
                "word_type": "interj.",
                "spelling_usage": "Help! Halloo! An exclamation of distress; a call for\n   succor;-the ancient Norman hue and cry."
            },
            {
                "spelling_detail_id": 58901,
                "word_type": "v. t.",
                "spelling_usage": "To pillage; to harry; to oppress."
            }
        ]
    },
    {
        "id": 36969,
        "spelling_name": "Haughty",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 59077,
                "word_type": "superl.",
                "spelling_usage": "High; lofty; bold."
            },
            {
                "spelling_detail_id": 59078,
                "word_type": "superl.",
                "spelling_usage": "Disdainfully or contemptuously proud; arrogant;\n   overbearing."
            },
            {
                "spelling_detail_id": 59079,
                "word_type": "superl.",
                "spelling_usage": "Indicating haughtiness; as, a haughty carriage."
            }
        ]
    },
    {
        "id": 37000,
        "spelling_name": "Hauteur",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 59131,
                "word_type": "n.",
                "spelling_usage": "Haughty manner or spirit; haughtiness; pride; arrogance."
            }
        ]
    },
    {
        "id": 37340,
        "spelling_name": "Heed",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 59781,
                "word_type": "v. t.",
                "spelling_usage": "To mind; to regard with care; to take notice of; to attend\n   to; to observe."
            },
            {
                "spelling_detail_id": 59782,
                "word_type": "v. i.",
                "spelling_usage": "To mind; to consider."
            },
            {
                "spelling_detail_id": 59783,
                "word_type": "n.",
                "spelling_usage": "Attention; notice; observation; regard; -- often with give or\n   take."
            },
            {
                "spelling_detail_id": 59784,
                "word_type": "n.",
                "spelling_usage": "Careful consideration; obedient regard."
            },
            {
                "spelling_detail_id": 59785,
                "word_type": "n.",
                "spelling_usage": "A look or expression of heading."
            }
        ]
    },
    {
        "id": 37370,
        "spelling_name": "Hegemony",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 59844,
                "word_type": "n.",
                "spelling_usage": "Leadership; preponderant influence or authority; --\n   usually applied to the relation of a government or state to its\n   neighbors or confederates."
            }
        ]
    },
    {
        "id": 37380,
        "spelling_name": "Heinous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 59861,
                "word_type": "a.",
                "spelling_usage": "Hateful; hatefully bad; flagrant; odious; atrocious;\n   giving great great offense; -- applied to deeds or to character."
            }
        ]
    },
    {
        "id": 37868,
        "spelling_name": "Heresy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 60491,
                "word_type": "n.",
                "spelling_usage": "An opinion held in opposition to the established or\n   commonly received doctrine, and tending to promote a division or party,\n   as in politics, literature, philosophy, etc.; -- usually, but not\n   necessarily, said in reproach."
            },
            {
                "spelling_detail_id": 60492,
                "word_type": "n.",
                "spelling_usage": "Religious opinion opposed to the authorized doctrinal\n   standards of any particular church, especially when tending to promote\n   schism or separation; lack of orthodox or sound belief; rejection of,\n   or erroneous belief in regard to, some fundamental religious doctrine\n   or truth; heterodoxy."
            },
            {
                "spelling_detail_id": 60493,
                "word_type": "n.",
                "spelling_usage": "An offense against Christianity, consisting in a denial of\n   some essential doctrine, which denial is publicly avowed, and\n   obstinately maintained."
            }
        ]
    },
    {
        "id": 37869,
        "spelling_name": "Heretic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 60494,
                "word_type": "n.",
                "spelling_usage": "One who holds to a heresy; one who believes some doctrine\n   contrary to the established faith or prevailing religion."
            },
            {
                "spelling_detail_id": 60495,
                "word_type": "n.",
                "spelling_usage": "One who having made a profession of Christian belief,\n   deliberately and pertinaciously refuses to believe one or more of the\n   articles of faith \"determined by the authority of the universal\n   church.\""
            }
        ]
    },
    {
        "id": 37905,
        "spelling_name": "Hermetic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 60536,
                "word_type": "a.",
                "spelling_usage": "Alt. of Hermetical"
            }
        ]
    },
    {
        "id": 38031,
        "spelling_name": "Heterogeneous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 60699,
                "word_type": "a.",
                "spelling_usage": "Differing in kind; having unlike qualities;\n   possessed of different characteristics; dissimilar; -- opposed to\n   homogeneous, and said of two or more connected objects, or of a\n   conglomerate mass, considered in respect to the parts of which it is\n   made up."
            }
        ]
    },
    {
        "id": 38096,
        "spelling_name": "Hew",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 60778,
                "word_type": "v. t.",
                "spelling_usage": "To cut with an ax; to fell with a sharp instrument; --\n   often with down, or off."
            },
            {
                "spelling_detail_id": 60779,
                "word_type": "v. t.",
                "spelling_usage": "To form or shape with a sharp instrument; to cut; hence, to\n   form laboriously; -- often with out; as, to hew out a sepulcher."
            },
            {
                "spelling_detail_id": 60780,
                "word_type": "v. t.",
                "spelling_usage": "To cut in pieces; to chop; to hack."
            },
            {
                "spelling_detail_id": 60781,
                "word_type": "n.",
                "spelling_usage": "Destruction by cutting down."
            },
            {
                "spelling_detail_id": 60782,
                "word_type": "n.",
                "spelling_usage": "Hue; color."
            },
            {
                "spelling_detail_id": 60783,
                "word_type": "n.",
                "spelling_usage": "Shape; form."
            }
        ]
    },
    {
        "id": 38441,
        "spelling_name": "Hirsute",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 61257,
                "word_type": "a.",
                "spelling_usage": "Rough with hair; set with bristles; shaggy."
            },
            {
                "spelling_detail_id": 61258,
                "word_type": "a.",
                "spelling_usage": "Rough and coarse; boorish."
            },
            {
                "spelling_detail_id": 61259,
                "word_type": "a.",
                "spelling_usage": "Pubescent with coarse or stiff hairs."
            },
            {
                "spelling_detail_id": 61260,
                "word_type": "a.",
                "spelling_usage": "Covered with hairlike feathers, as the feet of certain\n   birds."
            }
        ]
    },
    {
        "id": 38544,
        "spelling_name": "Hoax",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 61431,
                "word_type": "n.",
                "spelling_usage": "A deception for mockery or mischief; a deceptive trick or\n   story; a practical joke."
            },
            {
                "spelling_detail_id": 61434,
                "word_type": "v. t.",
                "spelling_usage": "To deceive by a story or a trick, for sport or mischief;\n   to impose upon sportively."
            }
        ]
    },
    {
        "id": 38683,
        "spelling_name": "Hollow",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 61686,
                "word_type": "a.",
                "spelling_usage": "Having an empty space or cavity, natural or artificial,\n   within a solid substance; not solid; excavated in the interior; as, a\n   hollow tree; a hollow sphere."
            },
            {
                "spelling_detail_id": 61687,
                "word_type": "a.",
                "spelling_usage": "Depressed; concave; gaunt; sunken."
            },
            {
                "spelling_detail_id": 61688,
                "word_type": "a.",
                "spelling_usage": "Reverberated from a cavity, or resembling such a sound;\n   deep; muffled; as, a hollow roar."
            },
            {
                "spelling_detail_id": 61689,
                "word_type": "a.",
                "spelling_usage": "Not sincere or faithful; false; deceitful; not sound; as, a\n   hollow heart; a hollow friend."
            },
            {
                "spelling_detail_id": 61690,
                "word_type": "n.",
                "spelling_usage": "A cavity, natural or artificial; an unfilled space within\n   anything; a hole, a cavern; an excavation; as the hollow of the hand or\n   of a tree."
            },
            {
                "spelling_detail_id": 61691,
                "word_type": "n.",
                "spelling_usage": "A low spot surrounded by elevations; a depressed part of a\n   surface; a concavity; a channel."
            },
            {
                "spelling_detail_id": 61694,
                "word_type": "v. t.",
                "spelling_usage": "To make hollow, as by digging, cutting, or engraving; to\n   excavate."
            },
            {
                "spelling_detail_id": 61695,
                "word_type": "adv.",
                "spelling_usage": "Wholly; completely; utterly; -- chiefly after the verb to\n   beat, and often with all; as, this story beats the other all hollow.\n   See All, adv."
            },
            {
                "spelling_detail_id": 61696,
                "word_type": "interj.",
                "spelling_usage": "Hollo."
            },
            {
                "spelling_detail_id": 61697,
                "word_type": "v. i.",
                "spelling_usage": "To shout; to hollo."
            },
            {
                "spelling_detail_id": 61698,
                "word_type": "v. t.",
                "spelling_usage": "To urge or call by shouting."
            }
        ]
    },
    {
        "id": 38733,
        "spelling_name": "Holster",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 61755,
                "word_type": "n.",
                "spelling_usage": "A leather case for a pistol, carried by a horseman at the\n   bow of his saddle."
            }
        ]
    },
    {
        "id": 38792,
        "spelling_name": "Homiletics",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 61853,
                "word_type": "n.",
                "spelling_usage": "The art of preaching; that branch of theology which\n   treats of homilies or sermons, and the best method of preparing and\n   delivering them."
            }
        ]
    },
    {
        "id": 38913,
        "spelling_name": "Hone",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 61999,
                "word_type": "v. i.",
                "spelling_usage": "To pine; to lament; to long."
            },
            {
                "spelling_detail_id": 62000,
                "word_type": "n.",
                "spelling_usage": "A kind of swelling in the cheek."
            },
            {
                "spelling_detail_id": 62001,
                "word_type": "n.",
                "spelling_usage": "A stone of a fine grit, or a slab, as of metal, covered with\n   an abrading substance or powder, used for sharpening cutting\n   instruments, and especially for setting razors; an oilstone."
            },
            {
                "spelling_detail_id": 62004,
                "word_type": "v. t.",
                "spelling_usage": "To sharpen on, or with, a hone; to rub on a hone in order\n   to sharpen; as, to hone a razor."
            }
        ]
    },
    {
        "id": 38970,
        "spelling_name": "Hoodwink",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 62127,
                "word_type": "v. t.",
                "spelling_usage": "To blind by covering the eyes."
            },
            {
                "spelling_detail_id": 62128,
                "word_type": "v. t.",
                "spelling_usage": "To cover; to hide."
            },
            {
                "spelling_detail_id": 62129,
                "word_type": "v. t.",
                "spelling_usage": "To deceive by false appearance; to impose upon."
            }
        ]
    },
    {
        "id": 39189,
        "spelling_name": "Hospitable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 62507,
                "word_type": "a.",
                "spelling_usage": "Receiving and entertaining strangers or guests with\n   kindness and without reward; kind to strangers and guests;\n   characterized by hospitality."
            },
            {
                "spelling_detail_id": 62508,
                "word_type": "a.",
                "spelling_usage": "Proceeding from or indicating kindness and generosity\n   to guests and strangers; as, hospitable rites."
            }
        ]
    },
    {
        "id": 39605,
        "spelling_name": "Hush",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 63249,
                "word_type": "v. t.",
                "spelling_usage": "To still; to silence; to calm; to make quiet; to repress\n   the noise or clamor of."
            },
            {
                "spelling_detail_id": 63250,
                "word_type": "v. t.",
                "spelling_usage": "To appease; to allay; to calm; to soothe."
            },
            {
                "spelling_detail_id": 63251,
                "word_type": "v. i.",
                "spelling_usage": "To become or to keep still or quiet; to become silent; --\n   esp. used in the imperative, as an exclamation; be still; be silent or\n   quiet; make no noise."
            },
            {
                "spelling_detail_id": 63252,
                "word_type": "n.",
                "spelling_usage": "Stillness; silence; quiet."
            },
            {
                "spelling_detail_id": 63253,
                "word_type": "a.",
                "spelling_usage": "Silent; quiet."
            }
        ]
    },
    {
        "id": 39609,
        "spelling_name": "Husk",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 63256,
                "word_type": "n.",
                "spelling_usage": "The external covering or envelope of certain fruits or seeds;\n   glume; hull; rind; in the United States, especially applied to the\n   covering of the ears of maize."
            },
            {
                "spelling_detail_id": 63257,
                "word_type": "n.",
                "spelling_usage": "The supporting frame of a run of millstones."
            },
            {
                "spelling_detail_id": 63260,
                "word_type": "v. t.",
                "spelling_usage": "To strip off the external covering or envelope of; as, to\n   husk Indian corn."
            }
        ]
    },
    {
        "id": 40125,
        "spelling_name": "Hypocrisy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 63872,
                "word_type": "n.",
                "spelling_usage": "The act or practice of a hypocrite; a feigning to be\n   what one is not, or to feel what one does not feel; a dissimulation, or\n   a concealment of one's real character, disposition, or motives;\n   especially, the assuming of false appearance of virtue or religion; a\n   simulation of goodness."
            }
        ]
    },
    {
        "id": 40371,
        "spelling_name": "Iconoclast",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64159,
                "word_type": "n.",
                "spelling_usage": "A breaker or destroyer of images or idols; a determined\n   enemy of idol worship."
            },
            {
                "spelling_detail_id": 64160,
                "word_type": "n.",
                "spelling_usage": "One who exposes or destroys impositions or shams; one\n   who attacks cherished beliefs; a radical."
            }
        ]
    },
    {
        "id": 40482,
        "spelling_name": "Idiosyncrasy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64314,
                "word_type": "n.",
                "spelling_usage": "A peculiarity of physical or mental constitution or\n   temperament; a characteristic belonging to, and distinguishing, an\n   individual; characteristic susceptibility; idiocrasy; eccentricity."
            }
        ]
    },
    {
        "id": 40519,
        "spelling_name": "Idolatry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64371,
                "word_type": "n.",
                "spelling_usage": "The worship of idols, images, or anything which is not\n   God; the worship of false gods."
            },
            {
                "spelling_detail_id": 64372,
                "word_type": "n.",
                "spelling_usage": "Excessive attachment or veneration for anything; respect\n   or love which borders on adoration."
            }
        ]
    },
    {
        "id": 40565,
        "spelling_name": "Ignoble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64429,
                "word_type": "a.",
                "spelling_usage": "Of low birth or family; not noble; not illustrious;\n   plebeian; common; humble."
            },
            {
                "spelling_detail_id": 64430,
                "word_type": "a.",
                "spelling_usage": "Not honorable, elevated, or generous; base."
            },
            {
                "spelling_detail_id": 64431,
                "word_type": "a.",
                "spelling_usage": "Not a true or noble falcon; -- said of certain hawks, as\n   the goshawk."
            },
            {
                "spelling_detail_id": 64432,
                "word_type": "v. t.",
                "spelling_usage": "To make ignoble."
            }
        ]
    },
    {
        "id": 40569,
        "spelling_name": "Ignominious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64435,
                "word_type": "a.",
                "spelling_usage": "Marked with ignominy; in curring public disgrace;\n   dishonorable; shameful."
            },
            {
                "spelling_detail_id": 64436,
                "word_type": "a.",
                "spelling_usage": "Deserving ignominy; despicable."
            },
            {
                "spelling_detail_id": 64437,
                "word_type": "a.",
                "spelling_usage": "Humiliating; degrading; as, an ignominious judgment or\n   sentence."
            }
        ]
    },
    {
        "id": 40681,
        "spelling_name": "Illicit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64571,
                "word_type": "a.",
                "spelling_usage": "Not permitted or allowed; prohibited; unlawful; as,\n   illicit trade; illicit intercourse; illicit pleasure."
            }
        ]
    },
    {
        "id": 40812,
        "spelling_name": "Imbibe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64786,
                "word_type": "v. t.",
                "spelling_usage": "To drink in; to absorb; to suck or take in; to receive\n   as by drinking; as, a person imbibes drink, or a sponge imbibes\n   moisture."
            },
            {
                "spelling_detail_id": 64787,
                "word_type": "v. t.",
                "spelling_usage": "To receive or absorb into the mind and retain; as, to\n   imbibe principles; to imbibe errors."
            },
            {
                "spelling_detail_id": 64788,
                "word_type": "v. t.",
                "spelling_usage": "To saturate; to imbue."
            }
        ]
    },
    {
        "id": 40857,
        "spelling_name": "Imbroglio",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64837,
                "word_type": "n.",
                "spelling_usage": "An intricate, complicated plot, as of a drama or work of\n   fiction."
            },
            {
                "spelling_detail_id": 64838,
                "word_type": "n.",
                "spelling_usage": "A complicated and embarrassing state of things; a\n   serious misunderstanding."
            }
        ]
    },
    {
        "id": 40892,
        "spelling_name": "Immaculate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64884,
                "word_type": "a.",
                "spelling_usage": "Without stain or blemish; spotless; undefiled; clear;\n   pure."
            }
        ]
    },
    {
        "id": 40982,
        "spelling_name": "Imminent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 64994,
                "word_type": "a.",
                "spelling_usage": "Threatening to occur immediately; near at hand;\n   impending; -- said especially of misfortune or peril."
            },
            {
                "spelling_detail_id": 64995,
                "word_type": "a.",
                "spelling_usage": "Full of danger; threatening; menacing; perilous."
            },
            {
                "spelling_detail_id": 64996,
                "word_type": "a.",
                "spelling_usage": "(With upon) Bent upon; attentive to."
            }
        ]
    },
    {
        "id": 41052,
        "spelling_name": "Immutable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65084,
                "word_type": "a.",
                "spelling_usage": "Not mutable; not capable or susceptible of change;\n   unchangeable; unalterable."
            }
        ]
    },
    {
        "id": 41065,
        "spelling_name": "Impair",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65109,
                "word_type": "v. t.",
                "spelling_usage": "To make worse; to diminish in quantity, value,\n   excellence, or strength; to deteriorate; as, to impair health,\n   character, the mind, value."
            },
            {
                "spelling_detail_id": 65110,
                "word_type": "v. t.",
                "spelling_usage": "To grow worse; to deteriorate."
            },
            {
                "spelling_detail_id": 65111,
                "word_type": "a.",
                "spelling_usage": "Not fit or appropriate."
            },
            {
                "spelling_detail_id": 65112,
                "word_type": "n.",
                "spelling_usage": "Diminution; injury."
            }
        ]
    },
    {
        "id": 41130,
        "spelling_name": "Impassive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65197,
                "word_type": "a.",
                "spelling_usage": "Not susceptible of pain or suffering; apathetic;\n   impassible; unmoved."
            }
        ]
    },
    {
        "id": 41167,
        "spelling_name": "Impecunious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65248,
                "word_type": "a.",
                "spelling_usage": "Not having money; habitually without money; poor."
            }
        ]
    },
    {
        "id": 41169,
        "spelling_name": "Impede",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65251,
                "word_type": "v. t.",
                "spelling_usage": "To hinder; to stop in progress; to obstruct; as, to\n   impede the advance of troops."
            }
        ]
    },
    {
        "id": 41172,
        "spelling_name": "Impediment",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65253,
                "word_type": "n.",
                "spelling_usage": "That which impedes or hinders progress, motion,\n   activity, or effect."
            },
            {
                "spelling_detail_id": 65254,
                "word_type": "v. t.",
                "spelling_usage": "To impede."
            }
        ]
    },
    {
        "id": 41189,
        "spelling_name": "Impending",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65272,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Impend"
            },
            {
                "spelling_detail_id": 65277,
                "word_type": "a.",
                "spelling_usage": "Hanging over; overhanging; suspended so as to menace;\n   imminet; threatening."
            }
        ]
    },
    {
        "id": 41210,
        "spelling_name": "Imperative",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65301,
                "word_type": "a.",
                "spelling_usage": "Expressive of command; containing positive command;\n   authoritatively or absolutely directive; commanding; authoritative; as,\n   imperative orders."
            },
            {
                "spelling_detail_id": 65302,
                "word_type": "a.",
                "spelling_usage": "Not to be avoided or evaded; obligatory; binding;\n   compulsory; as, an imperative duty or order."
            },
            {
                "spelling_detail_id": 65303,
                "word_type": "a.",
                "spelling_usage": "Expressive of commund, entreaty, advice, or\n   exhortation; as, the imperative mood."
            },
            {
                "spelling_detail_id": 65304,
                "word_type": "n.",
                "spelling_usage": "The imperative mood; also, a verb in the imperative\n   mood."
            }
        ]
    },
    {
        "id": 41250,
        "spelling_name": "Imperious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65359,
                "word_type": "a.",
                "spelling_usage": "Commanding; ascendant; imperial; lordly; majestic."
            },
            {
                "spelling_detail_id": 65360,
                "word_type": "a.",
                "spelling_usage": "Haughly; arrogant; overbearing; as, an imperious tyrant;\n   an imperious manner."
            },
            {
                "spelling_detail_id": 65361,
                "word_type": "a.",
                "spelling_usage": "Imperative; urgent; compelling."
            }
        ]
    },
    {
        "id": 41260,
        "spelling_name": "Impermeable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65371,
                "word_type": "a.",
                "spelling_usage": "Not permeable; not permitting passage, as of a fluid.\n   through its substance; impervious; impenetrable; as, India rubber is\n   impermeable to water and to air."
            }
        ]
    },
    {
        "id": 41289,
        "spelling_name": "Impervious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65408,
                "word_type": "a.",
                "spelling_usage": "Not pervious; not admitting of entrance or passage\n   through; as, a substance impervious to water or air."
            }
        ]
    },
    {
        "id": 41303,
        "spelling_name": "Impetuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65425,
                "word_type": "a.",
                "spelling_usage": "Rushing with force and violence; moving with impetus;\n   furious; forcible; violent; as, an impetuous wind; an impetuous\n   torrent."
            },
            {
                "spelling_detail_id": 65426,
                "word_type": "a.",
                "spelling_usage": "Vehement in feeling; hasty; passionate; violent; as, a\n   man of impetuous temper."
            }
        ]
    },
    {
        "id": 41311,
        "spelling_name": "Impiety",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65436,
                "word_type": "n.",
                "spelling_usage": "The quality of being impious; want of piety; irreverence\n   toward the Supreme Being; ungodliness; wickedness."
            },
            {
                "spelling_detail_id": 65437,
                "word_type": "n.",
                "spelling_usage": "An impious act; an act of wickednes."
            }
        ]
    },
    {
        "id": 41326,
        "spelling_name": "Implacable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65455,
                "word_type": "a.",
                "spelling_usage": "Not placable; not to be appeased; incapable of being\n   pacified; inexorable; as, an implacable prince."
            },
            {
                "spelling_detail_id": 65456,
                "word_type": "a.",
                "spelling_usage": "Incapable of ebign relieved or assuaged;\n   inextinguishable."
            }
        ]
    },
    {
        "id": 41354,
        "spelling_name": "Implicate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65492,
                "word_type": "v. t.",
                "spelling_usage": "To infold; to fold together; to interweave."
            },
            {
                "spelling_detail_id": 65493,
                "word_type": "v. t.",
                "spelling_usage": "To bring into connection with; to involve; to\n   connect; -- applied to persons, in an unfavorable sense; as, the\n   evidence implicates many in this conspiracy; to be implicated in a\n   crime, a discreditable transaction, a fault, etc."
            }
        ]
    },
    {
        "id": 41360,
        "spelling_name": "Implicit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65498,
                "word_type": "a.",
                "spelling_usage": "Infolded; entangled; complicated; involved."
            },
            {
                "spelling_detail_id": 65499,
                "word_type": "a.",
                "spelling_usage": "Tacitly comprised; fairly to be understood, though not\n   expressed in words; implied; as, an implicit contract or agreement."
            },
            {
                "spelling_detail_id": 65500,
                "word_type": "a.",
                "spelling_usage": "Resting on another; trusting in the word or authority of\n   another, without doubt or reserve; unquestioning; complete; as,\n   implicit confidence; implicit obedience."
            }
        ]
    },
    {
        "id": 41375,
        "spelling_name": "Implosion",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65519,
                "word_type": "n.",
                "spelling_usage": "A burstion inwards, as of a vessel from which the air\n   has been exhausted; -- contrasted with explosion."
            },
            {
                "spelling_detail_id": 65520,
                "word_type": "n.",
                "spelling_usage": "A sudden compression of the air in the mouth,\n   simultaneously with and affecting the sound made by the closure of the\n   organs in uttering p, t, or k, at the end of a syllable (see Guide to\n   Pronunciation, //159, 189); also, a similar compression made by an\n   upward thrust of the larynx without any accompanying explosive action,\n   as in the peculiar sound of b, d, and g, heard in Southern Germany."
            }
        ]
    },
    {
        "id": 41418,
        "spelling_name": "Importune",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65589,
                "word_type": "a.",
                "spelling_usage": "To request or solicit, with urgency; to press with\n   frequent, unreasonable, or troublesome application or pertinacity;\n   hence, to tease; to irritate; to worry."
            },
            {
                "spelling_detail_id": 65590,
                "word_type": "a.",
                "spelling_usage": "To import; to signify."
            },
            {
                "spelling_detail_id": 65591,
                "word_type": "v. i.",
                "spelling_usage": "To require; to demand."
            }
        ]
    },
    {
        "id": 41482,
        "spelling_name": "Imprecation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65686,
                "word_type": "n.",
                "spelling_usage": "The act of imprecating, or invoking evil upon any one;\n   a prayer that a curse or calamity may fall on any one; a curse."
            }
        ]
    },
    {
        "id": 41554,
        "spelling_name": "Impromptu",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65797,
                "word_type": "adv. / a.",
                "spelling_usage": "Offhand; without previous study; extemporaneous;\n   extempore; as, an impromptu verse."
            },
            {
                "spelling_detail_id": 65798,
                "word_type": "n.",
                "spelling_usage": "Something made or done offhand, at the moment, or\n   without previous study; an extemporaneous composition, address, or\n   remark."
            },
            {
                "spelling_detail_id": 65799,
                "word_type": "n.",
                "spelling_usage": "A piece composed or played at first thought; a\n   composition in the style of an extempore piece."
            }
        ]
    },
    {
        "id": 41612,
        "spelling_name": "Impudent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65886,
                "word_type": "a.",
                "spelling_usage": "Bold, with contempt or disregard; unblushingly forward;\n   impertinent; wanting modesty; shameless; saucy."
            }
        ]
    },
    {
        "id": 41618,
        "spelling_name": "Impugned",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65889,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Impugn"
            }
        ]
    },
    {
        "id": 41650,
        "spelling_name": "Impute",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 65945,
                "word_type": "v. t.",
                "spelling_usage": "To charge; to ascribe; to attribute; to set to the\n   account of; to charge to one as the author, responsible originator, or\n   possessor; -- generally in a bad sense."
            },
            {
                "spelling_detail_id": 65946,
                "word_type": "v. t.",
                "spelling_usage": "To adjudge as one's own (the sin or righteousness) of\n   another; as, the righteousness of Christ is imputed to us."
            },
            {
                "spelling_detail_id": 65947,
                "word_type": "v. t.",
                "spelling_usage": "To take account of; to consider; to regard."
            }
        ]
    },
    {
        "id": 41701,
        "spelling_name": "Inadvertent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66007,
                "word_type": "a.",
                "spelling_usage": "Not turning the mind to a matter; heedless; careless;\n   negligent; inattentive."
            }
        ]
    },
    {
        "id": 41722,
        "spelling_name": "Inane",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66030,
                "word_type": "a.",
                "spelling_usage": "Without contents; empty; void of sense or intelligence;\n   purposeless; pointless; characterless; useless."
            },
            {
                "spelling_detail_id": 66031,
                "word_type": "n.",
                "spelling_usage": "That which is void or empty."
            }
        ]
    },
    {
        "id": 41876,
        "spelling_name": "Incense",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66233,
                "word_type": "v. t.",
                "spelling_usage": "To set on fire; to inflame; to kindle; to burn."
            },
            {
                "spelling_detail_id": 66234,
                "word_type": "v. t.",
                "spelling_usage": "To inflame with anger; to endkindle; to fire; to\n   incite; to provoke; to heat; to madden."
            },
            {
                "spelling_detail_id": 66237,
                "word_type": "n.",
                "spelling_usage": "To offer incense to. See Incense."
            },
            {
                "spelling_detail_id": 66238,
                "word_type": "n.",
                "spelling_usage": "To perfume with, or as with, incense."
            },
            {
                "spelling_detail_id": 66239,
                "word_type": "n.",
                "spelling_usage": "The perfume or odors exhaled from spices and gums when\n   burned in celebrating religious rites or as an offering to some deity."
            },
            {
                "spelling_detail_id": 66240,
                "word_type": "n.",
                "spelling_usage": "The materials used for the purpose of producing a perfume\n   when burned, as fragrant gums, spices, frankincense, etc."
            },
            {
                "spelling_detail_id": 66241,
                "word_type": "n.",
                "spelling_usage": "Also used figuratively."
            }
        ]
    },
    {
        "id": 41902,
        "spelling_name": "Incessant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66272,
                "word_type": "a.",
                "spelling_usage": "Continuing or following without interruption; unceasing;\n   unitermitted; uninterrupted; continual; as, incessant clamors;\n   incessant pain, etc."
            }
        ]
    },
    {
        "id": 41922,
        "spelling_name": "Inchoate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66300,
                "word_type": "a.",
                "spelling_usage": "Recently, or just, begun; beginning; partially but not\n   fully in existence or operation; existing in its elements; incomplete."
            },
            {
                "spelling_detail_id": 66301,
                "word_type": "v. t.",
                "spelling_usage": "To begin."
            }
        ]
    },
    {
        "id": 41941,
        "spelling_name": "Incipient",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66330,
                "word_type": "a.",
                "spelling_usage": "Beginning to be, or to show itself; commencing; initial;\n   as, the incipient stage of a fever; incipient light of day."
            }
        ]
    },
    {
        "id": 41948,
        "spelling_name": "Incise",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66339,
                "word_type": "v. t.",
                "spelling_usage": "To cut in or into with a sharp instrument; to carve; to\n   engrave."
            },
            {
                "spelling_detail_id": 66340,
                "word_type": "v. t.",
                "spelling_usage": "To cut, gash, or wound with a sharp instrument; to cut\n   off."
            }
        ]
    },
    {
        "id": 41960,
        "spelling_name": "Incite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66360,
                "word_type": "v. t.",
                "spelling_usage": "To move to action; to stir up; to rouse; to spur or urge\n   on."
            }
        ]
    },
    {
        "id": 41988,
        "spelling_name": "Inclined",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66398,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Incline"
            },
            {
                "spelling_detail_id": 66407,
                "word_type": "p. p. & a.",
                "spelling_usage": "Having a leaning or tendency towards, or away\n   from, a thing; disposed or moved by wish, desire, or judgment; as, a\n   man inclined to virtue."
            },
            {
                "spelling_detail_id": 66408,
                "word_type": "p. p. & a.",
                "spelling_usage": "Making an angle with some line or plane; -- said\n   of a line or plane."
            },
            {
                "spelling_detail_id": 66409,
                "word_type": "p. p. & a.",
                "spelling_usage": "Bent out of a perpendicular position, or into a\n   curve with the convex side uppermost."
            }
        ]
    },
    {
        "id": 42146,
        "spelling_name": "Incongruous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66607,
                "word_type": "a.",
                "spelling_usage": "Not congruous; reciprocally disagreeing; not capable\n   of harmonizing or readily assimilating; inharmonious; inappropriate;\n   unsuitable; not fitting; inconsistent; improper; as, an incongruous\n   remark; incongruous behavior, action, dress, etc."
            }
        ]
    },
    {
        "id": 42236,
        "spelling_name": "Incorrigibility",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66726,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being incorrigible."
            }
        ]
    },
    {
        "id": 42272,
        "spelling_name": "Incredulous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66786,
                "word_type": "a.",
                "spelling_usage": "Not credulous; indisposed to admit or accept that\n   which is related as true, skeptical; unbelieving."
            },
            {
                "spelling_detail_id": 66787,
                "word_type": "a.",
                "spelling_usage": "Indicating, or caused by, disbelief or incredulity."
            },
            {
                "spelling_detail_id": 66788,
                "word_type": "a.",
                "spelling_usage": "Incredible; not easy to be believed."
            }
        ]
    },
    {
        "id": 42310,
        "spelling_name": "Inculcate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66841,
                "word_type": "v. t.",
                "spelling_usage": "To teach and impress by frequent repetitions or\n   admonitions; to urge on the mind; as, Christ inculcates on his\n   followers humility."
            }
        ]
    },
    {
        "id": 42355,
        "spelling_name": "Incursion",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66896,
                "word_type": "n.",
                "spelling_usage": "A running into; hence, an entering into a territory with\n   hostile intention; a temporary invasion; a predatory or harassing\n   inroad; a raid."
            },
            {
                "spelling_detail_id": 66897,
                "word_type": "n.",
                "spelling_usage": "Attack; occurrence."
            }
        ]
    },
    {
        "id": 42410,
        "spelling_name": "Indefatigability",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 66968,
                "word_type": "n.",
                "spelling_usage": "The state of being indefatigable."
            }
        ]
    },
    {
        "id": 42439,
        "spelling_name": "Indelible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67000,
                "word_type": "a.",
                "spelling_usage": "That can not be removed, washed away, blotted out, or\n   effaced; incapable of being canceled, lost, or forgotten; as, indelible\n   characters; an indelible stain; an indelible impression on the memory."
            },
            {
                "spelling_detail_id": 67001,
                "word_type": "a.",
                "spelling_usage": "That can not be annulled; indestructible."
            }
        ]
    },
    {
        "id": 42545,
        "spelling_name": "Indigence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67192,
                "word_type": "n.",
                "spelling_usage": "The condition of being indigent; want of estate, or\n   means of comfortable subsistence; penury; poverty; as, helpless,\n   indigence."
            }
        ]
    },
    {
        "id": 42548,
        "spelling_name": "Indigenous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67195,
                "word_type": "a.",
                "spelling_usage": "Native; produced, growing, or living, naturally in a\n   country or climate; not exotic; not imported."
            },
            {
                "spelling_detail_id": 67196,
                "word_type": "a.",
                "spelling_usage": "Native; inherent; innate."
            }
        ]
    },
    {
        "id": 42632,
        "spelling_name": "Indistinct",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67305,
                "word_type": "a.",
                "spelling_usage": "Not distinct or distinguishable; not separate in such a\n   manner as to be perceptible by itself; as, the indistinct parts of a\n   substance."
            },
            {
                "spelling_detail_id": 67306,
                "word_type": "a.",
                "spelling_usage": "Obscure to the mind or senses; not clear; not definite;\n   confused; imperfect; faint; as, indistinct vision; an indistinct sound;\n   an indistinct idea or recollection."
            }
        ]
    },
    {
        "id": 42695,
        "spelling_name": "Indolence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67384,
                "word_type": "n.",
                "spelling_usage": "Freedom from that which pains, or harasses, as toil,\n   care, grief, etc."
            },
            {
                "spelling_detail_id": 67385,
                "word_type": "n.",
                "spelling_usage": "The quality or condition of being indolent; inaction, or\n   want of exertion of body or mind, proceeding from love of ease or\n   aversion to toil; habitual idleness; indisposition to labor; laziness;\n   sloth; inactivity."
            }
        ]
    },
    {
        "id": 42702,
        "spelling_name": "Indomitable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67394,
                "word_type": "a.",
                "spelling_usage": "Not to be subdued; untamable; invincible; as, an\n   indomitable will, courage, animal."
            }
        ]
    },
    {
        "id": 42758,
        "spelling_name": "Indulge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67482,
                "word_type": "v. t.",
                "spelling_usage": "To be complacent toward; to give way to; not to oppose\n   or restrain"
            },
            {
                "spelling_detail_id": 67483,
                "word_type": "v. t.",
                "spelling_usage": "to give free course to; to give one's self up to; as,\n   to indulge sloth, pride, selfishness, or inclinations;"
            },
            {
                "spelling_detail_id": 67484,
                "word_type": "v. t.",
                "spelling_usage": "to yield to the desire of; to gratify by compliance; to\n   humor; to withhold restraint from; as, to indulge children in their\n   caprices or willfulness; to indulge one's self with a rest or in\n   pleasure."
            },
            {
                "spelling_detail_id": 67485,
                "word_type": "v. t.",
                "spelling_usage": "To grant as by favor; to bestow in concession, or in\n   compliance with a wish or request."
            },
            {
                "spelling_detail_id": 67486,
                "word_type": "v. i.",
                "spelling_usage": "To indulge one's self; to gratify one's tastes or\n   desires; esp., to give one's self up (to); to practice a forbidden or\n   questionable act without restraint; -- followed by in, but formerly,\n   also, by to."
            }
        ]
    },
    {
        "id": 42763,
        "spelling_name": "Indulgent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67493,
                "word_type": "a.",
                "spelling_usage": "Prone to indulge; yielding to the wishes, humor, or\n   appetites of those under one's care; compliant; not opposing or\n   restraining; tolerant; mild; favorable; not severe; as, an indulgent\n   parent."
            }
        ]
    },
    {
        "id": 42809,
        "spelling_name": "Ineffable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67563,
                "word_type": "a.",
                "spelling_usage": "Incapable of being expresses in words; unspeakable;\n   unutterable; indescribable; as, the ineffable joys of heaven."
            }
        ]
    },
    {
        "id": 42847,
        "spelling_name": "Ineluctable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67603,
                "word_type": "a.",
                "spelling_usage": "Not to be overcome by struggling; irresistible;\n   inevitable."
            }
        ]
    },
    {
        "id": 42850,
        "spelling_name": "Inept",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67607,
                "word_type": "a.",
                "spelling_usage": "Not apt or fit; unfit; unsuitable; improper; unbecoming."
            },
            {
                "spelling_detail_id": 67608,
                "word_type": "a.",
                "spelling_usage": "Silly; useless; nonsensical; absurd; foolish."
            }
        ]
    },
    {
        "id": 42851,
        "spelling_name": "Ineptitude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 67609,
                "word_type": "n.",
                "spelling_usage": "The quality of being inept; unfitness; inaptitude;\n   unsuitableness."
            },
            {
                "spelling_detail_id": 67610,
                "word_type": "n.",
                "spelling_usage": "Absurdity; nonsense; foolishness."
            }
        ]
    },
    {
        "id": 43267,
        "spelling_name": "Infuriate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68203,
                "word_type": "v. t.",
                "spelling_usage": "Enraged; rading; furiously angry; infuriated."
            },
            {
                "spelling_detail_id": 68206,
                "word_type": "v. t.",
                "spelling_usage": "To render furious; to enrage; to exasperate."
            }
        ]
    },
    {
        "id": 43273,
        "spelling_name": "Infuse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68213,
                "word_type": "v. t.",
                "spelling_usage": "To pour in, as a liquid; to pour (into or upon); to\n   shed."
            },
            {
                "spelling_detail_id": 68214,
                "word_type": "v. t.",
                "spelling_usage": "To instill, as principles or qualities; to introduce."
            },
            {
                "spelling_detail_id": 68215,
                "word_type": "v. t.",
                "spelling_usage": "To inspire; to inspirit or animate; to fill; -- followed\n   by with."
            },
            {
                "spelling_detail_id": 68216,
                "word_type": "v. t.",
                "spelling_usage": "To steep in water or other fluid without boiling, for\n   the propose of extracting medicinal qualities; to soak."
            },
            {
                "spelling_detail_id": 68217,
                "word_type": "v. t.",
                "spelling_usage": "To make an infusion with, as an ingredient; to tincture;\n   to saturate."
            },
            {
                "spelling_detail_id": 68218,
                "word_type": "n.",
                "spelling_usage": "Infusion."
            }
        ]
    },
    {
        "id": 43315,
        "spelling_name": "Ingenuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68276,
                "word_type": "a.",
                "spelling_usage": "Of honorable extraction; freeborn; noble; as, ingenuous\n   blood of birth."
            },
            {
                "spelling_detail_id": 68277,
                "word_type": "a.",
                "spelling_usage": "Noble; generous; magnanimous; honorable; upright;\n   high-minded; as, an ingenuous ardor or zeal."
            },
            {
                "spelling_detail_id": 68278,
                "word_type": "a.",
                "spelling_usage": "Free from reserve, disguise, equivocation, or\n   dissimulation; open; frank; as, an ingenuous man; an ingenuous\n   declaration, confession, etc."
            },
            {
                "spelling_detail_id": 68279,
                "word_type": "a.",
                "spelling_usage": "Ingenious."
            }
        ]
    },
    {
        "id": 43320,
        "spelling_name": "Ingest",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68285,
                "word_type": "v. t.",
                "spelling_usage": "To take into, or as into, the stomach or alimentary\n   canal."
            }
        ]
    },
    {
        "id": 43461,
        "spelling_name": "Inimical",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68483,
                "word_type": "a.",
                "spelling_usage": "Having the disposition or temper of an enemy; unfriendly;\n   unfavorable; -- chiefly applied to private, as hostile is to public,\n   enmity."
            },
            {
                "spelling_detail_id": 68484,
                "word_type": "a.",
                "spelling_usage": "Opposed in tendency, influence, or effects; antagonistic;\n   inconsistent; incompatible; adverse; repugnant."
            }
        ]
    },
    {
        "id": 43467,
        "spelling_name": "Inimitable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68490,
                "word_type": "a.",
                "spelling_usage": "Not capable of being imitated, copied, or\n   counterfeited; beyond imitation; surpassingly excellent; matchless;\n   unrivaled; exceptional; unique; as, an inimitable style; inimitable\n   eloquence."
            }
        ]
    },
    {
        "id": 43593,
        "spelling_name": "Innocuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68688,
                "word_type": "a.",
                "spelling_usage": "Harmless; producing no ill effect; innocent."
            }
        ]
    },
    {
        "id": 43676,
        "spelling_name": "Inquest",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68793,
                "word_type": "n.",
                "spelling_usage": "Inquiry; quest; search."
            },
            {
                "spelling_detail_id": 68794,
                "word_type": "n.",
                "spelling_usage": "Judicial inquiry; official examination, esp. before a\n   jury; as, a coroner's inquest in case of a sudden death."
            },
            {
                "spelling_detail_id": 68795,
                "word_type": "n.",
                "spelling_usage": "A body of men assembled under authority of law to inquire\n   into any matterm civil or criminal, particularly any case of violent or\n   sudden death; a jury, particularly a coroner's jury. The grand jury is\n   sometimes called the grand inquest. See under Grand."
            },
            {
                "spelling_detail_id": 68796,
                "word_type": "n.",
                "spelling_usage": "The finding of the jury upon such inquiry."
            }
        ]
    },
    {
        "id": 43765,
        "spelling_name": "Inscrutable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68914,
                "word_type": "a.",
                "spelling_usage": "Unsearchable; incapable of being searched into and\n   understood by inquiry or study; impossible or difficult to be explained\n   or accounted for satisfactorily; obscure; incomprehensible; as, an\n   inscrutable design or event."
            }
        ]
    },
    {
        "id": 43803,
        "spelling_name": "Insensible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 68965,
                "word_type": "a.",
                "spelling_usage": "Destitute of the power of feeling or perceiving;\n   wanting bodily sensibility."
            },
            {
                "spelling_detail_id": 68966,
                "word_type": "a.",
                "spelling_usage": "Not susceptible of emotion or passion; void of feeling;\n   apathetic; unconcerned; indifferent; as, insensible to danger, fear,\n   love, etc.; -- often used with of or to."
            },
            {
                "spelling_detail_id": 68967,
                "word_type": "a.",
                "spelling_usage": "Incapable of being perceived by the senses;\n   imperceptible. Hence: Progressing by imperceptible degrees; slow;\n   gradual; as, insensible motion."
            },
            {
                "spelling_detail_id": 68968,
                "word_type": "a.",
                "spelling_usage": "Not sensible or reasonable; meaningless."
            }
        ]
    },
    {
        "id": 43855,
        "spelling_name": "Insinuate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69048,
                "word_type": "v. t.",
                "spelling_usage": "To introduce gently or slowly, as by a winding or\n   narrow passage, or a gentle, persistent movement."
            },
            {
                "spelling_detail_id": 69049,
                "word_type": "v. t.",
                "spelling_usage": "To introduce artfully; to infuse gently; to instill."
            },
            {
                "spelling_detail_id": 69050,
                "word_type": "v. t.",
                "spelling_usage": "To hint; to suggest by remote allusion; -- often used\n   derogatorily; as, did you mean to insinuate anything?"
            },
            {
                "spelling_detail_id": 69051,
                "word_type": "v. t.",
                "spelling_usage": "To push or work (one's self), as into favor; to\n   introduce by slow, gentle, or artful means; to ingratiate; -- used\n   reflexively."
            },
            {
                "spelling_detail_id": 69052,
                "word_type": "v. i.",
                "spelling_usage": "To creep, wind, or flow in; to enter gently, slowly,\n   or imperceptibly, as into crevices."
            },
            {
                "spelling_detail_id": 69053,
                "word_type": "v. i.",
                "spelling_usage": "To ingratiate one's self; to obtain access or favor\n   by flattery or cunning."
            }
        ]
    },
    {
        "id": 43863,
        "spelling_name": "Insipid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69064,
                "word_type": "a.",
                "spelling_usage": "Wanting in the qualities which affect the organs of taste;\n   without taste or savor; vapid; tasteless; as, insipid drink or food."
            },
            {
                "spelling_detail_id": 69065,
                "word_type": "a.",
                "spelling_usage": "Wanting in spirit, life, or animation; uninteresting;\n   weak; vapid; flat; dull; heavy; as, an insipid woman; an insipid\n   composition."
            }
        ]
    },
    {
        "id": 43912,
        "spelling_name": "Insouciant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69137,
                "word_type": "a.",
                "spelling_usage": "Careless; heedless; indifferent; unconcerned."
            }
        ]
    },
    {
        "id": 44060,
        "spelling_name": "Insularity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69374,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being an island or consisting\n   of islands; insulation."
            },
            {
                "spelling_detail_id": 69375,
                "word_type": "n.",
                "spelling_usage": "Narrowness or illiberality of opinion; prejudice;\n   exclusiveness; as, the insularity of the Chinese or of the aristocracy."
            }
        ]
    },
    {
        "id": 44101,
        "spelling_name": "Insurrection",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69436,
                "word_type": "n.",
                "spelling_usage": "A rising against civil or political authority, or the\n   established government; open and active opposition to the execution of\n   law in a city or state."
            },
            {
                "spelling_detail_id": 69437,
                "word_type": "n.",
                "spelling_usage": "A rising in mass to oppose an enemy."
            }
        ]
    },
    {
        "id": 44358,
        "spelling_name": "Interdict",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69797,
                "word_type": "n.",
                "spelling_usage": "To forbid; to prohibit or debar; as, to interdict\n   intercourse with foreign nations."
            },
            {
                "spelling_detail_id": 69798,
                "word_type": "n.",
                "spelling_usage": "To lay under an interdict; to cut off from the enjoyment\n   of religious privileges, as a city, a church, an individual."
            },
            {
                "spelling_detail_id": 69799,
                "word_type": "n.",
                "spelling_usage": "A prohibitory order or decree; a prohibition."
            },
            {
                "spelling_detail_id": 69800,
                "word_type": "n.",
                "spelling_usage": "A prohibition of the pope, by which the clergy or laymen\n   are restrained from performing, or from attending, divine service, or\n   from administering the offices or enjoying the privileges of the\n   church."
            },
            {
                "spelling_detail_id": 69801,
                "word_type": "n.",
                "spelling_usage": "An order of the court of session, having the like\n   purpose and effect with a writ of injunction out of chancery in England\n   and America."
            }
        ]
    },
    {
        "id": 44408,
        "spelling_name": "Interim",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 69872,
                "word_type": "n.",
                "spelling_usage": "The meantime; time intervening; interval between events,\n   etc."
            },
            {
                "spelling_detail_id": 69873,
                "word_type": "n.",
                "spelling_usage": "A name given to each of three compromises made by the\n   emperor Charles V. of Germany for the sake of harmonizing the\n   connecting opinions of Protestants and Catholics."
            }
        ]
    },
    {
        "id": 44707,
        "spelling_name": "Intersperse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70278,
                "word_type": "v. t.",
                "spelling_usage": "To scatter or set here and there among other\n   things; to insert at intervals; as, to intersperse pictures in a book."
            },
            {
                "spelling_detail_id": 70279,
                "word_type": "v. t.",
                "spelling_usage": "To diversify or adorn with things set or scattered\n   at intervals; to place something at intervals in or among; as, to\n   intersperse a book with pictures."
            }
        ]
    },
    {
        "id": 44881,
        "spelling_name": "Intransigent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70512,
                "word_type": "a.",
                "spelling_usage": "Refusing compromise; uncompromising; irreconcilable."
            }
        ]
    },
    {
        "id": 44911,
        "spelling_name": "Intrepid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70549,
                "word_type": "a.",
                "spelling_usage": "Not trembling or shaking with fear; fearless; bold;\n   brave; undaunted; courageous; as, an intrepid soldier; intrepid spirit."
            }
        ]
    },
    {
        "id": 44962,
        "spelling_name": "Introspection",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70627,
                "word_type": "n.",
                "spelling_usage": "A view of the inside or interior; a looking inward;\n   specifically, the act or process of self-examination, or inspection of\n   one's own thoughts and feelings; the cognition which the mind has of\n   its own acts and states; self-consciousness; reflection."
            }
        ]
    },
    {
        "id": 44969,
        "spelling_name": "Introvert",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70638,
                "word_type": "v. t.",
                "spelling_usage": "To turn or bend inward."
            },
            {
                "spelling_detail_id": 70639,
                "word_type": "v. t.",
                "spelling_usage": "To look within; to introspect."
            }
        ]
    },
    {
        "id": 45020,
        "spelling_name": "Inundate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70706,
                "word_type": "v. t.",
                "spelling_usage": "To cover with a flood; to overflow; to deluge; to\n   flood; as, the river inundated the town."
            },
            {
                "spelling_detail_id": 70707,
                "word_type": "v. t.",
                "spelling_usage": "To fill with an overflowing abundance or superfluity;\n   as, the country was inundated with bills of credit."
            }
        ]
    },
    {
        "id": 45028,
        "spelling_name": "Inured",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70713,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Inure"
            }
        ]
    },
    {
        "id": 45072,
        "spelling_name": "Invective",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70776,
                "word_type": "a.",
                "spelling_usage": "Characterized by invection; critical; denunciatory;\n   satirical; abusive; railing."
            },
            {
                "spelling_detail_id": 70777,
                "word_type": "n.",
                "spelling_usage": "An expression which inveighs or rails against a person;\n   a severe or violent censure or reproach; something uttered or written,\n   intended to cast opprobrium, censure, or reproach on another; a harsh\n   or reproachful accusation; -- followed by against, having reference to\n   the person or thing affected; as an invective against tyranny."
            }
        ]
    },
    {
        "id": 45074,
        "spelling_name": "Inveigh",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70781,
                "word_type": "v. i.",
                "spelling_usage": "To declaim or rail (against some person or thing); to\n   utter censorious and bitter language; to attack with harsh criticism or\n   reproach, either spoken or written; to use invectives; -- with against;\n   as, to inveigh against character, conduct, manners, customs, morals, a\n   law, an abuse."
            }
        ]
    },
    {
        "id": 45136,
        "spelling_name": "Inveterate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70891,
                "word_type": "a.",
                "spelling_usage": "Old; long-established."
            },
            {
                "spelling_detail_id": 70892,
                "word_type": "a.",
                "spelling_usage": "Firmly established by long continuance; obstinate;\n   deep-rooted; of long standing; as, an inveterate disease; an inveterate\n   abuse."
            },
            {
                "spelling_detail_id": 70893,
                "word_type": "a.",
                "spelling_usage": "Having habits fixed by long continuance; confirmed;\n   habitual; as, an inveterate idler or smoker."
            },
            {
                "spelling_detail_id": 70894,
                "word_type": "a.",
                "spelling_usage": "Malignant; virulent; spiteful."
            },
            {
                "spelling_detail_id": 70895,
                "word_type": "v. t.",
                "spelling_usage": "To fix and settle by long continuance."
            }
        ]
    },
    {
        "id": 45152,
        "spelling_name": "Invincible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70913,
                "word_type": "a.",
                "spelling_usage": "Incapable of being conquered, overcome, or subdued;\n   unconquerable; insuperable; as, an invincible army, or obstacle."
            }
        ]
    },
    {
        "id": 45214,
        "spelling_name": "Involute",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 70997,
                "word_type": "a.",
                "spelling_usage": "Alt. of Involuted"
            },
            {
                "spelling_detail_id": 71001,
                "word_type": "n.",
                "spelling_usage": "A curve traced by the end of a string wound upon another\n   curve, or unwound from it; -- called also evolvent. See Evolute."
            }
        ]
    },
    {
        "id": 45284,
        "spelling_name": "Irascible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71114,
                "word_type": "a.",
                "spelling_usage": "Prone to anger; easily provoked or inflamed to anger;\n   choleric; irritable; as, an irascible man; an irascible temper or mood."
            }
        ]
    },
    {
        "id": 45285,
        "spelling_name": "Irate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71115,
                "word_type": "a.",
                "spelling_usage": "Angry; incensed; enraged."
            }
        ]
    },
    {
        "id": 45286,
        "spelling_name": "Ire",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71116,
                "word_type": "n.",
                "spelling_usage": "Anger; wrath."
            }
        ]
    },
    {
        "id": 45328,
        "spelling_name": "Irksome",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71168,
                "word_type": "a.",
                "spelling_usage": "Wearisome; tedious; disagreeable or troublesome by reason\n   of long continuance or repetition; as, irksome hours; irksome tasks."
            },
            {
                "spelling_detail_id": 71169,
                "word_type": "a.",
                "spelling_usage": "Weary; vexed; uneasy."
            }
        ]
    },
    {
        "id": 45467,
        "spelling_name": "Irresolute",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71348,
                "word_type": "a.",
                "spelling_usage": "Not resolute; not decided or determined; wavering;\n   given to doubt or irresolution."
            }
        ]
    },
    {
        "id": 45499,
        "spelling_name": "Irrevocable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71384,
                "word_type": "a.",
                "spelling_usage": "Incapable of being recalled or revoked; unchangeable;\n   irreversible; unalterable; as, an irrevocable promise or decree;\n   irrevocable fate."
            }
        ]
    },
    {
        "id": 45767,
        "spelling_name": "Itinerate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71765,
                "word_type": "v. i.",
                "spelling_usage": "To wander without a settled habitation; to travel\n   from place or on a circuit, particularly for the purpose of preaching,\n   lecturing, etc."
            }
        ]
    },
    {
        "id": 45800,
        "spelling_name": "Jabber",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71806,
                "word_type": "v. i.",
                "spelling_usage": "To talk rapidly, indistinctly, or unintelligibly; to\n   utter gibberish or nonsense; to chatter."
            },
            {
                "spelling_detail_id": 71807,
                "word_type": "v. t.",
                "spelling_usage": "To utter rapidly or indistinctly; to gabble; as, to\n   jabber French."
            },
            {
                "spelling_detail_id": 71808,
                "word_type": "n.",
                "spelling_usage": "Rapid or incoherent talk, with indistinct utterance;\n   gibberish."
            },
            {
                "spelling_detail_id": 71809,
                "word_type": "n.",
                "spelling_usage": "One who jabbers."
            }
        ]
    },
    {
        "id": 45888,
        "spelling_name": "Jagged",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 71955,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Jag"
            },
            {
                "spelling_detail_id": 71965,
                "word_type": "a.",
                "spelling_usage": "Having jags; having rough, sharp notches, protuberances, or\n   teeth; cleft; laciniate; divided; as, jagged rocks."
            }
        ]
    },
    {
        "id": 46067,
        "spelling_name": "Jejune",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 72222,
                "word_type": "a.",
                "spelling_usage": "Lacking matter; empty; void of substance."
            },
            {
                "spelling_detail_id": 72223,
                "word_type": "a.",
                "spelling_usage": "Void of interest; barren; meager; dry; as, a jejune\n   narrative."
            }
        ]
    },
    {
        "id": 46187,
        "spelling_name": "Jibe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 72405,
                "word_type": "v. i.",
                "spelling_usage": "To shift, as the boom of a fore-and-aft sail, from one\n   side of a vessel to the other when the wind is aft or on the quarter.\n   See Gybe."
            },
            {
                "spelling_detail_id": 72406,
                "word_type": "v. i.",
                "spelling_usage": "To change a ship's course so as to cause a shifting of the\n   boom. See Jibe, v. t., and Gybe."
            },
            {
                "spelling_detail_id": 72407,
                "word_type": "v. t.",
                "spelling_usage": "To agree; to harmonize."
            }
        ]
    },
    {
        "id": 46243,
        "spelling_name": "Jocular",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 72518,
                "word_type": "a.",
                "spelling_usage": "Given to jesting; jocose; as, a jocular person."
            },
            {
                "spelling_detail_id": 72519,
                "word_type": "a.",
                "spelling_usage": "Sportive; merry."
            }
        ]
    },
    {
        "id": 46436,
        "spelling_name": "Judicious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 72837,
                "word_type": "a.",
                "spelling_usage": "Of or relating to a court; judicial."
            },
            {
                "spelling_detail_id": 72838,
                "word_type": "a.",
                "spelling_usage": "Directed or governed by sound judgment; having sound\n   judgment; wise; prudent; sagacious; discreet."
            }
        ]
    },
    {
        "id": 47287,
        "spelling_name": "Knit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 74113,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Knit"
            },
            {
                "spelling_detail_id": 74116,
                "word_type": "v. t.",
                "spelling_usage": "To form into a knot, or into knots; to tie together, as\n   cord; to fasten by tying."
            },
            {
                "spelling_detail_id": 74117,
                "word_type": "v. t.",
                "spelling_usage": "To form, as a textile fabric, by the interlacing of yarn\n   or thread in a series of connected loops, by means of needles, either\n   by hand or by machinery; as, to knit stockings."
            },
            {
                "spelling_detail_id": 74118,
                "word_type": "v. t.",
                "spelling_usage": "To join; to cause to grow together."
            },
            {
                "spelling_detail_id": 74119,
                "word_type": "v. t.",
                "spelling_usage": "To unite closely; to connect; to engage; as, hearts knit\n   together in love."
            },
            {
                "spelling_detail_id": 74120,
                "word_type": "v. t.",
                "spelling_usage": "To draw together; to contract into wrinkles."
            },
            {
                "spelling_detail_id": 74121,
                "word_type": "v. i.",
                "spelling_usage": "To form a fabric by interlacing yarn or thread; to weave\n   by making knots or loops."
            },
            {
                "spelling_detail_id": 74122,
                "word_type": "v. i.",
                "spelling_usage": "To be united closely; to grow together; as, broken bones\n   will in time knit and become sound."
            },
            {
                "spelling_detail_id": 74123,
                "word_type": "n.",
                "spelling_usage": "Union knitting; texture."
            }
        ]
    },
    {
        "id": 47569,
        "spelling_name": "Labyrinthine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 74559,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or like, a labyrinth; labyrinthal."
            }
        ]
    },
    {
        "id": 47613,
        "spelling_name": "Lachrymose",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 74628,
                "word_type": "a.",
                "spelling_usage": "Generating or shedding tears; given to shedding tears;\n   suffused with tears; tearful."
            }
        ]
    },
    {
        "id": 47635,
        "spelling_name": "Lackluster",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 74666,
                "word_type": "n.",
                "spelling_usage": "Alt. of Lacklustre"
            }
        ]
    },
    {
        "id": 47829,
        "spelling_name": "Lam",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 74940,
                "word_type": "v. t.",
                "spelling_usage": "To beat soundly; to thrash."
            }
        ]
    },
    {
        "id": 47844,
        "spelling_name": "Lambaste",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 74960,
                "word_type": "v. t.",
                "spelling_usage": "To beat severely."
            }
        ]
    },
    {
        "id": 47885,
        "spelling_name": "Lament",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 75015,
                "word_type": "v. i.",
                "spelling_usage": "To express or feel sorrow; to weep or wail; to mourn."
            },
            {
                "spelling_detail_id": 75018,
                "word_type": "v. t.",
                "spelling_usage": "To mourn for; to bemoan; to bewail."
            },
            {
                "spelling_detail_id": 75019,
                "word_type": "v.",
                "spelling_usage": "Grief or sorrow expressed in complaints or cries;\n   lamentation; a wailing; a moaning; a weeping."
            },
            {
                "spelling_detail_id": 75020,
                "word_type": "v.",
                "spelling_usage": "An elegy or mournful ballad, or the like."
            }
        ]
    },
    {
        "id": 47940,
        "spelling_name": "Lampoon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 75084,
                "word_type": "n.",
                "spelling_usage": "A personal satire in writing; usually, malicious and\n   abusive censure written only to reproach and distress."
            },
            {
                "spelling_detail_id": 75087,
                "word_type": "v. t.",
                "spelling_usage": "To subject to abusive ridicule expressed in writing; to\n   make the subject of a lampoon."
            }
        ]
    },
    {
        "id": 48268,
        "spelling_name": "Lassitude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 75589,
                "word_type": "n.",
                "spelling_usage": "A condition of the body, or mind, when its voluntary\n   functions are performed with difficulty, and only by a strong exertion\n   of the will; languor; debility; weariness."
            }
        ]
    },
    {
        "id": 48297,
        "spelling_name": "Latent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 75656,
                "word_type": "a.",
                "spelling_usage": "Not visible or apparent; hidden; springs of action."
            }
        ]
    },
    {
        "id": 48399,
        "spelling_name": "Laudatory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 75808,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining praise, or to the expression of praise;\n   as, laudatory verses; the laudatory powers of Dryden."
            }
        ]
    },
    {
        "id": 48470,
        "spelling_name": "Lavish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 75914,
                "word_type": "a.",
                "spelling_usage": "Expending or bestowing profusely; profuse; prodigal; as,\n   lavish of money; lavish of praise."
            },
            {
                "spelling_detail_id": 75915,
                "word_type": "a.",
                "spelling_usage": "Superabundant; excessive; as, lavish spirits."
            },
            {
                "spelling_detail_id": 75918,
                "word_type": "v. t.",
                "spelling_usage": "To expend or bestow with profusion; to use with\n   prodigality; to squander; as, to lavish money or praise."
            }
        ]
    },
    {
        "id": 48725,
        "spelling_name": "Legacy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 76455,
                "word_type": "n.",
                "spelling_usage": "A gift of property by will, esp. of money or personal\n   property; a bequest. Also Fig.; as, a legacy of dishonor or disease."
            },
            {
                "spelling_detail_id": 76456,
                "word_type": "n.",
                "spelling_usage": "A business with which one is intrusted by another; a\n   commission; -- obsolete, except in the phrases last legacy, dying\n   legacy, and the like."
            }
        ]
    },
    {
        "id": 49079,
        "spelling_name": "Levee",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 76973,
                "word_type": "n.",
                "spelling_usage": "The act of rising."
            },
            {
                "spelling_detail_id": 76974,
                "word_type": "n.",
                "spelling_usage": "A morning assembly or reception of visitors, -- in\n   distinction from a soiree, or evening assembly; a matinee; hence, also,\n   any general or somewhat miscellaneous gathering of guests, whether in\n   the daytime or evening; as, the president's levee."
            },
            {
                "spelling_detail_id": 76975,
                "word_type": "v. t.",
                "spelling_usage": "To attend the levee or levees of."
            },
            {
                "spelling_detail_id": 76976,
                "word_type": "n.",
                "spelling_usage": "An embankment to prevent inundation; as, the levees along\n   the Mississippi; sometimes, the steep bank of a river."
            },
            {
                "spelling_detail_id": 76977,
                "word_type": "v. t.",
                "spelling_usage": "To keep within a channel by means of levees; as, to levee\n   a river."
            }
        ]
    },
    {
        "id": 49122,
        "spelling_name": "Levity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77058,
                "word_type": "n.",
                "spelling_usage": "The quality of weighing less than something else of equal\n   bulk; relative lightness, especially as shown by rising through, or\n   floating upon, a contiguous substance; buoyancy; -- opposed to gravity."
            },
            {
                "spelling_detail_id": 77059,
                "word_type": "n.",
                "spelling_usage": "Lack of gravity and earnestness in deportment or character;\n   trifling gayety; frivolity; sportiveness; vanity."
            },
            {
                "spelling_detail_id": 77060,
                "word_type": "n.",
                "spelling_usage": "Lack of steadiness or constancy; disposition to change;\n   fickleness; volatility."
            }
        ]
    },
    {
        "id": 49183,
        "spelling_name": "Libel",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77148,
                "word_type": "n.",
                "spelling_usage": "A brief writing of any kind, esp. a declaration, bill,\n   certificate, request, supplication, etc."
            },
            {
                "spelling_detail_id": 77149,
                "word_type": "n.",
                "spelling_usage": "Any defamatory writing; a lampoon; a satire."
            },
            {
                "spelling_detail_id": 77150,
                "word_type": "n.",
                "spelling_usage": "A malicious publication expressed either in print or in\n   writing, or by pictures, effigies, or other signs, tending to expose\n   another to public hatred, contempt, or ridicule. Such publication is\n   indictable at common law."
            },
            {
                "spelling_detail_id": 77151,
                "word_type": "n.",
                "spelling_usage": "The crime of issuing a malicious defamatory publication."
            },
            {
                "spelling_detail_id": 77152,
                "word_type": "n.",
                "spelling_usage": "A written declaration or statement by the plaintiff of his\n   cause of action, and of the relief he seeks."
            },
            {
                "spelling_detail_id": 77157,
                "word_type": "v. t.",
                "spelling_usage": "To defame, or expose to public hatred, contempt, or\n   ridicule, by a writing, picture, sign, etc.; to lampoon."
            },
            {
                "spelling_detail_id": 77158,
                "word_type": "v. t.",
                "spelling_usage": "To proceed against by filing a libel, particularly\n   against a ship or goods."
            },
            {
                "spelling_detail_id": 77159,
                "word_type": "v. i.",
                "spelling_usage": "To spread defamation, written or printed; -- with\n   against."
            }
        ]
    },
    {
        "id": 49200,
        "spelling_name": "Liberality",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77181,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being liberal; liberal\n   disposition or practice; freedom from narrowness or prejudice;\n   generosity; candor; charity."
            },
            {
                "spelling_detail_id": 77182,
                "word_type": "n.",
                "spelling_usage": "A gift; a gratuity; -- sometimes in the plural; as, a\n   prudent man is not impoverished by his liberalities."
            }
        ]
    },
    {
        "id": 49218,
        "spelling_name": "Libertine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77201,
                "word_type": "n.",
                "spelling_usage": "A manumitted slave; a freedman; also, the son of a\n   freedman."
            },
            {
                "spelling_detail_id": 77202,
                "word_type": "n.",
                "spelling_usage": "One of a sect of Anabaptists, in the fifteenth and early\n   part of the sixteenth century, who rejected many of the customs and\n   decencies of life, and advocated a community of goods and of women."
            },
            {
                "spelling_detail_id": 77203,
                "word_type": "n.",
                "spelling_usage": "One free from restraint; one who acts according to his\n   impulses and desires; now, specifically, one who gives rein to lust; a\n   rake; a debauchee."
            },
            {
                "spelling_detail_id": 77204,
                "word_type": "n.",
                "spelling_usage": "A defamatory name for a freethinker."
            },
            {
                "spelling_detail_id": 77205,
                "word_type": "n.",
                "spelling_usage": "Free from restraint; uncontrolled."
            },
            {
                "spelling_detail_id": 77206,
                "word_type": "n.",
                "spelling_usage": "Dissolute; licentious; profligate; loose in morals; as,\n   libertine principles or manners."
            }
        ]
    },
    {
        "id": 49305,
        "spelling_name": "Lien",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77333,
                "word_type": "",
                "spelling_usage": "of Lie"
            },
            {
                "spelling_detail_id": 77365,
                "word_type": "obs. p. p.",
                "spelling_usage": "of Lie. See Lain."
            },
            {
                "spelling_detail_id": 77366,
                "word_type": "n.",
                "spelling_usage": "A legal claim; a charge upon real or personal property for\n   the satisfaction of some debt or duty; a right in one to control or\n   hold and retain the property of another until some claim of the former\n   is paid or satisfied."
            }
        ]
    },
    {
        "id": 49519,
        "spelling_name": "Limn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77819,
                "word_type": "v. t.",
                "spelling_usage": "To draw or paint; especially, to represent in an artistic\n   way with pencil or brush."
            },
            {
                "spelling_detail_id": 77820,
                "word_type": "v. t.",
                "spelling_usage": "To illumine, as books or parchments, with ornamental\n   figures, letters, or borders."
            }
        ]
    },
    {
        "id": 49530,
        "spelling_name": "Limp",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 77835,
                "word_type": "v. i.",
                "spelling_usage": "To halt; to walk lamely. Also used figuratively."
            },
            {
                "spelling_detail_id": 77836,
                "word_type": "n.",
                "spelling_usage": "A halt; the act of limping."
            },
            {
                "spelling_detail_id": 77837,
                "word_type": "n.",
                "spelling_usage": "A scraper for removing poor ore or refuse from the sieve."
            },
            {
                "spelling_detail_id": 77838,
                "word_type": "a.",
                "spelling_usage": "Flaccid; flabby, as flesh."
            },
            {
                "spelling_detail_id": 77839,
                "word_type": "a.",
                "spelling_usage": "Lacking stiffness; flimsy; as, a limp cravat."
            }
        ]
    },
    {
        "id": 49669,
        "spelling_name": "Lionize",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 78079,
                "word_type": "v. t.",
                "spelling_usage": "To treat or regard as a lion or object of great\n   interest."
            },
            {
                "spelling_detail_id": 78080,
                "word_type": "v. t.",
                "spelling_usage": "To show the lions or objects of interest to; to conduct\n   about among objects of interest."
            }
        ]
    },
    {
        "id": 49800,
        "spelling_name": "Lithe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 78306,
                "word_type": "v. i. & i.",
                "spelling_usage": "To listen or listen to; to hearken to."
            },
            {
                "spelling_detail_id": 78307,
                "word_type": "a.",
                "spelling_usage": "Mild; calm; as, lithe weather."
            },
            {
                "spelling_detail_id": 78308,
                "word_type": "a.",
                "spelling_usage": "Capable of being easily bent; pliant; flexible; limber; as,\n   the elephant's lithe proboscis."
            },
            {
                "spelling_detail_id": 78309,
                "word_type": "a.",
                "spelling_usage": "To smooth; to soften; to palliate."
            }
        ]
    },
    {
        "id": 49948,
        "spelling_name": "Livid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 78544,
                "word_type": "a.",
                "spelling_usage": "Black and blue; grayish blue; of a lead color; discolored,\n   as flesh by contusion."
            }
        ]
    },
    {
        "id": 50216,
        "spelling_name": "Loll",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 78977,
                "word_type": "v. i.",
                "spelling_usage": "To act lazily or indolently; to recline; to lean; to throw\n   one's self down; to lie at ease."
            },
            {
                "spelling_detail_id": 78978,
                "word_type": "v. i.",
                "spelling_usage": "To hand extended from the mouth, as the tongue of an ox or\n   a log when heated with labor or exertion."
            },
            {
                "spelling_detail_id": 78979,
                "word_type": "v. i.",
                "spelling_usage": "To let the tongue hang from the mouth, as an ox, dog, or\n   other animal, when heated by labor; as, the ox stood lolling in the\n   furrow."
            },
            {
                "spelling_detail_id": 78980,
                "word_type": "v. t.",
                "spelling_usage": "To let hang from the mouth, as the tongue."
            }
        ]
    },
    {
        "id": 50367,
        "spelling_name": "Lope",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79255,
                "word_type": "imp.",
                "spelling_usage": "of Leap."
            },
            {
                "spelling_detail_id": 79258,
                "word_type": "v. i.",
                "spelling_usage": "To leap; to dance."
            },
            {
                "spelling_detail_id": 79259,
                "word_type": "v. i.",
                "spelling_usage": "To move with a lope, as a horse."
            },
            {
                "spelling_detail_id": 79260,
                "word_type": "n.",
                "spelling_usage": "A leap; a long step."
            },
            {
                "spelling_detail_id": 79261,
                "word_type": "n.",
                "spelling_usage": "An easy gait, consisting of long running strides or leaps."
            }
        ]
    },
    {
        "id": 50392,
        "spelling_name": "Loquacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79287,
                "word_type": "a.",
                "spelling_usage": "Given to continual talking; talkative; garrulous."
            },
            {
                "spelling_detail_id": 79288,
                "word_type": "a.",
                "spelling_usage": "Speaking; expressive."
            },
            {
                "spelling_detail_id": 79289,
                "word_type": "a.",
                "spelling_usage": "Apt to blab and disclose secrets."
            }
        ]
    },
    {
        "id": 50635,
        "spelling_name": "Lucubrate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79733,
                "word_type": "n.",
                "spelling_usage": "To study by candlelight or a lamp; to study by night."
            },
            {
                "spelling_detail_id": 79734,
                "word_type": "v. t.",
                "spelling_usage": "To elaborate, perfect, or compose, by night study or\n   by laborious endeavor."
            }
        ]
    },
    {
        "id": 50641,
        "spelling_name": "Luculent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79740,
                "word_type": "a.",
                "spelling_usage": "Lucid; clear; transparent."
            },
            {
                "spelling_detail_id": 79741,
                "word_type": "a.",
                "spelling_usage": "Clear; evident; luminous."
            },
            {
                "spelling_detail_id": 79742,
                "word_type": "a.",
                "spelling_usage": "Bright; shining in beauty."
            }
        ]
    },
    {
        "id": 50666,
        "spelling_name": "Lugubrious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79782,
                "word_type": "a.",
                "spelling_usage": "Mournful; indicating sorrow, often ridiculously or\n   feignedly; doleful; woful; pitiable; as, a whining tone and a\n   lugubrious look."
            }
        ]
    },
    {
        "id": 50670,
        "spelling_name": "Lull",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79788,
                "word_type": "v. t.",
                "spelling_usage": "To cause to rest by soothing influences; to compose; to\n   calm; to soothe; to quiet."
            },
            {
                "spelling_detail_id": 79789,
                "word_type": "v. i.",
                "spelling_usage": "To become gradually calm; to subside; to cease or abate\n   for a time; as, the storm lulls."
            },
            {
                "spelling_detail_id": 79790,
                "word_type": "n.",
                "spelling_usage": "The power or quality of soothing; that which soothes; a\n   lullaby."
            },
            {
                "spelling_detail_id": 79791,
                "word_type": "n.",
                "spelling_usage": "A temporary cessation of storm or confusion."
            }
        ]
    },
    {
        "id": 50683,
        "spelling_name": "Lumber",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79805,
                "word_type": "n.",
                "spelling_usage": "A pawnbroker's shop, or room for storing articles put in\n   pawn; hence, a pledge, or pawn."
            },
            {
                "spelling_detail_id": 79806,
                "word_type": "n.",
                "spelling_usage": "Old or refuse household stuff; things cumbrous, or bulky\n   and useless, or of small value."
            },
            {
                "spelling_detail_id": 79807,
                "word_type": "n.",
                "spelling_usage": "Timber sawed or split into the form of beams, joists,\n   boards, planks, staves, hoops, etc.; esp., that which is smaller than\n   heavy timber."
            },
            {
                "spelling_detail_id": 79810,
                "word_type": "b. t.",
                "spelling_usage": "To heap together in disorder."
            },
            {
                "spelling_detail_id": 79811,
                "word_type": "b. t.",
                "spelling_usage": "To fill or encumber with lumber; as, to lumber up a\n   room."
            },
            {
                "spelling_detail_id": 79812,
                "word_type": "v. i.",
                "spelling_usage": "To move heavily, as if burdened."
            },
            {
                "spelling_detail_id": 79813,
                "word_type": "v. i.",
                "spelling_usage": "To make a sound as if moving heavily or clumsily; to\n   rumble."
            },
            {
                "spelling_detail_id": 79814,
                "word_type": "v. i.",
                "spelling_usage": "To cut logs in the forest, or prepare timber for market."
            }
        ]
    },
    {
        "id": 50697,
        "spelling_name": "Luminary",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79828,
                "word_type": "n.",
                "spelling_usage": "Any body that gives light, especially one of the heavenly\n   bodies."
            },
            {
                "spelling_detail_id": 79829,
                "word_type": "n.",
                "spelling_usage": "One who illustrates any subject, or enlightens mankind;\n   as, Newton was a distinguished luminary."
            }
        ]
    },
    {
        "id": 50778,
        "spelling_name": "Lurk",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 79973,
                "word_type": "v. i.",
                "spelling_usage": "To lie hid; to lie in wait."
            },
            {
                "spelling_detail_id": 79974,
                "word_type": "v. i.",
                "spelling_usage": "To keep out of sight."
            }
        ]
    },
    {
        "id": 50818,
        "spelling_name": "Lustrous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 80040,
                "word_type": "a.",
                "spelling_usage": "Bright; shining; luminous."
            }
        ]
    },
    {
        "id": 51010,
        "spelling_name": "Macerate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 80303,
                "word_type": "v. t.",
                "spelling_usage": "To make lean; to cause to waste away."
            },
            {
                "spelling_detail_id": 80304,
                "word_type": "v. t.",
                "spelling_usage": "To subdue the appetites of by poor and scanty diet; to\n   mortify."
            },
            {
                "spelling_detail_id": 80305,
                "word_type": "v. t.",
                "spelling_usage": "To soften by steeping in a liquid, with or without\n   heat; to wear away or separate the parts of by steeping; as, to\n   macerate animal or vegetable fiber."
            }
        ]
    },
    {
        "id": 51029,
        "spelling_name": "Machination",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 80324,
                "word_type": "n.",
                "spelling_usage": "The act of machinating."
            },
            {
                "spelling_detail_id": 80325,
                "word_type": "n.",
                "spelling_usage": "That which is devised; a device; a hostile or\n   treacherous scheme; an artful design or plot."
            }
        ]
    },
    {
        "id": 51466,
        "spelling_name": "Maladroit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 80987,
                "word_type": "a.",
                "spelling_usage": "Of a quality opposed to adroitness; clumsy; awkward;\n   unskillful."
            }
        ]
    },
    {
        "id": 51479,
        "spelling_name": "Malapropism",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81003,
                "word_type": "n.",
                "spelling_usage": "A grotesque misuse of a word; a word so used."
            }
        ]
    },
    {
        "id": 51534,
        "spelling_name": "Malevolence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81074,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being malevolent; evil\n   disposition toward another; inclination to injure others; ill will. See\n   Synonym of Malice."
            }
        ]
    },
    {
        "id": 51535,
        "spelling_name": "Malevolent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81075,
                "word_type": "a.",
                "spelling_usage": "Wishing evil; disposed to injure others; rejoicing in\n   another's misfortune."
            }
        ]
    },
    {
        "id": 51548,
        "spelling_name": "Malign",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81092,
                "word_type": "a.",
                "spelling_usage": "Having an evil disposition toward others; harboring violent\n   enmity; malevolent; malicious; spiteful; -- opposed to benign."
            },
            {
                "spelling_detail_id": 81093,
                "word_type": "a.",
                "spelling_usage": "Unfavorable; unpropitious; pernicious; tending to injure;\n   as, a malign aspect of planets."
            },
            {
                "spelling_detail_id": 81094,
                "word_type": "a.",
                "spelling_usage": "Malignant; as, a malign ulcer."
            },
            {
                "spelling_detail_id": 81097,
                "word_type": "a.",
                "spelling_usage": "To treat with malice; to show hatred toward; to abuse; to\n   wrong; to injure."
            },
            {
                "spelling_detail_id": 81098,
                "word_type": "a.",
                "spelling_usage": "To speak great evil of; to traduce; to defame; to slander;\n   to vilify; to asperse."
            },
            {
                "spelling_detail_id": 81099,
                "word_type": "v. i.",
                "spelling_usage": "To entertain malice."
            }
        ]
    },
    {
        "id": 51561,
        "spelling_name": "Malinger",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81121,
                "word_type": "v. i.",
                "spelling_usage": "To act the part of a malingerer; to feign illness or\n   inability."
            }
        ]
    },
    {
        "id": 51571,
        "spelling_name": "Malleable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81143,
                "word_type": "a.",
                "spelling_usage": "Capable of being extended or shaped by beating with a\n   hammer, or by the pressure of rollers; -- applied to metals."
            }
        ]
    },
    {
        "id": 51691,
        "spelling_name": "Manacle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 81292,
                "word_type": "n.",
                "spelling_usage": "A handcuff; a shackle for the hand or wrist; -- usually in\n   the plural."
            },
            {
                "spelling_detail_id": 81295,
                "word_type": "v. t.",
                "spelling_usage": "To put handcuffs or other fastening upon, for confining\n   the hands; to shackle; to confine; to restrain from the use of the\n   limbs or natural powers."
            }
        ]
    },
    {
        "id": 52351,
        "spelling_name": "Massacre",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 82320,
                "word_type": "n.",
                "spelling_usage": "The killing of a considerable number of human beings\n   under circumstances of atrocity or cruelty, or contrary to the usages\n   of civilized people; as, the massacre on St. Bartholomew's Day."
            },
            {
                "spelling_detail_id": 82321,
                "word_type": "n.",
                "spelling_usage": "Murder."
            },
            {
                "spelling_detail_id": 82324,
                "word_type": "n.",
                "spelling_usage": "To kill in considerable numbers where much resistance can\n   not be made; to kill with indiscriminate violence, without necessity,\n   and contrary to the usages of nations; to butcher; to slaughter; --\n   limited to the killing of human beings."
            }
        ]
    },
    {
        "id": 52511,
        "spelling_name": "Matriculation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 82585,
                "word_type": "n.",
                "spelling_usage": "The act or process of matriculating; the state of\n   being matriculated."
            }
        ]
    },
    {
        "id": 52568,
        "spelling_name": "Maudlin",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 82688,
                "word_type": "a.",
                "spelling_usage": "Tearful; easily moved to tears; exciting to tears;\n   excessively sentimental; weak and silly."
            },
            {
                "spelling_detail_id": 82689,
                "word_type": "a.",
                "spelling_usage": "Drunk, or somewhat drunk; fuddled; given to drunkenness."
            },
            {
                "spelling_detail_id": 82690,
                "word_type": "n.",
                "spelling_usage": "Alt. of Maudeline"
            }
        ]
    },
    {
        "id": 52573,
        "spelling_name": "Maul",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 82699,
                "word_type": "n.",
                "spelling_usage": "A heavy wooden hammer or beetle."
            },
            {
                "spelling_detail_id": 82702,
                "word_type": "v. t.",
                "spelling_usage": "To beat and bruise with a heavy stick or cudgel; to wound\n   in a coarse manner."
            },
            {
                "spelling_detail_id": 82703,
                "word_type": "v. t.",
                "spelling_usage": "To injure greatly; to do much harm to."
            }
        ]
    },
    {
        "id": 52596,
        "spelling_name": "Maverick",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 82732,
                "word_type": "n.",
                "spelling_usage": "In the southwestern part of the united States, a bullock\n   or heifer that has not been branded, and is unclaimed or wild; -- said\n   to be from Maverick, the name of a cattle owner in Texas who neglected\n   to brand his cattle."
            }
        ]
    },
    {
        "id": 53060,
        "spelling_name": "Mellifluous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83433,
                "word_type": "a.",
                "spelling_usage": "Flowing as with honey; smooth; flowing sweetly or\n   smoothly; as, a mellifluous voice."
            }
        ]
    },
    {
        "id": 53172,
        "spelling_name": "Mendacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83604,
                "word_type": "a.",
                "spelling_usage": "Given to deception or falsehood; lying; as, a\n   mendacious person."
            },
            {
                "spelling_detail_id": 83605,
                "word_type": "a.",
                "spelling_usage": "False; counterfeit; containing falsehood; as, a\n   mendacious statement."
            }
        ]
    },
    {
        "id": 53174,
        "spelling_name": "Mendacity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83607,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being mendacious; a habit of\n   lying."
            },
            {
                "spelling_detail_id": 83608,
                "word_type": "n.",
                "spelling_usage": "A falsehood; a lie."
            }
        ]
    },
    {
        "id": 53179,
        "spelling_name": "Mendicant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83612,
                "word_type": "a.",
                "spelling_usage": "Practicing beggary; begging; living on alms; as,\n   mendicant friars."
            },
            {
                "spelling_detail_id": 83613,
                "word_type": "n.",
                "spelling_usage": "A beggar; esp., one who makes a business of begging;\n   specifically, a begging friar."
            }
        ]
    },
    {
        "id": 53266,
        "spelling_name": "Mephitic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83721,
                "word_type": "a.",
                "spelling_usage": "Alt. of Mephitical"
            }
        ]
    },
    {
        "id": 53310,
        "spelling_name": "Mercurial",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83777,
                "word_type": "a.",
                "spelling_usage": "Having the qualities fabled to belong to the god\n   Mercury; swift; active; sprightly; fickle; volatile; changeable; as, a\n   mercurial youth; a mercurial temperament."
            },
            {
                "spelling_detail_id": 83778,
                "word_type": "a.",
                "spelling_usage": "Having the form or image of Mercury; -- applied to\n   ancient guideposts."
            },
            {
                "spelling_detail_id": 83779,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to Mercury as the god of trade; hence,\n   money-making; crafty."
            },
            {
                "spelling_detail_id": 83780,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to, or containing, mercury; as,\n   mercurial preparations, barometer. See Mercury, 2."
            },
            {
                "spelling_detail_id": 83781,
                "word_type": "a.",
                "spelling_usage": "Caused by the use of mercury; as, mercurial sore mouth."
            },
            {
                "spelling_detail_id": 83782,
                "word_type": "n.",
                "spelling_usage": "A person having mercurial qualities."
            },
            {
                "spelling_detail_id": 83783,
                "word_type": "n.",
                "spelling_usage": "A preparation containing mercury."
            }
        ]
    },
    {
        "id": 53332,
        "spelling_name": "Meretricious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83827,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to prostitutes; having to do with\n   harlots; lustful; as, meretricious traffic."
            },
            {
                "spelling_detail_id": 83828,
                "word_type": "a.",
                "spelling_usage": "Resembling the arts of a harlot; alluring by false\n   show; gaudily and deceitfully ornamental; tawdry; as, meretricious\n   dress or ornaments."
            }
        ]
    },
    {
        "id": 53447,
        "spelling_name": "Mesmerize",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 83976,
                "word_type": "v. t.",
                "spelling_usage": "To bring into a state of mesmeric sleep."
            }
        ]
    },
    {
        "id": 53805,
        "spelling_name": "Meticulous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 84434,
                "word_type": "a.",
                "spelling_usage": "Timid; fearful."
            }
        ]
    },
    {
        "id": 53856,
        "spelling_name": "Mettle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 84495,
                "word_type": "n.",
                "spelling_usage": "Substance or quality of temperament; spirit, esp. as\n   regards honor, courage, fortitude, ardor, etc.; disposition; -- usually\n   in a good sense."
            }
        ]
    },
    {
        "id": 53858,
        "spelling_name": "Mettlesome",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 84497,
                "word_type": "a.",
                "spelling_usage": "Full of spirit; possessing constitutional ardor; fiery;\n   as, a mettlesome horse."
            }
        ]
    },
    {
        "id": 54038,
        "spelling_name": "Middling",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 84712,
                "word_type": "a.",
                "spelling_usage": "Of middle rank, state, size, or quality; about equally\n   distant from the extremes; medium; moderate; mediocre; ordinary."
            }
        ]
    },
    {
        "id": 54070,
        "spelling_name": "Mien",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 84768,
                "word_type": "n.",
                "spelling_usage": "Aspect; air; manner; demeanor; carriage; bearing."
            }
        ]
    },
    {
        "id": 54244,
        "spelling_name": "Minatory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 85027,
                "word_type": "a.",
                "spelling_usage": "Threatening; menacing."
            }
        ]
    },
    {
        "id": 54246,
        "spelling_name": "Mince",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 85031,
                "word_type": "v. t.",
                "spelling_usage": "To cut into very small pieces; to chop fine; to hash; as,\n   to mince meat."
            },
            {
                "spelling_detail_id": 85032,
                "word_type": "v. t.",
                "spelling_usage": "To suppress or weaken the force of; to extenuate; to\n   palliate; to tell by degrees, instead of directly and frankly; to clip,\n   as words or expressions; to utter half and keep back half of."
            },
            {
                "spelling_detail_id": 85033,
                "word_type": "v. t.",
                "spelling_usage": "To affect; to make a parade of."
            },
            {
                "spelling_detail_id": 85034,
                "word_type": "v. i.",
                "spelling_usage": "To walk with short steps; to walk in a prim, affected\n   manner."
            },
            {
                "spelling_detail_id": 85035,
                "word_type": "v. i.",
                "spelling_usage": "To act or talk with affected nicety; to affect delicacy\n   in manner."
            },
            {
                "spelling_detail_id": 85036,
                "word_type": "n.",
                "spelling_usage": "A short, precise step; an affected manner."
            }
        ]
    },
    {
        "id": 54442,
        "spelling_name": "Misanthrope",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 85367,
                "word_type": "n.",
                "spelling_usage": "A hater of mankind; a misanthropist."
            }
        ]
    },
    {
        "id": 54515,
        "spelling_name": "Mischievous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 85456,
                "word_type": "a.",
                "spelling_usage": "Causing mischief; harmful; hurtful; -- now often\n   applied where the evil is done carelessly or in sport; as, a\n   mischievous child."
            }
        ]
    },
    {
        "id": 54616,
        "spelling_name": "Miser",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 85579,
                "word_type": "n.",
                "spelling_usage": "A wretched person; a person afflicted by any great\n   misfortune."
            },
            {
                "spelling_detail_id": 85580,
                "word_type": "n.",
                "spelling_usage": "A despicable person; a wretch."
            },
            {
                "spelling_detail_id": 85581,
                "word_type": "n.",
                "spelling_usage": "A covetous, grasping, mean person; esp., one having wealth,\n   who lives miserably for the sake of saving and increasing his hoard."
            },
            {
                "spelling_detail_id": 85582,
                "word_type": "n.",
                "spelling_usage": "A kind of large earth auger."
            }
        ]
    },
    {
        "id": 54751,
        "spelling_name": "Misogynist",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 85751,
                "word_type": "n.",
                "spelling_usage": "A woman hater."
            }
        ]
    },
    {
        "id": 54966,
        "spelling_name": "Mite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 86055,
                "word_type": "n.",
                "spelling_usage": "A minute arachnid, of the order Acarina, of which there are\n   many species; as, the cheese mite, sugar mite, harvest mite, etc. See\n   Acarina."
            },
            {
                "spelling_detail_id": 86056,
                "word_type": "n.",
                "spelling_usage": "A small coin formerly circulated in England, rated at about a\n   third of a farthing. The name is also applied to a small coin used in\n   Palestine in the time of Christ."
            },
            {
                "spelling_detail_id": 86057,
                "word_type": "n.",
                "spelling_usage": "A small weight; one twentieth of a grain."
            },
            {
                "spelling_detail_id": 86058,
                "word_type": "n.",
                "spelling_usage": "Anything very small; a minute object; a very little quantity\n   or particle."
            }
        ]
    },
    {
        "id": 55104,
        "spelling_name": "Moderation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 86285,
                "word_type": "n.",
                "spelling_usage": "The act of moderating, or of imposing due restraint."
            },
            {
                "spelling_detail_id": 86286,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being mmoderate."
            },
            {
                "spelling_detail_id": 86287,
                "word_type": "n.",
                "spelling_usage": "Calmness of mind; equanimity; as, to bear adversity\n   with moderation."
            },
            {
                "spelling_detail_id": 86288,
                "word_type": "n.",
                "spelling_usage": "The first public examinations for degrees at the\n   University of Oxford; -- usually contracted to mods."
            }
        ]
    },
    {
        "id": 55267,
        "spelling_name": "Mollify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 86544,
                "word_type": "v. t.",
                "spelling_usage": "To soften; to make tender; to reduce the hardness,\n   harshness, or asperity of; to qualify; as, to mollify the ground."
            },
            {
                "spelling_detail_id": 86545,
                "word_type": "v. t.",
                "spelling_usage": "To assuage, as pain or irritation, to appease, as\n   excited feeling or passion; to pacify; to calm."
            }
        ]
    },
    {
        "id": 55289,
        "spelling_name": "Molt",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 86570,
                "word_type": "",
                "spelling_usage": "imp. of Melt."
            },
            {
                "spelling_detail_id": 86575,
                "word_type": "v. t.",
                "spelling_usage": "Alt. of Moult"
            },
            {
                "spelling_detail_id": 86577,
                "word_type": "v. t.",
                "spelling_usage": "Alt. of Moult"
            },
            {
                "spelling_detail_id": 86579,
                "word_type": "n.",
                "spelling_usage": "Alt. of Moult"
            }
        ]
    },
    {
        "id": 55785,
        "spelling_name": "Moot",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 87243,
                "word_type": "v.",
                "spelling_usage": "See 1st Mot."
            },
            {
                "spelling_detail_id": 87244,
                "word_type": "n.",
                "spelling_usage": "A ring for gauging wooden pins."
            },
            {
                "spelling_detail_id": 87247,
                "word_type": "v. t.",
                "spelling_usage": "To argue for and against; to debate; to discuss; to\n   propose for discussion."
            },
            {
                "spelling_detail_id": 87248,
                "word_type": "v. t.",
                "spelling_usage": "Specifically: To discuss by way of exercise; to argue for\n   practice; to propound and discuss in a mock court."
            },
            {
                "spelling_detail_id": 87249,
                "word_type": "v. i.",
                "spelling_usage": "To argue or plead in a supposed case."
            },
            {
                "spelling_detail_id": 87250,
                "word_type": "n.",
                "spelling_usage": "A meeting for discussion and deliberation; esp., a meeting of\n   the people of a village or district, in Anglo-Saxon times, for the\n   discussion and settlement of matters of common interest; -- usually in\n   composition; as, folk-moot."
            },
            {
                "spelling_detail_id": 87251,
                "word_type": "v.",
                "spelling_usage": "A discussion or debate; especially, a discussion of\n   fictitious causes by way of practice."
            },
            {
                "spelling_detail_id": 87252,
                "word_type": "a.",
                "spelling_usage": "Subject, or open, to argument or discussion; undecided;\n   debatable; mooted."
            },
            {
                "spelling_detail_id": 87643,
                "word_type": "",
                "spelling_usage": "of Mot"
            }
        ]
    },
    {
        "id": 55837,
        "spelling_name": "Morbid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 87336,
                "word_type": "a.",
                "spelling_usage": "Not sound and healthful; induced by a diseased or abnormal\n   condition; diseased; sickly; as, morbid humors; a morbid constitution;\n   a morbid state of the juices of a plant."
            },
            {
                "spelling_detail_id": 87337,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to disease or diseased parts; as, morbid\n   anatomy."
            }
        ]
    },
    {
        "id": 55877,
        "spelling_name": "Moribund",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 87400,
                "word_type": "a.",
                "spelling_usage": "In a dying state; dying; at the point of death."
            },
            {
                "spelling_detail_id": 87401,
                "word_type": "n.",
                "spelling_usage": "A dying person."
            }
        ]
    },
    {
        "id": 55916,
        "spelling_name": "Morose",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 87451,
                "word_type": "a.",
                "spelling_usage": "Of a sour temper; sullen and austere; ill-humored; severe."
            },
            {
                "spelling_detail_id": 87452,
                "word_type": "a.",
                "spelling_usage": "Lascivious; brooding over evil thoughts."
            }
        ]
    },
    {
        "id": 56294,
        "spelling_name": "Muffler",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 88129,
                "word_type": "n.",
                "spelling_usage": "Anything used in muffling; esp., a scarf for protecting\n   the head and neck in cold weather; a tippet."
            },
            {
                "spelling_detail_id": 88130,
                "word_type": "n.",
                "spelling_usage": "A cushion for terminating or softening a note made by a\n   stringed instrument with a keyboard."
            },
            {
                "spelling_detail_id": 88131,
                "word_type": "n.",
                "spelling_usage": "A kind of mitten or boxing glove, esp. when stuffed."
            },
            {
                "spelling_detail_id": 88132,
                "word_type": "n.",
                "spelling_usage": "One who muffles."
            }
        ]
    },
    {
        "id": 56330,
        "spelling_name": "Mulct",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 88172,
                "word_type": "n.",
                "spelling_usage": "A fine or penalty, esp. a pecuniary punishment or penalty."
            },
            {
                "spelling_detail_id": 88173,
                "word_type": "n.",
                "spelling_usage": "A blemish or defect."
            },
            {
                "spelling_detail_id": 88176,
                "word_type": "v. t.",
                "spelling_usage": "To punish for an offense or misdemeanor by imposing a\n   fine or forfeiture, esp. a pecuniary fine; to fine."
            },
            {
                "spelling_detail_id": 88177,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to deprive of; to withhold by way of punishment or\n   discipline."
            }
        ]
    },
    {
        "id": 56388,
        "spelling_name": "Multifarious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 88261,
                "word_type": "a.",
                "spelling_usage": "Having multiplicity; having great diversity or\n   variety; of various kinds; diversified; made up of many differing\n   parts; manifold."
            },
            {
                "spelling_detail_id": 88262,
                "word_type": "a.",
                "spelling_usage": "Having parts, as leaves, arranged in many vertical\n   rows."
            }
        ]
    },
    {
        "id": 56512,
        "spelling_name": "Mundane",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 88434,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the world; worldly; earthly;\n   terrestrial; as, the mundane sphere."
            }
        ]
    },
    {
        "id": 56897,
        "spelling_name": "Myriad",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 88958,
                "word_type": "n.",
                "spelling_usage": "The number of ten thousand; ten thousand persons or things."
            },
            {
                "spelling_detail_id": 88959,
                "word_type": "n.",
                "spelling_usage": "An immense number; a very great many; an indefinitely large\n   number."
            },
            {
                "spelling_detail_id": 88960,
                "word_type": "a.",
                "spelling_usage": "Consisting of a very great, but indefinite, number; as,\n   myriad stars."
            }
        ]
    },
    {
        "id": 57011,
        "spelling_name": "Nadir",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 89098,
                "word_type": "n.",
                "spelling_usage": "That point of the heavens, or lower hemisphere, directly\n   opposite the zenith; the inferior pole of the horizon; the point of the\n   celestial sphere directly under the place where we stand."
            },
            {
                "spelling_detail_id": 89099,
                "word_type": "n.",
                "spelling_usage": "The lowest point; the time of greatest depression."
            }
        ]
    },
    {
        "id": 57178,
        "spelling_name": "Nascent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 89356,
                "word_type": "a.",
                "spelling_usage": "Commencing, or in process of development; beginning to\n   exist or to grow; coming into being; as, a nascent germ."
            },
            {
                "spelling_detail_id": 89357,
                "word_type": "a.",
                "spelling_usage": "Evolving; being evolved or produced."
            }
        ]
    },
    {
        "id": 57383,
        "spelling_name": "Nebulous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 89690,
                "word_type": "a.",
                "spelling_usage": "Cloudy; hazy; misty."
            },
            {
                "spelling_detail_id": 89691,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or having the appearance of, a nebula;\n   nebular; cloudlike."
            }
        ]
    },
    {
        "id": 57530,
        "spelling_name": "Negligent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 89917,
                "word_type": "a.",
                "spelling_usage": "Apt to neglect; customarily neglectful; characterized by\n   negligence; careless; heedless; culpably careless; showing lack of\n   attention; as, disposed in negligent order."
            }
        ]
    },
    {
        "id": 57601,
        "spelling_name": "Nemesis",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 90017,
                "word_type": "n.",
                "spelling_usage": "The goddess of retribution or vengeance; hence,\n   retributive justice personified; divine vengeance."
            }
        ]
    },
    {
        "id": 57646,
        "spelling_name": "Neophyte",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 90068,
                "word_type": "n.",
                "spelling_usage": "A new convert or proselyte; -- a name given by the early\n   Christians, and still given by the Roman Catholics, to such as have\n   recently embraced the Christian faith, and been admitted to baptism,\n   esp. to converts from heathenism or Judaism."
            },
            {
                "spelling_detail_id": 90069,
                "word_type": "n.",
                "spelling_usage": "A novice; a tyro; a beginner in anything."
            }
        ]
    },
    {
        "id": 57887,
        "spelling_name": "Nexus",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 90430,
                "word_type": "n.",
                "spelling_usage": "Connection; tie."
            }
        ]
    },
    {
        "id": 57897,
        "spelling_name": "Nibble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 90445,
                "word_type": "v. t.",
                "spelling_usage": "To bite by little at a time; to seize gently with the\n   mouth; to eat slowly or in small bits."
            },
            {
                "spelling_detail_id": 90446,
                "word_type": "v. t.",
                "spelling_usage": "To bite upon something gently or cautiously; to eat a\n   little of a thing, as by taking small bits cautiously; as, fishes\n   nibble at the bait."
            },
            {
                "spelling_detail_id": 90447,
                "word_type": "n.",
                "spelling_usage": "A small or cautious bite."
            }
        ]
    },
    {
        "id": 58026,
        "spelling_name": "Nihilism",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 90626,
                "word_type": "n.",
                "spelling_usage": "Nothingness; nihility."
            },
            {
                "spelling_detail_id": 90627,
                "word_type": "n.",
                "spelling_usage": "The doctrine that nothing can be known; scepticism as to\n   all knowledge and all reality."
            },
            {
                "spelling_detail_id": 90628,
                "word_type": "n.",
                "spelling_usage": "The theories and practices of the Nihilists."
            }
        ]
    },
    {
        "id": 58280,
        "spelling_name": "Noisome",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 91008,
                "word_type": "a.",
                "spelling_usage": "Noxious to health; hurtful; mischievous; unwholesome;\n   insalubrious; destructive; as, noisome effluvia."
            },
            {
                "spelling_detail_id": 91009,
                "word_type": "a.",
                "spelling_usage": "Offensive to the smell or other senses; disgusting; fetid."
            }
        ]
    },
    {
        "id": 58382,
        "spelling_name": "Nonchalant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 91125,
                "word_type": "a.",
                "spelling_usage": "Indifferent; careless; cool."
            }
        ]
    },
    {
        "id": 58481,
        "spelling_name": "Nonplused",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 91250,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Nonplus"
            }
        ]
    },
    {
        "id": 58642,
        "spelling_name": "Nostrum",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 91480,
                "word_type": "n.",
                "spelling_usage": "A medicine, the ingredients of which are kept secret for\n   the purpose of restricting the profits of sale to the inventor or\n   proprietor; a quack medicine."
            },
            {
                "spelling_detail_id": 91481,
                "word_type": "n.",
                "spelling_usage": "Any scheme or device proposed by a quack."
            }
        ]
    },
    {
        "id": 58790,
        "spelling_name": "Noxious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 91737,
                "word_type": "a.",
                "spelling_usage": "Hurtful; harmful; baneful; pernicious; injurious;\n   destructive; unwholesome; insalubrious; as, noxious air, food, or\n   climate; pernicious; corrupting to morals; as, noxious practices or\n   examples."
            },
            {
                "spelling_detail_id": 91738,
                "word_type": "a.",
                "spelling_usage": "Guilty; criminal."
            }
        ]
    },
    {
        "id": 58865,
        "spelling_name": "Nugatory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 91835,
                "word_type": "a.",
                "spelling_usage": "Trifling; vain; futile; insignificant."
            },
            {
                "spelling_detail_id": 91836,
                "word_type": "a.",
                "spelling_usage": "Of no force; inoperative; ineffectual."
            }
        ]
    },
    {
        "id": 59099,
        "spelling_name": "Obdurate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92180,
                "word_type": "a.",
                "spelling_usage": "Hardened in feelings, esp. against moral or mollifying\n   influences; unyielding; hard-hearted; stubbornly wicked."
            },
            {
                "spelling_detail_id": 92181,
                "word_type": "a.",
                "spelling_usage": "Hard; harsh; rugged; rough; intractable."
            },
            {
                "spelling_detail_id": 92182,
                "word_type": "v. t.",
                "spelling_usage": "To harden."
            }
        ]
    },
    {
        "id": 59140,
        "spelling_name": "Obfuscate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92236,
                "word_type": "a.",
                "spelling_usage": "Obfuscated; darkened; obscured."
            },
            {
                "spelling_detail_id": 92239,
                "word_type": "v. t.",
                "spelling_usage": "To darken; to obscure; to becloud; hence, to confuse;\n   to bewilder."
            }
        ]
    },
    {
        "id": 59221,
        "spelling_name": "Oblivious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92362,
                "word_type": "a.",
                "spelling_usage": "Promoting oblivion; causing forgetfulness."
            },
            {
                "spelling_detail_id": 92363,
                "word_type": "a.",
                "spelling_usage": "Evincing oblivion; forgetful."
            }
        ]
    },
    {
        "id": 59233,
        "spelling_name": "Obloquy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92376,
                "word_type": "n.",
                "spelling_usage": "Censorious speech; defamatory language; language that\n   casts contempt on men or their actions; blame; reprehension."
            },
            {
                "spelling_detail_id": 92377,
                "word_type": "n.",
                "spelling_usage": "Cause of reproach; disgrace."
            }
        ]
    },
    {
        "id": 59276,
        "spelling_name": "Obsequious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92436,
                "word_type": "a.",
                "spelling_usage": "Promptly obedient, or submissive, to the will of\n   another; compliant; yielding to the desires of another; devoted."
            },
            {
                "spelling_detail_id": 92437,
                "word_type": "a.",
                "spelling_usage": "Servilely or meanly attentive; compliant to excess;\n   cringing; fawning; as, obsequious flatterer, parasite."
            },
            {
                "spelling_detail_id": 92438,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to obsequies; funereal."
            }
        ]
    },
    {
        "id": 59329,
        "spelling_name": "Obstreperous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92526,
                "word_type": "a.",
                "spelling_usage": "Attended by, or making, a loud and tumultuous noise;\n   clamorous; noisy; vociferous."
            }
        ]
    },
    {
        "id": 59344,
        "spelling_name": "Obtain",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92549,
                "word_type": "v. t.",
                "spelling_usage": "To hold; to keep; to possess."
            },
            {
                "spelling_detail_id": 92550,
                "word_type": "v. t.",
                "spelling_usage": "To get hold of by effort; to gain possession of; to\n   procure; to acquire, in any way."
            },
            {
                "spelling_detail_id": 92551,
                "word_type": "v. i.",
                "spelling_usage": "To become held; to gain or have a firm footing; to be\n   recognized or established; to subsist; to become prevalent or general;\n   to prevail; as, the custom obtains of going to the seashore in summer."
            },
            {
                "spelling_detail_id": 92552,
                "word_type": "v. i.",
                "spelling_usage": "To prevail; to succeed."
            }
        ]
    },
    {
        "id": 59371,
        "spelling_name": "Obtrusive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92584,
                "word_type": "a.",
                "spelling_usage": "Disposed to obtrude; inclined to intrude or thrust one's\n   self or one's opinions upon others, or to enter uninvited; forward;\n   pushing; intrusive."
            }
        ]
    },
    {
        "id": 59380,
        "spelling_name": "Obtuse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92595,
                "word_type": "superl.",
                "spelling_usage": "Not pointed or acute; blunt; -- applied esp. to angles\n   greater than a right angle, or containing more than ninety degrees."
            },
            {
                "spelling_detail_id": 92596,
                "word_type": "superl.",
                "spelling_usage": "Not having acute sensibility or perceptions; dull;\n   stupid; as, obtuse senses."
            },
            {
                "spelling_detail_id": 92597,
                "word_type": "superl.",
                "spelling_usage": "Dull; deadened; as, obtuse sound."
            }
        ]
    },
    {
        "id": 59399,
        "spelling_name": "Obviate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92622,
                "word_type": "v. t.",
                "spelling_usage": "To meet in the way."
            },
            {
                "spelling_detail_id": 92623,
                "word_type": "v. t.",
                "spelling_usage": "To anticipate; to prevent by interception; to remove\n   from the way or path; to make unnecessary; as, to obviate the necessity\n   of going."
            }
        ]
    },
    {
        "id": 59608,
        "spelling_name": "Odious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92906,
                "word_type": "a.",
                "spelling_usage": "Hateful; deserving or receiving hatred; as, an odious name,\n   system, vice."
            },
            {
                "spelling_detail_id": 92907,
                "word_type": "a.",
                "spelling_usage": "Causing or provoking hatred, repugnance, or disgust;\n   offensive; disagreeable; repulsive; as, an odious sight; an odious\n   smell."
            }
        ]
    },
    {
        "id": 59610,
        "spelling_name": "Odium",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92909,
                "word_type": "n.",
                "spelling_usage": "Hatred; dislike; as, his conduct brought him into odium, or,\n   brought odium upon him."
            },
            {
                "spelling_detail_id": 92910,
                "word_type": "n.",
                "spelling_usage": "The quality that provokes hatred; offensiveness."
            }
        ]
    },
    {
        "id": 59643,
        "spelling_name": "Odor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 92946,
                "word_type": "n.",
                "spelling_usage": "Any smell, whether fragrant or offensive; scent; perfume."
            }
        ]
    },
    {
        "id": 59717,
        "spelling_name": "Offhand",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93078,
                "word_type": "a.",
                "spelling_usage": "Instant; ready; extemporaneous; as, an offhand speech;\n   offhand excuses."
            },
            {
                "spelling_detail_id": 93079,
                "word_type": "adv.",
                "spelling_usage": "In an offhand manner; as, he replied offhand."
            }
        ]
    },
    {
        "id": 59735,
        "spelling_name": "Officious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93115,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, or being in accordance with, duty."
            },
            {
                "spelling_detail_id": 93116,
                "word_type": "a.",
                "spelling_usage": "Disposed to serve; kind; obliging."
            },
            {
                "spelling_detail_id": 93117,
                "word_type": "a.",
                "spelling_usage": "Importunately interposing services; intermeddling in\n   affairs in which one has no concern; meddlesome."
            }
        ]
    },
    {
        "id": 59927,
        "spelling_name": "Ominous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93378,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to an omen or to omens; being or\n   exhibiting an omen; significant; portentous; -- formerly used both in a\n   favorable and unfavorable sense; now chiefly in the latter; foreboding\n   or foreshowing evil; inauspicious; as, an ominous dread."
            }
        ]
    },
    {
        "id": 60041,
        "spelling_name": "Onerous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93535,
                "word_type": "a.",
                "spelling_usage": "Burdensome; oppressive."
            }
        ]
    },
    {
        "id": 60084,
        "spelling_name": "Onus",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93595,
                "word_type": "n.",
                "spelling_usage": "A burden; an obligation."
            }
        ]
    },
    {
        "id": 60162,
        "spelling_name": "Opaqueness",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93688,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being impervious to light;\n   opacity."
            }
        ]
    },
    {
        "id": 60353,
        "spelling_name": "Opprobrious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 93998,
                "word_type": "a.",
                "spelling_usage": "Expressive of opprobrium; attaching disgrace;\n   reproachful; scurrilous; as, opprobrious language."
            },
            {
                "spelling_detail_id": 93999,
                "word_type": "a.",
                "spelling_usage": "Infamous; despised; rendered hateful; as, an\n   opprobrious name."
            }
        ]
    },
    {
        "id": 60663,
        "spelling_name": "Orison",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 94496,
                "word_type": "n.",
                "spelling_usage": "A prayer; a supplication."
            }
        ]
    },
    {
        "id": 60922,
        "spelling_name": "Ossified",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 94816,
                "word_type": "a.",
                "spelling_usage": "Changed to bone or something resembling bone; hardened by\n   deposits of mineral matter of any kind; -- said of tissues."
            },
            {
                "spelling_detail_id": 94820,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Ossify"
            }
        ]
    },
    {
        "id": 60925,
        "spelling_name": "Ossify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 94822,
                "word_type": "v. t.",
                "spelling_usage": "To form into bone; to change from a soft animal\n   substance into bone, as by the deposition of lime salts."
            },
            {
                "spelling_detail_id": 94823,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To harden; as, to ossify the heart."
            },
            {
                "spelling_detail_id": 94824,
                "word_type": "v. i.",
                "spelling_usage": "To become bone; to change from a soft tissue to a hard\n   bony tissue."
            }
        ]
    },
    {
        "id": 60938,
        "spelling_name": "Ostensible",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 94838,
                "word_type": "a.",
                "spelling_usage": "Capable of being shown; proper or intended to be shown."
            },
            {
                "spelling_detail_id": 94839,
                "word_type": "a.",
                "spelling_usage": "Shown; exhibited; declared; avowed; professed;\n   apparent; -- often used as opposed to real or actual; as, an ostensible\n   reason, motive, or aim."
            }
        ]
    },
    {
        "id": 60947,
        "spelling_name": "Ostentation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 94851,
                "word_type": "n.",
                "spelling_usage": "The act of ostentating or of making an ambitious\n   display; unnecessary show; pretentious parade; -- usually in a\n   detractive sense."
            },
            {
                "spelling_detail_id": 94852,
                "word_type": "n.",
                "spelling_usage": "A show or spectacle."
            }
        ]
    },
    {
        "id": 61008,
        "spelling_name": "Ostracism",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 94920,
                "word_type": "n.",
                "spelling_usage": "Banishment by popular vote, -- a means adopted at Athens\n   to rid the city of a person whose talent and influence gave umbrage."
            },
            {
                "spelling_detail_id": 94921,
                "word_type": "n.",
                "spelling_usage": "Banishment; exclusion; as, social ostracism."
            }
        ]
    },
    {
        "id": 61652,
        "spelling_name": "Overhaul",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 95800,
                "word_type": "v. t.",
                "spelling_usage": "To haul or drag over; hence, to turn over for\n   examination; to inspect; to examine thoroughly with a view to\n   corrections or repairs."
            },
            {
                "spelling_detail_id": 95801,
                "word_type": "v. t.",
                "spelling_usage": "To gain upon in a chase; to overtake."
            },
            {
                "spelling_detail_id": 95802,
                "word_type": "n.",
                "spelling_usage": "Alt. of Overhauling"
            }
        ]
    },
    {
        "id": 62001,
        "spelling_name": "Overweening",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 96268,
                "word_type": "a.",
                "spelling_usage": "Unduly confident; arrogant; presumptuous; conceited."
            },
            {
                "spelling_detail_id": 96269,
                "word_type": "n.",
                "spelling_usage": "Conceit; arrogance."
            }
        ]
    },
    {
        "id": 62353,
        "spelling_name": "Paean",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 96781,
                "word_type": "n.",
                "spelling_usage": "An ancient Greek hymn in honor of Apollo as a healing deity,\n   and, later, a song addressed to other deities."
            },
            {
                "spelling_detail_id": 96782,
                "word_type": "n.",
                "spelling_usage": "Any loud and joyous song; a song of triumph."
            },
            {
                "spelling_detail_id": 96783,
                "word_type": "n.",
                "spelling_usage": "See Paeon."
            }
        ]
    },
    {
        "id": 62459,
        "spelling_name": "Palate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 96953,
                "word_type": "n.",
                "spelling_usage": "The roof of the mouth."
            },
            {
                "spelling_detail_id": 96954,
                "word_type": "n.",
                "spelling_usage": "Relish; taste; liking; -- a sense originating in the\n   mistaken notion that the palate is the organ of taste."
            },
            {
                "spelling_detail_id": 96955,
                "word_type": "n.",
                "spelling_usage": "Fig.: Mental relish; intellectual taste."
            },
            {
                "spelling_detail_id": 96956,
                "word_type": "n.",
                "spelling_usage": "A projection in the throat of such flowers as the\n   snapdragon."
            },
            {
                "spelling_detail_id": 96957,
                "word_type": "v. t.",
                "spelling_usage": "To perceive by the taste."
            }
        ]
    },
    {
        "id": 62460,
        "spelling_name": "Palatial",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 96958,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a palace; suitable for a palace;\n   resembling a palace; royal; magnificent; as, palatial structures."
            },
            {
                "spelling_detail_id": 96959,
                "word_type": "a.",
                "spelling_usage": "Palatal; palatine."
            },
            {
                "spelling_detail_id": 96960,
                "word_type": "n.",
                "spelling_usage": "A palatal letter."
            }
        ]
    },
    {
        "id": 62469,
        "spelling_name": "Palaver",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 96975,
                "word_type": "n.",
                "spelling_usage": "Talk; conversation; esp., idle or beguiling talk; talk\n   intended to deceive; flattery."
            },
            {
                "spelling_detail_id": 96976,
                "word_type": "n.",
                "spelling_usage": "In Africa, a parley with the natives; a talk; hence, a\n   public conference and deliberation; a debate."
            },
            {
                "spelling_detail_id": 96979,
                "word_type": "v. t. & i.",
                "spelling_usage": "To make palaver with, or to; to used palaver;to\n   talk idly or deceitfully; to employ flattery; to cajole; as, to palaver\n   artfully."
            }
        ]
    },
    {
        "id": 62587,
        "spelling_name": "Palliate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 97155,
                "word_type": "a.",
                "spelling_usage": "Covered with a mant/e; cloaked; disguised."
            },
            {
                "spelling_detail_id": 97156,
                "word_type": "a.",
                "spelling_usage": "Eased; mitigated; alleviated."
            },
            {
                "spelling_detail_id": 97159,
                "word_type": "v. t.",
                "spelling_usage": "To cover with a mantle or cloak; to cover up; to hide."
            },
            {
                "spelling_detail_id": 97160,
                "word_type": "v. t.",
                "spelling_usage": "To cover with excuses; to conceal the enormity of, by\n   excuses and apologies; to extenuate; as, to palliate faults."
            },
            {
                "spelling_detail_id": 97161,
                "word_type": "v. t.",
                "spelling_usage": "To reduce in violence; to lessen or abate; to\n   mitigate; to ease withhout curing; as, to palliate a disease."
            }
        ]
    },
    {
        "id": 62647,
        "spelling_name": "Palpability",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 97252,
                "word_type": "n.",
                "spelling_usage": "The quality of being palpable, or perceptible by the\n   touch."
            }
        ]
    },
    {
        "id": 62648,
        "spelling_name": "Palpable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 97253,
                "word_type": "a.",
                "spelling_usage": "Capable of being touched and felt; perceptible by the\n   touch; as, a palpable form."
            },
            {
                "spelling_detail_id": 97254,
                "word_type": "a.",
                "spelling_usage": "Easily perceptible; plain; distinct; obvious; readily\n   perceived and detected; gross; as, palpable imposture; palpable\n   absurdity; palpable errors."
            }
        ]
    },
    {
        "id": 62662,
        "spelling_name": "Palpitate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 97272,
                "word_type": "v. i.",
                "spelling_usage": "To beat rapidly and more strongly than usual; to\n   throb; to bound with emotion or exertion; to pulsate violently; to\n   flutter; -- said specifically of the heart when its action is abnormal,\n   as from excitement."
            }
        ]
    },
    {
        "id": 62783,
        "spelling_name": "Panegyric",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 97444,
                "word_type": "a.",
                "spelling_usage": "An oration or eulogy in praise of some person or\n   achievement; a formal or elaborate encomium; a laudatory discourse;\n   laudation. See Synonym of Eulogy."
            },
            {
                "spelling_detail_id": 97445,
                "word_type": "a.",
                "spelling_usage": "Alt. of Panegyrical"
            }
        ]
    },
    {
        "id": 63062,
        "spelling_name": "Paradigm",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 97821,
                "word_type": "n.",
                "spelling_usage": "An example; a model; a pattern."
            },
            {
                "spelling_detail_id": 97822,
                "word_type": "n.",
                "spelling_usage": "An example of a conjugation or declension, showing a word\n   in all its different forms of inflection."
            },
            {
                "spelling_detail_id": 97823,
                "word_type": "n.",
                "spelling_usage": "An illustration, as by a parable or fable."
            }
        ]
    },
    {
        "id": 63331,
        "spelling_name": "Parenthesis",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 98202,
                "word_type": "n.",
                "spelling_usage": "A word, phrase, or sentence, by way of comment or\n   explanation, inserted in, or attached to, a sentence which would be\n   grammatically complete without it. It is usually inclosed within curved\n   lines (see def. 2 below), or dashes."
            },
            {
                "spelling_detail_id": 98203,
                "word_type": "n.",
                "spelling_usage": "One of the curved lines () which inclose a parenthetic\n   word or phrase."
            }
        ]
    },
    {
        "id": 63363,
        "spelling_name": "Pariah",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 98245,
                "word_type": "n.",
                "spelling_usage": "One of an aboriginal people of Southern India, regarded by\n   the four castes of the Hindoos as of very low grade. They are usually\n   the serfs of the Sudra agriculturalists. See Caste."
            },
            {
                "spelling_detail_id": 98246,
                "word_type": "n.",
                "spelling_usage": "An outcast; one despised by society."
            }
        ]
    },
    {
        "id": 63403,
        "spelling_name": "Parley",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 98312,
                "word_type": "n.",
                "spelling_usage": "Mutual discourse or conversation; discussion; hence, an\n   oral conference with an enemy, as with regard to a truce."
            },
            {
                "spelling_detail_id": 98315,
                "word_type": "v. i.",
                "spelling_usage": "To speak with another; to confer on some point of mutual\n   concern; to discuss orally; hence, specifically, to confer orally with\n   an enemy; to treat with him by words, as on an exchange of prisoners,\n   an armistice, or terms of peace."
            }
        ]
    },
    {
        "id": 63489,
        "spelling_name": "Parsimonious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 98439,
                "word_type": "a.",
                "spelling_usage": "Exhibiting parsimony; sparing in expenditure of\n   money; frugal to excess; penurious; niggardly; stingy."
            }
        ]
    },
    {
        "id": 63561,
        "spelling_name": "Partisan",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 98593,
                "word_type": "n.",
                "spelling_usage": "An adherent to a party or faction; esp., one who is\n   strongly and passionately devoted to a party or an interest."
            },
            {
                "spelling_detail_id": 98594,
                "word_type": "n.",
                "spelling_usage": "The commander of a body of detached light troops engaged\n   in making forays and harassing an enemy."
            },
            {
                "spelling_detail_id": 98595,
                "word_type": "n.",
                "spelling_usage": "Any member of such a corps."
            },
            {
                "spelling_detail_id": 98596,
                "word_type": "a.",
                "spelling_usage": "Adherent to a party or faction; especially, having the\n   character of blind, passionate, or unreasonable adherence to a party;\n   as, blinded by partisan zeal."
            },
            {
                "spelling_detail_id": 98597,
                "word_type": "a.",
                "spelling_usage": "Serving as a partisan in a detached command; as, a\n   partisan officer or corps."
            },
            {
                "spelling_detail_id": 98598,
                "word_type": "n.",
                "spelling_usage": "A kind of halberd or pike; also, a truncheon; a staff."
            }
        ]
    },
    {
        "id": 63844,
        "spelling_name": "Patron",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99129,
                "word_type": "n.",
                "spelling_usage": "One who protects, supports, or countenances; a defender."
            },
            {
                "spelling_detail_id": 99130,
                "word_type": "n.",
                "spelling_usage": "A master who had freed his slave, but still retained some\n   paternal rights over him."
            },
            {
                "spelling_detail_id": 99131,
                "word_type": "n.",
                "spelling_usage": "A man of distinction under whose protection another person\n   placed himself."
            },
            {
                "spelling_detail_id": 99132,
                "word_type": "n.",
                "spelling_usage": "An advocate or pleader."
            },
            {
                "spelling_detail_id": 99133,
                "word_type": "n.",
                "spelling_usage": "One who encourages or helps a person, a cause, or a work; a\n   furtherer; a promoter; as, a patron of art."
            },
            {
                "spelling_detail_id": 99134,
                "word_type": "n.",
                "spelling_usage": "One who has gift and disposition of a benefice."
            },
            {
                "spelling_detail_id": 99135,
                "word_type": "n.",
                "spelling_usage": "A guardian saint. -- called also patron saint."
            },
            {
                "spelling_detail_id": 99136,
                "word_type": "n.",
                "spelling_usage": "See Padrone, 2."
            },
            {
                "spelling_detail_id": 99137,
                "word_type": "v. t.",
                "spelling_usage": "To be a patron of; to patronize; to favor."
            },
            {
                "spelling_detail_id": 99138,
                "word_type": "a.",
                "spelling_usage": "Doing the duty of a patron; giving aid or protection;\n   tutelary."
            }
        ]
    },
    {
        "id": 63882,
        "spelling_name": "Paucity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99200,
                "word_type": "n.",
                "spelling_usage": "Fewness; smallness of number; scarcity."
            },
            {
                "spelling_detail_id": 99201,
                "word_type": "n.",
                "spelling_usage": "Smallnes of quantity; exiguity; insufficiency; as, paucity\n   of blood."
            }
        ]
    },
    {
        "id": 64054,
        "spelling_name": "Peccadillo",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99492,
                "word_type": "n.",
                "spelling_usage": "A slight trespass or offense; a petty crime or fault."
            }
        ]
    },
    {
        "id": 64132,
        "spelling_name": "Pedantic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99612,
                "word_type": "a.",
                "spelling_usage": "Alt. of Pedantical"
            }
        ]
    },
    {
        "id": 64162,
        "spelling_name": "Pedestrian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99649,
                "word_type": "a.",
                "spelling_usage": "Going on foot; performed on foot; as, a pedestrian\n   journey."
            },
            {
                "spelling_detail_id": 99650,
                "word_type": "n.",
                "spelling_usage": "A walker; one who journeys on foot; a foot traveler;\n   specif., a professional walker or runner."
            }
        ]
    },
    {
        "id": 64318,
        "spelling_name": "Pellucid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99867,
                "word_type": "a.",
                "spelling_usage": "Transparent; clear; limpid; translucent; not opaque."
            }
        ]
    },
    {
        "id": 64364,
        "spelling_name": "Penchant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 99948,
                "word_type": "n.",
                "spelling_usage": "Inclination; decided taste; bias; as, a penchant for art."
            }
        ]
    },
    {
        "id": 64432,
        "spelling_name": "Penitent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100052,
                "word_type": "a.",
                "spelling_usage": "Feeling pain or sorrow on account of sins or offenses;\n   repentant; contrite; sincerely affected by a sense of guilt, and\n   resolved on amendment of life."
            },
            {
                "spelling_detail_id": 100053,
                "word_type": "a.",
                "spelling_usage": "Doing penance."
            },
            {
                "spelling_detail_id": 100054,
                "word_type": "n.",
                "spelling_usage": "One who repents of sin; one sorrowful on account of his\n   transgressions."
            },
            {
                "spelling_detail_id": 100055,
                "word_type": "n.",
                "spelling_usage": "One under church censure, but admitted to penance; one\n   undergoing penance."
            },
            {
                "spelling_detail_id": 100056,
                "word_type": "n.",
                "spelling_usage": "One under the direction of a confessor."
            }
        ]
    },
    {
        "id": 64602,
        "spelling_name": "Penurious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100274,
                "word_type": "a.",
                "spelling_usage": "Excessively sparing in the use of money; sordid; stingy;\n   miserly."
            },
            {
                "spelling_detail_id": 100275,
                "word_type": "a.",
                "spelling_usage": "Not bountiful or liberal; scanty."
            },
            {
                "spelling_detail_id": 100276,
                "word_type": "a.",
                "spelling_usage": "Destitute of money; suffering extreme want."
            }
        ]
    },
    {
        "id": 64603,
        "spelling_name": "Penury",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100277,
                "word_type": "n.",
                "spelling_usage": "Absence of resources; want; privation; indigence; extreme\n   poverty; destitution."
            },
            {
                "spelling_detail_id": 100278,
                "word_type": "n.",
                "spelling_usage": "Penuriousness; miserliness."
            }
        ]
    },
    {
        "id": 64694,
        "spelling_name": "Perch",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100408,
                "word_type": "n.",
                "spelling_usage": "Any fresh-water fish of the genus Perca and of several other\n   allied genera of the family Percidae, as the common American or yellow\n   perch (Perca flavescens, / Americana), and the European perch (P.\n   fluviatilis)."
            },
            {
                "spelling_detail_id": 100409,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of spiny-finned fishes belonging\n   to the Percidae, Serranidae, and related families, and resembling, more\n   or less, the true perches."
            },
            {
                "spelling_detail_id": 100410,
                "word_type": "n.",
                "spelling_usage": "A pole; a long staff; a rod; esp., a pole or other support\n   for fowls to roost on or to rest on; a roost; figuratively, any\n   elevated resting place or seat."
            },
            {
                "spelling_detail_id": 100411,
                "word_type": "n.",
                "spelling_usage": "A measure of length containing five and a half yards; a rod,\n   or pole."
            },
            {
                "spelling_detail_id": 100412,
                "word_type": "n.",
                "spelling_usage": "In land or square measure: A square rod; the 160th part of\n   an acre."
            },
            {
                "spelling_detail_id": 100413,
                "word_type": "n.",
                "spelling_usage": "In solid measure: A mass 16/ feet long, 1 foot in height,\n   and 1/ feet in breadth, or 24/ cubic feet (in local use, from 22 to 25\n   cubic feet); -- used in measuring stonework."
            },
            {
                "spelling_detail_id": 100414,
                "word_type": "n.",
                "spelling_usage": "A pole connecting the fore gear and hind gear of a spring\n   carriage; a reach."
            },
            {
                "spelling_detail_id": 100417,
                "word_type": "v. i.",
                "spelling_usage": "To alight or settle, as a bird; to sit or roost."
            },
            {
                "spelling_detail_id": 100418,
                "word_type": "v. t.",
                "spelling_usage": "To place or to set on, or as on, a perch."
            },
            {
                "spelling_detail_id": 100419,
                "word_type": "v. t.",
                "spelling_usage": "To occupy as a perch."
            }
        ]
    },
    {
        "id": 64747,
        "spelling_name": "Peregrination",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100485,
                "word_type": "n.",
                "spelling_usage": "A traveling from one country to another; a\n   wandering; sojourn in foreign countries."
            }
        ]
    },
    {
        "id": 64756,
        "spelling_name": "Peremptory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100496,
                "word_type": "a.",
                "spelling_usage": "Precluding debate or expostulation; not admitting of\n   question or appeal; positive; absolute; decisive; conclusive; final."
            },
            {
                "spelling_detail_id": 100497,
                "word_type": "a.",
                "spelling_usage": "Positive in opinion or judgment; decided; dictatorial;\n   dogmatical."
            },
            {
                "spelling_detail_id": 100498,
                "word_type": "a.",
                "spelling_usage": "Firmly determined; unawed."
            }
        ]
    },
    {
        "id": 64784,
        "spelling_name": "Perfidious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100536,
                "word_type": "a.",
                "spelling_usage": "Guilty of perfidy; violating good faith or vows; false\n   to trust or confidence reposed; teacherous; faithless; as, a perfidious\n   friend."
            },
            {
                "spelling_detail_id": 100537,
                "word_type": "a.",
                "spelling_usage": "Involving, or characterized by, perfidy."
            }
        ]
    },
    {
        "id": 64787,
        "spelling_name": "Perfidy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100541,
                "word_type": "n.",
                "spelling_usage": "The act of violating faith or allegiance; violation of a\n   promise or vow, or of trust reposed; faithlessness; treachery."
            }
        ]
    },
    {
        "id": 64815,
        "spelling_name": "Perfunctorily",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100583,
                "word_type": "adv.",
                "spelling_usage": "In a perfunctory manner; formally; carelessly."
            }
        ]
    },
    {
        "id": 64817,
        "spelling_name": "Perfunctory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100585,
                "word_type": "a.",
                "spelling_usage": "Done merely to get rid of a duty; performed\n   mechanically and as a thing of rote; done in a careless and superficial\n   manner; characterized by indifference; as, perfunctory admonitions."
            },
            {
                "spelling_detail_id": 100586,
                "word_type": "a.",
                "spelling_usage": "Hence: Mechanical; indifferent; listless; careless."
            }
        ]
    },
    {
        "id": 64907,
        "spelling_name": "Perilous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100687,
                "word_type": "a.",
                "spelling_usage": "Full of, attended with, or involving, peril; dangerous;\n   hazardous; as, a perilous undertaking."
            },
            {
                "spelling_detail_id": 100688,
                "word_type": "a.",
                "spelling_usage": "Daring; reckless; dangerous."
            }
        ]
    },
    {
        "id": 64950,
        "spelling_name": "Peripatetic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100750,
                "word_type": "a.",
                "spelling_usage": "Walking about; itinerant."
            },
            {
                "spelling_detail_id": 100751,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the philosophy taught by Aristotle\n   (who gave his instructions while walking in the Lyceum at Athens), or\n   to his followers."
            },
            {
                "spelling_detail_id": 100752,
                "word_type": "n.",
                "spelling_usage": "One who walks about; a pedestrian; an itinerant."
            },
            {
                "spelling_detail_id": 100753,
                "word_type": "n.",
                "spelling_usage": "A disciple of Aristotle; an Aristotelian."
            }
        ]
    },
    {
        "id": 64983,
        "spelling_name": "Perish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100794,
                "word_type": "v. i.",
                "spelling_usage": "To be destroyed; to pass away; to become nothing; to be\n   lost; to die; hence, to wither; to waste away."
            },
            {
                "spelling_detail_id": 100795,
                "word_type": "v. t.",
                "spelling_usage": "To cause perish."
            }
        ]
    },
    {
        "id": 65051,
        "spelling_name": "Perjury",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100872,
                "word_type": "v.",
                "spelling_usage": "False swearing."
            },
            {
                "spelling_detail_id": 100873,
                "word_type": "v.",
                "spelling_usage": "At common law, a willfully false statement in a fact\n   material to the issue, made by a witness under oath in a competent\n   judicial proceeding. By statute the penalties of perjury are imposed on\n   the making of willfully false affirmations."
            }
        ]
    },
    {
        "id": 65076,
        "spelling_name": "Permeate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100903,
                "word_type": "v. t.",
                "spelling_usage": "To pass through the pores or interstices of; to\n   penetrate and pass through without causing rupture or displacement; --\n   applied especially to fluids which pass through substances of loose\n   texture; as, water permeates sand."
            },
            {
                "spelling_detail_id": 100904,
                "word_type": "v. t.",
                "spelling_usage": "To enter and spread through; to pervade."
            }
        ]
    },
    {
        "id": 65106,
        "spelling_name": "Pernicious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100943,
                "word_type": "a.",
                "spelling_usage": "Quick; swift (to burn)."
            },
            {
                "spelling_detail_id": 100944,
                "word_type": "a.",
                "spelling_usage": "Having the quality of injuring or killing; destructive;\n   very mischievous; baleful; malicious; wicked."
            }
        ]
    },
    {
        "id": 65137,
        "spelling_name": "Perpetrate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 100981,
                "word_type": "v. t.",
                "spelling_usage": "To do or perform; to carry through; to execute,\n   commonly in a bad sense; to commit (as a crime, an offense); to be\n   guilty of; as, to perpetrate a foul deed."
            }
        ]
    },
    {
        "id": 65209,
        "spelling_name": "Personable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101094,
                "word_type": "a.",
                "spelling_usage": "Having a well-formed body, or person; graceful; comely;\n   of good appearance; presentable; as, a personable man or woman."
            },
            {
                "spelling_detail_id": 101095,
                "word_type": "a.",
                "spelling_usage": "Enabled to maintain pleas in court."
            },
            {
                "spelling_detail_id": 101096,
                "word_type": "a.",
                "spelling_usage": "Having capacity to take anything granted."
            }
        ]
    },
    {
        "id": 65240,
        "spelling_name": "Perspicacity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101154,
                "word_type": "n.",
                "spelling_usage": "The state of being perspicacious; acuteness of sight\n   or of intelligence; acute discernment."
            }
        ]
    },
    {
        "id": 65273,
        "spelling_name": "Pertain",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101212,
                "word_type": "v. i.",
                "spelling_usage": "To belong; to have connection with, or dependence on,\n   something, as an appurtenance, attribute, etc.; to appertain; as,\n   saltness pertains to the ocean; flowers pertain to plant life."
            },
            {
                "spelling_detail_id": 101213,
                "word_type": "v. i.",
                "spelling_usage": "To have relation or reference to something."
            }
        ]
    },
    {
        "id": 65316,
        "spelling_name": "Pervade",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101268,
                "word_type": "v. t.",
                "spelling_usage": "To pass or flow through, as an aperture, pore, or\n   interstice; to permeate."
            },
            {
                "spelling_detail_id": 101269,
                "word_type": "v. t.",
                "spelling_usage": "To pass or spread through the whole extent of; to be\n   diffused throughout."
            }
        ]
    },
    {
        "id": 65368,
        "spelling_name": "Pest",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101329,
                "word_type": "n.",
                "spelling_usage": "A fatal epidemic disease; a pestilence; specif., the plague."
            },
            {
                "spelling_detail_id": 101330,
                "word_type": "n.",
                "spelling_usage": "Anything which resembles a pest; one who, or that which, is\n   troublesome, noxious, mischievous, or destructive; a nuisance."
            }
        ]
    },
    {
        "id": 65456,
        "spelling_name": "Petrified",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101446,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Petrify"
            }
        ]
    },
    {
        "id": 65457,
        "spelling_name": "Petrify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101448,
                "word_type": "v. t.",
                "spelling_usage": "To convert, as any animal or vegetable matter, into\n   stone or stony substance."
            },
            {
                "spelling_detail_id": 101449,
                "word_type": "v. t.",
                "spelling_usage": "To make callous or obdurate; to stupefy; to paralyze;\n   to transform; as by petrifaction; as, to petrify the heart. Young."
            },
            {
                "spelling_detail_id": 101450,
                "word_type": "v. i.",
                "spelling_usage": "To become stone, or of a stony hardness, as organic\n   matter by calcareous deposits."
            },
            {
                "spelling_detail_id": 101451,
                "word_type": "v. i.",
                "spelling_usage": "Fig.: To become stony, callous, or obdurate."
            }
        ]
    },
    {
        "id": 65486,
        "spelling_name": "Petrous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101484,
                "word_type": "a.",
                "spelling_usage": "Like stone; hard; stony; rocky; as, the petrous part of\n   the temporal bone."
            },
            {
                "spelling_detail_id": 101485,
                "word_type": "a.",
                "spelling_usage": "Same as Petrosal."
            }
        ]
    },
    {
        "id": 65507,
        "spelling_name": "Petulant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101508,
                "word_type": "a.",
                "spelling_usage": "Forward; pert; insolent; wanton."
            },
            {
                "spelling_detail_id": 101509,
                "word_type": "a.",
                "spelling_usage": "Capriciously fretful; characterized by ill-natured\n   freakishness; irritable."
            }
        ]
    },
    {
        "id": 65723,
        "spelling_name": "Philanthropist",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101778,
                "word_type": "n.",
                "spelling_usage": "One who practices philanthropy; one who loves\n   mankind, and seeks to promote the good of others."
            }
        ]
    },
    {
        "id": 65745,
        "spelling_name": "Philistine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101804,
                "word_type": "n.",
                "spelling_usage": "A native or an inhabitant of ancient Philistia, a coast\n   region of southern Palestine."
            },
            {
                "spelling_detail_id": 101805,
                "word_type": "n.",
                "spelling_usage": "A bailiff."
            },
            {
                "spelling_detail_id": 101806,
                "word_type": "n.",
                "spelling_usage": "A person deficient in liberal culture and refinement;\n   one without appreciation of the nobler aspirations and sentiments of\n   humanity; one whose scope is limited to selfish and material interests."
            },
            {
                "spelling_detail_id": 101807,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the Philistines."
            },
            {
                "spelling_detail_id": 101808,
                "word_type": "a.",
                "spelling_usage": "Uncultured; commonplace."
            }
        ]
    },
    {
        "id": 65818,
        "spelling_name": "Phlegmatic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 101897,
                "word_type": "a.",
                "spelling_usage": "Watery."
            },
            {
                "spelling_detail_id": 101898,
                "word_type": "a.",
                "spelling_usage": "Abounding in phlegm; as, phlegmatic humors; a\n   phlegmatic constitution."
            },
            {
                "spelling_detail_id": 101899,
                "word_type": "a.",
                "spelling_usage": "Generating or causing phlegm."
            },
            {
                "spelling_detail_id": 101900,
                "word_type": "a.",
                "spelling_usage": "Not easily excited to action or passion; cold; dull;\n   sluggish; heavy; as, a phlegmatic person."
            }
        ]
    },
    {
        "id": 66282,
        "spelling_name": "Picaresque",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 102440,
                "word_type": "a.",
                "spelling_usage": "Applied to that class of literature in which the\n   principal personage is the Spanish picaro, meaning a rascal, a knave, a\n   rogue, an adventurer."
            }
        ]
    },
    {
        "id": 66400,
        "spelling_name": "Pied",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 102398,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Pi"
            },
            {
                "spelling_detail_id": 102654,
                "word_type": "",
                "spelling_usage": "imp. & p. p. of Pi, or Pie, v."
            },
            {
                "spelling_detail_id": 102655,
                "word_type": "a.",
                "spelling_usage": "Variegated with spots of different colors; party-colored;\n   spotted; piebald."
            }
        ]
    },
    {
        "id": 66515,
        "spelling_name": "Pileous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 102829,
                "word_type": "a.",
                "spelling_usage": "Consisting of, or covered with, hair; hairy; pilose."
            }
        ]
    },
    {
        "id": 66523,
        "spelling_name": "Pilferer",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 102843,
                "word_type": "n.",
                "spelling_usage": "One who pilfers; a petty thief."
            }
        ]
    },
    {
        "id": 66538,
        "spelling_name": "Pillage",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 102874,
                "word_type": "n.",
                "spelling_usage": "The act of pillaging; robbery."
            },
            {
                "spelling_detail_id": 102875,
                "word_type": "n.",
                "spelling_usage": "That which is taken from another or others by open force,\n   particularly and chiefly from enemies in war; plunder; spoil; booty."
            },
            {
                "spelling_detail_id": 102878,
                "word_type": "v. i.",
                "spelling_usage": "To strip of money or goods by open violence; to\n   plunder; to spoil; to lay waste; as, to pillage the camp of an enemy."
            },
            {
                "spelling_detail_id": 102879,
                "word_type": "v. i.",
                "spelling_usage": "To take spoil; to plunder; to ravage."
            }
        ]
    },
    {
        "id": 66615,
        "spelling_name": "Pinch",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103000,
                "word_type": "v. t.",
                "spelling_usage": "To press hard or squeeze between the ends of the fingers,\n   between teeth or claws, or between the jaws of an instrument; to\n   squeeze or compress, as between any two hard bodies."
            },
            {
                "spelling_detail_id": 103001,
                "word_type": "v. t.",
                "spelling_usage": "o seize; to grip; to bite; -- said of animals."
            },
            {
                "spelling_detail_id": 103002,
                "word_type": "v. t.",
                "spelling_usage": "To plait."
            },
            {
                "spelling_detail_id": 103003,
                "word_type": "v. t.",
                "spelling_usage": "Figuratively: To cramp; to straiten; to oppress; to\n   starve; to distress; as, to be pinched for money."
            },
            {
                "spelling_detail_id": 103004,
                "word_type": "v. t.",
                "spelling_usage": "To move, as a railroad car, by prying the wheels with a\n   pinch. See Pinch, n., 4."
            },
            {
                "spelling_detail_id": 103005,
                "word_type": "v. i.",
                "spelling_usage": "To act with pressing force; to compress; to squeeze; as,\n   the shoe pinches."
            },
            {
                "spelling_detail_id": 103006,
                "word_type": "v. i.",
                "spelling_usage": "To take hold; to grip, as a dog does."
            },
            {
                "spelling_detail_id": 103007,
                "word_type": "v. i.",
                "spelling_usage": "To spare; to be niggardly; to be covetous."
            },
            {
                "spelling_detail_id": 103008,
                "word_type": "n.",
                "spelling_usage": "A close compression, as with the ends of the fingers, or\n   with an instrument; a nip."
            },
            {
                "spelling_detail_id": 103009,
                "word_type": "n.",
                "spelling_usage": "As much as may be taken between the finger and thumb; any\n   very small quantity; as, a pinch of snuff."
            },
            {
                "spelling_detail_id": 103010,
                "word_type": "n.",
                "spelling_usage": "Pian; pang."
            },
            {
                "spelling_detail_id": 103011,
                "word_type": "n.",
                "spelling_usage": "A lever having a projection at one end, acting as a fulcrum,\n   -- used chiefly to roll heavy wheels, etc. Called also pinch bar."
            }
        ]
    },
    {
        "id": 66616,
        "spelling_name": "Pinchbeck",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103012,
                "word_type": "n.",
                "spelling_usage": "An alloy of copper and zinc, resembling gold; a yellow\n   metal, composed of about three ounces of zinc to a pound of copper. It\n   is much used as an imitation of gold in the manufacture of cheap\n   jewelry."
            },
            {
                "spelling_detail_id": 103013,
                "word_type": "a.",
                "spelling_usage": "Made of pinchbeck; sham; cheap; spurious; unreal."
            }
        ]
    },
    {
        "id": 66636,
        "spelling_name": "Pine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103033,
                "word_type": "n.",
                "spelling_usage": "Woe; torment; pain."
            },
            {
                "spelling_detail_id": 103036,
                "word_type": "v.",
                "spelling_usage": "To inflict pain upon; to torment; to torture; to afflict."
            },
            {
                "spelling_detail_id": 103037,
                "word_type": "v.",
                "spelling_usage": "To grieve or mourn for."
            },
            {
                "spelling_detail_id": 103038,
                "word_type": "v. i.",
                "spelling_usage": "To suffer; to be afflicted."
            },
            {
                "spelling_detail_id": 103039,
                "word_type": "v. i.",
                "spelling_usage": "To languish; to lose flesh or wear away, under any\n   distress or anexiety of mind; to droop; -- often used with away."
            },
            {
                "spelling_detail_id": 103040,
                "word_type": "v. i.",
                "spelling_usage": "To languish with desire; to waste away with longing for\n   something; -- usually followed by for."
            },
            {
                "spelling_detail_id": 103041,
                "word_type": "n.",
                "spelling_usage": "Any tree of the coniferous genus Pinus. See Pinus."
            },
            {
                "spelling_detail_id": 103042,
                "word_type": "n.",
                "spelling_usage": "The wood of the pine tree."
            },
            {
                "spelling_detail_id": 103043,
                "word_type": "n.",
                "spelling_usage": "A pineapple."
            }
        ]
    },
    {
        "id": 66741,
        "spelling_name": "Pious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103210,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to piety; exhibiting piety; reverential;\n   dutiful; religious; devout; godly."
            },
            {
                "spelling_detail_id": 103211,
                "word_type": "a.",
                "spelling_usage": "Practiced under the pretext of religion; prompted by\n   mistaken piety; as, pious errors; pious frauds."
            }
        ]
    },
    {
        "id": 66786,
        "spelling_name": "Piquant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103292,
                "word_type": "a.",
                "spelling_usage": "Stimulating to the taste; giving zest; tart; sharp;\n   pungent; as, a piquant anecdote."
            }
        ]
    },
    {
        "id": 66788,
        "spelling_name": "Pique",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103294,
                "word_type": "n.",
                "spelling_usage": "A cotton fabric, figured in the loom, -- used as a dress\n   goods for women and children, and for vestings, etc."
            },
            {
                "spelling_detail_id": 103295,
                "word_type": "n.",
                "spelling_usage": "The jigger. See Jigger."
            },
            {
                "spelling_detail_id": 103296,
                "word_type": "n.",
                "spelling_usage": "A feeling of hurt, vexation, or resentment, awakened by a\n   social slight or injury; irritation of the feelings, as through wounded\n   pride; stinging vexation."
            },
            {
                "spelling_detail_id": 103297,
                "word_type": "n.",
                "spelling_usage": "Keenly felt desire; a longing."
            },
            {
                "spelling_detail_id": 103298,
                "word_type": "n.",
                "spelling_usage": "In piquet, the right of the elder hand to count thirty in\n   hand, or to play before the adversary counts one."
            },
            {
                "spelling_detail_id": 103301,
                "word_type": "v. t.",
                "spelling_usage": "To wound the pride of; to sting; to nettle; to irritate;\n   to fret; to offend; to excite to anger."
            },
            {
                "spelling_detail_id": 103302,
                "word_type": "v. t.",
                "spelling_usage": "To excite to action by causing resentment or jealousy; to\n   stimulate; to prick; as, to pique ambition, or curiosity."
            },
            {
                "spelling_detail_id": 103303,
                "word_type": "v. t.",
                "spelling_usage": "To pride or value; -- used reflexively."
            },
            {
                "spelling_detail_id": 103304,
                "word_type": "v. i.",
                "spelling_usage": "To cause annoyance or irritation."
            }
        ]
    },
    {
        "id": 66891,
        "spelling_name": "Pitfall",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103477,
                "word_type": "n.",
                "spelling_usage": "A pit deceitfully covered to entrap wild beasts or men; a\n   trap of any kind."
            }
        ]
    },
    {
        "id": 66893,
        "spelling_name": "Pith",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103479,
                "word_type": "n.",
                "spelling_usage": "The soft spongy substance in the center of the stems of many\n   plants and trees, especially those of the dicotyledonous or exogenous\n   classes. It consists of cellular tissue."
            },
            {
                "spelling_detail_id": 103480,
                "word_type": "n.",
                "spelling_usage": "The spongy interior substance of a feather."
            },
            {
                "spelling_detail_id": 103481,
                "word_type": "n.",
                "spelling_usage": "The spinal cord; the marrow."
            },
            {
                "spelling_detail_id": 103482,
                "word_type": "n.",
                "spelling_usage": "Hence: The which contains the strength of life; the vital or\n   essential part; concentrated force; vigor; strength; importance; as,\n   the speech lacked pith."
            },
            {
                "spelling_detail_id": 103483,
                "word_type": "v. t.",
                "spelling_usage": "To destroy the central nervous system of (an animal, as a\n   frog), as by passing a stout wire or needle up and down the vertebral\n   canal."
            }
        ]
    },
    {
        "id": 66929,
        "spelling_name": "Pivotal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103541,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a pivot or turning point; belonging\n   to, or constituting, a pivot; of the nature of a pivot; as, the\n   pivotalopportunity of a career; the pivotal position in a battle."
            }
        ]
    },
    {
        "id": 66945,
        "spelling_name": "Placate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103562,
                "word_type": "n.",
                "spelling_usage": "Same as Placard, 4 & 5."
            },
            {
                "spelling_detail_id": 103565,
                "word_type": "v. t.",
                "spelling_usage": "To appease; to pacify; to concilate."
            }
        ]
    },
    {
        "id": 67042,
        "spelling_name": "Plaintive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103722,
                "word_type": "n.",
                "spelling_usage": "Repining; complaining; lamenting."
            },
            {
                "spelling_detail_id": 103723,
                "word_type": "n.",
                "spelling_usage": "Expressive of sorrow or melancholy; mournful; sad."
            }
        ]
    },
    {
        "id": 67143,
        "spelling_name": "Plaque",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 103891,
                "word_type": "n.",
                "spelling_usage": "Any flat, thin piece of metal, clay, ivory, or the like,\n   used for ornament, or for painting pictures upon, as a slab, plate,\n   dish, or the like, hung upon a wall; also, a smaller decoration worn on\n   the person, as a brooch."
            }
        ]
    },
    {
        "id": 67223,
        "spelling_name": "Platitude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104052,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being flat, thin, or insipid;\n   flat commonness; triteness; staleness of ideas of language."
            },
            {
                "spelling_detail_id": 104053,
                "word_type": "n.",
                "spelling_usage": "A thought or remark which is flat, dull, trite, or weak;\n   a truism; a commonplace."
            }
        ]
    },
    {
        "id": 67297,
        "spelling_name": "Plea",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104169,
                "word_type": "n.",
                "spelling_usage": "That which is alleged by a party in support of his cause; in\n   a stricter sense, an allegation of fact in a cause, as distinguished\n   from a demurrer; in a still more limited sense, and in modern practice,\n   the defendant's answer to the plaintiff's declaration and demand. That\n   which the plaintiff alleges in his declaration is answered and repelled\n   or justified by the defendant's plea. In chancery practice, a plea is a\n   special answer showing or relying upon one or more things as a cause\n   why the suit should be either dismissed, delayed, or barred. In\n   criminal practice, the plea is the defendant's formal answer to the\n   indictment or information presented against him."
            },
            {
                "spelling_detail_id": 104170,
                "word_type": "n.",
                "spelling_usage": "A cause in court; a lawsuit; as, the Court of Common Pleas.\n   See under Common."
            },
            {
                "spelling_detail_id": 104171,
                "word_type": "n.",
                "spelling_usage": "That which is alleged or pleaded, in defense or in\n   justification; an excuse; an apology."
            },
            {
                "spelling_detail_id": 104172,
                "word_type": "n.",
                "spelling_usage": "An urgent prayer or entreaty."
            }
        ]
    },
    {
        "id": 67301,
        "spelling_name": "Plead",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104177,
                "word_type": "",
                "spelling_usage": "of Plead"
            },
            {
                "spelling_detail_id": 104180,
                "word_type": "v. t.",
                "spelling_usage": "To argue in support of a claim, or in defense against the\n   claim of another; to urge reasons for or against a thing; to attempt to\n   persuade one by argument or supplication; to speak by way of\n   persuasion; as, to plead for the life of a criminal; to plead with a\n   judge or with a father."
            },
            {
                "spelling_detail_id": 104181,
                "word_type": "v. t.",
                "spelling_usage": "To present an answer, by allegation of fact, to the\n   declaration of a plaintiff; to deny the plaintiff's declaration and\n   demand, or to allege facts which show that ought not to recover in the\n   suit; in a less strict sense, to make an allegation of fact in a cause;\n   to carry on the allegations of the respective parties in a cause; to\n   carry on a suit or plea."
            },
            {
                "spelling_detail_id": 104182,
                "word_type": "v. t.",
                "spelling_usage": "To contend; to struggle."
            },
            {
                "spelling_detail_id": 104183,
                "word_type": "v. t.",
                "spelling_usage": "To discuss, defend, and attempt to maintain by arguments\n   or reasons presented to a tribunal or person having uthority to\n   determine; to argue at the bar; as, to plead a cause before a court or\n   jury."
            },
            {
                "spelling_detail_id": 104184,
                "word_type": "v. t.",
                "spelling_usage": "To allege or cite in a legal plea or defense, or for\n   repelling a demand in law; to answer to an indictment; as, to plead\n   usury; to plead statute of limitations; to plead not guilty."
            },
            {
                "spelling_detail_id": 104185,
                "word_type": "v. t.",
                "spelling_usage": "To allege or adduce in proof, support, or vendication; to\n   offer in excuse; as, the law of nations may be pleaded in favor of the\n   rights of ambassadors."
            }
        ]
    },
    {
        "id": 67425,
        "spelling_name": "Plethora",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104357,
                "word_type": "n.",
                "spelling_usage": "Overfullness; especially, excessive fullness of the blood\n   vessels; repletion; that state of the blood vessels or of the system\n   when the blood exceeds a healthy standard in quantity; hyperaemia; --\n   opposed to anaemia."
            },
            {
                "spelling_detail_id": 104358,
                "word_type": "n.",
                "spelling_usage": "State of being overfull; excess; superabundance."
            }
        ]
    },
    {
        "id": 67487,
        "spelling_name": "Pliant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104433,
                "word_type": "v.",
                "spelling_usage": "Capable of plying or bending; readily yielding to force or\n   pressure without breaking; flexible; pliable; lithe; limber; plastic;\n   as, a pliant thread; pliant wax. Also used figuratively: Easily\n   influenced for good or evil; tractable; as, a pliant heart."
            },
            {
                "spelling_detail_id": 104434,
                "word_type": "v.",
                "spelling_usage": "Favorable to pliancy."
            }
        ]
    },
    {
        "id": 67510,
        "spelling_name": "Plod",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104469,
                "word_type": "v. i.",
                "spelling_usage": "To travel slowly but steadily; to trudge."
            },
            {
                "spelling_detail_id": 104470,
                "word_type": "v. i.",
                "spelling_usage": "To toil; to drudge; especially, to study laboriously and\n   patiently."
            },
            {
                "spelling_detail_id": 104471,
                "word_type": "v. t.",
                "spelling_usage": "To walk on slowly or heavily."
            }
        ]
    },
    {
        "id": 67560,
        "spelling_name": "Pluck",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104552,
                "word_type": "v. t.",
                "spelling_usage": "To pull; to draw."
            },
            {
                "spelling_detail_id": 104553,
                "word_type": "v. t.",
                "spelling_usage": "Especially, to pull with sudden force or effort, or to\n   pull off or out from something, with a twitch; to twitch; also, to\n   gather, to pick; as, to pluck feathers from a fowl; to pluck hair or\n   wool from a skin; to pluck grapes."
            },
            {
                "spelling_detail_id": 104554,
                "word_type": "v. t.",
                "spelling_usage": "To strip of, or as of, feathers; as, to pluck a fowl."
            },
            {
                "spelling_detail_id": 104555,
                "word_type": "v. t.",
                "spelling_usage": "To reject at an examination for degrees."
            },
            {
                "spelling_detail_id": 104556,
                "word_type": "v. i.",
                "spelling_usage": "To make a motion of pulling or twitching; -- usually with\n   at; as, to pluck at one's gown."
            },
            {
                "spelling_detail_id": 104557,
                "word_type": "n.",
                "spelling_usage": "The act of plucking; a pull; a twitch."
            },
            {
                "spelling_detail_id": 104558,
                "word_type": "n.",
                "spelling_usage": "The heart, liver, and lights of an animal."
            },
            {
                "spelling_detail_id": 104559,
                "word_type": "n.",
                "spelling_usage": "Spirit; courage; indomitable resolution; fortitude."
            },
            {
                "spelling_detail_id": 104560,
                "word_type": "n.",
                "spelling_usage": "The act of plucking, or the state of being plucked, at\n   college. See Pluck, v. t., 4."
            },
            {
                "spelling_detail_id": 104561,
                "word_type": "v. t.",
                "spelling_usage": "The lyrie."
            }
        ]
    },
    {
        "id": 67579,
        "spelling_name": "Plumb",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104591,
                "word_type": "n.",
                "spelling_usage": "A little mass or weight of lead, or the like, attached to a\n   line, and used by builders, etc., to indicate a vertical direction; a\n   plummet; a plumb bob. See Plumb line, below."
            },
            {
                "spelling_detail_id": 104592,
                "word_type": "a.",
                "spelling_usage": "Perpendicular; vertical; conforming the direction of a line\n   attached to a plumb; as, the wall is plumb."
            },
            {
                "spelling_detail_id": 104593,
                "word_type": "adv.",
                "spelling_usage": "In a plumb direction; perpendicularly."
            },
            {
                "spelling_detail_id": 104596,
                "word_type": "v. t.",
                "spelling_usage": "To adjust by a plumb line; to cause to be perpendicular;\n   as, to plumb a building or a wall."
            },
            {
                "spelling_detail_id": 104597,
                "word_type": "v. t.",
                "spelling_usage": "To sound with a plumb or plummet, as the depth of water;\n   hence, to examine by test; to ascertain the depth, quality, dimension,\n   etc.; to sound; to fathom; to test."
            },
            {
                "spelling_detail_id": 104598,
                "word_type": "v. t.",
                "spelling_usage": "To seal with lead; as, to plumb a drainpipe."
            },
            {
                "spelling_detail_id": 104599,
                "word_type": "v. t.",
                "spelling_usage": "To supply, as a building, with a system of plumbing."
            }
        ]
    },
    {
        "id": 67607,
        "spelling_name": "Plummet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104639,
                "word_type": "n.",
                "spelling_usage": "A piece of lead attached to a line, used in sounding the\n   depth of water."
            },
            {
                "spelling_detail_id": 104640,
                "word_type": "n.",
                "spelling_usage": "A plumb bob or a plumb line. See under Plumb, n."
            },
            {
                "spelling_detail_id": 104641,
                "word_type": "n.",
                "spelling_usage": "Hence, any weight."
            },
            {
                "spelling_detail_id": 104642,
                "word_type": "n.",
                "spelling_usage": "A piece of lead formerly used by school children to rule\n   paper for writing."
            }
        ]
    },
    {
        "id": 67636,
        "spelling_name": "Plunge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104695,
                "word_type": "v. t.",
                "spelling_usage": "To thrust into water, or into any substance that is\n   penetrable; to immerse; to cause to penetrate or enter quickly and\n   forcibly; to thrust; as, to plunge the body into water; to plunge a\n   dagger into the breast. Also used figuratively; as, to plunge a nation\n   into war."
            },
            {
                "spelling_detail_id": 104696,
                "word_type": "v. t.",
                "spelling_usage": "To baptize by immersion."
            },
            {
                "spelling_detail_id": 104697,
                "word_type": "v. t.",
                "spelling_usage": "To entangle; to embarrass; to overcome."
            },
            {
                "spelling_detail_id": 104698,
                "word_type": "v. i.",
                "spelling_usage": "To thrust or cast one's self into water or other fluid;\n   to submerge one's self; to dive, or to rush in; as, he plunged into the\n   river. Also used figuratively; as, to plunge into debt."
            },
            {
                "spelling_detail_id": 104699,
                "word_type": "v. i.",
                "spelling_usage": "To pitch or throw one's self headlong or violently\n   forward, as a horse does."
            },
            {
                "spelling_detail_id": 104700,
                "word_type": "v. i.",
                "spelling_usage": "To bet heavily and with seeming recklessness on a race,\n   or other contest; in an extended sense, to risk large sums in hazardous\n   speculations."
            },
            {
                "spelling_detail_id": 104701,
                "word_type": "n.",
                "spelling_usage": "The act of thrusting into or submerging; a dive, leap,\n   rush, or pitch into, or as into, water; as, to take the water with a\n   plunge."
            },
            {
                "spelling_detail_id": 104702,
                "word_type": "n.",
                "spelling_usage": "Hence, a desperate hazard or act; a state of being\n   submerged or overwhelmed with difficulties."
            },
            {
                "spelling_detail_id": 104703,
                "word_type": "n.",
                "spelling_usage": "The act of pitching or throwing one's self headlong or\n   violently forward, like an unruly horse."
            },
            {
                "spelling_detail_id": 104704,
                "word_type": "n.",
                "spelling_usage": "Heavy and reckless betting in horse racing; hazardous\n   speculation."
            }
        ]
    },
    {
        "id": 67850,
        "spelling_name": "Poignant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 104997,
                "word_type": "a.",
                "spelling_usage": "Pricking; piercing; sharp; pungent."
            },
            {
                "spelling_detail_id": 104998,
                "word_type": "a.",
                "spelling_usage": "Fig.: Pointed; keen; satirical."
            }
        ]
    },
    {
        "id": 67937,
        "spelling_name": "Polemic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 105201,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to controversy; maintaining, or\n   involving, controversy; controversial; disputative; as, a polemic\n   discourse or essay; polemic theology."
            },
            {
                "spelling_detail_id": 105202,
                "word_type": "a.",
                "spelling_usage": "Engaged in, or addicted to, polemics, or to controversy;\n   disputations; as, a polemic writer."
            },
            {
                "spelling_detail_id": 105203,
                "word_type": "n.",
                "spelling_usage": "One who writes in support of one opinion, doctrine, or\n   system, in opposition to another; one skilled in polemics; a\n   controversialist; a disputant."
            },
            {
                "spelling_detail_id": 105204,
                "word_type": "n.",
                "spelling_usage": "A polemic argument or controversy."
            }
        ]
    },
    {
        "id": 68359,
        "spelling_name": "Poncho",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 105803,
                "word_type": "n.",
                "spelling_usage": "A kind of cloak worn by the Spanish Americans, having the\n   form of a blanket, with a slit in the middle for the head to pass\n   through. A kind of poncho made of rubber or painted cloth is used by\n   the mounted troops in the United States service."
            },
            {
                "spelling_detail_id": 105804,
                "word_type": "n.",
                "spelling_usage": "A trade name for camlets, or stout worsteds."
            }
        ]
    },
    {
        "id": 68375,
        "spelling_name": "Ponderous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 105825,
                "word_type": "a.",
                "spelling_usage": "Very heavy; weighty; as, a ponderous shield; a ponderous\n   load; the ponderous elephant."
            },
            {
                "spelling_detail_id": 105826,
                "word_type": "a.",
                "spelling_usage": "Important; momentous; forcible."
            },
            {
                "spelling_detail_id": 105827,
                "word_type": "a.",
                "spelling_usage": "Heavy; dull; wanting; lightless or spirit; as, a\n   ponderous style; a ponderous joke."
            }
        ]
    },
    {
        "id": 68585,
        "spelling_name": "Portent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 106170,
                "word_type": "n.",
                "spelling_usage": "That which portends, or foretoken; esp., that which\n   portends evil; a sign of coming calamity; an omen; a sign."
            }
        ]
    },
    {
        "id": 69221,
        "spelling_name": "Precarious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107179,
                "word_type": "a.",
                "spelling_usage": "Depending on the will or pleasure of another; held by\n   courtesy; liable to be changed or lost at the pleasure of another; as,\n   precarious privileges."
            },
            {
                "spelling_detail_id": 107180,
                "word_type": "a.",
                "spelling_usage": "Held by a doubtful tenure; depending on unknown causes\n   or events; exposed to constant risk; not to be depended on for\n   certainty or stability; uncertain; as, a precarious state of health;\n   precarious fortunes."
            }
        ]
    },
    {
        "id": 69286,
        "spelling_name": "Preclude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107290,
                "word_type": "v.",
                "spelling_usage": "To put a barrier before; hence, to shut out; to hinder;\n   to stop; to impede."
            },
            {
                "spelling_detail_id": 107291,
                "word_type": "v.",
                "spelling_usage": "To shut out by anticipative action; to prevent or hinder\n   by necessary consequence or implication; to deter action of, access to,\n   employment of, etc.; to render ineffectual; to obviate by anticipation."
            }
        ]
    },
    {
        "id": 69343,
        "spelling_name": "Precursory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107354,
                "word_type": "a.",
                "spelling_usage": "Preceding as a precursor or harbinger; indicating\n   something to follow; as, precursory symptoms of a fever."
            },
            {
                "spelling_detail_id": 107355,
                "word_type": "n.",
                "spelling_usage": "An introduction."
            }
        ]
    },
    {
        "id": 69409,
        "spelling_name": "Predilection",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107445,
                "word_type": "n.",
                "spelling_usage": "A previous liking; a prepossession of mind in favor\n   of something; predisposition to choose or like; partiality."
            }
        ]
    },
    {
        "id": 69422,
        "spelling_name": "Predominate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107464,
                "word_type": "v. i.",
                "spelling_usage": "To be superior in number, strength, influence, or\n   authority; to have controlling power or influence; to prevail; to rule;\n   to have the mastery; as, love predominated in her heart."
            },
            {
                "spelling_detail_id": 107465,
                "word_type": "v. t.",
                "spelling_usage": "To rule over; to overpower."
            }
        ]
    },
    {
        "id": 69445,
        "spelling_name": "Preen",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107488,
                "word_type": "n.",
                "spelling_usage": "A forked tool used by clothiers in dressing cloth."
            },
            {
                "spelling_detail_id": 107491,
                "word_type": "n.",
                "spelling_usage": "To dress with, or as with, a preen; to trim or dress with\n   the beak, as the feathers; -- said of birds."
            },
            {
                "spelling_detail_id": 107492,
                "word_type": "n.",
                "spelling_usage": "To trim up, as trees."
            }
        ]
    },
    {
        "id": 69603,
        "spelling_name": "Premature",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107689,
                "word_type": "a.",
                "spelling_usage": "Mature or ripe before the proper time; as, the premature\n   fruits of a hotbed."
            },
            {
                "spelling_detail_id": 107690,
                "word_type": "a.",
                "spelling_usage": "Happening, arriving, existing, or performed before the\n   proper or usual time; adopted too soon; too early; untimely; as, a\n   premature fall of snow; a premature birth; a premature opinion;\n   premature decay."
            },
            {
                "spelling_detail_id": 107691,
                "word_type": "a.",
                "spelling_usage": "Arriving or received without due authentication or\n   evidence; as, a premature report."
            }
        ]
    },
    {
        "id": 69710,
        "spelling_name": "Preponderance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107834,
                "word_type": "n.",
                "spelling_usage": "Alt. of Preponderancy"
            }
        ]
    },
    {
        "id": 69751,
        "spelling_name": "Presage",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 107890,
                "word_type": "v. t.",
                "spelling_usage": "Something which foreshows or portends a future event; a\n   prognostic; an omen; an augury."
            },
            {
                "spelling_detail_id": 107891,
                "word_type": "v. t.",
                "spelling_usage": "Power to look the future, or the exercise of that\n   power; foreknowledge; presentiment."
            },
            {
                "spelling_detail_id": 107894,
                "word_type": "v. t.",
                "spelling_usage": "To have a presentiment of; to feel beforehand; to\n   foreknow."
            },
            {
                "spelling_detail_id": 107895,
                "word_type": "v. t.",
                "spelling_usage": "To foretell; to predict; to foreshow; to indicate."
            },
            {
                "spelling_detail_id": 107896,
                "word_type": "v. i.",
                "spelling_usage": "To form or utter a prediction; -- sometimes used with\n   of."
            }
        ]
    },
    {
        "id": 69814,
        "spelling_name": "Presentiment",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108012,
                "word_type": "n.",
                "spelling_usage": "Previous sentiment, conception, or opinion; previous\n   apprehension; especially, an antecedent impression or conviction of\n   something unpleasant, distressing, or calamitous, about to happen;\n   anticipation of evil; foreboding."
            }
        ]
    },
    {
        "id": 69899,
        "spelling_name": "Presumption",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108165,
                "word_type": "n.",
                "spelling_usage": "The act of presuming, or believing upon probable\n   evidence; the act of assuming or taking for granted; belief upon\n   incomplete proof."
            },
            {
                "spelling_detail_id": 108166,
                "word_type": "n.",
                "spelling_usage": "Ground for presuming; evidence probable, but not\n   conclusive; strong probability; reasonable supposition; as, the\n   presumption is that an event has taken place."
            },
            {
                "spelling_detail_id": 108167,
                "word_type": "n.",
                "spelling_usage": "That which is presumed or assumed; that which is\n   supposed or believed to be real or true, on evidence that is probable\n   but not conclusive."
            },
            {
                "spelling_detail_id": 108168,
                "word_type": "n.",
                "spelling_usage": "The act of venturing beyond due beyond due bounds; an\n   overstepping of the bounds of reverence, respect, or courtesy; forward,\n   overconfident, or arrogant opinion or conduct; presumptuousness;\n   arrogance; effrontery."
            }
        ]
    },
    {
        "id": 69948,
        "spelling_name": "Preternatural",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108241,
                "word_type": "a.",
                "spelling_usage": "Beyond of different from what is natural, or\n   according to the regular course of things, but not clearly supernatural\n   or miraculous; strange; inexplicable; extraordinary; uncommon;\n   irregular; abnormal; as, a preternatural appearance; a preternatural\n   stillness; a preternatural presentation (in childbirth) or labor."
            }
        ]
    },
    {
        "id": 69984,
        "spelling_name": "Prevalent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108289,
                "word_type": "a.",
                "spelling_usage": "Gaining advantage or superiority; having superior force,\n   influence, or efficacy; prevailing; predominant; successful;\n   victorious."
            },
            {
                "spelling_detail_id": 108290,
                "word_type": "a.",
                "spelling_usage": "Most generally received or current; most widely adopted\n   or practiced; also, generally or extensively existing; widespread;\n   prevailing; as, a prevalent observance; prevalent disease."
            }
        ]
    },
    {
        "id": 69986,
        "spelling_name": "Prevaricate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108294,
                "word_type": "v. i.",
                "spelling_usage": "To shift or turn from one side to the other, from\n   the direct course, or from truth; to speak with equivocation; to\n   shuffle; to quibble; as, he prevaricates in his statement."
            },
            {
                "spelling_detail_id": 108295,
                "word_type": "v. i.",
                "spelling_usage": "To collude, as where an informer colludes with the\n   defendant, and makes a sham prosecution."
            },
            {
                "spelling_detail_id": 108296,
                "word_type": "v. i.",
                "spelling_usage": "To undertake a thing falsely and deceitfully, with\n   the purpose of defeating or destroying it."
            },
            {
                "spelling_detail_id": 108297,
                "word_type": "v. t.",
                "spelling_usage": "To evade by a quibble; to transgress; to pervert."
            }
        ]
    },
    {
        "id": 70093,
        "spelling_name": "Prim",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108491,
                "word_type": "n.",
                "spelling_usage": "The privet."
            },
            {
                "spelling_detail_id": 108492,
                "word_type": "a.",
                "spelling_usage": "Formal; precise; affectedly neat or nice; as, prim\n   regularity; a prim person."
            },
            {
                "spelling_detail_id": 108495,
                "word_type": "v. t.",
                "spelling_usage": "To deck with great nicety; to arrange with affected\n   preciseness; to prink."
            },
            {
                "spelling_detail_id": 108496,
                "word_type": "v. i.",
                "spelling_usage": "To dress or act smartly."
            }
        ]
    },
    {
        "id": 70229,
        "spelling_name": "Pristine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108744,
                "word_type": "a.",
                "spelling_usage": "Belonging to the earliest period or state; original;\n   primitive; primeval; as, the pristine state of innocence; the pristine\n   manners of a people; pristine vigor."
            }
        ]
    },
    {
        "id": 70245,
        "spelling_name": "Privation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108780,
                "word_type": "n.",
                "spelling_usage": "The act of depriving, or taking away; hence, the\n   depriving of rank or office; degradation in rank; deprivation."
            },
            {
                "spelling_detail_id": 108781,
                "word_type": "n.",
                "spelling_usage": "The state of being deprived or destitute of something,\n   especially of something required or desired; destitution; need; as, to\n   undergo severe privations."
            },
            {
                "spelling_detail_id": 108782,
                "word_type": "n.",
                "spelling_usage": "The condition of being absent; absence; negation."
            }
        ]
    },
    {
        "id": 70300,
        "spelling_name": "Probity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108883,
                "word_type": "n.",
                "spelling_usage": "Tried virtue or integrity; approved moral excellence;\n   honesty; rectitude; uprightness."
            }
        ]
    },
    {
        "id": 70361,
        "spelling_name": "Proclivity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 108980,
                "word_type": "n.",
                "spelling_usage": "Inclination; propensity; proneness; tendency."
            },
            {
                "spelling_detail_id": 108981,
                "word_type": "n.",
                "spelling_usage": "Readiness; facility; aptitude."
            }
        ]
    },
    {
        "id": 70376,
        "spelling_name": "Procrastination",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109001,
                "word_type": "n.",
                "spelling_usage": "The act or habit of procrastinating, or putting\n   off to a future time; delay; dilatoriness."
            }
        ]
    },
    {
        "id": 70425,
        "spelling_name": "Prodigal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109075,
                "word_type": "a.",
                "spelling_usage": "Given to extravagant expenditure; expending money or\n   other things without necessity; recklessly or viciously profuse;\n   lavish; wasteful; not frugal or economical; as, a prodigal man; the\n   prodigal son; prodigal giving; prodigal expenses."
            },
            {
                "spelling_detail_id": 109076,
                "word_type": "n.",
                "spelling_usage": "One who expends money extravagantly, viciously, or\n   without necessity; one that is profuse or lavish in any expenditure; a\n   waster; a spendthrift."
            }
        ]
    },
    {
        "id": 70432,
        "spelling_name": "Prodigious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109083,
                "word_type": "a.",
                "spelling_usage": "Of the nature of a prodigy; marvelous; wonderful;\n   portentous."
            },
            {
                "spelling_detail_id": 109084,
                "word_type": "a.",
                "spelling_usage": "Extraordinary in bulk, extent, quantity, or degree;\n   very great; vast; huge; immense; as, a prodigious mountain; a\n   prodigious creature; a prodigious blunder."
            }
        ]
    },
    {
        "id": 70468,
        "spelling_name": "Profane",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109149,
                "word_type": "a.",
                "spelling_usage": "Not sacred or holy; not possessing peculiar sanctity;\n   unconsecrated; hence, relating to matters other than sacred; secular;\n   -- opposed to sacred, religious, or inspired; as, a profane place."
            },
            {
                "spelling_detail_id": 109150,
                "word_type": "a.",
                "spelling_usage": "Unclean; impure; polluted; unholy."
            },
            {
                "spelling_detail_id": 109151,
                "word_type": "a.",
                "spelling_usage": "Treating sacred things with contempt, disrespect,\n   irreverence, or undue familiarity; irreverent; impious."
            },
            {
                "spelling_detail_id": 109152,
                "word_type": "a.",
                "spelling_usage": "Irreverent in language; taking the name of God in vain;\n   given to swearing; blasphemous; as, a profane person, word, oath, or\n   tongue."
            },
            {
                "spelling_detail_id": 109155,
                "word_type": "a.",
                "spelling_usage": "To violate, as anything sacred; to treat with abuse,\n   irreverence, obloquy, or contempt; to desecrate; to pollute; as, to\n   profane the name of God; to profane the Scriptures, or the ordinance of\n   God."
            },
            {
                "spelling_detail_id": 109156,
                "word_type": "a.",
                "spelling_usage": "To put to a wrong or unworthy use; to make a base\n   employment of; to debase; to abuse; to defile."
            }
        ]
    },
    {
        "id": 70512,
        "spelling_name": "Profligacy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109227,
                "word_type": "a.",
                "spelling_usage": "The quality of state of being profligate; a profligate\n   or very vicious course of life; a state of being abandoned in moral\n   principle and in vice; dissoluteness."
            }
        ]
    },
    {
        "id": 70513,
        "spelling_name": "Profligate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109228,
                "word_type": "a.",
                "spelling_usage": "Overthrown; beaten; conquered."
            },
            {
                "spelling_detail_id": 109229,
                "word_type": "a.",
                "spelling_usage": "Broken down in respect of rectitude, principle, virtue,\n   or decency; openly and shamelessly immoral or vicious; dissolute; as,\n   profligate man or wretch."
            },
            {
                "spelling_detail_id": 109230,
                "word_type": "n.",
                "spelling_usage": "An abandoned person; one openly and shamelessly\n   vicious; a dissolute person."
            },
            {
                "spelling_detail_id": 109231,
                "word_type": "v. t.",
                "spelling_usage": "To drive away; to overcome."
            }
        ]
    },
    {
        "id": 70523,
        "spelling_name": "Profundity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109249,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being profound; depth of place,\n   knowledge, feeling, etc."
            }
        ]
    },
    {
        "id": 70524,
        "spelling_name": "Profuse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109250,
                "word_type": "a.",
                "spelling_usage": "Pouring forth with fullness or exuberance; bountiful;\n   exceedingly liberal; giving without stint; as, a profuse government;\n   profuse hospitality."
            },
            {
                "spelling_detail_id": 109251,
                "word_type": "a.",
                "spelling_usage": "Superabundant; excessive; prodigal; lavish; as, profuse\n   expenditure."
            },
            {
                "spelling_detail_id": 109252,
                "word_type": "v. t.",
                "spelling_usage": "To pour out; to give or spend liberally; to lavish; to\n   squander."
            }
        ]
    },
    {
        "id": 70614,
        "spelling_name": "Proliferate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109400,
                "word_type": "v. t.",
                "spelling_usage": "To produce or form cells; especially, to produce\n   cells rapidly."
            },
            {
                "spelling_detail_id": 109401,
                "word_type": "v. t.",
                "spelling_usage": "To produce zooids by budding."
            }
        ]
    },
    {
        "id": 70623,
        "spelling_name": "Prolix",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109417,
                "word_type": "a.",
                "spelling_usage": "Extending to a great length; unnecessarily long; minute in\n   narration or argument; excessively particular in detail; -- rarely used\n   except with reference to discourse written or spoken; as, a prolix\n   oration; a prolix poem; a prolix sermon."
            },
            {
                "spelling_detail_id": 109418,
                "word_type": "a.",
                "spelling_usage": "Indulging in protracted discourse; tedious; wearisome; --\n   applied to a speaker or writer."
            }
        ]
    },
    {
        "id": 70718,
        "spelling_name": "Prone",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109556,
                "word_type": "a.",
                "spelling_usage": "Bending forward; inclined; not erect."
            },
            {
                "spelling_detail_id": 109557,
                "word_type": "a.",
                "spelling_usage": "Prostrate; flat; esp., lying with the face down; -- opposed\n   to supine."
            },
            {
                "spelling_detail_id": 109558,
                "word_type": "a.",
                "spelling_usage": "Headlong; running downward or headlong."
            },
            {
                "spelling_detail_id": 109559,
                "word_type": "a.",
                "spelling_usage": "Sloping, with reference to a line or surface; declivous;\n   inclined; not level."
            },
            {
                "spelling_detail_id": 109560,
                "word_type": "a.",
                "spelling_usage": "Inclined; propense; disposed; -- applied to the mind or\n   affections, usually in an ill sense. Followed by to."
            }
        ]
    },
    {
        "id": 70774,
        "spelling_name": "Propagation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109654,
                "word_type": "n.",
                "spelling_usage": "The act of propagating; continuance or multiplication\n   of the kind by generation or successive production; as, the propagation\n   of animals or plants."
            },
            {
                "spelling_detail_id": 109655,
                "word_type": "n.",
                "spelling_usage": "The spreading abroad, or extension, of anything;\n   diffusion; dissemination; as, the propagation of sound; the propagation\n   of the gospel."
            }
        ]
    },
    {
        "id": 70835,
        "spelling_name": "Propinquity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109749,
                "word_type": "n.",
                "spelling_usage": "Nearness in place; neighborhood; proximity."
            },
            {
                "spelling_detail_id": 109750,
                "word_type": "n.",
                "spelling_usage": "Nearness in time."
            },
            {
                "spelling_detail_id": 109751,
                "word_type": "n.",
                "spelling_usage": "Nearness of blood; kindred; affinity."
            }
        ]
    },
    {
        "id": 70851,
        "spelling_name": "Propitiatory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109769,
                "word_type": "a.",
                "spelling_usage": "Having the power to make propitious; pertaining to,\n   or employed in, propitiation; expiatory; as, a propitiatory sacrifice."
            },
            {
                "spelling_detail_id": 109770,
                "word_type": "n.",
                "spelling_usage": "The mercy seat; -- so called because a symbol of the\n   propitiated Jehovah."
            }
        ]
    },
    {
        "id": 70852,
        "spelling_name": "Propitious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109771,
                "word_type": "a.",
                "spelling_usage": "Convenient; auspicious; favorable; kind; as, a\n   propitious season; a propitious breeze."
            },
            {
                "spelling_detail_id": 109772,
                "word_type": "a.",
                "spelling_usage": "Hence, kind; gracious; merciful; helpful; -- said of a\n   person or a divinity."
            }
        ]
    },
    {
        "id": 70940,
        "spelling_name": "Prosaic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109899,
                "word_type": "a.",
                "spelling_usage": "Alt. of Prosaical"
            }
        ]
    },
    {
        "id": 70950,
        "spelling_name": "Proscribe",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 109913,
                "word_type": "v. t.",
                "spelling_usage": "To doom to destruction; to put out of the protection\n   of law; to outlaw; to exile; as, Sylla and Marius proscribed each\n   other's adherents."
            },
            {
                "spelling_detail_id": 109914,
                "word_type": "v. t.",
                "spelling_usage": "To denounce and condemn; to interdict; to prohibit;\n   as, the Puritans proscribed theaters."
            }
        ]
    },
    {
        "id": 71185,
        "spelling_name": "Protracted",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110248,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Protract"
            },
            {
                "spelling_detail_id": 110255,
                "word_type": "a.",
                "spelling_usage": "Prolonged; continued."
            }
        ]
    },
    {
        "id": 71245,
        "spelling_name": "Provident",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110361,
                "word_type": "a.",
                "spelling_usage": "Foreseeing wants and making provision to supply them;\n   prudent in preparing for future exigencies; cautious; economical; --\n   sometimes followed by of; as, aprovident man; an animal provident of\n   the future."
            }
        ]
    },
    {
        "id": 71265,
        "spelling_name": "Provisional",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110397,
                "word_type": "a.",
                "spelling_usage": "Of the nature of a provision; serving as a provision\n   for the time being; -- used of partial or temporary arrangements; as, a\n   provisional government; a provisional treaty."
            }
        ]
    },
    {
        "id": 71281,
        "spelling_name": "Provoke",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110422,
                "word_type": "v. t.",
                "spelling_usage": "To call forth; to call into being or action; esp., to\n   incense to action, a faculty or passion, as love, hate, or ambition;\n   hence, commonly, to incite, as a person, to action by a challenge, by\n   taunts, or by defiance; to exasperate; to irritate; to offend\n   intolerably; to cause to retaliate."
            },
            {
                "spelling_detail_id": 110423,
                "word_type": "v. i.",
                "spelling_usage": "To cause provocation or anger."
            },
            {
                "spelling_detail_id": 110424,
                "word_type": "v. i.",
                "spelling_usage": "To appeal. [A Latinism]"
            }
        ]
    },
    {
        "id": 71310,
        "spelling_name": "Prudence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110467,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being prudent; wisdom in the way\n   of caution and provision; discretion; carefulness; hence, also,\n   economy; frugality."
            }
        ]
    },
    {
        "id": 71321,
        "spelling_name": "Prudish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110481,
                "word_type": "a.",
                "spelling_usage": "Like a prude; very formal, precise, or reserved;\n   affectedly severe in virtue; as, a prudish woman; prudish manners."
            }
        ]
    },
    {
        "id": 71326,
        "spelling_name": "Prune",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110488,
                "word_type": "v. t.",
                "spelling_usage": "To lop or cut off the superfluous parts, branches, or\n   shoots of; to clear of useless material; to shape or smooth by\n   trimming; to trim: as, to prune trees; to prune an essay."
            },
            {
                "spelling_detail_id": 110489,
                "word_type": "v. t.",
                "spelling_usage": "To cut off or cut out, as useless parts."
            },
            {
                "spelling_detail_id": 110490,
                "word_type": "v. t.",
                "spelling_usage": "To preen; to prepare; to dress."
            },
            {
                "spelling_detail_id": 110491,
                "word_type": "v. i.",
                "spelling_usage": "To dress; to prink; -used humorously or in contempt."
            },
            {
                "spelling_detail_id": 110492,
                "word_type": "n.",
                "spelling_usage": "A plum; esp., a dried plum, used in cookery; as, French or\n   Turkish prunes; California prunes."
            }
        ]
    },
    {
        "id": 71345,
        "spelling_name": "Pry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110516,
                "word_type": "n.",
                "spelling_usage": "A lever; also, leverage."
            },
            {
                "spelling_detail_id": 110519,
                "word_type": "v. t.",
                "spelling_usage": "To raise or move, or attempt to raise or move, with a pry\n   or lever; to prize."
            },
            {
                "spelling_detail_id": 110520,
                "word_type": "v. i.",
                "spelling_usage": "To peep narrowly; to gaze; to inspect closely; to attempt\n   to discover something by a scrutinizing curiosity; -- often implying\n   reproach."
            },
            {
                "spelling_detail_id": 110521,
                "word_type": "n.",
                "spelling_usage": "Curious inspection; impertinent peeping."
            }
        ]
    },
    {
        "id": 71597,
        "spelling_name": "Pucker",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110823,
                "word_type": "v. t. & i.",
                "spelling_usage": "To gather into small folds or wrinkles; to contract\n   into ridges and furrows; to corrugate; -- often with up; as, to pucker\n   up the mouth."
            },
            {
                "spelling_detail_id": 110824,
                "word_type": "n.",
                "spelling_usage": "A fold; a wrinkle; a collection of folds."
            },
            {
                "spelling_detail_id": 110825,
                "word_type": "n.",
                "spelling_usage": "A state of perplexity or anxiety; confusion; bother;\n   agitation."
            }
        ]
    },
    {
        "id": 71635,
        "spelling_name": "Puerile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110880,
                "word_type": "a.",
                "spelling_usage": "Boyish; childish; trifling; silly."
            }
        ]
    },
    {
        "id": 71667,
        "spelling_name": "Pugnacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110950,
                "word_type": "a.",
                "spelling_usage": "Disposed to fight; inclined to fighting; quarrelsome;\n   fighting."
            }
        ]
    },
    {
        "id": 71673,
        "spelling_name": "Puissance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110959,
                "word_type": "n.",
                "spelling_usage": "Power; strength; might; force; potency."
            }
        ]
    },
    {
        "id": 71683,
        "spelling_name": "Pulchritude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 110973,
                "word_type": "n.",
                "spelling_usage": "That quality of appearance which pleases the eye;\n   beauty; comeliness; grace; loveliness."
            },
            {
                "spelling_detail_id": 110974,
                "word_type": "n.",
                "spelling_usage": "Attractive moral excellence; moral beauty."
            }
        ]
    },
    {
        "id": 71823,
        "spelling_name": "Punctilious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111187,
                "word_type": "a.",
                "spelling_usage": "Attentive to punctilio; very nice or exact in the\n   forms of behavior, etiquette, or mutual intercourse; precise; exact in\n   the smallest particulars."
            }
        ]
    },
    {
        "id": 71846,
        "spelling_name": "Pundit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111217,
                "word_type": "n.",
                "spelling_usage": "A learned man; a teacher; esp., a Brahman versed in the\n   Sanskrit language, and in the science, laws, and religion of the\n   Hindoos; in Cashmere, any clerk or native official."
            }
        ]
    },
    {
        "id": 71851,
        "spelling_name": "Pungency",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111222,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being pungent or piercing;\n   keenness; sharpness; piquancy; as, the pungency of ammonia."
            }
        ]
    },
    {
        "id": 71868,
        "spelling_name": "Punitive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111247,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to punishment; involving, awarding, or\n   inflicting punishment; as, punitive law or justice."
            }
        ]
    },
    {
        "id": 72065,
        "spelling_name": "Purvey",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111584,
                "word_type": "v. t.",
                "spelling_usage": "To furnish or provide, as with a convenience,\n   provisions, or the like."
            },
            {
                "spelling_detail_id": 111585,
                "word_type": "v. t.",
                "spelling_usage": "To procure; to get."
            },
            {
                "spelling_detail_id": 111586,
                "word_type": "v. i.",
                "spelling_usage": "To purchase provisions; to provide; to make provision."
            },
            {
                "spelling_detail_id": 111587,
                "word_type": "v. i.",
                "spelling_usage": "To pander; -- with to."
            }
        ]
    },
    {
        "id": 72083,
        "spelling_name": "Pusillanimous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111623,
                "word_type": "a.",
                "spelling_usage": "Destitute of a manly or courageous strength and\n   firmness of mind; of weak spirit; mean-spirited; spiritless; cowardly;\n   -- said of persons, as, a pussillanimous prince."
            },
            {
                "spelling_detail_id": 111624,
                "word_type": "a.",
                "spelling_usage": "Evincing, or characterized by, weakness of mind, and\n   want of courage; feeble; as, pusillanimous counsels."
            }
        ]
    },
    {
        "id": 72112,
        "spelling_name": "Putrefaction",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111678,
                "word_type": "n.",
                "spelling_usage": "The act or the process of putrefying; the offensive\n   decay of albuminous or other matter."
            },
            {
                "spelling_detail_id": 111679,
                "word_type": "n.",
                "spelling_usage": "The condition of being putrefied; also, that which\n   putrefied."
            }
        ]
    },
    {
        "id": 72220,
        "spelling_name": "Pyre",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 111819,
                "word_type": "n.",
                "spelling_usage": "A funeral pile; a combustible heap on which the dead are\n   burned; hence, any pile to be burnt."
            }
        ]
    },
    {
        "id": 72381,
        "spelling_name": "Quack",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112006,
                "word_type": "v. i.",
                "spelling_usage": "To utter a sound like the cry of a duck."
            },
            {
                "spelling_detail_id": 112007,
                "word_type": "v. i.",
                "spelling_usage": "To make vain and loud pretensions; to boast."
            },
            {
                "spelling_detail_id": 112008,
                "word_type": "v. i.",
                "spelling_usage": "To act the part of a quack, or pretender."
            },
            {
                "spelling_detail_id": 112009,
                "word_type": "n.",
                "spelling_usage": "The cry of the duck, or a sound in imitation of it; a\n   hoarse, quacking noise."
            },
            {
                "spelling_detail_id": 112010,
                "word_type": "n.",
                "spelling_usage": "A boastful pretender to medical skill; an empiric; an\n   ignorant practitioner."
            },
            {
                "spelling_detail_id": 112011,
                "word_type": "n.",
                "spelling_usage": "Hence, one who boastfully pretends to skill or knowledge of\n   any kind not possessed; a charlatan."
            },
            {
                "spelling_detail_id": 112012,
                "word_type": "a.",
                "spelling_usage": "Pertaining to or characterized by, boasting and pretension;\n   used by quacks; pretending to cure diseases; as, a quack medicine; a\n   quack doctor."
            }
        ]
    },
    {
        "id": 72493,
        "spelling_name": "Quaff",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112169,
                "word_type": "v. t.",
                "spelling_usage": "To drink with relish; to drink copiously of; to swallow\n   in large draughts."
            },
            {
                "spelling_detail_id": 112170,
                "word_type": "v. i.",
                "spelling_usage": "To drink largely or luxuriously."
            }
        ]
    },
    {
        "id": 72505,
        "spelling_name": "Quail",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112182,
                "word_type": "v. i.",
                "spelling_usage": "To die; to perish; hence, to wither; to fade."
            },
            {
                "spelling_detail_id": 112183,
                "word_type": "v. i.",
                "spelling_usage": "To become quelled; to become cast down; to sink under\n   trial or apprehension of danger; to lose the spirit and power of\n   resistance; to lose heart; to give way; to shrink; to cower."
            },
            {
                "spelling_detail_id": 112184,
                "word_type": "v. t.",
                "spelling_usage": "To cause to fail in spirit or power; to quell; to crush;\n   to subdue."
            },
            {
                "spelling_detail_id": 112185,
                "word_type": "v. i.",
                "spelling_usage": "To curdle; to coagulate, as milk."
            },
            {
                "spelling_detail_id": 112186,
                "word_type": "n.",
                "spelling_usage": "Any gallinaceous bird belonging to Coturnix and several\n   allied genera of the Old World, especially the common European quail\n   (C. communis), the rain quail (C. Coromandelica) of India, the stubble\n   quail (C. pectoralis), and the Australian swamp quail (Synoicus\n   australis)."
            },
            {
                "spelling_detail_id": 112187,
                "word_type": "n.",
                "spelling_usage": "Any one of several American partridges belonging to Colinus,\n   Callipepla, and allied genera, especially the bobwhite (called Virginia\n   quail, and Maryland quail), and the California quail (Calipepla\n   Californica)."
            },
            {
                "spelling_detail_id": 112188,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of Turnix and allied genera,\n   native of the Old World, as the Australian painted quail (Turnix\n   varius). See Turnix."
            },
            {
                "spelling_detail_id": 112189,
                "word_type": "n.",
                "spelling_usage": "A prostitute; -- so called because the quail was thought to\n   be a very amorous bird."
            }
        ]
    },
    {
        "id": 72542,
        "spelling_name": "Qualm",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112249,
                "word_type": "n.",
                "spelling_usage": "Sickness; disease; pestilence; death."
            },
            {
                "spelling_detail_id": 112250,
                "word_type": "n.",
                "spelling_usage": "A sudden attack of illness, faintness, or pain; an agony."
            },
            {
                "spelling_detail_id": 112251,
                "word_type": "n.",
                "spelling_usage": "Especially, a sudden sensation of nausea."
            },
            {
                "spelling_detail_id": 112252,
                "word_type": "n.",
                "spelling_usage": "A prick or scruple of conscience; uneasiness of conscience;\n   compunction."
            }
        ]
    },
    {
        "id": 72547,
        "spelling_name": "Quandary",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112257,
                "word_type": "n.",
                "spelling_usage": "A state of difficulty or perplexity; doubt; uncertainty."
            },
            {
                "spelling_detail_id": 112258,
                "word_type": "v. t.",
                "spelling_usage": "To bring into a state of uncertainty, perplexity, or\n   difficulty."
            }
        ]
    },
    {
        "id": 72696,
        "spelling_name": "Quell",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112530,
                "word_type": "v. i.",
                "spelling_usage": "To die."
            },
            {
                "spelling_detail_id": 112531,
                "word_type": "v. i.",
                "spelling_usage": "To be subdued or abated; to yield; to abate."
            },
            {
                "spelling_detail_id": 112532,
                "word_type": "v. t.",
                "spelling_usage": "To take the life of; to kill."
            },
            {
                "spelling_detail_id": 112533,
                "word_type": "v. t.",
                "spelling_usage": "To overpower; to subdue; to put down."
            },
            {
                "spelling_detail_id": 112534,
                "word_type": "v. t.",
                "spelling_usage": "To quiet; to allay; to pacify; to cause to yield or\n   cease; as, to quell grief; to quell the tumult of the soul."
            },
            {
                "spelling_detail_id": 112535,
                "word_type": "n.",
                "spelling_usage": "Murder."
            }
        ]
    },
    {
        "id": 72762,
        "spelling_name": "Quibble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112640,
                "word_type": "n.",
                "spelling_usage": "A shift or turn from the point in question; a trifling or\n   evasive distinction; an evasion; a cavil."
            },
            {
                "spelling_detail_id": 112641,
                "word_type": "n.",
                "spelling_usage": "A pun; a low conceit."
            },
            {
                "spelling_detail_id": 112644,
                "word_type": "v. i.",
                "spelling_usage": "To evade the point in question by artifice, play upon\n   words, caviling, or by raising any insignificant or impertinent\n   question or point; to trifle in argument or discourse; to equivocate."
            },
            {
                "spelling_detail_id": 112645,
                "word_type": "v. i.",
                "spelling_usage": "To pun; to practice punning."
            }
        ]
    },
    {
        "id": 72808,
        "spelling_name": "Quiescence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112717,
                "word_type": "n.",
                "spelling_usage": "Alt. of Quiescency"
            }
        ]
    },
    {
        "id": 72948,
        "spelling_name": "Quirk",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112910,
                "word_type": "n.",
                "spelling_usage": "A sudden turn; a starting from the point or line; hence, an\n   artful evasion or subterfuge; a shift; a quibble; as, the quirks of a\n   pettifogger."
            },
            {
                "spelling_detail_id": 112911,
                "word_type": "n.",
                "spelling_usage": "A fit or turn; a short paroxysm; a caprice."
            },
            {
                "spelling_detail_id": 112912,
                "word_type": "n.",
                "spelling_usage": "A smart retort; a quibble; a shallow conceit."
            },
            {
                "spelling_detail_id": 112913,
                "word_type": "n.",
                "spelling_usage": "An irregular air; as, light quirks of music."
            },
            {
                "spelling_detail_id": 112914,
                "word_type": "n.",
                "spelling_usage": "A piece of ground taken out of any regular ground plot or\n   floor, so as to make a court, yard, etc.; -- sometimes written quink."
            },
            {
                "spelling_detail_id": 112915,
                "word_type": "n.",
                "spelling_usage": "A small channel, deeply recessed in proportion to its width,\n   used to insulate and give relief to a convex rounded molding."
            }
        ]
    },
    {
        "id": 72979,
        "spelling_name": "Quixotic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 112967,
                "word_type": "a.",
                "spelling_usage": "Like Don Quixote; romantic to extravagance; absurdly\n   chivalric; apt to be deluded."
            }
        ]
    },
    {
        "id": 73016,
        "spelling_name": "Quotidian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113033,
                "word_type": "a.",
                "spelling_usage": "Occurring or returning daily; as, a quotidian fever."
            },
            {
                "spelling_detail_id": 113034,
                "word_type": "n.",
                "spelling_usage": "Anything returning daily; especially (Med.), an\n   intermittent fever or ague which returns every day."
            }
        ]
    },
    {
        "id": 73048,
        "spelling_name": "Rabble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113072,
                "word_type": "n.",
                "spelling_usage": "An iron bar, with the end bent, used in stirring or\n   skimming molten iron in the process of puddling."
            },
            {
                "spelling_detail_id": 113073,
                "word_type": "v. t.",
                "spelling_usage": "To stir or skim with a rabble, as molten iron."
            },
            {
                "spelling_detail_id": 113074,
                "word_type": "v. i.",
                "spelling_usage": "To speak in a confused manner."
            },
            {
                "spelling_detail_id": 113075,
                "word_type": "v. i.",
                "spelling_usage": "A tumultuous crowd of vulgar, noisy people; a mob; a\n   confused, disorderly throng."
            },
            {
                "spelling_detail_id": 113076,
                "word_type": "v. i.",
                "spelling_usage": "A confused, incoherent discourse; a medley of voices; a\n   chatter."
            },
            {
                "spelling_detail_id": 113077,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a rabble; like, or suited to, a rabble;\n   disorderly; vulgar."
            },
            {
                "spelling_detail_id": 113080,
                "word_type": "v. t.",
                "spelling_usage": "To insult, or assault, by a mob; to mob; as, to rabble a\n   curate."
            },
            {
                "spelling_detail_id": 113081,
                "word_type": "v. t.",
                "spelling_usage": "To utter glibly and incoherently; to mouth without\n   intelligence."
            },
            {
                "spelling_detail_id": 113082,
                "word_type": "v. t.",
                "spelling_usage": "To rumple; to crumple."
            }
        ]
    },
    {
        "id": 73117,
        "spelling_name": "Raconteur",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113212,
                "word_type": "n.",
                "spelling_usage": "A relater; a storyteller."
            }
        ]
    },
    {
        "id": 73194,
        "spelling_name": "Raffish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113351,
                "word_type": "a.",
                "spelling_usage": "Resembling, or having the character of, raff, or a raff;\n   worthless; low."
            }
        ]
    },
    {
        "id": 73335,
        "spelling_name": "Ramify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113635,
                "word_type": "v. t.",
                "spelling_usage": "To divide into branches or subdivisions; as, to ramify\n   an art, subject, scheme."
            },
            {
                "spelling_detail_id": 113636,
                "word_type": "v. i.",
                "spelling_usage": "To shoot, or divide, into branches or subdivisions, as\n   the stem of a plant."
            },
            {
                "spelling_detail_id": 113637,
                "word_type": "v. i.",
                "spelling_usage": "To be divided or subdivided, as a main subject."
            }
        ]
    },
    {
        "id": 73397,
        "spelling_name": "Rancorous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113722,
                "word_type": "a.",
                "spelling_usage": "Full of rancor; evincing, or caused by, rancor; deeply\n   malignant; implacably spiteful or malicious; intensely virulent."
            }
        ]
    },
    {
        "id": 73439,
        "spelling_name": "Rant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113834,
                "word_type": "v. i.",
                "spelling_usage": "To rave in violent, high-sounding, or extravagant\n   language, without dignity of thought; to be noisy, boisterous, and\n   bombastic in talk or declamation; as, a ranting preacher."
            },
            {
                "spelling_detail_id": 113835,
                "word_type": "n.",
                "spelling_usage": "High-sounding language, without importance or dignity of\n   thought; boisterous, empty declamation; bombast; as, the rant of\n   fanatics."
            }
        ]
    },
    {
        "id": 73456,
        "spelling_name": "Rapacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113868,
                "word_type": "a.",
                "spelling_usage": "Given to plunder; disposed or accustomed to seize by\n   violence; seizing by force."
            },
            {
                "spelling_detail_id": 113869,
                "word_type": "a.",
                "spelling_usage": "Accustomed to seize food; subsisting on prey, or animals\n   seized by violence; as, a tiger is a rapacious animal; a rapacious\n   bird."
            },
            {
                "spelling_detail_id": 113870,
                "word_type": "a.",
                "spelling_usage": "Avaricious; grasping; extortionate; also, greedy;\n   ravenous; voracious; as, rapacious usurers; a rapacious appetite."
            }
        ]
    },
    {
        "id": 73505,
        "spelling_name": "Rarefy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 113956,
                "word_type": "v. t.",
                "spelling_usage": "To make rare, thin, porous, or less dense; to expand or\n   enlarge without adding any new portion of matter to; -- opposed to\n   condense."
            },
            {
                "spelling_detail_id": 113957,
                "word_type": "v. i.",
                "spelling_usage": "To become less dense; to become thin and porous."
            }
        ]
    },
    {
        "id": 73640,
        "spelling_name": "Rave",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114193,
                "word_type": "",
                "spelling_usage": "imp. of Rive."
            },
            {
                "spelling_detail_id": 114194,
                "word_type": "n.",
                "spelling_usage": "One of the upper side pieces of the frame of a wagon body or\n   a sleigh."
            },
            {
                "spelling_detail_id": 114197,
                "word_type": "v. i.",
                "spelling_usage": "To wander in mind or intellect; to be delirious; to talk\n   or act irrationally; to be wild, furious, or raging, as a madman."
            },
            {
                "spelling_detail_id": 114198,
                "word_type": "v. i.",
                "spelling_usage": "To rush wildly or furiously."
            },
            {
                "spelling_detail_id": 114199,
                "word_type": "v. i.",
                "spelling_usage": "To talk with unreasonable enthusiasm or excessive passion\n   or excitement; -- followed by about, of, or on; as, he raved about her\n   beauty."
            },
            {
                "spelling_detail_id": 114200,
                "word_type": "v. t.",
                "spelling_usage": "To utter in madness or frenzy; to say wildly; as, to rave\n   nonsense."
            }
        ]
    },
    {
        "id": 73737,
        "spelling_name": "Reactionary",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114363,
                "word_type": "a.",
                "spelling_usage": "Being, causing, or favoring reaction; as, reactionary\n   movements."
            },
            {
                "spelling_detail_id": 114365,
                "word_type": "n.",
                "spelling_usage": "One who favors reaction, or seeks to undo political\n   progress or revolution."
            }
        ]
    },
    {
        "id": 73904,
        "spelling_name": "Rebuff",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114668,
                "word_type": "n.",
                "spelling_usage": "Repercussion, or beating back; a quick and sudden\n   resistance."
            },
            {
                "spelling_detail_id": 114669,
                "word_type": "n.",
                "spelling_usage": "Sudden check; unexpected repulse; defeat; refusal;\n   repellence; rejection of solicitation."
            },
            {
                "spelling_detail_id": 114672,
                "word_type": "v. t.",
                "spelling_usage": "To beat back; to offer sudden resistance to; to check;\n   to repel or repulse violently, harshly, or uncourteously."
            }
        ]
    },
    {
        "id": 73918,
        "spelling_name": "Rebus",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114687,
                "word_type": "n.",
                "spelling_usage": "A mode of expressing words and phrases by pictures of\n   objects whose names resemble those words, or the syllables of which\n   they are composed; enigmatical representation of words by figures;\n   hence, a peculiar form of riddle made up of such representations."
            },
            {
                "spelling_detail_id": 114688,
                "word_type": "n.",
                "spelling_usage": "A pictorial suggestion on a coat of arms of the name of the\n   person to whom it belongs. See Canting arms, under Canting."
            },
            {
                "spelling_detail_id": 114689,
                "word_type": "v. t.",
                "spelling_usage": "To mark or indicate by a rebus."
            }
        ]
    },
    {
        "id": 73927,
        "spelling_name": "Recalcitrant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114700,
                "word_type": "a.",
                "spelling_usage": "Kicking back; recalcitrating; hence, showing\n   repugnance or opposition; refractory."
            }
        ]
    },
    {
        "id": 73933,
        "spelling_name": "Recant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114713,
                "word_type": "v. t.",
                "spelling_usage": "To withdraw or repudiate formally and publicly (opinions\n   formerly expressed); to contradict, as a former declaration; to take\n   back openly; to retract; to recall."
            },
            {
                "spelling_detail_id": 114714,
                "word_type": "v. i.",
                "spelling_usage": "To revoke a declaration or proposition; to unsay what\n   has been said; to retract; as, convince me that I am wrong, and I will\n   recant."
            }
        ]
    },
    {
        "id": 73951,
        "spelling_name": "Recast",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114733,
                "word_type": "v. t.",
                "spelling_usage": "To throw again."
            },
            {
                "spelling_detail_id": 114734,
                "word_type": "v. t.",
                "spelling_usage": "To mold anew; to cast anew; to throw into a new form or\n   shape; to reconstruct; as, to recast cannon; to recast an argument or a\n   play."
            },
            {
                "spelling_detail_id": 114735,
                "word_type": "v. t.",
                "spelling_usage": "To compute, or cast up, a second time."
            }
        ]
    },
    {
        "id": 74024,
        "spelling_name": "Reciprocity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114875,
                "word_type": "n.",
                "spelling_usage": "Mutual action and reaction."
            },
            {
                "spelling_detail_id": 114876,
                "word_type": "n.",
                "spelling_usage": "Reciprocal advantages, obligations, or rights;\n   reciprocation."
            }
        ]
    },
    {
        "id": 74066,
        "spelling_name": "Recluse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 114967,
                "word_type": "a.",
                "spelling_usage": "Shut up; sequestered; retired from the world or from\n   public notice; solitary; living apart; as, a recluse monk or hermit; a\n   recluse life."
            },
            {
                "spelling_detail_id": 114968,
                "word_type": "a.",
                "spelling_usage": "A person who lives in seclusion from intercourse with the\n   world, as a hermit or monk; specifically, one of a class of secluded\n   devotees who live in single cells, usually attached to monasteries."
            },
            {
                "spelling_detail_id": 114969,
                "word_type": "a.",
                "spelling_usage": "The place where a recluse dwells."
            },
            {
                "spelling_detail_id": 114970,
                "word_type": "v. t.",
                "spelling_usage": "To shut up; to seclude."
            }
        ]
    },
    {
        "id": 74125,
        "spelling_name": "Recompense",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115060,
                "word_type": "v. t.",
                "spelling_usage": "To render an equivalent to, for service, loss, etc.;\n   to requite; to remunerate; to compensate."
            },
            {
                "spelling_detail_id": 115061,
                "word_type": "v. t.",
                "spelling_usage": "To return an equivalent for; to give compensation\n   for; to atone for; to pay for."
            },
            {
                "spelling_detail_id": 115062,
                "word_type": "v. t.",
                "spelling_usage": "To give in return; to pay back; to pay, as something\n   earned or deserved."
            },
            {
                "spelling_detail_id": 115063,
                "word_type": "v. i.",
                "spelling_usage": "To give recompense; to make amends or requital."
            },
            {
                "spelling_detail_id": 115064,
                "word_type": "n.",
                "spelling_usage": "An equivalent returned for anything done, suffered, or\n   given; compensation; requital; suitable return."
            }
        ]
    },
    {
        "id": 74140,
        "spelling_name": "Reconcile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115080,
                "word_type": "v. t.",
                "spelling_usage": "To cause to be friendly again; to conciliate anew; to\n   restore to friendship; to bring back to harmony; to cause to be no\n   longer at variance; as, to reconcile persons who have quarreled."
            },
            {
                "spelling_detail_id": 115081,
                "word_type": "v. t.",
                "spelling_usage": "To bring to acquiescence, content, or quiet\n   submission; as, to reconcile one's self to affictions."
            },
            {
                "spelling_detail_id": 115082,
                "word_type": "v. t.",
                "spelling_usage": "To make consistent or congruous; to bring to\n   agreement or suitableness; -- followed by with or to."
            },
            {
                "spelling_detail_id": 115083,
                "word_type": "v. t.",
                "spelling_usage": "To adjust; to settle; as, to reconcile differences."
            },
            {
                "spelling_detail_id": 115084,
                "word_type": "v. i.",
                "spelling_usage": "To become reconciled."
            }
        ]
    },
    {
        "id": 74149,
        "spelling_name": "Recondite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115092,
                "word_type": "a.",
                "spelling_usage": "Hidden from the mental or intellectual view; secret;\n   abstruse; as, recondite causes of things."
            },
            {
                "spelling_detail_id": 115093,
                "word_type": "a.",
                "spelling_usage": "Dealing in things abstruse; profound; searching; as,\n   recondite studies."
            }
        ]
    },
    {
        "id": 74207,
        "spelling_name": "Recreancy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115199,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being recreant."
            }
        ]
    },
    {
        "id": 74285,
        "spelling_name": "Recuperate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115315,
                "word_type": "v. i.",
                "spelling_usage": "To recover health; to regain strength; to\n   convalesce."
            },
            {
                "spelling_detail_id": 115316,
                "word_type": "v. t.",
                "spelling_usage": "To recover; to regain; as, to recuperate the health\n   or strength."
            }
        ]
    },
    {
        "id": 74356,
        "spelling_name": "Redeem",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115406,
                "word_type": "v. t.",
                "spelling_usage": "To purchase back; to regain possession of by payment of\n   a stipulated price; to repurchase."
            },
            {
                "spelling_detail_id": 115407,
                "word_type": "v. t.",
                "spelling_usage": "To recall, as an estate, or to regain, as mortgaged\n   property, by paying what may be due by force of the mortgage."
            },
            {
                "spelling_detail_id": 115408,
                "word_type": "v. t.",
                "spelling_usage": "To regain by performing the obligation or condition\n   stated; to discharge the obligation mentioned in, as a promissory note,\n   bond, or other evidence of debt; as, to redeem bank notes with coin."
            },
            {
                "spelling_detail_id": 115409,
                "word_type": "v. t.",
                "spelling_usage": "To ransom, liberate, or rescue from captivity or\n   bondage, or from any obligation or liability to suffer or to be\n   forfeited, by paying a price or ransom; to ransom; to rescue; to\n   recover; as, to redeem a captive, a pledge, and the like."
            },
            {
                "spelling_detail_id": 115410,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to rescue and deliver from the bondage of sin and\n   the penalties of God's violated law."
            },
            {
                "spelling_detail_id": 115411,
                "word_type": "v. t.",
                "spelling_usage": "To make good by performing fully; to fulfill; as, to\n   redeem one's promises."
            },
            {
                "spelling_detail_id": 115412,
                "word_type": "v. t.",
                "spelling_usage": "To pay the penalty of; to make amends for; to serve as\n   an equivalent or offset for; to atone for; to compensate; as, to redeem\n   an error."
            }
        ]
    },
    {
        "id": 74424,
        "spelling_name": "Redoubtable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115511,
                "word_type": "a.",
                "spelling_usage": "Formidable; dread; terrible to foes; as, a redoubtable\n   hero; hence, valiant; -- often in contempt or burlesque."
            }
        ]
    },
    {
        "id": 74590,
        "spelling_name": "Refine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115800,
                "word_type": "v. t.",
                "spelling_usage": "To reduce to a fine, unmixed, or pure state; to free\n   from impurities; to free from dross or alloy; to separate from\n   extraneous matter; to purify; to defecate; as, to refine gold or\n   silver; to refine iron; to refine wine or sugar."
            },
            {
                "spelling_detail_id": 115801,
                "word_type": "v. t.",
                "spelling_usage": "To purify from what is gross, coarse, vulgar, inelegant,\n   low, and the like; to make elegant or exellent; to polish; as, to\n   refine the manners, the language, the style, the taste, the intellect,\n   or the moral feelings."
            },
            {
                "spelling_detail_id": 115802,
                "word_type": "v. i.",
                "spelling_usage": "To become pure; to be cleared of feculent matter."
            },
            {
                "spelling_detail_id": 115803,
                "word_type": "v. i.",
                "spelling_usage": "To improve in accuracy, delicacy, or excellence."
            },
            {
                "spelling_detail_id": 115804,
                "word_type": "v. i.",
                "spelling_usage": "To affect nicety or subtilty in thought or language."
            }
        ]
    },
    {
        "id": 74665,
        "spelling_name": "Refractory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 115940,
                "word_type": "a.",
                "spelling_usage": "Obstinate in disobedience; contumacious; stubborn;\n   unmanageable; as, a refractory child; a refractory beast."
            },
            {
                "spelling_detail_id": 115941,
                "word_type": "a.",
                "spelling_usage": "Resisting ordinary treatment; difficult of fusion,\n   reduction, or the like; -- said especially of metals and the like,\n   which do not readily yield to heat, or to the hammer; as, a refractory\n   ore."
            },
            {
                "spelling_detail_id": 115942,
                "word_type": "n.",
                "spelling_usage": "A refractory person."
            },
            {
                "spelling_detail_id": 115943,
                "word_type": "n.",
                "spelling_usage": "Refractoriness."
            },
            {
                "spelling_detail_id": 115944,
                "word_type": "n.",
                "spelling_usage": "OPottery) A piece of ware covered with a vaporable flux\n   and placed in a kiln, to communicate a glaze to the other articles."
            }
        ]
    },
    {
        "id": 74703,
        "spelling_name": "Refulgent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116004,
                "word_type": "a.",
                "spelling_usage": "Casting a bright light; radiant; brilliant; resplendent;\n   shining; splendid; as, refulgent beams."
            }
        ]
    },
    {
        "id": 74729,
        "spelling_name": "Regale",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116043,
                "word_type": "n.",
                "spelling_usage": "A prerogative of royalty."
            },
            {
                "spelling_detail_id": 116046,
                "word_type": "v. t.",
                "spelling_usage": "To enerta/n in a regal or sumptuous manner; to\n   enrtertain with something that delights; to gratify; to refresh; as, to\n   regale the taste, the eye, or the ear."
            },
            {
                "spelling_detail_id": 116047,
                "word_type": "v. i.",
                "spelling_usage": "To feast; t/ fare sumtuously."
            },
            {
                "spelling_detail_id": 116048,
                "word_type": "v. t.",
                "spelling_usage": "A sumptuous repast; a banquet."
            }
        ]
    },
    {
        "id": 74776,
        "spelling_name": "Regicide",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116134,
                "word_type": "n.",
                "spelling_usage": "One who kills or who murders a king; specifically\n   (Eng.Hist.), one of the judges who condemned Charles I. to death."
            },
            {
                "spelling_detail_id": 116135,
                "word_type": "n.",
                "spelling_usage": "The killing or the murder of a king."
            }
        ]
    },
    {
        "id": 74968,
        "spelling_name": "Reiterate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116419,
                "word_type": "v. t.",
                "spelling_usage": "To repeat again and again; to say or do repeatedly;\n   sometimes, to repeat."
            },
            {
                "spelling_detail_id": 116420,
                "word_type": "a.",
                "spelling_usage": "Reiterated; repeated."
            }
        ]
    },
    {
        "id": 74993,
        "spelling_name": "Rejoinder",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116458,
                "word_type": "n.",
                "spelling_usage": "An answer to a reply; or, in general, an answer or\n   reply."
            },
            {
                "spelling_detail_id": 116459,
                "word_type": "n.",
                "spelling_usage": "The defendant's answer to the plaintiff's replication."
            },
            {
                "spelling_detail_id": 116460,
                "word_type": "v. i.",
                "spelling_usage": "To make a rejoinder."
            }
        ]
    },
    {
        "id": 75003,
        "spelling_name": "Rejuvenation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116470,
                "word_type": "n.",
                "spelling_usage": "Rejuvenescence."
            }
        ]
    },
    {
        "id": 75014,
        "spelling_name": "Relapse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116485,
                "word_type": "v. i.",
                "spelling_usage": "To slip or slide back, in a literal sense; to turn\n   back."
            },
            {
                "spelling_detail_id": 116486,
                "word_type": "v. i.",
                "spelling_usage": "To slide or turn back into a former state or practice;\n   to fall back from some condition attained; -- generally in a bad sense,\n   as from a state of convalescence or amended condition; as, to relapse\n   into a stupor, into vice, or into barbarism; -- sometimes in a good\n   sense; as, to relapse into slumber after being disturbed."
            },
            {
                "spelling_detail_id": 116487,
                "word_type": "v. i.",
                "spelling_usage": "To fall from Christian faith into paganism, heresy, or\n   unbelief; to backslide."
            },
            {
                "spelling_detail_id": 116488,
                "word_type": "v.",
                "spelling_usage": "A sliding or falling back, especially into a former bad\n   state, either of body or morals; backsliding; the state of having\n   fallen back."
            },
            {
                "spelling_detail_id": 116489,
                "word_type": "v.",
                "spelling_usage": "One who has relapsed, or fallen back, into error; a\n   backslider; specifically, one who, after recanting error, returns to it\n   again."
            }
        ]
    },
    {
        "id": 75212,
        "spelling_name": "Reminiscent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116817,
                "word_type": "a.",
                "spelling_usage": "Recalling to mind, or capable of recalling to mind;\n   having remembrance; reminding one of something."
            },
            {
                "spelling_detail_id": 116818,
                "word_type": "n.",
                "spelling_usage": "One who is addicted to indulging, narrating, or\n   recording reminiscences."
            }
        ]
    },
    {
        "id": 75251,
        "spelling_name": "Remonstrate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116891,
                "word_type": "v. t.",
                "spelling_usage": "To point out; to show clearly; to make plain or\n   manifest; hence, to prove; to demonstrate."
            },
            {
                "spelling_detail_id": 116892,
                "word_type": "v. i.",
                "spelling_usage": "To present and urge reasons in opposition to an\n   act, measure, or any course of proceedings; to expostulate; as, to\n   remonstrate with a person regarding his habits; to remonstrate against\n   proposed taxation."
            }
        ]
    },
    {
        "id": 75310,
        "spelling_name": "Render",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 116990,
                "word_type": "n.",
                "spelling_usage": "One who rends."
            },
            {
                "spelling_detail_id": 116993,
                "word_type": "v. t.",
                "spelling_usage": "To return; to pay back; to restore."
            },
            {
                "spelling_detail_id": 116994,
                "word_type": "v. t.",
                "spelling_usage": "To inflict, as a retribution; to requite."
            },
            {
                "spelling_detail_id": 116995,
                "word_type": "v. t.",
                "spelling_usage": "To give up; to yield; to surrender."
            },
            {
                "spelling_detail_id": 116996,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to furnish; to contribute."
            },
            {
                "spelling_detail_id": 116997,
                "word_type": "v. t.",
                "spelling_usage": "To furnish; to state; to deliver; as, to render an\n   account; to render judgment."
            },
            {
                "spelling_detail_id": 116998,
                "word_type": "v. t.",
                "spelling_usage": "To cause to be, or to become; as, to render a person\n   more safe or more unsafe; to render a fortress secure."
            },
            {
                "spelling_detail_id": 116999,
                "word_type": "v. t.",
                "spelling_usage": "To translate from one language into another; as, to\n   render Latin into English."
            },
            {
                "spelling_detail_id": 117000,
                "word_type": "v. t.",
                "spelling_usage": "To interpret; to set forth, represent, or exhibit; as,\n   an actor renders his part poorly; a singer renders a passage of music\n   with great effect; a painter renders a scene in a felicitous manner."
            },
            {
                "spelling_detail_id": 117001,
                "word_type": "v. t.",
                "spelling_usage": "To try out or extract (oil, lard, tallow, etc.) from\n   fatty animal substances; as, to render tallow."
            },
            {
                "spelling_detail_id": 117002,
                "word_type": "v. t.",
                "spelling_usage": "To plaster, as a wall of masonry, without the use of\n   lath."
            },
            {
                "spelling_detail_id": 117003,
                "word_type": "v. i.",
                "spelling_usage": "To give an account; to make explanation or confession."
            },
            {
                "spelling_detail_id": 117004,
                "word_type": "v. i.",
                "spelling_usage": "To pass; to run; -- said of the passage of a rope\n   through a block, eyelet, etc.; as, a rope renders well, that is, passes\n   freely; also, to yield or give way."
            },
            {
                "spelling_detail_id": 117005,
                "word_type": "n.",
                "spelling_usage": "A surrender."
            },
            {
                "spelling_detail_id": 117006,
                "word_type": "n.",
                "spelling_usage": "A return; a payment of rent."
            },
            {
                "spelling_detail_id": 117007,
                "word_type": "n.",
                "spelling_usage": "An account given; a statement."
            }
        ]
    },
    {
        "id": 75358,
        "spelling_name": "Renovate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117085,
                "word_type": "v. t.",
                "spelling_usage": "To make over again; to restore to freshness or vigor;\n   to renew."
            }
        ]
    },
    {
        "id": 75366,
        "spelling_name": "Renowned",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117095,
                "word_type": "a.",
                "spelling_usage": "Famous; celebrated for great achievements, for\n   distinguished qualities, or for grandeur; eminent; as, a renowned king."
            }
        ]
    },
    {
        "id": 75434,
        "spelling_name": "Repast",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117191,
                "word_type": "n.",
                "spelling_usage": "The act of taking food."
            },
            {
                "spelling_detail_id": 117192,
                "word_type": "n.",
                "spelling_usage": "That which is taken as food; a meal; figuratively, any\n   refreshment."
            },
            {
                "spelling_detail_id": 117193,
                "word_type": "v. t. & i.",
                "spelling_usage": "To supply food to; to feast; to take food."
            }
        ]
    },
    {
        "id": 75456,
        "spelling_name": "Repel",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117237,
                "word_type": "v. t.",
                "spelling_usage": "To drive back; to force to return; to check the advance\n   of; to repulse as, to repel an enemy or an assailant."
            },
            {
                "spelling_detail_id": 117238,
                "word_type": "v. t.",
                "spelling_usage": "To resist or oppose effectually; as, to repel an assault,\n   an encroachment, or an argument."
            },
            {
                "spelling_detail_id": 117239,
                "word_type": "v. i.",
                "spelling_usage": "To act with force in opposition to force impressed; to\n   exercise repulsion."
            }
        ]
    },
    {
        "id": 75480,
        "spelling_name": "Repertoire",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117279,
                "word_type": "n.",
                "spelling_usage": "A list of dramas, operas, pieces, parts, etc., which a\n   company or a person has rehearsed and is prepared to perform."
            }
        ]
    },
    {
        "id": 75492,
        "spelling_name": "Repine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117297,
                "word_type": "v. i.",
                "spelling_usage": "To fail; to wane."
            },
            {
                "spelling_detail_id": 117298,
                "word_type": "v. i.",
                "spelling_usage": "To continue pining; to feel inward discontent which\n   preys on the spirits; to indulge in envy or complaint; to murmur."
            },
            {
                "spelling_detail_id": 117299,
                "word_type": "n.",
                "spelling_usage": "Vexation; mortification."
            }
        ]
    },
    {
        "id": 75546,
        "spelling_name": "Repose",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117402,
                "word_type": "v.",
                "spelling_usage": "To cause to stop or to rest after motion; hence, to\n   deposit; to lay down; to lodge; to reposit."
            },
            {
                "spelling_detail_id": 117403,
                "word_type": "v.",
                "spelling_usage": "To lay at rest; to cause to be calm or quiet; to compose;\n   to rest, -- often reflexive; as, to repose one's self on a couch."
            },
            {
                "spelling_detail_id": 117404,
                "word_type": "v.",
                "spelling_usage": "To place, have, or rest; to set; to intrust."
            },
            {
                "spelling_detail_id": 117405,
                "word_type": "v. i.",
                "spelling_usage": "To lie at rest; to rest."
            },
            {
                "spelling_detail_id": 117406,
                "word_type": "v. i.",
                "spelling_usage": "Figuratively, to remain or abide restfully without\n   anxiety or alarms."
            },
            {
                "spelling_detail_id": 117407,
                "word_type": "v. i.",
                "spelling_usage": "To lie; to be supported; as, trap reposing on sand."
            },
            {
                "spelling_detail_id": 117408,
                "word_type": "v.",
                "spelling_usage": "A lying at rest; sleep; rest; quiet."
            },
            {
                "spelling_detail_id": 117409,
                "word_type": "v.",
                "spelling_usage": "Rest of mind; tranquillity; freedom from uneasiness; also,\n   a composed manner or deportment."
            },
            {
                "spelling_detail_id": 117410,
                "word_type": "v.",
                "spelling_usage": "A rest; a pause."
            },
            {
                "spelling_detail_id": 117411,
                "word_type": "v.",
                "spelling_usage": "That harmony or moderation which affords rest for the eye;\n   -- opposed to the scattering and division of a subject into too many\n   unconnected parts, and also to anything which is overstrained; as, a\n   painting may want repose."
            }
        ]
    },
    {
        "id": 75608,
        "spelling_name": "Reproach",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117525,
                "word_type": "v. t.",
                "spelling_usage": "To come back to, or come home to, as a matter of\n   blame; to bring shame or disgrace upon; to disgrace."
            },
            {
                "spelling_detail_id": 117526,
                "word_type": "v. t.",
                "spelling_usage": "To attribute blame to; to allege something disgraceful\n   against; to charge with a fault; to censure severely or contemptuously;\n   to upbraid."
            },
            {
                "spelling_detail_id": 117527,
                "word_type": "v.",
                "spelling_usage": "The act of reproaching; censure mingled with contempt;\n   contumelious or opprobrious language toward any person; abusive\n   reflections; as, severe reproach."
            },
            {
                "spelling_detail_id": 117528,
                "word_type": "v.",
                "spelling_usage": "A cause of blame or censure; shame; disgrace."
            },
            {
                "spelling_detail_id": 117529,
                "word_type": "v.",
                "spelling_usage": "An object of blame, censure, scorn, or derision."
            }
        ]
    },
    {
        "id": 75617,
        "spelling_name": "Reprobate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117538,
                "word_type": "a.",
                "spelling_usage": "Not enduring proof or trial; not of standard purity or\n   fineness; disallowed; rejected."
            },
            {
                "spelling_detail_id": 117539,
                "word_type": "a.",
                "spelling_usage": "Abandoned to punishment; hence, morally abandoned and\n   lost; given up to vice; depraved."
            },
            {
                "spelling_detail_id": 117540,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to one who is given up to wickedness;\n   as, reprobate conduct."
            },
            {
                "spelling_detail_id": 117541,
                "word_type": "n.",
                "spelling_usage": "One morally abandoned and lost."
            },
            {
                "spelling_detail_id": 117544,
                "word_type": "v. t.",
                "spelling_usage": "To disapprove with detestation or marks of extreme\n   dislike; to condemn as unworthy; to disallow; to reject."
            },
            {
                "spelling_detail_id": 117545,
                "word_type": "v. t.",
                "spelling_usage": "To abandon to punishment without hope of pardon."
            }
        ]
    },
    {
        "id": 75659,
        "spelling_name": "Repudiate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117614,
                "word_type": "v. t.",
                "spelling_usage": "To cast off; to disavow; to have nothing to do with;\n   to renounce; to reject."
            },
            {
                "spelling_detail_id": 117615,
                "word_type": "v. t.",
                "spelling_usage": "To divorce, put away, or discard, as a wife, or a\n   woman one has promised to marry."
            },
            {
                "spelling_detail_id": 117616,
                "word_type": "v. t.",
                "spelling_usage": "To refuse to acknowledge or to pay; to disclaim; as,\n   the State has repudiated its debts."
            }
        ]
    },
    {
        "id": 75680,
        "spelling_name": "Repulsive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117641,
                "word_type": "a.",
                "spelling_usage": "Serving, or able, to repulse; repellent; as, a repulsive\n   force."
            },
            {
                "spelling_detail_id": 117642,
                "word_type": "a.",
                "spelling_usage": "Cold; forbidding; offensive; as, repulsive manners."
            }
        ]
    },
    {
        "id": 75715,
        "spelling_name": "Requite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117704,
                "word_type": "v. t.",
                "spelling_usage": "To repay; in a good sense, to recompense; to return (an\n   equivalent) in good; to reward; in a bad sense, to retaliate; to return\n   (evil) for evil; to punish."
            }
        ]
    },
    {
        "id": 75734,
        "spelling_name": "Rescind",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117729,
                "word_type": "v. t.",
                "spelling_usage": "To cut off; to abrogate; to annul."
            },
            {
                "spelling_detail_id": 117730,
                "word_type": "v. t.",
                "spelling_usage": "Specifically, to vacate or make void, as an act, by the\n   enacting authority or by superior authority; to repeal; as, to rescind\n   a law, a resolution, or a vote; to rescind a decree or a judgment."
            }
        ]
    },
    {
        "id": 75833,
        "spelling_name": "Resigned",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117906,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Resign"
            },
            {
                "spelling_detail_id": 117914,
                "word_type": "a.",
                "spelling_usage": "Submissive; yielding; not disposed to resist or murmur."
            }
        ]
    },
    {
        "id": 75841,
        "spelling_name": "Resilience",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 117922,
                "word_type": "n.",
                "spelling_usage": "Alt. of Resiliency"
            }
        ]
    },
    {
        "id": 75899,
        "spelling_name": "Resort",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118026,
                "word_type": "n.",
                "spelling_usage": "Active power or movement; spring."
            },
            {
                "spelling_detail_id": 118029,
                "word_type": "v. i.",
                "spelling_usage": "To go; to repair; to betake one's self."
            },
            {
                "spelling_detail_id": 118030,
                "word_type": "v. i.",
                "spelling_usage": "To fall back; to revert."
            },
            {
                "spelling_detail_id": 118031,
                "word_type": "v. i.",
                "spelling_usage": "To have recourse; to apply; to one's self for help,\n   relief, or advantage."
            },
            {
                "spelling_detail_id": 118032,
                "word_type": "v.",
                "spelling_usage": "The act of going to, or making application; a betaking\n   one's self; the act of visiting or seeking; recourse; as, a place of\n   popular resort; -- often figuratively; as, to have resort to force."
            },
            {
                "spelling_detail_id": 118033,
                "word_type": "v.",
                "spelling_usage": "A place to which one betakes himself habitually; a place of\n   frequent assembly; a haunt."
            },
            {
                "spelling_detail_id": 118034,
                "word_type": "v.",
                "spelling_usage": "That to which one resorts or looks for help; resource;\n   refuge."
            }
        ]
    },
    {
        "id": 75990,
        "spelling_name": "Restive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118228,
                "word_type": "a.",
                "spelling_usage": "Unwilling to go on; obstinate in refusing to move forward;\n   stubborn; drawing back."
            },
            {
                "spelling_detail_id": 118229,
                "word_type": "a.",
                "spelling_usage": "Inactive; sluggish."
            },
            {
                "spelling_detail_id": 118230,
                "word_type": "a.",
                "spelling_usage": "Impatient under coercion, chastisement, or opposition;\n   refractory."
            },
            {
                "spelling_detail_id": 118231,
                "word_type": "a.",
                "spelling_usage": "Uneasy; restless; averse to standing still; fidgeting\n   about; -- applied especially to horses."
            }
        ]
    },
    {
        "id": 76066,
        "spelling_name": "Resuscitation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118352,
                "word_type": "n.",
                "spelling_usage": "The act of resuscitating, or state of being\n   resuscitated."
            }
        ]
    },
    {
        "id": 76091,
        "spelling_name": "Retard",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118396,
                "word_type": "v. t.",
                "spelling_usage": "To keep delaying; to continue to hinder; to prevent from\n   progress; to render more slow in progress; to impede; to hinder; as, to\n   retard the march of an army; to retard the motion of a ship; -- opposed\n   to accelerate."
            },
            {
                "spelling_detail_id": 118397,
                "word_type": "v. t.",
                "spelling_usage": "To put off; to postpone; as, to retard the attacks of\n   old age; to retard a rupture between nations."
            },
            {
                "spelling_detail_id": 118398,
                "word_type": "v. i.",
                "spelling_usage": "To stay back."
            },
            {
                "spelling_detail_id": 118399,
                "word_type": "n.",
                "spelling_usage": "Retardation; delay."
            }
        ]
    },
    {
        "id": 76121,
        "spelling_name": "Reticence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118441,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being reticent, or keeping\n   silence; the state of holding one's tonque; refraining to speak of that\n   which is suggested; uncommunicativeness."
            },
            {
                "spelling_detail_id": 118442,
                "word_type": "n.",
                "spelling_usage": "A figure by which a person really speaks of a thing\n   while he makes a show as if he would say nothingon the subject."
            }
        ]
    },
    {
        "id": 76123,
        "spelling_name": "Reticent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118444,
                "word_type": "a.",
                "spelling_usage": "Inclined to keep silent; reserved; uncommunicative."
            }
        ]
    },
    {
        "id": 76157,
        "spelling_name": "Retinue",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118486,
                "word_type": "n.",
                "spelling_usage": "The body of retainers who follow a prince or other\n   distinguished person; a train of attendants; a suite."
            }
        ]
    },
    {
        "id": 76243,
        "spelling_name": "Retrograde",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118634,
                "word_type": "a.",
                "spelling_usage": "Apparently moving backward, and contrary to the\n   succession of the signs, that is, from east to west, as a planet."
            },
            {
                "spelling_detail_id": 118635,
                "word_type": "a.",
                "spelling_usage": "Tending or moving backward; having a backward course;\n   contrary; as, a retrograde motion; -- opposed to progressive."
            },
            {
                "spelling_detail_id": 118636,
                "word_type": "a.",
                "spelling_usage": "Declining from a better to a worse state; as, a\n   retrograde people; retrograde ideas, morals, etc."
            },
            {
                "spelling_detail_id": 118639,
                "word_type": "v. i.",
                "spelling_usage": "To go in a retrograde direction; to move, or appear\n   to move, backward, as a planet."
            },
            {
                "spelling_detail_id": 118640,
                "word_type": "v. i.",
                "spelling_usage": "Hence, to decline from a better to a worse\n   condition, as in morals or intelligence."
            }
        ]
    },
    {
        "id": 76257,
        "spelling_name": "Retrospective",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118656,
                "word_type": "a.",
                "spelling_usage": "Looking backward; contemplating things past; --\n   opposed to prospective; as, a retrospective view."
            },
            {
                "spelling_detail_id": 118657,
                "word_type": "a.",
                "spelling_usage": "Having reference to what is past; affecting things\n   past; retroactive; as, a retrospective law."
            }
        ]
    },
    {
        "id": 76343,
        "spelling_name": "Revere",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118807,
                "word_type": "v. t.",
                "spelling_usage": "To regard with reverence, or profound respect and\n   affection, mingled with awe or fear; to venerate; to reverence; to\n   honor in estimation."
            }
        ]
    },
    {
        "id": 76351,
        "spelling_name": "Reverent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 118818,
                "word_type": "a.",
                "spelling_usage": "Disposed to revere; impressed with reverence; submissive;\n   humble; respectful; as, reverent disciples."
            },
            {
                "spelling_detail_id": 118819,
                "word_type": "a.",
                "spelling_usage": "Expressing reverence, veneration, devotion, or\n   submission; as, reverent words; reverent behavior."
            }
        ]
    },
    {
        "id": 76796,
        "spelling_name": "Riddle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 119486,
                "word_type": "n.",
                "spelling_usage": "A sieve with coarse meshes, usually of wire, for separating\n   coarser materials from finer, as chaff from grain, cinders from ashes,\n   or gravel from sand."
            },
            {
                "spelling_detail_id": 119487,
                "word_type": "n.",
                "spelling_usage": "A board having a row of pins, set zigzag, between which\n   wire is drawn to straighten it."
            },
            {
                "spelling_detail_id": 119490,
                "word_type": "v. t.",
                "spelling_usage": "To separate, as grain from the chaff, with a riddle; to\n   pass through a riddle; as, riddle wheat; to riddle coal or gravel."
            },
            {
                "spelling_detail_id": 119491,
                "word_type": "v. t.",
                "spelling_usage": "To perforate so as to make like a riddle; to make many\n   holes in; as, a house riddled with shot."
            },
            {
                "spelling_detail_id": 119492,
                "word_type": "n.",
                "spelling_usage": "Something proposed to be solved by guessing or conjecture;\n   a puzzling question; an ambiguous proposition; an enigma; hence,\n   anything ambiguous or puzzling."
            },
            {
                "spelling_detail_id": 119493,
                "word_type": "v. t.",
                "spelling_usage": "To explain; to solve; to unriddle."
            },
            {
                "spelling_detail_id": 119494,
                "word_type": "v. i.",
                "spelling_usage": "To speak ambiguously or enigmatically."
            }
        ]
    },
    {
        "id": 76834,
        "spelling_name": "Rife",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 119578,
                "word_type": "a.",
                "spelling_usage": "Prevailing; prevalent; abounding."
            },
            {
                "spelling_detail_id": 119579,
                "word_type": "a.",
                "spelling_usage": "Having power; active; nimble."
            }
        ]
    },
    {
        "id": 76844,
        "spelling_name": "Rift",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 119601,
                "word_type": "",
                "spelling_usage": "p. p. of Rive."
            },
            {
                "spelling_detail_id": 119602,
                "word_type": "n.",
                "spelling_usage": "An opening made by riving or splitting; a cleft; a fissure."
            },
            {
                "spelling_detail_id": 119603,
                "word_type": "n.",
                "spelling_usage": "A shallow place in a stream; a ford."
            },
            {
                "spelling_detail_id": 119606,
                "word_type": "v. t.",
                "spelling_usage": "To cleave; to rive; to split; as, to rift an oak or a\n   rock; to rift the clouds."
            },
            {
                "spelling_detail_id": 119607,
                "word_type": "v. i.",
                "spelling_usage": "To burst open; to split."
            },
            {
                "spelling_detail_id": 119608,
                "word_type": "v. i.",
                "spelling_usage": "To belch."
            }
        ]
    },
    {
        "id": 77068,
        "spelling_name": "Rivet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 120079,
                "word_type": "n.",
                "spelling_usage": "A metallic pin with a head, used for uniting two plates or\n   pieces of material together, by passing it through them and then\n   beating or pressing down the point so that it shall spread out and form\n   a second head; a pin or bolt headed or clinched at both ends."
            },
            {
                "spelling_detail_id": 120082,
                "word_type": "v. t.",
                "spelling_usage": "To fasten with a rivet, or with rivets; as, to rivet two\n   pieces of iron."
            },
            {
                "spelling_detail_id": 120083,
                "word_type": "v. t.",
                "spelling_usage": "To spread out the end or point of, as of a metallic pin,\n   rod, or bolt, by beating or pressing, so as to form a sort of head."
            },
            {
                "spelling_detail_id": 120084,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to fasten firmly; to make firm, strong, or\n   immovable; as, to rivet friendship or affection."
            }
        ]
    },
    {
        "id": 77222,
        "spelling_name": "Roll",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 120371,
                "word_type": "n.",
                "spelling_usage": "To cause to revolve by turning over and over; to move by\n   turning on an axis; to impel forward by causing to turn over and over\n   on a supporting surface; as, to roll a wheel, a ball, or a barrel."
            },
            {
                "spelling_detail_id": 120372,
                "word_type": "n.",
                "spelling_usage": "To wrap round on itself; to form into a spherical or\n   cylindrical body by causing to turn over and over; as, to roll a sheet\n   of paper; to roll parchment; to roll clay or putty into a ball."
            },
            {
                "spelling_detail_id": 120373,
                "word_type": "n.",
                "spelling_usage": "To bind or involve by winding, as in a bandage; to inwrap; --\n   often with up; as, to roll up a parcel."
            },
            {
                "spelling_detail_id": 120374,
                "word_type": "n.",
                "spelling_usage": "To drive or impel forward with an easy motion, as of rolling;\n   as, a river rolls its waters to the ocean."
            },
            {
                "spelling_detail_id": 120375,
                "word_type": "n.",
                "spelling_usage": "To utter copiously, esp. with sounding words; to utter with a\n   deep sound; -- often with forth, or out; as, to roll forth some one's\n   praises; to roll out sentences."
            },
            {
                "spelling_detail_id": 120376,
                "word_type": "n.",
                "spelling_usage": "To press or level with a roller; to spread or form with a\n   roll, roller, or rollers; as, to roll a field; to roll paste; to roll\n   steel rails, etc."
            },
            {
                "spelling_detail_id": 120377,
                "word_type": "n.",
                "spelling_usage": "To move, or cause to be moved, upon, or by means of, rollers\n   or small wheels."
            },
            {
                "spelling_detail_id": 120378,
                "word_type": "n.",
                "spelling_usage": "To beat with rapid, continuous strokes, as a drum; to sound a\n   roll upon."
            },
            {
                "spelling_detail_id": 120379,
                "word_type": "n.",
                "spelling_usage": "To apply (one line or surface) to another without slipping;\n   to bring all the parts of (one line or surface) into successive contact\n   with another, in suck manner that at every instant the parts that have\n   been in contact are equal."
            },
            {
                "spelling_detail_id": 120380,
                "word_type": "n.",
                "spelling_usage": "To turn over in one's mind; to revolve."
            },
            {
                "spelling_detail_id": 120381,
                "word_type": "v. i.",
                "spelling_usage": "To move, as a curved object may, along a surface by\n   rotation without sliding; to revolve upon an axis; to turn over and\n   over; as, a ball or wheel rolls on the earth; a body rolls on an\n   inclined plane."
            },
            {
                "spelling_detail_id": 120382,
                "word_type": "v. i.",
                "spelling_usage": "To move on wheels; as, the carriage rolls along the\n   street."
            },
            {
                "spelling_detail_id": 120383,
                "word_type": "v. i.",
                "spelling_usage": "To be wound or formed into a cylinder or ball; as, the\n   cloth rolls unevenly; the snow rolls well."
            },
            {
                "spelling_detail_id": 120384,
                "word_type": "v. i.",
                "spelling_usage": "To fall or tumble; -- with over; as, a stream rolls over a\n   precipice."
            },
            {
                "spelling_detail_id": 120385,
                "word_type": "v. i.",
                "spelling_usage": "To perform a periodical revolution; to move onward as with\n   a revolution; as, the rolling year; ages roll away."
            },
            {
                "spelling_detail_id": 120386,
                "word_type": "v. i.",
                "spelling_usage": "To turn; to move circularly."
            },
            {
                "spelling_detail_id": 120387,
                "word_type": "v. i.",
                "spelling_usage": "To move, as waves or billows, with alternate swell and\n   depression."
            },
            {
                "spelling_detail_id": 120388,
                "word_type": "v. i.",
                "spelling_usage": "To incline first to one side, then to the other; to rock;\n   as, there is a great difference in ships about rolling; in a general\n   semse, to be tossed about."
            },
            {
                "spelling_detail_id": 120389,
                "word_type": "v. i.",
                "spelling_usage": "To turn over, or from side to side, while lying down; to\n   wallow; as, a horse rolls."
            },
            {
                "spelling_detail_id": 120390,
                "word_type": "v. i.",
                "spelling_usage": "To spread under a roller or rolling-pin; as, the paste\n   rolls well."
            },
            {
                "spelling_detail_id": 120391,
                "word_type": "v. i.",
                "spelling_usage": "To beat a drum with strokes so rapid that they can\n   scarcely be distinguished by the ear."
            },
            {
                "spelling_detail_id": 120392,
                "word_type": "v. i.",
                "spelling_usage": "To make a loud or heavy rumbling noise; as, the thunder\n   rolls."
            },
            {
                "spelling_detail_id": 120393,
                "word_type": "v.",
                "spelling_usage": "The act of rolling, or state of being rolled; as, the roll of\n   a ball; the roll of waves."
            },
            {
                "spelling_detail_id": 120394,
                "word_type": "v.",
                "spelling_usage": "That which rolls; a roller."
            },
            {
                "spelling_detail_id": 120395,
                "word_type": "v.",
                "spelling_usage": "A heavy cylinder used to break clods."
            },
            {
                "spelling_detail_id": 120396,
                "word_type": "v.",
                "spelling_usage": "One of a set of revolving cylinders, or rollers, between\n   which metal is pressed, formed, or smoothed, as in a rolling mill; as,\n   to pass rails through the rolls."
            },
            {
                "spelling_detail_id": 120397,
                "word_type": "v.",
                "spelling_usage": "That which is rolled up; as, a roll of fat, of wool, paper,\n   cloth, etc."
            },
            {
                "spelling_detail_id": 120398,
                "word_type": "v.",
                "spelling_usage": "A document written on a piece of parchment, paper, or other\n   materials which may be rolled up; a scroll."
            },
            {
                "spelling_detail_id": 120399,
                "word_type": "v.",
                "spelling_usage": "Hence, an official or public document; a register; a record;\n   also, a catalogue; a list."
            },
            {
                "spelling_detail_id": 120400,
                "word_type": "v.",
                "spelling_usage": "A quantity of cloth wound into a cylindrical form; as, a roll\n   of carpeting; a roll of ribbon."
            },
            {
                "spelling_detail_id": 120401,
                "word_type": "v.",
                "spelling_usage": "A cylindrical twist of tobacco."
            },
            {
                "spelling_detail_id": 120402,
                "word_type": "v.",
                "spelling_usage": "A kind of shortened raised biscuit or bread, often rolled or\n   doubled upon itself."
            },
            {
                "spelling_detail_id": 120403,
                "word_type": "v.",
                "spelling_usage": "The oscillating movement of a vessel from side to side, in\n   sea way, as distinguished from the alternate rise and fall of bow and\n   stern called pitching."
            },
            {
                "spelling_detail_id": 120404,
                "word_type": "v.",
                "spelling_usage": "A heavy, reverberatory sound; as, the roll of cannon, or of\n   thunder."
            },
            {
                "spelling_detail_id": 120405,
                "word_type": "v.",
                "spelling_usage": "The uniform beating of a drum with strokes so rapid as\n   scarcely to be distinguished by the ear."
            },
            {
                "spelling_detail_id": 120406,
                "word_type": "v.",
                "spelling_usage": "Part; office; duty; role."
            }
        ]
    },
    {
        "id": 77493,
        "spelling_name": "Rotund",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 120864,
                "word_type": "a.",
                "spelling_usage": "Round; circular; spherical."
            },
            {
                "spelling_detail_id": 120865,
                "word_type": "a.",
                "spelling_usage": "Hence, complete; entire."
            },
            {
                "spelling_detail_id": 120866,
                "word_type": "a.",
                "spelling_usage": "Orbicular, or nearly so."
            },
            {
                "spelling_detail_id": 120867,
                "word_type": "n.",
                "spelling_usage": "A rotunda."
            }
        ]
    },
    {
        "id": 77685,
        "spelling_name": "Rubicund",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 121275,
                "word_type": "a.",
                "spelling_usage": "Inclining to redness; ruddy; red."
            }
        ]
    },
    {
        "id": 77738,
        "spelling_name": "Ruddy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 121357,
                "word_type": "n.",
                "spelling_usage": "Of a red color; red, or reddish; as, a ruddy sky; a ruddy\n   flame."
            },
            {
                "spelling_detail_id": 121358,
                "word_type": "n.",
                "spelling_usage": "Of a lively flesh color, or the color of the human skin in\n   high health; as, ruddy cheeks or lips."
            },
            {
                "spelling_detail_id": 121359,
                "word_type": "v. t.",
                "spelling_usage": "To make ruddy."
            }
        ]
    },
    {
        "id": 77754,
        "spelling_name": "Rueful",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 121392,
                "word_type": "a.",
                "spelling_usage": "Causing one to rue or lament; woeful; mournful; sorrowful."
            },
            {
                "spelling_detail_id": 121393,
                "word_type": "a.",
                "spelling_usage": "Expressing sorrow."
            }
        ]
    },
    {
        "id": 77761,
        "spelling_name": "Ruffian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 121417,
                "word_type": "n.",
                "spelling_usage": "A pimp; a pander; also, a paramour."
            },
            {
                "spelling_detail_id": 121418,
                "word_type": "n.",
                "spelling_usage": "A boisterous, cruel, brutal fellow; a desperate fellow\n   ready for murderous or cruel deeds; a cutthroat."
            },
            {
                "spelling_detail_id": 121419,
                "word_type": "a.",
                "spelling_usage": "brutal; cruel; savagely boisterous; murderous; as, ruffian\n   rage."
            },
            {
                "spelling_detail_id": 121420,
                "word_type": "v. i.",
                "spelling_usage": "To play the ruffian; to rage; to raise tumult."
            }
        ]
    },
    {
        "id": 77855,
        "spelling_name": "Rumple",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 121609,
                "word_type": "v. t. & i.",
                "spelling_usage": "To make uneven; to form into irregular\n   inequalities; to wrinkle; to crumple; as, to rumple an apron or a\n   cravat."
            },
            {
                "spelling_detail_id": 121610,
                "word_type": "n.",
                "spelling_usage": "A fold or plait; a wrinkle."
            }
        ]
    },
    {
        "id": 78208,
        "spelling_name": "Sagacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122294,
                "word_type": "a.",
                "spelling_usage": "Of quick sense perceptions; keen-scented; skilled in\n   following a trail."
            },
            {
                "spelling_detail_id": 122295,
                "word_type": "a.",
                "spelling_usage": "Hence, of quick intellectual perceptions; of keen\n   penetration and judgment; discerning and judicious; knowing;\n   far-sighted; shrewd; sage; wise; as, a sagacious man; a sagacious\n   remark."
            }
        ]
    },
    {
        "id": 78297,
        "spelling_name": "Salacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122432,
                "word_type": "n.",
                "spelling_usage": "Having a propensity to venery; lustful; lecherous."
            }
        ]
    },
    {
        "id": 78466,
        "spelling_name": "Salubrious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122668,
                "word_type": "a.",
                "spelling_usage": "Favorable to health; healthful; promoting health; as,\n   salubrious air, water, or climate."
            }
        ]
    },
    {
        "id": 78469,
        "spelling_name": "Salutary",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122671,
                "word_type": "a.",
                "spelling_usage": "Wholesome; healthful; promoting health; as, salutary\n   exercise."
            },
            {
                "spelling_detail_id": 122672,
                "word_type": "a.",
                "spelling_usage": "Promotive of, or contributing to, some beneficial\n   purpose; beneficial; advantageous; as, a salutary design."
            }
        ]
    },
    {
        "id": 78482,
        "spelling_name": "Salvage",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122693,
                "word_type": "n.",
                "spelling_usage": "The act of saving a vessel, goods, or life, from perils of\n   the sea."
            },
            {
                "spelling_detail_id": 122694,
                "word_type": "n.",
                "spelling_usage": "The compensation allowed to persons who voluntarily assist\n   in saving a ship or her cargo from peril."
            },
            {
                "spelling_detail_id": 122695,
                "word_type": "n.",
                "spelling_usage": "That part of the property that survives the peril and is\n   saved."
            },
            {
                "spelling_detail_id": 122696,
                "word_type": "a. & n.",
                "spelling_usage": "Savage."
            }
        ]
    },
    {
        "id": 78551,
        "spelling_name": "Sanctimony",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122796,
                "word_type": "n.",
                "spelling_usage": "Holiness; devoutness; scrupulous austerity; sanctity;\n   especially, outward or artificial saintliness; assumed or pretended\n   holiness; hypocritical devoutness."
            }
        ]
    },
    {
        "id": 78552,
        "spelling_name": "Sanction",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122797,
                "word_type": "n.",
                "spelling_usage": "Solemn or ceremonious ratification; an official act of a\n   superior by which he ratifies and gives validity to the act of some\n   other person or body; establishment or furtherance of anything by\n   giving authority to it; confirmation; approbation."
            },
            {
                "spelling_detail_id": 122798,
                "word_type": "n.",
                "spelling_usage": "Anything done or said to enforce the will, law, or\n   authority of another; as, legal sanctions."
            },
            {
                "spelling_detail_id": 122801,
                "word_type": "v. t.",
                "spelling_usage": "To give sanction to; to ratify; to confirm; to\n   approve."
            }
        ]
    },
    {
        "id": 78623,
        "spelling_name": "Sanguine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122907,
                "word_type": "a.",
                "spelling_usage": "Having the color of blood; red."
            },
            {
                "spelling_detail_id": 122908,
                "word_type": "a.",
                "spelling_usage": "Characterized by abundance and active circulation of\n   blood; as, a sanguine bodily temperament."
            },
            {
                "spelling_detail_id": 122909,
                "word_type": "a.",
                "spelling_usage": "Warm; ardent; as, a sanguine temper."
            },
            {
                "spelling_detail_id": 122910,
                "word_type": "a.",
                "spelling_usage": "Anticipating the best; not desponding; confident; full of\n   hope; as, sanguine of success."
            },
            {
                "spelling_detail_id": 122911,
                "word_type": "n.",
                "spelling_usage": "Blood color; red."
            },
            {
                "spelling_detail_id": 122912,
                "word_type": "n.",
                "spelling_usage": "Anything of a blood-red color, as cloth."
            },
            {
                "spelling_detail_id": 122913,
                "word_type": "n.",
                "spelling_usage": "Bloodstone."
            },
            {
                "spelling_detail_id": 122914,
                "word_type": "n.",
                "spelling_usage": "Red crayon. See the Note under Crayon, 1."
            },
            {
                "spelling_detail_id": 122915,
                "word_type": "v. t.",
                "spelling_usage": "To stain with blood; to impart the color of blood to;\n   to ensanguine."
            }
        ]
    },
    {
        "id": 78647,
        "spelling_name": "Sanity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 122943,
                "word_type": "n.",
                "spelling_usage": "The condition or quality of being sane; soundness of health\n   of body or mind, especially of the mind; saneness."
            }
        ]
    },
    {
        "id": 78847,
        "spelling_name": "Sash",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123179,
                "word_type": "n.",
                "spelling_usage": "A scarf or band worn about the waist, over the shoulder, or\n   otherwise; a belt; a girdle, -- worn by women and children as an\n   ornament; also worn as a badge of distinction by military officers,\n   members of societies, etc."
            },
            {
                "spelling_detail_id": 123180,
                "word_type": "v. t.",
                "spelling_usage": "To adorn with a sash or scarf."
            },
            {
                "spelling_detail_id": 123181,
                "word_type": "n.",
                "spelling_usage": "The framing in which the panes of glass are set in a glazed\n   window or door, including the narrow bars between the panes."
            },
            {
                "spelling_detail_id": 123182,
                "word_type": "n.",
                "spelling_usage": "In a sawmill, the rectangular frame in which the saw is\n   strained and by which it is carried up and down with a reciprocating\n   motion; -- also called gate."
            },
            {
                "spelling_detail_id": 123185,
                "word_type": "v. t.",
                "spelling_usage": "To furnish with a sash or sashes; as, to sash a door or a\n   window."
            }
        ]
    },
    {
        "id": 78881,
        "spelling_name": "Satiate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123221,
                "word_type": "a.",
                "spelling_usage": "Filled to satiety; glutted; sated; -- followed by with or\n   of."
            },
            {
                "spelling_detail_id": 123224,
                "word_type": "v. t.",
                "spelling_usage": "To satisfy the appetite or desire of; to feed to the\n   full; to furnish enjoyment to, to the extent of desire; to sate; as, to\n   satiate appetite or sense."
            },
            {
                "spelling_detail_id": 123225,
                "word_type": "v. t.",
                "spelling_usage": "To full beyond natural desire; to gratify to repletion\n   or loathing; to surfeit; to glut."
            },
            {
                "spelling_detail_id": 123226,
                "word_type": "v. t.",
                "spelling_usage": "To saturate."
            }
        ]
    },
    {
        "id": 78931,
        "spelling_name": "Saturnine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123299,
                "word_type": "a.",
                "spelling_usage": "Born under, or influenced by, the planet Saturn."
            },
            {
                "spelling_detail_id": 123300,
                "word_type": "a.",
                "spelling_usage": "Heavy; grave; gloomy; dull; -- the opposite of\n   mercurial; as, a saturnine person or temper."
            },
            {
                "spelling_detail_id": 123301,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to lead; characterized by, or\n   resembling, lead, which was formerly called Saturn."
            }
        ]
    },
    {
        "id": 79003,
        "spelling_name": "Savant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123406,
                "word_type": "a.",
                "spelling_usage": "A man of learning; one versed in literature or science; a\n   person eminent for acquirements."
            }
        ]
    },
    {
        "id": 79020,
        "spelling_name": "Savor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123444,
                "word_type": "a.",
                "spelling_usage": "That property of a thing which affects the organs of taste\n   or smell; taste and odor; flavor; relish; scent; as, the savor of an\n   orange or a rose; an ill savor."
            },
            {
                "spelling_detail_id": 123445,
                "word_type": "a.",
                "spelling_usage": "Hence, specific flavor or quality; characteristic property;\n   distinctive temper, tinge, taint, and the like."
            },
            {
                "spelling_detail_id": 123446,
                "word_type": "a.",
                "spelling_usage": "Sense of smell; power to scent, or trace by scent."
            },
            {
                "spelling_detail_id": 123447,
                "word_type": "a.",
                "spelling_usage": "Pleasure; delight; attractiveness."
            },
            {
                "spelling_detail_id": 123450,
                "word_type": "n.",
                "spelling_usage": "To have a particular smell or taste; -- with of."
            },
            {
                "spelling_detail_id": 123451,
                "word_type": "n.",
                "spelling_usage": "To partake of the quality or nature; to indicate the\n   presence or influence; to smack; -- with of."
            },
            {
                "spelling_detail_id": 123452,
                "word_type": "n.",
                "spelling_usage": "To use the sense of taste."
            },
            {
                "spelling_detail_id": 123453,
                "word_type": "v. t.",
                "spelling_usage": "To perceive by the smell or the taste; hence, to\n   perceive; to note."
            },
            {
                "spelling_detail_id": 123454,
                "word_type": "v. t.",
                "spelling_usage": "To have the flavor or quality of; to indicate the\n   presence of."
            },
            {
                "spelling_detail_id": 123455,
                "word_type": "v. t.",
                "spelling_usage": "To taste or smell with pleasure; to delight in; to\n   relish; to like; to favor."
            }
        ]
    },
    {
        "id": 79045,
        "spelling_name": "Sawdust",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123488,
                "word_type": "n.",
                "spelling_usage": "Dust or small fragments of wood (or of stone, etc.) made\n   by the cutting of a saw."
            }
        ]
    },
    {
        "id": 79088,
        "spelling_name": "Scabbard",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123568,
                "word_type": "n.",
                "spelling_usage": "The case in which the blade of a sword, dagger, etc., is\n   kept; a sheath."
            },
            {
                "spelling_detail_id": 123569,
                "word_type": "v. t.",
                "spelling_usage": "To put in a scabbard."
            }
        ]
    },
    {
        "id": 79125,
        "spelling_name": "Scalding",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 123614,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Scald"
            }
        ]
    },
    {
        "id": 79328,
        "spelling_name": "Scent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 124000,
                "word_type": "v. t.",
                "spelling_usage": "To perceive by the olfactory organs; to smell; as, to\n   scent game, as a hound does."
            },
            {
                "spelling_detail_id": 124001,
                "word_type": "v. t.",
                "spelling_usage": "To imbue or fill with odor; to perfume."
            },
            {
                "spelling_detail_id": 124002,
                "word_type": "v. i.",
                "spelling_usage": "To have a smell."
            },
            {
                "spelling_detail_id": 124003,
                "word_type": "v. i.",
                "spelling_usage": "To hunt animals by means of the sense of smell."
            },
            {
                "spelling_detail_id": 124004,
                "word_type": "n.",
                "spelling_usage": "That which, issuing from a body, affects the olfactory\n   organs of animals; odor; smell; as, the scent of an orange, or of a\n   rose; the scent of musk."
            },
            {
                "spelling_detail_id": 124005,
                "word_type": "n.",
                "spelling_usage": "Specifically, the odor left by an animal on the ground in\n   passing over it; as, dogs find or lose the scent; hence, course of\n   pursuit; track of discovery."
            },
            {
                "spelling_detail_id": 124006,
                "word_type": "n.",
                "spelling_usage": "The power of smelling; the sense of smell; as, a hound of\n   nice scent; to divert the scent."
            }
        ]
    },
    {
        "id": 79666,
        "spelling_name": "Scorch",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 124464,
                "word_type": "v. t.",
                "spelling_usage": "To burn superficially; to parch, or shrivel, the surface\n   of, by heat; to subject to so much heat as changes color and texture\n   without consuming; as, to scorch linen."
            },
            {
                "spelling_detail_id": 124465,
                "word_type": "v. t.",
                "spelling_usage": "To affect painfully with heat, or as with heat; to dry\n   up with heat; to affect as by heat."
            },
            {
                "spelling_detail_id": 124466,
                "word_type": "v. t.",
                "spelling_usage": "To burn; to destroy by, or as by, fire."
            },
            {
                "spelling_detail_id": 124467,
                "word_type": "v. i.",
                "spelling_usage": "To be burnt on the surface; to be parched; to be dried\n   up."
            },
            {
                "spelling_detail_id": 124468,
                "word_type": "v. i.",
                "spelling_usage": "To burn or be burnt."
            }
        ]
    },
    {
        "id": 79830,
        "spelling_name": "Scribble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 124792,
                "word_type": "v. t.",
                "spelling_usage": "To card coarsely; to run through the scribbling\n   machine."
            },
            {
                "spelling_detail_id": 124795,
                "word_type": "v. t.",
                "spelling_usage": "To write hastily or carelessly, without regard to\n   correctness or elegance; as, to scribble a letter."
            },
            {
                "spelling_detail_id": 124796,
                "word_type": "v. t.",
                "spelling_usage": "To fill or cover with careless or worthless writing."
            },
            {
                "spelling_detail_id": 124797,
                "word_type": "v. i.",
                "spelling_usage": "To write without care, elegance, or value; to scrawl."
            },
            {
                "spelling_detail_id": 124798,
                "word_type": "n.",
                "spelling_usage": "Hasty or careless writing; a writing of little value; a\n   scrawl; as, a hasty scribble."
            }
        ]
    },
    {
        "id": 79989,
        "spelling_name": "Scurvy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 125065,
                "word_type": "n.",
                "spelling_usage": "Covered or affected with scurf or scabs; scabby; scurfy;\n   specifically, diseased with the scurvy."
            },
            {
                "spelling_detail_id": 125066,
                "word_type": "n.",
                "spelling_usage": "Vile; mean; low; vulgar; contemptible."
            },
            {
                "spelling_detail_id": 125067,
                "word_type": "n.",
                "spelling_usage": "A disease characterized by livid spots, especially about\n   the thighs and legs, due to extravasation of blood, and by spongy gums,\n   and bleeding from almost all the mucous membranes. It is accompanied by\n   paleness, languor, depression, and general debility. It is occasioned\n   by confinement, innutritious food, and hard labor, but especially by\n   lack of fresh vegetable food, or confinement for a long time to a\n   limited range of food, which is incapable of repairing the waste of the\n   system. It was formerly prevalent among sailors and soldiers."
            }
        ]
    },
    {
        "id": 80465,
        "spelling_name": "Secular",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 125806,
                "word_type": "a.",
                "spelling_usage": "Coming or observed once in an age or a century."
            },
            {
                "spelling_detail_id": 125807,
                "word_type": "a.",
                "spelling_usage": "Pertaining to an age, or the progress of ages, or to a\n   long period of time; accomplished in a long progress of time; as,\n   secular inequality; the secular refrigeration of the globe."
            },
            {
                "spelling_detail_id": 125808,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to this present world, or to things not\n   spiritual or holy; relating to temporal as distinguished from eternal\n   interests; not immediately or primarily respecting the soul, but the\n   body; worldly."
            },
            {
                "spelling_detail_id": 125809,
                "word_type": "a.",
                "spelling_usage": "Not regular; not bound by monastic vows or rules; not\n   confined to a monastery, or subject to the rules of a religious\n   community; as, a secular priest."
            },
            {
                "spelling_detail_id": 125810,
                "word_type": "a.",
                "spelling_usage": "Belonging to the laity; lay; not clerical."
            },
            {
                "spelling_detail_id": 125811,
                "word_type": "n.",
                "spelling_usage": "A secular ecclesiastic, or one not bound by monastic\n   rules."
            },
            {
                "spelling_detail_id": 125812,
                "word_type": "n.",
                "spelling_usage": "A church official whose functions are confined to the\n   vocal department of the choir."
            },
            {
                "spelling_detail_id": 125813,
                "word_type": "n.",
                "spelling_usage": "A layman, as distinguished from a clergyman."
            }
        ]
    },
    {
        "id": 80525,
        "spelling_name": "Sedulous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 125905,
                "word_type": "a.",
                "spelling_usage": "Diligent in application or pursuit; constant, steady, and\n   persevering in business, or in endeavors to effect an object; steadily\n   industrious; assiduous; as, the sedulous bee."
            }
        ]
    },
    {
        "id": 81054,
        "spelling_name": "Seminal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 126581,
                "word_type": "a.",
                "spelling_usage": "Pertaining to, containing, or consisting of, seed or\n   semen; as, the seminal fluid."
            },
            {
                "spelling_detail_id": 126582,
                "word_type": "a.",
                "spelling_usage": "Contained in seed; holding the relation of seed, source,\n   or first principle; holding the first place in a series of developed\n   results or consequents; germinal; radical; primary; original; as,\n   seminal principles of generation; seminal virtue."
            },
            {
                "spelling_detail_id": 126583,
                "word_type": "n.",
                "spelling_usage": "A seed."
            }
        ]
    },
    {
        "id": 81260,
        "spelling_name": "Sententious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 126885,
                "word_type": "a.",
                "spelling_usage": "Abounding with sentences, axioms, and maxims; full of\n   meaning; terse and energetic in expression; pithy; as, a sententious\n   style or discourse; sententious truth."
            },
            {
                "spelling_detail_id": 126886,
                "word_type": "a.",
                "spelling_usage": "Comprising or representing sentences; sentential."
            }
        ]
    },
    {
        "id": 81410,
        "spelling_name": "Sequence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127094,
                "word_type": "n.",
                "spelling_usage": "The state of being sequent; succession; order of\n   following; arrangement."
            },
            {
                "spelling_detail_id": 127095,
                "word_type": "n.",
                "spelling_usage": "That which follows or succeeds as an effect; sequel;\n   consequence; result."
            },
            {
                "spelling_detail_id": 127096,
                "word_type": "n.",
                "spelling_usage": "Simple succession, or the coming after in time, without\n   asserting or implying causative energy; as, the reactions of chemical\n   agents may be conceived as merely invariable sequences."
            },
            {
                "spelling_detail_id": 127097,
                "word_type": "n.",
                "spelling_usage": "Any succession of chords (or harmonic phrase) rising or\n   falling by the regular diatonic degrees in the same scale; a succession\n   of similar harmonic steps."
            },
            {
                "spelling_detail_id": 127098,
                "word_type": "n.",
                "spelling_usage": "A melodic phrase or passage successively repeated one\n   tone higher; a rosalia."
            },
            {
                "spelling_detail_id": 127099,
                "word_type": "n.",
                "spelling_usage": "A hymn introduced in the Mass on certain festival days,\n   and recited or sung immediately before the gospel, and after the\n   gradual or introit, whence the name."
            },
            {
                "spelling_detail_id": 127100,
                "word_type": "n.",
                "spelling_usage": "Three or more cards of the same suit in immediately\n   consecutive order of value; as, ace, king, and queen; or knave, ten,\n   nine, and eight."
            },
            {
                "spelling_detail_id": 127101,
                "word_type": "n.",
                "spelling_usage": "All five cards, of a hand, in consecutive order as to\n   value, but not necessarily of the same suit; when of one suit, it is\n   called a sequence flush."
            }
        ]
    },
    {
        "id": 81444,
        "spelling_name": "Sere",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 125463,
                "word_type": "a.",
                "spelling_usage": "[OE. seer, AS. sear (assumed) fr. searian to wither; akin to\n   D. zoor dry, LG. soor, OHG. sor/n to to wither, Gr. a\"y`ein to parch,\n   to dry, Skr. /ush (for sush) to dry, to wither, Zend hush to dry. Ã152.\n   Cf. Austere, Sorrel, a.] Dry; withered; no longer green; -- applied to\n   leaves."
            },
            {
                "spelling_detail_id": 127157,
                "word_type": "a.",
                "spelling_usage": "Dry; withered. Same as Sear."
            },
            {
                "spelling_detail_id": 127158,
                "word_type": "n.",
                "spelling_usage": "Claw; talon."
            }
        ]
    },
    {
        "id": 81494,
        "spelling_name": "Sermon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127231,
                "word_type": "n.",
                "spelling_usage": "A discourse or address; a talk; a writing; as, the sermons\n   of Chaucer."
            },
            {
                "spelling_detail_id": 127232,
                "word_type": "n.",
                "spelling_usage": "Specifically, a discourse delivered in public, usually by a\n   clergyman, for the purpose of religious instruction and grounded on\n   some text or passage of Scripture."
            },
            {
                "spelling_detail_id": 127233,
                "word_type": "n.",
                "spelling_usage": "Hence, a serious address; a lecture on one's conduct or\n   duty; an exhortation or reproof; a homily; -- often in a depreciatory\n   sense."
            },
            {
                "spelling_detail_id": 127234,
                "word_type": "v. i.",
                "spelling_usage": "To speak; to discourse; to compose or deliver a sermon."
            },
            {
                "spelling_detail_id": 127235,
                "word_type": "v. t.",
                "spelling_usage": "To discourse to or of, as in a sermon."
            },
            {
                "spelling_detail_id": 127236,
                "word_type": "v. t.",
                "spelling_usage": "To tutor; to lecture."
            }
        ]
    },
    {
        "id": 81547,
        "spelling_name": "Serration",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127307,
                "word_type": "n.",
                "spelling_usage": "Condition of being serrate; formation in the shape of a\n   saw."
            },
            {
                "spelling_detail_id": 127308,
                "word_type": "n.",
                "spelling_usage": "One of the teeth in a serrate or serrulate margin."
            }
        ]
    },
    {
        "id": 81583,
        "spelling_name": "Servile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127389,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a servant or slave; befitting a\n   servant or a slave; proceeding from dependence; hence, meanly\n   submissive; slavish; mean; cringing; fawning; as, servile flattery;\n   servile fear; servile obedience."
            },
            {
                "spelling_detail_id": 127390,
                "word_type": "a.",
                "spelling_usage": "Held in subjection; dependent; enslaved."
            },
            {
                "spelling_detail_id": 127391,
                "word_type": "a.",
                "spelling_usage": "Not belonging to the original root; as, a servile letter."
            },
            {
                "spelling_detail_id": 127392,
                "word_type": "a.",
                "spelling_usage": "Not itself sounded, but serving to lengthen the preceeding\n   vowel, as e in tune."
            },
            {
                "spelling_detail_id": 127393,
                "word_type": "n.",
                "spelling_usage": "An element which forms no part of the original root; --\n   opposed to radical."
            }
        ]
    },
    {
        "id": 81691,
        "spelling_name": "Sever",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127656,
                "word_type": "v. t.",
                "spelling_usage": "To separate, as one from another; to cut off from\n   something; to divide; to part in any way, especially by violence, as by\n   cutting, rending, etc.; as, to sever the head from the body."
            },
            {
                "spelling_detail_id": 127657,
                "word_type": "v. t.",
                "spelling_usage": "To cut or break open or apart; to divide into parts; to\n   cut through; to disjoin; as, to sever the arm or leg."
            },
            {
                "spelling_detail_id": 127658,
                "word_type": "v. t.",
                "spelling_usage": "To keep distinct or apart; to except; to exempt."
            },
            {
                "spelling_detail_id": 127659,
                "word_type": "v. t.",
                "spelling_usage": "To disunite; to disconnect; to terminate; as, to sever an\n   estate in joint tenancy."
            },
            {
                "spelling_detail_id": 127660,
                "word_type": "v. i.",
                "spelling_usage": "To suffer disjunction; to be parted, or rent asunder; to\n   be separated; to part; to separate."
            },
            {
                "spelling_detail_id": 127661,
                "word_type": "v. i.",
                "spelling_usage": "To make a separation or distinction; to distinguish."
            }
        ]
    },
    {
        "id": 81699,
        "spelling_name": "Severance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127675,
                "word_type": "n.",
                "spelling_usage": "The act of severing, or the state of being severed;\n   partition; separation."
            },
            {
                "spelling_detail_id": 127676,
                "word_type": "n.",
                "spelling_usage": "The act of dividing; the singling or severing of two or\n   more that join, or are joined, in one writ; the putting in several or\n   separate pleas or answers by two or more disjointly; the destruction of\n   the unity of interest in a joint estate."
            }
        ]
    },
    {
        "id": 81869,
        "spelling_name": "Shallow",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 127998,
                "word_type": "superl.",
                "spelling_usage": "Not deep; having little depth; shoal."
            },
            {
                "spelling_detail_id": 127999,
                "word_type": "superl.",
                "spelling_usage": "Not deep in tone."
            },
            {
                "spelling_detail_id": 128000,
                "word_type": "superl.",
                "spelling_usage": "Not intellectually deep; not profound; not\n   penetrating deeply; simple; not wise or knowing; ignorant; superficial;\n   as, a shallow mind; shallow learning."
            },
            {
                "spelling_detail_id": 128001,
                "word_type": "n.",
                "spelling_usage": "A place in a body of water where the water is not deep; a\n   shoal; a flat; a shelf."
            },
            {
                "spelling_detail_id": 128002,
                "word_type": "n.",
                "spelling_usage": "The rudd."
            },
            {
                "spelling_detail_id": 128003,
                "word_type": "v. t.",
                "spelling_usage": "To make shallow."
            },
            {
                "spelling_detail_id": 128004,
                "word_type": "v. i.",
                "spelling_usage": "To become shallow, as water."
            }
        ]
    },
    {
        "id": 81934,
        "spelling_name": "Shard",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 128125,
                "word_type": "n.",
                "spelling_usage": "A plant; chard."
            },
            {
                "spelling_detail_id": 128126,
                "word_type": "n.",
                "spelling_usage": "A piece or fragment of an earthen vessel, or a like brittle\n   substance, as the shell of an egg or snail."
            },
            {
                "spelling_detail_id": 128127,
                "word_type": "n.",
                "spelling_usage": "The hard wing case of a beetle."
            },
            {
                "spelling_detail_id": 128128,
                "word_type": "n.",
                "spelling_usage": "A gap in a fence."
            },
            {
                "spelling_detail_id": 128129,
                "word_type": "n.",
                "spelling_usage": "A boundary; a division."
            }
        ]
    },
    {
        "id": 82013,
        "spelling_name": "Sheath",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 128325,
                "word_type": "n.",
                "spelling_usage": "A case for the reception of a sword, hunting knife, or\n   other long and slender instrument; a scabbard."
            },
            {
                "spelling_detail_id": 128326,
                "word_type": "n.",
                "spelling_usage": "Any sheathlike covering, organ, or part."
            },
            {
                "spelling_detail_id": 128327,
                "word_type": "n.",
                "spelling_usage": "The base of a leaf when sheathing or investing a stem or\n   branch, as in grasses."
            },
            {
                "spelling_detail_id": 128328,
                "word_type": "n.",
                "spelling_usage": "One of the elytra of an insect."
            }
        ]
    },
    {
        "id": 82480,
        "spelling_name": "Shrewd",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 129233,
                "word_type": "superl.",
                "spelling_usage": "Inclining to shrew; disposing to curse or scold;\n   hence, vicious; malicious; evil; wicked; mischievous; vexatious; rough;\n   unfair; shrewish."
            },
            {
                "spelling_detail_id": 129234,
                "word_type": "superl.",
                "spelling_usage": "Artful; wily; cunning; arch."
            },
            {
                "spelling_detail_id": 129235,
                "word_type": "superl.",
                "spelling_usage": "Able or clever in practical affairs; sharp in\n   business; astute; sharp-witted; sagacious; keen; as, a shrewd observer;\n   a shrewd design; a shrewd reply."
            }
        ]
    },
    {
        "id": 82493,
        "spelling_name": "Shrill",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 129253,
                "word_type": "v. i.",
                "spelling_usage": "Acute; sharp; piercing; having or emitting a sharp,\n   piercing tone or sound; -- said of a sound, or of that which produces a\n   sound."
            },
            {
                "spelling_detail_id": 129254,
                "word_type": "n.",
                "spelling_usage": "A shrill sound."
            },
            {
                "spelling_detail_id": 129257,
                "word_type": "v. i.",
                "spelling_usage": "To utter an acute, piercing sound; to sound with a\n   sharp, shrill tone; to become shrill."
            },
            {
                "spelling_detail_id": 129258,
                "word_type": "v. t.",
                "spelling_usage": "To utter or express in a shrill tone; to cause to make a\n   shrill sound."
            }
        ]
    },
    {
        "id": 82563,
        "spelling_name": "Shun",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 129390,
                "word_type": "v. t.",
                "spelling_usage": "To avoid; to keep clear of; to get out of the way of; to\n   escape from; to eschew; as, to shun rocks, shoals, vice."
            }
        ]
    },
    {
        "id": 82565,
        "spelling_name": "Shunned",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 129388,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Shun"
            }
        ]
    },
    {
        "id": 82567,
        "spelling_name": "Shunt",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 129394,
                "word_type": "v. t.",
                "spelling_usage": "To shun; to move from."
            },
            {
                "spelling_detail_id": 129395,
                "word_type": "v. t.",
                "spelling_usage": "To cause to move suddenly; to give a sudden start to; to\n   shove."
            },
            {
                "spelling_detail_id": 129396,
                "word_type": "v. t.",
                "spelling_usage": "To turn off to one side; especially, to turn off, as a\n   grain or a car upon a side track; to switch off; to shift."
            },
            {
                "spelling_detail_id": 129397,
                "word_type": "v. t.",
                "spelling_usage": "To provide with a shunt; as, to shunt a galvanometer."
            },
            {
                "spelling_detail_id": 129398,
                "word_type": "v. i.",
                "spelling_usage": "To go aside; to turn off."
            },
            {
                "spelling_detail_id": 129399,
                "word_type": "v. t.",
                "spelling_usage": "A turning off to a side or short track, that the\n   principal track may be left free."
            },
            {
                "spelling_detail_id": 129400,
                "word_type": "v. t.",
                "spelling_usage": "A conducting circuit joining two points in a conductor,\n   or the terminals of a galvanometer or dynamo, so as to form a parallel\n   or derived circuit through which a portion of the current may pass, for\n   the purpose of regulating the amount passing in the main circuit."
            },
            {
                "spelling_detail_id": 129401,
                "word_type": "v. t.",
                "spelling_usage": "The shifting of the studs on a projectile from the deep\n   to the shallow sides of the grooves in its discharge from a shunt gun."
            }
        ]
    },
    {
        "id": 82945,
        "spelling_name": "Simper",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 130047,
                "word_type": "v. i.",
                "spelling_usage": "To smile in a silly, affected, or conceited manner."
            },
            {
                "spelling_detail_id": 130048,
                "word_type": "v. i.",
                "spelling_usage": "To glimmer; to twinkle."
            },
            {
                "spelling_detail_id": 130049,
                "word_type": "n.",
                "spelling_usage": "A constrained, self-conscious smile; an affected, silly\n   smile; a smirk."
            }
        ]
    },
    {
        "id": 83105,
        "spelling_name": "Sinuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 130352,
                "word_type": "a.",
                "spelling_usage": "Bending in and out; of a serpentine or undulating form;\n   winding; crooked."
            }
        ]
    },
    {
        "id": 83340,
        "spelling_name": "Sketchy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 130762,
                "word_type": "a.",
                "spelling_usage": "Containing only an outline or rough form; being in the\n   manner of a sketch; incomplete."
            }
        ]
    },
    {
        "id": 83357,
        "spelling_name": "Skiff",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 130791,
                "word_type": "n.",
                "spelling_usage": "A small, light boat."
            },
            {
                "spelling_detail_id": 130794,
                "word_type": "v. t.",
                "spelling_usage": "To navigate in a skiff."
            }
        ]
    },
    {
        "id": 83425,
        "spelling_name": "Skit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 130936,
                "word_type": "v. t.",
                "spelling_usage": "To cast reflections on; to asperse."
            },
            {
                "spelling_detail_id": 130937,
                "word_type": "n.",
                "spelling_usage": "A reflection; a jeer or gibe; a sally; a brief satire; a\n   squib."
            },
            {
                "spelling_detail_id": 130938,
                "word_type": "n.",
                "spelling_usage": "A wanton girl; a light wench."
            }
        ]
    },
    {
        "id": 83495,
        "spelling_name": "Slack",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 131041,
                "word_type": "n.",
                "spelling_usage": "Small coal; also, coal dust; culm."
            },
            {
                "spelling_detail_id": 131042,
                "word_type": "n.",
                "spelling_usage": "A valley, or small, shallow dell."
            },
            {
                "spelling_detail_id": 131043,
                "word_type": "superl.",
                "spelling_usage": "Lax; not tense; not hard drawn; not firmly extended;\n   as, a slack rope."
            },
            {
                "spelling_detail_id": 131044,
                "word_type": "superl.",
                "spelling_usage": "Weak; not holding fast; as, a slack hand."
            },
            {
                "spelling_detail_id": 131045,
                "word_type": "superl.",
                "spelling_usage": "Remiss; backward; not using due diligence or care; not\n   earnest or eager; as, slack in duty or service."
            },
            {
                "spelling_detail_id": 131046,
                "word_type": "superl.",
                "spelling_usage": "Not violent, rapid, or pressing; slow; moderate; easy;\n   as, business is slack."
            },
            {
                "spelling_detail_id": 131047,
                "word_type": "adv.",
                "spelling_usage": "Slackly; as, slack dried hops."
            },
            {
                "spelling_detail_id": 131048,
                "word_type": "n.",
                "spelling_usage": "The part of anything that hangs loose, having no strain upon\n   it; as, the slack of a rope or of a sail."
            },
            {
                "spelling_detail_id": 131053,
                "word_type": "a.",
                "spelling_usage": "Alt. of Slacken"
            },
            {
                "spelling_detail_id": 131061,
                "word_type": "v. t.",
                "spelling_usage": "Alt. of Slacken"
            }
        ]
    },
    {
        "id": 83508,
        "spelling_name": "Slake",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 131078,
                "word_type": "a.",
                "spelling_usage": "To allay; to quench; to extinguish; as, to slake thirst."
            },
            {
                "spelling_detail_id": 131079,
                "word_type": "a.",
                "spelling_usage": "To mix with water, so that a true chemical combination shall\n   take place; to slack; as, to slake lime."
            },
            {
                "spelling_detail_id": 131080,
                "word_type": "v. i.",
                "spelling_usage": "To go out; to become extinct."
            },
            {
                "spelling_detail_id": 131081,
                "word_type": "v. i.",
                "spelling_usage": "To abate; to become less decided."
            },
            {
                "spelling_detail_id": 131082,
                "word_type": "v. i.",
                "spelling_usage": "To slacken; to become relaxed."
            },
            {
                "spelling_detail_id": 131083,
                "word_type": "v. i.",
                "spelling_usage": "To become mixed with water, so that a true chemical\n   combination takes place; as, the lime slakes."
            }
        ]
    },
    {
        "id": 83553,
        "spelling_name": "Slate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 131171,
                "word_type": "v. t.",
                "spelling_usage": "An argillaceous rock which readily splits into thin\n   plates; argillite; argillaceous schist."
            },
            {
                "spelling_detail_id": 131172,
                "word_type": "v. t.",
                "spelling_usage": "Any rock or stone having a slaty structure."
            },
            {
                "spelling_detail_id": 131173,
                "word_type": "v. t.",
                "spelling_usage": "A prepared piece of such stone."
            },
            {
                "spelling_detail_id": 131174,
                "word_type": "v. t.",
                "spelling_usage": "A thin, flat piece, for roofing or covering houses, etc."
            },
            {
                "spelling_detail_id": 131175,
                "word_type": "v. t.",
                "spelling_usage": "A tablet for writing upon."
            },
            {
                "spelling_detail_id": 131176,
                "word_type": "v. t.",
                "spelling_usage": "An artificial material, resembling slate, and used for\n   the above purposes."
            },
            {
                "spelling_detail_id": 131177,
                "word_type": "v. t.",
                "spelling_usage": "A thin plate of any material; a flake."
            },
            {
                "spelling_detail_id": 131178,
                "word_type": "v. t.",
                "spelling_usage": "A list of candidates, prepared for nomination or for\n   election; a list of candidates, or a programme of action, devised\n   beforehand."
            },
            {
                "spelling_detail_id": 131181,
                "word_type": "v. t.",
                "spelling_usage": "To cover with slate, or with a substance resembling\n   slate; as, to slate a roof; to slate a globe."
            },
            {
                "spelling_detail_id": 131182,
                "word_type": "v. t.",
                "spelling_usage": "To register (as on a slate and subject to revision), for\n   an appointment."
            },
            {
                "spelling_detail_id": 131183,
                "word_type": "v. t.",
                "spelling_usage": "To set a dog upon; to bait; to slat. See 2d Slat, 3."
            }
        ]
    },
    {
        "id": 83843,
        "spelling_name": "Sluggard",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 131787,
                "word_type": "n.",
                "spelling_usage": "A person habitually lazy, idle, and inactive; a drone."
            },
            {
                "spelling_detail_id": 131788,
                "word_type": "a.",
                "spelling_usage": "Sluggish; lazy."
            }
        ]
    },
    {
        "id": 83876,
        "spelling_name": "Slur",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 131840,
                "word_type": "v. t.",
                "spelling_usage": "To soil; to sully; to contaminate; to disgrace."
            },
            {
                "spelling_detail_id": 131841,
                "word_type": "v. t.",
                "spelling_usage": "To disparage; to traduce."
            },
            {
                "spelling_detail_id": 131842,
                "word_type": "v. t.",
                "spelling_usage": "To cover over; to disguise; to conceal; to pass over\n   lightly or with little notice."
            },
            {
                "spelling_detail_id": 131843,
                "word_type": "v. t.",
                "spelling_usage": "To cheat, as by sliding a die; to trick."
            },
            {
                "spelling_detail_id": 131844,
                "word_type": "v. t.",
                "spelling_usage": "To pronounce indistinctly; as, to slur syllables."
            },
            {
                "spelling_detail_id": 131845,
                "word_type": "v. t.",
                "spelling_usage": "To sing or perform in a smooth, gliding style; to connect\n   smoothly in performing, as several notes or tones."
            },
            {
                "spelling_detail_id": 131846,
                "word_type": "v. t.",
                "spelling_usage": "To blur or double, as an impression from type; to mackle."
            },
            {
                "spelling_detail_id": 131847,
                "word_type": "n.",
                "spelling_usage": "A mark or stain; hence, a slight reproach or disgrace; a\n   stigma; a reproachful intimation; an innuendo."
            },
            {
                "spelling_detail_id": 131848,
                "word_type": "n.",
                "spelling_usage": "A trick played upon a person; an imposition."
            },
            {
                "spelling_detail_id": 131849,
                "word_type": "n.",
                "spelling_usage": "A mark, thus [/ or /], connecting notes that are to be sung\n   to the same syllable, or made in one continued breath of a wind\n   instrument, or with one stroke of a bow; a tie; a sign of legato."
            },
            {
                "spelling_detail_id": 131850,
                "word_type": "n.",
                "spelling_usage": "In knitting machines, a contrivance for depressing the\n   sinkers successively by passing over them."
            }
        ]
    },
    {
        "id": 84017,
        "spelling_name": "Smolder",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132145,
                "word_type": "v. i.",
                "spelling_usage": "Alt. of Smoulder"
            },
            {
                "spelling_detail_id": 132148,
                "word_type": "v. t.",
                "spelling_usage": "Alt. of Smoulder"
            },
            {
                "spelling_detail_id": 132150,
                "word_type": "n.",
                "spelling_usage": "Alt. of Smoulder"
            }
        ]
    },
    {
        "id": 84117,
        "spelling_name": "Snare",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132358,
                "word_type": "n.",
                "spelling_usage": "A contrivance, often consisting of a noose of cord, or the\n   like, by which a bird or other animal may be entangled and caught; a\n   trap; a gin."
            },
            {
                "spelling_detail_id": 132359,
                "word_type": "n.",
                "spelling_usage": "Hence, anything by which one is entangled and brought into\n   trouble."
            },
            {
                "spelling_detail_id": 132360,
                "word_type": "n.",
                "spelling_usage": "The gut or string stretched across the lower head of a drum."
            },
            {
                "spelling_detail_id": 132361,
                "word_type": "n.",
                "spelling_usage": "An instrument, consisting usually of a wireloop or noose,\n   for removing tumors, etc., by avulsion."
            },
            {
                "spelling_detail_id": 132364,
                "word_type": "v. t.",
                "spelling_usage": "To catch with a snare; to insnare; to entangle; hence, to\n   bring into unexpected evil, perplexity, or danger."
            }
        ]
    },
    {
        "id": 84271,
        "spelling_name": "Snub",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132623,
                "word_type": "v. i.",
                "spelling_usage": "To sob with convulsions."
            },
            {
                "spelling_detail_id": 132626,
                "word_type": "v. t.",
                "spelling_usage": "To clip or break off the end of; to check or stunt the\n   growth of; to nop."
            },
            {
                "spelling_detail_id": 132627,
                "word_type": "v. t.",
                "spelling_usage": "To check, stop, or rebuke, with a tart, sarcastic reply or\n   remark; to reprimand; to check."
            },
            {
                "spelling_detail_id": 132628,
                "word_type": "v. t.",
                "spelling_usage": "To treat with contempt or neglect, as a forward or\n   pretentious person; to slight designedly."
            },
            {
                "spelling_detail_id": 132629,
                "word_type": "n.",
                "spelling_usage": "A knot; a protuberance; a song."
            },
            {
                "spelling_detail_id": 132630,
                "word_type": "n.",
                "spelling_usage": "A check or rebuke; an intended slight."
            }
        ]
    },
    {
        "id": 84323,
        "spelling_name": "Soar",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132724,
                "word_type": "v. i.",
                "spelling_usage": "To fly aloft, as a bird; to mount upward on wings, or as\n   on wings."
            },
            {
                "spelling_detail_id": 132725,
                "word_type": "v. i.",
                "spelling_usage": "Fig.: To rise in thought, spirits, or imagination; to be\n   exalted in mood."
            },
            {
                "spelling_detail_id": 132726,
                "word_type": "n.",
                "spelling_usage": "The act of soaring; upward flight."
            },
            {
                "spelling_detail_id": 132727,
                "word_type": "a.",
                "spelling_usage": "See 3d Sore."
            },
            {
                "spelling_detail_id": 132728,
                "word_type": "a.",
                "spelling_usage": "See Sore, reddish brown."
            }
        ]
    },
    {
        "id": 84331,
        "spelling_name": "Sober",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132739,
                "word_type": "superl.",
                "spelling_usage": "Temperate in the use of spirituous liquors; habitually\n   temperate; as, a sober man."
            },
            {
                "spelling_detail_id": 132740,
                "word_type": "superl.",
                "spelling_usage": "Not intoxicated or excited by spirituous liquors; as,\n   the sot may at times be sober."
            },
            {
                "spelling_detail_id": 132741,
                "word_type": "superl.",
                "spelling_usage": "Not mad or insane; not wild, visionary, or heated with\n   passion; exercising cool, dispassionate reason; self-controlled;\n   self-possessed."
            },
            {
                "spelling_detail_id": 132742,
                "word_type": "superl.",
                "spelling_usage": "Not proceeding from, or attended with, passion; calm;\n   as, sober judgment; a man in his sober senses."
            },
            {
                "spelling_detail_id": 132743,
                "word_type": "superl.",
                "spelling_usage": "Serious or subdued in demeanor, habit, appearance, or\n   color; solemn; grave; sedate."
            },
            {
                "spelling_detail_id": 132746,
                "word_type": "v. t.",
                "spelling_usage": "To make sober."
            },
            {
                "spelling_detail_id": 132747,
                "word_type": "v. i.",
                "spelling_usage": "To become sober; -- often with down."
            }
        ]
    },
    {
        "id": 84340,
        "spelling_name": "Sobriety",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132756,
                "word_type": "n.",
                "spelling_usage": "Habitual soberness or temperance as to the use of\n   spirituous liquors; as, a man of sobriety."
            },
            {
                "spelling_detail_id": 132757,
                "word_type": "n.",
                "spelling_usage": "Habitual freedom from enthusiasm, inordinate passion, or\n   overheated imagination; calmness; coolness; gravity; seriousness; as,\n   the sobriety of riper years."
            }
        ]
    },
    {
        "id": 84341,
        "spelling_name": "Sobriquet",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132758,
                "word_type": "n.",
                "spelling_usage": "An assumed name; a fanciful epithet or appellation; a\n   nickname."
            }
        ]
    },
    {
        "id": 84395,
        "spelling_name": "Sodden",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 126025,
                "word_type": "",
                "spelling_usage": "of Seethe"
            },
            {
                "spelling_detail_id": 132838,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Sod"
            },
            {
                "spelling_detail_id": 132849,
                "word_type": "p. p.",
                "spelling_usage": "Boiled; seethed; also, soaked; heavy with moisture;\n   saturated; as, sodden beef; sodden bread; sodden fields."
            },
            {
                "spelling_detail_id": 132850,
                "word_type": "v. i.",
                "spelling_usage": "To be seethed; to become sodden."
            },
            {
                "spelling_detail_id": 132851,
                "word_type": "v. t.",
                "spelling_usage": "To soak; to make heavy with water."
            }
        ]
    },
    {
        "id": 84433,
        "spelling_name": "Soggy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 132916,
                "word_type": "superl.",
                "spelling_usage": "Filled with water; soft with moisture; sodden; soaked;\n   wet; as, soggy land or timber."
            }
        ]
    },
    {
        "id": 84631,
        "spelling_name": "Solvent",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133253,
                "word_type": "a.",
                "spelling_usage": "Having the power of dissolving; dissolving; as, a solvent\n   fluid."
            },
            {
                "spelling_detail_id": 133254,
                "word_type": "a.",
                "spelling_usage": "Able or sufficient to pay all just debts; as, a solvent\n   merchant; the estate is solvent."
            },
            {
                "spelling_detail_id": 133255,
                "word_type": "n.",
                "spelling_usage": "A substance (usually liquid) suitable for, or employed in,\n   solution, or in dissolving something; as, water is the appropriate\n   solvent of most salts, alcohol of resins, ether of fats, and mercury or\n   acids of metals, etc."
            },
            {
                "spelling_detail_id": 133256,
                "word_type": "n.",
                "spelling_usage": "That which resolves; as, a solvent of mystery."
            }
        ]
    },
    {
        "id": 84640,
        "spelling_name": "Somatic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133265,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the body as a whole; corporeal; as,\n   somatic death; somatic changes."
            },
            {
                "spelling_detail_id": 133266,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the wall of the body; somatopleuric;\n   parietal; as, the somatic stalk of the yolk sac of an embryo."
            }
        ]
    },
    {
        "id": 84662,
        "spelling_name": "Somersault",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133303,
                "word_type": "n.",
                "spelling_usage": "Alt. of Somerset"
            }
        ]
    },
    {
        "id": 84760,
        "spelling_name": "Soot",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133438,
                "word_type": "n.",
                "spelling_usage": "A black substance formed by combustion, or disengaged from\n   fuel in the process of combustion, which rises in fine particles, and\n   adheres to the sides of the chimney or pipe conveying the smoke;\n   strictly, the fine powder, consisting chiefly of carbon, which colors\n   smoke, and which is the result of imperfect combustion. See Smoke."
            },
            {
                "spelling_detail_id": 133441,
                "word_type": "v. t.",
                "spelling_usage": "To cover or dress with soot; to smut with, or as with,\n   soot; as, to soot land."
            },
            {
                "spelling_detail_id": 133442,
                "word_type": "a.",
                "spelling_usage": "Alt. of Soote"
            }
        ]
    },
    {
        "id": 84794,
        "spelling_name": "Sophisticated",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133497,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Sophisticate"
            },
            {
                "spelling_detail_id": 133501,
                "word_type": "a.",
                "spelling_usage": "Adulterated; not pure; not genuine."
            }
        ]
    },
    {
        "id": 84798,
        "spelling_name": "Sophistry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133504,
                "word_type": "n.",
                "spelling_usage": "The art or process of reasoning; logic."
            },
            {
                "spelling_detail_id": 133505,
                "word_type": "n.",
                "spelling_usage": "The practice of a sophist; fallacious reasoning;\n   reasoning sound in appearance only."
            }
        ]
    },
    {
        "id": 84800,
        "spelling_name": "Sophomoric",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133507,
                "word_type": "a.",
                "spelling_usage": "Alt. of Sophomorical"
            }
        ]
    },
    {
        "id": 84809,
        "spelling_name": "Soporific",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133517,
                "word_type": "a.",
                "spelling_usage": "Causing sleep; tending to cause sleep; soporiferous; as,\n   the soporific virtues of opium."
            },
            {
                "spelling_detail_id": 133518,
                "word_type": "n.",
                "spelling_usage": "A medicine, drug, plant, or other agent that has the\n   quality of inducing sleep; a narcotic."
            }
        ]
    },
    {
        "id": 84845,
        "spelling_name": "Sordid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 133555,
                "word_type": "a.",
                "spelling_usage": "Filthy; foul; dirty."
            },
            {
                "spelling_detail_id": 133556,
                "word_type": "a.",
                "spelling_usage": "Vile; base; gross; mean; as, vulgar, sordid mortals."
            },
            {
                "spelling_detail_id": 133557,
                "word_type": "a.",
                "spelling_usage": "Meanly avaricious; covetous; niggardly."
            }
        ]
    },
    {
        "id": 85255,
        "spelling_name": "Spear",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 134311,
                "word_type": "n.",
                "spelling_usage": "A long, pointed weapon, used in war and hunting, by\n   thrusting or throwing; a weapon with a long shaft and a sharp head or\n   blade; a lance."
            },
            {
                "spelling_detail_id": 134312,
                "word_type": "n.",
                "spelling_usage": "Fig.: A spearman."
            },
            {
                "spelling_detail_id": 134313,
                "word_type": "n.",
                "spelling_usage": "A sharp-pointed instrument with barbs, used for stabbing\n   fish and other animals."
            },
            {
                "spelling_detail_id": 134314,
                "word_type": "n.",
                "spelling_usage": "A shoot, as of grass; a spire."
            },
            {
                "spelling_detail_id": 134315,
                "word_type": "n.",
                "spelling_usage": "The feather of a horse. See Feather, n., 4."
            },
            {
                "spelling_detail_id": 134316,
                "word_type": "n.",
                "spelling_usage": "The rod to which the bucket, or plunger, of a pump is\n   attached; a pump rod."
            },
            {
                "spelling_detail_id": 134319,
                "word_type": "v. t.",
                "spelling_usage": "To pierce with a spear; to kill with a spear; as, to\n   spear a fish."
            },
            {
                "spelling_detail_id": 134320,
                "word_type": "v. i.",
                "spelling_usage": "To shoot into a long stem, as some plants. See Spire."
            }
        ]
    },
    {
        "id": 85294,
        "spelling_name": "Specious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 134392,
                "word_type": "a.",
                "spelling_usage": "Presenting a pleasing appearance; pleasing in form or\n   look; showy."
            },
            {
                "spelling_detail_id": 134393,
                "word_type": "a.",
                "spelling_usage": "Apparently right; superficially fair, just, or correct,\n   but not so in reality; appearing well at first view; plausible; as,\n   specious reasoning; a specious argument."
            }
        ]
    },
    {
        "id": 85818,
        "spelling_name": "Splenetic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135263,
                "word_type": "a.",
                "spelling_usage": "Affected with spleen; malicious; spiteful; peevish;\n   fretful."
            },
            {
                "spelling_detail_id": 135264,
                "word_type": "n.",
                "spelling_usage": "A person affected with spleen."
            }
        ]
    },
    {
        "id": 85837,
        "spelling_name": "Splice",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135289,
                "word_type": "v. t.",
                "spelling_usage": "To unite, as two ropes, or parts of a rope, by a\n   particular manner of interweaving the strands, -- the union being\n   between two ends, or between an end and the body of a rope."
            },
            {
                "spelling_detail_id": 135290,
                "word_type": "v. t.",
                "spelling_usage": "To unite, as spars, timbers, rails, etc., by lapping the\n   two ends together, or by applying a piece which laps upon the two ends,\n   and then binding, or in any way making fast."
            },
            {
                "spelling_detail_id": 135291,
                "word_type": "v. t.",
                "spelling_usage": "To unite in marrige."
            },
            {
                "spelling_detail_id": 135292,
                "word_type": "n.",
                "spelling_usage": "A junction or joining made by splicing."
            }
        ]
    },
    {
        "id": 85901,
        "spelling_name": "Sponge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135409,
                "word_type": "n.",
                "spelling_usage": "Any one of numerous species of Spongiae, or Porifera. See\n   Illust. and Note under Spongiae."
            },
            {
                "spelling_detail_id": 135410,
                "word_type": "n.",
                "spelling_usage": "The elastic fibrous skeleton of many species of horny\n   Spongiae (keratosa), used for many purposes, especially the varieties\n   of the genus Spongia. The most valuable sponges are found in the\n   Mediterranean and the Red Sea, and on the coasts of Florida and the\n   West Indies."
            },
            {
                "spelling_detail_id": 135411,
                "word_type": "n.",
                "spelling_usage": "One who lives upon others; a pertinaceous and indolent\n   dependent; a parasite; a sponger."
            },
            {
                "spelling_detail_id": 135412,
                "word_type": "n.",
                "spelling_usage": "Any spongelike substance."
            },
            {
                "spelling_detail_id": 135413,
                "word_type": "n.",
                "spelling_usage": "Dough before it is kneaded and formed into loaves, and\n   after it is converted into a light, spongy mass by the agency of the\n   yeast or leaven."
            },
            {
                "spelling_detail_id": 135414,
                "word_type": "n.",
                "spelling_usage": "Iron from the puddling furnace, in a pasty condition."
            },
            {
                "spelling_detail_id": 135415,
                "word_type": "n.",
                "spelling_usage": "Iron ore, in masses, reduced but not melted or worked."
            },
            {
                "spelling_detail_id": 135416,
                "word_type": "n.",
                "spelling_usage": "A mop for cleaning the bore of a cannon after a discharge.\n   It consists of a cylinder of wood, covered with sheepskin with the wool\n   on, or cloth with a heavy looped nap, and having a handle, or staff."
            },
            {
                "spelling_detail_id": 135417,
                "word_type": "n.",
                "spelling_usage": "The extremity, or point, of a horseshoe, answering to the\n   heel."
            },
            {
                "spelling_detail_id": 135420,
                "word_type": "v. t.",
                "spelling_usage": "To cleanse or wipe with a sponge; as, to sponge a slate\n   or a cannon; to wet with a sponge; as, to sponge cloth."
            },
            {
                "spelling_detail_id": 135421,
                "word_type": "v. t.",
                "spelling_usage": "To wipe out with a sponge, as letters or writing; to\n   efface; to destroy all trace of."
            },
            {
                "spelling_detail_id": 135422,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To deprive of something by imposition."
            },
            {
                "spelling_detail_id": 135423,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To get by imposition or mean arts without cost;\n   as, to sponge a breakfast."
            },
            {
                "spelling_detail_id": 135424,
                "word_type": "v. i.",
                "spelling_usage": "To suck in, or imbile, as a sponge."
            },
            {
                "spelling_detail_id": 135425,
                "word_type": "v. i.",
                "spelling_usage": "Fig.: To gain by mean arts, by intrusion, or hanging on;\n   as, an idler sponges on his neighbor."
            },
            {
                "spelling_detail_id": 135426,
                "word_type": "v. i.",
                "spelling_usage": "To be converted, as dough, into a light, spongy mass by\n   the agency of yeast, or leaven."
            }
        ]
    },
    {
        "id": 85957,
        "spelling_name": "Sporadic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135507,
                "word_type": "a.",
                "spelling_usage": "Occuring singly, or apart from other things of the same\n   kind, or in scattered instances; separate; single; as, a sporadic\n   fireball; a sporadic case of disease; a sporadic example of a flower."
            }
        ]
    },
    {
        "id": 86139,
        "spelling_name": "Spurious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135887,
                "word_type": "a.",
                "spelling_usage": "Not proceeding from the true source, or from the source\n   pretended; not genuine; false; adulterate."
            },
            {
                "spelling_detail_id": 135888,
                "word_type": "a.",
                "spelling_usage": "Not legitimate; bastard; as, spurious issue."
            }
        ]
    },
    {
        "id": 86143,
        "spelling_name": "Spurn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135894,
                "word_type": "v. t.",
                "spelling_usage": "To drive back or away, as with the foot; to kick."
            },
            {
                "spelling_detail_id": 135895,
                "word_type": "v. t.",
                "spelling_usage": "To reject with disdain; to scorn to receive or accept; to\n   treat with contempt."
            },
            {
                "spelling_detail_id": 135896,
                "word_type": "v. i.",
                "spelling_usage": "To kick or toss up the heels."
            },
            {
                "spelling_detail_id": 135897,
                "word_type": "v. i.",
                "spelling_usage": "To manifest disdain in rejecting anything; to make\n   contemptuous opposition or resistance."
            },
            {
                "spelling_detail_id": 135898,
                "word_type": "n.",
                "spelling_usage": "A kick; a blow with the foot."
            },
            {
                "spelling_detail_id": 135899,
                "word_type": "n.",
                "spelling_usage": "Disdainful rejection; contemptuous tratment."
            },
            {
                "spelling_detail_id": 135900,
                "word_type": "n.",
                "spelling_usage": "A body of coal left to sustain an overhanding mass."
            }
        ]
    },
    {
        "id": 86194,
        "spelling_name": "Squalid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 135981,
                "word_type": "a.",
                "spelling_usage": "Dirty through neglect; foul; filthy; extremely dirty."
            }
        ]
    },
    {
        "id": 86232,
        "spelling_name": "Squander",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 136030,
                "word_type": "v. t.",
                "spelling_usage": "To scatter; to disperse."
            },
            {
                "spelling_detail_id": 136031,
                "word_type": "v. t.",
                "spelling_usage": "To spend lavishly or profusely; to spend prodigally or\n   wastefully; to use without economy or judgment; to dissipate; as, to\n   squander an estate."
            },
            {
                "spelling_detail_id": 136032,
                "word_type": "v. i.",
                "spelling_usage": "To spend lavishly; to be wasteful."
            },
            {
                "spelling_detail_id": 136033,
                "word_type": "v. i.",
                "spelling_usage": "To wander at random; to scatter."
            },
            {
                "spelling_detail_id": 136034,
                "word_type": "n.",
                "spelling_usage": "The act of squandering; waste."
            }
        ]
    },
    {
        "id": 86257,
        "spelling_name": "Squat",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 136101,
                "word_type": "n.",
                "spelling_usage": "The angel fish (Squatina angelus)."
            },
            {
                "spelling_detail_id": 136104,
                "word_type": "v. t.",
                "spelling_usage": "To sit down upon the hams or heels; as, the savages\n   squatted near the fire."
            },
            {
                "spelling_detail_id": 136105,
                "word_type": "v. t.",
                "spelling_usage": "To sit close to the ground; to cower; to stoop, or lie\n   close, to escape observation, as a partridge or rabbit."
            },
            {
                "spelling_detail_id": 136106,
                "word_type": "v. t.",
                "spelling_usage": "To settle on another's land without title; also, to\n   settle on common or public lands."
            },
            {
                "spelling_detail_id": 136107,
                "word_type": "v. t.",
                "spelling_usage": "To bruise or make flat by a fall."
            },
            {
                "spelling_detail_id": 136108,
                "word_type": "a.",
                "spelling_usage": "Sitting on the hams or heels; sitting close to the ground;\n   cowering; crouching."
            },
            {
                "spelling_detail_id": 136109,
                "word_type": "a.",
                "spelling_usage": "Short and thick, like the figure of an animal squatting."
            },
            {
                "spelling_detail_id": 136110,
                "word_type": "n.",
                "spelling_usage": "The posture of one that sits on his heels or hams, or close\n   to the ground."
            },
            {
                "spelling_detail_id": 136111,
                "word_type": "n.",
                "spelling_usage": "A sudden or crushing fall."
            },
            {
                "spelling_detail_id": 136112,
                "word_type": "n.",
                "spelling_usage": "A small vein of ore."
            },
            {
                "spelling_detail_id": 136113,
                "word_type": "n.",
                "spelling_usage": "A mineral consisting of tin ore and spar."
            }
        ]
    },
    {
        "id": 86520,
        "spelling_name": "Stanch",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 136627,
                "word_type": "v. t.",
                "spelling_usage": "To stop the flowing of, as blood; to check; also, to\n   stop the flowing of blood from; as, to stanch a wound."
            },
            {
                "spelling_detail_id": 136628,
                "word_type": "v. t.",
                "spelling_usage": "To extinguish; to quench, as fire or thirst."
            },
            {
                "spelling_detail_id": 136629,
                "word_type": "v. i.",
                "spelling_usage": "To cease, as the flowing of blood."
            },
            {
                "spelling_detail_id": 136630,
                "word_type": "n.",
                "spelling_usage": "That which stanches or checks."
            },
            {
                "spelling_detail_id": 136631,
                "word_type": "n.",
                "spelling_usage": "A flood gate by which water is accumulated, for floating a\n   boat over a shallow part of a stream by its release."
            },
            {
                "spelling_detail_id": 136632,
                "word_type": "v. t.",
                "spelling_usage": "Strong and tight; sound; firm; as, a stanch ship."
            },
            {
                "spelling_detail_id": 136633,
                "word_type": "v. t.",
                "spelling_usage": "Firm in principle; constant and zealous; loyal; hearty;\n   steady; steadfast; as, a stanch churchman; a stanch friend or adherent."
            },
            {
                "spelling_detail_id": 136634,
                "word_type": "v. t.",
                "spelling_usage": "Close; secret; private."
            },
            {
                "spelling_detail_id": 136635,
                "word_type": "v. t.",
                "spelling_usage": "To prop; to make stanch, or strong."
            }
        ]
    },
    {
        "id": 86847,
        "spelling_name": "Steeply",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137284,
                "word_type": "adv.",
                "spelling_usage": "In a steep manner; with steepness; with precipitous\n   declivity."
            }
        ]
    },
    {
        "id": 86949,
        "spelling_name": "Stentorian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137445,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a stentor; extremely loud;\n   powerful; as, a stentorian voice; stentorian lungs."
            }
        ]
    },
    {
        "id": 87143,
        "spelling_name": "Stickler",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137745,
                "word_type": "v. t.",
                "spelling_usage": "One who stickles."
            },
            {
                "spelling_detail_id": 137746,
                "word_type": "v. t.",
                "spelling_usage": "One who arbitrates a duel; a sidesman to a fencer; a\n   second; an umpire."
            },
            {
                "spelling_detail_id": 137747,
                "word_type": "v. t.",
                "spelling_usage": "One who pertinaciously contends for some trifling\n   things, as a point of etiquette; an unreasonable, obstinate contender;\n   as, a stickler for ceremony."
            }
        ]
    },
    {
        "id": 87165,
        "spelling_name": "Stifled",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137780,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Stifle"
            },
            {
                "spelling_detail_id": 137786,
                "word_type": "a.",
                "spelling_usage": "Stifling."
            }
        ]
    },
    {
        "id": 87168,
        "spelling_name": "Stigma",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137791,
                "word_type": "v. t.",
                "spelling_usage": "A mark made with a burning iron; a brand."
            },
            {
                "spelling_detail_id": 137792,
                "word_type": "v. t.",
                "spelling_usage": "Any mark of infamy or disgrace; sign of moral blemish;\n   stain or reproach caused by dishonorable conduct; reproachful\n   characterization."
            },
            {
                "spelling_detail_id": 137793,
                "word_type": "v. t.",
                "spelling_usage": "That part of a pistil which has no epidermis, and is\n   fitted to receive the pollen. It is usually the terminal portion, and\n   is commonly somewhat glutinous or viscid. See Illust. of Stamen and of\n   Flower."
            },
            {
                "spelling_detail_id": 137794,
                "word_type": "v. t.",
                "spelling_usage": "A small spot, mark, scar, or a minute hole; -- applied\n   especially to a spot on the outer surface of a Graafian follicle, and\n   to spots of intercellular substance in scaly epithelium, or to minute\n   holes in such spots."
            },
            {
                "spelling_detail_id": 137795,
                "word_type": "v. t.",
                "spelling_usage": "A red speck upon the skin, produced either by the\n   extravasation of blood, as in the bloody sweat characteristic of\n   certain varieties of religious ecstasy, or by capillary congestion, as\n   in the case of drunkards."
            },
            {
                "spelling_detail_id": 137796,
                "word_type": "v. t.",
                "spelling_usage": "One of the external openings of the tracheae of insects,\n   myriapods, and other arthropods; a spiracle."
            },
            {
                "spelling_detail_id": 137797,
                "word_type": "v. t.",
                "spelling_usage": "One of the apertures of the pulmonary sacs of arachnids.\n   See Illust. of Scorpion."
            },
            {
                "spelling_detail_id": 137798,
                "word_type": "v. t.",
                "spelling_usage": "One of the apertures of the gill of an ascidian, and of\n   Amphioxus."
            },
            {
                "spelling_detail_id": 137799,
                "word_type": "v. t.",
                "spelling_usage": "A point so connected by any law whatever with another\n   point, called an index, that as the index moves in any manner in a\n   plane the first point or stigma moves in a determinate way in the same\n   plane."
            },
            {
                "spelling_detail_id": 137800,
                "word_type": "v. t.",
                "spelling_usage": "Marks believed to have been supernaturally impressed\n   upon the bodies of certain persons in imitation of the wounds on the\n   crucified body of Christ. See def. 5, above."
            }
        ]
    },
    {
        "id": 87177,
        "spelling_name": "Stigmatize",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137816,
                "word_type": "v. t.",
                "spelling_usage": "To mark with a stigma, or brand; as, the ancients\n   stigmatized their slaves and soldiers."
            },
            {
                "spelling_detail_id": 137817,
                "word_type": "v. t.",
                "spelling_usage": "To set a mark of disgrace on; to brand with some\n   mark of reproach or infamy."
            }
        ]
    },
    {
        "id": 87232,
        "spelling_name": "Sting",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137917,
                "word_type": "v. t.",
                "spelling_usage": "Any sharp organ of offense and defense, especially when\n   connected with a poison gland, and adapted to inflict a wound by\n   piercing; as the caudal sting of a scorpion. The sting of a bee or wasp\n   is a modified ovipositor. The caudal sting, or spine, of a sting ray is\n   a modified dorsal fin ray. The term is sometimes applied to the fang of\n   a serpent. See Illust. of Scorpion."
            },
            {
                "spelling_detail_id": 137918,
                "word_type": "v. t.",
                "spelling_usage": "A sharp-pointed hollow hair seated on a gland which\n   secrets an acrid fluid, as in nettles. The points of these hairs\n   usually break off in the wound, and the acrid fluid is pressed into it."
            },
            {
                "spelling_detail_id": 137919,
                "word_type": "v. t.",
                "spelling_usage": "Anything that gives acute pain, bodily or mental; as, the\n   stings of remorse; the stings of reproach."
            },
            {
                "spelling_detail_id": 137920,
                "word_type": "v. t.",
                "spelling_usage": "The thrust of a sting into the flesh; the act of\n   stinging; a wound inflicted by stinging."
            },
            {
                "spelling_detail_id": 137921,
                "word_type": "v. t.",
                "spelling_usage": "A goad; incitement."
            },
            {
                "spelling_detail_id": 137922,
                "word_type": "v. t.",
                "spelling_usage": "The point of an epigram or other sarcastic saying."
            },
            {
                "spelling_detail_id": 137926,
                "word_type": "v. t.",
                "spelling_usage": "To pierce or wound with a sting; as, bees will sting an\n   animal that irritates them; the nettles stung his hands."
            },
            {
                "spelling_detail_id": 137927,
                "word_type": "v. t.",
                "spelling_usage": "To pain acutely; as, the conscience is stung with\n   remorse; to bite."
            },
            {
                "spelling_detail_id": 137928,
                "word_type": "v. t.",
                "spelling_usage": "To goad; to incite, as by taunts or reproaches."
            }
        ]
    },
    {
        "id": 87243,
        "spelling_name": "Stingy",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137939,
                "word_type": "a.",
                "spelling_usage": "Stinging; able to sting."
            },
            {
                "spelling_detail_id": 137940,
                "word_type": "superl.",
                "spelling_usage": "Extremely close and covetous; meanly avaricious;\n   niggardly; miserly; penurious; as, a stingy churl."
            }
        ]
    },
    {
        "id": 87255,
        "spelling_name": "Stint",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137961,
                "word_type": "n.",
                "spelling_usage": "Any one of several species of small sandpipers, as the\n   sanderling of Europe and America, the dunlin, the little stint of India\n   (Tringa minuta), etc. Called also pume."
            },
            {
                "spelling_detail_id": 137962,
                "word_type": "n.",
                "spelling_usage": "A phalarope."
            },
            {
                "spelling_detail_id": 137965,
                "word_type": "v. t.",
                "spelling_usage": "To restrain within certain limits; to bound; to confine;\n   to restrain; to restrict to a scant allowance."
            },
            {
                "spelling_detail_id": 137966,
                "word_type": "v. t.",
                "spelling_usage": "To put an end to; to stop."
            },
            {
                "spelling_detail_id": 137967,
                "word_type": "v. t.",
                "spelling_usage": "To assign a certain (i. e., limited) task to (a person),\n   upon the performance of which one is excused from further labor for the\n   day or for a certain time; to stent."
            },
            {
                "spelling_detail_id": 137968,
                "word_type": "v. t.",
                "spelling_usage": "To serve successfully; to get with foal; -- said of\n   mares."
            },
            {
                "spelling_detail_id": 137969,
                "word_type": "v. i.",
                "spelling_usage": "To stop; to cease."
            },
            {
                "spelling_detail_id": 137970,
                "word_type": "v. t.",
                "spelling_usage": "Limit; bound; restraint; extent."
            },
            {
                "spelling_detail_id": 137971,
                "word_type": "v. t.",
                "spelling_usage": "Quantity or task assigned; proportion allotted."
            }
        ]
    },
    {
        "id": 87275,
        "spelling_name": "Stipple",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 137997,
                "word_type": "v. t.",
                "spelling_usage": "To engrave by means of dots, in distinction from\n   engraving in lines."
            },
            {
                "spelling_detail_id": 137998,
                "word_type": "v. t.",
                "spelling_usage": "To paint, as in water colors, by small, short touches\n   which together produce an even or softly graded surface."
            },
            {
                "spelling_detail_id": 137999,
                "word_type": "n.",
                "spelling_usage": "Alt. of Stippling"
            }
        ]
    },
    {
        "id": 87285,
        "spelling_name": "Stipulate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 138010,
                "word_type": "a.",
                "spelling_usage": "Furnished with stipules; as, a stipulate leaf."
            },
            {
                "spelling_detail_id": 138013,
                "word_type": "v. i.",
                "spelling_usage": "To make an agreement or covenant with any person or\n   company to do or forbear anything; to bargain; to contract; to settle\n   terms; as, certain princes stipulated to assist each other in resisting\n   the armies of France."
            }
        ]
    },
    {
        "id": 87372,
        "spelling_name": "Stolid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 138185,
                "word_type": "a.",
                "spelling_usage": "Hopelessly insensible or stupid; not easily aroused or\n   excited; dull; impassive; foolish."
            }
        ]
    },
    {
        "id": 87675,
        "spelling_name": "Stray",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 138786,
                "word_type": "a.",
                "spelling_usage": "To wander, as from a direct course; to deviate, or go out of\n   the way."
            },
            {
                "spelling_detail_id": 138787,
                "word_type": "a.",
                "spelling_usage": "To wander from company, or from the proper limits; to rove\n   at large; to roam; to go astray."
            },
            {
                "spelling_detail_id": 138788,
                "word_type": "a.",
                "spelling_usage": "Figuratively, to wander from the path of duty or rectitude;\n   to err."
            },
            {
                "spelling_detail_id": 138789,
                "word_type": "v. t.",
                "spelling_usage": "To cause to stray."
            },
            {
                "spelling_detail_id": 138790,
                "word_type": "v. i.",
                "spelling_usage": "Having gone astray; strayed; wandering; as, a strayhorse\n   or sheep."
            },
            {
                "spelling_detail_id": 138791,
                "word_type": "n.",
                "spelling_usage": "Any domestic animal that has an inclosure, or its proper\n   place and company, and wanders at large, or is lost; an estray. Used\n   also figuratively."
            },
            {
                "spelling_detail_id": 138792,
                "word_type": "n.",
                "spelling_usage": "The act of wandering or going astray."
            }
        ]
    },
    {
        "id": 87680,
        "spelling_name": "Streak",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 138795,
                "word_type": "v. t.",
                "spelling_usage": "To stretch; to extend; hence, to lay out, as a dead\n   body."
            },
            {
                "spelling_detail_id": 138796,
                "word_type": "n.",
                "spelling_usage": "A line or long mark of a different color from the ground; a\n   stripe; a vein."
            },
            {
                "spelling_detail_id": 138797,
                "word_type": "n.",
                "spelling_usage": "A strake."
            },
            {
                "spelling_detail_id": 138798,
                "word_type": "n.",
                "spelling_usage": "The fine powder or mark yielded by a mineral when scratched\n   or rubbed against a harder surface, the color of which is sometimes a\n   distinguishing character."
            },
            {
                "spelling_detail_id": 138799,
                "word_type": "n.",
                "spelling_usage": "The rung or round of a ladder."
            },
            {
                "spelling_detail_id": 138802,
                "word_type": "v. t.",
                "spelling_usage": "To form streaks or stripes in or on; to stripe; to\n   variegate with lines of a different color, or of different colors."
            },
            {
                "spelling_detail_id": 138803,
                "word_type": "v. t.",
                "spelling_usage": "With it as an object: To run swiftly."
            }
        ]
    },
    {
        "id": 87751,
        "spelling_name": "Striated",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 138944,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Striate"
            },
            {
                "spelling_detail_id": 138948,
                "word_type": "a.",
                "spelling_usage": "Marked with striaae, or fine grooves, or lines of color;\n   showing narrow structural bands or lines; as, a striated crystal;\n   striated muscular fiber."
            }
        ]
    },
    {
        "id": 87770,
        "spelling_name": "Stride",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 138985,
                "word_type": "v. t.",
                "spelling_usage": "To walk with long steps, especially in a measured or\n   pompous manner."
            },
            {
                "spelling_detail_id": 138986,
                "word_type": "v. t.",
                "spelling_usage": "To stand with the legs wide apart; to straddle."
            },
            {
                "spelling_detail_id": 138987,
                "word_type": "v. t.",
                "spelling_usage": "To pass over at a step; to step over."
            },
            {
                "spelling_detail_id": 138988,
                "word_type": "v. t.",
                "spelling_usage": "To straddle; to bestride."
            },
            {
                "spelling_detail_id": 138989,
                "word_type": "n.",
                "spelling_usage": "The act of stridding; a long step; the space measured by a\n   long step; as, a masculine stride."
            }
        ]
    },
    {
        "id": 87818,
        "spelling_name": "Strive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 139153,
                "word_type": "v. i.",
                "spelling_usage": "To make efforts; to use exertions; to endeavor with\n   earnestness; to labor hard."
            },
            {
                "spelling_detail_id": 139154,
                "word_type": "v. i.",
                "spelling_usage": "To struggle in opposition; to be in contention or\n   dispute; to contend; to contest; -- followed by against or with before\n   the person or thing opposed; as, strive against temptation; strive for\n   the truth."
            },
            {
                "spelling_detail_id": 139155,
                "word_type": "v. i.",
                "spelling_usage": "To vie; to compete; to be a rival."
            },
            {
                "spelling_detail_id": 139156,
                "word_type": "n.",
                "spelling_usage": "An effort; a striving."
            },
            {
                "spelling_detail_id": 139157,
                "word_type": "n.",
                "spelling_usage": "Strife; contention."
            }
        ]
    },
    {
        "id": 87921,
        "spelling_name": "Strut",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 139339,
                "word_type": "v. t.",
                "spelling_usage": "To swell; to bulge out."
            },
            {
                "spelling_detail_id": 139340,
                "word_type": "v. t.",
                "spelling_usage": "To walk with a lofty, proud gait, and erect head; to walk\n   with affected dignity."
            },
            {
                "spelling_detail_id": 139341,
                "word_type": "n.",
                "spelling_usage": "The act of strutting; a pompous step or walk."
            },
            {
                "spelling_detail_id": 139342,
                "word_type": "n.",
                "spelling_usage": "In general, any piece of a frame which resists thrust or\n   pressure in the direction of its own length. See Brace, and Illust. of\n   Frame, and Roof."
            },
            {
                "spelling_detail_id": 139343,
                "word_type": "n.",
                "spelling_usage": "Any part of a machine or structure, of which the principal\n   function is to hold things apart; a brace subjected to compressive\n   stress; -- the opposite of stay, and tie."
            },
            {
                "spelling_detail_id": 139344,
                "word_type": "v. t.",
                "spelling_usage": "To hold apart. Cf. Strut, n., 3."
            },
            {
                "spelling_detail_id": 139345,
                "word_type": "a.",
                "spelling_usage": "Protuberant."
            }
        ]
    },
    {
        "id": 88070,
        "spelling_name": "Stygian",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 139627,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the river Styx; hence, hellish;\n   infernal. See Styx."
            }
        ]
    },
    {
        "id": 88262,
        "spelling_name": "Subdue",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 139869,
                "word_type": "v. t.",
                "spelling_usage": "To bring under; to conquer by force or the exertion of\n   superior power, and bring into permanent subjection; to reduce under\n   dominion; to vanquish."
            },
            {
                "spelling_detail_id": 139870,
                "word_type": "v. t.",
                "spelling_usage": "To overpower so as to disable from further resistance;\n   to crush."
            },
            {
                "spelling_detail_id": 139871,
                "word_type": "v. t.",
                "spelling_usage": "To destroy the force of; to overcome; as, medicines\n   subdue a fever."
            },
            {
                "spelling_detail_id": 139872,
                "word_type": "v. t.",
                "spelling_usage": "To render submissive; to bring under command; to reduce\n   to mildness or obedience; to tame; as, to subdue a stubborn child; to\n   subdue the temper or passions."
            },
            {
                "spelling_detail_id": 139873,
                "word_type": "v. t.",
                "spelling_usage": "To overcome, as by persuasion or other mild means; as,\n   to subdue opposition by argument or entreaties."
            },
            {
                "spelling_detail_id": 139874,
                "word_type": "v. t.",
                "spelling_usage": "To reduce to tenderness; to melt; to soften; as, to\n   subdue ferocity by tears."
            },
            {
                "spelling_detail_id": 139875,
                "word_type": "v. t.",
                "spelling_usage": "To make mellow; to break, as land; also, to destroy, as\n   weeds."
            },
            {
                "spelling_detail_id": 139876,
                "word_type": "v. t.",
                "spelling_usage": "To reduce the intensity or degree of; to tone down; to\n   soften; as, to subdue the brilliancy of colors."
            }
        ]
    },
    {
        "id": 88373,
        "spelling_name": "Sublime",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140026,
                "word_type": "superl.",
                "spelling_usage": "Lifted up; high in place; exalted aloft; uplifted;\n   lofty."
            },
            {
                "spelling_detail_id": 140027,
                "word_type": "superl.",
                "spelling_usage": "Distinguished by lofty or noble traits; eminent; --\n   said of persons."
            },
            {
                "spelling_detail_id": 140028,
                "word_type": "superl.",
                "spelling_usage": "Awakening or expressing the emotion of awe,\n   adoration, veneration, heroic resolve, etc.; dignified; grand; solemn;\n   stately; -- said of an impressive object in nature, of an action, of a\n   discourse, of a work of art, of a spectacle, etc.; as, sublime scenery;\n   a sublime deed."
            },
            {
                "spelling_detail_id": 140029,
                "word_type": "superl.",
                "spelling_usage": "Elevated by joy; elate."
            },
            {
                "spelling_detail_id": 140030,
                "word_type": "superl.",
                "spelling_usage": "Lofty of mien; haughty; proud."
            },
            {
                "spelling_detail_id": 140031,
                "word_type": "n.",
                "spelling_usage": "That which is sublime; -- with the definite article"
            },
            {
                "spelling_detail_id": 140032,
                "word_type": "n.",
                "spelling_usage": "A grand or lofty style in speaking or writing; a style\n   that expresses lofty conceptions."
            },
            {
                "spelling_detail_id": 140033,
                "word_type": "n.",
                "spelling_usage": "That which is grand in nature or art, as distinguished\n   from the merely beautiful."
            },
            {
                "spelling_detail_id": 140036,
                "word_type": "v. t.",
                "spelling_usage": "To raise on high."
            },
            {
                "spelling_detail_id": 140037,
                "word_type": "v. t.",
                "spelling_usage": "To subject to the process of sublimation; to heat,\n   volatilize, and condense in crystals or powder; to distill off, and\n   condense in solid form; hence, also, to purify."
            },
            {
                "spelling_detail_id": 140038,
                "word_type": "v. t.",
                "spelling_usage": "To exalt; to heighten; to improve; to purify."
            },
            {
                "spelling_detail_id": 140039,
                "word_type": "v. t.",
                "spelling_usage": "To dignify; to ennoble."
            },
            {
                "spelling_detail_id": 140040,
                "word_type": "v. i.",
                "spelling_usage": "To pass off in vapor, with immediate condensation;\n   specifically, to evaporate or volatilize from the solid state without\n   apparent melting; -- said of those substances, like arsenic, benzoic\n   acid, etc., which do not exhibit a liquid form on heating, except under\n   increased pressure."
            }
        ]
    },
    {
        "id": 88402,
        "spelling_name": "Submerge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140075,
                "word_type": "v. t.",
                "spelling_usage": "To put under water; to plunge."
            },
            {
                "spelling_detail_id": 140076,
                "word_type": "v. t.",
                "spelling_usage": "To cover or overflow with water; to inundate; to\n   flood; to drown."
            },
            {
                "spelling_detail_id": 140077,
                "word_type": "v. i.",
                "spelling_usage": "To plunge into water or other fluid; to be buried or\n   covered, as by a fluid; to be merged; hence, to be completely included."
            }
        ]
    },
    {
        "id": 88460,
        "spelling_name": "Suborn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140156,
                "word_type": "v. t.",
                "spelling_usage": "To procure or cause to take a false oath amounting to\n   perjury, such oath being actually taken."
            },
            {
                "spelling_detail_id": 140157,
                "word_type": "v. t.",
                "spelling_usage": "To procure privately, or by collusion; to procure by\n   indirect means; to incite secretly; to instigate."
            }
        ]
    },
    {
        "id": 88480,
        "spelling_name": "Subpoena",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140176,
                "word_type": "n.",
                "spelling_usage": "A writ commanding the attendance in court, as a witness,\n   of the person on whom it is served, under a penalty; the process by\n   which a defendant in equity is commanded to appear and answer the\n   plaintiff's bill."
            },
            {
                "spelling_detail_id": 140179,
                "word_type": "v. t.",
                "spelling_usage": "To serve with a writ of subpoena; to command\n   attendance in court by a legal writ, under a penalty in case of\n   disobedience."
            }
        ]
    },
    {
        "id": 88587,
        "spelling_name": "Substantiation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140334,
                "word_type": "n.",
                "spelling_usage": "The act of substantiating or proving; evidence;\n   proof."
            }
        ]
    },
    {
        "id": 88619,
        "spelling_name": "Subsume",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140387,
                "word_type": "v. t.",
                "spelling_usage": "To take up into or under, as individual under species,\n   species under genus, or particular under universal; to place (any one\n   cognition) under another as belonging to it; to include under something\n   else."
            }
        ]
    },
    {
        "id": 88767,
        "spelling_name": "Succor",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140592,
                "word_type": "v. t.",
                "spelling_usage": "To run to, or run to support; hence, to help or relieve\n   when in difficulty, want, or distress; to assist and deliver from\n   suffering; to relieve; as, to succor a besieged city."
            },
            {
                "spelling_detail_id": 140593,
                "word_type": "v. t.",
                "spelling_usage": "Aid; help; assistance; esp., assistance that relieves\n   and delivers from difficulty, want, or distress."
            },
            {
                "spelling_detail_id": 140594,
                "word_type": "v. t.",
                "spelling_usage": "The person or thing that brings relief."
            }
        ]
    },
    {
        "id": 88788,
        "spelling_name": "Succumb",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140616,
                "word_type": "v. t.",
                "spelling_usage": "To yield; to submit; to give up unresistingly; as, to\n   succumb under calamities; to succumb to disease."
            }
        ]
    },
    {
        "id": 88854,
        "spelling_name": "Suffice",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140752,
                "word_type": "v. i.",
                "spelling_usage": "To be enough, or sufficient; to meet the need (of\n   anything); to be equal to the end proposed; to be adequate."
            },
            {
                "spelling_detail_id": 140753,
                "word_type": "v. t.",
                "spelling_usage": "To satisfy; to content; to be equal to the wants or\n   demands of."
            },
            {
                "spelling_detail_id": 140754,
                "word_type": "v. t.",
                "spelling_usage": "To furnish; to supply adequately."
            }
        ]
    },
    {
        "id": 88871,
        "spelling_name": "Suffocate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140780,
                "word_type": "a.",
                "spelling_usage": "Suffocated; choked."
            },
            {
                "spelling_detail_id": 140783,
                "word_type": "v. t.",
                "spelling_usage": "To choke or kill by stopping respiration; to stifle;\n   to smother."
            },
            {
                "spelling_detail_id": 140784,
                "word_type": "v. t.",
                "spelling_usage": "To destroy; to extinguish; as, to suffocate fire."
            },
            {
                "spelling_detail_id": 140785,
                "word_type": "v. i.",
                "spelling_usage": "To become choked, stifled, or smothered."
            }
        ]
    },
    {
        "id": 88968,
        "spelling_name": "Sullied",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 140945,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Sully"
            }
        ]
    },
    {
        "id": 89102,
        "spelling_name": "Sumptuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141118,
                "word_type": "a.",
                "spelling_usage": "Involving large outlay or expense; costly; expensive;\n   hence, luxurious; splendid; magnificient; as, a sumptuous house or\n   table; sumptuous apparel."
            }
        ]
    },
    {
        "id": 89129,
        "spelling_name": "Sundry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141158,
                "word_type": "v. t.",
                "spelling_usage": "Several; divers; more than one or two; various."
            },
            {
                "spelling_detail_id": 141159,
                "word_type": "v. t.",
                "spelling_usage": "Separate; diverse."
            }
        ]
    },
    {
        "id": 89203,
        "spelling_name": "Supercilious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141262,
                "word_type": "a.",
                "spelling_usage": "Lofty with pride; haughty; dictatorial; overbearing;\n   arrogant; as, a supercilious officer; asupercilious air; supercilious\n   behavior."
            }
        ]
    },
    {
        "id": 89253,
        "spelling_name": "Superfluous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141321,
                "word_type": "a.",
                "spelling_usage": "More than is wanted or is sufficient; rendered\n   unnecessary by superabundance; unnecessary; useless; excessive; as, a\n   superfluous price."
            }
        ]
    },
    {
        "id": 89265,
        "spelling_name": "Superimpose",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141337,
                "word_type": "v. t.",
                "spelling_usage": "To lay or impose on something else; as, a stratum\n   of earth superimposed on another stratum."
            }
        ]
    },
    {
        "id": 89359,
        "spelling_name": "Supersede",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141455,
                "word_type": "v. t.",
                "spelling_usage": "To come, or be placed, in the room of; to replace."
            },
            {
                "spelling_detail_id": 141456,
                "word_type": "v. t.",
                "spelling_usage": "To displace, or set aside, and put another in place\n   of; as, to supersede an officer."
            },
            {
                "spelling_detail_id": 141457,
                "word_type": "v. t.",
                "spelling_usage": "To make void, inefficacious, or useless, by superior\n   power, or by coming in the place of; to set aside; to render\n   unnecessary; to suspend; to stay."
            },
            {
                "spelling_detail_id": 141458,
                "word_type": "v. t.",
                "spelling_usage": "To omit; to forbear."
            }
        ]
    },
    {
        "id": 89416,
        "spelling_name": "Supine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141527,
                "word_type": "a.",
                "spelling_usage": "Lying on the back, or with the face upward; -- opposed to\n   prone."
            },
            {
                "spelling_detail_id": 141528,
                "word_type": "a.",
                "spelling_usage": "Leaning backward, or inclining with exposure to the sun;\n   sloping; inclined."
            },
            {
                "spelling_detail_id": 141529,
                "word_type": "a.",
                "spelling_usage": "Negligent; heedless; indolent; listless."
            },
            {
                "spelling_detail_id": 141530,
                "word_type": "n.",
                "spelling_usage": "A verbal noun; or (according to C.F.Becker), a case of the\n   infinitive mood ending in -um and -u, that in -um being sometimes\n   called the former supine, and that in -u the latter supine."
            }
        ]
    },
    {
        "id": 89453,
        "spelling_name": "Suppliant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141583,
                "word_type": "a.",
                "spelling_usage": "Asking earnestly and submissively; entreating;\n   beseeching; supplicating."
            },
            {
                "spelling_detail_id": 141584,
                "word_type": "a.",
                "spelling_usage": "Manifesting entreaty; expressive of supplication."
            },
            {
                "spelling_detail_id": 141585,
                "word_type": "n.",
                "spelling_usage": "One who supplicates; a humble petitioner; one who\n   entreats submissively."
            }
        ]
    },
    {
        "id": 89457,
        "spelling_name": "Supplicate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141592,
                "word_type": "v. t.",
                "spelling_usage": "To entreat for; to seek by earnest prayer; to ask\n   for earnestly and humbly; as, to supplicate blessings on Christian\n   efforts to spread the gospel."
            },
            {
                "spelling_detail_id": 141593,
                "word_type": "v. t.",
                "spelling_usage": "To address in prayer; to entreat as a supplicant;\n   as, to supplicate the Deity."
            },
            {
                "spelling_detail_id": 141594,
                "word_type": "v. i.",
                "spelling_usage": "To make petition with earnestness and submission; to\n   implore."
            }
        ]
    },
    {
        "id": 89497,
        "spelling_name": "Suppress",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141669,
                "word_type": "v. t.",
                "spelling_usage": "To overpower and crush; to subdue; to put down; to\n   quell."
            },
            {
                "spelling_detail_id": 141670,
                "word_type": "v. t.",
                "spelling_usage": "To keep in; to restrain from utterance or vent; as, to\n   suppress the voice; to suppress a smile."
            },
            {
                "spelling_detail_id": 141671,
                "word_type": "v. t.",
                "spelling_usage": "To retain without disclosure; to conceal; not to\n   reveal; to prevent publication of; as, to suppress evidence; to\n   suppress a pamphlet; to suppress the truth."
            },
            {
                "spelling_detail_id": 141672,
                "word_type": "v. t.",
                "spelling_usage": "To stop; to restrain; to arrest the discharges of; as,\n   to suppress a diarrhea, or a hemorrhage."
            }
        ]
    },
    {
        "id": 89600,
        "spelling_name": "Surcharge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141801,
                "word_type": "v. t.",
                "spelling_usage": "To overload; to overburden; to overmatch; to\n   overcharge; as, to surcharge a beast or a ship; to surcharge a cannon."
            },
            {
                "spelling_detail_id": 141802,
                "word_type": "v. t.",
                "spelling_usage": "To overstock; especially, to put more cattle into, as\n   a common, than the person has a right to do, or more than the herbage\n   will sustain. Blackstone."
            },
            {
                "spelling_detail_id": 141803,
                "word_type": "v. t.",
                "spelling_usage": "To show an omission in (an account) for which credit\n   ought to have been given."
            },
            {
                "spelling_detail_id": 141804,
                "word_type": "n.",
                "spelling_usage": "An overcharge; an excessive load or burden; a load\n   greater than can well be borne."
            },
            {
                "spelling_detail_id": 141805,
                "word_type": "n.",
                "spelling_usage": "The putting, by a commoner, of more beasts on the common\n   than he has a right to."
            },
            {
                "spelling_detail_id": 141806,
                "word_type": "n.",
                "spelling_usage": "The showing an omission, as in an account, for which\n   credit ought to have been given."
            }
        ]
    },
    {
        "id": 89636,
        "spelling_name": "Surfeit",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 141865,
                "word_type": "n.",
                "spelling_usage": "Excess in eating and drinking."
            },
            {
                "spelling_detail_id": 141866,
                "word_type": "n.",
                "spelling_usage": "Fullness and oppression of the system, occasioned often by\n   excessive eating and drinking."
            },
            {
                "spelling_detail_id": 141867,
                "word_type": "n.",
                "spelling_usage": "Disgust caused by excess; satiety."
            },
            {
                "spelling_detail_id": 141868,
                "word_type": "v. i.",
                "spelling_usage": "To load the stomach with food, so that sickness or\n   uneasiness ensues; to eat to excess."
            },
            {
                "spelling_detail_id": 141869,
                "word_type": "v. i.",
                "spelling_usage": "To indulge to satiety in any gratification."
            },
            {
                "spelling_detail_id": 141872,
                "word_type": "v. t.",
                "spelling_usage": "To feed so as to oppress the stomach and derange the\n   function of the system; to overfeed, and produce satiety, sickness, or\n   uneasiness; -- often reflexive; as, to surfeit one's self with sweets."
            },
            {
                "spelling_detail_id": 141873,
                "word_type": "v. t.",
                "spelling_usage": "To fill to satiety and disgust; to cloy; as, he\n   surfeits us with compliments."
            }
        ]
    },
    {
        "id": 89767,
        "spelling_name": "Susceptibility",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 142072,
                "word_type": "n.",
                "spelling_usage": "The state or quality of being susceptible; the\n   capability of receiving impressions, or of being affected."
            },
            {
                "spelling_detail_id": 142073,
                "word_type": "n.",
                "spelling_usage": "Specifically, capacity for deep feeling or\n   emotional excitement; sensibility, in its broadest acceptation;\n   impressibility; sensitiveness."
            }
        ]
    },
    {
        "id": 90035,
        "spelling_name": "Swerve",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 142659,
                "word_type": "v. i.",
                "spelling_usage": "To stray; to wander; to rope."
            },
            {
                "spelling_detail_id": 142660,
                "word_type": "v. i.",
                "spelling_usage": "To go out of a straight line; to deflect."
            },
            {
                "spelling_detail_id": 142661,
                "word_type": "v. i.",
                "spelling_usage": "To wander from any line prescribed, or from a rule or\n   duty; to depart from what is established by law, duty, custom, or the\n   like; to deviate."
            },
            {
                "spelling_detail_id": 142662,
                "word_type": "v. i.",
                "spelling_usage": "To bend; to incline."
            },
            {
                "spelling_detail_id": 142663,
                "word_type": "v. i.",
                "spelling_usage": "To climb or move upward by winding or turning."
            },
            {
                "spelling_detail_id": 142664,
                "word_type": "v. t.",
                "spelling_usage": "To turn aside."
            }
        ]
    },
    {
        "id": 90181,
        "spelling_name": "Sycophant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 142933,
                "word_type": "n.",
                "spelling_usage": "An informer; a talebearer."
            },
            {
                "spelling_detail_id": 142934,
                "word_type": "n.",
                "spelling_usage": "A base parasite; a mean or servile flatterer;\n   especially, a flatterer of princes and great men."
            },
            {
                "spelling_detail_id": 142935,
                "word_type": "v. t.",
                "spelling_usage": "To inform against; hence, to calumniate."
            },
            {
                "spelling_detail_id": 142936,
                "word_type": "v. t.",
                "spelling_usage": "To play the sycophant toward; to flatter\n   obsequiously."
            },
            {
                "spelling_detail_id": 142937,
                "word_type": "v. i.",
                "spelling_usage": "To play the sycophant."
            }
        ]
    },
    {
        "id": 90669,
        "spelling_name": "Taciturn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 143638,
                "word_type": "a.",
                "spelling_usage": "Habitually silent; not given to converse; not apt to talk\n   or speak."
            }
        ]
    },
    {
        "id": 90695,
        "spelling_name": "Tadpole",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 143692,
                "word_type": "n.",
                "spelling_usage": "The young aquatic larva of any amphibian. In this stage it\n   breathes by means of external or internal gills, is at first destitute\n   of legs, and has a finlike tail. Called also polliwig, polliwog,\n   porwiggle, or purwiggy."
            },
            {
                "spelling_detail_id": 143693,
                "word_type": "n.",
                "spelling_usage": "The hooded merganser."
            }
        ]
    },
    {
        "id": 90843,
        "spelling_name": "Talon",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 143977,
                "word_type": "n.",
                "spelling_usage": "The claw of a predaceous bird or animal, especially the claw\n   of a bird of prey."
            },
            {
                "spelling_detail_id": 143978,
                "word_type": "n.",
                "spelling_usage": "One of certain small prominences on the hind part of the\n   face of an elephant's tooth."
            },
            {
                "spelling_detail_id": 143979,
                "word_type": "n.",
                "spelling_usage": "A kind of molding, concave at the bottom and convex at the\n   top; -- usually called an ogee."
            },
            {
                "spelling_detail_id": 143980,
                "word_type": "n.",
                "spelling_usage": "The shoulder of the bolt of a lock on which the key acts to\n   shoot the bolt."
            }
        ]
    },
    {
        "id": 90886,
        "spelling_name": "Tamp",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144044,
                "word_type": "v. t.",
                "spelling_usage": "In blasting, to plug up with clay, earth, dry sand, sod,\n   or other material, as a hole bored in a rock, in order to prevent the\n   force of the explosion from being misdirected."
            },
            {
                "spelling_detail_id": 144045,
                "word_type": "v. t.",
                "spelling_usage": "To drive in or down by frequent gentle strokes; as, to\n   tamp earth so as to make a smooth place."
            }
        ]
    },
    {
        "id": 90890,
        "spelling_name": "Tamper",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144048,
                "word_type": "n.",
                "spelling_usage": "One who tamps; specifically, one who prepares for blasting,\n   by filling the hole in which the charge is placed."
            },
            {
                "spelling_detail_id": 144049,
                "word_type": "n.",
                "spelling_usage": "An instrument used in tamping; a tamping iron."
            },
            {
                "spelling_detail_id": 144052,
                "word_type": "v. i.",
                "spelling_usage": "To meddle; to be busy; to try little experiments; as, to\n   tamper with a disease."
            },
            {
                "spelling_detail_id": 144053,
                "word_type": "v. i.",
                "spelling_usage": "To meddle so as to alter, injure, or vitiate a thing."
            },
            {
                "spelling_detail_id": 144054,
                "word_type": "v. i.",
                "spelling_usage": "To deal unfairly; to practice secretly; to use bribery."
            }
        ]
    },
    {
        "id": 90915,
        "spelling_name": "Tangential",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144106,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to a tangent; in the direction of a\n   tangent."
            }
        ]
    },
    {
        "id": 91060,
        "spelling_name": "Tarnished",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144312,
                "word_type": "imp. & p. p.",
                "spelling_usage": "of Tarnish"
            }
        ]
    },
    {
        "id": 91151,
        "spelling_name": "Tassel",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144444,
                "word_type": "n.",
                "spelling_usage": "A male hawk. See Tercel."
            },
            {
                "spelling_detail_id": 144445,
                "word_type": "n.",
                "spelling_usage": "A kind of bur used in dressing cloth; a teasel."
            },
            {
                "spelling_detail_id": 144446,
                "word_type": "n.",
                "spelling_usage": "A pendent ornament, attached to the corners of cushions, to\n   curtains, and the like, ending in a tuft of loose threads or cords."
            },
            {
                "spelling_detail_id": 144447,
                "word_type": "n.",
                "spelling_usage": "The flower or head of some plants, esp. when pendent."
            },
            {
                "spelling_detail_id": 144448,
                "word_type": "n.",
                "spelling_usage": "A narrow silk ribbon, or the like, sewed to a book to be\n   put between the leaves."
            },
            {
                "spelling_detail_id": 144449,
                "word_type": "n.",
                "spelling_usage": "A piece of board that is laid upon a wall as a sort of\n   plate, to give a level surface to the ends of floor timbers; -- rarely\n   used in the United States."
            },
            {
                "spelling_detail_id": 144454,
                "word_type": "v. i.",
                "spelling_usage": "To put forth a tassel or flower; as, maize tassels."
            },
            {
                "spelling_detail_id": 144455,
                "word_type": "v. t.",
                "spelling_usage": "To adorn with tassels."
            }
        ]
    },
    {
        "id": 91193,
        "spelling_name": "Taunt",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144530,
                "word_type": "a.",
                "spelling_usage": "Very high or tall; as, a ship with taunt masts."
            },
            {
                "spelling_detail_id": 144533,
                "word_type": "v. t.",
                "spelling_usage": "To reproach with severe or insulting words; to revile; to\n   upbraid; to jeer at; to flout."
            },
            {
                "spelling_detail_id": 144534,
                "word_type": "n.",
                "spelling_usage": "Upbraiding language; bitter or sarcastic reproach; insulting\n   invective."
            }
        ]
    },
    {
        "id": 91212,
        "spelling_name": "Taut",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144556,
                "word_type": "a.",
                "spelling_usage": "Tight; stretched; not slack; -- said esp. of a rope that is\n   tightly strained."
            },
            {
                "spelling_detail_id": 144557,
                "word_type": "a.",
                "spelling_usage": "Snug; close; firm; secure."
            }
        ]
    },
    {
        "id": 91224,
        "spelling_name": "Tautology",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144569,
                "word_type": "n.",
                "spelling_usage": "A repetition of the same meaning in different words;\n   needless repetition of an idea in different words or phrases; a\n   representation of anything as the cause, condition, or consequence of\n   itself, as in the following lines: --//The dawn is overcast, the\n   morning lowers,/And heavily in clouds brings on the day. Addison."
            }
        ]
    },
    {
        "id": 91241,
        "spelling_name": "Tawdry",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144592,
                "word_type": "superl.",
                "spelling_usage": "Bought at the festival of St. Audrey."
            },
            {
                "spelling_detail_id": 144593,
                "word_type": "superl.",
                "spelling_usage": "Very fine and showy in colors, without taste or\n   elegance; having an excess of showy ornaments without grace; cheap and\n   gaudy; as, a tawdry dress; tawdry feathers; tawdry colors."
            },
            {
                "spelling_detail_id": 144595,
                "word_type": "n.",
                "spelling_usage": "A necklace of a rural fashion, bought at St. Audrey's fair;\n   hence, a necklace in general."
            }
        ]
    },
    {
        "id": 91408,
        "spelling_name": "Teetotal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144839,
                "word_type": "a.",
                "spelling_usage": "Entire; total."
            }
        ]
    },
    {
        "id": 91501,
        "spelling_name": "Telltale",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 144963,
                "word_type": "a.",
                "spelling_usage": "Telling tales; babbling."
            },
            {
                "spelling_detail_id": 144964,
                "word_type": "n.",
                "spelling_usage": "One who officiously communicates information of the\n   private concerns of others; one who tells that which prudence should\n   suppress."
            },
            {
                "spelling_detail_id": 144965,
                "word_type": "n.",
                "spelling_usage": "A movable piece of ivory, lead, or other material,\n   connected with the bellows of an organ, that gives notice, by its\n   position, when the wind is exhausted."
            },
            {
                "spelling_detail_id": 144966,
                "word_type": "n.",
                "spelling_usage": "A mechanical attachment to the steering wheel, which, in\n   the absence of a tiller, shows the position of the helm."
            },
            {
                "spelling_detail_id": 144967,
                "word_type": "n.",
                "spelling_usage": "A compass in the cabin of a vessel, usually placed where\n   the captain can see it at all hours, and thus inform himself of the\n   vessel's course."
            },
            {
                "spelling_detail_id": 144968,
                "word_type": "n.",
                "spelling_usage": "A machine or contrivance for indicating or recording\n   something, particularly for keeping a check upon employees, as factory\n   hands, watchmen, drivers, check takers, and the like, by revealing to\n   their employers what they have done or omitted."
            },
            {
                "spelling_detail_id": 144969,
                "word_type": "n.",
                "spelling_usage": "The tattler. See Tattler."
            }
        ]
    },
    {
        "id": 91529,
        "spelling_name": "Temerity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145003,
                "word_type": "n.",
                "spelling_usage": "Unreasonable contempt of danger; extreme venturesomeness;\n   rashness; as, the temerity of a commander in war."
            }
        ]
    },
    {
        "id": 91537,
        "spelling_name": "Temperance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145033,
                "word_type": "v. t.",
                "spelling_usage": "Habitual moderation in regard to the indulgence of\n   the natural appetites and passions; restrained or moderate indulgence;\n   moderation; as, temperance in eating and drinking; temperance in the\n   indulgence of joy or mirth; specifically, moderation, and sometimes\n   abstinence, in respect to using intoxicating liquors."
            },
            {
                "spelling_detail_id": 145034,
                "word_type": "v. t.",
                "spelling_usage": "Moderation of passion; patience; calmness;\n   sedateness."
            },
            {
                "spelling_detail_id": 145035,
                "word_type": "v. t.",
                "spelling_usage": "State with regard to heat or cold; temperature."
            }
        ]
    },
    {
        "id": 91539,
        "spelling_name": "Temperate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145037,
                "word_type": "v. t.",
                "spelling_usage": "Moderate; not excessive; as, temperate heat; a\n   temperate climate."
            },
            {
                "spelling_detail_id": 145038,
                "word_type": "v. t.",
                "spelling_usage": "Not marked with passion; not violent; cool; calm; as,\n   temperate language."
            },
            {
                "spelling_detail_id": 145039,
                "word_type": "v. t.",
                "spelling_usage": "Moderate in the indulgence of the natural appetites\n   or passions; as, temperate in eating and drinking."
            },
            {
                "spelling_detail_id": 145040,
                "word_type": "v. t.",
                "spelling_usage": "Proceeding from temperance."
            },
            {
                "spelling_detail_id": 145041,
                "word_type": "v. t.",
                "spelling_usage": "To render temperate; to moderate; to soften; to\n   temper."
            }
        ]
    },
    {
        "id": 91606,
        "spelling_name": "Tenacity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145143,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being tenacious; as, tenacity, or\n   retentiveness, of memory; tenacity, or persistency, of purpose."
            },
            {
                "spelling_detail_id": 145144,
                "word_type": "n.",
                "spelling_usage": "That quality of bodies which keeps them from parting\n   without considerable force; cohesiveness; the effect of attraction; --\n   as distinguished from brittleness, fragility, mobility, etc."
            },
            {
                "spelling_detail_id": 145145,
                "word_type": "n.",
                "spelling_usage": "That quality of bodies which makes them adhere to other\n   bodies; adhesiveness; viscosity."
            },
            {
                "spelling_detail_id": 145146,
                "word_type": "n.",
                "spelling_usage": "The greatest longitudinal stress a substance can bear\n   without tearing asunder, -- usually expressed with reference to a unit\n   area of the cross section of the substance, as the number of pounds per\n   square inch, or kilograms per square centimeter, necessary to produce\n   rupture."
            }
        ]
    },
    {
        "id": 91745,
        "spelling_name": "Tenuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145371,
                "word_type": "a.",
                "spelling_usage": "Thin; slender; small; minute."
            },
            {
                "spelling_detail_id": 145372,
                "word_type": "a.",
                "spelling_usage": "Rare; subtile; not dense; -- said of fluids."
            },
            {
                "spelling_detail_id": 145373,
                "word_type": "a.",
                "spelling_usage": "Lacking substance, as a tenuous argument."
            }
        ]
    },
    {
        "id": 91760,
        "spelling_name": "Tepid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145391,
                "word_type": "a.",
                "spelling_usage": "Moderately warm; lukewarm; as, a tepid bath; tepid rays;\n   tepid vapors."
            }
        ]
    },
    {
        "id": 91933,
        "spelling_name": "Terse",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145640,
                "word_type": "superl.",
                "spelling_usage": "Appearing as if rubbed or wiped off; rubbed; smooth;\n   polished."
            },
            {
                "spelling_detail_id": 145641,
                "word_type": "superl.",
                "spelling_usage": "Refined; accomplished; -- said of persons."
            },
            {
                "spelling_detail_id": 145642,
                "word_type": "superl.",
                "spelling_usage": "Elegantly concise; free of superfluous words; polished\n   to smoothness; as, terse language; a terse style."
            }
        ]
    },
    {
        "id": 91996,
        "spelling_name": "Testiness",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 145759,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being testy; fretfulness;\n   petulance."
            }
        ]
    },
    {
        "id": 92808,
        "spelling_name": "Thrift",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 146924,
                "word_type": "n.",
                "spelling_usage": "A thriving state; good husbandry; economical management in\n   regard to property; frugality."
            },
            {
                "spelling_detail_id": 146925,
                "word_type": "n.",
                "spelling_usage": "Success and advance in the acquisition of property;\n   increase of worldly goods; gain; prosperity."
            },
            {
                "spelling_detail_id": 146926,
                "word_type": "n.",
                "spelling_usage": "Vigorous growth, as of a plant."
            },
            {
                "spelling_detail_id": 146927,
                "word_type": "n.",
                "spelling_usage": "One of several species of flowering plants of the genera\n   Statice and Armeria."
            }
        ]
    },
    {
        "id": 92955,
        "spelling_name": "Thwart",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 147229,
                "word_type": "a.",
                "spelling_usage": "Situated or placed across something else; transverse;\n   oblique."
            },
            {
                "spelling_detail_id": 147230,
                "word_type": "a.",
                "spelling_usage": "Fig.: Perverse; crossgrained."
            },
            {
                "spelling_detail_id": 147231,
                "word_type": "a.",
                "spelling_usage": "Thwartly; obliquely; transversely; athwart."
            },
            {
                "spelling_detail_id": 147232,
                "word_type": "prep.",
                "spelling_usage": "Across; athwart."
            },
            {
                "spelling_detail_id": 147233,
                "word_type": "n.",
                "spelling_usage": "A seat in an open boat reaching from one side to the other,\n   or athwart the boat."
            },
            {
                "spelling_detail_id": 147236,
                "word_type": "v. t.",
                "spelling_usage": "To move across or counter to; to cross; as, an arrow\n   thwarts the air."
            },
            {
                "spelling_detail_id": 147237,
                "word_type": "v. t.",
                "spelling_usage": "To cross, as a purpose; to oppose; to run counter to; to\n   contravene; hence, to frustrate or defeat."
            },
            {
                "spelling_detail_id": 147238,
                "word_type": "v. i.",
                "spelling_usage": "To move or go in an oblique or crosswise manner."
            },
            {
                "spelling_detail_id": 147239,
                "word_type": "v. i.",
                "spelling_usage": "Hence, to be in opposition; to clash."
            }
        ]
    },
    {
        "id": 93189,
        "spelling_name": "Timid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 147674,
                "word_type": "a.",
                "spelling_usage": "Wanting courage to meet danger; easily frightened; timorous;\n   not bold; fearful; shy."
            }
        ]
    },
    {
        "id": 93198,
        "spelling_name": "Timorous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 147684,
                "word_type": "a.",
                "spelling_usage": "Fearful of danger; timid; deficient in courage."
            },
            {
                "spelling_detail_id": 147685,
                "word_type": "a.",
                "spelling_usage": "Indicating, or caused by, fear; as, timorous doubts."
            }
        ]
    },
    {
        "id": 93425,
        "spelling_name": "Toady",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148056,
                "word_type": "n.",
                "spelling_usage": "A mean flatterer; a toadeater; a sycophant."
            },
            {
                "spelling_detail_id": 148057,
                "word_type": "n.",
                "spelling_usage": "A coarse, rustic woman."
            },
            {
                "spelling_detail_id": 148060,
                "word_type": "v. t.",
                "spelling_usage": "To fawn upon with mean sycophancy."
            }
        ]
    },
    {
        "id": 93451,
        "spelling_name": "Tocsin",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148098,
                "word_type": "n.",
                "spelling_usage": "An alarm bell, or the ringing of a bell for the purpose of\n   alarm."
            }
        ]
    },
    {
        "id": 93618,
        "spelling_name": "Tonic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148390,
                "word_type": "a.",
                "spelling_usage": "Of or relating to tones or sounds; specifically (Phon.),\n   applied to, or distingshing, a speech sound made with tone unmixed and\n   undimmed by obstruction, such sounds, namely, the vowels and\n   diphthongs, being so called by Dr. James Rush (1833) \" from their\n   forming the purest and most plastic material of intonation.\""
            },
            {
                "spelling_detail_id": 148391,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to tension; increasing tension; hence,\n   increasing strength; as, tonic power."
            },
            {
                "spelling_detail_id": 148392,
                "word_type": "a.",
                "spelling_usage": "Increasing strength, or the tone of the animal system;\n   obviating the effects of debility, and restoring healthy functions."
            },
            {
                "spelling_detail_id": 148393,
                "word_type": "n.",
                "spelling_usage": "A tonic element or letter; a vowel or a diphthong."
            },
            {
                "spelling_detail_id": 148394,
                "word_type": "n.",
                "spelling_usage": "The key tone, or first tone of any scale."
            },
            {
                "spelling_detail_id": 148395,
                "word_type": "n.",
                "spelling_usage": "A medicine that increases the strength, and gives vigor of\n   action to the system."
            }
        ]
    },
    {
        "id": 93747,
        "spelling_name": "Topple",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148603,
                "word_type": "v. i.",
                "spelling_usage": "To fall forward; to pitch or tumble down."
            },
            {
                "spelling_detail_id": 148604,
                "word_type": "v. t.",
                "spelling_usage": "To throw down; to overturn."
            }
        ]
    },
    {
        "id": 93783,
        "spelling_name": "Torment",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148655,
                "word_type": "n.",
                "spelling_usage": "An engine for casting stones."
            },
            {
                "spelling_detail_id": 148656,
                "word_type": "n.",
                "spelling_usage": "Extreme pain; anguish; torture; the utmost degree of\n   misery, either of body or mind."
            },
            {
                "spelling_detail_id": 148657,
                "word_type": "n.",
                "spelling_usage": "That which gives pain, vexation, or misery."
            },
            {
                "spelling_detail_id": 148660,
                "word_type": "v. t.",
                "spelling_usage": "To put to extreme pain or anguish; to inflict\n   excruciating misery upon, either of body or mind; to torture."
            },
            {
                "spelling_detail_id": 148661,
                "word_type": "v. t.",
                "spelling_usage": "To pain; to distress; to afflict."
            },
            {
                "spelling_detail_id": 148662,
                "word_type": "v. t.",
                "spelling_usage": "To tease; to vex; to harass; as, to be tormented with\n   importunities, or with petty annoyances."
            },
            {
                "spelling_detail_id": 148663,
                "word_type": "v. t.",
                "spelling_usage": "To put into great agitation."
            }
        ]
    },
    {
        "id": 93808,
        "spelling_name": "Torpid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148698,
                "word_type": "a.",
                "spelling_usage": "Having lost motion, or the power of exertion and feeling;\n   numb; benumbed; as, a torpid limb."
            },
            {
                "spelling_detail_id": 148699,
                "word_type": "a.",
                "spelling_usage": "Dull; stupid; sluggish; inactive."
            }
        ]
    },
    {
        "id": 93820,
        "spelling_name": "Torque",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148712,
                "word_type": "n.",
                "spelling_usage": "A collar or neck chain, usually twisted, especially as worn\n   by ancient barbaric nations, as the Gauls, Germans, and Britons."
            },
            {
                "spelling_detail_id": 148713,
                "word_type": "n.",
                "spelling_usage": "That which tends to produce torsion; a couple of forces."
            },
            {
                "spelling_detail_id": 148714,
                "word_type": "n.",
                "spelling_usage": "A turning or twisting; tendency to turn, or cause to turn,\n   about an axis."
            }
        ]
    },
    {
        "id": 93864,
        "spelling_name": "Tortuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148774,
                "word_type": "a.",
                "spelling_usage": "Bent in different directions; wreathed; twisted; winding;\n   as, a tortuous train; a tortuous train; a tortuous leaf or corolla."
            },
            {
                "spelling_detail_id": 148775,
                "word_type": "a.",
                "spelling_usage": "Fig.: Deviating from rectitude; indirect; erroneous;\n   deceitful."
            },
            {
                "spelling_detail_id": 148776,
                "word_type": "a.",
                "spelling_usage": "Injurious: tortious."
            },
            {
                "spelling_detail_id": 148777,
                "word_type": "a.",
                "spelling_usage": "Oblique; -- applied to the six signs of the zodiac (from\n   Capricorn to Gemini) which ascend most rapidly and obliquely."
            }
        ]
    },
    {
        "id": 93982,
        "spelling_name": "Tout",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 148993,
                "word_type": "v. i.",
                "spelling_usage": "To act as a tout. See 2d Tout."
            },
            {
                "spelling_detail_id": 148994,
                "word_type": "v. i.",
                "spelling_usage": "To ply or seek for customers."
            },
            {
                "spelling_detail_id": 148995,
                "word_type": "n.",
                "spelling_usage": "One who secretly watches race horses which are in course of\n   training, to get information about their capabilities, for use in\n   betting."
            },
            {
                "spelling_detail_id": 148996,
                "word_type": "v. i.",
                "spelling_usage": "To toot a horn."
            },
            {
                "spelling_detail_id": 148997,
                "word_type": "n.",
                "spelling_usage": "The anus."
            }
        ]
    },
    {
        "id": 94116,
        "spelling_name": "Tractable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 149217,
                "word_type": "v. t.",
                "spelling_usage": "Capable of being easily led, taught, or managed;\n   docile; manageable; governable; as, tractable children; a tractable\n   learner."
            },
            {
                "spelling_detail_id": 149218,
                "word_type": "v. t.",
                "spelling_usage": "Capable of being handled; palpable; practicable;\n   feasible; as, tractable measures."
            }
        ]
    },
    {
        "id": 94380,
        "spelling_name": "Transgress",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 149669,
                "word_type": "v. t.",
                "spelling_usage": "To pass over or beyond; to surpass."
            },
            {
                "spelling_detail_id": 149670,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to overpass, as any prescribed as the /imit\n   of duty; to break or violate, as a law, civil or moral."
            },
            {
                "spelling_detail_id": 149671,
                "word_type": "v. t.",
                "spelling_usage": "To offend against; to vex."
            },
            {
                "spelling_detail_id": 149672,
                "word_type": "v. i.",
                "spelling_usage": "To offend against the law; to sin."
            }
        ]
    },
    {
        "id": 94394,
        "spelling_name": "Transient",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 149684,
                "word_type": "a.",
                "spelling_usage": "Passing before the sight or perception, or, as it were,\n   moving over or across a space or scene viewed, and then disappearing;\n   hence, of short duration; not permanent; not lasting or durable; not\n   stationary; passing; fleeting; brief; transitory; as, transient\n   pleasure."
            },
            {
                "spelling_detail_id": 149685,
                "word_type": "a.",
                "spelling_usage": "Hasty; momentary; imperfect; brief; as, a transient view\n   of a landscape."
            },
            {
                "spelling_detail_id": 149686,
                "word_type": "a.",
                "spelling_usage": "Staying for a short time; not regular or permanent; as,\n   a transient guest; transient boarders."
            },
            {
                "spelling_detail_id": 149687,
                "word_type": "n.",
                "spelling_usage": "That which remains but for a brief time."
            }
        ]
    },
    {
        "id": 94405,
        "spelling_name": "Transitory",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 149709,
                "word_type": "a.",
                "spelling_usage": "Continuing only for a short time; not enduring;\n   fleeting; evanescent."
            }
        ]
    },
    {
        "id": 94620,
        "spelling_name": "Travesty",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 150067,
                "word_type": "a.",
                "spelling_usage": "Disguised by dress so as to be ridiculous; travestied; --\n   applied to a book or shorter composition."
            },
            {
                "spelling_detail_id": 150069,
                "word_type": "n.",
                "spelling_usage": "A burlesque translation or imitation of a work."
            },
            {
                "spelling_detail_id": 150072,
                "word_type": "v. t.",
                "spelling_usage": "To translate, imitate, or represent, so as to render\n   ridiculous or ludicrous."
            }
        ]
    },
    {
        "id": 94634,
        "spelling_name": "Treacherous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 150093,
                "word_type": "a.",
                "spelling_usage": "Like a traitor; involving treachery; violating\n   allegiance or faith pledged; traitorous to the state or sovereign;\n   perfidious in private life; betraying a trust; faithless."
            }
        ]
    },
    {
        "id": 94731,
        "spelling_name": "Trenchant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 150276,
                "word_type": "v. t.",
                "spelling_usage": "Fitted to trench or cut; gutting; sharp."
            },
            {
                "spelling_detail_id": 150277,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: Keen; biting; severe; as, trenchant wit."
            }
        ]
    },
    {
        "id": 94757,
        "spelling_name": "Trepidation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 150319,
                "word_type": "n.",
                "spelling_usage": "An involuntary trembling, sometimes an effect of\n   paralysis, but usually caused by terror or fear; quaking; quivering."
            },
            {
                "spelling_detail_id": 150320,
                "word_type": "n.",
                "spelling_usage": "Hence, a state of terror or alarm; fear; confusion;\n   fright; as, the men were in great trepidation."
            },
            {
                "spelling_detail_id": 150321,
                "word_type": "n.",
                "spelling_usage": "A libration of the starry sphere in the Ptolemaic\n   system; a motion ascribed to the firmament, to account for certain\n   small changes in the position of the ecliptic and of the stars."
            }
        ]
    },
    {
        "id": 94907,
        "spelling_name": "Trickle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 150543,
                "word_type": "v. t.",
                "spelling_usage": "To flow in a small, gentle stream; to run in drops."
            }
        ]
    },
    {
        "id": 94979,
        "spelling_name": "Trifling",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 150627,
                "word_type": "p. pr. & vb. n.",
                "spelling_usage": "of Trifle"
            },
            {
                "spelling_detail_id": 150632,
                "word_type": "a.",
                "spelling_usage": "Being of small value or importance; trivial; paltry; as,\n   a trifling debt; a trifling affair."
            }
        ]
    },
    {
        "id": 95242,
        "spelling_name": "Trite",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 151006,
                "word_type": "a.",
                "spelling_usage": "Worn out; common; used until so common as to have lost\n   novelty and interest; hackneyed; stale; as, a trite remark; a trite\n   subject."
            }
        ]
    },
    {
        "id": 95463,
        "spelling_name": "Truce",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 151332,
                "word_type": "n.",
                "spelling_usage": "A suspension of arms by agreement of the commanders of\n   opposing forces; a temporary cessation of hostilities, for negotiation\n   or other purpose; an armistice."
            },
            {
                "spelling_detail_id": 151333,
                "word_type": "n.",
                "spelling_usage": "Hence, intermission of action, pain, or contest; temporary\n   cessation; short quiet."
            }
        ]
    },
    {
        "id": 95480,
        "spelling_name": "Truculence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 151367,
                "word_type": "n.",
                "spelling_usage": "Alt. of Truculency"
            }
        ]
    },
    {
        "id": 95484,
        "spelling_name": "Trudge",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 151374,
                "word_type": "v. i.",
                "spelling_usage": "To walk or march with labor; to jog along; to move\n   wearily."
            }
        ]
    },
    {
        "id": 95823,
        "spelling_name": "Turbid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 151985,
                "word_type": "a.",
                "spelling_usage": "Having the lees or sediment disturbed; roiled; muddy;\n   thick; not clear; -- used of liquids of any kind; as, turbid water;\n   turbid wine."
            },
            {
                "spelling_detail_id": 151986,
                "word_type": "a.",
                "spelling_usage": "Disturbed; confused; disordered."
            }
        ]
    },
    {
        "id": 95842,
        "spelling_name": "Turbulence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152015,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being turbulent; a disturbed\n   state; tumult; disorder; agitation."
            }
        ]
    },
    {
        "id": 95872,
        "spelling_name": "Turgid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152057,
                "word_type": "a.",
                "spelling_usage": "Distended beyond the natural state by some internal agent\n   or expansive force; swelled; swollen; bloated; inflated; tumid; --\n   especially applied to an enlarged part of the body; as, a turgid limb;\n   turgid fruit."
            },
            {
                "spelling_detail_id": 152058,
                "word_type": "a.",
                "spelling_usage": "Swelling in style or language; vainly ostentatious;\n   bombastic; pompous; as, a turgid style of speaking."
            }
        ]
    },
    {
        "id": 95899,
        "spelling_name": "Turmoil",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152093,
                "word_type": "n.",
                "spelling_usage": "Harassing labor; trouble; molestation by tumult;\n   disturbance; worrying confusion."
            },
            {
                "spelling_detail_id": 152096,
                "word_type": "v. t.",
                "spelling_usage": "To harass with commotion; to disquiet; to worry."
            },
            {
                "spelling_detail_id": 152097,
                "word_type": "v. i.",
                "spelling_usage": "To be disquieted or confused; to be in commotion."
            }
        ]
    },
    {
        "id": 95942,
        "spelling_name": "Turpitude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152208,
                "word_type": "n.",
                "spelling_usage": "Inherent baseness or vileness of principle, words, or\n   actions; shameful wickedness; depravity."
            }
        ]
    },
    {
        "id": 95944,
        "spelling_name": "Turquoise",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152209,
                "word_type": "n.",
                "spelling_usage": "Alt. of Turquois"
            },
            {
                "spelling_detail_id": 152211,
                "word_type": "a.",
                "spelling_usage": "Having a fine light blue color, like that of choice\n   mineral turquoise."
            }
        ]
    },
    {
        "id": 96236,
        "spelling_name": "Tyro",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152721,
                "word_type": "n.",
                "spelling_usage": "A beginner in learning; one who is in the rudiments of any\n   branch of study; a person imperfectly acquainted with a subject; a\n   novice."
            }
        ]
    },
    {
        "id": 96264,
        "spelling_name": "Ubiquitous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152750,
                "word_type": "a.",
                "spelling_usage": "Existing or being everywhere, or in all places, at the\n   same time; omnipresent."
            }
        ]
    },
    {
        "id": 96324,
        "spelling_name": "Ulterior",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152826,
                "word_type": "a.",
                "spelling_usage": "Situated beyond, or on the farther side; thither; --\n   correlative with hither."
            },
            {
                "spelling_detail_id": 152827,
                "word_type": "a.",
                "spelling_usage": "Further; remoter; more distant; succeeding; as, ulterior\n   demands or propositions; ulterior views; what ulterior measures will be\n   adopted is uncertain."
            },
            {
                "spelling_detail_id": 152828,
                "word_type": "n.",
                "spelling_usage": "Ulterior side or part."
            }
        ]
    },
    {
        "id": 96394,
        "spelling_name": "Umbrage",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 152928,
                "word_type": "n.",
                "spelling_usage": "Shade; shadow; obscurity; hence, that which affords a\n   shade, as a screen of trees or foliage."
            },
            {
                "spelling_detail_id": 152929,
                "word_type": "n.",
                "spelling_usage": "Shadowy resemblance; shadow."
            },
            {
                "spelling_detail_id": 152930,
                "word_type": "n.",
                "spelling_usage": "The feeling of being overshadowed; jealousy of another, as\n   standing in one's light or way; hence, suspicion of injury or wrong;\n   offense; resentment."
            }
        ]
    },
    {
        "id": 96789,
        "spelling_name": "Uncouth",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 153457,
                "word_type": "a.",
                "spelling_usage": "Unknown."
            },
            {
                "spelling_detail_id": 153458,
                "word_type": "a.",
                "spelling_usage": "Uncommon; rare; exquisite; elegant."
            },
            {
                "spelling_detail_id": 153459,
                "word_type": "a.",
                "spelling_usage": "Unfamiliar; strange; hence, mysterious; dreadful; also,\n   odd; awkward; boorish; as, uncouth manners."
            }
        ]
    },
    {
        "id": 96871,
        "spelling_name": "Underbid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 153570,
                "word_type": "v. t.",
                "spelling_usage": "To bid less than, as when a contract or service is\n   offered to the lowest bidder; to offer to contract, sell, or do for a\n   less price than."
            }
        ]
    },
    {
        "id": 96977,
        "spelling_name": "Undermine",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 153719,
                "word_type": "v. t.",
                "spelling_usage": "To excavate the earth beneath, or the part of,\n   especially for the purpose of causing to fall or be overthrown; to form\n   a mine under; to sap; as, to undermine a wall."
            },
            {
                "spelling_detail_id": 153720,
                "word_type": "v. t.",
                "spelling_usage": "Fig.: To remove the foundation or support of by\n   clandestine means; to ruin in an underhand way; as, to undermine\n   reputation; to undermine the constitution of the state."
            }
        ]
    },
    {
        "id": 97166,
        "spelling_name": "Undulate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 153989,
                "word_type": "a.",
                "spelling_usage": "Same as Undulated."
            },
            {
                "spelling_detail_id": 153992,
                "word_type": "v. t.",
                "spelling_usage": "To cause to move backward and forward, or up and down,\n   in undulations or waves; to cause to vibrate."
            },
            {
                "spelling_detail_id": 153993,
                "word_type": "v. i.",
                "spelling_usage": "To move in, or have, undulations or waves; to vibrate;\n   to wave; as, undulating air."
            }
        ]
    },
    {
        "id": 97183,
        "spelling_name": "Unearth",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 154018,
                "word_type": "v. t.",
                "spelling_usage": "To drive or draw from the earth; hence, to uncover; to\n   bring out from concealment; to bring to light; to disclose; as, to\n   unearth a secret."
            }
        ]
    },
    {
        "id": 97256,
        "spelling_name": "Unfeigned",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 154115,
                "word_type": "a.",
                "spelling_usage": "Not feigned; not counterfeit; not hypocritical; real;\n   sincere; genuine; as, unfeigned piety; unfeigned love to man."
            }
        ]
    },
    {
        "id": 98173,
        "spelling_name": "Untoward",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 155314,
                "word_type": "prep.",
                "spelling_usage": "Toward."
            },
            {
                "spelling_detail_id": 155315,
                "word_type": "a.",
                "spelling_usage": "Froward; perverse."
            },
            {
                "spelling_detail_id": 155316,
                "word_type": "a.",
                "spelling_usage": "Awkward; ungraceful."
            },
            {
                "spelling_detail_id": 155317,
                "word_type": "a.",
                "spelling_usage": "Inconvenient; troublesome; vexatious; unlucky;\n   unfortunate; as, an untoward wind or accident."
            }
        ]
    },
    {
        "id": 98299,
        "spelling_name": "Upbraid",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 155486,
                "word_type": "v. t.",
                "spelling_usage": "To charge with something wrong or disgraceful; to\n   reproach; to cast something in the teeth of; -- followed by with or\n   for, and formerly of, before the thing imputed."
            },
            {
                "spelling_detail_id": 155487,
                "word_type": "v. t.",
                "spelling_usage": "To reprove severely; to rebuke; to chide."
            },
            {
                "spelling_detail_id": 155488,
                "word_type": "v. t.",
                "spelling_usage": "To treat with contempt."
            },
            {
                "spelling_detail_id": 155489,
                "word_type": "v. t.",
                "spelling_usage": "To object or urge as a matter of reproach; to cast up;\n   -- with to before the person."
            },
            {
                "spelling_detail_id": 155490,
                "word_type": "v. i.",
                "spelling_usage": "To utter upbraidings."
            },
            {
                "spelling_detail_id": 155491,
                "word_type": "n.",
                "spelling_usage": "The act of reproaching; contumely."
            }
        ]
    },
    {
        "id": 98485,
        "spelling_name": "Urbane",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 155743,
                "word_type": "a.",
                "spelling_usage": "Courteous in manners; polite; refined; elegant."
            }
        ]
    },
    {
        "id": 98767,
        "spelling_name": "Vacillation",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156142,
                "word_type": "n.",
                "spelling_usage": "The act of vacillating; a moving one way and the\n   other; a wavering."
            }
        ]
    },
    {
        "id": 98773,
        "spelling_name": "Vacuity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156147,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being vacuous, or not filled;\n   emptiness; vacancy; as, vacuity of mind; vacuity of countenance."
            },
            {
                "spelling_detail_id": 156148,
                "word_type": "n.",
                "spelling_usage": "Space unfilled or unoccupied, or occupied with an\n   invisible fluid only; emptiness; void; vacuum."
            },
            {
                "spelling_detail_id": 156149,
                "word_type": "n.",
                "spelling_usage": "Want of reality; inanity; nihility."
            }
        ]
    },
    {
        "id": 98799,
        "spelling_name": "Vagary",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156181,
                "word_type": "n.",
                "spelling_usage": "A wandering or strolling."
            },
            {
                "spelling_detail_id": 156182,
                "word_type": "n.",
                "spelling_usage": "Hence, a wandering of the thoughts; a wild or fanciful\n   freak; a whim; a whimsical purpose."
            }
        ]
    },
    {
        "id": 98828,
        "spelling_name": "Vain",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156232,
                "word_type": "superl.",
                "spelling_usage": "Having no real substance, value, or importance; empty;\n   void; worthless; unsatisfying."
            },
            {
                "spelling_detail_id": 156233,
                "word_type": "superl.",
                "spelling_usage": "Destitute of forge or efficacy; effecting no purpose;\n   fruitless; ineffectual; as, vain toil; a vain attempt."
            },
            {
                "spelling_detail_id": 156234,
                "word_type": "superl.",
                "spelling_usage": "Proud of petty things, or of trifling attainments;\n   having a high opinion of one's own accomplishments with slight reason;\n   conceited; puffed up; inflated."
            },
            {
                "spelling_detail_id": 156235,
                "word_type": "superl.",
                "spelling_usage": "Showy; ostentatious."
            },
            {
                "spelling_detail_id": 156236,
                "word_type": "n.",
                "spelling_usage": "Vanity; emptiness; -- now used only in the phrase in vain."
            }
        ]
    },
    {
        "id": 98878,
        "spelling_name": "Valiant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156296,
                "word_type": "a.",
                "spelling_usage": "Vigorous in body; strong; powerful; as, a valiant fencer."
            },
            {
                "spelling_detail_id": 156297,
                "word_type": "a.",
                "spelling_usage": "Intrepid in danger; courageous; brave."
            },
            {
                "spelling_detail_id": 156298,
                "word_type": "a.",
                "spelling_usage": "Performed with valor or bravery; heroic."
            }
        ]
    },
    {
        "id": 98904,
        "spelling_name": "Valorous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156335,
                "word_type": "a.",
                "spelling_usage": "Possessing or exhibiting valor; brave; courageous;\n   valiant; intrepid."
            }
        ]
    },
    {
        "id": 98977,
        "spelling_name": "Vanquish",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156465,
                "word_type": "v. t.",
                "spelling_usage": "To conquer, overcome, or subdue in battle, as an\n   enemy."
            },
            {
                "spelling_detail_id": 156466,
                "word_type": "v. t.",
                "spelling_usage": "Hence, to defeat in any contest; to get the better of;\n   to put down; to refute."
            },
            {
                "spelling_detail_id": 156467,
                "word_type": "n.",
                "spelling_usage": "A disease in sheep, in which they pine away."
            }
        ]
    },
    {
        "id": 99041,
        "spelling_name": "Variegate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156568,
                "word_type": "v. t.",
                "spelling_usage": "To diversify in external appearance; to mark with\n   different colors; to dapple; to streak; as, to variegate a floor with\n   marble of different colors."
            }
        ]
    },
    {
        "id": 99165,
        "spelling_name": "Veer",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156770,
                "word_type": "v. i.",
                "spelling_usage": "To change direction; to turn; to shift; as, wind veers to\n   the west or north."
            },
            {
                "spelling_detail_id": 156771,
                "word_type": "v. t.",
                "spelling_usage": "To direct to a different course; to turn; to wear; as, to\n   veer, or wear, a vessel."
            }
        ]
    },
    {
        "id": 99185,
        "spelling_name": "Vehemence",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156806,
                "word_type": "n.",
                "spelling_usage": "The quality pr state of being vehement; impetuous force;\n   impetuosity; violence; fury; as, the vehemence."
            },
            {
                "spelling_detail_id": 156807,
                "word_type": "n.",
                "spelling_usage": "Violent ardor; great heat; animated fervor; as, the\n   vehemence of love, anger, or other passions."
            }
        ]
    },
    {
        "id": 99256,
        "spelling_name": "Venal",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156915,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to veins; venous; as, venal blood."
            },
            {
                "spelling_detail_id": 156916,
                "word_type": "a.",
                "spelling_usage": "Capable of being bought or obtained for money or other\n   valuable consideration; made matter of trade or barter; held for sale;\n   salable; mercenary; purchasable; hireling; as, venal services."
            }
        ]
    },
    {
        "id": 99282,
        "spelling_name": "Veneer",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156948,
                "word_type": "v. t.",
                "spelling_usage": "To overlay or plate with a thin layer of wood or other\n   material for outer finish or decoration; as, to veneer a piece of\n   furniture with mahogany. Used also figuratively."
            },
            {
                "spelling_detail_id": 156949,
                "word_type": "v. t.",
                "spelling_usage": "A thin leaf or layer of a more valuable or beautiful\n   material for overlaying an inferior one, especially such a thin leaf of\n   wood to be glued to a cheaper wood; hence, external show; gloss; false\n   pretense."
            }
        ]
    },
    {
        "id": 99299,
        "spelling_name": "Veneration",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 156970,
                "word_type": "n.",
                "spelling_usage": "The act of venerating, or the state of being venerated;\n   the highest degree of respect and reverence; respect mingled with awe;\n   a feeling or sentimental excited by the dignity, wisdom, or superiority\n   of a person, by sacredness of character, by consecration to sacred\n   services, or by hallowed associations."
            }
        ]
    },
    {
        "id": 99383,
        "spelling_name": "Veracity",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157121,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being veracious; habitual\n   observance of truth; truthfulness; truth; as, a man of veracity."
            }
        ]
    },
    {
        "id": 99420,
        "spelling_name": "Verdant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157170,
                "word_type": "a.",
                "spelling_usage": "Covered with growing plants or grass; green; fresh;\n   flourishing; as, verdant fields; a verdant lawn."
            },
            {
                "spelling_detail_id": 157171,
                "word_type": "a.",
                "spelling_usage": "Unripe in knowledge or judgment; unsophisticated; raw;\n   green; as, a verdant youth."
            }
        ]
    },
    {
        "id": 99462,
        "spelling_name": "Verisimilitude",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157242,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being verisimilar; the\n   appearance of truth; probability; likelihood."
            }
        ]
    },
    {
        "id": 99464,
        "spelling_name": "Veritable",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157245,
                "word_type": "a.",
                "spelling_usage": "Agreeable to truth or to fact; actual; real; true;\n   genuine."
            }
        ]
    },
    {
        "id": 99605,
        "spelling_name": "Verve",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157447,
                "word_type": "n.",
                "spelling_usage": "Excitement of imagination such as animates a poet, artist,\n   or musician, in composing or performing; rapture; enthusiasm; spirit;\n   energy."
            }
        ]
    },
    {
        "id": 99664,
        "spelling_name": "Vestige",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157540,
                "word_type": "n.",
                "spelling_usage": "The mark of the foot left on the earth; a track or\n   footstep; a trace; a sign; hence, a faint mark or visible sign left by\n   something which is lost, or has perished, or is no longer present;\n   remains; as, the vestiges of ancient magnificence in Palmyra; vestiges\n   of former population."
            }
        ]
    },
    {
        "id": 99697,
        "spelling_name": "Vex",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157588,
                "word_type": "v. t.",
                "spelling_usage": "To to/s back and forth; to agitate; to disquiet."
            },
            {
                "spelling_detail_id": 157589,
                "word_type": "v. t.",
                "spelling_usage": "To make angry or annoyed by little provocations; to\n   irritate; to plague; to torment; to harass; to afflict; to trouble; to\n   tease."
            },
            {
                "spelling_detail_id": 157590,
                "word_type": "v. t.",
                "spelling_usage": "To twist; to weave."
            },
            {
                "spelling_detail_id": 157591,
                "word_type": "v. i.",
                "spelling_usage": "To be irritated; to fret."
            }
        ]
    },
    {
        "id": 99842,
        "spelling_name": "Vigilance",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157829,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being vigilant; forbearance of\n   sleep; wakefulness."
            },
            {
                "spelling_detail_id": 157830,
                "word_type": "n.",
                "spelling_usage": "Watchfulness in respect of danger; care; caution;\n   circumspection."
            },
            {
                "spelling_detail_id": 157831,
                "word_type": "n.",
                "spelling_usage": "Guard; watch."
            }
        ]
    },
    {
        "id": 99844,
        "spelling_name": "Vigilant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157833,
                "word_type": "a.",
                "spelling_usage": "Attentive to discover and avoid danger, or to provide for\n   safety; wakeful; watchful; circumspect; wary."
            }
        ]
    },
    {
        "id": 99853,
        "spelling_name": "Vigorous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157847,
                "word_type": "a.",
                "spelling_usage": "Possessing vigor; full of physical or mental strength or\n   active force; strong; lusty; robust; as, a vigorous youth; a vigorous\n   plant."
            },
            {
                "spelling_detail_id": 157848,
                "word_type": "a.",
                "spelling_usage": "Exhibiting strength, either of body or mind; powerful;\n   strong; forcible; energetic; as, vigorous exertions; a vigorous\n   prosecution of a war."
            }
        ]
    },
    {
        "id": 99865,
        "spelling_name": "Vilify",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157861,
                "word_type": "v. t.",
                "spelling_usage": "To make vile; to debase; to degrade; to disgrace."
            },
            {
                "spelling_detail_id": 157862,
                "word_type": "v. t.",
                "spelling_usage": "To degrade or debase by report; to defame; to traduce;\n   to calumniate."
            },
            {
                "spelling_detail_id": 157863,
                "word_type": "v. t.",
                "spelling_usage": "To treat as vile; to despise."
            }
        ]
    },
    {
        "id": 99935,
        "spelling_name": "Vindictive",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 157964,
                "word_type": "a.",
                "spelling_usage": "Disposed to revenge; prompted or characterized by\n   revenge; revengeful."
            },
            {
                "spelling_detail_id": 157965,
                "word_type": "a.",
                "spelling_usage": "Punitive."
            }
        ]
    },
    {
        "id": 100009,
        "spelling_name": "Virago",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158070,
                "word_type": "n.",
                "spelling_usage": "A woman of extraordinary stature, strength, and courage; a\n   woman who has the robust body and masculine mind of a man; a female\n   warrior."
            },
            {
                "spelling_detail_id": 158071,
                "word_type": "n.",
                "spelling_usage": "Hence, a mannish woman; a bold, turbulent woman; a\n   termagant; a vixen."
            }
        ]
    },
    {
        "id": 100078,
        "spelling_name": "Visceral",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158182,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to the viscera; splanchnic."
            },
            {
                "spelling_detail_id": 158183,
                "word_type": "a.",
                "spelling_usage": "Fig.: Having deep sensibility."
            }
        ]
    },
    {
        "id": 100094,
        "spelling_name": "Viscous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158201,
                "word_type": "a.",
                "spelling_usage": "Adhesive or sticky, and having a ropy or glutinous\n   consistency; viscid; glutinous; clammy; tenacious; as, a viscous juice."
            }
        ]
    },
    {
        "id": 100154,
        "spelling_name": "Vitiate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158302,
                "word_type": "v. t.",
                "spelling_usage": "To make vicious, faulty, or imperfect; to render\n   defective; to injure the substance or qualities of; to impair; to\n   contaminate; to spoil; as, exaggeration vitiates a style of writing;\n   sewer gas vitiates the air."
            },
            {
                "spelling_detail_id": 158303,
                "word_type": "v. t.",
                "spelling_usage": "To cause to fail of effect, either wholly or in part;\n   to make void; to destroy, as the validity or binding force of an\n   instrument or transaction; to annul; as, any undue influence exerted on\n   a jury vitiates their verdict; fraud vitiates a contract."
            }
        ]
    },
    {
        "id": 100195,
        "spelling_name": "Vitriolic",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158350,
                "word_type": "a.",
                "spelling_usage": "Of or pertaining to vitriol; derived from, or\n   resembling, vitriol; vitriolous; as, a vitriolic taste. Cf. Vitriol."
            }
        ]
    },
    {
        "id": 100207,
        "spelling_name": "Vituperate",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158364,
                "word_type": "v. t.",
                "spelling_usage": "To find fault with; to scold; to overwhelm with\n   wordy abuse; to censure severely or abusively; to rate."
            }
        ]
    },
    {
        "id": 100214,
        "spelling_name": "Vivacious",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158370,
                "word_type": "a.",
                "spelling_usage": "Having vigorous powers of life; tenacious of life;\n   long-lived."
            },
            {
                "spelling_detail_id": 158371,
                "word_type": "a.",
                "spelling_usage": "Sprightly in temper or conduct; lively; merry; as, a\n   vivacious poet."
            },
            {
                "spelling_detail_id": 158372,
                "word_type": "a.",
                "spelling_usage": "Living through the winter, or from year to year;\n   perennial."
            }
        ]
    },
    {
        "id": 100322,
        "spelling_name": "Volatile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158549,
                "word_type": "a.",
                "spelling_usage": "Passing through the air on wings, or by the buoyant force\n   of the atmosphere; flying; having the power to fly."
            },
            {
                "spelling_detail_id": 158550,
                "word_type": "a.",
                "spelling_usage": "Capable of wasting away, or of easily passing into the\n   aeriform state; subject to evaporation."
            },
            {
                "spelling_detail_id": 158551,
                "word_type": "a.",
                "spelling_usage": "Fig.: Light-hearted; easily affected by circumstances;\n   airy; lively; hence, changeable; fickle; as, a volatile temper."
            },
            {
                "spelling_detail_id": 158552,
                "word_type": "n.",
                "spelling_usage": "A winged animal; wild fowl; game."
            }
        ]
    },
    {
        "id": 100381,
        "spelling_name": "Volubility",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158633,
                "word_type": "n.",
                "spelling_usage": "The quality or state of being voluble (in any of the\n   senses of the adjective)."
            }
        ]
    },
    {
        "id": 100382,
        "spelling_name": "Voluble",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158634,
                "word_type": "a.",
                "spelling_usage": "Easily rolling or turning; easily set in motion; apt to\n   roll; rotating; as, voluble particles of matter."
            },
            {
                "spelling_detail_id": 158635,
                "word_type": "a.",
                "spelling_usage": "Moving with ease and smoothness in uttering words; of\n   rapid speech; nimble in speaking; glib; as, a flippant, voluble,\n   tongue."
            },
            {
                "spelling_detail_id": 158636,
                "word_type": "a.",
                "spelling_usage": "Changeable; unstable; fickle."
            },
            {
                "spelling_detail_id": 158637,
                "word_type": "a.",
                "spelling_usage": "Having the power or habit of turning or twining; as, the\n   voluble stem of hop plants."
            }
        ]
    },
    {
        "id": 100404,
        "spelling_name": "Voluptuous",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158682,
                "word_type": "a.",
                "spelling_usage": "Full of delight or pleasure, especially that of the\n   senses; ministering to sensuous or sensual gratification; exciting\n   sensual desires; luxurious; sensual."
            },
            {
                "spelling_detail_id": 158683,
                "word_type": "a.",
                "spelling_usage": "Given to the enjoyments of luxury and pleasure;\n   indulging to excess in sensual gratifications."
            }
        ]
    },
    {
        "id": 100576,
        "spelling_name": "Waffle",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158939,
                "word_type": "n.",
                "spelling_usage": "A thin cake baked and then rolled; a wafer."
            },
            {
                "spelling_detail_id": 158940,
                "word_type": "n.",
                "spelling_usage": "A soft indented cake cooked in a waffle iron."
            }
        ]
    },
    {
        "id": 100577,
        "spelling_name": "Waft",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158943,
                "word_type": "v. t.",
                "spelling_usage": "To give notice to by waving something; to wave the hand\n   to; to beckon."
            },
            {
                "spelling_detail_id": 158944,
                "word_type": "v. t.",
                "spelling_usage": "To cause to move or go in a wavy manner, or by the impulse\n   of waves, as of water or air; to bear along on a buoyant medium; as, a\n   balloon was wafted over the channel."
            },
            {
                "spelling_detail_id": 158945,
                "word_type": "v. t.",
                "spelling_usage": "To cause to float; to keep from sinking; to buoy."
            },
            {
                "spelling_detail_id": 158946,
                "word_type": "v. i.",
                "spelling_usage": "To be moved, or to pass, on a buoyant medium; to float."
            },
            {
                "spelling_detail_id": 158947,
                "word_type": "n.",
                "spelling_usage": "A wave or current of wind."
            },
            {
                "spelling_detail_id": 158948,
                "word_type": "n.",
                "spelling_usage": "A signal made by waving something, as a flag, in the air."
            },
            {
                "spelling_detail_id": 158949,
                "word_type": "n.",
                "spelling_usage": "An unpleasant flavor."
            },
            {
                "spelling_detail_id": 158950,
                "word_type": "n.",
                "spelling_usage": "A knot, or stop, in the middle of a flag."
            }
        ]
    },
    {
        "id": 100583,
        "spelling_name": "Wag",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 158957,
                "word_type": "v. t.",
                "spelling_usage": "To move one way and the other with quick turns; to shake to\n   and fro; to move vibratingly; to cause to vibrate, as a part of the\n   body; as, to wag the head."
            },
            {
                "spelling_detail_id": 158958,
                "word_type": "v. i.",
                "spelling_usage": "To move one way and the other; to be shaken to and fro; to\n   vibrate."
            },
            {
                "spelling_detail_id": 158959,
                "word_type": "v. i.",
                "spelling_usage": "To be in action or motion; to move; to get along; to\n   progress; to stir."
            },
            {
                "spelling_detail_id": 158960,
                "word_type": "v. i.",
                "spelling_usage": "To go; to depart; to pack oft."
            },
            {
                "spelling_detail_id": 158961,
                "word_type": "v.",
                "spelling_usage": "The act of wagging; a shake; as, a wag of the head."
            },
            {
                "spelling_detail_id": 158962,
                "word_type": "v.",
                "spelling_usage": "A man full of sport and humor; a ludicrous fellow; a humorist;\n   a wit; a joker."
            }
        ]
    },
    {
        "id": 100738,
        "spelling_name": "Wan",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 159254,
                "word_type": "imp.",
                "spelling_usage": "Won."
            },
            {
                "spelling_detail_id": 159255,
                "word_type": "a.",
                "spelling_usage": "Having a pale or sickly hue; languid of look; pale; pallid."
            },
            {
                "spelling_detail_id": 159256,
                "word_type": "n.",
                "spelling_usage": "The quality of being wan; wanness."
            },
            {
                "spelling_detail_id": 159257,
                "word_type": "v. i.",
                "spelling_usage": "To grow wan; to become pale or sickly in looks."
            },
            {
                "spelling_detail_id": 161949,
                "word_type": "",
                "spelling_usage": "of Win"
            }
        ]
    },
    {
        "id": 100771,
        "spelling_name": "Wanton",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 159314,
                "word_type": "v. t.",
                "spelling_usage": "Untrained; undisciplined; unrestrained; hence, loose;\n   free; luxuriant; roving; sportive."
            },
            {
                "spelling_detail_id": 159315,
                "word_type": "v. t.",
                "spelling_usage": "Wandering from moral rectitude; perverse; dissolute."
            },
            {
                "spelling_detail_id": 159316,
                "word_type": "v. t.",
                "spelling_usage": "Specifically: Deviating from the rules of chastity;\n   lewd; lustful; lascivious; libidinous; lecherous."
            },
            {
                "spelling_detail_id": 159317,
                "word_type": "v. t.",
                "spelling_usage": "Reckless; heedless; as, wanton mischief."
            },
            {
                "spelling_detail_id": 159318,
                "word_type": "n.",
                "spelling_usage": "A roving, frolicsome thing; a trifler; -- used rarely as a\n   term of endearment."
            },
            {
                "spelling_detail_id": 159319,
                "word_type": "n.",
                "spelling_usage": "One brought up without restraint; a pampered pet."
            },
            {
                "spelling_detail_id": 159320,
                "word_type": "n.",
                "spelling_usage": "A lewd person; a lascivious man or woman."
            },
            {
                "spelling_detail_id": 159323,
                "word_type": "v. i.",
                "spelling_usage": "To rove and ramble without restraint, rule, or limit; to\n   revel; to play loosely; to frolic."
            },
            {
                "spelling_detail_id": 159324,
                "word_type": "v. i.",
                "spelling_usage": "To sport in lewdness; to play the wanton; to play\n   lasciviously."
            },
            {
                "spelling_detail_id": 159325,
                "word_type": "v. t.",
                "spelling_usage": "To cause to become wanton; also, to waste in wantonness."
            }
        ]
    },
    {
        "id": 100861,
        "spelling_name": "Warmonger",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 159499,
                "word_type": "n.",
                "spelling_usage": "One who makes ar a trade or business; a mercenary."
            }
        ]
    },
    {
        "id": 100880,
        "spelling_name": "Warrant",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 159549,
                "word_type": "n.",
                "spelling_usage": "That which warrants or authorizes; a commission giving\n   authority, or justifying the doing of anything; an act, instrument, or\n   obligation, by which one person authorizes another to do something\n   which he has not otherwise a right to do; an act or instrument\n   investing one with a right or authority, and thus securing him from\n   loss or damage; commission; authority."
            },
            {
                "spelling_detail_id": 159550,
                "word_type": "n.",
                "spelling_usage": "A writing which authorizes a person to receive money or\n   other thing."
            },
            {
                "spelling_detail_id": 159551,
                "word_type": "n.",
                "spelling_usage": "A precept issued by a magistrate authorizing an officer to\n   make an arrest, a seizure, or a search, or do other acts incident to\n   the administration of justice."
            },
            {
                "spelling_detail_id": 159552,
                "word_type": "n.",
                "spelling_usage": "An official certificate of appointment issued to an\n   officer of lower rank than a commissioned officer. See Warrant officer,\n   below."
            },
            {
                "spelling_detail_id": 159553,
                "word_type": "n.",
                "spelling_usage": "That which vouches or insures for anything; guaranty;\n   security."
            },
            {
                "spelling_detail_id": 159554,
                "word_type": "n.",
                "spelling_usage": "That which attests or proves; a voucher."
            },
            {
                "spelling_detail_id": 159555,
                "word_type": "n.",
                "spelling_usage": "Right; legality; allowance."
            },
            {
                "spelling_detail_id": 159558,
                "word_type": "n.",
                "spelling_usage": "To make secure; to give assurance against harm; to\n   guarantee safety to; to give authority or power to do, or forbear to\n   do, anything by which the person authorized is secured, or saved\n   harmless, from any loss or damage by his action."
            },
            {
                "spelling_detail_id": 159559,
                "word_type": "n.",
                "spelling_usage": "To support by authority or proof; to justify; to maintain;\n   to sanction; as, reason warrants it."
            },
            {
                "spelling_detail_id": 159560,
                "word_type": "n.",
                "spelling_usage": "To give a warrant or warranty to; to assure as if by\n   giving a warrant to."
            },
            {
                "spelling_detail_id": 159561,
                "word_type": "n.",
                "spelling_usage": "To secure to, as a grantee, an estate granted; to assure."
            },
            {
                "spelling_detail_id": 159562,
                "word_type": "n.",
                "spelling_usage": "To secure to, as a purchaser of goods, the title to the\n   same; to indemnify against loss."
            },
            {
                "spelling_detail_id": 159563,
                "word_type": "n.",
                "spelling_usage": "To secure to, as a purchaser, the quality or quantity of\n   the goods sold, as represented. See Warranty, n., 2."
            },
            {
                "spelling_detail_id": 159564,
                "word_type": "n.",
                "spelling_usage": "To assure, as a thing sold, to the purchaser; that is, to\n   engage that the thing is what it appears, or is represented, to be,\n   which implies a covenant to make good any defect or loss incurred by\n   it."
            }
        ]
    },
    {
        "id": 101343,
        "spelling_name": "Wean",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 160312,
                "word_type": "a.",
                "spelling_usage": "To accustom and reconcile, as a child or other young animal,\n   to a want or deprivation of mother's milk; to take from the breast or\n   udder; to cause to cease to depend on the mother nourishment."
            },
            {
                "spelling_detail_id": 160313,
                "word_type": "a.",
                "spelling_usage": "Hence, to detach or alienate the affections of, from any\n   object of desire; to reconcile to the want or loss of anything."
            },
            {
                "spelling_detail_id": 160314,
                "word_type": "n.",
                "spelling_usage": "A weanling; a young child."
            }
        ]
    },
    {
        "id": 101481,
        "spelling_name": "Weigh",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 160596,
                "word_type": "n.",
                "spelling_usage": "A corruption of Way, used only in the phrase under weigh."
            },
            {
                "spelling_detail_id": 160599,
                "word_type": "v. t.",
                "spelling_usage": "To bear up; to raise; to lift into the air; to swing up;\n   as, to weigh anchor."
            },
            {
                "spelling_detail_id": 160600,
                "word_type": "v. t.",
                "spelling_usage": "To examine by the balance; to ascertain the weight of,\n   that is, the force with which a thing tends to the center of the earth;\n   to determine the heaviness, or quantity of matter of; as, to weigh\n   sugar; to weigh gold."
            },
            {
                "spelling_detail_id": 160601,
                "word_type": "v. t.",
                "spelling_usage": "To be equivalent to in weight; to counterbalance; to have\n   the heaviness of."
            },
            {
                "spelling_detail_id": 160602,
                "word_type": "v. t.",
                "spelling_usage": "To pay, allot, take, or give by weight."
            },
            {
                "spelling_detail_id": 160603,
                "word_type": "v. t.",
                "spelling_usage": "To examine or test as if by the balance; to ponder in the\n   mind; to consider or examine for the purpose of forming an opinion or\n   coming to a conclusion; to estimate deliberately and maturely; to\n   balance."
            },
            {
                "spelling_detail_id": 160604,
                "word_type": "v. t.",
                "spelling_usage": "To consider as worthy of notice; to regard."
            },
            {
                "spelling_detail_id": 160605,
                "word_type": "v. i.",
                "spelling_usage": "To have weight; to be heavy."
            },
            {
                "spelling_detail_id": 160606,
                "word_type": "v. i.",
                "spelling_usage": "To be considered as important; to have weight in the\n   intellectual balance."
            },
            {
                "spelling_detail_id": 160607,
                "word_type": "v. i.",
                "spelling_usage": "To bear heavily; to press hard."
            },
            {
                "spelling_detail_id": 160608,
                "word_type": "v. i.",
                "spelling_usage": "To judge; to estimate."
            },
            {
                "spelling_detail_id": 160609,
                "word_type": "n.",
                "spelling_usage": "A certain quantity estimated by weight; an English measure\n   of weight. See Wey."
            }
        ]
    },
    {
        "id": 101582,
        "spelling_name": "Welter",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 160787,
                "word_type": "v. i.",
                "spelling_usage": "To roll, as the body of an animal; to tumble about,\n   especially in anything foul or defiling; to wallow."
            },
            {
                "spelling_detail_id": 160788,
                "word_type": "v. i.",
                "spelling_usage": "To rise and fall, as waves; to tumble over, as billows."
            },
            {
                "spelling_detail_id": 160789,
                "word_type": "v. i.",
                "spelling_usage": "To wither; to wilt."
            },
            {
                "spelling_detail_id": 160790,
                "word_type": "a.",
                "spelling_usage": "Of, pertaining to, or designating, the most heavily\n   weighted race in a meeting; as, a welter race; the welter stakes."
            },
            {
                "spelling_detail_id": 160791,
                "word_type": "n.",
                "spelling_usage": "That in which any person or thing welters, or wallows;\n   filth; mire; slough."
            },
            {
                "spelling_detail_id": 160792,
                "word_type": "n.",
                "spelling_usage": "A rising or falling, as of waves; as, the welter of the\n   billows; the welter of a tempest."
            }
        ]
    },
    {
        "id": 101594,
        "spelling_name": "Wend",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 160807,
                "word_type": "",
                "spelling_usage": "p. p. of Wene."
            },
            {
                "spelling_detail_id": 160811,
                "word_type": "v. i.",
                "spelling_usage": "To go; to pass; to betake one's self."
            },
            {
                "spelling_detail_id": 160812,
                "word_type": "v. i.",
                "spelling_usage": "To turn round."
            },
            {
                "spelling_detail_id": 160813,
                "word_type": "v. t.",
                "spelling_usage": "To direct; to betake; -- used chiefly in the phrase to\n   wend one's way. Also used reflexively."
            },
            {
                "spelling_detail_id": 160814,
                "word_type": "n.",
                "spelling_usage": "A large extent of ground; a perambulation; a circuit."
            }
        ]
    },
    {
        "id": 101853,
        "spelling_name": "Whimsical",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 161258,
                "word_type": "a.",
                "spelling_usage": "Full of, or characterized by, whims; actuated by a whim;\n   having peculiar notions; queer; strange; freakish."
            },
            {
                "spelling_detail_id": 161259,
                "word_type": "a.",
                "spelling_usage": "Odd or fantastic in appearance; quaintly devised;\n   fantastic."
            }
        ]
    },
    {
        "id": 102187,
        "spelling_name": "Wile",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 161859,
                "word_type": "n.",
                "spelling_usage": "A trick or stratagem practiced for insnaring or deception; a\n   sly, insidious; artifice; a beguilement; an allurement."
            },
            {
                "spelling_detail_id": 161860,
                "word_type": "v. t.",
                "spelling_usage": "To practice artifice upon; to deceive; to beguile; to\n   allure."
            },
            {
                "spelling_detail_id": 161861,
                "word_type": "v. t.",
                "spelling_usage": "To draw or turn away, as by diversion; to while or while\n   away; to cause to pass pleasantly."
            }
        ]
    },
    {
        "id": 102235,
        "spelling_name": "Wince",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 161959,
                "word_type": "v. i.",
                "spelling_usage": "To shrink, as from a blow, or from pain; to flinch; to\n   start back."
            },
            {
                "spelling_detail_id": 161960,
                "word_type": "v. i.",
                "spelling_usage": "To kick or flounce when unsteady, or impatient at a\n   rider; as, a horse winces."
            },
            {
                "spelling_detail_id": 161961,
                "word_type": "n.",
                "spelling_usage": "The act of one who winces."
            },
            {
                "spelling_detail_id": 161962,
                "word_type": "n.",
                "spelling_usage": "A reel used in dyeing, steeping, or washing cloth; a winch.\n   It is placed over the division wall between two wince pits so as to\n   allow the cloth to descend into either compartment. at will."
            }
        ]
    },
    {
        "id": 102602,
        "spelling_name": "Woo",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 162634,
                "word_type": "v. t.",
                "spelling_usage": "To solicit in love; to court."
            },
            {
                "spelling_detail_id": 162635,
                "word_type": "v. t.",
                "spelling_usage": "To court solicitously; to invite with importunity."
            },
            {
                "spelling_detail_id": 162636,
                "word_type": "v. i.",
                "spelling_usage": "To court; to make love."
            }
        ]
    },
    {
        "id": 102943,
        "spelling_name": "Writ",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 163221,
                "word_type": "obs.",
                "spelling_usage": "3d pers. sing. pres. of Write, for writeth."
            },
            {
                "spelling_detail_id": 163222,
                "word_type": "",
                "spelling_usage": "imp. & p. p. of Write."
            },
            {
                "spelling_detail_id": 163223,
                "word_type": "n.",
                "spelling_usage": "That which is written; writing; scripture; -- applied\n   especially to the Scriptures, or the books of the Old and New\n   testaments; as, sacred writ."
            },
            {
                "spelling_detail_id": 163224,
                "word_type": "n.",
                "spelling_usage": "An instrument in writing, under seal, in an epistolary form,\n   issued from the proper authority, commanding the performance or\n   nonperformance of some act by the person to whom it is directed; as, a\n   writ of entry, of error, of execution, of injunction, of mandamus, of\n   return, of summons, and the like."
            },
            {
                "spelling_detail_id": 163230,
                "word_type": "Archaic imp. & p. p.",
                "spelling_usage": "of Write"
            }
        ]
    },
    {
        "id": 103192,
        "spelling_name": "Yarn",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 163564,
                "word_type": "n.",
                "spelling_usage": "Spun wool; woolen thread; also, thread of other material, as\n   of cotton, flax, hemp, or silk; material spun and prepared for use in\n   weaving, knitting, manufacturing sewing thread, or the like."
            },
            {
                "spelling_detail_id": 163565,
                "word_type": "n.",
                "spelling_usage": "One of the threads of which the strands of a rope are\n   composed."
            },
            {
                "spelling_detail_id": 163566,
                "word_type": "n.",
                "spelling_usage": "A story told by a sailor for the amusement of his companions;\n   a story or tale; as, to spin a yarn."
            }
        ]
    },
    {
        "id": 103291,
        "spelling_name": "Yeoman",
        "image_path": null,
        "bee_type": 4,
        "spellingDetails": [
            {
                "spelling_detail_id": 163719,
                "word_type": "n.",
                "spelling_usage": "A common man, or one of the commonly of the first or most\n   respectable class; a freeholder; a man free born."
            },
            {
                "spelling_detail_id": 163720,
                "word_type": "n.",
                "spelling_usage": "A servant; a retainer."
            },
            {
                "spelling_detail_id": 163721,
                "word_type": "n.",
                "spelling_usage": "A yeoman of the guard; also, a member of the yeomanry\n   cavalry."
            },
            {
                "spelling_detail_id": 163722,
                "word_type": "n.",
                "spelling_usage": "An interior officer under the boatswain, gunner, or\n   carpenters, charged with the stowage, account, and distribution of the\n   stores."
            }
        ]
    }
]