export const BeeList1 =[
        {
            "id": 255,
            "spelling_name": "Background",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 187,
                    "word_type": "n.",
                    "spelling_usage": "Ground in the rear or behind, or in the distance, as\n   opposed to the foreground, or the ground in front."
                },
                {
                    "spelling_detail_id": 188,
                    "word_type": "n.",
                    "spelling_usage": "The space which is behind and subordinate to a portrait\n   or group of figures."
                },
                {
                    "spelling_detail_id": 189,
                    "word_type": "n.",
                    "spelling_usage": "Anything behind, serving as a foil; as, the statue had\n   a background of red hangings."
                },
                {
                    "spelling_detail_id": 190,
                    "word_type": "n.",
                    "spelling_usage": "A place in obscurity or retirement, or out of sight."
                }
            ]
        },
        {
            "id": 330,
            "spelling_name": "Badger",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 303,
                    "word_type": "n.",
                    "spelling_usage": "An itinerant licensed dealer in commodities used for food;\n   a hawker; a huckster; -- formerly applied especially to one who bought\n   grain in one place and sold it in another."
                },
                {
                    "spelling_detail_id": 304,
                    "word_type": "n.",
                    "spelling_usage": "A carnivorous quadruped of the genus Meles or of an allied\n   genus. It is a burrowing animal, with short, thick legs, and long claws\n   on the fore feet. One species (M. vulgaris), called also brock,\n   inhabits the north of Europe and Asia; another species (Taxidea\n   Americana / Labradorica) inhabits the northern parts of North America.\n   See Teledu."
                },
                {
                    "spelling_detail_id": 305,
                    "word_type": "n.",
                    "spelling_usage": "A brush made of badgers' hair, used by artists."
                },
                {
                    "spelling_detail_id": 308,
                    "word_type": "v. t.",
                    "spelling_usage": "To tease or annoy, as a badger when baited; to worry or\n   irritate persistently."
                },
                {
                    "spelling_detail_id": 309,
                    "word_type": "v. t.",
                    "spelling_usage": "To beat down; to cheapen; to barter; to bargain."
                }
            ]
        },
        {
            "id": 368,
            "spelling_name": "Bagpipe",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 378,
                    "word_type": "n.",
                    "spelling_usage": "A musical wind instrument, now used chiefly in the\n   Highlands of Scotland."
                },
                {
                    "spelling_detail_id": 379,
                    "word_type": "v. t.",
                    "spelling_usage": "To make to look like a bagpipe."
                }
            ]
        },
        {
            "id": 387,
            "spelling_name": "Bailiff",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 422,
                    "word_type": "n.",
                    "spelling_usage": "Originally, a person put in charge of something\n   especially, a chief officer, magistrate, or keeper, as of a county,\n   town, hundred, or castle; one to whom power/ of custody or care are\n   intrusted."
                },
                {
                    "spelling_detail_id": 423,
                    "word_type": "n.",
                    "spelling_usage": "A sheriff's deputy, appointed to make arrests, collect\n   fines, summon juries, etc."
                },
                {
                    "spelling_detail_id": 424,
                    "word_type": "n.",
                    "spelling_usage": "An overseer or under steward of an estate, who directs\n   husbandry operations, collects rents, etc."
                }
            ]
        },
        {
            "id": 414,
            "spelling_name": "Bakery",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 467,
                    "word_type": "n.",
                    "spelling_usage": "The trade of a baker."
                },
                {
                    "spelling_detail_id": 468,
                    "word_type": "n.",
                    "spelling_usage": "The place for baking bread; a bakehouse."
                }
            ]
        },
        {
            "id": 449,
            "spelling_name": "Balderdash",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 535,
                    "word_type": "n.",
                    "spelling_usage": "A worthless mixture, especially of liquors."
                },
                {
                    "spelling_detail_id": 536,
                    "word_type": "n.",
                    "spelling_usage": "Senseless jargon; ribaldry; nonsense; trash."
                },
                {
                    "spelling_detail_id": 537,
                    "word_type": "v. t.",
                    "spelling_usage": "To mix or adulterate, as liquors."
                }
            ]
        },
        {
            "id": 482,
            "spelling_name": "Ballad",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 606,
                    "word_type": "n.",
                    "spelling_usage": "A popular kind of narrative poem, adapted for recitation or\n   singing; as, the ballad of Chevy Chase; esp., a sentimental or romantic\n   poem in short stanzas."
                },
                {
                    "spelling_detail_id": 607,
                    "word_type": "v. i.",
                    "spelling_usage": "To make or sing ballads."
                },
                {
                    "spelling_detail_id": 608,
                    "word_type": "v. t.",
                    "spelling_usage": "To make mention of in ballads."
                }
            ]
        },
        {
            "id": 562,
            "spelling_name": "Banana",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 737,
                    "word_type": "n.",
                    "spelling_usage": "A perennial herbaceous plant of almost treelike size (Musa\n   sapientum); also, its edible fruit. See Musa."
                }
            ]
        },
        {
            "id": 753,
            "spelling_name": "Barefoot",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 1123,
                    "word_type": "a. & adv.",
                    "spelling_usage": "With the feet bare; without shoes or stockings."
                }
            ]
        },
        {
            "id": 988,
            "spelling_name": "Bask",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 1516,
                    "word_type": "v. t.",
                    "spelling_usage": "To lie in warmth; to be exposed to genial heat."
                },
                {
                    "spelling_detail_id": 1517,
                    "word_type": "v. t.",
                    "spelling_usage": "To warm by continued exposure to heat; to warm with genial\n   heat."
                }
            ]
        },
        {
            "id": 1141,
            "spelling_name": "Bauble",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 1807,
                    "word_type": "n.",
                    "spelling_usage": "A trifling piece of finery; a gewgaw; that which is gay and\n   showy without real value; a cheap, showy plaything."
                },
                {
                    "spelling_detail_id": 1808,
                    "word_type": "n.",
                    "spelling_usage": "The fool's club."
                }
            ]
        },
        {
            "id": 1165,
            "spelling_name": "Bawl",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 1841,
                    "word_type": "v. i.",
                    "spelling_usage": "To cry out with a loud, full sound; to cry with vehemence,\n   as in calling or exultation; to shout; to vociferate."
                },
                {
                    "spelling_detail_id": 1842,
                    "word_type": "v. i.",
                    "spelling_usage": "To cry loudly, as a child from pain or vexation."
                },
                {
                    "spelling_detail_id": 1843,
                    "word_type": "v. t.",
                    "spelling_usage": "To proclaim with a loud voice, or by outcry, as a hawker\n   or town-crier does."
                },
                {
                    "spelling_detail_id": 1844,
                    "word_type": "n.",
                    "spelling_usage": "A loud, prolonged cry; an outcry."
                }
            ]
        },
        {
            "id": 1234,
            "spelling_name": "Beagle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 1977,
                    "word_type": "n.",
                    "spelling_usage": "A small hound, or hunting dog, twelve to fifteen inches\n   high, used in hunting hares and other small game. See Illustration in\n   Appendix."
                },
                {
                    "spelling_detail_id": 1978,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: A spy or detective; a constable."
                }
            ]
        },
        {
            "id": 1434,
            "spelling_name": "Bedlam",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 2388,
                    "word_type": "n.",
                    "spelling_usage": "A place appropriated to the confinement and care of the\n   insane; a madhouse."
                },
                {
                    "spelling_detail_id": 2389,
                    "word_type": "n.",
                    "spelling_usage": "An insane person; a lunatic; a madman."
                },
                {
                    "spelling_detail_id": 2390,
                    "word_type": "n.",
                    "spelling_usage": "Any place where uproar and confusion prevail."
                },
                {
                    "spelling_detail_id": 2391,
                    "word_type": "a.",
                    "spelling_usage": "Belonging to, or fit for, a madhouse."
                }
            ]
        },
        {
            "id": 1563,
            "spelling_name": "Began",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 2613,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Begin"
                }
            ]
        },
        {
            "id": 1754,
            "spelling_name": "Belle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 2870,
                    "word_type": "n.",
                    "spelling_usage": "A young lady of superior beauty and attractions; a handsome\n   lady, or one who attracts notice in society; a fair lady."
                }
            ]
        },
        {
            "id": 2074,
            "spelling_name": "Beseech",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 3382,
                    "word_type": "v. t.",
                    "spelling_usage": "To ask or entreat with urgency; to supplicate; to\n   implore."
                },
                {
                    "spelling_detail_id": 3383,
                    "word_type": "n.",
                    "spelling_usage": "Solicitation; supplication."
                }
            ]
        },
        {
            "id": 2190,
            "spelling_name": "Bestow",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 3550,
                    "word_type": "v. t.",
                    "spelling_usage": "To lay up in store; to deposit for safe keeping; to\n   stow; to place; to put."
                },
                {
                    "spelling_detail_id": 3551,
                    "word_type": "v. t.",
                    "spelling_usage": "To use; to apply; to devote, as time or strength in some\n   occupation."
                },
                {
                    "spelling_detail_id": 3552,
                    "word_type": "v. t.",
                    "spelling_usage": "To expend, as money."
                },
                {
                    "spelling_detail_id": 3553,
                    "word_type": "v. t.",
                    "spelling_usage": "To give or confer; to impart; -- with on or upon."
                },
                {
                    "spelling_detail_id": 3554,
                    "word_type": "v. t.",
                    "spelling_usage": "To give in marriage."
                },
                {
                    "spelling_detail_id": 3555,
                    "word_type": "v. t.",
                    "spelling_usage": "To demean; to conduct; to behave; -- followed by a\n   reflexive pronoun."
                }
            ]
        },
        {
            "id": 2278,
            "spelling_name": "Better",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 3676,
                    "word_type": "a.",
                    "spelling_usage": "Having good qualities in a greater degree than another; as,\n   a better man; a better physician; a better house; a better air."
                },
                {
                    "spelling_detail_id": 3677,
                    "word_type": "a.",
                    "spelling_usage": "Preferable in regard to rank, value, use, fitness,\n   acceptableness, safety, or in any other respect."
                },
                {
                    "spelling_detail_id": 3678,
                    "word_type": "a.",
                    "spelling_usage": "Greater in amount; larger; more."
                },
                {
                    "spelling_detail_id": 3679,
                    "word_type": "a.",
                    "spelling_usage": "Improved in health; less affected with disease; as, the\n   patient is better."
                },
                {
                    "spelling_detail_id": 3680,
                    "word_type": "a.",
                    "spelling_usage": "More advanced; more perfect; as, upon better acquaintance;\n   a better knowledge of the subject."
                },
                {
                    "spelling_detail_id": 3681,
                    "word_type": "n.",
                    "spelling_usage": "Advantage, superiority, or victory; -- usually with of; as,\n   to get the better of an enemy."
                },
                {
                    "spelling_detail_id": 3682,
                    "word_type": "n.",
                    "spelling_usage": "One who has a claim to precedence; a superior, as in merit,\n   social standing, etc.; -- usually in the plural."
                },
                {
                    "spelling_detail_id": 3683,
                    "word_type": "compar.",
                    "spelling_usage": "In a superior or more excellent manner; with more\n   skill and wisdom, courage, virtue, advantage, or success; as, Henry\n   writes better than John; veterans fight better than recruits."
                },
                {
                    "spelling_detail_id": 3684,
                    "word_type": "compar.",
                    "spelling_usage": "More correctly or thoroughly."
                },
                {
                    "spelling_detail_id": 3685,
                    "word_type": "compar.",
                    "spelling_usage": "In a higher or greater degree; more; as, to love one\n   better than another."
                },
                {
                    "spelling_detail_id": 3686,
                    "word_type": "compar.",
                    "spelling_usage": "More, in reference to value, distance, time, etc.; as,\n   ten miles and better."
                },
                {
                    "spelling_detail_id": 3689,
                    "word_type": "a.",
                    "spelling_usage": "To improve or ameliorate; to increase the good qualities\n   of."
                },
                {
                    "spelling_detail_id": 3690,
                    "word_type": "a.",
                    "spelling_usage": "To improve the condition of, morally, physically,\n   financially, socially, or otherwise."
                },
                {
                    "spelling_detail_id": 3691,
                    "word_type": "a.",
                    "spelling_usage": "To surpass in excellence; to exceed; to excel."
                },
                {
                    "spelling_detail_id": 3692,
                    "word_type": "a.",
                    "spelling_usage": "To give advantage to; to support; to advance the interest\n   of."
                },
                {
                    "spelling_detail_id": 3693,
                    "word_type": "v. i.",
                    "spelling_usage": "To become better; to improve."
                },
                {
                    "spelling_detail_id": 3694,
                    "word_type": "n.",
                    "spelling_usage": "One who bets or lays a wager."
                }
            ]
        },
        {
            "id": 2380,
            "spelling_name": "Bias",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 3841,
                    "word_type": "n.",
                    "spelling_usage": "A weight on the side of the ball used in the game of bowls,\n   or a tendency imparted to the ball, which turns it from a straight\n   line."
                },
                {
                    "spelling_detail_id": 3842,
                    "word_type": "n.",
                    "spelling_usage": "A leaning of the mind; propensity or prepossession toward an\n   object or view, not leaving the mind indifferent; bent; inclination."
                },
                {
                    "spelling_detail_id": 3843,
                    "word_type": "n.",
                    "spelling_usage": "A wedge-shaped piece of cloth taken out of a garment (as the\n   waist of a dress) to diminish its circumference."
                },
                {
                    "spelling_detail_id": 3844,
                    "word_type": "n.",
                    "spelling_usage": "A slant; a diagonal; as, to cut cloth on the bias."
                },
                {
                    "spelling_detail_id": 3845,
                    "word_type": "a.",
                    "spelling_usage": "Inclined to one side; swelled on one side."
                },
                {
                    "spelling_detail_id": 3846,
                    "word_type": "a.",
                    "spelling_usage": "Cut slanting or diagonally, as cloth."
                },
                {
                    "spelling_detail_id": 3847,
                    "word_type": "adv.",
                    "spelling_usage": "In a slanting manner; crosswise; obliquely; diagonally; as,\n   to cut cloth bias."
                },
                {
                    "spelling_detail_id": 3850,
                    "word_type": "v. t.",
                    "spelling_usage": "To incline to one side; to give a particular direction to;\n   to influence; to prejudice; to prepossess."
                }
            ]
        },
        {
            "id": 2955,
            "spelling_name": "Bite",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 4670,
                    "word_type": "v. t.",
                    "spelling_usage": "To seize with the teeth, so that they enter or nip the\n   thing seized; to lacerate, crush, or wound with the teeth; as, to bite\n   an apple; to bite a crust; the dog bit a man."
                },
                {
                    "spelling_detail_id": 4671,
                    "word_type": "v. t.",
                    "spelling_usage": "To puncture, abrade, or sting with an organ (of some\n   insects) used in taking food."
                },
                {
                    "spelling_detail_id": 4672,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause sharp pain, or smarting, to; to hurt or injure,\n   in a literal or a figurative sense; as, pepper bites the mouth."
                },
                {
                    "spelling_detail_id": 4673,
                    "word_type": "v. t.",
                    "spelling_usage": "To cheat; to trick; to take in."
                },
                {
                    "spelling_detail_id": 4674,
                    "word_type": "v. t.",
                    "spelling_usage": "To take hold of; to hold fast; to adhere to; as, the\n   anchor bites the ground."
                },
                {
                    "spelling_detail_id": 4675,
                    "word_type": "v. i.",
                    "spelling_usage": "To seize something forcibly with the teeth; to wound with\n   the teeth; to have the habit of so doing; as, does the dog bite?"
                },
                {
                    "spelling_detail_id": 4676,
                    "word_type": "v. i.",
                    "spelling_usage": "To cause a smarting sensation; to have a property which\n   causes such a sensation; to be pungent; as, it bites like pepper or\n   mustard."
                },
                {
                    "spelling_detail_id": 4677,
                    "word_type": "v. i.",
                    "spelling_usage": "To cause sharp pain; to produce anguish; to hurt or\n   injure; to have the property of so doing."
                },
                {
                    "spelling_detail_id": 4678,
                    "word_type": "v. i.",
                    "spelling_usage": "To take a bait into the mouth, as a fish does; hence, to\n   take a tempting offer."
                },
                {
                    "spelling_detail_id": 4679,
                    "word_type": "v. i.",
                    "spelling_usage": "To take or keep a firm hold; as, the anchor bites."
                },
                {
                    "spelling_detail_id": 4680,
                    "word_type": "v.",
                    "spelling_usage": "The act of seizing with the teeth or mouth; the act of\n   wounding or separating with the teeth or mouth; a seizure with the\n   teeth or mouth, as of a bait; as, to give anything a hard bite."
                },
                {
                    "spelling_detail_id": 4681,
                    "word_type": "v.",
                    "spelling_usage": "The act of puncturing or abrading with an organ for taking\n   food, as is done by some insects."
                },
                {
                    "spelling_detail_id": 4682,
                    "word_type": "v.",
                    "spelling_usage": "The wound made by biting; as, the pain of a dog's or snake's\n   bite; the bite of a mosquito."
                },
                {
                    "spelling_detail_id": 4683,
                    "word_type": "v.",
                    "spelling_usage": "A morsel; as much as is taken at once by biting."
                },
                {
                    "spelling_detail_id": 4684,
                    "word_type": "v.",
                    "spelling_usage": "The hold which the short end of a lever has upon the thing to\n   be lifted, or the hold which one part of a machine has upon another."
                },
                {
                    "spelling_detail_id": 4685,
                    "word_type": "v.",
                    "spelling_usage": "A cheat; a trick; a fraud."
                },
                {
                    "spelling_detail_id": 4686,
                    "word_type": "v.",
                    "spelling_usage": "A sharper; one who cheats."
                },
                {
                    "spelling_detail_id": 4687,
                    "word_type": "v.",
                    "spelling_usage": "A blank on the edge or corner of a page, owing to a portion\n   of the frisket, or something else, intervening between the type and\n   paper."
                }
            ]
        },
        {
            "id": 3243,
            "spelling_name": "Bleary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 5177,
                    "word_type": "a.",
                    "spelling_usage": "Somewhat blear."
                }
            ]
        },
        {
            "id": 3257,
            "spelling_name": "Blemish",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 5208,
                    "word_type": "v. t.",
                    "spelling_usage": "To mark with deformity; to injure or impair, as\n   anything which is well formed, or excellent; to mar, or make defective,\n   either the body or mind."
                },
                {
                    "spelling_detail_id": 5209,
                    "word_type": "v. t.",
                    "spelling_usage": "To tarnish, as reputation or character; to defame."
                },
                {
                    "spelling_detail_id": 5211,
                    "word_type": "n.",
                    "spelling_usage": "Any mark of deformity or injury, whether physical or\n   moral; anything that diminishes beauty, or renders imperfect that which\n   is otherwise well formed; that which impairs reputation."
                }
            ]
        },
        {
            "id": 3347,
            "spelling_name": "Blizzard",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 5381,
                    "word_type": "n.",
                    "spelling_usage": "A gale of piercingly cold wind, usually accompanied with\n   fine and blinding snow; a furious blast."
                }
            ]
        },
        {
            "id": 3692,
            "spelling_name": "Bogus",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6057,
                    "word_type": "a.",
                    "spelling_usage": "Spurious; fictitious; sham; -- a cant term originally\n   applied to counterfeit coin, and hence denoting anything counterfeit."
                },
                {
                    "spelling_detail_id": 6058,
                    "word_type": "n.",
                    "spelling_usage": "A liquor made of rum and molasses."
                }
            ]
        },
        {
            "id": 3771,
            "spelling_name": "Bombard",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6235,
                    "word_type": "n.",
                    "spelling_usage": "A piece of heavy ordnance formerly used for throwing\n   stones and other ponderous missiles. It was the earliest kind of\n   cannon."
                },
                {
                    "spelling_detail_id": 6236,
                    "word_type": "n.",
                    "spelling_usage": "A bombardment."
                },
                {
                    "spelling_detail_id": 6237,
                    "word_type": "n.",
                    "spelling_usage": "A large drinking vessel or can, or a leather bottle, for\n   carrying liquor or beer."
                },
                {
                    "spelling_detail_id": 6238,
                    "word_type": "n.",
                    "spelling_usage": "Padded breeches."
                },
                {
                    "spelling_detail_id": 6239,
                    "word_type": "n.",
                    "spelling_usage": "See Bombardo."
                },
                {
                    "spelling_detail_id": 6242,
                    "word_type": "v. t.",
                    "spelling_usage": "To attack with bombards or with artillery; especially,\n   to throw shells, hot shot, etc., at or into."
                }
            ]
        },
        {
            "id": 3851,
            "spelling_name": "Bonfire",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6358,
                    "word_type": "n.",
                    "spelling_usage": "A large fire built in the open air, as an expression of\n   public joy and exultation, or for amusement."
                }
            ]
        },
        {
            "id": 3934,
            "spelling_name": "Bookworm",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6489,
                    "word_type": "n.",
                    "spelling_usage": "Any larva of a beetle or moth, which is injurious to\n   books. Many species are known."
                },
                {
                    "spelling_detail_id": 6490,
                    "word_type": "n.",
                    "spelling_usage": "A student closely attached to books or addicted to study;\n   a reader without appreciation."
                }
            ]
        },
        {
            "id": 3938,
            "spelling_name": "Boom",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6494,
                    "word_type": "n.",
                    "spelling_usage": "A long pole or spar, run out for the purpose of extending the\n   bottom of a particular sail; as, the jib boom, the studding-sail boom,\n   etc."
                },
                {
                    "spelling_detail_id": 6495,
                    "word_type": "n.",
                    "spelling_usage": "A long spar or beam, projecting from the mast of a derrick,\n   from the outer end of which the body to be lifted is suspended."
                },
                {
                    "spelling_detail_id": 6496,
                    "word_type": "n.",
                    "spelling_usage": "A pole with a conspicuous top, set up to mark the channel in\n   a river or harbor."
                },
                {
                    "spelling_detail_id": 6497,
                    "word_type": "n.",
                    "spelling_usage": "A strong chain cable, or line of spars bound together,\n   extended across a river or the mouth of a harbor, to obstruct\n   navigation or passage."
                },
                {
                    "spelling_detail_id": 6498,
                    "word_type": "n.",
                    "spelling_usage": "A line of connected floating timbers stretched across a\n   river, or inclosing an area of water, to keep saw logs, etc., from\n   floating away."
                },
                {
                    "spelling_detail_id": 6499,
                    "word_type": "v. t.",
                    "spelling_usage": "To extend, or push, with a boom or pole; as, to boom out a\n   sail; to boom off a boat."
                },
                {
                    "spelling_detail_id": 6502,
                    "word_type": "v. i.",
                    "spelling_usage": "To cry with a hollow note; to make a hollow sound, as the\n   bittern, and some insects."
                },
                {
                    "spelling_detail_id": 6503,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a hollow sound, as of waves or cannon."
                },
                {
                    "spelling_detail_id": 6504,
                    "word_type": "v. i.",
                    "spelling_usage": "To rush with violence and noise, as a ship under a press\n   of sail, before a free wind."
                },
                {
                    "spelling_detail_id": 6505,
                    "word_type": "v. i.",
                    "spelling_usage": "To have a rapid growth in market value or in popular\n   favor; to go on rushingly."
                },
                {
                    "spelling_detail_id": 6506,
                    "word_type": "n.",
                    "spelling_usage": "A hollow roar, as of waves or cannon; also, the hollow cry of\n   the bittern; a booming."
                },
                {
                    "spelling_detail_id": 6507,
                    "word_type": "n.",
                    "spelling_usage": "A strong and extensive advance, with more or less noisy\n   excitement; -- applied colloquially or humorously to market prices, the\n   demand for stocks or commodities and to political chances of aspirants\n   to office; as, a boom in the stock market; a boom in coffee."
                },
                {
                    "spelling_detail_id": 6508,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause to advance rapidly in price; as, to boom railroad\n   or mining shares; to create a \"boom\" for; as to boom Mr. C. for\n   senator."
                }
            ]
        },
        {
            "id": 3949,
            "spelling_name": "Boorish",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6530,
                    "word_type": "a.",
                    "spelling_usage": "Like a boor; clownish; uncultured; unmannerly."
                }
            ]
        },
        {
            "id": 4026,
            "spelling_name": "Born",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 2040,
                    "word_type": "p. p.",
                    "spelling_usage": "of Bear"
                },
                {
                    "spelling_detail_id": 6671,
                    "word_type": "v. t.",
                    "spelling_usage": "Brought forth, as an animal; brought into life; introduced\n   by birth."
                },
                {
                    "spelling_detail_id": 6672,
                    "word_type": "v. t.",
                    "spelling_usage": "Having from birth a certain character; by or from birth;\n   by nature; innate; as, a born liar."
                }
            ]
        },
        {
            "id": 4102,
            "spelling_name": "Botch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6790,
                    "word_type": "n.",
                    "spelling_usage": "A swelling on the skin; a large ulcerous affection; a boil;\n   an eruptive disease."
                },
                {
                    "spelling_detail_id": 6791,
                    "word_type": "n.",
                    "spelling_usage": "A patch put on, or a part of a garment patched or mended in\n   a clumsy manner."
                },
                {
                    "spelling_detail_id": 6792,
                    "word_type": "n.",
                    "spelling_usage": "Work done in a bungling manner; a clumsy performance; a\n   piece of work, or a place in work, marred in the doing, or not properly\n   finished; a bungle."
                },
                {
                    "spelling_detail_id": 6795,
                    "word_type": "n.",
                    "spelling_usage": "To mark with, or as with, botches."
                },
                {
                    "spelling_detail_id": 6796,
                    "word_type": "n.",
                    "spelling_usage": "To repair; to mend; esp. to patch in a clumsy or imperfect\n   manner, as a garment; -- sometimes with up."
                },
                {
                    "spelling_detail_id": 6797,
                    "word_type": "n.",
                    "spelling_usage": "To put together unsuitably or unskillfully; to express or\n   perform in a bungling manner; to spoil or mar, as by unskillful work."
                }
            ]
        },
        {
            "id": 4116,
            "spelling_name": "Bother",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6814,
                    "word_type": "v. t.",
                    "spelling_usage": "To annoy; to trouble; to worry; to perplex. See Pother."
                },
                {
                    "spelling_detail_id": 6815,
                    "word_type": "v. i.",
                    "spelling_usage": "To feel care or anxiety; to make or take trouble; to be\n   troublesome."
                },
                {
                    "spelling_detail_id": 6816,
                    "word_type": "n.",
                    "spelling_usage": "One who, or that which, bothers; state of perplexity or\n   annoyance; embarrassment; worry; disturbance; petty trouble; as, to be\n   in a bother."
                }
            ]
        },
        {
            "id": 4135,
            "spelling_name": "Bottle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 6839,
                    "word_type": "n.",
                    "spelling_usage": "A hollow vessel, usually of glass or earthenware (but\n   formerly of leather), with a narrow neck or mouth, for holding liquids."
                },
                {
                    "spelling_detail_id": 6840,
                    "word_type": "n.",
                    "spelling_usage": "The contents of a bottle; as much as a bottle contains; as,\n   to drink a bottle of wine."
                },
                {
                    "spelling_detail_id": 6841,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: Intoxicating liquor; as, to drown one's reason in the\n   bottle."
                },
                {
                    "spelling_detail_id": 6844,
                    "word_type": "v. t.",
                    "spelling_usage": "To put into bottles; to inclose in, or as in, a bottle\n   or bottles; to keep or restrain as in a bottle; as, to bottle wine or\n   porter; to bottle up one's wrath."
                },
                {
                    "spelling_detail_id": 6845,
                    "word_type": "n.",
                    "spelling_usage": "A bundle, esp. of hay."
                }
            ]
        },
        {
            "id": 4274,
            "spelling_name": "Bowl",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 7116,
                    "word_type": "n.",
                    "spelling_usage": "A concave vessel of various forms (often approximately\n   hemispherical), to hold liquids, etc."
                },
                {
                    "spelling_detail_id": 7117,
                    "word_type": "n.",
                    "spelling_usage": "Specifically, a drinking vessel for wine or other spirituous\n   liquors; hence, convivial drinking."
                },
                {
                    "spelling_detail_id": 7118,
                    "word_type": "n.",
                    "spelling_usage": "The contents of a full bowl; what a bowl will hold."
                },
                {
                    "spelling_detail_id": 7119,
                    "word_type": "n.",
                    "spelling_usage": "The hollow part of a thing; as, the bowl of a spoon."
                },
                {
                    "spelling_detail_id": 7120,
                    "word_type": "n.",
                    "spelling_usage": "A ball of wood or other material used for rolling on a level\n   surface in play; a ball of hard wood having one side heavier than the\n   other, so as to give it a bias when rolled."
                },
                {
                    "spelling_detail_id": 7121,
                    "word_type": "n.",
                    "spelling_usage": "An ancient game, popular in Great Britain, played with biased\n   balls on a level plat of greensward."
                },
                {
                    "spelling_detail_id": 7122,
                    "word_type": "n.",
                    "spelling_usage": "The game of tenpins or bowling."
                },
                {
                    "spelling_detail_id": 7125,
                    "word_type": "v. t.",
                    "spelling_usage": "To roll, as a bowl or cricket ball."
                },
                {
                    "spelling_detail_id": 7126,
                    "word_type": "v. t.",
                    "spelling_usage": "To roll or carry smoothly on, or as on, wheels; as, we\n   were bowled rapidly along the road."
                },
                {
                    "spelling_detail_id": 7127,
                    "word_type": "v. t.",
                    "spelling_usage": "To pelt or strike with anything rolled."
                },
                {
                    "spelling_detail_id": 7128,
                    "word_type": "v. i.",
                    "spelling_usage": "To play with bowls."
                },
                {
                    "spelling_detail_id": 7129,
                    "word_type": "v. i.",
                    "spelling_usage": "To roll a ball on a plane, as at cricket, bowls, etc."
                },
                {
                    "spelling_detail_id": 7130,
                    "word_type": "v. i.",
                    "spelling_usage": "To move rapidly, smoothly, and like a ball; as, the\n   carriage bowled along."
                }
            ]
        },
        {
            "id": 4320,
            "spelling_name": "Boycott",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 7221,
                    "word_type": "v. t.",
                    "spelling_usage": "To combine against (a landlord, tradesman, employer, or\n   other person), to withhold social or business relations from him, and\n   to deter others from holding such relations; to subject to a boycott."
                },
                {
                    "spelling_detail_id": 7222,
                    "word_type": "n.",
                    "spelling_usage": "The process, fact, or pressure of boycotting; a combining\n   to withhold or prevent dealing or social intercourse with a tradesman,\n   employer, etc.; social and business interdiction for the purpose of\n   coercion."
                }
            ]
        },
        {
            "id": 4740,
            "spelling_name": "Bribery",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8023,
                    "word_type": "n.",
                    "spelling_usage": "Robbery; extortion."
                },
                {
                    "spelling_detail_id": 8024,
                    "word_type": "n.",
                    "spelling_usage": "The act or practice of giving or taking bribes; the act of\n   influencing the official or political action of another by corrupt\n   inducements."
                }
            ]
        },
        {
            "id": 4743,
            "spelling_name": "Brick",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8026,
                    "word_type": "n.",
                    "spelling_usage": "A block or clay tempered with water, sand, etc., molded into\n   a regular form, usually rectangular, and sun-dried, or burnt in a kiln,\n   or in a heap or stack called a clamp."
                },
                {
                    "spelling_detail_id": 8027,
                    "word_type": "n.",
                    "spelling_usage": "Bricks, collectively, as designating that kind of material;\n   as, a load of brick; a thousand of brick."
                },
                {
                    "spelling_detail_id": 8028,
                    "word_type": "n.",
                    "spelling_usage": "Any oblong rectangular mass; as, a brick of maple sugar; a\n   penny brick (of bread)."
                },
                {
                    "spelling_detail_id": 8029,
                    "word_type": "n.",
                    "spelling_usage": "A good fellow; a merry person; as, you 're a brick."
                },
                {
                    "spelling_detail_id": 8032,
                    "word_type": "v. t.",
                    "spelling_usage": "To lay or pave with bricks; to surround, line, or\n   construct with bricks."
                },
                {
                    "spelling_detail_id": 8033,
                    "word_type": "v. t.",
                    "spelling_usage": "To imitate or counterfeit a brick wall on, as by smearing\n   plaster with red ocher, making the joints with an edge tool, and\n   pointing them."
                }
            ]
        },
        {
            "id": 4827,
            "spelling_name": "Brilliant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8173,
                    "word_type": "p. pr.",
                    "spelling_usage": "Sparkling with luster; glittering; very bright; as,\n   a brilliant star."
                },
                {
                    "spelling_detail_id": 8174,
                    "word_type": "p. pr.",
                    "spelling_usage": "Distinguished by qualities which excite admiration;\n   splendid; shining; as, brilliant talents."
                },
                {
                    "spelling_detail_id": 8175,
                    "word_type": "a.",
                    "spelling_usage": "A diamond or other gem of the finest cut, formed into\n   faces and facets, so as to reflect and refract the light, by which it\n   is rendered more brilliant. It has at the middle, or top, a principal\n   face, called the table, which is surrounded by a number of sloping\n   facets forming a bizet; below, it has a small face or collet, parallel\n   to the table, connected with the girdle by a pavilion of elongated\n   facets. It is thus distinguished from the rose diamond, which is\n   entirely covered with facets on the surface, and is flat below."
                },
                {
                    "spelling_detail_id": 8176,
                    "word_type": "a.",
                    "spelling_usage": "The smallest size of type used in England printing."
                },
                {
                    "spelling_detail_id": 8177,
                    "word_type": "a.",
                    "spelling_usage": "A kind of cotton goods, figured on the weaving."
                }
            ]
        },
        {
            "id": 4858,
            "spelling_name": "Bristle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8232,
                    "word_type": "n.",
                    "spelling_usage": "A short, stiff, coarse hair, as on the back of swine."
                },
                {
                    "spelling_detail_id": 8233,
                    "word_type": "n.",
                    "spelling_usage": "A stiff, sharp, roundish hair."
                },
                {
                    "spelling_detail_id": 8236,
                    "word_type": "v. t.",
                    "spelling_usage": "To erect the bristles of; to cause to stand up, as the\n   bristles of an angry hog; -- sometimes with up."
                },
                {
                    "spelling_detail_id": 8237,
                    "word_type": "v. t.",
                    "spelling_usage": "To fix a bristle to; as, to bristle a thread."
                },
                {
                    "spelling_detail_id": 8238,
                    "word_type": "v. i.",
                    "spelling_usage": "To rise or stand erect, like bristles."
                },
                {
                    "spelling_detail_id": 8239,
                    "word_type": "v. i.",
                    "spelling_usage": "To appear as if covered with bristles; to have\n   standing, thick and erect, like bristles."
                },
                {
                    "spelling_detail_id": 8240,
                    "word_type": "v. i.",
                    "spelling_usage": "To show defiance or indignation."
                }
            ]
        },
        {
            "id": 5162,
            "spelling_name": "Bubbly",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8731,
                    "word_type": "a.",
                    "spelling_usage": "Abounding in bubbles; bubbling."
                }
            ]
        },
        {
            "id": 5251,
            "spelling_name": "Buffoonery",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8923,
                    "word_type": "n.",
                    "spelling_usage": "The arts and practices of a buffoon, as low jests,\n   ridiculous pranks, vulgar tricks and postures."
                }
            ]
        },
        {
            "id": 5259,
            "spelling_name": "Bugaboo",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 8935,
                    "word_type": "n.",
                    "spelling_usage": "Alt. of Bugbear"
                }
            ]
        },
        {
            "id": 5612,
            "spelling_name": "Bushel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 9542,
                    "word_type": "n.",
                    "spelling_usage": "A dry measure, containing four pecks, eight gallons, or\n   thirty-two quarts."
                },
                {
                    "spelling_detail_id": 9543,
                    "word_type": "n.",
                    "spelling_usage": "A vessel of the capacity of a bushel, used in measuring; a\n   bushel measure."
                },
                {
                    "spelling_detail_id": 9544,
                    "word_type": "n.",
                    "spelling_usage": "A quantity that fills a bushel measure; as, a heap\n   containing ten bushels of apples."
                },
                {
                    "spelling_detail_id": 9545,
                    "word_type": "n.",
                    "spelling_usage": "A large indefinite quantity."
                },
                {
                    "spelling_detail_id": 9546,
                    "word_type": "n.",
                    "spelling_usage": "The iron lining in the nave of a wheel. [Eng.] In the\n   United States it is called a box. See 4th Bush."
                }
            ]
        },
        {
            "id": 5825,
            "spelling_name": "Cabbage",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 9891,
                    "word_type": "n.",
                    "spelling_usage": "An esculent vegetable of many varieties, derived from the\n   wild Brassica oleracea of Europe. The common cabbage has a compact head\n   of leaves. The cauliflower, Brussels sprouts, etc., are sometimes\n   classed as cabbages."
                },
                {
                    "spelling_detail_id": 9892,
                    "word_type": "n.",
                    "spelling_usage": "The terminal bud of certain palm trees, used, like,\n   cabbage, for food. See Cabbage tree, below."
                },
                {
                    "spelling_detail_id": 9893,
                    "word_type": "n.",
                    "spelling_usage": "The cabbage palmetto. See below."
                },
                {
                    "spelling_detail_id": 9894,
                    "word_type": "v. i.",
                    "spelling_usage": "To form a head like that the cabbage; as, to make\n   lettuce cabbage."
                },
                {
                    "spelling_detail_id": 9897,
                    "word_type": "v. i.",
                    "spelling_usage": "To purloin or embezzle, as the pieces of cloth\n   remaining after cutting out a garment; to pilfer."
                },
                {
                    "spelling_detail_id": 9898,
                    "word_type": "n.",
                    "spelling_usage": "Cloth or clippings cabbaged or purloined by one who cuts\n   out garments."
                }
            ]
        },
        {
            "id": 5924,
            "spelling_name": "Cactus",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 10030,
                    "word_type": "n.",
                    "spelling_usage": "Any plant of the order Cactacae, as the prickly pear and\n   the night-blooming cereus. See Cereus. They usually have leafless stems\n   and branches, often beset with clustered thorns, and are mostly natives\n   of the warmer parts of America."
                }
            ]
        },
        {
            "id": 6031,
            "spelling_name": "Cajole",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 10185,
                    "word_type": "v. i.",
                    "spelling_usage": "To deceive with flattery or fair words; to wheedle."
                }
            ]
        },
        {
            "id": 6040,
            "spelling_name": "Cake",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 10192,
                    "word_type": "n.",
                    "spelling_usage": "A small mass of dough baked; especially, a thin loaf from\n   unleavened dough; as, an oatmeal cake; johnnycake."
                },
                {
                    "spelling_detail_id": 10193,
                    "word_type": "n.",
                    "spelling_usage": "A sweetened composition of flour and other ingredients,\n   leavened or unleavened, baked in a loaf or mass of any size or shape."
                },
                {
                    "spelling_detail_id": 10194,
                    "word_type": "n.",
                    "spelling_usage": "A thin wafer-shaped mass of fried batter; a griddlecake or\n   pancake; as buckwheat cakes."
                },
                {
                    "spelling_detail_id": 10195,
                    "word_type": "n.",
                    "spelling_usage": "A mass of matter concreted, congealed, or molded into a solid\n   mass of any form, esp. into a form rather flat than high; as, a cake of\n   soap; an ague cake."
                },
                {
                    "spelling_detail_id": 10196,
                    "word_type": "v. i.",
                    "spelling_usage": "To form into a cake, or mass."
                },
                {
                    "spelling_detail_id": 10199,
                    "word_type": "v. i.",
                    "spelling_usage": "To concrete or consolidate into a hard mass, as dough in\n   an oven; to coagulate."
                },
                {
                    "spelling_detail_id": 10200,
                    "word_type": "v. i.",
                    "spelling_usage": "To cackle as a goose."
                }
            ]
        },
        {
            "id": 6187,
            "spelling_name": "Calico",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 10404,
                    "word_type": "n.",
                    "spelling_usage": "Plain white cloth made from cotton, but which receives\n   distinctive names according to quality and use, as, super calicoes,\n   shirting calicoes, unbleached calicoes, etc."
                },
                {
                    "spelling_detail_id": 10405,
                    "word_type": "n.",
                    "spelling_usage": "Cotton cloth printed with a figured pattern."
                },
                {
                    "spelling_detail_id": 10406,
                    "word_type": "a.",
                    "spelling_usage": "Made of, or having the appearance of, calico; -- often\n   applied to an animal, as a horse or cat, on whose body are large\n   patches of a color strikingly different from its main color."
                }
            ]
        },
        {
            "id": 6436,
            "spelling_name": "Campus",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 10770,
                    "word_type": "n.",
                    "spelling_usage": "The principal grounds of a college or school, between the\n   buildings or within the main inclosure; as, the college campus."
                }
            ]
        },
        {
            "id": 6630,
            "spelling_name": "Canteen",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11100,
                    "word_type": "n.",
                    "spelling_usage": "A vessel used by soldiers for carrying water, liquor, or\n   other drink."
                },
                {
                    "spelling_detail_id": 11101,
                    "word_type": "n.",
                    "spelling_usage": "The sutler's shop in a garrison; also, a chest containing\n   culinary and other vessels for officers."
                }
            ]
        },
        {
            "id": 6901,
            "spelling_name": "Caravan",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11549,
                    "word_type": "n.",
                    "spelling_usage": "A company of travelers, pilgrims, or merchants, organized\n   and equipped for a long journey, or marching or traveling together,\n   esp. through deserts and countries infested by robbers or hostile\n   tribes, as in Asia or Africa."
                },
                {
                    "spelling_detail_id": 11550,
                    "word_type": "n.",
                    "spelling_usage": "A large, covered wagon, or a train of such wagons, for\n   conveying wild beasts, etc., for exhibition; an itinerant show, as of\n   wild beasts."
                },
                {
                    "spelling_detail_id": 11551,
                    "word_type": "n.",
                    "spelling_usage": "A covered vehicle for carrying passengers or for moving\n   furniture, etc.; -- sometimes shorted into van."
                }
            ]
        },
        {
            "id": 7018,
            "spelling_name": "Career",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11714,
                    "word_type": "n.",
                    "spelling_usage": "A race course: the ground run over."
                },
                {
                    "spelling_detail_id": 11715,
                    "word_type": "n.",
                    "spelling_usage": "A running; full speed; a rapid course."
                },
                {
                    "spelling_detail_id": 11716,
                    "word_type": "n.",
                    "spelling_usage": "General course of action or conduct in life, or in a\n   particular part or calling in life, or in some special undertaking;\n   usually applied to course or conduct which is of a public character;\n   as, Washington's career as a soldier."
                },
                {
                    "spelling_detail_id": 11717,
                    "word_type": "n.",
                    "spelling_usage": "The flight of a hawk."
                },
                {
                    "spelling_detail_id": 11720,
                    "word_type": "v. i.",
                    "spelling_usage": "To move or run rapidly."
                }
            ]
        },
        {
            "id": 7022,
            "spelling_name": "Carefully",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11724,
                    "word_type": "adv.",
                    "spelling_usage": "In a careful manner."
                }
            ]
        },
        {
            "id": 7102,
            "spelling_name": "Carnation",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11832,
                    "word_type": "n.",
                    "spelling_usage": "The natural color of flesh; rosy pink."
                },
                {
                    "spelling_detail_id": 11833,
                    "word_type": "n.",
                    "spelling_usage": "Those parts of a picture in which the human body or any\n   part of it is represented in full color; the flesh tints."
                },
                {
                    "spelling_detail_id": 11834,
                    "word_type": "n.",
                    "spelling_usage": "A species of Dianthus (D. Caryophyllus) or pink, having\n   very beautiful flowers of various colors, esp. white and usually a\n   rich, spicy scent."
                }
            ]
        },
        {
            "id": 7112,
            "spelling_name": "Carnival",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11844,
                    "word_type": "n.",
                    "spelling_usage": "A festival celebrated with merriment and revelry in Roman\n   Gatholic countries during the week before Lent, esp. at Rome and\n   Naples, during a few days (three to ten) before Lent, ending with\n   Shrove Tuesday."
                },
                {
                    "spelling_detail_id": 11845,
                    "word_type": "n.",
                    "spelling_usage": "Any merrymaking, feasting, or masquerading, especially\n   when overstepping the bounds of decorum; a time of riotous excess."
                }
            ]
        },
        {
            "id": 7206,
            "spelling_name": "Carrot",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 11983,
                    "word_type": "n.",
                    "spelling_usage": "An umbelliferous biennial plant (Daucus Carota), of many\n   varieties."
                },
                {
                    "spelling_detail_id": 11984,
                    "word_type": "n.",
                    "spelling_usage": "The esculent root of cultivated varieties of the plant,\n   usually spindle-shaped, and of a reddish yellow color."
                }
            ]
        },
        {
            "id": 7243,
            "spelling_name": "Cartoon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 12058,
                    "word_type": "n.",
                    "spelling_usage": "A design or study drawn of the full size, to serve as a\n   model for transferring or copying; -- used in the making of mosaics,\n   tapestries, fresco pantings and the like; as, the cartoons of Raphael."
                },
                {
                    "spelling_detail_id": 12059,
                    "word_type": "n.",
                    "spelling_usage": "A large pictorial sketch, as in a journal or magazine;\n   esp. a pictorial caricature; as, the cartoons of \"Puck.\""
                }
            ]
        },
        {
            "id": 7283,
            "spelling_name": "Cascade",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 12128,
                    "word_type": "n.",
                    "spelling_usage": "A fall of water over a precipice, as in a river or brook;\n   a waterfall less than a cataract."
                },
                {
                    "spelling_detail_id": 12129,
                    "word_type": "v. i.",
                    "spelling_usage": "To fall in a cascade."
                },
                {
                    "spelling_detail_id": 12130,
                    "word_type": "v. i.",
                    "spelling_usage": "To vomit."
                }
            ]
        },
        {
            "id": 7320,
            "spelling_name": "Casino",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 12198,
                    "word_type": "n.",
                    "spelling_usage": "A small country house."
                },
                {
                    "spelling_detail_id": 12199,
                    "word_type": "n.",
                    "spelling_usage": "A building or room used for meetings, or public amusements,\n   for dancing, gaming, etc."
                },
                {
                    "spelling_detail_id": 12200,
                    "word_type": "n.",
                    "spelling_usage": "A game at cards. See Cassino."
                }
            ]
        },
        {
            "id": 7577,
            "spelling_name": "Caterpillar",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 12613,
                    "word_type": "n.",
                    "spelling_usage": "The larval state of a butterfly or any lepidopterous\n   insect; sometimes, but less commonly, the larval state of other\n   insects, as the sawflies, which are also called false caterpillars. The\n   true caterpillars have three pairs of true legs, and several pairs of\n   abdominal fleshy legs (prolegs) armed with hooks. Some are hairy,\n   others naked. They usually feed on leaves, fruit, and succulent\n   vegetables, being often very destructive, Many of them are popularly\n   called worms, as the cutworm, cankerworm, army worm, cotton worm,\n   silkworm."
                },
                {
                    "spelling_detail_id": 12614,
                    "word_type": "n.",
                    "spelling_usage": "A plant of the genus Scorpiurus, with pods resembling\n   caterpillars."
                }
            ]
        },
        {
            "id": 7880,
            "spelling_name": "Cement",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 13045,
                    "word_type": "n.",
                    "spelling_usage": "Any substance used for making bodies adhere to each other,\n   as mortar, glue, etc."
                },
                {
                    "spelling_detail_id": 13046,
                    "word_type": "n.",
                    "spelling_usage": "A kind of calcined limestone, or a calcined mixture of clay\n   and lime, for making mortar which will harden under water."
                },
                {
                    "spelling_detail_id": 13047,
                    "word_type": "n.",
                    "spelling_usage": "The powder used in cementation. See Cementation, n., 2."
                },
                {
                    "spelling_detail_id": 13048,
                    "word_type": "n.",
                    "spelling_usage": "Bond of union; that which unites firmly, as persons in\n   friendship, or men in society."
                },
                {
                    "spelling_detail_id": 13049,
                    "word_type": "n.",
                    "spelling_usage": "The layer of bone investing the root and neck of a tooth;\n   -- called also cementum."
                },
                {
                    "spelling_detail_id": 13052,
                    "word_type": "n.",
                    "spelling_usage": "To unite or cause to adhere by means of a cement."
                },
                {
                    "spelling_detail_id": 13053,
                    "word_type": "n.",
                    "spelling_usage": "To unite firmly or closely."
                },
                {
                    "spelling_detail_id": 13054,
                    "word_type": "n.",
                    "spelling_usage": "To overlay or coat with cement; as, to cement a cellar\n   bottom."
                },
                {
                    "spelling_detail_id": 13055,
                    "word_type": "v. i.",
                    "spelling_usage": "To become cemented or firmly united; to cohere."
                }
            ]
        },
        {
            "id": 8442,
            "spelling_name": "Chaotic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 13911,
                    "word_type": "a.",
                    "spelling_usage": "Resembling chaos; confused."
                }
            ]
        },
        {
            "id": 8476,
            "spelling_name": "Chapter",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 13985,
                    "word_type": "n.",
                    "spelling_usage": "A division of a book or treatise; as, Genesis has fifty\n   chapters."
                },
                {
                    "spelling_detail_id": 13986,
                    "word_type": "n.",
                    "spelling_usage": "An assembly of monks, or of the prebends and other\n   clergymen connected with a cathedral, conventual, or collegiate church,\n   or of a diocese, usually presided over by the dean."
                },
                {
                    "spelling_detail_id": 13987,
                    "word_type": "n.",
                    "spelling_usage": "A community of canons or canonesses."
                },
                {
                    "spelling_detail_id": 13988,
                    "word_type": "n.",
                    "spelling_usage": "A bishop's council."
                },
                {
                    "spelling_detail_id": 13989,
                    "word_type": "n.",
                    "spelling_usage": "A business meeting of any religious community."
                },
                {
                    "spelling_detail_id": 13990,
                    "word_type": "n.",
                    "spelling_usage": "An organized branch of some society or fraternity as of\n   the Freemasons."
                },
                {
                    "spelling_detail_id": 13991,
                    "word_type": "n.",
                    "spelling_usage": "A meeting of certain organized societies or orders."
                },
                {
                    "spelling_detail_id": 13992,
                    "word_type": "n.",
                    "spelling_usage": "A chapter house."
                },
                {
                    "spelling_detail_id": 13993,
                    "word_type": "n.",
                    "spelling_usage": "A decretal epistle."
                },
                {
                    "spelling_detail_id": 13994,
                    "word_type": "n.",
                    "spelling_usage": "A location or compartment."
                },
                {
                    "spelling_detail_id": 13995,
                    "word_type": "v. t.",
                    "spelling_usage": "To divide into chapters, as a book."
                },
                {
                    "spelling_detail_id": 13996,
                    "word_type": "v. t.",
                    "spelling_usage": "To correct; to bring to book, i. e., to demand chapter\n   and verse."
                }
            ]
        },
        {
            "id": 8699,
            "spelling_name": "Cheek",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 14427,
                    "word_type": "n.",
                    "spelling_usage": "The side of the face below the eye."
                },
                {
                    "spelling_detail_id": 14428,
                    "word_type": "n.",
                    "spelling_usage": "The cheek bone."
                },
                {
                    "spelling_detail_id": 14429,
                    "word_type": "n.",
                    "spelling_usage": "Those pieces of a machine, or of any timber, or stone work,\n   which form corresponding sides, or which are similar and in pair; as,\n   the cheeks (jaws) of a vise; the cheeks of a gun carriage, etc."
                },
                {
                    "spelling_detail_id": 14430,
                    "word_type": "n.",
                    "spelling_usage": "The branches of a bridle bit."
                },
                {
                    "spelling_detail_id": 14431,
                    "word_type": "n.",
                    "spelling_usage": "A section of a flask, so made that it can be moved\n   laterally, to permit the removal of the pattern from the mold; the\n   middle part of a flask."
                },
                {
                    "spelling_detail_id": 14432,
                    "word_type": "n.",
                    "spelling_usage": "Cool confidence; assurance; impudence."
                },
                {
                    "spelling_detail_id": 14433,
                    "word_type": "v. t.",
                    "spelling_usage": "To be impudent or saucy to."
                }
            ]
        },
        {
            "id": 8808,
            "spelling_name": "Chess",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 14583,
                    "word_type": "n.",
                    "spelling_usage": "A game played on a chessboard, by two persons, with two\n   differently colored sets of men, sixteen in each set. Each player has a\n   king, a queen, two bishops, two knights, two castles or rooks, and\n   eight pawns."
                },
                {
                    "spelling_detail_id": 14584,
                    "word_type": "n.",
                    "spelling_usage": "A species of brome grass (Bromus secalinus) which is a\n   troublesome weed in wheat fields, and is often erroneously regarded as\n   degenerate or changed wheat; it bears a very slight resemblance to\n   oats, and if reaped and ground up with wheat, so as to be used for\n   food, is said to produce narcotic effects; -- called also cheat and\n   Willard's bromus."
                }
            ]
        },
        {
            "id": 8894,
            "spelling_name": "Chide",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 14706,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "To rebuke; to reprove; to scold; to find fault\n   with."
                },
                {
                    "spelling_detail_id": 14707,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "Fig.: To be noisy about; to chafe against."
                },
                {
                    "spelling_detail_id": 14708,
                    "word_type": "v. i.",
                    "spelling_usage": "To utter words of disapprobation and displeasure; to find\n   fault; to contend angrily."
                },
                {
                    "spelling_detail_id": 14709,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a clamorous noise; to chafe."
                },
                {
                    "spelling_detail_id": 14710,
                    "word_type": "n.",
                    "spelling_usage": "A continuous noise or murmur."
                }
            ]
        },
        {
            "id": 8923,
            "spelling_name": "Child",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 14751,
                    "word_type": "n.",
                    "spelling_usage": "A son or a daughter; a male or female descendant, in the\n   first degree; the immediate progeny of human parents; -- in law,\n   legitimate offspring. Used also of animals and plants."
                },
                {
                    "spelling_detail_id": 14752,
                    "word_type": "n.",
                    "spelling_usage": "A descendant, however remote; -- used esp. in the plural;\n   as, the children of Israel; the children of Edom."
                },
                {
                    "spelling_detail_id": 14753,
                    "word_type": "n.",
                    "spelling_usage": "One who, by character of practice, shows signs of\n   relationship to, or of the influence of, another; one closely connected\n   with a place, occupation, character, etc.; as, a child of God; a child\n   of the devil; a child of disobedience; a child of toil; a child of the\n   people."
                },
                {
                    "spelling_detail_id": 14754,
                    "word_type": "n.",
                    "spelling_usage": "A noble youth. See Childe."
                },
                {
                    "spelling_detail_id": 14755,
                    "word_type": "n.",
                    "spelling_usage": "A young person of either sex. esp. one between infancy and\n   youth; hence, one who exhibits the characteristics of a very young\n   person, as innocence, obedience, trustfulness, limited understanding,\n   etc."
                },
                {
                    "spelling_detail_id": 14756,
                    "word_type": "n.",
                    "spelling_usage": "A female infant."
                },
                {
                    "spelling_detail_id": 14759,
                    "word_type": "v. i.",
                    "spelling_usage": "To give birth; to produce young."
                }
            ]
        },
        {
            "id": 9298,
            "spelling_name": "Chose",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 15212,
                    "word_type": "imp.",
                    "spelling_usage": "of Choose"
                },
                {
                    "spelling_detail_id": 15214,
                    "word_type": "",
                    "spelling_usage": "of Choose"
                },
                {
                    "spelling_detail_id": 15338,
                    "word_type": "n.",
                    "spelling_usage": "A thing; personal property."
                },
                {
                    "spelling_detail_id": 15339,
                    "word_type": "",
                    "spelling_usage": "imp. & p. p. of Choose."
                }
            ]
        },
        {
            "id": 9312,
            "spelling_name": "Chowder",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 15357,
                    "word_type": "n.",
                    "spelling_usage": "A dish made of fresh fish or clams, biscuit, onions, etc.,\n   stewed together."
                },
                {
                    "spelling_detail_id": 15358,
                    "word_type": "n.",
                    "spelling_usage": "A seller of fish."
                },
                {
                    "spelling_detail_id": 15359,
                    "word_type": "v. t.",
                    "spelling_usage": "To make a chowder of."
                }
            ]
        },
        {
            "id": 9681,
            "spelling_name": "Circle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 15855,
                    "word_type": "n.",
                    "spelling_usage": "A plane figure, bounded by a single curve line called its\n   circumference, every part of which is equally distant from a point\n   within it, called the center."
                },
                {
                    "spelling_detail_id": 15856,
                    "word_type": "n.",
                    "spelling_usage": "The line that bounds such a figure; a circumference; a\n   ring."
                },
                {
                    "spelling_detail_id": 15857,
                    "word_type": "n.",
                    "spelling_usage": "An instrument of observation, the graduated limb of which\n   consists of an entire circle."
                },
                {
                    "spelling_detail_id": 15858,
                    "word_type": "n.",
                    "spelling_usage": "A round body; a sphere; an orb."
                },
                {
                    "spelling_detail_id": 15859,
                    "word_type": "n.",
                    "spelling_usage": "Compass; circuit; inclosure."
                },
                {
                    "spelling_detail_id": 15860,
                    "word_type": "n.",
                    "spelling_usage": "A company assembled, or conceived to assemble, about a\n   central point of interest, or bound by a common tie; a class or\n   division of society; a coterie; a set."
                },
                {
                    "spelling_detail_id": 15861,
                    "word_type": "n.",
                    "spelling_usage": "A circular group of persons; a ring."
                },
                {
                    "spelling_detail_id": 15862,
                    "word_type": "n.",
                    "spelling_usage": "A series ending where it begins, and repeating itself."
                },
                {
                    "spelling_detail_id": 15863,
                    "word_type": "n.",
                    "spelling_usage": "A form of argument in which two or more unproved statements\n   are used to prove each other; inconclusive reasoning."
                },
                {
                    "spelling_detail_id": 15864,
                    "word_type": "n.",
                    "spelling_usage": "Indirect form of words; circumlocution."
                },
                {
                    "spelling_detail_id": 15865,
                    "word_type": "n.",
                    "spelling_usage": "A territorial division or district."
                },
                {
                    "spelling_detail_id": 15868,
                    "word_type": "n.",
                    "spelling_usage": "To move around; to revolve around."
                },
                {
                    "spelling_detail_id": 15869,
                    "word_type": "n.",
                    "spelling_usage": "To encompass, as by a circle; to surround; to inclose; to\n   encircle."
                },
                {
                    "spelling_detail_id": 15870,
                    "word_type": "v. i.",
                    "spelling_usage": "To move circularly; to form a circle; to circulate."
                }
            ]
        },
        {
            "id": 9871,
            "spelling_name": "Citation",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 16136,
                    "word_type": "n.",
                    "spelling_usage": "An official summons or notice given to a person to\n   appear; the paper containing such summons or notice."
                },
                {
                    "spelling_detail_id": 16137,
                    "word_type": "n.",
                    "spelling_usage": "The act of citing a passage from a book, or from another\n   person, in his own words; also, the passage or words quoted; quotation."
                },
                {
                    "spelling_detail_id": 16138,
                    "word_type": "n.",
                    "spelling_usage": "Enumeration; mention; as, a citation of facts."
                },
                {
                    "spelling_detail_id": 16139,
                    "word_type": "n.",
                    "spelling_usage": "A reference to decided cases, or books of authority, to\n   prove a point in law."
                }
            ]
        },
        {
            "id": 9907,
            "spelling_name": "Civics",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 16195,
                    "word_type": "n.",
                    "spelling_usage": "The science of civil government."
                }
            ]
        },
        {
            "id": 10160,
            "spelling_name": "Cleave",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 16652,
                    "word_type": "v. i. ",
                    "spelling_usage": "To adhere closely; to stick; to hold fast; to cling."
                },
                {
                    "spelling_detail_id": 16653,
                    "word_type": "v. i. ",
                    "spelling_usage": "To unite or be united closely in interest or affection;\n   to adhere with strong attachment."
                },
                {
                    "spelling_detail_id": 16654,
                    "word_type": "v. i. ",
                    "spelling_usage": "To fit; to be adapted; to assimilate."
                },
                {
                    "spelling_detail_id": 16662,
                    "word_type": "v. t.",
                    "spelling_usage": "To part or divide by force; to split or rive; to cut."
                },
                {
                    "spelling_detail_id": 16663,
                    "word_type": "v. t.",
                    "spelling_usage": "To part or open naturally; to divide."
                },
                {
                    "spelling_detail_id": 16664,
                    "word_type": "v. i.",
                    "spelling_usage": "To part; to open; to crack; to separate; as parts of\n   bodies; as, the ground cleaves by frost."
                }
            ]
        },
        {
            "id": 10249,
            "spelling_name": "Climate",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 16800,
                    "word_type": "v. i.",
                    "spelling_usage": "One of thirty regions or zones, parallel to the\n   equator, into which the surface of the earth from the equator to the\n   pole was divided, according to the successive increase of the length of\n   the midsummer day."
                },
                {
                    "spelling_detail_id": 16801,
                    "word_type": "v. i.",
                    "spelling_usage": "The condition of a place in relation to various\n   phenomena of the atmosphere, as temperature, moisture, etc., especially\n   as they affect animal or vegetable life."
                },
                {
                    "spelling_detail_id": 16802,
                    "word_type": "v. i.",
                    "spelling_usage": "To dwell."
                }
            ]
        },
        {
            "id": 10327,
            "spelling_name": "Clodhopper",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 16953,
                    "word_type": "n.",
                    "spelling_usage": "A rude, rustic fellow."
                }
            ]
        },
        {
            "id": 10382,
            "spelling_name": "Clothes",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 17069,
                    "word_type": "pl. ",
                    "spelling_usage": "of Cloth"
                },
                {
                    "spelling_detail_id": 17080,
                    "word_type": "n. pl.",
                    "spelling_usage": "Covering for the human body; dress; vestments;\n   vesture; -- a general term for whatever covering is worn, or is made to\n   be worn, for decency or comfort."
                },
                {
                    "spelling_detail_id": 17081,
                    "word_type": "n. pl.",
                    "spelling_usage": "The covering of a bed; bedclothes."
                }
            ]
        },
        {
            "id": 10412,
            "spelling_name": "Cloudy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 17125,
                    "word_type": "n.",
                    "spelling_usage": "Overcast or obscured with clouds; clouded; as, a cloudy\n   sky."
                },
                {
                    "spelling_detail_id": 17126,
                    "word_type": "n.",
                    "spelling_usage": "Consisting of a cloud or clouds."
                },
                {
                    "spelling_detail_id": 17127,
                    "word_type": "n.",
                    "spelling_usage": "Indicating gloom, anxiety, sullenness, or ill-nature; not\n   open or cheerful."
                },
                {
                    "spelling_detail_id": 17128,
                    "word_type": "n.",
                    "spelling_usage": "Confused; indistinct; obscure; dark."
                },
                {
                    "spelling_detail_id": 17129,
                    "word_type": "n.",
                    "spelling_usage": "Lacking clearness, brightness, or luster."
                },
                {
                    "spelling_detail_id": 17130,
                    "word_type": "n.",
                    "spelling_usage": "Marked with veins or sports of dark or various hues, as\n   marble."
                }
            ]
        },
        {
            "id": 10422,
            "spelling_name": "Clover",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 17156,
                    "word_type": "n.",
                    "spelling_usage": "A plant of different species of the genus Trifolium; as the\n   common red clover, T. pratense, the white, T. repens, and the hare's\n   foot, T. arvense."
                }
            ]
        },
        {
            "id": 10432,
            "spelling_name": "Cloying",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 17168,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Cloy"
                }
            ]
        },
        {
            "id": 10822,
            "spelling_name": "Coercive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 17773,
                    "word_type": "a.",
                    "spelling_usage": "Serving or intended to coerce; having power to constrain."
                }
            ]
        },
        {
            "id": 10846,
            "spelling_name": "Coffee",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 17800,
                    "word_type": "n.",
                    "spelling_usage": "The \"beans\" or \"berries\" (pyrenes) obtained from the drupes\n   of a small evergreen tree of the genus Coffea, growing in Abyssinia,\n   Arabia, Persia, and other warm regions of Asia and Africa, and also in\n   tropical America."
                },
                {
                    "spelling_detail_id": 17801,
                    "word_type": "n.",
                    "spelling_usage": "The coffee tree."
                },
                {
                    "spelling_detail_id": 17802,
                    "word_type": "n.",
                    "spelling_usage": "The beverage made from the roasted and ground berry."
                }
            ]
        },
        {
            "id": 11299,
            "spelling_name": "Combed",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 18510,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Comb"
                }
            ]
        },
        {
            "id": 11585,
            "spelling_name": "Companion",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 19053,
                    "word_type": "n.",
                    "spelling_usage": "One who accompanies or is in company with another for a\n   longer or shorter period, either from choice or casually; one who is\n   much in the company of, or is associated with, another or others; an\n   associate; a comrade; a consort; a partner."
                },
                {
                    "spelling_detail_id": 19054,
                    "word_type": "n.",
                    "spelling_usage": "A knight of the lowest rank in certain orders; as, a\n   companion of the Bath."
                },
                {
                    "spelling_detail_id": 19055,
                    "word_type": "n.",
                    "spelling_usage": "A fellow; -- in contempt."
                },
                {
                    "spelling_detail_id": 19056,
                    "word_type": "n.",
                    "spelling_usage": "A skylight on an upper deck with frames and sashes of\n   various shapes, to admit light to a cabin or lower deck."
                },
                {
                    "spelling_detail_id": 19057,
                    "word_type": "n.",
                    "spelling_usage": "A wooden hood or penthouse covering the companion way; a\n   companion hatch."
                },
                {
                    "spelling_detail_id": 19058,
                    "word_type": "v. t.",
                    "spelling_usage": "To be a companion to; to attend on; to accompany."
                },
                {
                    "spelling_detail_id": 19059,
                    "word_type": "v. t.",
                    "spelling_usage": "To qualify as a companion; to make equal."
                }
            ]
        },
        {
            "id": 11669,
            "spelling_name": "Competitive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 19217,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to competition; producing\n   competition; competitory; as, a competitive examination."
                }
            ]
        },
        {
            "id": 11693,
            "spelling_name": "Complaint",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 19252,
                    "word_type": "n.",
                    "spelling_usage": "Expression of grief, regret, pain, censure, or\n   resentment; lamentation; murmuring; accusation; fault-finding."
                },
                {
                    "spelling_detail_id": 19253,
                    "word_type": "n.",
                    "spelling_usage": "Cause or subject of complaint or murmuring."
                },
                {
                    "spelling_detail_id": 19254,
                    "word_type": "n.",
                    "spelling_usage": "An ailment or disease of the body."
                },
                {
                    "spelling_detail_id": 19255,
                    "word_type": "n.",
                    "spelling_usage": "A formal allegation or charge against a party made or\n   presented to the appropriate court or officer, as for a wrong done or a\n   crime committed (in the latter case, generally under oath); an\n   information; accusation; the initial bill in proceedings in equity."
                }
            ]
        },
        {
            "id": 12063,
            "spelling_name": "Concrete",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 19870,
                    "word_type": "a.",
                    "spelling_usage": "United in growth; hence, formed by coalition of separate\n   particles into one mass; united in a solid form."
                },
                {
                    "spelling_detail_id": 19871,
                    "word_type": "a.",
                    "spelling_usage": "Standing for an object as it exists in nature, invested\n   with all its qualities, as distinguished from standing for an attribute\n   of an object; -- opposed to abstract."
                },
                {
                    "spelling_detail_id": 19872,
                    "word_type": "a.",
                    "spelling_usage": "Applied to a specific object; special; particular; --\n   opposed to general. See Abstract, 3."
                },
                {
                    "spelling_detail_id": 19873,
                    "word_type": "n.",
                    "spelling_usage": "A compound or mass formed by concretion, spontaneous\n   union, or coalescence of separate particles of matter in one body."
                },
                {
                    "spelling_detail_id": 19874,
                    "word_type": "n.",
                    "spelling_usage": "A mixture of gravel, pebbles, or broken stone with cement\n   or with tar, etc., used for sidewalks, roadways, foundations, etc., and\n   esp. for submarine structures."
                },
                {
                    "spelling_detail_id": 19875,
                    "word_type": "n.",
                    "spelling_usage": "A term designating both a quality and the subject in\n   which it exists; a concrete term."
                },
                {
                    "spelling_detail_id": 19876,
                    "word_type": "n.",
                    "spelling_usage": "Sugar boiled down from cane juice to a solid mass."
                },
                {
                    "spelling_detail_id": 19879,
                    "word_type": "v. i.",
                    "spelling_usage": "To unite or coalesce, as separate particles, into a\n   mass or solid body."
                },
                {
                    "spelling_detail_id": 19880,
                    "word_type": "v. t.",
                    "spelling_usage": "To form into a mass, as by the cohesion or coalescence\n   of separate particles."
                },
                {
                    "spelling_detail_id": 19881,
                    "word_type": "v. t.",
                    "spelling_usage": "To cover with, or form of, concrete, as a pavement."
                }
            ]
        },
        {
            "id": 12821,
            "spelling_name": "Constituent",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 21091,
                    "word_type": "a.",
                    "spelling_usage": "Serving to form, compose, or make up; elemental;\n   component."
                },
                {
                    "spelling_detail_id": 21092,
                    "word_type": "a.",
                    "spelling_usage": "Having the power of electing or appointing."
                },
                {
                    "spelling_detail_id": 21093,
                    "word_type": "n.",
                    "spelling_usage": "The person or thing which constitutes, determines, or\n   constructs."
                },
                {
                    "spelling_detail_id": 21094,
                    "word_type": "n.",
                    "spelling_usage": "That which constitutes or composes, as a part, or an\n   essential part; a component; an element."
                },
                {
                    "spelling_detail_id": 21095,
                    "word_type": "n.",
                    "spelling_usage": "One for whom another acts; especially, one who is\n   represented by another in a legislative assembly; -- correlative to\n   representative."
                },
                {
                    "spelling_detail_id": 21096,
                    "word_type": "n.",
                    "spelling_usage": "A person who appoints another to act for him as\n   attorney in fact."
                }
            ]
        },
        {
            "id": 13077,
            "spelling_name": "Contraction",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 21531,
                    "word_type": "n.",
                    "spelling_usage": "The act or process of contracting, shortening, or\n   shrinking; the state of being contracted; as, contraction of the heart,\n   of the pupil of the eye, or of a tendion; the contraction produced by\n   cold."
                },
                {
                    "spelling_detail_id": 21532,
                    "word_type": "n.",
                    "spelling_usage": "The process of shortening an operation."
                },
                {
                    "spelling_detail_id": 21533,
                    "word_type": "n.",
                    "spelling_usage": "The act of incurring or becoming subject to, as\n   liabilities, obligation, debts, etc.; the process of becoming subject\n   to; as, the contraction of a disease."
                },
                {
                    "spelling_detail_id": 21534,
                    "word_type": "n.",
                    "spelling_usage": "Something contracted or abbreviated, as a word or\n   phrase; -- as, plenipo for plenipotentiary; crim. con. for criminal\n   conversation, etc."
                },
                {
                    "spelling_detail_id": 21535,
                    "word_type": "n.",
                    "spelling_usage": "The shortening of a word, or of two words, by the\n   omission of a letter or letters, or by reducing two or more vowels or\n   syllables to one; as, ne'er for never; can't for can not; don't for do\n   not; it's for it is."
                },
                {
                    "spelling_detail_id": 21536,
                    "word_type": "n.",
                    "spelling_usage": "A marriage contract."
                }
            ]
        },
        {
            "id": 13422,
            "spelling_name": "Cooperate",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 22090,
                    "word_type": "v. i.",
                    "spelling_usage": "To act or operate jointly with another or others; to\n   concur in action, effort, or effect."
                }
            ]
        },
        {
            "id": 14028,
            "spelling_name": "Cost",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 23004,
                    "word_type": "n.",
                    "spelling_usage": "A rib; a side; a region or coast."
                },
                {
                    "spelling_detail_id": 23005,
                    "word_type": "n.",
                    "spelling_usage": "See Cottise."
                },
                {
                    "spelling_detail_id": 23006,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Cost"
                },
                {
                    "spelling_detail_id": 23008,
                    "word_type": "v. t.",
                    "spelling_usage": "To require to be given, expended, or laid out therefor, as\n   in barter, purchase, acquisition, etc.; to cause the cost, expenditure,\n   relinquishment, or loss of; as, the ticket cost a dollar; the effort\n   cost his life."
                },
                {
                    "spelling_detail_id": 23009,
                    "word_type": "v. t.",
                    "spelling_usage": "To require to be borne or suffered; to cause."
                },
                {
                    "spelling_detail_id": 23010,
                    "word_type": "v. t.",
                    "spelling_usage": "The amount paid, charged, or engaged to be paid, for\n   anything bought or taken in barter; charge; expense; hence, whatever,\n   as labor, self-denial, suffering, etc., is requisite to secure benefit."
                },
                {
                    "spelling_detail_id": 23011,
                    "word_type": "v. t.",
                    "spelling_usage": "Loss of any kind; detriment; pain; suffering."
                },
                {
                    "spelling_detail_id": 23012,
                    "word_type": "v. t.",
                    "spelling_usage": "Expenses incurred in litigation."
                }
            ]
        },
        {
            "id": 14118,
            "spelling_name": "Couch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 23141,
                    "word_type": "v. t.",
                    "spelling_usage": "To lay upon a bed or other resting place."
                },
                {
                    "spelling_detail_id": 23142,
                    "word_type": "v. t.",
                    "spelling_usage": "To arrange or dispose as in a bed; -- sometimes followed\n   by the reflexive pronoun."
                },
                {
                    "spelling_detail_id": 23143,
                    "word_type": "v. t.",
                    "spelling_usage": "To lay or deposit in a bed or layer; to bed."
                },
                {
                    "spelling_detail_id": 23144,
                    "word_type": "v. t.",
                    "spelling_usage": "To transfer (as sheets of partly dried pulp) from the\n   wire cloth mold to a felt blanket, for further drying."
                },
                {
                    "spelling_detail_id": 23145,
                    "word_type": "v. t.",
                    "spelling_usage": "To conceal; to include or involve darkly."
                },
                {
                    "spelling_detail_id": 23146,
                    "word_type": "v. t.",
                    "spelling_usage": "To arrange; to place; to inlay."
                },
                {
                    "spelling_detail_id": 23147,
                    "word_type": "v. t.",
                    "spelling_usage": "To put into some form of language; to express; to phrase;\n   -- used with in and under."
                },
                {
                    "spelling_detail_id": 23148,
                    "word_type": "v. t.",
                    "spelling_usage": "To treat by pushing down or displacing the opaque lens\n   with a needle; as, to couch a cataract."
                },
                {
                    "spelling_detail_id": 23149,
                    "word_type": "v. i.",
                    "spelling_usage": "To lie down or recline, as on a bed or other place of\n   rest; to repose; to lie."
                },
                {
                    "spelling_detail_id": 23150,
                    "word_type": "v. i.",
                    "spelling_usage": "To lie down for concealment; to hide; to be concealed; to\n   be included or involved darkly."
                },
                {
                    "spelling_detail_id": 23151,
                    "word_type": "v. i.",
                    "spelling_usage": "To bend the body, as in reverence, pain, labor, etc.; to\n   stoop; to crouch."
                },
                {
                    "spelling_detail_id": 23152,
                    "word_type": "v. t.",
                    "spelling_usage": "A bed or place for repose or sleep; particularly, in the\n   United States, a lounge."
                },
                {
                    "spelling_detail_id": 23153,
                    "word_type": "v. t.",
                    "spelling_usage": "Any place for repose, as the lair of a beast, etc."
                },
                {
                    "spelling_detail_id": 23154,
                    "word_type": "v. t.",
                    "spelling_usage": "A mass of steeped barley spread upon a floor to\n   germinate, in malting; or the floor occupied by the barley; as, couch\n   of malt."
                },
                {
                    "spelling_detail_id": 23155,
                    "word_type": "v. t.",
                    "spelling_usage": "A preliminary layer, as of color, size, etc."
                }
            ]
        },
        {
            "id": 14130,
            "spelling_name": "Cough",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 23175,
                    "word_type": "v. i.",
                    "spelling_usage": "To expel air, or obstructing or irritating matter, from\n   the lungs or air passages, in a noisy and violent manner."
                },
                {
                    "spelling_detail_id": 23176,
                    "word_type": "v. t.",
                    "spelling_usage": "To expel from the lungs or air passages by coughing; --\n   followed by up; as, to cough up phlegm."
                },
                {
                    "spelling_detail_id": 23177,
                    "word_type": "v. t.",
                    "spelling_usage": "To bring to a specified state by coughing; as, he coughed\n   himself hoarse."
                },
                {
                    "spelling_detail_id": 23178,
                    "word_type": "v. i.",
                    "spelling_usage": "A sudden, noisy, and violent expulsion of air from the\n   chest, caused by irritation in the air passages, or by the reflex\n   action of nervous or gastric disorder, etc."
                },
                {
                    "spelling_detail_id": 23179,
                    "word_type": "v. i.",
                    "spelling_usage": "The more or less frequent repetition of coughing,\n   constituting a symptom of disease."
                }
            ]
        },
        {
            "id": 14487,
            "spelling_name": "Cowlick",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 23801,
                    "word_type": "n.",
                    "spelling_usage": "A tuft of hair turned up or awry (usually over the\n   forehead), as if licked by a cow."
                }
            ]
        },
        {
            "id": 14642,
            "spelling_name": "Cranky",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 24099,
                    "word_type": "a.",
                    "spelling_usage": "Full of spirit; crank."
                },
                {
                    "spelling_detail_id": 24100,
                    "word_type": "a.",
                    "spelling_usage": "Addicted to crotchets and whims; unreasonable in opinions;\n   crotchety."
                },
                {
                    "spelling_detail_id": 24101,
                    "word_type": "a.",
                    "spelling_usage": "Unsteady; easy to upset; crank."
                }
            ]
        },
        {
            "id": 14713,
            "spelling_name": "Crayon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 24203,
                    "word_type": "n.",
                    "spelling_usage": "An implement for drawing, made of clay and plumbago, or of\n   some preparation of chalk, usually sold in small prisms or cylinders."
                },
                {
                    "spelling_detail_id": 24204,
                    "word_type": "n.",
                    "spelling_usage": "A crayon drawing."
                },
                {
                    "spelling_detail_id": 24205,
                    "word_type": "n.",
                    "spelling_usage": "A pencil of carbon used in producing electric light."
                },
                {
                    "spelling_detail_id": 24208,
                    "word_type": "v. t.",
                    "spelling_usage": "To sketch, as with a crayon; to sketch or plan."
                }
            ]
        },
        {
            "id": 14939,
            "spelling_name": "Cricket",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 24603,
                    "word_type": "n.",
                    "spelling_usage": "An orthopterous insect of the genus Gryllus, and allied\n   genera. The males make chirping, musical notes by rubbing together the\n   basal parts of the veins of the front wings."
                },
                {
                    "spelling_detail_id": 24604,
                    "word_type": "n.",
                    "spelling_usage": "A low stool."
                },
                {
                    "spelling_detail_id": 24605,
                    "word_type": "n.",
                    "spelling_usage": "A game much played in England, and sometimes in America,\n   with a ball, bats, and wickets, the players being arranged in two\n   contesting parties or sides."
                },
                {
                    "spelling_detail_id": 24606,
                    "word_type": "n.",
                    "spelling_usage": "A small false roof, or the raising of a portion of a roof,\n   so as to throw off water from behind an obstacle, such as a chimney."
                },
                {
                    "spelling_detail_id": 24607,
                    "word_type": "v. i.",
                    "spelling_usage": "To play at cricket."
                }
            ]
        },
        {
            "id": 14949,
            "spelling_name": "Criminal",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 24620,
                    "word_type": "a.",
                    "spelling_usage": "Guilty of crime or sin."
                },
                {
                    "spelling_detail_id": 24621,
                    "word_type": "a.",
                    "spelling_usage": "Involving a crime; of the nature of a crime; -- said of\n   an act or of conduct; as, criminal carelessness."
                },
                {
                    "spelling_detail_id": 24622,
                    "word_type": "a.",
                    "spelling_usage": "Relating to crime; -- opposed to civil; as, the criminal\n   code."
                },
                {
                    "spelling_detail_id": 24623,
                    "word_type": "n.",
                    "spelling_usage": "One who has commited a crime; especially, one who is\n   found guilty by verdict, confession, or proof; a malefactor; a felon."
                }
            ]
        },
        {
            "id": 15345,
            "spelling_name": "Crumpet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 25307,
                    "word_type": "n.",
                    "spelling_usage": "A kind of large, thin muffin or cake, light and spongy,\n   and cooked on a griddle or spider."
                }
            ]
        },
        {
            "id": 15514,
            "spelling_name": "Cuckoo",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 25577,
                    "word_type": "n.",
                    "spelling_usage": "A bird belonging to Cuculus, Coccyzus, and several allied\n   genera, of many species."
                }
            ]
        },
        {
            "id": 15523,
            "spelling_name": "Cucumber",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 25589,
                    "word_type": "n.",
                    "spelling_usage": "A creeping plant, and its fruit, of several species of\n   the genus Cucumis, esp. Cucumis sativus, the unripe fruit of which is\n   eaten either fresh or picked. Also, similar plants or fruits of several\n   other genera. See below."
                }
            ]
        },
        {
            "id": 15565,
            "spelling_name": "Culinary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 25661,
                    "word_type": "a.",
                    "spelling_usage": "Relating to the kitchen, or to the art of cookery; used\n   in kitchens; as, a culinary vessel; the culinary art."
                }
            ]
        },
        {
            "id": 16346,
            "spelling_name": "Damp",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 26873,
                    "word_type": "n.",
                    "spelling_usage": "Moisture; humidity; fog; fogginess; vapor."
                },
                {
                    "spelling_detail_id": 26874,
                    "word_type": "n.",
                    "spelling_usage": "Dejection; depression; cloud of the mind."
                },
                {
                    "spelling_detail_id": 26875,
                    "word_type": "n.",
                    "spelling_usage": "A gaseous product, formed in coal mines, old wells, pints,\n   etc."
                },
                {
                    "spelling_detail_id": 26876,
                    "word_type": "superl.",
                    "spelling_usage": "Being in a state between dry and wet; moderately wet;\n   moist; humid."
                },
                {
                    "spelling_detail_id": 26877,
                    "word_type": "superl.",
                    "spelling_usage": "Dejected; depressed; sunk."
                },
                {
                    "spelling_detail_id": 26880,
                    "word_type": "n.",
                    "spelling_usage": "To render damp; to moisten; to make humid, or moderately wet;\n   to dampen; as, to damp cloth."
                },
                {
                    "spelling_detail_id": 26881,
                    "word_type": "n.",
                    "spelling_usage": "To put out, as fire; to depress or deject; to deaden; to\n   cloud; to check or restrain, as action or vigor; to make dull; to\n   weaken; to discourage."
                }
            ]
        },
        {
            "id": 16372,
            "spelling_name": "Dandelion",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 26916,
                    "word_type": "n.",
                    "spelling_usage": "A well-known plant of the genus Taraxacum (T.\n   officinale, formerly called T. Dens-leonis and Leontodos Taraxacum)\n   bearing large, yellow, compound flowers, and deeply notched leaves."
                }
            ]
        },
        {
            "id": 16477,
            "spelling_name": "Darted",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27093,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Dart"
                }
            ]
        },
        {
            "id": 16491,
            "spelling_name": "Dash",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27117,
                    "word_type": "v. t.",
                    "spelling_usage": "To throw with violence or haste; to cause to strike\n   violently or hastily; -- often used with against."
                },
                {
                    "spelling_detail_id": 27118,
                    "word_type": "v. t.",
                    "spelling_usage": "To break, as by throwing or by collision; to shatter; to\n   crust; to frustrate; to ruin."
                },
                {
                    "spelling_detail_id": 27119,
                    "word_type": "v. t.",
                    "spelling_usage": "To put to shame; to confound; to confuse; to abash; to\n   depress."
                },
                {
                    "spelling_detail_id": 27120,
                    "word_type": "v. t.",
                    "spelling_usage": "To throw in or on in a rapid, careless manner; to mix,\n   reduce, or adulterate, by throwing in something of an inferior quality;\n   to overspread partially; to bespatter; to touch here and there; as, to\n   dash wine with water; to dash paint upon a picture."
                },
                {
                    "spelling_detail_id": 27121,
                    "word_type": "v. t.",
                    "spelling_usage": "To form or sketch rapidly or carelessly; to execute\n   rapidly, or with careless haste; -- with off; as, to dash off a review\n   or sermon."
                },
                {
                    "spelling_detail_id": 27122,
                    "word_type": "v. t.",
                    "spelling_usage": "To erase by a stroke; to strike out; knock out; -- with\n   out; as, to dash out a word."
                },
                {
                    "spelling_detail_id": 27123,
                    "word_type": "v. i.",
                    "spelling_usage": "To rust with violence; to move impetuously; to strike\n   violently; as, the waves dash upon rocks."
                },
                {
                    "spelling_detail_id": 27124,
                    "word_type": "n.",
                    "spelling_usage": "Violent striking together of two bodies; collision; crash."
                },
                {
                    "spelling_detail_id": 27125,
                    "word_type": "n.",
                    "spelling_usage": "A sudden check; abashment; frustration; ruin; as, his hopes\n   received a dash."
                },
                {
                    "spelling_detail_id": 27126,
                    "word_type": "n.",
                    "spelling_usage": "A slight admixture, infusion, or adulteration; a partial\n   overspreading; as, wine with a dash of water; red with a dash of\n   purple."
                },
                {
                    "spelling_detail_id": 27127,
                    "word_type": "n.",
                    "spelling_usage": "A rapid movement, esp. one of short duration; a quick stroke\n   or blow; a sudden onset or rush; as, a bold dash at the enemy; a dash\n   of rain."
                },
                {
                    "spelling_detail_id": 27128,
                    "word_type": "n.",
                    "spelling_usage": "Energy in style or action; animation; spirit."
                },
                {
                    "spelling_detail_id": 27129,
                    "word_type": "n.",
                    "spelling_usage": "A vain show; a blustering parade; a flourish; as, to make or\n   cut a great dash."
                },
                {
                    "spelling_detail_id": 27130,
                    "word_type": "n.",
                    "spelling_usage": "A mark or line [--], in writing or printing, denoting a\n   sudden break, stop, or transition in a sentence, or an abrupt change in\n   its construction, a long or significant pause, or an unexpected or\n   epigrammatic turn of sentiment. Dashes are also sometimes used instead\n   of marks or parenthesis."
                },
                {
                    "spelling_detail_id": 27131,
                    "word_type": "n.",
                    "spelling_usage": "The sign of staccato, a small mark [/] denoting that the note\n   over which it is placed is to be performed in a short, distinct manner."
                },
                {
                    "spelling_detail_id": 27132,
                    "word_type": "n.",
                    "spelling_usage": "The line drawn through a figure in the thorough bass, as a\n   direction to raise the interval a semitone."
                },
                {
                    "spelling_detail_id": 27133,
                    "word_type": "n.",
                    "spelling_usage": "A short, spirited effort or trial of speed upon a race\n   course; -- used in horse racing, when a single trial constitutes the\n   race."
                }
            ]
        },
        {
            "id": 16539,
            "spelling_name": "Daughter",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27215,
                    "word_type": "n.",
                    "spelling_usage": "The female offspring of the human species; a female child\n   of any age; -- applied also to the lower animals."
                },
                {
                    "spelling_detail_id": 27216,
                    "word_type": "n.",
                    "spelling_usage": "A female descendant; a woman."
                },
                {
                    "spelling_detail_id": 27217,
                    "word_type": "n.",
                    "spelling_usage": "A son's wife; a daughter-in-law."
                },
                {
                    "spelling_detail_id": 27218,
                    "word_type": "n.",
                    "spelling_usage": "A term of address indicating parental interest."
                }
            ]
        },
        {
            "id": 16565,
            "spelling_name": "Dawdle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27249,
                    "word_type": "v. i.",
                    "spelling_usage": "To waste time in trifling employment; to trifle; to\n   saunter."
                },
                {
                    "spelling_detail_id": 27250,
                    "word_type": "v. t.",
                    "spelling_usage": "To waste by trifling; as, to dawdle away a whole\n   morning."
                },
                {
                    "spelling_detail_id": 27251,
                    "word_type": "n.",
                    "spelling_usage": "A dawdler."
                }
            ]
        },
        {
            "id": 16576,
            "spelling_name": "Day",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27265,
                    "word_type": "n.",
                    "spelling_usage": "The time of light, or interval between one night and the next;\n   the time between sunrise and sunset, or from dawn to darkness; hence,\n   the light; sunshine."
                },
                {
                    "spelling_detail_id": 27266,
                    "word_type": "n.",
                    "spelling_usage": "The period of the earth's revolution on its axis. --\n   ordinarily divided into twenty-four hours. It is measured by the\n   interval between two successive transits of a celestial body over the\n   same meridian, and takes a specific name from that of the body. Thus,\n   if this is the sun, the day (the interval between two successive\n   transits of the sun's center over the same meridian) is called a solar\n   day; if it is a star, a sidereal day; if it is the moon, a lunar day.\n   See Civil day, Sidereal day, below."
                },
                {
                    "spelling_detail_id": 27267,
                    "word_type": "n.",
                    "spelling_usage": "Those hours, or the daily recurring period, allotted by usage\n   or law for work."
                },
                {
                    "spelling_detail_id": 27268,
                    "word_type": "n.",
                    "spelling_usage": "A specified time or period; time, considered with reference to\n   the existence or prominence of a person or thing; age; time."
                },
                {
                    "spelling_detail_id": 27269,
                    "word_type": "n.",
                    "spelling_usage": "(Preceded by the) Some day in particular, as some day of\n   contest, some anniversary, etc."
                }
            ]
        },
        {
            "id": 16787,
            "spelling_name": "Decade",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27599,
                    "word_type": "n.",
                    "spelling_usage": "A group or division of ten; esp., a period of ten years; a\n   decennium; as, a decade of years or days; a decade of soldiers; the\n   second decade of Livy."
                }
            ]
        },
        {
            "id": 16879,
            "spelling_name": "December",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27712,
                    "word_type": "n.",
                    "spelling_usage": "The twelfth and last month of the year, containing\n   thirty-one days. During this month occurs the winter solstice."
                },
                {
                    "spelling_detail_id": 27713,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: With reference to the end of the year and to the\n   winter season; as, the December of his life."
                }
            ]
        },
        {
            "id": 16970,
            "spelling_name": "Decision",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 27831,
                    "word_type": "n.",
                    "spelling_usage": "Cutting off; division; detachment of a part."
                },
                {
                    "spelling_detail_id": 27832,
                    "word_type": "n.",
                    "spelling_usage": "The act of deciding; act of settling or terminating, as a\n   controversy, by giving judgment on the matter at issue; determination,\n   as of a question or doubt; settlement; conclusion."
                },
                {
                    "spelling_detail_id": 27833,
                    "word_type": "n.",
                    "spelling_usage": "An account or report of a conclusion, especially of a\n   legal adjudication or judicial determination of a question or cause;\n   as, a decision of arbitrators; a decision of the Supreme Court."
                },
                {
                    "spelling_detail_id": 27834,
                    "word_type": "n.",
                    "spelling_usage": "The quality of being decided; prompt and fixed\n   determination; unwavering firmness; as, to manifest great decision."
                }
            ]
        },
        {
            "id": 17246,
            "spelling_name": "Default",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 28262,
                    "word_type": "n.",
                    "spelling_usage": "A failing or failure; omission of that which ought to be\n   done; neglect to do what duty or law requires; as, this evil has\n   happened through the governor's default."
                },
                {
                    "spelling_detail_id": 28263,
                    "word_type": "n.",
                    "spelling_usage": "Fault; offense; ill deed; wrong act; failure in virtue or\n   wisdom."
                },
                {
                    "spelling_detail_id": 28264,
                    "word_type": "n.",
                    "spelling_usage": "A neglect of, or failure to take, some step necessary to\n   secure the benefit of law, as a failure to appear in court at a day\n   assigned, especially of the defendant in a suit when called to make\n   answer; also of jurors, witnesses, etc."
                },
                {
                    "spelling_detail_id": 28267,
                    "word_type": "v. i.",
                    "spelling_usage": "To fail in duty; to offend."
                },
                {
                    "spelling_detail_id": 28268,
                    "word_type": "v. i.",
                    "spelling_usage": "To fail in fulfilling a contract, agreement, or duty."
                },
                {
                    "spelling_detail_id": 28269,
                    "word_type": "v. i.",
                    "spelling_usage": "To fail to appear in court; to let a case go by\n   default."
                },
                {
                    "spelling_detail_id": 28270,
                    "word_type": "v. t.",
                    "spelling_usage": "To fail to perform or pay; to be guilty of neglect of;\n   to omit; as, to default a dividend."
                },
                {
                    "spelling_detail_id": 28271,
                    "word_type": "v. t.",
                    "spelling_usage": "To call a defendant or other party whose duty it is to\n   be present in court, and make entry of his default, if he fails to\n   appear; to enter a default against."
                },
                {
                    "spelling_detail_id": 28272,
                    "word_type": "v. t.",
                    "spelling_usage": "To leave out of account; to omit."
                }
            ]
        },
        {
            "id": 17606,
            "spelling_name": "Delicacy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 28803,
                    "word_type": "a.",
                    "spelling_usage": "The state or condition of being delicate; agreeableness\n   to the senses; delightfulness; as, delicacy of flavor, of odor, and the\n   like."
                },
                {
                    "spelling_detail_id": 28804,
                    "word_type": "a.",
                    "spelling_usage": "Nicety or fineness of form, texture, or constitution;\n   softness; elegance; smoothness; tenderness; and hence, frailty or\n   weakness; as, the delicacy of a fiber or a thread; delicacy of a hand\n   or of the human form; delicacy of the skin; delicacy of frame."
                },
                {
                    "spelling_detail_id": 28805,
                    "word_type": "a.",
                    "spelling_usage": "Nice propriety of manners or conduct; susceptibility or\n   tenderness of feeling; refinement; fastidiousness; and hence, in an\n   exaggerated sense, effeminacy; as, great delicacy of behavior; delicacy\n   in doing a kindness; delicacy of character that unfits for earnest\n   action."
                },
                {
                    "spelling_detail_id": 28806,
                    "word_type": "a.",
                    "spelling_usage": "Addiction to pleasure; luxury; daintiness; indulgence;\n   luxurious or voluptuous treatment."
                },
                {
                    "spelling_detail_id": 28807,
                    "word_type": "a.",
                    "spelling_usage": "Nice and refined perception and discrimination; critical\n   niceness; fastidious accuracy."
                },
                {
                    "spelling_detail_id": 28808,
                    "word_type": "a.",
                    "spelling_usage": "The state of being affected by slight causes;\n   sensitiveness; as, the delicacy of a chemist's balance."
                },
                {
                    "spelling_detail_id": 28809,
                    "word_type": "a.",
                    "spelling_usage": "That which is alluring, delicate, or refined; a luxury or\n   pleasure; something pleasant to the senses, especially to the sense of\n   taste; a dainty; as, delicacies of the table."
                },
                {
                    "spelling_detail_id": 28810,
                    "word_type": "a.",
                    "spelling_usage": "Pleasure; gratification; delight."
                }
            ]
        },
        {
            "id": 17699,
            "spelling_name": "Delta",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 28965,
                    "word_type": "n.",
                    "spelling_usage": "A tract of land shaped like the letter delta (/), especially\n   when the land is alluvial and inclosed between two or more mouths of a\n   river; as, the delta of the Ganges, of the Nile, or of the Mississippi."
                }
            ]
        },
        {
            "id": 17842,
            "spelling_name": "Demolition",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 29167,
                    "word_type": "n.",
                    "spelling_usage": "The act of overthrowing, pulling down, or destroying a\n   pile or structure; destruction by violence; utter overthrow; -- opposed\n   to construction; as, the demolition of a house, of military works, of a\n   town, or of hopes."
                }
            ]
        },
        {
            "id": 18216,
            "spelling_name": "Depose",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 29694,
                    "word_type": "v. t.",
                    "spelling_usage": "To lay down; to divest one's self of; to lay aside."
                },
                {
                    "spelling_detail_id": 29695,
                    "word_type": "v. t.",
                    "spelling_usage": "To let fall; to deposit."
                },
                {
                    "spelling_detail_id": 29696,
                    "word_type": "v. t.",
                    "spelling_usage": "To remove from a throne or other high station; to\n   dethrone; to divest or deprive of office."
                },
                {
                    "spelling_detail_id": 29697,
                    "word_type": "v. t.",
                    "spelling_usage": "To testify under oath; to bear testimony to; -- now\n   usually said of bearing testimony which is officially written down for\n   future use."
                },
                {
                    "spelling_detail_id": 29698,
                    "word_type": "v. t.",
                    "spelling_usage": "To put under oath."
                },
                {
                    "spelling_detail_id": 29699,
                    "word_type": "v. i.",
                    "spelling_usage": "To bear witness; to testify under oath; to make\n   deposition."
                }
            ]
        },
        {
            "id": 18319,
            "spelling_name": "Deputy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 29867,
                    "word_type": "n.",
                    "spelling_usage": "One appointed as the substitute of another, and empowered\n   to act for him, in his name or his behalf; a substitute in office; a\n   lieutenant; a representative; a delegate; a vicegerent; as, the deputy\n   of a prince, of a sheriff, of a township, etc."
                },
                {
                    "spelling_detail_id": 29868,
                    "word_type": "n.",
                    "spelling_usage": "A member of the Chamber of Deputies."
                }
            ]
        },
        {
            "id": 18598,
            "spelling_name": "Despicable",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 30295,
                    "word_type": "a.",
                    "spelling_usage": "Fit or deserving to be despised; contemptible; mean;\n   vile; worthless; as, a despicable man; despicable company; a despicable\n   gift."
                }
            ]
        },
        {
            "id": 18734,
            "spelling_name": "Detention",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 30475,
                    "word_type": "n.",
                    "spelling_usage": "The act of detaining or keeping back; a withholding."
                },
                {
                    "spelling_detail_id": 30476,
                    "word_type": "n.",
                    "spelling_usage": "The state of being detained (stopped or hindered); delay\n   from necessity."
                },
                {
                    "spelling_detail_id": 30477,
                    "word_type": "n.",
                    "spelling_usage": "Confinement; restraint; custody."
                }
            ]
        },
        {
            "id": 18887,
            "spelling_name": "Developer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 30686,
                    "word_type": "n.",
                    "spelling_usage": "One who, or that which, develops."
                },
                {
                    "spelling_detail_id": 30687,
                    "word_type": "n.",
                    "spelling_usage": "A reagent by the action of which the latent image upon a\n   photographic plate, after exposure in the camera, or otherwise, is\n   developed and visible."
                }
            ]
        },
        {
            "id": 19107,
            "spelling_name": "Dialect",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 31012,
                    "word_type": "n.",
                    "spelling_usage": "Means or mode of expressing thoughts; language; tongue;\n   form of speech."
                },
                {
                    "spelling_detail_id": 31013,
                    "word_type": "n.",
                    "spelling_usage": "The form of speech of a limited region or people, as\n   distinguished from ether forms nearly related to it; a variety or\n   subdivision of a language; speech characterized by local peculiarities\n   or specific circumstances; as, the Ionic and Attic were dialects of\n   Greece; the Yorkshire dialect; the dialect of the learned."
                }
            ]
        },
        {
            "id": 19437,
            "spelling_name": "Difficult",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 31480,
                    "word_type": "a.",
                    "spelling_usage": "Hard to do or to make; beset with difficulty; attended\n   with labor, trouble, or pains; not easy; arduous."
                },
                {
                    "spelling_detail_id": 31481,
                    "word_type": "a.",
                    "spelling_usage": "Hard to manage or to please; not easily wrought upon;\n   austere; stubborn; as, a difficult person."
                },
                {
                    "spelling_detail_id": 31482,
                    "word_type": "v. t.",
                    "spelling_usage": "To render difficult; to impede; to perplex."
                }
            ]
        },
        {
            "id": 19602,
            "spelling_name": "Dilemma",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 31738,
                    "word_type": "n.",
                    "spelling_usage": "An argument which presents an antagonist with two or more\n   alternatives, but is equally conclusive against him, whichever\n   alternative he chooses."
                },
                {
                    "spelling_detail_id": 31739,
                    "word_type": "n.",
                    "spelling_usage": "A state of things in which evils or obstacles present\n   themselves on every side, and it is difficult to determine what course\n   to pursue; a vexatious alternative or predicament; a difficult choice\n   or position."
                }
            ]
        },
        {
            "id": 19643,
            "spelling_name": "Dimension",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 31798,
                    "word_type": "n.",
                    "spelling_usage": "Measure in a single line, as length, breadth, height,\n   thickness, or circumference; extension; measurement; -- usually, in the\n   plural, measure in length and breadth, or in length, breadth, and\n   thickness; extent; size; as, the dimensions of a room, or of a ship;\n   the dimensions of a farm, of a kingdom."
                },
                {
                    "spelling_detail_id": 31799,
                    "word_type": "n.",
                    "spelling_usage": "Extent; reach; scope; importance; as, a project of large\n   dimensions."
                },
                {
                    "spelling_detail_id": 31800,
                    "word_type": "n.",
                    "spelling_usage": "The degree of manifoldness of a quantity; as, time is\n   quantity having one dimension; volume has three dimensions, relative to\n   extension."
                },
                {
                    "spelling_detail_id": 31801,
                    "word_type": "n.",
                    "spelling_usage": "A literal factor, as numbered in characterizing a term.\n   The term dimensions forms with the cardinal numbers a phrase equivalent\n   to degree with the ordinal; thus, a2b2c is a term of five dimensions,\n   or of the fifth degree."
                },
                {
                    "spelling_detail_id": 31802,
                    "word_type": "n.",
                    "spelling_usage": "The manifoldness with which the fundamental units of\n   time, length, and mass are involved in determining the units of other\n   physical quantities."
                }
            ]
        },
        {
            "id": 19728,
            "spelling_name": "Dinner",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 31930,
                    "word_type": "n.",
                    "spelling_usage": "The principal meal of the day, eaten by most people about\n   midday, but by many (especially in cities) at a later hour."
                },
                {
                    "spelling_detail_id": 31931,
                    "word_type": "n.",
                    "spelling_usage": "An entertainment; a feast."
                }
            ]
        },
        {
            "id": 20377,
            "spelling_name": "Disease",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 32910,
                    "word_type": "n.",
                    "spelling_usage": "Lack of ease; uneasiness; trouble; vexation; disquiet."
                },
                {
                    "spelling_detail_id": 32911,
                    "word_type": "n.",
                    "spelling_usage": "An alteration in the state of the body or of some of its\n   organs, interrupting or disturbing the performance of the vital\n   functions, and causing or threatening pain and weakness; malady;\n   affection; illness; sickness; disorder; -- applied figuratively to the\n   mind, to the moral character and habits, to institutions, the state,\n   etc."
                },
                {
                    "spelling_detail_id": 32914,
                    "word_type": "v. t.",
                    "spelling_usage": "To deprive of ease; to disquiet; to trouble; to\n   distress."
                },
                {
                    "spelling_detail_id": 32915,
                    "word_type": "v. t.",
                    "spelling_usage": "To derange the vital functions of; to afflict with\n   disease or sickness; to disorder; -- used almost exclusively in the\n   participle diseased."
                }
            ]
        },
        {
            "id": 21283,
            "spelling_name": "Distinctive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 34228,
                    "word_type": "a.",
                    "spelling_usage": "Marking or expressing distinction or difference;\n   distinguishing; characteristic; peculiar."
                },
                {
                    "spelling_detail_id": 34229,
                    "word_type": "a.",
                    "spelling_usage": "Having the power to distinguish and discern;\n   discriminating."
                }
            ]
        },
        {
            "id": 21327,
            "spelling_name": "Distraught",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 34275,
                    "word_type": "p. p.",
                    "spelling_usage": "of Distract"
                },
                {
                    "spelling_detail_id": 34310,
                    "word_type": "a.",
                    "spelling_usage": "Torn asunder; separated."
                },
                {
                    "spelling_detail_id": 34311,
                    "word_type": "a.",
                    "spelling_usage": "Distracted; perplexed."
                }
            ]
        },
        {
            "id": 21494,
            "spelling_name": "Diversity",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 34585,
                    "word_type": "n.",
                    "spelling_usage": "A state of difference; dissimilitude; unlikeness."
                },
                {
                    "spelling_detail_id": 34586,
                    "word_type": "n.",
                    "spelling_usage": "Multiplicity of difference; multiformity; variety."
                },
                {
                    "spelling_detail_id": 34587,
                    "word_type": "n.",
                    "spelling_usage": "Variegation."
                }
            ]
        },
        {
            "id": 21537,
            "spelling_name": "Divine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 34661,
                    "word_type": "a.",
                    "spelling_usage": "Of or belonging to God; as, divine perfections; the divine\n   will."
                },
                {
                    "spelling_detail_id": 34662,
                    "word_type": "a.",
                    "spelling_usage": "Proceeding from God; as, divine judgments."
                },
                {
                    "spelling_detail_id": 34663,
                    "word_type": "a.",
                    "spelling_usage": "Appropriated to God, or celebrating his praise; religious;\n   pious; holy; as, divine service; divine songs; divine worship."
                },
                {
                    "spelling_detail_id": 34664,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to, or proceeding from, a deity; partaking of\n   the nature of a god or the gods."
                },
                {
                    "spelling_detail_id": 34665,
                    "word_type": "a.",
                    "spelling_usage": "Godlike; heavenly; excellent in the highest degree;\n   supremely admirable; apparently above what is human. In this\n   application, the word admits of comparison; as, the divinest mind. Sir\n   J. Davies."
                },
                {
                    "spelling_detail_id": 34666,
                    "word_type": "a.",
                    "spelling_usage": "Presageful; foreboding; prescient."
                },
                {
                    "spelling_detail_id": 34667,
                    "word_type": "a.",
                    "spelling_usage": "Relating to divinity or theology."
                },
                {
                    "spelling_detail_id": 34668,
                    "word_type": "a.",
                    "spelling_usage": "One skilled in divinity; a theologian."
                },
                {
                    "spelling_detail_id": 34669,
                    "word_type": "a.",
                    "spelling_usage": "A minister of the gospel; a priest; a clergyman."
                },
                {
                    "spelling_detail_id": 34672,
                    "word_type": "v. t.",
                    "spelling_usage": "To foresee or foreknow; to detect; to anticipate; to\n   conjecture."
                },
                {
                    "spelling_detail_id": 34673,
                    "word_type": "v. t.",
                    "spelling_usage": "To foretell; to predict; to presage."
                },
                {
                    "spelling_detail_id": 34674,
                    "word_type": "v. t.",
                    "spelling_usage": "To render divine; to deify."
                },
                {
                    "spelling_detail_id": 34675,
                    "word_type": "v. i.",
                    "spelling_usage": "To use or practice divination; to foretell by\n   divination; to utter prognostications."
                },
                {
                    "spelling_detail_id": 34676,
                    "word_type": "v. i.",
                    "spelling_usage": "To have or feel a presage or foreboding."
                },
                {
                    "spelling_detail_id": 34677,
                    "word_type": "v. i.",
                    "spelling_usage": "To conjecture or guess; as, to divine rightly."
                }
            ]
        },
        {
            "id": 21679,
            "spelling_name": "Dodge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 34913,
                    "word_type": "v. i.",
                    "spelling_usage": "To start suddenly aside, as to avoid a blow or a missile;\n   to shift place by a sudden start."
                },
                {
                    "spelling_detail_id": 34914,
                    "word_type": "v. i.",
                    "spelling_usage": "To evade a duty by low craft; to practice mean shifts; to\n   use tricky devices; to play fast and loose; to quibble."
                },
                {
                    "spelling_detail_id": 34915,
                    "word_type": "v. t.",
                    "spelling_usage": "To evade by a sudden shift of place; to escape by\n   starting aside; as, to dodge a blow aimed or a ball thrown."
                },
                {
                    "spelling_detail_id": 34916,
                    "word_type": "v. t.",
                    "spelling_usage": "Fig.: To evade by craft; as, to dodge a question; to\n   dodge responsibility."
                },
                {
                    "spelling_detail_id": 34917,
                    "word_type": "v. t.",
                    "spelling_usage": "To follow by dodging, or suddenly shifting from place to\n   place."
                },
                {
                    "spelling_detail_id": 34918,
                    "word_type": "n.",
                    "spelling_usage": "The act of evading by some skillful movement; a sudden\n   starting aside; hence, an artful device to evade, deceive, or cheat; a\n   cunning trick; an artifice."
                }
            ]
        },
        {
            "id": 21831,
            "spelling_name": "Dome",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 35138,
                    "word_type": "n.",
                    "spelling_usage": "A building; a house; an edifice; -- used chiefly in poetry."
                },
                {
                    "spelling_detail_id": 35139,
                    "word_type": "n.",
                    "spelling_usage": "A cupola formed on a large scale."
                },
                {
                    "spelling_detail_id": 35140,
                    "word_type": "n.",
                    "spelling_usage": "Any erection resembling the dome or cupola of a building; as\n   the upper part of a furnace, the vertical steam chamber on the top of a\n   boiler, etc."
                },
                {
                    "spelling_detail_id": 35141,
                    "word_type": "n.",
                    "spelling_usage": "A prism formed by planes parallel to a lateral axis which\n   meet above in a horizontal edge, like the roof of a house; also, one of\n   the planes of such a form."
                },
                {
                    "spelling_detail_id": 35142,
                    "word_type": "n.",
                    "spelling_usage": "Decision; judgment; opinion; a court decision."
                }
            ]
        },
        {
            "id": 21874,
            "spelling_name": "Domineering",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 35202,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Domineer"
                },
                {
                    "spelling_detail_id": 35204,
                    "word_type": "a.",
                    "spelling_usage": "Ruling arrogantly; overbearing."
                }
            ]
        },
        {
            "id": 21881,
            "spelling_name": "Domino",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 35220,
                    "word_type": "n.",
                    "spelling_usage": "A kind of hood worn by the canons of a cathedral church; a\n   sort of amice."
                },
                {
                    "spelling_detail_id": 35221,
                    "word_type": "n.",
                    "spelling_usage": "A mourning veil formerly worn by women."
                },
                {
                    "spelling_detail_id": 35222,
                    "word_type": "n.",
                    "spelling_usage": "A kind of mask; particularly, a half mask worn at\n   masquerades, to conceal the upper part of the face. Dominos were\n   formerly worn by ladies in traveling."
                },
                {
                    "spelling_detail_id": 35223,
                    "word_type": "n.",
                    "spelling_usage": "A costume worn as a disguise at masquerades, consisting of\n   a robe with a hood adjustable at pleasure."
                },
                {
                    "spelling_detail_id": 35224,
                    "word_type": "n.",
                    "spelling_usage": "A person wearing a domino."
                },
                {
                    "spelling_detail_id": 35225,
                    "word_type": "n.",
                    "spelling_usage": "A game played by two or more persons, with twenty-eight\n   pieces of wood, bone, or ivory, of a flat, oblong shape, plain at the\n   back, but on the face divided by a line in the middle, and either left\n   blank or variously dotted after the manner of dice. The game is played\n   by matching the spots or the blank of an unmatched half of a domino\n   already played"
                },
                {
                    "spelling_detail_id": 35226,
                    "word_type": "n.",
                    "spelling_usage": "One of the pieces with which the game of dominoes is\n   played."
                }
            ]
        },
        {
            "id": 22045,
            "spelling_name": "Double",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 35472,
                    "word_type": "a.",
                    "spelling_usage": "Twofold; multiplied by two; increased by its equivalent;\n   made twice as large or as much, etc."
                },
                {
                    "spelling_detail_id": 35473,
                    "word_type": "a.",
                    "spelling_usage": "Being in pairs; presenting two of a kind, or two in a set\n   together; coupled."
                },
                {
                    "spelling_detail_id": 35474,
                    "word_type": "a.",
                    "spelling_usage": "Divided into two; acting two parts, one openly and the\n   other secretly; equivocal; deceitful; insincere."
                },
                {
                    "spelling_detail_id": 35475,
                    "word_type": "a.",
                    "spelling_usage": "Having the petals in a flower considerably increased beyond\n   the natural number, usually as the result of cultivation and the\n   expense of the stamens, or stamens and pistils. The white water lily\n   and some other plants have their blossoms naturally double."
                },
                {
                    "spelling_detail_id": 35476,
                    "word_type": "adv.",
                    "spelling_usage": "Twice; doubly."
                },
                {
                    "spelling_detail_id": 35479,
                    "word_type": "a.",
                    "spelling_usage": "To increase by adding an equal number, quantity, length,\n   value, or the like; multiply by two; to double a sum of money; to\n   double a number, or length."
                },
                {
                    "spelling_detail_id": 35480,
                    "word_type": "a.",
                    "spelling_usage": "To make of two thicknesses or folds by turning or bending\n   together in the middle; to fold one part upon another part of; as, to\n   double the leaf of a book, and the like; to clinch, as the fist; --\n   often followed by up; as, to double up a sheet of paper or cloth."
                },
                {
                    "spelling_detail_id": 35481,
                    "word_type": "a.",
                    "spelling_usage": "To be the double of; to exceed by twofold; to contain or be\n   worth twice as much as."
                },
                {
                    "spelling_detail_id": 35482,
                    "word_type": "a.",
                    "spelling_usage": "To pass around or by; to march or sail round, so as to\n   reverse the direction of motion."
                },
                {
                    "spelling_detail_id": 35483,
                    "word_type": "a.",
                    "spelling_usage": "To unite, as ranks or files, so as to form one from each\n   two."
                },
                {
                    "spelling_detail_id": 35484,
                    "word_type": "v. i.",
                    "spelling_usage": "To be increased to twice the sum, number, quantity,\n   length, or value; to increase or grow to twice as much."
                },
                {
                    "spelling_detail_id": 35485,
                    "word_type": "v. i.",
                    "spelling_usage": "To return upon one's track; to turn and go back over the\n   same ground, or in an opposite direction."
                },
                {
                    "spelling_detail_id": 35486,
                    "word_type": "v. i.",
                    "spelling_usage": "To play tricks; to use sleights; to play false."
                },
                {
                    "spelling_detail_id": 35487,
                    "word_type": "v. i.",
                    "spelling_usage": "To set up a word or words a second time by mistake; to\n   make a doublet."
                },
                {
                    "spelling_detail_id": 35488,
                    "word_type": "n.",
                    "spelling_usage": "Twice as much; twice the number, sum, quantity, length,\n   value, and the like."
                },
                {
                    "spelling_detail_id": 35489,
                    "word_type": "n.",
                    "spelling_usage": "Among compositors, a doublet (see Doublet, 2.); among\n   pressmen, a sheet that is twice pulled, and blurred."
                },
                {
                    "spelling_detail_id": 35490,
                    "word_type": "n.",
                    "spelling_usage": "That which is doubled over or together; a doubling; a\n   plait; a fold."
                },
                {
                    "spelling_detail_id": 35491,
                    "word_type": "n.",
                    "spelling_usage": "A turn or circuit in running to escape pursues; hence, a\n   trick; a shift; an artifice."
                },
                {
                    "spelling_detail_id": 35492,
                    "word_type": "n.",
                    "spelling_usage": "Something precisely equal or counterpart to another; a\n   counterpart. Hence, a wraith."
                },
                {
                    "spelling_detail_id": 35493,
                    "word_type": "n.",
                    "spelling_usage": "A player or singer who prepares to take the part of another\n   player in his absence; a substitute."
                },
                {
                    "spelling_detail_id": 35494,
                    "word_type": "n.",
                    "spelling_usage": "Double beer; strong beer."
                },
                {
                    "spelling_detail_id": 35495,
                    "word_type": "n.",
                    "spelling_usage": "A feast in which the antiphon is doubled, hat is, said\n   twice, before and after the Psalms, instead of only half being said, as\n   in simple feasts."
                },
                {
                    "spelling_detail_id": 35496,
                    "word_type": "n.",
                    "spelling_usage": "A game between two pairs of players; as, a first prize for\n   doubles."
                },
                {
                    "spelling_detail_id": 35497,
                    "word_type": "n.",
                    "spelling_usage": "An old term for a variation, as in Bach's Suites."
                }
            ]
        },
        {
            "id": 22105,
            "spelling_name": "Dough",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 35602,
                    "word_type": "n.",
                    "spelling_usage": "Paste of bread; a soft mass of moistened flour or meal,\n   kneaded or unkneaded, but not yet baked; as, to knead dough."
                },
                {
                    "spelling_detail_id": 35603,
                    "word_type": "n.",
                    "spelling_usage": "Anything of the consistency of such paste."
                }
            ]
        },
        {
            "id": 22404,
            "spelling_name": "Dream",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 36171,
                    "word_type": "n.",
                    "spelling_usage": "The thoughts, or series of thoughts, or imaginary\n   transactions, which occupy the mind during sleep; a sleeping vision."
                },
                {
                    "spelling_detail_id": 36172,
                    "word_type": "n.",
                    "spelling_usage": "A visionary scheme; a wild conceit; an idle fancy; a vagary;\n   a revery; -- in this sense, applied to an imaginary or anticipated\n   state of happiness; as, a dream of bliss; the dream of his youth."
                },
                {
                    "spelling_detail_id": 36176,
                    "word_type": "n.",
                    "spelling_usage": "To have ideas or images in the mind while in the state of\n   sleep; to experience sleeping visions; -- often with of; as, to dream\n   of a battle, or of an absent friend."
                },
                {
                    "spelling_detail_id": 36177,
                    "word_type": "n.",
                    "spelling_usage": "To let the mind run on in idle revery or vagary; to\n   anticipate vaguely as a coming and happy reality; to have a visionary\n   notion or idea; to imagine."
                },
                {
                    "spelling_detail_id": 36178,
                    "word_type": "v. t.",
                    "spelling_usage": "To have a dream of; to see, or have a vision of, in\n   sleep, or in idle fancy; -- often followed by an objective clause."
                }
            ]
        },
        {
            "id": 22525,
            "spelling_name": "Drizzle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 36459,
                    "word_type": "v. i.",
                    "spelling_usage": "To rain slightly in very small drops; to fall, as water\n   from the clouds, slowly and in fine particles; as, it drizzles;\n   drizzling drops or rain."
                },
                {
                    "spelling_detail_id": 36460,
                    "word_type": "v. t.",
                    "spelling_usage": "To shed slowly in minute drops or particles."
                },
                {
                    "spelling_detail_id": 36461,
                    "word_type": "n.",
                    "spelling_usage": "Fine rain or mist."
                }
            ]
        },
        {
            "id": 22911,
            "spelling_name": "Duo",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 37104,
                    "word_type": "n.",
                    "spelling_usage": "A composition for two performers; a duet."
                }
            ]
        },
        {
            "id": 22980,
            "spelling_name": "Dusk",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 37194,
                    "word_type": "a.",
                    "spelling_usage": "Tending to darkness or blackness; moderately dark or black;\n   dusky."
                },
                {
                    "spelling_detail_id": 37195,
                    "word_type": "n.",
                    "spelling_usage": "Imperfect obscurity; a middle degree between light and\n   darkness; twilight; as, the dusk of the evening."
                },
                {
                    "spelling_detail_id": 37196,
                    "word_type": "n.",
                    "spelling_usage": "A darkish color."
                },
                {
                    "spelling_detail_id": 37197,
                    "word_type": "v. t.",
                    "spelling_usage": "To make dusk."
                },
                {
                    "spelling_detail_id": 37198,
                    "word_type": "v. i.",
                    "spelling_usage": "To grow dusk."
                }
            ]
        },
        {
            "id": 23035,
            "spelling_name": "Dwindled",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 37291,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Dwindle"
                }
            ]
        },
        {
            "id": 23127,
            "spelling_name": "Each",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 37400,
                    "word_type": "a. / a. pron.",
                    "spelling_usage": "Every one of the two or more individuals composing\n   a number of objects, considered separately from the rest. It is used\n   either with or without a following noun; as, each of you or each one of\n   you."
                },
                {
                    "spelling_detail_id": 37401,
                    "word_type": "a. / a. pron.",
                    "spelling_usage": "Every; -- sometimes used interchangeably with\n   every."
                }
            ]
        },
        {
            "id": 23269,
            "spelling_name": "Eavesdrop",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 37666,
                    "word_type": "v. i.",
                    "spelling_usage": "To stand under the eaves, near a window or at the\n   door, of a house, to listen and learn what is said within doors; hence,\n   to listen secretly to what is said in private."
                },
                {
                    "spelling_detail_id": 37667,
                    "word_type": "n.",
                    "spelling_usage": "The water which falls in drops from the eaves of a\n   house."
                }
            ]
        },
        {
            "id": 23577,
            "spelling_name": "Eel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 38092,
                    "word_type": "n.",
                    "spelling_usage": "An elongated fish of many genera and species. The common eels\n   of Europe and America belong to the genus Anguilla. The electrical eel\n   is a species of Gymnotus. The so called vinegar eel is a minute\n   nematode worm. See Conger eel, Electric eel, and Gymnotus."
                }
            ]
        },
        {
            "id": 23682,
            "spelling_name": "Effortless",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 38239,
                    "word_type": "a.",
                    "spelling_usage": "Making no effort."
                }
            ]
        },
        {
            "id": 23736,
            "spelling_name": "Eggplant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 38309,
                    "word_type": "n.",
                    "spelling_usage": "A plant (Solanum Melongena), of East Indian origin,\n   allied to the tomato, and bearing a large, smooth, edible fruit, shaped\n   somewhat like an egg; mad-apple."
                }
            ]
        },
        {
            "id": 23819,
            "spelling_name": "Either",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 38422,
                    "word_type": "a. & pron.",
                    "spelling_usage": "One of two; the one or the other; -- properly used\n   of two things, but sometimes of a larger number, for any one."
                },
                {
                    "spelling_detail_id": 38423,
                    "word_type": "a. & pron.",
                    "spelling_usage": "Each of two; the one and the other; both; --\n   formerly, also, each of any number."
                },
                {
                    "spelling_detail_id": 38424,
                    "word_type": "conj. Either",
                    "spelling_usage": "precedes two, or more, coordinate words or\n   phrases, and is introductory to an alternative. It is correlative to\n   or."
                }
            ]
        },
        {
            "id": 23919,
            "spelling_name": "Elderly",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 38570,
                    "word_type": "a.",
                    "spelling_usage": "Somewhat old; advanced beyond middle age; bordering on old\n   age; as, elderly people."
                }
            ]
        },
        {
            "id": 24239,
            "spelling_name": "Else",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 39041,
                    "word_type": "a. & pron.",
                    "spelling_usage": "Other; one or something beside; as, Who else is\n   coming? What else shall I give? Do you expect anything else?"
                },
                {
                    "spelling_detail_id": 39042,
                    "word_type": "adv. & conj.",
                    "spelling_usage": "Besides; except that mentioned; in addition; as,\n   nowhere else; no one else."
                },
                {
                    "spelling_detail_id": 39043,
                    "word_type": "adv. & conj.",
                    "spelling_usage": "Otherwise; in the other, or the contrary, case; if\n   the facts were different."
                }
            ]
        },
        {
            "id": 24560,
            "spelling_name": "Emerge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 39470,
                    "word_type": "v. i.",
                    "spelling_usage": "To rise out of a fluid; to come forth from that in which\n   anything has been plunged, enveloped, or concealed; to issue and\n   appear; as, to emerge from the water or the ocean; the sun emerges from\n   behind the moon in an eclipse; to emerge from poverty or obscurity."
                }
            ]
        },
        {
            "id": 24641,
            "spelling_name": "Emotional",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 39571,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to, or characterized by, emotion; excitable;\n   easily moved; sensational; as, an emotional nature."
                }
            ]
        },
        {
            "id": 24671,
            "spelling_name": "Emperor",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 39607,
                    "word_type": "n.",
                    "spelling_usage": "The sovereign or supreme monarch of an empire; -- a title\n   of dignity superior to that of king; as, the emperor of Germany or of\n   Austria; the emperor or Czar of Russia."
                }
            ]
        },
        {
            "id": 24741,
            "spelling_name": "Empty",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 39703,
                    "word_type": "superl.",
                    "spelling_usage": "Containing nothing; not holding or having anything\n   within; void of contents or appropriate contents; not filled; -- said\n   of an inclosure, as a box, room, house, etc.; as, an empty chest, room,\n   purse, or pitcher; an empty stomach; empty shackles."
                },
                {
                    "spelling_detail_id": 39704,
                    "word_type": "superl.",
                    "spelling_usage": "Free; clear; devoid; -- often with of."
                },
                {
                    "spelling_detail_id": 39705,
                    "word_type": "superl.",
                    "spelling_usage": "Having nothing to carry; unburdened."
                },
                {
                    "spelling_detail_id": 39706,
                    "word_type": "superl.",
                    "spelling_usage": "Destitute of effect, sincerity, or sense; -- said of\n   language; as, empty words, or threats."
                },
                {
                    "spelling_detail_id": 39707,
                    "word_type": "superl.",
                    "spelling_usage": "Unable to satisfy; unsatisfactory; hollow; vain; --\n   said of pleasure, the world, etc."
                },
                {
                    "spelling_detail_id": 39708,
                    "word_type": "superl.",
                    "spelling_usage": "Producing nothing; unfruitful; -- said of a plant or\n   tree; as, an empty vine."
                },
                {
                    "spelling_detail_id": 39709,
                    "word_type": "superl.",
                    "spelling_usage": "Destitute of, or lacking, sense, knowledge, or\n   courtesy; as, empty brains; an empty coxcomb."
                },
                {
                    "spelling_detail_id": 39710,
                    "word_type": "superl.",
                    "spelling_usage": "Destitute of reality, or real existence; unsubstantial;\n   as, empty dreams."
                },
                {
                    "spelling_detail_id": 39712,
                    "word_type": "n.",
                    "spelling_usage": "An empty box, crate, cask, etc.; -- used in commerce, esp.\n   in transportation of freight; as, \"special rates for empties.\""
                },
                {
                    "spelling_detail_id": 39715,
                    "word_type": "v. t.",
                    "spelling_usage": "To deprive of the contents; to exhaust; to make void or\n   destitute; to make vacant; to pour out; to discharge; as, to empty a\n   vessel; to empty a well or a cistern."
                },
                {
                    "spelling_detail_id": 39716,
                    "word_type": "v. i.",
                    "spelling_usage": "To discharge itself; as, a river empties into the ocean."
                },
                {
                    "spelling_detail_id": 39717,
                    "word_type": "v. i.",
                    "spelling_usage": "To become empty."
                }
            ]
        },
        {
            "id": 25239,
            "spelling_name": "Enfranchise",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 40351,
                    "word_type": "v. t.",
                    "spelling_usage": "To set free; to liberate from slavery, prison, or\n   any binding power."
                },
                {
                    "spelling_detail_id": 40352,
                    "word_type": "v. t.",
                    "spelling_usage": "To endow with a franchise; to incorporate into a\n   body politic and thus to invest with civil and political privileges; to\n   admit to the privileges of a freeman."
                },
                {
                    "spelling_detail_id": 40353,
                    "word_type": "v. t.",
                    "spelling_usage": "To receive as denizens; to naturalize; as, to\n   enfranchise foreign words."
                }
            ]
        },
        {
            "id": 25672,
            "spelling_name": "Entire",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 40976,
                    "word_type": "a.",
                    "spelling_usage": "Complete in all parts; undivided; undiminished; whole; full\n   and perfect; not deficient; as, the entire control of a business;\n   entire confidence, ignorance."
                },
                {
                    "spelling_detail_id": 40977,
                    "word_type": "a.",
                    "spelling_usage": "Without mixture or alloy of anything; unqualified; morally\n   whole; pure; faithful."
                },
                {
                    "spelling_detail_id": 40978,
                    "word_type": "a.",
                    "spelling_usage": "Consisting of a single piece, as a corolla."
                },
                {
                    "spelling_detail_id": 40979,
                    "word_type": "a.",
                    "spelling_usage": "Having an evenly continuous edge, as a leaf which has no\n   kind of teeth."
                },
                {
                    "spelling_detail_id": 40980,
                    "word_type": "a.",
                    "spelling_usage": "Not gelded; -- said of a horse."
                },
                {
                    "spelling_detail_id": 40981,
                    "word_type": "a.",
                    "spelling_usage": "Internal; interior."
                },
                {
                    "spelling_detail_id": 40982,
                    "word_type": "n.",
                    "spelling_usage": "Entirely."
                },
                {
                    "spelling_detail_id": 40983,
                    "word_type": "n.",
                    "spelling_usage": "A name originally given to a kind of beer combining\n   qualities of different kinds of beer."
                }
            ]
        },
        {
            "id": 25750,
            "spelling_name": "Entrance",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 41082,
                    "word_type": "n.",
                    "spelling_usage": "The act of entering or going into; ingress; as, the\n   entrance of a person into a house or an apartment; hence, the act of\n   taking possession, as of property, or of office; as, the entrance of an\n   heir upon his inheritance, or of a magistrate into office."
                },
                {
                    "spelling_detail_id": 41083,
                    "word_type": "n.",
                    "spelling_usage": "Liberty, power, or permission to enter; as, to give\n   entrance to friends."
                },
                {
                    "spelling_detail_id": 41084,
                    "word_type": "n.",
                    "spelling_usage": "The passage, door, or gate, for entering."
                },
                {
                    "spelling_detail_id": 41085,
                    "word_type": "n.",
                    "spelling_usage": "The entering upon; the beginning, or that with which the\n   beginning is made; the commencement; initiation; as, a difficult\n   entrance into business."
                },
                {
                    "spelling_detail_id": 41086,
                    "word_type": "n.",
                    "spelling_usage": "The causing to be entered upon a register, as a ship or\n   goods, at a customhouse; an entering; as, his entrance of the arrival\n   was made the same day."
                },
                {
                    "spelling_detail_id": 41087,
                    "word_type": "n.",
                    "spelling_usage": "The angle which the bow of a vessel makes with the water\n   at the water line."
                },
                {
                    "spelling_detail_id": 41088,
                    "word_type": "n.",
                    "spelling_usage": "The bow, or entire wedgelike forepart of a vessel, below\n   the water line."
                },
                {
                    "spelling_detail_id": 41091,
                    "word_type": "v. t.",
                    "spelling_usage": "To put into a trance; to make insensible to present\n   objects."
                },
                {
                    "spelling_detail_id": 41092,
                    "word_type": "v. t.",
                    "spelling_usage": "To put into an ecstasy; to ravish with delight or\n   wonder; to enrapture; to charm."
                }
            ]
        },
        {
            "id": 25843,
            "spelling_name": "Envoy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 41218,
                    "word_type": "n.",
                    "spelling_usage": "One dispatched upon an errand or mission; a messenger; esp.,\n   a person deputed by a sovereign or a government to negotiate a treaty,\n   or transact other business, with a foreign sovereign or government; a\n   minister accredited to a foreign government. An envoy's rank is below\n   that of an ambassador."
                },
                {
                    "spelling_detail_id": 41219,
                    "word_type": "n.",
                    "spelling_usage": "An explanatory or commendatory postscript to a poem, essay,\n   or book; -- also in the French from, l'envoi."
                }
            ]
        },
        {
            "id": 25947,
            "spelling_name": "Epic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 41355,
                    "word_type": "a.",
                    "spelling_usage": "Narrated in a grand style; pertaining to or designating a\n   kind of narrative poem, usually called an heroic poem, in which real or\n   fictitious events, usually the achievements of some hero, are narrated\n   in an elevated style."
                },
                {
                    "spelling_detail_id": 41356,
                    "word_type": "n.",
                    "spelling_usage": "An epic or heroic poem. See Epic, a."
                }
            ]
        },
        {
            "id": 26393,
            "spelling_name": "Erase",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 41941,
                    "word_type": "v. t.",
                    "spelling_usage": "To rub or scrape out, as letters or characters written,\n   engraved, or painted; to efface; to expunge; to cross out; as, to erase\n   a word or a name."
                },
                {
                    "spelling_detail_id": 41942,
                    "word_type": "v. t.",
                    "spelling_usage": "Fig.: To obliterate; to expunge; to blot out; -- used of\n   ideas in the mind or memory."
                }
            ]
        },
        {
            "id": 26480,
            "spelling_name": "Erode",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 42063,
                    "word_type": "v. t.",
                    "spelling_usage": "To eat into or away; to corrode; as, canker erodes the\n   flesh."
                }
            ]
        },
        {
            "id": 26756,
            "spelling_name": "Esteem",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 42470,
                    "word_type": "v. t.",
                    "spelling_usage": "To set a value on; to appreciate the worth of; to\n   estimate; to value; to reckon."
                },
                {
                    "spelling_detail_id": 42471,
                    "word_type": "v. t.",
                    "spelling_usage": "To set a high value on; to prize; to regard with\n   reverence, respect, or friendship."
                },
                {
                    "spelling_detail_id": 42472,
                    "word_type": "v. i.",
                    "spelling_usage": "To form an estimate; to have regard to the value; to\n   consider."
                },
                {
                    "spelling_detail_id": 42473,
                    "word_type": "v. t.",
                    "spelling_usage": "Estimation; opinion of merit or value; hence, valuation;\n   reckoning; price."
                },
                {
                    "spelling_detail_id": 42474,
                    "word_type": "v. t.",
                    "spelling_usage": "High estimation or value; great regard; favorable\n   opinion, founded on supposed worth."
                }
            ]
        },
        {
            "id": 26771,
            "spelling_name": "Estimate",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 42492,
                    "word_type": "v. t.",
                    "spelling_usage": "To judge and form an opinion of the value of, from\n   imperfect data, -- either the extrinsic (money), or intrinsic (moral),\n   value; to fix the worth of roughly or in a general way; as, to estimate\n   the value of goods or land; to estimate the worth or talents of a\n   person."
                },
                {
                    "spelling_detail_id": 42493,
                    "word_type": "v. t.",
                    "spelling_usage": "To from an opinion of, as to amount,, number, etc.,\n   from imperfect data, comparison, or experience; to make an estimate of;\n   to calculate roughly; to rate; as, to estimate the cost of a trip, the\n   number of feet in a piece of land."
                },
                {
                    "spelling_detail_id": 42494,
                    "word_type": "n.",
                    "spelling_usage": "A valuing or rating by the mind, without actually\n   measuring, weighing, or the like; rough or approximate calculation; as,\n   an estimate of the cost of a building, or of the quantity of water in a\n   pond."
                }
            ]
        },
        {
            "id": 26838,
            "spelling_name": "Eternity",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 42590,
                    "word_type": "n.",
                    "spelling_usage": "Infinite duration, without beginning in the past or end\n   in the future; also, duration without end in the future; endless time."
                },
                {
                    "spelling_detail_id": 42591,
                    "word_type": "n.",
                    "spelling_usage": "Condition which begins at death; immortality."
                }
            ]
        },
        {
            "id": 27261,
            "spelling_name": "Evince",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 43144,
                    "word_type": "v. t.",
                    "spelling_usage": "To conquer; to subdue."
                },
                {
                    "spelling_detail_id": 43145,
                    "word_type": "v. t.",
                    "spelling_usage": "To show in a clear manner; to prove beyond any\n   reasonable doubt; to manifest; to make evident; to bring to light; to\n   evidence."
                }
            ]
        },
        {
            "id": 27307,
            "spelling_name": "Ewe",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 43200,
                    "word_type": "n.",
                    "spelling_usage": "The female of the sheep, and of sheeplike animals."
                }
            ]
        },
        {
            "id": 28041,
            "spelling_name": "Explosive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 44284,
                    "word_type": "a.",
                    "spelling_usage": "Driving or bursting out with violence and noise; causing\n   explosion; as, the explosive force of gunpowder."
                },
                {
                    "spelling_detail_id": 44285,
                    "word_type": "n.",
                    "spelling_usage": "An explosive agent; a compound or mixture susceptible of\n   a rapid chemical reaction, as gunpowder, or nitro-glycerine."
                },
                {
                    "spelling_detail_id": 44286,
                    "word_type": "n.",
                    "spelling_usage": "A sound produced by an explosive impulse of the breath;\n   (Phonetics) one of consonants p, b, t, d, k, g, which are sounded with\n   a sort of explosive power of voice. [See Guide to Pronunciation, Ã\n   155-7, 184.]"
                }
            ]
        },
        {
            "id": 28239,
            "spelling_name": "Extinct",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 44574,
                    "word_type": "a.",
                    "spelling_usage": "Extinguished; put out; quenched; as, a fire, a light, or a\n   lamp, is extinct; an extinct volcano."
                },
                {
                    "spelling_detail_id": 44575,
                    "word_type": "a.",
                    "spelling_usage": "Without a survivor; without force; dead; as, a family\n   becomes extinct; an extinct feud or law."
                },
                {
                    "spelling_detail_id": 44576,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause to be extinct."
                }
            ]
        },
        {
            "id": 28448,
            "spelling_name": "Eyelet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 44871,
                    "word_type": "n.",
                    "spelling_usage": "A small hole or perforation to receive a cord or fastener,\n   as in garments, sails, etc."
                },
                {
                    "spelling_detail_id": 44872,
                    "word_type": "n.",
                    "spelling_usage": "A metal ring or grommet, or short metallic tube, the ends\n   of which can be bent outward and over to fasten it in place; -- used to\n   line an eyelet hole."
                }
            ]
        },
        {
            "id": 28617,
            "spelling_name": "Failure",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45185,
                    "word_type": "n.",
                    "spelling_usage": "Cessation of supply, or total defect; a failing;\n   deficiency; as, failure of rain; failure of crops."
                },
                {
                    "spelling_detail_id": 45186,
                    "word_type": "n.",
                    "spelling_usage": "Omission; nonperformance; as, the failure to keep a\n   promise."
                },
                {
                    "spelling_detail_id": 45187,
                    "word_type": "n.",
                    "spelling_usage": "Want of success; the state of having failed."
                },
                {
                    "spelling_detail_id": 45188,
                    "word_type": "n.",
                    "spelling_usage": "Decay, or defect from decay; deterioration; as, the\n   failure of memory or of sight."
                },
                {
                    "spelling_detail_id": 45189,
                    "word_type": "n.",
                    "spelling_usage": "A becoming insolvent; bankruptcy; suspension of payment;\n   as, failure in business."
                },
                {
                    "spelling_detail_id": 45190,
                    "word_type": "n.",
                    "spelling_usage": "A failing; a slight fault."
                }
            ]
        },
        {
            "id": 28630,
            "spelling_name": "Fair",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45219,
                    "word_type": "superl.",
                    "spelling_usage": "Free from spots, specks, dirt, or imperfection;\n   unblemished; clean; pure."
                },
                {
                    "spelling_detail_id": 45220,
                    "word_type": "superl.",
                    "spelling_usage": "Pleasing to the eye; handsome; beautiful."
                },
                {
                    "spelling_detail_id": 45221,
                    "word_type": "superl.",
                    "spelling_usage": "Without a dark hue; light; clear; as, a fair skin."
                },
                {
                    "spelling_detail_id": 45222,
                    "word_type": "superl.",
                    "spelling_usage": "Not overcast; cloudless; clear; pleasant; propitious;\n   favorable; -- said of the sky, weather, or wind, etc.; as, a fair sky;\n   a fair day."
                },
                {
                    "spelling_detail_id": 45223,
                    "word_type": "superl.",
                    "spelling_usage": "Free from obstacles or hindrances; unobstructed;\n   unincumbered; open; direct; -- said of a road, passage, etc.; as, a\n   fair mark; in fair sight; a fair view."
                },
                {
                    "spelling_detail_id": 45224,
                    "word_type": "superl.",
                    "spelling_usage": "Without sudden change of direction or curvature; smooth;\n   fowing; -- said of the figure of a vessel, and of surfaces, water\n   lines, and other lines."
                },
                {
                    "spelling_detail_id": 45225,
                    "word_type": "superl.",
                    "spelling_usage": "Characterized by frankness, honesty, impartiality, or\n   candor; open; upright; free from suspicion or bias; equitable; just; --\n   said of persons, character, or conduct; as, a fair man; fair dealing; a\n   fair statement."
                },
                {
                    "spelling_detail_id": 45226,
                    "word_type": "superl.",
                    "spelling_usage": "Pleasing; favorable; inspiring hope and confidence; --\n   said of words, promises, etc."
                },
                {
                    "spelling_detail_id": 45227,
                    "word_type": "superl.",
                    "spelling_usage": "Distinct; legible; as, fair handwriting."
                },
                {
                    "spelling_detail_id": 45228,
                    "word_type": "superl.",
                    "spelling_usage": "Free from any marked characteristic; average; middling;\n   as, a fair specimen."
                },
                {
                    "spelling_detail_id": 45229,
                    "word_type": "adv.",
                    "spelling_usage": "Clearly; openly; frankly; civilly; honestly; favorably;\n   auspiciously; agreeably."
                },
                {
                    "spelling_detail_id": 45230,
                    "word_type": "n.",
                    "spelling_usage": "Fairness, beauty."
                },
                {
                    "spelling_detail_id": 45231,
                    "word_type": "n.",
                    "spelling_usage": "A fair woman; a sweetheart."
                },
                {
                    "spelling_detail_id": 45232,
                    "word_type": "n.",
                    "spelling_usage": "Good fortune; good luck."
                },
                {
                    "spelling_detail_id": 45233,
                    "word_type": "v. t.",
                    "spelling_usage": "To make fair or beautiful."
                },
                {
                    "spelling_detail_id": 45234,
                    "word_type": "v. t.",
                    "spelling_usage": "To make smooth and flowing, as a vessel's lines."
                },
                {
                    "spelling_detail_id": 45235,
                    "word_type": "n.",
                    "spelling_usage": "A gathering of buyers and sellers, assembled at a particular\n   place with their merchandise at a stated or regular season, or by\n   special appointment, for trade."
                },
                {
                    "spelling_detail_id": 45236,
                    "word_type": "n.",
                    "spelling_usage": "A festival, and sale of fancy articles. erc., usually for\n   some charitable object; as, a Grand Army fair."
                },
                {
                    "spelling_detail_id": 45237,
                    "word_type": "n.",
                    "spelling_usage": "A competitive exhibition of wares, farm products, etc., not\n   primarily for purposes of sale; as, the Mechanics' fair; an\n   agricultural fair."
                }
            ]
        },
        {
            "id": 28758,
            "spelling_name": "Famous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45508,
                    "word_type": "a.",
                    "spelling_usage": "Celebrated in fame or public report; renowned; mach talked\n   of; distinguished in story; -- used in either a good or a bad sense,\n   chiefly the former; often followed by for; as, famous for erudition,\n   for eloquence, for military skill; a famous pirate."
                }
            ]
        },
        {
            "id": 28821,
            "spelling_name": "Fantastically",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45618,
                    "word_type": "adv.",
                    "spelling_usage": "In a fantastic manner."
                }
            ]
        },
        {
            "id": 28860,
            "spelling_name": "Farewell",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45690,
                    "word_type": "interj.",
                    "spelling_usage": "Go well; good-by; adieu; -- originally applied to a\n   person departing, but by custom now applied both to those who depart\n   and those who remain. It is often separated by the pronoun; as, fare\n   you well; and is sometimes used as an expression of separation only;\n   as, farewell the year; farewell, ye sweet groves; that is, I bid you\n   farewell."
                },
                {
                    "spelling_detail_id": 45691,
                    "word_type": "n.",
                    "spelling_usage": "A wish of happiness or welfare at parting; the parting\n   compliment; a good-by; adieu."
                },
                {
                    "spelling_detail_id": 45692,
                    "word_type": "n.",
                    "spelling_usage": "Act of departure; leave-taking; a last look at, or\n   reference to something."
                },
                {
                    "spelling_detail_id": 45693,
                    "word_type": "a.",
                    "spelling_usage": "Parting; valedictory; final; as, a farewell discourse;\n   his farewell bow."
                }
            ]
        },
        {
            "id": 28863,
            "spelling_name": "Farfetched",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45697,
                    "word_type": "a.",
                    "spelling_usage": "Brought from far, or from a remote place."
                },
                {
                    "spelling_detail_id": 45698,
                    "word_type": "a.",
                    "spelling_usage": "Studiously sought; not easily or naturally deduced or\n   introduced; forced; strained."
                }
            ]
        },
        {
            "id": 28989,
            "spelling_name": "Father",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 45929,
                    "word_type": "n.",
                    "spelling_usage": "One who has begotten a child, whether son or daughter; a\n   generator; a male parent."
                },
                {
                    "spelling_detail_id": 45930,
                    "word_type": "n.",
                    "spelling_usage": "A male ancestor more remote than a parent; a progenitor;\n   especially, a first ancestor; a founder of a race or family; -- in the\n   plural, fathers, ancestors."
                },
                {
                    "spelling_detail_id": 45931,
                    "word_type": "n.",
                    "spelling_usage": "One who performs the offices of a parent by maintenance,\n   affetionate care, counsel, or protection."
                },
                {
                    "spelling_detail_id": 45932,
                    "word_type": "n.",
                    "spelling_usage": "A respectful mode of address to an old man."
                },
                {
                    "spelling_detail_id": 45933,
                    "word_type": "n.",
                    "spelling_usage": "A senator of ancient Rome."
                },
                {
                    "spelling_detail_id": 45934,
                    "word_type": "n.",
                    "spelling_usage": "A dignitary of the church, a superior of a convent, a\n   confessor (called also father confessor), or a priest; also, the eldest\n   member of a profession, or of a legislative assembly, etc."
                },
                {
                    "spelling_detail_id": 45935,
                    "word_type": "n.",
                    "spelling_usage": "One of the chief esslesiastical authorities of the first\n   centuries after Christ; -- often spoken of collectively as the Fathers;\n   as, the Latin, Greek, or apostolic Fathers."
                },
                {
                    "spelling_detail_id": 45936,
                    "word_type": "n.",
                    "spelling_usage": "One who, or that which, gives origin; an originator; a\n   producer, author, or contriver; the first to practice any art,\n   profession, or occupation; a distinguished example or teacher."
                },
                {
                    "spelling_detail_id": 45937,
                    "word_type": "n.",
                    "spelling_usage": "The Supreme Being and Creator; God; in theology, the first\n   person in the Trinity."
                },
                {
                    "spelling_detail_id": 45940,
                    "word_type": "v. t.",
                    "spelling_usage": "To make one's self the father of; to beget."
                },
                {
                    "spelling_detail_id": 45941,
                    "word_type": "v. t.",
                    "spelling_usage": "To take as one's own child; to adopt; hence, to assume\n   as one's own work; to acknowledge one's self author of or responsible\n   for (a statement, policy, etc.)."
                },
                {
                    "spelling_detail_id": 45942,
                    "word_type": "v. t.",
                    "spelling_usage": "To provide with a father."
                }
            ]
        },
        {
            "id": 29040,
            "spelling_name": "Faucet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 46010,
                    "word_type": "n.",
                    "spelling_usage": "A fixture for drawing a liquid, as water, molasses, oil,\n   etc., from a pipe, cask, or other vessel, in such quantities as may be\n   desired; -- called also tap, and cock. It consists of a tubular spout,\n   stopped with a movable plug, spigot, valve, or slide."
                },
                {
                    "spelling_detail_id": 46011,
                    "word_type": "n.",
                    "spelling_usage": "The enlarged end of a section of pipe which receives the\n   spigot end of the next section."
                }
            ]
        },
        {
            "id": 29088,
            "spelling_name": "Favorite",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 46091,
                    "word_type": "n.",
                    "spelling_usage": "A person or thing regarded with peculiar favor; one\n   treated with partiality; one preferred above others; especially, one\n   unduly loved, trusted, and enriched with favors by a person of high\n   rank or authority."
                },
                {
                    "spelling_detail_id": 46092,
                    "word_type": "n.",
                    "spelling_usage": "Short curls dangling over the temples; -- fashionable in\n   the reign of Charles II."
                },
                {
                    "spelling_detail_id": 46093,
                    "word_type": "n.",
                    "spelling_usage": "The competitor (as a horse in a race) that is judged most\n   likely to win; the competitor standing highest in the betting."
                },
                {
                    "spelling_detail_id": 46094,
                    "word_type": "a.",
                    "spelling_usage": "Regarded with particular affection, esteem, or\n   preference; as, a favorite walk; a favorite child."
                }
            ]
        },
        {
            "id": 29195,
            "spelling_name": "Fed",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 46276,
                    "word_type": "",
                    "spelling_usage": "imp. & p. p. of Feed."
                },
                {
                    "spelling_detail_id": 46308,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Feed"
                }
            ]
        },
        {
            "id": 29277,
            "spelling_name": "Fellowship",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 46460,
                    "word_type": "n.",
                    "spelling_usage": "The state or relation of being or associate."
                },
                {
                    "spelling_detail_id": 46461,
                    "word_type": "n.",
                    "spelling_usage": "Companionship of persons on equal and friendly terms;\n   frequent and familiar intercourse."
                },
                {
                    "spelling_detail_id": 46462,
                    "word_type": "n.",
                    "spelling_usage": "A state of being together; companionship; partnership;\n   association; hence, confederation; joint interest."
                },
                {
                    "spelling_detail_id": 46463,
                    "word_type": "n.",
                    "spelling_usage": "Those associated with one, as in a family, or a\n   society; a company."
                },
                {
                    "spelling_detail_id": 46464,
                    "word_type": "n.",
                    "spelling_usage": "A foundation for the maintenance, on certain\n   conditions, of a scholar called a fellow, who usually resides at the\n   university."
                },
                {
                    "spelling_detail_id": 46465,
                    "word_type": "n.",
                    "spelling_usage": "The rule for dividing profit and loss among partners;\n   -- called also partnership, company, and distributive proportion."
                },
                {
                    "spelling_detail_id": 46468,
                    "word_type": "v. t.",
                    "spelling_usage": "To acknowledge as of good standing, or in communion\n   according to standards of faith and practice; to admit to Christian\n   fellowship."
                }
            ]
        },
        {
            "id": 29297,
            "spelling_name": "Felt",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 46345,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Feel"
                },
                {
                    "spelling_detail_id": 46493,
                    "word_type": "",
                    "spelling_usage": "imp. & p. p. / a. from Feel."
                },
                {
                    "spelling_detail_id": 46494,
                    "word_type": "n.",
                    "spelling_usage": "A cloth or stuff made of matted fibers of wool, or wool and\n   fur, fulled or wrought into a compact substance by rolling and\n   pressure, with lees or size, without spinning or weaving."
                },
                {
                    "spelling_detail_id": 46495,
                    "word_type": "n.",
                    "spelling_usage": "A hat made of felt."
                },
                {
                    "spelling_detail_id": 46496,
                    "word_type": "n.",
                    "spelling_usage": "A skin or hide; a fell; a pelt."
                },
                {
                    "spelling_detail_id": 46499,
                    "word_type": "v. t.",
                    "spelling_usage": "To make into felt, or a feltike substance; to cause to\n   adhere and mat together."
                },
                {
                    "spelling_detail_id": 46500,
                    "word_type": "v. t.",
                    "spelling_usage": "To cover with, or as with, felt; as, to felt the cylinder\n   of a steam emgine."
                }
            ]
        },
        {
            "id": 29419,
            "spelling_name": "Ferocious",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 46678,
                    "word_type": "a.",
                    "spelling_usage": "Fierce; savage; wild; indicating cruelty; ravenous;\n   rapacious; as, ferocious look or features; a ferocious lion."
                }
            ]
        },
        {
            "id": 29899,
            "spelling_name": "Fine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 47470,
                    "word_type": "superl.",
                    "spelling_usage": "Finished; brought to perfection; refined; hence, free\n   from impurity; excellent; superior; elegant; worthy of admiration;\n   accomplished; beautiful."
                },
                {
                    "spelling_detail_id": 47471,
                    "word_type": "superl.",
                    "spelling_usage": "Aiming at show or effect; loaded with ornament;\n   overdressed or overdecorated; showy."
                },
                {
                    "spelling_detail_id": 47472,
                    "word_type": "superl.",
                    "spelling_usage": "Nice; delicate; subtle; exquisite; artful; skillful;\n   dexterous."
                },
                {
                    "spelling_detail_id": 47473,
                    "word_type": "superl.",
                    "spelling_usage": "Not coarse, gross, or heavy"
                },
                {
                    "spelling_detail_id": 47474,
                    "word_type": "superl.",
                    "spelling_usage": "Not gross; subtile; thin; tenous."
                },
                {
                    "spelling_detail_id": 47475,
                    "word_type": "superl.",
                    "spelling_usage": "Not coarse; comminuted; in small particles; as, fine\n   sand or flour."
                },
                {
                    "spelling_detail_id": 47476,
                    "word_type": "superl.",
                    "spelling_usage": "Not thick or heavy; slender; filmy; as, a fine thread."
                },
                {
                    "spelling_detail_id": 47477,
                    "word_type": "superl.",
                    "spelling_usage": "Thin; attenuate; keen; as, a fine edge."
                },
                {
                    "spelling_detail_id": 47478,
                    "word_type": "superl.",
                    "spelling_usage": "Made of fine materials; light; delicate; as, fine linen\n   or silk."
                },
                {
                    "spelling_detail_id": 47479,
                    "word_type": "superl.",
                    "spelling_usage": "Having (such) a proportion of pure metal in its\n   composition; as, coins nine tenths fine."
                },
                {
                    "spelling_detail_id": 47480,
                    "word_type": "superl.",
                    "spelling_usage": "(Used ironically.)"
                },
                {
                    "spelling_detail_id": 47483,
                    "word_type": "a.",
                    "spelling_usage": "To make fine; to refine; to purify, to clarify; as, to fine\n   gold."
                },
                {
                    "spelling_detail_id": 47484,
                    "word_type": "a.",
                    "spelling_usage": "To make finer, or less coarse, as in bulk, texture, etc.; as.\n   to fine the soil."
                },
                {
                    "spelling_detail_id": 47485,
                    "word_type": "a.",
                    "spelling_usage": "To change by fine gradations; as (Naut.), to fine down a\n   ship's lines, to diminish her lines gradually."
                },
                {
                    "spelling_detail_id": 47486,
                    "word_type": "n.",
                    "spelling_usage": "End; conclusion; termination; extinction."
                },
                {
                    "spelling_detail_id": 47487,
                    "word_type": "n.",
                    "spelling_usage": "A sum of money paid as the settlement of a claim, or by way\n   of terminating a matter in dispute; especially, a payment of money\n   imposed upon a party as a punishment for an offense; a mulct."
                },
                {
                    "spelling_detail_id": 47488,
                    "word_type": "n.",
                    "spelling_usage": "A final agreement concerning lands or rents between persons,\n   as the lord and his vassal."
                },
                {
                    "spelling_detail_id": 47489,
                    "word_type": "n.",
                    "spelling_usage": "A sum of money or price paid for obtaining a benefit, favor,\n   or privilege, as for admission to a copyhold, or for obtaining or\n   renewing a lease."
                },
                {
                    "spelling_detail_id": 47490,
                    "word_type": "n.",
                    "spelling_usage": "To impose a pecuniary penalty upon for an offense or breach\n   of law; to set a fine on by judgment of a court; to punish by fine; to\n   mulct; as, the trespassers were fined ten dollars."
                },
                {
                    "spelling_detail_id": 47491,
                    "word_type": "v. i.",
                    "spelling_usage": "To pay a fine. See Fine, n., 3 (b)."
                },
                {
                    "spelling_detail_id": 47492,
                    "word_type": "v. t.",
                    "spelling_usage": "To finish; to cease; or to cause to cease."
                }
            ]
        },
        {
            "id": 30261,
            "spelling_name": "Flannel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 48115,
                    "word_type": "n.",
                    "spelling_usage": "A soft, nappy, woolen cloth, of loose texture."
                }
            ]
        },
        {
            "id": 30316,
            "spelling_name": "Flattery",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 48267,
                    "word_type": "v. t.",
                    "spelling_usage": "The act or practice of flattering; the act of pleasing\n   by artiful commendation or compliments; adulation; false, insincere, or\n   excessive praise."
                }
            ]
        },
        {
            "id": 30655,
            "spelling_name": "Flounder",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 48841,
                    "word_type": "n.",
                    "spelling_usage": "A flatfish of the family Pleuronectidae, of many species."
                },
                {
                    "spelling_detail_id": 48842,
                    "word_type": "n.",
                    "spelling_usage": "A tool used in crimping boot fronts."
                },
                {
                    "spelling_detail_id": 48845,
                    "word_type": "v. i.",
                    "spelling_usage": "To fling the limbs and body, as in making efforts to\n   move; to struggle, as a horse in the mire, or as a fish on land; to\n   roll, toss, and tumble; to flounce."
                },
                {
                    "spelling_detail_id": 48846,
                    "word_type": "n.",
                    "spelling_usage": "The act of floundering."
                }
            ]
        },
        {
            "id": 30723,
            "spelling_name": "Fluid",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 48980,
                    "word_type": "a.",
                    "spelling_usage": "Having particles which easily move and change their relative\n   position without a separation of the mass, and which easily yield to\n   pressure; capable of flowing; liquid or gaseous."
                },
                {
                    "spelling_detail_id": 48981,
                    "word_type": "n.",
                    "spelling_usage": "A fluid substance; a body whose particles move easily among\n   themselves."
                }
            ]
        },
        {
            "id": 30967,
            "spelling_name": "Followed",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 49434,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Follow"
                }
            ]
        },
        {
            "id": 31082,
            "spelling_name": "Forage",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 49663,
                    "word_type": "n.",
                    "spelling_usage": "The act of foraging; search for provisions, etc."
                },
                {
                    "spelling_detail_id": 49664,
                    "word_type": "n.",
                    "spelling_usage": "Food of any kind for animals, especially for horses and\n   cattle, as grass, pasture, hay, corn, oats."
                },
                {
                    "spelling_detail_id": 49667,
                    "word_type": "v. i.",
                    "spelling_usage": "To wander or rove in search of food; to collect food,\n   esp. forage, for horses and cattle by feeding on or stripping the\n   country; to ravage; to feed on spoil."
                },
                {
                    "spelling_detail_id": 49668,
                    "word_type": "v. t.",
                    "spelling_usage": "To strip of provisions; to supply with forage; as, to\n   forage steeds."
                }
            ]
        },
        {
            "id": 31108,
            "spelling_name": "Forbidden",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 49703,
                    "word_type": "p. p.",
                    "spelling_usage": "of Forbid"
                },
                {
                    "spelling_detail_id": 49713,
                    "word_type": "a.",
                    "spelling_usage": "Prohibited; interdicted."
                }
            ]
        },
        {
            "id": 31342,
            "spelling_name": "Forever",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50062,
                    "word_type": "adv.",
                    "spelling_usage": "Through eternity; through endless ages, eternally."
                },
                {
                    "spelling_detail_id": 50063,
                    "word_type": "adv.",
                    "spelling_usage": "At all times; always."
                }
            ]
        },
        {
            "id": 31371,
            "spelling_name": "Forfend",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50102,
                    "word_type": "v. t.",
                    "spelling_usage": "To prohibit; to forbid; to avert."
                }
            ]
        },
        {
            "id": 31445,
            "spelling_name": "Formalize",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50263,
                    "word_type": "v. t.",
                    "spelling_usage": "To give form, or a certain form, to; to model."
                },
                {
                    "spelling_detail_id": 50264,
                    "word_type": "v. t.",
                    "spelling_usage": "To render formal."
                },
                {
                    "spelling_detail_id": 50265,
                    "word_type": "v. i.",
                    "spelling_usage": "To affect formality."
                }
            ]
        },
        {
            "id": 31550,
            "spelling_name": "Fortification",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50422,
                    "word_type": "n.",
                    "spelling_usage": "The act of fortifying; the art or science of\n   fortifying places in order to defend them against an enemy."
                },
                {
                    "spelling_detail_id": 50423,
                    "word_type": "n.",
                    "spelling_usage": "That which fortifies; especially, a work or works\n   erected to defend a place against attack; a fortified place; a\n   fortress; a fort; a castle."
                }
            ]
        },
        {
            "id": 31572,
            "spelling_name": "Fortune",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50452,
                    "word_type": "n.",
                    "spelling_usage": "The arrival of something in a sudden or unexpected manner;\n   chance; accident; luck; hap; also, the personified or deified power\n   regarded as determining human success, apportioning happiness and\n   unhappiness, and distributing arbitrarily or fortuitously the lots of\n   life."
                },
                {
                    "spelling_detail_id": 50453,
                    "word_type": "n.",
                    "spelling_usage": "That which befalls or is to befall one; lot in life, or\n   event in any particular undertaking; fate; destiny; as, to tell one's\n   fortune."
                },
                {
                    "spelling_detail_id": 50454,
                    "word_type": "n.",
                    "spelling_usage": "That which comes as the result of an undertaking or of a\n   course of action; good or ill success; especially, favorable issue;\n   happy event; success; prosperity as reached partly by chance and partly\n   by effort."
                },
                {
                    "spelling_detail_id": 50455,
                    "word_type": "n.",
                    "spelling_usage": "Wealth; large possessions; large estate; riches; as, a\n   gentleman of fortune."
                },
                {
                    "spelling_detail_id": 50456,
                    "word_type": "n.",
                    "spelling_usage": "To make fortunate; to give either good or bad fortune to."
                },
                {
                    "spelling_detail_id": 50457,
                    "word_type": "n.",
                    "spelling_usage": "To provide with a fortune."
                },
                {
                    "spelling_detail_id": 50458,
                    "word_type": "n.",
                    "spelling_usage": "To presage; to tell the fortune of."
                },
                {
                    "spelling_detail_id": 50459,
                    "word_type": "v. i.",
                    "spelling_usage": "To fall out; to happen."
                }
            ]
        },
        {
            "id": 31766,
            "spelling_name": "Fragile",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50788,
                    "word_type": "a.",
                    "spelling_usage": "Easily broken; brittle; frail; delicate; easily destroyed."
                }
            ]
        },
        {
            "id": 31785,
            "spelling_name": "Frailty",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50817,
                    "word_type": "a.",
                    "spelling_usage": "The condition quality of being frail, physically,\n   mentally, or morally, frailness; infirmity; weakness of resolution;\n   liableness to be deceived or seduced."
                },
                {
                    "spelling_detail_id": 50818,
                    "word_type": "a.",
                    "spelling_usage": "A fault proceeding from weakness; foible; sin of\n   infirmity."
                }
            ]
        },
        {
            "id": 31866,
            "spelling_name": "Fraught",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50955,
                    "word_type": "n.",
                    "spelling_usage": "A freight; a cargo."
                },
                {
                    "spelling_detail_id": 50956,
                    "word_type": "a.",
                    "spelling_usage": "Freighted; laden; filled; stored; charged."
                },
                {
                    "spelling_detail_id": 50958,
                    "word_type": "",
                    "spelling_usage": "of Fraught"
                },
                {
                    "spelling_detail_id": 50960,
                    "word_type": "n.",
                    "spelling_usage": "To freight; to load; to burden; to fill; to crowd."
                }
            ]
        },
        {
            "id": 31881,
            "spelling_name": "Freckle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 50983,
                    "word_type": "v. t.",
                    "spelling_usage": "A small yellowish or brownish spot in the skin,\n   particularly on the face, neck, or hands."
                },
                {
                    "spelling_detail_id": 50984,
                    "word_type": "v. t.",
                    "spelling_usage": "Any small spot or discoloration."
                },
                {
                    "spelling_detail_id": 50987,
                    "word_type": "v. t.",
                    "spelling_usage": "To spinkle or mark with freckle or small discolored\n   spots; to spot."
                },
                {
                    "spelling_detail_id": 50988,
                    "word_type": "v. i.",
                    "spelling_usage": "To become covered or marked with freckles; to be\n   spotted."
                }
            ]
        },
        {
            "id": 31932,
            "spelling_name": "Freeze",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51072,
                    "word_type": "n.",
                    "spelling_usage": "A frieze."
                },
                {
                    "spelling_detail_id": 51076,
                    "word_type": "v. i.",
                    "spelling_usage": "To become congealed by cold; to be changed from a liquid\n   to a solid state by the abstraction of heat; to be hardened into ice or\n   a like solid body."
                },
                {
                    "spelling_detail_id": 51077,
                    "word_type": "v. i.",
                    "spelling_usage": "To become chilled with cold, or as with cold; to suffer\n   loss of animation or life by lack of heat; as, the blood freezes in the\n   veins."
                },
                {
                    "spelling_detail_id": 51078,
                    "word_type": "v. t.",
                    "spelling_usage": "To congeal; to harden into ice; to convert from a fluid\n   to a solid form by cold, or abstraction of heat."
                },
                {
                    "spelling_detail_id": 51079,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause loss of animation or life in, from lack of\n   heat; to give the sensation of cold to; to chill."
                },
                {
                    "spelling_detail_id": 51080,
                    "word_type": "n.",
                    "spelling_usage": "The act of congealing, or the state of being congealed."
                }
            ]
        },
        {
            "id": 31936,
            "spelling_name": "Freight",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51084,
                    "word_type": "n.",
                    "spelling_usage": "That with which anything in fraught or laden for\n   transportation; lading; cargo, especially of a ship, or a car on a\n   railroad, etc.; as, a freight of cotton; a full freight."
                },
                {
                    "spelling_detail_id": 51085,
                    "word_type": "n.",
                    "spelling_usage": "The sum paid by a party hiring a ship or part of a ship\n   for the use of what is thus hired."
                },
                {
                    "spelling_detail_id": 51086,
                    "word_type": "n.",
                    "spelling_usage": "The price paid a common carrier for the carriage of goods."
                },
                {
                    "spelling_detail_id": 51087,
                    "word_type": "n.",
                    "spelling_usage": "Freight transportation, or freight line."
                },
                {
                    "spelling_detail_id": 51088,
                    "word_type": "a.",
                    "spelling_usage": "Employed in the transportation of freight; having to do\n   with freight; as, a freight car."
                },
                {
                    "spelling_detail_id": 51091,
                    "word_type": "v. t.",
                    "spelling_usage": "To load with goods, as a ship, or vehicle of any kind,\n   for transporting them from one place to another; to furnish with\n   freight; as, to freight a ship; to freight a car."
                }
            ]
        },
        {
            "id": 31973,
            "spelling_name": "Frequently",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51144,
                    "word_type": "adv.",
                    "spelling_usage": "At frequent or short intervals; many times; often;\n   repeatedly; commonly."
                }
            ]
        },
        {
            "id": 32039,
            "spelling_name": "Friend",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51269,
                    "word_type": "n.",
                    "spelling_usage": "One who entertains for another such sentiments of esteem,\n   respect, and affection that he seeks his society aud welfare; a\n   wellwisher; an intimate associate; sometimes, an attendant."
                },
                {
                    "spelling_detail_id": 51270,
                    "word_type": "n.",
                    "spelling_usage": "One not inimical or hostile; one not a foe or enemy; also,\n   one of the same nation, party, kin, etc., whose friendly feelings may\n   be assumed. The word is some times used as a term of friendly address."
                },
                {
                    "spelling_detail_id": 51271,
                    "word_type": "n.",
                    "spelling_usage": "One who looks propitiously on a cause, an institution, a\n   project, and the like; a favorer; a promoter; as, a friend to commerce,\n   to poetry, to an institution."
                },
                {
                    "spelling_detail_id": 51272,
                    "word_type": "n.",
                    "spelling_usage": "One of a religious sect characterized by disuse of outward\n   rites and an ordained ministry, by simplicity of dress and speech, and\n   esp. by opposition to war and a desire to live at peace with all men.\n   They are popularly called Quakers."
                },
                {
                    "spelling_detail_id": 51273,
                    "word_type": "n.",
                    "spelling_usage": "A paramour of either sex."
                },
                {
                    "spelling_detail_id": 51276,
                    "word_type": "v. t.",
                    "spelling_usage": "To act as the friend of; to favor; to countenance; to\n   befriend."
                }
            ]
        },
        {
            "id": 32128,
            "spelling_name": "Frivolous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51414,
                    "word_type": "a.",
                    "spelling_usage": "Of little weight or importance; not worth notice;\n   slight; as, a frivolous argument."
                },
                {
                    "spelling_detail_id": 51415,
                    "word_type": "a.",
                    "spelling_usage": "Given to trifling; marked with unbecoming levity; silly;\n   interested especially in trifling matters."
                }
            ]
        },
        {
            "id": 32145,
            "spelling_name": "Frock",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51436,
                    "word_type": "n.",
                    "spelling_usage": "A loose outer garment; especially, a gown forming a part of\n   European modern costume for women and children; also, a coarse\n   shirtlike garment worn by some workmen over their other clothes; a\n   smock frock; as, a marketman's frock."
                },
                {
                    "spelling_detail_id": 51437,
                    "word_type": "n.",
                    "spelling_usage": "A coarse gown worn by monks or friars, and supposed to take\n   the place of all, or nearly all, other garments. It has a hood which\n   can be drawn over the head at pleasure, and is girded by a cord."
                },
                {
                    "spelling_detail_id": 51438,
                    "word_type": "v. t.",
                    "spelling_usage": "To clothe in a frock."
                },
                {
                    "spelling_detail_id": 51439,
                    "word_type": "v. t.",
                    "spelling_usage": "To make a monk of. Cf. Unfrock."
                }
            ]
        },
        {
            "id": 32165,
            "spelling_name": "From",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51470,
                    "word_type": "prep.",
                    "spelling_usage": "Out of the neighborhood of; lessening or losing proximity\n   to; leaving behind; by reason of; out of; by aid of; -- used whenever\n   departure, setting out, commencement of action, being, state,\n   occurrence, etc., or procedure, emanation, absence, separation, etc.,\n   are to be expressed. It is construed with, and indicates, the point of\n   space or time at which the action, state, etc., are regarded as setting\n   out or beginning; also, less frequently, the source, the cause, the\n   occasion, out of which anything proceeds; -- the aritithesis and\n   correlative of to; as, it, is one hundred miles from Boston to\n   Springfield; he took his sword from his side; light proceeds from the\n   sun; separate the coarse wool from the fine; men have all sprung from\n   Adam, and often go from good to bad, and from bad to worse; the merit\n   of an action depends on the principle from which it proceeds; men judge\n   of facts from personal knowledge, or from testimony."
                }
            ]
        },
        {
            "id": 32259,
            "spelling_name": "Fructose",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51638,
                    "word_type": "n.",
                    "spelling_usage": "Fruit sugar; levulose."
                }
            ]
        },
        {
            "id": 32403,
            "spelling_name": "Full",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 51851,
                    "word_type": "Compar.",
                    "spelling_usage": "Filled up, having within its limits all that it can\n   contain; supplied; not empty or vacant; -- said primarily of hollow\n   vessels, and hence of anything else; as, a cup full of water; a house\n   full of people."
                },
                {
                    "spelling_detail_id": 51852,
                    "word_type": "Compar.",
                    "spelling_usage": "Abundantly furnished or provided; sufficient in.\n   quantity, quality, or degree; copious; plenteous; ample; adequate; as,\n   a full meal; a full supply; a full voice; a full compensation; a house\n   full of furniture."
                },
                {
                    "spelling_detail_id": 51853,
                    "word_type": "Compar.",
                    "spelling_usage": "Not wanting in any essential quality; complete, entire;\n   perfect; adequate; as, a full narrative; a person of full age; a full\n   stop; a full face; the full moon."
                },
                {
                    "spelling_detail_id": 51854,
                    "word_type": "Compar.",
                    "spelling_usage": "Sated; surfeited."
                },
                {
                    "spelling_detail_id": 51855,
                    "word_type": "Compar.",
                    "spelling_usage": "Having the mind filled with ideas; stocked with\n   knowledge; stored with information."
                },
                {
                    "spelling_detail_id": 51856,
                    "word_type": "Compar.",
                    "spelling_usage": "Having the attention, thoughts, etc., absorbed in any\n   matter, and the feelings more or less excited by it, as, to be full of\n   some project."
                },
                {
                    "spelling_detail_id": 51857,
                    "word_type": "Compar.",
                    "spelling_usage": "Filled with emotions."
                },
                {
                    "spelling_detail_id": 51858,
                    "word_type": "Compar.",
                    "spelling_usage": "Impregnated; made pregnant."
                },
                {
                    "spelling_detail_id": 51859,
                    "word_type": "n.",
                    "spelling_usage": "Complete measure; utmost extent; the highest state or degree."
                },
                {
                    "spelling_detail_id": 51860,
                    "word_type": "adv.",
                    "spelling_usage": "Quite; to the same degree; without abatement or diminution;\n   with the whole force or effect; thoroughly; completely; exactly;\n   entirely."
                },
                {
                    "spelling_detail_id": 51861,
                    "word_type": "v. i.",
                    "spelling_usage": "To become full or wholly illuminated; as, the moon fulls\n   at midnight."
                },
                {
                    "spelling_detail_id": 51864,
                    "word_type": "n.",
                    "spelling_usage": "To thicken by moistening, heating, and pressing, as cloth; to\n   mill; to make compact; to scour, cleanse, and thicken in a mill."
                },
                {
                    "spelling_detail_id": 51865,
                    "word_type": "v. i.",
                    "spelling_usage": "To become fulled or thickened; as, this material fulls\n   well."
                }
            ]
        },
        {
            "id": 32504,
            "spelling_name": "Functionary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 52012,
                    "word_type": "n.",
                    "spelling_usage": "One charged with the performance of a function or\n   office; as, a public functionary; secular functionaries."
                }
            ]
        },
        {
            "id": 32593,
            "spelling_name": "Furious",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 52146,
                    "word_type": "a.",
                    "spelling_usage": "Transported with passion or fury; raging; violent; as, a\n   furious animal."
                },
                {
                    "spelling_detail_id": 52147,
                    "word_type": "a.",
                    "spelling_usage": "Rushing with impetuosity; moving with violence; as, a\n   furious stream; a furious wind or storm."
                }
            ]
        },
        {
            "id": 32603,
            "spelling_name": "Furnace",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 52158,
                    "word_type": "n.",
                    "spelling_usage": "An inclosed place in which heat is produced by the\n   combustion of fuel, as for reducing ores or melting metals, for warming\n   a house, for baking pottery, etc.; as, an iron furnace; a hot-air\n   furnace; a glass furnace; a boiler furnace, etc."
                },
                {
                    "spelling_detail_id": 52159,
                    "word_type": "n.",
                    "spelling_usage": "A place or time of punishment, affiction, or great trial;\n   severe experience or discipline."
                },
                {
                    "spelling_detail_id": 52160,
                    "word_type": "n.",
                    "spelling_usage": "To throw out, or exhale, as from a furnace; also, to put\n   into a furnace."
                }
            ]
        },
        {
            "id": 32630,
            "spelling_name": "Furtive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 52209,
                    "word_type": "a.",
                    "spelling_usage": "Stolen; obtained or characterized by stealth; sly; secret;\n   stealthy; as, a furtive look."
                }
            ]
        },
        {
            "id": 32837,
            "spelling_name": "Galaxy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 52529,
                    "word_type": "n.",
                    "spelling_usage": "The Milky Way; that luminous tract, or belt, which is seen\n   at night stretching across the heavens, and which is composed of\n   innumerable stars, so distant and blended as to be distinguishable only\n   with the telescope. The term has recently been used for remote clusters\n   of stars."
                },
                {
                    "spelling_detail_id": 52530,
                    "word_type": "n.",
                    "spelling_usage": "A splendid assemblage of persons or things."
                }
            ]
        },
        {
            "id": 33123,
            "spelling_name": "Gargantuan",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 52973,
                    "word_type": "a.",
                    "spelling_usage": "Characteristic of Gargantua, a gigantic, wonderful\n   personage; enormous; prodigious; inordinate."
                }
            ]
        },
        {
            "id": 33141,
            "spelling_name": "Garlic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 53005,
                    "word_type": "n.",
                    "spelling_usage": "A plant of the genus Allium (A. sativum is the cultivated\n   variety), having a bulbous root, a very strong smell, and an acrid,\n   pungent taste. Each root is composed of several lesser bulbs, called\n   cloves of garlic, inclosed in a common membranous coat, and easily\n   separable."
                },
                {
                    "spelling_detail_id": 53006,
                    "word_type": "n.",
                    "spelling_usage": "A kind of jig or farce."
                }
            ]
        },
        {
            "id": 33341,
            "spelling_name": "Gauntlet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 53310,
                    "word_type": "n.",
                    "spelling_usage": "See Gantlet."
                },
                {
                    "spelling_detail_id": 53311,
                    "word_type": "n.",
                    "spelling_usage": "A glove of such material that it defends the hand from\n   wounds."
                },
                {
                    "spelling_detail_id": 53312,
                    "word_type": "n.",
                    "spelling_usage": "A long glove, covering the wrist."
                },
                {
                    "spelling_detail_id": 53313,
                    "word_type": "n.",
                    "spelling_usage": "A rope on which hammocks or clothes are hung for drying."
                }
            ]
        },
        {
            "id": 33564,
            "spelling_name": "Genetic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 53671,
                    "word_type": "a.",
                    "spelling_usage": "Same as Genetical."
                }
            ]
        },
        {
            "id": 33596,
            "spelling_name": "Genius",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 53717,
                    "word_type": "n.",
                    "spelling_usage": "A good or evil spirit, or demon, supposed by the ancients\n   to preside over a man's destiny in life; a tutelary deity; a\n   supernatural being; a spirit, good or bad. Cf. Jinnee."
                },
                {
                    "spelling_detail_id": 53718,
                    "word_type": "n.",
                    "spelling_usage": "The peculiar structure of mind with whoch each individual\n   is endowed by nature; that disposition or aptitude of mind which is\n   peculiar to each man, and which qualifies him for certain kinds of\n   action or special success in any pursuit; special taste, inclination,\n   or disposition; as, a genius for history, for poetry, or painting."
                },
                {
                    "spelling_detail_id": 53719,
                    "word_type": "n.",
                    "spelling_usage": "Peculiar character; animating spirit, as of a nation, a\n   religion, a language."
                },
                {
                    "spelling_detail_id": 53720,
                    "word_type": "n.",
                    "spelling_usage": "Distinguished mental superiority; uncommon intellectual\n   power; especially, superior power of invention or origination of any\n   kind, or of forming new combinations; as, a man of genius."
                },
                {
                    "spelling_detail_id": 53721,
                    "word_type": "n.",
                    "spelling_usage": "A man endowed with uncommon vigor of mind; a man of\n   superior intellectual faculties; as, Shakespeare was a rare genius."
                }
            ]
        },
        {
            "id": 33657,
            "spelling_name": "Genuine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 53814,
                    "word_type": "a.",
                    "spelling_usage": "Belonging to, or proceeding from, the original stock;\n   native; hence, not counterfeit, spurious, false, or adulterated;\n   authentic; real; natural; true; pure; as, a genuine text; a genuine\n   production; genuine materials."
                }
            ]
        },
        {
            "id": 33710,
            "spelling_name": "Geometric",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 53876,
                    "word_type": "a.",
                    "spelling_usage": "Alt. of Geometrical"
                }
            ]
        },
        {
            "id": 33940,
            "spelling_name": "Giggle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 54218,
                    "word_type": "v. t.",
                    "spelling_usage": "To laugh with short catches of the breath or voice; to\n   laugh in a light, affected, or silly manner; to titter with childish\n   levity."
                },
                {
                    "spelling_detail_id": 54219,
                    "word_type": "n.",
                    "spelling_usage": "A kind of laugh, with short catches of the voice or breath;\n   a light, silly laugh."
                }
            ]
        },
        {
            "id": 34206,
            "spelling_name": "Gleaming",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 54685,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Gleam"
                }
            ]
        },
        {
            "id": 34257,
            "spelling_name": "Glimmer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 54773,
                    "word_type": "v. i.",
                    "spelling_usage": "To give feeble or scattered rays of light; to shine\n   faintly; to show a faint, unsteady light; as, the glimmering dawn; a\n   glimmering lamp."
                },
                {
                    "spelling_detail_id": 54774,
                    "word_type": "n.",
                    "spelling_usage": "A faint, unsteady light; feeble, scattered rays of light;\n   also, a gleam."
                },
                {
                    "spelling_detail_id": 54775,
                    "word_type": "n.",
                    "spelling_usage": "Mica. See Mica."
                }
            ]
        },
        {
            "id": 34427,
            "spelling_name": "Glucose",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 55023,
                    "word_type": "n.",
                    "spelling_usage": "A variety of sugar occurring in nature very abundantly, as\n   in ripe grapes, and in honey, and produced in great quantities from\n   starch, etc., by the action of heat and acids. It is only about half as\n   sweet as cane sugar. Called also dextrose, grape sugar, diabetic sugar,\n   and starch sugar. See Dextrose."
                },
                {
                    "spelling_detail_id": 55024,
                    "word_type": "n.",
                    "spelling_usage": "Any one of a large class of sugars, isometric with glucose\n   proper, and including levulose, galactose, etc."
                },
                {
                    "spelling_detail_id": 55025,
                    "word_type": "n.",
                    "spelling_usage": "The trade name of a sirup, obtained as an uncrystallizable\n   reside in the manufacture of glucose proper, and containing, in\n   addition to some dextrose or glucose, also maltose, dextrin, etc. It is\n   used as a cheap adulterant of sirups, beers, etc."
                }
            ]
        },
        {
            "id": 34539,
            "spelling_name": "Gnash",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 55168,
                    "word_type": "v. t.",
                    "spelling_usage": "To strike together, as in anger or pain; as, to gnash the\n   teeth."
                },
                {
                    "spelling_detail_id": 55169,
                    "word_type": "v. i.",
                    "spelling_usage": "To grind or strike the teeth together."
                }
            ]
        },
        {
            "id": 34694,
            "spelling_name": "Golden",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 55398,
                    "word_type": "a.",
                    "spelling_usage": "Made of gold; consisting of gold."
                },
                {
                    "spelling_detail_id": 55399,
                    "word_type": "a.",
                    "spelling_usage": "Having the color of gold; as, the golden grain."
                },
                {
                    "spelling_detail_id": 55400,
                    "word_type": "a.",
                    "spelling_usage": "Very precious; highly valuable; excellent; eminently\n   auspicious; as, golden opinions."
                }
            ]
        },
        {
            "id": 34892,
            "spelling_name": "Gossip",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 55704,
                    "word_type": "n.",
                    "spelling_usage": "A sponsor; a godfather or a godmother."
                },
                {
                    "spelling_detail_id": 55705,
                    "word_type": "n.",
                    "spelling_usage": "A friend or comrade; a companion; a familiar and customary\n   acquaintance."
                },
                {
                    "spelling_detail_id": 55706,
                    "word_type": "n.",
                    "spelling_usage": "One who runs house to house, tattling and telling news; an\n   idle tattler."
                },
                {
                    "spelling_detail_id": 55707,
                    "word_type": "n.",
                    "spelling_usage": "The tattle of a gossip; groundless rumor."
                },
                {
                    "spelling_detail_id": 55708,
                    "word_type": "v. t.",
                    "spelling_usage": "To stand sponsor to."
                },
                {
                    "spelling_detail_id": 55711,
                    "word_type": "v. i.",
                    "spelling_usage": "To make merry."
                },
                {
                    "spelling_detail_id": 55712,
                    "word_type": "v. i.",
                    "spelling_usage": "To prate; to chat; to talk much."
                },
                {
                    "spelling_detail_id": 55713,
                    "word_type": "v. i.",
                    "spelling_usage": "To run about and tattle; to tell idle tales."
                }
            ]
        },
        {
            "id": 35009,
            "spelling_name": "Gradual",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 55926,
                    "word_type": "n.",
                    "spelling_usage": "Proceeding by steps or degrees; advancing, step by step,\n   as in ascent or descent or from one state to another; regularly\n   progressive; slow; as, a gradual increase of knowledge; a gradual\n   decline."
                },
                {
                    "spelling_detail_id": 55927,
                    "word_type": "n.",
                    "spelling_usage": "An antiphon or responsory after the epistle, in the Mass,\n   which was sung on the steps, or while the deacon ascended the steps."
                },
                {
                    "spelling_detail_id": 55928,
                    "word_type": "n.",
                    "spelling_usage": "A service book containing the musical portions of the\n   Mass."
                },
                {
                    "spelling_detail_id": 55929,
                    "word_type": "n.",
                    "spelling_usage": "A series of steps."
                }
            ]
        },
        {
            "id": 35414,
            "spelling_name": "Grew",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 56646,
                    "word_type": "",
                    "spelling_usage": "imp. of Grow."
                },
                {
                    "spelling_detail_id": 57032,
                    "word_type": "imp.",
                    "spelling_usage": "of Grow"
                }
            ]
        },
        {
            "id": 35535,
            "spelling_name": "Groats",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 56862,
                    "word_type": "n. pl.",
                    "spelling_usage": "Dried grain, as oats or wheat, hulled and broken or\n   crushed; in high milling, cracked fragments of wheat larger than grits."
                }
            ]
        },
        {
            "id": 35556,
            "spelling_name": "Groom",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 56895,
                    "word_type": "n.",
                    "spelling_usage": "A boy or young man; a waiter; a servant; especially, a man\n   or boy who has charge of horses, or the stable."
                },
                {
                    "spelling_detail_id": 56896,
                    "word_type": "n.",
                    "spelling_usage": "One of several officers of the English royal household,\n   chiefly in the lord chamberlain's department; as, the groom of the\n   chamber; the groom of the stole."
                },
                {
                    "spelling_detail_id": 56897,
                    "word_type": "n.",
                    "spelling_usage": "A man recently married, or about to be married; a\n   bridegroom."
                },
                {
                    "spelling_detail_id": 56900,
                    "word_type": "v. i.",
                    "spelling_usage": "To tend or care for, or to curry or clean, as a, horse."
                }
            ]
        },
        {
            "id": 35563,
            "spelling_name": "Groove",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 56905,
                    "word_type": "n.",
                    "spelling_usage": "A furrow, channel, or long hollow, such as may be formed by\n   cutting, molding, grinding, the wearing force of flowing water, or\n   constant travel; a depressed way; a worn path; a rut."
                },
                {
                    "spelling_detail_id": 56906,
                    "word_type": "n.",
                    "spelling_usage": "Hence: The habitual course of life, work, or affairs; fixed\n   routine."
                },
                {
                    "spelling_detail_id": 56907,
                    "word_type": "n.",
                    "spelling_usage": "A shaft or excavation."
                },
                {
                    "spelling_detail_id": 56910,
                    "word_type": "v. t.",
                    "spelling_usage": "To cut a groove or channel in; to form into channels or\n   grooves; to furrow."
                }
            ]
        },
        {
            "id": 35611,
            "spelling_name": "Grouse",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57007,
                    "word_type": "n. sing. & pl.",
                    "spelling_usage": "Any of the numerous species of gallinaceous\n   birds of the family Tetraonidae, and subfamily Tetraoninae, inhabiting\n   Europe, Asia, and North America. They have plump bodies, strong,\n   well-feathered legs, and usually mottled plumage. The group includes\n   the ptarmigans (Lagopus), having feathered feet."
                },
                {
                    "spelling_detail_id": 57008,
                    "word_type": "v. i.",
                    "spelling_usage": "To seek or shoot grouse."
                },
                {
                    "spelling_detail_id": 57009,
                    "word_type": "v. i.",
                    "spelling_usage": "To complain or grumble."
                }
            ]
        },
        {
            "id": 35662,
            "spelling_name": "Gruel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57091,
                    "word_type": "n.",
                    "spelling_usage": "A light, liquid food, made by boiling meal of maize,\n   oatmeal, or fiour in water or milk; thin porridge."
                }
            ]
        },
        {
            "id": 35672,
            "spelling_name": "Grumbling",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57101,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Grumble"
                }
            ]
        },
        {
            "id": 35741,
            "spelling_name": "Guardian",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57214,
                    "word_type": "v. t.",
                    "spelling_usage": "One who guards, preserves, or secures; one to whom any\n   person or thing is committed for protection, security, or preservation\n   from injury; a warden."
                },
                {
                    "spelling_detail_id": 57215,
                    "word_type": "v. t.",
                    "spelling_usage": "One who has, or is entitled to, the custody of the\n   person or property of an infant, a minor without living parents, or a\n   person incapable of managing his own affairs."
                },
                {
                    "spelling_detail_id": 57216,
                    "word_type": "a.",
                    "spelling_usage": "Performing, or appropriate to, the office of a protector;\n   as, a guardian care."
                }
            ]
        },
        {
            "id": 35781,
            "spelling_name": "Guess",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57266,
                    "word_type": "v. t.",
                    "spelling_usage": "To form an opinion concerning, without knowledge or means\n   of knowledge; to judge of at random; to conjecture."
                },
                {
                    "spelling_detail_id": 57267,
                    "word_type": "v. t.",
                    "spelling_usage": "To judge or form an opinion of, from reasons that seem\n   preponderating, but are not decisive."
                },
                {
                    "spelling_detail_id": 57268,
                    "word_type": "v. t.",
                    "spelling_usage": "To solve by a correct conjecture; to conjecture rightly;\n   as, he who guesses the riddle shall have the ring; he has guessed my\n   designs."
                },
                {
                    "spelling_detail_id": 57269,
                    "word_type": "v. t.",
                    "spelling_usage": "To hit upon or reproduce by memory."
                },
                {
                    "spelling_detail_id": 57270,
                    "word_type": "v. t.",
                    "spelling_usage": "To think; to suppose; to believe; to imagine; -- followed\n   by an objective clause."
                },
                {
                    "spelling_detail_id": 57271,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a guess or random judgment; to conjecture; --\n   with at, about, etc."
                },
                {
                    "spelling_detail_id": 57272,
                    "word_type": "n.",
                    "spelling_usage": "An opinion as to anything, formed without sufficient or\n   decisive evidence or grounds; an attempt to hit upon the truth by a\n   random judgment; a conjecture; a surmise."
                }
            ]
        },
        {
            "id": 35794,
            "spelling_name": "Guffaw",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57286,
                    "word_type": "n.",
                    "spelling_usage": "A loud burst of laughter; a horse laugh."
                }
            ]
        },
        {
            "id": 35901,
            "spelling_name": "Gummy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57447,
                    "word_type": "a.",
                    "spelling_usage": "Consisting of gum; viscous; adhesive; producing or\n   containing gum; covered with gum or a substance resembling gum."
                }
            ]
        },
        {
            "id": 36030,
            "spelling_name": "Gymnastics",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 57628,
                    "word_type": "n.",
                    "spelling_usage": "Athletic or disciplinary exercises; the art of\n   performing gymnastic exercises; also, disciplinary exercises for the\n   intellect or character."
                }
            ]
        },
        {
            "id": 36321,
            "spelling_name": "Haggle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 58010,
                    "word_type": "v. t.",
                    "spelling_usage": "To cut roughly or hack; to cut into small pieces; to\n   notch or cut in an unskillful manner; to make rough or mangle by\n   cutting; as, a boy haggles a stick of wood."
                },
                {
                    "spelling_detail_id": 58011,
                    "word_type": "v. i.",
                    "spelling_usage": "To be difficult in bargaining; to stick at small\n   matters; to chaffer; to higgle."
                },
                {
                    "spelling_detail_id": 58012,
                    "word_type": "n.",
                    "spelling_usage": "The act or process of haggling."
                }
            ]
        },
        {
            "id": 36542,
            "spelling_name": "Hamlet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 58326,
                    "word_type": "n.",
                    "spelling_usage": "A small village; a little cluster of houses in the country."
                }
            ]
        },
        {
            "id": 36665,
            "spelling_name": "Hankering",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 58572,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Hanker"
                }
            ]
        },
        {
            "id": 36810,
            "spelling_name": "Harmonious",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 58817,
                    "word_type": "a.",
                    "spelling_usage": "Adapted to each other; having parts proportioned to\n   each other; symmetrical."
                },
                {
                    "spelling_detail_id": 58818,
                    "word_type": "a.",
                    "spelling_usage": "Acting together to a common end; agreeing in action or\n   feeling; living in peace and friendship; as, an harmonious family."
                },
                {
                    "spelling_detail_id": 58819,
                    "word_type": "a.",
                    "spelling_usage": "Vocally or musically concordant; agreeably consonant;\n   symphonious."
                }
            ]
        },
        {
            "id": 36859,
            "spelling_name": "Harrowing",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 58897,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Harrow"
                }
            ]
        },
        {
            "id": 37076,
            "spelling_name": "Hazelnut",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 59280,
                    "word_type": "n.",
                    "spelling_usage": "The nut of the hazel."
                }
            ]
        },
        {
            "id": 37095,
            "spelling_name": "Headdress",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 59331,
                    "word_type": "n.",
                    "spelling_usage": "A covering or ornament for the head; a headtire."
                },
                {
                    "spelling_detail_id": 59332,
                    "word_type": "n.",
                    "spelling_usage": "A manner of dressing the hair or of adorning it, whether\n   with or without a veil, ribbons, combs, etc."
                }
            ]
        },
        {
            "id": 37109,
            "spelling_name": "Headlong",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 59364,
                    "word_type": "a. & adv.",
                    "spelling_usage": "With the head foremost; as, to fall headlong."
                },
                {
                    "spelling_detail_id": 59365,
                    "word_type": "a. & adv.",
                    "spelling_usage": "Rashly; precipitately; without deliberation."
                },
                {
                    "spelling_detail_id": 59366,
                    "word_type": "a. & adv.",
                    "spelling_usage": "Hastily; without delay or respite."
                },
                {
                    "spelling_detail_id": 59367,
                    "word_type": "a.",
                    "spelling_usage": "Rash; precipitate; as, headlong folly."
                },
                {
                    "spelling_detail_id": 59368,
                    "word_type": "a.",
                    "spelling_usage": "Steep; precipitous."
                }
            ]
        },
        {
            "id": 37162,
            "spelling_name": "Hear",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 59454,
                    "word_type": "v. t.",
                    "spelling_usage": "To perceive by the ear; to apprehend or take cognizance of\n   by the ear; as, to hear sounds; to hear a voice; to hear one call."
                },
                {
                    "spelling_detail_id": 59455,
                    "word_type": "v. t.",
                    "spelling_usage": "To give audience or attention to; to listen to; to heed;\n   to accept the doctrines or advice of; to obey; to examine; to try in a\n   judicial court; as, to hear a recitation; to hear a class; the case\n   will be heard to-morrow."
                },
                {
                    "spelling_detail_id": 59456,
                    "word_type": "v. t.",
                    "spelling_usage": "To attend, or be present at, as hearer or worshiper; as,\n   to hear a concert; to hear Mass."
                },
                {
                    "spelling_detail_id": 59457,
                    "word_type": "v. t.",
                    "spelling_usage": "To give attention to as a teacher or judge."
                },
                {
                    "spelling_detail_id": 59458,
                    "word_type": "v. t.",
                    "spelling_usage": "To accede to the demand or wishes of; to listen to and\n   answer favorably; to favor."
                },
                {
                    "spelling_detail_id": 59459,
                    "word_type": "v. i.",
                    "spelling_usage": "To have the sense or faculty of perceiving sound."
                },
                {
                    "spelling_detail_id": 59460,
                    "word_type": "v. i.",
                    "spelling_usage": "To use the power of perceiving sound; to perceive or\n   apprehend by the ear; to attend; to listen."
                },
                {
                    "spelling_detail_id": 59461,
                    "word_type": "v. i.",
                    "spelling_usage": "To be informed by oral communication; to be told; to\n   receive information by report or by letter."
                }
            ]
        },
        {
            "id": 37252,
            "spelling_name": "Heavenly",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 59639,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to, resembling, or inhabiting heaven;\n   celestial; not earthly; as, heavenly regions; heavenly music."
                },
                {
                    "spelling_detail_id": 59640,
                    "word_type": "a.",
                    "spelling_usage": "Appropriate to heaven in character or happiness; perfect;\n   pure; supremely blessed; as, a heavenly race; the heavenly, throng."
                },
                {
                    "spelling_detail_id": 59641,
                    "word_type": "adv.",
                    "spelling_usage": "In a manner resembling that of heaven."
                },
                {
                    "spelling_detail_id": 59642,
                    "word_type": "adv.",
                    "spelling_usage": "By the influence or agency of heaven."
                }
            ]
        },
        {
            "id": 37383,
            "spelling_name": "Heiress",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 59866,
                    "word_type": "n.",
                    "spelling_usage": "A female heir."
                }
            ]
        },
        {
            "id": 37929,
            "spelling_name": "Heroic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 60567,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to, or like, a hero; of the nature of\n   heroes; distinguished by the existence of heroes; as, the heroic age;\n   an heroic people; heroic valor."
                },
                {
                    "spelling_detail_id": 60568,
                    "word_type": "a.",
                    "spelling_usage": "Worthy of a hero; bold; daring; brave; illustrious; as,\n   heroic action; heroic enterprises."
                },
                {
                    "spelling_detail_id": 60569,
                    "word_type": "a.",
                    "spelling_usage": "Larger than life size, but smaller than colossal; -- said\n   of the representation of a human figure."
                }
            ]
        },
        {
            "id": 38481,
            "spelling_name": "Historical",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 61314,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to history, or the record of past\n   events; as, an historical poem; the historic page."
                }
            ]
        },
        {
            "id": 38504,
            "spelling_name": "Hit",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 61339,
                    "word_type": "pron.",
                    "spelling_usage": "It."
                },
                {
                    "spelling_detail_id": 61340,
                    "word_type": "",
                    "spelling_usage": "3d pers. sing. pres. of Hide, contracted from hideth."
                },
                {
                    "spelling_detail_id": 61341,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Hit"
                },
                {
                    "spelling_detail_id": 61343,
                    "word_type": "v. t.",
                    "spelling_usage": "To reach with a stroke or blow; to strike or touch, usually\n   with force; especially, to reach or touch (an object aimed at)."
                },
                {
                    "spelling_detail_id": 61344,
                    "word_type": "v. t.",
                    "spelling_usage": "To reach or attain exactly; to meet according to the\n   occasion; to perform successfully; to attain to; to accord with; to be\n   conformable to; to suit."
                },
                {
                    "spelling_detail_id": 61345,
                    "word_type": "v. t.",
                    "spelling_usage": "To guess; to light upon or discover."
                },
                {
                    "spelling_detail_id": 61346,
                    "word_type": "v. t.",
                    "spelling_usage": "To take up, or replace by a piece belonging to the opposing\n   player; -- said of a single unprotected piece on a point."
                },
                {
                    "spelling_detail_id": 61347,
                    "word_type": "v. i.",
                    "spelling_usage": "To meet or come in contact; to strike; to clash; --\n   followed by against or on."
                },
                {
                    "spelling_detail_id": 61348,
                    "word_type": "v. i.",
                    "spelling_usage": "To meet or reach what was aimed at or desired; to succeed,\n   -- often with implied chance, or luck."
                },
                {
                    "spelling_detail_id": 61349,
                    "word_type": "n.",
                    "spelling_usage": "A striking against; the collision of one body against another;\n   the stroke that touches anything."
                },
                {
                    "spelling_detail_id": 61350,
                    "word_type": "n.",
                    "spelling_usage": "A stroke of success in an enterprise, as by a fortunate\n   chance; as, he made a hit."
                },
                {
                    "spelling_detail_id": 61351,
                    "word_type": "n.",
                    "spelling_usage": "A peculiarly apt expression or turn of thought; a phrase which\n   hits the mark; as, a happy hit."
                },
                {
                    "spelling_detail_id": 61352,
                    "word_type": "n.",
                    "spelling_usage": "A game won at backgammon after the adversary has removed some\n   of his men. It counts less than a gammon."
                },
                {
                    "spelling_detail_id": 61353,
                    "word_type": "n.",
                    "spelling_usage": "A striking of the ball; as, a safe hit; a foul hit; --\n   sometimes used specifically for a base hit."
                }
            ]
        },
        {
            "id": 38555,
            "spelling_name": "Hobble",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 61448,
                    "word_type": "n. i.",
                    "spelling_usage": "To walk lame, bearing chiefly on one leg; to walk with a\n   hitch or hop, or with crutches."
                },
                {
                    "spelling_detail_id": 61449,
                    "word_type": "n. i.",
                    "spelling_usage": "To move roughly or irregularly; -- said of style in\n   writing."
                },
                {
                    "spelling_detail_id": 61450,
                    "word_type": "v. t.",
                    "spelling_usage": "To fetter by tying the legs; to hopple; to clog."
                },
                {
                    "spelling_detail_id": 61451,
                    "word_type": "v. t.",
                    "spelling_usage": "To perplex; to embarrass."
                },
                {
                    "spelling_detail_id": 61452,
                    "word_type": "n.",
                    "spelling_usage": "An unequal gait; a limp; a halt; as, he has a hobble in his\n   gait."
                },
                {
                    "spelling_detail_id": 61453,
                    "word_type": "n.",
                    "spelling_usage": "Same as Hopple."
                },
                {
                    "spelling_detail_id": 61454,
                    "word_type": "n.",
                    "spelling_usage": "Difficulty; perplexity; embarrassment."
                }
            ]
        },
        {
            "id": 38592,
            "spelling_name": "Hodgepodge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 61517,
                    "word_type": "n.",
                    "spelling_usage": "A mixed mass; a medley. See Hotchpot."
                }
            ]
        },
        {
            "id": 38668,
            "spelling_name": "Holiday",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 61661,
                    "word_type": "n.",
                    "spelling_usage": "A consecrated day; religious anniversary; a day set apart\n   in honor of some person, or in commemoration of some event. See\n   Holyday."
                },
                {
                    "spelling_detail_id": 61662,
                    "word_type": "n.",
                    "spelling_usage": "A day of exemption from labor; a day of amusement and\n   gayety; a festival day."
                },
                {
                    "spelling_detail_id": 61663,
                    "word_type": "n.",
                    "spelling_usage": "A day fixed by law for suspension of business; a legal\n   holiday."
                },
                {
                    "spelling_detail_id": 61664,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to a festival; cheerful; joyous; gay."
                },
                {
                    "spelling_detail_id": 61665,
                    "word_type": "a.",
                    "spelling_usage": "Occurring rarely; adapted for a special occasion."
                }
            ]
        },
        {
            "id": 38926,
            "spelling_name": "Honeybee",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 62027,
                    "word_type": "n.",
                    "spelling_usage": "Any bee of the genus Apis, which lives in communities and\n   collects honey, esp. the common domesticated hive bee (Apis mellifica),\n   the Italian bee (A. ligustica), and the Arabiab bee (A. fasciata). The\n   two latter are by many entomologists considered only varieties of the\n   common hive bee. Each swarm of bees consists of a large number of\n   workers (barren females), with, ordinarily, one queen or fertile\n   female, but in the swarming season several young queens, and a number\n   of males or drones, are produced."
                }
            ]
        },
        {
            "id": 39006,
            "spelling_name": "Hooves",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 62132,
                    "word_type": "pl. ",
                    "spelling_usage": "of Hoof"
                }
            ]
        },
        {
            "id": 39045,
            "spelling_name": "Horizon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 62276,
                    "word_type": "n.",
                    "spelling_usage": "The circle which bounds that part of the earth's surface\n   visible to a spectator from a given point; the apparent junction of the\n   earth and sky."
                },
                {
                    "spelling_detail_id": 62277,
                    "word_type": "n.",
                    "spelling_usage": "A plane passing through the eye of the spectator and at\n   right angles to the vertical at a given place; a plane tangent to the\n   earth's surface at that place; called distinctively the sensible\n   horizon."
                },
                {
                    "spelling_detail_id": 62278,
                    "word_type": "n.",
                    "spelling_usage": "A plane parallel to the sensible horizon of a place, and\n   passing through the earth's center; -- called also rational / celestial\n   horizon."
                },
                {
                    "spelling_detail_id": 62279,
                    "word_type": "n.",
                    "spelling_usage": "The unbroken line separating sky and water, as seen by an\n   eye at a given elevation, no land being visible."
                },
                {
                    "spelling_detail_id": 62280,
                    "word_type": "n.",
                    "spelling_usage": "The epoch or time during which a deposit was made."
                },
                {
                    "spelling_detail_id": 62281,
                    "word_type": "n.",
                    "spelling_usage": "The chief horizontal line in a picture of any sort, which\n   determines in the picture the height of the eye of the spectator; in an\n   extended landscape, the representation of the natural horizon\n   corresponds with this line."
                }
            ]
        },
        {
            "id": 39381,
            "spelling_name": "Huge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 62855,
                    "word_type": "superl.",
                    "spelling_usage": "Very large; enormous; immense; excessive; -- used esp.\n   of material bulk, but often of qualities, extent, etc.; as, a huge ox;\n   a huge space; a huge difference."
                }
            ]
        },
        {
            "id": 39412,
            "spelling_name": "Humane",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 62914,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to man; human."
                },
                {
                    "spelling_detail_id": 62915,
                    "word_type": "a.",
                    "spelling_usage": "Having the feelings and inclinations creditable to man;\n   having a disposition to treat other human beings or animals with\n   kindness; kind; benevolent."
                },
                {
                    "spelling_detail_id": 62916,
                    "word_type": "a.",
                    "spelling_usage": "Humanizing; exalting; tending to refine."
                }
            ]
        },
        {
            "id": 39512,
            "spelling_name": "Hunch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 63071,
                    "word_type": "n.",
                    "spelling_usage": "A hump; a protuberance."
                },
                {
                    "spelling_detail_id": 63072,
                    "word_type": "n.",
                    "spelling_usage": "A lump; a thick piece; as, a hunch of bread."
                },
                {
                    "spelling_detail_id": 63073,
                    "word_type": "n.",
                    "spelling_usage": "A push or thrust, as with the elbow."
                },
                {
                    "spelling_detail_id": 63076,
                    "word_type": "v. t.",
                    "spelling_usage": "To push or jostle with the elbow; to push or thrust\n   suddenly."
                },
                {
                    "spelling_detail_id": 63077,
                    "word_type": "v. t.",
                    "spelling_usage": "To thrust out a hump or protuberance; to crook, as the\n   back."
                }
            ]
        },
        {
            "id": 39524,
            "spelling_name": "Hungary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 63094,
                    "word_type": "n.",
                    "spelling_usage": "A country in Central Europe, now a part of the\n   Austro-Hungarian Empire."
                }
            ]
        },
        {
            "id": 39683,
            "spelling_name": "Hydra",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 63368,
                    "word_type": "n.",
                    "spelling_usage": "A serpent or monster in the lake or marsh of Lerna, in the\n   Peloponnesus, represented as having many heads, one of which, when cut\n   off, was immediately succeeded by two others, unless the wound was\n   cauterized. It was slain by Hercules. Hence, a terrible monster."
                },
                {
                    "spelling_detail_id": 63369,
                    "word_type": "n.",
                    "spelling_usage": "Hence: A multifarious evil, or an evil having many sources;\n   not to be overcome by a single effort."
                },
                {
                    "spelling_detail_id": 63370,
                    "word_type": "n.",
                    "spelling_usage": "Any small fresh-water hydroid of the genus Hydra, usually\n   found attached to sticks, stones, etc., by a basal sucker."
                },
                {
                    "spelling_detail_id": 63371,
                    "word_type": "n.",
                    "spelling_usage": "A southern constellation of great length lying southerly\n   from Cancer, Leo, and Virgo."
                }
            ]
        },
        {
            "id": 40084,
            "spelling_name": "Hypnotic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 63822,
                    "word_type": "a.",
                    "spelling_usage": "Having the quality of producing sleep; tending to produce\n   sleep; soporific."
                },
                {
                    "spelling_detail_id": 63823,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to hypnotism; in a state of hypnotism;\n   liable to hypnotism; as, a hypnotic condition."
                },
                {
                    "spelling_detail_id": 63824,
                    "word_type": "n.",
                    "spelling_usage": "Any agent that produces, or tends to produce, sleep; an\n   opiate; a soporific; a narcotic."
                },
                {
                    "spelling_detail_id": 63825,
                    "word_type": "n.",
                    "spelling_usage": "A person who exhibits the phenomena of, or is subject to,\n   hypnotism."
                }
            ]
        },
        {
            "id": 40292,
            "spelling_name": "Iceberg",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 64070,
                    "word_type": "n.",
                    "spelling_usage": "A large mass of ice, generally floating in the ocean."
                }
            ]
        },
        {
            "id": 40557,
            "spelling_name": "Ignite",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 64420,
                    "word_type": "v. t.",
                    "spelling_usage": "To kindle or set on fire; as, to ignite paper or wood."
                },
                {
                    "spelling_detail_id": 64421,
                    "word_type": "v. t.",
                    "spelling_usage": "To subject to the action of intense heat; to heat\n   strongly; -- often said of incombustible or infusible substances; as,\n   to ignite iron or platinum."
                },
                {
                    "spelling_detail_id": 64422,
                    "word_type": "v. i.",
                    "spelling_usage": "To take fire; to begin to burn."
                }
            ]
        },
        {
            "id": 40731,
            "spelling_name": "Illusionist",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 64663,
                    "word_type": "n.",
                    "spelling_usage": "One given to illusion; a visionary dreamer."
                }
            ]
        },
        {
            "id": 40760,
            "spelling_name": "Imagery",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 64713,
                    "word_type": "n.",
                    "spelling_usage": "The work of one who makes images or visible representation\n   of objects; imitation work; images in general, or in mass."
                },
                {
                    "spelling_detail_id": 64714,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: Unreal show; imitation; appearance."
                },
                {
                    "spelling_detail_id": 64715,
                    "word_type": "n.",
                    "spelling_usage": "The work of the imagination or fancy; false ideas;\n   imaginary phantasms."
                },
                {
                    "spelling_detail_id": 64716,
                    "word_type": "n.",
                    "spelling_usage": "Rhetorical decoration in writing or speaking; vivid\n   descriptions presenting or suggesting images of sensible objects;\n   figures in discourse."
                }
            ]
        },
        {
            "id": 40812,
            "spelling_name": "Imbibe",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 64786,
                    "word_type": "v. t.",
                    "spelling_usage": "To drink in; to absorb; to suck or take in; to receive\n   as by drinking; as, a person imbibes drink, or a sponge imbibes\n   moisture."
                },
                {
                    "spelling_detail_id": 64787,
                    "word_type": "v. t.",
                    "spelling_usage": "To receive or absorb into the mind and retain; as, to\n   imbibe principles; to imbibe errors."
                },
                {
                    "spelling_detail_id": 64788,
                    "word_type": "v. t.",
                    "spelling_usage": "To saturate; to imbue."
                }
            ]
        },
        {
            "id": 41889,
            "spelling_name": "Incentive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 66254,
                    "word_type": "a.",
                    "spelling_usage": "Inciting; encouraging or moving; rousing to action;\n   stimulative."
                },
                {
                    "spelling_detail_id": 66255,
                    "word_type": "a.",
                    "spelling_usage": "Serving to kindle or set on fire."
                },
                {
                    "spelling_detail_id": 66256,
                    "word_type": "n.",
                    "spelling_usage": "That which moves or influences the mind, or operates on\n   the passions; that which incites, or has a tendency to incite, to\n   determination or action; that which prompts to good or ill; motive;\n   spur; as, the love of money, and the desire of promotion, are two\n   powerful incentives to action."
                }
            ]
        },
        {
            "id": 41917,
            "spelling_name": "Inched",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 66280,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Inch"
                },
                {
                    "spelling_detail_id": 66295,
                    "word_type": "a.",
                    "spelling_usage": "Having or measuring (so many) inches; as, a four-inched\n   bridge."
                }
            ]
        },
        {
            "id": 42572,
            "spelling_name": "Indigo",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 67230,
                    "word_type": "n.",
                    "spelling_usage": "A kind of deep blue, one of the seven prismatic colors."
                },
                {
                    "spelling_detail_id": 67231,
                    "word_type": "n.",
                    "spelling_usage": "A blue dyestuff obtained from several plants belonging to\n   very different genera and orders; as, the woad, Isatis tinctoria,\n   Indigofera tinctoria, I. Anil, Nereum tinctorium, etc. It is a dark\n   blue earthy substance, tasteless and odorless, with a copper-violet\n   luster when rubbed. Indigo does not exist in the plants as such, but is\n   obtained by decomposition of the glycoside indican."
                },
                {
                    "spelling_detail_id": 67232,
                    "word_type": "a.",
                    "spelling_usage": "Having the color of, pertaining to, or derived from,\n   indigo."
                }
            ]
        },
        {
            "id": 43002,
            "spelling_name": "Infant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 67790,
                    "word_type": "n.",
                    "spelling_usage": "A child in the first period of life, beginning at his\n   birth; a young babe; sometimes, a child several years of age."
                },
                {
                    "spelling_detail_id": 67791,
                    "word_type": "n.",
                    "spelling_usage": "A person who is not of full age, or who has not attained\n   the age of legal capacity; a person under the age of twenty-one years;\n   a minor."
                },
                {
                    "spelling_detail_id": 67792,
                    "word_type": "n.",
                    "spelling_usage": "Same as Infante."
                },
                {
                    "spelling_detail_id": 67793,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to infancy, or the first period of life;\n   tender; not mature; as, infant strength."
                },
                {
                    "spelling_detail_id": 67794,
                    "word_type": "a.",
                    "spelling_usage": "Intended for young children; as, an infant school."
                },
                {
                    "spelling_detail_id": 67795,
                    "word_type": "v. t.",
                    "spelling_usage": "To bear or bring forth, as a child; hence, to produce,\n   in general."
                }
            ]
        },
        {
            "id": 43088,
            "spelling_name": "Infiltrate",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 67923,
                    "word_type": "v. i.",
                    "spelling_usage": "To enter by penetrating the pores or interstices of\n   a substance; to filter into or through something."
                },
                {
                    "spelling_detail_id": 67924,
                    "word_type": "v. t.",
                    "spelling_usage": "To penetrate gradually; -- sometimes used\n   reflexively."
                }
            ]
        },
        {
            "id": 43105,
            "spelling_name": "Infirm",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 67958,
                    "word_type": "a.",
                    "spelling_usage": "Not firm or sound; weak; feeble; as, an infirm body; an\n   infirm constitution."
                },
                {
                    "spelling_detail_id": 67959,
                    "word_type": "a.",
                    "spelling_usage": "Weak of mind or will; irresolute; vacillating."
                },
                {
                    "spelling_detail_id": 67960,
                    "word_type": "a.",
                    "spelling_usage": "Not solid or stable; insecure; precarious."
                },
                {
                    "spelling_detail_id": 67961,
                    "word_type": "v. t.",
                    "spelling_usage": "To weaken; to enfeeble."
                }
            ]
        },
        {
            "id": 43242,
            "spelling_name": "Infringe",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 68172,
                    "word_type": "v. t.",
                    "spelling_usage": "To break; to violate; to transgress; to neglect to\n   fulfill or obey; as, to infringe a law or contract."
                },
                {
                    "spelling_detail_id": 68173,
                    "word_type": "v. t.",
                    "spelling_usage": "To hinder; to destroy; as, to infringe efficacy; to\n   infringe delight or power."
                },
                {
                    "spelling_detail_id": 68174,
                    "word_type": "v. i.",
                    "spelling_usage": "To break, violate, or transgress some contract, rule,\n   or law; to injure; to offend."
                },
                {
                    "spelling_detail_id": 68175,
                    "word_type": "v. i.",
                    "spelling_usage": "To encroach; to trespass; -- followed by on or upon;\n   as, to infringe upon the rights of another."
                }
            ]
        },
        {
            "id": 44150,
            "spelling_name": "Intellectual",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 69501,
                    "word_type": "a.",
                    "spelling_usage": "Belonging to, or performed by, the intellect; mental;\n   as, intellectual powers, activities, etc."
                },
                {
                    "spelling_detail_id": 69502,
                    "word_type": "a.",
                    "spelling_usage": "Endowed with intellect; having the power of\n   understanding; having capacity for the higher forms of knowledge or\n   thought; characterized by intelligence or mental capacity; as, an\n   intellectual person."
                },
                {
                    "spelling_detail_id": 69503,
                    "word_type": "a.",
                    "spelling_usage": "Suitable for exercising the intellect; formed by, and\n   existing for, the intellect alone; perceived by the intellect; as,\n   intellectual employments."
                },
                {
                    "spelling_detail_id": 69504,
                    "word_type": "a.",
                    "spelling_usage": "Relating to the understanding; treating of the mind;\n   as, intellectual philosophy, sometimes called \"mental\" philosophy."
                },
                {
                    "spelling_detail_id": 69505,
                    "word_type": "n.",
                    "spelling_usage": "The intellect or understanding; mental powers or\n   faculties."
                }
            ]
        },
        {
            "id": 44472,
            "spelling_name": "Interloper",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 69958,
                    "word_type": "n.",
                    "spelling_usage": "One who interlopes; one who interlopes; one who\n   unlawfully intrudes upon a property, a station, or an office; one who\n   interferes wrongfully or officiously."
                }
            ]
        },
        {
            "id": 44970,
            "spelling_name": "Introverted",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 70636,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Introvert"
                }
            ]
        },
        {
            "id": 45195,
            "spelling_name": "Invoke",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 70975,
                    "word_type": "v. t.",
                    "spelling_usage": "To call on for aid or protection; to invite earnestly or\n   solemnly; to summon; to address in prayer; to solicit or demand by\n   invocation; to implore; as, to invoke the Supreme Being, or to invoke\n   His and blessing."
                }
            ]
        },
        {
            "id": 45269,
            "spelling_name": "Iota",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 71096,
                    "word_type": "n.",
                    "spelling_usage": "The ninth letter of the Greek alphabet (/) corresponding with\n   the English i."
                },
                {
                    "spelling_detail_id": 71097,
                    "word_type": "n.",
                    "spelling_usage": "A very small quantity or degree; a jot; a particle."
                }
            ]
        },
        {
            "id": 45586,
            "spelling_name": "Island",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 71503,
                    "word_type": "n.",
                    "spelling_usage": "A tract of land surrounded by water, and smaller than a\n   continent. Cf. Continent."
                },
                {
                    "spelling_detail_id": 71504,
                    "word_type": "n.",
                    "spelling_usage": "Anything regarded as resembling an island; as, an island of\n   ice."
                },
                {
                    "spelling_detail_id": 71505,
                    "word_type": "n.",
                    "spelling_usage": "See Isle, n., 2."
                },
                {
                    "spelling_detail_id": 71506,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause to become or to resemble an island; to make an\n   island or islands of; to isle."
                },
                {
                    "spelling_detail_id": 71507,
                    "word_type": "v. t.",
                    "spelling_usage": "To furnish with an island or with islands; as, to island\n   the deep."
                }
            ]
        },
        {
            "id": 45645,
            "spelling_name": "Isolation",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 71581,
                    "word_type": "n.",
                    "spelling_usage": "The act of isolating, or the state of being isolated;\n   insulation; separation; loneliness."
                }
            ]
        },
        {
            "id": 45758,
            "spelling_name": "Iteration",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 71751,
                    "word_type": "n.",
                    "spelling_usage": "Recital or performance a second time; repetition."
                }
            ]
        },
        {
            "id": 45916,
            "spelling_name": "Jam",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 71994,
                    "word_type": "n.",
                    "spelling_usage": "A kind of frock for children."
                },
                {
                    "spelling_detail_id": 71995,
                    "word_type": "n.",
                    "spelling_usage": "See Jamb."
                },
                {
                    "spelling_detail_id": 71998,
                    "word_type": "v. t.",
                    "spelling_usage": "To press into a close or tight position; to crowd; to\n   squeeze; to wedge in."
                },
                {
                    "spelling_detail_id": 71999,
                    "word_type": "v. t.",
                    "spelling_usage": "To crush or bruise; as, to jam a finger in the crack of a\n   door."
                },
                {
                    "spelling_detail_id": 72000,
                    "word_type": "v. t.",
                    "spelling_usage": "To bring (a vessel) so close to the wind that half her\n   upper sails are laid aback."
                },
                {
                    "spelling_detail_id": 72001,
                    "word_type": "n.",
                    "spelling_usage": "A mass of people or objects crowded together; also, the\n   pressure from a crowd; a crush; as, a jam in a street; a jam of logs in\n   a river."
                },
                {
                    "spelling_detail_id": 72002,
                    "word_type": "n.",
                    "spelling_usage": "An injury caused by jamming."
                },
                {
                    "spelling_detail_id": 72003,
                    "word_type": "n.",
                    "spelling_usage": "A preserve of fruit boiled with sugar and water; as, raspberry\n   jam; currant jam; grape jam."
                }
            ]
        },
        {
            "id": 45943,
            "spelling_name": "Janitor",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72041,
                    "word_type": "n.",
                    "spelling_usage": "A door-keeper; a porter; one who has the care of a public\n   building, or a building occupied for offices, suites of rooms, etc."
                }
            ]
        },
        {
            "id": 45959,
            "spelling_name": "January",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72057,
                    "word_type": "n.",
                    "spelling_usage": "The first month of the year, containing thirty-one days."
                }
            ]
        },
        {
            "id": 46045,
            "spelling_name": "Jealousy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72193,
                    "word_type": "n.",
                    "spelling_usage": "The quality of being jealous; earnest concern or\n   solicitude; painful apprehension of rivalship in cases nearly affecting\n   one's happiness; painful suspicion of the faithfulness of husband,\n   wife, or lover."
                }
            ]
        },
        {
            "id": 46119,
            "spelling_name": "Jersey",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72296,
                    "word_type": "n.",
                    "spelling_usage": "The finest of wool separated from the rest; combed wool;\n   also, fine yarn of wool."
                },
                {
                    "spelling_detail_id": 72297,
                    "word_type": "n.",
                    "spelling_usage": "A kind of knitted jacket; hence, in general, a closefitting\n   jacket or upper garment made of an elastic fabric (as stockinet)."
                },
                {
                    "spelling_detail_id": 72298,
                    "word_type": "n.",
                    "spelling_usage": "One of a breed of cattle in the Island of Jersey. Jerseys\n   are noted for the richness of their milk."
                }
            ]
        },
        {
            "id": 46168,
            "spelling_name": "Jewel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72370,
                    "word_type": "n.",
                    "spelling_usage": "An ornament of dress usually made of a precious metal, and\n   having enamel or precious stones as a part of its design."
                },
                {
                    "spelling_detail_id": 72371,
                    "word_type": "n.",
                    "spelling_usage": "A precious stone; a gem."
                },
                {
                    "spelling_detail_id": 72372,
                    "word_type": "n.",
                    "spelling_usage": "An object regarded with special affection; a precious thing."
                },
                {
                    "spelling_detail_id": 72373,
                    "word_type": "n.",
                    "spelling_usage": "A bearing for a pivot a pivot in a watch, formed of a\n   crystal or precious stone, as a ruby."
                },
                {
                    "spelling_detail_id": 72378,
                    "word_type": "v. t.",
                    "spelling_usage": "To dress, adorn, deck, or supply with jewels, as a dress,\n   a sword hilt, or a watch; to bespangle, as with jewels."
                }
            ]
        },
        {
            "id": 46344,
            "spelling_name": "Jotted",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72678,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Jot"
                }
            ]
        },
        {
            "id": 46355,
            "spelling_name": "Journal",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72692,
                    "word_type": "a.",
                    "spelling_usage": "Daily; diurnal."
                },
                {
                    "spelling_detail_id": 72693,
                    "word_type": "a.",
                    "spelling_usage": "A diary; an account of daily transactions and events."
                },
                {
                    "spelling_detail_id": 72694,
                    "word_type": "a.",
                    "spelling_usage": "A book of accounts, in which is entered a condensed and\n   grouped statement of the daily transactions."
                },
                {
                    "spelling_detail_id": 72695,
                    "word_type": "a.",
                    "spelling_usage": "A daily register of the ship's course and distance, the\n   winds, weather, incidents of the voyage, etc."
                },
                {
                    "spelling_detail_id": 72696,
                    "word_type": "a.",
                    "spelling_usage": "The record of daily proceedings, kept by the clerk."
                },
                {
                    "spelling_detail_id": 72697,
                    "word_type": "a.",
                    "spelling_usage": "A newspaper published daily; by extension, a weekly\n   newspaper or any periodical publication, giving an account of passing\n   events, the proceedings and memoirs of societies, etc."
                },
                {
                    "spelling_detail_id": 72698,
                    "word_type": "a.",
                    "spelling_usage": "That which has occurred in a day; a day's work or travel;\n   a day's journey."
                },
                {
                    "spelling_detail_id": 72699,
                    "word_type": "a.",
                    "spelling_usage": "That portion of a rotating piece, as a shaft, axle,\n   spindle, etc., which turns in a bearing or box. See Illust. of Axle\n   box."
                }
            ]
        },
        {
            "id": 46390,
            "spelling_name": "Joyful",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72753,
                    "word_type": "a.",
                    "spelling_usage": "Full of joy; having or causing joy; very glad; as, a joyful\n   heart."
                }
            ]
        },
        {
            "id": 46400,
            "spelling_name": "Jubilant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72764,
                    "word_type": "a.",
                    "spelling_usage": "Uttering songs of triumph; shouting with joy; triumphant;\n   exulting."
                }
            ]
        },
        {
            "id": 46498,
            "spelling_name": "Jumping",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72929,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Jump"
                },
                {
                    "spelling_detail_id": 72955,
                    "word_type": "p. a. & vb. n.",
                    "spelling_usage": "of Jump, to leap."
                }
            ]
        },
        {
            "id": 46515,
            "spelling_name": "Junior",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 72975,
                    "word_type": "a.",
                    "spelling_usage": "Less advanced in age than another; younger."
                },
                {
                    "spelling_detail_id": 72976,
                    "word_type": "a.",
                    "spelling_usage": "Lower in standing or in rank; later in office; as, a junior\n   partner; junior counsel; junior captain."
                },
                {
                    "spelling_detail_id": 72977,
                    "word_type": "a.",
                    "spelling_usage": "Composed of juniors, whether younger or a lower standing;\n   as, the junior class; of or pertaining to juniors or to a junior class.\n   See Junior, n., 2."
                },
                {
                    "spelling_detail_id": 72978,
                    "word_type": "n.",
                    "spelling_usage": "Belonging to a younger person, or an earlier time of life."
                },
                {
                    "spelling_detail_id": 72979,
                    "word_type": "n.",
                    "spelling_usage": "A younger person."
                },
                {
                    "spelling_detail_id": 72980,
                    "word_type": "n.",
                    "spelling_usage": "Hence: One of a lower or later standing; specifically, in\n   American colleges, one in the third year of his course, one in the\n   fourth or final year being designated a senior; in some seminaries, one\n   in the first year, in others, one in the second year, of a three years'\n   course."
                }
            ]
        },
        {
            "id": 46694,
            "spelling_name": "Kangaroo",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73223,
                    "word_type": "n.",
                    "spelling_usage": "Any one of numerous species of jumping marsupials of the\n   family Macropodidae. They inhabit Australia, New Guinea, and adjacent\n   islands, They have long and strong hind legs and a large tail, while\n   the fore legs are comparatively short and feeble. The giant kangaroo\n   (Macropus major) is the largest species, sometimes becoming twelve or\n   fourteen feet in total length. The tree kangaroos, belonging to the\n   genus Dendrolagus, live in trees; the rock kangaroos, of the genus\n   Petrogale, inhabit rocky situations; and the brush kangaroos, of the\n   genus Halmaturus, inhabit wooded districts. See Wallaby."
                }
            ]
        },
        {
            "id": 46828,
            "spelling_name": "Kennel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73436,
                    "word_type": "n.",
                    "spelling_usage": "The water course of a street; a little canal or channel; a\n   gutter; also, a puddle."
                },
                {
                    "spelling_detail_id": 73437,
                    "word_type": "n.",
                    "spelling_usage": "A house for a dog or for dogs, or for a pack of hounds."
                },
                {
                    "spelling_detail_id": 73438,
                    "word_type": "n.",
                    "spelling_usage": "A pack of hounds, or a collection of dogs."
                },
                {
                    "spelling_detail_id": 73439,
                    "word_type": "n.",
                    "spelling_usage": "The hole of a fox or other beast; a haunt."
                },
                {
                    "spelling_detail_id": 73443,
                    "word_type": "v. i.",
                    "spelling_usage": "To lie or lodge; to dwell, as a dog or a fox."
                },
                {
                    "spelling_detail_id": 73444,
                    "word_type": "v. t.",
                    "spelling_usage": "To put or keep in a kennel."
                }
            ]
        },
        {
            "id": 46878,
            "spelling_name": "Kernel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73504,
                    "word_type": "n.",
                    "spelling_usage": "The essential part of a seed; all that is within the seed\n   walls; the edible substance contained in the shell of a nut; hence,\n   anything included in a shell, husk, or integument; as, the kernel of a\n   nut. See Illust. of Endocarp."
                },
                {
                    "spelling_detail_id": 73505,
                    "word_type": "n.",
                    "spelling_usage": "A single seed or grain; as, a kernel of corn."
                },
                {
                    "spelling_detail_id": 73506,
                    "word_type": "n.",
                    "spelling_usage": "A small mass around which other matter is concreted; a\n   nucleus; a concretion or hard lump in the flesh."
                },
                {
                    "spelling_detail_id": 73507,
                    "word_type": "n.",
                    "spelling_usage": "The central, substantial or essential part of anything; the\n   gist; the core; as, the kernel of an argument."
                },
                {
                    "spelling_detail_id": 73512,
                    "word_type": "v. i.",
                    "spelling_usage": "To harden or ripen into kernels; to produce kernels."
                }
            ]
        },
        {
            "id": 46983,
            "spelling_name": "Kidney",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73660,
                    "word_type": "n.",
                    "spelling_usage": "A glandular organ which excretes urea and other waste\n   products from the animal body; a urinary gland."
                },
                {
                    "spelling_detail_id": 73661,
                    "word_type": "n.",
                    "spelling_usage": "Habit; disposition; sort; kind."
                },
                {
                    "spelling_detail_id": 73662,
                    "word_type": "n.",
                    "spelling_usage": "A waiter."
                }
            ]
        },
        {
            "id": 47009,
            "spelling_name": "Kiln",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73697,
                    "word_type": "n.",
                    "spelling_usage": "A large stove or oven; a furnace of brick or stone, or a\n   heated chamber, for the purpose of hardening, burning, or drying\n   anything; as, a kiln for baking or hardening earthen vessels; a kiln\n   for drying grain, meal, lumber, etc.; a kiln for calcining limestone."
                },
                {
                    "spelling_detail_id": 73698,
                    "word_type": "n.",
                    "spelling_usage": "A furnace for burning bricks; a brickkiln."
                }
            ]
        },
        {
            "id": 47024,
            "spelling_name": "Kilt",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73713,
                    "word_type": "",
                    "spelling_usage": "p. p. from Kill."
                },
                {
                    "spelling_detail_id": 73714,
                    "word_type": "n.",
                    "spelling_usage": "A kind of short petticoat, reaching from the waist to the\n   knees, worn in the Highlands of Scotland by men, and in the Lowlands by\n   young boys; a filibeg."
                },
                {
                    "spelling_detail_id": 73717,
                    "word_type": "v. t.",
                    "spelling_usage": "To tuck up; to truss up, as the clothes."
                }
            ]
        },
        {
            "id": 47040,
            "spelling_name": "Kindergarten",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73746,
                    "word_type": "n.",
                    "spelling_usage": "A school for young children, conducted on the theory\n   that education should be begun by gratifying and cultivating the normal\n   aptitude for exercise, play, observation, imitation, and construction;\n   -- a name given by Friedrich Froebel, a German educator, who introduced\n   this method of training, in rooms opening on a garden."
                }
            ]
        },
        {
            "id": 47051,
            "spelling_name": "Kindred",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73771,
                    "word_type": "n.",
                    "spelling_usage": "Relationship by birth or marriage; consanguinity;\n   affinity; kin."
                },
                {
                    "spelling_detail_id": 73772,
                    "word_type": "n.",
                    "spelling_usage": "Relatives by blood or marriage, more properly the former;\n   relations; persons related to each other."
                },
                {
                    "spelling_detail_id": 73773,
                    "word_type": "a.",
                    "spelling_usage": "Related; congenial; of the like nature or properties; as,\n   kindred souls; kindred skies; kindred propositions."
                }
            ]
        },
        {
            "id": 47192,
            "spelling_name": "Knack",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 73970,
                    "word_type": "v. i.",
                    "spelling_usage": "To crack; to make a sharp, abrupt noise to chink."
                },
                {
                    "spelling_detail_id": 73971,
                    "word_type": "v. i.",
                    "spelling_usage": "To speak affectedly."
                },
                {
                    "spelling_detail_id": 73972,
                    "word_type": "n.",
                    "spelling_usage": "A petty contrivance; a toy; a plaything; a knickknack."
                },
                {
                    "spelling_detail_id": 73973,
                    "word_type": "n.",
                    "spelling_usage": "A readiness in performance; aptness at doing something;\n   skill; facility; dexterity."
                },
                {
                    "spelling_detail_id": 73974,
                    "word_type": "n.",
                    "spelling_usage": "Something performed, or to be done, requiring aptness and\n   dexterity; a trick; a device."
                }
            ]
        },
        {
            "id": 47306,
            "spelling_name": "Knock",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 74151,
                    "word_type": "v. i.",
                    "spelling_usage": "To drive or be driven against something; to strike\n   against something; to clash; as, one heavy body knocks against another."
                },
                {
                    "spelling_detail_id": 74152,
                    "word_type": "v. i.",
                    "spelling_usage": "To strike or beat with something hard or heavy; to rap;\n   as, to knock with a club; to knock on the door."
                },
                {
                    "spelling_detail_id": 74153,
                    "word_type": "v. t.",
                    "spelling_usage": "To strike with something hard or heavy; to move by\n   striking; to drive (a thing) against something; as, to knock a ball\n   with a bat; to knock the head against a post; to knock a lamp off the\n   table."
                },
                {
                    "spelling_detail_id": 74154,
                    "word_type": "v. t.",
                    "spelling_usage": "To strike for admittance; to rap upon, as a door."
                },
                {
                    "spelling_detail_id": 74155,
                    "word_type": "n.",
                    "spelling_usage": "A blow; a stroke with something hard or heavy; a jar."
                },
                {
                    "spelling_detail_id": 74156,
                    "word_type": "n.",
                    "spelling_usage": "A stroke, as on a door for admittance; a rap."
                }
            ]
        },
        {
            "id": 47349,
            "spelling_name": "Known",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 74223,
                    "word_type": "p. p.",
                    "spelling_usage": "of Know"
                },
                {
                    "spelling_detail_id": 74251,
                    "word_type": "p. p.",
                    "spelling_usage": "of Know."
                }
            ]
        },
        {
            "id": 47351,
            "spelling_name": "Knuckle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 74255,
                    "word_type": "n.",
                    "spelling_usage": "The joint of a finger, particularly when made prominent by\n   the closing of the fingers."
                },
                {
                    "spelling_detail_id": 74256,
                    "word_type": "n.",
                    "spelling_usage": "The kneejoint, or middle joint, of either leg of a\n   quadruped, especially of a calf; -- formerly used of the kneejoint of a\n   human being."
                },
                {
                    "spelling_detail_id": 74257,
                    "word_type": "n.",
                    "spelling_usage": "The joint of a plant."
                },
                {
                    "spelling_detail_id": 74258,
                    "word_type": "n.",
                    "spelling_usage": "The joining pars of a hinge through which the pin or rivet\n   passes; a knuckle joint."
                },
                {
                    "spelling_detail_id": 74259,
                    "word_type": "n.",
                    "spelling_usage": "A convex portion of a vessel's figure where a sudden\n   change of shape occurs, as in a canal boat, where a nearly vertical\n   side joins a nearly flat bottom."
                },
                {
                    "spelling_detail_id": 74260,
                    "word_type": "n.",
                    "spelling_usage": "A contrivance, usually of brass or iron, and furnished\n   with points, worn to protect the hand, to add force to a blow, and to\n   disfigure the person struck; as, brass knuckles; -- called also knuckle\n   duster."
                },
                {
                    "spelling_detail_id": 74263,
                    "word_type": "v. i.",
                    "spelling_usage": "To yield; to submit; -- used with down, to, or under."
                },
                {
                    "spelling_detail_id": 74264,
                    "word_type": "v. t.",
                    "spelling_usage": "To beat with the knuckles; to pommel."
                }
            ]
        },
        {
            "id": 47438,
            "spelling_name": "Kudos",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 74357,
                    "word_type": "n.",
                    "spelling_usage": "Glory; fame; renown; praise."
                },
                {
                    "spelling_detail_id": 74358,
                    "word_type": "v. t.",
                    "spelling_usage": "To praise; to extol; to glorify."
                }
            ]
        },
        {
            "id": 47691,
            "spelling_name": "Lactose",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 74738,
                    "word_type": "n.",
                    "spelling_usage": "Sugar of milk or milk sugar; a crystalline sugar present\n   in milk, and separable from the whey by evaporation and\n   crystallization. It has a slightly sweet taste, is dextrorotary, and is\n   much less soluble in water than either cane sugar or glucose. Formerly\n   called lactin."
                },
                {
                    "spelling_detail_id": 74739,
                    "word_type": "n.",
                    "spelling_usage": "See Galactose."
                }
            ]
        },
        {
            "id": 47854,
            "spelling_name": "Lambkin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 74973,
                    "word_type": "n.",
                    "spelling_usage": "A small lamb."
                }
            ]
        },
        {
            "id": 47886,
            "spelling_name": "Lamentable",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 75021,
                    "word_type": "a.",
                    "spelling_usage": "Mourning; sorrowful; expressing grief; as, a lamentable\n   countenance."
                },
                {
                    "spelling_detail_id": 75022,
                    "word_type": "a.",
                    "spelling_usage": "Fitted to awaken lament; to be lamented; sorrowful;\n   pitiable; as, a lamentable misfortune, or error."
                },
                {
                    "spelling_detail_id": 75023,
                    "word_type": "a.",
                    "spelling_usage": "Miserable; pitiful; paltry; -- in a contemptuous or\n   ridiculous sense."
                }
            ]
        },
        {
            "id": 48084,
            "spelling_name": "Lantern",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 75306,
                    "word_type": "n.",
                    "spelling_usage": "Something inclosing a light, and protecting it from wind,\n   rain, etc. ; -- sometimes portable, as a closed vessel or case of horn,\n   perforated tin, glass, oiled paper, or other material, having a lamp or\n   candle within; sometimes fixed, as the glazed inclosure of a street\n   light, or of a lighthouse light."
                },
                {
                    "spelling_detail_id": 75307,
                    "word_type": "n.",
                    "spelling_usage": "An open structure of light material set upon a roof, to\n   give light and air to the interior."
                },
                {
                    "spelling_detail_id": 75308,
                    "word_type": "n.",
                    "spelling_usage": "A cage or open chamber of rich architecture, open below\n   into the building or tower which it crowns."
                },
                {
                    "spelling_detail_id": 75309,
                    "word_type": "n.",
                    "spelling_usage": "A smaller and secondary cupola crowning a larger one, for\n   ornament, or to admit light; such as the lantern of the cupola of the\n   Capitol at Washington, or that of the Florence cathedral."
                },
                {
                    "spelling_detail_id": 75310,
                    "word_type": "n.",
                    "spelling_usage": "A lantern pinion or trundle wheel. See Lantern pinion\n   (below)."
                },
                {
                    "spelling_detail_id": 75311,
                    "word_type": "n.",
                    "spelling_usage": "A kind of cage inserted in a stuffing box and surrounding\n   a piston rod, to separate the packing into two parts and form a chamber\n   between for the reception of steam, etc. ; -- called also lantern\n   brass."
                },
                {
                    "spelling_detail_id": 75312,
                    "word_type": "n.",
                    "spelling_usage": "A perforated barrel to form a core upon."
                },
                {
                    "spelling_detail_id": 75313,
                    "word_type": "n.",
                    "spelling_usage": "See Aristotle's lantern."
                },
                {
                    "spelling_detail_id": 75316,
                    "word_type": "v. t.",
                    "spelling_usage": "To furnish with a lantern; as, to lantern a lighthouse."
                }
            ]
        },
        {
            "id": 48301,
            "spelling_name": "Lateral",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 75662,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the sides; as, the lateral walls of a\n   house; the lateral branches of a tree."
                },
                {
                    "spelling_detail_id": 75663,
                    "word_type": "a.",
                    "spelling_usage": "Lying at, or extending toward, the side; away from the\n   mesial plane; external; -- opposed to mesial."
                },
                {
                    "spelling_detail_id": 75664,
                    "word_type": "a.",
                    "spelling_usage": "Directed to the side; as, a lateral view of a thing."
                }
            ]
        },
        {
            "id": 48501,
            "spelling_name": "Lawyer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 75968,
                    "word_type": "n.",
                    "spelling_usage": "One versed in the laws, or a practitioner of law; one whose\n   profession is to conduct lawsuits for clients, or to advise as to\n   prosecution or defence of lawsuits, or as to legal rights and\n   obligations in other matters. It is a general term, comprehending\n   attorneys, counselors, solicitors, barristers, sergeants, and\n   advocates."
                },
                {
                    "spelling_detail_id": 75969,
                    "word_type": "n.",
                    "spelling_usage": "The black-necked stilt. See Stilt."
                },
                {
                    "spelling_detail_id": 75970,
                    "word_type": "n.",
                    "spelling_usage": "The bowfin (Amia calva)."
                },
                {
                    "spelling_detail_id": 75971,
                    "word_type": "n.",
                    "spelling_usage": "The burbot (Lota maculosa)."
                }
            ]
        },
        {
            "id": 48624,
            "spelling_name": "Least",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 76267,
                    "word_type": "a.",
                    "spelling_usage": "Smallest, either in size or degree; shortest; lowest; most\n   unimportant; as, the least insect; the least mercy; the least space."
                },
                {
                    "spelling_detail_id": 76268,
                    "word_type": "adv.",
                    "spelling_usage": "In the smallest or lowest degree; in a degree below all\n   others; as, to reward those who least deserve it."
                },
                {
                    "spelling_detail_id": 76269,
                    "word_type": "conj.",
                    "spelling_usage": "See Lest, conj."
                }
            ]
        },
        {
            "id": 48714,
            "spelling_name": "Leeway",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 76428,
                    "word_type": "n.",
                    "spelling_usage": "The lateral movement of a ship to the leeward of her\n   course; drift."
                }
            ]
        },
        {
            "id": 48725,
            "spelling_name": "Legacy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 76455,
                    "word_type": "n.",
                    "spelling_usage": "A gift of property by will, esp. of money or personal\n   property; a bequest. Also Fig.; as, a legacy of dishonor or disease."
                },
                {
                    "spelling_detail_id": 76456,
                    "word_type": "n.",
                    "spelling_usage": "A business with which one is intrusted by another; a\n   commission; -- obsolete, except in the phrases last legacy, dying\n   legacy, and the like."
                }
            ]
        },
        {
            "id": 48866,
            "spelling_name": "Leniency",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 76662,
                    "word_type": "n.",
                    "spelling_usage": "The quality or state of being lenient; lenity; clemency."
                }
            ]
        },
        {
            "id": 49027,
            "spelling_name": "Lettuce",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 76906,
                    "word_type": "n.",
                    "spelling_usage": "A composite plant of the genus Lactuca (L. sativa), the\n   leaves of which are used as salad. Plants of this genus yield a milky\n   juice, from which lactucarium is obtained. The commonest wild lettuce\n   of the United States is L. Canadensis."
                }
            ]
        },
        {
            "id": 49122,
            "spelling_name": "Levity",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 77058,
                    "word_type": "n.",
                    "spelling_usage": "The quality of weighing less than something else of equal\n   bulk; relative lightness, especially as shown by rising through, or\n   floating upon, a contiguous substance; buoyancy; -- opposed to gravity."
                },
                {
                    "spelling_detail_id": 77059,
                    "word_type": "n.",
                    "spelling_usage": "Lack of gravity and earnestness in deportment or character;\n   trifling gayety; frivolity; sportiveness; vanity."
                },
                {
                    "spelling_detail_id": 77060,
                    "word_type": "n.",
                    "spelling_usage": "Lack of steadiness or constancy; disposition to change;\n   fickleness; volatility."
                }
            ]
        },
        {
            "id": 49148,
            "spelling_name": "Lexicon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 77105,
                    "word_type": "n.",
                    "spelling_usage": "A vocabulary, or book containing an alphabetical\n   arrangement of the words in a language or of a considerable number of\n   them, with the definition of each; a dictionary; especially, a\n   dictionary of the Greek, Hebrew, or Latin language."
                }
            ]
        },
        {
            "id": 49583,
            "spelling_name": "Linen",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 77950,
                    "word_type": "n.",
                    "spelling_usage": "Made of linen; as, linen cloth; a linen stocking."
                },
                {
                    "spelling_detail_id": 77951,
                    "word_type": "n.",
                    "spelling_usage": "Resembling linen cloth; white; pale."
                },
                {
                    "spelling_detail_id": 77952,
                    "word_type": "n.",
                    "spelling_usage": "Thread or cloth made of flax or (rarely) of hemp; -- used in\n   a general sense to include cambric, shirting, sheeting, towels,\n   tablecloths, etc."
                },
                {
                    "spelling_detail_id": 77953,
                    "word_type": "n.",
                    "spelling_usage": "Underclothing, esp. the shirt, as being, in former times,\n   chiefly made of linen."
                }
            ]
        },
        {
            "id": 49669,
            "spelling_name": "Lionize",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 78079,
                    "word_type": "v. t.",
                    "spelling_usage": "To treat or regard as a lion or object of great\n   interest."
                },
                {
                    "spelling_detail_id": 78080,
                    "word_type": "v. t.",
                    "spelling_usage": "To show the lions or objects of interest to; to conduct\n   about among objects of interest."
                }
            ]
        },
        {
            "id": 49758,
            "spelling_name": "Listener",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 78243,
                    "word_type": "n.",
                    "spelling_usage": "One who listens; a hearkener."
                }
            ]
        },
        {
            "id": 49774,
            "spelling_name": "Literacy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 78262,
                    "word_type": "n.",
                    "spelling_usage": "State of being literate."
                }
            ]
        },
        {
            "id": 49903,
            "spelling_name": "Little",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 78437,
                    "word_type": "a.",
                    "spelling_usage": "Small in size or extent; not big; diminutive; -- opposed to\n   big or large; as, a little body; a little animal; a little piece of\n   ground; a little hill; a little distance; a little child."
                },
                {
                    "spelling_detail_id": 78438,
                    "word_type": "a.",
                    "spelling_usage": "Short in duration; brief; as, a little sleep."
                },
                {
                    "spelling_detail_id": 78439,
                    "word_type": "a.",
                    "spelling_usage": "Small in quantity or amount; not much; as, a little food; a\n   little air or water."
                },
                {
                    "spelling_detail_id": 78440,
                    "word_type": "a.",
                    "spelling_usage": "Small in dignity, power, or importance; not great;\n   insignificant; contemptible."
                },
                {
                    "spelling_detail_id": 78441,
                    "word_type": "a.",
                    "spelling_usage": "Small in force or efficiency; not strong; weak; slight;\n   inconsiderable; as, little attention or exertion;little effort; little\n   care or diligence."
                },
                {
                    "spelling_detail_id": 78442,
                    "word_type": "a.",
                    "spelling_usage": "Small in extent of views or sympathies; narrow; shallow;\n   contracted; mean; illiberal; ungenerous."
                },
                {
                    "spelling_detail_id": 78443,
                    "word_type": "n.",
                    "spelling_usage": "That which is little; a small quantity, amount, space, or\n   the like."
                },
                {
                    "spelling_detail_id": 78444,
                    "word_type": "n.",
                    "spelling_usage": "A small degree or scale; miniature."
                },
                {
                    "spelling_detail_id": 78445,
                    "word_type": "adv.",
                    "spelling_usage": "In a small quantity or degree; not much; slightly;\n   somewhat; -- often with a preceding it."
                }
            ]
        },
        {
            "id": 50002,
            "spelling_name": "Loathe",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 78641,
                    "word_type": "v. t.",
                    "spelling_usage": "To feel extreme disgust at, or aversion for."
                },
                {
                    "spelling_detail_id": 78642,
                    "word_type": "v. t.",
                    "spelling_usage": "To dislike greatly; to abhor; to hate."
                },
                {
                    "spelling_detail_id": 78643,
                    "word_type": "v. i.",
                    "spelling_usage": "To feel disgust or nausea."
                }
            ]
        },
        {
            "id": 50088,
            "spelling_name": "Locker",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 78774,
                    "word_type": "n.",
                    "spelling_usage": "One who, or that which, locks."
                },
                {
                    "spelling_detail_id": 78775,
                    "word_type": "n.",
                    "spelling_usage": "A drawer, cupboard, compartment, or chest, esp. one in a\n   ship, that may be closed with a lock."
                }
            ]
        },
        {
            "id": 50484,
            "spelling_name": "Lounge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 79461,
                    "word_type": "a.",
                    "spelling_usage": "To spend time lazily, whether lolling or idly sauntering;\n   to pass time indolently; to stand, sit, or recline, in an indolent\n   manner."
                },
                {
                    "spelling_detail_id": 79462,
                    "word_type": "n.",
                    "spelling_usage": "An idle gait or stroll; the state of reclining indolently;\n   a place of lounging."
                },
                {
                    "spelling_detail_id": 79463,
                    "word_type": "n.",
                    "spelling_usage": "A piece of furniture resembling a sofa, upon which one may\n   lie or recline."
                }
            ]
        },
        {
            "id": 50626,
            "spelling_name": "Lucky",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 79720,
                    "word_type": "superl.",
                    "spelling_usage": "Favored by luck; fortunate; meeting with good success\n   or good fortune; -- said of persons; as, a lucky adventurer."
                },
                {
                    "spelling_detail_id": 79721,
                    "word_type": "superl.",
                    "spelling_usage": "Producing, or resulting in, good by chance, or\n   unexpectedly; favorable; auspicious; fortunate; as, a lucky mistake; a\n   lucky cast; a lucky hour."
                }
            ]
        },
        {
            "id": 50648,
            "spelling_name": "Ludicrous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 79749,
                    "word_type": "a.",
                    "spelling_usage": "Adapted to excite laughter, without scorn or contempt;\n   sportive."
                }
            ]
        },
        {
            "id": 51204,
            "spelling_name": "Magician",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 80567,
                    "word_type": "n.",
                    "spelling_usage": "One skilled in magic; one who practices the black art; an\n   enchanter; a necromancer; a sorcerer or sorceress; a conjurer."
                }
            ]
        },
        {
            "id": 51222,
            "spelling_name": "Magma",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 80595,
                    "word_type": "n.",
                    "spelling_usage": "Any crude mixture of mineral or organic matters in the state\n   of a thin paste."
                },
                {
                    "spelling_detail_id": 80596,
                    "word_type": "n.",
                    "spelling_usage": "A thick residuum obtained from certain substances after the\n   fluid parts are expressed from them; the grounds which remain after\n   treating a substance with any menstruum, as water or alcohol."
                },
                {
                    "spelling_detail_id": 80597,
                    "word_type": "n.",
                    "spelling_usage": "A salve or confection of thick consistency."
                },
                {
                    "spelling_detail_id": 80598,
                    "word_type": "n.",
                    "spelling_usage": "The molten matter within the earth, the source of the\n   material of lava flows, dikes of eruptive rocks, etc."
                },
                {
                    "spelling_detail_id": 80599,
                    "word_type": "n.",
                    "spelling_usage": "The glassy base of an eruptive rock."
                },
                {
                    "spelling_detail_id": 80600,
                    "word_type": "n.",
                    "spelling_usage": "The amorphous or homogenous matrix or ground mass, as\n   distinguished from well-defined crystals; as, the magma of porphyry."
                }
            ]
        },
        {
            "id": 51727,
            "spelling_name": "Mandate",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 81346,
                    "word_type": "n.",
                    "spelling_usage": "An official or authoritative command; an order or\n   injunction; a commission; a judicial precept."
                },
                {
                    "spelling_detail_id": 81347,
                    "word_type": "n.",
                    "spelling_usage": "A rescript of the pope, commanding an ordinary collator to\n   put the person therein named in possession of the first vacant benefice\n   in his collation."
                },
                {
                    "spelling_detail_id": 81348,
                    "word_type": "n.",
                    "spelling_usage": "A contract by which one employs another to manage any\n   business for him. By the Roman law, it must have been gratuitous."
                }
            ]
        },
        {
            "id": 51811,
            "spelling_name": "Mangrove",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 81458,
                    "word_type": "n.",
                    "spelling_usage": "The name of one or two trees of the genus Rhizophora (R.\n   Mangle, and R. mucronata, the last doubtfully distinct) inhabiting\n   muddy shores of tropical regions, where they spread by emitting aerial\n   roots, which fasten in the saline mire and eventually become new stems.\n   The seeds also send down a strong root while yet attached to the parent\n   plant."
                },
                {
                    "spelling_detail_id": 81459,
                    "word_type": "n.",
                    "spelling_usage": "The mango fish."
                }
            ]
        },
        {
            "id": 51931,
            "spelling_name": "Manta",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 81630,
                    "word_type": "n.",
                    "spelling_usage": "See Coleoptera and Sea devil."
                }
            ]
        },
        {
            "id": 52119,
            "spelling_name": "Marine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 81918,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the sea; having to do with the ocean,\n   or with navigation or naval affairs; nautical; as, marine productions\n   or bodies; marine shells; a marine engine."
                },
                {
                    "spelling_detail_id": 81919,
                    "word_type": "a.",
                    "spelling_usage": "Formed by the action of the currents or waves of the sea;\n   as, marine deposits."
                },
                {
                    "spelling_detail_id": 81920,
                    "word_type": "a.",
                    "spelling_usage": "A solider serving on shipboard; a sea soldier; one of a\n   body of troops trained to do duty in the navy."
                },
                {
                    "spelling_detail_id": 81921,
                    "word_type": "a.",
                    "spelling_usage": "The sum of naval affairs; naval economy; the department of\n   navigation and sea forces; the collective shipping of a country; as,\n   the mercantile marine."
                },
                {
                    "spelling_detail_id": 81922,
                    "word_type": "a.",
                    "spelling_usage": "A picture representing some marine subject."
                }
            ]
        },
        {
            "id": 52265,
            "spelling_name": "Martial",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 82163,
                    "word_type": "a.",
                    "spelling_usage": "Of, pertaining to, or suited for, war; military; as,\n   martial music; a martial appearance."
                },
                {
                    "spelling_detail_id": 82164,
                    "word_type": "a.",
                    "spelling_usage": "Practiced in, or inclined to, war; warlike; brave."
                },
                {
                    "spelling_detail_id": 82165,
                    "word_type": "a.",
                    "spelling_usage": "Belonging to war, or to an army and navy; -- opposed to\n   civil; as, martial law; a court-martial."
                },
                {
                    "spelling_detail_id": 82166,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to, or resembling, the god, or the planet,\n   Mars."
                },
                {
                    "spelling_detail_id": 82167,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to, or containing, iron; chalybeate; as,\n   martial preparations."
                }
            ]
        },
        {
            "id": 52301,
            "spelling_name": "Marvelous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 82217,
                    "word_type": "n.",
                    "spelling_usage": "Exciting wonder or surprise; astonishing; wonderful."
                },
                {
                    "spelling_detail_id": 82218,
                    "word_type": "n.",
                    "spelling_usage": "Partaking of the character of miracle, or supernatural\n   power; incredible."
                }
            ]
        },
        {
            "id": 52412,
            "spelling_name": "Mastiff",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 82420,
                    "word_type": "n.",
                    "spelling_usage": "A breed of large dogs noted for strength and courage.\n   There are various strains, differing in form and color, and\n   characteristic of different countries."
                }
            ]
        },
        {
            "id": 52626,
            "spelling_name": "Maximum",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 82774,
                    "word_type": "n.",
                    "spelling_usage": "The greatest quantity or value attainable in a given case;\n   or, the greatest value attained by a quantity which first increases and\n   then begins to decrease; the highest point or degree; -- opposed to\n   minimum."
                },
                {
                    "spelling_detail_id": 82775,
                    "word_type": "a.",
                    "spelling_usage": "Greatest in quantity or highest in degree attainable or\n   attained; as, a maximum consumption of fuel; maximum pressure; maximum\n   heat."
                }
            ]
        },
        {
            "id": 52637,
            "spelling_name": "Mayhem",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 82796,
                    "word_type": "n.",
                    "spelling_usage": "The maiming of a person by depriving him of the use of any\n   of his members which are necessary for defense or protection. See Maim."
                }
            ]
        },
        {
            "id": 52712,
            "spelling_name": "Measly",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 82929,
                    "word_type": "a.",
                    "spelling_usage": "Infected with measles."
                },
                {
                    "spelling_detail_id": 82930,
                    "word_type": "a.",
                    "spelling_usage": "Containing larval tapeworms; -- said of pork and beef."
                }
            ]
        },
        {
            "id": 52776,
            "spelling_name": "Medallion",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83045,
                    "word_type": "n.",
                    "spelling_usage": "A large medal or memorial coin."
                },
                {
                    "spelling_detail_id": 83046,
                    "word_type": "n.",
                    "spelling_usage": "A circular or oval (or, sometimes, square) tablet\n   bearing a figure or figures represented in relief."
                }
            ]
        },
        {
            "id": 52890,
            "spelling_name": "Meekness",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83218,
                    "word_type": "n.",
                    "spelling_usage": "The quality or state of being meek."
                }
            ]
        },
        {
            "id": 53095,
            "spelling_name": "Melody",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83478,
                    "word_type": "n.",
                    "spelling_usage": "A sweet or agreeable succession of sounds."
                },
                {
                    "spelling_detail_id": 83479,
                    "word_type": "n.",
                    "spelling_usage": "A rhythmical succession of single tones, ranging for the\n   most part within a given key, and so related together as to form a\n   musical whole, having the unity of what is technically called a musical\n   thought, at once pleasing to the ear and characteristic in expression."
                },
                {
                    "spelling_detail_id": 83480,
                    "word_type": "n.",
                    "spelling_usage": "The air or tune of a musical piece."
                }
            ]
        },
        {
            "id": 53116,
            "spelling_name": "Membership",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83520,
                    "word_type": "n.",
                    "spelling_usage": "The state of being a member."
                },
                {
                    "spelling_detail_id": 83521,
                    "word_type": "n.",
                    "spelling_usage": "The collective body of members, as of a society."
                }
            ]
        },
        {
            "id": 53136,
            "spelling_name": "Memorandum",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83546,
                    "word_type": "n.",
                    "spelling_usage": "A record of something which it is desired to remember;\n   a note to help the memory."
                },
                {
                    "spelling_detail_id": 83547,
                    "word_type": "n.",
                    "spelling_usage": "A brief or informal note in writing of some\n   transaction, or an outline of an intended instrument; an instrument\n   drawn up in a brief and compendious form."
                }
            ]
        },
        {
            "id": 53292,
            "spelling_name": "Merchandise",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83751,
                    "word_type": "n.",
                    "spelling_usage": "The objects of commerce; whatever is usually bought or\n   sold in trade, or market, or by merchants; wares; goods; commodities."
                },
                {
                    "spelling_detail_id": 83752,
                    "word_type": "n.",
                    "spelling_usage": "The act or business of trading; trade; traffic."
                },
                {
                    "spelling_detail_id": 83755,
                    "word_type": "v. i.",
                    "spelling_usage": "To trade; to carry on commerce."
                },
                {
                    "spelling_detail_id": 83756,
                    "word_type": "v. t.",
                    "spelling_usage": "To make merchandise of; to buy and sell."
                }
            ]
        },
        {
            "id": 53366,
            "spelling_name": "Merlin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 83880,
                    "word_type": "n.",
                    "spelling_usage": "A small European falcon (Falco lithofalco, or F. aesalon)."
                }
            ]
        },
        {
            "id": 53742,
            "spelling_name": "Meteor",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 84351,
                    "word_type": "n.",
                    "spelling_usage": "Any phenomenon or appearance in the atmosphere, as clouds,\n   rain, hail, snow, etc."
                },
                {
                    "spelling_detail_id": 84352,
                    "word_type": "n.",
                    "spelling_usage": "Specif.: A transient luminous body or appearance seen in\n   the atmosphere, or in a more elevated region."
                }
            ]
        },
        {
            "id": 54055,
            "spelling_name": "Midriff",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 84738,
                    "word_type": "n.",
                    "spelling_usage": "See Diaphragm, n., 2."
                }
            ]
        },
        {
            "id": 54084,
            "spelling_name": "Migraine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 84790,
                    "word_type": "n.",
                    "spelling_usage": "Same as Megrim."
                }
            ]
        },
        {
            "id": 54098,
            "spelling_name": "Mildew",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 84809,
                    "word_type": "n.",
                    "spelling_usage": "A growth of minute powdery or webby fungi, whitish or of\n   different colors, found on various diseased or decaying substances."
                },
                {
                    "spelling_detail_id": 84812,
                    "word_type": "v. t.",
                    "spelling_usage": "To taint with mildew."
                },
                {
                    "spelling_detail_id": 84813,
                    "word_type": "v. i.",
                    "spelling_usage": "To become tainted with mildew."
                }
            ]
        },
        {
            "id": 54117,
            "spelling_name": "Militant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 84838,
                    "word_type": "a.",
                    "spelling_usage": "Engaged in warfare; fighting; combating; serving as a\n   soldier."
                }
            ]
        },
        {
            "id": 54190,
            "spelling_name": "Millionaire",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 84956,
                    "word_type": "n.",
                    "spelling_usage": "One whose wealth is counted by millions of francs,\n   dollars, or pounds; a very rich person; a person worth a million or\n   more."
                }
            ]
        },
        {
            "id": 54246,
            "spelling_name": "Mince",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85031,
                    "word_type": "v. t.",
                    "spelling_usage": "To cut into very small pieces; to chop fine; to hash; as,\n   to mince meat."
                },
                {
                    "spelling_detail_id": 85032,
                    "word_type": "v. t.",
                    "spelling_usage": "To suppress or weaken the force of; to extenuate; to\n   palliate; to tell by degrees, instead of directly and frankly; to clip,\n   as words or expressions; to utter half and keep back half of."
                },
                {
                    "spelling_detail_id": 85033,
                    "word_type": "v. t.",
                    "spelling_usage": "To affect; to make a parade of."
                },
                {
                    "spelling_detail_id": 85034,
                    "word_type": "v. i.",
                    "spelling_usage": "To walk with short steps; to walk in a prim, affected\n   manner."
                },
                {
                    "spelling_detail_id": 85035,
                    "word_type": "v. i.",
                    "spelling_usage": "To act or talk with affected nicety; to affect delicacy\n   in manner."
                },
                {
                    "spelling_detail_id": 85036,
                    "word_type": "n.",
                    "spelling_usage": "A short, precise step; an affected manner."
                }
            ]
        },
        {
            "id": 54353,
            "spelling_name": "Minority",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85231,
                    "word_type": "a. & n.",
                    "spelling_usage": "The state of being a minor, or under age."
                },
                {
                    "spelling_detail_id": 85232,
                    "word_type": "a. & n.",
                    "spelling_usage": "State of being less or small."
                },
                {
                    "spelling_detail_id": 85233,
                    "word_type": "a. & n.",
                    "spelling_usage": "The smaller number; -- opposed to majority; as, the\n   minority must be ruled by the majority."
                }
            ]
        },
        {
            "id": 54396,
            "spelling_name": "Miraculous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85309,
                    "word_type": "a.",
                    "spelling_usage": "Of the nature of a miracle; performed by supernatural\n   power; effected by the direct agency of almighty power, and not by\n   natural causes."
                },
                {
                    "spelling_detail_id": 85310,
                    "word_type": "a.",
                    "spelling_usage": "Supernatural; wonderful."
                },
                {
                    "spelling_detail_id": 85311,
                    "word_type": "a.",
                    "spelling_usage": "Wonder-working."
                }
            ]
        },
        {
            "id": 54510,
            "spelling_name": "Mischief",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85448,
                    "word_type": "n.",
                    "spelling_usage": "Harm; damage; esp., disarrangement of order; trouble or\n   vexation caused by human agency or by some living being, intentionally\n   or not; often, calamity, mishap; trivial evil caused by\n   thoughtlessness, or in sport."
                },
                {
                    "spelling_detail_id": 85449,
                    "word_type": "n.",
                    "spelling_usage": "Cause of trouble or vexation; trouble."
                },
                {
                    "spelling_detail_id": 85450,
                    "word_type": "v. t.",
                    "spelling_usage": "To do harm to."
                }
            ]
        },
        {
            "id": 54541,
            "spelling_name": "Misconception",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85483,
                    "word_type": "n.",
                    "spelling_usage": "Erroneous conception; false opinion; wrong\n   understanding."
                }
            ]
        },
        {
            "id": 54626,
            "spelling_name": "Misery",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85602,
                    "word_type": "n.",
                    "spelling_usage": "Great unhappiness; extreme pain of body or mind;\n   wretchedness; distress; woe."
                },
                {
                    "spelling_detail_id": 85603,
                    "word_type": "n.",
                    "spelling_usage": "Cause of misery; calamity; misfortune."
                },
                {
                    "spelling_detail_id": 85604,
                    "word_type": "n.",
                    "spelling_usage": "Covetousness; niggardliness; avarice."
                }
            ]
        },
        {
            "id": 54697,
            "spelling_name": "Misinterpret",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85687,
                    "word_type": "v. t.",
                    "spelling_usage": "To interpret erroneously; to understand or to\n   explain in a wrong sense."
                }
            ]
        },
        {
            "id": 54844,
            "spelling_name": "Missile",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85874,
                    "word_type": "a.",
                    "spelling_usage": "Capable of being thrown; adapted for hurling or to be\n   projected from the hand, or from any instrument or rngine, so as to\n   strike an object at a distance."
                },
                {
                    "spelling_detail_id": 85875,
                    "word_type": "n.",
                    "spelling_usage": "A weapon thrown or projected or intended to be projcted,\n   as a lance, an arrow, or a bullet."
                }
            ]
        },
        {
            "id": 54854,
            "spelling_name": "Missive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85893,
                    "word_type": "n.",
                    "spelling_usage": "Specially sent; intended or prepared to be sent; as, a\n   letter missive."
                },
                {
                    "spelling_detail_id": 85894,
                    "word_type": "n.",
                    "spelling_usage": "Missile."
                },
                {
                    "spelling_detail_id": 85895,
                    "word_type": "n.",
                    "spelling_usage": "That which is sent; a writing containing a message."
                },
                {
                    "spelling_detail_id": 85896,
                    "word_type": "n.",
                    "spelling_usage": "One who is sent; a messenger."
                }
            ]
        },
        {
            "id": 54892,
            "spelling_name": "Mister",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 85957,
                    "word_type": "n.",
                    "spelling_usage": "A title of courtesy prefixed to the name of a man or youth.\n   It is usually written in the abbreviated form Mr."
                },
                {
                    "spelling_detail_id": 85958,
                    "word_type": "v. t.",
                    "spelling_usage": "To address or mention by the title Mr.; as, he mistered\n   me in a formal way."
                },
                {
                    "spelling_detail_id": 85959,
                    "word_type": "n.",
                    "spelling_usage": "A trade, art, or occupation."
                },
                {
                    "spelling_detail_id": 85960,
                    "word_type": "n.",
                    "spelling_usage": "Manner; kind; sort."
                },
                {
                    "spelling_detail_id": 85961,
                    "word_type": "n.",
                    "spelling_usage": "Need; necessity."
                },
                {
                    "spelling_detail_id": 85962,
                    "word_type": "v. i.",
                    "spelling_usage": "To be needful or of use."
                }
            ]
        },
        {
            "id": 55124,
            "spelling_name": "Modesty",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 86315,
                    "word_type": "n.",
                    "spelling_usage": "The quality or state of being modest; that lowly temper\n   which accompanies a moderate estimate of one's own worth and\n   importance; absence of self-assertion, arrogance, and presumption;\n   humility respecting one's own merit."
                },
                {
                    "spelling_detail_id": 86316,
                    "word_type": "n.",
                    "spelling_usage": "Natural delicacy or shame regarding personal charms and\n   the sexual relation; purity of thought and manners; due regard for\n   propriety in speech or action."
                }
            ]
        },
        {
            "id": 55168,
            "spelling_name": "Mogul",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 86372,
                    "word_type": "n.",
                    "spelling_usage": "A person of the Mongolian race."
                },
                {
                    "spelling_detail_id": 86373,
                    "word_type": "n.",
                    "spelling_usage": "A heavy locomotive for freight traffic, having three pairs\n   of connected driving wheels and a two-wheeled truck."
                }
            ]
        },
        {
            "id": 55204,
            "spelling_name": "Moisture",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 86420,
                    "word_type": "n.",
                    "spelling_usage": "A moderate degree of wetness."
                },
                {
                    "spelling_detail_id": 86421,
                    "word_type": "n.",
                    "spelling_usage": "That which moistens or makes damp or wet; exuding fluid;\n   liquid in small quantity."
                }
            ]
        },
        {
            "id": 55394,
            "spelling_name": "Money",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 86712,
                    "word_type": "n.",
                    "spelling_usage": "A piece of metal, as gold, silver, copper, etc., coined, or\n   stamped, and issued by the sovereign authority as a medium of exchange\n   in financial transactions between citizens and with government; also,\n   any number of such pieces; coin."
                },
                {
                    "spelling_detail_id": 86713,
                    "word_type": "n.",
                    "spelling_usage": "Any written or stamped promise, certificate, or order, as a\n   government note, a bank note, a certificate of deposit, etc., which is\n   payable in standard coined money and is lawfully current in lieu of it;\n   in a comprehensive sense, any currency usually and lawfully employed in\n   buying and selling."
                },
                {
                    "spelling_detail_id": 86714,
                    "word_type": "n.",
                    "spelling_usage": "In general, wealth; property; as, he has much money in land,\n   or in stocks; to make, or lose, money."
                },
                {
                    "spelling_detail_id": 86715,
                    "word_type": "v. t.",
                    "spelling_usage": "To supply with money."
                }
            ]
        },
        {
            "id": 55604,
            "spelling_name": "Monopolize",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 86992,
                    "word_type": "v. t.",
                    "spelling_usage": "To acquire a monopoly of; to have or get the\n   exclusive privilege or means of dealing in, or the exclusive possession\n   of; to engross the whole of; as, to monopolize the coffee trade; to\n   monopolize land."
                }
            ]
        },
        {
            "id": 55681,
            "spelling_name": "Monstrosity",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87084,
                    "word_type": "n.",
                    "spelling_usage": "The state of being monstrous, or out of the common\n   order of nature; that which is monstrous; a monster."
                }
            ]
        },
        {
            "id": 55783,
            "spelling_name": "Moose",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87240,
                    "word_type": "n.",
                    "spelling_usage": "A large cervine mammal (Alces machlis, or A. Americanus),\n   native of the Northern United States and Canada. The adult male is\n   about as large as a horse, and has very large, palmate antlers. It\n   closely resembles the European elk, and by many zoologists is\n   considered the same species. See Elk."
                }
            ]
        },
        {
            "id": 55800,
            "spelling_name": "Moped",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87269,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Mope"
                }
            ]
        },
        {
            "id": 55958,
            "spelling_name": "Mortal",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87509,
                    "word_type": "a.",
                    "spelling_usage": "Subject to death; destined to die; as, man is mortal."
                },
                {
                    "spelling_detail_id": 87510,
                    "word_type": "a.",
                    "spelling_usage": "Destructive to life; causing or occasioning death;\n   terminating life; exposing to or deserving death; deadly; as, a mortal\n   wound; a mortal sin."
                },
                {
                    "spelling_detail_id": 87511,
                    "word_type": "a.",
                    "spelling_usage": "Fatally vulnerable; vital."
                },
                {
                    "spelling_detail_id": 87512,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the time of death."
                },
                {
                    "spelling_detail_id": 87513,
                    "word_type": "a.",
                    "spelling_usage": "Affecting as if with power to kill; deathly."
                },
                {
                    "spelling_detail_id": 87514,
                    "word_type": "a.",
                    "spelling_usage": "Human; belonging to man, who is mortal; as, mortal wit or\n   knowledge; mortal power."
                },
                {
                    "spelling_detail_id": 87515,
                    "word_type": "a.",
                    "spelling_usage": "Very painful or tedious; wearisome; as, a sermon lasting\n   two mortal hours."
                },
                {
                    "spelling_detail_id": 87516,
                    "word_type": "n.",
                    "spelling_usage": "A being subject to death; a human being; man."
                }
            ]
        },
        {
            "id": 56017,
            "spelling_name": "Mosquito",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87616,
                    "word_type": "n.",
                    "spelling_usage": "Any one of various species of gnats of the genus Culex\n   and allied genera. The females have a proboscis containing, within the\n   sheathlike labium, six fine, sharp, needlelike organs with which they\n   puncture the skin of man and animals to suck the blood. These bites,\n   when numerous, cause, in many persons, considerable irritation and\n   swelling, with some pain. The larvae and pupae, called wigglers, are\n   aquatic."
                }
            ]
        },
        {
            "id": 56081,
            "spelling_name": "Motor",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87740,
                    "word_type": "n.",
                    "spelling_usage": "One who, or that which, imparts motion; a source of\n   mechanical power."
                },
                {
                    "spelling_detail_id": 87741,
                    "word_type": "n.",
                    "spelling_usage": "A prime mover; a machine by means of which a source of\n   power, as steam, moving water, electricity, etc., is made available for\n   doing mechanical work."
                },
                {
                    "spelling_detail_id": 87742,
                    "word_type": "n.",
                    "spelling_usage": "Alt. of Motorial"
                }
            ]
        },
        {
            "id": 56091,
            "spelling_name": "Motto",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87755,
                    "word_type": "n.",
                    "spelling_usage": "A sentence, phrase, or word, forming part of an heraldic\n   achievment."
                },
                {
                    "spelling_detail_id": 87756,
                    "word_type": "n.",
                    "spelling_usage": "A sentence, phrase, or word, prefixed to an essay,\n   discourse, chapter, canto, or the like, suggestive of its subject\n   matter; a short, suggestive expression of a guiding principle; a maxim."
                }
            ]
        },
        {
            "id": 56199,
            "spelling_name": "Mower",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87976,
                    "word_type": "n.",
                    "spelling_usage": "One who, or that which, mows; a mowing machine; as, a lawn\n   mower."
                }
            ]
        },
        {
            "id": 56204,
            "spelling_name": "Moxie",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 87983,
                    "word_type": "n.",
                    "spelling_usage": "energy; pep."
                },
                {
                    "spelling_detail_id": 87984,
                    "word_type": "n.",
                    "spelling_usage": "courage, determination."
                },
                {
                    "spelling_detail_id": 87985,
                    "word_type": "n.",
                    "spelling_usage": "Know-how, expertise."
                }
            ]
        },
        {
            "id": 56651,
            "spelling_name": "Muscular",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 88611,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to a muscle, or to a system of muscles;\n   consisting of, or constituting, a muscle or muscles; as, muscular\n   fiber."
                },
                {
                    "spelling_detail_id": 88612,
                    "word_type": "a.",
                    "spelling_usage": "Performed by, or dependent on, a muscle or the muscles."
                },
                {
                    "spelling_detail_id": 88613,
                    "word_type": "a.",
                    "spelling_usage": "Well furnished with muscles; having well-developed\n   muscles; brawny; hence, strong; powerful; vigorous; as, a muscular body\n   or arm."
                }
            ]
        },
        {
            "id": 56775,
            "spelling_name": "Mutter",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 88817,
                    "word_type": "v. i.",
                    "spelling_usage": "To utter words indistinctly or with a low voice and lips\n   partly closed; esp., to utter indistinct complaints or angry\n   expressions; to grumble; to growl."
                },
                {
                    "spelling_detail_id": 88818,
                    "word_type": "v. i.",
                    "spelling_usage": "To sound with a low, rumbling noise."
                },
                {
                    "spelling_detail_id": 88819,
                    "word_type": "v. t.",
                    "spelling_usage": "To utter with imperfect articulations, or with a low\n   voice; as, to mutter threats."
                },
                {
                    "spelling_detail_id": 88820,
                    "word_type": "n.",
                    "spelling_usage": "Repressed or obscure utterance."
                }
            ]
        },
        {
            "id": 56938,
            "spelling_name": "Myself",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 89003,
                    "word_type": "pron.",
                    "spelling_usage": "I or me in person; -- used for emphasis, my own self or\n   person; as I myself will do it; I have done it myself; -- used also\n   instead of me, as the object of the first person of a reflexive verb,\n   without emphasis; as, I will defend myself."
                }
            ]
        },
        {
            "id": 57107,
            "spelling_name": "Napkin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 89243,
                    "word_type": "n.",
                    "spelling_usage": "A little towel, or small cloth, esp. one for wiping the\n   fingers and mouth at table."
                },
                {
                    "spelling_detail_id": 89244,
                    "word_type": "n.",
                    "spelling_usage": "A handkerchief."
                }
            ]
        },
        {
            "id": 57231,
            "spelling_name": "Nationalism",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 89426,
                    "word_type": "n.",
                    "spelling_usage": "The state of being national; national attachment;\n   nationality."
                },
                {
                    "spelling_detail_id": 89427,
                    "word_type": "n.",
                    "spelling_usage": "An idiom, trait, or character peculiar to any nation."
                },
                {
                    "spelling_detail_id": 89428,
                    "word_type": "n.",
                    "spelling_usage": "National independence; the principles of the\n   Nationalists."
                }
            ]
        },
        {
            "id": 57323,
            "spelling_name": "Navigator",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 89594,
                    "word_type": "n.",
                    "spelling_usage": "One who navigates or sails; esp., one who direct the\n   course of a ship, or one who is skillful in the art of navigation;\n   also, a book which teaches the art of navigation; as, Bowditch's\n   Navigator."
                }
            ]
        },
        {
            "id": 57561,
            "spelling_name": "Neigh",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 89961,
                    "word_type": "v. i.",
                    "spelling_usage": "To utter the cry of the horse; to whinny."
                },
                {
                    "spelling_detail_id": 89962,
                    "word_type": "v. i.",
                    "spelling_usage": "To scoff or sneer; to jeer."
                },
                {
                    "spelling_detail_id": 89963,
                    "word_type": "n.",
                    "spelling_usage": "The cry of a horse; a whinny."
                }
            ]
        },
        {
            "id": 57679,
            "spelling_name": "Nephew",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 90106,
                    "word_type": "n.",
                    "spelling_usage": "A grandson or grandchild, or remoter lineal descendant."
                },
                {
                    "spelling_detail_id": 90107,
                    "word_type": "n.",
                    "spelling_usage": "A cousin."
                },
                {
                    "spelling_detail_id": 90108,
                    "word_type": "n.",
                    "spelling_usage": "The son of a brother or a sister, or of a brother-in-law or\n   sister-in-law."
                }
            ]
        },
        {
            "id": 57897,
            "spelling_name": "Nibble",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 90445,
                    "word_type": "v. t.",
                    "spelling_usage": "To bite by little at a time; to seize gently with the\n   mouth; to eat slowly or in small bits."
                },
                {
                    "spelling_detail_id": 90446,
                    "word_type": "v. t.",
                    "spelling_usage": "To bite upon something gently or cautiously; to eat a\n   little of a thing, as by taking small bits cautiously; as, fishes\n   nibble at the bait."
                },
                {
                    "spelling_detail_id": 90447,
                    "word_type": "n.",
                    "spelling_usage": "A small or cautious bite."
                }
            ]
        },
        {
            "id": 58263,
            "spelling_name": "Noggin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 90984,
                    "word_type": "n.",
                    "spelling_usage": "A small mug or cup."
                },
                {
                    "spelling_detail_id": 90985,
                    "word_type": "n.",
                    "spelling_usage": "A measure equivalent to a gill."
                }
            ]
        },
        {
            "id": 58332,
            "spelling_name": "Nominee",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 91085,
                    "word_type": "n.",
                    "spelling_usage": "A person named, or designated, by another, to any office,\n   duty, or position; one nominated, or proposed, by others for office or\n   for election to office."
                }
            ]
        },
        {
            "id": 58544,
            "spelling_name": "Noon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 91329,
                    "word_type": "a.",
                    "spelling_usage": "No. See the Note under No."
                },
                {
                    "spelling_detail_id": 91330,
                    "word_type": "n.",
                    "spelling_usage": "The middle of the day; midday; the time when the sun is in\n   the meridian; twelve o'clock in the daytime."
                },
                {
                    "spelling_detail_id": 91331,
                    "word_type": "n.",
                    "spelling_usage": "Hence, the highest point; culmination."
                },
                {
                    "spelling_detail_id": 91332,
                    "word_type": "a.",
                    "spelling_usage": "Belonging to midday; occurring at midday; meridional."
                },
                {
                    "spelling_detail_id": 91333,
                    "word_type": "v. i.",
                    "spelling_usage": "To take rest and refreshment at noon."
                }
            ]
        },
        {
            "id": 58736,
            "spelling_name": "Nourish",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 91648,
                    "word_type": "v. t.",
                    "spelling_usage": "To feed and cause to grow; to supply with matter which\n   increases bulk or supplies waste, and promotes health; to furnish with\n   nutriment."
                },
                {
                    "spelling_detail_id": 91649,
                    "word_type": "v. t.",
                    "spelling_usage": "To support; to maintain."
                },
                {
                    "spelling_detail_id": 91650,
                    "word_type": "v. t.",
                    "spelling_usage": "To supply the means of support and increase to; to\n   encourage; to foster; as, to nourish rebellion; to nourish the virtues."
                },
                {
                    "spelling_detail_id": 91651,
                    "word_type": "v. t.",
                    "spelling_usage": "To cherish; to comfort."
                },
                {
                    "spelling_detail_id": 91652,
                    "word_type": "v. t.",
                    "spelling_usage": "To educate; to instruct; to bring up; to nurture; to\n   promote the growth of in attainments."
                },
                {
                    "spelling_detail_id": 91653,
                    "word_type": "v. i.",
                    "spelling_usage": "To promote growth; to furnish nutriment."
                },
                {
                    "spelling_detail_id": 91654,
                    "word_type": "v. i.",
                    "spelling_usage": "To gain nourishment."
                },
                {
                    "spelling_detail_id": 91655,
                    "word_type": "n.",
                    "spelling_usage": "A nurse."
                }
            ]
        },
        {
            "id": 58800,
            "spelling_name": "Nuance",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 91751,
                    "word_type": "n.",
                    "spelling_usage": "A shade of difference; a delicate gradation."
                }
            ]
        },
        {
            "id": 58975,
            "spelling_name": "Nurture",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 92004,
                    "word_type": "n.",
                    "spelling_usage": "The act of nourishing or nursing; thender care; education;\n   training."
                },
                {
                    "spelling_detail_id": 92005,
                    "word_type": "n.",
                    "spelling_usage": "That which nourishes; food; diet."
                },
                {
                    "spelling_detail_id": 92008,
                    "word_type": "v. t.",
                    "spelling_usage": "To feed; to nourish."
                },
                {
                    "spelling_detail_id": 92009,
                    "word_type": "v. t.",
                    "spelling_usage": "To educate; to bring or train up."
                }
            ]
        },
        {
            "id": 59075,
            "spelling_name": "Oasis",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 92151,
                    "word_type": "n.",
                    "spelling_usage": "A fertile or green spot in a waste or desert, esp. in a\n   sandy desert."
                }
            ]
        },
        {
            "id": 59223,
            "spelling_name": "Oblong",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 92365,
                    "word_type": "a.",
                    "spelling_usage": "Having greater length than breadth, esp. when rectangular."
                },
                {
                    "spelling_detail_id": 92366,
                    "word_type": "n.",
                    "spelling_usage": "A rectangular figure longer than it is broad; hence, any\n   figure longer than it is broad."
                }
            ]
        },
        {
            "id": 59888,
            "spelling_name": "Olive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 93321,
                    "word_type": "n.",
                    "spelling_usage": "A tree (Olea Europaea) with small oblong or elliptical\n   leaves, axillary clusters of flowers, and oval, one-seeded drupes. The\n   tree has been cultivated for its fruit for thousands of years, and its\n   branches are the emblems of peace. The wood is yellowish brown and\n   beautifully variegated."
                },
                {
                    "spelling_detail_id": 93322,
                    "word_type": "n.",
                    "spelling_usage": "The fruit of the olive. It has been much improved by\n   cultivation, and is used for making pickles. Olive oil is pressed from\n   its flesh."
                },
                {
                    "spelling_detail_id": 93323,
                    "word_type": "n.",
                    "spelling_usage": "Any shell of the genus Oliva and allied genera; -- so called\n   from the form. See Oliva."
                },
                {
                    "spelling_detail_id": 93324,
                    "word_type": "n.",
                    "spelling_usage": "The oyster catcher."
                },
                {
                    "spelling_detail_id": 93325,
                    "word_type": "n.",
                    "spelling_usage": "The color of the olive, a peculiar dark brownish, yellowish,\n   or tawny green."
                },
                {
                    "spelling_detail_id": 93326,
                    "word_type": "n.",
                    "spelling_usage": "One of the tertiary colors, composed of violet and green\n   mixed in equal strength and proportion."
                },
                {
                    "spelling_detail_id": 93327,
                    "word_type": "n.",
                    "spelling_usage": "An olivary body. See under Olivary."
                },
                {
                    "spelling_detail_id": 93328,
                    "word_type": "n.",
                    "spelling_usage": "A small slice of meat seasoned, rolled up, and cooked; as,\n   olives of beef or veal."
                },
                {
                    "spelling_detail_id": 93329,
                    "word_type": "a.",
                    "spelling_usage": "Approaching the color of the olive; of a peculiar dark\n   brownish, yellowish, or tawny green."
                }
            ]
        },
        {
            "id": 59917,
            "spelling_name": "Omen",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 93366,
                    "word_type": "n.",
                    "spelling_usage": "An occurrence supposed to portend, or show the character of,\n   some future event; any indication or action regarded as a foreshowing;\n   a foreboding; a presage; an augury."
                },
                {
                    "spelling_detail_id": 93369,
                    "word_type": "v. t.",
                    "spelling_usage": "To divine or to foreshow by signs or portents; to have\n   omens or premonitions regarding; to predict; to augur; as, to omen ill\n   of an enterprise."
                }
            ]
        },
        {
            "id": 59929,
            "spelling_name": "Omission",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 93380,
                    "word_type": "n.",
                    "spelling_usage": "The act of omitting; neglect or failure to do something\n   required by propriety or duty."
                },
                {
                    "spelling_detail_id": 93381,
                    "word_type": "n.",
                    "spelling_usage": "That which is omitted or is left undone."
                }
            ]
        },
        {
            "id": 60048,
            "spelling_name": "Onion",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 93545,
                    "word_type": "n.",
                    "spelling_usage": "A liliaceous plant of the genus Allium (A. cepa), having a\n   strong-flavored bulb and long hollow leaves; also, its bulbous root,\n   much used as an article of food. The name is often extended to other\n   species of the genus."
                }
            ]
        },
        {
            "id": 60280,
            "spelling_name": "Opinionated",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 93890,
                    "word_type": "a.",
                    "spelling_usage": "Stiff in opinion; firmly or unduly adhering to one's\n   own opinion or to preconceived notions; obstinate in opinion."
                }
            ]
        },
        {
            "id": 60650,
            "spelling_name": "Originate",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 94482,
                    "word_type": "v. t.",
                    "spelling_usage": "To give an origin or beginning to; to cause to be; to\n   bring into existence; to produce as new."
                },
                {
                    "spelling_detail_id": 94483,
                    "word_type": "v. i.",
                    "spelling_usage": "To take first existence; to have origin or beginning;\n   to begin to exist or act; as, the scheme originated with the governor\n   and council."
                }
            ]
        },
        {
            "id": 60675,
            "spelling_name": "Ornament",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 94510,
                    "word_type": "n.",
                    "spelling_usage": "That which embellishes or adorns; that which adds grace\n   or beauty; embellishment; decoration; adornment."
                },
                {
                    "spelling_detail_id": 94513,
                    "word_type": "v. t.",
                    "spelling_usage": "To adorn; to deck; to embellish; to beautify; as, to\n   ornament a room, or a city."
                }
            ]
        },
        {
            "id": 61093,
            "spelling_name": "Ounce",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 95027,
                    "word_type": "n.",
                    "spelling_usage": "A weight, the sixteenth part of a pound avoirdupois, and\n   containing 437/ grains."
                },
                {
                    "spelling_detail_id": 95028,
                    "word_type": "n.",
                    "spelling_usage": "The twelfth part of a troy pound."
                },
                {
                    "spelling_detail_id": 95029,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: A small portion; a bit."
                },
                {
                    "spelling_detail_id": 95030,
                    "word_type": "n.",
                    "spelling_usage": "A feline quadruped (Felis irbis, / uncia) resembling the\n   leopard in size, and somewhat in color, but it has longer and thicker\n   fur, which forms a short mane on the back. The ounce is pale yellowish\n   gray, with irregular dark spots on the neck and limbs, and dark rings\n   on the body. It inhabits the lofty mountain ranges of Asia. Called also\n   once."
                }
            ]
        },
        {
            "id": 61307,
            "spelling_name": "Outrageous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 95330,
                    "word_type": "n.",
                    "spelling_usage": "Of the nature of an outrage; exceeding the limits of\n   right, reason, or decency; involving or doing an outrage; furious;\n   violent; atrocious."
                }
            ]
        },
        {
            "id": 61846,
            "spelling_name": "Overrun",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 96047,
                    "word_type": "p. p.",
                    "spelling_usage": "of Overrun"
                },
                {
                    "spelling_detail_id": 96049,
                    "word_type": "v. t.",
                    "spelling_usage": "To run over; to grow or spread over in excess; to\n   invade and occupy; to take possession of; as, the vine overran its\n   trellis; the farm is overrun with witch grass."
                },
                {
                    "spelling_detail_id": 96050,
                    "word_type": "v. t.",
                    "spelling_usage": "To exceed in distance or speed of running; to go beyond\n   or pass in running."
                },
                {
                    "spelling_detail_id": 96051,
                    "word_type": "v. t.",
                    "spelling_usage": "To go beyond; to extend in part beyond; as, one line\n   overruns another in length."
                },
                {
                    "spelling_detail_id": 96052,
                    "word_type": "v. t.",
                    "spelling_usage": "To abuse or oppress, as if by treading upon."
                },
                {
                    "spelling_detail_id": 96053,
                    "word_type": "v. t.",
                    "spelling_usage": "To carry over, or back, as type, from one line or page\n   into the next after, or next before."
                },
                {
                    "spelling_detail_id": 96054,
                    "word_type": "v. t.",
                    "spelling_usage": "To extend the contents of (a line, column, or page)\n   into the next line, column, or page."
                },
                {
                    "spelling_detail_id": 96055,
                    "word_type": "v. i.",
                    "spelling_usage": "To run, pass, spread, or flow over or by something; to\n   be beyond, or in excess."
                },
                {
                    "spelling_detail_id": 96056,
                    "word_type": "v. i.",
                    "spelling_usage": "To extend beyond its due or desired length; as, a line,\n   or advertisement, overruns."
                }
            ]
        },
        {
            "id": 62713,
            "spelling_name": "Pamper",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 97332,
                    "word_type": "v. t.",
                    "spelling_usage": "To feed to the full; to feed luxuriously; to glut; as,\n   to pamper the body or the appetite."
                },
                {
                    "spelling_detail_id": 97333,
                    "word_type": "v. t.",
                    "spelling_usage": "To gratify inordinately; to indulge to excess; as, to\n   pamper pride; to pamper the imagination."
                }
            ]
        },
        {
            "id": 62845,
            "spelling_name": "Panorama",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 97529,
                    "word_type": "n.",
                    "spelling_usage": "A complete view in every direction."
                },
                {
                    "spelling_detail_id": 97530,
                    "word_type": "n.",
                    "spelling_usage": "A picture presenting a view of objects in every\n   direction, as from a central point."
                },
                {
                    "spelling_detail_id": 97531,
                    "word_type": "n.",
                    "spelling_usage": "A picture representing scenes too extended to be beheld\n   at once, and so exhibited a part at a time, by being unrolled, and made\n   to pass continuously before the spectator."
                }
            ]
        },
        {
            "id": 63212,
            "spelling_name": "Paraphrase",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 98032,
                    "word_type": "n.",
                    "spelling_usage": "A restatement of a text, passage, or work, expressing\n   the meaning of the original in another form, generally for the sake of\n   its clearer and fuller exposition; a setting forth the signification of\n   a text in other and ampler terms; a free translation or rendering; --\n   opposed to metaphrase."
                },
                {
                    "spelling_detail_id": 98035,
                    "word_type": "v. t.",
                    "spelling_usage": "To express, interpret, or translate with latitude;\n   to give the meaning of a passage in other language."
                },
                {
                    "spelling_detail_id": 98036,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a paraphrase."
                }
            ]
        },
        {
            "id": 63243,
            "spelling_name": "Parasite",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 98067,
                    "word_type": "n.",
                    "spelling_usage": "One who frequents the tables of the rich, or who lives at\n   another's expense, and earns his welcome by flattery; a hanger-on; a\n   toady; a sycophant."
                },
                {
                    "spelling_detail_id": 98068,
                    "word_type": "n.",
                    "spelling_usage": "A plant obtaining nourishment immediately from other\n   plants to which it attaches itself, and whose juices it absorbs; --\n   sometimes, but erroneously, called epiphyte."
                },
                {
                    "spelling_detail_id": 98069,
                    "word_type": "n.",
                    "spelling_usage": "A plant living on or within an animal, and supported at\n   its expense, as many species of fungi of the genus Torrubia."
                },
                {
                    "spelling_detail_id": 98070,
                    "word_type": "n.",
                    "spelling_usage": "An animal which lives during the whole or part of its\n   existence on or in the body of some other animal, feeding upon its\n   food, blood, or tissues, as lice, tapeworms, etc."
                },
                {
                    "spelling_detail_id": 98071,
                    "word_type": "n.",
                    "spelling_usage": "An animal which steals the food of another, as the\n   parasitic jager."
                },
                {
                    "spelling_detail_id": 98072,
                    "word_type": "n.",
                    "spelling_usage": "An animal which habitually uses the nest of another, as\n   the cowbird and the European cuckoo."
                }
            ]
        },
        {
            "id": 63333,
            "spelling_name": "Parenthetic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 98205,
                    "word_type": "a.",
                    "spelling_usage": "Alt. of Parenthetical"
                }
            ]
        },
        {
            "id": 63683,
            "spelling_name": "Pastel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 98858,
                    "word_type": "n.",
                    "spelling_usage": "A crayon made of a paste composed of a color ground with\n   gum water."
                },
                {
                    "spelling_detail_id": 98859,
                    "word_type": "n.",
                    "spelling_usage": "A plant affording a blue dye; the woad (Isatis tinctoria);\n   also, the dye itself."
                }
            ]
        },
        {
            "id": 63838,
            "spelling_name": "Patrol",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99120,
                    "word_type": "v. i.",
                    "spelling_usage": "To go the rounds along a chain of sentinels; to traverse\n   a police district or beat."
                },
                {
                    "spelling_detail_id": 99121,
                    "word_type": "v.",
                    "spelling_usage": "t To go the rounds of, as a sentry, guard, or policeman;\n   as, to patrol a frontier; to patrol a beat."
                },
                {
                    "spelling_detail_id": 99122,
                    "word_type": "v. i.",
                    "spelling_usage": "A going of the rounds along the chain of sentinels and\n   between the posts, by a guard, usually consisting of three or four men,\n   to insure greater security from attacks on the outposts."
                },
                {
                    "spelling_detail_id": 99123,
                    "word_type": "v. i.",
                    "spelling_usage": "A movement, by a small body of troops beyond the line of\n   outposts, to explore the country and gain intelligence of the enemy's\n   whereabouts."
                },
                {
                    "spelling_detail_id": 99124,
                    "word_type": "v. i.",
                    "spelling_usage": "The guard or men who go the rounds for observation; a\n   detachment whose duty it is to patrol."
                },
                {
                    "spelling_detail_id": 99125,
                    "word_type": "v. i.",
                    "spelling_usage": "Any perambulation of a particular line or district to\n   guard it; also, the men thus guarding; as, a customs patrol; a fire\n   patrol."
                }
            ]
        },
        {
            "id": 63870,
            "spelling_name": "Pattern",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99180,
                    "word_type": "n.",
                    "spelling_usage": "Anything proposed for imitation; an archetype; an\n   exemplar; that which is to be, or is worthy to be, copied or imitated;\n   as, a pattern of a machine."
                },
                {
                    "spelling_detail_id": 99181,
                    "word_type": "n.",
                    "spelling_usage": "A part showing the figure or quality of the whole; a\n   specimen; a sample; an example; an instance."
                },
                {
                    "spelling_detail_id": 99182,
                    "word_type": "n.",
                    "spelling_usage": "Stuff sufficient for a garment; as, a dress pattern."
                },
                {
                    "spelling_detail_id": 99183,
                    "word_type": "n.",
                    "spelling_usage": "Figure or style of decoration; design; as, wall paper of a\n   beautiful pattern."
                },
                {
                    "spelling_detail_id": 99184,
                    "word_type": "n.",
                    "spelling_usage": "Something made after a model; a copy."
                },
                {
                    "spelling_detail_id": 99185,
                    "word_type": "n.",
                    "spelling_usage": "Anything cut or formed to serve as a guide to cutting or\n   forming objects; as, a dressmaker's pattern."
                },
                {
                    "spelling_detail_id": 99186,
                    "word_type": "n.",
                    "spelling_usage": "A full-sized model around which a mold of sand is made, to\n   receive the melted metal. It is usually made of wood and in several\n   parts, so as to be removed from the mold without injuring it."
                },
                {
                    "spelling_detail_id": 99189,
                    "word_type": "v. t.",
                    "spelling_usage": "To make or design (anything) by, from, or after,\n   something that serves as a pattern; to copy; to model; to imitate."
                },
                {
                    "spelling_detail_id": 99190,
                    "word_type": "v. t.",
                    "spelling_usage": "To serve as an example for; also, to parallel."
                }
            ]
        },
        {
            "id": 64013,
            "spelling_name": "Peanut",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99427,
                    "word_type": "n.",
                    "spelling_usage": "The fruit of a trailing leguminous plant (Arachis\n   hypogaea); also, the plant itself, which is widely cultivated for its\n   fruit."
                }
            ]
        },
        {
            "id": 64014,
            "spelling_name": "Pear",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99428,
                    "word_type": "n.",
                    "spelling_usage": "The fleshy pome, or fruit, of a rosaceous tree (Pyrus\n   communis), cultivated in many varieties in temperate climates; also,\n   the tree which bears this fruit. See Pear family, below."
                }
            ]
        },
        {
            "id": 64041,
            "spelling_name": "Peat",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99474,
                    "word_type": "n.",
                    "spelling_usage": "A small person; a pet; -- sometimes used contemptuously."
                },
                {
                    "spelling_detail_id": 99475,
                    "word_type": "n.",
                    "spelling_usage": "A substance of vegetable origin, consisting of roots and\n   fibers, moss, etc., in various stages of decomposition, and found, as a\n   kind of turf or bog, usually in low situations, where it is always more\n   or less saturated with water. It is often dried and used for fuel."
                }
            ]
        },
        {
            "id": 64147,
            "spelling_name": "Peddle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99627,
                    "word_type": "v. i.",
                    "spelling_usage": "To travel about with wares for sale; to go from place to\n   place, or from house to house, for the purpose of retailing goods; as,\n   to peddle without a license."
                },
                {
                    "spelling_detail_id": 99628,
                    "word_type": "v. i.",
                    "spelling_usage": "To do a small business; to be busy about trifles; to\n   piddle."
                },
                {
                    "spelling_detail_id": 99631,
                    "word_type": "v. t.",
                    "spelling_usage": "To sell from place to place; to retail by carrying\n   around from customer to customer; to hawk; hence, to retail in very\n   small quantities; as, to peddle vegetables or tinware."
                }
            ]
        },
        {
            "id": 64189,
            "spelling_name": "Pedigree",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99682,
                    "word_type": "n.",
                    "spelling_usage": "A line of ancestors; descent; lineage; genealogy; a\n   register or record of a line of ancestors."
                },
                {
                    "spelling_detail_id": 99683,
                    "word_type": "n.",
                    "spelling_usage": "A record of the lineage or strain of an animal, as of a\n   horse."
                }
            ]
        },
        {
            "id": 64310,
            "spelling_name": "Pellet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99853,
                    "word_type": "n.",
                    "spelling_usage": "A little ball; as, a pellet of wax / paper."
                },
                {
                    "spelling_detail_id": 99854,
                    "word_type": "n.",
                    "spelling_usage": "A bullet; a ball for firearms."
                },
                {
                    "spelling_detail_id": 99855,
                    "word_type": "v./.",
                    "spelling_usage": "To form into small balls."
                }
            ]
        },
        {
            "id": 64385,
            "spelling_name": "Pending",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 99972,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Pend"
                },
                {
                    "spelling_detail_id": 99992,
                    "word_type": "a.",
                    "spelling_usage": "Not yet decided; in continuance; in suspense; as, a\n   pending suit."
                },
                {
                    "spelling_detail_id": 99993,
                    "word_type": "prep.",
                    "spelling_usage": "During; as, pending the trail."
                }
            ]
        },
        {
            "id": 64415,
            "spelling_name": "Penguin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 100034,
                    "word_type": "n.",
                    "spelling_usage": "Any bird of the order Impennes, or Ptilopteri. They are\n   covered with short, thick feathers, almost scalelike on the wings,\n   which are without true quills. They are unable to fly, but use their\n   wings to aid in diving, in which they are very expert. See King\n   penguin, under Jackass."
                },
                {
                    "spelling_detail_id": 100035,
                    "word_type": "n.",
                    "spelling_usage": "The egg-shaped fleshy fruit of a West Indian plant\n   (Bromelia Pinguin) of the Pineapple family; also, the plant itself,\n   which has rigid, pointed, and spiny-toothed leaves, and is used for\n   hedges."
                }
            ]
        },
        {
            "id": 65086,
            "spelling_name": "Permission",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 100913,
                    "word_type": "n.",
                    "spelling_usage": "The act of permitting or allowing; formal consent;\n   authorization; leave; license or liberty granted."
                }
            ]
        },
        {
            "id": 66371,
            "spelling_name": "Picture",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 102592,
                    "word_type": "n.",
                    "spelling_usage": "The art of painting; representation by painting."
                },
                {
                    "spelling_detail_id": 102593,
                    "word_type": "n.",
                    "spelling_usage": "A representation of anything (as a person, a landscape, a\n   building) upon canvas, paper, or other surface, produced by means of\n   painting, drawing, engraving, photography, etc.; a representation in\n   colors. By extension, a figure; a model."
                },
                {
                    "spelling_detail_id": 102594,
                    "word_type": "n.",
                    "spelling_usage": "An image or resemblance; a representation, either to the\n   eye or to the mind; that which, by its likeness, brings vividly to mind\n   some other thing; as, a child is the picture of his father; the man is\n   the picture of grief."
                },
                {
                    "spelling_detail_id": 102597,
                    "word_type": "v. t.",
                    "spelling_usage": "To draw or paint a resemblance of; to delineate; to\n   represent; to form or present an ideal likeness of; to bring before the\n   mind."
                }
            ]
        },
        {
            "id": 66442,
            "spelling_name": "Pigeon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 102715,
                    "word_type": "n.",
                    "spelling_usage": "Any bird of the order Columbae, of which numerous species\n   occur in nearly all parts of the world."
                },
                {
                    "spelling_detail_id": 102716,
                    "word_type": "n.",
                    "spelling_usage": "An unsuspected victim of sharpers; a gull."
                },
                {
                    "spelling_detail_id": 102717,
                    "word_type": "v. t.",
                    "spelling_usage": "To pluck; to fleece; to swindle by tricks in gambling."
                }
            ]
        },
        {
            "id": 66480,
            "spelling_name": "Pigsty",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 102763,
                    "word_type": "n.",
                    "spelling_usage": "A pigpen."
                }
            ]
        },
        {
            "id": 66523,
            "spelling_name": "Pilferer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 102843,
                    "word_type": "n.",
                    "spelling_usage": "One who pilfers; a petty thief."
                }
            ]
        },
        {
            "id": 66758,
            "spelling_name": "Piper",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 103255,
                    "word_type": "n.",
                    "spelling_usage": "See Pepper."
                },
                {
                    "spelling_detail_id": 103256,
                    "word_type": "n.",
                    "spelling_usage": "One who plays on a pipe, or the like, esp. on a bagpipe."
                },
                {
                    "spelling_detail_id": 103257,
                    "word_type": "n.",
                    "spelling_usage": "A common European gurnard (Trigla lyra), having a large\n   head, with prominent nasal projection, and with large, sharp, opercular\n   spines."
                },
                {
                    "spelling_detail_id": 103258,
                    "word_type": "n.",
                    "spelling_usage": "A sea urchin (Goniocidaris hystrix) having very long spines,\n   native of both the American and European coasts."
                }
            ]
        },
        {
            "id": 66803,
            "spelling_name": "Piratical",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 103325,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to a pirate; acquired by, or\n   practicing, piracy; as, a piratical undertaking."
                }
            ]
        },
        {
            "id": 67041,
            "spelling_name": "Plaintiff",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 103720,
                    "word_type": "n.",
                    "spelling_usage": "One who commences a personal action or suit to obtain a\n   remedy for an injury to his rights; -- opposed to defendant."
                },
                {
                    "spelling_detail_id": 103721,
                    "word_type": "a.",
                    "spelling_usage": "See Plaintive."
                }
            ]
        },
        {
            "id": 67309,
            "spelling_name": "Pleasant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 104194,
                    "word_type": "a.",
                    "spelling_usage": "Pleasing; grateful to the mind or to the senses;\n   agreeable; as, a pleasant journey; pleasant weather."
                },
                {
                    "spelling_detail_id": 104195,
                    "word_type": "a.",
                    "spelling_usage": "Cheerful; enlivening; gay; sprightly; humorous; sportive;\n   as, pleasant company; a pleasant fellow."
                },
                {
                    "spelling_detail_id": 104196,
                    "word_type": "n.",
                    "spelling_usage": "A wit; a humorist; a buffoon."
                }
            ]
        },
        {
            "id": 67752,
            "spelling_name": "Pocket",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 104861,
                    "word_type": "n.",
                    "spelling_usage": "A bag or pouch; especially; a small bag inserted in a\n   garment for carrying small articles, particularly money; hence,\n   figuratively, money; wealth."
                },
                {
                    "spelling_detail_id": 104862,
                    "word_type": "n.",
                    "spelling_usage": "One of several bags attached to a billiard table, into\n   which the balls are driven."
                },
                {
                    "spelling_detail_id": 104863,
                    "word_type": "n.",
                    "spelling_usage": "A large bag or sack used in packing various articles, as\n   ginger, hops, cowries, etc."
                },
                {
                    "spelling_detail_id": 104864,
                    "word_type": "n.",
                    "spelling_usage": "A hole or space covered by a movable piece of board, as in\n   a floor, boxing, partitions, or the like."
                },
                {
                    "spelling_detail_id": 104865,
                    "word_type": "n.",
                    "spelling_usage": "A cavity in a rock containing a nugget of gold, or other\n   mineral; a small body of ore contained in such a cavity."
                },
                {
                    "spelling_detail_id": 104866,
                    "word_type": "n.",
                    "spelling_usage": "A hole containing water."
                },
                {
                    "spelling_detail_id": 104867,
                    "word_type": "n.",
                    "spelling_usage": "A strip of canvas, sewn upon a sail so that a batten or a\n   light spar can placed in the interspace."
                },
                {
                    "spelling_detail_id": 104868,
                    "word_type": "n.",
                    "spelling_usage": "Same as Pouch."
                },
                {
                    "spelling_detail_id": 104871,
                    "word_type": "v. t.",
                    "spelling_usage": "To put, or conceal, in the pocket; as, to pocket the\n   change."
                },
                {
                    "spelling_detail_id": 104872,
                    "word_type": "v. t.",
                    "spelling_usage": "To take clandestinely or fraudulently."
                }
            ]
        },
        {
            "id": 67946,
            "spelling_name": "Polenta",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 105213,
                    "word_type": "n.",
                    "spelling_usage": "Pudding made of Indian meal; also, porridge made of\n   chestnut meal."
                }
            ]
        },
        {
            "id": 68359,
            "spelling_name": "Poncho",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 105803,
                    "word_type": "n.",
                    "spelling_usage": "A kind of cloak worn by the Spanish Americans, having the\n   form of a blanket, with a slit in the middle for the head to pass\n   through. A kind of poncho made of rubber or painted cloth is used by\n   the mounted troops in the United States service."
                },
                {
                    "spelling_detail_id": 105804,
                    "word_type": "n.",
                    "spelling_usage": "A trade name for camlets, or stout worsteds."
                }
            ]
        },
        {
            "id": 68552,
            "spelling_name": "Porridge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 106107,
                    "word_type": "n.",
                    "spelling_usage": "A food made by boiling some leguminous or farinaceous\n   substance, or the meal of it, in water or in milk, making of broth or\n   thin pudding; as, barley porridge, milk porridge, bean porridge, etc."
                }
            ]
        },
        {
            "id": 68799,
            "spelling_name": "Postpone",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 106511,
                    "word_type": "v. t.",
                    "spelling_usage": "To defer to a future or later time; to put off; also,\n   to cause to be deferred or put off; to delay; to adjourn; as, to\n   postpone the consideration of a bill to the following day, or\n   indefinitely."
                },
                {
                    "spelling_detail_id": 106512,
                    "word_type": "v. t.",
                    "spelling_usage": "To place after, behind, or below something, in respect\n   to precedence, preference, value, or importance."
                }
            ]
        },
        {
            "id": 68869,
            "spelling_name": "Potato",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 106613,
                    "word_type": "n.",
                    "spelling_usage": "A plant (Solanum tuberosum) of the Nightshade family, and\n   its esculent farinaceous tuber, of which there are numerous varieties\n   used for food. It is native of South America, but a form of the species\n   is found native as far north as New Mexico."
                },
                {
                    "spelling_detail_id": 106614,
                    "word_type": "n.",
                    "spelling_usage": "The sweet potato (see below)."
                }
            ]
        },
        {
            "id": 69110,
            "spelling_name": "Praise",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 107031,
                    "word_type": "v.",
                    "spelling_usage": "To commend; to applaud; to express approbation of; to laud;\n   -- applied to a person or his acts."
                },
                {
                    "spelling_detail_id": 107032,
                    "word_type": "v.",
                    "spelling_usage": "To extol in words or song; to magnify; to glorify on\n   account of perfections or excellent works; to do honor to; to display\n   the excellence of; -- applied especially to the Divine Being."
                },
                {
                    "spelling_detail_id": 107033,
                    "word_type": "v.",
                    "spelling_usage": "To value; to appraise."
                },
                {
                    "spelling_detail_id": 107034,
                    "word_type": "v.",
                    "spelling_usage": "Commendation for worth; approval expressed; honor rendered\n   because of excellence or worth; laudation; approbation."
                },
                {
                    "spelling_detail_id": 107035,
                    "word_type": "v.",
                    "spelling_usage": "Especially, the joyful tribute of gratitude or homage\n   rendered to the Divine Being; the act of glorifying or extolling the\n   Creator; worship, particularly worship by song, distinction from prayer\n   and other acts of worship; as, a service of praise."
                },
                {
                    "spelling_detail_id": 107036,
                    "word_type": "v.",
                    "spelling_usage": "The object, ground, or reason of praise."
                }
            ]
        },
        {
            "id": 69694,
            "spelling_name": "Prepare",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 107812,
                    "word_type": "v. t.",
                    "spelling_usage": "To fit, adapt, or qualify for a particular purpose or\n   condition; to make ready; to put into a state for use or application;\n   as, to prepare ground for seed; to prepare a lesson."
                },
                {
                    "spelling_detail_id": 107813,
                    "word_type": "v. t.",
                    "spelling_usage": "To procure as suitable or necessary; to get ready; to\n   provide; as, to prepare ammunition and provisions for troops; to\n   prepare ships for defence; to prepare an entertainment."
                },
                {
                    "spelling_detail_id": 107814,
                    "word_type": "v. i.",
                    "spelling_usage": "To make all things ready; to put things in order; as,\n   to prepare for a hostile invasion."
                },
                {
                    "spelling_detail_id": 107815,
                    "word_type": "v. i.",
                    "spelling_usage": "To make one's self ready; to get ready; to take the\n   necessary previous measures; as, to prepare for death."
                },
                {
                    "spelling_detail_id": 107816,
                    "word_type": "n.",
                    "spelling_usage": "Preparation."
                }
            ]
        },
        {
            "id": 69797,
            "spelling_name": "Presence",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 107960,
                    "word_type": "n.",
                    "spelling_usage": "The state of being present, or of being within sight or\n   call, or at hand; -- opposed to absence."
                },
                {
                    "spelling_detail_id": 107961,
                    "word_type": "n.",
                    "spelling_usage": "The place in which one is present; the part of space\n   within one's ken, call, influence, etc.; neighborhood without the\n   intervention of anything that forbids intercourse."
                },
                {
                    "spelling_detail_id": 107962,
                    "word_type": "n.",
                    "spelling_usage": "Specifically, neighborhood to the person of one of\n   superior of exalted rank; also, presence chamber."
                },
                {
                    "spelling_detail_id": 107963,
                    "word_type": "n.",
                    "spelling_usage": "The whole of the personal qualities of an individual;\n   person; personality; especially, the person of a superior, as a\n   sovereign."
                },
                {
                    "spelling_detail_id": 107964,
                    "word_type": "n.",
                    "spelling_usage": "An assembly, especially of person of rank or nobility;\n   noble company."
                },
                {
                    "spelling_detail_id": 107965,
                    "word_type": "n.",
                    "spelling_usage": "Port, mien; air; personal appearence."
                }
            ]
        },
        {
            "id": 70168,
            "spelling_name": "Princess",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 108629,
                    "word_type": "n.",
                    "spelling_usage": "A female prince; a woman having sovereign power, or the\n   rank of a prince."
                },
                {
                    "spelling_detail_id": 108630,
                    "word_type": "n.",
                    "spelling_usage": "The daughter of a sovereign; a female member of a royal\n   family."
                },
                {
                    "spelling_detail_id": 108631,
                    "word_type": "n.",
                    "spelling_usage": "The consort of a prince; as, the princess of Wales."
                }
            ]
        },
        {
            "id": 70229,
            "spelling_name": "Pristine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 108744,
                    "word_type": "a.",
                    "spelling_usage": "Belonging to the earliest period or state; original;\n   primitive; primeval; as, the pristine state of innocence; the pristine\n   manners of a people; pristine vigor."
                }
            ]
        },
        {
            "id": 70235,
            "spelling_name": "Privacy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 108751,
                    "word_type": "n.",
                    "spelling_usage": "The state of being in retirement from the company or\n   observation of others; seclusion."
                },
                {
                    "spelling_detail_id": 108752,
                    "word_type": "n.",
                    "spelling_usage": "A place of seclusion from company or observation; retreat;\n   solitude; retirement."
                },
                {
                    "spelling_detail_id": 108753,
                    "word_type": "n.",
                    "spelling_usage": "Concealment of what is said or done."
                },
                {
                    "spelling_detail_id": 108754,
                    "word_type": "n.",
                    "spelling_usage": "A private matter; a secret."
                },
                {
                    "spelling_detail_id": 108755,
                    "word_type": "n.",
                    "spelling_usage": "See Privity, 2."
                }
            ]
        },
        {
            "id": 70249,
            "spelling_name": "Privet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 108791,
                    "word_type": "n.",
                    "spelling_usage": "An ornamental European shrub (Ligustrum vulgare), much used\n   in hedges; -- called also prim."
                }
            ]
        },
        {
            "id": 70579,
            "spelling_name": "Project",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 109341,
                    "word_type": "n.",
                    "spelling_usage": "The place from which a thing projects, or starts forth."
                },
                {
                    "spelling_detail_id": 109342,
                    "word_type": "n.",
                    "spelling_usage": "That which is projected or designed; something intended or\n   devised; a scheme; a design; a plan."
                },
                {
                    "spelling_detail_id": 109343,
                    "word_type": "n.",
                    "spelling_usage": "An idle scheme; an impracticable design; as, a man given\n   to projects."
                },
                {
                    "spelling_detail_id": 109346,
                    "word_type": "v. t.",
                    "spelling_usage": "To throw or cast forward; to shoot forth."
                },
                {
                    "spelling_detail_id": 109347,
                    "word_type": "v. t.",
                    "spelling_usage": "To cast forward or revolve in the mind; to contrive; to\n   devise; to scheme; as, to project a plan."
                },
                {
                    "spelling_detail_id": 109348,
                    "word_type": "v. t.",
                    "spelling_usage": "To draw or exhibit, as the form of anything; to\n   delineate; as, to project a sphere, a map, an ellipse, and the like; --\n   sometimes with on, upon, into, etc.; as, to project a line or point\n   upon a plane. See Projection, 4."
                },
                {
                    "spelling_detail_id": 109349,
                    "word_type": "v. i.",
                    "spelling_usage": "To shoot forward; to extend beyond something else; to\n   be prominent; to jut; as, the cornice projects; branches project from\n   the tree."
                },
                {
                    "spelling_detail_id": 109350,
                    "word_type": "v. i.",
                    "spelling_usage": "To form a project; to scheme."
                }
            ]
        },
        {
            "id": 70718,
            "spelling_name": "Prone",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 109556,
                    "word_type": "a.",
                    "spelling_usage": "Bending forward; inclined; not erect."
                },
                {
                    "spelling_detail_id": 109557,
                    "word_type": "a.",
                    "spelling_usage": "Prostrate; flat; esp., lying with the face down; -- opposed\n   to supine."
                },
                {
                    "spelling_detail_id": 109558,
                    "word_type": "a.",
                    "spelling_usage": "Headlong; running downward or headlong."
                },
                {
                    "spelling_detail_id": 109559,
                    "word_type": "a.",
                    "spelling_usage": "Sloping, with reference to a line or surface; declivous;\n   inclined; not level."
                },
                {
                    "spelling_detail_id": 109560,
                    "word_type": "a.",
                    "spelling_usage": "Inclined; propense; disposed; -- applied to the mind or\n   affections, usually in an ill sense. Followed by to."
                }
            ]
        },
        {
            "id": 71582,
            "spelling_name": "Publish",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 110803,
                    "word_type": "v. t.",
                    "spelling_usage": "To make public; to make known to mankind, or to people\n   in general; to divulge, as a private transaction; to promulgate or\n   proclaim, as a law or an edict."
                },
                {
                    "spelling_detail_id": 110804,
                    "word_type": "v. t.",
                    "spelling_usage": "To make known by posting, or by reading in a church;\n   as, to publish banns of marriage."
                },
                {
                    "spelling_detail_id": 110805,
                    "word_type": "v. t.",
                    "spelling_usage": "To send forth, as a book, newspaper, musical piece, or\n   other printed work, either for sale or for general distribution; to\n   print, and issue from the press."
                },
                {
                    "spelling_detail_id": 110806,
                    "word_type": "v. t.",
                    "spelling_usage": "To utter, or put into circulation; as, to publish\n   counterfeit paper."
                }
            ]
        },
        {
            "id": 71701,
            "spelling_name": "Pulley",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 111014,
                    "word_type": "v. t.",
                    "spelling_usage": "A wheel with a broad rim, or grooved rim, for\n   transmitting power from, or imparting power to, the different parts of\n   machinery, or for changing the direction of motion, by means of a belt,\n   cord, rope, or chain."
                },
                {
                    "spelling_detail_id": 111015,
                    "word_type": "b. t.",
                    "spelling_usage": "To raise or lift by means of a pulley."
                }
            ]
        },
        {
            "id": 71992,
            "spelling_name": "Purple",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 111465,
                    "word_type": "n.",
                    "spelling_usage": "A color formed by, or resembling that formed by, a\n   combination of the primary colors red and blue."
                },
                {
                    "spelling_detail_id": 111466,
                    "word_type": "n.",
                    "spelling_usage": "Cloth dyed a purple color, or a garment of such color;\n   especially, a purple robe, worn as an emblem of rank or authority;\n   specifically, the purple rode or mantle worn by Roman emperors as the\n   emblem of imperial dignity; as, to put on the imperial purple."
                },
                {
                    "spelling_detail_id": 111467,
                    "word_type": "n.",
                    "spelling_usage": "Hence: Imperial sovereignty; royal rank, dignity, or favor;\n   loosely and colloquially, any exalted station; great wealth."
                },
                {
                    "spelling_detail_id": 111468,
                    "word_type": "n.",
                    "spelling_usage": "A cardinalate. See Cardinal."
                },
                {
                    "spelling_detail_id": 111469,
                    "word_type": "n.",
                    "spelling_usage": "Any species of large butterflies, usually marked with\n   purple or blue, of the genus Basilarchia (formerly Limenitis) as, the\n   banded purple (B. arthemis). See Illust. under Ursula."
                },
                {
                    "spelling_detail_id": 111470,
                    "word_type": "n.",
                    "spelling_usage": "Any shell of the genus Purpura."
                },
                {
                    "spelling_detail_id": 111471,
                    "word_type": "n.",
                    "spelling_usage": "See Purpura."
                },
                {
                    "spelling_detail_id": 111472,
                    "word_type": "n.",
                    "spelling_usage": "A disease of wheat. Same as Earcockle."
                },
                {
                    "spelling_detail_id": 111473,
                    "word_type": "a.",
                    "spelling_usage": "Exhibiting or possessing the color called purple, much\n   esteemed for its richness and beauty; of a deep red, or red and blue\n   color; as, a purple robe."
                },
                {
                    "spelling_detail_id": 111474,
                    "word_type": "a.",
                    "spelling_usage": "Imperial; regal; -- so called from the color having been an\n   emblem of imperial authority."
                },
                {
                    "spelling_detail_id": 111475,
                    "word_type": "a.",
                    "spelling_usage": "Blood-red; bloody."
                },
                {
                    "spelling_detail_id": 111478,
                    "word_type": "v. t.",
                    "spelling_usage": "To make purple; to dye of purple or deep red color; as,\n   hands purpled with blood."
                }
            ]
        },
        {
            "id": 72032,
            "spelling_name": "Purse",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 111523,
                    "word_type": "n.",
                    "spelling_usage": "A small bag or pouch, the opening of which is made to draw\n   together closely, used to carry money in; by extension, any receptacle\n   for money carried on the person; a wallet; a pocketbook; a\n   portemonnaie."
                },
                {
                    "spelling_detail_id": 111524,
                    "word_type": "n.",
                    "spelling_usage": "Hence, a treasury; finances; as, the public purse."
                },
                {
                    "spelling_detail_id": 111525,
                    "word_type": "n.",
                    "spelling_usage": "A sum of money offered as a prize, or collected as a\n   present; as, to win the purse; to make up a purse."
                },
                {
                    "spelling_detail_id": 111526,
                    "word_type": "n.",
                    "spelling_usage": "A specific sum of money"
                },
                {
                    "spelling_detail_id": 111527,
                    "word_type": "n.",
                    "spelling_usage": "In Turkey, the sum of 500 piasters."
                },
                {
                    "spelling_detail_id": 111528,
                    "word_type": "n.",
                    "spelling_usage": "In Persia, the sum of 50 tomans."
                },
                {
                    "spelling_detail_id": 111531,
                    "word_type": "v. t.",
                    "spelling_usage": "To put into a purse."
                },
                {
                    "spelling_detail_id": 111532,
                    "word_type": "v. t.",
                    "spelling_usage": "To draw up or contract into folds or wrinkles, like the\n   mouth of a purse; to pucker; to knit."
                },
                {
                    "spelling_detail_id": 111533,
                    "word_type": "v. i.",
                    "spelling_usage": "To steal purses; to rob."
                }
            ]
        },
        {
            "id": 72381,
            "spelling_name": "Quack",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 112006,
                    "word_type": "v. i.",
                    "spelling_usage": "To utter a sound like the cry of a duck."
                },
                {
                    "spelling_detail_id": 112007,
                    "word_type": "v. i.",
                    "spelling_usage": "To make vain and loud pretensions; to boast."
                },
                {
                    "spelling_detail_id": 112008,
                    "word_type": "v. i.",
                    "spelling_usage": "To act the part of a quack, or pretender."
                },
                {
                    "spelling_detail_id": 112009,
                    "word_type": "n.",
                    "spelling_usage": "The cry of the duck, or a sound in imitation of it; a\n   hoarse, quacking noise."
                },
                {
                    "spelling_detail_id": 112010,
                    "word_type": "n.",
                    "spelling_usage": "A boastful pretender to medical skill; an empiric; an\n   ignorant practitioner."
                },
                {
                    "spelling_detail_id": 112011,
                    "word_type": "n.",
                    "spelling_usage": "Hence, one who boastfully pretends to skill or knowledge of\n   any kind not possessed; a charlatan."
                },
                {
                    "spelling_detail_id": 112012,
                    "word_type": "a.",
                    "spelling_usage": "Pertaining to or characterized by, boasting and pretension;\n   used by quacks; pretending to cure diseases; as, a quack medicine; a\n   quack doctor."
                }
            ]
        },
        {
            "id": 72586,
            "spelling_name": "Quart",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 112332,
                    "word_type": "n.",
                    "spelling_usage": "The fourth part; a quarter; hence, a region of the earth."
                },
                {
                    "spelling_detail_id": 112333,
                    "word_type": "n.",
                    "spelling_usage": "A measure of capacity, both in dry and in liquid measure;\n   the fourth part of a gallon; the eighth part of a peck; two pints."
                },
                {
                    "spelling_detail_id": 112334,
                    "word_type": "n.",
                    "spelling_usage": "A vessel or measure containing a quart."
                },
                {
                    "spelling_detail_id": 112335,
                    "word_type": "n.",
                    "spelling_usage": "In cards, four successive cards of the same suit. Cf.\n   Tierce, 4."
                }
            ]
        },
        {
            "id": 72776,
            "spelling_name": "Quicken",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 112666,
                    "word_type": "a.",
                    "spelling_usage": "To make alive; to vivify; to revive or resuscitate, as\n   from death or an inanimate state; hence, to excite; to, stimulate; to\n   incite."
                },
                {
                    "spelling_detail_id": 112667,
                    "word_type": "a.",
                    "spelling_usage": "To make lively, active, or sprightly; to impart additional\n   energy to; to stimulate; to make quick or rapid; to hasten; to\n   accelerate; as, to quicken one's steps or thoughts; to quicken one's\n   departure or speed."
                },
                {
                    "spelling_detail_id": 112668,
                    "word_type": "a.",
                    "spelling_usage": "To shorten the radius of (a curve); to make (a curve)\n   sharper; as, to quicken the sheer, that is, to make its curve more\n   pronounced."
                },
                {
                    "spelling_detail_id": 112669,
                    "word_type": "v. i.",
                    "spelling_usage": "To come to life; to become alive; to become vivified or\n   enlivened; hence, to exhibit signs of life; to move, as the fetus in\n   the womb."
                },
                {
                    "spelling_detail_id": 112670,
                    "word_type": "v. i.",
                    "spelling_usage": "To move with rapidity or activity; to become\n   accelerated; as, his pulse quickened."
                }
            ]
        },
        {
            "id": 73057,
            "spelling_name": "Rabid",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 113089,
                    "word_type": "n.",
                    "spelling_usage": "Furious; raging; extremely violent."
                },
                {
                    "spelling_detail_id": 113090,
                    "word_type": "n.",
                    "spelling_usage": "Extreme, unreasonable, or fanatical in opinion; excessively\n   zealous; as, a rabid socialist."
                },
                {
                    "spelling_detail_id": 113091,
                    "word_type": "n.",
                    "spelling_usage": "Affected with the distemper called rabies; mad; as, a rabid\n   dog or fox."
                },
                {
                    "spelling_detail_id": 113092,
                    "word_type": "n.",
                    "spelling_usage": "Of or pertaining to rabies, or hydrophobia; as, rabid virus."
                }
            ]
        },
        {
            "id": 73269,
            "spelling_name": "Raisin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 113510,
                    "word_type": "n.",
                    "spelling_usage": "A grape, or a bunch of grapes."
                },
                {
                    "spelling_detail_id": 113511,
                    "word_type": "n.",
                    "spelling_usage": "A grape dried in the sun or by artificial heat."
                }
            ]
        },
        {
            "id": 73538,
            "spelling_name": "Raspberry",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114016,
                    "word_type": "n.",
                    "spelling_usage": "The thimble-shaped fruit of the Rubus Idaeus and other\n   similar brambles; as, the black, the red, and the white raspberry."
                },
                {
                    "spelling_detail_id": 114017,
                    "word_type": "n.",
                    "spelling_usage": "The shrub bearing this fruit."
                }
            ]
        },
        {
            "id": 73650,
            "spelling_name": "Raven",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114216,
                    "word_type": "n.",
                    "spelling_usage": "A large black passerine bird (Corvus corax), similar to the\n   crow, but larger. It is native of the northern parts of Europe, Asia,\n   and America, and is noted for its sagacity."
                },
                {
                    "spelling_detail_id": 114217,
                    "word_type": "a.",
                    "spelling_usage": "Of the color of the raven; jet black; as, raven curls; raven\n   darkness."
                },
                {
                    "spelling_detail_id": 114218,
                    "word_type": "n.",
                    "spelling_usage": "Rapine; rapacity."
                },
                {
                    "spelling_detail_id": 114219,
                    "word_type": "n.",
                    "spelling_usage": "Prey; plunder; food obtained by violence."
                },
                {
                    "spelling_detail_id": 114222,
                    "word_type": "v. t.",
                    "spelling_usage": "To obtain or seize by violence."
                },
                {
                    "spelling_detail_id": 114223,
                    "word_type": "v. t.",
                    "spelling_usage": "To devour with great eagerness."
                },
                {
                    "spelling_detail_id": 114224,
                    "word_type": "v. i.",
                    "spelling_usage": "To prey with rapacity; to be greedy; to show rapacity."
                }
            ]
        },
        {
            "id": 73676,
            "spelling_name": "Ray",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114277,
                    "word_type": "v. t.",
                    "spelling_usage": "To array."
                },
                {
                    "spelling_detail_id": 114278,
                    "word_type": "v. t.",
                    "spelling_usage": "To mark, stain, or soil; to streak; to defile."
                },
                {
                    "spelling_detail_id": 114279,
                    "word_type": "n.",
                    "spelling_usage": "Array; order; arrangement; dress."
                },
                {
                    "spelling_detail_id": 114280,
                    "word_type": "n.",
                    "spelling_usage": "One of a number of lines or parts diverging from a common\n   point or center, like the radii of a circle; as, a star of six rays."
                },
                {
                    "spelling_detail_id": 114281,
                    "word_type": "n.",
                    "spelling_usage": "A radiating part of a flower or plant; the marginal florets of\n   a compound flower, as an aster or a sunflower; one of the pedicels of\n   an umbel or other circular flower cluster; radius. See Radius."
                },
                {
                    "spelling_detail_id": 114282,
                    "word_type": "n.",
                    "spelling_usage": "One of the radiating spines, or cartilages, supporting the\n   fins of fishes."
                },
                {
                    "spelling_detail_id": 114283,
                    "word_type": "n.",
                    "spelling_usage": "One of the spheromeres of a radiate, especially one of the\n   arms of a starfish or an ophiuran."
                },
                {
                    "spelling_detail_id": 114284,
                    "word_type": "n.",
                    "spelling_usage": "A line of light or heat proceeding from a radiant or\n   reflecting point; a single element of light or heat propagated\n   continuously; as, a solar ray; a polarized ray."
                },
                {
                    "spelling_detail_id": 114285,
                    "word_type": "n.",
                    "spelling_usage": "One of the component elements of the total radiation from a\n   body; any definite or limited portion of the spectrum; as, the red ray;\n   the violet ray. See Illust. under Light."
                },
                {
                    "spelling_detail_id": 114286,
                    "word_type": "n.",
                    "spelling_usage": "Sight; perception; vision; -- from an old theory of vision,\n   that sight was something which proceeded from the eye to the object\n   seen."
                },
                {
                    "spelling_detail_id": 114287,
                    "word_type": "n.",
                    "spelling_usage": "One of a system of diverging lines passing through a point,\n   and regarded as extending indefinitely in both directions. See\n   Half-ray."
                },
                {
                    "spelling_detail_id": 114290,
                    "word_type": "n.",
                    "spelling_usage": "To mark with long lines; to streak."
                },
                {
                    "spelling_detail_id": 114291,
                    "word_type": "n.",
                    "spelling_usage": "To send forth or shoot out; to cause to shine out; as, to ray\n   smiles."
                },
                {
                    "spelling_detail_id": 114292,
                    "word_type": "v. i.",
                    "spelling_usage": "To shine, as with rays."
                },
                {
                    "spelling_detail_id": 114293,
                    "word_type": "n.",
                    "spelling_usage": "Any one of numerous elasmobranch fishes of the order Raiae,\n   including the skates, torpedoes, sawfishes, etc."
                },
                {
                    "spelling_detail_id": 114294,
                    "word_type": "n.",
                    "spelling_usage": "In a restricted sense, any of the broad, flat, narrow-tailed\n   species, as the skates and sting rays. See Skate."
                }
            ]
        },
        {
            "id": 73737,
            "spelling_name": "Reactionary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114363,
                    "word_type": "a.",
                    "spelling_usage": "Being, causing, or favoring reaction; as, reactionary\n   movements."
                },
                {
                    "spelling_detail_id": 114365,
                    "word_type": "n.",
                    "spelling_usage": "One who favors reaction, or seeks to undo political\n   progress or revolution."
                }
            ]
        },
        {
            "id": 73980,
            "spelling_name": "Receptacle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114796,
                    "word_type": "n.",
                    "spelling_usage": "That which serves, or is used, for receiving and\n   containing something, as a basket, a vase, a bag, a reservoir; a\n   repository."
                },
                {
                    "spelling_detail_id": 114797,
                    "word_type": "n.",
                    "spelling_usage": "The apex of the flower stalk, from which the organs of\n   the flower grow, or into which they are inserted. See Illust. of\n   Flower, and Ovary."
                },
                {
                    "spelling_detail_id": 114798,
                    "word_type": "n.",
                    "spelling_usage": "The dilated apex of a pedicel which serves as a common\n   support to a head of flowers."
                },
                {
                    "spelling_detail_id": 114799,
                    "word_type": "n.",
                    "spelling_usage": "An intercellular cavity containing oil or resin or\n   other matters."
                },
                {
                    "spelling_detail_id": 114800,
                    "word_type": "n.",
                    "spelling_usage": "A special branch which bears the fructification in many\n   cryptogamous plants."
                }
            ]
        },
        {
            "id": 74015,
            "spelling_name": "Recipient",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114856,
                    "word_type": "n.",
                    "spelling_usage": "A receiver; the person or thing that receives; one to\n   whom, or that to which, anything is given or communicated;\n   specifically, the receiver of a still."
                },
                {
                    "spelling_detail_id": 114857,
                    "word_type": "a.",
                    "spelling_usage": "Receiving; receptive."
                }
            ]
        },
        {
            "id": 74043,
            "spelling_name": "Reckon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114914,
                    "word_type": "v. t.",
                    "spelling_usage": "To count; to enumerate; to number; also, to compute; to\n   calculate."
                },
                {
                    "spelling_detail_id": 114915,
                    "word_type": "v. t.",
                    "spelling_usage": "To count as in a number, rank, or series; to estimate by\n   rank or quality; to place by estimation; to account; to esteem; to\n   repute."
                },
                {
                    "spelling_detail_id": 114916,
                    "word_type": "v. t.",
                    "spelling_usage": "To charge, attribute, or adjudge to one, as having a\n   certain quality or value."
                },
                {
                    "spelling_detail_id": 114917,
                    "word_type": "v. t.",
                    "spelling_usage": "To conclude, as by an enumeration and balancing of\n   chances; hence, to think; to suppose; -- followed by an objective\n   clause; as, I reckon he won't try that again."
                },
                {
                    "spelling_detail_id": 114918,
                    "word_type": "v. i.",
                    "spelling_usage": "To make an enumeration or computation; to engage in\n   numbering or computing."
                },
                {
                    "spelling_detail_id": 114919,
                    "word_type": "v. i.",
                    "spelling_usage": "To come to an accounting; to make up accounts; to\n   settle; to examine and strike the balance of debt and credit; to adjust\n   relations of desert or penalty."
                }
            ]
        },
        {
            "id": 74066,
            "spelling_name": "Recluse",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 114967,
                    "word_type": "a.",
                    "spelling_usage": "Shut up; sequestered; retired from the world or from\n   public notice; solitary; living apart; as, a recluse monk or hermit; a\n   recluse life."
                },
                {
                    "spelling_detail_id": 114968,
                    "word_type": "a.",
                    "spelling_usage": "A person who lives in seclusion from intercourse with the\n   world, as a hermit or monk; specifically, one of a class of secluded\n   devotees who live in single cells, usually attached to monasteries."
                },
                {
                    "spelling_detail_id": 114969,
                    "word_type": "a.",
                    "spelling_usage": "The place where a recluse dwells."
                },
                {
                    "spelling_detail_id": 114970,
                    "word_type": "v. t.",
                    "spelling_usage": "To shut up; to seclude."
                }
            ]
        },
        {
            "id": 74192,
            "spelling_name": "Recoup",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 115162,
                    "word_type": "v. t.",
                    "spelling_usage": "Alt. of Recoupe"
                }
            ]
        },
        {
            "id": 74601,
            "spelling_name": "Reflect",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 115820,
                    "word_type": "v.",
                    "spelling_usage": "To bend back; to give a backwa/d turn to; to throw back;\n   especially, to cause to return after striking upon any surface; as, a\n   mirror reflects rays of light; polished metals reflect heat."
                },
                {
                    "spelling_detail_id": 115821,
                    "word_type": "v.",
                    "spelling_usage": "To give back an image or likeness of; to mirror."
                },
                {
                    "spelling_detail_id": 115822,
                    "word_type": "v. i.",
                    "spelling_usage": "To throw back light, heat, or the like; to return rays\n   or beams."
                },
                {
                    "spelling_detail_id": 115823,
                    "word_type": "v. i.",
                    "spelling_usage": "To be sent back; to rebound as from a surface; to\n   revert; to return."
                },
                {
                    "spelling_detail_id": 115824,
                    "word_type": "v. i.",
                    "spelling_usage": "To throw or turn back the thoughts upon anything; to\n   contemplate. Specifically: To attend earnestly to what passes within\n   the mind; to attend to the facts or phenomena of consciousness; to use\n   attention or earnest thought; to meditate; especially, to think in\n   relation to moral truth or rules."
                },
                {
                    "spelling_detail_id": 115825,
                    "word_type": "v. i.",
                    "spelling_usage": "To cast reproach; to cause censure or dishonor."
                }
            ]
        },
        {
            "id": 74885,
            "spelling_name": "Reign",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 116318,
                    "word_type": "n.",
                    "spelling_usage": "Royal authority; supreme power; sovereignty; rule; dominion."
                },
                {
                    "spelling_detail_id": 116319,
                    "word_type": "n.",
                    "spelling_usage": "The territory or sphere which is reigned over; kingdom;\n   empire; realm; dominion."
                },
                {
                    "spelling_detail_id": 116320,
                    "word_type": "n.",
                    "spelling_usage": "The time during which a king, queen, or emperor possesses\n   the supreme authority; as, it happened in the reign of Elizabeth."
                },
                {
                    "spelling_detail_id": 116323,
                    "word_type": "n.",
                    "spelling_usage": "To possess or exercise sovereign power or authority; to\n   exercise government, as a king or emperor;; to hold supreme power; to\n   rule."
                },
                {
                    "spelling_detail_id": 116324,
                    "word_type": "n.",
                    "spelling_usage": "Hence, to be predominant; to prevail."
                },
                {
                    "spelling_detail_id": 116325,
                    "word_type": "n.",
                    "spelling_usage": "To have superior or uncontrolled dominion; to rule."
                }
            ]
        },
        {
            "id": 75141,
            "spelling_name": "Rely",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 116714,
                    "word_type": "v. i.",
                    "spelling_usage": "To rest with confidence, as when fully satisfied of the\n   veracity, integrity, or ability of persons, or of the certainty of\n   facts or of evidence; to have confidence; to trust; to depend; -- with\n   on, formerly also with in."
                }
            ]
        },
        {
            "id": 75178,
            "spelling_name": "Remedial",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 116767,
                    "word_type": "a.",
                    "spelling_usage": "Affording a remedy; intended for a remedy, or for the\n   removal or abatement of an evil; as, remedial treatment."
                }
            ]
        },
        {
            "id": 75187,
            "spelling_name": "Remember",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 116782,
                    "word_type": "v. t.",
                    "spelling_usage": "To have ( a notion or idea) come into the mind again,\n   as previously perceived, known, or felt; to have a renewed apprehension\n   of; to bring to mind again; to think of again; to recollect; as, I\n   remember the fact; he remembers the events of his childhood; I cannot\n   remember dates."
                },
                {
                    "spelling_detail_id": 116783,
                    "word_type": "v. t.",
                    "spelling_usage": "To be capable of recalling when required; to keep in\n   mind; to be continually aware or thoughtful of; to preserve fresh in\n   the memory; to attend to; to think of with gratitude, affection,\n   respect, or any other emotion."
                },
                {
                    "spelling_detail_id": 116784,
                    "word_type": "v. t.",
                    "spelling_usage": "To put in mind; to remind; -- also used reflexively\n   and impersonally."
                },
                {
                    "spelling_detail_id": 116785,
                    "word_type": "v. t.",
                    "spelling_usage": "To mention."
                },
                {
                    "spelling_detail_id": 116786,
                    "word_type": "v. t.",
                    "spelling_usage": "To recall to the mind of another, as in the friendly\n   messages, remember me to him, he wishes to be remembered to you, etc."
                },
                {
                    "spelling_detail_id": 116787,
                    "word_type": "v. i.",
                    "spelling_usage": "To execise or have the power of memory; as, some\n   remember better than others."
                }
            ]
        },
        {
            "id": 75582,
            "spelling_name": "Repress",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 117477,
                    "word_type": "v. t.",
                    "spelling_usage": "To press again."
                },
                {
                    "spelling_detail_id": 117478,
                    "word_type": "v. t.",
                    "spelling_usage": "To press back or down effectually; to crush down or\n   out; to quell; to subdue; to supress; as, to repress sedition or\n   rebellion; to repress the first risings of discontent."
                },
                {
                    "spelling_detail_id": 117479,
                    "word_type": "v. t.",
                    "spelling_usage": "Hence, to check; to restrain; to keep back."
                },
                {
                    "spelling_detail_id": 117480,
                    "word_type": "n.",
                    "spelling_usage": "The act of repressing."
                }
            ]
        },
        {
            "id": 75749,
            "spelling_name": "Rescue",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 117749,
                    "word_type": "v. t.",
                    "spelling_usage": "To free or deliver from any confinement, violence,\n   danger, or evil; to liberate from actual restraint; to remove or\n   withdraw from a state of exposure to evil; as, to rescue a prisoner\n   from the enemy; to rescue seamen from destruction."
                },
                {
                    "spelling_detail_id": 117750,
                    "word_type": "v.",
                    "spelling_usage": "The act of rescuing; deliverance from restraint, violence,\n   or danger; liberation."
                },
                {
                    "spelling_detail_id": 117751,
                    "word_type": "v.",
                    "spelling_usage": "The forcible retaking, or taking away, against law, of\n   things lawfully distrained."
                },
                {
                    "spelling_detail_id": 117752,
                    "word_type": "v.",
                    "spelling_usage": "The forcible liberation of a person from an arrest or\n   imprisonment."
                },
                {
                    "spelling_detail_id": 117753,
                    "word_type": "v.",
                    "spelling_usage": "The retaking by a party captured of a prize made by the\n   enemy."
                }
            ]
        },
        {
            "id": 75913,
            "spelling_name": "Respect",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 118059,
                    "word_type": "v. t.",
                    "spelling_usage": "To take notice of; to regard with special attention; to\n   regard as worthy of special consideration; hence, to care for; to heed."
                },
                {
                    "spelling_detail_id": 118060,
                    "word_type": "v. t.",
                    "spelling_usage": "To consider worthy of esteem; to regard with honor."
                },
                {
                    "spelling_detail_id": 118061,
                    "word_type": "v. t.",
                    "spelling_usage": "To look toward; to front upon or toward."
                },
                {
                    "spelling_detail_id": 118062,
                    "word_type": "v. t.",
                    "spelling_usage": "To regard; to consider; to deem."
                },
                {
                    "spelling_detail_id": 118063,
                    "word_type": "v. t.",
                    "spelling_usage": "To have regard to; to have reference to; to relate to;\n   as, the treaty particularly respects our commerce."
                },
                {
                    "spelling_detail_id": 118064,
                    "word_type": "v.",
                    "spelling_usage": "The act of noticing with attention; the giving particular\n   consideration to; hence, care; caution."
                },
                {
                    "spelling_detail_id": 118065,
                    "word_type": "v.",
                    "spelling_usage": "Esteem; regard; consideration; honor."
                },
                {
                    "spelling_detail_id": 118066,
                    "word_type": "v.",
                    "spelling_usage": "An expression of respect of deference; regards; as, to\n   send one's respects to another."
                },
                {
                    "spelling_detail_id": 118067,
                    "word_type": "v.",
                    "spelling_usage": "Reputation; repute."
                },
                {
                    "spelling_detail_id": 118068,
                    "word_type": "v.",
                    "spelling_usage": "Relation; reference; regard."
                },
                {
                    "spelling_detail_id": 118069,
                    "word_type": "v.",
                    "spelling_usage": "Particular; point regarded; point of view; as, in this\n   respect; in any respect; in all respects."
                },
                {
                    "spelling_detail_id": 118070,
                    "word_type": "v.",
                    "spelling_usage": "Consideration; motive; interest."
                }
            ]
        },
        {
            "id": 75972,
            "spelling_name": "Restaurant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 118203,
                    "word_type": "n.",
                    "spelling_usage": "An eating house."
                }
            ]
        },
        {
            "id": 75990,
            "spelling_name": "Restive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 118228,
                    "word_type": "a.",
                    "spelling_usage": "Unwilling to go on; obstinate in refusing to move forward;\n   stubborn; drawing back."
                },
                {
                    "spelling_detail_id": 118229,
                    "word_type": "a.",
                    "spelling_usage": "Inactive; sluggish."
                },
                {
                    "spelling_detail_id": 118230,
                    "word_type": "a.",
                    "spelling_usage": "Impatient under coercion, chastisement, or opposition;\n   refractory."
                },
                {
                    "spelling_detail_id": 118231,
                    "word_type": "a.",
                    "spelling_usage": "Uneasy; restless; averse to standing still; fidgeting\n   about; -- applied especially to horses."
                }
            ]
        },
        {
            "id": 76761,
            "spelling_name": "Rich",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 119431,
                    "word_type": "superl.",
                    "spelling_usage": "Having an abundance of material possessions; possessed\n   of a large amount of property; well supplied with land, goods, or\n   money; wealthy; opulent; affluent; -- opposed to poor."
                },
                {
                    "spelling_detail_id": 119432,
                    "word_type": "superl.",
                    "spelling_usage": "Hence, in general, well supplied; abounding; abundant;\n   copious; bountiful; as, a rich treasury; a rich entertainment; a rich\n   crop."
                },
                {
                    "spelling_detail_id": 119433,
                    "word_type": "superl.",
                    "spelling_usage": "Yielding large returns; productive or fertile; fruitful;\n   as, rich soil or land; a rich mine."
                },
                {
                    "spelling_detail_id": 119434,
                    "word_type": "superl.",
                    "spelling_usage": "Composed of valuable or costly materials or ingredients;\n   procured at great outlay; highly valued; precious; sumptuous; costly;\n   as, a rich dress; rich silk or fur; rich presents."
                },
                {
                    "spelling_detail_id": 119435,
                    "word_type": "superl.",
                    "spelling_usage": "Abounding in agreeable or nutritive qualities; --\n   especially applied to articles of food or drink which are high-seasoned\n   or abound in oleaginous ingredients, or are sweet, luscious, and\n   high-flavored; as, a rich dish; rich cream or soup; rich pastry; rich\n   wine or fruit."
                },
                {
                    "spelling_detail_id": 119436,
                    "word_type": "superl.",
                    "spelling_usage": "Not faint or delicate; vivid; as, a rich color."
                },
                {
                    "spelling_detail_id": 119437,
                    "word_type": "superl.",
                    "spelling_usage": "Full of sweet and harmonius sounds; as, a rich voice;\n   rich music."
                },
                {
                    "spelling_detail_id": 119438,
                    "word_type": "superl.",
                    "spelling_usage": "Abounding in beauty; gorgeous; as, a rich landscape;\n   rich scenery."
                },
                {
                    "spelling_detail_id": 119439,
                    "word_type": "superl.",
                    "spelling_usage": "Abounding in humor; exciting amusement; entertaining;\n   as, the scene was a rich one; a rich incident or character."
                },
                {
                    "spelling_detail_id": 119440,
                    "word_type": "v. t.",
                    "spelling_usage": "To enrich."
                }
            ]
        },
        {
            "id": 76837,
            "spelling_name": "Riffraff",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 119582,
                    "word_type": "n.",
                    "spelling_usage": "Sweepings; refuse; the lowest order of society."
                }
            ]
        },
        {
            "id": 77119,
            "spelling_name": "Robin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 120191,
                    "word_type": "n.",
                    "spelling_usage": "A small European singing bird (Erythacus rubecula), having a\n   reddish breast; -- called also robin redbreast, robinet, and ruddock."
                },
                {
                    "spelling_detail_id": 120192,
                    "word_type": "n.",
                    "spelling_usage": "An American singing bird (Merula migratoria), having the\n   breast chestnut, or dull red. The upper parts are olive-gray, the head\n   and tail blackish. Called also robin redbreast, and migratory thrush."
                },
                {
                    "spelling_detail_id": 120193,
                    "word_type": "n.",
                    "spelling_usage": "Any one of several species of Australian warblers of the\n   genera Petroica, Melanadrays, and allied genera; as, the\n   scarlet-breasted robin (Petroica mullticolor)."
                },
                {
                    "spelling_detail_id": 120194,
                    "word_type": "n.",
                    "spelling_usage": "Any one of several Asiatic birds; as, the Indian robins. See\n   Indian robin, below."
                }
            ]
        },
        {
            "id": 77153,
            "spelling_name": "Rocket",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 120251,
                    "word_type": "n.",
                    "spelling_usage": "A cruciferous plant (Eruca sativa) sometimes eaten in\n   Europe as a salad."
                },
                {
                    "spelling_detail_id": 120252,
                    "word_type": "n.",
                    "spelling_usage": "Damewort."
                },
                {
                    "spelling_detail_id": 120253,
                    "word_type": "n.",
                    "spelling_usage": "Rocket larkspur. See below."
                },
                {
                    "spelling_detail_id": 120254,
                    "word_type": "n.",
                    "spelling_usage": "An artificial firework consisting of a cylindrical case of\n   paper or metal filled with a composition of combustible ingredients, as\n   niter, charcoal, and sulphur, and fastened to a guiding stick. The\n   rocket is projected through the air by the force arising from the\n   expansion of the gases liberated by combustion of the composition.\n   Rockets are used as projectiles for various purposes, for signals, and\n   also for pyrotechnic display."
                },
                {
                    "spelling_detail_id": 120255,
                    "word_type": "n.",
                    "spelling_usage": "A blunt lance head used in the joust."
                },
                {
                    "spelling_detail_id": 120258,
                    "word_type": "v. i.",
                    "spelling_usage": "To rise straight up; said of birds; usually in the\n   present participle or as an adjective."
                }
            ]
        },
        {
            "id": 77229,
            "spelling_name": "Rollicking",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 120420,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Rollic"
                }
            ]
        },
        {
            "id": 77341,
            "spelling_name": "Rooster",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 120604,
                    "word_type": "n.",
                    "spelling_usage": "The male of the domestic fowl; a cock."
                }
            ]
        },
        {
            "id": 77597,
            "spelling_name": "Routine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 121109,
                    "word_type": "n.",
                    "spelling_usage": "A round of business, amusement, or pleasure, daily or\n   frequently pursued; especially, a course of business or offical duties\n   regularly or frequently returning."
                },
                {
                    "spelling_detail_id": 121110,
                    "word_type": "n.",
                    "spelling_usage": "Any regular course of action or procedure rigidly adhered\n   to by the mere force of habit."
                }
            ]
        },
        {
            "id": 78595,
            "spelling_name": "Sandwich",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 122870,
                    "word_type": "n.",
                    "spelling_usage": "Two pieces of bread and butter with a thin slice of meat,\n   cheese, or the like, between them."
                },
                {
                    "spelling_detail_id": 122873,
                    "word_type": "v. t.",
                    "spelling_usage": "To make into a sandwich; also, figuratively, to insert\n   between portions of something dissimilar; to form of alternate parts or\n   things, or alternating layers of a different nature; to interlard."
                }
            ]
        },
        {
            "id": 78878,
            "spelling_name": "Satellite",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 123216,
                    "word_type": "n.",
                    "spelling_usage": "An attendant attached to a prince or other powerful\n   person; hence, an obsequious dependent."
                },
                {
                    "spelling_detail_id": 123217,
                    "word_type": "n.",
                    "spelling_usage": "A secondary planet which revolves about another planet;\n   as, the moon is a satellite of the earth. See Solar system, under\n   Solar."
                },
                {
                    "spelling_detail_id": 123218,
                    "word_type": "a.",
                    "spelling_usage": "Situated near; accompanying; as, the satellite veins,\n   those which accompany the arteries."
                }
            ]
        },
        {
            "id": 78901,
            "spelling_name": "Satisfactory",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 123248,
                    "word_type": "a.",
                    "spelling_usage": "Giving or producing satisfaction; yielding content;\n   especially, relieving the mind from doubt or uncertainty, and enabling\n   it to rest with confidence; sufficient; as, a satisfactory account or\n   explanation."
                },
                {
                    "spelling_detail_id": 123249,
                    "word_type": "a.",
                    "spelling_usage": "Making amends, indemnification, or recompense;\n   causing to cease from claims and to rest content; compensating;\n   atoning; as, to make satisfactory compensation, or a satisfactory\n   apology."
                }
            ]
        },
        {
            "id": 79147,
            "spelling_name": "Scalp",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 123693,
                    "word_type": "n.",
                    "spelling_usage": "A bed of oysters or mussels."
                },
                {
                    "spelling_detail_id": 123694,
                    "word_type": "n.",
                    "spelling_usage": "That part of the integument of the head which is usually\n   covered with hair."
                },
                {
                    "spelling_detail_id": 123695,
                    "word_type": "n.",
                    "spelling_usage": "A part of the skin of the head, with the hair attached, cut\n   or torn off from an enemy by the Indian warriors of North America, as a\n   token of victory."
                },
                {
                    "spelling_detail_id": 123696,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: The top; the summit."
                },
                {
                    "spelling_detail_id": 123699,
                    "word_type": "v. t.",
                    "spelling_usage": "To deprive of the scalp; to cut or tear the scalp from\n   the head of."
                },
                {
                    "spelling_detail_id": 123700,
                    "word_type": "v. t.",
                    "spelling_usage": "To remove the skin of."
                },
                {
                    "spelling_detail_id": 123701,
                    "word_type": "v. t.",
                    "spelling_usage": "To brush the hairs or fuzz from, as wheat grains, in the\n   process of high milling."
                },
                {
                    "spelling_detail_id": 123702,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a small, quick profit by slight fluctuations of\n   the market; -- said of brokers who operate in this way on their own\n   account."
                }
            ]
        },
        {
            "id": 79328,
            "spelling_name": "Scent",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124000,
                    "word_type": "v. t.",
                    "spelling_usage": "To perceive by the olfactory organs; to smell; as, to\n   scent game, as a hound does."
                },
                {
                    "spelling_detail_id": 124001,
                    "word_type": "v. t.",
                    "spelling_usage": "To imbue or fill with odor; to perfume."
                },
                {
                    "spelling_detail_id": 124002,
                    "word_type": "v. i.",
                    "spelling_usage": "To have a smell."
                },
                {
                    "spelling_detail_id": 124003,
                    "word_type": "v. i.",
                    "spelling_usage": "To hunt animals by means of the sense of smell."
                },
                {
                    "spelling_detail_id": 124004,
                    "word_type": "n.",
                    "spelling_usage": "That which, issuing from a body, affects the olfactory\n   organs of animals; odor; smell; as, the scent of an orange, or of a\n   rose; the scent of musk."
                },
                {
                    "spelling_detail_id": 124005,
                    "word_type": "n.",
                    "spelling_usage": "Specifically, the odor left by an animal on the ground in\n   passing over it; as, dogs find or lose the scent; hence, course of\n   pursuit; track of discovery."
                },
                {
                    "spelling_detail_id": 124006,
                    "word_type": "n.",
                    "spelling_usage": "The power of smelling; the sense of smell; as, a hound of\n   nice scent; to divert the scent."
                }
            ]
        },
        {
            "id": 79366,
            "spelling_name": "Scheme",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124048,
                    "word_type": "n.",
                    "spelling_usage": "A combination of things connected and adjusted by design; a\n   system."
                },
                {
                    "spelling_detail_id": 124049,
                    "word_type": "n.",
                    "spelling_usage": "A plan or theory something to be done; a design; a project;\n   as, to form a scheme."
                },
                {
                    "spelling_detail_id": 124050,
                    "word_type": "n.",
                    "spelling_usage": "Any lineal or mathematical diagram; an outline."
                },
                {
                    "spelling_detail_id": 124051,
                    "word_type": "n.",
                    "spelling_usage": "A representation of the aspects of the celestial bodies for\n   any moment or at a given event."
                },
                {
                    "spelling_detail_id": 124054,
                    "word_type": "v. t.",
                    "spelling_usage": "To make a scheme of; to plan; to design; to project; to\n   plot."
                },
                {
                    "spelling_detail_id": 124055,
                    "word_type": "v. i.",
                    "spelling_usage": "To form a scheme or schemes."
                }
            ]
        },
        {
            "id": 79488,
            "spelling_name": "Science",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124214,
                    "word_type": "n.",
                    "spelling_usage": "Knowledge; knowledge of principles and causes; ascertained\n   truth of facts."
                },
                {
                    "spelling_detail_id": 124215,
                    "word_type": "n.",
                    "spelling_usage": "Accumulated and established knowledge, which has been\n   systematized and formulated with reference to the discovery of general\n   truths or the operation of general laws; knowledge classified and made\n   available in work, life, or the search for truth; comprehensive,\n   profound, or philosophical knowledge."
                },
                {
                    "spelling_detail_id": 124216,
                    "word_type": "n.",
                    "spelling_usage": "Especially, such knowledge when it relates to the physical\n   world and its phenomena, the nature, constitution, and forces of\n   matter, the qualities and functions of living tissues, etc.; -- called\n   also natural science, and physical science."
                },
                {
                    "spelling_detail_id": 124217,
                    "word_type": "n.",
                    "spelling_usage": "Any branch or department of systematized knowledge\n   considered as a distinct field of investigation or object of study; as,\n   the science of astronomy, of chemistry, or of mind."
                },
                {
                    "spelling_detail_id": 124218,
                    "word_type": "n.",
                    "spelling_usage": "Art, skill, or expertness, regarded as the result of\n   knowledge of laws and principles."
                },
                {
                    "spelling_detail_id": 124219,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause to become versed in science; to make skilled;\n   to instruct."
                }
            ]
        },
        {
            "id": 79607,
            "spelling_name": "Scold",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124369,
                    "word_type": "v. i.",
                    "spelling_usage": "To find fault or rail with rude clamor; to brawl; to\n   utter harsh, rude, boisterous rebuke; to chide sharply or coarsely; --\n   often with at; as, to scold at a servant."
                },
                {
                    "spelling_detail_id": 124370,
                    "word_type": "v. t.",
                    "spelling_usage": "To chide with rudeness and clamor; to rate; also, to\n   rebuke or reprove with severity."
                },
                {
                    "spelling_detail_id": 124371,
                    "word_type": "n.",
                    "spelling_usage": "One who scolds, or makes a practice of scolding; esp., a\n   rude, clamorous woman; a shrew."
                },
                {
                    "spelling_detail_id": 124372,
                    "word_type": "n.",
                    "spelling_usage": "A scolding; a brawl."
                }
            ]
        },
        {
            "id": 79666,
            "spelling_name": "Scorch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124464,
                    "word_type": "v. t.",
                    "spelling_usage": "To burn superficially; to parch, or shrivel, the surface\n   of, by heat; to subject to so much heat as changes color and texture\n   without consuming; as, to scorch linen."
                },
                {
                    "spelling_detail_id": 124465,
                    "word_type": "v. t.",
                    "spelling_usage": "To affect painfully with heat, or as with heat; to dry\n   up with heat; to affect as by heat."
                },
                {
                    "spelling_detail_id": 124466,
                    "word_type": "v. t.",
                    "spelling_usage": "To burn; to destroy by, or as by, fire."
                },
                {
                    "spelling_detail_id": 124467,
                    "word_type": "v. i.",
                    "spelling_usage": "To be burnt on the surface; to be parched; to be dried\n   up."
                },
                {
                    "spelling_detail_id": 124468,
                    "word_type": "v. i.",
                    "spelling_usage": "To burn or be burnt."
                }
            ]
        },
        {
            "id": 79867,
            "spelling_name": "Scripture",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124855,
                    "word_type": "n.",
                    "spelling_usage": "Anything written; a writing; a document; an inscription."
                },
                {
                    "spelling_detail_id": 124856,
                    "word_type": "n.",
                    "spelling_usage": "The books of the Old and the new Testament, or of either\n   of them; the Bible; -- used by way of eminence or distinction, and\n   chiefly in the plural."
                },
                {
                    "spelling_detail_id": 124857,
                    "word_type": "n.",
                    "spelling_usage": "A passage from the Bible;; a text."
                }
            ]
        },
        {
            "id": 79909,
            "spelling_name": "Scruple",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 124915,
                    "word_type": "n.",
                    "spelling_usage": "A weight of twenty grains; the third part of a dram."
                },
                {
                    "spelling_detail_id": 124916,
                    "word_type": "n.",
                    "spelling_usage": "Hence, a very small quantity; a particle."
                },
                {
                    "spelling_detail_id": 124917,
                    "word_type": "n.",
                    "spelling_usage": "Hesitation as to action from the difficulty of determining\n   what is right or expedient; unwillingness, doubt, or hesitation\n   proceeding from motives of conscience."
                },
                {
                    "spelling_detail_id": 124920,
                    "word_type": "v. i.",
                    "spelling_usage": "To be reluctant or to hesitate, as regards an action,\n   on account of considerations of conscience or expedience."
                },
                {
                    "spelling_detail_id": 124921,
                    "word_type": "v. t.",
                    "spelling_usage": "To regard with suspicion; to hesitate at; to question."
                },
                {
                    "spelling_detail_id": 124922,
                    "word_type": "v. t.",
                    "spelling_usage": "To excite scruples in; to cause to scruple."
                }
            ]
        },
        {
            "id": 79964,
            "spelling_name": "Scumble",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 125027,
                    "word_type": "v. t.",
                    "spelling_usage": "To cover lighty, as a painting, or a drawing, with a\n   thin wash of opaque color, or with color-crayon dust rubbed on with the\n   stump, or to make any similar additions to the work, so as to produce a\n   softened effect."
                }
            ]
        },
        {
            "id": 80340,
            "spelling_name": "Season",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 125535,
                    "word_type": "n.",
                    "spelling_usage": "One of the divisions of the year, marked by alternations in\n   the length of day and night, or by distinct conditions of temperature,\n   moisture, etc., caused mainly by the relative position of the earth\n   with respect to the sun. In the north temperate zone, four seasons,\n   namely, spring, summer, autumn, and winter, are generally recognized.\n   Some parts of the world have three seasons, -- the dry, the rainy, and\n   the cold; other parts have but two, -- the dry and the rainy."
                },
                {
                    "spelling_detail_id": 125536,
                    "word_type": "n.",
                    "spelling_usage": "Hence, a period of time, especially as regards its fitness\n   for anything contemplated or done; a suitable or convenient time;\n   proper conjuncture; as, the season for planting; the season for rest."
                },
                {
                    "spelling_detail_id": 125537,
                    "word_type": "n.",
                    "spelling_usage": "A period of time not very long; a while; a time."
                },
                {
                    "spelling_detail_id": 125538,
                    "word_type": "n.",
                    "spelling_usage": "That which gives relish; seasoning."
                },
                {
                    "spelling_detail_id": 125541,
                    "word_type": "v. t.",
                    "spelling_usage": "To render suitable or appropriate; to prepare; to fit."
                },
                {
                    "spelling_detail_id": 125542,
                    "word_type": "v. t.",
                    "spelling_usage": "To fit for any use by time or habit; to habituate; to\n   accustom; to inure; to ripen; to mature; as, to season one to a\n   climate."
                },
                {
                    "spelling_detail_id": 125543,
                    "word_type": "v. t.",
                    "spelling_usage": "Hence, to prepare by drying or hardening, or removal of\n   natural juices; as, to season timber."
                },
                {
                    "spelling_detail_id": 125544,
                    "word_type": "v. t.",
                    "spelling_usage": "To fit for taste; to render palatable; to give zest or\n   relish to; to spice; as, to season food."
                },
                {
                    "spelling_detail_id": 125545,
                    "word_type": "v. t.",
                    "spelling_usage": "Hence, to fit for enjoyment; to render agrecable."
                },
                {
                    "spelling_detail_id": 125546,
                    "word_type": "v. t.",
                    "spelling_usage": "To qualify by admixture; to moderate; to temper."
                },
                {
                    "spelling_detail_id": 125547,
                    "word_type": "v. t.",
                    "spelling_usage": "To imbue; to tinge or taint."
                },
                {
                    "spelling_detail_id": 125548,
                    "word_type": "v. t.",
                    "spelling_usage": "To copulate with; to impregnate."
                },
                {
                    "spelling_detail_id": 125549,
                    "word_type": "v. i.",
                    "spelling_usage": "To become mature; to grow fit for use; to become adapted\n   to a climate."
                },
                {
                    "spelling_detail_id": 125550,
                    "word_type": "v. i.",
                    "spelling_usage": "To become dry and hard, by the escape of the natural\n   juices, or by being penetrated with other substance; as, timber seasons\n   in the sun."
                },
                {
                    "spelling_detail_id": 125551,
                    "word_type": "v. i.",
                    "spelling_usage": "To give token; to savor."
                }
            ]
        },
        {
            "id": 80500,
            "spelling_name": "Sedentary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 125868,
                    "word_type": "a.",
                    "spelling_usage": "Accustomed to sit much or long; as, a sedentary man."
                },
                {
                    "spelling_detail_id": 125869,
                    "word_type": "a.",
                    "spelling_usage": "Characterized by, or requiring, much sitting; as, a\n   sedentary employment; a sedentary life."
                },
                {
                    "spelling_detail_id": 125870,
                    "word_type": "a.",
                    "spelling_usage": "Inactive; motionless; sluggish; hence, calm; tranquil."
                },
                {
                    "spelling_detail_id": 125871,
                    "word_type": "a.",
                    "spelling_usage": "Caused by long sitting."
                },
                {
                    "spelling_detail_id": 125872,
                    "word_type": "a.",
                    "spelling_usage": "Remaining in one place, especially when firmly attached\n   to some object; as, the oyster is a sedentary mollusk; the barnacles\n   are sedentary crustaceans."
                }
            ]
        },
        {
            "id": 81808,
            "spelling_name": "Shade",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 127841,
                    "word_type": "n.",
                    "spelling_usage": "Comparative obscurity owing to interception or interruption\n   of the rays of light; partial darkness caused by the intervention of\n   something between the space contemplated and the source of light."
                },
                {
                    "spelling_detail_id": 127842,
                    "word_type": "n.",
                    "spelling_usage": "Darkness; obscurity; -- often in the plural."
                },
                {
                    "spelling_detail_id": 127843,
                    "word_type": "n.",
                    "spelling_usage": "An obscure place; a spot not exposed to light; hence, a\n   secluded retreat."
                },
                {
                    "spelling_detail_id": 127844,
                    "word_type": "n.",
                    "spelling_usage": "That which intercepts, or shelters from, light or the direct\n   rays of the sun; hence, also, that which protects from heat or currents\n   of air; a screen; protection; shelter; cover; as, a lamp shade."
                },
                {
                    "spelling_detail_id": 127845,
                    "word_type": "n.",
                    "spelling_usage": "Shadow."
                },
                {
                    "spelling_detail_id": 127846,
                    "word_type": "n.",
                    "spelling_usage": "The soul after its separation from the body; -- so called\n   because the ancients it to be perceptible to the sight, though not to\n   the touch; a spirit; a ghost; as, the shades of departed heroes."
                },
                {
                    "spelling_detail_id": 127847,
                    "word_type": "n.",
                    "spelling_usage": "The darker portion of a picture; a less illuminated part.\n   See Def. 1, above."
                },
                {
                    "spelling_detail_id": 127848,
                    "word_type": "n.",
                    "spelling_usage": "Degree or variation of color, as darker or lighter, stronger\n   or paler; as, a delicate shade of pink."
                },
                {
                    "spelling_detail_id": 127849,
                    "word_type": "n.",
                    "spelling_usage": "A minute difference or variation, as of thought, belief,\n   expression, etc.; also, the quality or degree of anything which is\n   distinguished from others similar by slight differences; as, the shades\n   of meaning in synonyms."
                },
                {
                    "spelling_detail_id": 127852,
                    "word_type": "v. t.",
                    "spelling_usage": "To shelter or screen by intercepting the rays of light;\n   to keep off illumination from."
                },
                {
                    "spelling_detail_id": 127853,
                    "word_type": "v. t.",
                    "spelling_usage": "To shelter; to cover from injury; to protect; to screen;\n   to hide; as, to shade one's eyes."
                },
                {
                    "spelling_detail_id": 127854,
                    "word_type": "v. t.",
                    "spelling_usage": "To obscure; to dim the brightness of."
                },
                {
                    "spelling_detail_id": 127855,
                    "word_type": "v. t.",
                    "spelling_usage": "To pain in obscure colors; to darken."
                },
                {
                    "spelling_detail_id": 127856,
                    "word_type": "v. t.",
                    "spelling_usage": "To mark with gradations of light or color."
                },
                {
                    "spelling_detail_id": 127857,
                    "word_type": "v. t.",
                    "spelling_usage": "To present a shadow or image of; to shadow forth; to\n   represent."
                }
            ]
        },
        {
            "id": 81817,
            "spelling_name": "Shadow",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 127866,
                    "word_type": "n.",
                    "spelling_usage": "Shade within defined limits; obscurity or deprivation of\n   light, apparent on a surface, and representing the form of the body\n   which intercepts the rays of light; as, the shadow of a man, of a tree,\n   or of a tower. See the Note under Shade, n., 1."
                },
                {
                    "spelling_detail_id": 127867,
                    "word_type": "n.",
                    "spelling_usage": "Darkness; shade; obscurity."
                },
                {
                    "spelling_detail_id": 127868,
                    "word_type": "n.",
                    "spelling_usage": "A shaded place; shelter; protection; security."
                },
                {
                    "spelling_detail_id": 127869,
                    "word_type": "n.",
                    "spelling_usage": "A reflected image, as in a mirror or in water."
                },
                {
                    "spelling_detail_id": 127870,
                    "word_type": "n.",
                    "spelling_usage": "That which follows or attends a person or thing like a\n   shadow; an inseparable companion; hence, an obsequious follower."
                },
                {
                    "spelling_detail_id": 127871,
                    "word_type": "n.",
                    "spelling_usage": "A spirit; a ghost; a shade; a phantom."
                },
                {
                    "spelling_detail_id": 127872,
                    "word_type": "n.",
                    "spelling_usage": "An imperfect and faint representation; adumbration;\n   indistinct image; dim bodying forth; hence, mystical representation;\n   type."
                },
                {
                    "spelling_detail_id": 127873,
                    "word_type": "n.",
                    "spelling_usage": "A small degree; a shade."
                },
                {
                    "spelling_detail_id": 127874,
                    "word_type": "n.",
                    "spelling_usage": "An uninvited guest coming with one who is invited."
                },
                {
                    "spelling_detail_id": 127877,
                    "word_type": "n.",
                    "spelling_usage": "To cut off light from; to put in shade; to shade; to throw\n   a shadow upon; to overspead with obscurity."
                },
                {
                    "spelling_detail_id": 127878,
                    "word_type": "n.",
                    "spelling_usage": "To conceal; to hide; to screen."
                },
                {
                    "spelling_detail_id": 127879,
                    "word_type": "n.",
                    "spelling_usage": "To protect; to shelter from danger; to shroud."
                },
                {
                    "spelling_detail_id": 127880,
                    "word_type": "n.",
                    "spelling_usage": "To mark with gradations of light or color; to shade."
                },
                {
                    "spelling_detail_id": 127881,
                    "word_type": "n.",
                    "spelling_usage": "To represent faintly or imperfectly; to adumbrate; hence,\n   to represent typically."
                },
                {
                    "spelling_detail_id": 127882,
                    "word_type": "n.",
                    "spelling_usage": "To cloud; to darken; to cast a gloom over."
                },
                {
                    "spelling_detail_id": 127883,
                    "word_type": "n.",
                    "spelling_usage": "To attend as closely as a shadow; to follow and watch\n   closely, especially in a secret or unobserved manner; as, a detective\n   shadows a criminal."
                }
            ]
        },
        {
            "id": 81909,
            "spelling_name": "Shamrock",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 128067,
                    "word_type": "n.",
                    "spelling_usage": "A trifoliate plant used as a national emblem by the\n   Irish. The legend is that St. Patrick once plucked a leaf of it for use\n   in illustrating the doctrine of the trinity."
                }
            ]
        },
        {
            "id": 82028,
            "spelling_name": "Shebang",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 128352,
                    "word_type": "n.",
                    "spelling_usage": "A jocosely depreciative name for a dwelling or shop."
                }
            ]
        },
        {
            "id": 82037,
            "spelling_name": "Sheen",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 128379,
                    "word_type": "v. t.",
                    "spelling_usage": "Bright; glittering; radiant; fair; showy; sheeny."
                },
                {
                    "spelling_detail_id": 128380,
                    "word_type": "v. i.",
                    "spelling_usage": "To shine; to glisten."
                },
                {
                    "spelling_detail_id": 128381,
                    "word_type": "n.",
                    "spelling_usage": "Brightness; splendor; glitter."
                }
            ]
        },
        {
            "id": 82169,
            "spelling_name": "Shield",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 128595,
                    "word_type": "n.",
                    "spelling_usage": "A broad piece of defensive armor, carried on the arm, --\n   formerly in general use in war, for the protection of the body. See\n   Buckler."
                },
                {
                    "spelling_detail_id": 128596,
                    "word_type": "n.",
                    "spelling_usage": "Anything which protects or defends; defense; shelter;\n   protection."
                },
                {
                    "spelling_detail_id": 128597,
                    "word_type": "n.",
                    "spelling_usage": "Figuratively, one who protects or defends."
                },
                {
                    "spelling_detail_id": 128598,
                    "word_type": "n.",
                    "spelling_usage": "In lichens, a Hardened cup or disk surrounded by a rim and\n   containing the fructification, or asci."
                },
                {
                    "spelling_detail_id": 128599,
                    "word_type": "n.",
                    "spelling_usage": "The escutcheon or field on which are placed the bearings in\n   coats of arms. Cf. Lozenge. See Illust. of Escutcheon."
                },
                {
                    "spelling_detail_id": 128600,
                    "word_type": "n.",
                    "spelling_usage": "A framework used to protect workmen in making an adit under\n   ground, and capable of being pushed along as excavation progresses."
                },
                {
                    "spelling_detail_id": 128601,
                    "word_type": "n.",
                    "spelling_usage": "A spot resembling, or having the form of, a shield."
                },
                {
                    "spelling_detail_id": 128602,
                    "word_type": "n.",
                    "spelling_usage": "A coin, the old French crown, or ecu, having on one side\n   the figure of a shield."
                },
                {
                    "spelling_detail_id": 128605,
                    "word_type": "n.",
                    "spelling_usage": "To cover with, or as with, a shield; to cover from danger;\n   to defend; to protect from assault or injury."
                },
                {
                    "spelling_detail_id": 128606,
                    "word_type": "n.",
                    "spelling_usage": "To ward off; to keep off or out."
                },
                {
                    "spelling_detail_id": 128607,
                    "word_type": "n.",
                    "spelling_usage": "To avert, as a misfortune; hence, as a supplicatory\n   exclamation, forbid!"
                }
            ]
        },
        {
            "id": 82364,
            "spelling_name": "Shore",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 128283,
                    "word_type": "",
                    "spelling_usage": "of Shear"
                },
                {
                    "spelling_detail_id": 129000,
                    "word_type": "",
                    "spelling_usage": "imp. of Shear."
                },
                {
                    "spelling_detail_id": 129001,
                    "word_type": "n.",
                    "spelling_usage": "A sewer."
                },
                {
                    "spelling_detail_id": 129002,
                    "word_type": "n.",
                    "spelling_usage": "A prop, as a timber, placed as a brace or support against\n   the side of a building or other structure; a prop placed beneath\n   anything, as a beam, to prevent it from sinking or sagging."
                },
                {
                    "spelling_detail_id": 129005,
                    "word_type": "v. t.",
                    "spelling_usage": "To support by a shore or shores; to prop; -- usually with\n   up; as, to shore up a building."
                },
                {
                    "spelling_detail_id": 129006,
                    "word_type": "v. t.",
                    "spelling_usage": "The coast or land adjacent to a large body of water, as\n   an ocean, lake, or large river."
                },
                {
                    "spelling_detail_id": 129007,
                    "word_type": "v. t.",
                    "spelling_usage": "To set on shore."
                }
            ]
        },
        {
            "id": 82419,
            "spelling_name": "Shoulder",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 129111,
                    "word_type": "n.",
                    "spelling_usage": "The joint, or the region of the joint, by which the fore\n   limb is connected with the body or with the shoulder girdle; the\n   projection formed by the bones and muscles about that joint."
                },
                {
                    "spelling_detail_id": 129112,
                    "word_type": "n.",
                    "spelling_usage": "The flesh and muscles connected with the shoulder joint;\n   the upper part of the back; that part of the human frame on which it is\n   most easy to carry a heavy burden; -- often used in the plural."
                },
                {
                    "spelling_detail_id": 129113,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: That which supports or sustains; support."
                },
                {
                    "spelling_detail_id": 129114,
                    "word_type": "n.",
                    "spelling_usage": "That which resembles a human shoulder, as any\n   protuberance or projection from the body of a thing."
                },
                {
                    "spelling_detail_id": 129115,
                    "word_type": "n.",
                    "spelling_usage": "The upper joint of the fore leg and adjacent parts of an\n   animal, dressed for market; as, a shoulder of mutton."
                },
                {
                    "spelling_detail_id": 129116,
                    "word_type": "n.",
                    "spelling_usage": "The angle of a bastion included between the face and\n   flank. See Illust. of Bastion."
                },
                {
                    "spelling_detail_id": 129117,
                    "word_type": "n.",
                    "spelling_usage": "An abrupt projection which forms an abutment on an\n   object, or limits motion, etc., as the projection around a tenon at the\n   end of a piece of timber, the part of the top of a type which projects\n   beyond the base of the raised character, etc."
                },
                {
                    "spelling_detail_id": 129120,
                    "word_type": "v. t.",
                    "spelling_usage": "To push or thrust with the shoulder; to push with\n   violence; to jostle."
                },
                {
                    "spelling_detail_id": 129121,
                    "word_type": "v. t.",
                    "spelling_usage": "To take upon the shoulder or shoulders; as, to\n   shoulder a basket; hence, to assume the burden or responsibility of;\n   as, to shoulder blame; to shoulder a debt."
                }
            ]
        },
        {
            "id": 82500,
            "spelling_name": "Shrimp",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 129264,
                    "word_type": "v. t.",
                    "spelling_usage": "To contract; to shrink."
                },
                {
                    "spelling_detail_id": 129265,
                    "word_type": "v.",
                    "spelling_usage": "Any one of numerous species of macruran Crustacea belonging\n   to Crangon and various allied genera, having a slender body and long\n   legs. Many of them are used as food. The larger kinds are called also\n   prawns. See Illust. of Decapoda."
                },
                {
                    "spelling_detail_id": 129266,
                    "word_type": "v.",
                    "spelling_usage": "In a more general sense, any species of the macruran tribe\n   Caridea, or any species of the order Schizopoda, having a similar form."
                },
                {
                    "spelling_detail_id": 129267,
                    "word_type": "v.",
                    "spelling_usage": "In a loose sense, any small crustacean, including some\n   amphipods and even certain entomostracans; as, the fairy shrimp, and\n   brine shrimp. See under Fairy, and Brine."
                },
                {
                    "spelling_detail_id": 129268,
                    "word_type": "v.",
                    "spelling_usage": "Figuratively, a little wrinkled man; a dwarf; -- in\n   contempt."
                }
            ]
        },
        {
            "id": 82706,
            "spelling_name": "Sighed",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 129643,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Sigh"
                }
            ]
        },
        {
            "id": 82884,
            "spelling_name": "Silver",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 129957,
                    "word_type": "n.",
                    "spelling_usage": "A soft white metallic element, sonorous, ductile, very\n   malleable, and capable of a high degree of polish. It is found native,\n   and also combined with sulphur, arsenic, antimony, chlorine, etc., in\n   the minerals argentite, proustite, pyrargyrite, ceragyrite, etc. Silver\n   is one of the \"noble\" metals, so-called, not being easily oxidized, and\n   is used for coin, jewelry, plate, and a great variety of articles.\n   Symbol Ag (Argentum). Atomic weight 107.7. Specific gravity 10.5."
                },
                {
                    "spelling_detail_id": 129958,
                    "word_type": "n.",
                    "spelling_usage": "Coin made of silver; silver money."
                },
                {
                    "spelling_detail_id": 129959,
                    "word_type": "n.",
                    "spelling_usage": "Anything having the luster or appearance of silver."
                },
                {
                    "spelling_detail_id": 129960,
                    "word_type": "n.",
                    "spelling_usage": "The color of silver."
                },
                {
                    "spelling_detail_id": 129961,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to silver; made of silver; as, silver\n   leaf; a silver cup."
                },
                {
                    "spelling_detail_id": 129962,
                    "word_type": "a.",
                    "spelling_usage": "Resembling silver."
                },
                {
                    "spelling_detail_id": 129963,
                    "word_type": "a.",
                    "spelling_usage": "Bright; resplendent; white."
                },
                {
                    "spelling_detail_id": 129964,
                    "word_type": "a.",
                    "spelling_usage": "Precious; costly."
                },
                {
                    "spelling_detail_id": 129965,
                    "word_type": "a.",
                    "spelling_usage": "Giving a clear, ringing sound soft and clear."
                },
                {
                    "spelling_detail_id": 129966,
                    "word_type": "a.",
                    "spelling_usage": "Sweet; gentle; peaceful."
                },
                {
                    "spelling_detail_id": 129969,
                    "word_type": "v. t.",
                    "spelling_usage": "To cover with silver; to give a silvery appearance to by\n   applying a metal of a silvery color; as, to silver a pin; to silver a\n   glass mirror plate with an amalgam of tin and mercury."
                },
                {
                    "spelling_detail_id": 129970,
                    "word_type": "v. t.",
                    "spelling_usage": "To polish like silver; to impart a brightness to, like\n   that of silver."
                },
                {
                    "spelling_detail_id": 129971,
                    "word_type": "v. t.",
                    "spelling_usage": "To make hoary, or white, like silver."
                },
                {
                    "spelling_detail_id": 129972,
                    "word_type": "v. i.",
                    "spelling_usage": "To acquire a silvery color."
                }
            ]
        },
        {
            "id": 82930,
            "spelling_name": "Simmer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 130030,
                    "word_type": "v. i.",
                    "spelling_usage": "To boil gently, or with a gentle hissing; to begin to\n   boil."
                },
                {
                    "spelling_detail_id": 130031,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause to boil gently; to cook in liquid heated almost\n   or just to the boiling point."
                }
            ]
        },
        {
            "id": 83159,
            "spelling_name": "Sir",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 130436,
                    "word_type": "n.",
                    "spelling_usage": "A man of social authority and dignity; a lord; a master; a\n   gentleman; -- in this sense usually spelled sire."
                },
                {
                    "spelling_detail_id": 130437,
                    "word_type": "n.",
                    "spelling_usage": "A title prefixed to the Christian name of a knight or a\n   baronet."
                },
                {
                    "spelling_detail_id": 130438,
                    "word_type": "n.",
                    "spelling_usage": "An English rendering of the LAtin Dominus, the academical\n   title of a bachelor of arts; -- formerly colloquially, and sometimes\n   contemptuously, applied to the clergy."
                },
                {
                    "spelling_detail_id": 130439,
                    "word_type": "n.",
                    "spelling_usage": "A respectful title, used in addressing a man, without being\n   prefixed to his name; -- used especially in speaking to elders or\n   superiors; sometimes, also, used in the way of emphatic formality."
                }
            ]
        },
        {
            "id": 83273,
            "spelling_name": "Sizzle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 130665,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a hissing sound; to fry, or to dry and shrivel\n   up, with a hissing sound."
                },
                {
                    "spelling_detail_id": 130666,
                    "word_type": "n.",
                    "spelling_usage": "A hissing sound, as of something frying over a fire."
                }
            ]
        },
        {
            "id": 83296,
            "spelling_name": "Skedaddle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 130694,
                    "word_type": "v. i.",
                    "spelling_usage": "To betake one's self to flight, as if in a panic; to\n   flee; to run away."
                }
            ]
        },
        {
            "id": 83385,
            "spelling_name": "Skin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 130844,
                    "word_type": "n.",
                    "spelling_usage": "The external membranous integument of an animal."
                },
                {
                    "spelling_detail_id": 130845,
                    "word_type": "n.",
                    "spelling_usage": "The hide of an animal, separated from the body, whether\n   green, dry, or tanned; especially, that of a small animal, as a calf,\n   sheep, or goat."
                },
                {
                    "spelling_detail_id": 130846,
                    "word_type": "n.",
                    "spelling_usage": "A vessel made of skin, used for holding liquids. See Bottle,\n   1."
                },
                {
                    "spelling_detail_id": 130847,
                    "word_type": "n.",
                    "spelling_usage": "The bark or husk of a plant or fruit; the exterior coat of\n   fruits and plants."
                },
                {
                    "spelling_detail_id": 130848,
                    "word_type": "n.",
                    "spelling_usage": "That part of a sail, when furled, which remains on the\n   outside and covers the whole."
                },
                {
                    "spelling_detail_id": 130849,
                    "word_type": "n.",
                    "spelling_usage": "The covering, as of planking or iron plates, outside the\n   framing, forming the sides and bottom of a vessel; the shell; also, a\n   lining inside the framing."
                },
                {
                    "spelling_detail_id": 130852,
                    "word_type": "v. t.",
                    "spelling_usage": "To strip off the skin or hide of; to flay; to peel; as, to\n   skin an animal."
                },
                {
                    "spelling_detail_id": 130853,
                    "word_type": "v. t.",
                    "spelling_usage": "To cover with skin, or as with skin; hence, to cover\n   superficially."
                },
                {
                    "spelling_detail_id": 130854,
                    "word_type": "v. t.",
                    "spelling_usage": "To strip of money or property; to cheat."
                },
                {
                    "spelling_detail_id": 130855,
                    "word_type": "v. i.",
                    "spelling_usage": "To become covered with skin; as, a wound skins over."
                },
                {
                    "spelling_detail_id": 130856,
                    "word_type": "v. i.",
                    "spelling_usage": "To produce, in recitation, examination, etc., the work of\n   another for one's own, or to use in such exercise cribs, memeoranda,\n   etc., which are prohibited."
                }
            ]
        },
        {
            "id": 83415,
            "spelling_name": "Skirmish",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 130914,
                    "word_type": "v. i.",
                    "spelling_usage": "To fight slightly or in small parties; to engage in a\n   skirmish or skirmishes; to act as skirmishers."
                },
                {
                    "spelling_detail_id": 130915,
                    "word_type": "v. i.",
                    "spelling_usage": "A slight fight in war; a light or desultory combat\n   between detachments from armies, or between detached and small bodies\n   of troops."
                },
                {
                    "spelling_detail_id": 130916,
                    "word_type": "v. i.",
                    "spelling_usage": "A slight contest."
                }
            ]
        },
        {
            "id": 83619,
            "spelling_name": "Sleek",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131289,
                    "word_type": "superl.",
                    "spelling_usage": "Having an even, smooth surface; smooth; hence, glossy;\n   as, sleek hair."
                },
                {
                    "spelling_detail_id": 131290,
                    "word_type": "superl.",
                    "spelling_usage": "Not rough or harsh."
                },
                {
                    "spelling_detail_id": 131291,
                    "word_type": "adv.",
                    "spelling_usage": "With ease and dexterity."
                },
                {
                    "spelling_detail_id": 131292,
                    "word_type": "n.",
                    "spelling_usage": "That which makes smooth; varnish."
                },
                {
                    "spelling_detail_id": 131295,
                    "word_type": "v. t.",
                    "spelling_usage": "To make even and smooth; to render smooth, soft, and\n   glossy; to smooth over."
                }
            ]
        },
        {
            "id": 83640,
            "spelling_name": "Sleepy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131335,
                    "word_type": "n.",
                    "spelling_usage": "Drowsy; inclined to, or overcome by, sleep."
                },
                {
                    "spelling_detail_id": 131336,
                    "word_type": "n.",
                    "spelling_usage": "Tending to induce sleep; soporiferous; somniferous; as, a\n   sleepy drink or potion."
                },
                {
                    "spelling_detail_id": 131337,
                    "word_type": "n.",
                    "spelling_usage": "Dull; lazy; heavy; sluggish."
                },
                {
                    "spelling_detail_id": 131338,
                    "word_type": "n.",
                    "spelling_usage": "Characterized by an absence of watchfulness; as, sleepy\n   security."
                }
            ]
        },
        {
            "id": 83720,
            "spelling_name": "Slimy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131510,
                    "word_type": "superl.",
                    "spelling_usage": "Of or pertaining to slime; resembling slime; of the\n   nature of slime; viscous; glutinous; also, covered or daubed with\n   slime; yielding, or abounding in, slime."
                }
            ]
        },
        {
            "id": 83755,
            "spelling_name": "Slither",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131620,
                    "word_type": "v. i.",
                    "spelling_usage": "To slide; to glide."
                }
            ]
        },
        {
            "id": 83773,
            "spelling_name": "Slogan",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131646,
                    "word_type": "n.",
                    "spelling_usage": "The war cry, or gathering word, of a Highland clan in\n   Scotland; hence, any rallying cry."
                }
            ]
        },
        {
            "id": 83843,
            "spelling_name": "Sluggard",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131787,
                    "word_type": "n.",
                    "spelling_usage": "A person habitually lazy, idle, and inactive; a drone."
                },
                {
                    "spelling_detail_id": 131788,
                    "word_type": "a.",
                    "spelling_usage": "Sluggish; lazy."
                }
            ]
        },
        {
            "id": 83913,
            "spelling_name": "Smart",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 131925,
                    "word_type": "v. i.",
                    "spelling_usage": "To feel a lively, pungent local pain; -- said of some\n   part of the body as the seat of irritation; as, my finger smarts; these\n   wounds smart."
                },
                {
                    "spelling_detail_id": 131926,
                    "word_type": "v. i.",
                    "spelling_usage": "To feel a pungent pain of mind; to feel sharp pain or\n   grief; to suffer; to feel the sting of evil."
                },
                {
                    "spelling_detail_id": 131927,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause a smart in."
                },
                {
                    "spelling_detail_id": 131928,
                    "word_type": "v. i.",
                    "spelling_usage": "Quick, pungent, lively pain; a pricking local pain, as\n   the pain from puncture by nettles."
                },
                {
                    "spelling_detail_id": 131929,
                    "word_type": "v. i.",
                    "spelling_usage": "Severe, pungent pain of mind; pungent grief; as, the\n   smart of affliction."
                },
                {
                    "spelling_detail_id": 131930,
                    "word_type": "v. i.",
                    "spelling_usage": "A fellow who affects smartness, briskness, and vivacity;\n   a dandy."
                },
                {
                    "spelling_detail_id": 131931,
                    "word_type": "v. i.",
                    "spelling_usage": "Smart money (see below)."
                },
                {
                    "spelling_detail_id": 131932,
                    "word_type": "v. i.",
                    "spelling_usage": "Causing a smart; pungent; pricking; as, a smart stroke or\n   taste."
                },
                {
                    "spelling_detail_id": 131933,
                    "word_type": "v. i.",
                    "spelling_usage": "Keen; severe; poignant; as, smart pain."
                },
                {
                    "spelling_detail_id": 131934,
                    "word_type": "v. i.",
                    "spelling_usage": "Vigorous; sharp; severe."
                },
                {
                    "spelling_detail_id": 131935,
                    "word_type": "v. i.",
                    "spelling_usage": "Accomplishing, or able to accomplish, results quickly;\n   active; sharp; clever."
                },
                {
                    "spelling_detail_id": 131936,
                    "word_type": "v. i.",
                    "spelling_usage": "Efficient; vigorous; brilliant."
                },
                {
                    "spelling_detail_id": 131937,
                    "word_type": "v. i.",
                    "spelling_usage": "Marked by acuteness or shrewdness; quick in suggestion or\n   reply; vivacious; witty; as, a smart reply; a smart saying."
                },
                {
                    "spelling_detail_id": 131938,
                    "word_type": "v. i.",
                    "spelling_usage": "Pretentious; showy; spruce; as, a smart gown."
                },
                {
                    "spelling_detail_id": 131939,
                    "word_type": "v. i.",
                    "spelling_usage": "Brisk; fresh; as, a smart breeze."
                }
            ]
        },
        {
            "id": 84199,
            "spelling_name": "Snippet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 132508,
                    "word_type": "n.",
                    "spelling_usage": "A small part or piece."
                }
            ]
        },
        {
            "id": 84409,
            "spelling_name": "Sofa",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 132865,
                    "word_type": "n.",
                    "spelling_usage": "A long seat, usually with a cushioned bottom, back, and ends;\n   -- much used as a comfortable piece of furniture."
                }
            ]
        },
        {
            "id": 85059,
            "spelling_name": "Space",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 133947,
                    "word_type": "n.",
                    "spelling_usage": "Extension, considered independently of anything which it may\n   contain; that which makes extended objects conceivable and possible."
                },
                {
                    "spelling_detail_id": 133948,
                    "word_type": "n.",
                    "spelling_usage": "Place, having more or less extension; room."
                },
                {
                    "spelling_detail_id": 133949,
                    "word_type": "n.",
                    "spelling_usage": "A quantity or portion of extension; distance from one thing\n   to another; an interval between any two or more objects; as, the space\n   between two stars or two hills; the sound was heard for the space of a\n   mile."
                },
                {
                    "spelling_detail_id": 133950,
                    "word_type": "n.",
                    "spelling_usage": "Quantity of time; an interval between two points of time;\n   duration; time."
                },
                {
                    "spelling_detail_id": 133951,
                    "word_type": "n.",
                    "spelling_usage": "A short time; a while."
                },
                {
                    "spelling_detail_id": 133952,
                    "word_type": "n.",
                    "spelling_usage": "Walk; track; path; course."
                },
                {
                    "spelling_detail_id": 133953,
                    "word_type": "n.",
                    "spelling_usage": "A small piece of metal cast lower than a face type, so as\n   not to receive the ink in printing, -- used to separate words or\n   letters."
                },
                {
                    "spelling_detail_id": 133954,
                    "word_type": "n.",
                    "spelling_usage": "The distance or interval between words or letters in the\n   lines, or between lines, as in books."
                },
                {
                    "spelling_detail_id": 133955,
                    "word_type": "n.",
                    "spelling_usage": "One of the intervals, or open places, between the lines of\n   the staff."
                },
                {
                    "spelling_detail_id": 133956,
                    "word_type": "n.",
                    "spelling_usage": "To walk; to rove; to roam."
                },
                {
                    "spelling_detail_id": 133959,
                    "word_type": "n.",
                    "spelling_usage": "To arrange or adjust the spaces in or between; as, to space\n   words, lines, or letters."
                }
            ]
        },
        {
            "id": 85123,
            "spelling_name": "Spangled",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134056,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Spangle"
                }
            ]
        },
        {
            "id": 85128,
            "spelling_name": "Spaniel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134063,
                    "word_type": "n.",
                    "spelling_usage": "One of a breed of small dogs having long and thick hair\n   and large drooping ears. The legs are usually strongly feathered, and\n   the tail bushy. See Illust. under Clumber, and Cocker."
                },
                {
                    "spelling_detail_id": 134064,
                    "word_type": "n.",
                    "spelling_usage": "A cringing, fawning person."
                },
                {
                    "spelling_detail_id": 134065,
                    "word_type": "a.",
                    "spelling_usage": "Cringing; fawning."
                },
                {
                    "spelling_detail_id": 134066,
                    "word_type": "v. i.",
                    "spelling_usage": "To fawn; to cringe; to be obsequious."
                },
                {
                    "spelling_detail_id": 134067,
                    "word_type": "v. t.",
                    "spelling_usage": "To follow like a spaniel."
                }
            ]
        },
        {
            "id": 85168,
            "spelling_name": "Sparkle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134157,
                    "word_type": "n.",
                    "spelling_usage": "A little spark; a scintillation."
                },
                {
                    "spelling_detail_id": 134158,
                    "word_type": "n.",
                    "spelling_usage": "Brilliancy; luster; as, the sparkle of a diamond."
                },
                {
                    "spelling_detail_id": 134161,
                    "word_type": "n.",
                    "spelling_usage": "To emit sparks; to throw off ignited or incandescent\n   particles; to shine as if throwing off sparks; to emit flashes of\n   light; to scintillate; to twinkle; as, the blazing wood sparkles; the\n   stars sparkle."
                },
                {
                    "spelling_detail_id": 134162,
                    "word_type": "n.",
                    "spelling_usage": "To manifest itself by, or as if by, emitting sparks; to\n   glisten; to flash."
                },
                {
                    "spelling_detail_id": 134163,
                    "word_type": "n.",
                    "spelling_usage": "To emit little bubbles, as certain kinds of liquors; to\n   effervesce; as, sparkling wine."
                },
                {
                    "spelling_detail_id": 134164,
                    "word_type": "v. t.",
                    "spelling_usage": "To emit in the form or likeness of sparks."
                },
                {
                    "spelling_detail_id": 134165,
                    "word_type": "v. t.",
                    "spelling_usage": "To disperse."
                },
                {
                    "spelling_detail_id": 134166,
                    "word_type": "v. t.",
                    "spelling_usage": "To scatter on or over."
                }
            ]
        },
        {
            "id": 85320,
            "spelling_name": "Spectral",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134433,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to a specter; ghosty."
                },
                {
                    "spelling_detail_id": 134434,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the spectrum; made by the spectrum;\n   as, spectral colors; spectral analysis."
                }
            ]
        },
        {
            "id": 85582,
            "spelling_name": "Spider",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134837,
                    "word_type": "n.",
                    "spelling_usage": "Any one of numerous species of arachnids comprising the\n   order Araneina. Spiders have the mandibles converted into poison fangs,\n   or falcers. The abdomen is large and not segmented, with two or three\n   pairs of spinnerets near the end, by means of which they spin threads\n   of silk to form cocoons, or nests, to protect their eggs and young.\n   Many species spin also complex webs to entrap the insects upon which\n   they prey. The eyes are usually eight in number (rarely six), and are\n   situated on the back of the cephalothorax. See Illust. under Araneina."
                },
                {
                    "spelling_detail_id": 134838,
                    "word_type": "n.",
                    "spelling_usage": "Any one of various other arachnids resembling the true\n   spiders, especially certain mites, as the red spider (see under Red)."
                },
                {
                    "spelling_detail_id": 134839,
                    "word_type": "n.",
                    "spelling_usage": "An iron pan with a long handle, used as a kitchen utensil\n   in frying food. Originally, it had long legs, and was used over coals\n   on the hearth."
                },
                {
                    "spelling_detail_id": 134840,
                    "word_type": "n.",
                    "spelling_usage": "A trevet to support pans or pots over a fire."
                },
                {
                    "spelling_detail_id": 134841,
                    "word_type": "n.",
                    "spelling_usage": "A skeleton, or frame, having radiating arms or members,\n   often connected by crosspieces; as, a casting forming the hub and\n   spokes to which the rim of a fly wheel or large gear is bolted; the\n   body of a piston head; a frame for strengthening a core or mold for a\n   casting, etc."
                }
            ]
        },
        {
            "id": 85625,
            "spelling_name": "Spinal",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134931,
                    "word_type": "a.",
                    "spelling_usage": "Of, pertaining to, or in the region of, the backbone, or\n   vertebral column; rachidian; vertebral."
                },
                {
                    "spelling_detail_id": 134932,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to a spine or spines."
                }
            ]
        },
        {
            "id": 85627,
            "spelling_name": "Spindle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 134934,
                    "word_type": "n.",
                    "spelling_usage": "The long, round, slender rod or pin in spinning wheels by\n   which the thread is twisted, and on which, when twisted, it is wound;\n   also, the pin on which the bobbin is held in a spinning machine, or in\n   the shuttle of a loom."
                },
                {
                    "spelling_detail_id": 134935,
                    "word_type": "n.",
                    "spelling_usage": "A slender rod or pin on which anything turns; an axis; as,\n   the spindle of a vane."
                },
                {
                    "spelling_detail_id": 134936,
                    "word_type": "n.",
                    "spelling_usage": "The shaft, mandrel, or arbor, in a machine tool, as a\n   lathe or drilling machine, etc., which causes the work to revolve, or\n   carries a tool or center, etc."
                },
                {
                    "spelling_detail_id": 134937,
                    "word_type": "n.",
                    "spelling_usage": "The vertical rod on which the runner of a grinding mill\n   turns."
                },
                {
                    "spelling_detail_id": 134938,
                    "word_type": "n.",
                    "spelling_usage": "A shaft or pipe on which a core of sand is formed."
                },
                {
                    "spelling_detail_id": 134939,
                    "word_type": "n.",
                    "spelling_usage": "The fusee of a watch."
                },
                {
                    "spelling_detail_id": 134940,
                    "word_type": "n.",
                    "spelling_usage": "A long and slender stalk resembling a spindle."
                },
                {
                    "spelling_detail_id": 134941,
                    "word_type": "n.",
                    "spelling_usage": "A yarn measure containing, in cotton yarn, 15,120 yards;\n   in linen yarn, 14,400 yards."
                },
                {
                    "spelling_detail_id": 134942,
                    "word_type": "n.",
                    "spelling_usage": "A solid generated by the revolution of a curved line about\n   its base or double ordinate or chord."
                },
                {
                    "spelling_detail_id": 134943,
                    "word_type": "n.",
                    "spelling_usage": "Any marine univalve shell of the genus Rostellaria; --\n   called also spindle stromb."
                },
                {
                    "spelling_detail_id": 134944,
                    "word_type": "n.",
                    "spelling_usage": "Any marine gastropod of the genus Fusus."
                },
                {
                    "spelling_detail_id": 134947,
                    "word_type": "v. i.",
                    "spelling_usage": "To shoot or grow into a long, slender stalk or body; to\n   become disproportionately tall and slender."
                }
            ]
        },
        {
            "id": 85859,
            "spelling_name": "Splurge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 135343,
                    "word_type": "n.",
                    "spelling_usage": "A blustering demonstration, or great effort; a great\n   display."
                },
                {
                    "spelling_detail_id": 135344,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a great display in any way, especially in\n   oratory."
                }
            ]
        },
        {
            "id": 85980,
            "spelling_name": "Sport",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 135538,
                    "word_type": "n.",
                    "spelling_usage": "That which diverts, and makes mirth; pastime; amusement."
                },
                {
                    "spelling_detail_id": 135539,
                    "word_type": "n.",
                    "spelling_usage": "Mock; mockery; contemptuous mirth; derision."
                },
                {
                    "spelling_detail_id": 135540,
                    "word_type": "n.",
                    "spelling_usage": "That with which one plays, or which is driven about in play;\n   a toy; a plaything; an object of mockery."
                },
                {
                    "spelling_detail_id": 135541,
                    "word_type": "n.",
                    "spelling_usage": "Play; idle jingle."
                },
                {
                    "spelling_detail_id": 135542,
                    "word_type": "n.",
                    "spelling_usage": "Diversion of the field, as fowling, hunting, fishing,\n   racing, games, and the like, esp. when money is staked."
                },
                {
                    "spelling_detail_id": 135543,
                    "word_type": "n.",
                    "spelling_usage": "A plant or an animal, or part of a plant or animal, which\n   has some peculiarity not usually seen in the species; an abnormal\n   variety or growth. See Sporting plant, under Sporting."
                },
                {
                    "spelling_detail_id": 135544,
                    "word_type": "n.",
                    "spelling_usage": "A sportsman; a gambler."
                },
                {
                    "spelling_detail_id": 135547,
                    "word_type": "v. i.",
                    "spelling_usage": "To play; to frolic; to wanton."
                },
                {
                    "spelling_detail_id": 135548,
                    "word_type": "v. i.",
                    "spelling_usage": "To practice the diversions of the field or the turf; to\n   be given to betting, as upon races."
                },
                {
                    "spelling_detail_id": 135549,
                    "word_type": "v. i.",
                    "spelling_usage": "To trifle."
                },
                {
                    "spelling_detail_id": 135550,
                    "word_type": "v. i.",
                    "spelling_usage": "To assume suddenly a new and different character from the\n   rest of the plant or from the type of the species; -- said of a bud,\n   shoot, plant, or animal. See Sport, n., 6."
                },
                {
                    "spelling_detail_id": 135551,
                    "word_type": "v. t.",
                    "spelling_usage": "To divert; to amuse; to make merry; -- used with the\n   reciprocal pronoun."
                },
                {
                    "spelling_detail_id": 135552,
                    "word_type": "v. t.",
                    "spelling_usage": "To represent by any knd of play."
                },
                {
                    "spelling_detail_id": 135553,
                    "word_type": "v. t.",
                    "spelling_usage": "To exhibit, or bring out, in public; to use or wear; as,\n   to sport a new equipage."
                },
                {
                    "spelling_detail_id": 135554,
                    "word_type": "v. t.",
                    "spelling_usage": "To give utterance to in a sportive manner; to throw out\n   in an easy and copious manner; -- with off; as, to sport off epigrams."
                }
            ]
        },
        {
            "id": 86084,
            "spelling_name": "Sprint",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 135784,
                    "word_type": "v. i.",
                    "spelling_usage": "To run very rapidly; to run at full speed."
                },
                {
                    "spelling_detail_id": 135785,
                    "word_type": "n.",
                    "spelling_usage": "The act of sprinting; a run of a short distance at full\n   speed."
                }
            ]
        },
        {
            "id": 86198,
            "spelling_name": "Squall",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 135985,
                    "word_type": "n.",
                    "spelling_usage": "A sudden violent gust of wind often attended with rain or\n   snow."
                },
                {
                    "spelling_detail_id": 135988,
                    "word_type": "v. i.",
                    "spelling_usage": "To cry out; to scream or cry violently, as a woman\n   frightened, or a child in anger or distress; as, the infant squalled."
                },
                {
                    "spelling_detail_id": 135989,
                    "word_type": "n.",
                    "spelling_usage": "A loud scream; a harsh cry."
                }
            ]
        },
        {
            "id": 86271,
            "spelling_name": "Squeak",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 136130,
                    "word_type": "v. i.",
                    "spelling_usage": "To utter a sharp, shrill cry, usually of short duration;\n   to cry with an acute tone, as an animal; or, to make a sharp,\n   disagreeable noise, as a pipe or quill, a wagon wheel, a door; to\n   creak."
                },
                {
                    "spelling_detail_id": 136131,
                    "word_type": "v. i.",
                    "spelling_usage": "To break silence or secrecy for fear of pain or\n   punishment; to speak; to confess."
                },
                {
                    "spelling_detail_id": 136132,
                    "word_type": "n.",
                    "spelling_usage": "A sharp, shrill, disagreeable sound suddenly utered, either\n   of the human voice or of any animal or instrument, such as is made by\n   carriage wheels when dry, by the soles of leather shoes, or by a pipe\n   or reed."
                }
            ]
        },
        {
            "id": 86330,
            "spelling_name": "Squirm",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 136238,
                    "word_type": "v. i.",
                    "spelling_usage": "To twist about briskly with contor/ions like an eel or a\n   worm; to wriggle; to writhe."
                }
            ]
        },
        {
            "id": 86532,
            "spelling_name": "Standard",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 136683,
                    "word_type": "n.",
                    "spelling_usage": "A flag; colors; a banner; especially, a national or other\n   ensign."
                },
                {
                    "spelling_detail_id": 136684,
                    "word_type": "n.",
                    "spelling_usage": "That which is established by authority as a rule for the\n   measure of quantity, extent, value, or quality; esp., the original\n   specimen weight or measure sanctioned by government, as the standard\n   pound, gallon, or yard."
                },
                {
                    "spelling_detail_id": 136685,
                    "word_type": "n.",
                    "spelling_usage": "That which is established as a rule or model by\n   authority, custom, or general consent; criterion; test."
                },
                {
                    "spelling_detail_id": 136686,
                    "word_type": "n.",
                    "spelling_usage": "The proportion of weights of fine metal and alloy\n   established by authority."
                },
                {
                    "spelling_detail_id": 136687,
                    "word_type": "n.",
                    "spelling_usage": "A tree of natural size supported by its own stem, and not\n   dwarfed by grafting on the stock of a smaller species nor trained upon\n   a wall or trellis."
                },
                {
                    "spelling_detail_id": 136688,
                    "word_type": "n.",
                    "spelling_usage": "The upper petal or banner of a papilionaceous corolla."
                },
                {
                    "spelling_detail_id": 136689,
                    "word_type": "n.",
                    "spelling_usage": "An upright support, as one of the poles of a scaffold;\n   any upright in framing."
                },
                {
                    "spelling_detail_id": 136690,
                    "word_type": "n.",
                    "spelling_usage": "An inverted knee timber placed upon the deck instead of\n   beneath it, with its vertical branch turned upward from that which lies\n   horizontally."
                },
                {
                    "spelling_detail_id": 136691,
                    "word_type": "n.",
                    "spelling_usage": "The sheth of a plow."
                },
                {
                    "spelling_detail_id": 136692,
                    "word_type": "n.",
                    "spelling_usage": "A large drinking cup."
                },
                {
                    "spelling_detail_id": 136693,
                    "word_type": "a.",
                    "spelling_usage": "Being, affording, or according with, a standard for\n   comparison and judgment; as, standard time; standard weights and\n   measures; a standard authority as to nautical terms; standard gold or\n   silver."
                },
                {
                    "spelling_detail_id": 136694,
                    "word_type": "a.",
                    "spelling_usage": "Hence: Having a recognized and permanent value; as,\n   standard works in history; standard authors."
                },
                {
                    "spelling_detail_id": 136695,
                    "word_type": "a.",
                    "spelling_usage": "Not supported by, or fastened to, a wall; as, standard\n   fruit trees."
                },
                {
                    "spelling_detail_id": 136696,
                    "word_type": "a.",
                    "spelling_usage": "Not of the dwarf kind; as, a standard pear tree."
                }
            ]
        },
        {
            "id": 86587,
            "spelling_name": "Star",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 136787,
                    "word_type": "n.",
                    "spelling_usage": "One of the innumerable luminous bodies seen in the heavens;\n   any heavenly body other than the sun, moon, comets, and nebulae."
                },
                {
                    "spelling_detail_id": 136788,
                    "word_type": "n.",
                    "spelling_usage": "The polestar; the north star."
                },
                {
                    "spelling_detail_id": 136789,
                    "word_type": "n.",
                    "spelling_usage": "A planet supposed to influence one's destiny; (usually pl.) a\n   configuration of the planets, supposed to influence fortune."
                },
                {
                    "spelling_detail_id": 136790,
                    "word_type": "n.",
                    "spelling_usage": "That which resembles the figure of a star, as an ornament\n   worn on the breast to indicate rank or honor."
                },
                {
                    "spelling_detail_id": 136791,
                    "word_type": "n.",
                    "spelling_usage": "Specifically, a radiated mark in writing or printing; an\n   asterisk [thus, *]; -- used as a reference to a note, or to fill a\n   blank where something is omitted, etc."
                },
                {
                    "spelling_detail_id": 136792,
                    "word_type": "n.",
                    "spelling_usage": "A composition of combustible matter used in the heading of\n   rockets, in mines, etc., which, exploding in the air, presents a\n   starlike appearance."
                },
                {
                    "spelling_detail_id": 136793,
                    "word_type": "n.",
                    "spelling_usage": "A person of brilliant and attractive qualities, especially on\n   public occasions, as a distinguished orator, a leading theatrical\n   performer, etc."
                },
                {
                    "spelling_detail_id": 136796,
                    "word_type": "v. t.",
                    "spelling_usage": "To set or adorn with stars, or bright, radiating bodies;\n   to bespangle; as, a robe starred with gems."
                },
                {
                    "spelling_detail_id": 136797,
                    "word_type": "v. i.",
                    "spelling_usage": "To be bright, or attract attention, as a star; to shine\n   like a star; to be brilliant or prominent; to play a part as a\n   theatrical star."
                }
            ]
        },
        {
            "id": 86606,
            "spelling_name": "stared",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 136821,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Stare"
                }
            ]
        },
        {
            "id": 86926,
            "spelling_name": "Stencil",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 137415,
                    "word_type": "n.",
                    "spelling_usage": "A thin plate of metal, leather, or other material, used in\n   painting, marking, etc. The pattern is cut out of the plate, which is\n   then laid flat on the surface to be marked, and the color brushed over\n   it. Called also stencil plate."
                },
                {
                    "spelling_detail_id": 137420,
                    "word_type": "v. t.",
                    "spelling_usage": "To mark, paint, or color in figures with stencils; to\n   form or print by means of a stencil."
                }
            ]
        },
        {
            "id": 87098,
            "spelling_name": "Stewardship",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 137665,
                    "word_type": "n.",
                    "spelling_usage": "The office of a steward."
                }
            ]
        },
        {
            "id": 87232,
            "spelling_name": "Sting",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 137917,
                    "word_type": "v. t.",
                    "spelling_usage": "Any sharp organ of offense and defense, especially when\n   connected with a poison gland, and adapted to inflict a wound by\n   piercing; as the caudal sting of a scorpion. The sting of a bee or wasp\n   is a modified ovipositor. The caudal sting, or spine, of a sting ray is\n   a modified dorsal fin ray. The term is sometimes applied to the fang of\n   a serpent. See Illust. of Scorpion."
                },
                {
                    "spelling_detail_id": 137918,
                    "word_type": "v. t.",
                    "spelling_usage": "A sharp-pointed hollow hair seated on a gland which\n   secrets an acrid fluid, as in nettles. The points of these hairs\n   usually break off in the wound, and the acrid fluid is pressed into it."
                },
                {
                    "spelling_detail_id": 137919,
                    "word_type": "v. t.",
                    "spelling_usage": "Anything that gives acute pain, bodily or mental; as, the\n   stings of remorse; the stings of reproach."
                },
                {
                    "spelling_detail_id": 137920,
                    "word_type": "v. t.",
                    "spelling_usage": "The thrust of a sting into the flesh; the act of\n   stinging; a wound inflicted by stinging."
                },
                {
                    "spelling_detail_id": 137921,
                    "word_type": "v. t.",
                    "spelling_usage": "A goad; incitement."
                },
                {
                    "spelling_detail_id": 137922,
                    "word_type": "v. t.",
                    "spelling_usage": "The point of an epigram or other sarcastic saying."
                },
                {
                    "spelling_detail_id": 137926,
                    "word_type": "v. t.",
                    "spelling_usage": "To pierce or wound with a sting; as, bees will sting an\n   animal that irritates them; the nettles stung his hands."
                },
                {
                    "spelling_detail_id": 137927,
                    "word_type": "v. t.",
                    "spelling_usage": "To pain acutely; as, the conscience is stung with\n   remorse; to bite."
                },
                {
                    "spelling_detail_id": 137928,
                    "word_type": "v. t.",
                    "spelling_usage": "To goad; to incite, as by taunts or reproaches."
                }
            ]
        },
        {
            "id": 87667,
            "spelling_name": "Straw",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 138773,
                    "word_type": "v. t.",
                    "spelling_usage": "To spread or scatter. See Strew, and Strow."
                },
                {
                    "spelling_detail_id": 138774,
                    "word_type": "n.",
                    "spelling_usage": "A stalk or stem of certain species of grain, pulse, etc.,\n   especially of wheat, rye, oats, barley, more rarely of buckwheat,\n   beans, and pease."
                },
                {
                    "spelling_detail_id": 138775,
                    "word_type": "n.",
                    "spelling_usage": "The gathered and thrashed stalks of certain species of\n   grain, etc.; as, a bundle, or a load, of rye straw."
                },
                {
                    "spelling_detail_id": 138776,
                    "word_type": "n.",
                    "spelling_usage": "Anything proverbially worthless; the least possible thing; a\n   mere trifle."
                }
            ]
        },
        {
            "id": 87856,
            "spelling_name": "Strong",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 139230,
                    "word_type": "superl.",
                    "spelling_usage": "Having active physical power, or great physical power\n   to act; having a power of exerting great bodily force; vigorous."
                },
                {
                    "spelling_detail_id": 139231,
                    "word_type": "superl.",
                    "spelling_usage": "Having passive physical power; having ability to bear\n   or endure; firm; hale; sound; robust; as, a strong constitution; strong\n   health."
                },
                {
                    "spelling_detail_id": 139232,
                    "word_type": "superl.",
                    "spelling_usage": "Solid; tough; not easily broken or injured; able to\n   withstand violence; able to sustain attacks; not easily subdued or\n   taken; as, a strong beam; a strong rock; a strong fortress or town."
                },
                {
                    "spelling_detail_id": 139233,
                    "word_type": "superl.",
                    "spelling_usage": "Having great military or naval force; powerful; as, a\n   strong army or fleet; a nation strong at sea."
                },
                {
                    "spelling_detail_id": 139234,
                    "word_type": "superl.",
                    "spelling_usage": "Having great wealth, means, or resources; as, a strong\n   house, or company of merchants."
                },
                {
                    "spelling_detail_id": 139235,
                    "word_type": "superl.",
                    "spelling_usage": "Reaching a certain degree or limit in respect to\n   strength or numbers; as, an army ten thousand strong."
                },
                {
                    "spelling_detail_id": 139236,
                    "word_type": "superl.",
                    "spelling_usage": "Moving with rapidity or force; violent; forcible;\n   impetuous; as, a strong current of water or wind; the wind was strong\n   from the northeast; a strong tide."
                },
                {
                    "spelling_detail_id": 139237,
                    "word_type": "superl.",
                    "spelling_usage": "Adapted to make a deep or effectual impression on the\n   mind or imagination; striking or superior of the kind; powerful;\n   forcible; cogent; as, a strong argument; strong reasons; strong\n   evidence; a strong example; strong language."
                },
                {
                    "spelling_detail_id": 139238,
                    "word_type": "superl.",
                    "spelling_usage": "Ardent; eager; zealous; earnestly engaged; as, a\n   strong partisan; a strong Whig or Tory."
                },
                {
                    "spelling_detail_id": 139239,
                    "word_type": "superl.",
                    "spelling_usage": "Having virtues of great efficacy; or, having a\n   particular quality in a great degree; as, a strong powder or tincture;\n   a strong decoction; strong tea or coffee."
                },
                {
                    "spelling_detail_id": 139240,
                    "word_type": "superl.",
                    "spelling_usage": "Full of spirit; containing a large proportion of\n   alcohol; intoxicating; as, strong liquors."
                },
                {
                    "spelling_detail_id": 139241,
                    "word_type": "superl.",
                    "spelling_usage": "Affecting any sense powerfully; as, strong light,\n   colors, etc.; a strong flavor of onions; a strong scent."
                },
                {
                    "spelling_detail_id": 139242,
                    "word_type": "superl.",
                    "spelling_usage": "Solid; nourishing; as, strong meat."
                },
                {
                    "spelling_detail_id": 139243,
                    "word_type": "superl.",
                    "spelling_usage": "Well established; firm; not easily overthrown or\n   altered; as, a strong custom; a strong belief."
                },
                {
                    "spelling_detail_id": 139244,
                    "word_type": "superl.",
                    "spelling_usage": "Violent; vehement; earnest; ardent."
                },
                {
                    "spelling_detail_id": 139245,
                    "word_type": "superl.",
                    "spelling_usage": "Having great force, vigor, power, or the like, as the\n   mind, intellect, or any faculty; as, a man of a strong mind, memory,\n   judgment, or imagination."
                },
                {
                    "spelling_detail_id": 139246,
                    "word_type": "superl.",
                    "spelling_usage": "Vigorous; effective; forcible; powerful."
                },
                {
                    "spelling_detail_id": 139247,
                    "word_type": "superl.",
                    "spelling_usage": "Tending to higher prices; rising; as, a strong market."
                },
                {
                    "spelling_detail_id": 139248,
                    "word_type": "superl.",
                    "spelling_usage": "Pertaining to, or designating, a verb which forms its\n   preterit (imperfect) by a variation in the root vowel, and the past\n   participle (usually) by the addition of -en (with or without a change\n   of the root vowel); as in the verbs strive, strove, striven; break,\n   broke, broken; drink, drank, drunk. Opposed to weak, or regular. See\n   Weak."
                },
                {
                    "spelling_detail_id": 139249,
                    "word_type": "superl.",
                    "spelling_usage": "Applied to forms in Anglo-Saxon, etc., which retain\n   the old declensional endings. In the Teutonic languages the vowel stems\n   have held the original endings most firmly, and are called strong; the\n   stems in -n are called weak other constant stems conform, or are\n   irregular."
                }
            ]
        },
        {
            "id": 87942,
            "spelling_name": "Stubble",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 139379,
                    "word_type": "n.",
                    "spelling_usage": "The stumps of wheat, rye, barley, oats, or buckwheat, left\n   in the ground; the part of the stalk left by the scythe or sickle."
                }
            ]
        },
        {
            "id": 87972,
            "spelling_name": "Studio",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 139428,
                    "word_type": "n.",
                    "spelling_usage": "The working room of an artist."
                }
            ]
        },
        {
            "id": 87978,
            "spelling_name": "Stuff",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 139451,
                    "word_type": "v. t.",
                    "spelling_usage": "Material which is to be worked up in any process of\n   manufacture."
                },
                {
                    "spelling_detail_id": 139452,
                    "word_type": "v. t.",
                    "spelling_usage": "The fundamental material of which anything is made up;\n   elemental part; essence."
                },
                {
                    "spelling_detail_id": 139453,
                    "word_type": "v. t.",
                    "spelling_usage": "Woven material not made into garments; fabric of any\n   kind; specifically, any one of various fabrics of wool or worsted;\n   sometimes, worsted fiber."
                },
                {
                    "spelling_detail_id": 139454,
                    "word_type": "v. t.",
                    "spelling_usage": "Furniture; goods; domestic vessels or utensils."
                },
                {
                    "spelling_detail_id": 139455,
                    "word_type": "v. t.",
                    "spelling_usage": "A medicine or mixture; a potion."
                },
                {
                    "spelling_detail_id": 139456,
                    "word_type": "v. t.",
                    "spelling_usage": "Refuse or worthless matter; hence, also, foolish or\n   irrational language; nonsense; trash."
                },
                {
                    "spelling_detail_id": 139457,
                    "word_type": "v. t.",
                    "spelling_usage": "A melted mass of turpentine, tallow, etc., with which the\n   masts, sides, and bottom of a ship are smeared for lubrication."
                },
                {
                    "spelling_detail_id": 139458,
                    "word_type": "v. t.",
                    "spelling_usage": "Paper stock ground ready for use."
                },
                {
                    "spelling_detail_id": 139461,
                    "word_type": "n.",
                    "spelling_usage": "To fill by crowding something into; to cram with something;\n   to load to excess; as, to stuff a bedtick."
                },
                {
                    "spelling_detail_id": 139462,
                    "word_type": "n.",
                    "spelling_usage": "To thrust or crowd; to press; to pack."
                },
                {
                    "spelling_detail_id": 139463,
                    "word_type": "n.",
                    "spelling_usage": "To fill by being pressed or packed into."
                },
                {
                    "spelling_detail_id": 139464,
                    "word_type": "n.",
                    "spelling_usage": "To fill with a seasoning composition of bread, meat,\n   condiments, etc.; as, to stuff a turkey."
                },
                {
                    "spelling_detail_id": 139465,
                    "word_type": "n.",
                    "spelling_usage": "To obstruct, as any of the organs; to affect with some\n   obstruction in the organs of sense or respiration."
                },
                {
                    "spelling_detail_id": 139466,
                    "word_type": "n.",
                    "spelling_usage": "To fill the skin of, for the purpose of preserving as a\n   specimen; -- said of birds or other animals."
                },
                {
                    "spelling_detail_id": 139467,
                    "word_type": "n.",
                    "spelling_usage": "To form or fashion by packing with the necessary material."
                },
                {
                    "spelling_detail_id": 139468,
                    "word_type": "n.",
                    "spelling_usage": "To crowd with facts; to cram the mind of; sometimes, to\n   crowd or fill with false or idle tales or fancies."
                },
                {
                    "spelling_detail_id": 139469,
                    "word_type": "n.",
                    "spelling_usage": "To put fraudulent votes into (a ballot box)."
                },
                {
                    "spelling_detail_id": 139470,
                    "word_type": "v. i.",
                    "spelling_usage": "To feed gluttonously; to cram."
                }
            ]
        },
        {
            "id": 88051,
            "spelling_name": "Sturdy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 139591,
                    "word_type": "superl.",
                    "spelling_usage": "Foolishly obstinate or resolute; stubborn;\n   unrelenting; unfeeling; stern."
                },
                {
                    "spelling_detail_id": 139592,
                    "word_type": "superl.",
                    "spelling_usage": "Resolute, in a good sense; or firm, unyielding\n   quality; as, a man of sturdy piety or patriotism."
                },
                {
                    "spelling_detail_id": 139593,
                    "word_type": "superl.",
                    "spelling_usage": "Characterized by physical strength or force; strong;\n   lusty; violent; as, a sturdy lout."
                },
                {
                    "spelling_detail_id": 139594,
                    "word_type": "superl.",
                    "spelling_usage": "Stiff; stout; strong; as, a sturdy oak."
                },
                {
                    "spelling_detail_id": 139595,
                    "word_type": "n.",
                    "spelling_usage": "A disease in sheep and cattle, marked by great nervousness,\n   or by dullness and stupor."
                }
            ]
        },
        {
            "id": 88831,
            "spelling_name": "Sudden",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 140698,
                    "word_type": "a.",
                    "spelling_usage": "Happening without previous notice or with very brief\n   notice; coming unexpectedly, or without the common preparation;\n   immediate; instant; speedy."
                },
                {
                    "spelling_detail_id": 140699,
                    "word_type": "a.",
                    "spelling_usage": "Hastly prepared or employed; quick; rapid."
                },
                {
                    "spelling_detail_id": 140700,
                    "word_type": "a.",
                    "spelling_usage": "Hasty; violent; rash; precipitate."
                },
                {
                    "spelling_detail_id": 140701,
                    "word_type": "adv.",
                    "spelling_usage": "Suddenly; unexpectedly."
                },
                {
                    "spelling_detail_id": 140702,
                    "word_type": "n.",
                    "spelling_usage": "An unexpected occurrence; a surprise."
                }
            ]
        },
        {
            "id": 88848,
            "spelling_name": "Suffer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 140731,
                    "word_type": "v. t.",
                    "spelling_usage": "To feel, or endure, with pain, annoyance, etc.; to\n   submit to with distress or grief; to undergo; as, to suffer pain of\n   body, or grief of mind."
                },
                {
                    "spelling_detail_id": 140732,
                    "word_type": "v. t.",
                    "spelling_usage": "To endure or undergo without sinking; to support; to\n   sustain; to bear up under."
                },
                {
                    "spelling_detail_id": 140733,
                    "word_type": "v. t.",
                    "spelling_usage": "To undergo; to be affected by; to sustain; to\n   experience; as, most substances suffer a change when long exposed to\n   air and moisture; to suffer loss or damage."
                },
                {
                    "spelling_detail_id": 140734,
                    "word_type": "v. t.",
                    "spelling_usage": "To allow; to permit; not to forbid or hinder; to\n   tolerate."
                },
                {
                    "spelling_detail_id": 140735,
                    "word_type": "v. i.",
                    "spelling_usage": "To feel or undergo pain of body or mind; to bear what is\n   inconvenient; as, we suffer from pain, sickness, or sorrow; we suffer\n   with anxiety."
                },
                {
                    "spelling_detail_id": 140736,
                    "word_type": "v. i.",
                    "spelling_usage": "To undergo punishment; specifically, to undergo the\n   penalty of death."
                },
                {
                    "spelling_detail_id": 140737,
                    "word_type": "v. i.",
                    "spelling_usage": "To be injured; to sustain loss or damage."
                }
            ]
        },
        {
            "id": 89069,
            "spelling_name": "Summer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 141067,
                    "word_type": "v.",
                    "spelling_usage": "One who sums; one who casts up an account."
                },
                {
                    "spelling_detail_id": 141068,
                    "word_type": "n.",
                    "spelling_usage": "A large stone or beam placed horizontally on columns,\n   piers, posts, or the like, serving for various uses. Specifically: (a)\n   The lintel of a door or window. (b) The commencement of a cross vault.\n   (c) A central floor timber, as a girder, or a piece reaching from a\n   wall to a girder. Called also summertree."
                },
                {
                    "spelling_detail_id": 141069,
                    "word_type": "n.",
                    "spelling_usage": "The season of the year in which the sun shines most\n   directly upon any region; the warmest period of the year."
                },
                {
                    "spelling_detail_id": 141072,
                    "word_type": "v. i.",
                    "spelling_usage": "To pass the summer; to spend the warm season; as, to\n   summer in Switzerland."
                },
                {
                    "spelling_detail_id": 141073,
                    "word_type": "v. t.",
                    "spelling_usage": "To keep or carry through the summer; to feed during the\n   summer; as, to summer stock."
                }
            ]
        },
        {
            "id": 89133,
            "spelling_name": "Sunflower",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 141168,
                    "word_type": "n.",
                    "spelling_usage": "Any plant of the genus Helianthus; -- so called probably\n   from the form and color of its flower, which is large disk with yellow\n   rays. The commonly cultivated sunflower is Helianthus annuus, a native\n   of America."
                }
            ]
        },
        {
            "id": 89427,
            "spelling_name": "Supper",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 141540,
                    "word_type": "n.",
                    "spelling_usage": "A meal taken at the close of the day; the evening meal."
                },
                {
                    "spelling_detail_id": 141541,
                    "word_type": "v. i.",
                    "spelling_usage": "To take supper; to sup."
                },
                {
                    "spelling_detail_id": 141542,
                    "word_type": "v. t.",
                    "spelling_usage": "To supply with supper."
                }
            ]
        },
        {
            "id": 89974,
            "spelling_name": "Sweat",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 142478,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Sweat"
                },
                {
                    "spelling_detail_id": 142482,
                    "word_type": "v. i.",
                    "spelling_usage": "To excrete sensible moisture from the pores of the skin;\n   to perspire."
                },
                {
                    "spelling_detail_id": 142483,
                    "word_type": "v. i.",
                    "spelling_usage": "Fig.: To perspire in toil; to work hard; to drudge."
                },
                {
                    "spelling_detail_id": 142484,
                    "word_type": "v. i.",
                    "spelling_usage": "To emit moisture, as green plants in a heap."
                },
                {
                    "spelling_detail_id": 142485,
                    "word_type": "v. t.",
                    "spelling_usage": "To cause to excrete moisture from the skin; to cause to\n   perspire; as, his physicians attempted to sweat him by most powerful\n   sudorifics."
                },
                {
                    "spelling_detail_id": 142486,
                    "word_type": "v. t.",
                    "spelling_usage": "To emit or suffer to flow from the pores; to exude."
                },
                {
                    "spelling_detail_id": 142487,
                    "word_type": "v. t.",
                    "spelling_usage": "To unite by heating, after the application of soldier."
                },
                {
                    "spelling_detail_id": 142488,
                    "word_type": "v. t.",
                    "spelling_usage": "To get something advantageous, as money, property, or\n   labor from (any one), by exaction or oppression; as, to sweat a\n   spendthrift; to sweat laborers."
                },
                {
                    "spelling_detail_id": 142489,
                    "word_type": "v. i.",
                    "spelling_usage": "The fluid which is excreted from the skin of an animal;\n   the fluid secreted by the sudoriferous glands; a transparent,\n   colorless, acid liquid with a peculiar odor, containing some fatty\n   acids and mineral matter; perspiration. See Perspiration."
                },
                {
                    "spelling_detail_id": 142490,
                    "word_type": "v. i.",
                    "spelling_usage": "The act of sweating; or the state of one who sweats;\n   hence, labor; toil; drudgery."
                },
                {
                    "spelling_detail_id": 142491,
                    "word_type": "v. i.",
                    "spelling_usage": "Moisture issuing from any substance; as, the sweat of hay\n   or grain in a mow or stack."
                },
                {
                    "spelling_detail_id": 142492,
                    "word_type": "v. i.",
                    "spelling_usage": "The sweating sickness."
                },
                {
                    "spelling_detail_id": 142493,
                    "word_type": "v. i.",
                    "spelling_usage": "A short run by a race horse in exercise."
                }
            ]
        },
        {
            "id": 90030,
            "spelling_name": "Sweltering",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 142647,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Swelter"
                }
            ]
        },
        {
            "id": 90099,
            "spelling_name": "Swipe",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 142806,
                    "word_type": "n.",
                    "spelling_usage": "A swape or sweep. See Sweep."
                },
                {
                    "spelling_detail_id": 142807,
                    "word_type": "n.",
                    "spelling_usage": "A strong blow given with a sweeping motion, as with a bat or\n   club."
                },
                {
                    "spelling_detail_id": 142808,
                    "word_type": "n.",
                    "spelling_usage": "Poor, weak beer; small beer."
                },
                {
                    "spelling_detail_id": 142811,
                    "word_type": "v. t.",
                    "spelling_usage": "To give a swipe to; to strike forcibly with a sweeping\n   motion, as a ball."
                },
                {
                    "spelling_detail_id": 142812,
                    "word_type": "v. t.",
                    "spelling_usage": "To pluck; to snatch; to steal."
                }
            ]
        },
        {
            "id": 90105,
            "spelling_name": "Swirled",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 142815,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Swirl"
                }
            ]
        },
        {
            "id": 90137,
            "spelling_name": "Sword",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 142874,
                    "word_type": "n.",
                    "spelling_usage": "An offensive weapon, having a long and usually sharp/pointed\n   blade with a cutting edge or edges. It is the general term, including\n   the small sword, rapier, saber, scimiter, and many other varieties."
                },
                {
                    "spelling_detail_id": 142875,
                    "word_type": "n.",
                    "spelling_usage": "Hence, the emblem of judicial vengeance or punishment, or of\n   authority and power."
                },
                {
                    "spelling_detail_id": 142876,
                    "word_type": "n.",
                    "spelling_usage": "Destruction by the sword, or in battle; war; dissension."
                },
                {
                    "spelling_detail_id": 142877,
                    "word_type": "n.",
                    "spelling_usage": "The military power of a country."
                },
                {
                    "spelling_detail_id": 142878,
                    "word_type": "n.",
                    "spelling_usage": "One of the end bars by which the lay of a hand loom is\n   suspended."
                }
            ]
        },
        {
            "id": 90486,
            "spelling_name": "Synthetic",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 143348,
                    "word_type": "a.",
                    "spelling_usage": "Alt. of Synthetical"
                }
            ]
        },
        {
            "id": 90536,
            "spelling_name": "System",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 143407,
                    "word_type": "n.",
                    "spelling_usage": "An assemblage of objects arranged in regular subordination,\n   or after some distinct method, usually logical or scientific; a\n   complete whole of objects related by some common law, principle, or\n   end; a complete exhibition of essential principles or facts, arranged\n   in a rational dependence or connection; a regular union of principles\n   or parts forming one entire thing; as, a system of philosophy; a system\n   of government; a system of divinity; a system of botany or chemistry; a\n   military system; the solar system."
                },
                {
                    "spelling_detail_id": 143408,
                    "word_type": "n.",
                    "spelling_usage": "Hence, the whole scheme of created things regarded as\n   forming one complete plan of whole; the universe."
                },
                {
                    "spelling_detail_id": 143409,
                    "word_type": "n.",
                    "spelling_usage": "Regular method or order; formal arrangement; plan; as, to\n   have a system in one's business."
                },
                {
                    "spelling_detail_id": 143410,
                    "word_type": "n.",
                    "spelling_usage": "The collection of staves which form a full score. See\n   Score, n."
                },
                {
                    "spelling_detail_id": 143411,
                    "word_type": "n.",
                    "spelling_usage": "An assemblage of parts or organs, either in animal or\n   plant, essential to the performance of some particular function or\n   functions which as a rule are of greater complexity than those\n   manifested by a single organ; as, the capillary system, the muscular\n   system, the digestive system, etc.; hence, the whole body as a\n   functional unity."
                },
                {
                    "spelling_detail_id": 143412,
                    "word_type": "n.",
                    "spelling_usage": "One of the stellate or irregular clusters of intimately\n   united zooids which are imbedded in, or scattered over, the surface of\n   the common tissue of many compound ascidians."
                }
            ]
        },
        {
            "id": 90793,
            "spelling_name": "Talent",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 143892,
                    "word_type": "v. t.",
                    "spelling_usage": "Among the ancient Greeks, a weight and a denomination of\n   money equal to 60 minae or 6,000 drachmae. The Attic talent, as a\n   weight, was about 57 lbs. avoirdupois; as a denomination of silver\n   money, its value was £243 15s. sterling, or about $1,180."
                },
                {
                    "spelling_detail_id": 143893,
                    "word_type": "v. t.",
                    "spelling_usage": "Among the Hebrews, a weight and denomination of money.\n   For silver it was equivalent to 3,000 shekels, and in weight was equal\n   to about 93/ lbs. avoirdupois; as a denomination of silver, it has been\n   variously estimated at from £340 to £396 sterling, or about $1,645 to\n   $1,916. For gold it was equal to 10,000 gold shekels."
                },
                {
                    "spelling_detail_id": 143894,
                    "word_type": "v. t.",
                    "spelling_usage": "Inclination; will; disposition; desire."
                },
                {
                    "spelling_detail_id": 143895,
                    "word_type": "v. t.",
                    "spelling_usage": "Intellectual ability, natural or acquired; mental\n   endowment or capacity; skill in accomplishing; a special gift,\n   particularly in business, art, or the like; faculty; a use of the word\n   probably originating in the Scripture parable of the talents (Matt.\n   xxv. 14-30)."
                }
            ]
        },
        {
            "id": 90814,
            "spelling_name": "Talking",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 143914,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Talk"
                },
                {
                    "spelling_detail_id": 143928,
                    "word_type": "a.",
                    "spelling_usage": "That talks; able to utter words; as, a talking parrot."
                },
                {
                    "spelling_detail_id": 143929,
                    "word_type": "a.",
                    "spelling_usage": "Given to talk; loquacious."
                }
            ]
        },
        {
            "id": 90815,
            "spelling_name": "Tall",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 143930,
                    "word_type": "superl.",
                    "spelling_usage": "High in stature; having a considerable, or an unusual,\n   extension upward; long and comparatively slender; having the diameter\n   or lateral extent small in proportion to the height; as, a tall person,\n   tree, or mast."
                },
                {
                    "spelling_detail_id": 143931,
                    "word_type": "superl.",
                    "spelling_usage": "Brave; bold; courageous."
                },
                {
                    "spelling_detail_id": 143932,
                    "word_type": "superl.",
                    "spelling_usage": "Fine; splendid; excellent; also, extravagant; excessive."
                }
            ]
        },
        {
            "id": 90921,
            "spelling_name": "Tangible",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144112,
                    "word_type": "a.",
                    "spelling_usage": "Perceptible to the touch; tactile; palpable."
                },
                {
                    "spelling_detail_id": 144113,
                    "word_type": "a.",
                    "spelling_usage": "Capable of being possessed or realized; readily\n   apprehensible by the mind; real; substantial; evident."
                }
            ]
        },
        {
            "id": 90971,
            "spelling_name": "Tantrum",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144174,
                    "word_type": "n.",
                    "spelling_usage": "A whim, or burst of ill-humor; an affected air."
                }
            ]
        },
        {
            "id": 91045,
            "spelling_name": "Target",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144291,
                    "word_type": "n.",
                    "spelling_usage": "A kind of small shield or buckler, used as a defensive\n   weapon in war."
                },
                {
                    "spelling_detail_id": 144292,
                    "word_type": "n.",
                    "spelling_usage": "A butt or mark to shoot at, as for practice, or to test the\n   accuracy of a firearm, or the force of a projectile."
                },
                {
                    "spelling_detail_id": 144293,
                    "word_type": "n.",
                    "spelling_usage": "The pattern or arrangement of a series of hits made by a\n   marksman on a butt or mark; as, he made a good target."
                },
                {
                    "spelling_detail_id": 144294,
                    "word_type": "n.",
                    "spelling_usage": "The sliding crosspiece, or vane, on a leveling staff."
                },
                {
                    "spelling_detail_id": 144295,
                    "word_type": "n.",
                    "spelling_usage": "A conspicuous disk attached to a switch lever to show its\n   position, or for use as a signal."
                }
            ]
        },
        {
            "id": 91161,
            "spelling_name": "Tasteless",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144480,
                    "word_type": "a.",
                    "spelling_usage": "Having no taste; insipid; flat; as, tasteless fruit."
                },
                {
                    "spelling_detail_id": 144481,
                    "word_type": "a.",
                    "spelling_usage": "Destitute of the sense of taste; or of good taste; as, a\n   tasteless age."
                },
                {
                    "spelling_detail_id": 144482,
                    "word_type": "a.",
                    "spelling_usage": "Not in accordance with good taste; as, a tasteless\n   arrangement of drapery."
                }
            ]
        },
        {
            "id": 91247,
            "spelling_name": "Tawny",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144599,
                    "word_type": "n.",
                    "spelling_usage": "Of a dull yellowish brown color, like things tanned, or\n   persons who are sunburnt; as, tawny Moor or Spaniard; the tawny lion."
                }
            ]
        },
        {
            "id": 91327,
            "spelling_name": "Teaspoon",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144734,
                    "word_type": "n.",
                    "spelling_usage": "A small spoon used in stirring and sipping tea, coffee,\n   etc., and for other purposes."
                }
            ]
        },
        {
            "id": 91386,
            "spelling_name": "Teemed",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 144806,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Teem"
                }
            ]
        },
        {
            "id": 91674,
            "spelling_name": "Tennis",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 145251,
                    "word_type": "n.",
                    "spelling_usage": "A play in which a ball is driven to and fro, or kept in\n   motion by striking it with a racket or with the open hand."
                },
                {
                    "spelling_detail_id": 145252,
                    "word_type": "v. t.",
                    "spelling_usage": "To drive backward and forward, as a ball in playing\n   tennis."
                }
            ]
        },
        {
            "id": 92235,
            "spelling_name": "Thanks",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 146054,
                    "word_type": "pl. ",
                    "spelling_usage": "of Thank"
                }
            ]
        },
        {
            "id": 92261,
            "spelling_name": "Thawed",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 146103,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Thaw"
                }
            ]
        },
        {
            "id": 92275,
            "spelling_name": "Theater",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 146124,
                    "word_type": "n.",
                    "spelling_usage": "Alt. of Theatre"
                }
            ]
        },
        {
            "id": 92317,
            "spelling_name": "Theme",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 146181,
                    "word_type": "n.",
                    "spelling_usage": "A subject or topic on which a person writes or speaks; a\n   proposition for discussion or argument; a text."
                },
                {
                    "spelling_detail_id": 146182,
                    "word_type": "n.",
                    "spelling_usage": "Discourse on a certain subject."
                },
                {
                    "spelling_detail_id": 146183,
                    "word_type": "n.",
                    "spelling_usage": "A composition or essay required of a pupil."
                },
                {
                    "spelling_detail_id": 146184,
                    "word_type": "n.",
                    "spelling_usage": "A noun or verb, not modified by inflections; also, that part\n   of a noun or verb which remains unchanged (except by euphonic\n   variations) in declension or conjugation; stem."
                },
                {
                    "spelling_detail_id": 146185,
                    "word_type": "n.",
                    "spelling_usage": "That by means of which a thing is done; means; instrument."
                },
                {
                    "spelling_detail_id": 146186,
                    "word_type": "n.",
                    "spelling_usage": "The leading subject of a composition or a movement."
                }
            ]
        },
        {
            "id": 92542,
            "spelling_name": "Thicket",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 146484,
                    "word_type": "a.",
                    "spelling_usage": "A wood or a collection of trees, shrubs, etc., closely\n   set; as, a ram caught in a thicket."
                }
            ]
        },
        {
            "id": 92634,
            "spelling_name": "Thistle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 146648,
                    "word_type": "n.",
                    "spelling_usage": "Any one of several prickly composite plants, especially\n   those of the genera Cnicus, Craduus, and Onopordon. The name is often\n   also applied to other prickly plants."
                }
            ]
        },
        {
            "id": 92913,
            "spelling_name": "Thunderbolt",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 147168,
                    "word_type": "n.",
                    "spelling_usage": "A shaft of lightning; a brilliant stream of\n   electricity passing from one part of the heavens to another, or from\n   the clouds to the earth."
                },
                {
                    "spelling_detail_id": 147169,
                    "word_type": "n.",
                    "spelling_usage": "Something resembling lightning in suddenness and\n   effectiveness."
                },
                {
                    "spelling_detail_id": 147170,
                    "word_type": "n.",
                    "spelling_usage": "Vehement threatening or censure; especially,\n   ecclesiastical denunciation; fulmination."
                },
                {
                    "spelling_detail_id": 147171,
                    "word_type": "n.",
                    "spelling_usage": "A belemnite, or thunderstone."
                }
            ]
        },
        {
            "id": 93000,
            "spelling_name": "Tibia",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 147289,
                    "word_type": "n.",
                    "spelling_usage": "The inner, or preaxial, and usually the larger, of the two\n   bones of the leg or hind limb below the knee."
                },
                {
                    "spelling_detail_id": 147290,
                    "word_type": "n.",
                    "spelling_usage": "The fourth joint of the leg of an insect. See Illust. under\n   Coleoptera, and under Hexapoda."
                },
                {
                    "spelling_detail_id": 147291,
                    "word_type": "n.",
                    "spelling_usage": "A musical instrument of the flute kind, originally made of\n   the leg bone of an animal."
                }
            ]
        },
        {
            "id": 93026,
            "spelling_name": "Tickled",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 147341,
                    "word_type": "imp. & p. p.",
                    "spelling_usage": "of Tickle"
                }
            ]
        },
        {
            "id": 93086,
            "spelling_name": "Tiger",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 147461,
                    "word_type": "n.",
                    "spelling_usage": "A very large and powerful carnivore (Felis tigris) native of\n   Southern Asia and the East Indies. Its back and sides are tawny or\n   rufous yellow, transversely striped with black, the tail is ringed with\n   black, the throat and belly are nearly white. When full grown, it\n   equals or exceeds the lion in size and strength. Called also royal\n   tiger, and Bengal tiger."
                },
                {
                    "spelling_detail_id": 147462,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: A ferocious, bloodthirsty person."
                },
                {
                    "spelling_detail_id": 147463,
                    "word_type": "n.",
                    "spelling_usage": "A servant in livery, who rides with his master or mistress."
                },
                {
                    "spelling_detail_id": 147464,
                    "word_type": "n.",
                    "spelling_usage": "A kind of growl or screech, after cheering; as, three cheers\n   and a tiger."
                },
                {
                    "spelling_detail_id": 147465,
                    "word_type": "n.",
                    "spelling_usage": "A pneumatic box or pan used in refining sugar."
                }
            ]
        },
        {
            "id": 93156,
            "spelling_name": "Timber",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 147595,
                    "word_type": "n.",
                    "spelling_usage": "A certain quantity of fur skins, as of martens, ermines,\n   sables, etc., packed between boards; being in some cases forty skins,\n   in others one hundred and twenty; -- called also timmer."
                },
                {
                    "spelling_detail_id": 147596,
                    "word_type": "n.",
                    "spelling_usage": "The crest on a coat of arms."
                },
                {
                    "spelling_detail_id": 147597,
                    "word_type": "v. t.",
                    "spelling_usage": "To surmount as a timber does."
                },
                {
                    "spelling_detail_id": 147598,
                    "word_type": "n.",
                    "spelling_usage": "That sort of wood which is proper for buildings or for\n   tools, utensils, furniture, carriages, fences, ships, and the like; --\n   usually said of felled trees, but sometimes of those standing. Cf.\n   Lumber, 3."
                },
                {
                    "spelling_detail_id": 147599,
                    "word_type": "n.",
                    "spelling_usage": "The body, stem, or trunk of a tree."
                },
                {
                    "spelling_detail_id": 147600,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: Material for any structure."
                },
                {
                    "spelling_detail_id": 147601,
                    "word_type": "n.",
                    "spelling_usage": "A single piece or squared stick of wood intended for\n   building, or already framed; collectively, the larger pieces or sticks\n   of wood, forming the framework of a house, ship, or other structure, in\n   distinction from the covering or boarding."
                },
                {
                    "spelling_detail_id": 147602,
                    "word_type": "n.",
                    "spelling_usage": "Woods or forest; wooden land."
                },
                {
                    "spelling_detail_id": 147603,
                    "word_type": "n.",
                    "spelling_usage": "A rib, or a curving piece of wood, branching outward from\n   the keel and bending upward in a vertical direction. One timber is\n   composed of several pieces united."
                },
                {
                    "spelling_detail_id": 147606,
                    "word_type": "v. t.",
                    "spelling_usage": "To furnish with timber; -- chiefly used in the past\n   participle."
                },
                {
                    "spelling_detail_id": 147607,
                    "word_type": "v. i.",
                    "spelling_usage": "To light on a tree."
                },
                {
                    "spelling_detail_id": 147608,
                    "word_type": "v. i.",
                    "spelling_usage": "To make a nest."
                }
            ]
        },
        {
            "id": 93455,
            "spelling_name": "Toddler",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 148109,
                    "word_type": "n.",
                    "spelling_usage": "One who toddles; especially, a young child."
                }
            ]
        },
        {
            "id": 93508,
            "spelling_name": "Tolerable",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 148197,
                    "word_type": "a.",
                    "spelling_usage": "Capable of being borne or endured; supportable, either\n   physically or mentally."
                },
                {
                    "spelling_detail_id": 148198,
                    "word_type": "a.",
                    "spelling_usage": "Moderately good or agreeable; not contemptible; not very\n   excellent or pleasing, but such as can be borne or received without\n   disgust, resentment, or opposition; passable; as, a tolerable\n   administration; a tolerable entertainment; a tolerable translation."
                }
            ]
        },
        {
            "id": 93603,
            "spelling_name": "Tongue",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 148346,
                    "word_type": "n.",
                    "spelling_usage": "an organ situated in the floor of the mouth of most\n   vertebrates and connected with the hyoid arch."
                },
                {
                    "spelling_detail_id": 148347,
                    "word_type": "n.",
                    "spelling_usage": "The power of articulate utterance; speech."
                },
                {
                    "spelling_detail_id": 148348,
                    "word_type": "n.",
                    "spelling_usage": "Discourse; fluency of speech or expression."
                },
                {
                    "spelling_detail_id": 148349,
                    "word_type": "n.",
                    "spelling_usage": "Honorable discourse; eulogy."
                },
                {
                    "spelling_detail_id": 148350,
                    "word_type": "n.",
                    "spelling_usage": "A language; the whole sum of words used by a particular\n   nation; as, the English tongue."
                },
                {
                    "spelling_detail_id": 148351,
                    "word_type": "n.",
                    "spelling_usage": "Speech; words or declarations only; -- opposed to thoughts\n   or actions."
                },
                {
                    "spelling_detail_id": 148352,
                    "word_type": "n.",
                    "spelling_usage": "A people having a distinct language."
                },
                {
                    "spelling_detail_id": 148353,
                    "word_type": "n.",
                    "spelling_usage": "The lingual ribbon, or odontophore, of a mollusk."
                },
                {
                    "spelling_detail_id": 148354,
                    "word_type": "n.",
                    "spelling_usage": "The proboscis of a moth or a butterfly."
                },
                {
                    "spelling_detail_id": 148355,
                    "word_type": "n.",
                    "spelling_usage": "The lingua of an insect."
                },
                {
                    "spelling_detail_id": 148356,
                    "word_type": "n.",
                    "spelling_usage": "Any small sole."
                },
                {
                    "spelling_detail_id": 148357,
                    "word_type": "n.",
                    "spelling_usage": "That which is considered as resembing an animal's tongue,\n   in position or form."
                },
                {
                    "spelling_detail_id": 148358,
                    "word_type": "n.",
                    "spelling_usage": "A projection, or slender appendage or fixture; as, the\n   tongue of a buckle, or of a balance."
                },
                {
                    "spelling_detail_id": 148359,
                    "word_type": "n.",
                    "spelling_usage": "A projection on the side, as of a board, which fits into a\n   groove."
                },
                {
                    "spelling_detail_id": 148360,
                    "word_type": "n.",
                    "spelling_usage": "A point, or long, narrow strip of land, projecting from the\n   mainland into a sea or a lake."
                },
                {
                    "spelling_detail_id": 148361,
                    "word_type": "n.",
                    "spelling_usage": "The pole of a vehicle; especially, the pole of an ox cart,\n   to the end of which the oxen are yoked."
                },
                {
                    "spelling_detail_id": 148362,
                    "word_type": "n.",
                    "spelling_usage": "The clapper of a bell."
                },
                {
                    "spelling_detail_id": 148363,
                    "word_type": "n.",
                    "spelling_usage": "A short piece of rope spliced into the upper part of\n   standing backstays, etc.; also. the upper main piece of a mast composed\n   of several pieces."
                },
                {
                    "spelling_detail_id": 148364,
                    "word_type": "n.",
                    "spelling_usage": "Same as Reed, n., 5."
                },
                {
                    "spelling_detail_id": 148367,
                    "word_type": "v. t.",
                    "spelling_usage": "To speak; to utter."
                },
                {
                    "spelling_detail_id": 148368,
                    "word_type": "v. t.",
                    "spelling_usage": "To chide; to scold."
                },
                {
                    "spelling_detail_id": 148369,
                    "word_type": "v. t.",
                    "spelling_usage": "To modulate or modify with the tongue, as notes, in\n   playing the flute and some other wind instruments."
                },
                {
                    "spelling_detail_id": 148370,
                    "word_type": "v. t.",
                    "spelling_usage": "To join means of a tongue and grove; as, to tongue\n   boards together."
                },
                {
                    "spelling_detail_id": 148371,
                    "word_type": "v. i.",
                    "spelling_usage": "To talk; to prate."
                },
                {
                    "spelling_detail_id": 148372,
                    "word_type": "v. i.",
                    "spelling_usage": "To use the tongue in forming the notes, as in playing\n   the flute and some other wind instruments."
                }
            ]
        },
        {
            "id": 93663,
            "spelling_name": "Tooth",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 148465,
                    "word_type": "n.",
                    "spelling_usage": "One of the hard, bony appendages which are borne on the\n   jaws, or on other bones in the walls of the mouth or pharynx of most\n   vertebrates, and which usually aid in the prehension and mastication of\n   food."
                },
                {
                    "spelling_detail_id": 148466,
                    "word_type": "n.",
                    "spelling_usage": "Fig.: Taste; palate."
                },
                {
                    "spelling_detail_id": 148467,
                    "word_type": "n.",
                    "spelling_usage": "Any projection corresponding to the tooth of an animal, in\n   shape, position, or office; as, the teeth, or cogs, of a cogwheel; a\n   tooth, prong, or tine, of a fork; a tooth, or the teeth, of a rake, a\n   saw, a file, a card."
                },
                {
                    "spelling_detail_id": 148468,
                    "word_type": "n.",
                    "spelling_usage": "A projecting member resembling a tenon, but fitting into a\n   mortise that is only sunk, not pierced through."
                },
                {
                    "spelling_detail_id": 148469,
                    "word_type": "n.",
                    "spelling_usage": "One of several steps, or offsets, in a tusk. See Tusk."
                },
                {
                    "spelling_detail_id": 148470,
                    "word_type": "n.",
                    "spelling_usage": "An angular or prominence on any edge; as, a tooth on the\n   scale of a fish, or on a leaf of a plant"
                },
                {
                    "spelling_detail_id": 148471,
                    "word_type": "n.",
                    "spelling_usage": "one of the appendages at the mouth of the capsule of a moss.\n   See Peristome."
                },
                {
                    "spelling_detail_id": 148472,
                    "word_type": "n.",
                    "spelling_usage": "Any hard calcareous or chitinous organ found in the mouth of\n   various invertebrates and used in feeding or procuring food; as, the\n   teeth of a mollusk or a starfish."
                },
                {
                    "spelling_detail_id": 148475,
                    "word_type": "v. t.",
                    "spelling_usage": "To furnish with teeth."
                },
                {
                    "spelling_detail_id": 148476,
                    "word_type": "v. t.",
                    "spelling_usage": "To indent; to jag; as, to tooth a saw."
                },
                {
                    "spelling_detail_id": 148477,
                    "word_type": "v. t.",
                    "spelling_usage": "To lock into each other. See Tooth, n., 4."
                }
            ]
        },
        {
            "id": 93765,
            "spelling_name": "Torch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 148630,
                    "word_type": "n.",
                    "spelling_usage": "A light or luminary formed of some combustible substance, as\n   of resinous wood; a large candle or flambeau, or a lamp giving a large,\n   flaring flame."
                },
                {
                    "spelling_detail_id": 148631,
                    "word_type": "n.",
                    "spelling_usage": "A flashlight."
                }
            ]
        },
        {
            "id": 93956,
            "spelling_name": "Toughness",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 148954,
                    "word_type": "n.",
                    "spelling_usage": "The quality or state of being tough."
                }
            ]
        },
        {
            "id": 93997,
            "spelling_name": "Towel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 149027,
                    "word_type": "n.",
                    "spelling_usage": "A cloth used for wiping, especially one used for drying\n   anything wet, as the person after a bath."
                },
                {
                    "spelling_detail_id": 149028,
                    "word_type": "v. t.",
                    "spelling_usage": "To beat with a stick."
                }
            ]
        },
        {
            "id": 94200,
            "spelling_name": "Train",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 149377,
                    "word_type": "v. t.",
                    "spelling_usage": "To draw along; to trail; to drag."
                },
                {
                    "spelling_detail_id": 149378,
                    "word_type": "v. t.",
                    "spelling_usage": "To draw by persuasion, artifice, or the like; to attract\n   by stratagem; to entice; to allure."
                },
                {
                    "spelling_detail_id": 149379,
                    "word_type": "v. t.",
                    "spelling_usage": "To teach and form by practice; to educate; to exercise;\n   to discipline; as, to train the militia to the manual exercise; to\n   train soldiers to the use of arms."
                },
                {
                    "spelling_detail_id": 149380,
                    "word_type": "v. t.",
                    "spelling_usage": "To break, tame, and accustom to draw, as oxen."
                },
                {
                    "spelling_detail_id": 149381,
                    "word_type": "v. t.",
                    "spelling_usage": "To lead or direct, and form to a wall or espalier; to\n   form to a proper shape, by bending, lopping, or pruning; as, to train\n   young trees."
                },
                {
                    "spelling_detail_id": 149382,
                    "word_type": "v. t.",
                    "spelling_usage": "To trace, as a lode or any mineral appearance, to its\n   head."
                },
                {
                    "spelling_detail_id": 149383,
                    "word_type": "v. i.",
                    "spelling_usage": "To be drilled in military exercises; to do duty in a\n   military company."
                },
                {
                    "spelling_detail_id": 149384,
                    "word_type": "v. i.",
                    "spelling_usage": "To prepare by exercise, diet, instruction, etc., for any\n   physical contest; as, to train for a boat race."
                },
                {
                    "spelling_detail_id": 149385,
                    "word_type": "v.",
                    "spelling_usage": "That which draws along; especially, persuasion, artifice, or\n   enticement; allurement."
                },
                {
                    "spelling_detail_id": 149386,
                    "word_type": "v.",
                    "spelling_usage": "Hence, something tied to a lure to entice a hawk; also, a\n   trap for an animal; a snare."
                },
                {
                    "spelling_detail_id": 149387,
                    "word_type": "v.",
                    "spelling_usage": "That which is drawn along in the rear of, or after,\n   something; that which is in the hinder part or rear."
                },
                {
                    "spelling_detail_id": 149388,
                    "word_type": "v.",
                    "spelling_usage": "That part of a gown which trails behind the wearer."
                },
                {
                    "spelling_detail_id": 149389,
                    "word_type": "v.",
                    "spelling_usage": "The after part of a gun carriage; the trail."
                },
                {
                    "spelling_detail_id": 149390,
                    "word_type": "v.",
                    "spelling_usage": "The tail of a bird."
                },
                {
                    "spelling_detail_id": 149391,
                    "word_type": "v.",
                    "spelling_usage": "A number of followers; a body of attendants; a retinue; a\n   suite."
                },
                {
                    "spelling_detail_id": 149392,
                    "word_type": "v.",
                    "spelling_usage": "A consecution or succession of connected things; a series."
                },
                {
                    "spelling_detail_id": 149393,
                    "word_type": "v.",
                    "spelling_usage": "Regular method; process; course; order; as, things now in a\n   train for settlement."
                },
                {
                    "spelling_detail_id": 149394,
                    "word_type": "v.",
                    "spelling_usage": "The number of beats of a watch in any certain time."
                },
                {
                    "spelling_detail_id": 149395,
                    "word_type": "v.",
                    "spelling_usage": "A line of gunpowder laid to lead fire to a charge, mine, or\n   the like."
                },
                {
                    "spelling_detail_id": 149396,
                    "word_type": "v.",
                    "spelling_usage": "A connected line of cars or carriages on a railroad."
                },
                {
                    "spelling_detail_id": 149397,
                    "word_type": "v.",
                    "spelling_usage": "A heavy, long sleigh used in Canada for the transportation\n   of merchandise, wood, and the like."
                },
                {
                    "spelling_detail_id": 149398,
                    "word_type": "v.",
                    "spelling_usage": "A roll train; as, a 12-inch train."
                }
            ]
        },
        {
            "id": 94571,
            "spelling_name": "Trapezoid",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 149962,
                    "word_type": "n.",
                    "spelling_usage": "A plane four-sided figure, having two sides parallel to\n   each other."
                },
                {
                    "spelling_detail_id": 149963,
                    "word_type": "n.",
                    "spelling_usage": "A bone of the carpus at the base of the second\n   metacarpal, or index finger."
                },
                {
                    "spelling_detail_id": 149964,
                    "word_type": "a.",
                    "spelling_usage": "Having the form of a trapezoid; trapezoidal; as, the\n   trapezoid ligament which connects the coracoid process and the\n   clavicle."
                },
                {
                    "spelling_detail_id": 149965,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the trapezoid ligament; as, the\n   trapezoid line."
                }
            ]
        },
        {
            "id": 94603,
            "spelling_name": "Travel",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 150017,
                    "word_type": "v. i.",
                    "spelling_usage": "To labor; to travail."
                },
                {
                    "spelling_detail_id": 150018,
                    "word_type": "v. i.",
                    "spelling_usage": "To go or march on foot; to walk; as, to travel over the\n   city, or through the streets."
                },
                {
                    "spelling_detail_id": 150019,
                    "word_type": "v. i.",
                    "spelling_usage": "To pass by riding, or in any manner, to a distant place,\n   or to many places; to journey; as, a man travels for his health; he is\n   traveling in California."
                },
                {
                    "spelling_detail_id": 150020,
                    "word_type": "v. i.",
                    "spelling_usage": "To pass; to go; to move."
                },
                {
                    "spelling_detail_id": 150021,
                    "word_type": "v. t.",
                    "spelling_usage": "To journey over; to traverse; as, to travel the\n   continent."
                },
                {
                    "spelling_detail_id": 150022,
                    "word_type": "v. t.",
                    "spelling_usage": "To force to journey."
                },
                {
                    "spelling_detail_id": 150023,
                    "word_type": "n.",
                    "spelling_usage": "The act of traveling, or journeying from place to place; a\n   journey."
                },
                {
                    "spelling_detail_id": 150024,
                    "word_type": "n.",
                    "spelling_usage": "An account, by a traveler, of occurrences and observations\n   during a journey; as, a book of travels; -- often used as the title of\n   a book; as, Travels in Italy."
                },
                {
                    "spelling_detail_id": 150025,
                    "word_type": "n.",
                    "spelling_usage": "The length of stroke of a reciprocating piece; as, the\n   travel of a slide valve."
                },
                {
                    "spelling_detail_id": 150026,
                    "word_type": "n.",
                    "spelling_usage": "Labor; parturition; travail."
                }
            ]
        },
        {
            "id": 94647,
            "spelling_name": "Treadmill",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 150129,
                    "word_type": "n.",
                    "spelling_usage": "A mill worked by persons treading upon steps on the\n   periphery of a wide wheel having a horizontal axis. It is used\n   principally as a means of prison discipline. Also, a mill worked by\n   horses, dogs, etc., treading an endless belt."
                }
            ]
        },
        {
            "id": 94861,
            "spelling_name": "Trice",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 150476,
                    "word_type": "v. t.",
                    "spelling_usage": "To pull; to haul; to drag; to pull away."
                },
                {
                    "spelling_detail_id": 150477,
                    "word_type": "v. t.",
                    "spelling_usage": "To haul and tie up by means of a rope."
                },
                {
                    "spelling_detail_id": 150478,
                    "word_type": "n.",
                    "spelling_usage": "A very short time; an instant; a moment; -- now used only in\n   the phrase in a trice."
                }
            ]
        },
        {
            "id": 95351,
            "spelling_name": "Troll",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151160,
                    "word_type": "n.",
                    "spelling_usage": "A supernatural being, often represented as of diminutive\n   size, but sometimes as a giant, and fabled to inhabit caves, hills, and\n   like places; a witch."
                },
                {
                    "spelling_detail_id": 151163,
                    "word_type": "v. t.",
                    "spelling_usage": "To move circularly or volubly; to roll; to turn."
                },
                {
                    "spelling_detail_id": 151164,
                    "word_type": "v. t.",
                    "spelling_usage": "To send about; to circulate, as a vessel in drinking."
                },
                {
                    "spelling_detail_id": 151165,
                    "word_type": "v. t.",
                    "spelling_usage": "To sing the parts of in succession, as of a round, a\n   catch, and the like; also, to sing loudly or freely."
                },
                {
                    "spelling_detail_id": 151166,
                    "word_type": "v. t.",
                    "spelling_usage": "To angle for with a trolling line, or with a book drawn\n   along the surface of the water; hence, to allure."
                },
                {
                    "spelling_detail_id": 151167,
                    "word_type": "v. t.",
                    "spelling_usage": "To fish in; to seek to catch fish from."
                },
                {
                    "spelling_detail_id": 151168,
                    "word_type": "v. i.",
                    "spelling_usage": "To roll; to run about; to move around; as, to troll in a\n   coach and six."
                },
                {
                    "spelling_detail_id": 151169,
                    "word_type": "v. i.",
                    "spelling_usage": "To move rapidly; to wag."
                },
                {
                    "spelling_detail_id": 151170,
                    "word_type": "v. i.",
                    "spelling_usage": "To take part in trolling a song."
                },
                {
                    "spelling_detail_id": 151171,
                    "word_type": "v. i.",
                    "spelling_usage": "To fish with a rod whose line runs on a reel; also, to\n   fish by drawing the hook through the water."
                },
                {
                    "spelling_detail_id": 151172,
                    "word_type": "n.",
                    "spelling_usage": "The act of moving round; routine; repetition."
                },
                {
                    "spelling_detail_id": 151173,
                    "word_type": "n.",
                    "spelling_usage": "A song the parts of which are sung in succession; a catch; a\n   round."
                },
                {
                    "spelling_detail_id": 151174,
                    "word_type": "n.",
                    "spelling_usage": "A trolley."
                }
            ]
        },
        {
            "id": 95382,
            "spelling_name": "Trope",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151217,
                    "word_type": "n.",
                    "spelling_usage": "The use of a word or expression in a different sense from\n   that which properly belongs to it; the use of a word or expression as\n   changed from the original signification to another, for the sake of\n   giving life or emphasis to an idea; a figure of speech."
                },
                {
                    "spelling_detail_id": 151218,
                    "word_type": "n.",
                    "spelling_usage": "The word or expression so used."
                }
            ]
        },
        {
            "id": 95391,
            "spelling_name": "Trophy",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151227,
                    "word_type": "n.",
                    "spelling_usage": "A sign or memorial of a victory raised on the field of\n   battle, or, in case of a naval victory, on the nearest land. Sometimes\n   trophies were erected in the chief city of the conquered people."
                },
                {
                    "spelling_detail_id": 151228,
                    "word_type": "n.",
                    "spelling_usage": "The representation of such a memorial, as on a medal; esp.\n   (Arch.), an ornament representing a group of arms and military weapons,\n   offensive and defensive."
                },
                {
                    "spelling_detail_id": 151229,
                    "word_type": "n.",
                    "spelling_usage": "Anything taken from an enemy and preserved as a memorial of\n   victory, as arms, flags, standards, etc."
                },
                {
                    "spelling_detail_id": 151230,
                    "word_type": "n.",
                    "spelling_usage": "Any evidence or memorial of victory or conquest; as, every\n   redeemed soul is a trophy of grace."
                }
            ]
        },
        {
            "id": 95508,
            "spelling_name": "Trumpet",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151423,
                    "word_type": "n.",
                    "spelling_usage": "A wind instrument of great antiquity, much used in war and\n   military exercises, and of great value in the orchestra. In consists of\n   a long metallic tube, curved (once or twice) into a convenient shape,\n   and ending in a bell. Its scale in the lower octaves is limited to the\n   first natural harmonics; but there are modern trumpets capable, by\n   means of valves or pistons, of producing every tone within their\n   compass, although at the expense of the true ringing quality of tone."
                },
                {
                    "spelling_detail_id": 151424,
                    "word_type": "n.",
                    "spelling_usage": "A trumpeter."
                },
                {
                    "spelling_detail_id": 151425,
                    "word_type": "n.",
                    "spelling_usage": "One who praises, or propagates praise, or is the\n   instrument of propagating it."
                },
                {
                    "spelling_detail_id": 151426,
                    "word_type": "n.",
                    "spelling_usage": "A funnel, or short, fiaring pipe, used as a guide or\n   conductor, as for yarn in a knitting machine."
                },
                {
                    "spelling_detail_id": 151429,
                    "word_type": "v. t.",
                    "spelling_usage": "To publish by, or as by, sound of trumpet; to noise\n   abroad; to proclaim; as, to trumpet good tidings."
                },
                {
                    "spelling_detail_id": 151430,
                    "word_type": "v. i.",
                    "spelling_usage": "To sound loudly, or with a tone like a trumpet; to\n   utter a trumplike cry."
                }
            ]
        },
        {
            "id": 95536,
            "spelling_name": "Trunk",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151480,
                    "word_type": "n.",
                    "spelling_usage": "The stem, or body, of a tree, apart from its limbs and\n   roots; the main stem, without the branches; stock; stalk."
                },
                {
                    "spelling_detail_id": 151481,
                    "word_type": "n.",
                    "spelling_usage": "The body of an animal, apart from the head and limbs."
                },
                {
                    "spelling_detail_id": 151482,
                    "word_type": "n.",
                    "spelling_usage": "The main body of anything; as, the trunk of a vein or of an\n   artery, as distinct from the branches."
                },
                {
                    "spelling_detail_id": 151483,
                    "word_type": "n.",
                    "spelling_usage": "That part of a pilaster which is between the base and the\n   capital, corresponding to the shaft of a column."
                },
                {
                    "spelling_detail_id": 151484,
                    "word_type": "n.",
                    "spelling_usage": "That segment of the body of an insect which is between the\n   head and abdomen, and bears the wings and legs; the thorax; the\n   truncus."
                },
                {
                    "spelling_detail_id": 151485,
                    "word_type": "n.",
                    "spelling_usage": "The proboscis of an elephant."
                },
                {
                    "spelling_detail_id": 151486,
                    "word_type": "n.",
                    "spelling_usage": "The proboscis of an insect."
                },
                {
                    "spelling_detail_id": 151487,
                    "word_type": "n.",
                    "spelling_usage": "A long tube through which pellets of clay, p/as, etc., are\n   driven by the force of the breath."
                },
                {
                    "spelling_detail_id": 151488,
                    "word_type": "n.",
                    "spelling_usage": "A box or chest usually covered with leather, metal, or\n   cloth, or sometimes made of leather, hide, or metal, for containing\n   clothes or other goods; especially, one used to convey the effects of a\n   traveler."
                },
                {
                    "spelling_detail_id": 151489,
                    "word_type": "n.",
                    "spelling_usage": "A flume or sluice in which ores are separated from the\n   slimes in which they are contained."
                },
                {
                    "spelling_detail_id": 151490,
                    "word_type": "n.",
                    "spelling_usage": "A large pipe forming the piston rod of a steam engine, of\n   sufficient diameter to allow one end of the connecting rod to be\n   attached to the crank, and the other end to pass within the pipe\n   directly to the piston, thus making the engine more compact."
                },
                {
                    "spelling_detail_id": 151491,
                    "word_type": "n.",
                    "spelling_usage": "A long, large box, pipe, or conductor, made of plank or\n   metal plates, for various uses, as for conveying air to a mine or to a\n   furnace, water to a mill, grain to an elevator, etc."
                },
                {
                    "spelling_detail_id": 151492,
                    "word_type": "v. t.",
                    "spelling_usage": "To lop off; to curtail; to truncate; to maim."
                },
                {
                    "spelling_detail_id": 151493,
                    "word_type": "v. t.",
                    "spelling_usage": "To extract (ores) from the slimes in which they are\n   contained, by means of a trunk. See Trunk, n., 9."
                }
            ]
        },
        {
            "id": 95562,
            "spelling_name": "Truth",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151560,
                    "word_type": "n.",
                    "spelling_usage": "The quality or being true; as: -- (a) Conformity to fact or\n   reality; exact accordance with that which is, or has been; or shall be."
                },
                {
                    "spelling_detail_id": 151561,
                    "word_type": "n.",
                    "spelling_usage": "Conformity to rule; exactness; close correspondence with an\n   example, mood, object of imitation, or the like."
                },
                {
                    "spelling_detail_id": 151562,
                    "word_type": "n.",
                    "spelling_usage": "Fidelity; constancy; steadfastness; faithfulness."
                },
                {
                    "spelling_detail_id": 151563,
                    "word_type": "n.",
                    "spelling_usage": "The practice of speaking what is true; freedom from\n   falsehood; veracity."
                },
                {
                    "spelling_detail_id": 151564,
                    "word_type": "n.",
                    "spelling_usage": "That which is true or certain concerning any matter or\n   subject, or generally on all subjects; real state of things; fact;\n   verity; reality."
                },
                {
                    "spelling_detail_id": 151565,
                    "word_type": "n.",
                    "spelling_usage": "A true thing; a verified fact; a true statement or\n   proposition; an established principle, fixed law, or the like; as, the\n   great truths of morals."
                },
                {
                    "spelling_detail_id": 151566,
                    "word_type": "n.",
                    "spelling_usage": "Righteousness; true religion."
                },
                {
                    "spelling_detail_id": 151567,
                    "word_type": "v. t.",
                    "spelling_usage": "To assert as true; to declare."
                }
            ]
        },
        {
            "id": 95705,
            "spelling_name": "Tulip",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 151794,
                    "word_type": "n.",
                    "spelling_usage": "Any plant of the liliaceous genus Tulipa. Many varieties are\n   cultivated for their beautiful, often variegated flowers."
                }
            ]
        },
        {
            "id": 95946,
            "spelling_name": "Turret",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152213,
                    "word_type": "n.",
                    "spelling_usage": "A little tower, frequently a merely ornamental structure at\n   one of the angles of a larger structure."
                },
                {
                    "spelling_detail_id": 152214,
                    "word_type": "n.",
                    "spelling_usage": "A movable building, of a square form, consisting of ten or\n   even twenty stories and sometimes one hundred and twenty cubits high,\n   usually moved on wheels, and employed in approaching a fortified place,\n   for carrying soldiers, engines, ladders, casting bridges, and other\n   necessaries."
                },
                {
                    "spelling_detail_id": 152215,
                    "word_type": "n.",
                    "spelling_usage": "A revolving tower constructed of thick iron plates, within\n   which cannon are mounted. Turrets are used on vessels of war and on\n   land."
                },
                {
                    "spelling_detail_id": 152216,
                    "word_type": "n.",
                    "spelling_usage": "The elevated central portion of the roof of a passenger\n   car. Its sides are pierced for light and ventilation."
                }
            ]
        },
        {
            "id": 95955,
            "spelling_name": "Turtle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152226,
                    "word_type": "n.",
                    "spelling_usage": "The turtledove."
                },
                {
                    "spelling_detail_id": 152227,
                    "word_type": "n.",
                    "spelling_usage": "Any one of the numerous species of Testudinata, especially\n   a sea turtle, or chelonian."
                },
                {
                    "spelling_detail_id": 152228,
                    "word_type": "n.",
                    "spelling_usage": "The curved plate in which the form is held in a\n   type-revolving cylinder press."
                }
            ]
        },
        {
            "id": 95975,
            "spelling_name": "Tussle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152256,
                    "word_type": "v. i. & t.",
                    "spelling_usage": "To struggle, as in sport; to scuffle; to struggle\n   with."
                },
                {
                    "spelling_detail_id": 152257,
                    "word_type": "n.",
                    "spelling_usage": "A struggle; a scuffle."
                }
            ]
        },
        {
            "id": 96056,
            "spelling_name": "Twice",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152382,
                    "word_type": "adv.",
                    "spelling_usage": "Two times; once and again."
                },
                {
                    "spelling_detail_id": 152383,
                    "word_type": "adv.",
                    "spelling_usage": "Doubly; in twofold quantity or degree; as, twice the sum;\n   he is twice as fortunate as his neighbor."
                }
            ]
        },
        {
            "id": 96083,
            "spelling_name": "Twinge",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152452,
                    "word_type": "v. i.",
                    "spelling_usage": "To pull with a twitch; to pinch; to tweak."
                },
                {
                    "spelling_detail_id": 152453,
                    "word_type": "v. i.",
                    "spelling_usage": "To affect with a sharp, sudden pain; to torment with\n   pinching or sharp pains."
                },
                {
                    "spelling_detail_id": 152454,
                    "word_type": "v. i.",
                    "spelling_usage": "To have a sudden, sharp, local pain, like a twitch; to\n   suffer a keen, darting, or shooting pain; as, the side twinges."
                },
                {
                    "spelling_detail_id": 152455,
                    "word_type": "n.",
                    "spelling_usage": "A pinch; a tweak; a twitch."
                },
                {
                    "spelling_detail_id": 152456,
                    "word_type": "n.",
                    "spelling_usage": "A sudden sharp pain; a darting local pain of momentary\n   continuance; as, a twinge in the arm or side."
                }
            ]
        },
        {
            "id": 96279,
            "spelling_name": "Ugliness",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152769,
                    "word_type": "n.",
                    "spelling_usage": "The quality or state of being ugly."
                }
            ]
        },
        {
            "id": 96313,
            "spelling_name": "Ulna",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152813,
                    "word_type": "n.",
                    "spelling_usage": "The postaxial bone of the forearm, or branchium,\n   corresponding to the fibula of the hind limb. See Radius."
                },
                {
                    "spelling_detail_id": 152814,
                    "word_type": "n.",
                    "spelling_usage": "An ell; also, a yard."
                }
            ]
        },
        {
            "id": 96403,
            "spelling_name": "Umbrella",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 152941,
                    "word_type": "n.",
                    "spelling_usage": "A shade, screen, or guard, carried in the hand for\n   sheltering the person from the rays of the sun, or from rain or snow.\n   It is formed of silk, cotton, or other fabric, extended on strips of\n   whalebone, steel, or other elastic material, inserted, or fastened to,\n   a rod or stick by means of pivots or hinges, in such a way as to allow\n   of being opened and closed with ease. See Parasol."
                },
                {
                    "spelling_detail_id": 152942,
                    "word_type": "n.",
                    "spelling_usage": "The umbrellalike disk, or swimming bell, of a jellyfish."
                },
                {
                    "spelling_detail_id": 152943,
                    "word_type": "n.",
                    "spelling_usage": "Any marine tectibranchiate gastropod of the genus\n   Umbrella, having an umbrella-shaped shell; -- called also umbrella\n   shell."
                }
            ]
        },
        {
            "id": 96701,
            "spelling_name": "Uncle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 153340,
                    "word_type": "n.",
                    "spelling_usage": "The brother of one's father or mother; also applied to an\n   aunt's husband; -- the correlative of aunt in sex, and of nephew and\n   niece in relationship."
                },
                {
                    "spelling_detail_id": 153341,
                    "word_type": "n.",
                    "spelling_usage": "A pawnbroker."
                }
            ]
        },
        {
            "id": 98389,
            "spelling_name": "Upset",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 155617,
                    "word_type": "v. t.",
                    "spelling_usage": "To set up; to put upright."
                },
                {
                    "spelling_detail_id": 155618,
                    "word_type": "v. t.",
                    "spelling_usage": "To thicken and shorten, as a heated piece of iron, by\n   hammering on the end."
                },
                {
                    "spelling_detail_id": 155619,
                    "word_type": "v. t.",
                    "spelling_usage": "To shorten (a tire) in the process of resetting,\n   originally by cutting it and hammering on the ends."
                },
                {
                    "spelling_detail_id": 155620,
                    "word_type": "v. t.",
                    "spelling_usage": "To overturn, overthrow, or overset; as, to upset a\n   carriage; to upset an argument."
                },
                {
                    "spelling_detail_id": 155621,
                    "word_type": "v. t.",
                    "spelling_usage": "To disturb the self-possession of; to disorder the nerves\n   of; to make ill; as, the fright upset her."
                },
                {
                    "spelling_detail_id": 155622,
                    "word_type": "v. i.",
                    "spelling_usage": "To become upset."
                },
                {
                    "spelling_detail_id": 155623,
                    "word_type": "a.",
                    "spelling_usage": "Set up; fixed; determined; -- used chiefly or only in the\n   phrase upset price; that is, the price fixed upon as the minimum for\n   property offered in a public sale, or, in an auction, the price at\n   which property is set up or started by the auctioneer, and the lowest\n   price at which it will be sold."
                },
                {
                    "spelling_detail_id": 155624,
                    "word_type": "n.",
                    "spelling_usage": "The act of upsetting, or the state of being upset; an\n   overturn; as, the wagon had an upset."
                }
            ]
        },
        {
            "id": 98392,
            "spelling_name": "Upshot",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 155627,
                    "word_type": "n.",
                    "spelling_usage": "Final issue; conclusion; the sum and substance; the end;\n   the result; the consummation."
                }
            ]
        },
        {
            "id": 98625,
            "spelling_name": "Useful",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 155935,
                    "word_type": "a.",
                    "spelling_usage": "Full of use, advantage, or profit; producing, or having\n   power to produce, good; serviceable for any end or object; helpful\n   toward advancing any purpose; beneficial; profitable; advantageous; as,\n   vessels and instruments useful in a family; books useful for\n   improvement; useful knowledge; useful arts."
                }
            ]
        },
        {
            "id": 98630,
            "spelling_name": "Usher",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 155941,
                    "word_type": "n.",
                    "spelling_usage": "An officer or servant who has the care of the door of a\n   court, hall, chamber, or the like; hence, an officer whose business it\n   is to introduce strangers, or to walk before a person of rank. Also,\n   one who escorts persons to seats in a church, theater, etc."
                },
                {
                    "spelling_detail_id": 155942,
                    "word_type": "n.",
                    "spelling_usage": "An under teacher, or assistant master, in a school."
                },
                {
                    "spelling_detail_id": 155945,
                    "word_type": "v. t.",
                    "spelling_usage": "To introduce or escort, as an usher, forerunner, or\n   harbinger; to forerun; -- sometimes followed by in or forth; as, to\n   usher in a stranger; to usher forth the guests; to usher a visitor into\n   the room."
                }
            ]
        },
        {
            "id": 98706,
            "spelling_name": "Utterable",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 156058,
                    "word_type": "a.",
                    "spelling_usage": "Capable of being uttered."
                }
            ]
        },
        {
            "id": 98747,
            "spelling_name": "Vacation",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 156113,
                    "word_type": "n.",
                    "spelling_usage": "The act of vacating; a making void or of no force; as,\n   the vacation of an office or a charter."
                },
                {
                    "spelling_detail_id": 156114,
                    "word_type": "n.",
                    "spelling_usage": "Intermission of a stated employment, procedure, or\n   office; a period of intermission; rest; leisure."
                },
                {
                    "spelling_detail_id": 156115,
                    "word_type": "n.",
                    "spelling_usage": "Intermission of judicial proceedings; the space of time\n   between the end of one term and the beginning of the next; nonterm;\n   recess."
                },
                {
                    "spelling_detail_id": 156116,
                    "word_type": "n.",
                    "spelling_usage": "The intermission of the regular studies and exercises of\n   an educational institution between terms; holidays; as, the spring\n   vacation."
                },
                {
                    "spelling_detail_id": 156117,
                    "word_type": "n.",
                    "spelling_usage": "The time when an office is vacant; esp. (Eccl.), the time\n   when a see, or other spiritual dignity, is vacant."
                }
            ]
        },
        {
            "id": 98878,
            "spelling_name": "Valiant",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 156296,
                    "word_type": "a.",
                    "spelling_usage": "Vigorous in body; strong; powerful; as, a valiant fencer."
                },
                {
                    "spelling_detail_id": 156297,
                    "word_type": "a.",
                    "spelling_usage": "Intrepid in danger; courageous; brave."
                },
                {
                    "spelling_detail_id": 156298,
                    "word_type": "a.",
                    "spelling_usage": "Performed with valor or bravery; heroic."
                }
            ]
        },
        {
            "id": 99062,
            "spelling_name": "Various",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 156595,
                    "word_type": "a.",
                    "spelling_usage": "Different; diverse; several; manifold; as, men of various\n   names; various occupations; various colors."
                },
                {
                    "spelling_detail_id": 156596,
                    "word_type": "a.",
                    "spelling_usage": "Changeable; uncertain; inconstant; variable."
                },
                {
                    "spelling_detail_id": 156597,
                    "word_type": "a.",
                    "spelling_usage": "Variegated; diversified; not monotonous."
                }
            ]
        },
        {
            "id": 99070,
            "spelling_name": "Varnish",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 156609,
                    "word_type": "n.",
                    "spelling_usage": "A viscid liquid, consisting of a solution of resinous\n   matter in an oil or a volatile liquid, laid on work with a brush, or\n   otherwise. When applied the varnish soon dries, either by evaporation\n   or chemical action, and the resinous part forms thus a smooth, hard\n   surface, with a beautiful gloss, capable of resisting, to a greater or\n   less degree, the influences of air and moisture."
                },
                {
                    "spelling_detail_id": 156610,
                    "word_type": "n.",
                    "spelling_usage": "That which resembles varnish, either naturally or\n   artificially; a glossy appearance."
                },
                {
                    "spelling_detail_id": 156611,
                    "word_type": "n.",
                    "spelling_usage": "An artificial covering to give a fair appearance to any\n   act or conduct; outside show; gloss."
                },
                {
                    "spelling_detail_id": 156614,
                    "word_type": "n.",
                    "spelling_usage": "To lay varnish on; to cover with a liquid which produces,\n   when dry, a hard, glossy surface; as, to varnish a table; to varnish a\n   painting."
                },
                {
                    "spelling_detail_id": 156615,
                    "word_type": "n.",
                    "spelling_usage": "To cover or conceal with something that gives a fair\n   appearance; to give a fair coloring to by words; to gloss over; to\n   palliate; as, to varnish guilt."
                }
            ]
        },
        {
            "id": 99131,
            "spelling_name": "Vault",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 156714,
                    "word_type": "n.",
                    "spelling_usage": "An arched structure of masonry, forming a ceiling or canopy."
                },
                {
                    "spelling_detail_id": 156715,
                    "word_type": "n.",
                    "spelling_usage": "An arched apartment; especially, a subterranean room, use\n   for storing articles, for a prison, for interment, or the like; a cell;\n   a cellar."
                },
                {
                    "spelling_detail_id": 156716,
                    "word_type": "n.",
                    "spelling_usage": "The canopy of heaven; the sky."
                },
                {
                    "spelling_detail_id": 156717,
                    "word_type": "n.",
                    "spelling_usage": "A leap or bound."
                },
                {
                    "spelling_detail_id": 156718,
                    "word_type": "n.",
                    "spelling_usage": "The bound or leap of a horse; a curvet."
                },
                {
                    "spelling_detail_id": 156719,
                    "word_type": "n.",
                    "spelling_usage": "A leap by aid of the hands, or of a pole, springboard, or\n   the like."
                },
                {
                    "spelling_detail_id": 156722,
                    "word_type": "v. t.",
                    "spelling_usage": "To form with a vault, or to cover with a vault; to give\n   the shape of an arch to; to arch; as, vault a roof; to vault a passage\n   to a court."
                },
                {
                    "spelling_detail_id": 156723,
                    "word_type": "v. i.",
                    "spelling_usage": "To leap over; esp., to leap over by aid of the hands or a\n   pole; as, to vault a fence."
                },
                {
                    "spelling_detail_id": 156724,
                    "word_type": "n.",
                    "spelling_usage": "To leap; to bound; to jump; to spring."
                },
                {
                    "spelling_detail_id": 156725,
                    "word_type": "n.",
                    "spelling_usage": "To exhibit feats of tumbling or leaping; to tumble."
                }
            ]
        },
        {
            "id": 99325,
            "spelling_name": "Venomous",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 157009,
                    "word_type": "a.",
                    "spelling_usage": "Full of venom; noxious to animal life; poisonous; as, the\n   bite of a serpent may be venomous."
                },
                {
                    "spelling_detail_id": 157010,
                    "word_type": "a.",
                    "spelling_usage": "Having a poison gland or glands for the secretion of\n   venom, as certain serpents and insects."
                },
                {
                    "spelling_detail_id": 157011,
                    "word_type": "a.",
                    "spelling_usage": "Noxious; mischievous; malignant; spiteful; as, a venomous\n   progeny; a venomous writer."
                }
            ]
        },
        {
            "id": 99413,
            "spelling_name": "Verbiage",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 157161,
                    "word_type": "n.",
                    "spelling_usage": "The use of many words without necessity, or with little\n   sense; a superabundance of words; verbosity; wordiness."
                }
            ]
        },
        {
            "id": 99560,
            "spelling_name": "Version",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 157380,
                    "word_type": "n.",
                    "spelling_usage": "A change of form, direction, or the like; transformation;\n   conversion; turning."
                },
                {
                    "spelling_detail_id": 157381,
                    "word_type": "n.",
                    "spelling_usage": "A condition of the uterus in which its axis is deflected\n   from its normal position without being bent upon itself. See\n   Anteversion, and Retroversion."
                },
                {
                    "spelling_detail_id": 157382,
                    "word_type": "n.",
                    "spelling_usage": "The act of translating, or rendering, from one language\n   into another language."
                },
                {
                    "spelling_detail_id": 157383,
                    "word_type": "n.",
                    "spelling_usage": "A translation; that which is rendered from another\n   language; as, the Common, or Authorized, Version of the Scriptures (see\n   under Authorized); the Septuagint Version of the Old Testament."
                },
                {
                    "spelling_detail_id": 157384,
                    "word_type": "n.",
                    "spelling_usage": "An account or description from a particular point of view,\n   especially as contrasted with another account; as, he gave another\n   version of the affair."
                }
            ]
        },
        {
            "id": 99682,
            "spelling_name": "Veteran",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 157565,
                    "word_type": "a.",
                    "spelling_usage": "Long exercised in anything, especially in military life\n   and the duties of a soldier; long practiced or experienced; as, a\n   veteran officer or soldier; veteran skill."
                },
                {
                    "spelling_detail_id": 157566,
                    "word_type": "n.",
                    "spelling_usage": "One who has been long exercised in any service or art,\n   particularly in war; one who has had."
                }
            ]
        },
        {
            "id": 99871,
            "spelling_name": "Villa",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 157869,
                    "word_type": "n.",
                    "spelling_usage": "A country seat; a country or suburban residence of some\n   pretensions to elegance."
                }
            ]
        },
        {
            "id": 100136,
            "spelling_name": "Vital",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158274,
                    "word_type": "a.",
                    "spelling_usage": "Belonging or relating to life, either animal or vegetable;\n   as, vital energies; vital functions; vital actions."
                },
                {
                    "spelling_detail_id": 158275,
                    "word_type": "a.",
                    "spelling_usage": "Contributing to life; necessary to, or supporting, life; as,\n   vital blood."
                },
                {
                    "spelling_detail_id": 158276,
                    "word_type": "a.",
                    "spelling_usage": "Containing life; living."
                },
                {
                    "spelling_detail_id": 158277,
                    "word_type": "a.",
                    "spelling_usage": "Being the seat of life; being that on which life depends;\n   mortal."
                },
                {
                    "spelling_detail_id": 158278,
                    "word_type": "a.",
                    "spelling_usage": "Very necessary; highly important; essential."
                },
                {
                    "spelling_detail_id": 158279,
                    "word_type": "a.",
                    "spelling_usage": "Capable of living; in a state to live; viable."
                },
                {
                    "spelling_detail_id": 158280,
                    "word_type": "n.",
                    "spelling_usage": "A vital part; one of the vitals."
                }
            ]
        },
        {
            "id": 100343,
            "spelling_name": "Volcano",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158578,
                    "word_type": "n.",
                    "spelling_usage": "A mountain or hill, usually more or less conical in form,\n   from which lava, cinders, steam, sulphur gases, and the like, are\n   ejected; -- often popularly called a burning mountain."
                }
            ]
        },
        {
            "id": 100389,
            "spelling_name": "Volumetric",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158649,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the measurement of volume."
                }
            ]
        },
        {
            "id": 100396,
            "spelling_name": "Voluntary",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158658,
                    "word_type": "v. t.",
                    "spelling_usage": "Proceeding from the will; produced in or by an act of\n   choice."
                },
                {
                    "spelling_detail_id": 158659,
                    "word_type": "v. t.",
                    "spelling_usage": "Unconstrained by the interference of another;\n   unimpelled by the influence of another; not prompted or persuaded by\n   another; done of his or its own accord; spontaneous; acting of one's\n   self, or of itself; free."
                },
                {
                    "spelling_detail_id": 158660,
                    "word_type": "v. t.",
                    "spelling_usage": "Done by design or intention; intentional; purposed;\n   intended; not accidental; as, if a man kills another by lopping a tree,\n   it is not voluntary manslaughter."
                },
                {
                    "spelling_detail_id": 158661,
                    "word_type": "v. t.",
                    "spelling_usage": "Of or pertaining to the will; subject to, or\n   regulated by, the will; as, the voluntary motions of an animal, such as\n   the movements of the leg or arm (in distinction from involuntary\n   motions, such as the movements of the heart); the voluntary muscle\n   fibers, which are the agents in voluntary motion."
                },
                {
                    "spelling_detail_id": 158662,
                    "word_type": "v. t.",
                    "spelling_usage": "Endowed with the power of willing; as, man is a\n   voluntary agent."
                },
                {
                    "spelling_detail_id": 158663,
                    "word_type": "v. t.",
                    "spelling_usage": "Free; without compulsion; according to the will,\n   consent, or agreement, of a party; without consideration; gratuitous;\n   without valuable consideration."
                },
                {
                    "spelling_detail_id": 158664,
                    "word_type": "v. t.",
                    "spelling_usage": "Of or pertaining to voluntaryism; as, a voluntary\n   church, in distinction from an established or state church."
                },
                {
                    "spelling_detail_id": 158666,
                    "word_type": "n.",
                    "spelling_usage": "One who engages in any affair of his own free will; a\n   volunteer."
                },
                {
                    "spelling_detail_id": 158667,
                    "word_type": "n.",
                    "spelling_usage": "A piece played by a musician, often extemporarily,\n   according to his fancy; specifically, an organ solo played before,\n   during, or after divine service."
                },
                {
                    "spelling_detail_id": 158668,
                    "word_type": "n.",
                    "spelling_usage": "One who advocates voluntaryism."
                }
            ]
        },
        {
            "id": 100456,
            "spelling_name": "Votive",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158764,
                    "word_type": "a.",
                    "spelling_usage": "Given by vow, or in fulfillment of a vow; consecrated by a\n   vow; devoted; as, votive offerings; a votive tablet."
                }
            ]
        },
        {
            "id": 100458,
            "spelling_name": "Vouch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158768,
                    "word_type": "v. t.",
                    "spelling_usage": "To call; to summon."
                },
                {
                    "spelling_detail_id": 158769,
                    "word_type": "v. t.",
                    "spelling_usage": "To call upon to witness; to obtest."
                },
                {
                    "spelling_detail_id": 158770,
                    "word_type": "v. t.",
                    "spelling_usage": "To warrant; to maintain by affirmations; to attest; to\n   affirm; to avouch."
                },
                {
                    "spelling_detail_id": 158771,
                    "word_type": "v. t.",
                    "spelling_usage": "To back; to support; to confirm; to establish."
                },
                {
                    "spelling_detail_id": 158772,
                    "word_type": "v. t.",
                    "spelling_usage": "To call into court to warrant and defend, or to make good\n   a warranty of title."
                },
                {
                    "spelling_detail_id": 158773,
                    "word_type": "v. i.",
                    "spelling_usage": "To bear witness; to give testimony or full attestation."
                },
                {
                    "spelling_detail_id": 158774,
                    "word_type": "v. i.",
                    "spelling_usage": "To assert; to aver; to declare."
                },
                {
                    "spelling_detail_id": 158775,
                    "word_type": "n.",
                    "spelling_usage": "Warrant; attestation."
                }
            ]
        },
        {
            "id": 100529,
            "spelling_name": "Vulpine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158870,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to the fox; resembling the fox; foxy;\n   cunning; crafty; artful."
                }
            ]
        },
        {
            "id": 100572,
            "spelling_name": "Wafer",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 158932,
                    "word_type": "n.",
                    "spelling_usage": "A thin cake made of flour and other ingredients."
                },
                {
                    "spelling_detail_id": 158933,
                    "word_type": "n.",
                    "spelling_usage": "A thin cake or piece of bread (commonly unleavened,\n   circular, and stamped with a crucifix or with the sacred monogram) used\n   in the Eucharist, as in the Roman Catholic Church."
                },
                {
                    "spelling_detail_id": 158934,
                    "word_type": "n.",
                    "spelling_usage": "An adhesive disk of dried paste, made of flour, gelatin,\n   isinglass, or the like, and coloring matter, -- used in sealing letters\n   and other documents."
                },
                {
                    "spelling_detail_id": 158937,
                    "word_type": "v. t.",
                    "spelling_usage": "To seal or close with a wafer."
                }
            ]
        },
        {
            "id": 100739,
            "spelling_name": "Wand",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 159258,
                    "word_type": "n.",
                    "spelling_usage": "A small stick; a rod; a verge."
                },
                {
                    "spelling_detail_id": 159259,
                    "word_type": "n.",
                    "spelling_usage": "A staff of authority."
                },
                {
                    "spelling_detail_id": 159260,
                    "word_type": "n.",
                    "spelling_usage": "A rod used by conjurers, diviners, magicians, etc."
                }
            ]
        },
        {
            "id": 100868,
            "spelling_name": "Warning",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 159507,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Warn"
                },
                {
                    "spelling_detail_id": 159513,
                    "word_type": "a.",
                    "spelling_usage": "Giving previous notice; cautioning; admonishing; as, a\n   warning voice."
                },
                {
                    "spelling_detail_id": 159514,
                    "word_type": "n.",
                    "spelling_usage": "Previous notice."
                },
                {
                    "spelling_detail_id": 159515,
                    "word_type": "n.",
                    "spelling_usage": "Caution against danger, or against faults or evil\n   practices which incur danger; admonition; monition."
                }
            ]
        },
        {
            "id": 100940,
            "spelling_name": "Wasp",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 159678,
                    "word_type": "n.",
                    "spelling_usage": "Any one of numerous species of stinging hymenopterous\n   insects, esp. any of the numerous species of the genus Vespa, which\n   includes the true, or social, wasps, some of which are called yellow\n   jackets."
                }
            ]
        },
        {
            "id": 100961,
            "spelling_name": "Watch",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 159728,
                    "word_type": "v. i.",
                    "spelling_usage": "The act of watching; forbearance of sleep; vigil;\n   wakeful, vigilant, or constantly observant attention; close\n   observation; guard; preservative or preventive vigilance; formerly, a\n   watching or guarding by night."
                },
                {
                    "spelling_detail_id": 159729,
                    "word_type": "v. i.",
                    "spelling_usage": "One who watches, or those who watch; a watchman, or a\n   body of watchmen; a sentry; a guard."
                },
                {
                    "spelling_detail_id": 159730,
                    "word_type": "v. i.",
                    "spelling_usage": "The post or office of a watchman; also, the place where a\n   watchman is posted, or where a guard is kept."
                },
                {
                    "spelling_detail_id": 159731,
                    "word_type": "v. i.",
                    "spelling_usage": "The period of the night during which a person does duty\n   as a sentinel, or guard; the time from the placing of a sentinel till\n   his relief; hence, a division of the night."
                },
                {
                    "spelling_detail_id": 159732,
                    "word_type": "v. i.",
                    "spelling_usage": "A small timepiece, or chronometer, to be carried about\n   the person, the machinery of which is moved by a spring."
                },
                {
                    "spelling_detail_id": 159733,
                    "word_type": "n.",
                    "spelling_usage": "An allotted portion of time, usually four hour for standing\n   watch, or being on deck ready for duty. Cf. Dogwatch."
                },
                {
                    "spelling_detail_id": 159734,
                    "word_type": "n.",
                    "spelling_usage": "That part, usually one half, of the officers and crew, who\n   together attend to the working of a vessel for an allotted time,\n   usually four hours. The watches are designated as the port watch, and\n   the starboard watch."
                },
                {
                    "spelling_detail_id": 159735,
                    "word_type": "v. i.",
                    "spelling_usage": "To be awake; to be or continue without sleep; to wake; to\n   keep vigil."
                },
                {
                    "spelling_detail_id": 159736,
                    "word_type": "v. i.",
                    "spelling_usage": "To be attentive or vigilant; to give heed; to be on the\n   lookout; to keep guard; to act as sentinel."
                },
                {
                    "spelling_detail_id": 159737,
                    "word_type": "v. i.",
                    "spelling_usage": "To be expectant; to look with expectation; to wait; to\n   seek opportunity."
                },
                {
                    "spelling_detail_id": 159738,
                    "word_type": "v. i.",
                    "spelling_usage": "To remain awake with any one as nurse or attendant; to\n   attend on the sick during the night; as, to watch with a man in a\n   fever."
                },
                {
                    "spelling_detail_id": 159739,
                    "word_type": "v. i.",
                    "spelling_usage": "To serve the purpose of a watchman by floating properly\n   in its place; -- said of a buoy."
                },
                {
                    "spelling_detail_id": 159742,
                    "word_type": "v. t.",
                    "spelling_usage": "To give heed to; to observe the actions or motions of,\n   for any purpose; to keep in view; not to lose from sight and\n   observation; as, to watch the progress of a bill in the legislature."
                },
                {
                    "spelling_detail_id": 159743,
                    "word_type": "v. t.",
                    "spelling_usage": "To tend; to guard; to have in keeping."
                }
            ]
        },
        {
            "id": 100976,
            "spelling_name": "Water",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 159759,
                    "word_type": "n.",
                    "spelling_usage": "The fluid which descends from the clouds in rain, and which\n   forms rivers, lakes, seas, etc."
                },
                {
                    "spelling_detail_id": 159760,
                    "word_type": "n.",
                    "spelling_usage": "A body of water, standing or flowing; a lake, river, or\n   other collection of water."
                },
                {
                    "spelling_detail_id": 159761,
                    "word_type": "n.",
                    "spelling_usage": "Any liquid secretion, humor, or the like, resembling water;\n   esp., the urine."
                },
                {
                    "spelling_detail_id": 159762,
                    "word_type": "n.",
                    "spelling_usage": "A solution in water of a gaseous or readily volatile\n   substance; as, ammonia water."
                },
                {
                    "spelling_detail_id": 159763,
                    "word_type": "n.",
                    "spelling_usage": "The limpidity and luster of a precious stone, especially a\n   diamond; as, a diamond of the first water, that is, perfectly pure and\n   transparent. Hence, of the first water, that is, of the first\n   excellence."
                },
                {
                    "spelling_detail_id": 159764,
                    "word_type": "n.",
                    "spelling_usage": "A wavy, lustrous pattern or decoration such as is imparted\n   to linen, silk, metals, etc. See Water, v. t., 3, Damask, v. t., and\n   Damaskeen."
                },
                {
                    "spelling_detail_id": 159765,
                    "word_type": "v. t.",
                    "spelling_usage": "An addition to the shares representing the capital of a\n   stock company so that the aggregate par value of the shares is\n   increased while their value for investment is diminished, or \"diluted.\""
                },
                {
                    "spelling_detail_id": 159768,
                    "word_type": "v. t.",
                    "spelling_usage": "To wet or supply with water; to moisten; to overflow with\n   water; to irrigate; as, to water land; to water flowers."
                },
                {
                    "spelling_detail_id": 159769,
                    "word_type": "v. t.",
                    "spelling_usage": "To supply with water for drink; to cause or allow to\n   drink; as, to water cattle and horses."
                },
                {
                    "spelling_detail_id": 159770,
                    "word_type": "v. t.",
                    "spelling_usage": "To wet and calender, as cloth, so as to impart to it a\n   lustrous appearance in wavy lines; to diversify with wavelike lines;\n   as, to water silk. Cf. Water, n., 6."
                },
                {
                    "spelling_detail_id": 159771,
                    "word_type": "n.",
                    "spelling_usage": "To add water to (anything), thereby extending the quantity\n   or bulk while reducing the strength or quality; to extend; to dilute;\n   to weaken."
                },
                {
                    "spelling_detail_id": 159772,
                    "word_type": "v. i.",
                    "spelling_usage": "To shed, secrete, or fill with, water or liquid matter;\n   as, his eyes began to water."
                },
                {
                    "spelling_detail_id": 159773,
                    "word_type": "v. i.",
                    "spelling_usage": "To get or take in water; as, the ship put into port to\n   water."
                }
            ]
        },
        {
            "id": 101249,
            "spelling_name": "Wave",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 160121,
                    "word_type": "v. t.",
                    "spelling_usage": "See Waive."
                },
                {
                    "spelling_detail_id": 160124,
                    "word_type": "v. i.",
                    "spelling_usage": "To play loosely; to move like a wave, one way and the\n   other; to float; to flutter; to undulate."
                },
                {
                    "spelling_detail_id": 160125,
                    "word_type": "v. i.",
                    "spelling_usage": "To be moved to and fro as a signal."
                },
                {
                    "spelling_detail_id": 160126,
                    "word_type": "v. i.",
                    "spelling_usage": "To fluctuate; to waver; to be in an unsettled state; to\n   vacillate."
                },
                {
                    "spelling_detail_id": 160127,
                    "word_type": "v. t.",
                    "spelling_usage": "To move one way and the other; to brandish."
                },
                {
                    "spelling_detail_id": 160128,
                    "word_type": "v. t.",
                    "spelling_usage": "To raise into inequalities of surface; to give an\n   undulating form a surface to."
                },
                {
                    "spelling_detail_id": 160129,
                    "word_type": "v. t.",
                    "spelling_usage": "To move like a wave, or by floating; to waft."
                },
                {
                    "spelling_detail_id": 160130,
                    "word_type": "v. t.",
                    "spelling_usage": "To call attention to, or give a direction or command to,\n   by a waving motion, as of the hand; to signify by waving; to beckon; to\n   signal; to indicate."
                },
                {
                    "spelling_detail_id": 160131,
                    "word_type": "v. i.",
                    "spelling_usage": "An advancing ridge or swell on the surface of a liquid, as\n   of the sea, resulting from the oscillatory motion of the particles\n   composing it when disturbed by any force their position of rest; an\n   undulation."
                },
                {
                    "spelling_detail_id": 160132,
                    "word_type": "v. i.",
                    "spelling_usage": "A vibration propagated from particle to particle through a\n   body or elastic medium, as in the transmission of sound; an assemblage\n   of vibrating molecules in all phases of a vibration, with no phase\n   repeated; a wave of vibration; an undulation. See Undulation."
                },
                {
                    "spelling_detail_id": 160133,
                    "word_type": "v. i.",
                    "spelling_usage": "Water; a body of water."
                },
                {
                    "spelling_detail_id": 160134,
                    "word_type": "v. i.",
                    "spelling_usage": "Unevenness; inequality of surface."
                },
                {
                    "spelling_detail_id": 160135,
                    "word_type": "v. i.",
                    "spelling_usage": "A waving or undulating motion; a signal made with the\n   hand, a flag, etc."
                },
                {
                    "spelling_detail_id": 160136,
                    "word_type": "v. i.",
                    "spelling_usage": "The undulating line or streak of luster on cloth watered,\n   or calendered, or on damask steel."
                },
                {
                    "spelling_detail_id": 160137,
                    "word_type": "v. i.",
                    "spelling_usage": "Fig.: A swelling or excitement of thought, feeling, or\n   energy; a tide; as, waves of enthusiasm."
                },
                {
                    "spelling_detail_id": 160161,
                    "word_type": "n.",
                    "spelling_usage": "Woe."
                }
            ]
        },
        {
            "id": 101374,
            "spelling_name": "Weather",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 160371,
                    "word_type": "n.",
                    "spelling_usage": "The state of the air or atmosphere with respect to heat or\n   cold, wetness or dryness, calm or storm, clearness or cloudiness, or\n   any other meteorological phenomena; meteorological condition of the\n   atmosphere; as, warm weather; cold weather; wet weather; dry weather,\n   etc."
                },
                {
                    "spelling_detail_id": 160372,
                    "word_type": "n.",
                    "spelling_usage": "Vicissitude of season; meteorological change; alternation\n   of the state of the air."
                },
                {
                    "spelling_detail_id": 160373,
                    "word_type": "n.",
                    "spelling_usage": "Storm; tempest."
                },
                {
                    "spelling_detail_id": 160374,
                    "word_type": "n.",
                    "spelling_usage": "A light rain; a shower."
                },
                {
                    "spelling_detail_id": 160377,
                    "word_type": "v. t.",
                    "spelling_usage": "To expose to the air; to air; to season by exposure to\n   air."
                },
                {
                    "spelling_detail_id": 160378,
                    "word_type": "v. t.",
                    "spelling_usage": "Hence, to sustain the trying effect of; to bear up\n   against and overcome; to sustain; to endure; to resist; as, to weather\n   the storm."
                },
                {
                    "spelling_detail_id": 160379,
                    "word_type": "v. t.",
                    "spelling_usage": "To sail or pass to the windward of; as, to weather a\n   cape; to weather another ship."
                },
                {
                    "spelling_detail_id": 160380,
                    "word_type": "v. t.",
                    "spelling_usage": "To place (a hawk) unhooded in the open air."
                },
                {
                    "spelling_detail_id": 160381,
                    "word_type": "v. i.",
                    "spelling_usage": "To undergo or endure the action of the atmosphere; to\n   suffer meteorological influences; sometimes, to wear away, or alter,\n   under atmospheric influences; to suffer waste by weather."
                },
                {
                    "spelling_detail_id": 160382,
                    "word_type": "a.",
                    "spelling_usage": "Being toward the wind, or windward -- opposed to lee; as,\n   weather bow, weather braces, weather gauge, weather lifts, weather\n   quarter, weather shrouds, etc."
                }
            ]
        },
        {
            "id": 101524,
            "spelling_name": "Welding",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 160674,
                    "word_type": "p. pr. & vb. n.",
                    "spelling_usage": "of Weld"
                }
            ]
        },
        {
            "id": 101720,
            "spelling_name": "Wheedle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161011,
                    "word_type": "v. t.",
                    "spelling_usage": "To entice by soft words; to cajole; to flatter; to\n   coax."
                },
                {
                    "spelling_detail_id": 161012,
                    "word_type": "v. t.",
                    "spelling_usage": "To grain, or get away, by flattery."
                },
                {
                    "spelling_detail_id": 161013,
                    "word_type": "v. i.",
                    "spelling_usage": "To flatter; to coax; to cajole."
                }
            ]
        },
        {
            "id": 101751,
            "spelling_name": "Whelp",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161077,
                    "word_type": "n.",
                    "spelling_usage": "One of the young of a dog or a beast of prey; a puppy; a\n   cub; as, a lion's whelps."
                },
                {
                    "spelling_detail_id": 161078,
                    "word_type": "n.",
                    "spelling_usage": "A child; a youth; -- jocosely or in contempt."
                },
                {
                    "spelling_detail_id": 161079,
                    "word_type": "n.",
                    "spelling_usage": "One of the longitudinal ribs or ridges on the barrel of a\n   capstan or a windless; -- usually in the plural; as, the whelps of a\n   windlass."
                },
                {
                    "spelling_detail_id": 161080,
                    "word_type": "n.",
                    "spelling_usage": "One of the teeth of a sprocket wheel."
                },
                {
                    "spelling_detail_id": 161083,
                    "word_type": "v. i.",
                    "spelling_usage": "To bring forth young; -- said of the female of the dog\n   and some beasts of prey."
                },
                {
                    "spelling_detail_id": 161084,
                    "word_type": "v. t.",
                    "spelling_usage": "To bring forth, as cubs or young; to give birth to."
                }
            ]
        },
        {
            "id": 101768,
            "spelling_name": "Whereas",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161110,
                    "word_type": "adv.",
                    "spelling_usage": "At which place; where."
                },
                {
                    "spelling_detail_id": 161111,
                    "word_type": "conj.",
                    "spelling_usage": "Considering that; it being the case that; since; --\n   used to introduce a preamble which is the basis of declarations,\n   affirmations, commands, requests, or like, that follow."
                },
                {
                    "spelling_detail_id": 161112,
                    "word_type": "conj.",
                    "spelling_usage": "When in fact; while on the contrary; the case being in\n   truth that; although; -- implying opposition to something that\n   precedes; or implying recognition of facts, sometimes followed by a\n   different statement, and sometimes by inferences or something\n   consequent."
                }
            ]
        },
        {
            "id": 101863,
            "spelling_name": "Whine",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161273,
                    "word_type": "v. i.",
                    "spelling_usage": "To utter a plaintive cry, as some animals; to moan with a\n   childish noise; to complain, or to tell of sorrow, distress, or the\n   like, in a plaintive, nasal tone; hence, to complain or to beg in a\n   mean, unmanly way; to moan basely."
                },
                {
                    "spelling_detail_id": 161274,
                    "word_type": "v. t.",
                    "spelling_usage": "To utter or express plaintively, or in a mean, unmanly\n   way; as, to whine out an excuse."
                },
                {
                    "spelling_detail_id": 161275,
                    "word_type": "n.",
                    "spelling_usage": "A plaintive tone; the nasal, childish tone of mean\n   complaint; mean or affected complaint."
                }
            ]
        },
        {
            "id": 101922,
            "spelling_name": "Whisk",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161383,
                    "word_type": "n.",
                    "spelling_usage": "A game at cards; whist."
                },
                {
                    "spelling_detail_id": 161384,
                    "word_type": "n.",
                    "spelling_usage": "The act of whisking; a rapid, sweeping motion, as of\n   something light; a sudden motion or quick puff."
                },
                {
                    "spelling_detail_id": 161385,
                    "word_type": "n.",
                    "spelling_usage": "A small bunch of grass, straw, twigs, hair, or the like,\n   used for a brush; hence, a brush or small besom, as of broom corn."
                },
                {
                    "spelling_detail_id": 161386,
                    "word_type": "n.",
                    "spelling_usage": "A small culinary instrument made of wire, or the like, for\n   whisking or beating eggs, cream, etc."
                },
                {
                    "spelling_detail_id": 161387,
                    "word_type": "n.",
                    "spelling_usage": "A kind of cape, forming part of a woman's dress."
                },
                {
                    "spelling_detail_id": 161388,
                    "word_type": "n.",
                    "spelling_usage": "An impertinent fellow."
                },
                {
                    "spelling_detail_id": 161389,
                    "word_type": "n.",
                    "spelling_usage": "A plane used by coopers for evening chines."
                },
                {
                    "spelling_detail_id": 161392,
                    "word_type": "n.",
                    "spelling_usage": "To sweep, brush, or agitate, with a light, rapid motion; as,\n   to whisk dust from a table; to whisk the white of eggs into a froth."
                },
                {
                    "spelling_detail_id": 161393,
                    "word_type": "n.",
                    "spelling_usage": "To move with a quick, sweeping motion."
                },
                {
                    "spelling_detail_id": 161394,
                    "word_type": "v. i.",
                    "spelling_usage": "To move nimbly at with velocity; to make a sudden agile\n   movement."
                }
            ]
        },
        {
            "id": 102030,
            "spelling_name": "Whittle",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161591,
                    "word_type": "n.",
                    "spelling_usage": "A grayish, coarse double blanket worn by countrywomen, in\n   the west of England, over the shoulders, like a cloak or shawl."
                },
                {
                    "spelling_detail_id": 161592,
                    "word_type": "n.",
                    "spelling_usage": "Same as Whittle shawl, below."
                },
                {
                    "spelling_detail_id": 161593,
                    "word_type": "n.",
                    "spelling_usage": "A knife; esp., a pocket, sheath, or clasp knife."
                },
                {
                    "spelling_detail_id": 161596,
                    "word_type": "v. t.",
                    "spelling_usage": "To pare or cut off the surface of with a small knife;\n   to cut or shape, as a piece of wood held in the hand, with a clasp\n   knife or pocketknife."
                },
                {
                    "spelling_detail_id": 161597,
                    "word_type": "v. t.",
                    "spelling_usage": "To edge; to sharpen; to render eager or excited; esp.,\n   to excite with liquor; to inebriate."
                },
                {
                    "spelling_detail_id": 161598,
                    "word_type": "v. i.",
                    "spelling_usage": "To cut or shape a piece of wood with am small knife; to\n   cut up a piece of wood with a knife."
                }
            ]
        },
        {
            "id": 102323,
            "spelling_name": "Winnow",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 162179,
                    "word_type": "v. i.",
                    "spelling_usage": "To separate chaff from grain."
                }
            ]
        },
        {
            "id": 102406,
            "spelling_name": "Wistful",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 162310,
                    "word_type": "a.",
                    "spelling_usage": "Longing; wishful; desirous."
                },
                {
                    "spelling_detail_id": 162311,
                    "word_type": "a.",
                    "spelling_usage": "Full of thought; eagerly attentive; meditative; musing;\n   pensive; contemplative."
                }
            ]
        },
        {
            "id": 102572,
            "spelling_name": "Wombat",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 162585,
                    "word_type": "n.",
                    "spelling_usage": "Any one of three species of Australian burrowing marsupials\n   of the genus Phascolomys, especially the common species (P. ursinus).\n   They are nocturnal in their habits, and feed mostly on roots."
                }
            ]
        },
        {
            "id": 102786,
            "spelling_name": "Worse",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 162939,
                    "word_type": "compar.",
                    "spelling_usage": "Bad, ill, evil, or corrupt, in a greater degree; more\n   bad or evil; less good; specifically, in poorer health; more sick; --\n   used both in a physical and moral sense."
                },
                {
                    "spelling_detail_id": 162940,
                    "word_type": "n.",
                    "spelling_usage": "Loss; disadvantage; defeat."
                },
                {
                    "spelling_detail_id": 162941,
                    "word_type": "n.",
                    "spelling_usage": "That which is worse; something less good; as, think not the\n   worse of him for his enterprise."
                },
                {
                    "spelling_detail_id": 162942,
                    "word_type": "a.",
                    "spelling_usage": "In a worse degree; in a manner more evil or bad."
                },
                {
                    "spelling_detail_id": 162943,
                    "word_type": "v. t.",
                    "spelling_usage": "To make worse; to put disadvantage; to discomfit; to\n   worst. See Worst, v."
                }
            ]
        },
        {
            "id": 102818,
            "spelling_name": "Would",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 161875,
                    "word_type": "imp.",
                    "spelling_usage": "of Will"
                },
                {
                    "spelling_detail_id": 163002,
                    "word_type": "v. t.",
                    "spelling_usage": "Commonly used as an auxiliary verb, either in the past\n   tense or in the conditional or optative present. See 2d & 3d Will."
                },
                {
                    "spelling_detail_id": 163003,
                    "word_type": "n.",
                    "spelling_usage": "See 2d Weld."
                }
            ]
        },
        {
            "id": 103172,
            "spelling_name": "Yankee",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 163533,
                    "word_type": "n.",
                    "spelling_usage": "A nickname for a native or citizen of New England,\n   especially one descended from old New England stock; by extension, an\n   inhabitant of the Northern States as distinguished from a Southerner;\n   also, applied sometimes by foreigners to any inhabitant of the United\n   States."
                },
                {
                    "spelling_detail_id": 163534,
                    "word_type": "a.",
                    "spelling_usage": "Of or pertaining to a Yankee; characteristic of the\n   Yankees."
                }
            ]
        },
        {
            "id": 103237,
            "spelling_name": "Yearn",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 163638,
                    "word_type": "v. t.",
                    "spelling_usage": "To pain; to grieve; to vex."
                },
                {
                    "spelling_detail_id": 163639,
                    "word_type": "v. i.",
                    "spelling_usage": "To be pained or distressed; to grieve; to mourn."
                },
                {
                    "spelling_detail_id": 163640,
                    "word_type": "v. i. & t.",
                    "spelling_usage": "To curdle, as milk."
                },
                {
                    "spelling_detail_id": 163641,
                    "word_type": "v. i.",
                    "spelling_usage": "To be filled with longing desire; to be harassed or\n   rendered uneasy with longing, or feeling the want of a thing; to strain\n   with emotions of affection or tenderness; to long; to be eager."
                }
            ]
        },
        {
            "id": 103266,
            "spelling_name": "Yellowfin",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 163677,
                    "word_type": "n.",
                    "spelling_usage": "A large squeteague."
                }
            ]
        },
        {
            "id": 103390,
            "spelling_name": "Yonder",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 163878,
                    "word_type": "adv.",
                    "spelling_usage": "At a distance, but within view."
                },
                {
                    "spelling_detail_id": 163879,
                    "word_type": "a.",
                    "spelling_usage": "Being at a distance within view, or conceived of as within\n   view; that or those there; yon."
                }
            ]
        },
        {
            "id": 103529,
            "spelling_name": "Zebra",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 164040,
                    "word_type": "n.",
                    "spelling_usage": "Either one of two species of South African wild horses\n   remarkable for having the body white or yellowish white, and\n   conspicuously marked with dark brown or brackish bands."
                }
            ]
        },
        {
            "id": 103640,
            "spelling_name": "Zither",
            "image_path": null,
            "bee_type": 1,
            "spellingDetails": [
                {
                    "spelling_detail_id": 164173,
                    "word_type": "n.",
                    "spelling_usage": "An instrument of music used in Austria and Germany. It has\n   from thirty to forty wires strung across a shallow sounding-board,\n   which lies horizontally on a table before the performer, who uses both\n   hands in playing on it. [Not to be confounded with the old lute-shaped\n   cittern, or cithern.]"
                }
            ]
        }
]