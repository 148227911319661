
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge,OverlayTrigger, Container, Row, Col, Card} from 'react-bootstrap';


class ContactComponent extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            spellings:[]
        }
    }

    componentDidMount(){
    }

    render(){
        return (
            <div>
                <br/>
                <Container>
                    <Row>
                        <Col>Contact..</Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ContactComponent